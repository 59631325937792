<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835">
        <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-3. 코드관리</span>
            </div>
            <v-spacer></v-spacer>
        </div>
        <v-form ref="mdlform" lazy-validation>

            <div class="mt-15 mx-3">
                <v-row>
                    <v-col class="" cols="5">
                        <div class="d-flex align-center">
                            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold">분류코드</span>
                        </div>
                        <v-row class="justify-right mt-3 mx-3">
                            <v-spacer></v-spacer>
                            <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify"
                                width="100px" height="37px" single-line rounded outlined dense filled hide-details label="분류 조회"
                                @input="setSearchText">
                            </v-text-field>
                        </v-row>
                        <div class="mt-5 mr-2">
                            <v-data-table class="msclnTable mt-2 ml-2" height="" locale="ko" 
                                :headers="headers"
                                :items="items" 
                                :search="schDummy" 
                                :custom-filter="customFilter"
                                hide-default-header hide-default-footer dense
                                style="border-bottom: 1px solid #dbdbdb;">
                                <template v-slot:header="{ props }">
                                    <thead style="">
                                        <th class=" justify-center align-center greyE01 py-1"
                                            style="border-right: 1px solid #dbdbdb !important; width: 40%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                            {{ props.headers[0].text }}</th>
                                        <th class=" justify-center align-center greyE01"
                                            style="width: 50%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                            {{ props.headers[1].text }}</th>
                                    </thead>
                                </template>
                                <template v-slot:item='{ item, index }'>
                                    <tr class="fixedCol" :class="['fixedCol', selectedItem == index ? 'sltRow' : 'disSltRow']"
                                        @click="tableClickEvent(index, item)">
                                        <td class="fixedCol0 "
                                            style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                                            <span style="width:35%;">{{ item.valClcdnm }}</span>
                                        </td>
                                        <td class="fixedCol0" style="height:40px !important; text-align: center;">
                                            <span style="width:35%;">{{ item.valCdDesc }}</span>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </v-col>
                    <v-col class="" cols="7" style="">
                        <div class="d-flex align-center">
                            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold">상세코드</span>
                        </div>
                        <v-row class="justify-right mt-12 mx-3">
                        </v-row>
                        <v-row class="pa-0 ma-0 mt-5"
                            style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;"
                            no-gutters>
                            
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">
                                <span class="black--text font-weight-medium" v-if="valClcd == 96">목욕시간</span>
                                <span class="black--text font-weight-medium" v-else>코드명</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" 
                                style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                <span class="black--text font-weight-medium">순서</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                                style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                <span class="black--text font-weight-medium">사용여부</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                                style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                <v-btn class="" color="blue200" icon height="26" :disabled="btnOnOff"
                                    @click="addRow2()"><v-icon>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <div style="width: 100%;" v-if="items1.length > 0">
                            <div style=" height:500px; overflow-y:auto; width: 100%;">
                                <v-row v-for="(item, i) in items1" :key="i" class="pa-0 ma-0 ma-0" 
                                    style="border-bottom: 1px solid #dbdbdb !important; width: 100%;" no-gutters>
                                    <v-col class="d-flex justify-start align-center pa-1 ma-0" v-if="valClcd == 95" cols="6">
                                        <span class="d-inline-block mr-1" style="width: 70%;">
                                            <v-text-field v-model="item.valCdnm" color="blue" hide-details outlined dense ></v-text-field>
                                        </span>
                                        <span class="d-inline-block" style="width:100px;">
                                            <v-text-field v-model="item.valCdDesc" class="ma-0 pa-0 colorFiled" readonly hide-details outlined dense>
                                                <template v-slot:append>
                                                    <v-menu v-model="menu[i]" top nudge-bottom="210" nudge-left="-30" :close-on-content-click="false">
                                                        <template v-slot:activator="{ on }">
                                                            <div class="" :style="item.wrkPtnColrStyle" v-on="on"></div>
                                                        </template>
                                                        <v-card>
                                                            <v-card-text class="pa-0">
                                                                <v-color-picker
                                                                    v-model="colorObj" :swatches="swatches" @update:color="clrSelector(i)" show-swatches>
                                                                </v-color-picker>
                                                              </v-card-text>
                                                        </v-card>
                                                    </v-menu>
                                                </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col class="d-flex justify-end align-center pa-1 ma-0" v-else-if="valClcd == 96" cols="6">
                                        <div style="width: 65px !important;" class="d-flex justify-end align-center ma-0">
                                            <v-text-field v-model="item.valCdnm" color="blue" hide-details outlined dense type="number"></v-text-field>
                                        </div>
                                        <span class="pa-1 ma-0">분</span>
                                    </v-col>
                                    <v-col class="d-flex justify-start align-center pa-1 ma-0" v-else
                                         cols="6">
                                        <v-text-field v-model="item.valCdnm" color="blue" hide-details outlined dense ></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex justify-start align-center pa-1 ma-0"
                                            style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                        <v-text-field v-model="item.ord" color="blue" hide-details outlined dense ></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex justify-start align-center pa-1 ma-0"
                                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                        <span style="width:100%;">
                                            <v-select dense hide-details outlined v-model="item.useYn" :items="selYn"
                                                item-text="valcdnm" item-value="valcd" :rules="[rules.selCheck]"></v-select>
                                        </span>
                                    </v-col>
                                    <v-col class="d-flex justify-center align-center pa-1 ma-0"
                                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                        <span class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;" v-if="item.fcltyNum != 10000000000">
                                            <v-btn class="" color="blue200" icon height="26"
                                                @click="onSave(i)"><v-icon>mdi-content-save-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="" color="grey006" icon height="26" v-if="item.cmnItmValCdPk > 0"
                                                @click="onDelete1(i)"><v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="" color="grey006" icon height="26" v-else
                                                @click="onDelete1(i)"><v-icon>mdi-minus-circle-outline</v-icon>
                                            </v-btn>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div style="width: 100%;" v-if="items2.length > 0">
                            <div style=" height:500px; overflow-y:auto; width: 100%;">
                                <v-row v-for="(item2, i) in items2" :key="i" class="pa-0 ma-0 ma-0" 
                                style="border-bottom: 1px solid #dbdbdb !important; width: 100%;" no-gutters>
                                    
                                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="6">
                                        <v-text-field v-model="item2.itmCdnm" color="blue" hide-details outlined dense ></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex justify-start align-center pa-1 ma-0"
                                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                        <v-text-field v-model="item2.ord" color="blue" hide-details outlined dense ></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex justify-start align-center pa-1 ma-0"
                                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                        <span style="width:100%;">
                                            <v-select dense hide-details outlined v-model="selYn[0]" :items="selYn"
                                                item-text="valcdnm" item-value="valcd" :rules="[rules.selCheck]"></v-select>
                                        </span>
                                    </v-col>
                                    <v-col class="d-flex justify-center align-center pa-1 ma-0"
                                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                        <span class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;" v-if="item2.fcltyNum != 10000000000">
                                            <v-btn class="" color="blue200" icon height="26"
                                                @click="onSave2(i)"><v-icon>mdi-content-save-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="" color="grey006" icon height="26" v-if="item2.cmnItmCdPk > 0"
                                                @click="onDelete2(i)"><v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                            <v-btn class="" color="grey006" icon height="26" v-else
                                                @click="onDelete2(i)"><v-icon>mdi-minus-circle-outline</v-icon>
                                            </v-btn>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-form>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'상세코드'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
        <v-dialog v-model="dialogDelete1" max-width="500">
            <btnModalDelete :title="'상세코드'" @madalState="onDeleteBtn1"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template> 

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { selCmnItmValClCdList, selCmnItmValCd, insCmnItmValCd, delCmnItmValCd, selCmnItmCd, insCmnItmCd, delCmnItmCd } from '../../api/cmm.js';
export default {
    name: 'CmmCdMgmt',

    components: {
        btnModalDelete,

    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selCmnItmValClCdList()
        })
    },

    computed: {
        swatchStyle: function () {
            return {
                backgroundColor: this.color[this.sltIndex],
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                borderRadius: this.menu[this.sltIndex] ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out',
                margin: '1px 0px 0px 0px !important'
            }
        },
    },
    watch: {
    },

    methods: {
        selCmnItmValClCdList: function () {
            selCmnItmValClCdList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selCmnItmValClCdListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        selCmnItmValClCdListAfter: function (res) {
            this.items = res
        },
        selCmnItmValCd: function (cd) {
            this.valClcd = cd
            selCmnItmValCd(this.$store.getters.getFcltyNum, cd)
                .then((response) => (this.selCmnItmValCdAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        selCmnItmValCdAfter: function (res) {
            this.items1.splice(0)
            res.forEach(el => {
                let wrkPtnColrStyle =  'cursor: pointer; height: 20px; width: 20px;' +
                    'margin: 1px 0px 0px 0px !important; transition: border-radius 200ms ease-in-out; background-color: '+el.valCdDesc+'; border-radius: 4px;'
                el.wrkPtnColrStyle = wrkPtnColrStyle
                this.items1.push(el)
            });
        },
        selCmnItmCd: function (clsfc1cd, clsfc2cd, clsfc3cd) {
            this.clsfc1cd = clsfc1cd
            this.clsfc2cd = clsfc2cd
            this.clsfc3cd = clsfc3cd
            selCmnItmCd(this.$store.getters.getFcltyNum, clsfc1cd, clsfc2cd, clsfc3cd, 1)    
                .then((response) => (this.selCmnItmCdAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        selCmnItmCdAfter: function (res) {
            this.items2 = res       
        },
        addRow2() {
            if(this.add ==1){
                if(this.items1.length>0){
                    let intValCd = Number(this.items1[this.items1.length - 1].valCd);
                    let wrkPtnColrStyle =  'cursor: pointer; height: 20px; width: 20px;' +
                        'margin: 1px 0px 0px 0px !important; transition: border-radius 200ms ease-in-out; background-color: #000000; border-radius: 4px;'
                    let addObj = {
                        valCd: intValCd + 1,
                        valCdnm: '',
                        valClcd: '',
                        valClcdnm: '',
                        useYn: '1',
                        valCdDesc: '',
                        wrkPtnColrStyle: wrkPtnColrStyle,
                        ord: this.items1.length + 1 ,
                    }
                    this.items1.push(addObj)
                }else{
                    let wrkPtnColrStyle =  'cursor: pointer; height: 20px; width: 20px;' +
                        'margin: 1px 0px 0px 0px !important; transition: border-radius 200ms ease-in-out; background-color: #000000; border-radius: 4px;'
                    let addObj = {
                        valCd: 1,
                        valCdnm: '',
                        valClcd: '',
                        valClcdnm: '',
                        useYn: '1',
                        valCdDesc: '',
                        wrkPtnColrStyle: wrkPtnColrStyle,
                        ord: this.items1.length + 1 ,
                    }
                    this.items1.push(addObj)
                }
            } else if(this.add == 2){
                if(this.items2.length>0){
                    let intItmcd = Number(this.items2[this.items2.length - 1].itmCd);
                    let addObj = {
                        itmCd: intItmcd + 1,
                        valCd: '',
                        valClcd: '',
                        valClcdnm: '',
                        useYn: '1',
                        ord: this.items2.length + 1 ,
                    }
                    this.items2.push(addObj)
                }else{
                    let addObj = {
                        itmCd: 1,
                        valCd: '',
                        valClcd: '',
                        valClcdnm: '',
                        useYn: '1',
                        ord: this.items2.length + 1 ,
                    }
                    this.items2.push(addObj)
                }
            }
        },
        onSave: function (key) {
            if(this.$refs.mdlform.validate()){
                let obj = {
                    valClcd: this.valClcd,
                    valClcdnm: this.items3.valClcdnm,
                    valCd: this.items1[key].valCd,
                    ord: this.items1[key].ord,
                    valCdnm: this.items1[key].valCdnm,
                    useYn: this.items1[key].useYn,
                    userNm: this.$store.getters.getUserNm,
                    cmnItmValCdPk: this.items1[key].cmnItmValCdPk,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    valCdDesc: this.items1[key].valCdDesc,
                    cdPrp: 2,
                }
                insCmnItmValCd(obj)
                    .then((response) => (this.insCmnItmValCdAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/insPrgInf : ' + error))
            }
        },
        insCmnItmValCdAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selCmnItmValCd(this.valClcd)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        onSave2: function (key) {
            if(this.$refs.mdlform.validate()){
                if(this.items2[key].cmnItmCdPk==null){
                    let obj = {
                        clsfc1Cd: this.clsfc1cd,
                        clsfc1Cdnm: this.items2[0].clsfc1Cdnm,
                        clsfc2Cd: this.clsfc2cd,
                        clsfc2Cdnm: this.items2[0].clsfc2Cdnm,
                        clsfc3Cd: this.clsfc3cd,
                        clsfc3Cdnm: this.items2[0].clsfc3Cdnm,
                        itmCd: this.items2[key].itmCd,
                        itmCdnm: this.items2[key].itmCdnm,
                        valClcd: this.items3.valClcd,
                        ord: this.items2[key].ord,
                        userNm: this.$store.getters.getUserNm,
                        // cmnItmCdPk: this.items2[key].cmnItmCdPk,
                        fcltyNum: this.$store.getters.getFcltyNum,
                        cdPrp: 2,
                        useYn: 1,
                    }
                    insCmnItmCd(obj)
                        .then((response) => (this.insCmnItmCdAfter(response.data)))
                        .catch((error) => console.log('connect error prgrm/insPrgInf : ' + error))
                }else if (this.items2[key].cmnItmCdPk!=null){
                    let obj = {
                        clsfc1Cd: this.clsfc1cd,
                        clsfc1Cdnm: this.items2[0].clsfc1Cdnm,
                        clsfc2Cd: this.clsfc2cd,
                        clsfc2Cdnm: this.items2[0].clsfc2Cdnm,
                        clsfc3Cd: this.clsfc3cd,
                        clsfc3Cdnm: this.items2[0].clsfc3Cdnm,
                        itmCd: this.items2[key].itmCd,
                        itmCdnm: this.items2[key].itmCdnm,
                        valClcd: this.items3.valClcd,
                        ord: this.items2[key].ord,
                        userNm: this.$store.getters.getUserNm,
                        cmnItmCdPk: this.items2[key].cmnItmCdPk,
                        fcltyNum: this.$store.getters.getFcltyNum,
                        cdPrp: 2,
                        useYn: 1,
                    }
                    insCmnItmCd(obj)
                        .then((response) => (this.insCmnItmCdAfter(response.data)))
                        .catch((error) => console.log('connect error prgrm/insPrgInf : ' + error))
                }
                
            }
        },
        insCmnItmCdAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selCmnItmCd(this.clsfc1cd, this.clsfc2cd, this.clsfc3cd)

            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        onDelete1(key) {
            if (this.$store.getters.getFcltyNum != 10000000000) {
                if (this.items1[key].cmnItmValCdPk != undefined) {
                    this.key = key
                    this.dialogDelete = true
                } else {
                    if (key != 0) {
                        this.items1.splice(key, 1)
                    }
                }
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items1[this.key].cmnItmValCdPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        cmnItmValCdPk: this.items1[this.key].cmnItmValCdPk,
                    }
                        delCmnItmValCd(obj)
                            .then((response) => (this.delCmnItmValCdAfter(response.data)))
                            .catch((error) => console.log(error))
                } else {
                    this.items1.splice(this.key, 1)
                    this.dialogDelete = false
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delCmnItmValCdAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selCmnItmValCd(this.valClcd)
                this.dialogDelete = false
                this.selectedItem = -1
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete2(key) {
            if (this.$store.getters.getFcltyNum != 10000000000) {
                if (this.items2[key].cmnItmCdPk != undefined) {
                    this.key = key
                    this.dialogDelete1 = true
                } else {
                    if (key != 0) {
                        this.items2.splice(key, 1)
                    }
                }
            }
        },
        onDeleteBtn1: function (res) {
            if (res == 9990) {
                if (this.items2[this.key].cmnItmCdPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        cmnItmCdPk: this.items2[this.key].cmnItmCdPk,
                    }
                        delCmnItmCd(obj)
                            .then((response) => (this.delCmnItmCdAfter(response.data)))
                            .catch((error) => console.log(error))
                } else {
                    this.items2.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delCmnItmCdAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selCmnItmCd(this.clsfc1cd, this.clsfc2cd, this.clsfc3cd)
                this.dialogDelete1 = false
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onShow: function (cd, clsfc1cd, clsfc2cd, clsfc3cd) {
            if(cd == 116){
                this.selCmnItmCd(clsfc1cd, clsfc2cd, clsfc3cd)
                this.add = 2
            }else{
                this.selCmnItmValCd(cd)
                this.add = 1
            }
        },
        tableClickEvent: function (rn, item) {
            if (this.selectedItem == -1) {
                this.selectedItem = rn
                if(item.valClcd == 116){
                    this.items1.splice(0)
                }else{
                    this.items2.splice(0)
                }
                this.onShow(item.valClcd, item.clsfc1cd, item.clsfc2cd, item.clsfc3cd)
                this.btnOnOff = false
                this.items3 = item
                this.valClcd = item.valClcd
                this.clsfc1Cdnm = item.clsfc1cdnm
                this.clsfc2Cdnm = item.clsfc2cdnm
                this.clsfc3Cdnm = item.clsfc3cdnm
            }
            else if (this.selectedItem != rn) {
                this.selectedItem = rn
                if(item.valClcd == 116){
                    this.items1.splice(0)
                }else{
                    this.items2.splice(0)
                }
                this.onShow(item.valClcd, item.clsfc1cd, item.clsfc2cd, item.clsfc3cd)
                this.btnOnOff = false
                this.items3 = item
                this.valClcd = item.valClcd
                this.clsfc1Cdnm = item.clsfc1Cdnm
                this.clsfc2Cdnm = item.clsfc2Cdnm
                this.clsfc3Cdnm = item.clsfc3Cdnm
            }
            else {
                //Form Clear
                this.selectedItem = -1
                this.btnOnOff = true
                this.items1.splice(0)
                this.items2.splice(0)
                this.items3 = Object.assign({})  
                this.valClcd = 0
                this.clsfc1Cdnm = ''
                this.clsfc2Cdnm = ''
                this.clsfc3Cdnm = ''
            }
        },
        onClickBtn: function (text) {
            if (text == "저장") {
                this.insCmnItmValCd()
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
      
        customFilter: function (value, search, filter) {
            // value: items, search: search key, filter: array column
            let col0 = filter.valClcdnm.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.valClcdnm.length != 0){
                this.schChkField.valClcdnm.forEach((valClcdnm1, i) => {
                    if(i == 0) col1 = filter.valClcdnm.includes(valClcdnm1)
                    else col1 = col1 || filter.valClcdnm.includes(valClcdnm1)
                });
            }
            this.selectedItem = -1
            return col0 && col1 ;
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        clrSelector: function (index){
            // this.color[index] = this.colorObj.hex
            this.items1[index].valCdDesc = this.colorObj.hex
            this.items1[index].wrkPtnColrStyle = 'cursor: pointer; height: 20px; width: 20px;' +
                'margin: 1px 0px 0px 0px !important; transition: border-radius 200ms ease-in-out;' +
                'border-radius: 4px; background-color: ' + this.colorObj.hex + '; '
        },
    },

    data: () => ({
        headers: [
            { text: '분류', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '적용 화면', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        rules: {
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
        },
        selYn: [{ valcd: '1', valcdnm: '사용' }, { valcd: '2', valcdnm: '미사용' },],
        snackOptions: [false, 3000, '', 'info'],
        schChkField: { valClcdnm: [], target: '' },
        schField: '',
        schDummy: '',
        btnOnOff: true,
        dialogDelete: false,
        dialogDelete1: false,
        items: [],
        items1: [],
        items2: [],
        selectedItem: -1,
        valClcd: '',
        key: 0,
        color: [],
        colorObj: null,
        swatches: [
            ['#FF0000', '#AA0000', '#550000'],
            ['#FFFF00', '#AAAA00', '#555500'],
            ['#00FF00', '#00AA00', '#005500'],
            ['#00FFFF', '#00AAAA', '#005555'],
            ['#0000FF', '#0000AA', '#000055'],
        ],
        clsfc1cd:'',
        clsfc2cd:'',
        clsfc3cd:'',
        clsfc1Cdnm: '',
        clsfc2Cdnm: '',
        clsfc3Cdnm: '',
        items3: {
            clsfc1Cd : '',
            clsfc1Cdnm: '',
            clsfc2Cd : '',
            clsfc2Cdnm: '',
            clsfc3Cd : '',
            clsfc3Cdnm: '',
            valClcd: '',
            valClcdnm: '',
        },
        menu: [],
        wrkPtnObj: {
            // cmnItmValCdPk
            // fcltyNum
            // ord
            // useYn
            // valCd
            // valCdDesc
            // valCdnm
            // valClcd
            // valClcdnm
            wrkPtnColrStyle: 'cursor: pointer; height: 20px; width: 20px;' +
            'margin: 1px 0px 0px 0px !important; transition: border-radius 200ms ease-in-out; background-color: #000000; border-radius: 4px;'
        },
        add: 0,
    }),
}
</script>
