<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
        <v-row no-gutters>
            <v-col cols="6">
                <div class="d-inline-block">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">유치도뇨관 관리기록</span>
                        <v-spacer></v-spacer>
                        <span v-if="preBtn[0]">
                            <v-btn 
                                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                                min-width="30" height="25"
                                @click="getPreItmes"
                                small outlined>
                                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                            </v-btn>
                        </span>
                    </div>
                    <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <span class="black--text font-weight-medium">제공자</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="3">
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field v-model="items.wrtr" color="blue" ref="info" readonly
                                    :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="26"  
                                style="padding:0px 1px 0px 2px !important"
                                @click="onModal(0)"
                                >                                    
                                <v-tooltip top >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>직원선택</span>
                                </v-tooltip>

                            </v-btn>   
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>                    
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">유치도뇨관</div>
                                <div class="d-flex justify-center black--text font-weight-medium">조치사항</div>
                            </div>
                        </v-col>
                        <v-col class="justify-start align-center pa-1 ma-0" cols="8">
                            <div class="d-inline-block" v-for="(item, i) in codeItem32" :key="i">
                                <v-checkbox v-if="item.valcdnm !='기타'" v-model="items.itm3ValAr" :label="item.valcdnm" hide-details :value="item.valcd" :style="setStyle(item.valcdnm.length)"></v-checkbox>
                            </div>
                            <div class="d-flex mt-1">
                                <v-checkbox v-model="items.itm3ValAr" label="기타" hide-details value="7" @click="onItemChk">
                                    <template v-slot:label>
                                        <span class="black--text">기타</span>
                                    </template>
                                </v-checkbox>
                                <div style="width:120px;">
                                    <v-text-field v-model="items.itm3Val" :disabled="etcDisabled" hide-details outlined dense></v-text-field>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">관리기록</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">
                            <v-textarea v-model="items.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="9" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="pl-2 ma-0 align-self-start">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">소변량기록&#40;유치도뇨관&#41;&#45;필요시</span>
                        <v-spacer></v-spacer>
                        <span v-if="preBtn[1]">
                            <v-btn 
                                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                                min-width="30" height="25"
                                @click="getPreList"
                                small outlined>
                                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                            </v-btn>
                        </span>
                    </div>
                    <v-row :class="['d-flex mt-2', recItems.listDtl.length > 6 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                            <span class="black--text font-weight-medium">횟수</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">소변시간</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">소변량</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">                             
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow('rec1')">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div style="height:294px;overflow-y:auto;">
                        <div v-if="recItems.listDtl.length > 0">
                            <v-row v-for="(item, i) in recItems.listDtl" :key="i" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                                    <span class="black--text font-weight-medium">{{i+1}}</span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                                    <span class="white d-inline-block pa-1" style="width: 80px;">                                     
                                        <CmmTimeField v-model="item.ofrTmStrt" :required="false"></CmmTimeField>
                                    </span>
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                                    <span style="width:80px" class="ml-1">
                                        <v-text-field v-model="item.itm1Val" type="number" @input="getItmValTot" hide-details outlined dense></v-text-field>
                                    </span> 
                                </v-col>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">                                 
                                    <v-btn  icon color='grey006' @click="delRow('rec1',i)"> 
                                        <v-icon v-if="item.bnftOfrRecPk > 0">mdi-trash-can-outline</v-icon>                                           
                                        <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else class="d-flex pa-0" no-gutters style="width:435px;border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div> 
                        </div>
                    </div>
                    <v-row :class="['d-flex ', recItems.listDtl.length > 6 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2"> 
                            <span class="black--text font-weight-medium">총합</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                            
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;"> 
                            {{restot.itm1ValTot?.toLocaleString('ko-KR')}}
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>             
        </v-form>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">유치도뇨관 기록 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="205" locale="ko"
                :headers="histHeaders"
                :items="nsgCareRcrdHist" 
                hide-default-header
                hide-default-footer
                fixed-header
                dense disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:300px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:300px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:150px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr :class="['fixedCol', $parent.$parent.$parent.$parent.inqYMD == item.recDt ? 'sltRow' : 'disSltRow']"
                        @click="tableClickEvent(item.recDt)">
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.recDt?.beforeDateFormatDot()}}</td> 
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:300px" class="text-overflow">{{item.itm2ValNm}}</div>
                        </td>                             
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:300px" class="text-overflow">{{item.cntnt}}</div>
                        </td>  
                        <td class="clmsBodyCommon fixedCol0">{{item.wrtr}}</td>            
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div>            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delModelTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmTimeField from '../../components/commons/CmmTimeField.vue';
import { selBnftOfrRecList, selExcrtNrsRecList, selPreBnftOfrRecList, insBnftOfrRecMulti, delBnftOfrRec, delAllBnftOfrRec } from '../../api/bnftrcrd.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'NsgCareRcrdTab4',
    
    components: {
        EmpSelector,
        btnModalDelete,
        CmmTimeField,

    },

    props : {
        
    },

    created: function(){       
      getMultiStcd(['31','32','33'], this.$store.getters.getFcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
    },

    mounted: function(){
        
    },

    computed: {
       
    },  

    watch: {
    },

    methods: {
        codeMapping: function (res){            
            this.codeItem31 = res.vCd31
            this.codeItem32 = res.vCd32
            this.$refs.tabform.reset()
        },  
        setStyle:function(len){
            let style = "";
            let wd = (len*15)+20;
            style="width:"+wd+"px"        
            return style;
        },  
        onLoad:function(){
            this.frmReset()
            this.getBnftOfrRecList()
            this.getExcrtNrsRecList(0,this.itemsRows)
            this.$refs.tabform.resetValidation()
        },    
        frmReset:function(){
            this.$refs.tabform.reset()              
            this.items.bnftOfrRecHisPk = 0
            this.items.bnftOfrRecPk = 0
            this.items.recDt  =  '',
            this.items.bnMmbrPk = 0,
            this.items.userNm = ''
            this.items.itm1Val = ''
            this.items.itm2ValAr.splice(0)
            this.items.itm3ValAr.splice(0)
            this.items.itm3Val = ''
            this.items.cntnt = ''
            this.items.wrtr = ''
            this.items.wrtrMmbrPk = 0

            this.recItems.bnftOfrRecHisPk = 0
            this.recItems.recDt = ''
            this.recItems.listDtl.splice(0)
            
            this.recItems1.bnftOfrRecHisPk = 0
            this.recItems1.bnftOfrRecPk = 0
            this.recItems1.recDt = ''
            this.recItems1.wrtr = ''
            this.recItems1.wrtrMmbrPk = 0
            this.recItems1.itm1Val = ''
            this.recItems1.itm2Val = ''
            this.recItems1.itm3Val = ''            
        },
        getBnftOfrRecList1(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '3',
                clsfc3CdS: ['3','4'],
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD: this.$parent.$parent.$parent.$parent.inqYMD,
            }

            selBnftOfrRecList(obj)
            .then((response) => ( this.getBnftOfrRecList1After(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecList : ' + error))
        },
        getBnftOfrRecList1After:function(res){
           
            this.recItems.listDtl.splice(0)
            res.forEach(el => {   
                if(el.clsfc3Cd == '3'){
                    this.recItems1.bnftOfrRecHisPk  = el.bnftOfrRecHisPk
                    this.recItems1.bnftOfrRecPk     = el.bnftOfrRecPk
                    this.recItems1.recDt            = el.recDt
                    this.recItems1.wrtr             = el.wrtr
                    this.recItems1.wrtrMmbrPk       = el.wrtrMmbrPk
                    this.recItems1.itm1Val          = el.itm1Val
                    this.recItems1.itm2Val          = el.itm2Val
                    this.recItems1.itm3Val          = el.itm3Val
                }else if(el.clsfc3Cd == '4'){
                    this.recItems.bnftOfrRecHisPk = el.bnftOfrRecHisPk
                    let ofrTmStrt = ''
    
                    if(el.ofrTmHhStrt?.isEmptyVal() && el.ofrTmMmStrt?.isEmptyVal()) ofrTmStrt = el.ofrTmHhStrt+':'+el.ofrTmMmStrt
        
                    let obj = {
                        bnftOfrRecPk:el.bnftOfrRecPk,                    
                        ofrTmStrt:ofrTmStrt,
                        ofrTmHhStrt:el.ofrTmHhStrt,
                        ofrTmMmStrt:el.ofrTmMmStrt,                    
                        itm1Cd:'2',
                        itm1Val:el.itm1Val,
                        wrtr:el.wrtr,
                        wrtrMmbrPk:el.wrtrMmbrPk,
                    }
    
                    this.recItems.listDtl.push(obj)
                } 
            });

            this.getItmValTot()        
            
            if(this.recItems.listDtl.length > 0) this.preBtn.splice(1, 1, false)
            else this.preBtn.splice(1, 1, true)
        },
        getBnftOfrRecList(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '303',
                clsfc2Cd: '2',
                clsfc3Cd: '2',
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD: this.$parent.$parent.$parent.$parent.inqYMD,
            }
            
            selBnftOfrRecList(obj)
            .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecList : ' + error))
        },
        getBnftOfrRecListAfter:function(res){
            res.forEach(el => {
                this.items.bnftOfrRecHisPk = el.bnftOfrRecHisPk
                this.items.bnftOfrRecPk = el.bnftOfrRecPk
                this.items.itm1Val = el.itm1Val // 배설간호구분
                this.items.itm3ValAr = [] // 유치도뇨관 조치사항
                this.items.itm3Val = el.itm3Val // 유치도뇨관 조치사항 기타 필드
                this.items.cntnt = el.cntnt // 관리기록
                this.items.wrtr = el.wrtr // 유치도뇨관 제공자
                this.items.wrtrMmbrPk = el.wrtrMmbrPk

                if(el.itm3ValAr ){
                    el.itm3ValAr.forEach(val => {
                        this.items.itm3ValAr.push(val)
                    });
                }
                this.onItemChk()
            });

            this.getBnftOfrRecList1()
        },
        getItmValTot:function(){
            this.restot.itm1ValTot = 0
            this.recItems.listDtl.forEach(el => {                
                this.restot.itm1ValTot += Number(el.itm1Val)
            });
        },        
        getPreItmes(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc1Cd: '303',
                clsfc2Cd: '2',
                clsfc3Cd: '2',
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreBnftOfrRecListAfter(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selPreBnftOfrRecList : ' + error))
        },
        getPreBnftOfrRecListAfter:function(res){            
            res.forEach(el => {                
                this.items.itm1Val = el.itm1Val // 배설간호구분
                this.items.itm3ValAr = [] // 유치도뇨관 조치사항
                this.items.itm3Val = el.itm3Val // 유치도뇨관 조치사항 기타 필드
                this.items.cntnt = el.cntnt // 관리기록
                this.items.wrtr = el.wrtr // 유치도뇨관 제공자
                this.items.wrtrMmbrPk = el.wrtrMmbrPk

                if(el.itm3ValAr.length > 0 ){
                    el.itm3ValAr.forEach(val => {
                        this.items.itm3ValAr.push(val)
                    });
                }
                this.onItemChk()
            });
        },

        getPreList(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc1Cd: '301',
                clsfc2Cd: '3',
                clsfc3Cd: '4',
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreListAfter(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selPreBnftOfrRecList : ' + error))
        },
        getPreListAfter:function(res){ 
            this.recItems.listDtl.splice(0)
            res.forEach(el => {                
                this.recItems.bnftOfrRecHisPk = 0
                let ofrTmStrt = ''

                if(el.ofrTmHhStrt?.isEmptyVal() && el.ofrTmMmStrt?.isEmptyVal()) ofrTmStrt = el.ofrTmHhStrt+':'+el.ofrTmMmStrt
    
                let obj = {
                    bnftOfrRecPk:0,                    
                    ofrTmStrt:ofrTmStrt,
                    ofrTmHhStrt:el.ofrTmHhStrt,
                    ofrTmMmStrt:el.ofrTmMmStrt,                    
                    itm1Cd:'2',
                    itm1Val:el.itm1Val,
                    wrtr:el.wrtr,
                    wrtrMmbrPk:el.wrtrMmbrPk,
                }

                this.recItems.listDtl.push(obj)
            });

            this.getItmValTot()            
        },
        getExcrtNrsRecList : function(strt, lmt){     
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc3Cd: 2,
                rnStrt:strt,
                rnLmt:lmt, 
            }         
            selExcrtNrsRecList(obj)
                .then((response) => ( this.getExcrtNrsRecListAfter(response.data)))
                .catch((error) => console.log(error))                
        },
        getExcrtNrsRecListAfter:function(res){
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)            
            this.nsgCareRcrdHist = res.list

            this.nsgCareRcrdHist.forEach(e => {
                let target = this.codeItem31.find(x=>x.valcd == e.itm1Val) 
                e.itm1ValNm = target.valcdnm
                let itm2ValNm = ""
                let n=0
                e.itm2ValAr.forEach(e => {                                        
                    if(n == 0)
                        itm2ValNm = e
                    else
                        itm2ValNm += ", "+e
                    n++
                });

                e.itm2ValNm = itm2ValNm
            });               
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getExcrtNrsRecList(strt,this.itemsRows)
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk            
        },
        onItemChk:function(){
            let etcBl = this.items.itm3ValAr.some((element) => element =='7')
            this.etcDisabled = !etcBl            
        },
        onforce(key){
            this.mtimes[key] = false
            ++this.forceRender
        },
        onSave:function(){

            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0) {

                if(this.$refs.tabform.validate()){

                    let obj = {list:[]}
    
                    let recItems1 = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,
                        recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                        wrtr        : this.items.wrtr,
                        wrtrMmbrPk  : this.items.wrtrMmbrPk,          
                        clsfc1Cd:'303',
                        clsfc2Cd:'2',
                        clsfc3Cd:'2',
                        cntnt:this.items.cntnt,
                        listDtl : [
                            {
                                itm1Val:'1',
                                itm1Cd:'31',
                                itm2ValAr:this.items.itm2ValAr,
                                itm2Cd:'32',
                                itm3ValAr:this.items.itm3ValAr,
                                itm3Cd:'33',
                                itm3Val:this.items.itm3Val,
                                wrtr:this.items.wrtr,
                                wrtrMmbrPk:this.items.wrtrMmbrPk,
                            }
                        ]
                    }
    
                    obj.list.push(recItems1)
    
                    let recItems2 = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,
                        recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                        wrtr        : this.items.wrtr,
                        wrtrMmbrPk  : this.items.wrtrMmbrPk,                   
                        clsfc1Cd    : '301',
                        clsfc2Cd    : '3',
                        clsfc3Cd    : '4',
                        listDtl     : []
                    }
    
                    this.recItems.listDtl.forEach(x => {
                        if(x.ofrTmStrt?.isEmptyVal()){
                            let ofrTmStrt = x.ofrTmStrt.split(":")
                            x.ofrTmHhStrt = ofrTmStrt[0]
                            x.ofrTmMmStrt = ofrTmStrt[1]
                            x.wrtr = this.items.wrtr
                            x.wrtrMmbrPk =this.items.wrtrMmbrPk
    
                            recItems2.listDtl.push(x)
                        }
                    });
    
                    let recItems3 = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,
                        recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                        wrtr        : this.items.wrtr,
                        wrtrMmbrPk  : this.items.wrtrMmbrPk,                 
                        clsfc1Cd    : '301',
                        clsfc2Cd    : '3',
                        clsfc3Cd    : '3',
                        listDtl     : [
                            {
                                itm1Cd:'1',
                                itm1Val:this.recItems1.itm1Val,
                                itm2Cd:'2',
                                itm2Val:recItems2.listDtl.length,
                                itm3Cd:'3',
                                itm3Val:this.recItems1.itm3Val,
                                wrtr:this.recItems1.wrtr,
                                wrtrMmbrPk:this.recItems1.wrtrMmbrPk,
                            }
                        ]
                    }
    
    
                    if(recItems2.listDtl.length > 0) {
                        obj.list.push(recItems2)
                        obj.list.push(recItems3)
                    }
                    this.$parent.$parent.$parent.$parent.progress = true
                    insBnftOfrRecMulti(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insBnftOfrRecMulti : ' + error))
                }else{
                    let error = {
                        statusCode:500,
                        message:'필수항목을 입력해주세요.'
                    }
                    this.$emit("nsgCareRcrdTrigger",error)
                }

            }else{
                let error = {
                    statusCode:500,
                    message:'수급자를 선택해주세요.'
                }
                this.$emit("nsgCareRcrdTrigger",error)
            }
        },
        onSaveAfter:function(res){
            this.$emit('nsgCareRcrdTrigger', res);
            this.onLoad()
        },
        addRow(type){
          switch (type) {
            case 'rec1':
                this.recItems.listDtl.push(
                            {
                                bnftOfrRecPk:0,
                                ofrTmStrt: "",
                                ofrTmHhStrt:"",
                                ofrTmMmStrt:"",
                                wrtr:'',
                                wrtrMmbrPk:0,
                                itm1Cd:'2',
                                itm1Val:'',
                            })
                break;           
            default:
                break;
          }
        },
        delRow(type, idx){
            switch (type) {
                case 'rec1':
                    if(this.recItems.listDtl[idx].bnftOfrRecPk > 0){
                        this.idx=idx
                        this.type=type
                        this.delModelTitle  = '소변량 기록'
                        this.dialogDelete = true
                    }else{
                        this.recItems.listDtl.splice(idx,1)
                    }
                    break;                
                default:
                    break;
           }
        },
        delAll(){
            this.delModelTitle='배설관리'
            this.type = 'all'
            this.dialogDelete = true
        },
        onDelete:function(res){
            if(res == 9990){
                this.dialogDelete = false
                if(this.type=='all'){
                    
                    let fcltyNum = this.$store.getters.getFcltyNum     
                                    
                    let obj = {list:[]}  

                    if(this.items.bnftOfrRecHisPk > 0){
                        obj.list.push({
                            fcltyNum:fcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            bnftOfrRecHisPk:this.items.bnftOfrRecHisPk,
                        })
                    }

                    if(this.recItems.bnftOfrRecHisPk > 0){
                        obj.list.push({
                            fcltyNum:fcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            bnftOfrRecHisPk:this.recItems.bnftOfrRecHisPk,
                        })
                    }

                    if(obj.list.length > 0) {
                        this.$parent.$parent.$parent.$parent.progress = true
                        delAllBnftOfrRec(obj)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
                    } else {
                        this.$emit('onMsgTrigger','warning','삭제 대상이 존재하지 않습니다.')
                    }
                }else{
                    let bnftOfrRecPk = 0
                    let bnftOfrRecHisPk = 0
                    switch (this.type) {
                        case 'rec1':
                            bnftOfrRecPk = this.recItems.listDtl[this.idx].bnftOfrRecPk
                            bnftOfrRecHisPk = this.recItems.bnftOfrRecHisPk
                            break;                       
                        default:
                            break;
                    }

                    if(bnftOfrRecPk > 0){
                        let obj = {
                            fcltyNum: this.$store.getters.getFcltyNum,
                            bnMmbrPk : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                            userNm : this.$store.getters.getUserNm,
                            bnftOfrRecPk : bnftOfrRecPk,
                            bnftOfrRecHisPk : bnftOfrRecHisPk,
                        }
                        this.$parent.$parent.$parent.$parent.progress = true
                        delBnftOfrRec(obj)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('upload error /bnftrcrd/delBnftOfrRec : ' + error))
                    }
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){            
            this.$emit('nsgCareRcrdTrigger', res);
            this.onLoad()
            this.dialogDelete = false            
        },
        tableClickEvent(value){            
            if(value != ''){
                this.$emit("setDate",value)
            }
        },
    },
    data: () => ({
        idx:0,
        type:'',
        delModelTitle:'배설관리',
        forceRender:0,
        pageCount:0,
        itemsRows: 5,
        page:1,
        codeItem31:[],
        codeItem32:[],
        items:{
            bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt : '', bnMmbrPk:0, userNm:'',
            clsfc1Cd:'303',clsfc2Cd:'2',clsfc3Cd:'2',
            itm1Val:'',itm1Cd:'31',itm2ValAr:[],itm2Cd:'32',itm3ValAr:[],itm3Cd:'33',itm3Val:'',
            cntnt:'',wrtr:'',wrtrMmbrPk:0,},        
        recItems:{
            bnftOfrRecHisPk:0,clsfc1Cd:'301',clsfc2Cd:'3',clsfc3Cd:'4',recDt:'',
            listDtl:[{bnftOfrRecPk:0,ofrTmStrt: "",ofrTmHhStrt:"",ofrTmMmStrt:"",itm1Cd:'2',itm1Val:'',wrtr:'',wrtrMmbrPk:0,}]
        },
        recItems1:{
            bnftOfrRecHisPk:0,bnftOfrRecPk:0,
            clsfc1Cd:'301',clsfc2Cd:'3',clsfc3Cd:'3',
            recDt:'',itm1Cd:'1',itm1Val:'',itm2Cd:'2',itm2Val:'',itm3Cd:'3',itm3Val:'',
            wrtr:'',wrtrMmbrPk:0,
        },
        restot:{itm1ValTot:0},
        etcDisabled:true,
        nsgCareRcrdHist:[],
        histHeaders: [
            { text: '일자',width: '', value: '', align: 'center', },
            { text: '조치사항',width: '', value: '', align: 'center', },
            { text: '간호내용',width: '', value: '', align: 'center', },
            { text: '제공자',width: '', value: '', align: 'center', },
        ],
        dialog:[false,],
        rtimes:[false,false,false,false,false,false,false,],
        mtimes:[false,false,false,false,false,false,false,],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
        preBtn:[true,true],
    }),
};
</script>