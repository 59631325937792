<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">생활실&nbsp;선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <div class="mt-2">
            <div class="d-flex pa-0 ma-0" no-gutters style="width:580px !important;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;">                                
                    <span class="black--text font-weight-medium">건물명</span>
                </div>
                <div style="width:80%;">
                    <div class="d-flex pa-0 ma-0" no-gutters style="width:100%">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">건물층</span>                        
                        </div>
                        <div style="width:85%;">
                            <div class="d-flex pa-0 ma-0" no-gutters style="width:100%">
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:40%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">생활실</span>                        
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:40%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">생활실 구분</span>                        
                                </div>
                                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">적용</span>                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height:430px;overflow-y:auto">
                <div v-for="(blds,i) in lvnRmList" :key="i" class="d-flex pa-0 ma-0" no-gutters style="width:580px !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-bottom: 1px solid #dbdbdb !important;">
                        {{blds.bldnm}}
                    </div>
                    <div style="width:80%;">
                        <div v-for="(bldFlrs,k) in blds.bldFlrs" :key="k" class="d-flex pa-0 ma-0" no-gutters style="width:100%">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-bottom: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                                {{bldFlrs.bldFlr}}
                            </div>
                            <div style="width:85%;">
                                <div v-for="(lvnRms, n) in bldFlrs.lvnRmList" :key="n" class="d-flex pa-0 ma-0" no-gutters style="width:100%">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:40%;border-bottom: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                                        {{lvnRms.lvnRmNm}}
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:40%;border-bottom: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                                        {{lvnRms.lvnRmTypNm}}
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-bottom: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                                        <v-btn
                                            class="ml-1 white--text darken-1" style="padding:1px 3px 0px 2px !important"
                                            color="bckgrColor" min-width="30" height="25"
                                            @click="onAction(lvnRms)" depressed>
                                            <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import { selLvnRmInf,  } from '../../api/opr.js';
import { groupBy } from '../../api/common.js';

export default {
    name: 'LvnRmSelector',

    props : {
        
    },
            
    components: {
        
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getLvnRmInf()
        })
    },

    computed: {

    },

    watch: {
        
    },
        
    methods: {
        onClose: function () {
            this.$emit('isClose', '.')
        },
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmStsList : ' + error))
        },
        getLvnRmInfAfter:function(res){
            let gList = {}
            res.forEach(x=>{
                if (gList[x.bldNm]) gList[x.bldNm].push(x); 
                else gList[x.bldNm] = [x];
            })

            let blds = Object.keys(gList)
            let list = []

            blds.forEach(key => {
                let gbitem = groupBy(gList[key],'bldFlr')
                let gbldFlr = Object.keys(gbitem)
                let item = []

                gbldFlr.forEach(x=>{
                    let obj = {
                        bldFlr:x,
                        lvnRmList:gbitem[x]
                    }

                    item.push(obj)
                })

                list.push({
                    bldnm:key,
                    bldFlrs:item
                })
            });
            this.lvnRmList = list
        },
        onAction: function (selObj) {
            this.$emit('outLvnRm', selObj)
            this.onClose()
        },
    },
    
    data: () => ({
        lvnRmList:[]
    }),
};
</script>