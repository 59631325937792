<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">손익항목관리</span>
            <span class="text-h4 font-weight-bold">&#40;</span>
            <span class="text-h4 font-weight-bold blue--text" v-if="calcClcd=='1' && tabNo==0">수익</span>
            <span class="text-h4 font-weight-bold blue--text" v-else-if="calcClcd=='1' && tabNo==1">수입</span>
            <span class="text-h4 font-weight-bold red--text" v-else-if="calcClcd=='2' && tabNo==0">비용</span>
            <span class="text-h4 font-weight-bold red--text" v-else-if="calcClcd=='2' && tabNo==1">지출</span>
            <span class="text-h4 font-weight-bold">&#41;</span>
            <v-spacer></v-spacer>    
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" :disabled="list.disabled" rounded
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>                   
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-row class="ma-0 pa-0 mt-2" no-gutters>
            <v-col cols="12" class="ma-0 pa-0">
                <v-data-table 
                     locale="ko"
                    :headers="prfLosItmHeaders" 
                    :items="prfLosItmList"
                    height="450"
                    fixed-header
                    hide-default-header 
                    hide-default-footer 
                    disable-pagination
                    dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:25% !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:17% !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:10% !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:23% !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:10% !important;">{{props.headers[4].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT" style="width:10% !important;">
                                    <v-btn 
                                        class="mr-1" 
                                        color="blue200" 
                                        height="26" 
                                        @click="addRow"
                                        icon>
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                    </v-btn>    
                                </th> 
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr>
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                <v-text-field color="blue" v-model="item.itmNm" hide-details outlined dense></v-text-field>
                            </td> 
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                <span class="d-inline-block" style="width: 147px;">
                                    <v-radio-group v-model="item.useYn" @change="chkUse(item)" class="pl-2 pt-1" hide-details row >
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="사용" value="1"></v-radio>                                
                                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="미사용" value="2"></v-radio>                                
                                    </v-radio-group>
                                </span>
                            </td>      
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                {{item.calcClcdNm}}
                            </td>   
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                <v-row no-gutters>
                                    <v-col class="ma-0 pa-0 d-flex justify-start align-center" cols="7">
                                        <span class="d-inline-block" style="width: 147px;">
                                            <v-radio-group v-model="item.dtlYn" class="" hide-details row >
                                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="사용" value="1"></v-radio>                                
                                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="미사용" value="2"></v-radio>                                
                                            </v-radio-group>
                                        </span>
                                    </v-col>
                                    <v-col class="ma-0 pa-0 d-flex justify-center align-center" cols="5">
                                        <v-tooltip v-if="item.prfLosItmPk > 0 && item.orgDtlYn == '1'"  bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                    v-bind="attrs"
                                                    v-on="on"                                                    
                                                    @click="onDtlModal(0, item)"
                                                    color="blue200" style="padding:0px 2px 0px 2px !important" 
                                                    outlined
                                                    small
                                                >                                                                                            
                                                    <v-icon>mdi-square-edit-outline</v-icon>
                                                    <span style="font-size:0.975rem">코드추가</span>
                                                </v-btn>
                                            </template>
                                            <span>{{item.itmNm}} 손익항목상세관리</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </td>      
                            <td class="clmsBodyCommon fixedCol0 tableBR">                               
                                <div class="d-flex justify-center align-center py-2 px-1">
                                    <span style="width:70px;">
                                        <v-text-field color="blue" v-model="item.ord" type="number" hide-details outlined dense></v-text-field>
                                    </span>
                                    <span>
                                        <v-btn icon small style="padding:0px !important" @click="onCalculOrd(item)">                                                
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-calculator</v-icon>
                                                </template>
                                                <span>순서 계산</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                </div>
                            </td>      
                            <td class="clmsBodyCommon fixedCol0">    
                                <v-row no-gutters>
                                    <v-col class="ma-0 pa-0 d-flex justify-center align-center" cols="6">
                                        <v-btn icon color='blue200' @click="onSaveRow(index)">
                                            <v-icon>mdi-content-save-outline</v-icon>
                                        </v-btn>

                                    </v-col>
                                    <v-col class="ma-0 pa-0 d-flex justify-center align-center" cols="6">
                                        <v-btn icon color='grey006' @click="delRow(index)">                                            
                                            <v-icon v-if="item.prfLosItmPk > 0">mdi-trash-can-outline</v-icon>
                                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                            </td>      
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>       
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" persistent max-width="800" min-width="800">                
                <prfLosItmDtlMdl
                    v-if = "dialog[0]"
                    :itmCd  = "itmCd"
                    :itmNm  = "itmNm"
                    :calcClcd = "calcClcd"
                    @isClose="isDtlModal(0)"
                    @modalEvent="modalDtlEvent"
                ></prfLosItmDtlMdl>
            </v-dialog>
        </div> 
    </v-sheet>
</template>
<script>
import prfLosItmDtlMdl from './PrfLosItmDtlMdl.vue';
import { selPrfLosItmList, insPrfLosItm, delPrfLosItm } from '../../api/impExp.js';

export default {
    name: 'MnthlPrlssLosItmWrt',
    
    components: {          
        prfLosItmDtlMdl,  
    },

    props : {
        tabNo       : { type: Number, default: 0 },
        calcClcd    : { type: String, default: '' },
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    created: function(){  

    },

    mounted: function(){
        this.$nextTick(function () {
            this.onLoad()
        })
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmMnthCnfm){
                this.onDelete()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmMnthCnfm = false
            }
        },
    },

    methods: {     
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    console.log(this.selectedList)
                    break;
            
                default:
                    break;
            }
        },        
        onLoad:function(){
            this.getPrfLosItmList()
        },
        

        getPrfLosItmList: function () {
            this.prfLosItmList.splice(0)
            selPrfLosItmList(this.$store.getters.getFcltyNum, this.calcClcd)
                .then((response) => ( this.getPrfLosItmListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selPrfLosItmList : ' + error))
        },
        getPrfLosItmListAfter: function (res) {            
            if(res != null && res != '') {
                res.forEach(item => {
                    item.calcClcdNm = this.getClcdNm(item.calcClcd)
                    item.orgDtlYn   =  item.dtlYn 
                    this.prfLosItmList.push(item)
                });
            }
        },

        onSaveRow:function(idx){            
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                userNm      : this.$store.getters.getUserNm,
                prfLosItmPk : this.prfLosItmList[idx].prfLosItmPk,
                calcClcd    : this.calcClcd,
                itmNm       : this.prfLosItmList[idx].itmNm,
                ord         : this.prfLosItmList[idx].ord,
                useYn       : this.prfLosItmList[idx].useYn,
                dtlYn       : this.prfLosItmList[idx].dtlYn,
                itmCd       : this.prfLosItmList[idx].itmCd,
            }

            if(this.getValidate(obj)){                
                insPrfLosItm(obj)
                    .then((response) => ( this.onSaveRowAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/insPrfLosItm : ' + error))
            }

        },
        onSaveRowAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getPrfLosItmList()
                this.$emit('modalEvent', 99)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },        
        
        
        addRow:function(){
            this.prfLosItmList.push({
                prfLosItmPk:0,
                calcClcd    : this.calcClcd,
                calcClcdNm  : this.getClcdNm(this.calcClcd),
                itmNm       : '',
                ord         : '',
                dtlYn       : '1',
                useYn       : '1',
                itmCd       : null
            })
        },
        delRow:function(idx){
            if(this.prfLosItmList[idx].prfLosItmPk > 0){
                this.idx = idx
                this.delTitle = '항목 : '+this.prfLosItmList[idx].itmNm
                this.dialogDelete = true

                this.idx = idx
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'orange darken-3'
                obj.title = this.prfLosItmList[idx].itmNm
                obj.cntnt = ['항목을 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });
                this.rmMnthCnfm = true
            }else{
                this.prfLosItmList.splice(idx,1)
            }
        },
        onDelete:function(){      
            if(this.prfLosItmList[this.idx].prfLosItmPk > 0){
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    userNm      : this.$store.getters.getUserNm,
                    calcClcd    : this.calcClcd,
                    itmCd       : this.prfLosItmList[this.idx].itmCd,
                }

                delPrfLosItm(obj)
                    .then((response) => ( this.onDeleteAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/delPrfLosItm : ' + error))
            }else{
                this.snackControll([true, 3500, '삭제 할 대상을 선택해주세요.', 'warning'])
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){
            this.dialogDelete = false
            this.idx = 0
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getPrfLosItmList()
                this.$emit('modalEvent', 99)
            }else if (res.statusCode == '810') {
                let obj = {}
                obj.actv = true                             // 경고 노출여부
                obj.tmr = 5000                              // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
                obj.title = res.message       // 경고 타이틀
                obj.cntnt = ['손익 항목별 금액이 존재합니다.']                              // 경고 내용 (arr만 출력됨!)

                if(res.messagesList != null){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                this.$store.commit('setWrnnInf', obj)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        getClcdNm(val){
            let restn = ''
            switch (val) {
                case '1':
                    restn = '수익'
                    break;
                case '2':
                    restn = '비용'
                    break;
                default:
                    break;
            }

            return restn
        },
        getValidate:function(pram){
            let rbl = true
            let msg = ''

            if(pram.ord == '' || pram.ord == 0){
                rbl = false
                msg = '순서를 선택해주세요.'
            }

            if(pram.itmNm == ''){
                rbl = false
                msg = '항목명을 입력해주세요.'
            }

            if(!rbl) this.snackControll([true, 3500, msg, 'warning'])

            return rbl
        },
        checkBoxIndex: function () {
            let chk = []
            let pkList = []
            this.prfLosItmList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)

                if(items.isSelected == true) pkList.push(items)
            });
            this.selectedList = pkList
            let result = chk.every((item) => item==true)

            if(result) this.allChecker = true
            else this.allChecker = false
        },
        checkBoxMessage: function () {
            if(this.allChecker == true){
                this.prfLosItmList.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.prfLosItmList.forEach(item => {
                    item.isSelected = false
                })
            }
            let pkList = []
            this.prfLosItmList.forEach(items => {
                if(items.isSelected == true) pkList.push(items)
            });
            this.selectedList = pkList
        },

        onClose: function () {
            this.$emit('isClose')
        },

        onDtlModal:function(key, target){
            if(key == 0){
                this.itmCd = target.itmCd
                this.itmNm = target.itmNm

            }
            this.dialog.splice(key, 1, true)
        },

        isDtlModal:function(key){
            this.dialog.splice(key, 1, false)
        },

        modalDtlEvent:function(key){
            if(key == 99){
                this.onLoad()
            }
        },

        snackControll: function (options) {
            this.snackOptions = options
        },

        onCalculOrd:function(obj){
            var max = 0;
            this.prfLosItmList.forEach(el => {
                max = Math.max(max, el.ord);
            });
            obj.ord = max+1
        },

        chkUse:function(target){
            if(target.useYn == '2'){
                target.dtlYn = '2'
            }
        }
    },
    data: () => ({ 
        forceRender : 0,      
        allChecker  : false,
        idx         : 0,
        itmCd       : '',
        itmNm       : '',
        dialog      : [false,],
        selectedList: [],        
        prfLosItmHeaders:[
            { text: '항목', value: '', align: 'center', },
            { text: '사용여부', value: '', align: 'center', },
            { text: '계산구분', value: '', align: 'center', },
            { text: '상세코드', value: '', align: 'center', },
            { text: '순서', value: '', align: 'center', },
            { text: '처리', value: '', align: 'center', },
        ],
        prfLosItmList   : [],
        snackOptions    : [false, 3000, '', 'info'],
        filledBtn: [
            // { icon: 'mdi-check', class: 'ml-1 white--text', color:'blueBtnColor', text: '선택완료', type: 'action', width:'800px', disabled: false, },            
        ],
        delTitle    : '',
        rmMnthCnfm  : false
    }),
};
</script>