<template>
    <v-app class="minimumSize"
        :style="{ background: $vuetify.theme.themes[theme].bckgrColor }">
        <v-main class="d-flex align-center">
            <v-row class="ma-0 pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="" cols="6">
                    <v-card
                        class="py-8 px-10 d-flex justify-center align-center"
                        height="660"
                        elevation="9" rounded="xl">
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="12">
                                <v-icon color="red" size="220">mdi-close-octagon-outline</v-icon>
                            </v-col>
                            <v-col class="mt-16 mb-6 d-flex justify-center" cols="12">
                                <span class="red--text font-weight-bold" style="font-size: 2rem !important;">
                                    사용권한이 없는 페이지 입니다.
                                </span>
                            </v-col>
                            <v-col class="d-flex justify-center" cols="12">
                                <span class="font-weight-medium" style="font-size: 1.2rem !important;">
                                    시설 권한 관리자에게 문의해 주시기 바랍니다.
                                </span>
                            </v-col>
                            <v-col class="d-flex justify-center mt-10" cols="12">
                                <v-btn class="forbidden mr-5" color="" outlined @click="$router.go(-1)">이전 페이지로</v-btn>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'AccessDenied',

    components: {
       
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
        
        })
    },

    computed: {
        theme: function() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
    },

    watch:{
  
    },
        
    methods: {

    },

    data: () => ({
        
    }),
};
</script>

<style>
    .forbidden span { font-size: 1rem; }
</style>