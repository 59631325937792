<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <v-row class="mb-4 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">YYS&nbsp;공지</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col cols="12">
                <FcltyNtcBrdWdt v-if="sltNtcBbsPk == -1" ref="fcltyNtcBrdWdt"></FcltyNtcBrdWdt>

                <FcltyNtcBrdInWdt v-else ref="fcltyNtcBrdInWdt" :ntcBbsPk="sltNtcBbsPk"></FcltyNtcBrdInWdt>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import FcltyNtcBrdWdt from './FcltyNtcBrdWdt.vue';
import FcltyNtcBrdInWdt from './FcltyNtcBrdInWdt.vue';

export default {
    name: 'YysNtcMdl',

    props : {
   
    },
        
    components: {
        FcltyNtcBrdWdt,
        FcltyNtcBrdInWdt,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
 
        onClose: function () {
            this.$emit('modalClose', '1')
        },
    },

    data: () => ({
        sltNtcBbsPk: -1,
    }),
};
</script>