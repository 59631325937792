<template>
    <v-sheet class="ma-0 overflow-y-auto" rounded="md" style="height: 712px;">
        <v-form ref="nclform" lazy-validation>
            <v-row class="tableBT" no-gutters>
                <v-col class="greyE01" cols="2">
                    <v-row class="tableBR" style="height: 100%;" no-gutters>
                        <v-col class="tableBR" style="height: 100%;" cols="5">
                            <div class="d-flex justify-center align-center font-weight-medium" style="height: 100%;">구분</div>
                        </v-col>
                        <v-col class="" style="height: 100%;" cols="7">
                            <v-row class="" style="height: 100%;" no-gutters>
                                <v-col class="d-flex justify-center align-center tableBR" style="height: 100%;" cols="4">
                                    <div class="font-weight-medium">
                                        <div>점검</div>
                                        <div>항목</div>
                                    </div>
                                </v-col>
                                <v-col class="" style="height: 100%;" cols="8">
                                    <div class="d-flex align-center font-weight-medium tableBB" style="height: 37px;">
                                        <span class="d-inline-block" style="width: 100%; text-align: center;">점검시간</span>
                                    </div>
                                    <div class="d-flex justify-center align-center font-weight-medium tableBB" style="height:37px;">점검자</div>
                                    <div class="d-flex justify-center align-center font-weight-medium" style="height:25px;">결과</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-for="(rtl, i) in resultListOfTitle" :class="i == 5 ? '' : 'tableBR'" :key="i" cols="2">
                   <div class="d-flex tableBB" style="position:relative;">
                        <span class="d-inline-block px-1 mb-1 pt-1" style="width: 100px;">
                            <CmmTimeField
                                v-model="entTm[i]"
                                @change="timePicked(i)" :required="false">
                            </CmmTimeField>
                        </span>
                        <!-- <span class="d-inline-block px-1 mb-1 pt-1" style="width: 100px;">
                            <v-menu
                                ref="menu" v-model="menu[i]"
                                :close-on-content-click="false" :nudge-right="40" :return-value.sync="entTm[i]"
                                transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="entTm[i]" width="140"
                                        readonly hide-details outlined dense
                                        v-bind="attrs" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-time-picker :key="forceRender"
                                    v-model="entTm[i]" scrollable
                                    @click:minute="timePicked(i)">
                                </v-time-picker>
                            </v-menu>
                        </span> -->
                        <span class="d-inline-block cmmFontSizeOne pb-1 d-flex align-center" style="width: 100px; text-align: center;">
                            {{ Number(entTm[i]?.substr(0, 2)) >= 18 && Number(entTm[i]?.substr(0, 2)) <= 23 ? '( ' + now?.dateFormatDotMmDd() + ' )' : '( ' + tomRow?.dateFormatDotMmDd() + ' )'}}
                        </span>
                    </div>
                    <div class="d-flex align-center tableBB">
                        <span class="d-inline-block pa-1" style="width:81%;">
                            <v-text-field
                                v-model="wrtr[i]"
                                readonly hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <v-btn 
                            class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                            min-width="30" height="28" @click="dialog.splice(0, 1, true), sltItem=i"
                            dark small depressed>
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                    <div class="greyE01">
                        <span class="d-inline-block tableBR ngh" style="width: 33.3333%; text-align: center;">
                            <v-icon color="black" small>mdi-checkbox-blank-circle-outline</v-icon>
                        </span>
                        <span class="d-inline-block tableBR ngh" style="width: 33.3333%; text-align: center;">
                            <v-icon color="black" small>mdi-triangle-outline</v-icon>
                        </span>
                        <span class="d-inline-block ngh" style="width: 33.3333%; text-align: center;">
                            <v-icon color="black" small>mdi-close</v-icon>
                        </span>
                    </div>
                </v-col>
                <v-col v-for="n in (5-resultListOfTitle.length)" :key="(resultListOfTitle.length+n)" :class="(resultListOfTitle.length+n) == 5 ? '' : 'tableBR'" cols="2">
                    <div class="d-flex tableBB" style="position:relative;">
                        <span class="d-inline-block px-1 mb-1 pt-1" style="width: 100px;">
                            <CmmTimeField
                                v-model="entTm[resultListOfTitle.length + n-1]"
                                @change="timePicked(resultListOfTitle.length + n-1)" :required="false">
                            </CmmTimeField>
                        </span>
                        <!-- <span class="d-inline-block px-1 mb-1 pt-1" style="width: 100px;">
                            <v-menu
                                ref="menu" v-model="menu[(resultListOfTitle.length + n-1)]"
                                :close-on-content-click="false" :nudge-right="40" :return-value.sync="entTm[resultListOfTitle.length + n-1]"
                                transition="scale-transition" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="entTm[resultListOfTitle.length + n-1]" width="140"
                                        readonly hide-details outlined dense
                                        v-bind="attrs" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-time-picker :key="forceRender"
                                    v-model="entTm[resultListOfTitle.length + n-1]" scrollable
                                    @click:minute="timePicked(resultListOfTitle.length + n-1)">
                                </v-time-picker>
                            </v-menu>
                        </span> -->
                        <span
                            v-if="entTm[resultListOfTitle.length + n-1].length == 5"
                            class="d-inline-block cmmFontSizeOne pb-1 d-flex align-center" style="width: 100px;">
                            {{ Number(entTm[resultListOfTitle.length + n-1]?.substr(0, 2)) >= 18 && Number(entTm[resultListOfTitle.length + n-1]?.substr(0, 2)) <= 23 ? '( ' + now?.dateFormatDotMmDd() + ' )' : '( ' + tomRow?.dateFormatDotMmDd() + ' )'}}
                        </span>
                        <span
                            v-else
                            class="d-inline-block cmmFontSizeOne pb-1 d-flex align-center" style="width:100px; text-align: center;">
                            {{'( ' + now?.dateFormatDotMmDd() + ' )'}}
                        </span>
                    </div>
                    <div class="d-flex align-center tableBB">
                        <span class="d-inline-block pa-1" style="width:81%;">
                            <v-text-field
                                v-model="wrtr[resultListOfTitle.length + n-1]"
                                readonly hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <v-btn 
                            class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                            min-width="30" height="28" @click="dialog.splice(0, 1, true), sltItem=(resultListOfTitle.length + n-1)"
                            dark small>
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                    <div class="greyE01">
                        <span class="d-inline-block tableBR ngh" style="width: 33.3333%; text-align: center;">
                            <v-icon color="black" small>mdi-checkbox-blank-circle-outline</v-icon>
                        </span>
                        <span class="d-inline-block tableBR ngh" style="width: 33.3333%; text-align: center;">
                            <v-icon color="black" small>mdi-triangle-outline</v-icon>
                        </span>
                        <span class="d-inline-block ngh" style="width: 33.3333%; text-align: center;">
                            <v-icon color="black" small>mdi-close</v-icon>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-row class="tableBT" no-gutters>
                <v-col class="tableBR" cols="2">
                    <v-row v-for="(bCat, i) in title" :key="i" class="" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR tableBB font-weight-medium cmmFontSizeOne" cols="5">{{bCat}}</v-col>
                        <v-col class="" cols="7">
                            <div v-for="(mCat, j) in subTitle" :key="j">
                                <div v-for="(sCat, k) in mCat[bCat]" :key="k"
                                    class="d-flex justify-center align-center py-2 font-weight-medium cmmFontSizeOne tableBB">
                                    {{sCat.itmCdnm}}
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-for="(rtl, i) in resultList" :key="i"
                    :class="[i != 4 ? 'tableBR' : '']" cols="2">
                    <div v-for="(mCat, j) in rtl[Object.keys(rtl)]" :key="j"
                        class="d-flex justify-center align-center cmmFontSizeOne tableBB">
                        <v-radio-group v-model="mCat.itm1Val" :key="forceRender"
                            class="d-flex justify-center align-center d-inline-block" style="width:100%;" hide-details row>
                            <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:33.3333%; height: 37px;"
                                on-icon="mdi-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="1"></v-radio>
                            <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:33.3333%;"
                                on-icon="mdi-triangle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="2"></v-radio>
                            <v-radio class="d-inline-block ma-0 d-flex justify-center" style="width:33.3333%;"
                                on-icon="mdi-close" off-icon="mdi-minus-circle-outline" :ripple="false" value="3"></v-radio>
                        </v-radio-group>
                    </div>
                </v-col>
            </v-row>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-1" style="border-right: 1px solid #dbdbdb !important;" cols="2">
                    <span class="font-weight-medium">특이사항</span>
                </v-col>
                <v-col class="pa-1" cols="10">
                    <v-textarea
                        v-model="cntnt1"
                        class="rmrksArea" rows="1" no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider class="greyE00"></v-divider>
        </v-form>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector :prtWrtDt="$parent.$parent.$parent.$parent.$parent.$parent.sltDate" @isClose="isModal" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selInspRecDtllist, insInspRecDtl, delInspRecDtl } from '../../api/sfty.js';
import { getCmnItmCd } from '../../api/index.js';

import EmpSelector from '../commons/EmpSelector.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'NghChcLog',

    props : {
       
    },
        
    components: {
        EmpSelector,
        CmmTimeField,
    },

    created: function(){
        let obj = { code: this.$store.getters.getFcltyNum, clsfc1Cd: 501, clsfc2Cd: 1 }
        this.getCmnItmCd(obj)
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.clearForm()
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            this.now = timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp()
            this.tomRow = this.$parent.$parent.$parent.$parent.nextDate            
            if(this.now != this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate) this.now = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
            this.onLoad()
        })
    },

    computed: {
        getNowDate: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
        },
        getNextDate: function () {
            return this.$parent.$parent.$parent.$parent.nextDate
        },
        getSftyList: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList
        },
        getSltInspRecPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
        },
    },

    watch:{
        getNowDate: function (val) {
            this.clearForm()
            this.now = val
        },
        getNextDate: function (val) {
            this.tomRow = val
        },
        entTm: {
            async handler() {
                await this.$nextTick()

            }
        },
        getSltInspRecPk:function(){
            this.onLoad()
        }
    },
    
    methods: {
        onLoad:function(){
            if(this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk == 0) this.setInspRecDtllistDefault()
            else if(this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk != 0) {
                this.$parent.$parent.$parent.$parent.btnControll(1, false, 'fill')
                this.getInspRecDtllist()
            }
        },
        getCmnItmCd: function (obj){
            getCmnItmCd(obj)
                .then((response) => ( this.codePrcsn(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmCd : ' + error))
        },
        //야간점검일지상세 조회
        getInspRecDtllist: function (){
            //console.log('recPk : ' + this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk)
            selInspRecDtllist(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate, 501, 1)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtllist : ' + error))
        },
        //야간점검일지 저장
        setInspRecDtl: function (obj){
            this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
            this.$parent.$parent.$parent.$parent.btnControll(0, true, 'fill')
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //야간점검일지 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        codePrcsn: function (res){
            //console.log(res)
            res.forEach(items => {
                let tmp = this.title.find(v => v === items.clsfc3Cdnm)
                if(tmp == undefined) this.title.push(items.clsfc3Cdnm)
            });
            this.title.forEach(items => {          
                this.subTitle.push({[items]: []})
            });
            this.subTitle.forEach(element => {
                let list = res.filter(v => v.clsfc3Cdnm == Object.keys(element))
                element[Object.keys(element)] = list
            });

            this.setInspRecDtllistDefault()
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                // this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                // this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                
                if(this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk != 0) this.getInspRecDtllist()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])

            this.$parent.$parent.$parent.$parent.btnControll(1, false, 'fill')
            this.$parent.$parent.$parent.$parent.btnControll(0, false, 'fill')
        },
        getInspRecDtllistAfter: function (res){           
            //초기화
            this.resultList.splice(0)
            this.resultListOfTitle.splice(0)
            
            this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.forEach(items => {
                if(this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem == items.inspDt){                
                    this.cntnt1 = items.cntnt1
                    this.cntnt2 = items.cntnt2
                    this.cntnt3 = items.cntnt3
                    this.recWrtr = items.recWrtr
                }
            });

            let resGroup = []

            //비동기 통신으로 수신한 리스트에서 시간대 목록을 추출
            res.forEach(items => {
                let tmp = this.resultListOfTitle.find(v => v === (items.inspTmHh.toString()+items.inspTmMm.toString()))
                if(tmp == undefined) this.resultListOfTitle.push((items.inspTmHh.toString()+items.inspTmMm.toString()))
            });

            //시간대에 해당하는 오브젝트 배열을 생성
            this.resultListOfTitle.forEach(element => {
                resGroup.push({[element]: []})
            });
            if(resGroup.length < 5){
                for (let index = resGroup.length; index < 5; index++) {
                    resGroup.push({[(index).toString()]: []})
                }
            }

            //비동기 통신으로 추출한 시간대에 해당하는 리스트를 검색해서 분류
            resGroup.forEach(element => {
                let depth1Cd = ''
                let depth2Cd = ''
                let depth3Cd = ''
                let cdCnt = 0
                let dList = []

                let list = res.filter(v => (v.inspTmHh.toString()+v.inspTmMm.toString()) == Object.keys(element))

                this.subTitle.forEach(sub => {
                    cdCnt = sub[Object.keys(sub)].length

                    sub[Object.keys(sub)].forEach(el => {
                        depth1Cd = el.clsfc1Cd
                        depth2Cd = el.clsfc2Cd
                        depth3Cd = el.clsfc3Cd
                    });
                    //console.log('depth3Cd: ' + depth3Cd + ', cnt: ' + cdCnt)

                    for (let index = 1; index <= cdCnt; index++) {
                        let tmp = null

                        list.forEach(lll => {
                            if(Number(lll.clsfc3Cd) == depth3Cd && Number(lll.itm1Cd) == index)
                                tmp = lll
                        });

                        if(tmp == null){
                            tmp = {}
                            tmp.clsfc1Cd = depth1Cd
                            tmp.clsfc2Cd = depth2Cd
                            tmp.clsfc3Cd = depth3Cd
                            tmp.itm1Cd = index
                            tmp.itm1Val = 0
                        }
                        dList.push(tmp)
                    }
                });

                element[Object.keys(element)] = dList
            });

            //완성된 그룹 리스트를 컴포넌트 데이터에 반영
            this.resultList = resGroup

            //그룹 리스트에서 점검자를 찾아 컴포넌트 데이터에 반영
            this.resultListOfTitle.forEach((items, i) => {
                resGroup.forEach(element => {
                    if(Object.keys(element)[0] == items){
                        element[Object.keys(element)].forEach(els => {
                            if(typeof els.wrtr != 'undefined' && els.wrtr != '')
                                if(this.wrtr[i] == '')
                                    this.wrtr[i] = els.wrtr
                        });
                    }             
                });
            });

            //시간대 목록을 점검시간 컴포넌트 데이터에 반영
            this.resultListOfTitle.forEach((element, i) => {
                this.entTm.splice(i, 1, element.beforeTimeFormat())
            });
        },
        setInspRecDtllistDefault: function (){
            //console.log(res)
            //초기화
            this.resultList.splice(0)
            this.resultListOfTitle.splice(0)
     
            let resGroup = []
           
            for (let index = resGroup.length; index < 5; index++) {
                resGroup.push({[(index).toString()]: []})
            }

            //비동기 통신으로 추출한 시간대에 해당하는 리스트를 검색해서 분류
            resGroup.forEach(element => {
                let depth1Cd = ''
                let depth2Cd = ''
                let depth3Cd = ''
                let cdCnt = 0
                let dList = []

                this.subTitle.forEach(sub => {
                    cdCnt = sub[Object.keys(sub)].length

                    sub[Object.keys(sub)].forEach(el => {
                        depth1Cd = el.clsfc1Cd
                        depth2Cd = el.clsfc2Cd
                        depth3Cd = el.clsfc3Cd
                    });
                    //console.log('depth3Cd: ' + depth3Cd + ', cnt: ' + cdCnt)

                    for (let index = 1; index <= cdCnt; index++) {
                        let tmp = null

                        if(tmp == null){
                            tmp = {}
                            tmp.clsfc1Cd = depth1Cd
                            tmp.clsfc2Cd = depth2Cd
                            tmp.clsfc3Cd = depth3Cd
                            tmp.itm1Cd = index
                            tmp.itm1Val = 0
                        }
                        dList.push(tmp)
                    }
                });

                element[Object.keys(element)] = dList
            });

            //완성된 그룹 리스트를 컴포넌트 데이터에 반영
            this.resultList = resGroup

            //그룹 리스트에서 점검자를 찾아 컴포넌트 데이터에 반영
            this.resultListOfTitle.forEach((items, i) => {
                resGroup.forEach(element => {
                    if(Object.keys(element)[0] == items){
                        element[Object.keys(element)].forEach(els => {
                            if(typeof els.wrtr != 'undefined' && els.wrtr != '')
                                if(this.wrtr[i] == '')
                                    this.wrtr[i] = els.wrtr
                        });
                    }             
                });
            });

            //시간대 목록을 점검시간 컴포넌트 데이터에 반영
            this.resultListOfTitle.forEach((element, i) => {
                this.entTm.splice(i, 1, element.beforeTimeFormat())
            });
        },
        preInspRecDtllist: function (){
            this.inspRecDtllist.splice(0)

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 501
            obj.clsfc2Cd = 1
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
            obj.cntnt1 = this.cntnt1
            obj.cntnt2 = this.cntnt2
            obj.cntnt3 = this.cntnt3
            obj.wrtr = this.recWrtr
            obj.list = []

            // if(this.$refs.nclform.validate())
            //     console.log('체크 성공')
            // else
            //     console.log('체크 실패')
        
            this.resultList.forEach((elements, i) => {
                let list = Object.values(elements)
                this.inspRecDtllist.push({[this.entTm[i].replaceAll(':', '')]: list[0]})
            });

            this.inspRecDtllist.forEach((items, i) => {
                let hh = Object.keys(items)[0]?.substring(0, 2)
                let mm = Object.keys(items)[0]?.substring(2, 4)
                
                items[Object.keys(items)].forEach(list => {
                    if(hh != '' && mm != ''){
                        list.inspTmHh = hh
                        list.inspTmMm = mm
                    }

                    if(hh >= 16 && hh <= 23)
                        list.inspDtlDt = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
                    else
                        list.inspDtlDt = this.$parent.$parent.$parent.$parent.nextDate

                    list.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.sltDate
                    list.wrtr = this.wrtr[i]
                });
            });

            this.inspRecDtllist.forEach(items => {
                items[Object.keys(items)].forEach(list => {
                    obj.list.push(list)
                });
            });

            this.setInspRecDtl(obj)

        },
        timePicked: function (index){
            let time = this.entTm[index]

            this.$refs.menu.forEach(element => {
                element.save(time)
            });

            this.menu.splice(index, 1, false)
            this.entTm.splice(index, 1, time)
            this.forceRender += 1
        },
        clearForm: function (){
            //console.log('clearForm')
            this.$refs.nclform.reset()
            //this.resultList.splice(0)
            this.resultListOfTitle.splice(0)
            this.inspRecDtllist.splice(0)

            for (let index = 0; index < 5; index++) {
                this.entTm.splice(index, 1, '')
                this.wrtr.splice(index, 1, '')
            }

            this.cntnt1 = ''
            this.cntnt2 = ''
            this.cntnt3 = ''
            this.recWrtr = ''
        },
        isModal: function () {
            this.dialog.splice(0, 1, false)
        },
        setDftVal: function () {
            this.resultList[this.sltItem][Object.keys(this.resultList[this.sltItem])].forEach(rtl => {
                if(rtl.itm1Val == 0 || rtl.itm1Val == null) rtl.itm1Val = '1'
            });
        },
        getEmpInfo: function (obj) {
            this.wrtr[this.sltItem] = obj[0].name
            this.setDftVal()
            this.sltItem = -1
        },
    },

    data: () => ({
        sltItem: -1,
        forceRender: 0,
        now: '',
        tomRow: '',
        menu: [false, false, false, false, false,],
        dialog: [false, false, false, false, false,],
        title: [],
        subTitle: [],
        cntnt1: '',
        cntnt2: '',
        cntnt3: '',
        recWrtr : '',
        inspRecDtllist: [],
        resultListOfTitle: [],
        resultList: [],
        dummyInspRecDtl: {
            inspRecDtlPk: 0, inspRecPk: 0, inspDt: '', inspTmHh: '', inspTmMm: '', itm1Val: '',  itm1Cd: '',
            clsfc3Cd: '', clsfc2Cd: '', clsfc1Cd: '', wrtr: '',
        },
        entTm: ['', '', '', '', '',],
        wrtr: ['', '', '', '', '',],
    }),
};
</script>

<style>
    .ngh i { line-height: 1.4 !important; }
</style>