import { render, staticRenderFns } from "./WeklyMenu.vue?vue&type=template&id=025da3ad&"
import script from "./WeklyMenu.vue?vue&type=script&lang=js&"
export * from "./WeklyMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports