<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1560" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">8-3. 직원 임금정보 관리</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width" content-class="round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <div class="mt-3 px-2">
            <v-tabs hide-slider v-model="tab"  color="white" class="black--text"
                active-class="bodyColor" center-active>
                <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">            
                <v-tab-item eager transition="fade-transition">
                    <empWageInfTab1                         
                        ref="empWageInfTab1"
                        @trigger="empWageInfTrigger">
                    </empWageInfTab1>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <empWageInfTab2 
                        ref="empWageInfTab2"
                        @trigger="empWageInfTrigger">
                    </empWageInfTab2>
                </v-tab-item>   
                <v-tab-item eager transition="fade-transition">
                    <empWageInfTab3 
                        ref="empWageInfTab3"
                        @trigger="empWageInfTrigger">
                    </empWageInfTab3>
                </v-tab-item>          
            </v-tabs-items>
        </div>
        
    </v-sheet>
</template>
<script>
import empWageInfTab1 from '../../components/impExp/EmpWageInfTab1.vue';
import empWageInfTab2 from '../../components/impExp/EmpWageInfTab2.vue';
import empWageInfTab3 from '../../components/impExp/EmpWageInfTab3.vue';

export default {
    name: 'EmpWageInfMgmt',
        
    components: {
        empWageInfTab1,
        empWageInfTab2,
        empWageInfTab3,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
   
        })
    },

    watch:{
        'tab':function(){
            switch (this.tab) {
                case 0:
                    this.$refs.empWageInfTab1.onLoad()
                    this.filledBtn[0].visible = true
                    this.filledBtn[0].disabled = true
                    this.filledBtn[1].visible = false
                    break;
                case 1:
                    this.$refs.empWageInfTab2.onLoad()
                    this.filledBtn[0].visible = false
                    this.filledBtn[1].visible = true
                    break;
                case 2:
                    this.$refs.empWageInfTab3.onLoad()
                    this.filledBtn[0].visible = true
                    this.filledBtn[0].disabled = false
                    this.filledBtn[1].visible = false
                    break;
                default:
                    break;
            }
        }
    },

    methods: {        
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onSave()               
                    break;
                case 1:
                    this.$refs.empWageInfTab2.onTotSave()             
                    break;
                default:
                    break;
            }
        },
        onSave:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.empWageInfTab1.onSave()
                    break;
                case 1:
                    this.$refs.empWageInfTab2.onSave()
                    break;
                case 2:
                    this.$refs.empWageInfTab3.onSave()
                    break;
                default:
                    break;
            }
        },             
        snackControll: function (options) {
            this.snackOptions = options
        },
        empWageInfTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
    },
    data: () => ({   
        tab: 0,  
        dialog:[],  
        modal:[false,],
        tabItems: [
            { index: 0, tab: '직원임금정보', style: 'font-size: 1.2rem !important;', enter:false},
            { index: 1, tab: '임금기본정보', style: 'font-size: 1.2rem !important;', enter:false},
            { index: 2, tab: '기관임금정보', style: 'font-size: 1.2rem !important;', enter:false},
        ],         
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: true, visible: true,},
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '일괄저장', type: 'action', width:'800px', disabled: false, visible: false,},
        ],
        outlinedBtn: [
            
        ],
        snackOptions: [false, 3000, '', 'info'],
    })
}
</script>