<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-row class="mt-2 align-center" no-gutters>
            <v-col class="d-flex align-center" cols='4'>
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />

                <span class="d-inline-block mr-1" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white"
                        append-icon="mdi-magnify" single-line rounded outlined dense filled hide-details label="이름조회"
                        @input="setSearchText">
                    </v-text-field>
                </span>
            </v-col>
            <v-col cols='4'>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <span class="d-inline-block mt-1" style="width: 97px; height: 32px;">                                            
                        <v-select
                            v-model="srchCd"                                            
                            :items="selectItems"
                            item-text="valcdnm"
                            item-value="valcd"
                            @change="selectedDate()"
                            dense hide-details outlined
                        />
                    </span>
                    <div class="d-flex mx-3 align-right">
                        <span >
                            <expandNowDatePicker @nowDate="getDate" v-if="srchCd==0"></expandNowDatePicker>
                            <expandMonthPicker @nowYearWithMonth="getPickerDate" v-if="srchCd==1"></expandMonthPicker>
                            <expandYearOnlyPicker @nowYear = "getYear" v-if="srchCd==2"></expandYearOnlyPicker>
                        </span>
                    </div>
                </div>
                <v-spacer></v-spacer>
            </v-col>
            <v-col cols='4'>

            </v-col>
        </v-row>
        <div class="mt-2 rounded-lg">
            <v-data-table
                class="msclnTable" height="622" locale="ko"
                :headers="headers" 
                :items="empList" 
                :search="schDummy" 
                :custom-filter="customFilter"
                fixed-header disable-pagination hide-default-footer hide-default-header dense>
                <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:70px !important">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT">{{props.headers[5].text}}</th>
                            </tr>
                        </thead>
                    </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="tableContentCommon tableBR">{{item.rn}}</td>
                        <td class="tableContentCommon tableBR">{{item.wrkDt}}</td>
                        <td class="tableContentCommon tableBR">{{item.vctnTypNm}}</td>
                        <td class="tableContentCommon tableBR">{{item.name}}</td>
                        <td class="tableContentCommon tableBR">{{item.jobCd}}</td>
                        <td class="tableContentCommon tableBR">{{item.rmrks}}</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </v-sheet>
</template>
<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import { getMultiStcd } from '../../api/index.js';
import { selBtcInqVctnUseList } from '../../api/bnfcrStts';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';
export default {
    name: 'EmpInfSttsTab3',

    props: {
        tab: Number,
    },
    components: {
        exBtn,
        expandNowDatePicker,
        expandYearOnlyPicker,
        expandMonthPicker,
    },

    computed: {
    },

    watch: {
        'date': function(value){
            let obj = {
                inqYMD: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqVctnUseList(obj)
        },
        'mDate': function(value){
            let obj = {
                inqYYMM: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqVctnUseList(obj)
        },
        'yDate': function(value){
            let obj = {
                inqYYYY: value,
                fcltyNum: this.$store.getters.getFcltyNum
            }
            this.selBtcInqVctnUseList(obj)
        },
    },

    created: function () {
        this.getCode(['42', '70', '107'])
    },

    mounted: function () {
        this.$nextTick(function () {
        })
    },

    methods: {
        xlsxProd: function (){
            let shetNm = '휴가사용내역'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.empList, shetNm, title)
            this.exlBtn = true
        },
        selBtcInqVctnUseList: function (obj) {
            selBtcInqVctnUseList(obj)
                .then((response) => ( this.selBtcInqVctnUseListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkSchdHrlList : ' + error))
        },
        selBtcInqVctnUseListAfter: function (res) {
            res.forEach(items => {
                this.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });
                this.vCd70.forEach(item => {
                    if(items.wrkStcd == item.valcd)
                        items.wrkStcd = item.valcdnm
                });
                this.vCd107.forEach(item => {
                    if(items.vctnTypcd == item.valcd)
                        items.vctnTypNm = item.valcdnm
                });
                items.isSelected = false
            });

            this.empList = res
            this.empList.forEach(element => {
                element.wrkDt = element.wrkDt?.beforeDateFormatDot()
            });

        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => (this.stCdMapping(response.data)))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
            this.vCd70 = res.vCd70
            this.vCd107 = res.vCd107

            this.vCd107.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })
            this.vCd42.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if (this.schChkField.jobCd.length != 0) {
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if (i == 0) col1 = filter.jobCd.includes(stcd)
                    else col1 = col1 || filter.jobCd.includes(stcd)
                });
            }
            let col2 = true
            if (this.schChkField.vctnTypNm.length != 0) {
                this.schChkField.vctnTypNm.forEach((stcd, i) => {
                    if (i == 0) col2 = filter.vctnTypNm.includes(stcd)
                    else col2 = col2 || filter.vctnTypNm.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        filterSearch: function (sch) {
            switch (this.schChkField.target) {
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
                case 'vctnTypNm': this.schChkField.vctnTypNm.splice(0)
                    sch.forEach((item) => { this.schChkField.vctnTypNm.push(item) });
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },
        expandClose: function (index) {
            switch (index) {
                case 0: this.schChkField.target = 'jobCd'
                    break;
                case 1: this.schChkField.target = 'vctnTypNm'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        getDate: function (obj) {
            this.date = obj.date
            this.inqYmd = obj.date
        },
        getPickerDate: function (obj) {
            this.mDate = obj
            this.inqYmd = obj
        },
        getYear: function (obj) {
            this.yDate = obj
        },
        selectedDate: function() {
            if(this.srchCd==0){
                let obj = {
                    inqYMD: this.date,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqVctnUseList(obj)
            } else if (this.srchCd==1){
                let obj = {
                    inqYYMM: this.mDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqVctnUseList(obj)
            } else {
                let obj = {
                    inqYYYY: this.yDate,
                    fcltyNum: this.$store.getters.getFcltyNum
                }
                this.selBtcInqVctnUseList(obj)
            }
        },  
    },
    data: () => ({
        
        exBtn: [
            { index: 0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
            { index: 1, btnTitle: '휴가종류', btnIcon: 'airplane', exWidth: 200, exHeigth: 220, expandList: [], },
        ],
        headers: [
            { text: '연번', value: 'rn', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '휴가사용일', value: 'wrkDt', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '구분', value: 'vctnTypNm', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '직원명', value: 'name', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '담당직종', value: 'jobCd', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '비고', value: 'rmrks', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBT' },
        ],
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, },
            { header: '휴가사용일', key: 'wrkDt', width: 12, },
            { header: '구분', key: 'vctnTypNm', width: 12, },
            { header: '직원명', key: 'name', width: 12, },
            { header: '담당직종', key: 'jobCd', width: 16, },
            { header: '비고', key: 'rmrks', width: 16, },
        ],
        empList: [],
        vCd42: [],  //직원 직종 코드
        vCd70: [],  //직원 상태 코드
        vCd107: [],  //직원 휴가 코드
        snackOptions: [false, 3000, '', 'info'],
        schDummy: '',
        srchCd:0,
        selectItems: [{valcdnm: '일별',valcd: 0}, {valcdnm: '월별',valcd: 1},{valcdnm: '연별',valcd: 2}, ],
        date: 19900101,
        mDate: 199001,
        yDate: 1990,
        schField: '',
        schChkField: { jobCd: [], vctnTypNm:[], target: '' },
        inqYmd: 199001,
    }),
};
</script>