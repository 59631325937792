<template>
    <v-sheet class="ma-0 pa-0">
        <div class="elevation-3 rounded tiptap">
            <div class="blue600 rounded rounded-b-0 px-4 mt-3 tiptapMenu d-flex align-center">

                <v-btn class="" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().undo().run()" :disabled="!editor?.can().chain().focus().undo().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="white--text mt-1" v-bind="attrs" v-on="on">mdi-undo</v-icon>
                        </template>
                        <span>이전 단계</span>
                    </v-tooltip>
                </v-btn>

                <v-btn class="" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().redo().run()" :disabled="!editor?.can().chain().focus().redo().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="white--text mt-1" v-bind="attrs" v-on="on">mdi-redo</v-icon>
                        </template>
                        <span>다음 단계</span>
                    </v-tooltip>
                </v-btn>

                <v-btn class="blue600" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().setHorizontalRule().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="white--text mt-1" v-bind="attrs" v-on="on">mdi-minus</v-icon>
                        </template>
                        <span>밑 줄</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('blockquote') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().toggleBlockquote().run()" :disabled="!editor?.can().chain().focus().toggleBlockquote().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :class="['mt-1', editor?.isActive('blockquote') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-quote-close</v-icon>
                        </template>
                        <span>인용구</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('bulletList') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().toggleBulletList().run()" :disabled="!editor?.can().chain().focus().toggleBulletList().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :class="['mt-1', editor?.isActive('bulletList') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-list-bulleted</v-icon>
                        </template>
                        <span>글머리 목록</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('orderedList') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().toggleOrderedList().run()" :disabled="!editor?.can().chain().focus().toggleOrderedList().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :class="['mt-1', editor?.isActive('orderedList') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-list-numbered</v-icon>
                        </template>
                        <span>순서 있는 목록</span>
                    </v-tooltip>
                </v-btn>

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="blue600" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                            v-bind="attrs" v-on="on">
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="white--text mt-1" v-bind="attrs" v-on="on">mdi-format-color-text</v-icon>
                                </template>
                                <span>글자 색상</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-row class="pa-0" no-gutters>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().unsetColor().run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="" small>mdi-square-off-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>black</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#F44336').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="red--text" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>red</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#4CAF50').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="green--text" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>green</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#2196F3').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="blue--text" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>blue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#FFEB3B').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="yellow--text" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>yellow</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#9E9E9E').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="grey--text" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>grey</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#3E2723').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="brown--text darken-4" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>brown</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#E65100').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="orange--text darken-4" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>orange</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#006064').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="cyan--text darken-4" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>cyan</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="2">
                                <v-tooltip bottom >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="" @click="editor?.chain().focus().setColor('#311B92').run()" v-bind="attrs" v-on="on" icon>
                                            <v-icon class="deep-purple--text darken-4" small>mdi-square</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>purple</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-menu>

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="blue600" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                            v-bind="attrs" v-on="on">
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="white--text mt-1" v-bind="attrs" v-on="on">mdi-image-plus-outline</v-icon>
                                </template>
                                <span>이미지 첨부</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <v-card class="pa-2" min-width="200">
                        <v-file-input class="tiptapImage" v-model="file"
                            outlined multiple full-width dense hide-details prepend-icon="mdi-image-plus-outline"
                            truncate-length="14" label="add Image">
                        </v-file-input>
                    </v-card>
                </v-menu>

                <v-btn
                    :class="{ 'is-active': editor?.isActive('link') }"
                    style="min-width: 24px; padding: 0px 0px; margin: 2px 0;"
                    color="white" @click="editor?.isActive('link') ? editor?.chain().focus().unsetLink().run() : setLink()"
                    icon>
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="editor?.isActive('link')" class="white--text mt-1" v-bind="attrs" v-on="on">mdi-link-variant-off</v-icon>
                            <v-icon v-else class="white--text mt-1" v-bind="attrs" v-on="on">mdi-link-variant</v-icon>
                        </template>
                        <span v-if="editor?.isActive('link')">끊기</span>
                        <span v-else>연결</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('paragraph') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().setParagraph().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :class="['mt-1', editor?.isActive('paragraph') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-paragraph</v-icon>
                        </template>
                        <span>단락</span>
                    </v-tooltip>
                </v-btn>

                <span class="d-inline-block ml-1" style="width: 66px;">
                    <v-select class="tiptapSelect" @change="fontSizeChanger"
                        v-model="font" height="28" color="white"
                        item-text="valcdnm" item-value="valcd" :items="ftItems"
                        dense hide-details outlined>
                    </v-select>
                </span>

                <span class="d-inline-block ml-1" style="width: 102px;">
                    <v-select class="tiptapSelect" @change="fontFamilyChanger"
                        v-model="fontFamily" height="28" color="white"
                        item-text="valcdnm" item-value="valcd" :items="ftFmlItems"
                        dense hide-details outlined>
                    </v-select>
                </span>
                
            </div>

            <bubble-menu
                class="blue600 rounded elevation-5 px-2"
                :editor="editor"
                :tippy-options="{ duration: 100 }"
                v-if="editor">

                <v-btn :class="[editor?.isActive( { textAlign: 'left' }) ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().setTextAlign('left').run()" :disabled="!editor?.can().chain().focus().setTextAlign('left').run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mt-1" :class="['mt-1', editor?.isActive( { textAlign: 'left' }) ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-align-left</v-icon>
                        </template>
                        <span class="font-weight-medium">Left</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive( { textAlign: 'center' }) ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().setTextAlign('center').run()" :disabled="!editor?.can().chain().focus().setTextAlign('center').run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mt-1" :class="['mt-1', editor?.isActive( { textAlign: 'center' }) ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-align-center</v-icon>
                        </template>
                        <span class="font-weight-medium">Center</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive( { textAlign: 'right' }) ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().setTextAlign('right').run()" :disabled="!editor?.can().chain().focus().setTextAlign('right').run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mt-1" :class="['mt-1', editor?.isActive( { textAlign: 'right' }) ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-align-right</v-icon>
                        </template>
                        <span class="font-weight-medium">Right</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('bold') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().toggleBold().run()" :disabled="!editor?.can().chain().focus().toggleBold().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mt-1" :class="['mt-1', editor?.isActive('bold') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-bold</v-icon>
                        </template>
                        <span class="font-weight-medium">Bold</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('italic') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().toggleItalic().run()" :disabled="!editor?.can().chain().focus().toggleItalic().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :class="['mt-1', editor?.isActive('italic') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-italic</v-icon>
                        </template>
                        <span>Italic</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('strike') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().toggleStrike().run()" :disabled="!editor?.can().chain().focus().toggleStrike().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :class="['mt-1', editor?.isActive('strike') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-strikethrough</v-icon>
                        </template>
                        <span>Strike</span>
                    </v-tooltip>
                </v-btn>

                <v-btn :class="[editor?.isActive('underline') ? 'white' : 'blue600']" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" icon color="white"
                    @click="editor?.chain().focus().toggleUnderline().run()" :disabled="!editor?.can().chain().focus().toggleUnderline().run()">
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon :class="['mt-1', editor?.isActive('underline') ? 'blue600--text' : 'white--text']" v-bind="attrs" v-on="on">mdi-format-underline</v-icon>
                        </template>
                        <span>Underline</span>
                    </v-tooltip>
                </v-btn>

            </bubble-menu>
            
            <editor-content class="rounded rounded-t-0 tiptapEditor" :editor="editor" />
            
        </div>
        
    </v-sheet>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import { Underline } from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import { TextStyle } from '@tiptap/extension-text-style'
import { FontFamily } from '@tiptap/extension-font-family'
import { Image  } from '@tiptap/extension-image'
import { FocusClasses } from '@tiptap/extension-focus'
import { TextAlign } from '@tiptap/extension-text-align'
import { Link } from '@tiptap/extension-link'
import { FontSize } from '../../api/global/tiptapExtensionFontSize.js'
import StarterKit from '@tiptap/starter-kit'

import { editorFileUploads } from '../../api/index.js'
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'TipTapEditor',

    props : {
        editorCnt: { type: String, default: '' }
    },
            
    components: {
        EditorContent,
        BubbleMenu,
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.editorInisializing()
        })
    },

    computed: {

    },

    watch: {
        'editorCnt': function(value){
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
            return
            }

            this.editor.commands.setContent(value, false)
        },
        'file': function () {
            this.upFile()
            //this.editor.chain().setParagraph().focus().setImage('http://192.168.0.4:4000/opr/imgPrview?fileKndCd=2&filePth=editor/16681510&fileNm=46c910bf-4eb3-42da-8ca6-9c84eac5fc17.jpg').blur().run()
            //this.editor.chain().setParagraph().focus().run()
            //this.editor.chain().setContent('<img src="111111"></img>').blur().run()
            //this.editor.chain().setContent('<img src="111111"></img>').blur().run()
        },
    },

    beforeDestroy() {
        this.editor.destroy()
    },
        
    methods: {
        editorInisializing: function () {
            this.editor = new Editor({
                content: this.tiptapValue,
                autofocus: false,
                editorProps: {
                attributes: {
                    class: '',
                    },
                },
                onUpdate: () => {
                    // HTML
                    this.$emit('editorToHtml', this.editor.getHTML())
                    this.$emit('editorToJson', this.editor.getJSON())
                    // JSON
                    // this.$emit('input', this.editor.getJSON())
                    //console.log(this.editor.getJSON())
                },
                extensions: [
                    StarterKit.configure({
                        history: true,
                        // Configure an included extension
                        heading: {
                            levels: [1, 2, 3],
                        },
                    }),
                    Underline,
                    TextStyle,
                    Color,
                    FontFamily,
                    FontSize,
                    Image,
                    Link.configure({
                        openOnClick: false,
                    }),
                    FocusClasses.configure({
                        className: 'has-focus',
                        mode: 'all',
                    }),
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                        alignments: ['left', 'center', 'right'],
                        defaultAlignment: 'left',
                        }),
                ],//close extensions
            })//close editor
        },//close fuction

        upFile: function () {

            let formData = new FormData()
            this.file.forEach(element => {
                formData.append("files", element)
            })
            
            if(this.file.length > 0){
                editorFileUploads(formData)
                    .then((response) => ( this.upFileAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/editorFileUploads : ' + error))
            }

        },
        
        upFileAfter: function (res) {
            this.backFile = res

            let total = this.editor.getHTML()
            this.backFile.files.forEach(element => {
                let path = remoteURL + 'opr/imgPrview?'
                let fileName = 'fileNm=' + element.fileNm
                let filePath = 'filePth=' + element.filePth
                let fileCode = 'fileKndCd=2'

                let fileSrc = '<img src="' + path+fileCode+'&'+filePath+'&'+fileName + '" crossorigin="anonymous"'
                fileSrc += ' />'
                fileSrc += '<p></p>'
                total += fileSrc

            })
      
            this.editor.chain().setContent(total).blur().run()
            this.editor.chain().setParagraph().blur().run()
            
        },

        fontSizeChanger: function () {
            this.editor?.chain().focus().setFontSize(this.font).run()
        },

        fontFamilyChanger: function () {
            this.editor?.chain().focus().setFontFamily(this.fontFamily).run()
        },

        sleep: function(sec) {
          return new Promise(resolve => setTimeout(resolve, sec * 100));
        },

        setLink: function () {
      
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
     
    },
    
    data: () => ({
        file: null,
        backFile: null,
        editor: null,
        font: 16,
        fontFamily: 'Noto Sans KR',
        ftItems: [
            { valcdnm: '10pt', valcd: 10 },
            { valcdnm: '12pt', valcd: 12 },
            { valcdnm: '14pt', valcd: 14 },
            { valcdnm: '16pt', valcd: 16 },
            { valcdnm: '20pt', valcd: 20 },
            { valcdnm: '24pt', valcd: 24 },
            { valcdnm: '32pt', valcd: 32 },
            { valcdnm: '64pt', valcd: 64 },
        ],
        ftFmlItems: [
            { valcdnm: 'Noto Sans', valcd: 'Noto Sans KR' },
            { valcdnm: 'Nanum Gothic', valcd: 'Nanum Gothic' },
            { valcdnm: 'Black Han Sans', valcd: 'Black Han Sans' },
            { valcdnm: 'Nanum Pen', valcd: 'Nanum Pen Script' },
        ],
    }),
};
</script>

<style>
    .has-focus {
        border-radius: 3px;
        box-shadow: none;
    }
    .ProseMirror p { margin-bottom: 0px !important; }
    .ProseMirror {
        border-radius: 6px !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        width: 100%; height: 522px;
        overflow-y: auto;
        padding: 12px;
    }
    .ProseMirror:focus { outline: none; }
    .ProseMirrorMenu { box-shadow: 0px 0px 1px -2px rgb(0 0 0 / 20%), 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 0px 5px 0px rgb(0 0 0 / 12%) !important; }
    .tiptapEditor blockquote { border-left: 0.25em solid #dfe2e5; padding-left: 1em; margin: 4px 0!important; }
    .tiptapEditor img { width: 100%; }
    .tiptapEditor hr { margin: 12px 0 9px 0 !important; }
</style>

<style>
    a { color: #68CEF8; }
    p { min-height: 24px; }
    .tiptapImage .v-input__prepend-outer { margin: 1px 10px 0 0px !important; }

    .tiptapSelect .v-input__control .v-input__slot { padding: 0px 8px !important; }
    .tiptapSelect .v-input__control .v-input__slot .v-select__selections div { font-size: 0.875rem; }
    .tiptapSelect div div div div div { color: white; }
    .tiptapSelect div div div div div i { color: white !important; }
    .tiptapSelect div div div div .v-input__icon { min-width: 16px; width: 16px; height: 23px !important; }
</style>