<template>
    <v-sheet class="ma-0 pa-0">
        <div class="d-inline-block" style="width: 100%; height: 471.38px;">
            <v-divider class="mt-3"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">제목</span>
                </v-col>
                <v-col class="py-1 px-4" cols="10">
                    <span>{{ items.title }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">첨부파일</span>
                </v-col>
                <v-col :class="[fileRes.length == 0 ? 'px-4' : 'px-2']" cols="10">
                    <span 
                        v-if="fileRes.length == 0"
                        class="d-inline-block d-flex align-center"
                        style="width: 100%; height: 34px;">
                        첨부파일이 없습니다.
                    </span>
                    <span v-else>
                        <v-chip v-for="(item, j) in fileRes" :key="j"                        
                            class="ma-1"
                            @click="fileDownload(j)" @click:close="delfiles(j)"
                            outlined close label>
                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            <span>{{ item.orgFileNm }}</span>
                        </v-chip> 
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <div style="text-align: center;">
                        <span class="black--text font-weight-medium">내용</span>
                    </div>
                </v-col>
                <v-col class="px-4 py-1" cols="10">
                    <span
                        class="d-inline-block" style="width: 100%; height: 376px;"
                        v-html="items.cntnt?.replaceAll('\n', '<br>')">
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn v-if="items.nxtNtcBbsPk != 0"
                    class="text-caption font-weight-medium px-0" style="font-size: 1.1rem !important;" color="#666"
                    min-width="40" width="40"
                    @click="$parent.$parent.sltNtcBbsPk = items.nxtNtcBbsPk" outlined>
                    <v-icon style="padding-top: 1px; padding-right: 2px;">mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="text-caption font-weight-medium mx-2 pl-3" style="font-size: 1.1rem !important;" color="#666"
                    @click="$parent.$parent.sltNtcBbsPk = -1" outlined>
                    <v-icon style="padding-top: 1px;">mdi-menu</v-icon>
                    <span class="ml-2">목록</span>
                </v-btn>
                <v-btn v-if="items.preNtcBbsPk != 0"
                    class="text-caption font-weight-medium px-0" style="font-size: 1.1rem !important;" color="#666"
                    min-width="40" width="40"
                    @click="$parent.$parent.sltNtcBbsPk = items.preNtcBbsPk" outlined>
                    <v-icon style="padding-top: 1px; padding-left: 1px;">mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selNtsBbsInfo, selNtsBbsPreNxtInfo, } from '../../api/othr.js';
import { selAtchdFileList, fileDownload } from '../../api/index.js';

export default {
    name: 'FcltyNtcBrdInWdt',

    props : {
        ntcBbsPk: { type: Number, default: 0 },
        prtFcltyNum: { type: String, default: '10000000000' },
    },
        
    components: {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getNtsBbsInfo(this.prtFcltyNum, this.ntcBbsPk)
            this.getNtsBbsPreNxtInfo(this.prtFcltyNum, this.ntcBbsPk)
        })
    },

    computed: {

    },

    watch:{
        'ntcBbsPk': function () {
            this.getNtsBbsInfo(this.prtFcltyNum, this.ntcBbsPk)
            this.getNtsBbsPreNxtInfo(this.prtFcltyNum, this.ntcBbsPk)
        },
    },
    
    methods: {
        getNtsBbsInfo: function (code, pk) {
            selNtsBbsInfo(code, pk)
                .then((response) => (this.getNtsBbsInfoAfter(response.data)))
                .catch((error) => console.log('connect error ntsBbs/selNtsBbsInfo : ' + error))
        },
        getNtsBbsPreNxtInfo: function (code, pk) {
            selNtsBbsPreNxtInfo(code, pk)
                .then((response) => ( this.getNtsBbsPreNxtInfoAfter(response.data) ))
                .catch((error) => console.log('connect error ntsBbs/selNtsBbsPreNxtInfo : ' + error))
        },
        getAtchdFileList: function (pk){
            selAtchdFileList(this.$store.getters.getFcltyNum, '24', pk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
        },
        fileDownload: function (idx){
            if(this.fileRes[idx] != undefined){
                fileDownload(this.fileRes[idx])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
        },
        getNtsBbsInfoAfter: function (res) {
            if(typeof res == 'object'){
                if(res.almDtStrt == null || res.almDtStrt == '')
                    res.almDtStrt = ''
                else
                    res.almDtStrt = res.almDtStrt?.beforeDateFormatHyp()

                if(res.almDtEnd == null || res.almDtEnd == '')
                    res.almDtEnd = ''
                else
                    res.almDtEnd = res.almDtEnd?.beforeDateFormatHyp()

                this.items = res
                this.getAtchdFileList(res.ntcBbsPk)
            }
        },
        getNtsBbsPreNxtInfoAfter: function (res) {
            if(res.nxt != null)
                this.items.nxtNtcBbsPk = res.nxt.ntcBbsPk
            else
                this.items.nxtNtcBbsPk = 0

            if(res.pre != null)
                this.items.preNtcBbsPk = res.pre.ntcBbsPk
            else
                this.items.preNtcBbsPk = 0
        },
        getAtchdFileListAfter:function(res){

            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            res.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]  
                this.fileRes.push(x)
            })
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
    },

    data: () => ({
        key: 0,
        idx: 0,
        items: { title: '', cntnt: '', ntcBbsPk: 0, ntcYn: '', almYn: '2', almDtStrt: '', almDtEnd: '', nxtNtcBbsPk: 0, preNtcBbsPk: 0,},
        files: null,
        fileRes: [],
        images: null, 
        imageRes: [],  
        imgAccept: 'image/*',
    }),
};
</script>