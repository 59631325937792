<template>
    <v-sheet class="pt-2" rounded="md">
        <v-row class="pa-0 mb-1" no-gutters>
            <v-col class="d-flex" style="padding-top: 1px;" cols="12">
                <v-spacer></v-spacer>
                <v-btn
                    class="font-weight-bold mr-2" height="28" color="grey006"
                    @click="rprtActCall()"
                    rounded outlined samll>
                    <div class="d-flex justify-left align-center">
                        <v-icon style="margin-bottom: 1px;" size="18">mdi-microsoft-excel</v-icon>
                        <span class="ml-2" style="font-size: 1rem;">월간&nbsp;근무일정표</span>
                    </div>
                </v-btn>
                <v-btn
                    class="font-weight-bold mr-2" height="28" color="grey006"
                    @click="getExcelCrtDwnl()"
                    rounded outlined samll>
                    <div class="d-flex justify-left align-center">
                        <v-icon style="margin-bottom: 1px;" size="18">mdi-microsoft-excel</v-icon>
                        <span class="ml-2" style="font-size: 1rem;">근무표&nbsp;엑셀&nbsp;다운로드</span>
                    </div>
                </v-btn>
                <v-btn
                    v-if="exlBtn"
                    class="font-weight-bold" height="28" color="grey006"
                    @click="getExlEmpAtnd()"
                    :disabled="events.length == 0"
                    rounded outlined samll>
                    <div class="d-flex justify-left align-center">
                        <v-icon style="margin-bottom: 1px;" size="18">mdi-microsoft-excel</v-icon>
                        <span class="ml-2 fontOneRem">근무일정&#40;공단&#41;&nbsp;엑셀&nbsp;다운로드</span>
                    </div>
                </v-btn>
                <span v-else class="fontOneRem">작업중...</span>
            </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <CmmClndr
            :clndrHght="'680'" :hdrTyp="'totCnt'" :clndrList="events" :vsbYn="!savBtn"
            @chgDtAct="getPickerDate" @dayClck="calendarDayAct">
        </CmmClndr>

        <v-dialog v-model="dialog[0]" content-class="round" max-width="1000" persistent eager>
            <WrkngSchdlModal @isClose="dialog.splice(0, 1, false)"></WrkngSchdlModal>
        </v-dialog>
        <v-dialog v-model="dialog[1]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="dialog.splice(1, 1, false)"></ReportMgmt>
        </v-dialog>
    </v-sheet>
</template>

<script>
import CmmClndr from '../../components/commons/CmmClndr.vue';
import WrkngSchdlModal from './WrkngSchdlModal.vue';
import ReportMgmt from '../commons/ReportMgmt.vue';

import { selEmpWrkJobCntList, selExlEmpAtndList } from '../../api/emp.js';
import { groupBy } from '../../api/common.js';
import { excelCrtDwnl } from '../../api/index.js';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';

export default {
    name: 'EmpWrkngSchdl',

    props : {
       
    },
        
    components: {
        CmmClndr,
        WrkngSchdlModal,
        ReportMgmt,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.now = this.$moment().format('YYYY-MM-DD')
        })
    },

    computed: {
 
    },

    watch:{
        'start': function () {
            this.getEmpWrkJobCntList(this.start.afterDateFormatHyp().substring(0, 6))
        },
    },
    
    methods: {
        //근무일정현황 다건 조회
        getEmpWrkJobCntList: function (date) {
            this.savBtn = false

            selEmpWrkJobCntList(this.$store.getters.getFcltyNum, date)
                .then((response) => ( this.getEmpWrkJobCntListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkJobCntList : ' + error))
        },
        //근무표 엑셀 다운로드
        getExcelCrtDwnl: function () {
            let dt = this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM')

            excelCrtDwnl(this.$store.getters.getFcltyNum, '6', null, dt, 0)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /report/excelCrtDwnl : ' + error))
        },
        getExlEmpAtnd: function () {
            this.exlBtn = false
            let dt = this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM')

            selExlEmpAtndList(this.$store.getters.getFcltyNum, dt)
                .then((response) => ( this.exlEmpAtndAfter(response) ))
                .catch((error) => console.log('connect error /emp/selExlEmpAtndList : ' + error))
        },
        exlEmpAtndAfter: function (res) {
            if(res.data.length > 0){
                let shetNm = '직원근무내역'
                let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM') 

                // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
                snglHdrXlsxPrdc(this.exlHeader, res.data, shetNm, title)
                this.exlBtn = true
            }
        },
        fileDownLoaderAfter: function (res) {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))

            link.href = url
            link.setAttribute('download', '직원 근무표_' + this.$store.getters.getFcltyNum + '_' + new Date(this.start).toISOString().split('T',2)[0].afterDateFormatHyp().substring(0, 6) + '.xlsx')
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },
        getEmpWrkJobCntListAfter: function (res) {
            this.events.splice(0)
            this.dayEmpCnt.splice(0)

            let now = new Date(this.start.substring(0, 4), this.start.substring(5, 7), 0)

            let noGroup = []

            res.filter( v => v.wrktypcd == '1' ).forEach((items) => {
                let obj = {}
                this.$parent.$parent.$parent.$parent.$parent.$parent.vCd42.forEach(el=> {
                    if(items.jobCd == el.valcd)
                        obj.name = el.valcdnm
                });
                obj.start = items.wrkDt.beforeDateFormatHyp()
                obj.end = items.wrkDt.beforeDateFormatHyp()
                obj.cnt = items.empCnt
                obj.color = '#dbdbdb'

                this.events.push(obj)
                noGroup.push(obj)
            });

            for (let index = 1; index <= now.getDate(); index++) {
                let obj = {}
                obj.name = '휴가'

                let chcDate = now.getFullYear() + '' + ('00'+(now.getMonth()+1)).slice(-2) + '' + ('00'+index).slice(-2)
                let vctn = res.filter( v => v.wrktypcd == '2' )
                let cnt = vctn.filter( v => v.wrkDt == chcDate )

                obj.start = chcDate.beforeDateFormatHyp()
                obj.end = chcDate.beforeDateFormatHyp()
                obj.cnt = cnt.length
                obj.color = 'primary'

                if(cnt.length > 0){
                    this.events.push(obj)
                    noGroup.push(obj)
                }
            }

            let groupB = groupBy(noGroup, 'start')
            Object.keys(groupB).forEach((key, i)  => {
                let obj = {}
                obj.name = ''
                obj.start = key
                obj.end = key
                this.dayEmpCnt.push({date: '', tCnt: 0})
                obj.data = Object.values(groupB)[i]
                obj.data.forEach(cnt => {
                    this.dayEmpCnt[i].date = cnt.start
                    this.dayEmpCnt[i].tCnt += cnt.cnt
                });
                //this.events.push(obj)
            });

            this.savBtn = true

        },
        calendarDayAct: function (value) {
            this.sltDate = value.day.date
            this.dialog.splice(0, 1, true)
        },
        getPickerDate: function (date) {
            this.start = this.$moment(date, 'YYYYMM').format('YYYY-MM-DD')
            this.$parent.$parent.$parent.$parent.$parent.$parent.sltDt = this.$moment(date, 'YYYYMM').format('YYYY-MM-DD')
        },
        getEventColor: function (event) {
            return event.color
        },
        //월간 근무 일정표 출력팝업
        rprtActCall: function (){
            //this.prtThrowObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.prtThrowObj.inqYYMM = this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM')
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            
            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.dialog.splice(1, 1, true)
        },
    },

    data: () => ({
        exlBtn: true,
        savBtn: true,
        exlHeader: [
            { header: '성명', key: 'name', width: 10, }, { header: '생년월일', key: 'birth', width: 10, },
            { header: '근무기간', key: 'wrkPrd', width: 24, }, { header: '직종', key: 'JobNm', width: 24, },
            { header: '일자', key: 'wrkDt', width: 12, }, { header: '요일', key: 'wrkDY', width: 7, },
            { header: '근무시작', key: 'atndTm1', width: 10, }, { header: '근무종료', key: 'leavTm1', width: 10, },
            { header: '주간휴식시', key: 'brkDayHh1', width: 12, }, { header: '주간휴식분', key: 'brkDayMm1', width: 12, },
            { header: '야간휴식시', key: 'brkNgtHh1', width: 12, }, { header: '야간휴식분', key: 'brkNgtMm1', width: 12, },
            { header: '근무시작2', key: 'atndTm2', width: 10, }, { header: '근무종료2', key: 'leavTm2', width: 10, },
            { header: '주간휴식시2', key: 'brkDayHh2', width: 12, }, { header: '주간휴식분2', key: 'brkDayMm2', width: 12, },
            { header: '야간휴식시2', key: 'brkNgtHh2', width: 12, }, { header: '야간휴식분2', key: 'brkNgtMm2', width: 12, },
            { header: '휴가코드', key: 'vctnCd', width: 10, }, { header: '휴가시간', key: 'vctnHr', width: 10, },
            { header: '휴일근무시간', key: 'vctWrkHr', width: 14, }, { header: '근무상세구분', key: 'wrkDtl', width: 14, },
            { header: '근무상세내용', key: 'wrkDtlCtt', width: 14, }, { header: '근무휴일상세여부', key: 'wrkTypNm', width: 16, },
        ],
        now: '',
        sltDate: '',
        start: '',
        dayEmpCnt: [],
        events: [
            //{ name: '1', start: '2023-01-01', end: '2023-01-01', color: 'light-blue darken-2', cnt: 1 },
        ],
        colors: ['blue', 'deep-orange darken-3', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        calendarObj: 
        { 
            type: 'month', types: ['month', 'week', 'day', '4day'],
            weekday: [0, 1, 2, 3, 4, 5, 6], mode: 'stack',
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
        },
        dialog: [false, false],
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
        prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '54', inTxt1: '', inTxt2: '', inqYYMM: '', list: [ { bzClcd: [], bzPk: [] } ], },
    }),
};
</script>

<style>
    .empCalendar .v-calendar-weekly__head div { padding: 6px 0px; font-size: 1.2rem; font-weight: 500;
        background-color: white !important; border-bottom: 1px solid #eaeaea; margin-right: 0px !important; }
    .empCalendar .v-calendar-weekly__week .v-calendar-weekly__day .v-calendar-weekly__day-label span { font-size: 0.975rem; font-weight: 300; }
    .empCalendar .v-calendar-weekly__week .v-event {
        margin-left: 2px; width: 98%; border: 1px solid #dbdbdb; padding-bottom: 20px;
    }
    .nowDay { width: 24px; height: 24px; border-radius: 0.85rem; justify-content: center; display: flex;
        color: white; align-items: center; font-size: 1rem; font-weight: 500; padding: 0px 1px 2px 0px; }
    .v-event-more { height: 20px !important; }
    .v-calendar.v-calendar-events .v-calendar-weekly__day { margin-right: 0px !important; }
    .v-outside .v-calendar-weekly__day-label { color: #f7f7f7; }
    .empCalendar div .v-outside { border-right: 1px solid #e0e0e0 !important; }
    .empCalendar .v-calendar-weekly__week { min-height: initial; }
    .empCalendar .v-calendar-weekly__week .v-calendar-weekly__day { padding-bottom: 4px; }
    /* .v-calendar .v-event { overflow: auto; } */
    /* .empCalendar div .v-event { height: 70px !important; } */
    .empCalendar { position: relative; border-top: initial !important; }
    .empCalendar .v-calendar-weekly__head { 
        position: sticky; top: 0; left: 0; width: 100%; background-color: white; z-index: 3;
        border-top: #e0e0e0 1px solid;
        border-collapse: separate !important; border-spacing: 0px !important;
    }
</style>