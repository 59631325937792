<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">근무패턴&nbsp;설정</span>
            <v-btn
                class="ml-2 mt-1" width="30" height="30" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 20)" icon>
                <v-icon size="30" style="">mdi-help-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="mt-4">
            <span class="d-inline-block ml-1" style="width: 120px;">
                <v-select class="wrkSelect"
                    v-model="wrkState" height="28" item-text="valcdnm" 
                    item-value="valcd" :items="$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73"
                    dense hide-details outlined>
                </v-select>
            </span>
        </div>
        <v-divider :class="['mt-2', $parent.$parent.$parent.$parent.wrkPtnList.length+editYwrkPtnList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01', $parent.$parent.$parent.$parent.wrkPtnList.length+editYwrkPtnList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="font-weight-medium">구분</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="3">
                <span class="font-weight-medium">근무형태</span>
            </v-col>
            <v-col class="tableBR" cols="3">
                <v-row class="d-flex justify-center align-center tableBB py-1" no-gutters>
                    <span class="font-weight-medium">근로시간</span>
                </v-row>
                <v-row no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                        <span class="font-weight-medium">시작시간</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                        <span class="font-weight-medium">종료시간</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="4">
                        <span class="font-weight-medium">근로익일여부</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="tableBR" cols="3">
                <v-row class="d-flex justify-center align-center tableBB py-1" no-gutters>
                    <span class="font-weight-medium">휴게시간&#40;분&#41;</span>
                </v-row>
                <v-row no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="6">
                        <span class="font-weight-medium">주간&nbsp;&#40;06&#126;22&#41;</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="6">
                        <span class="font-weight-medium">야간&nbsp;&#40;22&#126;06&#41;</span>
                    </v-col>
                    <!-- <v-col class="d-flex justify-center align-center py-1" cols="4">
                        <span class="font-weight-medium">야간익일&nbsp;&#40;01&#126;05&#41;</span>
                    </v-col> -->
                </v-row>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="font-weight-medium">실근무시간</span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="1">
                <span class="">
                    <v-btn 
                        class="d-inline-block" style="padding:0px 1px 0px 2px !important"
                        color="blue200" min-width="28" width="30" height="28" @click="addRow()"
                        icon dark depressed>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="22" v-bind="attrs" v-on="on">mdi-plus-circle-outline</v-icon>
                            </template>
                            <span>추가</span>
                        </v-tooltip>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-divider :class="['greyE01', $parent.$parent.$parent.$parent.wrkPtnList.length+editYwrkPtnList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 370px;">
            <v-row class=""  v-for="(list, i) in editYwrkPtnList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR tableBB py-1" cols="1">
                    {{ $parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73.findIndex( v => v.valcd == list.wrkTypCd ) > -1 ?
                    $parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73[$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73.findIndex( v => v.valcd == list.wrkTypCd )].valcdnm :
                    '오류'}}
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB py-1" cols="3">
                    <span class="d-inline-block mr-1" style="width: 58%;">
                        <v-text-field v-model="list.wrkPtnNm" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block" style="width: 96px;">
                        <v-text-field v-model="list.wrkPtnColr" class="ma-0 pa-0 colorFiled" readonly hide-details outlined dense>
                            <template v-slot:append>
                                <v-menu v-model="menu[i]" top nudge-bottom="210" nudge-left="-30" :close-on-content-click="false">
                                    <template v-slot:activator="{ on }">
                                        <div class="" :style="list.wrkPtnColrStyle" v-on="on" />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                v-model="colorObj" :swatches="swatches" @update:color="clrSelector(i)" show-swatches>
                                            </v-color-picker>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="3">
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField
                                    v-model="list.wrkStrtTm" :required="false" :disabled="list.wrkTypCd == '2'">
                                </CmmTimeField>                                     
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField
                                    v-model="list.wrkEndTm" :required="false" :disabled="list.wrkTypCd == '2'">
                                </CmmTimeField>                                     
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center" cols="4">
                            <v-checkbox class="checkIcon" v-model="list.wrkOvnYn" true-value="1" false-value="2" :disabled="list.wrkTypCd == '2'"
                                on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="3">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR py-2" cols="6">
                            <span class="d-inline-block" style="width: 60%;">
                                <v-text-field
                                    v-model="list.brkDay" type="number"
                                    @input="actWrkTmCalc(i)" :disabled="list.wrkTypCd == '2'"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span>분</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center" cols="6">
                            <span class="d-inline-block" style="width: 60%;">
                                <v-text-field
                                    v-model="list.brkNgt" type="number"
                                    @input="actWrkTmCalc(i)" :disabled="list.wrkTypCd == '2'"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span>분</span>
                        </v-col>
                        <!-- <v-col class="d-flex justify-center align-center py-1" cols="4">
                            <span class="d-inline-block mx-1" style="width: 60%;">
                                <v-text-field v-model="list.brkOvn" type="number" @input="actWrkTmCalc(i)" :disabled="list.wrkTypCd == '2'"
                                    hide-details outlined dense></v-text-field>
                            </span>
                            <span>분</span>
                        </v-col> -->
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="1">
                    <span class="d-inline-block mx-1">
                        <!-- list.actWrkHr --> {{ list.wrkTm }}
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBB" cols="1">
                    <v-btn 
                        class="d-inline-block" style="padding:0px 1px 0px 2px !important"
                        color="blue200" min-width="28" width="30" height="28" @click="preWrkPtn(i)"
                        icon dark depressed>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-content-save-outline</v-icon>
                            </template>
                            <span>저장</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn 
                        class="d-inline-block" style="padding:0px 1px 0px 2px !important"
                        color="grey006" min-width="28" width="30" height="28" @click="editYwrkPtnList.splice(i, 1)"
                        icon dark depressed>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-minus-circle-outline</v-icon>
                            </template>
                            <span>삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                class=""
                v-for="(list, j) in $parent.$parent.$parent.$parent.wrkPtnList" :key="editYwrkPtnList.length + j"
                no-gutters>
                <v-col class="d-flex justify-center align-center tableBR tableBB py-1" cols="1">
                    {{ $parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73.findIndex( v => v.valcd == list.wrkTypCd ) > -1 ?
                    $parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73[$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73.findIndex( v => v.valcd == list.wrkTypCd )].valcdnm :
                    '오류'}}
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB py-1" cols="3">
                    <span class="d-inline-block mr-1 pb-1" v-if="list.wrkTypCd == '1'">
                        <v-icon size="16">mdi-calendar-blank</v-icon>
                    </span>
                    <span class="d-inline-block mr-1 pb-1" v-else-if="list.wrkTypCd == '2'">
                        <v-icon size="16">mdi-airplane</v-icon>
                    </span>
                    <span class="d-inline-block mr-1 pb-1" v-else-if="list.wrkTypCd == '3'">
                        <v-icon size="16">mdi-school-outline</v-icon>
                    </span>
                    <span class="d-inline-block mr-1 pb-1" v-else>
                        <v-icon size="16">mdi-clipboard-list-outline</v-icon>
                    </span>
                    <span class="d-inline-block mr-1" style="width: 50%;">{{ list.wrkPtnNm }}</span>
                    <span class="d-inline-block" style="width: 62px; font-size: 0.975rem; color: rgba(0, 0, 0, 0.87);">{{ list.wrkPtnColr }}</span>
                    <span class="d-inline-block" :style="list.wrkPtnColrStyle"></span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="3">
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR" cols="4">
                            <div class="py-1" style="margin: 2px !important;">
                                <span class="">{{ list.wrkTmHhStrt }}</span>
                                <span v-if="list.wrkTmHhStrt != '' && list.wrkTmMmStrt != ''">&#58;</span>
                                <span v-else>&nbsp;</span>
                                <span class="">{{ list.wrkTmMmStrt }}</span>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR" cols="4">
                            <div class="py-1" style="margin: 2px !important;">
                                <span class="">{{ list.wrkTmHhEnd }}</span>
                                <span v-if="list.wrkTmHhEnd != '' && list.wrkTmMmEnd != ''">&#58;</span>
                                <span v-else>&nbsp;</span>
                                <span class="">{{ list.wrkTmMmEnd }}</span>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-center align-center" cols="4">
                            <v-checkbox class="checkIcon" v-model="list.wrkOvnYn" true-value="1" false-value="2" readonly :ripple="false"
                                on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" hide-details></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="3">
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR py-1" cols="6">
                            <span class="d-inline-block" style="margin: 2px !important;">{{ list.brkDay }}분</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="6">
                            <span class="d-inline-block">{{ list.brkNgt }}분</span>
                        </v-col>
                        <!-- <v-col class="d-flex justify-center align-center py-1" cols="4">
                            <span class="d-inline-block">{{ list.brkOvn }}분</span>
                        </v-col> -->
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="1">
                    <span class="">{{ list.actWrkHrTxt }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBB" cols="1">
                    <span class="" v-if="list.fcltyNum != '10000000000'">
                        <v-btn 
                            class="d-inline-block" style="padding:0px 1px 0px 2px !important"
                            color="grey006" min-width="28" width="30" height="28" @click="dialog.splice(0, 1, true), delTitle = list.wrkPtnNm, sltIndex = list.wrkPtnPk"
                            icon dark depressed>
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="22" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                </template>
                                <span>삭제</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        
        <v-dialog v-model="dialog[0]" max-width="500">    
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { insWrkPtn, delWrkPtn } from '../../api/emp.js';

import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'WrkPtrCnfgr',

    props : {

    },
        
    components: {
        btnModalDelete,
        CmmTimeField,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
        swatchStyle: function () {
            return {
                backgroundColor: this.color[this.sltIndex],
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                borderRadius: this.menu[this.sltIndex] ? '50%' : '4px',
                transition: 'border-radius 200ms ease-in-out',
                margin: '1px 0px 0px 0px !important'
            }
        },
    },

    watch:{

    },
    
    methods: {
        //근무패턴 저장
        setWrkPtn: function (obj) {
            insWrkPtn(obj)
                .then((response) => ( this.setWrkPtnAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/insWrkPtn : ' + error))
        },
        //근무패턴 삭제
        removeWrkPtn: function (pk) {
            delWrkPtn(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk)
                .then((response) => ( this.setWrkPtnAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/delWrkPtn : ' + error))
        },
        setWrkPtnAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.clearForm()
                this.$parent.$parent.$parent.$parent.getWrkPtnList()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preWrkPtn: function (index) {
            let obj = Object.assign({}, this.editYwrkPtnList[index])
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            if(obj.wrkStrtTm != ''){
                let strt = obj.wrkStrtTm.split(':', 2)
                obj.wrkTmHhStrt = strt[0]
                obj.wrkTmMmStrt = strt[1]
            }
            if(obj.wrkEndTm != ''){
                let end = obj.wrkEndTm.split(':', 2)
                obj.wrkTmHhEnd = end[0]
                obj.wrkTmMmEnd = end[1]
            }
            obj.actWrkHr = obj.wrkTmMin

            if( Number(this.wrkState) > 2 )
                obj.ptnTypCd = '2'
            else
                obj.ptnTypCd = '1'

            //console.log(obj)
            this.setWrkPtn(obj)
        },
        actWrkTmCalc: function (index){
            if(this.editYwrkPtnList[index].wrkStrtTm == '')
                return 0
            if(this.editYwrkPtnList[index].wrkEndTm == '')
                return 0

            //시간을 분단위로 변경
            let strtMm = ( Number(this.editYwrkPtnList[index].wrkStrtTm.split(':', 2)[0]) * 60 )
                + Number( this.editYwrkPtnList[index].wrkStrtTm.split(':', 2)[1] )
            let endMm = ( Number(this.editYwrkPtnList[index].wrkEndTm.split(':', 2)[0]) * 60 )
                + Number( this.editYwrkPtnList[index].wrkEndTm.split(':', 2)[1] )
            let brkDay = Number(this.editYwrkPtnList[index].brkDay)
            let brkNgt = Number(this.editYwrkPtnList[index].brkNgt)
            let brkOvn = Number(this.editYwrkPtnList[index].brkOvn)
      
            if(strtMm <= endMm){
                let hh = Math.floor( ( endMm - strtMm - brkDay - brkNgt - brkOvn ) / 60 )
                let mm = ( endMm - strtMm - brkDay - brkNgt - brkOvn ) % 60
                this.editYwrkPtnList[index].wrkTm = hh + '시간' + mm + '분'
                this.editYwrkPtnList[index].wrkTmMin = (hh * 60) + mm
            }
            else if(strtMm > endMm){
                let hh = Math.floor( (((24 * 60) - strtMm) + endMm - brkDay - brkNgt - brkOvn ) / 60 )
                let mm = ( ((24 * 60) - strtMm) + endMm - brkDay - brkNgt - brkOvn ) % 60
                this.editYwrkPtnList[index].wrkTm = hh + '시간' + mm + '분'
                this.editYwrkPtnList[index].wrkTmMin = (hh * 60) + mm
            }
        },
        clrSelector: function (index){
            this.color[index] = this.colorObj.hex
            this.editYwrkPtnList[index].wrkPtnColr = this.colorObj.hex
            this.editYwrkPtnList[index].wrkPtnColrStyle = 'cursor: pointer; height: 20px; width: 20px;' +
                'margin: 1px 0px 0px 0px !important; transition: border-radius 200ms ease-in-out;' +
                'border-radius: 4px; background-color: ' + this.colorObj.hex + '; '
        },
        addRow: function (){
            let obj = Object.assign({}, this.$parent.$parent.$parent.$parent.wrkPtnObj)
            obj.wrkTypCd = this.wrkState

            if(this.wrkState == '2'){
                let chk = this.$parent.$parent.$parent.$parent.wrkPtnList.findIndex(v => v.wrkTypCd == '2')

                if(chk == -1){
                    obj.wrkTypCd = '2'
                    obj.wrkStrtTm = '09:00'
                    obj.wrkEndTm = '18:00'
                    obj.brkDay = '60'
                    obj.wrkTm = '8시간'
                    obj.wrkTmMin = 480

                    this.editYwrkPtnList.push(obj)
                    this.workTimeStrt.push(false)
                    this.workTimeEnd.push(false)
                    this.color.push('#000000')
                    this.menu.push(false)
                }
                else
                    this.snackControll([true, 2500, '등록된 휴가패턴이 존재합니다.', 'info'])
            }
            else{
                this.editYwrkPtnList.push(obj)
                this.workTimeStrt.push(false)
                this.workTimeEnd.push(false)
                this.color.push('#000000')
                this.menu.push(false)
            }
        },
        strtPicked: function (index, tm){
            this.$refs.strtPicker[index].save(tm)
            this.forceRender += 1
            this.actWrkTmCalc(index)
        },
        endPicked: function (index, tm){
            this.$refs.endPicker[index].save(tm)
            this.forceRender += 1
            this.actWrkTmCalc(index)
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        clearForm: function () {
            this.editYwrkPtnList.splice(0)
            this.$parent.$parent.$parent.$parent.wrkPtnList.splice(0)
            this.workTimeStrt.splice(0)
            this.workTimeEnd.splice(0)
            this.color.splice(0)
            this.menu.splice(0)
            this.colorObj = null
        },
        delModalAct: function (res) {
            if(res == 9990){    //OK'
                if(this.sltIndex != -1)
                    this.removeWrkPtn(this.sltIndex)
                
                this.dialog.splice(0, 1, false)
            }
            else if(res == 7)   //Cancel
                this.dialog.splice(0, 1, false)

            this.delTitle = ''
            this.sltIndex = -1
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        wrkState: '1',
        dialog: [false],
        delTitle: '',
        sltIndex: -1,
        editYwrkPtnList: [],
        editNwrkPtnList: [],
        workTimeStrt: [],
        workTimeEnd: [],
        color: [],
        colorObj: null,
		menu: [],
        swatches: [
            ['#FF0000', '#AA0000', '#550000'],
            ['#FFFF00', '#AAAA00', '#555500'],
            ['#00FF00', '#00AA00', '#005500'],
            ['#00FFFF', '#00AAAA', '#005555'],
            ['#0000FF', '#0000AA', '#000055'],
            ],
        rules: {
            required: value => !!value || 'Required.',
        },
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .colorFiled .v-input__control { min-height: 30px !important; }
    .colorFiled .v-input__control .v-input__slot { padding: 0px 8px 2px 8px !important; }
    .colorFiled .v-input__control .v-input__append-inner { margin-top: 3px !important; }
    .colorFiled .v-input__control .v-input__slot .v-text-field__slot input { padding: 0px !important; }
    .checkIcon .v-input__control { width: 24px !important; }
</style>