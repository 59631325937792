<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-form ref="tabform" lazy-validation>         
        <div class="d-flex align-center">
            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">기관 기본정보</span>
        </div>
        <v-divider class="mt-2"></v-divider>        
        <v-row no-gutters>
            <v-col class="" cols="10">
                <v-row no-gutters>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">기관번호</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 130px">
                            <v-text-field v-model="items.fcltyNum" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">치매전담실</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span class="d-inline-block" style="width: 130px;">
                            <v-radio-group v-model="items.dmntTrtYn" :rules="[rules.required]" class="pl-2 pt-1"
                                hide-details row>
                                <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="운영" value="1"></v-radio>
                                <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="미운영" value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">전화번호</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 50px;">
                            <v-text-field v-model="items.fcltyTel1" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                        <span class="px-1">&#45;</span>
                        <span style="width: 60px;">
                            <v-text-field v-model="items.fcltyTel2" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                        <span class="px-1">&#45;</span>
                        <span style="width: 60px;">
                            <v-text-field v-model="items.fcltyTel3" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">시설명</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 130px">
                            <v-text-field v-model="items.fcltyClnm" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">시설장명</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 80px" class=" d-flex justify-center align-center pa-1 ma-0">
                            <v-text-field v-model="items1.name" :rules="[rules.required]" hide-details outlined dense
                                style="width: 50px;">
                            </v-text-field>
                        </span>
                        <v-btn class="white--text darken-1" color="bckgrColor" height="28" width="20"
                            min-width="20" @click="menu.splice(1, 1, true)" depressed>
                            <v-icon small>mdi-account-plus</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">팩스번호</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 50px">
                            <v-text-field v-model="items.fcltyFax1" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                        <span class="px-1">&#45;</span>
                        <span style="width: 60px">
                            <v-text-field v-model="items.fcltyFax2" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                        <span class="px-1">&#45;</span>
                        <span style="width: 60px">
                            <v-text-field v-model="items.fcltyFax3" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">시설구분</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 130px">
                            <v-select dense hide-details outlined style="margin-top: 3px;" v-model="items.fcltyClcd"
                                :items="codeItem" item-text="valcdnm" item-value="valcd"
                                :rules="[rules.required]"></v-select>
                        </span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">휴대폰</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 50px">
                            <v-text-field v-model="items1.celphn_1" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                        <span class="px-1">&#45;</span>
                        <span style="width: 60px">
                            <v-text-field v-model="items1.celphn_2" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                        <span class="px-1">&#45;</span>
                        <span style="width: 60px">
                            <v-text-field v-model="items1.celphn_3" :rules="[rules.required]" hide-details outlined
                                dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">입소정원</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span style="width: 50px" class=" d-flex justify-center align-center">
                            <v-text-field v-model="items.entCap" :rules="[rules.required]" hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span>명</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">이메일</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="7">
                        <!-- 이메일 area -->
                        <span class="d-inline-block" style="width: 150px;">
                            <v-text-field v-model="items.emailId" :rules="[rules.required]" hide-details outlined
                                dense></v-text-field>
                        </span>

                        <span class="d-inline-block text-h6 pa-0 pl-1 mx-1" style="width: 28px;">
                            &#64;
                        </span>
                        <span class="d-inline-block" style="width: 150px;">
                            <v-text-field v-model="items.emailDmn" :rules="[rules.required, rules.emailDomain]" hide-details
                                outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block ml-1" style="width: 140px;">
                            <v-select v-model="selectEmail" :items="email_list" @change="emailSelected" :key="forceRender"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                        <span class="black--text font-weight-medium">사업개시일</span>
                    </v-col>
                    <v-col class="d-flex align-center tableBL pl-2" cols="3">
                        <span class="d-inline-block" style="width: 150px;">
                            <CmmDateComp v-model="items.bsnsStrtDt" :required="true"></CmmDateComp>
                        </span>
                    </v-col>
                </v-row>                
            </v-col>
            <v-col class="tableBL d-flex justify-center align-center" cols="2">                                    
                <div v-if="imageRes.length < 1" style="width:180px;">
                    <div class="d-flex justify-center align-center">직인을 선택해주세요.</div>
                    <div>
                        <v-file-input                        
                            v-model="images" 
                            :accept="imgAccept" 
                            dense hide-details
                            truncate-length="5" 
                            multiple counter 
                            prepend-icon="mdi-stamper"                                 
                            @change="upImgFile()"></v-file-input>
                    </div>

                </div>
                <div v-else>
                    <span class="d-inline-block mt-1" v-for="(item, i) in imageRes" :key="i">
                        <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'110'" @isEvent="deleteImg(i)"
                            @onDown="fileDownload(i)">
                        </imageCard>
                    </span>
                </div>
            </v-col>
        </v-row>    
        <v-divider></v-divider> 
        <div class="mt-2 d-flex align-center">
            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">사업자 정보</span>
        </div>  
        <v-divider class="mt-2"></v-divider>                
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">사업자번호</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 130px">
                    <v-text-field v-model="items.bsnsRgst" :rules="[rules.required]" hide-details outlined type="number"
                        dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">대표자명</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 80px">
                    <v-text-field v-model="items.ceo" :rules="[rules.required]" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">업태/종목</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="5">
                <span style="width: 150px;">
                    <v-text-field v-model="items.bsnsCndtn" :rules="[rules.required]" hide-details outlined
                        dense>
                    </v-text-field>
                </span>
                <span class="d-inline-block text-h6 pa-0 px-1">
                    &#47;
                </span>
                <span style="width: 150px;">
                    <v-text-field v-model="items.bsnsItm" :rules="[rules.required]" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">담당자</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 80px">
                    <v-text-field v-model="items.mngr" :rules="[rules.required]" hide-details outlined dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">연락처</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="2">
                <span style="width: 50px">
                    <v-text-field v-model="items.mngrTel1" :rules="[rules.required]" hide-details outlined
                        dense>
                    </v-text-field>
                </span>
                <span class="px-1">&#45;</span>
                <span style="width: 55px">
                    <v-text-field v-model="items.mngrTel2" :rules="[rules.required]" hide-details outlined
                        dense>
                    </v-text-field>
                </span>
                <span class="px-1">&#45;</span>
                <span style="width: 55px">
                    <v-text-field v-model="items.mngrTel3" :rules="[rules.required]" hide-details outlined
                        dense>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">이메일</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="5">
                <span class="d-inline-block">
                    <!-- 이메일 area -->
                    <span class="d-inline-block" style="width: 150px;">
                        <v-text-field v-model="items.mngrEmailId" :rules="[rules.required]" hide-details
                            outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h6 pa-0 pl-1 mx-1" style="width: 28px;">
                        &#64;
                    </span>
                    <span class="d-inline-block" style="width: 150px;">
                        <v-text-field v-model="items.mngrEmailDmn" :rules="[rules.required, rules.emailDomain]"
                            hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 140px;">
                        <v-select v-model="mngrSelectEmail" :items="email_list" @change="mngrEmailSelected"
                            :key="forceRender" dense hide-details outlined>
                        </v-select>
                    </span>
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">주소</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="10">
                <!-- 주소 area -->
                <span class="d-inline-block d-flex flex-row">
                    <span class="d-inline-block" style="width: 188px;">
                        <v-text-field v-model="items.zip" :rules="[rules.required, rules.numberCheck]"
                            readonly hide-details outlined dense></v-text-field>
                    </span>
                    <span>
                        <v-btn class="ml-1" color="#3C9099" style="padding: 1px 0px 0px 3px !important"
                            @click="addressPopup1 = true" min-width="30" height="24" dark small>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs"
                                        v-on="on">mdi-map-search-outline</v-icon>
                                </template>
                                <span>주소검색</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                    <span class="d-inline-block" style="width: 461px;">
                        <v-text-field v-model="items.addr" :rules="[rules.required]" class="ml-2" readonly
                            hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block" style="width: 190px;">
                        <v-text-field v-model="items.addrDtl" :rules="[rules.required]" class="ml-1"
                            hide-details outlined dense></v-text-field>
                    </span>
                </span>
            </v-col>                       
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">문자 발신번호</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="5">
                <span style="width: 60px;">
                    <v-text-field v-model="items.cllTel1" hide-details outlined disabled dense>
                    </v-text-field>
                </span>
                -
                <span style="width: 60px">
                    <v-text-field v-model="items.cllTel2" hide-details outlined disabled dense>
                    </v-text-field>
                </span>
                -
                <span style="width: 60px">
                    <v-text-field v-model="items.cllTel3" hide-details outlined disabled dense>
                    </v-text-field>
                </span>
                <span style="padding-top: 1px;">
                    <v-btn class="ml-1" color="light-blue accent-2" style="padding: 1px 0px 0px 0px !important"
                        @click="onModal(0)" min-width="30" height="27" dark small>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="18" v-bind="attrs" v-on="on">mdi-phone-plus</v-icon>
                            </template>
                            <span>문자발신번호 등록</span>
                        </v-tooltip>
                    </v-btn>
                </span>
                <span class="ml-3 rounded" style="border: 1px solid #ccc; padding: 1px 20px 2px 20px;">
                    <span v-if="items.cllCnfmYn == '1'">심사완료</span>
                    <span v-if="items.cllCnfmYn == '2'">심사중</span>
                </span>
            </v-col>           
            <v-col class="greyE01 py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">현금영수증</span>
            </v-col>
            <v-col class="d-flex align-center tableBL pl-2" cols="5">
                <div v-if="items.pbilYn == '1'" class="d-flex justify-center align-center ">
                    <span class="ml-3 rounded" style="border: 1px solid #ccc; padding: 1px 20px 2px 20px;">
                        <span>신청완료</span>
                    </span>
                    <span class="pl-3"> &#40;ID</span>
                    <span class="px-1">&#58;</span>
                    <span>{{ items.pbilId }}&#41;</span>
                </div>
                <div v-else>
                    <v-btn class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                        min-width="30" height="25" @click="uptPopBill" small outlined>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-receipt-text-arrow-right-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">연동신청</span>
                                </div>
                            </template>
                            <span>현금영수증 연동 신청하기</span>
                        </v-tooltip>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="menu.splice(1, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="''"></EmpSelector>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" persistent max-width="1000">
                <SnsNumSave @isClose="isModal(0)"></SnsNumSave>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">
                <btnModalDelete :title="title" @madalState="delAtchdFile"></btnModalDelete>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="addressPopup1" content-class="round" max-width="500" persistent eager>
                <AdrsSrch ref="adrsSrch" @onDataThr="onAddressComp" @isClose="addressPopup1 = false"></AdrsSrch>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import SnsNumSave from '../opr/SnsNumSave.vue';
import AdrsSrch from '../commons/AdrsSrch.vue';
import imageCard from '../commons/imageCard.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import { selFcltyInfInfo, insFcltyInfSeal, selMmbrList, uptPopBillFcltyInf } from '../../api/opr.js';
import { tmpFileUpload, fileDownload } from '../../api/index.js';
import { remoteURL } from '@/api/baseAxios';
import { getMultiStcd, selESig, delESig } from '../../api/index.js';

export default {
    name: 'FacInfoMgmtTab1',

    props: {
        tab: Number,
    },
    components: {
        imageCard,
        AdrsSrch,
        btnModalDelete,
        SnsNumSave,
        EmpSelector,
        CmmDateComp,
    },

    computed: {
    },

    watch: {
        'items.bnMmbrPk': function () {
            if (this.items != [])
                this.galFmlyFormat()
        },
        'items.mngrEmailDmn': function () {
            this.emailInput()
        },
    },

    created: function () {
        getMultiStcd(['89', '64'])
            .then((response) => (this.codeMapping(response.data)))
            .catch((error) => console.log(error))
    },

    mounted: function () {
        this.$nextTick(function () {
            this.selFcltyInfInfo()
        })
    },

    methods: {
        selFcltyInfInfo: function () {
            this.valClcd = '89'
            this.valClcdnm = '시설구분'
            selFcltyInfInfo(this.$store.getters.getFcltyNum)
                .then((response) => (this.selFcltyInfInfoAfter(response.data)))
                .catch((error) => console.log('connect error opr/selFcltyInfInfo : ' + error))
        },
        selFcltyInfInfoAfter: function (res) {
            this.items = res
            this.items.bsnsStrtDt = res.bsnsStrtDt?.beforeDateFormatHyp()
            this.getESig(this.items.fcltyInfPk)
            this.selMmbrList(res.drcMmbrPk)
            this.$refs.tabform.resetValidation();
        },
        selMmbrList: function (pk) {
            this.mmbrPk = pk
            selMmbrList(this.$store.getters.getFcltyNum, 3, pk)
                .then((response) => (this.selMmbrListAfter(response.data)))
                .catch((error) => console.log('connect error cmm/selMmbrList : ' + error))
        },
        selMmbrListAfter: function (res) {
            if (res != '') {
                this.items1 = res[0]
            } else {
                this.items1.splice(0)
            }
        },
        insFcltyInfSeal: function () {

            if (this.$refs.tabform.validate()) {
                let obj = {
                    addr: this.items.addr,
                    addrDtl: this.items.addrDtl,
                    bsnsCndtn: this.items.bsnsCndtn,
                    bsnsItm: this.items.bsnsItm,
                    bsnsRgst: this.items.bsnsRgst,
                    ceo: this.items.ceo,
                    dmntTrtYn: this.items.dmntTrtYn,
                    drcMmbrPk: this.mmbrPk,
                    emailDmn: this.items.emailDmn,
                    emailId: this.items.emailId,
                    entCap: this.items.entCap,
                    fcltyClcd: this.items.fcltyClcd,
                    fcltyClnm: this.items.fcltyClnm,
                    fcltyFax1: this.items.fcltyFax1,
                    fcltyFax2: this.items.fcltyFax2,
                    fcltyFax3: this.items.fcltyFax3,
                    fcltyTel1: this.items.fcltyTel1,
                    fcltyTel2: this.items.fcltyTel2,
                    fcltyTel3: this.items.fcltyTel3,
                    mngr: this.items.mngr,
                    mngrEmailDmn: this.items.mngrEmailDmn,
                    mngrEmailId: this.items.mngrEmailId,
                    mngrTel1: this.items.mngrTel1,
                    mngrTel2: this.items.mngrTel2,
                    mngrTel3: this.items.mngrTel3,
                    cllTel1: this.items.cllTel1,
                    cllTel2: this.items.cllTel2,
                    cllTel3: this.items.cllTel3,
                    cllCnfmYn: '2',
                    fcltyInfPk: this.items.fcltyInfPk,
                    zip: this.items.zip,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                    files: [],
                    bsnsStrtDt: this.items.bsnsStrtDt?.afterDateFormatHyp(),
                }

                if (this.imageRes.length == 0) {
                    this.snackControll([true, 5000, "사진을 첨부해주세요.", 'warning'])
                    return
                }
                this.imageRes.forEach(x => {
                    if (x.eSigPk == undefined) {
                        obj.files.push(x)
                    }
                })
                insFcltyInfSeal(obj)
                    .then((response) => (this.insFcltyInfSealAfter(response.data)))
                    .catch((error) => console.log('connect error /opr/insFcltyInfSeal : ' + error))
            }
        },
        insFcltyInfSealAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selFcltyInfInfo()
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.images = null
            this.$emit("facInfoTrigger", 99)
        },
        onAddressComp: function (result) {
            this.items.zip = result.zonecode
            this.items.addr = result.address
            this.addressPopup1 = false
        },
        upImgFile: function () {
            let formData = new FormData();

            if (this.images != null) {
                this.images.forEach(element => {
                    formData.append("files", element)
                })

                if (this.images.length > 0) {
                    tmpFileUpload(formData)
                        .then((response) => (this.upImgFileAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }

        },
        upImgFileAfter: function (res) {

            res.files.forEach(x => {
                x.src = remoteURL + 'opr/imgPrview?fileNm=' + x.fileNm + '&filePth=' + x.filePth + '&fileKndCd=' + x.fileKndCd
                x.sigFileNm = x.fileNm
                x.sigFilePath = x.filePth
                this.imageRes.push(x)
            })
            this.images = null
        },
        getESig: function (value) {
            if (value > 0) {
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    eSigClcd: '10',
                    bzClcdPk: value,
                }
                selESig(obj)
                    .then((response) => (this.getESigAfter(response.data)))
                    .catch((error) => console.log('upload error /cmm/selESig : ' + error))
            } else {
                this.images = null
                this.imageRes.splice(0)
            }
        },
        getESigAfter: function (res) {
            this.images = null
            this.imageRes.splice(0)

            if (res != undefined && res != null && res != '') {
                let obj = {
                    eSigPk: res.eSigPk,
                    sigFileNm: res.sigFileNm,
                    sigFilePath: res.sigFilePath,
                    src: remoteURL + 'opr/imgView?fileNm=' + res.sigFileNm + '&filePth=' + res.sigFilePath
                }

                this.imageRes.push(obj)
            }
        },
        uptPopBill: function () {

            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                userNm: this.$store.getters.getUserNm,
            }

            uptPopBillFcltyInf(obj)
                .then((response) => (this.uptPopBillAfter(response.data)))
                .catch((error) => console.log('upload error /opr/uptPopBillFcltyInf : ' + error))
        },
        uptPopBillAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selFcltyInfInfo()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        deleteImg(idx) {
            if (this.imageRes[idx].eSigPk != undefined) {
                this.idx = idx
                this.title = '사진'
                this.dialogDelete = true
            } else {
                this.imageRes.splice(idx, 1)
            }
        },
        fileDownload: function (idx) {
            if (this.imageRes[idx] != undefined) {
                let obj = {
                    atchdFilePk: this.imageRes[idx].eSigPk,
                    fileNm: this.imageRes[idx].sigFileNm,
                    filePth: this.imageRes[idx].sigFilePath,
                }
                fileDownload(obj)
                    .then((response) => (this.fileDownLoaderAfter(response)))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [fileNameMatch] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [, fileName] = fileNameMatch.split('=');
            }
            link.href = url;

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        delAtchdFile: function (res) {
            if (res == 9990) {
                let pk = this.imageRes[this.idx].eSigPk

                if (pk > 0) {

                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        eSigPk: pk
                    }

                    delESig(obj)
                        .then((response) => (this.delAtchdFileAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delAtchdFileAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getESig(this.items.fcltyInfPk)
                this.idx = 0
                this.dialogDelete = false
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        codeMapping: function (res) {
            this.codeItem = res.vCd89
            this.emailCode = res.vCd64
            this.emailCode.forEach((cd) => {
                this.email_list.push(cd.valcdnm)
            });
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        emailSelected: function () {
            if (this.selectEmail == '직접입력')
                this.items.emailDmn = ''
            else
                this.items.emailDmn = this.selectEmail
        },
        mngrEmailSelected: function () {
            if (this.mngrSelectEmail == '직접입력')
                this.items.mngrEmailDmn = ''
            else
                this.items.mngrEmailDmn = this.mngrSelectEmail
        },
        galFmlyFormat: function () {

            //데이터 가공(인정등급 기간)
            this.items.rsdntBirth = this.items.rsdntBirth?.beforeDateFormatHyp()

            //이메일 선택
            if (this.items.mngrEmailDmn != null)
                this.selectEmail = this.items.mngrEmailDmn

        },
        emailInput: function () {
            if (this.items.mngrEmailDmn == '') this.selectEmail = "직접입력"
        },
        onModal: function (value) {
            this.menu.splice(value, 1, true)
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
            this.selFcltyInfInfo()
        },
        getEmpInfo: function (obj) {
            this.selMmbrList(obj[0].mmbrPk)
        },
    },

    data: () => ({
        headers: [
            { text: '적용시기', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '구분', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '1등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '2등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '3등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '4등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '5등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '초진비', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '재진비', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        items:
        {
            addr: '', addrDtl: '', bsnsCndtn: '', bsnsItm: '', bsnsRgst: '', ceo: '', dmntTrtYn: '', drcMmbr: '', drcMmbrPk: 0, drccClphn1: '', drccClphn2: '', drccClphn3: '', emailDmn: '', emailId: '',
            entCap: 0, fcltyClcd: '', fcltyClnm: '', fcltyFax1: '', fcltyFax2: '', fcltyFax3: '', fcltyTel1: '', fcltyTel2: '', fcltyTel3: '', mngr: '', mngrEmailDmn: '', mngrEmailId: '', mngrTel1: '',
            mngrTel2: '', mngrTel3: '', zip: '', pbilId: '', pbilYn: '',
        },
        items1: {},
        email_list: ['직접입력',],
        year: 2022,
        addressPopup1: false,
        menu: [false, false, false],
        file: null,
        FcltyInf: [],
        codeItem: [],
        snackOptions: [false, 3000, '', 'info'],
        imgAccept: 'image/*',
        images: null,
        files: null,
        imageRes: [],
        fileRes: [],
        forceRender: 0,
        selectEmail: '직접입력',
        mngrSelectEmail: '직접입력',
        title: '직접입력',
        dialogDelete: false,
        mmbrPk: 0,
    }),
};
</script>