<template>
    <v-sheet class="ma-0" rounded="md">
        <v-row class="pa-0 mt-1 mb-2" no-gutters>
            <v-col v-if="prtEmpPk == -1" style="" cols="12">
                <div class="d-inline-block blue001 d-flex justify-center align-center rounded-lg" style="width: 100%; height: 200px; opacity: 0.7;">
                    <span class="font-weight-bold white--text" style="font-size: 2rem;">좌측 메뉴에서 직원 선택후 이용해주세요.</span>
                </div>
            </v-col>
            <v-col v-else class="d-flex justify-center" cols="12">
                <span class="d-inline-block tableBR" style="width: 120px; border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb;">
                    <div class="d-inline-block" style="width: 100%; height: 100%;">
                        <v-img min-width="100%" max-width="100%" :key="forceRender"
                            :aspect-ratio="3/4" :src="imgSrc" lazy-src="../../assets/empNoImg.png" eager>
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular
                                        indeterminate color="grey lighten-5">
                                    </v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <div class="mt-1" style="text-align: center;">
                            <span class="font-weight-bold">{{ wrkCode }}</span>
                        </div>
                    </div>
                </span>
                <v-row class="ma-0 pa-0" no-gutters>
                    <v-col cols="12">
                        <v-row class="ma-0 pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">직원명</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.name : '' }}</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">성별&#40;나이&#41;</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span v-if="empInfo != null">
                                    <v-img class="ic-sex" v-if="empInfo.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                                    <v-img class="ic-sex" v-else-if="empInfo.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                                </span>
                                <span class="ml-1">
                                    {{ empInfo != null ? '( ' + empInfo.age + ' )' : '' }}
                                </span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">직종</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.jobCd : '' }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">생년월일</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.rsdntBirth : '' }}</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">입사일</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.entDtNm : '' }}</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">퇴사일</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.rtrDtNm : '' }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">아이디</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.id : '' }}</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">프로그램권한</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.authNm : '' }}</span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">조편성</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">{{ empInfo != null ? empInfo.grpNm : '' }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">휴대폰</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="2">
                                <span class="">
                                    {{ empInfo != null ? empInfo.celphn_1+'-'+empInfo.celphn_2+'-'+empInfo.celphn_3 : '' }}
                                </span>
                            </v-col>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="4">
                                <span class="black--text font-weight-medium">입사전 건강검진 제출일</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center py-1 tableBL tableBR" cols="4">
                                <span class="">{{ empInfo != null ? empInfo.entHlthChdt : '' }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">주소</span>
                            </v-col>
                            <v-col class="px-2 py-1 tableBL tableBR" cols="10">
                                <span class="d-inline-block overflow-y-auto" style="width: 100%; height: 23px;">
                                    {{ empInfo != null ? '( ' + empInfo.zip + ' ) ' + empInfo.addr + ' ' + empInfo.addrDtl : '' }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                                <span class="black--text font-weight-medium">비고</span>
                            </v-col>
                            <v-col class="pl-2 py-1 tableBL tableBR" cols="10">
                                <span class="">{{ empInfo != null ? empInfo.rmrks : '' }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <span class="d-inline-block d-flex align-center" style="width: 120px; border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb;">
                    <v-img 
                        :aspect-ratio="4/3" :src="signSrc.src" :key="forceRender"
                        width="120" height="90"  lazy-src="../../assets/noSignImg.png" eager>
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                    indeterminate color="grey lighten-5">
                                </v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selEmpInfo } from '../../api/emp.js';
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'EmpDtl',

    props : {
       prtEmpPk: { type: Number, default: -1 },
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
          
        })
    },

    computed: {

    },

    watch:{
        'prtEmpPk': function () {
            window.URL.revokeObjectURL(this.imgSrc)
            window.URL.revokeObjectURL(this.signSrc.src)
            this.imgSrc = ''
            this.signSrc.src = ''

            if(this.prtEmpPk == -1){
                this.empInfo = null
            }
            else {
                this.getEmpInfo(this.prtEmpPk)
            }
        },
    },
    
    methods: {
        getEmpInfo: function (pk) {
            selEmpInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.getEmpInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpInfo : ' + error))
        },
        getEmpInfoAfter: function (res) {
            res.rsdntBirth = res.rsdntBirth?.beforeDateFormatDot()
            res.entDtNm = res.entDt?.beforeDateFormatDot()
            if(res.rtrDt == null)
                res.rtrDt = ''
            else
                res.rtrDtNm = res.rtrDt?.beforeDateFormatDot()
            res.entHlthChdt = res.entHlthChdt?.beforeDateFormatDot()

            this.$parent.$parent.$parent.$parent.vCd42.forEach(item => {
                if(res.jobCd == item.valcd) res.jobCd = item.valcdnm
            });
            this.$parent.$parent.$parent.$parent.vCd41.forEach(item => {
                if(res.wrkClcd == item.valcd) this.wrkCode = item.valcdnm
            });

            this.cntlGrpBtn(res)

            this.empInfo = res
            this.cvImgEncode()
            this.signSrc = this.getEmpSignURL()
        },
       
        cvImgEncode: async function () {
            //직원 관리: 8(bzClcd), empPk(bzPk)
            let path = remoteURL + 'opr/imgDownload?'
            let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum
            let bzClcd = '&bzClcd=8&'
            let bzPk = 'bzPk=' + this.$parent.$parent.$parent.$parent.sltEmpPk

            let tmp = new Image()
            tmp.src = path+fcltyNum+bzClcd+bzPk+"&a=" + Math.random()
          
            tmp.crossOrigin = 'Anonymous';
            
            let canvas = document.createElement("canvas")
            let context = canvas.getContext("2d")
            let path1 = this

            tmp.onload = function() {
                canvas.width = this.width
                canvas.height = this.height
                context.drawImage(tmp, 0, 0)

                canvas.toBlob((blob) => {
                    let url = URL.createObjectURL(blob)
                
                    path1.imgSrc = url
                    path1.forceRender += 1
                })
            }
        },
        getEmpSignURL: function () {
            let path = remoteURL + 'cmm/eSigImgDownload?'
            let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum
            let bzClcd = '&eSigClcd=9'
            let bzPk = '&bzClcdPk=' + this.empInfo.empPk

            let tmp = new Image()
            tmp.src = path + fcltyNum + bzClcd + bzPk + '&t=' + new Date().getTime()

            tmp.crossOrigin = 'Anonymous';

            let canvas = document.createElement("canvas")
            let context = canvas.getContext("2d")

            let me = { src: '', org: tmp.src }

            tmp.onload = function() {
                canvas.width = this.width
                canvas.height = this.height
                context.drawImage(tmp, 0, 0)

                canvas.toBlob((blob) => {
                    me.src = URL.createObjectURL(blob)
                })

            }

            tmp.onerror = function() {
                me.src = remoteURL + "cmm/eSigImgDownload"
            }

            return me
        },
        cntlGrpBtn: function (obj) {
            if(obj.rtrDt == ''){
                if(this.$moment().isAfter(obj.entDt))
                    this.$parent.$parent.filledBtn[2].disabled = false
                else 
                    this.$parent.$parent.filledBtn[2].disabled = true
                this.$parent.$parent.filledBtn[3].disabled = true
            }
            else{
                if(this.$moment().isAfter(obj.rtrDt))
                    this.$parent.$parent.filledBtn[3].disabled = false
                else
                    this.$parent.$parent.filledBtn[3].disabled = true

                this.$parent.$parent.filledBtn[2].disabled = true
            }

            // 1: 재직, 2: 퇴직, 3: 휴직
            if(obj.wrkStcd == '2'){
                this.$parent.$parent.filledBtn[2].visible = false
                this.$parent.$parent.filledBtn[3].visible = true
            }
            else{
                this.$parent.$parent.filledBtn[2].visible = true
                this.$parent.$parent.filledBtn[3].visible = false
            }
        },
    },

    data: () => ({
        empInfo: null,
        wrkCode: '',
        imgSrc: '',
        signSrc:  { src: '', org: '' },
        forceRender: 0,
    }),
};
</script>