<template>
    <v-sheet class="pa-7" min-width="1100px" max-width="1100px" min-height="500px">
        <v-row class="mb-7 pa-0" no-gutters>
            <v-col cols="8">
                <span class="text-h4 font-weight-bold">계약&nbsp;이력</span>
            </v-col>
            <v-col class="d-flex" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-divider :class="['mt-2', cntrcHisCustomList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['pa-0 greyE01', cntrcHisCustomList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="2">
                <span class="black--text font-weight-medium py-2">계약일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="3">
                <span class="black--text font-weight-medium py-2">계약기간</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">등급</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">본인부담률</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" style="line-height: 1;" cols="1">
                <div>
                    <div class="black--text font-weight-medium txtAlgnMid my-1">수급자</div>
                    <div class="black--text font-weight-medium txtAlgnMid mb-1">서명여부</div>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" style="line-height: 1;" cols="1">
                <div>
                    <div class="black--text font-weight-medium txtAlgnMid my-1">보호자</div>
                    <div class="black--text font-weight-medium txtAlgnMid mb-1">서명여부</div>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" style="line-height: 1;" cols="1">
                <div>
                    <div class="black--text font-weight-medium txtAlgnMid my-1">개인정보</div>
                    <div class="black--text font-weight-medium txtAlgnMid mb-1">동의여부</div>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">노인인권</span>
            </v-col>
            <v-col class="" cols="1">

            </v-col>
        </v-row>
        <v-divider :class="[cntrcHisCustomList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div
            v-if="cntrcHisCustomList.length > 0"
            class="overflow-y-auto d-inline-block" style="width: 100%; height: 402px;">
            <v-row
                class="tableBB pa-0" ref="infoList"
                v-for="(list, i) in cntrcHisCustomList" :key="i"
                no-gutters>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="2">
                    <span class="py-2">{{list.cntrcDt}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="3">
                    <span class="py-2">{{list.cntrcPrdStrt}}&nbsp;&#126;&nbsp;{{list.cntrcPrdEnd}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                    <span class="py-2">{{list.acknwRtng}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                    <span class="py-2">{{list.copayRate}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                    <span class="py-2">{{list.bnfcrSigYn == 1 ? '예' : '아니오'}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                    <span class="py-2">{{list.grdnSigYn == 1 ? '예' : '아니오'}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                    <span class="py-2">{{list.prvcyAgrYn == 1 ? '예' : '아니오'}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                    <span class="py-2">{{list.eldHmnOfrYn == 1 ? '예' : '아니오'}}</span>
                </v-col>
                <v-col class="d-flex justify-center" cols="1">
                    <v-btn :disabled="true" icon>
                        <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div
            v-else
            class="overflow-y-auto d-inline-block" style="width: 100%; height: 402px;">
            <v-row
                class="tableBB pa-0"
                no-gutters>
                <v-col class="txtAlgnMid py-3" cols="12">
                    <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { getBnfcrCntrcHisList } from '../../api/bnfcr.js';

export default {
    name: 'BnfcrCntrcMgmtHist',
        
    components: {
        
    },

    created: function(){
        this.getBnfcrHisList(0, 99)
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           
        })
    },

    computed: {
        getBnMmbrPk(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getTmpObj(){
            return this.$store.getters.getTmpObj
        },
    },

    watch:{
        getBnMmbrPk(value){
            if(value == 0)
                this.cntrcHisCustomList.splice(0)
            else
                this.getBnfcrHisList(0, 99)
            //console.log('change BnMmbrPk: ' + value)
        },
        getTmpObj(value){
            console.log('bnfcrHis Changed: ' + value)
            this.getBnfcrHisList(0, 99)
        },
    },
    
    methods: {
        getBnfcrHisList: function (strtRn, range) {
            getBnfcrCntrcHisList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, 2, strtRn, range)
                .then((response) => ( this.getBnfcrHisListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcHisList : ' + error))
        },

        getBnfcrHisListAfter: function (res) {
            this.bnfcrCntrcHisCnt = 0
            this.bnfcrCntrcHisList.splice(0)
            this.cntrcHisCustomList.splice(0)

            this.bnfcrCntrcHisCnt = res.rnTot
            this.bnfcrCntrcHisList = res.list

            res.list.forEach(items => {
                let obj = {}
                obj.cntrcDt = items.cntntJson.bnfcrCntrc.cntrcDt?.beforeDateFormatDot()
                obj.cntrcPrdStrt = items.cntntJson.bnfcrCntrc.cntrcPrdStrt?.beforeDateFormatDot()
                obj.cntrcPrdEnd = items.cntntJson.bnfcrCntrc.cntrcPrdEnd?.beforeDateFormatDot()
                obj.bnfcrSigYn = items.cntntJson.bnfcrCntrc.bnfcrSigYn
                obj.grdnSigYn = items.cntntJson.bnfcrCntrc.grdnSigYn
                obj.prvcyAgrYn = items.cntntJson.bnfcrCntrc.prvcyAgrYn
                obj.eldHmnOfrYn = items.cntntJson.bnfcrCntrc.eldHmnOfrYn

                if(typeof items.cntntJson.bnfcrCntrc.acknwRtng == 'undefined')
                    obj.acknwRtng = ''
                else if(items.cntntJson.bnfcrCntrc.acknwRtng == '99')
                    obj.acknwRtng = '등급외'
                else
                    obj.acknwRtng = items.cntntJson.bnfcrCntrc.acknwRtng + '등급'
                if(typeof items.cntntJson.bnfcrCntrc.copayRate == 'undefined')
                    obj.copayRate= ''
                else{
                    this.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                        if(items.cntntJson.bnfcrCntrc.copayRate == cd.valcd)
                            obj.copayRate = cd.valcdnm
                    });
                }

                obj.gvrnmSprt

                this.cntrcHisCustomList.push(obj)
            });

        },

        onInfoSingle: function (index) {
            // this.$refs.infoList.forEach(element => {
            //     element.style="border-bottom: 1px solid #dbdbdb !important;"
            // });

            // this.$refs.infoList[index].style="border-bottom: 1px solid #dbdbdb; background: #ebf6fe !important;"

            this.$parent.$parent.$parent.$refs.bnfcrCntInf.hisBnfcrCntrcChanger(this.bnfcrCntrcHisList[index])
            // this.$store.commit('setTmpObj', this.bnfcrCntrcHisList[index])
            // this.$emit('objChanger', true)
            // this.$parent.$parent.$parent.outlinedBtn[1].disabled = true
            this.onClose()
        },

        onClose: function () {
            this.$emit('madalState', 6)
        },
    },

    data: () => ({
        cntrcHisCustomList: [],
        bnfcrCntrcHisCnt: 0,
        bnfcrCntrcHisList: [],   //mdfDt변경일, mfdRsn변경사유, mdfr작성자
    }),
};
</script>
<style>
    .rowReactive:hover { background: #ebf6fe !important; }
    .rowFixed { background: #ebf6fe !important; }
</style>