<template>
    <div class="d-flex justify-center align-center">
        <v-btn class="mr-1" color="btnBrdrColor" x-small outlined @click="isPrev"
            style="background-color: white; width:26px;" height="26">
            <span class="black--text">
                <v-icon>mdi-chevron-left</v-icon>
            </span>
        </v-btn>
        <v-btn class="" color="btnBrdrColor" x-small outlined @click="isNext"
            style="background-color: white; width:26px;" height="26">
            <span class="black--text">
                <v-icon>mdi-chevron-right</v-icon>
            </span>
        </v-btn>
        <span class="text-h5 font-weight-bold topTitleColor--text mx-3 pb-1">{{ dotDate }}</span>
        <v-menu
            ref="menu" v-model="menu"
            :close-on-content-click="false" :return-value.sync="date"
            transition="scale-transition" offset-y min-width="auto" dense>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="monthBtnPicker pa-0" style="min-width: 32px !important; background-color: white;"
                    v-bind="attrs" v-on="on" color="btnBrdrColor" height="26" small outlined>
                    <v-icon class="black--text" style="margin-top: 0px;" small>mdi-calendar-month</v-icon>
                </v-btn>
            </template>
            <v-date-picker
                v-model="date"
                class="exMnthComp" locale="en-US" type="month"
                :month-format="getMonth" :header-date-format="getTitle"
                @click:month="datePickClose"
                no-title scrollable>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'expandMonthPicker',

    props : {
        prtDate: { type: String, default: '' }
    },
    
    components: {
    
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)

            if(this.prtDate == '')
                this.date = timezoneDate.toISOString().split('T',2)[0].substring(0, 7)
            else if(this.prtDate != '')
                this.date = this.prtDate.substr(0, 4) + '-' + this.prtDate.substr(4, 2)
        })
    },

    watch: {
        'date': function() {
            this.dotDate = this.beforeDateFormatDot(this.afterDateFormatHyp(this.date))
            this.$emit('nowYearWithMonth', this.afterDateFormatHyp(this.date))
        },
        'prtDate': function() {
            this.date = this.prtDate.substr(0, 4) + '-' + this.prtDate.substr(4, 2)
            this.$emit('nowYearWithMonth', this.afterDateFormatHyp(this.date))
        },
    },

    methods: {
        getTitle: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)

            if(date.length == 4)
                return new Date(date).getFullYear() + '년'
            else
                return new Date(date).getFullYear() + '년 ' + monthName[i]
        },
        getMonth: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)
            return monthName[i]
        },
        isPrev: function (){
            //let date = new Date().toISOString().split('T',2)
            let prevDt = new Date(this.date+'-'+'01')
            let calc = new Date(prevDt.setMonth(prevDt.getMonth() -1 ))
            this.date = calc.getFullYear() + '-' + ('00'+(calc.getMonth() +1).toString()).slice(-2)
        },
        isNext: function (){
            let nextDt = new Date(this.date+'-'+'01')
            let calc = new Date(nextDt.setMonth(nextDt.getMonth() +1 ))
            this.date = calc.getFullYear() + '-' + ('00'+(calc.getMonth() +1).toString()).slice(-2)
        },
        datePickClose: function () {
            this.menu = false
            this.$refs.menu.save(this.date)
        },
        afterDateFormatHyp: function (date) {
            let result = ''
            if (date != null && date != '') {
                let splitDate = date.split('-', 2)
                splitDate.forEach((txt) => {
                    result += txt
                });
            }
            return result
        },
        beforeDateFormatDot: function (date) {
            let result = ''
            if (date != null && date != '') {
                result = date.substr(0, 4) + '.' + date.substr(4, 2)
            }
            return result
        },
    },

    data: () => ({
        menu: false,
        date: '',
        dotDate: '',
    }),
};
</script>

<style>
    .exMnthComp .v-picker__body div .v-date-picker-header .v-date-picker-header__value div button { font-size: 16px; }
    .exMnthComp .v-picker__body div .v-date-picker-table table tbody tr td button div { font-size: 14px; }
</style>