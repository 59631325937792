<template>
    <div class="">
        <v-divider></v-divider>
        <v-data-table
            class="msclnTable tblCelFx" height="634" locale="ko"
            :headers="headers" :items="doseRecList" :loading="doseRecLoader"
            :page.sync="page" :items-per-page="itemsRows" @page-count="pageCount = $event"
            fixed-header hide-default-footer hide-default-header dense>
            <template v-slot:header="{ props }">
                <thead>
                    <tr>
                        <th :class="props.headers[0].class" :style="props.headers[0].style" rowspan="2">
                            {{ props.headers[0].text }}
                        </th>
                        <th :class="props.headers[1].class" :style="props.headers[1].style" rowspan="2">
                            {{ props.headers[1].text }}
                        </th>
                        <th :class="props.headers[2].class" :style="props.headers[2].style" rowspan="2">
                            {{ props.headers[2].text }}
                        </th>
                        <th :class="props.headers[3].class" :style="props.headers[3].style" rowspan="2">
                            {{ props.headers[3].text }}
                        </th>
                        <th class="clmsHeaderCommon tableBR" style="" colspan="2" rowspan="1">
                            <span class="black--text">아침</span>
                        </th>
                        <th class="clmsHeaderCommon tableBR" style="" colspan="2" rowspan="1">
                            <span class="black--text">점심</span>
                        </th>
                        <th class="clmsHeaderCommon tableBR" style="" colspan="2" rowspan="1">
                            <span class="black--text">저녁</span>
                        </th>
                        <th :class="props.headers[6].class" :style="props.headers[6].style" rowspan="2">
                            {{ props.headers[6].text }}
                        </th>
                        <th :class="props.headers[7].class" :style="props.headers[7].style" rowspan="2">
                            {{ props.headers[7].text }}
                        </th>
                    </tr>
                    <tr>
                        <th :class="props.headers[4].class" :style="props.headers[4].style" rowspan="1">
                            {{ props.headers[4].text }}
                        </th>
                        <th :class="props.headers[5].class" :style="props.headers[5].style" rowspan="1">
                            {{ props.headers[5].text }}
                        </th>
                        <th :class="props.headers[4].class" :style="props.headers[4].style" rowspan="1">
                            {{ props.headers[4].text }}
                        </th>
                        <th :class="props.headers[5].class" :style="props.headers[5].style" rowspan="1">
                            {{ props.headers[5].text }}
                        </th>
                        <th :class="props.headers[4].class" :style="props.headers[4].style" rowspan="1">
                            {{ props.headers[4].text }}
                        </th>
                        <th :class="props.headers[5].class" :style="props.headers[5].style" rowspan="1">
                            {{ props.headers[5].text }}
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item }'>
                <tr>
                    <td class="tableBR tableBB clmsBodyCommon py-2">
                        <span class="cmmFontSizeOne">{{ $moment(item.doseDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon overflow-text-hidden px-2">
                        <span class="cmmFontSizeOne">{{ item.drugNm }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.drugAmt }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon overflow-text-hidden">
                        <span class="cmmFontSizeOne">{{ item.drugMthd }}</span>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsBrkfsBf == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsBrkfsAf == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsLunchBf == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsLunchAf == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsDinerBf == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsDinerAf == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid tableBR tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsBedBf == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                    <td class="txtAlgnMid tableBB clmsBodyCommon">
                        <v-icon v-if="item.dsNeed == '1'" color="blue002" size="20">mdi-check-circle-outline</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-divider class="mb-2"></v-divider>
        <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="10">
        </v-pagination>
    </div>
</template>

<script>
import { selDoseRecList } from '../../api/bnftrcrd.js';

export default {
    name: 'NsgRcrdSttsTab3',

    props: {
        
    },

    components: {
 
    },

    
    created: function(){
        
    },
    
    mounted: function () {
        this.$nextTick(function () {
        
        })
    },
    
    computed: {
        getBnMmbrPk: function () {
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getSltYYMM: function () {
            return this.$parent.$parent.$parent.$parent.sltYymm
        },
    },
    
    watch: {
        getBnMmbrPk: function ( v ) {
            if(v > 0)
                this.getDoseRecList()
            else
                this.doseRecList.splice(0)
        },
        getSltYYMM: function (yymm) {
            if(yymm != '' && this.$store.getters.getBnfcrState.bnmmbr > 0)
                this.getDoseRecList()
        },
        'doseRecList': function () {
            this.$parent.$parent.$parent.$parent.totCnt.splice(2, 1, this.doseRecList.length)
        },
    },

    methods: {
        getDoseRecList: function () {
            this.doseRecList.splice(0)
            this.doseRecLoader = true

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.inqYmdStart = this.$moment(this.$parent.$parent.$parent.$parent.sltYymm, 'YYYYMM').startOf('months').format('YYYYMMDD')
            obj.inqYmdEnd = this.$moment(this.$parent.$parent.$parent.$parent.sltYymm, 'YYYYMM').endOf('months').format('YYYYMMDD')
            
            selDoseRecList(obj)
                .then((response) => ( this.doseRecListRst(response.data)))
                .catch((error) => console.log('connect error /bnftrcrd/selDoseRecList : ' + error))
        },
        doseRecListRst: function(res){
            if(res.length > 0)
                this.doseRecList = res
            else
                this.doseRecList.splice(0)

            this.doseRecLoader = false
        },
    },
    data: () => ({
        page: 1,
        pageCount: 0,
        itemsRows: 15,
        doseRecLoader: false,
        doseRecList: [],
        headers: [
            { text: '투약일자', value: 'doseDt', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 90px !important;' },
            { text: '투약명', value: 'drugNm', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 180px !important;' },
            { text: '투약량', value: 'drugAmt', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 80px !important;' },
            { text: '투약방법', value: 'drugMthd', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 80px !important;' },
            { text: '식전', value: '', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 40px !important;' },
            { text: '식후', value: '', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 40px !important;' },
            { text: '취침전', value: 'dsBedBf', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 50px !important;' },
            { text: '필요시', value: 'dsNeed', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon', style: 'width: 50px !important;' },
            {},
            {},
            {},
            {}
        ],
    }),
};
</script>