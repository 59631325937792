<template>
    <v-sheet>     
        <v-form ref="tabform" lazy-validation>   
            <div class="d-flex mt-3">
                <div style="width:50%">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">월급 설정</span>
                    </div>
                    <div class="d-flex pa-0 ma-0 mt-1" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">                                
                            <span class="black--text font-weight-medium">공휴일 유급휴일 여부</span>                    
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;"> 
                            <v-checkbox class="clmsHeaderCheck" v-model="items13.itmVal" value="1" hide-details></v-checkbox>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">근로자의날 유급휴일 여부</span>
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;"> 
                            <v-checkbox class="clmsHeaderCheck" v-model="items14.itmVal" value="1" hide-details></v-checkbox>
                        </div>        
                    </div>
                    <div class="mt-3 d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">시급 설정</span>
                    </div>
                    <div class="mt-1">
                        <div class="d-flex pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">                                
                                <span class="black--text font-weight-medium">통상시급</span>                    
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:70%;border-left: 1px solid #dbdbdb !important;"> 
                                <span class="d-inline-block txtfldRT" style="width: 110px;">
                                    <v-text-field v-model="items8.amt" type="number" min="0" color="blue" hide-details outlined dense></v-text-field>
                                </span>
                            </div>                                    
                        </div>                        
                        <div class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">                                
                                <span class="black--text font-weight-medium">공휴일 유급휴일 여부</span>                    
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;"> 
                                <v-checkbox class="clmsHeaderCheck" v-model="items15.itmVal" value="1" hide-details></v-checkbox>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">근로자의날 유급휴일 여부</span>
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;"> 
                                <v-checkbox class="clmsHeaderCheck" v-model="items16.itmVal" value="1" hide-details></v-checkbox>
                            </div>        
                        </div>
                        <div class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">                                
                                <span class="black--text font-weight-medium">토요일 휴일 가산여부</span>                    
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;"> 
                                <v-checkbox class="clmsHeaderCheck" v-model="items17.itmVal" value="1" hide-details></v-checkbox>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">일요일 휴일 가산여부</span>
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;"> 
                                <v-checkbox class="clmsHeaderCheck" v-model="items18.itmVal" value="1" hide-details></v-checkbox>
                            </div>        
                        </div>
                    </div>
                </div>
                <div class="ml-2" style="width:50%">
                    <!-- <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">장기근속장려금</span>
                    </div>
                    <div class="d-flex pa-0 ma-0 mt-1" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                        
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:100%;"> 
                            <span style="width:30px">
                                <v-checkbox class="clmsHeaderCheck" v-model="items19.itmVal"  value="1" hide-details></v-checkbox>
                            </span>
                            <span class="d-inline-block" style="width:590px">
                                장기근속장려금에서 기관부담금 및 퇴직적립금 제외한 금액을 직원 급여로 책정합니다.
                            </span>
                        </div>        
                    </div> -->
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">기관부담금 설정</span>
                    </div>
                    <div class="d-flex pa-0 ma-0 mt-1" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">                                
                            <span class="black--text font-weight-medium">산재보험요율</span>                    
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:70%;border-left: 1px solid #dbdbdb !important;"> 
                            <span class="d-inline-block txtfldRT" style="width: 110px;">
                                <v-text-field v-model="items20.rto" type="number" min="0" color="blue" hide-details outlined dense></v-text-field>
                            </span>
                            <span class="pl-2">&#37;</span>
                        </div>        
                    </div>
                    <div class="mt-3 d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">급여일 설정</span>
                    </div>
                    <div class="d-flex pa-0 ma-0 mt-1" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">                                
                            <span class="black--text font-weight-medium">급여일 설정</span>                    
                        </div>
                        <div class="d-flex justify-start align-center pa-1 ma-0" style="width:70%;border-left: 1px solid #dbdbdb !important;"> 
                            <span>당월 근무일정에 대한 급여일</span>
                            <span class="ml-2" style="width:100px">
                                <v-select 
                                    v-model="items21.itmVal"                               
                                    :items="vCd99"
                                    item-text="valcdnm"
                                    item-value="valcd"
                                    dense hide-details outlined
                                    :rules="[rules.selectCheck]"
                                />
                            </span>
                            <span class="ml-2 " style="width:50px">
                                <v-text-field v-model="items22.itmVal" color="blue" hide-details outlined dense></v-text-field>
                            </span>
                            <span>일</span>
                        </div>        
                    </div>
                </div>
            </div>
        </v-form>
    </v-sheet>
</template>
<script>
import { getMultiStcd } from '../../api/index.js';
import { selFcltyWgeItmList, insMultiFcltyWgeItm } from '../../api/impExp.js';

export default {
    name: 'EmpWageInfTab2',
    
    components: {  
        
    },

    props : {
    },

    computed: {
        
    },

    created: function(){  
    },

    mounted: function(){
        this.$nextTick(function () {           
        })
    },

    watch: {
        
    },

    methods: {       
        onLoad:function(){
            this.getFcltyWgeItmList()
        },
        onSave:function(){
            if(this.$refs.tabform.validate()){

                let obj = {
                    list:[],
                }

                this.items8.fcltyNum = this.$store.getters.getFcltyNum
                this.items8.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items8)

                // this.items11.fcltyNum = this.$store.getters.getFcltyNum
                // this.items11.userNm = this.$store.getters.getUserNm
                // obj.list.push(this.items11)

                this.items13.fcltyNum = this.$store.getters.getFcltyNum
                this.items13.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items13)

                this.items14.fcltyNum = this.$store.getters.getFcltyNum
                this.items14.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items14)

                this.items15.fcltyNum = this.$store.getters.getFcltyNum
                this.items15.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items15)

                this.items16.fcltyNum = this.$store.getters.getFcltyNum
                this.items16.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items16)

                this.items17.fcltyNum = this.$store.getters.getFcltyNum
                this.items17.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items17)

                this.items18.fcltyNum = this.$store.getters.getFcltyNum
                this.items18.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items18)

                // this.items19.fcltyNum = this.$store.getters.getFcltyNum
                // this.items19.userNm = this.$store.getters.getUserNm
                // obj.list.push(this.items19)

                this.items20.fcltyNum = this.$store.getters.getFcltyNum
                this.items20.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items20)

                this.items21.fcltyNum = this.$store.getters.getFcltyNum
                this.items21.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items21)

                this.items22.fcltyNum = this.$store.getters.getFcltyNum
                this.items22.userNm = this.$store.getters.getUserNm
                obj.list.push(this.items22)

                insMultiFcltyWgeItm(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/insMultiFcltyWgeItm : ' + error))
            }
        },
        onSaveAfter: function (res) {
            this.$emit('trigger', res)

            if(res.statusCode == 200){                                                                
                this.onLoad()
            }
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd78 = res.vCd78
            this.vCd79 = res.vCd79
            this.vCd80 = res.vCd80
        },        
        getFcltyWgeItmList: function () {
            selFcltyWgeItmList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getFcltyWgeItmListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selFcltyWgeItmList : ' + error))
        },
        getFcltyWgeItmListAfter: function (res) {
            
            this.items8 = Object.assign({})
            this.items11 = Object.assign({})
            this.items13 = Object.assign({})
            this.items14 = Object.assign({})
            this.items15 = Object.assign({})
            this.items16 = Object.assign({})
            this.items17 = Object.assign({})
            this.items18 = Object.assign({})
            this.items19 = Object.assign({})
            this.items20 = Object.assign({})
            this.items21 = Object.assign({})
            this.items22 = Object.assign({})

            res.forEach(item => {                
                switch (item.valClcd) {
                    case '8':
                        this.items8 = item
                        break;
                    case '11':
                        this.items11 = item
                        break;
                    case '13':
                        this.items13 = item
                        break;
                    case '14':
                        this.items14 = item
                        break;
                    case '15':
                        this.items15 = item
                        break;
                    case '16':
                        this.items16 = item
                        break;
                    case '17':
                        this.items17 = item
                        break;
                    case '18':
                        this.items18 = item
                        break;
                    case '19':
                        this.items19 = item
                        break;
                    case '20':
                        this.items20 = item
                        break;
                    case '21':
                        this.items21 = item
                        break;
                    case '22':
                        this.items22 = item
                        break;
                    default:
                        break;
                }
            });
        },
    },

    data: () => ({
        items8:{},
        items11:{},
        items13:{},
        items14:{},
        items15:{},
        items16:{},
        items17:{},
        items18:{},
        items19:{},
        items20:{},
        items21:{},
        items22:{},
        vCd99:[{valcdnm:'익월',valcd:'1'},{valcdnm:'당월',valcd:'2'}],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>