<template>
    <v-row class="pa-0" no-gutters>
        <v-col cols="12">
            <span class="black--text">{{ thwInf }}</span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ExtLogin',

    components: {

    },

    computed:{

    },
    created: function(){

    },
    mounted: function(){
        this.$nextTick(function () {
            this.thwInf = '잘못된 접근입니다.'
        })
    },

    methods: {
        

    },

    data: () => ({
        thwInf: '',
        authInfo: { fcltyNum: '', id: '', passwd: '' },
        iv: [ 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00 ],
    }),
};
</script>