<template>
    <v-sheet class="mt-2 pa-0">
        <v-form ref="extVlntrInfo" lazy-validation>
            <v-divider></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">봉사자명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL tableBR py-1" cols="5">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field  v-model="empInfo.name" :rules="[rules.required]"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">성별</span>
                </v-col>
                <v-col class="tableBL px-1" cols="3">
                    <span class="">
                        <v-radio-group class="pl-2 pt-1" v-model="empInfo.gndr" hide-details row>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="남" value="M"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="여" value="F"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">연락처</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="10">
                    <span class="d-inline-block" style="width: 80px;">
                        <v-text-field v-model="empInfo.celphn_1"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex align-center px-1">
                        <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 80px;">
                        <v-text-field v-model="empInfo.celphn_2"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex align-center px-1">
                        <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 80px;">
                        <v-text-field v-model="empInfo.celphn_3"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">계약기간</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="5">
                    <span class="d-inline-block" style="width: 133px;">
                        <CmmDateComp
                            v-model="empInfo.entDt" :required="false">
                        </CmmDateComp>
                    </span>
                    <span class="d-flex align-center mx-2">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 133px;">
                        <CmmDateComp
                            v-model="empInfo.rtrDt" :required="false">
                        </CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">소속</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="3">
                    <span class="d-inline-block" style="width: 80px;">
                        <v-select
                            class="wrkSelect"
                            v-model="empInfo.afltnClcd" :key="forceRender"
                            :items="extSrtt" item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 90px;" v-if="empInfo.afltnClcd == '2'">
                        <v-text-field v-model="empInfo.afltnNm"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL py-1" cols="10">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field v-model="empInfo.rmrks"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" height="26" @click="preExtEmpsInfo()" samll rounded>
                    <v-icon small>mdi-content-save-outline</v-icon>
                    <span style="font-size: 1rem;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { insExtEmp } from '../../api/emp.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'ExtVlntr',

    props : {
   
    },
        
    components: {
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        setEmpInfo: function (obj) {
            insExtEmp(obj)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/insExtEmp : ' + error))
        },
        asyncResultChk: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.getExtEmpList()
                this.clearForm()
                this.$parent.$parent.$parent.$parent.onClose()
            }
            else
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preExtEmpsInfo: function () {
            let obj = Object.assign({}, this.empInfo )
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm

            if(obj.entDt != '')
                obj.entDt = obj.entDt?.afterDateFormatHyp()
            if(obj.rtrDt != '')
                obj.rtrDt = obj.rtrDt?.afterDateFormatHyp()

            let chk = this.$refs.extVlntrInfo.validate()
            if(chk)
                this.setEmpInfo(obj)
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        clearForm: function () {
            this.empInfo = Object.assign({
                empPk: 0, empWgePk: 0, empEtcPk: 0,
                name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', wgeTyp: '3', wage: 0, jobCd: '17', pstnCd: '6',
                celphn_1: '', celphn_2: '', celphn_3: '', etcClsfc1Cd: '2', etcClsfc2Cd: '5', rmrks: '', gndr: 'M', wrkStcd: '1',
            })

            this.forceRender += 1
        },
    },

    data: () => ({
        menu: [false, false,],
        extSrtt: [
            { valcd: '1', valcdnm: '개인' },
            { valcd: '2', valcdnm: '단체' },
        ],
        empInfo: {
            empPk: 0, empWgePk: 0, empEtcPk: 0,
            name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', jobCd: '17', pstnCd: '6',
            celphn_1: '', celphn_2: '', celphn_3: '', rmrks: '', gndr: 'M', wrkStcd: '1',
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            pwdSizeCheck: value => (value && value.length > 3) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
        },
        forceRender: 0,
    }),
};
</script>