<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" @click="onClose">                
        <div class="pa-2 rounded-lg" style="background:#e7e3e3" rounded="lg">                
            <div class="px-3 py-2" v-html="msg"></div>
            <div class="pa-2">
                <div style="word-break: break-all;cursor: pointer;" @click="onLink">{{lnkUrl}}</div>
            </div>
        </div>
    </v-sheet>
</template>
<script>
import { selESndMsgList } from '../../api/othr.js'

export default {
    name: 'FmlyLeterSndSmsViw',
    
    components: {  
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){      
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        
    },

    methods: {  
        onClose: function () {
            this.$emit('modalClose')
        },
        onLoad:function(item){
            this.lnkUrl = item.lnkUrl
            this.getESndMsgList(item)
        },
        getESndMsgList:function(item){              
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eDocClcd : '5',
                itmCtt3:item.cntnt,
                sndMda:'2',
            }
            selESndMsgList(obj)
                .then((response) => ( this.getESndMsgListAfter(response.data) ))
                .catch((error) => console.log('connect error /othr/selESndMsgList : ' + error))
        },
        getESndMsgListAfter:function(res){
            if(res.length > 0){
                res.forEach(item => {
                    switch (item.itmClcd) {
                        case '1':
                            
                            break;
                        case '2':
                            this.msg = item.itmCntnt?.replaceAll("\\n", "<br />")
                            break;
                        case '3':
                            this.btnNm = item.itmCntnt
                            break;
                        default:
                            break;
                    }
                    
                });
            }
        },
        onLink:function(){
            let ttl = '가정통신문 미리보기 서비스 미리보기'
            let loc = (document.body.offsetWidth / 2) - (200 / 2)
            let posn = 'left=' + loc.toString() + ', top=0, '
            let options = 'width=380, height=740, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no'
            
            window.open(this.lnkUrl, ttl, posn+options)

            // this.windowRef = window.open(this.btnLink, "_blank");         
            // this.windowRef = null
        },
    },
    data: () => ({ 
        sObj:{},
        items:{},
        clcd:0,
        lnkUrl:'',
        msg:'',
        btnNm:'상세보기',
        btnLink:'',
        windowRef: null,
    }),
};
</script>