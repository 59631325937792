<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">본인부담금&nbsp;잔여금&nbsp;환불</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-divider class="mt-5"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="" cols="6">
                <v-row class="pa-0 tableBB" no-gutters>
                    <v-col class="greyE01 txtAlgnMid py-1" cols="5">
                        <span class="black--text font-weight-medium py-1">환불예정금액</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL tableBR pa-1" cols="7">
                        <span class="black--text">{{ prtCopayDpsStsInfo.rmnPrpym?.toLocaleString('ko-KR') }}&nbsp;원</span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 txtAlgnMid py-1" cols="5">
                        <span class="black--text font-weight-medium py-1">미납금액</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBL tableBR pa-1" cols="7">
                        <span class="black--text">{{ prtCopayDpsStsInfo.unpdAmt?.toLocaleString('ko-KR') }}&nbsp;원</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">보호자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBL" style="line-height: 1.2;" cols="5">
                <div>
                    <div v-for="(grdn, i) in prtCopayDpsStsInfo.grdName" :key="i">
                        <span class="black--text">{{ grdn }}</span>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-form ref="dpsRfnd" lazy-validation>
            <v-divider class="mt-5"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">처리자</span>
                </v-col>
                <v-col class="d-flex align-center tableBR tableBL" cols="4">
                    <span class="d-inline-block pl-3 pr-1 py-2" style="width: 100px;">
                        <v-text-field
                            v-model="offcr" :rules="[rules.required]"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block">
                        <v-btn
                            class="white--text darken-1" color="bckgrColor" height="28" width="20" min-width="20"
                            @click="menu.splice(0, 1, true)"
                            depressed>
                            <v-icon small>mdi-account-plus</v-icon>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">환불일</span>
                </v-col>
                <v-col class="d-flex align-center tableBL" cols="4">
                    <span class="white d-inline-block py-1 pl-2" style="width: 147px;">
                        <CmmDateComp v-model="dpsDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">내용</span>
                </v-col>
                <v-col class="px-3 py-2 tableBL" cols="10">
                    <v-textarea
                        class="rmrksArea"
                        v-model="rmrks"
                        :rules="[rules.required]"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="pa-0 mt-4" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" height="30"
                    @click="preCopayDpsRfnd()"
                    samll rounded>
                    <v-icon small>mdi-content-save-outline</v-icon>
                    <span style="font-size: 1rem;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" content-class="round" max-width="800" persistent eager>
            <EmpSelector @isClose="menu.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="dpsDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

import { updCopayDpsRfnd } from '../../api/bnfcr.js';

export default {
    name: 'PrpymPymntRefund',

    props : {
        prtCopayDpsStsInfo : { type: Object, default: new Object() }
    },
        
    components: {
        EmpSelector,
        CmmDateComp,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.dpsDt = this.$moment().format('YYYY-MM-DD')
        })
    },

    computed: {
   
    },

    watch:{

    },
    
    methods: {
        setCopayDpsRfnd: function (obj){
            updCopayDpsRfnd(obj)
                .then((response) => ( this.setCopayDpsRfndAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updCopayDpsRfnd : ' + error))
        },
        setCopayDpsRfndAfter: function (res){
            if(res.statusCode == 200) {
                this.rmrks = ''
                this.offcr = ''
                this.dpsDt = ''
                this.$parent.$parent.$parent.$parent.$parent.$parent.reloadList()
                this.$parent.$parent.$parent.$parent.getCopayDpsList()
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.onClose()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preCopayDpsRfnd: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltBnfcrPk
            obj.rmrks = this.rmrks
            obj.offcr = this.offcr
            obj.dpsClcd = '3'
            obj.userNm = this.$store.getters.getUserNm

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.dpsDt))
                obj.dpsDt = this.$moment(this.dpsDt, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                this.dpsDt = ''

            if(this.$refs.dpsRfnd.validate())
                this.setCopayDpsRfnd(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info'])
        },
        getEmpInfo: function (obj) {
            this.offcr = obj[0].name
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [false,],
        rmrks: '',
        offcr: '',
        dpsDt: '',
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .rmrksArea .v-input__control .v-input__slot { padding: 0 16px !important; }
    .rmrksArea .v-input__control .v-input__slot .v-text-field__slot textarea { margin-bottom: 6px; }
</style>