<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]"
                class="pa-0" style="top: 70px;"
                min-width="200" max-width="500" rounded="pill" transition="slide-y-transition"
                :color="snackOptions[3]" :timeout="snackOptions[1]" :value="true"
                absolute top>
                <div class="txtAlgnMid pl-4 pr-2">
                    <span class="text-h6 white--text font-weight-bold">{{ snackOptions[2] }}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">출퇴근&nbsp;관리</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-8 pa-0" no-gutters>
            <v-col class="pr-7" style="position: relative;" cols="3">
                <div class="mb-4">
                    <span class="text-h5 font-weight-bold blue002--text">{{ $store.getters.getUserNm }}</span>
                    <span class="text-h5 font-weight-bold ml-2">{{ tgtObj.scheInf }}</span>
                    <!-- 출근전  근무중  근무종료 -->
                </div>
                <v-divider class="mb-4"></v-divider>
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold ml-3">근무&nbsp;일정</span>
                </v-row>
                <v-row
                    v-for="(arr, i) in wrkList.filter( v => v.wrkTypCd != '2' )" :key="i"
                    class="pa-0 align-center mt-1 pl-5" no-gutters>
                    <span class="text-h6 font-weight-bold">{{ arr.esWrkTmHhStrt }}&#58;{{ arr.esWrkTmMmStrt }}</span>
                    <span class="d-flex align-center ml-1 mr-1">
                        <v-icon class="black--text" size="16">mdi-tilde</v-icon>
                    </span>
                    <span class="text-h6 font-weight-bold">{{ arr.esWrkTmHhEnd }}&#58;{{ arr.esWrkTmMmEnd }}</span>
                </v-row>
                <v-row v-if="wrkList.filter( v => v.wrkTypCd != '2' ).length == 0" class="mt-1 pl-5" no-gutters>
                    <span class="text-h6 font-weight-bold">근무일정이 없습니다.</span>
                </v-row>
                <v-divider class="my-5"></v-divider>
                <v-row class="pa-0 align-center" no-gutters>
                    <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold ml-3">휴가&nbsp;일정</span>
                </v-row>
                <v-row
                    v-for="(arr, j) in wrkList.filter( v => v.wrkTypCd == '2' )" :key="wrkList.length + j"
                    class="pa-0 align-center mt-1 pl-5" no-gutters>
                    <span class="text-h6 font-weight-bold">{{ arr.esWrkTmHhStrt }}&#58;{{ arr.esWrkTmMmStrt }}</span>
                    <span class="d-flex align-center ml-1 mr-1">
                        <v-icon class="black--text" size="16">mdi-tilde</v-icon>
                    </span>
                    <span class="text-h6 font-weight-bold">{{ arr.esWrkTmHhEnd }}&#58;{{ arr.esWrkTmMmEnd }}</span>
                </v-row>
                <v-row v-if="wrkList.filter( v => v.wrkTypCd == '2' ).length == 0" class="mt-1 pl-5" no-gutters>
                    <span class="text-h6 font-weight-bold">휴가일정이 없습니다.</span>
                </v-row>
                <v-row class="pa-0 align-center mt-12" no-gutters>
                    <v-btn
                        v-if="tgtObj.scheInf == '출근전'"
                        @click="preEmpAtndDt()"
                        class="py-5" width="100%" color="#2a9bed">
                        <span class="white--text" style="font-size: 1.3rem;">출근하기</span>
                    </v-btn>
                    <v-btn
                        v-else-if="tgtObj.scheInf == '근무중'"
                        @click="preEmpAtndDt()"
                        class="py-5" width="100%" color="#059006">
                        <span class="white--text" style="font-size: 1.3rem;">퇴근하기</span>
                    </v-btn>
                    <v-btn
                        v-else-if="tgtObj.scheInf == '휴가' && atndList.findIndex( v => v.atndYn == '1' || v.leavYn == '1' ) == -1"
                        @click="preEmpAtndDt()"
                        class="py-5" width="100%" color="#9b83d2">
                        <span class="white--text" style="font-size: 1.3rem;">휴가확인</span>
                    </v-btn>
                </v-row>
                <!-- {{ wrkList }} -->
                <div
                    class="d-inline-block pr-7"
                    style="width: 100%; position: absolute; bottom: 4px; left: 50%; transform: translateX(-50%);">
                    <CsmClock class="mt-4"></CsmClock>
                </div>
            </v-col>
            <v-col class="" cols="9">
                <div class="" style="position: relative; z-index: 4;">
                    <span class="" style="position: absolute; right: 14px; top: 7px; z-index: 4;">
                        <v-icon
                            class="mr-1" style="padding-right: 2px;"
                            size="16">
                            mdi-circle-outline
                        </v-icon>
                        <span class="mr-3" style="font-size: 13px;">출근전</span>
                        <v-icon
                            class="mr-1" style="padding-right: 2px;"
                            color="blue001" size="16">
                            mdi-circle-slice-4
                        </v-icon>
                        <span class="mr-3" style="font-size: 13px;">근무중</span>
                        <v-icon
                            class="mr-1" style="padding-right: 2px;"
                            color="green" size="16">
                            mdi-circle
                        </v-icon>
                        <span class="" style="font-size: 13px;">근무종료</span>
                    </span>
                </div>
                <CmmClndr
                    ref="cmmClndr"
                    :clndrHght="'622'" :hdrTyp="'splView'" :vsbYn="savBtn" :hdrDtYn="'N'"
                    :intlDt="$moment().format('YYYYMM')" :clndrList="wrkSchdlList"
                    @chgDtAct="getPickerDate">
                </CmmClndr>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import CsmClock from '../commons/CsmClock.vue';
import CmmClndr from '../commons/CmmClndr.vue';

import { selEmpAtndList, selEmpAtndYnList, insEmpAtndDt } from '../../api/emp.js';
import { selCmnEmpInfo } from '../../api/cmm.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'CmtngRcrdMdl',

    props : {
   
    },
        
    components: {
        CsmClock,
        CmmClndr,
    },

    created: function(){
        this.getCode(['73', '107'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCmnEmpInfo( this.$store.getters.getMmbrPk )
        })
    },

    computed: {

    },

    watch:{
        'tgtObj.empPk': function () {
            if(this.tgtObj.empPk != 0){
                this.getEmpAtndList(this.tgtObj.empPk, this.$moment().format('YYYYMM'))
                this.getEmpAtndYnList(this.tgtObj.empPk, this.$moment().format('YYYYMMDD'))
            }
            else
                this.clrData()
        },
    },
    
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getCmnEmpInfo: function (pk) {
            this.clrData()

            selCmnEmpInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.empInfRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selCmnEmpInfo : ' + error))
        },
        // 직원 근무현황 다건 조회
        getEmpAtndList: function (pk, yymm) {
            this.savBtn = true
            this.wrkList.splice(0)

            selEmpAtndList(this.$store.getters.getFcltyNum, pk, yymm, null)
                .then((response) => ( this.empAtndListRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAtndList : ' + error))
        },
        // 직원 출퇴근 여부 조회
        getEmpAtndYnList: function (pk, ymd) {
            this.atndList.splice(0)
            this.tgtObj.mixTyp = '3'
            this.tgtObj.scheInf = ''

            selEmpAtndYnList(this.$store.getters.getFcltyNum, pk, ymd)
                .then((response) => ( this.empAtndYnListRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAtndYnList : ' + error))
        },
        setEmpAtndDt: function (pk, dt, atnd, leav) {
            insEmpAtndDt(this.$store.getters.getFcltyNum, pk, dt, atnd, leav, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpAtnd : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd73 = res.vCd73
            this.vCd107 = res.vCd107
        },
        empInfRst: function (res) {
            if(Object.keys(res).length > 0){
                this.tgtObj.empPk = res.empPk
                this.tgtObj.mmbrPk = res.mmbrPk
                this.tgtObj.jobCd = res.jobCd
                this.tgtObj.name = res.name
            }
            else
                this.snackControll([true, 5000, '직원 정보 조회에 실패했습니다.', 'error'])
        },
        empAtndListRst: function (res) {
            if(res.length > 0){
                this.wrkList = res.filter( v => v.wrkDt == this.$moment().format('YYYYMMDD') )
                this.cvtClndrFrmList(res)
            }
            this.savBtn = false
        },
        empAtndYnListRst: function (res) {
            if(res.length > 0){
                let wrkCnt = res.filter( v => v.wrkTyp == '1' )
                let vctnCnt = res.filter( v => v.wrkTyp == '2' )
                

                if(wrkCnt.length > 0 && vctnCnt.length > 0)
                    this.tgtObj.mixTyp = '3'
                else if(wrkCnt.length > 0 && vctnCnt.length == 0)
                    this.tgtObj.mixTyp = '1'
                else if(wrkCnt.length == 0 && vctnCnt.length > 0)
                    this.tgtObj.mixTyp = '2'
           
                if(this.tgtObj.mixTyp == '2')
                    this.tgtObj.scheInf = '휴가'
                else if(res.filter( v => v.atndYn == '2').length > 0)
                    this.tgtObj.scheInf = '출근전'
                else if(res.filter( v => v.atndYn == '2').length == 0 && res.filter( v => v.leavYn == '2').length > 0)
                    this.tgtObj.scheInf = '근무중'
                else if(res.filter( v => v.atndYn == '2').length == 0 && res.filter( v => v.leavYn == '2').length == 0 )
                    this.tgtObj.scheInf = '근무종료'

                this.atndList = res
            }
        },
        asyncResult: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getCmnEmpInfo( this.$store.getters.getMmbrPk )
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preEmpAtndDt: function () {
            //근무중 휴가
            let obj = {}
            obj.empPk = this.tgtObj.empPk
            obj.wrkDt = this.$moment().format('YYYYMMDD')
            
            if(this.tgtObj.scheInf == '출근전'){
                obj.atndDt = this.$moment().format('YYYYMMDD')
                obj.leavDt = null
            }
            else if(this.tgtObj.scheInf == '근무중'){
                obj.atndDt = null
                obj.leavDt = this.$moment().format('YYYYMMDD')
            }
            else if(this.tgtObj.scheInf == '휴가'){
                obj.atndDt = this.$moment().format('YYYYMMDD')
                obj.leavDt = this.$moment().format('YYYYMMDD')
            }

            if(this.tgtObj.scheInf != '')
                this.setEmpAtndDt(obj.empPk, obj.wrkDt, obj.atndDt, obj.leavDt)
            else
                this.snackControll([true, 2500, '비정상적인 호출 입니다.', 'info'])
        },
        cvtClndrFrmList: function (arr) {
            arr.forEach(itm => {
                itm.start = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')
                itm.color = '#dbdbdb'
                if(itm.wrkOvnYn == '1')
                    itm.end = this.$moment(itm.wrkDt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')
                else
                    itm.end = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')

                this.vCd73.forEach(idx => {
                    if(idx.valcd == itm.wrkTypCd)
                        itm.wrkTypNm = idx.valcdnm
                });
                this.vCd107.forEach(idx => {
                    if(idx.valcd == itm.vctnTypCd)
                        itm.vctnTypNm = idx.valcdnm
                });
            });

            this.wrkSchdlList = arr
        },
        getPickerDate: function (date) { 
            this.start = date.substr(0, 4) + '-' + date.substr(4, 2) + '-01'
            this.sltDate = date
        },
        onClose: function () {
            this.$emit('modalClose', '2')
        },
        clrData: function () {
            this.tgtObj.empPk = 0
            this.tgtObj.mmbrPk = 0
            this.tgtObj.jobCd = ''
            this.tgtObj.name = ''
            this.tgtObj.mixTyp = '3'
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        savBtn: true,
        tgtObj: { empPk: 0, mmbrPk: 0, jobCd: '', name: '', mixTyp: '3', scheInf: '' },
        atndList: [],
        wrkSchdlList : [],
        wrkList: [],
        vCd73: [],
        vCd107: [],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>