<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">입소사실확인서</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-form ref="prtInfo" lazy-validation>
            <v-divider class="mt-7"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                    <span class="black--text font-weight-medium">사용용도</span>
                </v-col>
                <v-col class="tableBL px-2 py-1" cols="9">
                    <span class="d-inline-block px-2" style="width: 100%; padding-top: 0px;">
                        <v-text-field 
                            v-model="inTxt1" color="blue"
                            :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                    <span class="black--text font-weight-medium">제출처</span>
                </v-col>
                <v-col class="tableBL px-2 py-1" cols="9">
                    <span class="d-inline-block px-2" style="width: 100%; padding-top: 0px;">
                        <v-text-field 
                            v-model="inTxt2" color="blue"
                            :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="font-weight-bold" samll height="30" color="grey006" @click="prtAct()" rounded outlined>
                    <v-icon style="margin-top: 1px;" small>mdi-printer-outline</v-icon>
                    <span class="fontOneRem ml-1" style="margin-top: 1px; padding-bottom: 2px;">출력</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
export default {
    name: 'CnfrOfdmMdl',

    props : {
   
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{
  
    },
    
    methods: {
        prtAct: function () {
            let chc = this.$refs.prtInfo.validate()
            if(chc){
                this.$parent.$parent.$parent.prtThrowObj.prtCd = '4'
                this.$parent.$parent.$parent.prtThrowObj.inTxt1 = this.inTxt1
                this.$parent.$parent.$parent.prtThrowObj.inTxt2 = this.inTxt2
                this.$parent.$parent.$parent.rprtActCall()
            }
            else
                this.snackControll([true, 2500, '모든 항목을 입력해 주세요.', 'warning'])

            this.onClose()
        },

        clearForm: function () {

            this.forceRender =+ 1
        },

        onClose: function () {
            this.inTxt1 = ''
            this.inTxt2 = ''
            this.$emit('madalState', '1')
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        inTxt1: '',
        inTxt2: '',
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>