<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex mx-2 mt-2">
            <div class="d-flex justify-center align-center">
                <!--
                <v-icon style="font-size: 26px;" color="black">mdi-chevron-down</v-icon>
                -->
                <span class="d-inline-block text-h5 font-weight-bold" style="height:30px; color: #004584;">{{subTitle}}</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <!-- <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span> -->
        </div>
        <v-divider class="my-2"></v-divider>
        <!-- <div v-if="subTab == 0" class="" style="position:relative;">
            <div class="d-flex align-center" style="position: absolute; top: 8px; right: 1px; z-index: 1;">
                <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;양호&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-triangle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;보통&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-close</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;불량&#44;&nbsp;&nbsp;</span>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&nbsp;N&#47;A&#58;&nbsp;해당없음</span>
            </div>
        </div> -->
        <div>
            <v-tabs hide-slider v-model="tab" color="white" class="fcsftInspc black--text"
                :active-class="tab < 5 ? '' : 'bodyColor'" center-active>
                <v-tab v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item eager transition="fade-transition">
                    <GasSftIns v-show="subTab == 0" ref="gasSftIns"></GasSftIns>
                    <FcsftInspcYear v-show="subTab == 1" ref="fcsftInspcYear0" :clsfcMode=2></FcsftInspcYear>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <ElcSftIns v-show="subTab == 0" ref="elcSftIns"></ElcSftIns>
                    <FcsftInspcYear v-show="subTab == 1" ref="fcsftInspcYear1" :clsfcMode=4></FcsftInspcYear>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <FirePrvn v-show="subTab == 0" ref="firePrvn"></FirePrvn>
                    <FcsftInspcYear v-show="subTab == 1" ref="fcsftInspcYear2" :clsfcMode=6></FcsftInspcYear>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <FcsftInspcYear v-show="subTab == 1" ref="fcsftInspcYear3" :clsfcMode=8></FcsftInspcYear>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <FineDust v-show="subTab == 0" ref="fineDust"></FineDust>                    
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model="menu[0]" max-width="500">    
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import GasSftIns from '../../components/sfty/GasSftIns.vue';
import ElcSftIns from '../../components/sfty/ElcSftIns.vue';
import FirePrvn from '../../components/sfty/FirePrvn.vue';
import FcsftInspcYear from '../../components/sfty/FcsftInspcYear.vue';
import FineDust from '../../components/sfty/FineDust.vue';

export default {
    name: 'FcsftInspc',
    
    components: {
        btnModalDelete,
        GasSftIns,
        ElcSftIns,
        FirePrvn,
        FcsftInspcYear,
        FineDust,
    },

    props : {

    },

    created: function(){
        for (let index = 0; index < this.outlinedBtn.length+1; index++) {
            this.dialog.push(false)      
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getlytWd: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getSltInspRecPk: function () {
            return this.$parent.$parent.sltInspRecPk
        },
        getSelectedItem: function () {
            return this.$parent.$parent.selectedItem
        },
    },  

    watch: {
        getlytWd: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getSltInspRecPk: function (value) {
            if(value == 0){
                this.btnControll(1, true, 'fill')
                this.btnControll(0, true, 'out')

                //this.$refs.nghChcLog.setInspRecDtllistDefault()
            }
            else if(value != 0){
                this.btnControll(1, false, 'fill')
                this.btnControll(0, false, 'out')

                //this.$refs.nghChcLog.getInspRecDtllist()
            }
        },
        getSelectedItem: function (value) {
            this.subTab = 99
            this.subTitle = ''
            if(value == -1){
                this.tab = this.tabItems.length
                this.tabItems.forEach(items => {
                    items.enter = true
                });
                this.btnControll(0, true, 'fill')
            }
            else{
                let orgDate = ''
                let itmPos = value.substring(value.length-2, value.length)
                if(value.length == 6){
                    this.subTab = 1
                    orgDate = value.substring(0, 4)
                }
                else if(value.length == 9){
                    this.subTab = 0
                    orgDate = value.substring(0, 7)
                }

                this.tab = Number(itmPos.replaceAll('0', ''))-1
                this.tabItems.forEach(items => {
                    if(items.index != this.tab) items.enter = true
                    else if(items.index == this.tab) items.enter = false
                });
                
                this.subTitle += orgDate + ' ( '
                if(orgDate.length == 4)
                    this.subTitle += '연별 )'
                else
                    this.subTitle += '월별 )'
                
                this.btnControll(0, false, 'fill')
            }
        },
    },

    methods: {
        onClickBtn: function (index) {
            switch(index) {
                case 0:     //저장
                    if(this.tab == 0){
                        if(this.subTab == 0)
                            this.$refs.gasSftIns.preInspRecDtllist()
                        else if(this.subTab == 1)
                            this.$refs.fcsftInspcYear0.preInspRecDtllist()
                    }
                    else if(this.tab == 1){
                        if(this.subTab == 0)
                            this.$refs.elcSftIns.preInspRecDtllist()
                        else if(this.subTab == 1)
                            this.$refs.fcsftInspcYear1.preInspRecDtllist()
                    }
                    else if(this.tab == 2){
                        if(this.subTab == 0)
                            this.$refs.firePrvn.preInspRecDtllist()
                        else if(this.subTab == 1)
                            this.$refs.fcsftInspcYear2.preInspRecDtllist()
                    }
                    else if(this.tab == 3){
                        if(this.subTab == 1)
                            this.$refs.fcsftInspcYear3.preInspRecDtllist()
                    }
                    else if(this.tab == 4){
                        if(this.subTab == 0)
                            this.$refs.fineDust.preInspRecDtllist()                        
                    }
                    break;
                case 1:     //삭제
                    if(this.tab == 0){
                        if(this.subTab == 0)
                            this.delTitle = '가스안전점검월별'
                            //this.$refs.gasSftIns.removeInspRecDtl()
                        else if(this.subTab == 1)
                            this.delTitle = '가스안전점검연별'
                            //this.$refs.fcsftInspcYear0.removeInspRecDtl()
                    }
                    else if(this.tab == 1){
                        if(this.subTab == 0)
                            this.delTitle = '전기안전점검월별'
                            //this.$refs.elcSftIns.removeInspRecDtl()
                        else if(this.subTab == 1)
                            this.delTitle = '전기안전점검연별'
                            //this.$refs.fcsftInspcYear1.removeInspRecDtl()
                    }
                    else if(this.tab == 2){
                        if(this.subTab == 0)
                            this.delTitle = '화제예방점검월별'
                            //this.$refs.firePrvn.removeInspRecDtl()
                        else if(this.subTab == 1)
                            this.delTitle = '화제예방점검(정밀)연별'
                            //this.$refs.fcsftInspcYear2.removeInspRecDtl()
                    }
                    else if(this.tab == 3){
                        if(this.subTab == 1)
                            this.delTitle = '미세먼지점검(작동)연별'
                            //this.$refs.firePrvn.removeInspRecDtl()
                    }
                    else if(this.tab == 4){
                        if(this.subTab == 0)
                            this.delTitle = '미세먼지점검월별'
                            //this.$refs.firePrvn.removeInspRecDtl()
                    }

                    
                    this.menu.splice(0, 1, true)
                    break;
                default:
                    break;
            }
        },
        btnControll: function (index, dis, type) {
            switch (type) {
                case 'out':
                    //this.outlinedBtn[index].disabled = dis
                    break;
                case 'fill':
                    this.filledBtn[index].disabled = dis
                    break;
                default:
                    break;
            }
        },
        delModalAct: function (res) {
            this.delTitle = ''
            if(res == 9990){    //OK
                if(this.tab == 0){
                    if(this.subTab == 0)
                        this.$refs.gasSftIns.removeInspRecDtl()
                    else if(this.subTab == 1)
                        this.$refs.fcsftInspcYear0.removeInspRecDtl()
                }
                else if(this.tab == 1){
                    if(this.subTab == 0)
                        this.$refs.elcSftIns.removeInspRecDtl()
                    else if(this.subTab == 1)
                        this.$refs.fcsftInspcYear1.removeInspRecDtl()
                }
                else if(this.tab == 2){
                    if(this.subTab == 0)
                        this.$refs.firePrvn.removeInspRecDtl()
                    else if(this.subTab == 1)
                        this.$refs.fcsftInspcYear2.removeInspRecDtl()
                }
                else if(this.tab == 3){
                    if(this.subTab == 1)
                        this.$refs.fcsftInspcYear3.removeInspRecDtl()
                }
                else if(this.tab == 4){
                    if(this.subTab == 0)
                        this.$refs.fineDust.removeInspRecDtl()
                }

                this.menu.splice(0, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(0, 1, false)
        },
        clearForm: function () {
            if(this.tab == 0){
                if(this.subTab == 0)
                    this.$refs.gasSftIns.clearForm()
                else if(this.subTab == 1)
                    this.$refs.fcsftInspcYear0.clearForm()
            }
            else if(this.tab == 1){
                if(this.subTab == 0)
                    this.$refs.elcSftIns.clearForm()
                else if(this.subTab == 1)
                    this.$refs.fcsftInspcYear1.clearForm()
            }
            else if(this.tab == 2){
                if(this.subTab == 0)
                    this.$refs.firePrvn.clearForm()
                else if(this.subTab == 1){
                    this.$refs.fcsftInspcYear2.clearForm()
                }
            }
            else if(this.tab == 3){
                if(this.subTab == 1)
                    this.$refs.fcsftInspcYear3.clearForm()
            }
            else if(this.tab == 4){
                if(this.subTab == 0)
                    this.$refs.fineDust.clearForm()
            }

        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 99,
        subTab: 99,
        delTitle: '',
        dialog: [],
        menu: [false],
        subTitle: '',
        areaWidth: 708,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        tabItems: [
            { index: 0, tab: '가스안전', style: 'font-size: 1.2rem !important;', enter: true },
            { index: 1, tab: '전기안전', style: 'font-size: 1.2rem !important;', enter: true },
            { index: 2, tab: '화재예방(정밀)', style: 'font-size: 1.2rem !important;', enter: true },
            { index: 3, tab: '화재예방(작동)', style: 'font-size: 1.2rem !important;', enter: true },
            { index: 4, tab: '미세먼지', style: 'font-size: 1.2rem !important;', enter: true },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled { background-color: #ededed !important; color: black !important; }
</style>