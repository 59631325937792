<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">{{yyyy}}년&nbsp;CCTV&nbsp;촬영동의&nbsp;전자&nbsp;발송</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <div class="mt-2" style="height:650px;">
            <v-tabs hide-slider v-model="tab" color="white" class="black--text"
                active-class="bodyColor" center-active>
                <v-tab class="fmlySend" v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <div>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager transition="fade-transition">
                        <BnfcrCctvAlimTalk ref="bnfcrCctvAlimTalk" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></BnfcrCctvAlimTalk>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <BnfcrCctvSms ref="bnfcrCctvSms" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></BnfcrCctvSms>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <BnfcrCctvEmail ref="bnfcrCctvEmail" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></BnfcrCctvEmail>
                    </v-tab-item>
                    <v-tab-item transition="false">
                        <BnfcrCctvHist v-if="tab == 3" ref="bnfcrCctvHist"></BnfcrCctvHist>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>   
    </v-sheet>
</template>

<script>
import { selAllBnfcrCctvSndList } from '../../api/bnfcr.js';
import BnfcrCctvAlimTalk from './BnfcrCctvAlimTalk.vue'
import BnfcrCctvSms from './BnfcrCctvSms.vue'
import BnfcrCctvEmail from './BnfcrCctvEmail.vue'
import BnfcrCctvHist from './BnfcrCctvHist.vue'

export default {
    name: 'BnfcrCctvSndMdl',

    props : {                
        yyyy:{type:Number, default:0},
        cd65:{type: Array, default: () => { return [] }},
        cd109:{type: Array, default: () => { return [] }},
    },
            
    components: {
       BnfcrCctvAlimTalk,
       BnfcrCctvSms,
       BnfcrCctvEmail,
       BnfcrCctvHist,
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {  
            this.getAllBnfcrCctvList()      
        })
    },

    computed: {

    },

    watch: {
       
    },
        
    methods: {
        getAllBnfcrCctvList: function () {    
            selAllBnfcrCctvSndList(this.$store.getters.getFcltyNum, this.yyyy)
                .then((response) => ( this.getAllBnfcrCctvListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrCctvSndList : ' + error))
        },       
        getAllBnfcrCctvListAfter:function(res) {            
            res.forEach(items => {

                items.lnkUrl = this.encUrlObj(items.grdnMmbrPk, items.agrRcvNm, items.cctvBdrmInfPk)         
                
                this.cd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });

                this.cd109.forEach(code => {
                    if(items.alimSndSttsCd == code.valcd) items.alimSndSttsNm = code.valcdnm
                    if(items.smsSndSttsCd == code.valcd) items.smsSndSttsNm = code.valcdnm
                    if(items.emailSndSttsCd == code.valcd) items.emailSndSttsNm = code.valcdnm
                });
            })

            this.$refs.bnfcrCctvAlimTalk.setData(res)
            this.$refs.bnfcrCctvSms.setData(res)
            this.$refs.bnfcrCctvEmail.setData(res)
        },
        msgTrigger:function(msg){
            this.snackControll([true, 2500, msg, 'warning'])
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllBnfcrCctvList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.tab = 0
            this.$emit('isClose')
        },     
        snackControll: function (options) {
            this.snackOptions = options
        },   
        //Url Encrypt
        encUrlObj: function(mmbrpk, nm, bzpk){
            let linkURI = ''
            let contrl  = ''
            let bzClcd  = ''
            let ttl     = 'CCTV 침실 촬영 동의'
            
            contrl = 'out/cnsnFlmn?dstnt='
            bzClcd = '29'

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {}
            obj.fcltyNum    = this.$store.getters.getFcltyNum
            obj.mmbrPk      = mmbrpk
            obj.bzPk        = bzpk
            obj.clcd        = '23'
            obj.bzClcd      = bzClcd
            obj.prtCd       = '37'
            obj.name        = nm
            obj.title       = ttl

            // console.log(obj)
            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + contrl + bs64
        },
        
    },
    
    data: () => ({
        tab:0,
        copayEsndList:[],
        tabItems: [
            { index: 0, tab: '알림톡', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 1, tab: '문자', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 2, tab: 'E-MAIL', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 3, tab: '발송내역', style: 'font-size: 1.2rem !important;', enter: false },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>