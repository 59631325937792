import { http } from '@/api/baseAxios';

//수급자 급여제공계획및결과평가 조회
function getBnfcrBnftOfrList(code, state) {
    return http.post('bnftoffer/selBnfcrBnftOfrList'
        , { fcltyNum: code, 
            bnMmbrPk: state.bnMmbrPk, 
            inqYYYY : state.inqYYYY,
    })
}

//수급자 급여제공계획및결과평가 조회
function getBnfcrExamList(state) {
    return http.post('bnftoffer/selBnfcrExamList'
        , { clsfc1Cd: state.clsfc1Cd, 
            clsfc2Cd: state.clsfc2Cd, 
            clsfc2CdS: state.clsfc2CdS, 
            bnfcrExamHisPk : state.bnfcrExamHisPk,
            fcltyNum:state.code,
            bnMmbrPk:state.bnMmbrPk,
        })
}

//수급자 급여제공계획및결과평가 조회(트리구조)
function getBnfcrExamListTree(state) {
    return http.post('bnftoffer/selBnfcrExamListTree'
        , { clsfc1Cd: state.clsfc1Cd, 
            clsfc2Cd: state.clsfc2Cd, 
            bnfcrExamHisPk : state.bnfcrExamHisPk,
        })
}

//수급자평가내역 한건 조회
function getBnfcrExamHisInfo(code, value) {
    return http.post('bnftoffer/selBnfcrExamHisInfo'
        , { fcltyNum: code, 
            bnfcrExamHisPk : value,
        })
}

//수급자평가내역 저장
function insBnfcrExamHis(obj, exam, bnfcr=null) {
    return http.post('bnftoffer/insBnfcrExamHis', { examhis: obj, exam: exam, bnfcr: bnfcr})
}

//수급자평가 저장
function insBnfcrExam(obj, pk) {
    return http.post('bnftoffer/insBnfcrExamHis', { examhis: obj, bnfcrExamPk: pk})
}

//물리치료계획 저장
function insBnftOfrPhyThrPln(ofrPlnHisInfo, phyThrPlnInfo, phyThrPlnDtlInfo) {
    return http.post('bnftoffer/insBnftOfrPhyThrPln'
        , { ofrPlnHisInfo: ofrPlnHisInfo, phyThrPlnInfo: phyThrPlnInfo, phyThrPlnDtlInfo: phyThrPlnDtlInfo})
}

//전체 급여제공과정 조회
function getAllBnftOfrList(code, yyyy) {
    return http.post('bnftoffer/selAllBnftOfrList', { fcltyNum: code, inqYYYY : yyyy})
}

//급여제공계획내역
function insBnftOfrWrtPln(ofrPlnHisInfo, bnftOfrPlnWrtList) {
    return http.post('bnftoffer/insBnftOfrWrtPln', { ofrPlnHisInfo: ofrPlnHisInfo, bnftOfrPlnWrtList: bnftOfrPlnWrtList})
}

//급여제공계획내역 저장
function insBnftOfrPlnHis(obj) {
    return http.post('bnftoffer/insBnftOfrPlnHis' , obj)
}

//급여제공계획내역 저장
function insBnftOfrPlnMfd(obj) {
    return http.post('bnftoffer/insBnftOfrPlnMfd' , obj)
}

//급여제공계획내역 삭제
function delBnftOfrPlnHis(code, value, user) {
    return http.post('bnftoffer/delBnftOfrPlnHis' , { 
        fcltyNum: code, 
        bnftOfrPlnHisPk : value,
        userNm:user,
    })
}


//급여제공계획 삭제
function delBnftOfrWrtPln(code, state) {
    return http.post('bnftoffer/delBnftOfrWrtPln'
        , { fcltyNum: code, 
            bnMmbrPk: state.bnMmbrPk, 
            bnftOfrPlnHisPk : state.bnftOfrPlnHisPk,
            userNm:state.userNm,
        })
}

//인지기능평가 결과요약표
function getExam104ScrInfo(code,value) {
    return http.post('bnftoffer/selExam104ScrInfo'
        , { fcltyNum: code, 
            bnfcrExamHisPk : value,
        })
}

//수급자평가내역삭제
function delBnfcrExamHis(code, value, user) {
    return http.post('bnftoffer/delBnfcrExamHis', { fcltyNum: code, bnfcrExamHisPk : value, userNm:user,})
}

//수급자 물리(작업)치료 평가정보 조회
function getBnfcrPhyThrExamInfList(obj) {
    return http.post('bnftoffer/selBnfcrPhyThrExamInfList',obj)
}

//급여제공계획내역 단건 조회 (물리치료 포함)
function getBnftOfrPlnHisInfo(code, state) {
    return http.post('bnftoffer/selBnftOfrPlnHisInfo'
        , { fcltyNum: code, 
            bnMmbrPk: state.bnMmbrPk, 
            inqYYYY : state.inqYYYY,
            bnftOfrPlnHisPk : state.bnftOfrPlnHisPk,
        })
}

//급여제공계획내역 다건 조회 (다건)
function selBnftOfrPlnHisList(code, state) {
    return http.post('bnftoffer/selBnftOfrPlnHisList'
        , { fcltyNum: code, 
            bnMmbrPk: state.bnMmbrPk, 
            inqYYYY : state.inqYYYY,
            mdfClcd : state.mdfClcd,
            mdfRsncd : state.mdfRsncd,
            bzClcd:state.bzClcd,
            bzPk:state.bzPk,
        })
}

//전체치료주간현황
function getAllThrWkList(code, state) {
    return http.post('bnftoffer/selAllThrWkList'
        , { fcltyNum: code, 
            bnMmbrPk: state.bnMmbrPk,
            bnftOfrPlnHisPk : state.bnftOfrPlnHisPk,
        })
}

//물리치료계획 조회
function getPhyThrPlnInfo(code, state) {
    return http.post('bnftoffer/selPhyThrPlnInfo'
        , { fcltyNum: code, 
            bnMmbrPk: state.bnMmbrPk, 
            bnftOfrPlnHisPk : state.bnftOfrPlnHisPk,
        })
}

//물리치료계획상세 조회
function getPhyThrPlnDtlList(code, state) {
    return http.post('bnftoffer/selPhyThrPlnDtlList'
        , { fcltyNum: code, 
            phyThrPlnPk : state.phyThrPlnPk,
        })
}

//급여제공계획내역 및 물리치료계획 삭제
function delBnftOfrPhyThrPln(code,state) {
    return http.post('bnftoffer/delBnftOfrPhyThrPln'
        , { fcltyNum: code, 
            bnMmbrPk: state.bnMmbrPk, 
            bnftOfrPlnHisPk : state.bnftOfrPlnHisPk,
            userNm:state.userNm,
        })
}

//급여제공계획내용작성 다건 조회
function selBnftOfrPlnWrtList(code,value,mmbr=null) {
    return http.post('bnftoffer/selBnftOfrPlnWrtList', { fcltyNum: code, bnftOfrPlnHisPk : value, bnMmbrPk:mmbr})
}

//급여제공계획내용작성 다건 조회
function selBnftOfrPlnDtlWrtList(code,state) {
    return http.post('bnftoffer/selBnftOfrPlnDtlWrtList', { 
        fcltyNum: code, 
        bnftOfrPlnHisPk : state.bnftOfrPlnHisPk,
        bnftOfrPlnPk : state.bnftOfrPlnPk,
        clsfc1Cd : state.clsfc1Cd,
        clsfc2Cd : state.clsfc2Cd,
        clsfc3Cd : state.clsfc3Cd,
    })
}

function selBnftOfrPlnList(obj) {
    return http.post('bnftoffer/selBnftOfrPlnList', obj)
}

//급여제공 계획변경사유 다건 조회
function selBnftOfrMdfRsnList(obj) {
    return http.post('bnftoffer/selBnftOfrMdfRsnList', obj)
}

//물리(작업)치료 평가및계획 현황 조회
function getAllPhyThrList(code, year, stCd) {
    return http.post('bnftoffer/selAllPhyThrList', { fcltyNum: code, inqYYYY:year, entStcd:stCd})
}

//물리(작업)치료 평가및계획 현황 조회
function selBnfcrPhyThrExamList(obj) {
    return http.post('bnftoffer/selBnfcrPhyThrExamList', obj)
}

function selPhyThrPlnList(code, bnmmbrPk, year) {
    return http.post('bnftoffer/selPhyThrPlnList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, inqYYYY:year})
}

function selMenuList(code, startdt, enddt) {
    return http.post('bnftoffer/selMenuList', { fcltyNum: code, inqYmdStart: startdt, inqYmdEnd:enddt})
}
//주간식단표 저장
function insMenu(code, user, list) {
    return http.post('bnftoffer/insMenu', { fcltyNum: code, userNm:user, menuList: list})
}
//주간식단표 삭제
function delMenu(code, user, list) {
    return http.post('bnftoffer/delMenu', { fcltyNum: code, userNm:user, menuList: list})
}

//주간식단표 저장
function insBnfcrDiets(obj) {
    return http.post('bnftoffer/insBnfcrDiets', obj)
}

//수급자식이정보 다건 조회
function selBnfcrDietsList(code, ymd) {
    return http.post('bnftoffer/selBnfcrDietsList', { fcltyNum: code, inqYMD:ymd})
}

//수급자식이정보 조회
function selBnfcrDiets(obj) {
    return http.post('bnftoffer/selBnfcrDiets', obj)
}

function selBathSchdlPlnList(code, inqYmdStart, inqYmdEnd) {
    return http.post('bnftoffer/selBathSchdlPlnList', { fcltyNum: code, inqYmdStart: inqYmdStart, inqYmdEnd: inqYmdEnd})
}

function insBathSchdlPln(code, user, inqYmdStart, inqYmdEnd, list) {
    return http.post('bnftoffer/insBathSchdlPln', { 
        fcltyNum: code, 
        userNm: user, 
        inqYmdStart: inqYmdStart, 
        inqYmdEnd: inqYmdEnd, 
        list: list})
}


function delBathSchdlPln(code, pk=null,ymdstart=null,ymdend=null,user=null) {
    return http.post('bnftoffer/delBathSchdlPln', 
        { 
            fcltyNum: code, 
            bathSchdlPk: pk, 
            inqYmdStart: ymdstart,
            inqYmdEnd: ymdend,
            userNm:user,
    })
}

function delBathSchdlPlnRec(obj) {
    return http.post('bnftoffer/delBathSchdlPlnRec', obj)
}

//사례관리내역 조회
function selMtngCaseList(code, year) {
    return http.post('opr/selMtngCaseList', { fcltyNum: code, inqYYYY:year})
}

//사례관리회의 상세정보 조회
function selMtngRcrdInfo(code, pk) {
    return http.post('opr/selMtngRcrdInfo', { fcltyNum: code, mtngRcrdPk:pk})
}

//사례관리회의 참석자 조회
function selMtngAtndList(code, pk) {
    return http.post('opr/selMtngAtndList', { fcltyNum: code, mtngRcrdPk:pk})
}

//사례관리 저장
function insMtngRcrd(obj) {
    return http.post('opr/insMtngRcrd', obj)
}

//사례관리 삭제
function delMtngRcrd(code, clcd, pk, user) {
    return http.post('opr/delMtngRcrd', { fcltyNum: code, mtngTypcd:clcd, mtngRcrdPk:pk, userNm:user})
}
//회의참석자 삭제
function delMtngAtnd(code, pk, user) {
    return http.post('opr/delMtngAtnd', { fcltyNum: code, mtngAtndPk:pk, userNm:user})
}

//사례관리 관련자료 저장
function insMtngRcrdFile(obj) {
    return http.post('opr/insMtngRcrdFile', obj)
}

//사례관리 급여제공반영 내역 조회
function selMtngCaseBnftList(code, pk) {
    return http.post('opr/selMtngCaseBnftList', { fcltyNum: code, mtngRcrdPk:pk})
}

//진료기록 및 외출외박 정보 저장(다건)
function selBnfcrBathTmInfo(code,mmbr,ymd) {
    return http.post('bnftoffer/selBnfcrBathTmInfo', {fcltyNum:code, bnMmbrPk:mmbr,inqYMD:ymd})
}

//수급자식이정보 다건 조회
function selBnfcrDtryList(code,mmbr,start,lmt) {
    return http.post('bnftoffer/selBnfcrDtryList', {fcltyNum:code, bnMmbrPk:mmbr,rnStrt:start,rnLmt:lmt})
}

//수급자식이정보 치료식이 다건 조회
function selBnfcrDietsThrpList(code,ymd) {
    return http.post('bnftoffer/selBnfcrDietsThrpList', {fcltyNum:code, inqYMD:ymd})
}

//수급자식이정보관리
function selBnfcrDietsMgmt(obj) {
    return http.post('bnftoffer/selBnfcrDietsMgmt', obj)
}

//수급자식이정보내역 다건 조회
function selBnfcrDietsHisList(code,mmbr,start,lmt) {
    return http.post('bnftoffer/selBnfcrDietsHisList', {fcltyNum:code, bnMmbrPk:mmbr,rnStrt:start,rnLmt:lmt})
}

//수급자식이정보내역 수정
function insBnfcrDietsHis(obj) {
    return http.post('bnftoffer/insBnfcrDietsHis', obj)
}

//수급자식이정보내역 수정
function updBnfcrDietsHis(obj) {
    return http.post('bnftoffer/updBnfcrDietsHis', obj)
}

//수급자식이정보내역 삭제
function delBnfcrDietsHis(obj) {
    return http.post('bnftoffer/delBnfcrDietsHis', obj)
}

//수급자식이정보 특이사항 다건 조회	
function selBnfcrDietsSpclList(obj) {
    return http.post('bnftoffer/selBnfcrDietsSpclList', obj)
}

//급여제공계획상세 삭제
function delBnftOfrPlnDtl(obj) {
    return http.post('bnftoffer/delBnftOfrPlnDtl', obj)
}

export {
    getBnfcrBnftOfrList,   
    getBnfcrExamList,
    getBnfcrExamListTree,
    getBnfcrExamHisInfo,
    insBnfcrExamHis, 
    insBnfcrExam, 
    getExam104ScrInfo,
    delBnfcrExamHis,
    getBnfcrPhyThrExamInfList,
    getBnftOfrPlnHisInfo,
    insBnftOfrPhyThrPln,
    getAllThrWkList,
    getPhyThrPlnInfo,
    getPhyThrPlnDtlList,
    delBnftOfrPhyThrPln,
    selBnftOfrPlnWrtList,
    selBnftOfrPlnDtlWrtList,
    insBnftOfrWrtPln,
    delBnftOfrWrtPln,
    selBnftOfrPlnList,
    insBnftOfrPlnHis,
    insBnftOfrPlnMfd,
    delBnftOfrPlnHis,
    selBnftOfrMdfRsnList,
    selBnftOfrPlnHisList,
    getAllBnftOfrList,
    getAllPhyThrList,
    selBnfcrPhyThrExamList,
    selPhyThrPlnList,
    selMenuList,
    insMenu,
    delMenu,
    insBnfcrDiets,
    selBnfcrDietsList,
    selBnfcrDiets,
    selBathSchdlPlnList,
    insBathSchdlPln,
    delBathSchdlPln,
    selMtngCaseList,
    selMtngRcrdInfo,
    selMtngAtndList,
    insMtngRcrd,
    delMtngRcrd,
    delMtngAtnd,
    insMtngRcrdFile,
    selMtngCaseBnftList,
    selBnfcrBathTmInfo,
    delBathSchdlPlnRec,
    selBnfcrDtryList,    
    selBnfcrDietsThrpList,
    selBnfcrDietsMgmt,
    selBnfcrDietsHisList,
    insBnfcrDietsHis,
    updBnfcrDietsHis,
    delBnfcrDietsHis,
    selBnfcrDietsSpclList,
    delBnftOfrPlnDtl,
}