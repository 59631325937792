<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">건강검진&nbsp;제공&nbsp;정보</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-5 d-flex justyfy-right">
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i" @click="btnAction(list.text)"
                :class="list.class" samll height="28" :color="list.color" rounded>
                <v-icon small>{{list.icon}}</v-icon>
                <span style="font-size: 1rem;">{{list.text}}</span>
            </v-btn>
        </div>
        <v-divider class="mt-3"></v-divider>
        <v-row class="" no-gutters>
            <v-col class="pa-0 ma-0 greyE01 d-flex justify-center" cols="4">
                <span class="black--text font-weight-medium py-1">수급자</span>
            </v-col>
            <v-col class="pa-0 ma-0 d-flex justify-left" cols="8">
                <span class="black--text my-1 ml-5">{{bnfcrInfo == null ? '' : bnfcrInfo.name}}</span>
            </v-col>
        </v-row>
        <v-form ref="hltForm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row class="" no-gutters>
                <v-col class="pa-0 ma-0 greyE01 d-flex justify-center" cols="4">
                    <span class="black--text font-weight-medium py-1">건강검진일</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left" cols="8">
                    <span class="white d-inline-block py-1 pl-4" style="width: 153px;">
                        <CmmDateComp v-model="hltChcDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="" no-gutters>
                <v-col class="pa-0 ma-0 greyE01 d-flex justify-center" cols="4">
                    <span class="black--text font-weight-medium py-1">검진장소</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center" cols="8">
                    <span class="py-1 px-4" style="width: 100%;">
                        <v-text-field v-model="hltChcPlc" color="blue"
                            :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
        </v-form>
    </v-sheet>
</template>

<script>
import { selHltChcInf, insHltChcInf, delHltChcInf } from '../../api/bnftrcrd.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'HltChcOfrInfo',

    props : {
        bnfcrInfo: { type: Object, default: new Object() }
    },

    components: {
        CmmDateComp,
    },

    computed: {

    },

    watch: {
        'bnfcrInfo': function(){
            if(this.bnfcrInfo.hltChcInfPk != null || this.bnfcrInfo.hltChcInfPk != 0)
                this.getHltChcInf(this.bnfcrInfo.hltChcInfPk)
        },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    methods:{
        getHltChcInf: function (pk) {
            selHltChcInf(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.getHltChcInfAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selHltChcInf : ' + error))
        },
        setHltChcInf: function (obj) {
            let chk = this.$refs.hltForm.validate()
            if(chk){
                insHltChcInf(obj)
                    .then((response) => ( this.setHltChcInfAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnftrcrd/insHltChcInf : ' + error))
            }
        },
        removeHltChcInf: function () {
            delHltChcInf(this.$store.getters.getFcltyNum, this.bnfcrInfo.hltChcInfPk, this.$store.getters.getUserNm)
                .then((response) => ( this.setHltChcInfAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/delHltChcInf : ' + error))
        },
        getHltChcInfAfter: function (res) {
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)

            if(res != ''){
                this.hltChcDt = res.hltChcDt.beforeDateFormatHyp()
                this.hltChcPlc = res.hltChcPlc
            }
            else {
                this.hltChcDt = timezoneDate.toISOString().split('T',2)[0]
            }
        },
        setHltChcInfAfter: function (res) {
            if(res.statusCode == 200){
                this.hltChcDt = ''
                this.hltChcPlc = ''
                this.onClose()
                this.$parent.$parent.$parent.$parent.getHmnHltList()
            }
            else
                console.log('sava failed')
        },
        preHltChcInf: function () {
            let chk = this.$refs.hltForm.validate()

            if(chk) {
                let obj = {}
                obj.hltChcDt = this.hltChcDt.afterDateFormatHyp()
                obj.hltChcPlc = this.hltChcPlc
                obj.mmbrPk = this.bnfcrInfo.mmbrPk
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.userNm = this.$store.getters.getUserNm
             
                this.setHltChcInf(obj)
            }

        },
        btnAction: function (text) {
            if(text == '저장')
                this.preHltChcInf()
            else if(text == '삭제')
                this.removeHltChcInf()
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        hltChcDt: '',
        hltChcPlc: '',
        forceRender : 0,
        menu: [false, false],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', },
        ],
        rules: {
            required: value => !!value || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
        },
    })
}
</script>