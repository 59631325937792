<template>
    <v-sheet class="pa-7" rounded="lg">
        <div style="position: relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute centered top style="top: 30px;">
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">재입소&nbsp;정보&nbsp;&#40;미등록&#41;</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </v-row>
        
        <v-divider class="mt-7"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                <span class="fontOneDotOneRem black--text font-weight-medium">퇴소일</span>
            </v-col>
            <v-col class="pl-2 py-2" cols="9">
                <span>{{ $moment(lvDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                <span class="fontOneDotOneRem black--text font-weight-medium">재입소일</span>
            </v-col>
            <v-col class="pa-1" cols="9">
                <v-form ref="form" lazy-validation>
                    <!-- 입소일 area -->
                    <span class="d-inline-block" style="width: 140px;">
                        <CmmDateComp
                            v-model="entDt"
                            :minDt="$moment(lvDt, 'YYYYMMDD').format('YYYY-MM-DD')"
                            :required="true">
                        </CmmDateComp>
                    </span>
                    <!-- 입소시간 area -->
                    <span class="d-inline-block ml-1" style="width: 80px;">
                        <CmmTimeField v-model="entTm" :required="true"></CmmTimeField>
                    </span>
                </v-form>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                <span class="fontOneDotOneRem black--text font-weight-medium">생활실</span>
            </v-col>
            <v-col class="pa-1" cols="9">
                <span class="white d-inline-block" style="width: 106px;">
                    <v-text-field
                        v-model="lvnRmNm" :rules="[rules.required]"
                        readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="ml-1" color="#FFB6B9" style="padding:0px 1px 0px 2px !important"
                    min-width="30" height="25" @click="lvnRmMdl=true"
                    dark small>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-bed-king-outline</v-icon>
                        </template>
                        <span>생활실선택</span>
                    </v-tooltip>
                </v-btn>
                <!-- lvnRmMdl -->
            </v-col>
        </v-row>
        <v-divider class="mb-10"></v-divider>
        <v-row class="justify-center" no-gutters>
            <v-btn
                @click="preIns()"
                class="white--text" height="28" color="blueBtnColor" rounded samll>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span class="fontOneRem">저장</span>
            </v-btn>
        </v-row>
        <v-dialog v-model="lvnRmMdl" max-width="900" persistent eager>
            <LvnRmMdl
                ref="lvnRmMdl"
                @modalClose="lvnRmMdl=false" @sltLvnRmInf="getLvnRmInf">
            </LvnRmMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { reEntBnfcr } from '../../api/bnfcr.js';

import LvnRmMdl from '../opr/LvnRmMdl.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'BnfcrBasicReEnt',
        
    components: {
        LvnRmMdl,
        CmmDateComp,
        CmmTimeField,
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.lvDt = this.$store.getters.getBnfcrLvDt
        })
    },

    computed: {
        getBnfcrLvDt: function () {
            return this.$store.getters.getBnfcrLvDt
        },
    },

    watch: {
        getBnfcrLvDt: function (v) {
            this.lvDt = v
        },
    },
    
    methods: {
        // 수급자 재입소
        setReBnfcr: function (obj) {
            reEntBnfcr(obj)
                .then((response) => ( this.reBnfcrRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insReBnfcr : ' + error))
        },
        reBnfcrRst: function (res) {
            if(res.statusCode == '200'){
                this.$parent.$parent.$parent.bnfcrListReLoad('trigger')
                this.$parent.$parent.$parent.$refs.bnfcrInfo.snackControll([true, 2500, res.message, 'info'])
                this.onClose()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preIns: function(){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.entDt = this.$moment(this.entDt, 'YYYY-MM-DD').format('YYYYMMDD')
            if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.entTm)){
                obj.entTmHh = this.entTm?.slice(0, 2)
                obj.entTmMm = this.entTm?.slice(3, 5)
            }
            else {
                obj.entTmHh = ''
                obj.entTmMm = ''
            }
            obj.lvnRmInfPk = this.lvnRmInfPk
            obj.userNm = this.$store.getters.getUserNm

            if(this.lvnRmInfPk == 0 || this.lvnRmNm == ''){
                this.snackControll([true, 2500, '생활실은 필수 입력 항목입니다.', 'info'])
                return
            }
            
            //console.log(obj)
            if(this.$refs.form.validate())
                this.setReBnfcr(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info'])
        },
        getLvnRmInf: function (obj) {
            this.lvnRmNm = obj.lvnRmNm
            this.lvnRmInfPk = obj.lvnRmInfPk
        },
        onClose: function(){
            this.$emit('madalState', 2)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        lvnRmMdl: false,
        lvDt: '',
        entDt: '',
        entTm: '',
        lvnRmInfPk: 0,
        lvnRmNm: '',
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', },
        ],
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>