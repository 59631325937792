<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-divider :class="['mt-2', empChcWhList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', empChcWhList.length> 10 ? 'mr-2__5' : 'ma-0']" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="d-inline-block ml-1" style="width: 26px;" v-if="chkTyp == '2'">
                    <v-checkbox
                        class="checkIcon" v-model="allChckr" :key="forceRender" @click="allChnger"
                        true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                <span class="black--text font-weight-medium">직원명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">성별</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="4">
                <span class="black--text font-weight-medium">담당직종</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2" cols="2">
                <span class="black--text font-weight-medium">근무구분</span>
            </v-col>
            <!-- <v-col class="d-flex justify-center align-center py-2" cols="3">
                <span class="black--text font-weight-medium">근무일정</span>
            </v-col> -->
        </v-row>
        <v-divider :class="[empChcWhList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 402px;">
            <v-row class="ma-0 pa-0" style="border-bottom: 1px solid #dbdbdb;"
                v-for="(wList, i) in empChcWhList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="d-inline-block ml-1" style="width: 26px;" v-if="chkTyp == '2'">
                        <v-checkbox
                            class="checkIcon" v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="allChekrFunc"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 26px;" v-else-if="chkTyp == '1'">
                        <v-checkbox
                            class="checkIcon" v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="onlyOneCheckr(i)"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                    <span class="black--text">{{ wList.name }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="d-inline-block" style="width: 20px; heigth: 20px;">
                        <v-img class="ic-sex" v-if="wList.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                        <v-img class="ic-sex" v-else-if="wList.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="4">
                    <span class="black--text">{{ wList.jobCd }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text">{{ wList.wrkClcd }}</span>
                </v-col>
                <!-- <v-col class="d-flex justify-center align-center py-2" cols="3">
                    <span class="black--text" v-if="wList.wrkTmHhStrt != null">{{ wList.wrkTmHhStrt }}&#58;{{ wList.wrkTmMmStrt }}</span>
                    <span class="black--text" v-if="wList.wrkTmHhStrt != null">&nbsp;&#126;&nbsp;</span>
                    <span class="black--text" v-if="wList.wrkTmHhStrt != null">{{ wList.wrkTmHhEnd }}&#58;{{ wList.wrkTmMmEnd }}</span>
                </v-col> -->
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { selEmpChcWhList } from '../../api/emp.js';

export default {
    name: 'EmpMltpWh',

    props : {
        exChekrList: { type: Array, default: new Array() },
        chkTyp: { type: String, default: '2' },
    },
            
    components: {
        
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getEmpChcWhList()
        })
    },

    computed: {
        getJobCode: function () {
            return this.$parent.$parent.$parent.$parent.cd42
        },
        getWrkClCode: function () {
            return this.$parent.$parent.$parent.$parent.cd41
        },
    },

    watch: {
        getJobCode: function (value) {
            if(this.empChcWhList.length > 0){
                this.empChcWhList.forEach(item => {
                    value.forEach(cd => {
                        if(cd.valcd == item.jobCd) item.jobCd = cd.valcdnm
                    });
                });
            }
        },
        getWrkClCode: function (value) {
            if(this.empChcWhList.length > 0){
                this.empChcWhList.forEach(item => {
                    value.forEach(cd => {
                        if(cd.valcd == item.wrkClcd) item.wrkClcd = cd.valcdnm
                    });
                });
            }
        },
        'exChekrList': function () {
            this.empChcWhList.splice(0)
            this.allChckr = '2'
            this.getEmpChcWhList()

            this.forceRender += 1
        },
    },
        
    methods: {
        //직원 선택(근무정보) 다건 조회
        getEmpChcWhList: function () {
            selEmpChcWhList(this.$store.getters.getFcltyNum, '1', this.$parent.$parent.$parent.$parent.wrtDt)
                .then((response) => ( this.getEmpChcWhListAfter(response.data) ))
                .catch((error) => console.log('upload error /emp/selEmpChcWhList : ' + error))
        },
        getEmpChcWhListAfter: function (res) {
            this.empChcWhList.splice(0)
            this.forceRender += 1

            res.forEach(item => {
                item.disChekr = false
                item.checkr = '2'
                item.dtTyp = 'emp'
                if(item.entDt != '')
                    item.entDt = item.entDt?.beforeDateFormatDot()
                if(item.rtrDt != '')
                    item.rtrDt = item.rtrDt?.beforeDateFormatDot()

                this.$parent.$parent.$parent.$parent.cd42.forEach(cd => {
                    if(cd.valcd == item.jobCd) item.jobCd = cd.valcdnm
                });
                this.$parent.$parent.$parent.$parent.cd41.forEach(cd => {
                    if(cd.valcd == item.wrkClcd) item.wrkClcd = cd.valcdnm
                });
            });

            this.empChcWhList = res

            if(this.empChcWhList.length > 0){
                this.chngInfrm()
            }
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.empChcWhList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.empChcWhList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        allChekrFunc: function () {
            let tCh = '1'
            this.empChcWhList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },
        onlyOneCheckr: function (index) {
            if(this.chkTyp == '1'){
                this.empChcWhList.forEach(el => {
                    if(el.disChekr == false) el.checkr = '2'
                });
                this.forceRender += 1
            }
            this.empChcWhList[index].checkr = '1'
        },
        chngInfrm: function () {
            this.exChekrList.forEach(item => {
                if(typeof item.empPk != 'undefined'){
                    this.empChcWhList.forEach(items => {
                        if(item.empPk == items.empPk){
                            items.checkr = '1'
                            items.disChekr = item.disChekr
                        }
                    });
                }
                else  {
                    this.empChcWhList.forEach(items => {
                        if(item.mmbrpk == items.mmbrPk){
                            items.checkr = '1'
                            items.disChekr = item.disChekr
                        }
                    });
                }
            });
        },
        fltrThrw: function () {
            let arr = this.empChcWhList.filter(v => v.disChekr == false)

            return arr.filter(v => v.checkr == '1')
        },
    },
    
    data: () => ({
        empChcWhList: [],
        allChckr: '2',
        forceRender: 0,
    }),
};
</script>