<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <v-form ref="form" lazy-validation>            
            <!-- <v-divider class="my-2"></v-divider> -->
            <basicInfo ref="basicInfo" :bnMmbrPk="bnMmbrPk" @setParentData="setBasicInfo"></basicInfo>
            <div class="d-flex align-center mt-2">           
                <span class="d-inline-block rounded white" style="width: 220px;">
                    <v-select
                        v-model="bnfcrPk" :key="forceRender"
                        :items="bnfcrPkList" 
                        item-text="viewTxt" item-value="bnfcrPk"
                        @change="onBnfcrLoad"
                        dense hide-details outlined>
                    </v-select>
                </span>
                <v-spacer></v-spacer>
            </div>
            <div class="d-flex align-center mt-2">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">집중배설관찰 정보&#40;입소후 한번만 작성&#41;</span>                
                <v-spacer></v-spacer>
                <span v-if="progress">
                    <v-progress-circular            
                    :size="40"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                </span>
                <span v-if="!progress">
                    <v-btn
                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                        min-width="30" height="25"
                        @click="onSave"
                        small outlined>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-content-save-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                                </div>
                            </template>
                            <span>관찰기간 및 상태 저장</span>
                        </v-tooltip>
                    </v-btn>

                    <v-btn
                        v-if="this.rec1Items.bnftOfrRecHisPk > 0"
                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                        min-width="30" height="25"
                        @click="onAllDel"
                        small outlined>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-trash-can-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">삭제</span>
                                </div>
                            </template>
                            <span>집중배설관찰기록 삭제</span>
                        </v-tooltip>
                    </v-btn>

                    <v-btn
                        v-if="this.rec1Items.bnftOfrRecHisPk > 0"
                        class="ml-1" color="grey006" style="padding: 13px 12px 12px 10px !important"
                        min-width="30" height="25"
                        @click="prtPyrprRcrd"
                        small outlined>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20">mdi-printer-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">출력</span>
                                </div>
                            </template>
                            <span>집중배설관찰기록 출력</span>
                        </v-tooltip>
                    </v-btn>
                 </span>
            </div>
            
            <v-row class="pa-0 mt-1" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">관찰기간</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    <span class="white d-inline-block pa-1" style="width: 140px;">  
                        <CmmDateComp v-model="rec1Items.recDt" :required="true" :btnActv="!cncntDisabled" :disabled="cncntDisabled"></CmmDateComp>                                                 
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 50px;">
                        <v-text-field v-model="rec1Items.ofrTmHhStrt" color="blue" :rules="[rules.required, rules.numberCheck]" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="white d-inline-block pa-1">
                        시 ~ {{rec1Items.endDate}} &#40;{{rec1Items.thrTmHhEnd}}시&#41;
                    </span>
                    <span class="d-inline-block ml-4" style="width: 130px;">
                        <v-select 
                            v-model="rec1Items.itm1Val"                               
                            :items="codeItem117"
                            item-text="valcdnm"
                            item-value="valcd"
                            dense hide-details outlined
                            :rules="[rules.required]"
                        />    
                    </span>
                </v-col>
            </v-row>   
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">상태</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    {{blnftItems.exrtSt}}
                </v-col>
            </v-row>     
            <div v-if="rec1Items.bnftOfrRecHisPk > 0">
                <div class="pa-0 mt-1 d-flex mr-2__5" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;font-size:1rem">            
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;">
                        <span class="black--text font-weight-medium">날짜</span>
                    </div>
                    <div style="width:87%">
                        <div class="d-flex">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:14%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">시간</span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:16%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">섭취량</span>
                            </div>
                            <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">기저귀착용</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                        <span class="black--text font-weight-medium">대변</span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">소변</span>
                                    </div>
                                </div>
                            </div>
                            <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">스스로 이동</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                        <span class="black--text font-weight-medium">대변</span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">소변</span>
                                    </div>
                                </div>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;font-size:0.9rem">
                                <div>
                                    <div class="d-flex justify-center align-center">
                                        <span class="black--text font-weight-medium">장루&#40;요루&#41;</span>
                                    </div>
                                    <div class="d-flex justify-center align-center">
                                        <span class="black--text font-weight-medium">도뇨관&#40;ml&#41;</span>
                                    </div>
                                </div>
                            </div>        
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                <div>
                                    <div class="d-flex justify-center align-center">
                                        <span class="black--text font-weight-medium">기저기</span>
                                    </div>
                                    <div class="d-flex justify-center align-center">
                                        <span class="black--text font-weight-medium">교환</span>
                                    </div>
                                </div>
                            </div>      
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                <div>
                                    <div class="d-flex justify-center align-center">
                                        <span class="black--text font-weight-medium">옷</span>
                                    </div>
                                    <div class="d-flex justify-center align-center">
                                        <span class="black--text font-weight-medium">교환</span>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div style="height:560px;overflow-y:scroll;" id="target">
                    <div v-for="(item, i) in rec2List" :key="i" class="d-flex pa-0 ma-0" style="">            
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-bottom: 1px solid #dbdbdb !important;">
                            <div>
                                <div class="d-flex justify-center align-center">{{item.recDt?.beforeDateFormatDot()}}</div>
                                <div class="d-flex justify-center align-center">
                                    <v-btn icon @click="showDialog(i)">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div style="width:87%">
                            <div class="d-flex" v-for="(list, j) in item.subList" :key="j" :class="list.class" style="border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:14%;border-left: 1px solid #dbdbdb !important;">
                                    <span>{{list.ofrTmHhStrt}}</span>
                                    <span>&#58;</span>
                                    <span>{{list.ofrTmMmStrt}}</span>
                                </div>
                                <div class="pa-1 ma-0" style="width:16%;border-left: 1px solid #dbdbdb !important;">
                                    <div style="width:100px;" class="text-overflow">{{list.itm6Val}}</div>
                                </div>   
                                <div class="justify-center align-center pa-0 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;height:100%;">
                                            {{list.itm1Val}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">
                                            {{list.itm2Val}}
                                        </div>
                                    </div>
                                </div>
                                <div class="justify-center align-center pa-0 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;height:100%;">
                                            {{list.itm3Val}}
                                        </div>
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">
                                            {{list.itm4Val}}
                                        </div>
                                    </div>
                                </div>   
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                    {{list.itm5Val}}
                                </div>        
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                    <v-icon v-if="list.itm7Val=='1'" color="blue">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-minus-circle-outline
                                    </v-icon>
                                </div>      
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                                    <v-icon v-if="list.itm8Val=='1'" color="blue">
                                        mdi-check-circle-outline
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-minus-circle-outline
                                    </v-icon>
                                </div>    
                            </div>
                        </div>
                    </div>                
                </div>  
            </div>
            <div v-else class="mt-3 d-inline-block blue001 d-flex justify-center align-center rounded-lg" style="width: 100%; height: 200px; opacity: 0.7;">
                <span class="font-weight-bold white--text" style="font-size: 2rem;">관찰기간을 저장하여 주십시오.</span>
            </div> 
        </v-form>        
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'등록된 모든 관찰기록이 삭제됩니다.'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>  
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[2]" max-width="1400">    
                <cncntExcrtObsrvRcrdWrt 
                    ref="cncntRcrdWrt" 
                    :sltBnftOfrRecPk="sltBnftOfrRecPk"
                    :ymd="ymd"
                    :bnMmbrPk="bnMmbrPk"
                    :bnfcrPk="bnfcrPk"
                    :startDt="basicInfo.endDt"
                    @isClose="isModal(2)" 
                    @cncntExcrtTrigger="cncntExcrtTrigger"
                ></cncntExcrtObsrvRcrdWrt>                  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[3]" content-class="round" max-width="1000" persistent eager>
                <ReportMgmt ref="reportMgmt" @madalState="menu.splice(3, 1, false)"></ReportMgmt>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { selBnftOfrRecList, insBnftOfrRecMulti, selBnfcrStInfo, delBnftOfrHisRec } from '../../api/bnftrcrd.js';
import { selBnfcrPkList } from '../../api/bnfcr.js';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import cncntExcrtObsrvRcrdWrt from '../../components/bnftrcrd/CncntExcrtObsrvRcrdWrt.vue';
import CmmDateComp from '../../components/commons/CmmDateComp.vue';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';

export default {
    name: 'CncntExcrtObsrvRcrd',
    
    components: {
        CmmDateComp,
        basicInfo,
        btnModalDelete,
        cncntExcrtObsrvRcrdWrt,
        ReportMgmt,
    },

    props : {

    },

    created: function(){
        this.onCodeload()        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {            
            this.areaWidth          = 1260 - Number(this.$parent.$parent.midAreaWidth)   
            this.bnMmbrPk           = this.$parent.$parent.sltBnMmBrPk
            this.bnfcrPk            = this.$parent.$parent.sltBnfcrPk
            this.bnftOfrRecHisPk    = this.$parent.$parent.sltBnftrcrdPk
            this.onLoad()   
        })
    },

    computed: {
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        }
    },  

    watch: {
        getBnmmbrPk:function(value){
            this.bnMmbrPk = value   
            this.bnfcrPk            = this.$parent.$parent.sltBnfcrPk
            this.bnftOfrRecHisPk    =  this.$parent.$parent.sltBnftrcrdPk
            this.onLoad()
        },
    },

    methods: {
        onLoad(){            
            this.progress = false
            this.getBnfcrPkList()                   
        },
        onBnfcrLoad(){
            //초기 입소일 시, 분 저장

            let tIdx = this.bnfcrPkList.findIndex(v=>v.bnfcrPk == this.bnfcrPk)

            if(tIdx > -1){    
                this.basicInfo.endDt = this.$moment(this.bnfcrPkList[tIdx].entDt,'YYYY.MM.DD').format('YYYY-MM-DD')
                this.basicInfo.entTmHh = this.bnfcrPkList[tIdx].entTmHh
                this.basicInfo.entTmMm = this.bnfcrPkList[tIdx].entTmMm
            }
            
            this.getData();
        },
        onCodeload(){            
            getMultiStcd(['67', '68', '117'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        codeMapping(res){
            this.codeItem67.splice(0)
            this.codeItem68.splice(0)   
            this.codeItem117.splice(0)

            res.vCd67.forEach(e => {
                this.codeItem67.push(e)
            });

            res.vCd68.forEach(e => {
                this.codeItem68.push(e)
            });
            
            res.vCd117.forEach(e => {
                this.codeItem117.push(e)
            });
        },   
        onSave:function(){
            if(this.bnMmbrPk > 0){
               
                if(this.$refs.form.validate()){
                    let ofrTmHhStrt = ("00"+this.rec1Items.ofrTmHhStrt).slice(-2)

                    let lastDt = this.rec1Items.recDt+" "+ofrTmHhStrt+":00"
                    let itm2Val = this.$moment(lastDt, 'YYYY-MM-DD HH:ss').add(72, 'hours').format('YYYYMMDD')
                    

                    let obj = {
                        list:[
                            {
                                fcltyNum: this.$store.getters.getFcltyNum,
                                bnMmbrPk : this.bnMmbrPk,
                                userNm : this.$store.getters.getUserNm,
                                recDt:this.rec1Items.recDt.afterDateFormatHyp(),
                                ofrTmHhStrt:ofrTmHhStrt,
                                clsfc1Cd:'301',
                                clsfc2Cd:'3',
                                clsfc3Cd:'1',
                                listDtl :[{
                                    recDt:this.rec1Items.recDt.afterDateFormatHyp(),                    
                                    ofrTmHhStrt:ofrTmHhStrt,
                                    clsfc1Cd:'301',
                                    clsfc2Cd:'3',
                                    clsfc3Cd:'1',
                                    itm1Cd:'117',
                                    itm1Val:this.rec1Items.itm1Val,
                                    itm2Val:itm2Val,
                                }]
                            }
                        ]
                    }
                    
                    this.progress = true

                    insBnftOfrRecMulti(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insBnftOfrRecMulti : ' + error))
                }
                

            }else{
                this.snackControll([true, 5000, '수급자를 선택해주세요.', 'warning'])
            }
        },

        // 수급자 구성원(bnfcrPk) 고유번호 조회
        getBnfcrPkList: function (){
            this.bnfcrPkList.splice(0)

            selBnfcrPkList(this.$store.getters.getFcltyNum, this.bnMmbrPk)
                .then((response) => ( this.bnfcrPkRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrPkList : ' + error))
        },

        onSaveAfter:function(res){                        
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])  
                this.bnftOfrRecHisPk = Number(res.result)
                this.$parent.$parent.getTableList()
                this.onLoad()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },          

        bnfcrPkRst: function (res){
            
            if(res.statusCode == '200'){
                if(Array.isArray(res.result)){
                    res.result.forEach((itm, i) => {
                        let obj = ''
                        if(i == 0)
                            obj += '현재 '
                        else
                            obj += '과거 '

                        if(itm.lvDt == null || itm.lvDt == '')
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ~ )')
                        else{
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ')
                            obj += this.$moment(itm.lvDt, 'YYYYMMDD').format('~ YY.MM.DD ) ')
                        }

                        itm.viewTxt = obj

                        //초기 입소일 시, 분 저장
                        if(itm.bnfcrPk == this.bnfcrPk){
                            this.basicInfo.endDt = this.$moment(itm.entDt,'YYYY.MM.DD').format('YYYY-MM-DD')
                            this.basicInfo.entTmHh = itm.entTmHh
                            this.basicInfo.entTmMm = itm.entTmMm
                        }

                    });

                        
                    this.bnfcrPkList = res.result

                    this.getData()
                }
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },

        cncntExcrtTrigger:function(){
            this.onLoad()
            this.$parent.$parent.getTableList()
        },       
        clearForm: function () {
            console.log('clear')
        }, 
        getData:function(){     
                 let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '301',
                    clsfc2Cd: '3',
                    clsfc3CdS:['1','2'],
                    bnMmbrPk: this.bnMmbrPk,
                    bnfcrPk: this.bnfcrPk,
                    inqYMD:this.inqYMD,
                }
    
                selBnftOfrRecList(obj)
                    .then((response) => ( this.getDataAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnfrcrd/selBnftOfrRecList : ' + error))
        },
        getDataAfter:function(res){             
            this.rec1Items.recDt            = ''
            this.rec1Items.ofrTmHhStrt      = ''
            this.rec1Items.itm1Val          = ''
            this.rec1Items.bnftOfrRecPk     = 0
            this.rec1Items.bnftOfrRecHisPk  = 0
            this.cncntDisabled = false
            this.rec2List.splice(0)

            let reclist = []

            res.forEach(el => {
                if(el.clsfc3Cd == '1'){                    
                    this.rec1Items.recDt            = el.recDt.beforeDateFormatHyp()
                    this.rec1Items.ofrTmHhStrt      = el.ofrTmHhStrt                    
                    this.rec1Items.itm1Val          = el.itm1Val
                    this.rec1Items.bnftOfrRecPk     = el.bnftOfrRecPk
                    this.rec1Items.bnftOfrRecHisPk  = el.bnftOfrRecHisPk
                    this.cncntDisabled = true
                } else if(el.clsfc3Cd == '2'){                    
                    reclist.push(el)
                }
            });

            if(this.rec1Items.recDt == ''){
                this.rec1Items.recDt        =  this.basicInfo.endDt
                this.rec1Items.ofrTmHhStrt  =  this.basicInfo.entTmHh
                this.rec1Items.itm1Val      =  '1'
            }else{
                let endDate = this.$moment(this.rec1Items.recDt+' '+this.rec1Items.ofrTmHhStrt, 'YYYY-MM-DD HH').add(71, 'hours')                
                let endDt = endDate.format('YYYY-MM-DD')          
                let getDayCnt = this.getDateDiff(this.rec1Items.recDt, endDt)+1
                

                for(let i=0;i< getDayCnt;i++){
                    let obj = {
                        recDt:'',
                        subList:[]
                    }
                    obj.recDt = this.$moment(this.rec1Items.recDt, 'YYYY-MM-DD').add(i, 'days').format('YYYYMMDD')
                    
                    for(let j=0;j<24;j++){
                        
                        let hh = ("00"+j).slice(-2)
                        let mm = '00'

                        let idx = reclist.findIndex(x=> x.recDt === obj.recDt && x.ofrTmHhStrt === hh)
                        let itm = {
                            ofrTmHhStrt : hh,
                            ofrTmMmStrt : mm,
                            itm1Val     : '',
                            itm2Val     : '',
                            itm3Val     : '',
                            itm4Val     : '',
                            itm5Val     : '',
                            itm6Val     : '',
                            itm7Val     : '',
                            itm8Val     : '',
                            class       : '',
                        }

                        if(idx > -1) {
                            itm.ofrTmMmStrt = reclist[idx].ofrTmMmStrt                            
                            itm.itm5Val = reclist[idx].itm5Val
                            itm.itm6Val = reclist[idx].itm6Val
                            itm.itm7Val = reclist[idx].itm7Val
                            itm.itm8Val = reclist[idx].itm8Val

                            if(reclist[idx].itm1Val !=null) {
                                this.codeItem68.forEach(cd => {
                                    if(reclist[idx].itm1Val == cd.valcd) itm.itm1Val = cd.valcdnm
                                });                        
                            } else {
                                itm.itm1Val = '-'
                            }

                            if(reclist[idx].itm2Val !=null) {
                                this.codeItem68.forEach(cd => {
                                    if(reclist[idx].itm2Val == cd.valcd) itm.itm2Val = cd.valcdnm
                                });                        
                            } else {
                                itm.itm2Val = '-'
                            }

                            if(reclist[idx].itm3Val !=null) {
                                this.codeItem68.forEach(cd => {
                                    if(reclist[idx].itm3Val == cd.valcd) itm.itm3Val = cd.valcdnm
                                });                        
                            } else {
                                itm.itm3Val = '-'
                            }

                            if(reclist[idx].itm4Val !=null) {
                                this.codeItem68.forEach(cd => {
                                    if(reclist[idx].itm4Val == cd.valcd) itm.itm4Val = cd.valcdnm
                                });                        
                            } else {
                                itm.itm4Val = '-'
                            }
                        }
                        let startDate   = this.$moment(this.rec1Items.recDt+' '+this.rec1Items.ofrTmHhStrt, 'YYYY-MM-DD HH').unix()
                        let tm          = this.$moment(obj.recDt+' '+itm.ofrTmHhStrt, 'YYYYMMDD HH').unix()  
                        if(tm >= startDate && tm <= endDate.unix()) itm.class="excrtObsrv-blue-lighten-5"

                        obj.subList.push(itm)
                    }

                    this.rec2List.push(obj)
                }
            }

            this.gelBnfcrStInfo()
        },  
        gelBnfcrStInfo:function(){            
            let fcltyNum = this.$store.getters.getFcltyNum
            let bnmmbr = this.bnMmbrPk

            if(bnmmbr == -1){
                this.blnftItems = Object.assign({})
            }
            
            if(bnmmbr > 0){
                let state = {
                    bnMmbrPk : this.bnMmbrPk,
                    inqYYYY : this.rec1Items.recDt.substr(0,4),
                    inqYMD : this.$moment(this.rec1Items.recDt,'YYYY-MM-DD').format('YYYYMMDD'),
                }
                selBnfcrStInfo(fcltyNum, state)
                            .then((response) => ( this.gelBnfcrStInfoAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftoffer/selBnfcrStInfo : ' + error))
            }
        },
        gelBnfcrStInfoAfter:function(res){
            this.blnftItems = res
        },
        getScroll(e){
            let scrollPostion = e.target.scrollTop;
            let page = Math.floor(scrollPostion/500)
            this.items.list.forEach(x => {
                x.openChk = false
            })

            let cell = (page*13)

            for(let i=0;i<10;i++){                
                this.items.list[cell+i].openChk = true
            }

        },
        showDialog:function(idx){
            this.sltBnftOfrRecPk = 1
            this.ymd = this.rec2List[idx].recDt
            this.menu.splice(2, 1, true)            
        },
        isModal: function (value) {
            if(value == 2) {
                this.sltBnftOfrRecPk = -1
                this.ymd = ''
            }
            this.menu.splice(value, 1, false)
        },        
        onModal: function (idx) {  
            this.idx = idx
            this.menu.splice(1, 1, true)
        },        
        snackControll: function (options) {
            this.snackOptions = options            
        },
        getDateDiff:function(d1, d2) {
            let date1 = new Date(d1);
            let date2 = new Date(d2);            
            let diffDate = date1.getTime() - date2.getTime();            
            return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
        },
        onAllDel:function(){
            this.dialogDelete = true
        },
        onDelete:function(res){
            if(res == 9990){  
                if(this.bnMmbrPk > 0){ 
                    
                    let obj = {list:[
                        {
                            fcltyNum    : this.$store.getters.getFcltyNum,
                            userNm      : this.$store.getters.getUserNm,
                            bnMmbrPk    : this.bnMmbrPk,
                            bnfcrPk     : this.bnfcrPk,
                            clsfc1Cd    : '301',
                            clsfc2Cd    : '3',
                            clsfc3Cd    : '1',
                        },
                    ]}      
                    
                    
                    obj.list.push({
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        userNm      : this.$store.getters.getUserNm,
                        bnMmbrPk    : this.bnMmbrPk,
                        bnfcrPk     : this.bnfcrPk,
                        clsfc1Cd    : '301',
                        clsfc2Cd    : '3',
                        clsfc3Cd    : '2',
                    })

                    delBnftOfrHisRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log(error))
                }
                this.dialogDelete = false
            }else if(res == 7){
                this.dialogDelete = false
            }
            
        },
        onDeleteAfter(res){            
            if(res.statusCode == 200){   
                this.snackControll([true, 2500, res.message, 'info']) 
                this.$parent.$parent.getTableList()
                this.onLoad()       
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false            
        },
        prtPyrprRcrd: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
            this.prtThrowObj.bnfcrPk = this.bnfcrPk
            this.prtThrowObj.prtCd = '48'

            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.menu.splice(3, 1, true)
        },
        setBasicInfo:function(res){
            this.basicInfo.endDt = this.$moment(res.entDt,'YYYY.MM.DD').format('YYYY-MM-DD')
            this.basicInfo.entTmHh = res.entTmHh
            this.basicInfo.entTmMm = res.entTmMm
        }
    },
    data: () => ({
        forceRender:0,
        idx:0,
        srnum:0,
        ymd:'',
        cncntDisabled:false,
        areaWidth: 708,  
        bnMmbrPk:0,
        bnfcrPk:0,
        totTime:72,        
        menu: [false,false,false,false],
        codeItem45:[],
        codeItem67:[],
        codeItem68:[],
        codeItem117:[],
        bnfcrPkList : [],
        blnftItems:{actSt:'',bdSre:'',exrtSt:'',falRsk:'',},
        sltBnftOfrRecPk:-1,
        basicInfo:{endDt:'',entTmHh:'',entTmMm:'',},
        sltItems:{},
        rec1Items:{
            recDt:'',
            ofrTmHhStrt:'',
            endDate:'',
            thrTmHhEnd:'',
            itm1Val:'1',
            bnftOfrRecPk:0,
            bnftOfrRecHisPk:0,
        },    
        rec2Items:{
            bnftOfrRecHisPk:0,
        },    
        rec2List:[],
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'500px', visible: true, disabled: false, },
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '집중 배설관찰 기록지 출력', disabled: true, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },   
        snackOptions: [false, 3000, '', 'info'], 
        dialogDelete:false,
        progress:false,
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, bnfcrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '48',
            inqYmdStart: '', inqYmdEnd: '', inqYMD: '', inqYYYY: '',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>