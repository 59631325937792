<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1280" max-width="1280" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="">
            <div class="text-h4 d-flex align-center pr-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">1-8. 본인부담금 미납관리</span>                
            </div>
        </div>     
        <div class="mt-2">
            <v-tabs hide-slider v-model="tab" color="white" class="black--text"
                active-class="bodyColor" center-active>
                <v-tab class="fmlySend" v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item eager transition="fade-transition">
                    <CopayUnpdAlim ref="copayUnpdAlim" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></CopayUnpdAlim>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <CopayUnpdSms ref="copayUnpdSms" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></CopayUnpdSms>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <CopayUnpdEmail ref="copayUnpdEmail" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></CopayUnpdEmail>
                </v-tab-item>
                <v-tab-item transition="false">
                    <CopayUnpdHist v-if="tab==3" ref="copayUnpdHist"></CopayUnpdHist>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-divider class="mt-1 mr-2__5" style="border:1px dashed #dbdbdb"></v-divider>
    </v-sheet>
</template>

<script>
import CopayUnpdAlim from '../../components/bnfcr/CopayUnpdAlim.vue'
import CopayUnpdSms from '../../components/bnfcr/CopayUnpdSms.vue'
import CopayUnpdEmail from '../../components/bnfcr/CopayUnpdEmail.vue'
import CopayUnpdHist from '../../components/bnfcr/CopayUnpdHist.vue'
import { selAllCopayClmUnpdList } from '../../api/bnfcr.js'

export default {
    name: 'CopayUnpdMgmt',
    
    components: {
       CopayUnpdAlim,
       CopayUnpdSms,
       CopayUnpdEmail,
       CopayUnpdHist,
    },

    props : {

    },

    created: function(){       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllCopayClmUnpdList()
        })
    },

    computed: {
        
    },

    watch: {       
    },

    methods: {  
        //전체 본인부담금 미납금액 다건조회 (미납관리)
        getAllCopayClmUnpdList:function(){            
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                valClcdS    :['62', '63', '65']
            }
            selAllCopayClmUnpdList(obj)
                    .then((response) => ( this.getAllCopayClmUnpdListAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnfcr/selAllCopayClmUnpdList : ' + error))
        },     
        getAllCopayClmUnpdListAfter:function(res){
            this.unpdList.splice(0)

            res.list.forEach(el => {
                el.lnkUrl   = this.encUrlObj(el.bnMmbrPk)
                el.cntnt    = '노인장기요양 미납 청구 안내'
                el.title    = '노인장기요양 미납 청구 안내'

                res.code.vCd62.forEach((cd) => {
                    if(el.acknwRtng == cd.valcd) el.acknwRtngNm = cd.valcdnm
                })

                res.code.vCd63.forEach((cd) => {
                    if(el.copayRate == cd.valcd) el.copayRateNm = cd.valcdnm
                })

                res.code.vCd65.forEach((cd) => {
                    if(el.entStcd == cd.valcd) el.entStcdNm = cd.valcdnm
                })
                this.unpdList.push(el)
            })

            this.$refs.copayUnpdAlim.setData(this.unpdList)
            this.$refs.copayUnpdSms.setData(this.unpdList)
            this.$refs.copayUnpdEmail.setData(this.unpdList)
        },
        encUrlObj: function(pk){
            let linkURI = ''

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {}
            obj.fcltyNum    = this.$store.getters.getFcltyNum
            obj.bnMmbrPk    = pk

            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + 'out/unpdClms?dstnt=' + bs64
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        msgTrigger:function(msg){
            this.snackControll([true, 2500, msg, 'warning'])
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAllCopayClmUnpdList()                
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
    },
    data: () => ({
        tab:0,
        unpdList:[],
        tabItems: [
            { index: 0, tab: '알림톡', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 1, tab: '문자', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 2, tab: '이메일', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 3, tab: '발송내역', style: 'font-size: 1.2rem !important;', enter: false },
        ],  
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>