<template>
    <div class="">
        <v-divider></v-divider>
        <v-data-table
            class="msclnTable tblCelFx" height="708" locale="ko"
            :headers="headers" :items="recHisList" :loading="recHisLoader"
            fixed-header hide-default-footer hide-default-header dense>
            <template v-slot:header="{ props }">
                <thead>
                    <tr>
                        <th :class="props.headers[0].class" :style="props.headers[0].style">
                            {{ props.headers[0].text }}</th>
                        <th :class="props.headers[1].class" :style="props.headers[1].style">
                            {{ props.headers[1].text }}</th>
                        <th :class="props.headers[2].class" :style="props.headers[2].style">
                            {{ props.headers[2].text }}</th>
                        <th :class="props.headers[3].class" :style="props.headers[3].style">
                            {{ props.headers[3].text }}</th>
                        <th :class="props.headers[4].class" :style="props.headers[4].style">
                            {{ props.headers[4].text }}</th>
                        <th :class="props.headers[5].class" :style="props.headers[5].style">
                            {{ props.headers[5].text }}</th>
                        <th :class="props.headers[6].class" :style="props.headers[6].style">
                            {{ props.headers[6].text }}</th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item }'>
                <tr>
                    <td class="tableBR tableBB clmsBodyCommon pa-1">
                        <span>{{ $moment(item.recDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon pa-1" style="line-height: 1.3;">
                        <div
                            v-for="(itm, i) in item.bdsrPrtAr" :key="i"
                            class="txtAlgnFst">
                            <span class="fontEighthRem">{{ itm }}</span>
                        </div>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon pa-1">
                        <span>{{ item.bdsrDgr }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon pa-1">
                        <span>{{ item.bdsrTrt }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon pa-1">
                        <span>{{ item.excrtClNm }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon txt-break pa-1">
                        <span>{{ item.excrtTrtTxt }}</span>
                    </td>
                    <td class="tableBB clmsBodyCommon txt-break pa-1">
                        <span>{{ item.excrtCntnt }}</span>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-divider></v-divider>
    </div>
</template>
<script>
import { selBtcInqNrsRecHisList } from '../../api/bnfcrStts';

export default {
    name: 'NsgRcrdSttsTab2',

    props: {
     
    },
    
    components: {

    },

    
    created: function () {
        
    },
    
    mounted: function () {
        this.$nextTick(function () {
            
        })
    },

    computed: {
        getBnMmbrPk: function () {
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getSrchCode: function () {
            return this.$parent.$parent.$parent.$parent.srchCd
        },
        getSltYMD: function () {
            return this.$parent.$parent.$parent.$parent.sltYmd
        },
        getSltYYMM: function () {
            return this.$parent.$parent.$parent.$parent.sltYymm
        },
        getSltYYYY: function () {
            return this.$parent.$parent.$parent.$parent.sltYyyy
        },
    },

    watch: {
        getBnMmbrPk: function ( v ) {
            if(v > 0){
                switch (this.$parent.$parent.$parent.$parent.srchCd) {
                    case 0:
                        this.getBtcInqNrsRecHisList(this.$parent.$parent.$parent.$parent.sltYmd, null, null)
                        break;
                    case 1:
                        this.getBtcInqNrsRecHisList(null, this.$parent.$parent.$parent.$parent.sltYymm, null)
                        break;
                    case 2:
                        this.getBtcInqNrsRecHisList(null, null, this.$parent.$parent.$parent.$parent.sltYyyy)
                        break;
                    default:
                        break;
                }
            }
            else
                this.recHisList.splice(0)
        },
        getSrchCode: function () {
            this.$parent.$parent.$parent.$parent.sltYyyy = ''
            this.$parent.$parent.$parent.$parent.sltYymm = ''
            this.$parent.$parent.$parent.$parent.sltYmd = ''
        },
        getSltYMD: function (ymd) {
            if(ymd != '' && this.$store.getters.getBnfcrState.bnmmbr > 0){
                if(this.$parent.$parent.$parent.$parent.srchCd == 0)
                    this.getBtcInqNrsRecHisList(this.$parent.$parent.$parent.$parent.sltYmd, null, null)
                else
                    this.recHisList.splice(0)
            }
        },
        getSltYYMM: function (yymm) {
            if(yymm != '' && this.$store.getters.getBnfcrState.bnmmbr > 0){
                if(this.$parent.$parent.$parent.$parent.srchCd == 1)
                    this.getBtcInqNrsRecHisList(null, this.$parent.$parent.$parent.$parent.sltYymm, null)
                else
                    this.recHisList.splice(0)
            }
        },
        getSltYYYY: function (yyyy) {
            if(yyyy != '' && this.$store.getters.getBnfcrState.bnmmbr > 0){
                if(this.$parent.$parent.$parent.$parent.srchCd == 2)
                    this.getBtcInqNrsRecHisList(null, null, this.$parent.$parent.$parent.$parent.sltYyyy)
                else
                    this.recHisList.splice(0)
            }
        },
        'recHisList': function () {
            this.$parent.$parent.$parent.$parent.totCnt.splice(1, 1, this.recHisList.length)
        },
    },

    methods: {
        getBtcInqNrsRecHisList: function (ymd, yymm, yyyy) {
            this.recHisList.splice(0)
            this.recHisLoader = true

            selBtcInqNrsRecHisList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, ymd, yymm, yyyy)
                .then((response) => ( this.selBtcInqNrsRecHisListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBtcInqNrsRecHisList : ' + error))
        },
        selBtcInqNrsRecHisListAfter: function (res) {
            if(res.length > 0){

                res.forEach(itm => {
                    itm.bdsrPrtTxt = ''

                    itm.excrtTrtTxt = ''
                    itm.excrtTrtAr.forEach(idx => {
                        itm.excrtTrtTxt += idx + ', '
                    });

                    if(itm.excrtTrtTxt.length > 0)
                        itm.excrtTrtTxt = itm.excrtTrtTxt.slice(0, -2)
                });

                this.recHisList = res
            }

            this.recHisLoader = false
        }, 
    },
    data: () => ({
        recHisLoader: false,
        recHisList: [],
        headers: [
            { text: '처치일자', value: 'recDt', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR py-2', style: 'width: 90px !important;' },
            { text: '욕창부위', value: 'bdsrPrtAr', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 114px !important;' },
            { text: '욕창정도', value: 'bdsrDgr', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 90px !important;' },
            { text: '조치내용', value: 'bdsrTrt', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 151px !important;' },
            { text: '배설간호구분', value: 'excrtClNm', align: 'center', on: false, sortable: true,class: 'black--text clmsHeaderCommon tableBR', style: 'width: 100px !important;' },
            { text: '배설조치', value: 'excrtTrtAr', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 108px !important;' },
            { text: '배설간호내용', value: 'excrtCntnt', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon', style: 'width: 151px !important;' },
        ],
    }),
};
</script>