<template>
    <v-app class="minimumSize"
        :style="{ background: $vuetify.theme.themes[theme].bckgrColor }">
        <v-main class="d-flex align-center">
            <v-row class="ma-0 pa-0" no-gutters>
                <v-spacer></v-spacer>
                <v-col class="" cols="6">
                    <v-card
                        class="py-8 px-10 d-flex justify-center align-center"
                        height="660"
                        elevation="9" rounded="xl">
                        <div>
                            <div class="d-flex justify-center">
                                <span class="d-inline-block d-flex justify-center align-center notFound" style="">
                                    <span class="white--text font-weight-bold pb-4 pr-1" style="font-size: 8rem;">404</span>
                                </span>
                            </div>
                            <div class="blue001--text font-weight-bold mt-12 mb-6" style="font-size: 2rem !important;">
                                페이지를 찾을 수 없습니다.
                            </div>
                            <div class="" style="font-size: 1.2rem !important;">
                                원하는 결과를 찾을 수 없습니다.
                            </div>
                            <div class="" style="font-size: 1.2rem !important;">
                                올바른 URL을 입력하였는지 확인하세요. 자세한 내용은 관리자에게 문의하시기 바랍니다.
                            </div>
                            <div class="mt-10">
                                <v-btn class="notFoundBtn mr-5" color="blue001" outlined @click="$router.go(-1)">이전 페이지로</v-btn>
                                <v-btn class="notFoundBtn" color="blue001" outlined @click="$router.push({ path: '/' })">초기화면으로</v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'NotFound',

    components: {
       
    },

    props: {

    },

    created: function(){
    },

    mounted: function(){
        this.$nextTick(function () {
         
        })
    },

    computed: {
        theme: function() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
    },

    watch:{
  
    },
        
    methods: {

    },

    data: () => ({
        
    }),
};
</script>

<style>
    .notFound { width: 300px; height: 300px; background-color: #005593; clip-path: circle(50% at 50% 50%); }
    .notFoundBtn span { font-size: 1rem; }
</style>