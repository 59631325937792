<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">공제 항목 관리</span>
            <v-spacer></v-spacer>            
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click = "onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" :disabled="list.disabled" rounded
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation>            
            <div style="width:100%">                               
                <v-divider :class="['mt-2', empWgePymDctnList.length >  8 ? 'mr-2__5' : '']"></v-divider>
                <div :class="['greyE01 d-flex pa-0', empWgePymDctnList.length >  8 ? 'mr-2__5' : '']">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">                                
                        <span class="black--text font-weight-medium">공제항목</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                        <span class="black--text font-weight-medium">공제액</span>
                    </div>
                </div>
                <v-divider :class="[empWgePymDctnList.length >  8 ? 'mr-2__5' : '']"></v-divider>
                <div class="overflow-y-auto" style="height:418px;">
                    <div v-if="empWgePymDctnList.length > 0">
                        <div v-for="(item,m) in empWgePymDctnList" :key="m" class="d-flex pa-0 tableBB">
                            <div class="d-flex justify-center align-center py-1 pl-2ma-0" style="width:50%;">                                
                                {{item.itmNm}}
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                                <v-row no-gutters>
                                    <v-col class="txtfldRT" cols="12">
                                        <v-text-field
                                            v-model="item.amt" color="blue" type="number" min="0" @input="calcTotPym()"
                                            hide-details outlined dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="d-flex justify-end pr-2" cols="12" style="font-size:0.75rem !important">
                                        {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex pa-0 tableBB" style="width:100%">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                            데이터가 없습니다.
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex pa-0 ma-0">
                    <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:50%;">                                
                        <span class="black--text font-weight-medium">공제액 합계</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                        {{empWgePymDctnAmt?.toLocaleString('ko-KR')}}
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex pa-0 ma-0">
                    <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:50%;">                                
                        <span class="black--text font-weight-medium">실수령액</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL" style="width:50%;">                                
                        {{empWgePymRealAmt?.toLocaleString('ko-KR')}}
                    </div>
                </div>       
                <v-divider class=""></v-divider>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'기관부담금 재계산후 저장됩니다.'" :contents="''" @madalState="onEmpWgeSave"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

import { updWgeDctnMulti } from '../../api/impExp.js';

export default {
    name: 'EmpWageStmtDctnWrt',
    
    components: {  
        btnModalDelete,
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){   
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        
    },

    methods: { 
        setData: function(data){               
            if(this.$parent.$parent.$parent.$parent.cnfrm) this.filledBtn[0].disabled = true 
            else  this.filledBtn[0].disabled = false 

            this.empWgePymDctnList.splice(0)      
            let list  = JSON.parse(JSON.stringify(data))

            list.forEach(item => {
                if(item.amt == '' || item.amt == null) item.amt = 0
                if(item.calcClcd == '2')
                    this.empWgePymDctnList.push(item)           
                
            })

            this.calcTotPym()            
        },      

        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.dialogDelete = true
                    break;                
                default:
                    break;
            }
        },
        onEmpWgeSave:function(res){
            if(res == 9990){
                let pObj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    empPks:[this.$parent.$parent.$parent.$parent.empPk],
                    empPk: this.$parent.$parent.$parent.$parent.empPk,
                    userNm:this.$store.getters.getUserNm,
                    inqYYMM:this.$parent.$parent.$parent.$parent.date,
                    stdYymm:this.$parent.$parent.$parent.$parent.date,
                    mmbrPk: this.$parent.$parent.$parent.$parent.mmbrPk,
                    rmrks:this.$parent.$parent.$parent.$parent.empItems.rmrks,
                    pymDt: this.$moment(this.$parent.$parent.$parent.$parent.empWgeItems.pymDt, 'YYYY-MM-DD', true).format('YYYYMMDD'),
                    list:[],
                }

                if(!this.$parent.$parent.$parent.$parent.cnfrm){
                    
                    this.empWgePymDctnList.forEach(item => {  
                        item.trgt = '2'                      
                        pObj.list.push(item)
                    });

                    updWgeDctnMulti(pObj)
                        .then((response) => ( this.onEmpWgeSaveAfter(response.data) ))
                        .catch((error) => console.log('connect error /impExp/updWgeDctnMulti : ' + error))
                }
                
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onEmpWgeSaveAfter:function(res){
            this.$emit('mdlTrigger', res)
            this.onClose()
            this.dialogDelete = false
        },
          
        //지급, 공제 항목 합계 계산
        calcTotPym: function () {            
            let dctnAmt = 0

            this.empWgePymDctnList.forEach(dctn => {
                dctnAmt += Number(dctn.amt)
            });

            this.empWgePymDctnAmt = dctnAmt
            this.empWgePymRealAmt = this.$parent.$parent.$parent.$parent.empWgePymTotAmt - dctnAmt
        },                
        onClose: function () {
            this.$emit('modalClose', 0)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({ 
        forceRender:0,
        empPk:0,        
        empWgePymDctnAmt:0,
        empWgePymRealAmt:0,        
        empWgePymDctnList:[],       
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
        ],
        outlinedBtn: [            
        ],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
    }),
};
</script>