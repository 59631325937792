<template>
    <div class="d-flex justify-center align-center">
        <v-btn class=" mr-1" color="btnBrdrColor" x-small outlined @click="isPrev"
            style="background-color: white; width:26px;" height="26">
            <span class="black--text">
                <v-icon>mdi-chevron-left</v-icon>
            </span>
        </v-btn>
        <v-btn class="" color="btnBrdrColor" x-small outlined @click="isNext"
            style="background-color: white; width:26px;" height="26">
            <span class="black--text">
                <v-icon>mdi-chevron-right</v-icon>
            </span>
        </v-btn>

        <span class="text-h5 font-weight-bold mx-3" style="color: #004584;">{{sltQtr}}분기</span>

        <span class="d-inline-flex">
            <v-btn class="" color="btnBrdrColor" x-small outlined @click="isOpen"
                style="background-color: white; width:26px;" height="26">
                <span class="black--text">
                    <v-icon small>mdi-calendar-month</v-icon>
                </span>
            </v-btn>
            <v-expand-transition>
                <v-card v-show="menuEx" height="126" style="position: absolute; z-index: 11;" v-click-outside="onClickOutside"
                    class="mt-7 black--text" outlined width="90">
                    <div class="" v-for="i in nowQtr" :key="i">
                        <div class="py-1 yearRow" style="text-align: center;" ref="qtrRef" @click="onClicked(i)">
                            {{i}}분기
                        </div>
                    </div>
                </v-card>
            </v-expand-transition>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'expandQtrPicker',

        props : {
            inQtr: { type: Number, default: 1 },
        },
        
        components: {
        
        },

        created: function(){
            //확장 패널에 분기 추가
            for(var i = 0; i < 4; i++){
                this.nowQtr.push(i+1)
            }
        },

        mounted: function(){
            this.$nextTick(function () {
                this.$refs.qtrRef[this.sltQtr - 1].style="text-align: center; background: #ebf6fe !important;"
                this.$emit('nowQtr', this.sltQtr)
            })
        },

        watch: {
            sltQtr: function(){
                this.$refs.qtrRef.forEach(element => {
                    element.style="text-align: center;"
                })
                this.$refs.qtrRef[this.sltQtr - 1].style="text-align: center; background: #ebf6fe !important;"
            },
            inQtr: function(){
                this.sltQtr = this.inQtr
            }
        },
    
        methods: {
            isOpen: function (){
                this.menuEx = !this.menuEx
                this.extChk = 1
            },
            isClose: function (){
                this.menuEx = false
            },
            isPrev: function (){
                if(Math.min.apply(null, this.nowQtr) < this.sltQtr )
                    this.sltQtr -= 1

                this.$emit('nowQtr', this.sltQtr)
            },
            isNext: function (){
                if(Math.max.apply(null, this.nowQtr) > this.sltQtr )
                    this.sltQtr += 1

                this.$emit('nowQtr', this.sltQtr)
            },
            onClicked: function (item){
                this.sltQtr = item
                this.menuEx = false
                this.$emit('nowQtr', this.sltQtr)
            },
            onClickOutside: function (){
                if(this.extChk != 1) this.menuEx = false
                this.extChk = 0
            },
        },

        data: () => ({
            menuEx: false,
            nowQtr: [],
            extChk: 0,
            ckBoxAll: 'all',
            sltQtr: 1,
            allBtnState: true,
            ckBox: [],
        }),
    };
</script>

<style>
    .theme--light.v-label{ color: #666 !important; font-size: 0.98rem; }
    .v-input .v-label{line-height: 21px;}
    .yearRow:hover { background: #ebf6fe !important; }
</style>