<template>
    <v-sheet class="mt-2 pa-0">
        <v-divider></v-divider>
        <v-form ref="extInstrInfo" lazy-validation>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="" cols="12">
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">외부강사명</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBL tableBR py-1" cols="5">
                            <span class="d-inline-block mx-1" style="width: 100%;">
                                <v-text-field  v-model="empInfo.name" :rules="[rules.required]"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">성별</span>
                        </v-col>
                        <v-col class="tableBL px-1 py-1" cols="3">
                            <span class="">
                                <v-radio-group class="pl-2 pt-1" v-model="empInfo.gndr" hide-details row>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="남" value="M"></v-radio>
                                    <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="여" value="F"></v-radio>
                                </v-radio-group>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">강사료</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBL tableBR py-1" cols="5">
                            <span class="d-inline-block mx-1" style="width: 100%;">
                                <v-text-field v-model="empInfo.wage" type="number"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">자격증</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBL py-1" cols="3">
                            <span class="d-inline-block mx-1" style="width: 100%;">
                                <v-text-field v-model="empInfo.crtfcNm"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">연락처</span>
                        </v-col>
                        <v-col class="d-flex align-center tableBL px-1 py-1" cols="10">
                            <span class="d-inline-block" style="width: 80px;">
                                <v-text-field v-model="empInfo.celphn_1"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="d-inline-block d-flex align-center px-1">
                                <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 80px;">
                                <v-text-field v-model="empInfo.celphn_2"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="d-inline-block d-flex align-center px-1">
                                <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 80px;">
                                <v-text-field v-model="empInfo.celphn_3"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">계약기간</span>
                        </v-col>
                        <v-col class="d-flex align-center tableBL px-1 py-1" cols="5">
                            <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp
                                    v-model="empInfo.entDt" :required="false">
                                </CmmDateComp>
                            </span>
                            <span class="d-flex align-center mx-2">
                                <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                            </span>
                            <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp
                                    v-model="empInfo.rtrDt" :required="false">
                                </CmmDateComp>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">소속</span>
                        </v-col>
                        <v-col class="d-flex align-center tableBL px-1 py-1" cols="3">
                            <span class="d-inline-block" style="width: 80px;">
                                <v-select
                                    class="wrkSelect"
                                    v-model="empInfo.afltnClcd" :key="forceRender"
                                    :items="extSrtt" item-text="valcdnm" item-value="valcd"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                            <span class="d-inline-block ml-1" style="width: 90px;" v-if="empInfo.afltnClcd == '2'">
                                <v-text-field v-model="empInfo.afltnNm"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">비고</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBL py-1" cols="10">
                            <span class="d-inline-block mx-1" style="width: 100%;">
                                <v-text-field v-model="empInfo.rmrks"
                                    color="blue" hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                            <span class="black--text font-weight-medium">파일첨부</span>
                            <span class="d-inline-block">
                                <v-file-input
                                    class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                                    prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                                </v-file-input>
                            </span>
                        </v-col>
                        <v-col class="tableBL pa-2" cols="10">
                            <span class="pb-2 pr-2"
                                v-for="(item, j) in fileList" :key="j">
                                <v-chip
                                    class="mt-1" label close outlined
                                    @click="athFileDownload(j)" @click:close="delAtchfiles(j)">
                                    <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                        mdi-file-powerpoint
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                        mdi-file-word
                                    </v-icon>
                                    <v-icon v-else color='cyan' left>
                                        mdi-file-document
                                    </v-icon>
                                    <span class="longText">{{ item.orgFileNm }}</span>
                                </v-chip>
                            </span>
                            <v-divider class="my-2"></v-divider>
                            <div>
                                <span class="d-inline-block mx-1"
                                    v-for="( item, i ) in imgList" :key="i">
                                    <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)"></imageCard>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" height="26" @click="preExtEmpsInfo()" samll rounded>
                    <v-icon small>mdi-content-save-outline</v-icon>
                    <span style="font-size: 1rem;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import imageCard from '../commons/imageCard.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

import { insExtEmp } from '../../api/emp.js';
import { tmpFileUpload } from '../../api/index.js';
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'ExtInstr',

    props : {
      
    },
        
    components: {
        imageCard,
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        setEmpInfo: function (obj) {
            insExtEmp(obj)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/insExtEmp : ' + error))
        },
        //첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        asyncResultChk: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.getExtEmpList()
                this.clearForm()
                this.$parent.$parent.$parent.$parent.onClose()
            }
            else
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },
        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },
        preExtEmpsInfo: function () {
            let obj = Object.assign({}, this.empInfo )
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.etcFiles = []

            if(obj.entDt != '')
                obj.entDt = obj.entDt?.afterDateFormatHyp()
            if(obj.rtrDt != '')
                obj.rtrDt = obj.rtrDt?.afterDateFormatHyp()

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.etcFiles.push(items)
                }
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.etcFiles.push(items)
                }
            })

            let chk = this.$refs.extInstrInfo.validate()
            if(chk)
                this.setEmpInfo(obj)
        },
        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            }
            else {
                this.imgList.splice(idx, 1)
            }
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        clearForm: function () {
            this.empInfo = Object.assign({
                empPk: 0, empWgePk: 0, empEtcPk: 0,
                name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', wgeTyp: '3', wage: 0, jobCd: '16', pstnCd: '5',
                celphn_1: '', celphn_2: '', celphn_3: '', etcClsfc1Cd: '2', etcClsfc2Cd: '5', rmrks: '', gndr: 'M', wrkStcd: '1',
            })

            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            this.forceRender += 1
        },
    },

    data: () => ({
        menu: [false, false,],
        files: null,
        fileList: [],
        imgList: [],
        extSrtt: [
            { valcd: '1', valcdnm: '개인' },
            { valcd: '2', valcdnm: '단체' },
        ],
        empInfo: {
            empPk: 0, empWgePk: 0, empEtcPk: 0,
            name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', wgeTyp: '3', wage: 0, jobCd: '16', pstnCd: '5',
            celphn_1: '', celphn_2: '', celphn_3: '', etcClsfc1Cd: '2', etcClsfc2Cd: '5', rmrks: '', gndr: 'M', wrkStcd: '1',
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            pwdSizeCheck: value => (value && value.length > 3) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
        },
        forceRender: 0,
    }),
};
</script>