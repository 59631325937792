<template>
    <v-system-bar class="pa-0 ma-0" color="bckgrColor" height="28">
        <v-spacer></v-spacer>
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2 mr-2"
            @click="menu.splice(0, 1, true)"
            dense small color="topBtnColor" elevation="3">
            <v-img class="ic-notice" src="../styles/images/ic-notice-s.svg"></v-img>
            <span>기관공지</span>
        </v-btn>
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2 mr-2"
            @click="menu.splice(1, 1, true)"
            dense small color="topBtnColor" elevation="3">
            <v-img class="ic-notice" src="../styles/images/ic-notice-s.svg"></v-img>
            <span>YYS 공지</span>
        </v-btn>
        <NotifyCard class="mr-2"></NotifyCard>
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2"
            dense small color="topBtnColor" elevation="3" @click="cnfLogout()">
            <v-img class="ic-notice" src="../styles/images/ic-logout.svg"></v-img>
            <span>로그아웃</span>
        </v-btn>
        <v-dialog v-model="menu[0]" max-width="1000" content-class="round" persistent>
            <FcltyNtcMdl ref="fcltyNtcMdl" @modalClose="modalChanger"></FcltyNtcMdl>
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="1000" content-class="round" persistent>
            <YysNtcMdl ref="yysNtcMdl" @modalClose="modalChanger"></YysNtcMdl>
        </v-dialog>
    </v-system-bar>
</template>

<script>
import { http } from '@/api/baseAxios';

import FcltyNtcMdl from '../components/othr/FcltyNtcMdl.vue';
import YysNtcMdl from '../components/othr/YysNtcMdl.vue';
import NotifyCard from './NotifyCard.vue';

import VueJwtDecode from 'vue-jwt-decode'

export default {
    name: 'HeaderBar',
    
    components: {
        FcltyNtcMdl,
        NotifyCard,
        YysNtcMdl,
    },
    mounted: function(){

    },

    methods: {
        //로그아웃
        cnfLogout: function (){
            http.post('auth/logout', { fcltyNum: this.$store.getters.getFcltyNum, userNm: this.$store.getters.getUserNm })
                .then((response) => ( this.clearUserInfo(response.data) ))
                .catch((error) => console.log('axios error /auth/logout : ' + error))
        },
        //로그인 연장
        cnfRefreshToken: function (tk){
            http.post('auth/insRefreshToken', { token: tk })
                .then((response) => ( this.cnfRefreshTokenAfter(response.data) ))
                .catch((error) => console.log('axios error /auth/insRefreshToken : ' + error))
        },
        clearUserInfo: function (res){
            console.log(res)
            this.$store.commit('setAuthToken', null)
            this.$store.commit('setFcltyNum', 0)
            this.$store.commit('setUserNm', '')
            this.$store.commit('setSsMmbrPk', 0)
            this.$store.commit('setAcesAthrt', [])

            this.$router.go(0)
        },
        cnfRefreshTokenAfter: function (res){
            if(res.statusCode == '200'){
                let jwtDecode = null

                //로그인 인증 토큰 복호화
                let bytes = this.$crypto.AES.decrypt(res.token, this.$crypto.enc.Hex.parse(res.key), {
                    iv: this.$crypto.enc.Hex.parse("000102030405060708090A0B0C0D0E0F"),
                    padding: this.$crypto.pad.Pkcs7,
                    mode: this.$crypto.mode.CBC
                })

                //토큰정보를 얻어옴
                jwtDecode = VueJwtDecode.decode(bytes.toString(this.$crypto.enc.Utf8))

                //토큰정보를 vuex에 저장
                this.$store.commit('setAuthToken', bytes.toString(this.$crypto.enc.Utf8))
                this.$store.commit('setFcltyNum', jwtDecode.fcltyNum)
                this.$store.commit('setUserNm', decodeURI(jwtDecode.name))
                this.$store.commit('setSsMmbrPk', jwtDecode.mmbrPk)
                this.$store.commit('setExpTime', jwtDecode.exp)

                //this.$router.go(0)
            }
            else{
                this.clearUserInfo(res)
            }
        },
        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },
    },
    
    data: () => ({
        menu: [ false, false, false, ],
    }),
};
</script>