<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">금액설정</span>
            <v-spacer></v-spacer>                            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>                        
        </div>
        <div class="d-flex">
            <div class="pl-2" style="width:60%">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 20px;" color="black">mdi-chevron-down</v-icon>
                    <span class="text-h6">지급총액</span>
                </div>
                <div :class="['d-flex', paymtsList.length > 10 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">
                        <span class="black--text font-weight-medium">임금항목</span>
                    </div>                                        
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">과세구분</span>
                    </div>                                        
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">지급액</span>
                    </div>
                </div>
                <div v-if="paymtsList.length > 0" class="overflow-y-auto" style="height:370px;"> 
                    <div v-for="(item, m) in paymtsList" :key="m" >
                        <div v-if="item.wgeItmClcd != '7'"  class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;">
                                {{item.itmNm}}
                            </div>                                        
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                {{item.txClcdNm}}
                            </div>                                        
                            <div class="d-flex align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <v-row no-gutters>
                                    <v-col class="txtfldRT" cols="6">
                                        <v-text-field v-model="item.amt" color="blue" type="number" min="0" @input="getTotCalcl" hide-details outlined dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex justify-end pr-2" cols="6">
                                        {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}} 원
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex pa-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                        <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                    </div>
                </div>
                <div :class="['d-flex', paymtsList.length > 10 ? 'mr-2__5' : '']" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                        <span class="black--text font-weight-medium">합계</span>
                    </div>                                        
                    <div class="greyE01 d-flex justify-end align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                        {{paymtsTotAmt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}} 원
                    </div>
                </div>
            </div>
            <div class="pl-2" style="width:40%">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 20px;" color="black">mdi-chevron-down</v-icon>
                    <span class="text-h6">공제총액</span>
                </div>
                <div :class="['d-flex', ddctnList.length > 10 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;">
                        <span class="black--text font-weight-medium">공제항목</span>
                    </div>                                        
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:70%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">공제액</span>
                    </div>
                </div>
                <div v-if="ddctnList.length > 0" class="overflow-y-auto" style="height:370px;"> 
                    <div v-for="(item, m) in ddctnList" :key="m" class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;">
                            {{item.itmNm}}
                        </div>                                         
                        <div class="d-flex align-center pa-1 ma-0" style="width:70%;border-left: 1px solid #dbdbdb !important;">
                            <v-row no-gutters>
                                <v-col class="txtfldRT" cols="6">
                                    <v-text-field v-model="item.amt" color="blue" type="number" min="0" @input="getTotCalcl" hide-details outlined dense></v-text-field>
                                </v-col>
                                <v-col class="d-flex justify-end pr-2" cols="6">
                                    {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}} 원
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex pa-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                        <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                    </div>
                </div>
                <div :class="['d-flex', ddctnList.length > 10 ? 'mr-2__5' : '']" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                        <span class="black--text font-weight-medium">합계</span>
                    </div>                                        
                    <div class="greyE01 d-flex justify-end align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                        {{ddctnTotAmt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}} 원
                    </div>
                </div>
            </div>
        </div>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="setPayMnt" height="30" samll rounded>
                    <v-icon>mdi-content-save-outline</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
export default {
    name: 'EmpWageInfPayMnt',
    
    components: {  
    },

    props : {
        empWageInfPayMntPk : {type:Number, default:-1}
    },

    computed: {
        
    },

    created: function(){  
    },

    mounted: function(){
        this.$nextTick(function () {
            this.getList()
        })
    },

    watch: {
        'empWageInfPayMntPk':function(value){
            if(value > 0){
                this.getList()
            }
        }
    },

    methods: {       
        onClose:function(){
            this.$emit("modalClose")
        },   
        getList:function(){
            
            this.paymtsList.splice(0)
            this.ddctnList.splice(0)

            this.paymtsList = JSON.parse(JSON.stringify(this.$parent.$parent.$parent.$parent.paymtsList))
            this.ddctnList = JSON.parse(JSON.stringify(this.$parent.$parent.$parent.$parent.ddctnList))
            
            this.getTotCalcl()
        },
        setPayMnt: function(){
            // this.$emit("setPayMnt",this.paymtsList,this.ddctnList)

        
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.mmbrPk = this.$parent.$parent.$parent.$parent.empWgeItems.mmbrPk
            obj.empPk = this.$parent.$parent.$parent.$parent.empWgeItems.empPk
            obj.empWgeYn = '2'
            obj.list = []

            this.paymtsList.forEach(item => {
                obj.list.push(item)
            });
            this.ddctnList.forEach(item => {
                obj.list.push(item)
            });

            this.$parent.$parent.$parent.$parent.setMultiEmpWgeItm(obj)
          

            this.onClose()
        },
        getTotCalcl:function(){
            this.paymtsTotAmt = 0
            this.paymtsList.forEach(el => {
                if(el.wgeItmClcd != '7'){
                    this.paymtsTotAmt += Number(el.amt)
                }
            });

            this.ddctnTotAmt = 0
            this.ddctnList.forEach(el => {
                this.ddctnTotAmt += Number(el.amt)
            });
        }
    },

    data: () => ({ 
        paymtsTotAmt : 0,
        paymtsList:[],
        ddctnTotAmt : 0,
        ddctnList:[],
    }),
};
</script>