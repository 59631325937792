<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        
        <div class="d-flex justify-left mb-3">
            <span class="text-h4 font-weight-bold">회의록&nbsp;전자서명</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-3">
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="ma-0 pa-0" cols="5">                            
                     <v-data-table hide-default-footer dense locale="ko" height="370"
                        hide-default-header fixed-header 
                        :headers="oprMtngSignHeaders" 
                        :items="oprMtngSignList" 
                        class="" disable-pagination>
                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>                            
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;">{{props.headers[0].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;">{{props.headers[1].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBT" style="width: 100px !important;">{{props.headers[2].text}}</th>
                                </tr>
                            </thead>
                        </template>                
                        <template v-slot:item='{ item }'>
                            <tr :class="['fixedCol', sltMtngAtnd == item.mtngAtndPk ? 'sltRow' : 'disSltRow']" @click="tableClickEvent(item)">
                                <td class="fixedCol0 clmsBodyCommon tableBR">{{item.name}}</td>
                                <td class="fixedCol0 clmsBodyCommon tableBR">{{item.pstnNm}}</td>
                                <td class="fixedCol0 clmsBodyCommon">
                                    <v-icon v-if="item.eSigPk > 0">mdi-circle-outline</v-icon>
                                    <v-icon v-else>mdi-close</v-icon>
                                </td>                        
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>                        
                <v-col cols="7" class="pl-3">                     
                    <div v-if="signCanv" class="d-flex justify-center">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="ma-0 pa-0" cols="11">          
                                <vue-esign class="mt-0 rounded" style="border: 3px solid #4bc5e8;"
                                ref="esign" format="image/png" :width="400" :height="300"
                                :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor"/>
                            </v-col>
                            <v-col class="ma-0 pa-0" cols="1">
                                <v-btn color="blueBtnColor" height="30" @click="handleGenerate()" icon>
                                    <v-icon>mdi-content-save-edit-outline</v-icon>
                                </v-btn>  
                                <v-btn color="grey006" height="30" @click="handleReset()" icon>
                                    <v-icon>mdi-eraser</v-icon>
                                </v-btn>                      
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="signImg" class="d-flex justify-center">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="ma-0 pa-0" cols="11">   
                                <div class="mt-0 rounded" style="border: 3px solid #4bc5e8;">
                                    <v-img :src="imgSrc" :width="imgWidth"></v-img>
                                </div>
                            </v-col>
                            <v-col class="ma-0 pa-0" cols="1">                               
                                <v-btn color="grey006" height="30" @click="onDelSign()" icon>
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>                      
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="sltMtngAtnd == -1" class="d-inline-block d-flex justify-center align-center rounded-lg" style="width: 100%; height: 100%; opacity: 0.7;border: 3px solid #4bc5e8;">
                        <span class="font-weight-bold red--text" style="font-size: 1.5rem;">좌측 메뉴에서 선택후 이용해주세요.</span>
                    </div>
                </v-col>                        
            </v-row>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'전자서명'" @madalState="delSignAct"></btnModalDelete>  
            </v-dialog>
        </div> 
    </v-sheet>
</template>

<script>
import { remoteURL } from '@/api/baseAxios';
import { selMtngAtndSigList } from '../../api/opr.js';
import { uploadSIgn, delESig } from '../../api/index.js';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
export default {
    name: 'oprMtngSign',
    props: {
        oprMtngSignPk : { type: Number, default: -1 },
    },
    components: {
        btnModalDelete
    },
    watch: {
        'oprMtngSignPk':function (value){
            if(this.$refs.esign != undefined) this.$refs.esign.reset()
            this.sign = ''
            this.signCanv = false
            this.signImg = false 
            this.oprMtngSignList.splice(0)

            if(value > 0) {
                this.mtngRcrdPk = value            
                this.selMtngAtndSigList()
            } 
        }
    },
    created: function () {

    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$refs.esign != undefined) this.$refs.esign.reset()
            this.sign = ''
            this.signCanv = false
            this.signImg = false 
            this.oprMtngSignList.splice(0)

            if(this.oprMtngSignPk > 0) {
                this.mtngRcrdPk = this.oprMtngSignPk            
                this.selMtngAtndSigList()
            } 
        })
    },

    methods: {
        onClose: function () {
            this.$emit('isClose', '.')  
            this.sltMtngAtnd = -1          
        },     
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res
                this.setSign()
            }).catch(err => {
                alert(err)
            })
        },
        tableClickEvent: function (item) {
            
            if (this.sltMtngAtnd == item.mtngAtndPk) {
                this.sltMtngAtnd = -1
                this.signCanv = false
                this.signImg = false
                if(this.$refs.esign != undefined) this.$refs.esign.reset()
                this.sign = ''
            }
            else if (this.selectedItem != item.mtngAtndPk) {
                this.sltMtngAtnd    = item.mtngAtndPk

                if(item.eSigPk > 0){
                    this.signCanv = false
                    this.signImg = true   
                    this.setSignImg()  
                }else{
                    this.signCanv = true
                    this.signImg = false
                    if(this.$refs.esign != undefined) this.$refs.esign.reset()
                    this.sign = ''
                }

            }
            else {
                //Form Clear
                this.sltMtngAtnd = -1
                this.signCanv = true
                this.signImg = false
                if(this.$refs.esign != undefined) this.$refs.esign.reset()
                this.sign = ''
            }
        },
        selMtngAtndSigList: function () {
            selMtngAtndSigList(this.$store.getters.getFcltyNum, this.mtngRcrdPk)
                .then((response) => (this.selMtngAtndSigListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selMtngRcrdInfo : ' + error))
        },
        selMtngAtndSigListAfter: function (res) {
            this.oprMtngSignList = res

            if(this.sltMtngAtnd > 0){
                let eSigPk = 0
                let target = this.oprMtngSignList.filter(x=>x.mtngAtndPk === this.sltMtngAtnd)
                target.forEach(e => {
                    eSigPk = e.eSigPk;
                });

                if(eSigPk > 0){
                    this.setSignImg()
                    this.signCanv = false
                    this.signImg = true 
                }else{
                    this.signCanv = true
                    this.signImg = false 
                }

            }
        },
        handleReset: function () {
            this.$refs.esign.reset()
            this.sign = ''
        },        
        setSign: function () {
           
            let mmbrPk = 0;
            
            let target = this.oprMtngSignList.filter(x=>x.mtngAtndPk === this.sltMtngAtnd)
            target.forEach(e => {
                mmbrPk = e.mmbrPk;
            });
            
            if(mmbrPk > 0){
                //회의기록(eSigClcd): 7
                let obj = {}
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.mmbrPk = mmbrPk
                obj.bzClcdPk = this.mtngRcrdPk
                obj.eSigClcd = '7'
                obj.sign = this.sign
                obj.userNm = this.$store.getters.getUserNm

                uploadSIgn(obj)
                    .then((response) => ( this.setSignAfter(response.data) ))
                    .catch((error) => console.log('connect error /cmm/uploadSIgn : ' + error))
            }
        },
        setSignAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selMtngAtndSigList()
                this.$emit('reload','.')
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },

        onDelSign:function(){
            if(this.sltMtngAtnd > 0){
                this.dialogDelete = true
            }
        },

        delSignAct:function(res){
            if(res == 9990){
                let eSigPk = 0
                let target = this.oprMtngSignList.filter(x=>x.mtngAtndPk === this.sltMtngAtnd)

                target.forEach(e => {
                    eSigPk = e.eSigPk;
                });

                if(eSigPk > 0){
                    let obj = {
                        fcltyNum:this.$store.getters.getFcltyNum,
                        eSigPk : eSigPk,
                        userNm : this.$store.getters.getUserNm,
                    }
                    delESig(obj)
                        .then((response) => ( this.delSignActAfter(response.data) ))
                        .catch((error) => console.log('connect error /cmm/delESig : ' + error))
                }

            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        delSignActAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.selMtngAtndSigList()
                this.$emit('reload','.')
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        
        setSignImg:function(){
            let mpk = 0;
            
            let target = this.oprMtngSignList.filter(x=>x.mtngAtndPk === this.sltMtngAtnd)

            target.forEach(e => {
                mpk = e.mmbrPk;
            })

            let path = remoteURL + 'cmm/eSigImgDownload?'
            let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum
            let bzClcd = '&eSigClcd=7'
            let bzPk = '&bzClcdPk=' + this.mtngRcrdPk
            let mmbrPk = '&mmbrPk=' + mpk
            this.imgSrc = path + fcltyNum + bzClcd + bzPk + mmbrPk + '&t=' + new Date().getTime()
        },
          
        snackControll: function (options) {
            this.snackOptions = options
        },
         
    },
    data: () => ({
        sltMtngAtnd:-1,
        mtngRcrdPk:0,
        oprMtngSignHeaders: [
            { text: '대상자', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '소속', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '서명여부', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        oprMtngSignList:[],
        signSrc: { src: '', org: '' },
        dialog: false,
        selectedItem: -1,
        mmbrPk: 0,
        mtngAtndPk: 0,
        forceRender: 0,
        sign: '',
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
        snackOptions: [false, 3000, '', 'info'],
        signCanv:false,
        signImg:false,
        imgSrc:'',
        imgWidth:'400',
        dialogDelete:false,
    }),

};
</script>