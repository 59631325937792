<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-left">
                <span class="text-h4 font-weight-bold">{{title}}</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            
            <div class="pa-0 ma-0 mt-1">
                <v-row class="pa-0 ma-0 mt-2" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                        <span class="d-inline-block ml-1" style="width: 26px;">
                            <v-checkbox
                                class="checkIcon" v-model="chkAll" :key="forceRender" @click="onAllChk"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="8">
                        <span class="black--text font-weight-medium">치료방법</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="3">                       
                        <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-for="(item, i) in items" :key="i" class="pa-0 ma-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="1">
                        <span class="d-inline-block ml-1" style="width: 26px;">
                            <v-checkbox
                                class="checkIcon" v-model="item.chkyn" :key="forceRender"  @change="onSelChk"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="8">
                        <v-text-field v-model="item.valCdnm" color="blue" ref="info"
                            :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="3">
                        <v-btn icon color='blue200' @click="onSave(i)">
                            <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="item.fcltyNum !='10000000000'" icon color='grey006' @click="onDelete(i)"> 
                            <v-icon v-if="item.cmnItmValCdPk > 0">mdi-trash-can-outline</v-icon>                                           
                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                        </v-btn> 
                    </v-col>
                </v-row>                
                <v-row class="mt-4 pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center" cols="12">
                        <v-btn
                            class="white--text" color="blueBtnColor" @click="onApply" height="30" samll rounded>
                            <v-icon>mdi-checkbox-multiple-marked-outline</v-icon>
                            <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">선택</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>   
        </v-form>   
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'물리치료'" @madalState="delCmnItmValCd"></btnModalDelete>  
        </v-dialog>   
    </v-sheet>
</template>

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { getCmnItmValCdList, insCmnItmValCd, delCmnItmValCd } from '../../api/index.js';

export default {
    name: 'EmpSelector',

    props : {
        physcThrpyTypePk:{type:Number,default:-1},
        thrClcd:{type:String,default:''},
        thrMthdcd:{type:Array, default: () => { return [] }},
    },
            
    components: {
        btnModalDelete
    },

    created: function(){
        
    },
    
    mounted: function(){
        this.$nextTick(function () {  
            if(this.physcThrpyTypePk > 0) this.onShow()
        })
    },

    computed: {

    },

    watch: {
        'physcThrpyTypePk':function(value){    
            if(value > 0) {
                this.onShow()
            }
        }
    },
        
    methods: {
        onShow(){
            this.reset()

            if(this.thrClcd=="1"){
                this.title = "물리치료"
            }else{
                this.title = "작업치료"
            }
           
            this.getCmnItmValCd()
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        onAllChk(){
            if(this.chkAll == '1') {
                this.items.forEach(item => {
                    item.chkyn = '1'
                });
            }
            else if(this.chkAll == '2') {
                this.items.forEach(item => {
                    item.chkyn = '2'
                });
            }           
        },
        onSelChk:function(){
            let tCh = '1'
            this.items.forEach(el => {
                if(el.chkyn == '2') tCh = '2'
            });
            this.chkAll = tCh           
        },

        onApply(){
            let selArr = []

            this.items.forEach(x => {
                if(x.chkyn == '1'){
                    selArr.push(x.valCd)
                }
            });

            this.$emit('onApply', selArr)
            this.onClose()
        },

        getCmnItmValCd(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let value = (this.thrClcd =='1')?'92':'93'            
            this.valClcd = value
            this.valClcdnm = (this.thrClcd =='1')?'물리치료방법':'작업치료방법'
            getCmnItmValCdList(fcltyNum, value)
            .then((response) => ( this.resData(response.data)))
            .catch((error) => console.log(error))
        },

        resData(data){  
            data.map(x=>{
                let sel = this.thrMthdcd.includes(x.valCd)?'1':'2'
                x.chkyn = sel    
                if(this.maxValCd < Number(x.valCd)) this.maxValCd = x.valCd  
            })
            this.items = data
        },

        reset(){
            this.chkAll = false
            this.items.splice(0)
            this.valClcd = ''
            this.valClcdnm = ''
            this.maxValCd = 0
            this.pkey = ''
            this.$refs.mdlform.resetValidation();
        },
        addRow(){
            let addObj = {
                chkyn:false,                
                valCd : '',
                valCdnm : '',
                valClcd : '',
                valClcdnm : '',               
            }

            this.items.push(addObj)
        },
       

        onSave(key){
            if(this.$refs.mdlform.validate()){                
                let obj = {
                    valClcd : this.valClcd,
                    valClcdnm : this.valClcdnm,
                    valCd : Number(this.maxValCd)+1,
                    ord : Number(this.maxValCd)+1,
                    valCdnm : this.items[key].valCdnm,
                    fcltyNum : this.$store.getters.getFcltyNum,
                    userNm : this.$store.getters.getUserNm,
                    cmnItmValCdPk : this.items[key].cmnItmValCdPk,
                    useYn : '1',
                } 
                
                insCmnItmValCd(obj)
                    .then((response) => ( this.insCmnItmValCdAfter(response.data)))
                    .catch((error) => console.log(error))       
            }
        },
        insCmnItmValCdAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getCmnItmValCd()           
                this.$emit('cdReload')
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        onDelete(key){           
            console.log() 
            if(this.items[key].cmnItmValCdPk != undefined){
                this.idx = key
                this.dialogDelete = true
            }else{
                this.items.splice(key, 1)
            }
        },    
            

        delCmnItmValCd:function(res){
            if(res == 9990){
               if(this.items[this.idx].cmnItmValCdPk != undefined){
                    this.items[this.idx].userNm = this.$store.getters.getUserNm
                    delCmnItmValCd(this.items[this.idx])
                        .then((response) => ( this.delCmnItmValCdAfter(response.data)))
                        .catch((error) => console.log(error))  
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        delCmnItmValCdAfter(res){            
            if(res.statusCode == 200){                     
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('cdReload')
                this.getCmnItmValCd()
                this.items.splice(this.key, 1)
                this.idx=0
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },

        
        snackControll: function (options) {
            this.snackOptions = options
        },

    },
    
    data: () => ({
        idx:0,
        forceRender:0,
        title:"물리치료",
        valClcd:'',
        typeVal:'',
        valClcdnm:'',
        selecter:[],
        chkAll:false,
        maxValCd:0,
        pkey:'',
        items:[],
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        dialogDelete:false,
    }),
};
</script>