<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="outlinedBtn+i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <!-- <span v-for="(list, j) in outlinedBtn" :key="j">
                <v-dialog
                    v-model="dialog[j-1]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span> -->
        </div>
        <v-divider class="my-2"></v-divider>
        <v-form ref="nsgForm" lazy-validation>
            <v-divider :class="[inspRecDtllist.length > 12 ? 'mr-2__5' : '' ]"></v-divider>
            <v-row :class="['greyE01 pa-0', inspRecDtllist.length > 12 ? 'mr-2__5' : '' ]" no-gutters>
                <v-col class="d-flex justify-center align-center font-weight-bold py-1 tableBR" cols="1">
                    <span class="">연번</span>
                </v-col>
                <v-col class="d-flex justify-center align-center font-weight-bold py-1 tableBR" cols="4">
                    <span class="">간호비품명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center font-weight-bold py-1 tableBR" cols="1">
                    <span class="">수량</span>
                </v-col>
                <v-col class="tableBR" cols="2">
                    <div class="d-flex justify-center align-center font-weight-bold pt-1">일회용품</div>
                    <div class="d-flex justify-center align-center font-weight-bold pb-1">유통기한</div>
                </v-col>
                <v-col class="d-flex justify-center align-center font-weight-bold py-1 tableBR" cols="2">
                    <span class="">소독방법</span>
                </v-col>
                <v-col class="d-flex justify-center align-center font-weight-bold py-1" cols="2">
                    <span class="d-inline-block px-1" style="width:70%; text-align: center;">소독자</span>
                    <v-btn 
                        class="brown lighten-3 d-inline-block" style="padding: 0px 1px 0px 2px !important"
                        min-width="30" height="28" @click="dialog.splice((dialog.length-1), 1, true), sltItem = -9"
                        dark small depressed>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider :class="[inspRecDtllist.length > 12 ? 'mr-2__5' : '' ]"></v-divider>
            <div class="overflow-y-auto" style="height: 539px;">
                <v-row class="tableBB" v-for="(nsg, i) in inspRecDtllist" :key="i" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                        <span class="">{{nsg.rn}}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="4">
                        <span class="py-1 px-2">{{nsg.itm1CdNm}}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                        <span class="d-inline-block pa-1" style="width:100%;">
                            <v-text-field
                                class="numericTextField" v-model="nsg.itm2Val" type="number" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1 px-1" cols="2">
                        <span class="d-inline-block" style="width: 153px;">
                            <CmmDateComp v-model="nsg.itm4Val" :required="true"></CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                        <span class="px-1" style="width:100%; height: 31.11px;">
                            <v-select class="hsptlSelect"
                                v-model="nsg.itm3Val" height="28" :key="forceRender"
                                item-text="valcdnm" item-value="valcd" :items="vCd94"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="2">
                        <span class="d-inline-block pa-1" style="width:70%;">
                            <v-text-field
                                v-model="nsg.wrtr" readonly hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <v-btn 
                            class="brown lighten-3 d-inline-block" style="padding: 0px 1px 0px 2px !important"
                            min-width="30" height="28" @click="dialog.splice((dialog.length-1), 1, true), sltItem = i"
                            dark small depressed>
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
            <v-row no-gutters>
                <v-col
                    class="d-flex justify-center align-center font-weight-bold py-1"
                    style="background-color: #ededed !important;" cols="2">
                    <span class="">비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="10">
                    <span class="d-inline-block pa-1" style="width:100%;">
                        <v-textarea
                            class="rmrksArea" v-model="cntnt" no-resize hide-details outlined dense>
                        </v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>

        <v-dialog v-model="dialog[dialog.length-1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice((dialog.length-1), 1, false)" @outEmp="getEmpInfo" :prtWrtDt="sltDate"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[dialog.length-2]" max-width="500">
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import CmmDateComp from '../../components/commons/CmmDateComp.vue';

import { selInspRecDtl1list, insInspRecDtl, delInspRecDtl } from '../../api/sfty.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'NsgEqpmnMgmt',
    
    components: {
        btnModalDelete,
        expandNowDatePicker,
        EmpSelector,
        CmmDateComp,
    },

    props : {

    },

    created: function(){
        this.getCode(['94'])

        for (let index = 0; index < this.outlinedBtn.length+2; index++) {
            this.dialog.push(false)      
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)

            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)

            this.sltDate = timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp()

            this.getInspRecDtllist()
        })
    },

    computed: {
        getStrtDate: function () {
            return this.$parent.$parent.date.startDay
        },
        getSltInspRecPk: function () {
            return this.$parent.$parent.sltInspRecPk
        },
        getSelectedItem: function () {
            return this.$parent.$parent.selectedItem
        },
    },  

    watch: {
        getStrtDate: function (value) {
            let fDate = new Date(value.beforeDateFormatHyp())
            let nDate = new Date(this.$parent.$parent.sltDate.beforeDateFormatHyp())
            let lDate = new Date(this.$parent.$parent.date.endDay.beforeDateFormatHyp())

            if(fDate <= nDate && nDate <= lDate) this.$parent.$parent.sltDate = this.sltDate
            else {
                fDate.setDate(fDate.getDate()+3)
                this.sltDate = fDate.toISOString().split('T',2)[0].afterDateFormatHyp()
            }
        },
        getSltInspRecPk: function (value) {
            if(value == 0){
                this.btnControll(2, true, 'fill')
                this.btnControll(1, true, 'out')
            }
            else if(value != 0){
                this.btnControll(2, false, 'fill')
                this.btnControll(1, false, 'out')
            }
        },
        getSelectedItem: function (value) {
            this.getInspRecDtllist()

            if(value == -1){
                this.clearForm()
                this.btnControll(1, true, 'fill')
                this.btnControll(0, true, 'fill')
            }
            else{
                this.btnControll(1, false, 'fill')
                this.btnControll(0, false, 'fill')
            }
        },
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array, this.$store.getters.getFcltyNum)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //간호비품관리 조회(당일)
        getInspRecDtllist: function (){
            //console.log('recPk : ' + this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk)
            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.sltInspRecPk, this.sltDate, 505, 1)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //간호비품관리 저장
        setInspRecDtl: function (obj){
            this.btnControll(2, true, 'fill')
            this.btnControll(1, true, 'fill')

            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //간호비품관리 삭제
        removeInspRecDtl: function (){
            this.btnControll(2, true, 'fill')
            this.btnControll(1, true, 'fill')
            
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        stCdMapping: function (res){
            this.vCd94 = res.vCd94
        },
        getInspRecDtllistAfter: function (res){
            this.inspRecDtllist.splice(0)
            this.menu.splice(0)

            for (let index = 0; index < res.length; index++) {
                this.menu.push(false)
            }

            if(res.findIndex( v => v.inspDt == null ) == -1) this.btnControll(0, true, 'fill')
            else this.btnControll(0, false, 'fill')

            res.forEach(items => {
                if(items.itm3Val == null)
                    items.itm3Val = '1'
                if(items.itm4Val == null)
                    items.itm4Val = ''

                // items.itm4Val = items.itm4Val?.beforeDateFormatHyp()
            });

            this.inspRecDtllist = res
            
            this.$parent.$parent.sftyList.forEach(items => {
                if(this.$parent.$parent.selectedItem == items.inspDt)
                    this.cntnt = items.cntnt1
            });

            this.forceRender += 1
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.getTableList()
                this.clearForm()

                this.btnControll(2, false, 'fill')
                this.btnControll(1, false, 'fill')
                this.btnControll(1, true, 'out')
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 505
            obj.clsfc2Cd = 1
            obj.inspRecPk = this.$parent.$parent.sltInspRecPk
            obj.inspDt = this.$parent.$parent.sltDate
            obj.cntnt1 = this.cntnt
            obj.list = []

            this.inspRecDtllist.forEach(items => {
                items.inspTmHh = '00'
                items.inspTmMm = '00'

                obj.list.push(items)
            });
            //console.log(obj)
            this.setInspRecDtl(obj)
        },
        getDate: function (value) {
            this.$refs.nsgForm.reset()
            this.$parent.$parent.sltDate = value.date
            this.sltDate = value.date

            let fIndex = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == value.date.beforeDateFormatDot())
            if(fIndex != -1){
                let pkkk = this.$parent.$parent.sftyList[fIndex].inspRecPk

                if(pkkk == null)
                    this.$parent.$parent.sltInspRecPk = 0
                else
                    this.$parent.$parent.sltInspRecPk = pkkk

                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }
            else{
                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }

            let nDate = new Date(value.date.beforeDateFormatHyp())
            nDate.setDate(nDate.getDate() + 1)

            this.nextDate = nDate.toISOString().split('T',2)[0].afterDateFormatHyp()
        },
        btnControll: function (index, dis, type) {
            switch (type) {
                case 'out':
                    //this.outlinedBtn[index].disabled = dis
                    break;
                case 'fill':
                    this.filledBtn[index].disabled = dis
                    break;
                default:
                    break;
            }
        },
        delModalAct: function (res) {
            this.delTitle = ''
            if(res == 9990){    //OK
                this.removeInspRecDtl()
                this.dialog.splice((this.dialog.length-2), 1, false)
            }
            else if(res == 7)   //Cancel
                this.dialog.splice((this.dialog.length-2), 1, false)
        },
        onClickBtn: function (index) {
            switch(index) {
                case 0:     //전일자료
                    this.getTmwRecInfo()
                    break;
                case 1:     //저장
                    this.preInspRecDtllist()
                    break;
                case 2:     //삭제
                    this.delTitle = '간호비품관리'
                    this.dialog.splice((this.dialog.length-2), 1, true)
                    //this.removeInspRecDtl()
                    break;
                default:
                    break;
            }
        },
        //간호비품관리 조회(전일)
        getTmwRecInfo: function () {
            let tomwDay = this.$moment(this.sltDate, 'YYYYMMDD').subtract(1, 'days').format('YYYYMMDD')

            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.sltInspRecPk, tomwDay, 505, 1)
                .then((response) => ( this.getTmwRecInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        getTmwRecInfoAfter: function (res) {
            let cfm = res.findIndex( v => v.inspDt == null)
            if(cfm == -1){
                res.forEach(list => {
                    list.wrtr = ''
                    list.inspDt = null
                    list.inspRecDtlPk = null
                    list.inspRecPk = null
                });
                this.inspRecDtllist.splice(0)
                this.inspRecDtllist = res
                this.cntnt = res[0].cntnt1
            }
            else
                this.snackControll([true, 3000, '전일 데이터가 존재하지 않습니다.', 'info']) 
        },

        getEmpInfo: function (obj) {
            switch (this.sltItem) {
                case -1:        //작동X
                    break;
                case -9:        //소독자 전체 반영 
                    this.inspRecDtllist.forEach(arr => {
                        arr.wrtr = obj[0].name
                    });
                    break;
                default:         //선택된 항목에만 소독자 반영
                    this.inspRecDtllist[this.sltItem].wrtr = obj[0].name
                    break;
            }

            this.sltItem = -1
        }, 
        clearForm: function () {
            this.cntnt = ''
            this.$refs.nsgForm.reset()
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        dialog: [],
        menu:[],
        delTitle: '',
        sltItem: -1,
        sltDate: '',
        nextDate: '',
        areaWidth: 708,
        forceRender: 0,
        vCd94: [],
        inspRecDtllist: [],
        cntnt: '',
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '전일자료', disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>