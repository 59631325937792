<template>
    <v-sheet>
        <div v-if="$parent.$parent.$parent.$parent.loadable" >
            <div
              
                class="d-inline-block"
                style="width: 100%; height: 38px; text-align: center;">
                <v-progress-linear
                    class="mb-1" color="yellow darken-2"
                    indeterminate>
                </v-progress-linear>
                <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
            </div>            
        </div>

        <div v-else class="d-flex">
            <div style="width:23%;font-size:0.9rem !important;">
                <div class="d-flex">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" 
                        style="width:15%;border-top: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">영역</span>
                    </div>
                    <div class="d-flex" style="width:85%;border-left: 1px solid #dbdbdb !important;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" 
                            style="width:50%;border-top: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium" style="height:25px;line-height:25px;">제공내용</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" 
                            style="width:50%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium" style="height:25px;line-height:25px;">제공계획</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="width:100%">
                    <div class="greyE01 d-flex justify-center align-center pl-2 py-1 ma-0" 
                        style="width:15%;border-top: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">인지 정서 지원</span>
                    </div>
                    <div style="width:85%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" 
                            style="width:100%;border-top: 1px solid #dbdbdb !important;">
                            <span style="height:22px;line-height:28px;">처리</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" 
                            style="width:100%;border-top: 1px solid #dbdbdb !important;">
                            <span style="height:28px;line-height:28px;">작성자</span>
                        </div>
                        <div v-for="(item, i) in labels" :key="i" class="d-flex" style="border-top: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" 
                                style="width:50%;" >
                                    <span style="height:25px;line-height:25px;">{{item.title}}</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" 
                                style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                {{item.cnt}}
                            </div>
                        </div>                       
                        <div class="pa-1 ma-0" 
                            style="width:100%;border-top: 1px solid #dbdbdb !important;height:105px;">                            
                                <div class="mt-5 d-flex justify-center align-center">특이사항</div>
                                <div class="d-flex justify-center align-center">&#40;30자초과시 별지첨부&#41;</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="greyE01 d-flex justify-center align-center pl-2 py-1 ma-0" 
                        style="width:15%;border-top: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">기능회복</span>
                    </div>
                    <div style="width:85%;border-left: 1px solid #dbdbdb !important;">                        
                        <div v-for="item in labels2" :key="item.title" class="d-flex" style="border-top: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" 
                                style="width:50%;" >
                                    <span style="height:25px;line-height:25px;">{{item.title}}</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" 
                                style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                {{item.cnt}}
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <div class="careblnftsrv-input-wrap" v-for="(item,j) in dayitems" :key="j" style="position:relative;width:120px;font-size:0.9rem !important;">
                <v-form ref="tabform" lazy-validation>
                    <div v-if="(item.oldYn && item.wrtYn != '1') || item.bnftOfrItems.outYn == '1'" style="position:absolute;top:0px;left:0px;padding:0px 2px;width:100%;height:100%;z-index:7;">
                        <div style="width:100%;height:100%;background-color:black;;opacity: 0.2;"></div>
                        <v-icon size="50" style="position:absolute;top:45%;left:33%;">mdi-pencil-off-outline</v-icon>
                        <div v-if="item.bnftOfrItems.outYn == '1'" style="position:absolute;top:53%;left:18%;">
                            <span class="font-weight-bold" style="font-size:2rem;color:rgba(0, 0, 0, 0.54)">외박중</span>
                        </div>
                    </div>
                    <div v-if="!item.oldYn && $parent.$parent.$parent.$parent.sysVal1 != '1'" style="position:absolute;top:0px;left:0px;padding:0px 2px;width:100%;height:100%;z-index:7;">
                        <div style="width:100%;height:100%;background-color:black;opacity: 0.2;"></div>
                        <v-icon size="50" style="position:absolute;top:45%;left:33%;">mdi-pencil-off-outline</v-icon>
                        <div v-if="item.bnftOfrItems.outYn == '1'" style="position:absolute;top:53%;left:18%;">
                            <span class="font-weight-bold" style="font-size:2rem;color:rgba(0, 0, 0, 0.54)"></span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div :class="['d-flex justify-center align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                            style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                            <div style="height:25px;line-height:25px;">                            
                                <span :class="['font-weight-medium', item.savechk ? 'red--text':'black--text']">{{item.date.afterDateFormatHyp().beforeDateFormatDot()}}</span>
                                <span :class="item.class">
                                    &#40;{{item.day}}&#41;
                                </span>
                            </div>
                        </div>                    
                    </div>
                    <div :class="['d-flex justify-center align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"                    
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <div style="height:22px;">
                            <v-btn v-if="item.bnftOfrItems.hispk2 == 0"
                                class="ml-1 px-1" 
                                color="#3C9099"
                                small 
                                min-width="22"
                                height="22"  
                                dark
                                @click="preData(j)"                            
                            >
                                <v-icon size="16">mdi-text-box-search-outline</v-icon>
                                <span>전일자료</span>
                            </v-btn>
                        </div>
                    </div>
                    <div :class="['d-flex justify-center align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"                    
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:75px;margin-left:12px;">
                            <v-text-field v-model="item.bnftOfrItems.wrtr" color="blue" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                        </span>                       
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="22"
                            height="22"  
                            dark
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(j)"
                        >
                            <v-icon size="16">mdi-account-edit</v-icon>
                        </v-btn>
                    </div> 
                    <!-- 인지관리지원 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">                    
                        <span style="width:25px">    
                            <v-icon v-if="item.bnftOfrItems.itm1Val != ''" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm1Val" @focus="item.bnftOfrItems.itm1Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>
                    <!-- 의사소통,정서 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">                    
                        <span style="width:25px">    
                            <v-icon v-if="item.bnftOfrItems.itm2Val != ''" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm2Val" @focus="item.bnftOfrItems.itm2Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>
                    <!-- 특이사항(50자초과시 별지첨부) -->
                    <div 
                    :class="['d-flex justify-center align-center pa-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <v-textarea v-model="item.bnftOfrItems.spclNote"  @input="onCompare(j)" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="5" outlined dense hide-details no-resize>
                        </v-textarea>
                    </div> 
                    <!-- 신체기능의 훈련 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">                    
                        <span style="width:25px">    
                            <v-icon v-if="item.bnftOfrItems.itm3Val != ''" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm3Val" @focus="item.bnftOfrItems.itm3Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>
                    <!-- 기본동작 훈련 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">                    
                        <span style="width:25px">    
                            <v-icon v-if="item.bnftOfrItems.itm4Val != ''" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm4Val" @focus="item.bnftOfrItems.itm4Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>
                    <!-- 일상생활동작 훈련 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                    
                        <span style="width:25px">    
                            <v-icon v-if="item.bnftOfrItems.itm5Val != ''" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm5Val" @focus="item.bnftOfrItems.itm5Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>
                </v-form>
            </div>
        </div>
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
    </v-sheet>
</template>
<script>
import { selPreBnftOfrRecList, insMultiBnftRcrd} from '../../api/bnftrcrd.js';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'CareBlnftSrvRcrdTab2',

    components: {
        EmpSelector
    },

    props : {
        
    },

    created: function(){
        this.getToday()     
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {   
        
    },

    watch:{
        
    },

    methods: {
        getToday:function(){
            // let date = new Date()
            // this.today = date.getFullYear()+('00'+(date.getMonth()+1).toString()).slice(-2)+('00'+(date.getDate()).toString()).slice(-2)
            this.today = this.$moment().add(1, 'days')
        },        
        onCompare: function(idx){
            let entInpObj = Object.entries(this.dayitems[idx].bnftOfrItems).toString()
            let entDumyObj = Object.entries(this.dummyDayitems[idx].bnftOfrItems).toString()
            if(entInpObj != entDumyObj){                
                this.dayitems[idx].savechk = true
            }else{
                this.dayitems[idx].savechk = false
            }
        },        
        onSave:function(dupYn = '1'){

            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){
                let savego = true
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    userNm : this.$store.getters.getUserNm,
                    dupList     : [],
                    hisList     : []
                }

                this.dayitems.forEach((x,idx)=>{
                    if(x.savechk){                        
                        if(!this.$refs.tabform[idx].validate()){
                            savego = false
                        }else{             
                            
                            let hisObj1 = {
                                clsfc1Cd    : '301',
                                clsfc2Cd    : '1',
                                clsfc3Cd    : '2',
                                recDt       : x.bnftOfrItems.recDt,
                                cntnt       : '',
                                spclNote    : x.bnftOfrItems.spclNote,
                                wrtr        : x.bnftOfrItems.wrtr,
                                wrtrMmbrPk  : x.bnftOfrItems.wrtrMmbrPk,
                                recList     : [
                                    {
                                        itm1Cd:'1',
                                        itm1Val:x.bnftOfrItems.itm1Val,
                                        itm2Cd:'2',
                                        itm2Val:x.bnftOfrItems.itm2Val,
                                        wrtr        : x.bnftOfrItems.wrtr,
                                        wrtrMmbrPk  : x.bnftOfrItems.wrtrMmbrPk,
                                    }
                                ],
                            }
                            
                            obj.hisList.push(hisObj1)

                            if(x.bnftOfrItems.hispk2 == 0 && dupYn == '1'){
                                let dupObj1 = {
                                    clsfc1Cd    : '301',
                                    clsfc2Cd    : '1',
                                    clsfc3Cd    : '2',
                                    recDt       : x.bnftOfrItems.recDt,
                                }

                                obj.dupList.push(dupObj1)
                            } 


                            let hisObj2 = {
                                clsfc1Cd    : '301',
                                clsfc2Cd    : '1',
                                clsfc3Cd    : '3',
                                recDt       : x.bnftOfrItems.recDt,
                                cntnt       : '',
                                spclNote    : '',
                                wrtr        : x.bnftOfrItems.wrtr,
                                wrtrMmbrPk  : x.bnftOfrItems.wrtrMmbrPk,
                                recList     : [
                                    {
                                        itm1Cd:'1',
                                        itm1Val:x.bnftOfrItems.itm3Val,
                                        itm2Cd:'2',
                                        itm2Val:x.bnftOfrItems.itm4Val,
                                        itm3Cd:'3',
                                        itm3Val:x.bnftOfrItems.itm5Val,
                                        wrtr        : x.bnftOfrItems.wrtr,
                                        wrtrMmbrPk  : x.bnftOfrItems.wrtrMmbrPk,
                                    }
                                ],
                            }

                            obj.hisList.push(hisObj2)

                            if(x.bnftOfrItems.hispk3 == 0 && dupYn == '1'){
                                let dupObj1 = {
                                    clsfc1Cd    : '301',
                                    clsfc2Cd    : '1',
                                    clsfc3Cd    : '3',
                                    recDt       : x.bnftOfrItems.recDt,
                                }

                                obj.dupList.push(dupObj1)
                            } 
                            
                        }
                    }                    
                })

                if(savego && obj.hisList.length > 0){
                    insMultiBnftRcrd(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))
                }else{
                    this.$emit("msgTrigger","수정 사항이 없습니다.")
                }

            }else{
                this.$emit("msgTrigger","수급자를 선택해주세요.")
            }
        },
        onSaveAfter:function(res){
            this.$emit("careBlnftTrigger",res)            
        },
        isModal: function () {
            this.menu.splice(0, 1, false)
            this.prtWrtDt = ""
        },        
        onModal: function (idx) {  
            this.idx = idx
            this.prtWrtDt = this.dayitems[idx].date
            this.menu.splice(0, 1, true)
        },
        getEmpInfo: function (obj) {
            this.dayitems[this.idx].bnftOfrItems.wrtr = obj[0].name
            this.dayitems[this.idx].bnftOfrItems.wrtrMmbrPk = obj[0].mmbrPk
            this.onCompare(this.idx)
            this.idx = 0
        },

        getCnt:function(obj){
            let cnt = ''
            this.$parent.$parent.$parent.$parent.vCd45.forEach(e=>{
                if(e.valcd == obj.itmVal1){
                    cnt = e.valcdnm
                }
            })
            if(obj.itmVal2 != null && obj.itmVal2 != ''){
                cnt += obj.itmVal2+'회'
            }
            if(obj.itmVal3 != null && obj.itmVal3 != ''){
                cnt += '/'+obj.itmVal3+'분'
            }
            return cnt
        },     

        getBnftOfrRecWeekListAfter:function(res){
            this.dayitems.splice(0)
            this.setlabels()
            if(res != '' && res != null && res != undefined){
                if(res.weekList.length > 0){
                    res.weekList.forEach((x,i)=>{
                        let dayclass = "black--text font-weight-medium"
        
                        if(i==5) dayclass = "blue--text font-weight-medium"
                        else if(i==6) dayclass = "red--text font-weight-medium"

                        let day     = this.$moment(x.day+' '+this.$parent.$parent.$parent.$parent.sysVal2,'YYYYMMDD hh:mm')
                        let yn = this.$moment.duration(this.today.diff(day)).asMinutes()                                               
                        let oldYn = yn > 0

                        let obj = {
                            day:this.days[i],
                            date:x.day,
                            class:dayclass,
                            savechk:false,
                            wrtYn:x.wrtYn,
                            oldYn :oldYn,
                            bnftOfrItems:{
                                outYn:x.outYn,
                                hispk2:x.recInfo.recInfo2.bnftOfrRecHisPk,
                                pk2:x.recInfo.recInfo2.bnftOfrRecPk,
                                hispk3:x.recInfo.recInfo3.bnftOfrRecHisPk,
                                pk3:x.recInfo.recInfo3.bnftOfrRecPk,
                                wrtr:x.recInfo.recInfo2.wrtr,
                                wrtrMmbrPk:x.recInfo.recInfo2.wrtrMmbrPk,
                                recDt:x.day,                        
                                spclNote:x.recInfo.recInfo2.spclNote,
                                itm1Cd:'1',
                                itm1Val:x.recInfo.recInfo2.itm1Val,
                                itm2Cd:'2',
                                itm2Val:x.recInfo.recInfo2.itm2Val,
                                itm3Cd:'1',
                                itm3Val:x.recInfo.recInfo3.itm1Val,
                                itm4Cd:'2',
                                itm4Val:x.recInfo.recInfo3.itm2Val,
                                itm5Cd:'3',
                                itm5Val:x.recInfo.recInfo3.itm3Val,
                            }
                        }

                        this.dayitems.push(obj)
                    })

                    this.dummyDayitems = JSON.parse(JSON.stringify(this.dayitems))                
                }


                res.bnftOfrPlnList.forEach(x => {
                    if(x.clsfc2Cd != '1'){
                        let type = x.clsfc2Cd+x.clsfc3Cd
                        switch(type){
                            case "31":
                                this.labels[0].cnt = this.getCnt(x)
                            break;
                            case "41":
                                this.labels[1].cnt = this.getCnt(x)
                            break;
                            case "72":
                                this.labels2[0].cnt = this.getCnt(x)
                            break;
                            case "73":
                                this.labels2[1].cnt = this.getCnt(x)
                            break;
                            case "74":
                                this.labels2[2].cnt = this.getCnt(x)
                            break;
                            default: 
                            break;
                        } 
                    }
                });
            }
            this.frmReset()
        },

        setlabels:function(){
            this.labels.splice(0)
            this.labels2.splice(0)

            let obj0 = {title:'인지관리지원',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj0) 
            let obj1 = {title:'의사소통,정서',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj1) 
            let obj2 = {title:'신체기능의 훈련',subtitle:"",cnt:''}
            this.labels2.push(obj2) 
            let obj3 = {title:'기본동작 훈련',subtitle:'',cnt:''}
            this.labels2.push(obj3)    
            let obj4 = {title:'일상생활동작 훈련',subtitle:'',cnt:''}
            this.labels2.push(obj4)       
        },  
        clearData(){            
            this.dayitems.forEach(x=>{
                x.bnftOfrItems.hispk2 = 0,
                x.bnftOfrItems.pk2 = 0
                x.bnftOfrItems.hispk3 = 0,
                x.bnftOfrItems.pk3 = 0
                x.bnftOfrItems.wrtr = ''
                x.bnftOfrItems.wrtrMmbrPk = 0
                x.bnftOfrItems.itm1Val = 0
                x.bnftOfrItems.itm2Val = 0
                x.bnftOfrItems.spclNote = ''
                x.bnftOfrItems.itm3Val = 0
                x.bnftOfrItems.itm4Val = 0
                x.bnftOfrItems.itm5Val = 0
            })

            this.labels[0].cnt = ''
            this.labels[1].cnt = ''
            this.labels2[0].cnt = ''
            this.labels2[1].cnt = ''
            this.labels2[2].cnt = ''
            this.dummyDayitems = JSON.parse(JSON.stringify(this.dayitems))
            this.frmReset()
        },
        async frmReset(){
            await this.$nextTick();
            if(this.frmChk.length > 0){
                this.frmChk.forEach(x=>{
                    this.$refs.tabform[x].resetValidation()
                })
            }
        },
        preData:function(idx){            
            this.idx = idx               
            let date = this.dayitems[idx].bnftOfrItems.recDt
            this.getPreData(date)
        },
        getPreData:function(ymd){         
            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    inqYMD : ymd,
                    clsfc1Cd:'301',
                    clsfc2Cd:'1',
                    clsfc3CdS:['2','3'],
                }
                selPreBnftOfrRecList(obj)
                            .then((response) => ( this.getPreDataAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftoffer/selBnfcrBnftOfrPlnList : ' + error))
            }
        },
        getPreDataAfter:function(res){ 
            res.forEach(el => {                                
                if(el.clsfc3Cd == '2'){                    
                    this.dayitems[this.idx].bnftOfrItems.wrtr = el.wrtr
                    this.dayitems[this.idx].bnftOfrItems.wrtrMmbrPk = el.wrtrMmbrPk
                    this.dayitems[this.idx].bnftOfrItems.itm1Val = el.itm1Val
                    this.dayitems[this.idx].bnftOfrItems.itm2Val = el.itm2Val
                    this.dayitems[this.idx].bnftOfrItems.spclNote = el.spclNote
                }

                if(el.clsfc3Cd == '3'){                    
                    this.dayitems[this.idx].bnftOfrItems.itm3Val = el.itm1Val
                    this.dayitems[this.idx].bnftOfrItems.itm4Val = el.itm2Val
                    this.dayitems[this.idx].bnftOfrItems.itm5Val = el.itm3Val
                }
                this.onCompare(this.idx)
            });
        },  
    },

    data: () => ({
        idx:0,
        prtWrtDt:'',
        today:'',
        startDay:'',
        endDay:'',        
        frmChk:[0,1,2,3,4,5,6],
        labels:[{title:'',subtitle:'',cnt:'',style:''}],
        labels2:[{title:'',subtitle:'',cnt:'',style:''}],
        days:['월','화','수','목','금','토','일'],
        dayitems:[
            {day:'',date:'',class:'',savechk:false, wrtYn:'', oldYn:'',
                bnftOfrItems:{
                    hispk2:0,
                    pk2:0,
                    hispk3:0,
                    pk3:0,
                    wrtr:'',
                    wrtrMmbrPk:0,
                    recDt:'',
                    spclNote:'',
                    itm1Cd:'1',
                    itm1Val:0,
                    itm2Cd:'2',
                    itm2Val:0,
                    itm3Cd:'1',
                    itm3Val:0,
                    itm4Cd:'2',
                    itm4Val:0,
                    itm5Cd:'3',
                    itm5Val:0,
                },
            }],
        dummyDayitems:[
            {day:'',date:'',class:'',savechk:false, wrtYn:'', oldYn:'',
                bnftOfrItems:{
                    hispk2:0,
                    pk2:0,
                    hispk3:0,
                    pk3:0,                    
                    wrtr:'',
                    wrtrMmbrPk:0,
                    recDt:'',
                    spclNote:'',
                    itm1Cd:'1',
                    itm1Val:0,
                    itm2Cd:'2',
                    itm2Val:0,
                    itm3Cd:'1',
                    itm3Val:0,
                    itm4Cd:'2',
                    itm4Val:0,
                    itm5Cd:'3',
                    itm5Val:0,
                },
            }],
        menu: [false],
        onCheked:true,
        rules: {
            required: value => !!value || 'Required.',
            blnftsrvNumChk: value => (value > -1 && value < 100) || '',
        },
        snackOptions: [false, 3000, '', 'info'],    
    })
}
</script>
