<template>
    <v-sheet class="rounded-lg rounded-l-0" color="" min-width="900px" max-width="900px">
        <div class="d-flex justify-end pl-1 pr-3 pt-2">
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <!-- <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                <span style="font-size: 1rem;">{{list.text}}</span>
            </v-btn> -->
        </div>
        <v-divider class="my-2"></v-divider>
        <basicInfo class="pl-2 pr-2" :bnMmbrPk="bnMmbrPk"></basicInfo>
        <div class="pa-0 ma-0">
            <PhyscThrpyInspcPlnTab1 ref="physcThrpyInspcPlnTab1" @onPhyscThrpyEvltn="onPhyscThrpyEvltn"></PhyscThrpyInspcPlnTab1>
        </div>
        <div class="pa-0 ma-0 mt-3">
            <PhyscThrpyInspcPlnTab2 ref="physcThrpyInspcPlnTab2" @onPhyscThrpyPln="onPhyscThrpyPln"></PhyscThrpyInspcPlnTab2>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[0]" persistent max-width="1400" min-width="1400">                
                <PhyscThrpyEvltnComp 
                    ref="physcThrpyEvltn"
                    :bnMmbrPk="bnMmbrPk"
                    :inqYYYY="year"
                    :physcThrpyEvltnPk="physcThrpyEvltnPk"
                    @modalEvent="modalEvent"></PhyscThrpyEvltnComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[1]" persistent max-width="1470" min-width="1470">                
                <PhyscThrpyPlnComp
                    ref="physcThrpyPln"
                    :bnMmbrPk="bnMmbrPk" 
                    :bnfcrPk="bnfcrPk"
                    :inqYYYY="year"
                    :physcThrpyPlnPk="physcThrpyPlnPk"
                    @modalEvent="modalEvent"></PhyscThrpyPlnComp>
            </v-dialog>
        </div>
        <v-dialog v-model="menu[0]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(0, 1, false)"></ReportMgmt>
        </v-dialog>
    </v-sheet>
</template>

<script>
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import PhyscThrpyEvltnComp from '../../components/bnftSrv/PhyscThrpyEvltn.vue';
import PhyscThrpyPlnComp from '../../components/bnftSrv/PhyscThrpyPln.vue';
import PhyscThrpyInspcPlnTab1 from '../../components/bnftSrv/PhyscThrpyInspcPlnTab1.vue';
import PhyscThrpyInspcPlnTab2 from '../../components/bnftSrv/PhyscThrpyInspcPlnTab2.vue';

import ReportMgmt from '../../components/commons/ReportMgmt.vue';

import { getMultiStcd } from '../../api/index.js';
import { getBnfcrPhyThrExamInfList, getPhyThrPlnInfo, getPhyThrPlnDtlList,selBnfcrPhyThrExamList,selPhyThrPlnList } from '../../api/bnftSrv.js';

export default {
    name: 'PhyscThrpyInspcPln',
    
    components: {
        basicInfo,
        PhyscThrpyEvltnComp,
        PhyscThrpyPlnComp,
        PhyscThrpyInspcPlnTab1,
        PhyscThrpyInspcPlnTab2,
        ReportMgmt,
    },

    computed: {
        
    },

    watch: {
        
    },

    created: function(){
        
    },

    mounted: function(){
       
    },

    methods: {
        
        onShow:function(obj){ 
            this.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.bnfcrExamHisPk     =  (obj.bnfcrExamHisPk != null)?obj.bnfcrExamHisPk:0
            this.bnftOfrPlnHisPk    =  (obj.bnftOfrPlnHisPk != null)?obj.bnftOfrPlnHisPk:0
            this.phyThrPlnPk        =  (obj.phyThrPlnPk != null)?obj.phyThrPlnPk:0
            this.bnfcrPk            = (obj.bnfcrPk != null)?obj.bnfcrPk:0

            this.year               =   obj.year

            this.phyThrPlnItems = Object.assign({})
            this.phyThrPlnDtlItems.splice(0)

            this.onChkBtn()
            this.onCodeload()
            this.getBnfcrPhyThrExamInfData()
            this.getBnfcrPhyThrExamList()

            this.$refs.physcThrpyInspcPlnTab2.frmReset()

            this.getPhyThrPlnData()
            this.getPlnDtlList()    
            this.getPhyThrPlnList()
        },
        onCodeload:function(){
            let fcltyNum = this.$store.getters.getFcltyNum
            getMultiStcd(['37','92','93'], fcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping:function(res){
            this.$refs.physcThrpyInspcPlnTab2.setCode(res)
        },

        onChkBtn:function(){     
                 
           if(this.bnMmbrPk != 0){
                this.filledBtn[0].disabled = false
                this.filledBtn[1].disabled = false
                this.outlinedBtn[0].disabled = false
                this.outlinedBtn[1].disabled = false
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
                this.outlinedBtn[0].disabled = true
                this.outlinedBtn[1].disabled = true
            }
        },

        onClickBtn: function(text) {              
            if(text == '물리치료평가'){                
                this.onPhyscThrpyEvltn(this.bnfcrExamHisPk)
            }else if(text == '물리치료계획') {
                this.onPhyscThrpyPln(this.bnftOfrPlnHisPk)
            }
        },
        
        //물리(작업) 치료평가
        getBnfcrPhyThrExamInfData: function(){                          
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                bnMmbrPk : this.bnMmbrPk,
                inqYYYY : this.year,
                bnfcrPk :this.bnfcrPk,
            }

            getBnfcrPhyThrExamInfList(obj)
            .then((response) => ( this.$refs.physcThrpyInspcPlnTab1.getExamInfDataAfter(response.data)))
            .catch((error) => console.log(error))

            
        },
        

        //평가내역
        getBnfcrPhyThrExamList: function () {
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                bnMmbrPk: this.bnMmbrPk, 
                bnfcrPk :this.bnfcrPk,
                inqYYYY:this.year, 
                rnStrt: 0, 
                rnLmt: 10
            }

            selBnfcrPhyThrExamList(obj)
                .then((response) => ( this.$refs.physcThrpyInspcPlnTab1.getBnfcrPhyThrExamListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrPhyThrExamList : ' + error))
        },
        
        //물리(작업) 치료계획 정보 조회
        getPhyThrPlnData: function(){             
            if(this.bnftOfrPlnHisPk > 0){                
                let fcltyNum = this.$store.getters.getFcltyNum
                let state = {
                    bnftOfrPlnHisPk : this.bnftOfrPlnHisPk,
                    bnMmbrPk : this.bnMmbrPk
                }

                getPhyThrPlnInfo(fcltyNum,state)
                .then((response) => ( this.$refs.physcThrpyInspcPlnTab2.getPhyThrPlnDataAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        

        //물리(작업) 치료계획 상세 정보 조회
        getPlnDtlList(){            
            if(this.phyThrPlnPk > 0){  
                let fcltyNum = this.$store.getters.getFcltyNum
                let state = {
                    phyThrPlnPk : this.phyThrPlnPk,
                }

                getPhyThrPlnDtlList(fcltyNum,state)
                    .then((response) => ( this.$refs.physcThrpyInspcPlnTab2.getPlnDtlListAfter(response.data)))
                    .catch((error) => console.log(error))
            }
        },

        //계획내역
        getPhyThrPlnList: function () {
            selPhyThrPlnList(this.$store.getters.getFcltyNum, this.bnMmbrPk, this.year)
                .then((response) => ( this.$refs.physcThrpyInspcPlnTab2.getPhyThrPlnListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrPhyThrExamList : ' + error))
        },

        onPhyscThrpyEvltn:function(pk){            
            this.physcThrpyEvltnPk = pk
            this.dialogTab.splice(0, 1, true)   
        },

        onPhyscThrpyPln:function(pk){            
            this.physcThrpyPlnPk = pk
            this.dialogTab.splice(1, 1, true)   
        },

        modalEvent: function (event) { 
            if(event == 99){
                this.reloadPhyscThrpyInspcPln()
            }else if(event == 5){
                this.physcThrpyEvltnPk = -1
                this.dialogTab.splice(0, 1, false)
            }else if(event == 6){
                this.physcThrpyPlnPk = -1
                this.dialogTab.splice(1, 1, false)
            }
        },
        reloadPhyscThrpyInspcPln(){
            this.$parent.$parent.getAllList() 
            this.getBnfcrPhyThrExamList()

            this.getPhyThrPlnData()
            this.getPlnDtlList()    
            this.getPhyThrPlnList()
        },
        
    },

    data: () => ({
        menu: [false],
        tab:0,
        year:0,
        bnfcrExamHisPk:0,
        bnftOfrPlnHisPk:0,
        phyThrPlnPk:0,
        physcThrpyEvltnPk:-1,
        physcThrpyPlnPk:-1,
        bnMmbrPk:0,        
        bnfcrPk:0,
        examData:[],
        phyThrPlnItems:{},
        phyThrPlnDtlItems:[],
        filledBtn: [            
            { icon: 'mdi-text-box-edit', class: 'ml-1 white--text', color:'blue004', text: '물리치료평가', type: 'action', width:'1100px', visible: true, disabled: true, },
            { icon: 'mdi-text-box-edit', class: 'ml-1 white--text', color:'blue004', text: '물리치료계획', type: 'action', width:'1100px', visible: true, disabled: true, },
        ],
         outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '양식 출력', disabled: true, },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '평가내용 출력', disabled: true, },
        ],
        dialogTab:[false,false],
        tabItems: [
            { index: 0, tab: '평가정보', style: 'font-size: 1.2rem !important;'},
            { index: 1, tab: '평가내역', style: 'font-size: 1.2rem !important;'},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '7', inqYmdStart: '', inqYmdEnd: '',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>