<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">본인부담금 초기 미납금 관리</span>
            <v-spacer></v-spacer> 
            <span v-if="progress">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">              
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="list.disabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div> 
        <v-form ref="form" lazy-validation>
        <div>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium py-1">수급자</span>
                </v-col>
                <v-col class="d-flex py-1 tableBL tableBR align-center" cols="4">
                    <span class="d-inline-block ml-2" style="width:120px;">
                        <v-text-field v-model="copayClmDtlInfo.name" class="txtCtm" :rules="[rules.required]" hide-details readonly outlined dense></v-text-field>
                    </span>
                    <v-btn
                        class="ml-2 deep-orange lighten-3"
                        style="padding: 0px 1px 0px 2px !important; margin-bottom: 2px !important;"
                        min-width="30" height="26"  
                        @click="dialog.splice(0,1,true)"
                        dark small>
                        <v-icon size="20">mdi-account-multiple</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium py-1">청구년월</span>
                </v-col>
                <v-col class="py-1 tableBL" style="text-align: center;" cols="4">
                    <span class="black--text">{{$moment($parent.$parent.$parent.$parent.initItm.itmVal1,'YYYY-MM').subtract(1,'month').format('YYYY.MM')}}</span>
                </v-col>
            </v-row>            
            <v-divider class=""></v-divider>
            <v-divider class="mt-3"></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">본인부담금ⓐ</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field 
                            v-model="copayClmDtlInfo.copay"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.copay.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">공단부담금</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.nhisPay"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.nhisPay.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="8">
                    <span class="black--text font-weight-medium py-1">
                        급여&nbsp;계&nbsp;&#40;본인부담금&#43;공단부담금&#41;
                    </span>
                </v-col>
                <v-col class="pa-0 ma-0 white d-flex justify-center align-center" cols="4">
                    <span class="black--text py-1">
                        {{(Number(copayClmDtlInfo.copay) + Number(copayClmDtlInfo.nhisPay))?.toLocaleString('ko-KR')}}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">식사재료비①</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.mealCost"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.mealCost.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">상급침실비②</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.upBedFee"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.upBedFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">이,미용비③</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.btyFee"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.btyFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">경관유동식④</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.lqdFood"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.lqdFood.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">간식비⑤</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.snackFee"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.snackFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>                
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">기타비용⑥</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.msclnFee"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.msclnFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>                
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">진료약제비⑦</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.clncPhrmcFee"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.clncPhrmcFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="pa-0 ma-0 tableBL tableBR" style="line-height: 16px;" cols="2">
                    <div class="black--text font-weight-medium pt-1" style="text-align:center;">계약의사</div>
                    <div class="black--text font-weight-medium pb-1" style="text-align:center;">진찰비⑧</div>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.cntrcDctrFee"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.cntrcDctrFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">등급외⑨</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmDtlInfo.otrAmt"
                            color="blue" :rules="[rules.numberCheck]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ copayClmDtlInfo.otrAmt.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>              
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white py-1 px-1" cols="6">
                </v-col> 
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 tableBR" cols="8">
                    <div class="black--text font-weight-medium pt-1" style="text-align:center;">
                        비급여&nbsp;계
                    </div>
                    <div class="black--text font-weight-medium pb-1" style="text-align:center;">
                        ⓑ&nbsp;=&nbsp;①&#43;②&#43;③&#43;④&#43;⑤&#43;⑥&#43;⑦&#43;⑧&#43;⑨
                    </div>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white" cols="4">
                    <span class="black--text py-1">
                        {{
                            (Number(copayClmDtlInfo.mealCost) + Number(copayClmDtlInfo.upBedFee) +
                            Number(copayClmDtlInfo.btyFee) + Number(copayClmDtlInfo.lqdFood) +
                            Number(copayClmDtlInfo.snackFee) + Number(copayClmDtlInfo.msclnFee) +
                            Number(copayClmDtlInfo.clncPhrmcFee) + Number(copayClmDtlInfo.cntrcDctrFee) +
                            Number(copayClmDtlInfo.otrAmt))?.toLocaleString('ko-KR')
                        }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="greyE01 ma-0 pa-0" no-gutters>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="8">
                    <span class="black--text font-weight-medium py-1">당월본인부담금합계&nbsp;ⓒ&nbsp;=&nbsp;ⓐ&#43;ⓑ</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-center align-center white" cols="4">
                    <span class="black--text py-1">
                        {{
                            (Number(copayClmDtlInfo.copay) +         
                            Number(copayClmDtlInfo.mealCost) + Number(copayClmDtlInfo.upBedFee) +
                            Number(copayClmDtlInfo.btyFee) + Number(copayClmDtlInfo.lqdFood) +
                            Number(copayClmDtlInfo.snackFee) + Number(copayClmDtlInfo.msclnFee) +
                            Number(copayClmDtlInfo.clncPhrmcFee) + Number(copayClmDtlInfo.cntrcDctrFee) +
                            Number(copayClmDtlInfo.otrAmt) + Number(copayClmDtlInfo.unpdAmt))?.toLocaleString('ko-KR')
                        }}원
                    </span>
                </v-col>
            </v-row>            
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 pa-0 ma-0 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">비고</span>
                </v-col>
                <v-col class="pa-1" cols="10">
                    <span class="" style="">
                        <v-text-field
                            v-model="copayClmDtlInfo.rmrks"
                            color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="700" content-class="round" persistent eager>   
                <IntgrSlctn
                    :mdlTtl="'수급자 선택'" 
                    :tabInfList="['1']" 
                    :fixList="$parent.$parent.$parent.$parent.fixList" 
                    :mode="'1'"
                    :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"
                    :cd41="$parent.$parent.$parent.$parent.vCd41" 
                    :cd42="$parent.$parent.$parent.$parent.vCd42" 
                    :cd65="$parent.$parent.$parent.$parent.vCd65" 
                    @intgrResList="getAplyList" @onClose="dialog.splice(0,1,false)">
                </IntgrSlctn> 
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDeleteAct"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { setCopayClmHis, selCopayClmHisInfo } from '../../api/bnfcr.js';
import { delCopayClmHis } from '../../api/bnfcr.js';

export default {
    name: 'FacInfoUnpdWrt',
    
    components: {  
       IntgrSlctn,
       btnModalDelete
    },

    props : {
        
    },

    computed: {
        getSltPk:function(){
            return this.$parent.$parent.$parent.$parent.sltPk
        }
    },

    created: function(){  
    },

    mounted: function(){
        this.$nextTick(function () {   
            
        })
    },

    watch: {
        'getSltPk':function(value){
            // console.log('sltPk : '+value)
            if(value > -1){
                this.clearFrm()
                if(value > 0){
                    this.getCopayClmHisInfo()
                }
            }
        }
    },

    methods: {  
        onClickBtn:function(text){
            switch (text) {
                case '저장':
                    this.setCopayClmHis()
                    break;
                case '삭제':
                    this.delUnpd()
                    break;
                default:
                    break;
            }
        },
        getCopayClmHisInfo:function(){
            let pk      = this.$parent.$parent.$parent.$parent.sltPk
            let idx     = this.$parent.$parent.$parent.$parent.initUnpdList.findIndex(v=>v.copayClmHisPk == pk)
            let target  = this.$parent.$parent.$parent.$parent.initUnpdList[idx]
            let yyyymm  = target.clmYymm
            let bnmmbr  = target.bnMmbrPk
            let bnfcr   = target.bnfcrPk

            if(target.mdfAblYn != '1') {
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
            } else {
                this.filledBtn[1].disabled = false
            }

            selCopayClmHisInfo(this.$store.getters.getFcltyNum, yyyymm, bnmmbr, bnfcr)
                .then((response) => (this.getCopayClmHisInfoAfter(response.data)))
                .catch((error) => console.log('connect error /opr/insFcltyInit : ' + error))
        },
        setCopayClmHis:function(){
            if(this.$refs.form.validate()){                
                this.copayClmDtlInfo.clmYymm = this.$moment(this.$parent.$parent.$parent.$parent.initItm.itmVal1,'YYYY-MM').subtract(1,'month').format('YYYYMM')

                let clmMnthSum = Number(this.copayClmDtlInfo.copay) + Number(this.copayClmDtlInfo.upBedFee) 
                                + Number(this.copayClmDtlInfo.mealCost) + Number(this.copayClmDtlInfo.lqdFood) 
                                + Number(this.copayClmDtlInfo.snackFee) + Number(this.copayClmDtlInfo.btyFee) + Number(this.copayClmDtlInfo.msclnFee) 
                                + Number(this.copayClmDtlInfo.clncPhrmcFee) + Number(this.copayClmDtlInfo.cntrcDctrFee) + Number(this.copayClmDtlInfo.otrAmt)
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,
                    copayClmHisPk   : this.copayClmDtlInfo.copayClmHisPk,
                    acknwRtng       : this.copayClmDtlInfo.acknwRtng,
                    copayRate       : this.copayClmDtlInfo.copayRate,
                    bnMmbrPk        : this.copayClmDtlInfo.bnMmbrPk,
                    bnfcrPk         : this.copayClmDtlInfo.bnfcrPk,
                    clmYymm         : this.copayClmDtlInfo.clmYymm,
                    clmDt           : this.copayClmDtlInfo.clmYymm+'01',
                    bnftFee         : Number(this.copayClmDtlInfo.copay) + Number(this.copayClmDtlInfo.nhisPay), 
                    nhisPay         : this.copayClmDtlInfo.nhisPay, 
                    copay           : this.copayClmDtlInfo.copay,
                    mealCost        : this.copayClmDtlInfo.mealCost,
                    lqdFood         : this.copayClmDtlInfo.lqdFood,
                    snackFee        : this.copayClmDtlInfo.snackFee,
                    btyFee          : this.copayClmDtlInfo.btyFee,
                    msclnFee        : this.copayClmDtlInfo.msclnFee,
                    clncPhrmcFee    : this.copayClmDtlInfo.clncPhrmcFee,
                    cntrcDctrFee    : this.copayClmDtlInfo.cntrcDctrFee,
                    otrAmt          : this.copayClmDtlInfo.otrAmt,
                    upBedFee        : this.copayClmDtlInfo.upBedFee,
                    clmMnthSum      : clmMnthSum,
                    clmTot          : clmMnthSum,
                    cnfrmYn         : '1',
                    rmrks           : this.copayClmDtlInfo.rmrks,
                }

                this.progress = true
                
                setCopayClmHis(obj)
                        .then((response) => (this.setCopayClmHisAfter(response.data)))
                        .catch((error) => console.log('connect error /bnfcr/insCopayClmHis : ' + error))
            }else{
                this.snackControll([true, 5000, '필수 항목을 확인해주세요.', 'error'])
            }
        },

        //미납금 삭제
        onDeleteAct:function(res){
            if(res == 9990){
                this.progress = true
                let bnMmbrPks = []
                let bnfcrPks = []
                bnMmbrPks.push(this.copayClmDtlInfo.bnMmbrPk)
                bnfcrPks.push(this.copayClmDtlInfo.bnfcrPk)

                delCopayClmHis(this.$store.getters.getFcltyNum, bnMmbrPks, bnfcrPks, this.copayClmDtlInfo.clmYymm, this.$store.getters.getUserNm)
                    .then((response) => (this.delCopayClmHisAfter(response.data)))
                    .catch((error) => console.log('connect error /bnfcr/delCopayClmHis : ' + error))
            }else if(res == 7){
                this.dialog.splice(1, 1, false)
            }
        },

        getCopayClmHisInfoAfter:function(res){
            if(res != ''){
                this.copayClmDtlInfo.copayClmHisPk  = res.copayClmHisPk 
                this.copayClmDtlInfo.name           = res.name
                this.copayClmDtlInfo.bnMmbrPk       = res.bnMmbrPK
                this.copayClmDtlInfo.bnfcrPk        = res.bnfcrPk
                this.copayClmDtlInfo.upBedFee       = res.upBedFee
                this.copayClmDtlInfo.clmTot         = res.clmTot
                this.copayClmDtlInfo.clncPhrmcFee   = res.clncPhrmcFee 
                this.copayClmDtlInfo.nhisPay        = res.nhisPay 
                this.copayClmDtlInfo.rmrks          = res.rmrks 
                this.copayClmDtlInfo.btyFee         = res.btyFee             
                this.copayClmDtlInfo.clmYymm        = res.clmYymm 
                this.copayClmDtlInfo.dueDt          = res.dueDt             
                this.copayClmDtlInfo.copay          = res.copay             
                this.copayClmDtlInfo.clmDt          = res.clmDt 
                this.copayClmDtlInfo.msclnFee       = res.msclnFee 
                this.copayClmDtlInfo.snackFee       = res.snackFee 
                this.copayClmDtlInfo.lqdFood        = res.lqdFood 
                this.copayClmDtlInfo.rmnPrpym       = res.rmnPrpym 
                this.copayClmDtlInfo.cntrcDctrFee   = res.cntrcDctrFee 
                this.copayClmDtlInfo.copayAmt       = res.copayAmt 
                this.copayClmDtlInfo.mealCost       = res.mealCost 
                this.copayClmDtlInfo.unpdAmt        = res.unpdAmt 
                this.copayClmDtlInfo.ofrDys         = res.ofrDys 
                this.copayClmDtlInfo.prpymApl       = res.prpymApl 
                this.copayClmDtlInfo.otrAmt         = res.otrAmt 
                this.copayClmDtlInfo.clmMnthSum     = res.clmMnthSum 
    
                this.$parent.$parent.$parent.$parent.vCd63.forEach(code => {
                    if(res.copayRate == code.valcd) this.copayClmDtlInfo.copayRateNm = code.valcdnm
                });
            }
        },
        setCopayClmHisAfter:function(res){
            if (res.statusCode == 200) {
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$emit('reLoad')
                this.onClose()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.progress = false
        },
        delCopayClmHisAfter:function(res){
            if (res.statusCode == 200) {
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$emit('reLoad')
                this.onClose()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialog.splice(1, 1, false)
            this.progress = false
        },
        getAplyList:function(res){
            res.forEach(el => {                   
                this.copayClmDtlInfo.name       = el.name
                this.copayClmDtlInfo.bnMmbrPk   = el.bnMmbrPk
                this.copayClmDtlInfo.bnfcrPk    = el.bnfcrPk       
            });
        },
        onClose: function () {
            this.$emit('isClose')            
        },
        //미납금 삭제
        delUnpd:function(){
            if(this.copayClmDtlInfo.copayClmHisPk > 0){                
                this.delTitle   = this.copayClmDtlInfo.name+'님의 '+this.$moment(this.copayClmDtlInfo.clmYymm,'YYYYMM').format('YYYY.MM')+' 미납금'
                this.dialog.splice(1, 1, true)
            }
        }, 
        clearFrm:function(){
            //값 초기화
            this.copayClmDtlInfo.name = ''
            this.copayClmDtlInfo.bnMmbrPk = 0
            this.copayClmDtlInfo.bnfcrPk = 0
            this.copayClmDtlInfo.upBedFee =  0 
            this.copayClmDtlInfo.clmTot =  0 
            this.copayClmDtlInfo.clncPhrmcFee =  0 
            this.copayClmDtlInfo.nhisPay =  0 
            this.copayClmDtlInfo.rmrks =  '초기셋팅 - 시스템 초기 미납금' 
            this.copayClmDtlInfo.btyFee =  0             
            this.copayClmDtlInfo.clmYymm =  '' 
            this.copayClmDtlInfo.dueDt =  ''             
            this.copayClmDtlInfo.copay =  0             
            this.copayClmDtlInfo.clmDt =  '' 
            this.copayClmDtlInfo.copayClmHisPk =  0 
            this.copayClmDtlInfo.msclnFee =  0 
            this.copayClmDtlInfo.snackFee =  0 
            this.copayClmDtlInfo.lqdFood =  0 
            this.copayClmDtlInfo.rmnPrpym =  0 
            this.copayClmDtlInfo.cntrcDctrFee =  0             
            this.copayClmDtlInfo.copayAmt =  0 
            this.copayClmDtlInfo.mealCost =  0
            this.copayClmDtlInfo.unpdAmt =  0 
            this.copayClmDtlInfo.ofrDys =  0 
            this.copayClmDtlInfo.prpymApl =  0 
            this.copayClmDtlInfo.otrAmt =  0
            this.copayClmDtlInfo.clmMnthSum = 0
            this.$refs.form.resetValidation()
            this.filledBtn[0].disabled = false
            this.filledBtn[1].disabled = true
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({     
        ymd:'',     
        delTitle:'',
        progress:false,
        copayClmDtlInfo:{
            name:'',
            bnMmbrPk:0,
            bnfcrPk:0,
            upBedFee: 0, 
            clmTot: 0, 
            clncPhrmcFee: 0, 
            nhisPay: 0, 
            rmrks: '초기셋팅 - 시스템 초기 미납금', 
            btyFee: 0,             
            clmYymm: '', 
            dueDt: '',             
            copay: 0,             
            clmDt: '', 
            copayClmHisPk: 0, 
            msclnFee: 0, 
            snackFee: 0, 
            lqdFood: 0, 
            rmnPrpym: 0, 
            cntrcDctrFee: 0,             
            copayAmt: 0, 
            mealCost: 0,
            unpdAmt: 0, 
            ofrDys: 0, 
            prpymApl: 0, 
            otrAmt: 0,
            clmMnthSum:0,
        },
        mdlTtl: '',
        tabInfList: [],        
        rtrInc:false,
        dialog:[false,false,],
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true, disabled: true,},
        ],          
        snackOptions: [false, 3000, '', 'info'],    
        rules: {
            required: value => !!value || 'Required.',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },         
    }),
};
</script>