<template>
    <v-sheet class="bnftSrvMgmt-sheet overflow-x-auto" max-width="1200px" min-width="1200px">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <div class="d-flex justify-center align-center ma-0" style="position:relative;">
            <expandYearOnlyPicker @nowYear = "getYear" class="my-5"></expandYearOnlyPicker>
            <span style="position:absolute;top5px;right:20px;">
                <v-btn
                    class="ml-1" color="#2196f3" style="padding: 13px 12px 12px 10px !important"
                    min-width="30" height="25"
                    :disabled="bnMmbrPk<1"
                    @click="menu.splice(1, 1, true)"
                    small outlined>
                    <v-icon size="20">mdi-square-edit-outline</v-icon>
                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">식이정보관리</span>                
                </v-btn>
            </span>
        </div>
        <div style="width:1200px;">            
            <v-data-table hide-default-footer dense locale="ko" height="700" min-width="1200"
                hide-default-header fixed-header 
                :headers="bnftSrvHeader" 
                :items="bnftSrvList" 
                class="" disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>                                                        
                            <th class="black--text clmsHeaderCommon tableBR tableBT font-resize" style="width: 5%" colspan="1" rowspan='3'>{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT font-resize" style="width: 48%" colspan="6" rowspan='1'>{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT font-resize" colspan="3" rowspan='1'>{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT font-resize" style="width: 8%" colspan="1" rowspan='1'>
                                <div>급여제공</div>
                                <div>계획변경</div>
                                <div>(필요시)</div>
                            </th>
                            <th class="black--text clmsHeaderCommon tableBT font-resize" colspan="2" rowspan='1'>
                                <div>급여제공</div>
                                <div>결과평가</div>
                            </th>
                        </tr>  
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>{{props.headers[5].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>{{props.headers[6].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>{{props.headers[7].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>{{props.headers[8].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>{{props.headers[9].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>
                                <div>물리&#40;작업&#41;</div>
                                <div>치료평가</div>
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>
                                <div>물리&#40;작업&#41;</div>
                                <div>치료계획</div>
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="top:64px !important" colspan="2" rowspan='1'>{{props.headers[12].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>
                                <div>급여제공</div>
                                <div>계획변경</div>
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>{{props.headers[14].text}}</th>
                            <th class="black--text clmsHeaderCommon font-resize" style="width: 8%;top:64px !important" colspan="1" rowspan='1'>{{props.headers[15].text}}</th>                            
                        </tr>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important" colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(0,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(1,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(2,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(3,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(4,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(5,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(6,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(7,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 7%;top:106px !important"  colspan="1" rowspan='1'>서명</th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(8,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(9,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR font-resize" style="width: 8%;top:106px !important"  colspan="1" rowspan='1'>
                                <v-btn icon color="blueBtnColor" @click="showDialog(9,0)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn> 
                            </th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize">{{item.rn}}</td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(0,item.bnfcrExamHisPk101)">
                            <div class="d-flex justify-center">
                                <span>{{item.examDt101}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.examRsncd101}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(1,item.bnfcrExamHisPk102)">
                            <div class="d-flex justify-center">
                                <span>{{item.examDt102}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.examRsncd102}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(2,item.bnfcrExamHisPk103)">
                            <div class="d-flex justify-center">
                                <span>{{item.examDt103}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.examRsncd103}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(3,item.bnfcrExamHisPk104)">
                            <div class="d-flex justify-center">
                                <span>{{item.examDt104}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.examRsncd104}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(4,item.bnfcrExamHisPk105)">
                            <div class="d-flex justify-center">
                                <span>{{item.examDt105}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.examRsncd105}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(5,item.bnfcrExamHisPk106)">
                            <div class="d-flex justify-center">
                                <span>{{item.examDt106}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.examRsncd106}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(6,item.bnftOfrPlnHisPk202, item.bnfcrPk202)">
                            <div class="d-flex justify-center">
                                <span>{{item.wrDt202}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.mdfClcd202}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(7,item.bnftOfrPlnHisPk201, item.bnfcrPk201)">
                            {{item.bnfcrPk201}}
                            <div class="d-flex justify-center">
                                <span>{{item.wrDt201}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span v-if="item.bzPk != null && item.bzPk > 0" class="py-1">
                                    <span class="pa-1 result-msg">결과평가반영</span>
                                </span>
                                <span v-else> {{item.mdfClcd201}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showBizMsg(item.bnftOfrPlnHisPk201)">                            
                            {{item.eSigCfm}}
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(8,item.bnftOfrPlnHisPk3)">
                            <div class="d-flex justify-center">
                                <span>{{item.wrDt3}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.wrtr3}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon tableBR font-resize" @click="showChkDialog(9,item.bnftOfrPlnHisPk4, item.bnfcrPk4)">
                            <div class="d-flex justify-center">
                                <span>{{item.wrDt4}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.wrtr4}}</span>          
                            </div>
                        </td>
                        <td class="fixedCol clmsBodyCommon font-resize" @click="showChkDialog(9,item.bnftOfrPlnHisPk5, item.bnfcrPk5)">
                            <div class="d-flex justify-center">
                                <span>{{item.wrDt5}}</span> 
                            </div>
                            <div  class="d-flex justify-center" >
                                <span> {{item.wrtr5}}</span>          
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[0]" persistent max-width="1400" min-width="1400">                
                <DsrEvltnComp 
                    ref="dsrEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :bsrEvltnPk="bsrEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></DsrEvltnComp>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[1]" persistent max-width="1400" min-width="1400">                
                <FallEvltnComp 
                    ref="fallEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :fallEvltnPk="fallEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></FallEvltnComp>
            </v-dialog>
        </div>    
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[2]" persistent max-width="1400" min-width="1400">                
                <BdsrEvltnComp 
                    ref="bdsrEvltn"
                    :bnMmbrPk="bnMmbrPk"
                    :bdsrEvltnPk="bdsrEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></BdsrEvltnComp>
            </v-dialog>
        </div>   
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[3]" persistent max-width="1400" min-width="1400">                
                <CgntvEvltnComp 
                    ref="cgntvEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :cgntvEvltnPk ="cgntvEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></CgntvEvltnComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[4]" persistent max-width="1400" min-width="1400">                
                <HlthEvltnComp 
                    ref="hlthEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :hlthEvltnPk ="hlthEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></HlthEvltnComp>
            </v-dialog>
        </div>   
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[5]" persistent max-width="1470" min-width="1470">                
                <PhyscThrpyEvltnComp 
                    ref="physcThrpyEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :inqYYYY="year"
                    :physcThrpyEvltnPk="physcThrpyEvltnPk"
                    @modalEvent="modalEvent"></PhyscThrpyEvltnComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[6]" persistent max-width="1500" min-width="1500">                
                <PhyscThrpyPlnComp 
                    ref="physcThrpyPln"
                    :bnMmbrPk="bnMmbrPk"
                    :bnfcrPk="bnfcrPk"
                    :inqYYYY="year"
                    :physcThrpyPlnPk="physcThrpyPlnPk"
                    @modalEvent="modalEvent"></PhyscThrpyPlnComp>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[7]" persistent max-width="1600" min-width="1600">                
                <BnftSrvPlnComp 
                    v-if="dialogTab[7]"
                    ref="bnftSrvPln"
                    :bnMmbrPk="bnMmbrPk" 
                    :bnfcrPk="bnfcrPk"
                    :inqYYYY="year"
                    :btnDisabled="false"
                    :bnftSrvPlnCmpPk="bnftSrvPlnCmpPk"
                    :bzPk="plnBzPk"
                    @modalEvent="modalEvent"></BnftSrvPlnComp>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[8]" persistent max-width="1400" min-width="1400">                
                <BnftSrvPlnMdfComp 
                    ref="bnftSrvPlnMdf"
                    :bnMmbrPk="bnMmbrPk" 
                    :inqYYYY="year"
                    :bnftSrvPlnPk="bnftSrvPlnPk"
                    @isClose="modalEvent(8)"
                    @modalEvent="modalEvent"></BnftSrvPlnMdfComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[9]" persistent max-width="1500" min-width="1500">                
                <BnftSrvRsltEvltnComp 
                    ref="bnftSrvRsltEvltn"
                    :bnMmbrPk="bnMmbrPk"
                    :bnfcrPk="bnfcrPk"  
                    :inqYYYY="year"
                    :bnftSrvRsltEvltnPk="bnftSrvRsltEvltnPk"
                    @modalEvent="modalEvent"></BnftSrvRsltEvltnComp>
            </v-dialog>
        </div>                   
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[0]" content-class="round" max-width="1000" persistent eager>
                <ReportMgmt ref="reportMgmt" @madalState="menu.splice(0, 1, false)"></ReportMgmt>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="menu[1]" content-class="round" max-width="1300" persistent eager>
                <BnfcrDietsMdl 
                    v-if="menu[1]" 
                    ref="bnfcrDietsMdl" 
                    :bnMmbrPk="bnMmbrPk"
                    @isClose="menu.splice(1, 1, false)"></BnfcrDietsMdl>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogSign" v-if="dialogSign" persistent max-width="800" min-width="800">                
                <BnftSrvPlnSignMdl 
                    ref="bnftSrvPlnSignMdl"
                    :bnMmbrPk="bnMmbrPk"
                    :bzPk="bizMsgBzPk"
                    :eSigClcd="4"
                    :msgTitle="year+'년 급여제공계획'"
                    :msgCntnt="$store.getters.getBnfcrState.name"
                    @modalEvent="modalEvent"
                    @isClose="onSendClose"></BnftSrvPlnSignMdl>
            </v-dialog>
        </div> 
    </v-sheet>
</template>

<script>
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import { getBnfcrBnftOfrList } from '../../api/bnftSrv.js';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

import DsrEvltnComp from '../../components/bnftSrv/DsrEvltn.vue'; //욕구사정
import FallEvltnComp from '../../components/bnftSrv/FallEvltn.vue'; //낙상위험도
import BdsrEvltnComp from '../../components/bnftSrv/BdsrEvltn.vue'; //욕창위험도
import CgntvEvltnComp from '../../components/bnftSrv/CgntvEvltn.vue'; //인지기능
import HlthEvltnComp from '../../components/bnftSrv/HlthEvltn.vue'; //건강수준
import PhyscThrpyEvltnComp from '../../components/bnftSrv/PhyscThrpyEvltn.vue'; //물리치료평가
import PhyscThrpyPlnComp from '../../components/bnftSrv/PhyscThrpyPln.vue'; //물리치료계획
import BnftSrvPlnComp from '../../components/bnftSrv/BnftSrvPln.vue'; //급여제공계획
import BnftSrvPlnMdfComp from '../../components/bnftSrv/BnftSrvPlnMdf.vue'; //급여제공계획변경
import BnftSrvRsltEvltnComp from '../../components/bnftSrv/BnftSrvRsltEvltn.vue'; //급여제공결과평가
import BnftSrvPlnSignMdl from '../../components/bnftSrv/BnftSrvPlnSignMdl.vue'; //전자발송관리
import BnfcrDietsMdl from '../../components/bnftSrv/BnfcrDietsMdl.vue'; //식이정보관리


import ReportMgmt from '../../components/commons/ReportMgmt.vue';


export default {
    name: 'BnftSrvMgmt',

    created: function() {
        
    },
        
    components: {
        basicInfo,
        expandYearOnlyPicker,
        DsrEvltnComp,
        FallEvltnComp,
        BdsrEvltnComp,
        CgntvEvltnComp,
        HlthEvltnComp,
        PhyscThrpyEvltnComp,
        PhyscThrpyPlnComp,
        BnftSrvPlnComp,
        BnftSrvPlnMdfComp,
        BnftSrvRsltEvltnComp,
        ReportMgmt,      
        BnftSrvPlnSignMdl,  
        BnfcrDietsMdl,
    },

    computed: {
        getBnmmbrPk(){
            return this.$store.getters.getBnfcrState.bnmmbr
        }
    },

    watch: {
        getBnmmbrPk(value){
            this.bnMmbrPk = value   
            this.getBnfcrBnftOfrList()     
        },
    },

    mounted: function(){
        this.$nextTick(function () {  
            this.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.getBnfcrBnftOfrList()
        })
    },
    
    methods: {
        getList:function(){
            this.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.getBnfcrBnftOfrList()
        },
        clearBlob:function(){
        },
        clearForm: function (){
            this.bnMmbrPk = 0
            this.bnftSrvList.splice(0)
        },
        
        getYear: function (yyyy){
            this.year = yyyy
            this.getBnfcrBnftOfrList();
        },
        getBnfcrBnftOfrList: function (){
            this.onBtn()
            let code = this.$store.getters.getFcltyNum;
            let state = {};
            state.inqYYYY = this.year;
            state.bnMmbrPk = this.bnMmbrPk;
            getBnfcrBnftOfrList(code,state).then((response) => ( this.getBnfcrBnftOfrListAfter(response.data)))
                .catch((error) => console.log(error))
        },
        getBnfcrBnftOfrListAfter:function(data){              
            this.bnftSrvList.splice(0)

            data.map(x=>{                
                x.examDt101 = x.examDt101?.beforeDateFormatDot()
                x.examRsncd101 = this.setExamRsncdNm(x.examRsncd101)                   
                x.examDt102 = x.examDt102?.beforeDateFormatDot()
                x.examRsncd102 = this.setExamRsncdNm(x.examRsncd102) 
                x.examDt103 = x.examDt103?.beforeDateFormatDot()
                x.examRsncd103 = this.setExamRsncdNm(x.examRsncd103)
                x.examDt104 = x.examDt104?.beforeDateFormatDot()
                x.examRsncd104 = this.setExamRsncdNm(x.examRsncd104) 
                x.examDt105 = x.examDt105?.beforeDateFormatDot()
                x.examRsncd105 = this.setExamRsncdNm(x.examRsncd105) 
                x.examDt106 = x.examDt106?.beforeDateFormatDot()
                x.examRsncd106 = this.setExamRsncdNm(x.examRsncd106)
                x.wrDt202 = x.wrDt202?.beforeDateFormatDot()
                x.mdfClcd202 = this.setPlnMdfClcdNm(x.mdfClcd202) 
                x.wrDt201 = x.wrDt201?.beforeDateFormatDot()
                x.mdfClcd201 = this.setPlnMdfClcdNm(x.mdfClcd201) 
                x.wrDt3 = x.wrDt3?.beforeDateFormatDot()
                x.mdfClcd3 = this.setPlnMdfClcdNm(x.mdfClcd3)
                x.wrDt4 = x.wrDt4?.beforeDateFormatDot()
                x.mdfClcd4 = this.setPlnMdfClcdNm(x.mdfClcd4)
                x.wrDt5 = x.wrDt5?.beforeDateFormatDot()
                x.mdfClcd5 = this.setPlnMdfClcdNm(x.mdfClcd5)

                this.bnftSrvList.push(x)
            })

            if(data.length > 11){
                this.headerStyle="margin-right:10px";
            }else{
                this.headerStyle="";
            }
            
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.getBnfcrBnftOfrList();
            }else{
                switch (event) {
                    case 0:
                        this.bsrEvltnPk = -1
                        break;
                    case 1:
                        this.fallEvltnPk = -1
                        break;
                    case 2:
                        this.bdsrEvltnPk = -1
                        break;
                    case 3:
                        this.cgntvEvltnPk = -1
                        break;    
                    case 4:
                        this.hlthEvltnPk = -1
                        break;    
                    case 5:
                        this.physcThrpyEvltnPk = -1
                        break; 
                    case 6:
                        this.physcThrpyPlnPk = -1
                        break;  
                    case 7:
                        this.bnftSrvPlnCmpPk = -1
                        this.plnBzPk = -1
                        break;      
                    case 8:
                        this.bnftSrvPlnPk = -1
                        break;
                    case 9:
                        this.bnftSrvRsltEvltnPk = -1
                        break;
                    default:
                        break;
                }
                this.dialogTab.splice(event, 1, false)
            }      
        },
        setExamRsncdNm:function(value){
            if(value=="1"){
                // return "신규"
                return ""
            }else if(value=="2"){
                return "재사정"
            }else if(value=="3"){
                return "상태변화"
            }
        },
        setPlnMdfClcdNm:function(value){
            if(value=="1"){
                // return "신규"
                return ""
            }else if(value=="2"){
                return "변경"
            }
        },
        showChkDialog(tabkey,value,bnfcr=null){
            
            if(value > 0) this.showDialog(tabkey,value,bnfcr)
        },
        showDialog(tabkey,value, bnfcr=null){   

            this.bnfcrPk = (bnfcr != null)?bnfcr:this.$store.getters.getBnfcrState.bnfcr
            this.dialogTab.splice(tabkey, 1, true)

            if(tabkey == 0){      
                this.bsrEvltnPk = value      
            }else if(tabkey == 1){
                this.fallEvltnPk = value                      
            }else if(tabkey == 2){
                this.bdsrEvltnPk = value      
            }else if(tabkey == 3){
                this.cgntvEvltnPk = value
            }else if(tabkey == 4){
                this.hlthEvltnPk = value
            }else if(tabkey == 5){
                this.physcThrpyEvltnPk = value
            }else if(tabkey == 6){
                this.physcThrpyPlnPk = value
            }else if(tabkey == 7){
                this.bnftSrvPlnCmpPk = value
            }else if(tabkey == 8){                                
                this.bnftSrvPlnPk = value
            }else if(tabkey == 9){
                this.bnftSrvRsltEvltnPk = value
            }           
        },        
        showBizMsg:function(selPk){
            if(selPk > 0){
                this.bizMsgBzPk = selPk
                // this.bizMsg = true
                this.dialogSign = true
            }else{
                this.snackControll([true, 5000, '급여제공계획을 작성해주세요.', 'error'])
            }
        },
        onSendClose:function(){
            // this.bizMsg = false
            this.bizMsgBzPk = -1
            this.dialogSign = false
        },
        onBtn(){

            //수급자 선택시 버튼 활성화
            if(this.bnMmbrPk != null && this.bnMmbrPk > 0){
                this.btnOnOff=false
            }else{
                this.btnOnOff=true
            }   
        },
        
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [ false, false,],
        year: 0,
        bnMmbrPk:0,   
        bnfcrPk:0,   
        bsrEvltnPk:-1,              //욕구사정
        fallEvltnPk:-1,             //낙상위험도
        bdsrEvltnPk:-1,             //욕창위험도
        cgntvEvltnPk:-1,            //인지기능
        hlthEvltnPk:-1,             //건강수준
        physcThrpyEvltnPk:-1,       //물리치료평가
        physcThrpyPlnPk:-1,         //물리치료계획
        bnftSrvPlnCmpPk:-1,         //급여제공계획
        bnftSrvPlnPk:-1,            //급여제공계획변경
        bnftSrvRsltEvltnPk:-1,      //급여제공결과평가
        plnBzPk : -1,
        btnOnOff:true,
        bnftSrvHeader:[
            { text: '회차', align: 'center'},
            { text: '수급자 통합적 평가(연1회)', align: 'center'},
            { text: '급여제공 계획 (연1회)', align: 'center'},
            { text: '급여제공 계획변경(필요시)', align: 'center'},
            { text: '급여제공 결과평가', align: 'center'},
            { text: '욕구사정', align: 'center'},
            { text: '낙상위험도', align: 'center'},
            { text: '욕창위험도', align: 'center'},
            { text: '인지기능', align: 'center'},
            { text: '건강수준', align: 'center'},
            { text: '물리(작업) 치료평가', align: 'center'},
            { text: '물리(작업) 치료계획', align: 'center'},
            { text: '급여제공계획', align: 'center'},
            { text: '급여제공 계획변경', align: 'center'},
            { text: '상반기', align: 'center'},
            { text: '하반기', align: 'center'},
        ],
        bnftSrvList:[], 
        dialog: [false], 
        dialogTab: [false,false,false,false,false,false,false,false,false,false,false],
        headerStyle:'',         
        bnfcrExamHisPk:'',
        bizMsg:false,
        bizMsgBzPk:-1,
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '7', inqYmdStart: '', inqYmdEnd: '',
            inqYYYY: '', list: [ { bzClcd: [], bzPk: [] } ],
        },
        dialogSign:false,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>    
    .bnftSrvMgmt-sheet .cols-py-cstm1 {padding:25.5px 0px !important;}
    .bnftSrvMgmt-sheet .cols-height-cstm {height:50px}
    .bnftSrvMgmt-sheet .cols-line-left {border-left: 1px solid #dbdbdb !important;}
    .bnftSrvMgmt-sheet .fixedCol:hover { background: #ebf6fe !important; cursor: pointer;}
    .bnftSrvMgmt-sheet .font-resize {font-size:1.0rem !important}
    .bnftSrvMgmt-sheet .result-msg {border-radius:5px;background-color:#048d22;color:#FFFFFF;font-size:0.9rem}
</style>
