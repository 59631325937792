<template>
    <v-sheet>        
        <div style="position:relative;">
             <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="6">
                <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-3">연계기록지 직접수령</span>
            </v-col>
            <v-col class="d-flex justify-end align-center" cols="6">
                <span>
                    <v-btn
                        class="white--text" height="26" color="blueBtnColor"
                        @click="preDirctInf()" :disabled="saveBtn" rounded samll>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span class="fontOneRem">저장</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">제공일자</span>
                </v-col>
                <v-col class="pa-1" cols="4">
                    <span class="d-inline-block" style="width: 130px;">
                        <CmmDateComp v-model="drtItm.sndDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">제공자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="4">
                    <span class="d-inline-block black--text font-weight-medium ml-1" style="width:100px;">
                        <v-text-field
                            v-model="drtItm.sndNm" :rules="[rules.required]"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3 d-inline-block"
                        style="padding:0px 1px 0px 2px !important"
                        min-width="30" height="26" @click="onModal"
                        dark small>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium">수령자</span>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="4">
                    <span v-if="!drctInp" class="d-inline-block ml-1" style="width: 140px;">
                        <v-select
                            v-model="fmlyIdx"
                            :items="fmlyList" :key="forceRender"
                            item-text="name" item-value="idx"
                            @change="setFmly(0)"
                            :rules="[rules.required]"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                    <span v-if="drctInp" class="d-inline-block ml-1" style="width: 100px;">
                        <v-text-field v-model="drtItm.rcvNm" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                    <span v-if="drctInp">                        
                        <v-btn 
                            class="ml-1 white--text darken-1" style="padding:0px 1px 0px 2px !important"
                            color="bckgrColor" min-width="30" height="25" @click="setFmly(1)"
                            dark small>
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>보호자선택</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-2" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium">관계</span>
                </v-col>
                <v-col class="px-1 py-2" cols="4">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-text-field v-model="drtItm.rcvInfm" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium">연락처</span>
                </v-col>
                <v-col class="py-2" cols="10">
                    <span class="d-inline-block pl-2 pt-0 pb-1">
                        <!-- 연락처 area -->
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="drtItm.rcvCelphn1" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="drtItm.rcvCelphn2" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="drtItm.rcvCelphn3" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                        </span>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-3">연계기록지 직접수령 내역</span>
            </v-col>            
        </v-row>

        <v-divider :class="['mt-2', eSndList.length > 9 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['pa-0 greyE01', eSndList.length > 9 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">제공일자</span>
            </v-col>
            <v-col class="tableBL py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">제공자</span>
            </v-col>
            <v-col class="tableBL py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">수령자</span>
            </v-col>
            <v-col class="tableBL py-2" style="text-align: center;" cols="2">
                <span class="black--text font-weight-medium">관계</span>
            </v-col>
            <v-col class="tableBL py-2" style="text-align: center;" cols="3">
                <span class="black--text font-weight-medium">휴대폰번호</span>
            </v-col>
            <v-col class="tableBL py-2" style="text-align: center;" cols="1">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="['', eSndList.length > 9 ? 'mr-2__5' : '']"></v-divider>

        <div
            class="overflow-auto d-inline-block"
            style="width: 100%; height: 323px;"
            v-if="eSndList?.length > 0">
            <v-row
                class="fixedCol tableBB pa-0" style="cursor: pointer;"
                v-for="(list, i) in eSndList" :key="i"
                no-gutters>
                <v-col class="py-1" style="text-align: center;" cols="2">
                    <span class="black--text">{{ $moment(list.sndDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="tableBL py-1" style="text-align: center;" cols="2">
                    {{list.sndNm}}
                </v-col>
                <v-col class="tableBL py-1" style="text-align: center;" cols="2">
                    {{list.rcvNm}}
                </v-col>
                <v-col class="tableBL py-1" style="text-align: center;" cols="2">
                    {{list.rcvInfm}}
                </v-col>
                <v-col class="tableBL py-1" style="text-align: center;" cols="3">
                    {{list.rcvAdr}}
                </v-col>
                <v-col class="tableBL py-1" style="text-align: center;" cols="1">
                    <span class="mr-1">
                        <v-btn color="grey006" height="26" @click="preDelESnd(list.eSndPk)" icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                                    </div>
                                </template>
                                <span>연계기록지 삭제</span>
                            </v-tooltip>
                        </v-btn>
                    </span>                    
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="pa-0 tableBB" no-gutters>
            <v-col class="py-2" style="text-align: center;" cols="12">
                <span class="grey006--text" style="font-size: 0.925rem;">&#8251;조회된&nbsp;항목이&nbsp;없습니다.</span>
            </v-col>
        </v-row>

        <v-dialog v-model="menu[0]" content-class="round" max-width="800" persistent eager>
            <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "drtItm.sndDt"></EmpSelector>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'연계기록지 직접수령'" @madalState="onDelESnd"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>
<script>
import { getAllFmlyList } from '../../api/index.js';
import { insESnd, delESnd, selESndList } from '../../api/othr.js';

import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

export default {
    name: 'BizMsgDirct',

    props : {       
        
    },
            
    components: {
        EmpSelector,
        CmmDateComp,
        btnModalDelete,
    },

    computed: {
       
    },  

    watch:{        
    },

    created:function(){ 
        
    },
    
    mounted: function(){
        this.$nextTick(function () {             
            this.$parent.$parent.$parent.$parent.bizMsgData.forEach(el => {
                this.sltBnMmbrPk = el
            })
            
            this.getFmlyList()
            this.getESndList()
        })
    },

    methods: {
        preDirctInf:function(){
            if(this.$refs.form.validate()){
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.sltBnMmbrPk,
                    userNm      : this.$store.getters.getUserNm,
                    sndMda      : '4',
                    sndNm       : this.drtItm.sndNm,
                    sndSttsCd   : '3',
                    sndDt       : this.$moment(this.drtItm.sndDt,'YYYY-MM-DD').format('YYYYMMDD'),
                    eDocClcd    : '13',
                    bzClcd      : '19',
                    bzPk        : this.$parent.$parent.$parent.$parent.bizMsgBzPk,
                    rcvNm       : this.drtItm.rcvNm,
                    rcvInfm     : this.drtItm.rcvInfm,
                    rcvCelphn1  : this.drtItm.rcvCelphn1,
                    rcvCelphn2  : this.drtItm.rcvCelphn2,
                    rcvCelphn3  : this.drtItm.rcvCelphn3,
                    rcvAdr      : this.drtItm.rcvCelphn1+'-'+this.drtItm.rcvCelphn2+'-'+this.drtItm.rcvCelphn3
                }
                this.saveBtn = true
                this.setESnd(obj)
            } else {
                this.snackControll([true, 5000, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        preDelESnd:function(pk){
            this.delPk = pk
            this.dialogDelete = true
        },
        //발송목록 조회
        getESndList:function(){   
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bzClcd      : '19',
                bzPk        : this.$parent.$parent.$parent.$parent.bizMsgBzPk,
                sndSttsCd   : '3',
            }   

            selESndList(obj)
                    .then((response) => ( this.getESndListAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/selESndList : ' + error))
        },
        //보호자 전체 목록 조회
        getFmlyList: function () {
            if(this.sltBnMmbrPk > 0){
                getAllFmlyList(this.$store.getters.getFcltyNum, this.sltBnMmbrPk)
                    .then((response) => ( this.getFmlyListAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/selAllFmlyList : ' + error))
            }
        },
        //발송직접입력
        setESnd:function(obj){            
            insESnd(obj)
                .then((response) => ( this.setESndAfter(response.data) ))
                .catch((error) => console.log('connect error /othr/insESnd : ' + error))
        },        
        onDelESnd:function(res){   
            if (res == 9990) {

                if(this.delPk > 0){
                    let obj = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        eSndPk      : this.delPk,
                        userNm      : this.$store.getters.getUserNm,
                    }         
                    delESnd(obj)
                        .then((response) => ( this.delESndAfter(response.data) ))
                        .catch((error) => console.log('connect error /othr/delESnd : ' + error))
                }

            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        getESndListAfter:function(res){
            this.eSndList.splice(0)
            res.forEach(el => {
                this.eSndList.push(el)
            });
        },
        setESndAfter:function(res){
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getESndList()
                this.onClear()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])

            this.saveBtn = false
        },
        delESndAfter:function(res){
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getESndList()
                this.onClear()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])

            this.delPk = -1
            this.dialogDelete = false
        },
        getFmlyListAfter:function(res){
            this.fmlyList.splice(0)

            res.forEach((el,i) => {
                el.idx  = i+1
                this.fmlyList.push(el)
            });

            this.fmlyList.push({idx:999,name:'직접입력'})
        },
        setFmly:function(typ){            
            if(typ == 0){
                if(this.fmlyIdx == 999){
                    this.drctInp = true  
                    this.drtItm.rcvNm        = ''
                    this.drtItm.rcvInfm      = ''
                    this.drtItm.rcvCelphn1   = ''
                    this.drtItm.rcvCelphn2   = ''
                    this.drtItm.rcvCelphn3   = ''
                }else{
                    let ix = this.fmlyList.findIndex(x=> x.idx == this.fmlyIdx)
                    let targetObj = this.fmlyList[ix]

                    if(targetObj != null && targetObj != undefined){
                        this.drtItm.rcvNm        = targetObj.name
                        this.drtItm.rcvInfm      = targetObj.rltns
                        this.drtItm.rcvCelphn1   = targetObj.celphn_1
                        this.drtItm.rcvCelphn2   = targetObj.celphn_2
                        this.drtItm.rcvCelphn3   = targetObj.celphn_3
                    }
                }
            }else if(typ == 1){
                this.fmlyIdx = 0
                this.drctInp = false
            }
        },
        onClear:function(){            
            this.drtItm.sndNm      = ''
            this.drtItm.sndDt      = ''
            this.drtItm.rcvNm      = ''
            this.drtItm.rcvInfm    = ''
            this.drtItm.rcvCelphn1  = ''
            this.drtItm.rcvCelphn2  = ''
            this.drtItm.rcvCelphn3  = ''
            this.fmlyIdx            = 0
            this.drctInp            = false
            this.$refs.form.resetValidation()
        },
        onModal: function () {
            this.menu.splice(0, 1, true)
        },
        isModal: function () {
            this.menu.splice(0, 1, false)
        },
        getEmpInfo: function (obj) {
            this.drtItm.sndNm = obj[0].name
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({ 
        forceRender:0,
        sndMda:'4',
        sltBnMmbrPk:-1,     
        fmlyIdx:0,  
        delPk:-1,
        drtItm:{
            sndNm       :'',
            sndDt       :'',
            rcvNm       :'',
            rcvInfm     :'',
            rcvCelphn1  : '',
            rcvCelphn2  : '',
            rcvCelphn3  : '',
        },
        menu:[false,false,false,],
        saveBtn:false,
        drctInp:false,
        fmlyList:[],
        eSndList:[],
        snackOptions: [false, 3000, '', 'info'],  
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
        },
        dialogDelete:false,
    }),
};
</script>