<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">문자내용</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>    
        <v-row class="mt-3" no-gutters>
            <v-col cols="12">
                <v-form ref="form" lazy-validation>
                    <v-data-table hide-default-footer dense locale="ko" height="510"
                        hide-default-header fixed-header 
                        :headers="eSndMsgHeader" 
                        :items="eSndMsgList" 
                        class="" disable-pagination>

                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>                            
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 550px !important;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>
                                        <v-btn 
                                            color="blue200" 
                                            height="26" 
                                            @click="addRow"
                                            icon>
                                            <v-icon>mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </th>
                                    <th class="black--text clmsHeaderCommon  tableBT" style="width: 80px !important;" colspan="1" rowspan='2'>{{props.headers[2].text}}</th>
                                </tr>
                            </thead>
                        </template>     
                        <template v-slot:item='{ item, index }'>
                            <tr>  
                                <td class="clmsBodyCommon tableBR pa-1">      
                                    <div>
                                        <v-textarea v-model="item.itmCntnt" :rules="[rules.required]"
                                            rows="3" class="rmrksArea" outlined dense hide-details no-resize>
                                        </v-textarea>
                                    </div>                              
                                    <!-- <div class="mt-1 d-flex justify-end align-center" style="font-size:0.9rem !important;">
                                        <span>{{item.itmCntnt?.getBytes()}}</span>
                                        <span>/</span>
                                        <span>80bytes</span>
                                    </div> -->
                                </td>
                                <td class="clmsBodyCommon tableBR">
                                    <div class="d-flex justify-center align-center">      
                                        <v-btn icon color='blue200' @click="onSaveRow(index)">
                                            <v-icon>mdi-content-save-outline</v-icon>
                                        </v-btn>                                                                   
                                        <v-btn icon color='grey006' @click="onDelRow(index)">
                                            <v-icon v-if="item.eSndMsgPk > 0">mdi-trash-can-outline</v-icon>
                                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                        </v-btn>                                
                                    </div>
                                </td>    
                                <td class="clmsBodyCommon">
                                    <v-btn v-if="item.eSndMsgPk > 0"
                                        class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 2px !important"
                                        color="bckgrColor" min-width="30" height="25"
                                        @click="onAction(index)"
                                        dark small>
                                            <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                                    </v-btn>
                                </td>                   
                            </tr>                            
                        </template>
                    </v-data-table>
                </v-form>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">                    
                <btnModalDelete :title="'문자 발송 내용'" @madalState="onDelete"></btnModalDelete>
            </v-dialog>   
        </div> 
    </v-sheet>
</template>

<script>
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import { selESndMsgMgmtList, insESndMsg, delESndMsg } from '../../api/othr.js';

export default {
    name: 'SmsSndMsgMgmt',
    
    components: {
        btnModalDelete,  
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {     
            this.getESndMsgMgmtList()       
        })
    },

    computed: {

    },

    watch: {
    },
        
    methods: {
        onClose:function(){
            this.$emit("isClose")
        },
        getESndMsgMgmtList:function(){
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eDocClcd : '99',                
                sndMda:'2',
            }
            selESndMsgMgmtList(obj)
                    .then((response) => ( this.getESndMsgMgmtListAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/selESndMsgMgmtList : ' + error))
        },
        getESndMsgMgmtListAfter:function(res){
            this.eSndMsgList = res
        },
        onSaveRow:function(idx){
            if(this.$refs.form.validate()){
                let target = this.eSndMsgList[idx]
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    eSndMsgPk:target.eSndMsgPk,
                    sndMda:'2',
                    eDocClcd:'99',
                    itmOrd:idx+1,
                    itmClcd:'2',
                    itmTypClcd:'1',
                    itmCntnt:target.itmCntnt,
                }
                insESndMsg(obj)
                    .then((response) => ( this.onSaveRowAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/insESndMsg : ' + error))                
            }
        },
        onSaveRowAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getESndMsgMgmtList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onAction:function(key){
            let target = this.eSndMsgList[key]
            this.$emit("outSndMsg",target)
            this.onClose()
        },
        addRow:function(){
            this.eSndMsgList.push({eSndMsgPk:0,itmCntnt:''})
        },
        onDelRow:function(key){
            let target = this.eSndMsgList[key]
            
            if(target.eSndMsgPk > 0){
                this.idx = key
                this.dialogDelete = true
            }else{
                 this.eSndMsgList.splice(key,1)
            }
        },
        onDelete:function(res){
            if(res == 9990){
                let target = this.eSndMsgList[this.idx]
                if(target.eSndMsgPk > 0){
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm : this.$store.getters.getUserNm,
                        eSndMsgPk : target.eSndMsgPk,
                    }
    
                    delESndMsg(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log(error))
                }else{
                    this.snackControll([true, 5000, '대상건을 찾지 못했습니다.', 'error'])
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getESndMsgMgmtList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        idx:0,
        eSndMsgHeader:[
            { text: '문자 내용', value: '', align: 'center'},
            { text: '처리', value: '', align: 'center'},
            { text: '적용', value: '', align: 'center'},
        ],
        eSndMsgList:[],
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        dialogDelete:false,
    }),
};
</script>