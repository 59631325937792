<template>
    <v-sheet class="mr-2" rounded="md">
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">4대보험</span>
            </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-row class="greyE01 pa-0 ma-0" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text font-weight-medium">구분</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text font-weight-medium">가입일자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">상실일자</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">국민연금</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">{{ prtNtnlPnsn.rgstDt }}</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <span class="black--text">{{ prtNtnlPnsn.lossDt }}</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">건강보험</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">{{ prtHlthInsrn.rgstDt }}</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <span class="black--text">{{ prtHlthInsrn.lossDt }}</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">고용보험</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">{{ prtEmplyInsrn.rgstDt }}</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <span class="black--text">{{ prtEmplyInsrn.lossDt }}</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">산재보험</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text">{{ prtIndstInsrn.rgstDt }}</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <span class="black--text">{{ prtIndstInsrn.lossDt }}</span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
    </v-sheet>
</template>

<script>
export default {
    name: 'EmpMajorInsrn',

    props : {
       prtEmpPk: { type: Number, default: -1 },
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.prtNtnlPnsn = this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.ntnlPnsn
            this.prtHlthInsrn = this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.hlthInsrn
            this.prtEmplyInsrn = this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.emplyInsrn
            this.prtIndstInsrn = this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.indstInsrn
        })
    },

    computed: {
        getNtnlPnsn: function (){
            return this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.ntnlPnsn
        },
        getHlthInsrn: function (){
            return this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.hlthInsrn
        },
        getEmplyInsrn: function (){
            return this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.emplyInsrn
        },
        getIndstInsrn: function (){
            return this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.indstInsrn
        },
    },

    watch:{
        'prtEmpPk': function () {

        },
        getNtnlPnsn: function (value) {
            this.prtNtnlPnsn = value
        },
        getHlthInsrn: function (value) {
            this.prtHlthInsrn = value
        },
        getEmplyInsrn: function (value) {
            this.prtEmplyInsrn = value
        },
        getIndstInsrn: function (value) {
            this.prtIndstInsrn = value
        },
    },
    
    methods: {

    },

    data: () => ({
        prtNtnlPnsn: {},
        prtHlthInsrn: {},
        prtEmplyInsrn: {},
        prtIndstInsrn: {},
    }),
};
</script>