<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="6">
                <span class="text-h4 font-weight-bold">자원봉사자&nbsp;활동일지&nbsp;작성</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-form ref="vlntrWorkJrnl" lazy-validation>
            <v-divider class="mt-5"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">자원봉사자</span>
                </v-col>
                <v-col class="d-flex align-center tableBL tableBR py-1" cols="4">
                    <span class="d-inline-block mx-1" style="width: 50%;">
                        <v-text-field v-model="vlntrInfo.vlntrNm" :rules="[rules.required]"
                            color="blue" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">확인자</span>
                </v-col>
                <v-col class="d-flex align-center tableBL py-1" cols="4">
                    <span class="d-inline-block mx-1" style="width: 50%;">
                        <v-text-field v-model="vlntrInfo.cnfmNm" :rules="[rules.required]"
                            color="blue" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block" style="width: 28px; height: 28px; margin-bottom: 2px;">
                        <v-btn 
                            class="brown lighten-3 white--text" min-width="28" height="26"
                            @click="menu.splice(3, 1, true)"
                            style="padding: 0px 1px 0px 2px !important" small>
                            <v-icon size="20">mdi-account-multiple</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">활동일시</span>
                </v-col>
                <v-col class="d-flex align-center tableBL py-1" cols="10">
                    <span class="d-inline-block mx-1" style="width: 133px;">
                        <CmmDateComp
                            v-model="vlntrInfo.wrkDt" :required="true">
                        </CmmDateComp>
                    </span>
                    <span class="white d-inline-block px-1" style="width: 80px;">
                        <CmmTimeField
                            v-model="vlntrInfo.wrkStrt" :maxTm="vlntrInfo.wrkEnd" :required="true">
                        </CmmTimeField>                                     
                    </span>
                    <span class="d-flex align-center mx-2">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <span class="white d-inline-block px-1" style="width: 80px;">
                        <CmmTimeField
                            v-model="vlntrInfo.wrkEnd" :minTm="vlntrInfo.wrkStrt" :required="true">
                        </CmmTimeField>                                     
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">활동내용</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="10">
                    <v-textarea
                        class="rmrksArea" v-model="vlntrInfo.wrkCntnt" no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="mt-7 pa-0" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">관련자료</span>
                </v-col>
            </v-row>
            <v-divider class="mt-2"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">사진첨부</span>
                    <span class="d-inline-block">
                        <v-file-input
                            class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                            prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="tableBL pa-2" cols="10">
                    <span class="pb-2 pr-2"
                        v-for="(item, j) in fileList" :key="j">
                        <v-chip
                            class="mt-1" label close outlined
                            @click="athFileDownload(j)" @click:close="delAtchfiles(j)">
                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            <span class="longText">{{ item.orgFileNm }}</span>
                        </v-chip>
                    </span>
                    <v-divider class="my-2"></v-divider>
                    <div>
                        <span class="d-inline-block mx-1"
                            v-for="( item, i ) in imgList" :key="i">
                            <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)"></imageCard>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" height="26" @click="preExtEmpsInfo()" samll rounded>
                    <v-icon small>mdi-content-save-outline</v-icon>
                    <span style="font-size: 1rem;">저장</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[3]" max-width="700" content-class="round" persistent eager>
            <!-- 
                mdlTtl: 팝업 타이틀(누락시 = 참석자 선택), tabInfList: 활성화 탭 배열 ( '1': 수급자, '2': 직원 ),
                cd41, cd42: 직원 탭에서 사용하는 공통코드 (필수), intgrResList: Array - 팝업에서 선택된 목록
                mode: String - 단일 / 멀티 선택 ( '1': 단일, '2': 멀티, default: '2' )
                fixList: 선택을 제한할 목록, onClose: String - 결과 값 0 고정
                * fixList: 오브젝트에 다음 키를 추가 해야함 ( disChekr:boolean, checkr:String )
                ** disChekr: 체크 가능여부,  checkr: 체크박스 모델
             -->
             <IntgrSlctn
                :mdlTtl="'직원 선택'" :tabInfList="['2']" :fixList="exclsList" :mode="'1'"
                :cd41="$parent.$parent.$parent.$parent.$parent.$parent.vCd41"
                :cd42="$parent.$parent.$parent.$parent.$parent.$parent.vCd42"
                :cd65="$parent.$parent.$parent.$parent.$parent.$parent.vCd65"
                :prtWrtDt = "vlntrInfo.wrkDt"
                @intgrResList="getAplyList" @onClose="modalChanger">
            </IntgrSlctn>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { insVlntrWrkRec } from '../../api/emp.js';
import { tmpFileUpload, selAtchdFileList, delAtchdFile } from '../../api/index.js';

import { remoteURL } from '@/api/baseAxios';

import imageCard from '../commons/imageCard.vue';
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'VlntrWorkJrnlMdl',

    props : {
        bzpk: { type: Number, default: -1 }
    },
        
    components: {
        imageCard,
        IntgrSlctn,
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk != -1){
                this.$parent.$parent.$parent.$parent.$parent.$parent.empList.forEach(items => {
                    if(items.empPk == this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk){
                        this.vlntrInfo.vlntrNm = items.name
                        this.vlntrInfo.empPk = items.empPk
                        this.vlntrInfo.mmbrPk = items.mmbrPk
                        this.exclsList.push({ empPk: items.empPk, mmbrpk: items.mmbrPk, checkr: '2', disChekr: true })
                    }
                });
            }

        })
    },

    computed: {
        getSltEmpPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
        },
    },

    watch:{
        getSltEmpPk: function (value) {
            this.exclsList.splice(0)
            this.$parent.$parent.$parent.$parent.$parent.$parent.empList.forEach(items => {
                if(items.empPk == value){
                    this.vlntrInfo.vlntrNm = items.name
                    this.vlntrInfo.empPk = items.empPk
                    this.vlntrInfo.mmbrPk = items.mmbrPk
                    this.exclsList.push({ empPk: items.empPk, mmbrpk: items.mmbrPk, checkr: '2', disChekr: true })
                }
            });
        },
    },
    
    methods: {
        setVlntrWrkRec: function (obj) {
            insVlntrWrkRec(obj)
                .then((response) => ( this.asyncResultMdf(response.data) ))
                .catch((error) => console.log('connect error /emp/insVlntrWrkRec : ' + error))
        },
        //첨부파일 목록 조회
        getAtchdFileList: function (pk){
            selAtchdFileList(this.$store.getters.getFcltyNum, 14, pk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
        },
        //첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
        },
        asyncResultMdf: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$refs.prdWorkJrnl.extCallList(this.vlntrInfo.wrkDt?.afterDateFormatHyp())
                this.onClose()
            }
            else {
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }
        },
        getAtchdFileListAfter: function(res){
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            let fList = res.filter(v => v.fileKndCd == '1')
            let iList = res.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgView?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })
        },
        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },
        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList(this.bzpk)
                this.forceRender += 1
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },
        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            }
            else {
                this.imgList.splice(idx, 1)
            }
        },
        preExtEmpsInfo: function () {
            let obj = Object.assign({}, this.vlntrInfo )
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.vlntrWrkRecPk = this.bzpk
            obj.wrkTmHhStrt = obj.wrkStrt.split(':', 2)[0]
            obj.wrkTmMmStrt = obj.wrkStrt.split(':', 2)[1]
            obj.wrkTmHhEnd = obj.wrkEnd.split(':', 2)[0]
            obj.wrkTmMmEnd = obj.wrkEnd.split(':', 2)[1]
            obj.files = []

            if(obj.entDt != '')
                obj.wrkDt = obj.wrkDt?.afterDateFormatHyp()

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.files.push(items)
                }
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.files.push(items)
                }
            })

            let chk = this.$refs.vlntrWorkJrnl.validate()
            if(chk)
                this.setVlntrWrkRec(obj)
        },
        getAplyList: function (arr){
            arr.forEach(items => {
                this.vlntrInfo.cnfmNm = items.name
            });
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        onClose: function () {
            this.clearForm()
            this.$emit('modalClose', '3')
        },
        modalChanger: function() {
            this.menu.splice(3, 1, false)
        },
        clearForm: function () {
            this.vlntrInfo = Object.assign({
                empPk: 0, mmbrPk: 0, vlntrNm: '', cnfmNm: '', wrkDt: '', wrkStrt: '', wrkEnd: '', wrkCntnt: '',
            })

            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            this.forceRender += 1
        },
    },

    data: () => ({
        menu: [false, false, false, false,],
        files: null,
        fileList: [],
        imgList: [],
        exclsList: [],
        vlntrInfo: {
            empPk: 0, mmbrPk: 0, vlntrNm: '', cnfmNm: '', wrkDt: '', wrkStrt: '', wrkEnd: '', wrkCntnt: '',
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            pwdSizeCheck: value => (value && value.length > 3) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
        },
        forceRender: 0,
    }),
};
</script>