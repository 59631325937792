import { http } from '@/api/baseAxios';

//전체 목욕급여제공기록 조회
function selAllBathSchdlRecList(code,start,end) {
    return http.post('bnftoffer/selAllBathSchdlRecList', { fcltyNum: code, inqYmdStart:start, inqYmdEnd:end })
}

//목욕일정 기록 저장
function updBathSchdlRec(obj) {
    return http.post('bnftoffer/updBathSchdlRec',obj )
}

//목욕일정 기록 저장 (다건)
function updMultiBathSchdlRec(obj) {
    return http.post('bnftoffer/updMultiBathSchdlRec',obj )
}

//수급자별 급여제공계획내용 다건 조회
function selBnfcrBnftOfrPlnList(code,state) {
    return http.post('bnftoffer/selBnfcrBnftOfrPlnList', { 
        fcltyNum: code, 
        bnMmbrPk : state.bnMmbrPk,
        clsfc1Cd : state.clsfc1Cd,
        clsfc2CdS : state.clsfc2CdS,
        inqYMD:state.inqYMD,
    })
}


//수급자평가 조회
function selBnfcrExamSmryList(obj) {
    return http.post('bnftoffer/selBnfcrExamSmryList', obj)
}

//연계기록지 급여제공계획 불러오기
function selLnkRcrdBnftOfrInfo(code, pk, bnfcr) {
    return http.post('bnftoffer/selLnkRcrdBnftOfrInfo', { fcltyNum: code, bnMmbrPk: pk, bnfcrPk: bnfcr })
}

//수급자별 급여제공내역 한건 조회
function selBnfcrBnftPlnHisInfo(obj) {
    return http.post('bnftoffer/selBnfcrBnftPlnHisInfo', obj)
}

export {
    selAllBathSchdlRecList,
    updBathSchdlRec,
    updMultiBathSchdlRec,
    selBnfcrBnftOfrPlnList,
    selBnfcrExamSmryList,
    selLnkRcrdBnftOfrInfo,
    selBnfcrBnftPlnHisInfo,
}