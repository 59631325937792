<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>6-1. 회의기록</span>
                </span>
            </div>
            <v-form ref="form" lazy-validation class="mt-10">
                <div class="mt-2 mr-2 ml-2 rounded-lg">
                    <v-data-table class="msclnTable" height="635" locale="ko" :headers="headers" :items="items"
                        hide-default-header fixed-header disable-pagination hide-default-footer dense>
                        <template v-slot:header="{ props }">
                            <thead>
                                <tr>
                                    <th :class="props.headers[0].class" :style="props.headers[0].style" rowspan='2'>
                                        {{ props.headers[0].text }}</th>
                                    <th :class="props.headers[1].class" :style="props.headers[1].style" colspan='3'>
                                        {{ props.headers[1].text }}</th>
                                    <th :class="props.headers[2].class" :style="props.headers[2].style" rowspan='2'>
                                        {{ props.headers[2].text }}</th>
                                    <th :class="props.headers[6].class" :style="props.headers[6].style" rowspan='2'>
                                        {{ props.headers[6].text }}</th>
                                </tr>
                                <tr style="border-top: 1px solid #dbdbdb !important;">
                                    <th :class="props.headers[3].class" :style="props.headers[3].style">
                                        {{ props.headers[3].text }}</th>
                                    <th :class="props.headers[4].class" :style="props.headers[4].style">
                                        {{ props.headers[4].text }}</th>
                                    <th :class="props.headers[5].class" :style="props.headers[5].style">
                                        {{ props.headers[5].text }}</th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item='{ item }'>
                            <tr class="">
                                <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.inspCyclText }}</span>
                                </td>
                                <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne" style="color: red;" v-if="item.itm1 == null || item.itm1 == ''">미작성</span>
                                    <span class="cmmFontSizeOne" v-if="item.itm1 != null">{{ item.itm1 }}</span>
                                </td>
                                <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne" style="color: red;" v-if="item.itm2 == null || item.itm2 == ''">미작성</span>
                                    <span class="cmmFontSizeOne" v-if="item.itm2 != null">{{ item.itm2 }}</span>
                                </td>
                                <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne" style="color: red;" v-if="item.itm3 == null || item.itm3 == ''">미작성</span>
                                    <span class="cmmFontSizeOne" v-if="item.itm3 != null">{{ item.itm3 }}</span>
                                </td>
                                <td class="tableContentCommon" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne" style="color: red;" v-if="item.itm4 == null || item.itm4 == ''">미작성</span>
                                    <span class="cmmFontSizeOne" v-if="item.itm4 != null">{{ item.itm4 }}</span>
                                </td>
                                <td class="tableBB  tableContentCommon" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne" style="color: red;" v-if="item.itm5 == null || item.itm5 == ''">미작성</span>
                                    <span class="cmmFontSizeOne" v-if="item.itm5 != null">{{ item.itm5 }}</span>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </v-form>
        </v-sheet>
        <router-view ref="oprChild" @reload="reset"></router-view>
    </v-sheet>
</template>

<script>

import { selMtngRcrdSmryList } from '../../api/opr.js';

export default {
    name: 'OprCnfgr',

    components: {

    },

    created: function () {
        
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        selMtngRcrdSmryList: function (yyyy) {
            selMtngRcrdSmryList(this.$store.getters.getFcltyNum, yyyy)
                .then((response) => (this.selMtngRcrdSmryListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selMtngRcrdSmryList : ' + error))
        },
        selMtngRcrdSmryListAfter: function (res) {
            res.forEach(e => {
                if(e.itm1 == '' || e.itm1 == null)
                    e.itm1 = null
                else
                    e.itm1 = this.$moment(e.itm1, 'YYYYMMDD').format('YYYY.MM.DD')
                if(e.itm2 == '' || e.itm2 == null)
                    e.itm2 = null
                else
                    e.itm2 = this.$moment(e.itm2, 'YYYYMMDD').format('YYYY.MM.DD')
                if(e.itm3 == '' || e.itm3 == null)
                    e.itm3 = null
                else
                    e.itm3 = this.$moment(e.itm3, 'YYYYMMDD').format('YYYY.MM.DD')
                if(e.itm4 == '' || e.itm4 == null)
                    e.itm4 = null
                else
                    e.itm4 = this.$moment(e.itm4, 'YYYYMMDD').format('YYYY.MM.DD')
                if(e.itm5 == '' || e.itm5 == null)
                    e.itm5 = null
                else
                    e.itm5 = this.$moment(e.itm5, 'YYYYMMDD').format('YYYY.MM.DD')
            });
            
            this.items = res
        },
        reset: function (y) {
            this.selMtngRcrdSmryList(y)
        }
    },

    data: () => ({
        midAreaWidth: '551',
        headers: [
            { text: '회의시기', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important; width: 100px;' },
            { text: '운영위원회(서명필수)', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important; width: 265px;' },
            { text: '보호자', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important; width: 85px;' },
            { text: '직원처우개선', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important; width: 90px;' },
            { text: '노인학대예방', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important; width: 90px;' },
            { text: '기타', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important; width: 85px;' },
            { text: '수시', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important; width: 85px;' },
        ],
        MtngRcrdList: [],
        items: [],
        selectedItem: -1,
    }),
};
</script>