import { http } from '@/api/baseAxios';

//기관정보 조회
function selFcltyInfInfo(code) {
    return http.post('opr/selFcltyInfInfo', { fcltyNum: code })
}

//공단급여 수가정보 다건 조회
function selBnftFeeInfList(code) {
    return http.post('opr/selBnftFeeInfList', { fcltyNum: code })
}

//의사 다건 조회
function selClncFeeList(code) {
    return http.post('opr/selClncFeeList', { fcltyNum: code })
}

//비급여수가 조회
function selNbnftFeeInf(code) {
    return http.post('opr/selNbnftFeeInf', { fcltyNum: code })
}

//비급여수가 저장
function insNbnftFeeInfTyp(obj) {
    return http.post('opr/insNbnftFeeInfTyp', obj)
}

//비급여수가 삭제
function delNbnftFeeInf(code) {
    return http.post('opr/delNbnftFeeInf', { fcltyNum: code })
}

//비급여수가 변경내역
function selNbnftFeeHisList(code) {
    return http.post('opr/selNbnftFeeHisList', { fcltyNum: code })
}

//급여수가 변경내역
function selBnftFeeHisList(code,yyyy) {
    return http.post('opr/selBnftFeeHisList', {fcltyNum: code, inqYYYY: yyyy})
}

//생활실종류 조회
function selLvnRmTypList(code,upBedYn=null) {
    return http.post('opr/selLvnRmTypList', {fcltyNum: code, upBedYn: upBedYn})
}

//기관정보 저장
function insFcltyInfSeal(obj) {
    return http.post('opr/insFcltyInfSeal', obj)
}

//기관입금 관리정보 조회
function selFcltyDpsInfInfo(code) {
    return http.post('opr/selFcltyDpsInfInfo', {fcltyNum: code})
}
//기관입금 관리정보 저장
function insFcltyDpsInf(obj) {
    return http.post('opr/insFcltyDpsInf', obj)
}

//건물정보 조회
function selBldInf(code) {
    return http.post('opr/selBldInf', {fcltyNum: code})
}

//생활실정보 조회
function selLvnRmInf(code) {
    return http.post('opr/selLvnRmInf', {fcltyNum: code})
}

//건물 저장
function insBldInf(obj) {
    return http.post('opr/insBldInf', obj)
}

//생활실종류 저장
function insLvnRmTyp(obj) {
    return http.post('opr/insLvnRmTyp', obj)
}

//생활실종류 삭제
function delLvnRmTyp(obj) {
    return http.post('opr/delLvnRmTyp', obj)
}

//건물정보 삭제
function delBldInf(obj) {
    return http.post('opr/delBldInf', obj)
}
//생활실정보 저장
function insLvnRmInfMulti(obj) {
    return http.post('opr/insLvnRmInfMulti', obj)
}

//생활실정보 삭제
function delLvnRmInf(obj) {
    return http.post('opr/delLvnRmInf', obj)
}

//생활실현황 조회
function selLvnRmStsList(code) {
    return http.post('opr/selLvnRmStsList', {fcltyNum: code})
}

//회의기록 조회
function selMtngRcrdList(code, yyyy) {
    return http.post('opr/selMtngRcrdList', {fcltyNum: code, inqYYYY: yyyy})
}

//회의기록 조회
function selMtngRcrdSmryList(code, yyyy) {
    return http.post('opr/selMtngRcrdSmryList', {fcltyNum: code, inqYYYY: yyyy})
}

//회의기록 한건 조회
function selMtngRcrdInfo(code, state) {
    return http.post('opr/selMtngRcrdInfo', {fcltyNum: code, mtngRcrdPk: state})
}

//회의기록 삭제
function delMtngRcrd(obj) {
    return http.post('opr/delMtngRcrd', obj)
}

//회의기록 저장
function insMtngRcrd(obj) {
    return http.post('opr/insMtngRcrd', obj)
}

//회의참여자서명 조회
function selMtngAtndSigList(code, state) {
    return http.post('opr/selMtngAtndSigList', {fcltyNum: code, mtngRcrdPk: state})
}

//월간계획 조회
function selBzPlnMnthList(code, yyyy, yymm) {
    return http.post('opr/selBzPlnMnthList', { fcltyNum: code, inqYYYY: yyyy, inqYYMM: yymm })
}

//연간계획 조회
function selBzPlnYearList(code, yyyy) {
    return http.post('opr/selBzPlnYearList', { fcltyNum: code, inqYYYY: yyyy })
}

//업무계획상세 다건 조회
function selBzPlnList(code, yymm) {
    return http.post('opr/selBzPlnList', { fcltyNum: code, inqYYMM: yymm })
}

//업무 계획 상세 한건 조회
function selBzPlnInfo(code, pk) {
    return http.post('opr/selBzPlnInfo', { fcltyNum: code, bzPlnPk: pk })
}

//업무 계획 상세 저장
function insBzPln(obj) {
    return http.post('opr/insBzPln', obj)
}

//업무 계획 상세 삭제
function delBzPln(code, pk, nm ) {
    return http.post('opr/delBzPln', { fcltyNum: code, bzPlnPk: pk, userNm: nm })
}

//생활실 선택 팝업 조회
function selLvnRmInfList(code ) {
    return http.post('opr/selLvnRmInfList', { fcltyNum: code })
}
//생활실 선택 팝업 조회
function updFcltyCllTel(obj ) {
    return http.post('opr/updFcltyCllTel', obj)
}

//첨부화일 목록 조회
function selAtchdFileList(code, clcd, bzPk, fileClcd) {
    return http.post('opr/selAtchdFileList', { fcltyNum: code, bzClcd:clcd, bzPk:bzPk, fileClcd:fileClcd})
}

//첨부화일 목록 조회
function selMmbrList (code, pstnCd, mmbrPk) {
    return http.post('cmm/selMmbrList', { fcltyNum: code, pstnCd:pstnCd, mmbrPk:mmbrPk})
}

//비급여 목록 수가정보 조회
function selAckRtngBnftFeeList() {
    return http.post('opr/selAckRtngBnftFeeList', {})
}

//팝빌 연동신청하기
function uptPopBillFcltyInf(obj) {
    return http.post('opr/uptPopBillFcltyInf', obj)
}

//운영위원회 다건 조회
function selCmteList(code, ymd=null,) {
    return http.post('opr/selCmteList', { fcltyNum: code, inqYMD:ymd})
}

//운영위원회 저장
function insCmte(obj) {
    return http.post('opr/insCmte', obj)
}

//운영위원회 삭제
function delCmte(code, wrt, pk) {
    return http.post('opr/delCmte', {fcltyNum:code,userNm:wrt,cmtePk:pk})
}

//기본정보(식사) 저장
function insFcltyInit(obj) {
    return http.post('opr/insFcltyInit', obj)
}

//기본정보(식사) 조회
function selFcltyInitList(code, cd) {
    return http.post('opr/selFcltyInitList', { fcltyNum: code, initClcds: cd })
}
//기본정보(식사) 저장
function insFcltyInitMulti(obj) {
    return http.post('opr/insFcltyInitMulti', obj)
}
//기본정보(식사) 삭제
function delFcltyInit(obj) {
    return http.post('opr/delFcltyInit', obj)
}

//급여수가 변경내역 전자발송 대상자 목록 조회
function selOprFeeInfEsndInfo(obj) {
    return http.post('opr/selOprFeeInfEsndInfo', obj)
}

//시설정보관리 > 본인부담금 초기정보 조회
function selFcltyCopayInit(obj) {
    return http.post('opr/selFcltyCopayInit', obj)
}

//생활실 정보 조회(건물, 층 그룹)
function selLvnRmGrpList(obj) {
    return http.post('opr/selLvnRmGrpList', obj)
}

//상급침실비 수정
function updLvnRmTyp(obj) {
    return http.post('opr/updLvnRmTyp', obj)
}

export {
    selFcltyInfInfo,
    selBnftFeeInfList,
    selClncFeeList,
    selNbnftFeeInf,
    insNbnftFeeInfTyp,
    delNbnftFeeInf,
    selNbnftFeeHisList,
    selBnftFeeHisList,
    selLvnRmTypList,
    insFcltyInfSeal,
    selFcltyDpsInfInfo,
    insFcltyDpsInf,
    selBldInf,
    selLvnRmInf,
    insBldInf,
    insLvnRmTyp,
    delLvnRmTyp,
    delBldInf,
    insLvnRmInfMulti,
    delLvnRmInf,
    selLvnRmStsList,
    selMtngRcrdList,
    selMtngRcrdSmryList,
    delMtngRcrd,
    selMtngRcrdInfo,
    insMtngRcrd,
    selMtngAtndSigList,
    selBzPlnMnthList,
    selBzPlnYearList,
    selBzPlnList,
    selBzPlnInfo,
    insBzPln,
    delBzPln,
    selLvnRmInfList,
    updFcltyCllTel,
    selAtchdFileList,
    selMmbrList,
    selAckRtngBnftFeeList,
    uptPopBillFcltyInf,
    selCmteList,
    insCmte,
    delCmte,
    insFcltyInit,
    selFcltyInitList,
    insFcltyInitMulti,
    delFcltyInit,
    selOprFeeInfEsndInfo,
    selFcltyCopayInit,
    selLvnRmGrpList,
    updLvnRmTyp,
}