import { http } from '@/api/baseAxios';

//외출/외박 조회
function selBtcInqOutHisList(obj) {
    return http.post('bnfcr/selBtcInqOutHisList', obj)
}
//직원현황 조회
function selBtcInqEmpList(obj) {
    return http.post('emp/selBtcInqEmpList', obj)
}
//연차내역 조회
function selBtcInqEmpAnlList(obj) {
    return http.post('emp/selBtcInqEmpAnlList', obj)
}
//휴가사용내역 조회
function selBtcInqVctnUseList(obj) {
    return http.post('emp/selBtcInqVctnUseList', obj)
}
//본인부담금 수납내역 조회 (수납기준)
function selBatCopayClmDpsMgmt(obj) {
    return http.post('report/selBatCopayClmDpsMgmt', obj)
}
//본인부담금 수납내역 다건 조회 (수납기준)
function selBatCopayClmDpsList(obj) {
    return http.post('report/selBatCopayClmDpsList', obj)
}
//본인부담금 수납내역 다건 조회 (청구기준)
function selBatCopayClmDps1List(obj) {
    return http.post('report/selBatCopayClmDps1List', obj)
}
//건강기록 조회
function selHlthNrsRecHisList(code, value, ymd, yymm, yyyy, strtRn, rnCnt) {
    return http.post('bnftrcrd/selHlthNrsRecHisList'
    , { fcltyNum: code, bnMmbrPk: value, inqYMD: ymd, inqYYMM: yymm, inqYYYY: yyyy, rnStrt: strtRn, rnLmt: rnCnt})
}
//간호처치 기록 조회
function selBtcInqNrsRecHisList(code, value, ymd, yymm, yyyy) {
    return http.post('bnftrcrd/selBtcInqNrsRecHisList'
    , { fcltyNum: code, bnMmbrPk: value, inqYMD: ymd, inqYYMM: yymm, inqYYYY: yyyy})
}
//수급자참여
function selBtcInqPrgPrtcpList(obj) {
    return http.post('prgrm/selBtcInqPrgPrtcpList'
    , obj)
}
//시설운영일지 수급자현황 조회
function selFcltyJrnlBnfcrInfo(code, ymd, yymm, yyyy) {
    return http.post('bnfcr/selFcltyJrnlBnfcrInfo', { fcltyNum: code, inqYMD: ymd, inqYYMM: yymm, inqYYYY: yyyy})
}
//시설운영일지 수급자현황 업무일지
function selFcltyJrnlBnfcrBzList(code, ymd) {
    return http.post('bnfcr/selFcltyJrnlBnfcrBzList', { fcltyNum: code, inqYMD: ymd})
}
//시설운영일지 수급자 급여제공일지 조회
function selFcltyJrnlBnftList(code, ymd) {
    return http.post('bnftrcrd/selFcltyJrnlBnftList', { fcltyNum: code, inqYMD: ymd})
}
//시설운영일지 직원 업무일지
function selFcltyJrnlEmpBzList(code, ymd) {
    return http.post('emp/selFcltyJrnlEmpBzList', { fcltyNum: code, inqYMD: ymd})
}
//금일 식단표 조회
function selMenuList(code,  startdt, enddt) {
    return http.post('bnftoffer/selMenuList', { fcltyNum: code, inqYmdStart: startdt, inqYmdEnd:enddt})
}

export {
    //완성된 함수를 외부에 알림
    selBtcInqOutHisList,
    selBtcInqEmpList,
    selBtcInqEmpAnlList,
    selBtcInqVctnUseList,
    selBatCopayClmDpsMgmt,
    selBatCopayClmDpsList,
    selBatCopayClmDps1List,
    selHlthNrsRecHisList,
    selBtcInqNrsRecHisList,
    selBtcInqPrgPrtcpList,
    selFcltyJrnlBnfcrInfo,
    selFcltyJrnlBnfcrBzList,
    selFcltyJrnlBnftList,
    selFcltyJrnlEmpBzList,
    selMenuList,
    
}