<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">청구상세&nbsp;내역수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-row class="mt-5 mr-1 px-1" no-gutters>
            <v-col class="pa-0 ma-0" cols="12">
                <v-data-table class="cpDtlTbl" locale="ko" height="472"
                    :headers="headers" :items="copayClmDtlList" :loading="cpyClmLoader"
                    hide-default-header hide-default-footer fixed-header dense disable-pagination>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[0].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[1].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[2].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[3].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[4].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[5].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="1">{{props.headers[6].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="1">{{props.headers[7].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="1">{{props.headers[8].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[9].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="1">{{props.headers[10].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="1">{{props.headers[11].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" rowspan="2">{{props.headers[12].text}}</th>
                                <th class="black--text clmsDtlHeaderCommon tableBT" rowspan="2">&nbsp;</th>
                            </tr>
                            <tr>
                                <th class="clmsDtlHeaderCommon tableBR">
                                    <span class="d-inline-block">
                                        <v-checkbox
                                            class="clmHisCheck"
                                            v-model="onCheked[0]"
                                            true-value="1" false-value="2"
                                            @change="checkBoxMessage(0, 'brkfs'), allMealRflct()"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </th>
                                <th class="clmsDtlHeaderCommon tableBR">
                                    <span class="d-inline-block">
                                        <v-checkbox
                                            class="clmHisCheck"
                                            v-model="onCheked[1]"
                                            true-value="1" false-value="2"
                                            @change="checkBoxMessage(1, 'lunch'), allMealRflct()"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </th>
                                <th class="clmsDtlHeaderCommon tableBR">
                                    <span class="d-inline-block">
                                        <v-checkbox 
                                            class="clmHisCheck"
                                            v-model="onCheked[2]"
                                            true-value="1" false-value="2"
                                            @change="checkBoxMessage(2, 'diner'), allMealRflct()"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </th>
                                <th class="clmsDtlHeaderCommon tableBR">
                                    <span class="d-inline-block">
                                        <v-checkbox
                                            class="clmHisCheck"
                                            v-model="onCheked[3]"
                                            true-value="1" false-value="2"
                                            @change="checkBoxMessage(3, 'snckAm'), allSnackRflct()"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </th>
                                <th class="clmsDtlHeaderCommon tableBR">
                                    <span class="d-inline-block">
                                        <v-checkbox
                                            class="clmHisCheck"
                                            v-model="onCheked[4]"
                                            true-value="1" false-value="2"
                                            @change="checkBoxMessage(4, 'snckPm'), allSnackRflct()"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr style="padding: 0 4px !important;">
                            <td class="clmsBodyCommon tableBR">{{item.ofrDt}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.clmEntStcd}}</td>
                            <td class="clmsBodyCommon tableBR">{{ item.copayRateNm }}&#40;{{ item.copayRateDesc }}&#41;</td>
                            <td class="clmsBodyCommon tableBR py-1 px-1">
                                <v-text-field v-model="item.bnftFee" color="blue" type="number"
                                    :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </td>
                            <td class="clmsBodyCommon tableBR">{{item.mealCost?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.lqdFood?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <div class="d-flex justify-center">
                                    <v-checkbox class="clmHisCheck" v-model="item.brkfsYn" true-value="1" false-value="2"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        @change="mealCostChanger(index), checkBoxIndex(0, 'brkfs')" :rules="[rules.required]" hide-details></v-checkbox>
                                </div>
                            </td>
                            <td class="clmsBodyCommon tableBR">
                                <div class="d-flex justify-center">
                                    <v-checkbox class="clmHisCheck" v-model="item.lunchYn" true-value="1" false-value="2"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        @change="mealCostChanger(index), checkBoxIndex(1, 'lunch')" :rules="[rules.required]" hide-details></v-checkbox>
                                </div>
                            </td>
                            <td class="clmsBodyCommon tableBR">
                                <div class="d-flex justify-center">
                                    <v-checkbox class="clmHisCheck" v-model="item.dinerYn" true-value="1" false-value="2"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        @change="mealCostChanger(index), checkBoxIndex(2, 'diner')" :rules="[rules.required]" hide-details></v-checkbox>
                                </div>
                            </td>
                            <td class="clmsBodyCommon tableBR">{{item.snackFee?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <div class="d-flex justify-center">
                                    <v-checkbox
                                        class="clmHisCheck"
                                        true-value="1" false-value="2"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        @change="snackFeeChanger(index), checkBoxIndex(3, 'snckAm')"
                                        v-model="item.snckAmYn" :rules="[rules.required]" hide-details>
                                    </v-checkbox>
                                </div>
                            </td>
                            <td class="clmsBodyCommon tableBR">
                                <div class="d-flex justify-center">
                                    <v-checkbox
                                        class="clmHisCheck"
                                        true-value="1" false-value="2"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        @change="snackFeeChanger(index), checkBoxIndex(4, 'snckPm')"
                                        v-model="item.snckPmYn" :rules="[rules.required]"
                                        hide-details>
                                    </v-checkbox>
                                </div>
                            </td>
                            <td class="clmsBodyCommon tableBR py-1 px-1">
                                <v-text-field
                                    v-model="item.upBedFee" :rules="[rules.required]"
                                    color="blue" type="number"
                                    hide-details outlined dense>
                                </v-text-field>
                            </td>
                            <td class="clmsBodyCommon">
                                <v-btn class="" color="blueBtnColor"
                                    height="26"
                                    @click="preCopayClm(index)"
                                    :disabled="$parent.$parent.$parent.$parent.copayClmDtlInfo.cnfrmYn == '1' ? true : false"
                                    icon>
                                    <v-icon>mdi-content-save-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:[`foot`]="{ props }">
                        <tfoot>
                            <tr>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="3">당월&nbsp;합계</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="1">{{copayClmDtlListSum.bnftFee?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="1">{{copayClmDtlListSum.mealCost?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="1">{{copayClmDtlListSum.lqdFood?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="3">{{props}}</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="1">{{copayClmDtlListSum.snackFee?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="2">&nbsp;</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="1">{{copayClmDtlListSum.upBedFee?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsDtlHeaderCommon tableBR black--text" colspan="1">&nbsp;</td>
                            </tr>
                        </tfoot>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selCopayClmList, updCopayClm } from '../../api/bnfcr.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'ClmsDtlMdf',

    props : {
        yyMm: { type: String, default: '' },
        cpClmHisPk: { type: Number, default: -1 },
        bnmmbrPk: { type: Number, default: -1 },
        bnfcrPk: { type: Number, default: -1 },
    },

    created: function(){
        this.getCode(['63', '83'])
        this.$parent.$parent.$parent.$parent.headers = this.headers
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.bnmmbrPk != -1 && this.bnfcrPk != -1){
                this.getCopayClmList(this.yyMm, this.bnmmbrPk, this.bnfcrPk)
            }
        })
    },

    computed: {

    },

    watch: {
        'bnmmbrPk': function () {
            if(this.bnmmbrPk != -1 && this.bnfcrPk != -1)
                this.getCopayClmList(this.yyMm, this.bnmmbrPk, this.bnfcrPk)
        },
        'yyMm': function () {
            if(this.bnmmbrPk != -1 && this.bnfcrPk != -1)
                this.getCopayClmList(this.yyMm, this.bnmmbrPk, this.bnfcrPk)
        },
    },

    methods:{
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getCopayClmList: function (yyyyMm, bnmmPk, bnfcr) {
            this.copayClmDtlList.splice(0)
            this.orgCpyList.splice(0)
            this.cpyClmLoader = true

            selCopayClmList(this.$store.getters.getFcltyNum, yyyyMm, bnmmPk, bnfcr)
                .then((response) => ( this.getCopayClmListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmList : ' + error))
        },
        setCopayClm: function (obj) {
            updCopayClm(obj)
                .then((response) => ( this.setCopayClmAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updCopayClm : ' + error))
        },
        stCdMapping: function (res) {
            //입소현황
            this.vCd83 = res.vCd83
            this.vCd63 = res.vCd63
            this.$parent.$parent.$parent.$parent.vCd83 = res.vCd83
        },
        getCopayClmListAfter: function (res) {
            this.copayClmDtlList = res
            this.cpyClmLoader = false

            this.copayClmDtlListSum.nhisPay = 0
            this.copayClmDtlListSum.copay = 0
            this.copayClmDtlListSum.bnftFee = 0
            this.copayClmDtlListSum.mealCost = 0
            this.copayClmDtlListSum.lqdFood = 0
            this.copayClmDtlListSum.snackFee = 0
            this.copayClmDtlListSum.upBedFee = 0

            this.copayClmDtlList.forEach(items => {
                items.ofrDt = this.$moment(items.ofrDt, 'YYYYMMDD').format('YYYY.MM.DD')

                this.orgCpyList.push(Object.assign({}, items))

                this.copayClmDtlListSum.nhisPay += items.nhisPay
                this.copayClmDtlListSum.copay += items.copay
                this.copayClmDtlListSum.bnftFee += items.bnftFee
                this.copayClmDtlListSum.mealCost += items.mealCost
                this.copayClmDtlListSum.lqdFood += items.lqdFood
                this.copayClmDtlListSum.snackFee += items.snackFee
                this.copayClmDtlListSum.upBedFee += items.upBedFee

                if(items.mealCostUnit > 0 && items.lqdFoodUnit == 0) items.mode = 'meal'
                else if( items.mealCostUnit == 0 && items.lqdFoodUnit > 0) items.mode = 'lqd'
           
                // if(items.mode == 'lqd'){
                //     items.snackFee = 0
                //     items.snckAmYn = '2'
                //     items.snckPmYn = '2'
                // }

                this.vCd83.forEach(code => {
                    if(items.clmEntStcd == code.valcd) items.clmEntStcd = code.valcdnm
                });

                // this.vCd63.forEach(code => {
                //     if(items.copayRate == code.valcd) items.copayRate = code.valdesc + '%'
                // });

                this.vCd63.forEach(code => {
                    if(items.copayRate == code.valcd){
                        items.copayRateNm = code.valcdnm?.slice(0, 3)?.replaceAll('(', '')
                        items.copayRateDesc = code.valdesc + '%'
                    }
                });

            });

            let brkfsAll = this.copayClmDtlList.every( v => v.brkfsYn == '1' )
            if(brkfsAll == true)
                this.onCheked.splice(0, 1, '1')

            let lunchAll = this.copayClmDtlList.every( v => v.lunchYn == '1' )
            if(lunchAll == true)
                this.onCheked.splice(1, 1, '1')

            let dinerAll = this.copayClmDtlList.every( v => v.dinerYn == '1' )
            if(dinerAll == true)
                this.onCheked.splice(2, 1, '1')

            let snckAmAll = this.copayClmDtlList.every( v => v.snckAmYn == '1' )
            if(snckAmAll == true)
                this.onCheked.splice(3, 1, '1')

            let snckPmAll = this.copayClmDtlList.every( v => v.snckPmYn == '1' )
            if(snckPmAll == true)
                this.onCheked.splice(4, 1, '1')

            this.parentRflct()
        },
        setCopayClmAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getCopayClmList(this.yyMm, this.bnmmbrPk, this.bnfcrPk)
                this.$parent.$parent.$parent.$parent.getCopayClmHisInfo(
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date,
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnmmbr,
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnfcrPk
                )
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.getCopayClmHisList(
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date
                )
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
                
        },
        preCopayClm: function (index) {
            let obj = Object.assign({}, this.copayClmDtlList[index])

            this.vCd63.forEach(code => {
                if(obj.entStcd == code.valcdnm) obj.entStcd = code.valcd
            });

            obj.prcClcd = '4'
            obj.prcCdAr = []
            obj.copayRate = obj.copayRate.replaceAll('%', '')
            obj.ofrDt = this.$moment(obj.ofrDt, 'YYYY.MM.DD').format('YYYYMMDD')
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.inqYYMM = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.date
            obj.bnMmbrPks = []
            obj.bnfcrPks = []
            obj.bnMmbrPks.push(this.bnmmbrPk)
            obj.bnfcrPks.push(this.bnfcrPk)

            if(obj.bnftFee != this.orgCpyList[index].bnftFee)
                obj.prcCdAr.push('1')
            if(obj.mealCost != this.orgCpyList[index].mealCost)
                obj.prcCdAr.push('4')
            if(obj.lqdFood != this.orgCpyList[index].lqdFood)
                obj.prcCdAr.push('7')
            if(obj.snackFee != this.orgCpyList[index].snackFee)
                obj.prcCdAr.push('8')
            if(obj.upBedFee != this.orgCpyList[index].upBedFee)
                obj.prcCdAr.push('5')

            //console.log(obj)
            this.setCopayClm(obj)
        },
        parentRflct: function () {
            this.$parent.$parent.$parent.$parent.copayClmDtlListSum = this.copayClmDtlListSum
            this.$parent.$parent.$parent.$parent.copayClmDtlList = this.copayClmDtlList
            this.$parent.$parent.$parent.$parent.cpyClmLoader = this.cpyClmLoader
        },
        mealCostChanger: function (i) {
            let chk = 0
            let me = this

            if(this.copayClmDtlList[i].mode == 'meal'){
                if(this.copayClmDtlList[i].brkfsYn == '1')
                    chk += Number(this.copayClmDtlList[i].mealCostUnit)
                if(this.copayClmDtlList[i].lunchYn == '1')
                    chk += Number(this.copayClmDtlList[i].mealCostUnit)
                if(this.copayClmDtlList[i].dinerYn == '1')
                    chk += Number(this.copayClmDtlList[i].mealCostUnit)

                this.copayClmDtlList[i].mealCost = chk
            }
            else if(this.copayClmDtlList[i].mode == 'lqd'){
                if(this.copayClmDtlList[i].brkfsYn == '1')
                    chk += Number(this.copayClmDtlList[i].lqdFoodUnit)
                if(this.copayClmDtlList[i].lunchYn == '1')
                    chk += Number(this.copayClmDtlList[i].lqdFoodUnit)
                if(this.copayClmDtlList[i].dinerYn == '1')
                    chk += Number(this.copayClmDtlList[i].lqdFoodUnit)
                
                this.copayClmDtlList[i].lqdFood = chk
            }

            this.copayClmDtlListSum.mealCost = 0
            this.copayClmDtlListSum.lqdFood = 0
            this.copayClmDtlList.forEach(items => {
                me.copayClmDtlListSum.mealCost += items.mealCost
                me.copayClmDtlListSum.lqdFood += items.lqdFood
            });
        },
        snackFeeChanger: function (i) {
            let chk = 0
            let me = this
            if(this.copayClmDtlList[i].snckAmYn == '1')
                chk += Number(this.copayClmDtlList[i].snackFeeUnit)
            if(this.copayClmDtlList[i].snckPmYn == '1')
                chk += Number(this.copayClmDtlList[i].snackFeeUnit)

            this.copayClmDtlList[i].snackFee = chk

            this.copayClmDtlListSum.snackFee = 0
            this.copayClmDtlList.forEach(items => {
                me.copayClmDtlListSum.snackFee += items.snackFee
            });
        },
        checkBoxMessage: function (index, mode) {
            switch(mode) {
                case 'brkfs':
                    if(this.onCheked[index] == '1')
                        this.copayClmDtlList.forEach( item => { item.brkfsYn = '1' } )
                    else
                        this.copayClmDtlList.forEach( item => { item.brkfsYn = '2' } )
                    break;
                case 'lunch':
                    if(this.onCheked[index] == '1')
                        this.copayClmDtlList.forEach( item => { item.lunchYn = '1' } )
                    else
                        this.copayClmDtlList.forEach( item => { item.lunchYn = '2' } )
                    break;
                case 'diner':
                    if(this.onCheked[index] == '1')
                        this.copayClmDtlList.forEach( item => { item.dinerYn = '1' } )
                    else
                        this.copayClmDtlList.forEach( item => { item.dinerYn = '2' } )
                    break;
                case 'snckAm':
                    if(this.onCheked[index] == '1')
                        this.copayClmDtlList.forEach( item => { item.snckAmYn = '1' } )
                    else
                        this.copayClmDtlList.forEach( item => { item.snckAmYn = '2' } )
                    break;
                case 'snckPm':
                    if(this.onCheked[index] == '1')
                        this.copayClmDtlList.forEach( item => { item.snckPmYn = '1' } )
                    else
                        this.copayClmDtlList.forEach( item => { item.snckPmYn = '2' } )
                    break;
                default:
                    break;
            }
        },
        checkBoxIndex: function (index, mode) {
            let chk = []
            switch(mode) {
                case 'brkfs':
                    this.copayClmDtlList.forEach(items => {
                        chk.push(items.brkfsYn)
                    });
                    break;
                case 'lunch':
                    this.copayClmDtlList.forEach(items => {
                        chk.push(items.lunchYn)
                    });
                    break;
                case 'diner':
                    this.copayClmDtlList.forEach(items => {
                        chk.push(items.dinerYn)
                    });
                    break;
                case 'snckAm':
                    this.copayClmDtlList.forEach(items => {
                        chk.push(items.snckAmYn)
                    });
                    break;
                case 'snckPm':
                    this.copayClmDtlList.forEach(items => {
                        chk.push(items.snckPmYn)
                    });
                    break;
                default:
                    break;
            }
            
            let result = chk.every((item) => item == '1')
            if(result == '1') this.onCheked.splice(index, 1, '1')
            else this.onCheked.splice(index, 1, '2')
        },
        allMealRflct: function () {
            if(this.copayClmDtlList.length > 0){
                this.copayClmDtlList.forEach(list => {
                    let chc = 0
                    if(list.brkfsYn == '1')
                        chc += 1
                    if(list.lunchYn == '1')
                        chc += 1
                    if(list.dinerYn == '1')
                        chc += 1
                    
                    if(list.mode == 'meal')
                        list.mealCost = chc * list.mealCostUnit
                    else if(list.mode == 'lqd')
                        list.lqdFood = chc * list.lqdFoodUnit
                });

                this.copayClmDtlListSum.mealCost = 0
                this.copayClmDtlListSum.lqdFood = 0
                this.copayClmDtlList.forEach(items => {
                    this.copayClmDtlListSum.mealCost += items.mealCost
                    this.copayClmDtlListSum.lqdFood += items.lqdFood
                });
            }
        },
        allSnackRflct: function () {
            if(this.copayClmDtlList.length > 0){
                this.copayClmDtlList.forEach(list => {
                    let chc = 0
                    if(list.snckAmYn == '1')
                        chc += 1
                    if(list.snckPmYn == '1')
                        chc += 1

                    list.snackFee = chc * list.snackFeeUnit
                });

                this.copayClmDtlListSum.snackFee = 0
                this.copayClmDtlList.forEach(items => {
                    this.copayClmDtlListSum.snackFee += items.snackFee
                });
            }
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        cpyClmLoader: true,
        copayClmDtlList: [],
        onCheked: ['2', '2', '2', '2', '2',],
        vCd63: [],
        vCd83: [],
        orgCpyList: [],
        copayClmDtlListSum: { nhisPay: 0, copay: 0, bnftFee: 0, mealCost: 0, lqdFood: 0, snackFee: 0, upBedFee: 0 },
        headers: [
            { text: '제공일', value: 'ofrDt', align: 'center'},
            { text: '현황', value: 'clmEntStcd', align: 'center'},
            { text: '본인부담률', value: 'copayRate', align: 'center'},
            { text: '급여비용', value: 'bnftFee', align: 'center'},
            { text: '식사재료비①', value: 'mealCost', align: 'center'},
            { text: '경관유동식④', value: 'lqdFood', align: 'center'},
            { text: '아침', value: 'brkfsYn', align: 'center'},
            { text: '점심', value: 'lunchYn', align: 'center'},
            { text: '저녁', value: 'dinerYn', align: 'center'},
            { text: '간식비⑤', value: 'snackFee', align: 'center'},
            { text: '오전', value: 'snckAmYn', align: 'center'},
            { text: '오후', value: 'snckPmYn', align: 'center'},
            { text: '상급침실비②', value: 'upBedFee', align: 'center'},
        ],
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    })
}
</script>

<style>
    .clmHisCheck .v-input__control { width: 22px !important; }
</style>