<template>
    <v-sheet class="overflow-y-auto overflow-x-hidden" min-width="960" max-width="960">
        <div style="position: relative;">
            <v-row class="ma-0" no-gutters>
                <v-col cols="12">
                    <v-chip-group v-model="tab" class="my-1" mandatory>
                        <v-chip class="px-9 fontOneRem" active-class="black100 white--text font-weight-bold" @click="isSubTab(0)">계약정보&nbsp;&#183;&nbsp;비급여수가</v-chip>
                        <v-chip class="px-9 fontOneRem" active-class="black100 white--text font-weight-bold" @click="isSubTab(1)">계약서&nbsp;확인사항&nbsp;&#183;&nbsp;전자서명</v-chip>
                    </v-chip-group>
                    <pay
                        v-show="tab == 0" ref="cntInf"
                        :prtBnfcrCntrc = "bnfcrCntrc" :prtLvnRmList="lvnRmTypList"
                        @objectTrigger="objectChanger" @cntrcTrigger="bnfcrCntrcChanger()"
                        :key="forceRender">
                    </pay>
                    <Acptn
                        v-show="tab == 1" ref="cntInfItm"
                        :prtCntrcCnfm="cntrcCnfm" :prtBnfcrCntrc = "bnfcrCntrc" :garMmbrPk="bnfcrCntrc.garMmbrPk"
                        @reload="getCntrcfmList">
                    </Acptn>  
                </v-col>
            </v-row>
            <div 
                v-if="loader"
                class="d-inline-block d-flex justify-center align-center"
                style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 4;
                    opacity: 0.7; background-color: #666;">
                <div>
                    <div class="mb-2" style="text-align: center; ">
                        <v-icon class="white--text" size="120">mdi-hand-back-right-outline</v-icon>
                    </div>
                    <div>
                        <span class="white--text font-weight-bold" style="font-size: 2.4rem;">잠시만 기다려주세요...</span>
                    </div>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import { bnfcrCntrcDetail, bnfcrCntrcCnfmDetail, getBnftFeeInfo } from '../../api/bnfcr.js';

import pay from '../../components/bnfcr/BnfcrMgmtCntInfPymnt.vue';
import Acptn from '../../components/bnfcr/BnfcrInFrmMgmtCntrcAcptn.vue';

export default {
    name: 'BnfcrInFrmMgmtCntInf',
        
    components: {
        pay,
        Acptn,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            //this.$store.getters.getEntStcd
            this.bnfcrCntrc = Object.assign({}, this.dummyBnfcrCntrc)
            this.cntrcCnfm = Object.assign([], this.dummyCntrcCnfm)
        })
    },

    computed: {
        getBnMmbrPk(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getAcknwRtng(){
            return this.$store.getters.getAcknwRtng
        },
        getCopayRate(){
            return this.$store.getters.getCopayRate
        },
        getGvrnmSprt(){
            return this.$store.getters.getGvrnmSprt
        },
        getLvnRmList(){
            return this.$parent.$parent.$parent.$parent.$parent.lvnRmTypList
        },
    },

    watch:{
        'bnfcrCntrc.bnfcrCntrcInfPk': function(){
            if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0)
                this.$parent.$parent.$parent.outlinedBtn[1].disabled = true
            else
                this.$parent.$parent.$parent.outlinedBtn[1].disabled = false

            this.getCntrcfmList()
        },
        getBnMmbrPk: function(v){
            if(v == 0){
                this.$parent.$parent.$parent.tab = 0
                this.$parent.$parent.$parent.clearForm()
            }  
         
            if(this.$refs.cntInf.bnfcrCntrc.bnfcrCntrcInfPk == 0){
                let chc = this.$parent.$parent.$parent.$parent.$parent.items.findIndex(
                    v => v.bnfcrPk == this.$store.getters.getBnfcrState.bnfcr
                )
                if(chc > -1){
                    let tmpDt = this.$parent.$parent.$parent.$parent.$parent.items[chc].entDt
                    this.$refs.cntInf.bnfcrCntrc.cntrcDt = this.$moment(tmpDt, 'YYYY.MM.DD').format('YYYY-MM-DD')
                    this.$refs.cntInf.cntrcMinDt = this.$moment(tmpDt, 'YYYY.MM.DD').format('YYYY-MM-DD')
                }
            }
        },
        getAcknwRtng: function(v){
            if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0){
                this.bnfcrCntrc.acknwRtng = v
                this.getBnftFeeInfo(v)
            }
        },
        getGvrnmSprt: function(v){
            if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0){
                if( v.findIndex( v => v == '1' ) != -1 )
                    this.bnfcrCntrc.gvrnmSnack = '1'
                else
                    this.bnfcrCntrc.gvrnmSnack = '2'

                if( v.findIndex( v => v == '2' ) != -1 )
                    this.bnfcrCntrc.gvrnmMeal = '1'
                else
                    this.bnfcrCntrc.gvrnmMeal = '2'
            }
        },
        getCopayRate: function(v){
            if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0){
                this.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                    if(v == cd.valcd)
                        this.bnfcrCntrc.copayRate = Number(cd.valdesc)
                });
            }
        },
        getLvnRmList(value){
            this.lvnRmTypList = value
        },
    },
 
    methods: {
        getCntrcList: function (value) {
            this.loader = true

            bnfcrCntrcDetail(this.$store.getters.getFcltyNum, value)
                .then((response) => ( this.getCntrcMmbrPk(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcInf : ' + error))
        },

        getCntrcListNew: function (value) {
            this.loader = true
            
            bnfcrCntrcDetail(this.$store.getters.getFcltyNum, value)
                .then((response) => ( this.getCntrcMmbrPkNew(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcInf : ' + error))
        },

        getCntrcfmList: function () {
            bnfcrCntrcCnfmDetail(this.$store.getters.getFcltyNum, this.bnfcrCntrc.bnfcrCntrcInfPk, '1')
                .then((response) => ( this.cntrcCnfm = response.data ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcCnfmList : ' + error))
        },

        getBnftFeeInfo: function (value) {
            getBnftFeeInfo(1, 1, value)
                .then((response) => ( this.bnftFeeInfo = response.data ))
                .catch((error) => console.log('connect error /opr/selBnftFeeInfo : ' + error))
        },

        getCntrcMmbrPk: function (res) {
            this.bnfcrCntrc = Object.assign({}, this.dummyBnfcrCntrc)

            this.$store.commit('setCntrcInfPk', res.bnfcrCntrcInfPk)

            if(res.bnfcrCntrcInfPk == 0){
                this.bnfcrCntrc.bnfcrCntrcInfPk = 0
                this.bnfcrCntrc.garMmbrPk = res.garMmbrPk
                this.bnfcrCntrc.acknwRtng = this.$store.getters.getAcknwRtng
                this.bnfcrCntrc.pymntDt = this.$parent.$parent.$parent.$parent.$parent.fcltyInf.dpsDt
                this.$store.state.bnfcrChgClmn.orgCntrcPrdEnd = ''

                let chc = this.$parent.$parent.$parent.$parent.$parent.items.findIndex(
                    v => v.bnfcrPk == this.$store.getters.getBnfcrState.bnfcr
                )
                if(chc > -1){
                    let tmpDt = this.$parent.$parent.$parent.$parent.$parent.items[chc].entDt
                    this.bnfcrCntrc.cntrcDt = this.$moment(tmpDt, 'YYYY.MM.DD').format('YYYY-MM-DD')
                }

                if(this.$parent.$parent.$parent.$parent.$parent.feeInf.snackOfrClcd == '1')
                    this.bnfcrCntrc.snackCntCd = '1'
                else
                    this.bnfcrCntrc.snackCntCd = this.$parent.$parent.$parent.$parent.$parent.feeInf.snackCntCd

                this.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                    if(this.$store.getters.getCopayRate == cd.valcd)
                        this.bnfcrCntrc.copayRate = Number(cd.valdesc)
                });
                
                if( this.$store.getters.getGvrnmSprt.findIndex( v => v == '1' ) != -1 )
                    this.bnfcrCntrc.gvrnmSnack = '1'
                if( this.$store.getters.getGvrnmSprt.findIndex( v => v == '2' ) != -1 )
                    this.bnfcrCntrc.gvrnmMeal = '1'
            }
            else{
                res.cntrcDt = res.cntrcDt?.beforeDateFormatHyp()
                res.cntrcPrdStrt = res.cntrcPrdStrt?.beforeDateFormatHyp()
                res.cntrcPrdEnd = res.cntrcPrdEnd?.beforeDateFormatHyp()
    
                this.$store.state.bnfcrChgClmn.orgCntrcPrdEnd = res.cntrcPrdEnd
                
                this.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                    if(res.copayRate == cd.valcd){
                        res.copayRate = Number(cd.valdesc)
                        res.copayCd = cd.valcd
                    }
                });
                
                if(res.gvrnmSprt == null) res.gvrnmSprt = []

                if( res.gvrnmSprt.findIndex( v => v == '1' ) != -1 )
                    res.gvrnmSnack = '1'
                else
                    res.gvrnmSnack = '2'
                if( res.gvrnmSprt.findIndex( v => v == '2' ) != -1 )
                    res.gvrnmMeal = '1'
                else
                    res.gvrnmMeal = '2'

                if(res.rtngOtrAmt == null)
                    res.rtngOtrAmt = 0
                if(res.rtngOtrClcd == null)
                    res.rtngOtrClcd = '1'

                this.bnfcrCntrc = res
            }
        
            this.loader = false
        },

        getCntrcMmbrPkNew: function (res) {
            let obj = Object.assign({}, this.$refs.cntInf.indvCntrc)
            obj.npymntFeeStd = this.$refs.cntInf.bnfcrCntrc.npymntFeeStd
            obj.cntrcPrdEnd = this.$refs.cntInf.bnfcrCntrc.cntrcPrdEnd
            obj.mealCalcCd = this.$refs.cntInf.bnfcrCntrc.mealCalcCd
            obj.pymntDt = this.$parent.$parent.$parent.$parent.$parent.fcltyInf.dpsDt
            obj.upBedFee = this.$refs.cntInf.bnfcrCntrc.upBedFee
            obj.indvUpBedFee = this.$refs.cntInf.indvUpBedFee

            obj.chc = '1'

            this.$store.state.bnfcrChgClmn.orgCntrcPrdEnd = ''

            this.tmpIndvCntrc = Object.assign({}, obj)
            this.bnfcrCntrc = Object.assign({}, this.dummyBnfcrCntrc)

            this.$store.commit('setCntrcInfPk', res.bnfcrCntrcInfPk)

            this.bnfcrCntrc.bnfcrCntrcInfPk = 0
            this.bnfcrCntrc.garMmbrPk = res.garMmbrPk
            this.bnfcrCntrc.acknwRtng = this.$store.getters.getAcknwRtng

            if(this.$parent.$parent.$parent.$parent.$parent.feeInf.snackOfrClcd == '1')
                this.bnfcrCntrc.snackCntCd = '1'
            else
                this.bnfcrCntrc.snackCntCd = this.$parent.$parent.$parent.$parent.$parent.feeInf.snackCntCd

            this.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                if(this.$store.getters.getCopayRate == cd.valcd)
                    this.bnfcrCntrc.copayRate = Number(cd.valdesc)
            });
        
            if( this.$store.getters.getGvrnmSprt.findIndex( v => v == '1' ) != -1 )
                this.bnfcrCntrc.gvrnmSnack = '1'
            if( this.$store.getters.getGvrnmSprt.findIndex( v => v == '2' ) != -1 )
                this.bnfcrCntrc.gvrnmMeal = '1'

            if(obj.npymntFeeStd == '2')
                this.bnfcrCntrc.npymntFeeStd = '2'
            
            this.loader = false
        },

        isSubTab: function (value) {
            this.tab = value
            this.$emit('subTab', this.tab)
        },

        objectChanger: function (data) {
            this.bnfcrCntrc = data
        },

        bnfcrCntrcChanger: function () {
            this.getCntrcList(this.$store.getters.getBnfcrState)
        },
        hisBnfcrCntrcChanger: function (obj) {
            this.$refs.cntInf.hisCntInfPaymntChanger(obj)
        },

        intrcData: function () {
            this.$refs.cntInf.preSave()
        },
        intrcItmData: function () {
            this.$refs.cntInfItm.setCntrcCnfmInf()
        },
        hisCntInfPaymntChanger: function (tmp) {
            
            tmp.cntrcDt = tmp.cntrcDt?.beforeDateFormatHyp()
            tmp.cntrcPrdStrt = tmp.cntrcPrdStrt?.beforeDateFormatHyp()
            tmp.cntrcPrdEnd = tmp.cntrcPrdEnd?.beforeDateFormatHyp()

            this.$store.commit('setCntrcInfPk', tmp.bnfcrCntrcInfPk)
            this.bnfcrCntrc = tmp
        },
    },

    data: () => ({
        tab: 0,
        loader: true,
        bnfcrCntrc: { bnfcrCntrcInfPk: 0, },
        cntrcCnfm: [],
        tmpIndvCntrc: {},
        bnftFeeInfo: {},
        dummyBnfcrCntrc: {
            bnMmbrPk: 0, snackCntCd: '', sftNtcOfrDt: '', bnfcrCntrcInfPk: -1, btyFee: 0, snackOfrClcd: '', eldHmnRcvNm: '',
            pymntDt: '', eldHmnOfrDt: '', eldHmnRcvPstnCd: '', cntrcPrdEnd: '', prvcyAgrYn: '', sftNtcRcvNm: '', cntrcPrdStrt: '',
            sftNtcOfrYn: '', cntrcDt: '', prvcyAgrDt: '', sftNtcRcvPstnCd: '', lstYn: '', snackFee: 0, mealCostLmtYn: '', lqdFood: 0,
            mfdRsn: '', mealCalcCd: '1', eldHmnOfrYn: '', lqdFoodClcd: '', pymntMthdCd: '', npymntFeeStd: '1', mealCost: 0,
            upBedFee: 0, mealCostLmt: 0, garMmbrPk: 0, acknwRtng: '', copayRate: 0, gvrnmSnack: '2', gvrnmMeal: '2',
            rtngOtrAmt: 0, rtngOtrClcd: '1',
        },
        dummyCntrcCnfm: [
            { cntrcCnfmItmPk: '', cnfmCntnt: '', cnfmYn: '', bnfcrCntrcCnfmPk: 0, },
        ],
        lvnRmTypList: [],
        forceRender: 0,
    }),
};
</script>