<template>
    <v-sheet>
        <div class="d-flex align-center">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">기타자료</span>
            <v-spacer></v-spacer>            
        </div>
        <v-form ref="form" lazy-validation>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="white d-inline-block pa-1" style="width: 154px;">
                        <CmmDateComp v-model="items.wrtDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="d-inline-block" style="width: 100px;">
                            <v-text-field v-model="items.wrtr" color="blue" ref="info" readonly
                                :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="26"  
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(0)"
                            >
                            <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                        </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">내용</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="7" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>                
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">자료첨부</span>
                    <span class="d-inline-block ml-1" style="padding-top: 2px;">
                        <v-file-input
                            class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                            :disabled="this.$parent.$parent.$parent.$parent.bnMmbrPk == -1 ? true : false"
                            prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="pa-2" cols="10">
                    <div class="overflow-y-auto" style="height:200px;">
                        <span class="pb-2 pr-2"
                            v-for="(item, j) in fileList" :key="j">
                            <v-chip            
                                class="mt-1" label close outlined
                                @click="athFileDownload(j)" @click:close="delAtchfiles(j)">
                                <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                    mdi-file-pdf-box
                                </v-icon>
                                <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                    mdi-microsoft-excel
                                </v-icon>
                                <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                    mdi-file-powerpoint
                                </v-icon>
                                <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                    mdi-file-word
                                </v-icon>
                                <v-icon v-else color='cyan' left>
                                    mdi-file-document
                                </v-icon>
                                <span class="longText">{{ item.orgFileNm }}</span>
                            </v-chip> 
                        </span>
                        <v-divider class="my-2"></v-divider>
                        <div>
                            <span class="d-inline-block mx-1"
                                v-for="( item, i ) in imgList" :key="i">
                                <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)"></imageCard>
                            </span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-divider class="bodyColor my-2 mb-4" style="padding:1px 0px;"></v-divider>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">기타자료 내역</span>
        </div> 
        <div class="mt-2">
            <v-data-table height="100" locale="ko"
                :headers="headers"
                :items="nrsngDocList"  
                hide-default-header       
                hide-default-footer 
                fixed-header
                dense disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:740px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:100px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:100px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr :class="['fixedCol', sltNrsngDocPk == item.nrsngDocPk ? 'sltRow' : 'disSltRow']"
                        @click="tableClickEvent(item.nrsngDocPk)">
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.rn}}</td> 
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div class="text-overflow" style="text-align: center;width:700px;">{{item.cntnt}}</div>
                        </td>      
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.wrtDt?.beforeDateFormatDot()}}</td>  
                        <td class="clmsBodyCommon fixedCol0">{{item.wrtr}}</td>            
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="mt-2">            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>   
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'기타자료'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import imageCard from '../commons/imageCard.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { tmpFileUpload, fileDownload, delAtchdFile, selAtchdFileList } from '../../api/index.js';
import { insNrsngDoc, selNrsngDocInfo, selNrsngDocList, delNrsngDoc} from '../../api/bnftrcrd.js';
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'DctrCareTab',
    
    components: {  
        CmmDateComp,
        EmpSelector,
        imageCard,
        btnModalDelete,
    },

    props : {
        
    },

    computed: {
        getYmd:function(){
            return this.$parent.$parent.$parent.$parent.inqYMD
        }
    },

    created: function(){ 
        
    },

    mounted: function(){
        this.$nextTick(function () {
            this.inqYMD = this.$parent.$parent.$parent.$parent.inqYMD            
        })
    },

    watch: {
        'getYmd':function(value){
            this.inqYMD = value 
            this.items = Object.assign({},this.dummyItems)
            // this.items.wrtDt = this.inqYMD.beforeDateFormatHyp()
        }
    },

    methods: {   

        onLoad:function(){
            this.page=1
            this.frmReset()
            this.getNrsngDocList(0,this.itemsRows)
        },
        
        //초기화
        frmReset(){
            this.items.nrsngDocPk   = 0
            this.items.wrtDt        = this.inqYMD.beforeDateFormatHyp()
            this.items.wrtr         = ''
            this.items.cntnt        = ''
            this.$refs.form.resetValidation()

            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)
        },
        onSave:function(){
            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){
                if(this.$refs.form.validate()){
                    let obj = {
                        fcltyNum        : this.$store.getters.getFcltyNum,
                        bnMmbrPk        : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm          : this.$store.getters.getUserNm,
                        nrsngDocPk      : this.items.nrsngDocPk,
                        wrtDt           : this.items.wrtDt?.afterDateFormatHyp(),
                        wrtr            : this.items.wrtr,
                        cntnt           : this.items.cntnt,    
                        files           : []
                    }

                    this.fileList.forEach( items => {
                        if(items.atchdFilePk == undefined){
                            obj.files.push(items)
                        }
                    })
                    this.imgList.forEach( items => {
                        if(items.atchdFilePk == undefined){
                            obj.files.push(items)
                        }
                    })

                    this.setNrsngDoc(obj)
                }
            }else{
                 let error = {
                    statusCode:500,
                    message:'수급자를 선택해주세요.'
                }
                this.$emit("hlthBlnftTrigger",error)
            }
        },
        //기타서류 전체 삭제
        delAll:function(){
            if(this.items.nrsngDocPk > 0){
                this.dialogDelete = true
            }else{
                let error = {
                    statusCode:500,
                    message:'대상이 존재 하지 않습니다.'
                }
                this.$emit("hlthBlnftTrigger",error)
            }
        },

        //기타서류 저장
        setNrsngDoc: function (obj) {
            insNrsngDoc(obj)
                .then((response) => ( this.setNrsngDocAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/insNrsngDoc : ' + error))
        },
        //기타서류 단건 조회
        getNrsngDocInfo:function(pk){
            selNrsngDocInfo(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.bnMmbrPk, pk)
                .then((response) => ( this.getNrsngDocInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selNrsngDocInfo : ' + error))
        },
        //기타서류 다건 조회
        getNrsngDocList:function(strt, lmt){
            selNrsngDocList(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.bnMmbrPk, strt, lmt)
                .then((response) => ( this.selNrsngDocListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selNrsngDocList : ' + error))
        },
        
        
        //첨부파일 목록 조회
        getAtchdFileList: function (pk){
            selAtchdFileList(this.$store.getters.getFcltyNum, 28, pk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
        },
        //첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 다운로드
        athFileDownload: function (index) {
            fileDownload(this.fileList[index])
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
        },

        setNrsngDocAfter:function(res){
            this.$emit("hlthBlnftTrigger",res)
            this.frmReset()
            this.getNrsngDocInfo(res.result)
            this.getNrsngDocList(0,this.itemsRows)
        },
        getNrsngDocInfoAfter:function(res){
            if(res.nrsngDocPk != undefined && res.nrsngDocPk !=null){
                this.items.nrsngDocPk   = res.nrsngDocPk
                this.items.wrtDt        = res.wrtDt.beforeDateFormatHyp()
                this.items.wrtr         = res.wrtr
                this.items.cntnt        = res.cntnt

                this.getAtchdFileList(res.nrsngDocPk)
            }else{
                this.frmReset()
            }
        },
        selNrsngDocListAfter:function(res){            
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)
            this.nrsngDocList.splice(0)
            
            if(res != '' && res != null){
                res.list.forEach(el => {
                    this.nrsngDocList.push(el)
                });
            }
        },
        
        getAtchdFileListAfter: function(res){
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            let fList = res.filter(v => v.fileKndCd == '1')
            let iList = res.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgView?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })
        },
        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },
        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.fileList.splice(0)
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList()
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },        
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            }
            else {
                this.imgList.splice(idx, 1)
            }
        },
        delAtchfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.fileList[idx].atchdFilePk)
            }
            else {
                this.fileList.splice(idx, 1)
            }
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getNrsngDocList(strt,this.itemsRows)
        },

        tableClickEvent(value){
            if(this.sltNrsngDocPk != value){
                this.sltNrsngDocPk = value
            }else if(this.sltNrsngDocPk == value){
                this.sltNrsngDocPk = 0
            }

            this.getNrsngDocInfo(this.sltNrsngDocPk)
        },

        //기록 DB 삭제
        onDelete:function(res){
            if(res == 9990){       
                if(this.items.nrsngDocPk > 0){
                    let obj  = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        userNm      : this.$store.getters.getUserNm,
                        nrsngDocPk  : this.items.nrsngDocPk,

                    }
                    delNrsngDoc(obj)
                        .then((response) => ( this.onDeleteAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/delNrsngDoc : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){           
            this.dialogDelete = false
            this.page = 1
            this.$emit("hlthBlnftTrigger",res)
            this.frmReset()
            this.getNrsngDocList(0,this.itemsRows)
        },

        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk
        },
        onforce:function(){
            this.forceRender++
        },
    },
    data: () => ({     
        sltNrsngDocPk:0,
        forceRender:0, 
        pageCount:0,
        itemsRows: 2,
        page:1,
        files: null,
        fileList: [],
        imgList: [],
        items:{fcltyNum:'',bnMmbrPk:0,nrsngDocPk:0,wrtDt:'',cntnt:'',wrtr:'',wrtrMmbrPk:0,userNm:''},   
        headers:[
            { text: '번호', value: '', align: 'center', },
            { text: '내용', value: '', align: 'center', },
            { text: '작성일', value: '', align: 'center', },
            { text: '작성자', value: '', align: 'center', },  
        ], 
        nrsngDocList:[],  
        dialog:[false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
    }),
};
</script>