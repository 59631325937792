<template>
    <div class="d-flex justify-center align-center">
        <v-btn class="mr-1" color="btnBrdrColor" x-small outlined @click="isPrev"
            style="background-color: white; width:26px;" height="26" :disabled="btnDisabled">
            <span class="black--text">
                <v-icon>mdi-chevron-left</v-icon>
            </span>
        </v-btn>
        <v-btn class="" color="btnBrdrColor" x-small outlined @click="isNext"
            style="background-color: white; width:26px;" height="26" :disabled="btnDisabled">
            <span class="black--text">
                <v-icon>mdi-chevron-right</v-icon>
            </span>
        </v-btn>
        <span class="text-h5 font-weight-bold mx-3 pb-1" style="color: #004584;">{{weekstarttxt}} ~ {{weekendtxt}}</span>
        <v-menu
            ref="menu" v-model="menu"
            :close-on-content-click="false" :return-value.sync="date"
            transition="scale-transition" offset-y min-width="auto" dense>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="monthBtnPicker pa-0" style="background-color: white;min-width: 32px !important;" v-bind="attrs" v-on="on" color="btnBrdrColor" height="26" small outlined :disabled="btnDisabled">
                    <v-icon class="black--text" style="margin-top: 0px;" small>mdi-calendar-month</v-icon>
                </v-btn>
            </template>
            <v-date-picker
                v-model="date" type="date"
                class="cmmDtPckr" locale="en-US"
                :weekday-format="getDay" :month-format="getMonth" :header-date-format="getTitle"
                :allowed-dates="allowedDates" :key="forceRender"
                @click:date="datePickClose"
                no-title scrollable>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'expandWeekOnlyPicker',

    props : {
        prtDate: { type: String, default: '' },
        btnDisabled : {type:Boolean, default:false}
    },
    
    components: {
    
    },

    created: function(){
        this.setToday()
    },

    mounted: function(){
        this.$nextTick(function () {
           this.setToday()
        })
    },

    watch: {
        'date': function() {            
            this.setWeekFormat(this.date)            
            this.$emit('nowWeek', this.weekstart?.afterDateFormatHyp(), this.weekend?.afterDateFormatHyp())
        },
        'prtDate':function(){
           
            let pday = this.prtDate.substr(0,4)+"-"+this.prtDate.substr(4,2)+"-"+this.prtDate.substr(6,2)
            let currentDay = this.getDate(new Date(pday))            
            if(this.weekstart != currentDay){
                this.date = currentDay
            }
        }
    },

    methods: {
        isPrev: function (){
            let day = new Date(this.date);         
            day = day.setDate(day.getDate()-7)
            let arrDay = new Date(day).toISOString().split('T',2) 
            this.date = arrDay[0]
        },
        isNext: function (){
            let day = new Date(this.date);         
            day = day.setDate(day.getDate()+7)
            let arrDay = new Date(day).toISOString().split('T',2)
            this.date = arrDay[0]
        },
        datePickClose: function () {
            this.menu = false
            this.$refs.menu.save(this.date)
            this.forceRender += 1
        },
        //오늘 계산
        setToday : function(){  
            this.date = this.getDate()
        },
        getDate:function(currentDay = new Date()){
            let theYear = currentDay.getFullYear();
            let theMonth = currentDay.getMonth();
            let theDate  = currentDay.getDate();
            let theDayOfWeek = currentDay.getDay();
            let resultDay = null

            if(theDayOfWeek == 0){
                resultDay = new Date(theYear, theMonth, theDate-6)//월요일부터 계산
            }else{
                resultDay = new Date(theYear, theMonth, theDate +(1-theDayOfWeek))//월요일부터 계산
            }

            resultDay = resultDay.getFullYear()+"-"+('00'+(resultDay.getMonth()+1).toString()).slice(-2)+"-"+('00'+(resultDay.getDate()).toString()).slice(-2)

            return resultDay
        },
        //주간계산
        setWeekFormat: function(date){
            let currentDay = new Date(date);
            let arrStartDay = currentDay.toISOString().split('T',2)
            let arrEndDay = new Date(currentDay.setDate(currentDay.getDate()+6)).toISOString().split('T',2)
            this.weekstart = arrStartDay[0]
            this.weekend = arrEndDay[0]
            this.weekstarttxt = arrStartDay[0].afterDateFormatHyp().beforeDateFormatDot()
            this.weekendtxt = arrEndDay[0].afterDateFormatHyp().beforeDateFormatDot()
        }, 
        allowedDates: val => new Date(val).getDay() === 1,

        getTitle: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)

            if(date.length == 4)
                return new Date(date).getFullYear() + '년'
            else
                return new Date(date).getFullYear() + '년 ' + monthName[i]
        },
        getMonth: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)
            return monthName[i]
        },
        getDay: function (date) {
            const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
    },

    data: () => ({
        menu: false,
        date: '',
        dotDate: '',
        weekstart : '',
        weekend : '',
        weekstarttxt:'',
        weekendtxt:'',
        forceRender: 0,
    }),
};
</script>