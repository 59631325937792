<template>
    <v-row class="pa-0" no-gutters>
        <v-col class="d-flex align-center" cols="12">
            <v-text-field
                class="prdcDsnfcTF"
                :value="sltDt"
                :key="forceRender" :disabled="disabled" :label="label"
                :rules=" required == true ? [inRules.birthCheck, mmChk] : (sltDt != '' ? [inRules.birthCheck, mmChk] : [])"
                @input="lvData" hide-details outlined dense>
            </v-text-field>
            <CmmDatePicker
                v-if="btnActv" @cnfDt="(v) => vmlRflt(v)"
                :minDt="minDt" :maxDt="maxDt" :pckType="'month'" :disabled="disabled">
            </CmmDatePicker>
        </v-col>
    </v-row>
</template>

<script>
import CmmDatePicker from './CmmDatePicker.vue';

export default {
    name: 'CmmDateYMComp',

    props : {
        value: { type: String, default: '' },
        required: { type: Boolean, default: false },
        minDt: { type: String, default: '' },
        maxDt: { type: String, default: '' },
        label: { type: String, default: '' },
        btnActv: { type: Boolean, default: true },
        succBck: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
            
    components: {
        CmmDatePicker,
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.value == null)
                this.sltDt = ''
            else
                this.sltDt = this.value
        })
    },

    computed: {

    },

    watch:{
        'value': function () {
            if(this.value == null)
                this.sltDt = ''
            else
                this.sltDt = this.value
        },
    },
        
    methods: {
        vmlRflt: function (v) {
            this.sltDt = v
            this.$emit('input', v)
        },
        lvData: function (v) {
            if(this.succBck)
                this.evtChgDtFrm(v)
            else
                this.chnDtFrm(v)
        },
        chnDtFrm: function (v) {
            let rst = ''
            
            if(v != null)
                rst = v.replace(/\s/gi, "")

            if(rst.length == 6)
                rst = rst.replace(/(\d{4})(\d{2})/, '$1-$2')
          
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])$/.test(rst)){
                this.vmlRflt(rst)
            }
            else{
                this.vmlRflt(rst.replaceAll('-', ''))
            }
        },
        evtChgDtFrm: function (v) {
            let rst = ''
            
            if(v != null)
                rst = v.replace(/\s/gi, "")

            if(rst.length == 6)
                rst = rst.replace(/(\d{4})(\d{2})/, '$1-$2')
          
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])$/.test(rst)){
                this.sltDt = rst
                this.$emit('input', rst)
            }
            else{
                this.sltDt = rst.replaceAll('-', '')
            }
        },
        mmChk: function () {
            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])$/.test(this.sltDt)){

                if(this.minDt != ''){
                    if(new Date(this.minDt) <= new Date(this.sltDt))
                        return true
                    else
                        return false
                }

                if(this.maxDt != ''){
                    if(new Date(this.maxDt) >= new Date(this.sltDt))
                        return true
                    else
                        return false
                }

                if(this.maxDt == '' && this.maxDt == '')
                    return true
                    
            }
            else
                return false
        },
    },
    
    data: () => ({
        sltDt: '',
        forceRender: 0,
        inRules: {
            birthCheck: value => /^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])$/.test(value) || 'different',
        },
    }),
};
</script>