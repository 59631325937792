<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+180" :max-width="areaWidth+180">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <!-- <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span> -->
        </div>
        <v-divider class="my-2"></v-divider>
        <div v-if="tab==0" class="" style="position:relative;">
            <div class="d-flex align-center" style="position: absolute; top: 8px; left: 866px; z-index: 1;">
                <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;양호&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-triangle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;보통&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-close</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;불량</span>
            </div>
        </div>
        <div>
            <v-tabs hide-slider v-model="tab" color="white" class="black--text"
                active-class="bodyColor" center-active>
                <v-tab v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item transition="fade-transition">
                    <NghChcLog v-if="tab==0" ref="nghChcLog"></NghChcLog>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <NghWrkLog v-if="tab==1" ref="nghWrkLog"></NghWrkLog>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model="menu[0]" max-width="500">    
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import NghChcLog from '../../components/sfty/NghChcLog.vue';
import NghWrkLog from '../../components/sfty/NghWrkLog.vue';

export default {
    name: 'NtyInspJrnls',
    
    components: {
        btnModalDelete,
        expandNowDatePicker,
        NghChcLog,
        NghWrkLog,
    },

    props : {

    },

    created: function(){
        for (let index = 0; index < this.outlinedBtn.length; index++) {
            this.dialog.push(false)      
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)

            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)

            this.sltDate = timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp()
        })
    },

    computed: {
        getStrtDate: function () {
            return this.$parent.$parent.date.startDay
        },
        getSltInspRecPk: function () {
            return this.$parent.$parent.sltInspRecPk
        },
        getSelectedItem: function () {
            return this.$parent.$parent.selectedItem
        },
    },  

    watch: {
        getStrtDate: function (value) {

            let fDate = new Date(value.beforeDateFormatHyp())
            let nDate = new Date(this.$parent.$parent.sltDate.beforeDateFormatHyp())
            let lDate = new Date(this.$parent.$parent.date.endDay.beforeDateFormatHyp())

            if(fDate <= nDate && nDate <= lDate) this.$parent.$parent.sltDate = this.sltDate
            else {
                fDate.setDate(fDate.getDate()+3)
                this.sltDate = fDate.toISOString().split('T',2)[0].afterDateFormatHyp()
            }

        },
        getSltInspRecPk: function (value) {           
            if(value == 0){
                this.btnControll(1, true, 'fill')
                this.btnControll(0, true, 'out')
                this.tabItems[1].enter = true
                this.tab = 0
            }
            else if(value != 0){
                this.btnControll(1, false, 'fill')
                this.btnControll(0, false, 'out')
                this.tabItems[1].enter = false
            }
        },
        getSelectedItem: function (value) {
            if(value == -1)
                this.btnControll(0, true, 'fill')
            else{
                this.btnControll(0, false, 'fill')
            }
        },
    },

    methods: {
        getDate: function (value) {
            this.$parent.$parent.sltDate = value.date
            this.sltDate = value.date

            let fIndex = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == value.date.beforeDateFormatDot())
            if(fIndex != -1){
                let pkkk = this.$parent.$parent.sftyList[fIndex].inspRecPk

                if(pkkk == null)
                    this.$parent.$parent.sltInspRecPk = 0
                else
                    this.$parent.$parent.sltInspRecPk = pkkk

                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }
            else{
                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }

            let nDate = new Date(value.date.beforeDateFormatHyp())
            nDate.setDate(nDate.getDate() + 1)

            this.nextDate = nDate.toISOString().split('T',2)[0].afterDateFormatHyp()
        },
        onClickBtn: function (index) {
            switch(index) {
                case 0:     //저장
                    if(this.tab == 0)
                        this.$refs.nghChcLog.preInspRecDtllist()
                    else if(this.tab == 1)
                        this.$refs.nghWrkLog.preInspRec()
                    break;
                case 1:     //삭제
                    this.delTitle = '야간점검일지'
                    this.menu.splice(0, 1, true)
                    break;
                default:
                    break;
            }
        },
        delModalAct: function (res) {
            this.delTitle = ''
            if(res == 9990){    //OK
                this.$refs.nghChcLog.removeInspRecDtl()

                this.menu.splice(0, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(0, 1, false)
        },
        btnControll: function (index, dis, type) {
            switch (type) {
                case 'out':
                    //this.outlinedBtn[index].disabled = dis
                    break;
                case 'fill':
                    this.filledBtn[index].disabled = dis
                    break;
                default:
                    break;
            }
        },
        onLoad: function () {            
            // this.$refs.nghChcLog.getInspRecDtllist()
            // this.$refs.nghWrkLog.getInspRecInfo()
        },
        clearForm: function () {
            // this.$refs.nghChcLog.clearForm()
            // this.$refs.nghWrkLog.clearForm()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 0,
        menu: [false],
        dialog: [],
        delTitle: '',
        sltDate: '',
        nextDate: '',
        areaWidth: 708,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        tabItems: [
            { index: 0, tab: '야간점검일지', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 1, tab: '야간근무일지', style: 'font-size: 1.2rem !important;', enter: true },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>