<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            <div>
                <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                        <span class="black--text font-weight-medium">항목</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">
                        <span class="black--text font-weight-medium">확인</span>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0"  v-for="(item, i) in dsrEvltnList1" :key="i" no-gutters style="border-bottom: 1px solid #dbdbdb;">
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                        <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                    </v-col>
                    <v-col style="border-left: 1px solid #dbdbdb !important;" cols="9">
                        <div v-for="(list, i) in item.list" :key="i" class="d-inline-block">     
                            <div v-if="item.clsfc3Cd < 4">                       
                                <div v-if="list.valClcd =='1'" class="py-1" style="width:140px">
                                    <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                </div>
                                <div v-else class="d-flex py-1" style="width:300px">
                                    <span><v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox></span>
                                    <span>&#40;</span>
                                    <span style="width:250px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                    <span>&#41;</span>
                                </div>
                            </div>

                            <div v-else-if="item.clsfc3Cd == '4'" class="" >                       
                                <div v-if="list.itmCd=='1'" class="py-1 d-flex justify-center align-center" >
                                    <span style="width:80px;"><v-checkbox v-model="list.itmVal" hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox></span>
                                </div>
                                <div v-if="list.itmCd=='2'" class="d-flex justify-center align-center" style="width:300;">
                                    <span>&#40;</span>
                                    <span style="color: #666 !important;font-size: 0.98rem;">부위</span>
                                    <span>&#58;</span>
                                    <span class="d-inline-block pa-0 ma-0" style="width:220px">
                                        <v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field>
                                    </span>           
                                    <span>&#41;</span>
                                </div>
                                <div v-if="list.itmCd=='3'" class="d-flex justify-center align-center ml-2">
                                    <span><v-checkbox v-model="list.itmVal" hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox></span>
                                    <span>&#40;</span>
                                    <span class="d-inline-block pa-0 ma-0" style="width:220px">
                                        <v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field>
                                    </span>           
                                    <span>&#41;</span> 
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row> 
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col cols="3" class="greyE01" style="border-bottom: 1px solid #dbdbdb !important;">                                
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="6"> 
                                <span class="black--text font-weight-medium">욕창</span>
                            </v-col>
                            <v-col cols="6" style="border-left: 1px solid #dbdbdb !important;"> 
                                <div class="d-flex justify-center align-center pa-1 ma-0">
                                    <div class="black--text font-weight-medium" style="height:28px;">단계</div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;">
                                    <div class="black--text font-weight-medium" style="height:28px;">부위</div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top: 1px solid #dbdbdb !important;">
                                    <div class="black--text font-weight-medium" style="height:28px;">방지</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col style="border-left: 1px solid #dbdbdb !important;" cols="9">
                       <div v-for="(item, k) in dsrEvltnList2" :key='k' class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;">
                            <div v-if="item.clsfc3Cd == '5'">
                                <v-radio-group v-model="item.clsfc3Val" class="py-1" hide-details row>                                            
                                    <div class="d-flex pa-0 ma-0" ><v-radio v-for="(list, j) in item.list" :key="j" :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio></div>
                                </v-radio-group>
                            </div>
                            <div v-if="item.clsfc3Cd == '6'" class="d-flex">
                                <div v-for="(list, j) in item.list" :key="j" class="py-1">
                                    <div v-if="list.valClcd =='1'"  style="width:80px">
                                        <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </div>
                                    <div v-else class="d-flex" style="width:300px">
                                        <span><v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox></span>
                                        <span>&#40;</span>
                                        <span style="width:250px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                        <span>&#41;</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.clsfc3Cd == '7'" class="d-flex">
                                <div v-for="(list, n) in item.list" :key="n" class="py-1">
                                    <div v-if="list.valClcd =='1'"  style="width:80px">
                                        <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </div>
                                    <div v-else class="d-flex justify-center align-center" style="width:300px">                                        
                                        <span>&#40;</span>
                                        <span style="color: #666 !important;font-size: 0.98rem;">부위</span>
                                        <span>&#58;</span>
                                        <span style="width:250px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                        <span>&#41;</span>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0"  v-for="item in dsrEvltnList3" :key="item.clsfc3Cd" no-gutters style="border-bottom: 1px solid #dbdbdb;">
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                        <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                    </v-col>
                    <v-col style="border-left: 1px solid #dbdbdb !important;" cols="9">
                        <div v-for="list in item.list" :key="list.itmCd" class="d-inline-block">                                                    
                            <div v-if="list.valClcd =='1'" class="py-1" style="width:140px">
                                <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                            </div>
                            <div v-else class="d-flex py-1" style="width:300px">
                                <span><v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox></span>
                                <span>&#40;</span>
                                <span style="width:250px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                <span>&#41;</span>
                            </div>
                        </div>
                    </v-col>
                </v-row> 
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" cols="3">                                
                        <span class="black--text font-weight-medium">{{dsrEvltnTot.clsfc3Cdnm}}</span>
                    </v-col>
                    <v-col class="pa-1" style="border-bottom: 1px solid #dbdbdb;border-left: 1px solid #dbdbdb !important;" cols="9">                                
                        <v-textarea v-model="dsrEvltnTot.evltn" class="rmrksArea"
                            rows="5" outlined dense hide-details no-resize>
                        </v-textarea>
                    </v-col>
                </v-row>  
            </div>
        </v-form>        
    </v-sheet>
</template>
<script>
import { groupBy } from '../../api/common.js';

export default {
    name: 'DsrEvltnTab7',

    props : {
        
    },

    computed: {
        
    },  

    watch:{
        
    },

    created:function(){ 
        this.$nextTick(function () { 
        })          
    },
    
    mounted: function(){
       
    },
        
    methods: {             
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        setData(data){   
            this.dsrEvltnList1.splice(0)
            this.dsrEvltnList2.splice(0)
            this.dsrEvltnList3.splice(0)
            this.dsrEvltnTot = Object.assign({})

            let groups = groupBy(data, 'clsfc3Cd')
            
            Object.keys(groups).forEach((key)  => {
                    
                let obj = {
                    clsfc3Cd : key,
                    clsfc3Val:'',
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,                    
                    list : groups[key]
                }

                obj.clsfc3Val = this.getRadioData(groups[key])

                if(Number(key) < 5) this.dsrEvltnList1.push(obj)

                if(Number(key) > 4 && Number(key) < 8) this.dsrEvltnList2.push(obj)

                if( Number(key) ==  8) this.dsrEvltnList3.push(obj)

                if( Number(key) ==  99) {
                    groups[key].forEach(el => {                        
                        this.dsrEvltnTot = Object.assign({},el)
                    });
                }

            }); 
        },
 
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []

            this.dsrEvltnList1.forEach(x => {
                x.list.forEach(item => { 
                    //선택되지 않을경우                       
                    if(item.itmVal != '1') item.itmVal = '2'

                    //기타 선택 안될경우 
                    let bl = (item.clsfc3Cd == '4' && item.itmCd== '2')

                    if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''

                    examList.push(item)
                });
            })

            this.dsrEvltnList2.forEach(x => {
                x.list.forEach(item => { 

                    //선택되지 않을경우                       
                    if(item.itmVal != '1') item.itmVal = '2'
                    
                    if(item.clsfc3Cd == '5'){
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'
                    }

                    //기타 선택 안될경우 
                    let bl = (item.clsfc3Cd == '7')

                    if(item.valClcd =='2' && item.itmVal != '1' && !bl) item.evltn=''

                    examList.push(item)
                });
            })

            this.dsrEvltnList3.forEach(x => {
                x.list.forEach(item => { 
                    //선택되지 않을경우                       
                    if(item.itmVal != '1') item.itmVal = '2'

                    if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''

                    examList.push(item)
                });
            })

            examList.push(this.dsrEvltnTot)
            
            return examList
        },
        getRadioData(data){     
            let itmVal = ''       
            data.forEach(item => {
                if(item.itmVal == '1') itmVal = item.itmCd
            });

            return itmVal
        }, 
    },
    
    data: () => ({ 
        obj:{},
        dsrEvltnList1:[],
        dsrEvltnList2:[],
        dsrEvltnList3:[],
        dsrEvltnTot:{clsfc3Cdnm:'',evltn:''},
        items:[],
        jdgBss:{},
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
        },
        
    }),
};
</script>