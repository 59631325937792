<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">본인&nbsp;부담금&nbsp;입금&nbsp;처리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="mt-7">
            <basicInfo :bnMmbrPk="this.$parent.$parent.$parent.$parent.bnMmbrPk" ref="basicInfo"></basicInfo>
        </div>
        <v-row class="pa-0 mt-5 align-center" no-gutters>
            <v-icon style="font-size: 28px; width: 12px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-2">청구&nbsp;미납&nbsp;금액</span>
        </v-row>
        <v-divider :class="['mt-3', copayClmUnpdList.length > 5 ? 'mr-2__5' : '']" ></v-divider>
        <v-row :class="['greyE01 pa-0', copayClmUnpdList.length > 5 ? 'mr-2__5' : '']" no-gutters>
            <!-- <v-col class="d-flex justify-center align-center tableBR" cols="2">
                <v-checkbox class="fmlyHeaderCheck" v-model="allChecker" @change="checkBoxMessage" hide-details></v-checkbox>
            </v-col> -->
            <v-col class="d-flex justify-center align-center tableBR" cols="2">
                <span class="black--text font-weight-medium py-1">청구년월</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="2">
                <span class="black--text font-weight-medium">청구일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="3">
                <span class="black--text font-weight-medium">청구금액</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="3">
                <span class="black--text font-weight-medium">입금금액</span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="2">
                <span class="black--text font-weight-medium">미수금액</span>
            </v-col>
        </v-row>
        <v-divider :class="[copayClmUnpdList.length > 5 ? 'mr-2__5' : '']"></v-divider>
        <div
            v-if="copayClmUnpdList.length > 0"
            class="d-inline-block overflow-y-auto"
            style="width: 100%; height: 160px;">
            <v-row class="pa-0" v-for="(list, i) in copayClmUnpdList" :key="i" no-gutters>
                <!-- <v-col class="d-flex justify-center align-center tableBR tableBB" cols="2">
                    <v-checkbox
                        class="fmlyHeaderCheck"
                        v-model="list.isSelected"
                        @change="checkBoxIndex()"
                        hide-details>
                    </v-checkbox>
                </v-col> -->
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="2">
                    <span class="black--text py-1">{{list.clmYymm}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="2">
                    <span class="black--text">{{list.clmDt}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="3">
                    <span class="black--text">{{list.clmTot?.toLocaleString('ko-KR')}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" cols="3">
                    <span class="black--text">{{list.dpsAmt?.toLocaleString('ko-KR')}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBB" cols="2">
                    <span class="black--text">{{list.unpdAmt?.toLocaleString('ko-KR')}}</span>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center py-3" cols="12">
                    <span>※ 조회된 항목이 없습니다.</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
        <v-form ref="dpstForm" lazy-validation>
            <v-row class="pa-0 d-flex align-center mt-5" no-gutters>
                <v-icon style="font-size: 28px; width: 12px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-2">입금처리</span>
            </v-row>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium py-1">입금일</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="white d-inline-block py-1 pl-2" style="width: 147px;">
                        <CmmDateComp v-model="copayClmUnpdInfo.dpsDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">입금금액</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="4">
                    <span class="d-inline-block pl-2 py-1" style="width: 50%;">
                        <v-text-field
                            v-model="copayClmUnpdInfo.dpsAmt" type="number" :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-spacer></v-spacer>
                    <span class="px-2 py-1">
                        {{ Number(copayClmUnpdInfo.dpsAmt).toLocaleString('ko-KR') }} 원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium py-1">입금자명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block px-2 py-1" style="width: 100%;">
                        <v-text-field
                            v-model="copayClmUnpdInfo.dpsNm" :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">입금방법</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="4">
                    <span class="d-inline-block px-2 py-1" style="width: 150px;">
                        <v-select class="hsptlSelect"
                            v-model="copayClmUnpdInfo.dpsMthdCd" height="28"
                            item-text="valcdnm" item-value="valcd" :items="this.$parent.$parent.$parent.$parent.$parent.$parent.vCd84"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium py-1">입금비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="10">
                    <span class="d-inline-block px-2 py-1" style="width: 100%;">
                        <v-text-field
                            v-model="copayClmUnpdInfo.rmrks"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mb-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn class="white--text" color="blueBtnColor" height="30" @click="preCopayDpsHis()" samll rounded>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span style="font-size: 1rem;">저장</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

import { selCopayClmUnpdList, insCopayDpsHis } from '../../api/bnfcr.js';

export default {
    name: 'CopayDpst',

    props : {

    },
        
    components: {
        basicInfo,
        CmmDateComp,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.copayClmUnpdInfo.dpsDt = this.$store.getters.getPrsntDt('yyyy-mm-dd')

            if(this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem > -1){
                let chc = this.$parent.$parent.$parent.$parent.$parent.$parent.copayDpsStsList.findIndex(
                        v => v.bnMmbrPk == this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem 
                        )
                this.copayClmUnpdInfo.dpsNm = this.$parent.$parent.$parent.$parent.$parent.$parent.copayDpsStsList[chc].name
            }

            this.getCopayClmUnpdList()
        })
    },

    computed: {
        getSelectedItem(){
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSelectedItem(value){
            if(value > -1){
                let chc = this.$parent.$parent.$parent.$parent.$parent.$parent.copayDpsStsList.findIndex( v => v.bnMmbrPk == value )
                this.copayClmUnpdInfo.dpsNm = this.$parent.$parent.$parent.$parent.$parent.$parent.copayDpsStsList[chc].name
            }

            this.getCopayClmUnpdList()
        },
    },
    
    methods: {
        getCopayClmUnpdList: function (){
            selCopayClmUnpdList(
                this.$store.getters.getFcltyNum,
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem,
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltBnfcrPk
                )
                .then((response) => ( this.getCopayClmUnpdListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmUnpdList : ' + error))
        },
        setCopayDpsHis: function (obj){
            insCopayDpsHis(obj)
                .then((response) => ( this.setCopayDpsHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insCopayDpsHis : ' + error))
        },
        setCopayDpsHisAfter: function (res){
            if(res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.allChecker = false
                this.getCopayClmUnpdList()
                this.$parent.$parent.$parent.$parent.$parent.$parent.reloadList()
                this.$parent.$parent.$parent.$parent.getCopayDpsList()
                this.clearForm()
            }
        },
        getCopayClmUnpdListAfter: function (res){
            this.copayClmUnpdList = res
        },
        preCopayDpsHis: function (){
            let obj = Object.assign({}, this.copayClmUnpdInfo)
            let list = []
            let chk = this.$refs.dpstForm.validate()

            this.copayClmUnpdList.forEach(items => {
                // if(items.isSelected == true) list.push(items)
                list.push(items)
            });
            
            if(list.length != 0){
                list.forEach(items => {
                    items.clmDt = items.clmDt.afterDateFormatDot()
                    items.clmYymm = items.clmYymm.afterDateFormatMonthDot()
                    items.dpsClcd = '1'
                });
            }
            obj.list = list
            obj.dpsDt = obj.dpsDt.afterDateFormatHyp()

            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltBnfcrPk

            if(chk)
                this.setCopayDpsHis(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info'])
        },
        checkBoxMessage: function () {
     
            if(this.allChecker == true){
                this.copayClmUnpdList.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.copayClmUnpdList.forEach(item => {
                    item.isSelected = false
                })
            }
        },
        checkBoxIndex: function () {
            let chk = []
            this.copayClmUnpdList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)
            });

            let result = chk.every((item) => item==true)
            if(result) this.allChecker = true
            else this.allChecker = false
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        clearForm: function () {
            this.copayClmUnpdInfo.dpsDt = this.$store.getters.getPrsntDt('yyyy-mm-dd')
            this.copayClmUnpdInfo.dpsAmt = 0
            //this.copayClmUnpdInfo.dpsNm = ''
            this.copayClmUnpdInfo.dpsMthdCd = '1'
            this.copayClmUnpdInfo.rmrks = ''
            this.$refs.dpstForm.resetValidation()
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [false,],
        forceRender: 0,
        allChecker: false,
        copayClmUnpdList: [],
        copayClmUnpdInfo: { dpsDt: '', dpsAmt: 0, dpsNm: '', dpsMthdCd: '1',  rmrks: '', dpsAmtYN: '2', dpsClcd: '1' },
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>