<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">        
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">수급자 식이정보 특이사항</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-3">
            <v-divider class="mr-2__5"></v-divider>
            <v-row class="greyE01 mr-2__5" no-gutters>                
                <v-col class="d-flex py-2 justify-center align-center " cols="4">
                    <span class="black--text font-weight-medium">수급자</span>
                </v-col>
                <v-col class="d-flex py-2 justify-center align-center tableBL" cols="8">
                    <span class="black--text font-weight-medium">특이사항</span>
                </v-col>
            </v-row>
            <v-divider class="mr-2__5"></v-divider>
            <div style="height:550px;overflow-y:scroll">
                <div v-for="(item, i) in $parent.$parent.$parent.$parent.spclList" :key="i">
                    <v-row no-gutters>                
                        <v-col class="d-flex py-2 justify-center align-center " cols="4">
                            {{item.name}}
                        </v-col>
                        <v-col class="d-flex py-2 justify-center align-center tableBL" cols="8">
                            {{item.spclNote}}
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                </div>
            </div>

        </div>
    </v-sheet>
</template>
<script>
export default {
    name: 'WeklyMenuDietsSpc',

    props : {   
          
    },
        
    components: {
       
    },
    

    watch: {     
        
    },

    mounted: function(){             
       
    },
    
    methods: {
       
        onClose: function () {
            this.$emit('isClose')
        },
    },
    
    data: () => ({  
    }),
};
</script>