<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <v-row class="mb-7 pa-0" no-gutters>
            <v-col cols="5">
                <span class="text-h4 font-weight-bold">주소&nbsp;검색</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="5">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 500px;">
            <v-row class="ma-0 pr-1" no-gutters>
                <v-col cols="12">
                    <VueDaumPostcode :key="forceRender" @complete="onAddressComp" @load="onLoad" @error="onError" />
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { VueDaumPostcode } from "vue-daum-postcode"

export default {
    name: 'AdrsSrch',

    components: {
        VueDaumPostcode,
    },

    computed:{

    },

    watch:{

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    methods: {
        onLoad: function () {
            //다음 우편번호가 로딩 되었을 때 발생
        },
        onError: function (result) {
            //스크립트 로딩 실패시 발생
            console.log(result)
        },
        onAddressComp: function (result) {
            //Daum 우편번호 내부 테이블 클릭 이벤트 발생시 호출
            this.$emit('onDataThr', result)
            this.forceRender += 1
        },
        onClose: function () {
            //닫기 버튼 클릭시 발생 
            this.$emit('isClose', '.')
            this.forceRender += 1
        },
    },

    data: () => ({
        forceRender: 0,
    }),
};
</script>

<style>
   .vue-daum-postcode .vue-daum-postcode-container { height: 500px !important; }
</style>