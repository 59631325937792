import { http } from '@/api/baseAxios';
import { store } from '../store.js';

function getStcd(value) {
    return http.post('cmm/selCmnItmValCdMap', { valClcd: value })
}

function getMultiStcd(value, code = '') {
    return http.post('cmm/selCmnItmValCdJsn', { valClcdS: value, fcltyNum: code })
}

function uploadToFile(value) {
    return http.post('opr/atchdFileUpload', value, { headers: { 'Content-Type': 'multipart/form-data' }, })

}

function uploadSIgn(obj) {
    return http.post('cmm/insSign', obj)
}

//전자서명 삭제
function delESig(obj) {
    return http.post('cmm/delESig', obj)
}

function getCmnItmCd(obj) {
    return http.post('cmm/selCmnItmCd', {
        fcltyNum: obj.code,
        inqTFclsfc1Cd: obj.inqTFclsfc1Cd,
        inqTFclsfc2Cd: obj.inqTFclsfc2Cd,
        inqTFclsfc3Cd: obj.inqTFclsfc3Cd,
        inqTFitmCd: obj.inqTFitmCd,
        clsfc1Cd: obj.clsfc1Cd,
        clsfc2Cd: obj.clsfc2Cd,
        clsfc2CdS: obj.clsfc2CdS,
        clsfc3Cd: obj.clsfc3Cd,
    })
}

function getCmnItmCdTree(obj) {
    return http.post('cmm/selCmnItmCdTree', {
        fcltyNum: obj.code,
        inqTFclsfc1Cd: obj.inqTFclsfc1Cd,
        inqTFclsfc2Cd: obj.inqTFclsfc2Cd,
        inqTFclsfc3Cd: obj.inqTFclsfc3Cd,
        inqTFitmCd: obj.inqTFitmCd,
        clsfc1Cd: obj.clsfc1Cd,
        clsfc2Cd: obj.clsfc2Cd,
        clsfc3Cd: obj.clsfc3Cd,
    })
}

function getLvnRmTypList(code) {
    return http.post('opr/selLvnRmTypList', { fcltyNum: code })
}

//보호자 다 건 조회
function getAllFmlyList(code, bnmmbrPk, stcd=null) {
    return http.post('bnfcr/selAllFmlyList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, entStcd:stcd})
}

//공통코드항목값코드 조회
function getCmnItmValCdList(code, value) {
    return http.post('cmm/selCmnItmValCd', { fcltyNum: code, valClcd: value, useYn:'1' })
}

//공통코드항목값코드 저장
function insCmnItmValCd(obj) {
    return http.post('cmm/insCmnItmValCd', obj)
}

//공통코드항목값코드 삭제
function delCmnItmValCd(obj) {
    return http.post('cmm/delCmnItmValCd', obj)
}

//공통코드항목값코드 저장
function insCmnItmCd(obj) {
    return http.post('cmm/insCmnItmCd', obj)
}

//공통코드항목값코드 삭제
function delCmnItmCd(obj) {
    return http.post('cmm/delCmnItmCd', obj)
}

// 에디터 이미지 멀티 업로드
function editorFileUploads(value) {
    return http.post('opr/editorFileUploads', value, { headers: { 'Content-Type': 'multipart/form-data' }, })
}

// 파일 멀티 업로드
function tmpFileUpload(value) {
    return http.post('opr/tmpFileUpload', value, { headers: { 'Content-Type': 'multipart/form-data' }, })
}

//출력 관리 -출력물 종류
function selPrtList() {
    return http.post('report/selPrtList', {})
}

//출력 관리 PDF 조회
function selReportPdf(yyyymm, prtCode, code) {
    return http.post('report/selReportPdf', { inqYYMM: yyyymm, prtCd: prtCode, fcltyNum: code })
}

//PDF 다운로드
function pdfDownload(pth, nm) {
    return http({
        method: 'post',
        url: 'cmm/fileTempDownload',
        responseType: 'arraybuffer',
        data: { filePth: pth, fileNm: nm }
    })
}

//첨부파일 다운로드
function fileDownload(data) {
    return http({
        method: 'post',
        url: 'cmm/fileDownload',
        responseType: 'arraybuffer',
        data: data
    })
}

//엑셀 다운로드
function excelCrtDwnl(code, excel, ymd, yymm, pk, mmbr=null, yyyy=null) {
    return http({
        method: 'post',
        url: 'report/excelCrtDwnl',
        responseType: 'arraybuffer',
        data: { fcltyNum: code, exlCd: excel, inqYMD: ymd, inqYYMM: yymm, inqBzPk: pk, bnMmbrPk: mmbr, inqYYYY: yyyy }
    })
}

//출력 관리 PDF 경로 조회
function pdfInfo(list, yyyy, yyyymm, bnmmbrPk, ePk, code, prtCode, clcd) {
    return http.post('report/pdfInfo', { pkList: list, inqYYYY: yyyy, inqYYMM: yyyymm, bnMmbrPk: bnmmbrPk, empPk: ePk, fcltyNum: code, prtCd: prtCode, prtClcd: clcd })
}

//첨부화일 목록 조회
function selAtchdFileList(code, clcd, bzPk) {
    return http.post('opr/selAtchdFileList', { fcltyNum: code, bzClcd:clcd, bzPk:bzPk})
}

//첨부파일 삭제
function delAtchdFile(code, pk) {
    return http.post('opr/delAtchdFile', { fcltyNum: code, atchdFilePk: pk, userNm: store.state.sessionInfo.userNm })
}

//공통 다중 PDF 경로 조회
function selMultiReportInfo(obj) {
    return http.post('report/selMultiReportInfo', obj)
}

//공통 PDF 경로 조회
function selReportInfo(obj) {
    return http.post('report/selReportInfo', obj)
}

//엑셀 파일 생성 및 결과 조회
function excelDownload(code, excel, ymd, yymm, pk) {
    return http.post('report/excelDownload',
        { fcltyNum: code, exlCd: excel, inqYMD: ymd, inqYYMM: yymm, inqBzPk: pk }
    )
}

//의약품정보 저장
function insDrugInf(obj) {
    return http.post('cmm/insDrugInf', obj)
}

//의약품정보 삭제
function delDrugInf(obj) {
    return http.post('cmm/delDrugInf', obj)
}

//의약품정보 조회
function selDrugInfList(obj) {
    return http.post('cmm/selDrugInfList', obj)
}

//전자서명 조회
function selESig(obj) {
    return http.post('cmm/selESig', obj)
}

//전자서명 조회	(수급자 가족 전체)	
function selESigAllFmlyList(obj) {
    return http.post('cmm/selESigAllFmlyList', obj)
}

export {
    getStcd,
    getMultiStcd,
    uploadToFile,
    uploadSIgn,
    delESig,
    getCmnItmCd,
    getCmnItmCdTree,
    getLvnRmTypList,
    getAllFmlyList,
    getCmnItmValCdList,
    insCmnItmValCd,
    delCmnItmValCd,
    insCmnItmCd,
    delCmnItmCd,
    editorFileUploads,
    tmpFileUpload,
    selPrtList,
    selReportPdf,
    pdfDownload,
    pdfInfo,
    selAtchdFileList,
    fileDownload,
    delAtchdFile,
    selMultiReportInfo,
    selReportInfo,
    insDrugInf,
    delDrugInf,
    selDrugInfList,
    excelDownload,
    selESig,
    excelCrtDwnl,
    selESigAllFmlyList,
}