<template>
    <div id="l-menu" class="d-inline-block d-flex">
        <!-- left menu Top -->
        <v-list color="leftMemuColor" class="rounded-lg rounded-r-0" min-width="190" max-width="200">
            <v-list-item class="white--text px-2" link>
                <v-card width="100%" outlined>
                    <v-card-title class="white--text pb-0 px-2">
                        <v-avatar style="padding-bottom: 2px;" size="21">
                            <v-img src="../styles/images/ic-user.svg"></v-img>
                        </v-avatar>
                        <span class="ml-2">
                            <span class="black--text text-title font-weight-bold">{{ $store.state.sessionInfo.userNm }}</span>
                            <span class="black--text font-weight-medium fontOneRem ml-1">
                                &#40;{{ $store.state.sessionInfo.jobNm }}&#41;
                            </span>
                        </span>
                    </v-card-title>
                    <v-card-subtitle class="d-flex mt-1 px-2 py-1">
                        <v-icon class="grey006--text" size="20">mdi-timer-lock-outline</v-icon>
                        <div class="grey006--text font-weight-medium fontOneRem ml-2">
                            <countdown :time="logoutTimer" @progress="countEnd">
                                <template slot-scope="props">
                                    {{ ('00' + props.hours).slice(-2) }} : {{ ('00' + props.minutes).slice(-2) }} : {{ ('00' + props.seconds).slice(-2) }}
                                </template>
                            </countdown>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn v-if="remainTime < 300"
                            class="pa-0" color="red" width="20" min-width="42" height="20"
                            @click="$parent.$parent.$parent.$refs.headerTpl002.cnfRefreshToken($store.getters.getAuthToken)"
                            outlined>
                            <span style="font-size: 0.95rem; padding-bottom: 1px;">연장</span>
                        </v-btn>
                    </v-card-subtitle>
                    <div class="d-flex justify-center pa-2">
                        <v-btn class="white--text text-subtitle-1 py-0 px-2 mr-2" color="blueBtnColor" @click="floatMenu.splice(2, 1, true)">출퇴근관리</v-btn>
                        <v-btn class="white--text text-subtitle-1 py-0 px-2" color="blackBtnColor" @click="floatMenu.splice(1, 1, true)">수급자관찰</v-btn>
                    </div>
                </v-card>
            </v-list-item>

            <!-- left menu middle -->
            <v-list-item class="px-3 mt-5">
                <v-avatar size="30">
                    <v-img class="ic-list ml-1" src="../styles/images/ic-list.svg"></v-img>
                </v-avatar>
                <span class="ml-2 text-h5 font-weight-bold white--text">Menu List</span>
            </v-list-item>

            <v-divider class="mx-3 mb-3 white"></v-divider>

            <!-- left menu bottom -->
            <v-list-item-group>
                <v-list-item class="mt-1" v-for="(list, i) in menuList" :key="i" @click="menuActive(i)">
                    <v-list-item-content class="my-0">
                        <v-list-item-title v-if="slctdMenu == i"
                            class="slctdTxtColor--text mnListTtl text-h6 font-weight-medium">{{list.menu}}</v-list-item-title>
                        <v-list-item-title v-else
                            class="white--text text-h6 mnListTtl font-weight-medium">{{list.menu}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="my-0 pt-3m">
                        <v-slide-x-transition>
                            <v-icon color="slctdTxtColor" v-if="slctdMenu == i">{{list.icon}}</v-icon>
                        </v-slide-x-transition>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <!-- submenu dialog -->
        <v-bottom-sheet max-width="0" open-delay="0"
            hide-overlay transition="" eager
            v-model="modalTrns" persistent inset>

            <transition name="scroll-x-transition">
                <v-sheet
                    v-show="modalTrns"
                    id="subMenuDialog" rounded="lg" outlined elevation="5"
                    :style="customStyle" width="240px">

                    <div class="triangle triangle--right"></div>
                    <div class="triangle triangle--rightW"></div>

                    <v-list class="py-0 rounded-lg" dense flat>
                        <div style="text-align: right;">
                            <v-icon size="0" color="white" class="pt-2 pr-2" @click="modalClose">mdi-close-thick</v-icon>
                            <v-icon class="pt-2 pr-2" @click="modalClose">mdi-close-thick</v-icon>
                        </div>
                        <v-list-item-group v-model="slctdSubMenu">
                            <div v-for="(list, y) in subMenuList[slctdMenu]" :key="y">
                                <v-list-item 
                                    v-if="list.expsr == '1'"
                                    class="mt-1 mx-5 pa-0" active-class="slctdTxtColor--text"
                                    style="min-height: 30px; border-bottom: 1px dashed #ccc;"
                                    @click="routerAction(list.name)">
                                    <v-list-item-title
                                        :class="[list.slc == '1' ? 'slctdTxtColor--text' : 'blackBtnColor--text']">
                                        <span class="text-subtitle-1 font-weight-bold">{{list.sub}}</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </div>
                        </v-list-item-group>
                        <div style="text-align: right;height: 32px;"></div>
                    </v-list>
                </v-sheet>
            </transition>
        </v-bottom-sheet>
        <v-dialog v-model="floatMenu[1]" max-width="1200" content-class="round" persistent eager>
            <BnfcrObsrvHstry v-if="floatMenu[1]" @modalClose="modalChanger"></BnfcrObsrvHstry>
        </v-dialog>
        <v-dialog v-model="floatMenu[2]" max-width="1300" content-class="round" persistent>
            <CmtngRcrdMdl v-if="floatMenu[2]" @modalClose="modalChanger"></CmtngRcrdMdl>
        </v-dialog>
    </div>
</template>

<script>
    import BnfcrObsrvHstry from '../components/bnfcr/BnfcrObsrvHstry.vue';
    import CmtngRcrdMdl from '../components/emp/CmtngRcrdMdl.vue';
    import countdown from '@chenfengyuan/vue-countdown';

    export default {
        name: 'LeftMenu',
      
        components: {
            BnfcrObsrvHstry,
            CmtngRcrdMdl,
            countdown,
        },
        created: function(){

        },
        computed:{
            customStyle: function() {
                return {
                    '--sub-top': this.subMenuTop+'px',
                    '--sub-left': this.respMenuPosn+'px',
                    '--arrow-top': this.subArrowTop+'px',
                }
            },
            getTokenExp: function () {
                return this.$store.state.sessionInfo.exp
            },
        },
        watch: {
            getTokenExp: function (value) {
                this.logoutTimer = (value * 1000) - new Date().getTime() - 5000
            },
        },
        mounted: function(){
            //돔 로딩이 끝난 후 작업
            this.$nextTick(function () {
                this.setMnPrm()
                let expTime = new Date(this.$store.state.sessionInfo.exp * 1000)

                if((expTime.getTime() - new Date().getTime()) < 0)
                    this.$parent.$parent.$parent.$refs.headerTpl002.cnfLogout()
                else
                    this.logoutTimer = expTime.getTime() - new Date().getTime() - 5000
            })
        },
    
        methods: {
            menuActive: async function(index) {
                this.modalClose()

                await this.sleep(1)

                if(this.slctdMenu > 10){
                    this.modalClose() 
                }

                if(typeof this.subMenuList[index] != 'undefined'){
                    this.slctdMenu = index

                    this.respMenuPosn = document.getElementById('l-menu').getBoundingClientRect().left
                    this.respMenuPosn += 220

                    let subTopPostn = [274, 326, 378, 431, 431, 431, 476, 476, 516, 596]
                    let arrowTopPostn = [19, 19, 18, 18, 70, 122, 129, 180, 192, 164]

                    this.subMenuTop = subTopPostn[index]
                    this.subArrowTop = arrowTopPostn[index]
                    if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99
                    this.nowMenu = index
                    this.modalOpen()
                }
                
                // old code (dev test)
                // switch(this.slctdMenu) {
                //     case 0: this.subMenuTop = subTopPostn[0];
                //             this.subArrowTop = arrowTopPostn[0];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 0;
                //             this.modalOpen();
                //             break;
                //     case 1: this.subMenuTop = subTopPostn[1];
                //             this.subArrowTop = arrowTopPostn[1];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 1;
                //             this.modalOpen();
                //             break;
                //     case 2: this.subMenuTop = subTopPostn[2];
                //             this.subArrowTop = arrowTopPostn[2];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 2;
                //             this.modalOpen();
                //             break;
                //     case 3: this.subMenuTop = subTopPostn[3];
                //             this.subArrowTop = arrowTopPostn[3];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 3;
                //             this.modalOpen();
                //             break;
                //     case 4: this.subMenuTop = subTopPostn[4];
                //             this.subArrowTop = arrowTopPostn[4];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 4;
                //             this.modalOpen();
                //             break;
                //     case 5: this.subMenuTop = subTopPostn[5];
                //             this.subArrowTop = arrowTopPostn[5];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 5;
                //             this.modalOpen();
                //             break;
                //     case 6: this.subMenuTop = subTopPostn[6];
                //             this.subArrowTop = arrowTopPostn[6];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 6;
                //             this.modalOpen();
                //             break;
                //     case 7: this.subMenuTop = subTopPostn[7];
                //             this.subArrowTop = arrowTopPostn[7];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 7;
                //             this.modalOpen();
                //             break;
                //     case 8: this.subMenuTop = subTopPostn[8];
                //             this.subArrowTop = arrowTopPostn[8];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 8;
                //             this.modalOpen();
                //             break;
                //     case 9: this.subMenuTop = subTopPostn[9];
                //             this.subArrowTop = arrowTopPostn[9];
                //             if(this.nowMenu != this.slctdMenu) this.slctdSubMenu = 99;
                //             this.nowMenu = 9;
                //             this.modalOpen();
                //             break;
                //     default: this.modalClose();
                //             this.slctdMenu = 99;
                //             this.nowMenu = 99;
                //             this.slctdSubMenu = 99;
                //             break;
                // }
            },
            modalOpen: function() {
                this.modalTrns = true;         
            },
            modalClose: function() {
                this.modalTrns = false;         
            },
            modalChanger: function(value) {
                this.floatMenu.splice(value, 1, false)
            },
            routerAction: function (path){
                if(this.$route.name != path)
                    this.$router.push({ name: path, })
                
                this.modalTrns = false
                this.subSltClear()
                this.subArrSrch(path)
            },
            sleep: function(sec) {
                return new Promise(resolve => setTimeout(resolve, sec * 100));
            },
            subArrSrch: function(txt) {
                this.subMenuList.forEach(outList => {
                    let chc = outList.findIndex( v => v.name == txt )
                    if(chc != -1)
                        outList[chc].slc = '1'
                });
            },
            subSltClear: function() {
                this.subMenuList.forEach(outList => {
                    outList.forEach(inList => {
                        inList.slc = '2'
                    });
                });
            },
            setMnPrm: function() {
                //저장소에서 권한 리스트를 받아옴
                let authority = this.$store.getters.getAcesAthrt

                //권한 리스트가 존재하면 서브메뉴에 표시될 항목에 체크
                if(authority.length > 0){
                    this.subMenuList.forEach(outItm => {
                        authority.forEach(authItnm => {
                            let slct = outItm.findIndex( v => v.name == authItnm.viewId )
                            if(slct != -1)
                                outItm[slct].expsr = '1'
                        });
                    });
                }
                else if(authority.length == 0)
                    this.menuList.splice(0)

                //보여줄 서브메뉴가 있으면 카테고리도 활성화
                let actvSubMenu = []

                this.subMenuList.forEach(outItm => {
                    let tmp = outItm.filter( v => v.expsr == '1' )
                    tmp.forEach(itm => {
                        if(actvSubMenu.findIndex( v => v == itm.mnCtg ) == -1)
                            actvSubMenu.push(itm.mnCtg)
                    });
                });

                this.menuList.forEach(itm => {
                    if(actvSubMenu.findIndex( v => v == itm.mnCtg ) == -1)
                        itm.menu = ''
                });

                this.menuList = this.menuList.filter( v => v.menu != '' )

                let cnt = 11-Number(this.menuList.length)
                for (let index = 0; index < cnt; index++) {
                    this.menuList.push({ menu: '', icon: '', mnCtg: '' })
                }
                
                let ctgNm = ['bnfcr', 'bnftSrv', 'bnftrcrd', 'prgrm', 'sfty', 'opr', 'emp', 'empWage', 'btcInq', 'cmm']
                ctgNm.forEach((itm, i) => {
                    actvSubMenu.findIndex( v => v == itm )
                    if(actvSubMenu.findIndex( v => v == itm ) == -1)
                        this.subMenuList[i].splice(0)
                });
                
                this.subMenuList = this.subMenuList.filter( v => v.length > 0 )

            },
            countEnd: function (data) {
                this.remainTime = data.totalSeconds

                if(this.remainTime < 2)
                    this.$parent.$parent.$parent.$refs.headerTpl002.cnfLogout()
            },
        },
    
        data: () => ({
            logoutTimer: 52589,
            remainTime: 3600,
            floatMenu: [false, false, false],
            respMenuPosn: 0,
            slctdMenu: 99,
            slctdSubMenu: 99,
            nowMenu: 99,
            subMenuTop : 220,
            subArrowTop : 18,
            modalTrns: false,
            menuTrns: false,
            menuList: [
                { menu: '1. 수급자 관리', icon: 'mdi-chevron-right', mnCtg: 'bnfcr' }, 
                { menu: '2. 급여제공과정', icon: 'mdi-chevron-right', mnCtg: 'bnftSrv' },
                { menu: '3. 급여제공기록', icon: 'mdi-chevron-right', mnCtg: 'bnftrcrd' },
                { menu: '4. 프로그램', icon: 'mdi-chevron-right', mnCtg: 'prgrm' },
                { menu: '5. 환경및안전', icon: 'mdi-chevron-right', mnCtg: 'sfty' },
                { menu: '6. 기관운영관리', icon: 'mdi-chevron-right', mnCtg: 'opr' },
                { menu: '7. 직원관리', icon: 'mdi-chevron-right', mnCtg: 'emp' },
                { menu: '8. 수입지출관리', icon: 'mdi-chevron-right', mnCtg: 'empWage' },
                { menu: '9. 보고서', icon: 'mdi-chevron-right', mnCtg: 'btcInq' },
                { menu: '10. 기타관리', icon: 'mdi-chevron-right', mnCtg: 'cmm' },
                { menu: '', icon: '', mnCtg: '' },
                { menu: '', icon: '', mnCtg: '' },
            ],
            subMenuList: [
                [
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-1. 수급자 정보관리', name: 'BnfcrInfrmMgmt', path: '/bnfcr/bnfcrInfrmMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-2. 상담일지', name: 'BnfcrCnslnJrnls', path:'/cnslnJrnls' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-3. 외출·외박 관리', name: 'StayOutMgmt', path:'/bnfcr/stayOutMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-4. 가정통신문', name: 'FmlyLeter', path:'/fmlyLeter' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-5. 전체 수급자현황', name: 'AllBnfcrStts', path:'/allBnfcrStts' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-6. 본인부담금 청구관리', name: 'CopayClmsMgmt', path:'/copayClmsMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-7. 본인부담금 입금관리', name: 'CopayDpstMgmt', path:'/payLevy/copayDpstMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-8. 본인부담금 미납관리', name: 'CopayUnpdMgmt', path:'/CopayUnpdMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'bnfcr', sub: '1-9. 본인부담금 의료비공제', name: 'CopaymdcexDdctn', path:'/copaymdcexDdctn' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'bnftSrv', sub: '2-1. 전체급여 제공과정', name: 'AllBnftSrvPrcs', path:'/allBnftSrvPrcs' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftSrv', sub: '2-2. 급여제공 계획 및 결과평가', name: 'BnftSrvMgmt', path:'/bnfcr/bnftSrvMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftSrv', sub: '2-3. 물리(작업)치료 평가 및 계획', name: 'PhyscThrpyInspcPln', path:'/bnftSrvPlnCnfgr/physcThrpyInspcPln' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftSrv', sub: '2-4. 주간식단표', name: 'WeklyMenu', path:'/weklyMenu' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftSrv', sub: '2-5. 목욕일정', name: 'BathSchdl', path:'/bathSchdl' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftSrv', sub: '2-6. 사례관리', name: 'CaseMgmt', path:'/caseMtng/caseMgmt' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-1. 전체 급여제공기록', name: 'AllBlnftSrvRcrd', path:'/bnftrcrd/allBlnftSrvRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-2. 요양 급여제공기록', name: 'CareBlnftSrvRcrd', path:'/bnftrcrd/careBlnftSrvRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-3. 집중  배설관찰기록', name: 'CncntExcrtObsrvRcrd', path:'/bnftrcrd/cncntExcrtObsrvRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-4. 목욕 급여제공기록', name: 'BathBlnftSrvRcrd', path:'/bathBlnftSrvRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-5. 건강 급여제공기록', name: 'HlthBlnftSrvRcrd', path:'/bnftrcrd/hlthBlnftSrvRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-6. 간호 처치기록', name: 'NsgCareRcrd', path:'/bnftrcrd/NsgCareRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-7. 물리(작업)치료기록', name: 'PhyscThrpyRcrd', path:'/physcThrpyRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-8. 신체제재기록', name: 'PhyscSnctnRcrd', path:'/bnftrcrd/physcSnctnRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-9. 특별침실사용기록', name: 'SpclBdrmUseRcrd', path:'/bnftrcrd/spclBdrmUseRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'bnftrcrd', sub: '3-10. 응급상황기록', name: 'EmrgnRcrd', path:'/bnftrcrd/emrgnRcrd' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'prgrm', sub: '4-1. 프로그램 운영기록', name: 'PrgSrvRcrd', path:'/prgSrvRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'prgrm', sub: '4-2. 프로그램 정보관리', name: 'PrgInfrmMgmt', path:'/prgrm/prgInfrmMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'prgrm', sub: '4-3. 프로그램 일정관리', name: 'PrgSchdlMgmt', path:'/prgSchdlMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'prgrm', sub: '4-4. 프로그램 수급자 그룹관리', name: 'GrpstRcpnt', path:'/bnfcrAdv1/prgBnfcrGrpMgmt/grpstRcpnt' },
                    { slc: '2', expsr: '2', mnCtg: 'prgrm', sub: '4-5. 프로그램 개선(의견수렴)', name: 'PrgImprv', path:'/prgrmv/prgImprv' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'sfty', sub: '5-1. 야간점검(근무)일지', name: 'NtyInspJrnls', path:'/sfty/ntyInspJrnls' },
                    { slc: '2', expsr: '2', mnCtg: 'sfty', sub: '5-2. 일상소독일지', name: 'DailyDsnfcJrnls', path:'/sfty/dailyDsnfcJrnls' },
                    { slc: '2', expsr: '2', mnCtg: 'sfty', sub: '5-3. 식품위생관리', name: 'FoodHygnMgmt', path:'/sfty/foodHygnMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'sfty', sub: '5-4. 간호비품관리', name: 'NsgEqpmnMgmt', path:'/sfty/nsgEqpmnMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'sfty', sub: '5-5. 시설안전점검', name: 'FcsftInspc', path:'/sfty/FcsftInspc' },
                    { slc: '2', expsr: '2', mnCtg: 'sfty', sub: '5-6. 감염관리점검', name: 'InfctCntrlInspc', path:'/sfty/InfctCntrlInspc' },
                    { slc: '2', expsr: '2', mnCtg: 'sfty', sub: '5-7. 급식운영일지', name: 'MealSrvcLog', path:'/sftySp/MealSrvcLog' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'opr', sub: '6-1. 회의기록', name: 'CnfrnRcrd', path:'/opr/cnfrnRcrd' },
                    { slc: '2', expsr: '2', mnCtg: 'opr', sub: '6-2. 생활실 현황', name: 'LvnRmStts', path:'/lvnRmStts' },
                    { slc: '2', expsr: '2', mnCtg: 'opr', sub: '6-3. 시설업무일정', name: 'AnlWrkPln', path:'/anlWrkPln' },
                    { slc: '2', expsr: '2', mnCtg: 'opr', sub: '6-4. 시설정보관리', name: 'FacInfoMgmt', path:'/facInfoMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'opr', sub: '6-5. 급여수가정보', name: 'BlnftAmntInf', path:'/blnftAmntInf' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'emp', sub: '7-1. 직원정보관리', name: 'EmpInfMgmt', path:'/emp/EmpInfMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'emp', sub: '7-2. 근무일정현황', name: 'WrkngSchdl', path:'/empSlt/wrkngSchdl' },
                    { slc: '2', expsr: '2', mnCtg: 'emp', sub: '7-3. 근무일지(출퇴근)', name: 'WrkngJrnl', path:'/emp/wrkngJrnl' },
                    { slc: '2', expsr: '2', mnCtg: 'emp', sub: '7-4. 교육및훈련일지', name: 'EdctnJrnl', path:'/edctnJrnl' },
                    { slc: '2', expsr: '2', mnCtg: 'emp', sub: '7-5. 외부강사/자원봉사자 관리', name: 'OtsdExprtMgmt', path:'/emp/otsdExprtMgmt' }, 
                    { slc: '2', expsr: '2', mnCtg: 'emp', sub: '7-6. 처우개선(고충처리,포상)', name: 'GrvncHndlnMgmt', path:'/grvncHndlnMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'emp', sub: '7-7. 직원건강관리', name: 'EmpHlthMgmt', path:'/empHlthMgmt' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'empWage', sub: '8-1. 직원 임금명세서 관리', name: 'EmpWageStmtMgmt', path:'/empWageStmtMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'empWage', sub: '8-2. 기관 부담금관리', name: 'FcltylWgeMgmt', path:'/fcltylWgeMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'empWage', sub: '8-3. 직원 임금정보 관리', name: 'EmpWageInfMgmt', path:'/empWageInfMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'empWage', sub: '8-4. 요양 급여가산 관리', name: 'CareBlnftAddMgmt', path:'/careBlnftAddMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'empWage', sub: '8-5. 월별손익관리', name: 'MnthlPrlssMgmt', path:'/mnthlPrlssMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'empWage', sub: '8-6. 인건비 지출비율 계산', name: 'LbrCstExpRtCalc', path:'/lbrCstExpRtCalc' },
                    { slc: '2', expsr: '2', mnCtg: 'empWage', sub: '8-7. 직원 연간임금 대장', name: 'EmpAnlWageStmtMgmt', path:'/emp/empAnlWageStmtMgmt' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'btcInq', sub: '9-1. 수급자 정보현황', name: 'BnfcrStts', path:'/bnfcrStts' },
                    { slc: '2', expsr: '2', mnCtg: 'btcInq', sub: '9-2. 직원 정보현황', name: 'EmpInfStts', path:'/empInfStts' },
                    { slc: '2', expsr: '2', mnCtg: 'btcInq', sub: '9-3. 본인부담금', name: 'CopayStts', path:'/copayStts' },
                    { slc: '2', expsr: '2', mnCtg: 'btcInq', sub: '9-4. 간호기록현황', name: 'NsgRcrdStts', path:'/btcInq/nsgRcrdStts' },
                    { slc: '2', expsr: '2', mnCtg: 'btcInq', sub: '9-5. 프로그램 수급자 참여현황', name: 'PrgBnfcrPrtcpStts', path:'/btcInq/prgBnfcrPrtcpStts' },
                    { slc: '2', expsr: '2', mnCtg: 'btcInq', sub: '9-6. 시설운영일지', name: 'FcsftOprtnJrnl', path:'/fcsftOprtnJrnl' },
                    { slc: '2', expsr: '2', mnCtg: 'btcInq', sub: '9-7. 물리치료기록', name: 'PhyscRec', path:'/PhyscRec' },
                ],
                [
                    { slc: '2', expsr: '2', mnCtg: 'cmm', sub: '10-1. SMS 발송관리', name: 'SmsSndMgmt', path:'/smsSndMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'cmm', sub: '10-2. 공지사항 관리', name: 'NtcMgmt', path:'/ntcMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'cmm', sub: '10-3. 코드관리', name: 'CmmCdMgmt', path:'/cmmCdMgmt' },
                    { slc: '2', expsr: '2', mnCtg: 'cmm', sub: '10-4. 권한관리', name: 'CmmAuthMgmt', path:'/auth/cmmAuthMgmt' },
                ],
            ],
        }),
    };
</script>
    
<style>
    .ic-list{ width:25px;height:25px; font-size:0; margin-right:0.313rem; display: inline; }
    .pt-3m{ padding-top: 14px !important; }
    .triangle { display: inline-block; border: 8px solid transparent; }
    .triangle--right { border-right-color: #666; top: var(--arrow-top); left: -17px; position: absolute;}
    .triangle--rightW { border-right-color: #fff; top: var(--arrow-top); left:-14px; position: absolute;}
    .divDash{ border-bottom: 1px dashed #ccc; }
    .mnListTtl { line-height: 1.2 !important; }

    #subMenuDialog { position:absolute; top: var(--sub-top); left: var(--sub-left); border: 2px solid #666; }
    #subMenuArrow { position:absolute; top: var(--arrow-top); left: -11px; width: 11px; height: 24px;}
    #arrowDummy { width: 10px;height: 24px; position: absolute; top: var(--arrow-top); left: -2px; background: white; }
</style>