<template>
    <v-sheet>
        <v-form ref="form" lazy-validation>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">계약일</span>
                </v-col>
                <v-col class="pa-0 ma-0" cols="2">
                    <span class="d-inline-block py-1 pl-2" style="width: 140px;">
                        <CmmDateComp v-model="bnfcrCntrc.cntrcDt" :minDt="cntrcMinDt" :disabled="bnfcrCntrc.bnfcrCntrcInfPk != 0" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">계약기간</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex align-center" cols="6">
                    <span class="d-inline-block ml-2" style="width: 130px;">
                        <CmmDateComp v-model="bnfcrCntrc.cntrcPrdStrt" :minDt="cntrcMinDt" :maxDt="bnfcrCntrc.cntrcPrdEnd" :disabled="bnfcrCntrc.bnfcrCntrcInfPk != 0" :required="true"></CmmDateComp>
                    </span>
                    <span class="ml-3 mr-2">
                        <v-icon class="greyE00--text" size="16">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 130px;">
                        <CmmDateComp v-model="bnfcrCntrc.cntrcPrdEnd" :minDt="bnfcrCntrc.cntrcPrdStrt" :required="true"></CmmDateComp>
                    </span>
                    <span class="d-inline-block pl-2" style="width: 90px;">
                        <v-select
                            class="wrkSelect" height="28"
                            v-model="selectYear"
                            :items="year_term" :key="forceRender"
                            @change="calcYear()"
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">납부방법</span>
                </v-col>
                <v-col class="pa-0 ma-0" cols="2">
                    <span class="d-inline-block ml-2 pt-2 pb-1" style="width: 90px;">
                        계좌이체
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">납부일</span>
                </v-col>
                <v-col class="pa-0 ma-0" cols="6">
                    <span class="d-inline-block font-weight-mideum ml-3" style="width: 30px;">
                        매월
                    </span>
                    <span class="d-inline-block ml-2 py-1" style="width: 64px;">
                        <v-text-field
                            class="" type="number" color="blue"
                            v-model="bnfcrCntrc.pymntDt"
                            :rules="[rules.required]" :disabled="true"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block font-weight-mideum ml-3" style="width: 40px;">
                        일
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>

            <v-divider v-if="bnfcrCntrc.bnfcrCntrcInfPk != 0" class="mt-2 mr-1"></v-divider>
            <v-row class="pa-0 mr-1" v-if="bnfcrCntrc.bnfcrCntrcInfPk != 0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">본인부담률</span>
                </v-col>
                <v-col class="py-1 pl-2" cols="2">
                    <span class="d-inline-block" style="width: 132px;">
                        <v-select
                            v-model="bnfcrCntrc.copayCd" :key="forceRender"
                            :items="$parent.$parent.$parent.$parent.$parent.$parent.$parent.copayCode"
                            item-text="valcdnm" item-value="valcd"
                            @change="copayRflData"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">인정등급</span>
                </v-col>
                <v-col class="py-1 pl-2" cols="2">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-select
                            v-model="bnfcrCntrc.acknwRtng" :key="forceRender"
                            :items="$parent.$parent.$parent.$parent.$parent.$parent.$parent.acknwCode"
                            item-text="valcdnm" item-value="valcd"
                            @change="acknwRtngRflData"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">정부지원</span>
                </v-col>
                <v-col class="py-1 pl-2" cols="3">
                    <span class="d-inline-block" style="width: 80px; padding-top: 2px;">
                        <v-checkbox 
                            v-model="bnfcrCntrc.gvrnmSnack" :ripple="false"
                            label="간식비" true-value="1" false-value="2"
                            @change="onCalcSum"
                            hide-details>
                        </v-checkbox>
                    </span>
                    <span class="d-inline-block" style="width: 100px; padding-top: 2px;">
                        <v-checkbox 
                            v-model="bnfcrCntrc.gvrnmMeal" :ripple="false"
                            label="식사 재료비" true-value="1" false-value="2"
                            @change="onCalcSum"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
            </v-row>
            <v-divider v-if="bnfcrCntrc.bnfcrCntrcInfPk != 0" class="mr-1"></v-divider>

            <v-divider class="mt-2 mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="3">
                    <span class="black--text font-weight-medium">비급여&nbsp;수가&nbsp;기준</span>
                </v-col>
                <v-col class="pa-0 ma-0 tableBL" cols="9">
                    <v-radio-group v-model="bnfcrCntrc.npymntFeeStd" :key="forceRender" hide-details row>
                        <v-radio class="d-inline-block ma-0 pl-2 py-2 tableBR" style="width: 50%;"
                            :ripple="false" label="시설 비급여 수가" @click="onCalcSum()" value="1">
                        </v-radio>
                        <v-radio class="d-inline-block ma-0 pl-2 py-2" style="width: 50%"
                            :ripple="false" label="개별 비급여 수가" @click="onCalcSum()" value="2">
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="3">
                    <v-row class="pa-0 ma-0 greyE01" no-gutters>
                        <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="3">
                            <span class="black--text font-weight-medium py-2">
                                비<br/>급<br/>여<br/>수<br/>가<br/>정<br/>보
                            </span>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="9">
                           <div class="d-flex justify-center tableBB">
                                <span class="black--text font-weight-medium py-2">식사&nbsp;재료비</span>
                           </div>
                           <div class="d-flex justify-center tableBB">
                                <span class="black--text font-weight-medium py-2">경관&nbsp;유동식</span>
                           </div>
                           <div class="d-flex justify-center tableBB">
                                <span class="black--text font-weight-medium py-2">간식비</span>
                           </div>
                           <div class="tableBB">
                                <div class="d-flex justify-center">
                                    <span class="black--text font-weight-medium pt-2">식사&nbsp;재료비</span>
                                </div>
                                <div class="d-flex justify-center">
                                    <span class="black--text font-weight-medium pb-2">월&nbsp;한도액</span>
                                </div>
                           </div>
                           <div class="d-flex justify-center tableBB">
                                <span class="black--text font-weight-medium pt-2" style="padding-bottom: 9px;">이&#47;미용료</span>
                           </div>
                           <div class="d-flex justify-center tableBB">
                                <span class="black--text font-weight-medium py-2">상급&nbsp;침실비</span>
                           </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="pa-0 ma-0 tableBB" cols="9">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span class="d-inline-block pl-2 py-2 tableBR" style="width: 50%;">&#40;1식&#41;</span>
                                <span class="d-inline-block pl-2 py-2" style="width: 50%;">
                                    {{ bnfcrCntrc.bnfcrCntrcInfPk > 0 ?
                                        bnfcrCntrc.mealCost?.toLocaleString('ko-KR') :
                                        $parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCost?.toLocaleString('ko-KR') }}원
                                </span>
                           </div>
                        </v-col>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span class="d-inline-block pl-2 py-2 tableBR" style="width: 35%;">&#40;1식&#41;</span>
                                <span class="d-inline-block align-center pl-2" style="width: 55%; margin : auto;">
                                    <v-text-field v-model="indvCntrc.mealCost" :rules="[rules.numberCheck]" @blur="onCalcSum()"
                                        type="number" :key="forceRender" hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block pl-2 py-2" style="width:10%;">원</span>
                           </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" style="" no-gutters>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span class="d-inline-block pl-2 py-2 tableBR" style="width: 50%;">
                                    &#40;{{(bnfcrCntrc.lqdFoodClcd == 1) ? '1개월' : '1식' }}&#41;
                                </span>
                                <span class="d-inline-block pl-2 py-2" style="width: 50%;">
                                    {{ bnfcrCntrc.bnfcrCntrcInfPk > 0 ?
                                    bnfcrCntrc.lqdFood?.toLocaleString('ko-KR') :
                                    $parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodFee?.toLocaleString('ko-KR') }}원
                                </span>
                           </div>
                        </v-col>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span class="d-inline-block tableBR px-2" style="width: 35%;">
                                    <v-select
                                        class="wrkSelect" color="white" height="28"
                                        style="padding-top: 5px;"
                                        v-model="indvCntrc.lqdFoodClcd"
                                        :items="lqdFood_term" :key="forceRender"
                                        @change="onCalcSum()"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </span>
                                <span class="d-inline-block align-center pl-2" style="width: 55%; margin : auto;">
                                    <v-text-field v-model="indvCntrc.lqdFood" :rules="[rules.numberCheck]" @blur="onCalcSum()"
                                        type="number" :key="forceRender" hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block pl-2 py-2" style="width:10%;">원</span>
                           </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span class="d-inline-block d-flex align-center py-2 pl-2 tableBR" style="width: 50%;">
                                    <span>&#40;{{(bnfcrCntrc.snackOfrClcd == 1) ? '1일' : '1식' }}&#41;</span>
                                </span>
                                <span class="d-flex align-center d-inline-block pl-2" style="width: 50%;">
                                    <span>
                                        {{ bnfcrCntrc.bnfcrCntrcInfPk > 0 ?
                                        bnfcrCntrc.snackFee?.toLocaleString('ko-KR') :
                                        $parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee?.toLocaleString('ko-KR') }}원
                                    </span>
                                    <v-spacer></v-spacer>
                                    <span class="d-inline-block pr-2 pb-1" style="width: 90px;">
                                        <v-select
                                            class="wrkSelect" color="white" height="28"
                                            style="padding-top: 5px;"
                                            v-model="bnfcrCntrc.snackCntCd"
                                            :items="cnt_term" :key="forceRender" :rules="[rules.selCheck]"
                                            :disabled="bnfcrCntrc.snackOfrClcd == '1'"
                                            @change="onCalcSum()"
                                            item-text="valcdnm" item-value="valcd"
                                            dense hide-details outlined>
                                        </v-select>
                                    </span>
                                </span>
                           </div>
                        </v-col>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span class="d-inline-block tableBR" style="width: 35%;">
                                    <v-select
                                        class="px-2 wrkSelect" color="white" height="28"
                                        style="padding-top: 5px;"
                                        v-model="indvCntrc.snackOfrClcd"
                                        :items="snackFee_term" :key="forceRender" :rules="[rules.selCheck]"
                                        @change="onCalcSum()"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </span>
                                <span class="d-inline-block align-center" style="width: 27%; padding: 5px 5px 5px 10px;">
                                    <v-text-field
                                        v-model="indvCntrc.snackFee" :rules="[rules.numberCheck]" @blur="onCalcSum()"
                                        type="number" :key="forceRender" hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block py-2" style="width: 5%;">원</span>
                                <span class="d-inline-block pr-3" style="width: 33%;">
                                    <v-select
                                        class="pl-2 wrkSelect" color="white" height="28"
                                        style="padding-top: 5px;"
                                        v-model="indvCntrc.snackCntCd"
                                        :items="cnt_term" :key="forceRender" :rules="[rules.selCheck]"
                                        :disabled="indvCntrc.snackOfrClcd == '1'"
                                        @change="onCalcSum()"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </span>
                           </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span class="d-inline-block pl-2 py-5 tableBR"
                                    style="width: 50%;">
                                    {{(bnfcrCntrc.mealCostLmtYn == '1') ? '설정' : '미설정' }}
                                </span>
                                <span class="d-inline-block pl-2 py-5" style="width: 50%;">
                                    {{bnfcrCntrc.mealCostLmt?.toLocaleString('ko-KR')}}원
                                </span>
                           </div>
                        </v-col>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left tableBB">
                                <span
                                    class="d-inline-block pl-2 tableBR"
                                    style="width: 35%; padding-top: 17px; padding-bottom: 18px;">
                                    <v-checkbox
                                        v-model="indvCntrc.mealCostLmtYn" :key="forceRender" :ripple="false"
                                        @change="onCalcSum()" true-value="1" false-value="2"
                                        label="설정" value="1" hide-details>
                                    </v-checkbox>
                                </span>
                                <span class="d-inline-block align-center pl-2" style="width: 55%; margin: auto;">
                                    <v-text-field v-model="indvCntrc.mealCostLmt" @blur="onCalcSum()"
                                        type="number" :key="forceRender" hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block pl-2 py-2" style="width: 10%; margin: auto;">원</span>
                           </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left">
                                <span class="d-inline-block pl-2 py-2 tableBR" style="width: 50%;">&#40;1개월&#41;</span>
                                <span class="d-inline-block pl-2 py-2" style="width: 50%;">
                                    {{$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.btyFee?.toLocaleString('ko-KR')}}원
                                </span>
                           </div>
                        </v-col>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left">
                                <span class="d-inline-block pl-2 py-2 tableBR" style="width: 35%;">
                                    &#40;1개월&#41;
                                </span>
                                <span class="d-inline-block align-center pl-2" style="width: 55%; margin: auto;">
                                    <v-text-field v-model="indvCntrc.btyFee" :rules="[rules.numberCheck]" @blur="onCalcSum()"
                                        type="number" :key="forceRender" hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block pl-2 py-2" style="width: 10%;">원</span>
                           </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 tableBL" cols="6">
                            <div class="d-flex justify-left">
                                <span class="d-inline-block px-2 tableBR" style="width: 50%;">
                                    <span class="d-inline-block" style="width: 120px;">
                                        <v-select
                                            class="wrkSelect" color="white" height="28"
                                            style="padding-top: 5px;"
                                            v-model="bnfcrUpBedFee"
                                            :disabled="true"
                                            :items="lvnRm_item" :key="forceRender"
                                            @change="bnfcrBedFeeChanger()"
                                            item-text="valcdnm" item-value="valcd"
                                            dense hide-details outlined>
                                        </v-select>
                                    </span>
                                </span>
                                <span class="d-inline-block pl-2 py-1" style="width: 50%;">
                                    <span class="d-inline-block align-center" style="width:80%; margin: auto;">
                                        <v-text-field
                                            v-model="bnfcrCntrc.upBedFee" @blur="onCalcSum()" :disabled="true"
                                            :rules="bnfcrCntrc.npymntFeeStd == '1' ? [rules.numberCheck] : []"
                                            type="number" :key="forceRender" hide-details outlined dense>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block pl-2 py-1" style="width:20%;">원</span> 
                                </span>           
                           </div>
                        </v-col>
                        <v-col class="pa-0 ma-0 tableBL" cols="6" style="">
                            <div class="d-flex justify-left">
                                <span class="d-inline-block px-2 tableBR" style="width: 35%;">
                                    <v-select
                                        class="wrkSelect" color="white" height="28"
                                        style="padding-top: 5px;"
                                        v-model="indvUpBedFee"
                                        :items="lvnRm_item" :key="forceRender"
                                        @change="indvBedFeeChanger()"
                                        item-text="valcdnm" item-value="valcd"
                                        dense hide-details outlined>
                                    </v-select>
                                </span>
                                <span class="d-inline-block align-center pl-2" style="width: 55%; margin: auto;">
                                    <v-text-field v-model="indvCntrc.upBedFee" :rules="[rules.numberCheck]" @blur="onCalcSum()"
                                        type="number" :key="forceRender" hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block pl-2 py-2" style="width: 10%;">원</span>
                           </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="3">
                    <span class="black--text font-weight-medium">식사&nbsp;재료비</span>
                </v-col>    
                <v-col class="pa-0 ma-0 tableBL" cols="9">
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="pa-0 ma-0" cols="6" >
                            <v-radio-group
                                class="pl-2" ref="info"
                                v-model="bnfcrCntrc.mealCalcCd" :key="forceRender"
                                hide-details row>
                                <span style="width: 50%;">
                                    <v-radio
                                        class="pa-0 ma-0 mr-2 py-2" value="1"
                                        :ripple="false" label="식사 재료비로 계산"
                                        @click="onCalcSum">
                                    </v-radio>
                                </span>
                                <span style="width: 50%;">
                                    <v-radio
                                        class="pa-0 ma-0 mr-2 py-2" value="2"
                                        :ripple="false" label="경관 유동식으로 계산"
                                        @click="onCalcSum">
                                    </v-radio>
                                </span>
                            </v-radio-group>
                        </v-col>
                    </v-row>           
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mt-4" v-if="bnfcrCntrc.acknwRtng == 99" no-gutters>
                <v-col class="d-flex align-center" cols="12">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">등급외&nbsp;본인부담금</span>
                </v-col>
            </v-row>
            <v-divider class="mt-2 mr-1" v-if="bnfcrCntrc.acknwRtng == 99"></v-divider>
            <v-row class="pa-0 mr-1" v-if="bnfcrCntrc.acknwRtng == 99" no-gutters>
                <v-col class="greyE01 d-flex justify-center py-2" cols="3">
                    <span class="black--text font-weight-medium">등급외&nbsp;본인부담금</span>
                </v-col>
                <v-col class="d-flex align-center tableBL py-1" cols="9">
                    <span class="d-inline-block" style="width: 160px;">
                        <v-radio-group
                            class="pl-2"
                            v-model="bnfcrCntrc.rtngOtrClcd" :key="forceRender"
                            hide-details row>
                            <v-radio
                                class="pa-0 ma-0 mr-2" value="1"
                                :ripple="false" label="월별"
                                @click="onCalcSum">
                            </v-radio>
                            <v-radio
                                class="pa-0 ma-0 mr-2" value="2"
                                :ripple="false" label="일별"
                                @click="onCalcSum">
                            </v-radio>
                        </v-radio-group>
                    </span>
                    <span class="d-inline-block align-center" style="width: 30%;">
                        <v-text-field
                            type="number"
                            v-model="bnfcrCntrc.rtngOtrAmt"
                            :rules="[rules.numberCheck]" :key="forceRender"
                            @blur="onCalcSum()"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="pl-2">원</span>
                </v-col>
            </v-row>
            <v-divider class="mr-1" v-if="bnfcrCntrc.acknwRtng == 99"></v-divider>
        </v-form>
        <v-row class="pa-0 mt-4" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">세부내역&nbsp;&#40;30일&nbsp;기준&#41;</span>
            </v-col>
        </v-row>
        <v-divider class="mt-2 mr-1"></v-divider>
        <v-row class="pa-0 mr-1 greyE01" no-gutters>
            <v-col class="d-flex justify-center align-center ma-0 py-2" cols="4">
                <span class="black--text font-weight-medium">계약일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBL" cols="3">
                <span class="black--text font-weight-medium">금액&#40;원&#41;</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBL" cols="5">
                <span class="black--text font-weight-medium">내역</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center ma-0 py-2" cols="4">
                <span class="black--text font-weight-medium">총계</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBL" cols="3">
                <div v-if="bnfcrCntrc.acknwRtng == 99" class="" style="text-align: center;">
                    <div v-if="bnfcrCntrc.rtngOtrClcd == '2'">
                        {{ ( ( Number(feeSum[1]) + Number(paySum[0]) + Number(paySum[1]) + Number(paySum[2]) ) + (Number(bnfcrCntrc.rtngOtrAmt)*30) )?.toLocaleString('ko-KR') }}
                    </div>
                    <div v-else-if="bnfcrCntrc.rtngOtrClcd == '1'">
                        {{ ( ( Number(feeSum[1]) + Number(paySum[0]) + Number(paySum[1]) + Number(paySum[2]) ) + Number(bnfcrCntrc.rtngOtrAmt) )?.toLocaleString('ko-KR') }}
                    </div>
                </div>
                <div v-else class="" style="text-align: center;">
                    {{ ( Number(feeSum[0]) + ( Number(feeSum[1]) + Number(paySum[0]) + Number(paySum[1]) + Number(paySum[2]) ) )?.toLocaleString('ko-KR') }}
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBL" cols="5">
                <div v-if="bnfcrCntrc.acknwRtng == 99" class="" style="text-align: center;">
                    <span v-if="bnfcrCntrc.rtngOtrClcd == '2'">
                        {{ (Number(bnfcrCntrc.rtngOtrAmt)*30)?.toLocaleString('ko-KR') }}
                    </span>
                    <span v-if="bnfcrCntrc.rtngOtrClcd == '1'">
                        {{ Number(bnfcrCntrc.rtngOtrAmt)?.toLocaleString('ko-KR') }}
                    </span>
                    &nbsp;&nbsp;&#43;&nbsp;
                    {{( Number(feeSum[1]) + Number(paySum[1]) + Number(paySum[2]) + Number(paySum[0]) )?.toLocaleString('ko-KR')}}&nbsp;&nbsp;&#61;&nbsp;
                    <span v-if="bnfcrCntrc.rtngOtrClcd == '2'">
                        {{ ( (Number(bnfcrCntrc.rtngOtrAmt)*30) + Number(feeSum[1]) + Number(paySum[1]) + Number(paySum[2]) + Number(paySum[0]) )?.toLocaleString('ko-KR') }}
                    </span>
                    <span v-if="bnfcrCntrc.rtngOtrClcd == '1'">
                        {{ ( Number(bnfcrCntrc.rtngOtrAmt) + Number(feeSum[1]) + Number(paySum[1]) + Number(paySum[2]) + Number(paySum[0]) )?.toLocaleString('ko-KR') }}
                    </span>
                </div>
                <div v-else class="" style="text-align: center;">
                    {{ feeSum[0]?.toLocaleString('ko-KR') }}&nbsp;&nbsp;&#43;&nbsp;
                    {{( Number(feeSum[1]) + Number(paySum[1]) + Number(paySum[2]) + Number(paySum[0]) )?.toLocaleString('ko-KR')}}&nbsp;&nbsp;&#61;&nbsp;
                    {{ ( Number(feeSum[0]) + Number(feeSum[1]) + ( Number(paySum[1]) + Number(paySum[2]) + Number(paySum[0]) ) )?.toLocaleString('ko-KR') }}
                </div>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col class="greyE01 pa-0 ma-0 py-5" cols="2" style="border-right: 1px solid #dbdbdb !important;">
                <div class="black--text font-weight-medium" style="text-align: center;">요양보험</div>
                <div class="black--text font-weight-medium" style="text-align: center;">부담비용</div>
            </v-col>
            <v-col class="greyE01 pa-0 ma-0 py-2" cols="2">
                <div class="black--text font-weight-medium pb-2 tableBB" style="text-align: center;">소계</div>
                <div class="black--text font-weight-medium pt-3" style="text-align: center;">
                    요양급여비용&#40;{{100-bnfcrCntrc.copayRate}}%&#41;
                </div>
            </v-col>
            <v-col class="pa-0 ma-0 py-2 tableBL" cols="3">
                <div class="pb-2 tableBB" style="text-align: center;">{{ feeSum[0]?.toLocaleString('ko-KR') }}</div>
                <!-- 요양급여비용(등급) *(100-개인부담률)*30일 -->
                <div class="pt-2" style="text-align: center;">
                    {{ feeSum[0]?.toLocaleString('ko-KR')}}
                </div>
            </v-col>
            <v-col class="ma-0 py-2 tableBL" cols="5">
                <div class="pb-2 tableBB" style="text-align: center;">&nbsp;</div>
                <div class="pt-2 d-flex justify-center align-center">
                    {{ bnftFee?.toLocaleString('ko-KR') }}&nbsp;
                    &#40;{{bnfcrCntrc.acknwRtng == 99 ? '등급외' : bnfcrCntrc.acknwRtng+'등급'}}&#41;
                    <span class="mx-1">
                        <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                    </span>
                    <span class="mx-1" style="padding-top: 2px;">
                        {{(100-bnfcrCntrc.copayRate)}}&#37;
                    </span>
                    <span class="mx-1">
                        <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                    </span>
                    <span class="mx-1" style="padding-top: 2px;">
                        30일
                    </span>&#61;
                    <span class="mx-1" style="padding-top: 2px;">
                        {{feeSum[0]?.toLocaleString('ko-KR')}}
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mr-1" no-gutters>
            <v-col
                class="greyE01 ma-0 d-flex justify-center align-center"
                style="border-right: 1px solid #dbdbdb !important;" cols="2">
                <span class="black--text font-weight-medium">개인&nbsp;부담비용</span>
            </v-col>
            <v-col class="greyE01 ma-0 py-2" cols="2">
                <div class="black--text font-weight-medium pb-2 tableBB" style="text-align: center;">소계</div>
                <div class="black--text font-weight-medium py-2 tableBB" style="text-align: center;">요양급여비용&#40;{{bnfcrCntrc.copayRate}}%&#41;</div>
                <div class="black--text font-weight-medium pb-5 tableBB" style="text-align: center; padding-top: 19px !important;">식사&nbsp;재료비&#47;간식비</div>
                <div class="black--text font-weight-medium py-2 tableBB" style="text-align: center;">이&#47;미용료</div>
                <div
                    :class="['black--text font-weight-medium', bnfcrCntrc.acknwRtng == 99 ? 'py-2 tableBB' : 'pt-2']"
                    style="text-align: center;">
                    상급&nbsp;침실비
                </div>
                <div class="black--text font-weight-medium pt-2" style="text-align: center;" v-if="bnfcrCntrc.acknwRtng == 99">등급외&nbsp;본인부담금</div>
            </v-col>
            <v-col class="ma-0 py-2 tableBL" cols="3">
                <div class="pb-2 tableBB" style="text-align: center;">
                    <div class="" v-if="bnfcrCntrc.acknwRtng == 99" style="text-align:center;">
                        <span v-if="bnfcrCntrc.rtngOtrClcd == '2'">
                            {{ ( Number(feeSum[1]) + Number(paySum[0]) + Number(paySum[1]) + Number(paySum[2]) + (Number(bnfcrCntrc.rtngOtrAmt)*30) )?.toLocaleString('ko-KR') }}
                        </span>
                        <span v-else-if="bnfcrCntrc.rtngOtrClcd == '1'">
                            {{ ( Number(feeSum[1]) + Number(paySum[0]) + Number(paySum[1]) + Number(paySum[2]) + Number(bnfcrCntrc.rtngOtrAmt) )?.toLocaleString('ko-KR') }}
                        </span>
                    </div>
                    <div class="" v-else style="text-align:center;">
                        {{ ( Number(feeSum[1]) + Number(paySum[0]) + Number(paySum[1]) + Number(paySum[2]) )?.toLocaleString('ko-KR')}}
                    </div>
                </div>
                <!-- 요양급여비용(등급) * 개인부담률 * 30일 -->
                <div class="py-2 tableBB" style="text-align: center;">
                    {{ feeSum[1]?.toLocaleString('ko-KR') }}
                </div>
                <div class="tableBB pb-5" style="text-align: center; padding-top: 19px !important;">
                    {{ paySum[2]?.toLocaleString('ko-KR') }}
                </div>
                <div class="tableBB py-2" style="text-align: center;">
                    &nbsp;
                    {{bnfcrCntrc.npymntFeeStd == '2' ?  Number(indvCntrc.btyFee)?.toLocaleString('ko-KR'): Number(bnfcrCntrc.btyFee)?.toLocaleString('ko-KR') }}
                    &nbsp;
                </div>
                <div :class="['black--text', bnfcrCntrc.acknwRtng == 99 ? 'py-2 tableBB' : 'pt-2']" style="text-align:center;">
                    {{bnfcrCntrc.npymntFeeStd == '2' ?   Math.imul(indvCntrc.upBedFee, 30)?.toLocaleString('ko-KR') : Math.imul(bnfcrCntrc.upBedFee, 30)?.toLocaleString('ko-KR') }}
                </div>
                <div class="pt-2" v-if="bnfcrCntrc.acknwRtng == 99" style="text-align:center;">
                    <span v-if="bnfcrCntrc.rtngOtrClcd == '2'">{{ (Number(bnfcrCntrc.rtngOtrAmt)*30)?.toLocaleString('ko-KR') }}</span>
                    <span v-else-if="bnfcrCntrc.rtngOtrClcd == '1'">{{ Number(bnfcrCntrc.rtngOtrAmt)?.toLocaleString('ko-KR') }}</span>
                </div>
            </v-col>
            <v-col class="ma-0 py-2 tableBL" cols="5">
                <div class="pb-2 tableBB" style="text-align: center;">&nbsp;</div>
                <div class="py-2 tableBB" style="text-align: center;">
                    <div class="d-flex justify-center align-center">
                        {{ bnftFee?.toLocaleString('ko-KR') }}&nbsp;
                        &#40;{{bnfcrCntrc.acknwRtng == 99 ? '등급외' : bnfcrCntrc.acknwRtng+'등급'}}&#41;
                        <span class="mx-1">
                            <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                        </span>
                        <span class="mx-1">
                            {{bnfcrCntrc.copayRate}}&#37;
                        </span>
                        <span class="mx-1">
                            <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                        </span>
                        <span class="mx-1">
                            30일
                        </span>&#61;
                        <span class="mx-1">
                            {{feeSum[1]?.toLocaleString('ko-KR')}}
                        </span>
                    </div>
                </div>
                <div class="py-2" style="text-align: center;">
                    <div class="d-flex justify-center align-center">
                        <!-- <span v-if="bnfcrCntrc.npymntFeeStd == '1'">
                            {{bnfcrCntrc.mealCalcCd == 1 ? bnfcrCntrc.mealCost?.toLocaleString('ko-KR') : bnfcrCntrc.lqdFood?.toLocaleString('ko-KR') }}
                        </span>
                        <span v-else>
                            {{bnfcrCntrc.mealCalcCd == 1 ? Number(indvCntrc.mealCost)?.toLocaleString('ko-KR') : Number(indvCntrc.lqdFood)?.toLocaleString('ko-KR') }}
                        </span> -->
                        <span v-if="bnfcrCntrc.npymntFeeStd == '1'">
                            <span v-if="bnfcrCntrc.mealCalcCd == 1">
                                <span v-if="bnfcrCntrc.gvrnmMeal == '1'">0</span>
                                <span v-else>{{ bnfcrCntrc.mealCost?.toLocaleString('ko-KR') }}</span>
                            </span>
                            <span v-else>
                                <span v-if="bnfcrCntrc.gvrnmMeal == '1'">0</span>
                                <span v-else>{{ bnfcrCntrc.lqdFood?.toLocaleString('ko-KR') }}</span>
                            </span>
                        </span>
                        <span v-else>
                            <span v-if="bnfcrCntrc.mealCalcCd == 1">
                                {{ Number(indvCntrc.mealCost)?.toLocaleString('ko-KR') }}
                            </span>
                            <span v-else>
                                {{ Number(indvCntrc.lqdFood)?.toLocaleString('ko-KR') }}
                            </span>
                        </span>
                        &nbsp;&#40;
                        <span v-if="bnfcrCntrc.mealCalcCd == 1">
                            <span>식사재료비</span>
                            <span v-if="bnfcrCntrc.gvrnmMeal == '1'">,&nbsp;정부지원</span>
                        </span>
                        <span v-else-if="bnfcrCntrc.mealCalcCd == 2">
                            <span>경관유동식</span>
                            <span v-if="bnfcrCntrc.gvrnmMeal == '1'">,&nbsp;정부지원</span>
                        </span>
                        &#41;
                        <span class="mx-1">
                            <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                        </span>
                        <span v-if="bnfcrCntrc.npymntFeeStd == '1'">
                            <span class="mx-1">3식</span>
                            <span class="mx-1">
                                <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                            </span>
                            <span class="mx-1">30일</span>&#43;
                        </span>
                        <span v-else>
                            <span v-if="bnfcrCntrc.mealCalcCd == 2 && indvCntrc.lqdFoodClcd == '1'" class="mx-1">1개월 &#43;</span>
                            <span v-else class="mx-1">
                                <span class="mx-1">3식</span>
                                <span class="mx-1">
                                    <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                                </span>
                                <span class="mx-1">30일</span>&#43;
                            </span>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center">
                        <span v-if="bnfcrCntrc.npymntFeeStd == '1'">
                            <span v-if="bnfcrCntrc.gvrnmSnack == '1'">0</span>
                            <span v-else>{{ bnfcrCntrc.snackFee?.toLocaleString('ko-KR') }}</span>
                        </span>
                        <span v-else-if="bnfcrCntrc.npymntFeeStd == '2'">
                            <span v-if="bnfcrCntrc.gvrnmSnack == '1'">0</span>
                            <span v-else>{{ indvCntrc.snackFee?.toLocaleString('ko-KR') }}</span>
                        </span>
                        
                        <span>&nbsp;&#40;간식비</span>
                        <span v-if="bnfcrCntrc.gvrnmSnack == '1'">,&nbsp;정부지원</span>
                        <span>&#41;</span>

                        <span class="mx-1">
                            <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                        </span>
                        <span class="mx-1" v-if="bnfcrCntrc.npymntFeeStd == '1'">
                            {{ typeof bnfcrCntrc.snackCntCd == 'undefined' ? '2회' : bnfcrCntrc.snackCntCd + '회' }}
                        </span>
                        <span class="mx-1" v-else>
                            {{ indvCntrc.snackCntCd == '0' ? '0회' : indvCntrc.snackCntCd + '회' }}
                        </span>
                        <span class="mx-1" v-if="bnfcrCntrc.snackOfrClcd == '2'">
                            <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                        </span>
                        <span class="mx-1" v-if="bnfcrCntrc.snackOfrClcd != '1'">30일</span>
                        &nbsp;&#61;
                        {{paySum[2]?.toLocaleString('ko-KR')}}
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="py-2" style="text-align: center;">&nbsp;</div>
                <v-divider></v-divider>
                <div :class="['black--text', bnfcrCntrc.acknwRtng == 99 ? 'py-2' : 'pt-2']" style="text-align: center;">
                    <span>
                        {{bnfcrCntrc.npymntFeeStd == '2' ?  Number(indvCntrc.upBedFee)?.toLocaleString('ko-KR') : bnfcrCntrc.upBedFee?.toLocaleString('ko-KR') }}
                    </span>
                    <span>&#40;상급침실비&#41;</span>
                    <span class="mx-1">
                        <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                    </span>
                    <span>30일</span>
                    <span class="mx-1">&#61;</span>
                    <span>{{Number(paySum[1])?.toLocaleString('ko-KR')}}</span>
                </div>
                <v-divider v-if="bnfcrCntrc.acknwRtng == 99"></v-divider>
                <div class="pt-2" v-if="bnfcrCntrc.acknwRtng == 99" style="text-align: center;">
                    <div v-if="bnfcrCntrc.rtngOtrClcd == '2'">
                        <span>{{ Number(bnfcrCntrc.rtngOtrAmt)?.toLocaleString('ko-KR') }}</span>
                        <span class="mx-1">
                            <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                        </span>
                        <span>30일</span>
                        <span class="mx-1">&#61;</span>
                        <span>{{ (Number(bnfcrCntrc.rtngOtrAmt)*30)?.toLocaleString('ko-KR') }}</span>
                    </div>
                    <div v-else-if="bnfcrCntrc.rtngOtrClcd == '1'">
                        <span>{{ Number(bnfcrCntrc.rtngOtrAmt)?.toLocaleString('ko-KR') }}</span>
                        <span class="mx-1">
                            <v-icon color="black font-weight-medium" small>mdi-close</v-icon>
                        </span>
                        <span>1개월</span>
                        <span class="mx-1">&#61;</span>
                        <span>{{ Number(bnfcrCntrc.rtngOtrAmt)?.toLocaleString('ko-KR') }}</span>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <!-- <div>{{ bnfcrCntrc }}</div> -->
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute centered top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
    </v-sheet>
</template>

<script>
import { setBnfcrCntrc } from '../../api/bnfcr.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

    export default {
        name: 'BnfcrMgmtCntInfPymnt',
            
        components: {
            CmmDateComp,
        },

        props : {
            prtBnfcrCntrc: Object,
            prtLvnRmList: Array,
        },

        created: function(){
           
        },
    
        mounted: function(){
            this.$nextTick(function () {
                this.$refs.form.resetValidation()

                if(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnftFeeList.length > 0){
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnftFeeList.forEach(list => {
                        if(this.prtBnfcrCntrc.acknwRtng == list.acknwRtng)
                            this.bnftFee = list.rtgFee
                    });
                }
 
            })  
        },

        computed: {
            getNbnftFeeInf: function(){
                return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf
            },
            getBnftFeeInfo: function(){
                return this.$parent.$parent.bnftFeeInfo
            },
        },

        watch: {
            'prtBnfcrCntrc.bnfcrCntrcInfPk': function(){
                this.clearFilds()
                this.bnfcrCntrc = Object.assign({}, this.prtBnfcrCntrc)

                //신규 계약
                if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0) this.onBnfcrCntrcNew()
                else{       //기존 계약
                    if(this.bnfcrCntrc.npymntFeeStd == '1') this.forBnfcrCntrcExtF()
                    else if(this.bnfcrCntrc.npymntFeeStd == '2') this.forBnfcrCntrcExt()
                }

                this.chkLoad += 1
            },
            'bnfcrCntrc.mealCalcCd': function(){
                if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0 && this.bnfcrCntrc.mealCalcCd == '1')
                    this.bnfcrCntrc.snackFee = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee
                else if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0 && this.bnfcrCntrc.mealCalcCd == '2')
                    this.bnfcrCntrc.snackFee = 0
            },
            'getNbnftFeeInf': function(fInf){
                if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0){
                    this.bnfcrCntrc.mealCost = fInf.mealCost
                    this.bnfcrCntrc.lqdFood = fInf.lqdFoodFee
                    this.bnfcrCntrc.snackOfrClcd = fInf.snackOfrClcd
                    this.bnfcrCntrc.lqdFoodClcd = fInf.lqdFoodClcd
                    this.bnfcrCntrc.btyFee = fInf.btyFee
                    this.bnfcrCntrc.mealCostLmt = fInf.mealCostLmt
                    this.bnfcrCntrc.snackFee = fInf.snackFee

                    if(fInf.snackOfrClcd == '1')
                        this.bnfcrCntrc.snackCntCd = '1'
                    else
                        this.bnfcrCntrc.snackCntCd = fInf.snackCntCd
                }
          
                this.chkLoad += 1
            },
            getBnftFeeInfo: function(v){
                this.bnftFee = v.bnftFee

                this.chkLoad += 1
            },
            'prtLvnRmList': function(){
                this.lvnRm_item.push({ valcdnm: '선택', valcd: '0', desc: 0 })
                this.prtLvnRmList.forEach((lvn, i) => {
                    this.lvnRm_item.push({ valcdnm: lvn.lvnRmTypNm, valcd: String(i+1), desc: lvn.useFee })
                });
            },
            'prtBnfcrCntrc': function(){
                this.bnfcrCntrc.gvrnmSnack = this.prtBnfcrCntrc.gvrnmSnack
                this.bnfcrCntrc.gvrnmMeal = this.prtBnfcrCntrc.gvrnmMeal
                this.bnfcrCntrc.rtngOtrAmt = this.prtBnfcrCntrc.rtngOtrAmt
                this.bnfcrCntrc.rtngOtrClcd = this.prtBnfcrCntrc.rtngOtrClcd

                this.chkLoad += 1
            },
            'prtBnfcrCntrc.acknwRtng': function(){
                if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0)
                    this.bnfcrCntrc.acknwRtng = this.prtBnfcrCntrc.acknwRtng

                this.chkLoad += 1
            },
            'prtBnfcrCntrc.copayRate': function(){
                if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0)
                    this.bnfcrCntrc.copayRate = this.prtBnfcrCntrc.copayRate

                this.chkLoad += 1
            },
            'indvCntrc.snackOfrClcd': function(){
                if(this.indvCntrc.snackOfrClcd == '1')
                    this.indvCntrc.snackCntCd = '1'
            },
            'chkLoad': function(){
                this.onCalcSum()
            },
            'bnfcrCntrc.acknwRtng': function(){
                if(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnftFeeList.length > 0){
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.bnftFeeList.forEach(list => {
                        if(this.bnfcrCntrc.acknwRtng == list.acknwRtng)
                            this.bnftFee = list.rtgFee
                    });
                }

                this.chkLoad += 1
            },
            'bnfcrCntrc.cntrcPrdEnd': function(){
                this.rflCntrcDt()
            },
        },
        
        methods: {
            setCntrcList: function (obj) {
                this.$parent.$parent.loader = true
                //let setTer = Object.assign({}, this.bnfcrCntrc)
                setBnfcrCntrc(obj)
                    .then((response) => ( this.insCntrcAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/insBnfcrCntrc : ' + error))
            },

            insCntrcAfter: function (res) {
                //this.bnfcrCntrc =  Object.assign({}, this.dummyBnfcrCntrc)
                if(res.statusCode == 200){
                    this.$parent.$parent.getCntrcList(this.$store.getters.getBnfcrState)
                    this.$store.commit('setTmpObj', {})
                    //console.log(this.$store.getters.getTmpObj)
                    this.snackControll([true, 2500, res.message, 'info'])
                }
                else
                    this.snackControll([true, 5000, res.message, 'error'])
            },

            snackControll: function (options) {
                this.snackOptions = options
                //this.snackOptions[0] = true
                //this.snackOptions[1] = 2500
                //this.snackOptions[2] = '신규 작성 모드'
                //info, success, warning, error
                //this.snackOptions[3] = 'info'
            },

            bnfcrBedFeeChanger: function(){
                let idx = this.lvnRm_item.findIndex( v => v.valcd == this.bnfcrUpBedFee )

                if( idx != -1 )
                    this.bnfcrCntrc.upBedFee = this.lvnRm_item[idx].desc

                this.onCalcSum()
            },

            indvBedFeeChanger: function(){
                let idx = this.lvnRm_item.findIndex( v => v.valcd == this.indvUpBedFee )

                if( idx != -1 )
                    this.indvCntrc.upBedFee = this.lvnRm_item[idx].desc

                this.onCalcSum()
            },

            calcYear: function () {
                let trm = Number(this.selectYear)
                let rst = ''

                if(trm != '0')
                    rst = this.$moment(this.bnfcrCntrc.cntrcPrdStrt, 'YYYY-MM-DD', true)
                        .add(trm, 'years').subtract(1, 'days').format('YYYY-MM-DD')
            
                this.bnfcrCntrc.cntrcPrdEnd = rst
            },

            clearFilds: function () {
                this.bnfcrCntrc = Object.assign({}, this.dummyBnfcrCntrc)
                this.selectYear = '0'
                this.bnfcrUpBedFee = '0'
                this.bnfcrCntrc.upBedFee = 0
                this.indvUpBedFee = '0'
                this.indvCntrc.upBedFee = 0
                this.indvCntrc.lqdFoodClcd = '0'
                this.indvCntrc.snackCntCd = '0'
                this.indvCntrc.snackOfrClcd = '0'
                this.paySum = [0, 0, 0]
                this.feeSum = [0, 0, 0]
                this.forceRender += 1
                this.chkLoad = 0
                this.$refs.form.resetValidation()
            },

            preSave: function () {
                if(!this.$refs.form.validate()){
                    this.snackControll([true, 2500, '필수 항목을 입력해 주세요.', 'warning'])
                    return 0
                }

                let obj = Object.assign({}, this.bnfcrCntrc)
                
                //공통 값 할당
                obj.cntrcDt = obj.cntrcDt?.afterDateFormatHyp()
                obj.cntrcPrdStrt = obj.cntrcPrdStrt?.afterDateFormatHyp()
                obj.cntrcPrdEnd = obj.cntrcPrdEnd?.afterDateFormatHyp()
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.mfdRsn = this.$store.getters.getBnfcrSaveArea
                obj.hisChc = this.$store.getters.getBnfcrSaveChc
                obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
                obj.userNm = this.$store.getters.getUserNm
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                    if(obj.copayRate == cd.valdesc)
                        obj.copayRate = cd.valcd
                });
                obj.gvrnmSprt = []
                if(obj.gvrnmSnack == '1')
                    obj.gvrnmSprt.push('1')
                if(obj.gvrnmMeal == '1')
                    obj.gvrnmSprt.push('2')

                //시설 비급여 수가 저장
                if(obj.npymntFeeStd == '1'){

                    obj.mealCost = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCost
                    obj.lqdFoodClcd = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodClcd
                    obj.lqdFood = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodFee
                    obj.snackOfrClcd = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackOfrClcd
                    obj.mealCostLmtYn = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCostLmtYn
                    obj.mealCostLmt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCostLmt
                    obj.btyFee = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.btyFee
                    obj.upBedClcd = this.bnfcrUpBedFee
                    obj.snackCntCd = Number(obj.snackCntCd)
                    // if(obj.snackOfrClcd == '1')
                    //     obj.snackCntCd = 2
                    // else if(obj.snackOfrClcd == '2')
                    //     obj.snackCntCd = 1

                    //console.log(obj)
                    this.setCntrcList(obj)
                }
                //개별 비급여 수가 저장
                else if(obj.npymntFeeStd == '2'){
                    let succ = this.$refs.form.validate()
                    if(succ){
                        obj.mealCost = this.indvCntrc.mealCost
                        obj.mealCostLmtYn = this.indvCntrc.mealCostLmtYn
                        obj.mealCostLmt = this.indvCntrc.mealCostLmt
                        if(this.indvCntrc.lqdFoodClcd == '0')
                            obj.lqdFoodClcd = null
                        else
                            obj.lqdFoodClcd = this.indvCntrc.lqdFoodClcd

                        obj.lqdFood = this.indvCntrc.lqdFood
                        obj.pymntMthdCd = this.indvCntrc.pymntMthdCd
                        obj.snackFee = this.indvCntrc.snackFee
                        obj.snackCntCd =  Number(this.indvCntrc.snackCntCd)
                        obj.snackOfrClcd = Number(this.indvCntrc.snackOfrClcd)

                        obj.btyFee = this.indvCntrc.btyFee
                        obj.upBedFee = this.indvCntrc.upBedFee
                        obj.upBedClcd = this.indvUpBedFee

                        this.forceRender += 1
                        //console.log(obj)
                        this.setCntrcList(obj)
                    }   //validation Success
                }   //개별 비급여 수가
            },

            datePickClose: function (value) {
                this.menu.splice(value, 1, false)
            },

            onBnfcrCntrcNew: function () {
                //신규 계약
                if( typeof this.bnfcrCntrc.npymntFeeStd == "undefined" || this.bnfcrCntrc.npymntFeeStd == '' )
                    this.bnfcrCntrc.npymntFeeStd = '1'

                this.bnfcrCntrc.mealCost = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCost
                this.bnfcrCntrc.lqdFoodClcd = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodClcd
                this.bnfcrCntrc.lqdFood = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodFee
                this.bnfcrCntrc.snackOfrClcd = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackOfrClcd
                this.bnfcrCntrc.mealCostLmtYn = '2'
                this.bnfcrCntrc.mealCostLmt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCostLmt
                if( typeof this.bnfcrCntrc.mealCalcCd == "undefined"  || this.bnfcrCntrc.mealCalcCd == '')
                    this.bnfcrCntrc.mealCalcCd = '1'
                this.bnfcrCntrc.btyFee = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.btyFee
                this.bnfcrCntrc.upBedFee = 0

                if(this.bnfcrCntrc.mealCalcCd == '1')
                    this.bnfcrCntrc.snackFee = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee
                else if(this.bnfcrCntrc.mealCalcCd == '2')
                    this.bnfcrCntrc.snackFee = 0
        
                if(this.$parent.$parent.tmpIndvCntrc.chc == '1' && this.$parent.$parent.tmpIndvCntrc.npymntFeeStd == '1'){
                    let indvTmp = Object.assign({}, this.$parent.$parent.tmpIndvCntrc)
                 
                    this.bnfcrCntrc.pymntDt = indvTmp.pymntDt
                    this.bnfcrCntrc.mealCalcCd = indvTmp.mealCalcCd
                    this.bnfcrCntrc.cntrcDt = this.$moment(indvTmp.cntrcPrdEnd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                    this.bnfcrCntrc.cntrcPrdStrt = this.$moment(indvTmp.cntrcPrdEnd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                    //this.cntrcMinDt = this.$moment(indvTmp.cntrcDt, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                }
                else if(this.$parent.$parent.tmpIndvCntrc.chc == '1' && this.$parent.$parent.tmpIndvCntrc.npymntFeeStd == '2'){
                    this.indvCntrc = Object.assign({}, this.$parent.$parent.tmpIndvCntrc)
                
                    this.indvUpBedFee = this.indvCntrc.indvUpBedFee
                    this.bnfcrCntrc.pymntDt = this.indvCntrc.pymntDt
                    this.bnfcrCntrc.mealCalcCd = this.indvCntrc.mealCalcCd
                    this.bnfcrCntrc.cntrcDt = this.$moment(this.indvCntrc.cntrcPrdEnd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                    this.bnfcrCntrc.cntrcPrdStrt = this.$moment(this.indvCntrc.cntrcPrdEnd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                    //this.cntrcMinDt = this.$moment(this.indvCntrc.cntrcDt, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')

                    this.$parent.$parent.tmpIndvCntrc = Object.assign({})
                }
                else{
                    this.indvCntrc.mealCost = 0
                    this.indvCntrc.lqdFood = 0
                    this.indvCntrc.mealCostLmtYn = '2'
                    this.indvCntrc.mealCostLmt = 0
                    this.indvCntrc.btyFee = 0
                    this.indvCntrc.upBedFee = 0
                    this.cntrcMinDt = String(this.prtBnfcrCntrc.cntrcDt)
                    this.bnfcrCntrc.cntrcDt = this.prtBnfcrCntrc.cntrcDt
                }
            },

            onBnfcrCntrcExt: function () {
                //기준 계약 개별 비급여 저장
                this.bnfcrCntrc.mealCost = this.indvCntrc.mealCost
                this.bnfcrCntrc.lqdFood = this.indvCntrc.lqdFood
                this.bnfcrCntrc.snackFee = this.indvCntrc.snackFee
                this.bnfcrCntrc.mealCostLmtYn = this.indvCntrc.mealCostLmtYn
                this.bnfcrCntrc.mealCostLmt = this.indvCntrc.mealCostLmt
                this.bnfcrCntrc.btyFee = this.indvCntrc.btyFee
            },

            forBnfcrCntrcExt: function () {
                //개별 비급여 수가
                this.indvCntrc.mealCost = this.bnfcrCntrc.mealCost
                this.indvCntrc.lqdFood = this.bnfcrCntrc.lqdFood
                if(this.bnfcrCntrc.lqdFoodClcd == null)
                    this.indvCntrc.lqdFoodClcd = '0'
                else
                    this.indvCntrc.lqdFoodClcd = this.bnfcrCntrc.lqdFoodClcd
                this.indvCntrc.snackFee = this.bnfcrCntrc.snackFee
                this.indvCntrc.snackOfrClcd = this.bnfcrCntrc.snackOfrClcd
                this.indvCntrc.snackCntCd = this.bnfcrCntrc.snackCntCd
                this.indvCntrc.mealCostLmtYn =  this.bnfcrCntrc.mealCostLmtYn
                this.indvCntrc.mealCostLmt = this.bnfcrCntrc.mealCostLmt
                this.indvCntrc.btyFee =  this.bnfcrCntrc.btyFee
                this.indvUpBedFee =  this.bnfcrCntrc.upBedClcd
                this.indvCntrc.upBedFee =  this.bnfcrCntrc.upBedFee
                
                this.bnfcrCntrc.mealCost = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCost
                this.bnfcrCntrc.lqdFood = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodFee
                this.bnfcrCntrc.lqdFoodClcd = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodClcd
                this.bnfcrCntrc.snackFee = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee
                this.bnfcrCntrc.snackOfrClcd = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackOfrClcd

                if(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCostLmt == 0)
                    this.bnfcrCntrc.mealCostLmtYn = '1'
                else
                    this.bnfcrCntrc.mealCostLmtYn = '2'

                this.bnfcrCntrc.mealCostLmt = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCostLmt
                this.bnfcrCntrc.upBedFee = 0
                this.bnfcrCntrc.btyFee = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.btyFee

                this.forceRender += 1

                this.onCalcSum()
            },

            forBnfcrCntrcExtF: function () {
                //시설 비급여 수가
                this.indvCntrc.mealCost = 0
                this.indvCntrc.lqdFood = 0
                this.indvCntrc.snackFee = 0
                this.indvCntrc.mealCostLmtYn = '2'
                this.indvCntrc.mealCostLmt = 0
                this.indvCntrc.btyFee = 0
                this.indvCntrc.upBedFee = 0
                if(this.bnfcrCntrc.upBedClcd != null)
                    this.bnfcrUpBedFee = this.bnfcrCntrc.upBedClcd

                this.onCalcSum()
            },

            onCalcSum: function () {
                this.bnfcrCntrc.bnfcrCntrcInfPk = Number(this.bnfcrCntrc.bnfcrCntrcInfPk)

                //요양보험 급여비용
                this.feeSum[0] = Math.round((this.bnftFee * ( 1 - (this.bnfcrCntrc.copayRate/100) ) * 30)/10) * 10
                //개인부담 급여비용
                this.feeSum[1] = Math.round((this.bnftFee * (this.bnfcrCntrc.copayRate/100) * 30)/10) * 10

                if(this.bnfcrCntrc.mealCalcCd == '1')
                    this.feeSum[2] = ( ( this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCost * 3 ) + ( this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee * 2 ) ) * 30
                else if(this.bnfcrCntrc.mealCalcCd == '2')
                    this.feeSum[2] = ( ( this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodFee * 3 ) + ( this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee * 2 ) ) * 30

                if(this.feeSum[2] > this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCostLmt) this.feeSum[2] = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCostLmt
                
                //기존 계약
                if(this.bnfcrCntrc.bnfcrCntrcInfPk != 0){
                    //시설 비급여 수가
                    if(this.bnfcrCntrc.npymntFeeStd == '1'){

                        this.paySum[0] = this.bnfcrCntrc.btyFee
                        this.paySum[1] = this.bnfcrCntrc.upBedFee * 30
                        this.paySum.splice(2, 1, 0)
                        //식사재료비, 경관유동식 계산
                        if(this.bnfcrCntrc.mealCalcCd == '1'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else
                                this.paySum[2] += ( this.bnfcrCntrc.mealCost * 3 ) * 30
                        } 
                        else if(this.bnfcrCntrc.mealCalcCd == '2'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else{
                                if(this.bnfcrCntrc.lqdFoodClcd == '1')
                                    this.paySum[2] += this.bnfcrCntrc.lqdFood
                                else
                                    this.paySum[2] += ( this.bnfcrCntrc.lqdFood * 3 ) * 30
                            }
                        }
                        //간식비 계산
                        if(this.bnfcrCntrc.gvrnmSnack == '1')
                            this.paySum[2] += 0
                        else if(this.bnfcrCntrc.gvrnmSnack == '2')
                            this.paySum[2] += ( this.bnfcrCntrc.snackFee * Number(this.bnfcrCntrc.snackCntCd) ) * 30

                        //월 한도액 체크
                        if(this.bnfcrCntrc.mealCostLmtYn == '1'){
                            if(this.paySum[2] > this.bnfcrCntrc.mealCostLmt) this.paySum[2] = Number(this.bnfcrCntrc.mealCostLmt)
                        }
                    }
                    //개별 비급여 수가
                    else if(this.bnfcrCntrc.npymntFeeStd == '2'){
                        this.paySum[0] = this.indvCntrc.btyFee
                        this.paySum[1] = this.indvCntrc.upBedFee * 30
                        this.paySum.splice(2, 1, 0)

                        let snackCnt = Number(this.indvCntrc.snackCntCd)
                        //식사재료비, 경관유동식 계산
                        if(this.bnfcrCntrc.mealCalcCd == '1'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else
                                this.paySum[2] += ( this.indvCntrc.mealCost * 3 ) * 30
                        }
                        else if(this.bnfcrCntrc.mealCalcCd == '2'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else{
                                if(this.indvCntrc.lqdFoodClcd == '1')
                                    this.paySum[2] += Number(this.indvCntrc.lqdFood)
                                else
                                    this.paySum[2] += ( Number(this.indvCntrc.lqdFood) * 3 ) * 30
                            }
                        }

                        //간식비 계산
                        if(this.bnfcrCntrc.gvrnmSnack == '1')
                            this.paySum[2] += 0
                        else if(this.bnfcrCntrc.gvrnmSnack == '2')
                            this.paySum[2] += ( this.indvCntrc.snackFee * snackCnt ) * 30

                        //월 한도액 체크
                        if(this.indvCntrc.mealCostLmtYn == '1'){
                            if(this.paySum[2] > this.indvCntrc.mealCostLmt) this.paySum[2] = Number(this.indvCntrc.mealCostLmt)
                        }
                    }
                }
                //신규 계약
                else if(this.bnfcrCntrc.bnfcrCntrcInfPk == 0){
                    //시설 비급여 수가
                    if(this.bnfcrCntrc.npymntFeeStd == '1'){
                        this.paySum[0] = this.bnfcrCntrc.btyFee
                        this.paySum[1] = this.bnfcrCntrc.upBedFee * 30
                        this.paySum.splice(2, 1, 0)

                        if(this.bnfcrCntrc.mealCalcCd == '1'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else
                                this.paySum[2] += ( this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.mealCost * 3 ) * 30
                        }
                        else if(this.bnfcrCntrc.mealCalcCd == '2'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else{
                                if(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodClcd == '1')
                                    this.paySum[2] += this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodFee
                                else
                                    this.paySum[2] += ( this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.lqdFoodFee * 3 ) * 30  
                            }
                        }

                        if(this.bnfcrCntrc.gvrnmSnack == '1')
                            this.paySum[2] += 0
                        else if(this.bnfcrCntrc.gvrnmSnack == '2'){
                            if(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackOfrClcd == '1')
                                this.paySum[2] += this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee
                            else if(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackOfrClcd == '2')
                                this.paySum[2] += ( this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.feeInf.snackFee * Number( this.bnfcrCntrc.snackCntCd ) ) * 30
                        }

                        //
                        // 1식( 간식 )
                        // if(this.bnfcrCntrc.snackOfrClcd == 1){
                        //     //식사재료비, 간식비
                        //     if(this.bnfcrCntrc.mealCalcCd == '1')       //식사재료비
                        //         this.paySum[2] = ( ( this.prtFeeInf.mealCost * 3 ) + ( this.prtFeeInf.snackFee * 2 ) ) * 30
                        //     else if(this.bnfcrCntrc.mealCalcCd == '2')  //경관유동식
                        //         this.paySum[2] = ( this.prtFeeInf.lqdFoodFee * 3 ) * 30
                        // }
                        // 1개월( 간식 )
                        // else if(this.bnfcrCntrc.snackOfrClcd == 2){
                        //     //식사재료비, 간식비
                        //     if(this.bnfcrCntrc.mealCalcCd == '1')       //식사재료비
                        //         this.paySum[2] = ( this.prtFeeInf.mealCost * 3 ) * 30 + this.prtFeeInf.snackFee
                        //     else if(this.bnfcrCntrc.mealCalcCd == '2')  //경관유동식
                        //         this.paySum[2] = ( this.prtFeeInf.lqdFoodFee * 3 ) * 30
                        // }

                        //월 한도액 체크
                        if(this.bnfcrCntrc.mealCostLmtYn == '1'){
                            if(this.paySum[2] > this.bnfcrCntrc.mealCostLmt) this.paySum[2] = Number(this.bnfcrCntrc.mealCostLmt)
                         }
                    }
                    //개별 비급여 수가
                    else if(this.bnfcrCntrc.npymntFeeStd == '2'){
                        if( this.indvCntrc.mealCost == null) this.indvCntrc.mealCost = 0
                        this.paySum[0] = this.indvCntrc.btyFee
                        this.paySum[1] = this.indvCntrc.upBedFee * 30
                        this.paySum.splice(2, 1, 0)
                        let snackCnt = Number(this.indvCntrc.snackCntCd)

                        if(this.bnfcrCntrc.mealCalcCd == '1'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else
                                this.paySum[2] += ( this.indvCntrc.mealCost * 3 ) * 30
                        
                            if(this.bnfcrCntrc.gvrnmSnack == '1')
                                this.paySum[2] += 0
                            else if(this.bnfcrCntrc.gvrnmSnack == '2')
                                this.paySum[2] += ( this.indvCntrc.snackFee * snackCnt ) * 30
                        }

                        else if(this.bnfcrCntrc.mealCalcCd == '2'){
                            if(this.bnfcrCntrc.gvrnmMeal == '1')
                                this.paySum[2] += 0
                            else{
                                if(this.indvCntrc.lqdFoodClcd == '1')
                                    this.paySum[2] += Number(this.indvCntrc.lqdFood)
                                else
                                    this.paySum[2] += ( Number(this.indvCntrc.lqdFood) * 3 ) * 30  
                            }

                            if(this.bnfcrCntrc.gvrnmSnack == '1')
                                this.paySum[2] += 0
                            else if(this.bnfcrCntrc.gvrnmSnack == '2')
                                this.paySum[2] += ( this.indvCntrc.snackFee * snackCnt ) * 30
                        }

                        //월 한도액 체크
                        if(this.indvCntrc.mealCostLmtYn == '1'){
                            if(this.paySum[2] > Number(this.indvCntrc.mealCostLmt)) this.paySum[2] = Number(this.indvCntrc.mealCostLmt)
                         }
                    }

                    //월 한도액 체크
                    //if(this.paySum[2] > this.feeInf.mealCostLmt) this.paySum[2] = this.feeInf.mealCostLmt

                }
          
                this.forceRender += 1
            },

            hisCntInfPaymntChanger: function (obj) {
                let tmp = obj
                tmp.cntntJson.bnfcrCntrc.bnfcrCntrcInfPk = tmp.bnfcrCntrcInfPk
                tmp.cntntJson.bnfcrCntrc.cntrcDt = tmp.cntntJson.bnfcrCntrc.cntrcDt?.beforeDateFormatHyp()
                tmp.cntntJson.bnfcrCntrc.cntrcPrdStrt = tmp.cntntJson.bnfcrCntrc.cntrcPrdStrt?.beforeDateFormatHyp()
                tmp.cntntJson.bnfcrCntrc.cntrcPrdEnd = tmp.cntntJson.bnfcrCntrc.cntrcPrdEnd?.beforeDateFormatHyp()

                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                    if(tmp.cntntJson.bnfcrCntrc.copayRate == cd.valcd){
                        tmp.cntntJson.bnfcrCntrc.copayRate = Number(cd.valdesc)
                        tmp.cntntJson.bnfcrCntrc.copayCd = cd.valcd
                    }
                });
       
                if( tmp.cntntJson.bnfcrCntrc.gvrnmSprt?.findIndex( v => v == '1' ) != -1 )
                    tmp.cntntJson.bnfcrCntrc.gvrnmSnack = '1'
                else
                    tmp.cntntJson.bnfcrCntrc.gvrnmSnack = '2'
                if( tmp.cntntJson.bnfcrCntrc.gvrnmSprt?.findIndex( v => v == '2' ) != -1 )
                    tmp.cntntJson.bnfcrCntrc.gvrnmMeal = '1'
                else
                    tmp.cntntJson.bnfcrCntrc.gvrnmMeal = '2'

                this.$parent.$parent.bnfcrCntrc = tmp.cntntJson.bnfcrCntrc
              
                //this.$store.commit('setTmpObj', {})
            },

            rflCntrcDt: function () {
                if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.bnfcrCntrc.cntrcPrdEnd)){
                    if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                        if(this.$store.state.bnfcrChgClmn.orgCntrcPrdEnd == this.bnfcrCntrc.cntrcPrdEnd)
                            this.$store.state.bnfcrChgClmn.cfmSave.splice(0, 1, '2')
                        else
                            this.$store.state.bnfcrChgClmn.cfmSave.splice(0, 1, '1')
                        
                        this.$store.state.bnfcrChgClmn.chgCntrcPrdEnd = this.bnfcrCntrc.cntrcPrdEnd
                    }
                }
            },

            copayRflData: function () {
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                    if(this.bnfcrCntrc.copayCd == cd.valcd)
                        this.bnfcrCntrc.copayRate = Number(cd.valdesc)
                });

                this.onCalcSum()
            },
            acknwRtngRflData: function () {
                if(this.bnfcrCntrc.acknwRtng == 99)
                    this.bnfcrCntrc.rtngOtrAmt = 0

                this.chkLoad += 1
            },
        },
    
        data: () => ({
            forceRender: 0,
            cntrcMinDt: '',
            chkLoad: 0,
            bnftFee: 0,
            bnfcrCntrc: {
                bnMmbrPk: 0, snackCntCd: '0', sftNtcOfrDt: '', bnfcrCntrcInfPk: 0, btyFee: 0, snackOfrClcd: '0', eldHmnRcvNm: '', pymntDt: 0,
                eldHmnOfrDt: '', eldHmnRcvPstnCd: '', cntrcPrdEnd: '', prvcyAgrYn: '', sftNtcRcvNm: '', cntrcPrdStrt: '', sftNtcOfrYn: '', cntrcDt: '',
                prvcyAgrDt: '', sftNtcRcvPstnCd: '', lstYn: '', snackFee: 0, mealCostLmtYn: '2', lqdFood: 0, mfdRsn: '', mealCalcCd: '1', eldHmnOfrYn: '',
                lqdFoodClcd: '0', pymntMthdCd: 1, npymntFeeStd: '1', mealCost: 0, mealCostLmt: 0, fcltyNum: 0, bnfcrPk: 0, upBedFee: 0,
                rtngOtrAmt: 0, rtngOtrClcd: '1'
            },
            dummyBnfcrCntrc: {
                bnMmbrPk: 0, snackCntCd: '0', sftNtcOfrDt: '', bnfcrCntrcInfPk: 0, btyFee: 0, snackOfrClcd: '0', eldHmnRcvNm: '', pymntDt: 0,
                eldHmnOfrDt: '', eldHmnRcvPstnCd: '', cntrcPrdEnd: '', prvcyAgrYn: '', sftNtcRcvNm: '', cntrcPrdStrt: '', sftNtcOfrYn: '', cntrcDt: '',
                prvcyAgrDt: '', sftNtcRcvPstnCd: '', lstYn: '', snackFee: 0, mealCostLmtYn: '2', lqdFood: 0, mfdRsn: '', mealCalcCd: '1', eldHmnOfrYn: '',
                lqdFoodClcd: '0', pymntMthdCd: 1, npymntFeeStd: '', mealCost: 0, mealCostLmt: 0, fcltyNum: 0, bnfcrPk: 0, upBedFee: 0,
                rtngOtrAmt: 0, rtngOtrClcd: '1'
            },
            indvCntrc: { 
                mealCost: 0, lqdFoodClcd: '0', lqdFood: 0, snackCntCd: '0', snackFee: 0, mealCostLmtYn: '2', mealCostLmt: 0,
                snackOfrClcd: '0', btyFee: 0, pymntMthdCd: 1, upBedFee: 0
            },
            paySum: [0, 0, 0],     //미용료, 상급 침실비, 개별 비급여 식사간식비
            feeSum: [0, 0, 0],     //요양보험 급여비용, 개인부담 급여비용, 시설 비급여 식사간식비
            selectYear: '0',
            bnfcrUpBedFee: '0',
            indvUpBedFee: '0',
            year_term: [
                { valcdnm: '선택', valcd: '0' }, { valcdnm: '1년', valcd: '1' },
                { valcdnm: '2년', valcd: '2' }, { valcdnm: '3년', valcd: '3' },
            ],
            lqdFood_term: [ { valcdnm: '선택', valcd: '0' }, { valcdnm: '1개월', valcd: '1' }, { valcdnm: '1식', valcd: '2' } ],
            snackFee_term: [ { valcdnm: '선택', valcd: '0' }, { valcdnm: '1일', valcd: '1' }, { valcdnm: '1식', valcd: '2' }, ],
            cnt_term: [ { valcdnm: '선택', valcd: '0' }, { valcdnm: '1회', valcd: '1' }, { valcdnm: '2회', valcd: '2' }, ],
            lvnRm_item: [],
            menu: [false, false, false,],
            select: ['선택', '선택'],
            snackOptions: [false, 3000, '', 'info'],
            rules: {
                selectCheck: value => value?.length > 0 || 'Required.',
                required: value => !!value || 'Required.',
                selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
                strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
                numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
                numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            },
        }),
    };
    </script>
    <style>
        .v-autocomplete.v-select.v-input--is-focused input { min-width: 28px; }
    </style>