<template>
    <v-sheet class="pa-0 ma-0">
         <v-row class="mt-2" no-gutters>
            <v-col cols="12" class="d-flex align-center pa-0 pm-0">
                <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
                <exBtn class="ml-1" :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
                <exBtn class="ml-1" :btnData="exBtn[2]" @btnClosed="expandClose" ref="exBtnClose2" @changeCheck="filterSearchD3" />
                <span class="d-inline-block ml-2" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                        hide-details single-line rounded outlined dense filled label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>                
                <expandNowDatePicker class="pl-8" :prtDate="''" @nowDate="getDate"></expandNowDatePicker>
                <v-spacer></v-spacer>                
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-2" no-gutters>
            <v-col cols="12" class="pa-0 pm-0">
                <v-data-table
                    class="mt-2 stts" locale="ko" height="660" item-key="rn"
                    :search="schDummy" 
                    :custom-filter="customFilter" 
                    :headers="cntrcHeader" 
                    :items="cntrcList"
                    :loading="dataCall"
                    fixed-header disable-pagination hide-default-footer dense>

                    <template v-slot:header="props" >
                        <thead>
                            <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                        </thead>
                    </template>

                    <template v-slot:item='{ item }'>
                
                        <tr class="fixedCol">
                            <td class="fixedTable fixedPstn0 textCenter fontOneRem removeBB">
                                <span>{{item.rn}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="fixedTable fixedPstn1 textCenter fontOneRem removeBB">
                                <span>{{item.name}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="fixedTable fixedCntrcSttsPstn2 textCenter fontOneRem removeBB">
                                <span>{{item.entStcd}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.age}}</span>
                                <span class="d-inline-block" style="width:22px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                                <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                            </td> 
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.rsdntBirth}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.lvnRmNm}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>
                                    {{ item.acknwRtng == '99' ? '등급외' : item.acknwRtng+'등급' }}
                                </span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.copayRateNm}}</span>
                                <span class="d-inline-block" style="width:18px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.cntrcDt}}</span>
                                <span class="d-inline-block" style="width:16px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.cntrcPrdStrt}}&#126;{{item.cntrcPrdEnd}}</span>
                                <span class="d-inline-block" style="width:16px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.bnfcrSigYn}}</span>
                                <span class="d-inline-block" style="width:16px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.grdnSigYn}}</span>
                                <span class="d-inline-block" style="width:16px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.sndSttsCd}}</span>
                                <span class="d-inline-block" style="width:16px"></span>
                            </td>
                            <td class="textCenter fontOneRem removeBB">
                                <span>{{item.sndDt}}</span>
                                <span class="d-inline-block" style="width:16px"></span>
                            </td>
                            <td class="fixedTable fixedPstnR1 textCenter fontOneRem removeBB" >                                
                                <span>
                                    <v-btn
                                        color="grey006" height="26"
                                        @click="onCntrc(item.bnMmbrPk, item.name, item.bnfcrCntrcInfPk)"
                                        :disabled="item.bnfcrCntrcInfPk == null"
                                        icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="20">mdi-square-edit-outline </v-icon>
                                                </div>
                                            </template>
                                            <span>{{item.name}} 계약현황</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span> 
                                <span>
                                    <v-btn
                                        color="grey006" height="26"
                                        @click="$parent.$parent.$parent.$parent.sendMsgAct(item.bnMmbrPk, item.name, item.bnfcrCntrcInfPk)"
                                        :disabled="item.bnfcrCntrcInfPk == null"
                                        icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="20">mdi-arrow-expand-right</v-icon>
                                                </div>
                                            </template>
                                            <span>{{item.name}} 계약현황 전자발송</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>  
                                <span>
                                    <v-btn
                                        color="grey006" height="26"
                                        @click="$parent.$parent.$parent.$parent.sttrmCndtnPrt(item.bnMmbrPk, item.bnfcrCntrcInfPk)"
                                        :disabled="item.bnfcrCntrcInfPk == null"
                                        icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="20">mdi-printer-outline</v-icon>
                                                </div>
                                            </template>
                                            <span>{{item.name}} 계약현황 출력</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>   
                            </td>
                        </tr>
                    </template>
                    
                </v-data-table>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="1400">
                <BnfcrCntrcSttsMdl v-if="dialog[0]" ref="bnfcrCntrcSttsMdl" @isClose="dialog.splice(0,1,false)"></BnfcrCntrcSttsMdl>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { selAllBnfcrCntrc, selAllBnfcrCntrcList,} from '../../api/bnfcr.js';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import BnfcrCntrcSttsMdl from '../../components/bnfcr/BnfcrCntrcSttsMdl.vue';

export default {
    name: 'BnfcrCntrcStts',

    components: {
        expandNowDatePicker,
        exBtn,
        BnfcrCntrcSttsMdl,
    },

    created: function(){        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.ymd = this.$moment().format('YYYY-MM-DD')
            this.getAllBnfcrCntrc()
        })
    },

    computed: {
        
    },

    watch:{
        
    },
        
    methods: {
        onSndCctvMdl:function(){

        },        
        getAllBnfcrCntrc: function () {
            
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                valClcdS    : ['62', '63', '65', '109'],
                inqYMD      : this.$moment(this.ymd, 'YYYY-MM-DD').format('YYYYMMDD'),
            }
            
            this.dataCall = true
            selAllBnfcrCntrc(obj)
                .then((response) => ( this.getAllBnfcrCntrcAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrCntrc : ' + error))
        },

        getAllBnfcrCntrcList: function () {
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,                
                inqYMD      : this.$moment(this.ymd, 'YYYY-MM-DD').format('YYYYMMDD'),
            }
            
            this.dataCall = true
            selAllBnfcrCntrcList(obj)
                .then((response) => ( this.getAllBnfcrCntrcListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrCntrc : ' + error))
        },
                
        stCdMapping: function (res) {

            res.vCd62.forEach((code) => {
                this.exBtn[2].expandList.push(code.valcdnm)
            });//end foreach
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach
            
            this.vCd62 = res.vCd62
            this.vCd63 = res.vCd63
            this.vCd65 = res.vCd65
            this.vCd109 = res.vCd109
        },
        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },

        getAllBnfcrCntrcAfter: function (res) {
            
            if(res.statusCode == 200){                                                
                if(res.result.code != null && res.result.code != undefined){
                    this.stCdMapping(res.result.code)
                }

                if(res.result.lvnRmList != null && res.result.lvnRmList != undefined){
                    this.getLvnRmInfAfter(res.result.lvnRmList)
                }

                if(res.result.list != null && res.result.list != undefined){
                    this.setAllBnfcrCntrcListAfter(res.result.list)
                }
            }
            else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }

            this.dataCall = false
            
        },

        getAllBnfcrCntrcListAfter:function(res){            
            if(res.statusCode == 200){
                if(res.result != null && res.result != undefined){
                    this.setAllBnfcrCntrcListAfter(res.result)
                }
            }else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }

            this.dataCall = false
        },
        setAllBnfcrCntrcListAfter:function(res){
            this.cntrcList.splice(0)

            res.forEach(items => {
                items.ageNm = items.age + '세'

                items.rsdntBirth    = (items.rsdntBirth != '' && items.rsdntBirth != null)?this.$moment(items.rsdntBirth, 'YYYYMMDD').format('YYYY.MM.DD'):''
                items.entDt         = (items.entDt != '' && items.entDt != null)?this.$moment(items.entDt, 'YYYYMMDD').format('YYYY.MM.DD'):''
                items.lvDt          = (items.lvDt != '' && items.lvDt != null)?this.$moment(items.lvDt, 'YYYYMMDD').format('YYYY.MM.DD'):''
                items.cntrcDt       = (items.cntrcDt != '' && items.cntrcDt != null)?this.$moment(items.cntrcDt, 'YYYYMMDD').format('YYYY.MM.DD'):''
                items.cntrcPrdStrt  = (items.cntrcPrdStrt != '' && items.cntrcPrdStrt != null)?this.$moment(items.cntrcPrdStrt, 'YYYYMMDD').format('YYYY.MM.DD'):''
                items.cntrcPrdEnd   = (items.cntrcPrdEnd != '' && items.cntrcPrdEnd != null)?this.$moment(items.cntrcPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD'):''
                items.sndDt         = (items.sndDt != '' && items.sndDt != null)?this.$moment(items.sndDt, 'YYYYMMDD').format('YYYY.MM.DD'):''

                items.bnfcrSigYn    = (items.bnfcrSigYn == '1')?'예':'아니오'
                items.grdnSigYn    = (items.grdnSigYn == '1')?'예':'아니오'

                this.vCd63.forEach(code => {
                    if(items.copayRate == code.valcd) items.copayRateNm = code.valcdnm
                });

                this.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });

                this.vCd109.forEach(code => {
                    if(items.sndSttsCd == code.valcd) items.sndSttsCd = code.valcdnm
                });

                this.cntrcList.push(items)

            });
        },

        getDate: function (res){
            this.ymd = res.date    
            if(!this.dataCall) this.getAllBnfcrCntrcList()
        },
        expandClose: function (index) {
         
            switch(index) {
                case 0: this.$refs.exBtnClose0.isClose()
                    break;
                case 1: this.$refs.exBtnClose1.isClose()
                    break;
                case 2: this.$refs.exBtnClose2.isClose()
                    break;               
                default:
                    this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    this.$refs.exBtnClose2.isClose()
                    break;
            }

        },
        filterSearchD1: function (sch) {
                
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD2: function (sch) {
                
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },

        filterSearchD3: function (sch) {
                
            if(sch.length == 0) this.schChkField3.push('')

            this.schChkField3 = []
            sch.forEach((item) => {
                this.vCd62.forEach((code) => {
                    if(item == code.valcdnm) this.schChkField3.push(code.valcd)
                });
            });

            //배열 공백 제거
            this.schChkField3 = this.schChkField3.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField3 = this.schChkField3.filter((val, idx) => {
                return this.schChkField3.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            
            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((lvnRm, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == lvnRm
                    else col2 = col2 || filter.lvnRmNm == lvnRm
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            let col3 = true
            if(this.schChkField3.length != 0){
                this.schChkField3.forEach((acknwRtng, i) => {
                    if(i == 0) col3 = filter.acknwRtng.includes(acknwRtng)
                    else col3 = col3 || filter.acknwRtng.includes(acknwRtng)
                    //console.log('array3 : ' + this.schChkField3)
                });
            }

            return col0 && col1 && col2 && col3;
        },
        onModel:function(value){
            this.dialog.splice(value, 1, true)
        },
        isModal:function(value){
            this.dialog.splice(value, 1, false)
        },
        
        onCntrc:function(bnPk, bnName, cntrcPk){

            this.sltItem.bnMmbrPk           = bnPk
            this.sltItem.name               = bnName
            this.sltItem.bnfcrCntrcInfPk    = cntrcPk

            this.onModel(0)
        }
    },
    
    data: () => ({
        dataCall:false,
        sltItem:{
            bnMmbrPk        : 0,
            name            : '',
            bnfcrCntrcInfPk : 0,
        },
        ymd:'',
        vCd62: [],
        vCd63: [],
        vCd65: [],
        vCd109: [],
        lvnRmInf: [],
        schField: '',
        schDummy: '',
        schChkField1: [],
        schChkField2: [],
        schChkField3: [],
        dialog:[false,false],
        exBtn: [ 
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 100, expandList: [], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 194, expandList: [], },
        ],
        cntrcHeader: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 tableHeaderSize fixedPstn0 bnfcrMidIndex'},
            { text: '수급자', value: 'name', sortable: true, width: '150', align: 'center', class: 'black--text greyE01 tableHeaderSize fixedPstn1 bnfcrMidIndex'},
            { text: '현황', value: 'entStcd', sortable: true, width: '150', align: 'center', class: 'black--text greyE01 tableHeaderSize fixedCntrcSttsPstn2 bnfcrMidIndex'},
            { text: '나이', value: 'age', sortable: true, width: '100',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'},
            { text: '성별', value: 'gndr', sortable: true, width: '80',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '생년월일', value: 'rsdntBirth', sortable: true, width: '150',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '150',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '등급', value: 'acknwRtngNm', sortable: true, width: '100',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'},
            { text: '본인부담률', value: 'copayRateNm', sortable: true, width: '120',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '계약일', value: 'cntrcDt', width: '150',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'},
            { text: '계약기간', value: 'cntrcPrdStrt', sortable: true, width: '200',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '수급자서명여부', value: 'bnfcrSigYn', sortable: true, width: '150',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '보호자서명여부', value: 'grdnSigYn', sortable: true, width: '150',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '발송상태', value: 'sndSttsCd', sortable: true, width: '150',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '발송일', value: 'sndDt', sortable: true, width: '150',  align: 'center',  class: 'black--text greyE01 tableHeaderSize'}, 
            { text: '처리', value: 'acknwPrdEnd', sortable: false, width: '180',  align: 'center',  class: 'black--text greyE01 tableHeaderSize fixedPstnR1'}, 
        ],
        cntrcList:[],        
    }),
}
</script>
<style>    
    .fixedCntrcSttsPstn2 { left: 230px; }
    .fixedPstnR1 { right: 0px; }
</style>