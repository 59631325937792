<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">청구내역&nbsp;수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose()" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="mt-8"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                <span class="black--text font-weight-medium py-1">수급자</span>
            </v-col>
            <v-col class="py-1 txtAlgnMid tableBL tableBR" cols="4">
                <span class="black--text">{{$parent.$parent.$parent.$parent.copayClmDtlInfo.name}}</span>
            </v-col>
            <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                <span class="black--text font-weight-medium py-1">청구년월</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBL" cols="4">
                <span class="black--text">{{$parent.$parent.$parent.$parent.copayClmDtlInfo.clmYymm}}</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                <span class="black--text font-weight-medium">등급</span>
            </v-col>
            <v-col class="d-flex justify-center txtAlgnMid py-1 tableBL tableBR" cols="4">
                <span :class="'ic-level level0' + $parent.$parent.$parent.$parent.copayClmDtlInfo.acknwRtng" style="margin: auto;">
                    {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.acknwRtng == '99' ? '외' : $parent.$parent.$parent.$parent.copayClmDtlInfo.acknwRtng }}
                </span>
            </v-col>
            <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                <span class="black--text font-weight-medium">본인부담률</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBL" cols="4">
                <span class="black--text">
                    {{$parent.$parent.$parent.$parent.copayClmDtlInfo.copayRateNm}}&#40;{{ $parent.$parent.$parent.$parent.copayClmDtlInfo.copayRateDesc }}&#41;
                </span>
            </v-col>
        </v-row>
        <v-form ref="frmClm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">제공일수</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL tableBR py-1" cols="4">
                    <span class="black--text">{{$parent.$parent.$parent.$parent.copayClmDtlInfo.ofrDys}}</span>
                </v-col>
                <v-col class="" cols="6">
                    <v-row class="pa-0" no-gutters>
                        <v-col class="greyE01" cols="4">
                            <div class="d-flex justify-center align-center tableBB py-1">
                                <span class="black--text font-weight-medium">청구일</span>
                            </div>
                            <div class="d-flex justify-center align-center" style="padding-top: 6px;">
                                <span class="black--text font-weight-medium">납부기한</span>
                            </div>
                        </v-col>
                        <v-col class="tableBL" cols="8">
                            <div class="black--text d-flex justify-center align-center py-1 tableBB">
                                {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.clmDt == null ? '&nbsp;' : $parent.$parent.$parent.$parent.copayClmDtlInfo.clmDt }}
                            </div>
                            <div class="black--text d-flex justify-center align-center py-1">
                                <span class="white d-inline-block" style="width: 154px;">
                                    <CmmDateComp
                                        v-model="frmDueDt"
                                        :minDt="frmClmDt" :required="true">
                                    </CmmDateComp>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">본인부담금ⓐ</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field 
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.copay"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.copay.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">공단부담금</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.nhisPay"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.nhisPay.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="8">
                    <span class="black--text font-weight-medium py-1">
                        급여&nbsp;계&nbsp;&#40;본인부담금&#43;공단부담금&#41;
                    </span>
                </v-col>
                <v-col class="white d-flex justify-center align-center" cols="4">
                    <span class="black--text py-1">
                        {{(Number($parent.$parent.$parent.$parent.copayClmDtlInfo.copay) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.nhisPay))?.toLocaleString('ko-KR')}}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="mt-3"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">식사재료비①</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.mealCost"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.mealCost.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">상급침실비②</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.upBedFee"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.upBedFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">이,미용비③</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.btyFee"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.btyFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">경관유동식④</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.lqdFood"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.lqdFood.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">간식비⑤</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.snackFee"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.snackFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 tableBL tableBR" style="line-height: 14px;" cols="2">
                    <div class="txtAlgnMid pt-1">
                        <span class="black--text font-weight-medium">기타비용⑥</span>
                    </div>
                    <div class="txtAlgnMid pb-1">
                        <v-btn
                            class="black--text" height="17"
                            @click="$parent.$parent.$parent.$parent.menu.splice(4, 1, true)"
                            icon>
                            <v-icon small>mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.msclnFee"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.msclnFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 tableBR" style="line-height: 16px;" cols="2">
                    <div class="txtAlgnMid black--text font-weight-medium pt-1">진료약제비⑦</div>
                    <div class="txtAlgnMid black--text font-weight-medium pb-1">
                        <v-btn class="black--text" height="17" icon @click="$parent.$parent.$parent.$parent.menu.splice(2, 1, true)">
                            <v-icon small>mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <!-- <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.clncPhrmcFee"
                            color="blue" type="number" :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field> -->
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.clncPhrmcFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 tableBL tableBR" style="line-height: 16px;" cols="2">
                    <div class="black--text font-weight-medium pt-1" style="text-align:center;">계약의사</div>
                    <div class="black--text font-weight-medium pb-1" style="text-align:center;">진찰비⑧</div>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <!-- <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.cntrcDctrFee"
                            color="blue" type="number" :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field> -->
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.cntrcDctrFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">등급외⑨</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.otrAmt"
                            color="blue" type="number"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.otrAmt.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">이전미납금⑩</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <!-- <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.unpdAmt"
                            color="blue" type="number" :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field> -->
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.unpdAmt.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 tableBR" cols="8">
                    <div class="txtAlgnMid black--text font-weight-medium pt-1">
                        비급여&nbsp;계
                    </div>
                    <div class="txtAlgnMid black--text font-weight-medium pb-1">
                        ⓑ&nbsp;=&nbsp;①&#43;②&#43;③&#43;④&#43;⑤&#43;⑥&#43;⑦&#43;⑧&#43;⑨&#43;⑩
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center white" cols="4">
                    <span class="black--text py-1">
                        {{
                            (Number($parent.$parent.$parent.$parent.copayClmDtlInfo.mealCost) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.upBedFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.btyFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.lqdFood) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.snackFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.msclnFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.clncPhrmcFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.cntrcDctrFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.otrAmt) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.unpdAmt))?.toLocaleString('ko-KR')
                        }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="8">
                    <span class="black--text font-weight-medium py-1">당월본인부담금합계&nbsp;ⓒ&nbsp;=&nbsp;ⓐ&#43;ⓑ&#45;⑩</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white" cols="4">
                    <span class="black--text py-1">
                        {{
                            (Number($parent.$parent.$parent.$parent.copayClmDtlInfo.copay) +         
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.mealCost) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.upBedFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.btyFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.lqdFood) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.snackFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.msclnFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.clncPhrmcFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.cntrcDctrFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.otrAmt) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.unpdAmt) - 
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.unpdAmt))?.toLocaleString('ko-KR')
                        }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">선납적용액ⓓ</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 50%;">
                        <!-- <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.prpymApl"
                            color="blue" type="number" :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field> -->
                    </span>
                    <span class="d-inline-block d-flex justify-center" style="width: 50%;">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.prpymApl.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBL tableBR" cols="2">
                    <span class="black--text font-weight-medium py-1">잔여선납금</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white py-1 px-1" cols="4">
                    <!-- <span class="d-inline-block" style="width: 50%;">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.rmnPrpym"
                            color="blue" type="number" :rules="[rules.required]"
                            hide-details outlined dense>
                        </v-text-field>
                    </span> -->
                    <span class="d-flex justify-center align-center">
                        {{ $parent.$parent.$parent.$parent.copayClmDtlInfo.rmnPrpym.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 d-flex justify-center align-center tableBR" cols="8">
                    <span class="black--text font-weight-medium py-1">부담금&nbsp;총합&#40;ⓐ&#43;ⓑ&#45;ⓓ&#41;</span>
                </v-col>
                <v-col class="d-flex justify-center align-center white" cols="4">
                    <span class="black--text py-1">
                        {{ 
                            (Number($parent.$parent.$parent.$parent.copayClmDtlInfo.copay) +         
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.mealCost) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.upBedFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.btyFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.lqdFood) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.snackFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.msclnFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.clncPhrmcFee) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.cntrcDctrFee) +
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.otrAmt) + Number($parent.$parent.$parent.$parent.copayClmDtlInfo.unpdAmt) - 
                            Number($parent.$parent.$parent.$parent.copayClmDtlInfo.prpymApl))?.toLocaleString('ko-KR')
                        }}원
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium py-1">비고</span>
                </v-col>
                <v-col class="pa-1" cols="10">
                    <span class="" style="">
                        <v-text-field
                            v-model="$parent.$parent.$parent.$parent.copayClmDtlInfo.rmrks"
                            color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mb-5"></v-divider>
        </v-form>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn 
                    class="ml-1 white--text" height="30" color="blueBtnColor"
                    @click="$parent.$parent.$parent.$parent.preCopayClmHis()"
                    :disabled="$parent.$parent.$parent.$parent.copayClmDtlInfo.cnfrmYn == '1' ? true : false"
                    samll rounded>
                    <v-icon size="18">mdi-content-save-outline</v-icon>
                    <span class="ml-1" style="font-size: 1rem;">청구내역&nbsp;저장</span>
                </v-btn>
            </v-col>
        </v-row>

    </v-sheet>
</template>

<script>
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'MdfCopayClmsMdl',

    props : {
        prtDate: { type: String, default: '' },
        bnmmbr: { type: Number, default: -1 },
    },
        
    components: {
        CmmDateComp,
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.frmClmDt = this.$moment(this.$parent.$parent.$parent.$parent.copayClmDtlInfo.clmDt, 'YYYY.MM.DD').format('YYYY-MM-DD')
            this.frmDueDt = this.$moment(this.$parent.$parent.$parent.$parent.copayClmDtlInfo.dueDt, 'YYYY.MM.DD').format('YYYY-MM-DD')
        })
    },

    computed: {
        getClmDueDt: function () {
            return this.$parent.$parent.$parent.$parent.copayClmDtlInfo.dueDt
        },
        getClmDt: function () {
            return this.$parent.$parent.$parent.$parent.copayClmDtlInfo.clmDt
        }
    },

    watch: {
        getClmDueDt: function (v) {
            this.frmDueDt = this.$moment(v, 'YYYY.MM.DD').format('YYYY-MM-DD')
        },
        getClmDt: function (v) {
            this.frmClmDt = this.$moment(v, 'YYYY.MM.DD').format('YYYY-MM-DD')
        },
    },
    
    methods: {
        onClose: function(){
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [ false, ],
        frmClmDt: '',
        frmDueDt: '',
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>