<template>
    <v-sheet class="ma-0 pa-0" rounded="lg" style="position: relative;">
        <v-btn
            class="white--text text-subtitle-2 font-weight-mediums py-0 pr-3 pl-2"
            @click="exCard = !exCard"
            dense small color="topBtnColor" elevation="3">
            <v-icon class="white--text" size="16">mdi-bell-outline</v-icon>
            <span>알림내역</span>
            <span v-if="imprtAlrtCnt > 0" class="on_now_tag" style="position: absolute; top: -18px; right: -26px;"></span>
        </v-btn>
        <div style="position: absolute; top: 45px; left: -256px; z-index: 999;">
            <v-expand-transition>
                <v-card
                    v-show="exCard" width="440" elevation="7">
                    <v-row class="pa-4 ma-0" no-gutters>
                        <v-col class="" cols="12">
                            <div class="d-flex align-center greyF01 rounded py-2 px-4 mb-2">
                                <span class="fontOneRem font-weight-medium">알림 내역</span>
                                <v-spacer></v-spacer>
                                <span class="fontOneRem font-weight-medium">총 {{ ntfctList.length }}건</span>
                            </div>
                            <div class="d-flex align-center px-2 pt-2" v-for="(list, i) in ntfctList" :key="i">
                                <v-row class="pa-0 ma-0" no-gutters>
                                    <v-col class="d-flex align-center" cols="2">
                                        <span class="rounded-circle pl-2 pr-1 py-2 greyF01" v-if="list.almClcd == '3'">
                                            <v-icon color="grey006" style="margin-bottom: 3px;" size="16">mdi-bullhorn-variant-outline</v-icon>
                                        </span>
                                        <span class="rounded-circle pl-2 pt-2 greyF01" style="padding-right: 2px; padding-bottom: 8px;" v-else>
                                            <v-icon color="red" style="" size="20">mdi-alert-circle-outline</v-icon>
                                        </span>
                                    </v-col>
                                    <v-col cols="9">
                                        <div class="fontOneRem font-weight-medium">{{ list.title }}</div>
                                        <div class="grey006--text mt-1">{{ list.cntnt }}</div>
                                    </v-col>
                                    <v-col class="d-flex justify-center align-center" cols="1">
                                        <span v-if="list.almClcd != '3'" class="on_now_tag"></span>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="d-flex justify-center align-center rounded px-2 mt-3">
                                <v-btn class="rounded-circle px-0" color="blue600" min-width="34" height="30"
                                    @click="exCard = false">
                                    <v-icon style="padding: 1px 0px 0px 5px;" color="white" size="20">mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-expand-transition>
        </div>
        <span v-show="exCard" style="position: absolute; top: 30px; left: 44px; z-index: 1000;">
            <v-icon color="white" size="14">mdi-triangle</v-icon>
        </span>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'NotifyCard',

    props : {
       
    },
            
    components: {

    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getNtfctDtls()
        })
    },

    computed: {

    },

    watch:{

    },
        
    methods: {
        //알람내역 조회
        getNtfctDtls: function (){
            http.post('ntsBbs/selAlarmList', { fcltyNum: this.$store.getters.getFcltyNum })
                .then((response) => ( this.getNtfctDtlsAfter(response.data) ))
                .catch((error) => console.log('axios error /ntsBbs/selAlarmList : ' + error))
        },
        getNtfctDtlsAfter: function (res){
            this.ntfctList = res
            this.imprtAlrtCnt = res.filter( v => v.almClcd != '3' ).length

            if(this.imprtAlrtCnt > 0){
                this.exCard = this.$store.getters.getNtyAct
                this.$store.commit('setNtyAct', false)
            }
        },
    },
    
    data: () => ({
        exCard: false,
        ntfctList: [],
        imprtAlrtCnt: 0,
    }),
};
</script>

<style>
    .on_now_tag{
        font-size: 14px;
        color: #fd183a;
        padding: 2px 10px;
        margin-left: -7px;
        font-weight: 500;
    }
    .on_now_tag:after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #fd183a;
        border-radius: 50%;
        margin-left: 3px;
        -webkit-animation:blink 1s ease-in-out infinite alternate;
        -moz-animation:blink 1s ease-in-out infinite alternate;
        animation:blink 1s ease-in-out infinite alternate;
    }
    @-webkit-keyframes blink{
        0% {opacity:0;}
        100% {opacity:1;}
    }
    @-moz-keyframes blink{
        0% {opacity:0;}
        100% {opacity:1;}
    }
    @keyframes blink{
        0% {opacity:0;}
        100% {opacity:1;}
    }
</style>