<template>
    <span>
        <v-btn v-if="btnData.btnTitle != ''" class="py-4 px-2" color="btnBrdrColor" outlined @click="isOpen()"
            style="background-color: white;" height="22">
            <v-img class="ic-notice black--text" v-if="btnData.btnIcon == 'house'" src="../../styles/images/ic-house.svg"></v-img>
            <v-img class="ic-notice black--text" v-else-if="btnData.btnIcon == 'bed'" src="../../styles/images/ic-bed.svg"></v-img>
            <v-icon class="ic-notice black--text" v-else-if="btnData.btnIcon == 'wheelchair'" small>mdi-human-wheelchair</v-icon>
            <v-icon class="ic-notice black--text" v-else-if="btnData.btnIcon == 'business'" small>mdi-account-tie-woman</v-icon>
            <v-icon class="ic-notice black--text" v-else-if="btnData.btnIcon == 'listStatus'" small>mdi-list-status</v-icon>
            <v-icon class="ic-notice black--text" v-else-if="btnData.btnIcon == 'category'" small>mdi-shape-outline</v-icon>
            <v-icon class="ic-notice black--text" v-else-if="btnData.btnIcon == 'airplane'" small>mdi-airplane</v-icon>
            <v-icon class="ic-notice black--text" v-else-if="btnData.btnIcon == 'variant'" small>mdi-text-search-variant</v-icon>
            <v-icon class="ic-notice black--text" v-else-if="btnData.btnIcon == 'group'" small>mdi-account-group</v-icon>
            <span class="black--text" style="font-size: 1rem;">{{btnData.btnTitle}}</span>
        </v-btn>

        <v-btn v-else-if="btnData.btnTitle == ''" class="" color="btnBrdrColor" @click="isOpen()"
            style="background-color:white;padding:0px 2px !important; min-width: 24px !important;" width="22" height="22" outlined>
            <v-icon size="20" v-if="btnData.btnIcon == 'listChecks'">mdi-format-list-checks</v-icon>
        </v-btn>

        <v-expand-transition>
            <v-card v-show="menuEx"
                class="pt-1 px-3 pb-2 black--text" outlined
                style="position: absolute; z-index: 11;" :width="btnData.exWidth">
                    <v-checkbox v-if="btnData.mode != 'single'" v-model="ckBoxAll" @click="allCheckedBox()" :disabled="allBtnState"
                        class="text-caption font-weight-medium" dense hide-details="true" label="전체" value="all"></v-checkbox>
                    <v-card v-if="btnData.mode == 'single'" class="overflow-y-auto" :height="btnData.exHeigth" elevation="0">
                        <v-radio-group class="mt-2" v-model="rdBox" :key="forceRender" hide-details row>
                            <v-radio v-for="(list, i) in btnData.expandList" :key="i" :ripple="false"
                                :label="list" :value="list" class="font-weight-medium mb-1">
                            </v-radio>
                        </v-radio-group>
                    </v-card>
                    <v-card v-else class="overflow-y-auto" :height="btnData.exHeigth" elevation="0">
                        <v-checkbox v-model="ckBox" v-for="(list, i) in btnData.expandList" :key="i" @change="updateCheckBox"
                            class="text-caption font-weight-medium" dense hide-details="true" :label="list" :value="list"></v-checkbox>
                    </v-card>
                    <div class="d-flex justify-center mt-2">
                        <v-btn class="white--text" color="blackBtnColor" @click="isClose()" rounded elevation="1" small>닫기</v-btn>
                    </div>
            </v-card>
        </v-expand-transition>
    </span>
</template>

<script>
    export default {
        name: 'expandButtonCustom',

        props : {
            btnData : Object,
            sltElmnt: { type: String, default: '' },
        },
        
        components: {
        
        },

        created: function(){

        },

        mounted: function(){
            this.$nextTick(function () {
                this.btnIcon = this.btnData.btnIcon
       
                if(this.sltElmnt != '')
                    this.rdBox = this.sltElmnt
            })
        },

        watch:{ 
            'btnData.expandList': function (){
                if(this.btnData.mode == 'single')
                    this.rdBox = this.btnData.expandList[0]

                if(this.btnData.expandList.length > 0){
                    this.ckBox.splice(0)
                    this.updateCheckBox()
                }
            },
            'sltElmnt': function (){
                if(this.sltElmnt != '')
                    this.rdBox = this.sltElmnt

                this.forceRender += 1
            },
            'rdBox': function (){
                let arr = []
                arr.push(this.rdBox)
  
                this.$emit("changeCheck", arr);
            },
        },
    
        methods: {
            isOpen: function (){
                //let tmp = this.$store.getters.getExpandBtnState;

                this.$emit("btnClosed", this.btnData.index);
                this.menuEx = !this.menuEx;

                //this.$store.commit('setExpandBtnState', {
                    //index: this.btnData.index,
                   // data: this.menuEx,
                //});
               
            },
            isClose: function (){
                this.menuEx = false
            },
            allCheckedBox: function () {
                if(this.btnData.expandList.length == this.ckBox.length){
                    this.ckBox = this.btnData.expandList;
                    this.ckBoxAll = '';
                    this.allBtnState = false
                    this.$emit("changeCheck", this.ckBox);
                }
                else {
                    this.ckBox = this.btnData.expandList;
                    this.ckBox = []
                    this.ckBoxAll = 'all';
                    this.allBtnState = true
                    this.$emit("changeCheck", this.btnData.expandList);
                }

            },
            updateCheckBox: function () {
                if(this.btnData.expandList.length == this.ckBox.length) {
                    this.ckBoxAll = 'all';
                    this.allBtnState = true
                    this.ckBox = []
                }
                else if(this.ckBox.length == 0) {
                    this.ckBoxAll = 'all'
                    this.allBtnState = true
                    this.ckBox = []
                }
                else{
                    this.allBtnState = false
                    this.ckBoxAll = ''
                }

                this.$emit("changeCheck", this.ckBox);
            },
            updateCheckBoxSingle: function () {
                console.log(this.rdBox)

                this.$emit("changeCheck", this.ckBox);
            },
        },

        data: () => ({
            forceRender: 0,
            menuEx: false,
            ckBoxAll: 'all',
            allBtnState: true,
            ckBox: [],
            rdBox: '',
        }),
    };
</script>

<style>
    .theme--light.v-label{ color: #666 !important; font-size: 0.98rem; }
    .v-input .v-label{line-height: 24px !important;}
</style>