<template>
    <v-sheet class="ma-0 pa-0 d-flex" rounded="md">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <!-- left area -->
        <v-sheet class="d-inline-block pa-7" width="320" height="780" color="midAreaColor">
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col>
                    <span class="text-h4 font-weight-bold"><span class="text-h4 font-weight-bold" v-if="bnMmbrPk != 0">{{ items2.name }}</span>&nbsp;관찰내역</span>
                </v-col>
            </v-row>
            <div class="mt-4">
                <div class="d-flex">
                    <exBtn class="" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <v-spacer></v-spacer>
                    <span class="d-inline-block" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white"
                            append-icon="mdi-magnify" single-line rounded outlined dense filled hide-details label="이름조회"
                            @input="setSearchText">
                        </v-text-field>
                    </span>
                </div>
            </div>
            <div class="mt-2 rounded-lg">
                <v-data-table class="msclnTable pt-1" height="630" width="300" locale="ko" :headers="header" :items="items"
                    :search="schDummy" :custom-filter="customFilter" disable-pagination fixed-header hide-default-header
                    hide-default-footer dense>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th :class="props.headers[0].class" :style="props.headers[0].style">
                                    {{ props.headers[0].text }}</th>
                                <th :class="props.headers[1].class" :style="props.headers[1].style">
                                    {{ props.headers[1].text }}</th>
                                <th :class="props.headers[2].class" :style="props.headers[2].style">
                                    {{ props.headers[2].text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr :class="['fixedCol', selectedItem == index ? 'sltRow' : 'disSltRow']"
                            @click="tableClickEvent(index, item.bnMmbrPk)">
                            <td class="fixedCol0 tableBT removeTableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.entStcd }}</span>
                            </td>
                            <td class="fixedCol0 tableBT removeTableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.name }}</span>
                            </td>
                            <td class="fixedCol0 tableBT removeTableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.lvnRmNm }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <!-- content area -->
        <v-sheet class="d-inline-block pa-7" width="880" height="780">
            <v-row class="mb-4 pa-0" no-gutters>
                <v-col class="d-flex" cols="12">
                    <expandWeekOnlyPicker @nowWeek="getWeek" class="mt-2"></expandWeekOnlyPicker>
                    <v-spacer></v-spacer>
                    <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-form ref="form" lazy-validation>
                <basicInfo ref="basicInfo" :bnMmbrPk="bnMmbrPk"></basicInfo>
                <v-row class="pa-0 ma-0 mt-2"
                    style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important; width: 827px;"
                    no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                        <span class="black--text font-weight-medium">관찰일</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                        <span class="black--text font-weight-medium">구분</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="5">
                        <span class="black--text font-weight-medium">상세내용</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                        <span class="black--text font-weight-medium">작성자</span>

                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="1">
                        <v-btn icon class="mr-1" color="blue200" height="26"
                            @click="addRow2()"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                    </v-col>    
                </v-row>
                <div v-if="items1.length > 0" style="width: 837px;">
                    <div style=" height:570px; overflow-y:auto; ">
                        <v-row v-for="(item, i) in items1" :key="i" class="pa-0 ma-0 ma-0"
                            style="border-bottom: 1px solid #dbdbdb !important; width: 827px;" no-gutters>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2">
                                <span v-if="item.bnfcrObsHisPk > -1" class="d-inline-block py-1" style="width: 154px;">
                                    <CmmDateComp v-model="item.obsrvDt" :required="true"></CmmDateComp>
                                </span>
                                <span v-else>
                                    {{item.obsrvDt}}
                                </span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0"
                                style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                <!-- <span class="d-inline-block d-flex flex-row" style="width:100%; ">
                                    <v-select dense hide-details outlined v-model="item.obsrvClcd"
                                        :items="vCd103" item-text="valcdnm" item-value="valcd"
                                        :rules="[rules.required]"></v-select>
                                </span> -->
                                {{item.obsrvClcdNm}}
                            </v-col>
                            <v-col class="d-flex justify-start align-center ma-0 py-1 px-1"
                                style="border-left: 1px solid #dbdbdb !important;" cols="5">
                                <div v-if="item.bnfcrObsHisPk > -1" style="width:100%;position:relative;">
                                    <v-textarea 
                                        class="rmrksArea" rows="3"
                                        v-model="item.obsrvCntnt" :rules="[rules.required]"
                                        hide-details no-resize outlined dense>
                                    </v-textarea>
                                    <span style="position:absolute;bottom:9px;right:18px;">
                                        <v-btn
                                            class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                            min-width="28" height="28" @click="onCmnts(i)"
                                            outlined
                                            dark small>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                                </template>
                                                <span>확대</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                </div>
                                <div v-else class="overflow-y-auto" style="height:80px;">
                                    {{item.obsrvCntnt}}
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0"
                                style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                <div v-if="item.bnfcrObsHisPk > -1" class="d-flex justify-center align-center">
                                    <span class="d-inline-block py-1" style="width: 80px;">
                                        <v-text-field v-model="item.wrtr" color="blue" ref="info" :rules="[rules.required]"
                                            hide-details outlined dense :key="forceRender" readonly></v-text-field>
                                    </span>
                                    <v-btn class="brown lighten-3 d-inline-block ml-1"
                                        style="padding:0px 1px 0px 2px !important" dark small depressed min-width="30"
                                        height="28" @click="onEmpModel(i), sltEmpDate=item.obsrvDt">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                            </template>
                                            <span>직원선택</span>
                                        </v-tooltip>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    {{item.wrtr}}
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center ma-0" :btnDisabled='btnDisabled'
                                style="border-left: 1px solid #dbdbdb !important;" cols="1">

                                <v-btn icon color='blue200' @click="onSaveRow(i)" v-if="item.bnfcrObsHisPk > -1">
                                    <v-icon>mdi-content-save-outline</v-icon></v-btn>

                                <v-btn icon class="" color="grey006" height="26" v-if="item.bnfcrObsHisPk > 0"
                                    @click="onDelete(i)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                                <v-btn class="" color="grey006" icon height="26" v-else-if="item.bnfcrObsHisPk > -1"
                                    @click="onDelete1(i)"><v-icon>mdi-minus-circle-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="d-flex justify-center tableBB py-3" v-else> 
                    <span>※ 조회된 항목이 없습니다.</span>
                </div>
            </v-form>
        </v-sheet>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="sltEmpDate"></EmpSelector>
            </v-dialog> 
        </div>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'관찰내역'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" content-class="round" persistent max-width="800">
                <CmntsMdl v-if="dialog[1]" @isClose="dialog.splice(1, 1, false)" @outText="setDtldGls" :mdlTtl = "mdlTtl" :mdlCmnts="mdlCmnts" :hSize='10'></CmntsMdl>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import expandWeekOnlyPicker from '../../components/commons/expandWeekOnlyPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmntsMdl from '../commons/CmntsMdl.vue';
import { bnfcrList } from '../../api/bnfcr.js';
import { selBnfcrObsHisList,insBnfcrObsHis, delBnfcrObsHis } from '../../api/othr.js';
import { getMultiStcd } from '../../api/index.js';
import { getBnfcrBasicInfo } from '../../api/bnfcr.js';
export default {
    name: 'BnfcrObsrvHstry',

    props: {

    },

    components: {
        exBtn,
        expandWeekOnlyPicker,
        basicInfo,
        EmpSelector,
        btnModalDelete,
        CmmDateComp,
        CmntsMdl,
    },

    created: function () {
        getMultiStcd(['65'])
            .then((response) => (this.codeMapping(response.data)))
            .catch((error) => console.log(error))

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getBnfcrList()
        })
    },

    computed: {
    },

    watch: {
        
    },

    methods: {
        getInfo(){
                let fcltyNum = this.$store.getters.getFcltyNum
                let bnMmbrPk = this.bnMmbrPk             
                getBnfcrBasicInfo(fcltyNum, bnMmbrPk)
                .then((response) => ( this.setDataFormat(response.data)))
                .catch((error) => console.log(error))
            },
            setDataFormat(data){                   
                if(data !=''){
                    this.items2 = data;
                }else{
                    this.items2 = Object.assign({})
                }
            },
        getBnfcrList: function () {
            bnfcrList(this.$store.getters.getFcltyNum)
                .then((response) => (this.getBnfcrListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        getBnfcrListAfter: function (res) {
            this.items.splice(0)

            let me = this
            res.forEach(e => {
                me.vCd65.forEach(code => {
                    if (e.entStcd == code.valcd) e.entStcd = code.valcdnm
                });
            });

            this.items = res

            if (this.$store.getters.getBnfcrState.bnmmbr != 0) {
                this.items.forEach(e => {
                    if (e.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr) {
                        this.tableClickEvent(e.bnMmbrPk, e.bnftOfrRecHisPk)
                    }
                });
            }
        },
        selBnfcrObsHisList: function () {
            selBnfcrObsHisList(this.$store.getters.getFcltyNum, this.bnMmbrPk, this.minDate, this.maxDate)
                .then((response) => (this.selBnfcrObsHisListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        selBnfcrObsHisListAfter: function (res) {
            this.items1.splice(0)
            this.vCd103 = res.code.vCd103
            res.list.forEach(e => {
                e.obsrvDt = e.obsrvDt?.beforeDateFormatHyp()
                if(e.bnfcrObsHisPk == 0){
                    this.btnDisabled = true
                }

                this.vCd103.forEach(code => {
                    if (e.obsrvClcd == code.valcd) e.obsrvClcdNm = code.valcdnm
                });
                this.items1.push(e)
            });           
        },
        addRow2() {
            let addObj = {
                bnfcrObsHisPk   :0,
                obsrvClcd       :'9',
                obsrvClcdNm     :'기타',
                wrtr            :'',
                obsrvCntnt      :'',
                obsrvDt         : this.minDate?.beforeDateFormatHyp(),
            }
            this.items1.push(addObj)
        },
        getWeek: function (ws, we) {
            this.minDate = ws
            this.maxDate = we   
            this.selBnfcrObsHisList()         
        },
        tableClickEvent: function (rn, pk) {
            if (this.selectedItem == -1) {
                this.selectedItem = rn
                this.bnMmbrPk = pk
                this.selBnfcrObsHisList()
                this.getInfo()
            }
            else if (this.selectedItem != rn) {
                this.selectedItem = rn
                this.bnMmbrPk = pk
                this.selBnfcrObsHisList()
                this.getInfo()
            }
            else {
                //Form Clear
                this.selectedItem = -1
                this.bnMmbrPk = 0
                this.selBnfcrObsHisList()
                this.$refs.form.reset();    
            }
        },
        onSaveRow: function (key) {
            if (this.$refs.form.validate()) {
                let obj = {
                    bnMmbrPk: this.bnMmbrPk,
                    obsrvDt: this.items1[key].obsrvDt?.afterDateFormatHyp(),
                    bnfcrObsHisPk: this.items1[key].bnfcrObsHisPk,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    obsrvClcd: this.items1[key].obsrvClcd,
                    obsrvCntnt: this.items1[key].obsrvCntnt,
                    wrtr: this.items1[key].wrtr,
                    userNm: this.$store.getters.getUserNm,
                }
                insBnfcrObsHis(obj)
                    .then((response) => (this.insBnfcrObsHisAfter(response.data)))
                    .catch((error) => console.log(error))
            }
        },
        insBnfcrObsHisAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selBnfcrObsHisList()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete1(key) {
            this.items1.splice(key, 1)
        },
        onDelete(key) {            
            if (key > -1) {
                this.key = key
                this.dialogDelete = true
            } else {
                if (key != 0) {
                    this.items1.splice(key, 1)
                }
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items1[this.key].bnfcrObsHisPk > 0) {
                    let obj = {
                        userNm: this.$store.getters.getuserNm,
                        bnfcrObsHisPk: this.items1[this.key].bnfcrObsHisPk,
                    }
                    delBnfcrObsHis(obj)
                        .then((response) => (this.delBnfcrObsHisAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.items1.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delBnfcrObsHisAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.selBnfcrObsHisList()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.$emit('modalClose', '1')
        },
        codeMapping: function (res) {            
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach
            this.vCd65 = res.vCd65
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            if (sch.length == 0) this.schChkField.push('')

            this.schChkField = []
            sch.forEach((item) => {
                this.schChkField.push(item)
            });

            //배열 공백 제거
            this.schChkField = this.schChkField.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField = this.schChkField.filter((val, idx) => {
                return this.schChkField.indexOf(val) === idx
            });


            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            // value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if (this.schChkField.length != 0) {
                this.schChkField.forEach((idxCdnm1, i) => {
                    if (i == 0) col1 = filter.lvnRmNm.includes(idxCdnm1)
                    else col1 = col1 || filter.lvnRmNm.includes(idxCdnm1)
                });
            }

            this.selectedItem = -1
            return col0 && col1;
        },
        expandClose: function (index) {
            switch (index) {
                case 0: this.schChkField.target = 'lvnRmNm'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onEmpModel: function (idx) {
            this.idx = idx 
            this.dialog.splice(0, 1, true)
        },
        isModal: function () {
            this.prtWrtDt = this.opDt
            this.dialog.splice(0, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items1[this.idx].wrtr = obj[0].name

        },
        isModalType: function (value) {
            this.menu.splice(value, 1, false)
        },
        onCmnts:function(no){
            this.idx = no
            this.mdlCmnts =  this.items1[this.idx].obsrvCntnt
            this.dialog.splice(1, 1, true)
        },
        //세부내용 적용
        setDtldGls:function(txt){           
           this.items1[this.idx].obsrvCntnt = txt
           this.dialog.splice(1, 1, false)
        },
    },

    data: () => ({
        sltEmpDate: '',
        prtWrtDt:'',
        header: [
            { text: '현황', value: 'entStcd', align: 'center', on: false, sortable: true, width: '80', class: 'v-data-table--fixed-header fixedPstn0 black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '수급자', value: 'name', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '생활실', value: 'lvnRmNm', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
        ],
        filledBtn: [
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color: 'grey006 ', text: '출력', width: '500px' },
        ],
        exBtn: [
            { index: 0, btnTitle: '생활실', btnIcon: 'bed', exWidth: 104, exHeigth: 100, expandList: ['바나나', '사과', '오렌지'], },
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        schChkField: [],
        snackOptions: [false, 3000, '', 'info'],
        schField: '',
        forceRender: 0,
        schDummy: '',
        vCd65: [],
        vCd103: [],
        items: [],
        items1: [],
        items2: {},
        menu: [false],
        btnDisabled: false,
        dialogDelete: false,
        dialog: [false,false],
        selectedItem: -1,
        minDate: '',
        maxDate: '',
        bnMmbrPk: 0,
        idx: 0,
        mdlTtl:'상세내용',
        mdlCmnts:'',
    }),
};
</script>