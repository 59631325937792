<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                                
                    <span class="black--text font-weight-medium"></span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10">                                
                    <span class="black--text font-weight-medium">물리(작업)치료 평가</span>
                </v-col>
            </v-row>
            <div v-if="data.length > 0" class="pa-0 ma-0">
                <v-row v-for="(item, i) in data" :key="i" 
                    class="pa-0 ma-0" no-gutters  style="border-bottom: 1px solid #dbdbdb !important;">
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="2">                                
                        <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10">                                
                        {{item.evltn}}
                    </v-col>
                </v-row>            
            </div>
            <div v-else class="pa-0 ma-0">
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                </div>
            </div>
        </v-form>    
    </v-sheet>
</template>
<script>
import { insBnftOfrPhyThrPln} from '../../api/bnftSrv.js';

export default {
    name: 'PhyscThrpyPln1',

    props : {
        inqYYYY: Number,
    },

    computed: {        
    },  

    watch:{
    },

    created:function(){  
             
    },

    components: {
    },
    
    mounted: function(){
    },
        
    methods: {   
        onShow(){
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:200px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:80px;"
            }else{
                style="width:100px;"
            }
            return style;
        },   
        
        setData(data){      
           this.data = data            
        },
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        reset(){
            this.$refs.tabform.reset()
        },

        insOfrPhyThrPln(data){
            let ofrPlnHisInfo = {
                wrtDt :  data.wrtDt?.afterDateFormatHyp(),
                fcltyNum : this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,
                bnMmbrPk : data.bnMmbrPk,
                plnYyyy: data.inqYYYY,
                bnftOfrPlnHisPk: data.bnftOfrPlnHisPk,
                wrtr : data.wrtr,
                mdfClcd : data.mdfClcd,
                aplStrt : data.aplStrt?.afterDateFormatHyp(), 
                aplEnd : data.aplEnd?.afterDateFormatHyp(),
                plnClcd:'202',
            }

            insBnftOfrPhyThrPln(ofrPlnHisInfo,null,null)
                .then((response) => ( this.insOfrPhyThrPlnAfter(response.data)))
                .catch((error) => console.log(error))
            
        },
        insOfrPhyThrPlnAfter: function (res) {
            this.$emit('physcThrpyPlnTrigger', res)            
        },   
    },
    
    data: () => ({ 
        data:[],               
    }),
};
</script>