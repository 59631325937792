<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">욕구사정평가</span>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span  v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="btnDisabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :class="list.class" @click = "onClickBtn(list.text)"
                :disabled="btnDisabled"
                samll height="30" :color="list.color" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">조사사유</span>
                </v-col>
                <v-col class="py-1 pl-2 d-flex justify-center align-center" cols="3">
                    <span class="d-inline-block" style="width: 250px;">
                        <v-radio-group v-model="items.examRsncd" :rules="[rules.required]" class="pl-2 pt-1" hide-details row>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="신규" value="1"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="재사정" value="2"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="상태변화" value="3"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="2">
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;">   
                        <CmmDateComp v-model="items.examDt" :required="true"></CmmDateComp>                                                
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="4">
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field v-model="items.wrtr" color="blue" ref="info"
                            :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>            
            </v-row>
        </v-form>
        <v-tabs hide-slider v-model="tab"  color="white" class="black--text"
            active-class="bodyColor" center-active>
            <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style">{{item.tab}}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item eager transition="fade-transition">
                <tab1 ref="dsrEvltnTab1"
                    @dsrEvltnTrigger="dsrEvltnReLoad" 
                    style="height: 480px;"></tab1>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab2  ref="dsrEvltnTab2" 
                    :tab="tab"
                    @dsrEvltnTrigger="dsrEvltnReLoad" 
                    style="height: 480px;"></tab2>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab3  ref="dsrEvltnTab3" 
                    :tab="tab"
                    @dsrEvltnTrigger="dsrEvltnReLoad" 
                    style="height: 480px;"></tab3>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab4  ref="dsrEvltnTab4" 
                    :tab="tab"
                    @dsrEvltnTrigger="dsrEvltnReLoad" 
                    style="height: 480px;"></tab4>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab5  ref="dsrEvltnTab5" 
                    :tab="tab"
                    @dsrEvltnTrigger="dsrEvltnReLoad" 
                    style="height: 480px;"></tab5>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab6  ref="dsrEvltnTab6" 
                    :tab="tab"
                    @dsrEvltnTrigger="dsrEvltnReLoad" 
                    style="height: 480px;"></tab6>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab7  ref="dsrEvltnTab7" 
                    :tab="tab"
                    @dsrEvltnTrigger="dsrEvltnReLoad" 
                    style="height: 480px;"></tab7>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'욕구사정평가'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import tab1 from './DsrEvltnTab1.vue';
import tab2 from './DsrEvltnTab2.vue';
import tab3 from './DsrEvltnTab3.vue';
import tab4 from './DsrEvltnTab4.vue';
import tab5 from './DsrEvltnTab5.vue';
import tab6 from './DsrEvltnTab6.vue';
import tab7 from './DsrEvltnTab7.vue';

import { getBnfcrExamHisInfo, getBnfcrExamList, insBnfcrExamHis, delBnfcrExamHis} from '../../api/bnftSrv.js';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'DsrEvltn',

    props : {        
        inqYYYY: Number,       
        bnMmbrPk : { type: Number, default: -1 },
        bsrEvltnPk : { type: Number, default: -1 }, 
        btnDisabled : { type: Boolean, default: false },  
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        tab1,
        tab2,
        tab3,
        tab4,
        tab5,
        tab6,
        tab7,
        btnModalDelete,
        EmpSelector,
    },
    

    watch: {     
        'bsrEvltnPk':function(value){    
            this.filledBtn[0].visible = false
            if(value > -1){
                this.bnfcrExamHisPk = value
                this.onShow()
                if(value == 0) this.filledBtn[0].visible = true
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bnfcrExamHisPk = this.bsrEvltnPk
            this.onShow()
            this.filledBtn[0].visible = false
            if(this.bnfcrExamHisPk == 0) this.filledBtn[0].visible = true
        })
    },
    
    methods: {
        onShow : function(){
            this.getInit()
            this.getExamHisInfo();            
            this.getBnfcrExamList()
        },
        onClose: function () {
            this.$store.commit('setBnfcrExamHisPk', '')
            this.frmReset();
            this.$emit('modalEvent', 0)
        },
        getInit(){           
            // let date = new Date()                                    
            // let dateStr = date.toISOString().split('T',2)[0]
            
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            this.items.examDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;

            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'

            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text == "이전자료"){
                this.getPreData = true
                this.getBnfcrExamList()
            }else if(text=="저장"){                
                this.insBnfcrExam()                
            }else if(text=="삭제"){
                if(this.bnfcrExamHisPk > 0){
                    this.dialogDelete = true
                }
            }else if(text=="출력"){
                this.dsrEvltnPrt()
            }
        },

        onDelete:function(res){
            if(res == 9990){
                if(this.bnfcrExamHisPk > 0){
                    let code = this.$store.getters.getFcltyNum
                    let value = this.bnfcrExamHisPk
                    let user = this.$store.getters.getUserNm

                    delBnfcrExamHis(code, value, user)
                    .then((response) => ( this.delBnfcrExamHisAfter(response.data)))
                    .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        insBnfcrExam(){
            if(this.$refs.form.validate()){
                //수급자평가 내역 setting
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:this.bnMmbrPk,
                    bnfcrExamHisPk:this.bnfcrExamHisPk,
                    examClcd:'101',
                    examRsncd : this.items.examRsncd,
                    examDt : this.items.examDt.afterDateFormatHyp(),
                    wrtr : this.items.wrtr,
                    examYyyy : this.inqYYYY,
                }

                let  examList = []

                this.$refs.dsrEvltnTab1.selExamList().forEach(el => {
                    examList.push(el)
                });

                 this.$refs.dsrEvltnTab2.selExamList().forEach(el => {
                    examList.push(el)
                });

                 this.$refs.dsrEvltnTab3.selExamList().forEach(el => {
                    examList.push(el)
                });

                 this.$refs.dsrEvltnTab4.selExamList().forEach(el => {
                    examList.push(el)
                });

                this.$refs.dsrEvltnTab5.selExamList().forEach(el => {
                    examList.push(el)
                });

                this.$refs.dsrEvltnTab6.selExamList().forEach(el => {
                    examList.push(el)
                });

                this.$refs.dsrEvltnTab7.selExamList().forEach(el => {
                    examList.push(el)
                });

                this.progress = true
                
                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.bnfcrExamHisPk = res.result
                this.getExamHisInfo();
                this.getBnfcrExamList();
                //수급자 평가내역 Reload
                this.$emit('modalEvent', 99)     
                          
            }
            else{
                // this.snackControll([true, 5000, res.message, 'error'])
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }

            this.filledBtn[0].visible = false 
        },

        delBnfcrExamHisAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{
                this.$store.commit('setBnfcrExamHisPk', '')
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        frmReset:function(){
            this.tab=0            
            this.$refs.form.reset();
        },

        snackControll: function (options) {
            this.snackOptions = options
        },

        dsrEvltnReLoad:function(res){            
            //mmbrPk
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.bnfcrExamHisPk = res.result
                this.getExamHisInfo();
                this.getBnfcrExamList();
                //수급자 평가내역 Reload
                this.$emit('modalEvent', 99)                
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        getExamHisInfo(){
            this.progress = false
            if(this.bnfcrExamHisPk !='' && this.bnfcrExamHisPk > 0){
                let code = this.$store.getters.getFcltyNum;
                getBnfcrExamHisInfo(code, this.bnfcrExamHisPk)
                .then((response) => ( this.setData(response.data)))
                .catch((error) => console.log(error))
            }
        },
        setData(data){
            data.examDt = data.examDt.beforeDateFormatHyp()
            this.items = data
        },
        getBnfcrExamList(){
            if(this.getPreData){
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '101',
                    bnfcrExamHisPk:null,
                    bnMmbrPk:this.bnMmbrPk
                }
            }else{                
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '101',
                    bnfcrExamHisPk:this.bnfcrExamHisPk
                }
            }
            
            getBnfcrExamList(this.obj)
                .then((response) => ( this.getBnfcrExamListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrExamList : ' + error))
        },
        
        getBnfcrExamListAfter(res){
            let clsfcCdData1 = []
            let clsfcCdData2 = []
            let clsfcCdData3 = []
            let clsfcCdData4 = []
            let clsfcCdData5 = []
            let clsfcCdData6 = []
            let clsfcCdData7 = []
            let clsfcCdData999 = {}

            res.forEach(item => {
                if(this.getPreData) item.bnfcrExamPk = 0

                switch (item.clsfc2Cd) {
                    case '1':
                        clsfcCdData1.push(item)
                        break;
                    case '2':
                        clsfcCdData2.push(item)
                        break;
                    case '3':
                        clsfcCdData3.push(item)
                        break;
                    case '4':
                        clsfcCdData4.push(item)
                        break;
                    case '5':
                        clsfcCdData5.push(item)
                        break;
                    case '6':
                        clsfcCdData6.push(item)
                        break;
                    case '7':
                        clsfcCdData7.push(item)
                        break;
                    case '999':
                        clsfcCdData999 = item
                        break;
                    default:
                        break;
                }
            });

            this.$refs.dsrEvltnTab1.setData(clsfcCdData1)
            this.$refs.dsrEvltnTab2.setData(clsfcCdData2)
            this.$refs.dsrEvltnTab3.setData(clsfcCdData3)
            this.$refs.dsrEvltnTab4.setData(clsfcCdData4)
            this.$refs.dsrEvltnTab5.setData(clsfcCdData5)
            this.$refs.dsrEvltnTab6.setData(clsfcCdData6)
            this.$refs.dsrEvltnTab7.setData(clsfcCdData7)
            this.$refs.dsrEvltnTab6.setTotData(clsfcCdData999)

            if(this.getPreData) this.getPreData = false
        },

        //욕구사정평가 출력
        dsrEvltnPrt: function (){
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYmdStart = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYmdEnd = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYYY = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.bnMmbrPk = 0
            this.$parent.$parent.$parent.$parent.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.$parent.$parent.$parent.$parent.prtThrowObj.prtClcd = '1'
            this.$parent.$parent.$parent.$parent.prtThrowObj.prtCd = '40'
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.push(this.bnfcrExamHisPk)

            this.$parent.$parent.$parent.$parent.$refs.reportMgmt.prtDataCall(this.$parent.$parent.$parent.$parent.prtThrowObj)
            this.$parent.$parent.$parent.$parent.menu.splice(0, 1, true)
        },
        onModal: function () {
            this.prtWrtDt = this.items.examDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
    },
    
    data: () => ({  
        getPreData:false,
        minDate:'',
        maxDate:'', 
        prtWrtDt:'',
        tab: 0,       
        bnfcrExamHisPk:0,         
        items:{examDt:'',examRsncd:'',wrtr:''},
        menu: [false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        tabItems: [
            { index: 0, tab: '신체상태', style: 'font-size: 1.2rem !important;'},
            { index: 1, tab: '질병상태', style: 'font-size: 1.2rem !important;'},
            { index: 2, tab: '인지상태', style: 'font-size: 1.2rem !important;'},
            { index: 3, tab: '일반상태', style: 'font-size: 1.2rem !important;'},
            { index: 4, tab: '가족 및 지지체계', style: 'font-size: 1.2rem !important;'},
            { index: 5, tab: '수급자 및 보호자 개별 욕구ㆍ총평', style: 'font-size: 1.2rem !important;'},
            { index: 6, tab: '간호상태', style: 'font-size: 1.2rem !important;'},
        ],
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '이전자료', type:'action', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        dialogDelete:false,
        progress:false,
    }),
};
</script>