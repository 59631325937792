<template>
    <v-sheet>
        <div v-if="$parent.$parent.$parent.$parent.loadable" >
            <div
              
                class="d-inline-block"
                style="width: 100%; height: 38px; text-align: center;">
                <v-progress-linear
                    class="mb-1" color="yellow darken-2"
                    indeterminate>
                </v-progress-linear>
                <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
            </div>            
        </div>

        <div v-else class="d-flex">
            <div style="width:23%;font-size:0.9rem !important;">
                <div class="d-flex">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" 
                            style="width:50%;border-top: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium" style="height:25px;line-height:25px;">제공내용</span>
                        </div>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" 
                            style="width:50%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium" style="height:25px;line-height:25px;">제공계획</span>
                        </div>
                </div>
                <div class="d-flex">
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;">
                        <span style="height:22px;line-height:22px;">처리</span>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;">
                        <span style="height:28px;line-height:28px;">작성자</span>
                    </div>
                </div>
                <div class="d-flex" v-for="(item, i) in labels" :key="i" style="border-top: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:50%;" >
                            <span :style="item.style">{{item.title}}</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:50%;border-left: 1px solid #dbdbdb !important;">
                        {{item.cnt}}
                    </div>
                </div>
                <div class="d-flex" v-for="item in labels2" :key="item.title" style="border-top: 1px solid #dbdbdb !important;">
                    <div class="pa-1 ma-0" 
                        style="width:50%;">
                        <div :style="item.style" >
                            <div class="d-flex justify-center align-center">{{item.title}}</div>
                            <div v-if="item.subtitle != ''" class="d-flex justify-center align-center" style="font-size:0.8rem !important">
                                {{item.subtitle}}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:50%;border-left: 1px solid #dbdbdb !important;">
                        {{item.cnt}}
                    </div>
                </div>
                <div class="d-flex" style="border-top: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:50%;" >
                            <span style="height:28px;line-height:28px;">산책&#40;외출&#41; 동행</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:50%;border-left: 1px solid #dbdbdb !important;">
                    </div>
                </div>
                <div class="d-flex">
                    <div class="d-flex justify-center align-center pa-1 ma-0" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <span style="height:96px;line-height:96px;">특이사항&#40;30자초과시 별지첨부&#41;</span>
                    </div>
                </div>
            </div>
            <div class="careblnftsrv-input-wrap" v-for="(item,j) in dayitems" :key="j" style="position:relative;width:120px;font-size:0.9rem !important;">
                <v-form ref="tabform" lazy-validation>
                    <div v-if="(item.oldYn && item.wrtYn != '1') || item.bnftOfrItems.outYn == '1'" style="position:absolute;top:0px;left:0px;padding:0px 2px;width:100%;height:100%;z-index:7;">
                        <div style="width:100%;height:100%;background-color:black;opacity: 0.2;"></div>
                        <v-icon size="50" style="position:absolute;top:45%;left:33%;">mdi-pencil-off-outline</v-icon>
                        <div v-if="item.bnftOfrItems.outYn == '1'" style="position:absolute;top:53%;left:18%;">
                            <span class="font-weight-bold" style="font-size:2rem;color:rgba(0, 0, 0, 0.54)">외박중</span>
                        </div>
                    </div>
                    <div v-if="!item.oldYn && $parent.$parent.$parent.$parent.sysVal1 != '1'" style="position:absolute;top:0px;left:0px;padding:0px 2px;width:100%;height:100%;z-index:7;">
                        <div style="width:100%;height:100%;background-color:black;opacity: 0.2;"></div>
                        <v-icon size="50" style="position:absolute;top:45%;left:33%;">mdi-pencil-off-outline</v-icon>
                        <div v-if="item.bnftOfrItems.outYn == '1'" style="position:absolute;top:53%;left:18%;">
                            <span class="font-weight-bold" style="font-size:2rem;color:rgba(0, 0, 0, 0.54)"></span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div :class="['d-flex justify-center align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                            style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                            <div style="height:25px;line-height:25px;">                            
                                <span :class="['font-weight-medium', item.savechk ? 'red--text':'black--text']">{{item.date.afterDateFormatHyp().beforeDateFormatDot()}}</span>
                                <span :class="item.class">
                                    &#40;{{item.day}}&#41;
                                </span>
                            </div>
                        </div>                    
                    </div>
                    <div :class="['d-flex justify-center align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"                    
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;"> 
                        <div style="height:22px;">
                            <v-btn v-if="item.bnftOfrItems.bnftOfrRecHisPk == 0"
                                class="ml-1 px-1" 
                                color="#3C9099"
                                small 
                                min-width="22"
                                height="22"  
                                dark
                                @click="preData(j)"                            
                            >
                                <v-icon size="16">mdi-text-box-search-outline</v-icon>
                                <span>전일자료</span>
                            </v-btn>
                        </div>
                    </div> 
                    <div :class="['d-flex justify-center align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"                    
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:75px;margin-left:12px;">
                            <v-text-field v-model="item.bnftOfrItems.wrtr" color="blue" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="22"
                            height="22"  
                            dark
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(j)"
                        >
                            <v-icon size="16">mdi-account-edit</v-icon>
                        </v-btn>
                    </div> 
                    
                    <!-- 옷갈아입기 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">                    
                        <span style="width:25px">    
                            <v-icon v-if="item.bnftOfrItems.itm1Val > 0" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm1Val" @focus="item.bnftOfrItems.itm1Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>  
                    <!-- 세면하기 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"  
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:25px">
                            <v-icon v-if="item.bnftOfrItems.itm2Val > 0" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm2Val" @focus="item.bnftOfrItems.itm2Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div> 
                    <!-- 양치하기 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:25px">
                            <v-icon v-if="item.bnftOfrItems.itm3Val > 0" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm3Val" @focus="item.bnftOfrItems.itm3Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>  
                    <!-- 머리감기 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"  
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:25px">
                            <v-icon v-if="item.bnftOfrItems.itm5Val > 0" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm5Val" @focus="item.bnftOfrItems.itm5Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>  
                    <!-- 몸단장 -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"  
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:25px">
                            <v-icon v-if="item.bnftOfrItems.itm6Val > 0" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm6Val" @focus="item.bnftOfrItems.itm6Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div>  
                    <!-- 몸씻기 -->
                    <div :class="['d-flex justify-start number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">                         
                        <div class="pl-2">
                            <v-btn v-if="item.bnftOfrItems.bathSchdlPk > 0 && item.wrtYn == '1' && item.bnftOfrItems.outYn != '1'"
                                min-width="25" 
                                icon
                                @click="showDialog(0,j,item.bnftOfrItems.wrtr,item.bnftOfrItems.wrtrMmbrPk,item.bnftOfrItems.bnftOfrRecHisPk)"
                            >
                                    <v-icon v-if="item.bnftOfrItems.actBth != '' && item.bnftOfrItems.actBth != null" color="blue">
                                        mdi-text-box-check-outline
                                    </v-icon>
                                    <v-icon v-else color="red">
                                        mdi-text-box-edit-outline
                                    </v-icon>
                            </v-btn>
                        </div>
                        <div class="pl-1" style="height:25px;line-height:25px;" v-if="item.bnftOfrItems.bathSchdlPk > 0 && item.wrtYn == '1' && item.bnftOfrItems.outYn != '1'">
                            <div v-if="item.bnftOfrItems.actBth != '' && item.bnftOfrItems.actBth != null" style="text-align:center">
                                {{item.bnftOfrItems.actBth}}    
                            </div>
                            <div v-else style="text-align:center;color:red">                                    
                                {{item.bnftOfrItems.bthTm}}
                            </div>
                        </div>
                        <div v-else style="height:25px;line-height:25px;">                                                        
                        </div>
                    </div>  
                    <!-- 식사하기 -->
                    <div :class="['d-flex justify-start number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"  
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex" style="height:50px;padding-left:8px;">
                            <div>
                                <v-btn 
                                    min-width="25" 
                                    icon                                    
                                    @click="showDialog(1,j,item.bnftOfrItems.wrtr,item.bnftOfrItems.wrtrMmbrPk,item.bnftOfrItems.bnftOfrRecHisPk)"
                                >
                                    <v-icon v-if="item.bnftOfrItems.itmMeal.length > 0" color="blue">
                                        mdi-text-box-check-outline
                                    </v-icon>
                                    <v-icon v-else>
                                       mdi-text-box-edit-outline
                                    </v-icon>
                                </v-btn>
                            </div>
                            <div>                                                                
                                <span v-for="(itmMeal,n) in mealArr" :key="n">
                                    <v-chip                                        
                                        style="padding:0px !important; margin:1px;"
                                        label          
                                        :color="item.bnftOfrItems.itmMeal.indexOf(itmMeal) == -1?'gray':'blue'"                                                          
                                        outlined
                                        small>
                                    {{itmMeal}}
                                    </v-chip>
                                </span>
                                    
                            </div>
                        </div>
                    </div> 
                    <!-- 체위변경 -->
                    <div :class="['number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex" style="padding-left:8px;">           
                            <v-btn 
                                min-width="25" 
                                icon
                                @click="showDialog(2,j,item.bnftOfrItems.wrtr,item.bnftOfrItems.wrtrMmbrPk,item.bnftOfrItems.bnftOfrRecHisPk)">
                                <v-icon v-if="item.bnftOfrItems.itmPos>0" color="blue">
                                    mdi-text-box-check-outline
                                </v-icon>
                                <v-icon v-else-if="item.bnftOfrItems.bdsrYn == '1'" color="red">
                                    mdi-text-box-edit-outline
                                </v-icon>
                                <v-icon v-else>mdi-text-box-edit-outline</v-icon>
                            </v-btn>
                            <span style="width:50px" class="ml-1">
                                <v-text-field color="blue" v-model="item.bnftOfrItems.itmPos"  hide-details outlined dense disabled></v-text-field>
                            </span>                        
                            <span class="ml-1">
                                회
                            </span>
                        </div>
                    </div>  
                    <!-- 화장실 이용하기 -->
                    <div :class="['number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']"  
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex" style="padding-left:8px;height:38px">                                                        
                            <span v-if="item.bnftOfrItems.exrtYn =='1'">
                                <v-btn min-width="25" 
                                    icon @click="showDialog(4,j,item.bnftOfrItems.wrtr,item.bnftOfrItems.wrtrMmbrPk,item.bnftOfrItems.bnftOfrRecHisPk)">
                                        <v-icon v-if="item.bnftOfrItems.itmToil !='' && item.bnftOfrItems.itmToil != undefined" color="blue">
                                            mdi-text-box-check-outline
                                        </v-icon>
                                        <v-icon v-else>
                                        mdi-text-box-edit-outline
                                        </v-icon>
                                </v-btn>
                                <span class="pl-1" style="width:60px;">집중관찰</span>
                            </span>
                            <div v-else class="d-flex">
                                <span>
                                    <v-btn min-width="25" 
                                    icon @click="showDialog(3,j,item.bnftOfrItems.wrtr,item.bnftOfrItems.wrtrMmbrPk,item.bnftOfrItems.bnftOfrRecHisPk)">
                                        <v-icon v-if="item.bnftOfrItems.itmToil !='' && item.bnftOfrItems.itmToil != undefined" color="blue">
                                            mdi-text-box-check-outline
                                        </v-icon>
                                        <v-icon v-else>
                                        mdi-text-box-edit-outline
                                        </v-icon>
                                    </v-btn>
                                </span>
                                <span class="pl-1" style="width:60px;">{{item.bnftOfrItems.itmToil}}</span>
                            </div>
                        </div>
                    </div> 
                    <!-- 이동도움  -->
                    <div :class="['d-flex justify-center number-input align-center py-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;padding:10.5px 0px !important;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <span style="width:25px">
                            <v-icon v-if="item.bnftOfrItems.itm10Val > 0" color="blue">
                                mdi-check-circle-outline
                            </v-icon>
                            <v-icon v-else>
                                mdi-minus-circle-outline
                            </v-icon>
                        </span>
                        <span style="width:50px" class="ml-1">
                            <v-text-field v-model="item.bnftOfrItems.itm10Val" @focus="item.bnftOfrItems.itm10Val = '',onCompare(j)" @input="onCompare(j)" color="blue" :rules="[rules.blnftsrvNumChk]" hide-details outlined dense></v-text-field>
                        </span>                        
                        <span class="ml-1">
                            회
                        </span>
                    </div> 
                    <!-- 산책(외출) 동행  -->
                    <div :class="['d-flex justify-center number-input align-center py-0 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <div class="pa-1">
                            <v-btn small outlined 
                                style="padding:0px !important" 
                                :class="['',(item.bnftOfrItems.itm11Val_1 == '1')?'blueBtnColor':'']"
                                @click="onWalkOfOut(j,'1')">
                                <v-icon size="18" left style="padding:0px !important;margin:0px !important" :class="['',(item.bnftOfrItems.itm11Val_1 == '1')?'white--text':'']">
                                    mdi-walk
                                </v-icon>
                                <span :class="['',(item.bnftOfrItems.itm11Val_1 == '1')?'white--text':'']">산책</span>
                            </v-btn>
                            <v-btn class="ml-1" small outlined 
                                style="padding:0px !important" 
                                :class="['',(item.bnftOfrItems.itm11Val_2 == '1')?'blueBtnColor':'']"
                                @click="onWalkOfOut(j,'2')">
                                <v-icon size="18" left style="padding:0px !important;margin:0px !important" :class="['',(item.bnftOfrItems.itm11Val_2 == '1')?'white--text':'']">
                                    mdi-exit-run
                                </v-icon>
                                <span :class="['',(item.bnftOfrItems.itm11Val_2 == '1')?'white--text':'']">외출</span>
                            </v-btn>
                        </div>

                        <!-- <v-chip-group
                            class="careblnft-chip"
                            v-model="item.bnftOfrItems.itm11ValAr"
                            multiple
                        >
                            <v-chip                        
                                small
                                label
                                active-class="blue--text"
                                style="padding:5px !important; margin:2px;"
                                value="1"
                                @input="onCompare(j)"
                                outlined
                            >
                                산책
                            </v-chip>
                            <v-chip                        
                                small
                                label
                                active-class="blue--text"
                                style="padding:5px !important; margin:2px;"
                                value="2"
                                @input="onCompare(j)"
                                outlined
                            >
                                외출
                            </v-chip>
                        </v-chip-group> -->
                        <!-- {{item.bnftOfrItems.itm11ValAr}} -->
                    </div>   
                    <!-- 특이사항(50자초과시 별지첨부) -->
                    <div 
                    :class="['d-flex justify-center align-center pa-1 ma-0', (item.date.afterDateFormatHyp() == today)?'green lighten-5':'']" 
                        style="width:100%;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                        <v-textarea v-model="item.bnftOfrItems.spclNote" @input="onCompare(j)" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="5" outlined dense hide-details no-resize>
                        </v-textarea>
                    </div>    
                </v-form>        
            </div>
        </div>        
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
        </v-dialog>
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
    </v-sheet>
</template>
<script>
import { selPreBnftOfrRecList,  insMultiBnftRcrd} from '../../api/bnftrcrd.js';
import EmpSelector from '../commons/EmpSelector.vue';


export default {
    name: 'CareBlnftSrvRcrdTab1',
        
    components: {
        EmpSelector,
    },

    props : {        
    },

    created: function(){
        this.getToday()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {      
        
    },

    watch:{   
        
    },
        
    methods: {
        
        onLoad(){               
        },
        clearData(){                                 
            this.labels.forEach(x=>{
                x.cnt = ''
            })            
        },
        async frmReset(){
            await this.$nextTick();
            if(this.frmChk.length > 0){
                this.frmChk.forEach(x=>{
                    this.$refs.tabform[x].resetValidation()
                })
            }
        },
        getToday:function(){
            // let date = new Date()
            // this.today = date.getFullYear()+('00'+(date.getMonth()+1).toString()).slice(-2)+('00'+(date.getDate()).toString()).slice(-2)
            this.today = this.$moment().add(1, 'days')
        },        
        getBnftOfrRecWeekListAfter:function(res){
            
            this.dayitems.splice(0)
            this.setlabels()
            if(res != '' && res != null && res != undefined){
                if(res.weekList.length > 0){
                    res.weekList.forEach((x,i)=>{
                        let dayclass = "black--text font-weight-medium"
        
                        if(i==5) dayclass = "blue--text font-weight-medium"
                        else if(i==6) dayclass = "red--text font-weight-medium"
    
                        let day     = this.$moment(x.day+' '+this.$parent.$parent.$parent.$parent.sysVal2,'YYYYMMDD hh:mm')
                        let yn = this.$moment.duration(this.today.diff(day)).asMinutes()                       
                        // let oldYn   = this.$moment(this.today,'YYYYMMDD').isSameOrAfter(day)
                        let oldYn = yn > 0
    
                        let obj = {
                            day:this.days[i],
                            date:x.day,
                            class:dayclass,
                            savechk:false,
                            wrtYn:x.wrtYn,
                            oldYn :oldYn,
                            bnftOfrItems:{
                                bnftOfrRecHisPk:Number(x.recInfo.recInfo1.bnftOfrRecHisPk),
                                bnftOfrRecPk:Number(x.recInfo.recInfo1.bnftOfrRecPk),
                                wrtrMmbrPk:Number(x.recInfo.recInfo1.wrtrMmbrPk),
                                wrtr:x.recInfo.recInfo1.wrtr,
                                recDt:x.day,
                                clsfc1Cd:'301',
                                clsfc2Cd:'1',
                                clsfc3Cd:'1',
                                spclNote:x.recInfo.recInfo1.spclNote,
                                itm1Cd:'1',
                                itm1Val:x.recInfo.recInfo1.itm1Val,
                                itm2Cd:'2',
                                itm2Val:x.recInfo.recInfo1.itm2Val,
                                itm3Cd:'3',
                                itm3Val:x.recInfo.recInfo1.itm3Val,
                                itm5Cd:'5',
                                itm5Val:x.recInfo.recInfo1.itm5Val,
                                itm6Cd:'6',
                                itm6Val:x.recInfo.recInfo1.itm6Val,
                                itm10Cd:'10',
                                itm10Val:x.recInfo.recInfo1.itm10Val,
                                itm11Cd:'11',
                                itm11ValAr:x.recInfo.recInfo1.itm11ValAr,
                                itm11Val_1:'',
                                itm11Val_2:'',
                                itmBth:x.itmBth,
                                itmMeal:[],
                                itmPos:x.itmPos,
                                itmToil:x.itmToil,
                                actBth:x.actBth,
                                bthTm:x.bthTm,                            
                                bathSchdlPk:x.bathSchdlPk,
                                intbNutrYn:x.intbNutrYn,
                                cathYn:x.cathYn,
                                dprYn:x.dprYn,
                                bdsrYn:x.bdsrYn,
                                exrtYn:x.exrtYn,
                                outYn:x.outYn,
                            }
                        }
    
                        if(x.itmMeal != null){
                            x.itmMeal.forEach(x=>{
                                obj.bnftOfrItems.itmMeal.push(x)
                            })
                        }
    
                        if(typeof x.recInfo.recInfo1.itm11ValAr == "object" && x.recInfo.recInfo1.itm11ValAr != null) {
                            x.recInfo.recInfo1.itm11ValAr.forEach(el => {
                                if(el == '1') obj.bnftOfrItems.itm11Val_1 = '1'
                                else if(el == '2') obj.bnftOfrItems.itm11Val_2 = '1'
                            });
                        }
                        this.dayitems.push(obj)
                    })
    
                    this.dummyDayitems = JSON.parse(JSON.stringify(this.dayitems))
                }


                res.bnftOfrPlnList.forEach(x => {
                    if(x.clsfc2Cd == '1'){
                        switch(x.clsfc3Cd){
                            case "1":
                                this.labels[0].cnt = this.getCnt(x)
                            break;
                            case "2":
                                this.labels[1].cnt = this.getCnt(x)
                            break;
                            case "3":
                                this.labels[2].cnt = this.getCnt(x)
                            break;
                            case "4":
                                this.labels[5].cnt = this.getCnt(x)
                            break;
                            case "5":
                                this.labels[3].cnt = this.getCnt(x)
                            break;
                            case "6":
                                this.labels[4].cnt = this.getCnt(x)
                            break;
                            default: 
                            break;
                        }                
                    }
                });
            }


            this.frmReset()
            this.$parent.$parent.$parent.$parent.loadable = false
        },
        getCnt:function(obj){
            let cnt = ''
            this.$parent.$parent.$parent.$parent.vCd45.forEach(e=>{
                if(e.valcd == obj.itmVal1){
                    cnt = e.valcdnm
                }
            })
            if(obj.itmVal2 != null && obj.itmVal2 != ''){
                cnt += obj.itmVal2+'회'
            }
            if(obj.itmVal3 != null && obj.itmVal3 != ''){
                cnt += '/'+obj.itmVal3+'분'
            }
            return cnt
        },         
        setlabels:function(){
            this.labels.splice(0)
            this.labels2.splice(0)

            let obj0 = {title:'옷갈아입기',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj0) //옷갈아입기
            let obj1 = {title:'세면하기',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj1) //세면하기
            let obj2 = {title:'양치하기',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj2) //양치하기
            let obj3 = {title:'머리감기',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj3) //머리감기
            let obj4 = {title:'몸단장',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj4) //몸단장
            let obj5 = {title:'몸씻기',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj5) //몸씻기
            let obj6 = {title:'식사하기',subtitle:'',cnt:'',style:'height:50px;line-height:50px;'}
            this.labels.push(obj6) //몸씻기
            let obj7 = {title:'체위변경',subtitle:'',cnt:'',style:'height:25px;line-height:25px;'}
            this.labels.push(obj7) //체위변경

            let obj8 = {title:'화장실 이용하기',subtitle:"",cnt:'',style:'height:38px;line-height:38px;'}
            this.labels2.push(obj8) //화장실 이용하기
            let obj9 = {title:'이동도움',subtitle:'신체 기능유지, 증진',cnt:'',style:'height:38px;'}
            this.labels2.push(obj9) //이동도움            
        },  
        onSave:function(dupYn = '1'){
            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){
                let savego = true
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                    userNm      : this.$store.getters.getUserNm,
                    dupList     : [],
                    hisList     : []
                }

                this.dayitems.forEach((x,idx)=>{
                    
                    let hisObj = {
                        clsfc1Cd    : '',
                        clsfc2Cd    : '',
                        clsfc3Cd    : '',
                        recDt       : '',
                        cntnt       : '',
                        spclNote    : '',
                        wrtr        : '',
                        wrtrMmbrPk  : 0,
                        recList     : [],
                    }

                    if(x.savechk){                        
                        if(!this.$refs.tabform[idx].validate()){
                            savego = false
                        }else{                            

                            hisObj.clsfc1Cd         = x.bnftOfrItems.clsfc1Cd
                            hisObj.clsfc2Cd         = x.bnftOfrItems.clsfc2Cd
                            hisObj.clsfc3Cd         = x.bnftOfrItems.clsfc3Cd
                            hisObj.recDt            = x.bnftOfrItems.recDt
                            hisObj.spclNote         = x.bnftOfrItems.spclNote
                            hisObj.cntnt            = x.bnftOfrItems.cntnt
                            hisObj.wrtr             = x.bnftOfrItems.wrtr
                            hisObj.wrtrMmbrPk       = x.bnftOfrItems.wrtrMmbrPk

                            let recObj    = {
                                itm1Cd      : x.bnftOfrItems.itm1Cd,
                                itm1Val     : x.bnftOfrItems.itm1Val,
                                itm2Cd      : x.bnftOfrItems.itm2Cd,
                                itm2Val     : x.bnftOfrItems.itm2Val,
                                itm3Cd      : x.bnftOfrItems.itm3Cd,
                                itm3Val     : x.bnftOfrItems.itm3Val,
                                itm5Cd      : x.bnftOfrItems.itm5Cd,
                                itm5Val     : x.bnftOfrItems.itm5Val,
                                itm6Cd      : x.bnftOfrItems.itm6Cd,
                                itm6Val     : x.bnftOfrItems.itm6Val,
                                itm10Cd     : x.bnftOfrItems.itm10Cd,
                                itm10Val    : x.bnftOfrItems.itm10Val,
                                itm11Cd     : x.bnftOfrItems.itm11Cd,
                                itm11ValAr  : [],
                                wrtr        : x.bnftOfrItems.wrtr,
                                wrtrMmbrPk  : x.bnftOfrItems.wrtrMmbrPk,
                            }

                            if(x.bnftOfrItems.itm11Val_1 == '1') recObj.itm11ValAr.push('1')
                            if(x.bnftOfrItems.itm11Val_2 == '1') recObj.itm11ValAr.push('2')

                            //신규 등록건의 대한 중복 확인
                            if(x.bnftOfrItems.bnftOfrRecHisPk == 0 && dupYn == '1'){
                                let dupObj = {
                                    clsfc1Cd  : x.bnftOfrItems.clsfc1Cd,
                                    clsfc2Cd  : x.bnftOfrItems.clsfc2Cd,
                                    clsfc3Cd  : x.bnftOfrItems.clsfc3Cd,
                                    recDt     : x.bnftOfrItems.recDt,
                                }

                                obj.dupList.push(dupObj)
                            } 

                            hisObj.recList.push(recObj)                            
                            obj.hisList.push(hisObj)

                        }
                    }                    
                })

                if(savego && obj.hisList.length > 0){
                    insMultiBnftRcrd(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))
                }else{
                    this.$emit("msgTrigger","수정 사항이 없습니다.")
                }

            }else{
                this.$emit("msgTrigger","수급자를 선택해주세요.")
            }
        },
        onSaveAfter:function(res){
            this.$emit("careBlnftTrigger",res)            
        },
        getEmpInfo: function (obj) {
            this.dayitems[this.idx].bnftOfrItems.wrtr = obj[0].name
            this.dayitems[this.idx].bnftOfrItems.wrtrMmbrPk = obj[0].mmbrPk
            this.onCompare(this.idx)
            this.idx = 0
        },
        isModal: function () {
            this.menu.splice(0, 1, false)
            this.prtWrtDt = ""
        },        
        onModal: function (idx) {  
            this.prtWrtDt = this.dayitems[idx].date
            this.idx = idx
            this.menu.splice(0, 1, true)
        },
        onCompare: function(idx){
            let entInpObj = Object.entries(this.dayitems[idx].bnftOfrItems).toString()
            let entDumyObj = Object.entries(this.dummyDayitems[idx].bnftOfrItems).toString()
            if(entInpObj != entDumyObj){                
                this.dayitems[idx].savechk = true
            }else{
                this.dayitems[idx].savechk = false
            }
        },
        onWalkOfOut:function(idx,type){
            if(type=='1'){
                this.dayitems[idx].bnftOfrItems.itm11Val_1 = (this.dayitems[idx].bnftOfrItems.itm11Val_1 == '1')?'':'1'
            }else if(type=='2'){
                this.dayitems[idx].bnftOfrItems.itm11Val_2 = (this.dayitems[idx].bnftOfrItems.itm11Val_2 == '1')?'':'1'
            }

            this.onCompare(idx)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        showDialog(key, idx, wrtr, wrtrpk, hispk){ 
            let inqYMD =  this.dayitems[idx].date
            let cathYn = this.dayitems[idx].bnftOfrItems.cathYn
            let dprYn = this.dayitems[idx].bnftOfrItems.dprYn
            this.$parent.$parent.$parent.$parent.empWrtr = wrtr
            this.$parent.$parent.$parent.$parent.empWrtrpk = wrtrpk
            this.$emit("dialog",key,inqYMD,hispk,cathYn,dprYn)
        },        
        preData:function(idx){            
            this.idx = idx                
            let date = this.dayitems[idx].bnftOfrItems.recDt  
            this.getPreData(date)
        },
        getPreData:function(ymd){            
            let bnmmbr = this.$parent.$parent.$parent.$parent.bnMmbrPk

            if(bnmmbr > 0){
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : bnmmbr,
                    inqYMD : ymd,
                    clsfc1Cd:'301',
                    clsfc2Cd:'1',
                    clsfc3Cd:'1',
                }
                selPreBnftOfrRecList(obj)
                            .then((response) => ( this.getPreDataAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftoffer/selBnfcrBnftOfrPlnList : ' + error))
            }
        },
        getPreDataAfter:function(res){ 
            res.forEach(el => {                
                this.dayitems[this.idx].bnftOfrItems.clsfc1Cd = el.clsfc1Cd
                this.dayitems[this.idx].bnftOfrItems.clsfc2Cd = el.clsfc2Cd
                this.dayitems[this.idx].bnftOfrItems.clsfc3Cd = el.clsfc3Cd
                this.dayitems[this.idx].bnftOfrItems.itm1Cd = el.itm1Cd
                this.dayitems[this.idx].bnftOfrItems.itm1Val = el.itm1Val
                this.dayitems[this.idx].bnftOfrItems.itm2Cd = el.itm2Cd
                this.dayitems[this.idx].bnftOfrItems.itm2Val = el.itm2Val
                this.dayitems[this.idx].bnftOfrItems.itm3Cd = el.itm3Cd
                this.dayitems[this.idx].bnftOfrItems.itm3Val = el.itm3Val
                this.dayitems[this.idx].bnftOfrItems.itm5Cd = el.itm5Cd
                this.dayitems[this.idx].bnftOfrItems.itm5Val = el.itm5Val
                this.dayitems[this.idx].bnftOfrItems.itm6Cd = el.itm6Cd
                this.dayitems[this.idx].bnftOfrItems.itm6Val = el.itm6Val
                this.dayitems[this.idx].bnftOfrItems.itm10Cd = el.itm10Cd
                this.dayitems[this.idx].bnftOfrItems.itm10Val = el.itm10Val
                this.dayitems[this.idx].bnftOfrItems.itm11Cd = el.itm11Cd
                this.dayitems[this.idx].bnftOfrItems.itm11ValAr = el.itm11ValAr
                this.dayitems[this.idx].bnftOfrItems.spclNote = el.spclNote
                this.dayitems[this.idx].bnftOfrItems.wrtr = el.wrtr
                this.dayitems[this.idx].bnftOfrItems.wrtrMmbrPk = el.wrtrMmbrPk

                if(typeof el.itm11ValAr == "object" && el.itm11ValAr != null) {
                    el.itm11ValAr.forEach(val => {
                        if(val == '1') this.dayitems[this.idx].bnftOfrItems.itm11Val_1 = '1'
                        else if(val == '2') this.dayitems[this.idx].bnftOfrItems.itm11Val_2 = '1'
                    });
                }

                this.onCompare(this.idx)
            });
        },  
    },

        
    
    data: () => ({
        prtWrtDt:'20230101',
        tab:0,
        startDay:'',
        endDay:'',        
        idx:0,
        today:'',
        frmChk:[0,1,2,3,4,5,6],
        labels:[{title:'',subtitle:'',cnt:'',style:''}],
        labels2:[{title:'',subtitle:'',cnt:'',style:''}],
        days:['월','화','수','목','금','토','일'],        
        dayitems:[
            {day:'',date:'',class:'',savechk:false, wrtYn:'', oldYn:'',
                bnftOfrItems:{
                    bnftOfrRecHisPk:0,
                    bnftOfrRecPk:0,
                    wrtrMmbrPk:0,
                    wrtr:'',
                    recDt:'',
                    clsfc1Cd:'301',
                    clsfc2Cd:'1',
                    clsfc3Cd:'1',
                    spclNote:'',
                    itm1Cd:'1',
                    itm1Val:0,
                    itm2Cd:'2',
                    itm2Val:0,
                    itm3Cd:'3',
                    itm3Val:0,
                    itm5Cd:'5',
                    itm5Val:0,
                    itm6Cd:'6',
                    itm6Val:0,
                    itm10Cd:'10',
                    itm10Val:0,
                    itm11Cd:'11',
                    itm11ValAr:[],
                    itm11Val_1:'',
                    itm11Val_2:'',
                    itmBth:'',itmMeal:[],itmPos:'',itmToil:'',actBth:'',bthTm:'',bathSchdlPk:0,intbNutrYn:'',cathYn:'',dprYn:'',bdsrYn:'', exrtYn:'', outYn:'',
                }
            }],
        dummyDayitems:[
            {day:'',date:'',class:'',savechk:false, wrtYn:'', oldYn:'',
                bnftOfrItems:{
                    bnftOfrRecHisPk:0,
                    bnftOfrRecPk:0,
                    wrtrMmbrPk:0,
                    wrtr:'',
                    recDt:'',
                    clsfc1Cd:'301',
                    clsfc2Cd:'1',
                    clsfc3Cd:'1',
                    spclNote:'',
                    itm1Cd:'1',
                    itm1Val:0,
                    itm2Cd:'2',
                    itm2Val:0,
                    itm3Cd:'3',
                    itm3Val:0,
                    itm5Cd:'5',
                    itm5Val:0,
                    itm6Cd:'6',
                    itm6Val:0,
                    itm10Cd:'10',
                    itm10Val:0,
                    itm11Cd:'11',
                    itm11ValAr:[],
                    itm11Val_1:'',
                    itm11Val_2:'',
                    itmBth:'',itmMeal:[],itmPos:'',itmToil:'',actBth:'',bthTm:'',bathSchdlPk:0,intbNutrYn:'',cathYn:'',dprYn:'',bdsrYn:'', exrtYn:'', outYn:'',
                },
            }],
        menu: [false],
        onCheked:true,
        rules: {
            required: value => !!value || 'Required.',
            blnftsrvNumChk: value => (value > -1 && value < 100) || '',
        },
        snackOptions: [false, 3000, '', 'info'],  
        mealArr:['아침','점심','저녁','오전','오후'],
        chips:[1,2],
    }),
};
</script>
<style>
.careblnftsrv-input-wrap .v-input {font-size:0.9rem !important;}
.careblnftsrv-input-wrap .v-text-field input {padding-left:5px;}
.careblnftsrv-input-wrap .emp-find-input .v-input__slot {min-height:25px !important;}
.careblnftsrv-input-wrap .number-input .v-input__slot {min-height:25px !important;padding:0px 5px !important;}
.careblnftsrv-input-wrap .v-btn--icon.v-size--default {width:20px !important;height:20px !important}
.careblnft-chip .v-chip-group .v-slide-group__content {padding:0px !important}
</style>