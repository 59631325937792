<template>
    <v-row class="ma-0 pa-0" no-gutters>
        <v-col class="d-flex align-center my-2" cols="12">
            <v-icon style="font-size: 26px;" color="black">mdi-chevron-right</v-icon>
            <span class="font-weight-medium  ml-1 mr-3">
                활동기간&nbsp;&#58;
            </span>
            <span class="d-inline-block black--text" style="width: 130px;">
                <CmmDateComp v-model="wrkngPrdStrt" :required="true" :maxDt="wrkngPrdEnd"></CmmDateComp>
            </span>
            <span class="d-flex align-center mx-2">
                <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
            </span>
            <span class="d-inline-block black--text" style="width: 130px;">
                <CmmDateComp v-model="wrkngPrdEnd" :required="true" :minDt="wrkngPrdStrt"></CmmDateComp>
            </span>
        </v-col>
        <v-col cols="12">
            <v-divider :class="['mt-1', wrkRecList.length > 14 ? 'mr-2__5' : '']"></v-divider>
            <v-row :class="['greyE01 pa-0', wrkRecList.length > 14 ? 'mr-2__5' : 'ma-0']" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                    <span class="black--text font-weight-medium">직종</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                    <span class="black--text font-weight-medium">이름</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                    <span class="black--text font-weight-medium">소속</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                    <span class="black--text font-weight-medium">활동일자</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="3">
                    <span class="black--text font-weight-medium">내용</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                    <span class="black--text font-weight-medium">확인자</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="1">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
            </v-row>
            <v-divider :class="[wrkRecList.length > 14 ? 'mr-2__5' : '']"></v-divider>
        </v-col>
        <v-col class="" v-if="wrkRecList.length > 0" cols="12">
            <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 630px;">
                <v-row class="ma-0 pa-0" style="border-bottom: 1px solid #dbdbdb;"
                    v-for="(list, i) in wrkRecList" :key="i" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                        <span class="black--text">{{ list.jobCd }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                        <span class="black--text">{{ list.empNm }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                        <span class="black--text">{{ list.afltnNm }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                        <span class="black--text">{{ list.wrkDt }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="3">
                        <span class="black--text">{{ list.wrkCntnt }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                        <span class="black--text">{{ list.cnfmNm }}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-1" cols="1">
                        <span v-if="list.jobCd == '자원봉사자'" class="">
                            <v-btn @click="menu.splice(2, 1, true), sltPk=list.bzPk" icon>
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </span>
                        <span v-if="list.jobCd == '외부강사'" class="">
                            <v-btn @click="prgmOpener(list), sltPk=list.bzPk" icon>
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col class="" v-else-if="wrkRecList.length == 0" cols="12">
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center py-3" cols="12">
                    <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-col>
        <v-dialog v-model="menu[2]" max-width="900" content-class="round" persistent>
            <MdfVlntrWorkJrnl ref="vlntrWorkJrnl" :bzpk="sltPk" @modalClose="mdlCloser"></MdfVlntrWorkJrnl>
        </v-dialog>
    </v-row>
</template>

<script>
import { selExtEmpWrkRecList } from '../../api/emp.js';
import CmmDateComp from '../commons/CmmDateComp.vue';
import MdfVlntrWorkJrnl from './MdfVlntrWorkJrnl.vue';

export default {
    name: 'PrdWorkJrnl',
    
    components: {
        MdfVlntrWorkJrnl,
        CmmDateComp,
    },

    props : {

    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.now = this.$moment().format('YYYY-MM-DD')
        })
    },

    computed: {
        getRootEvt: function () {
            return this.$store.getters.getRootRunEvt
        },
    },  

    watch: {
        getRootEvt: function (bol) {
            if(bol){
                this.callList()
                this.$store.commit('setRootRunEvt', false)
            }
        },
        'now': function () {
            this.wrkngPrdStrt = this.now
            this.wrkngPrdEnd = this.now
        },
        'wrkngPrdStrt': function () {
            if(this.cpmStt == 'init')
                this.cpmStt = 'loaded'
            else
                this.callList()
        },
        'wrkngPrdEnd': function () {
            if(this.cpmStt == 'init')
                this.cpmStt = 'loaded'
            else
                this.callList()
        },
    },

    methods: {
        getExtEmpWrkRecList: function (ymdStrt, ymdEnd) {
            selExtEmpWrkRecList(this.$store.getters.getFcltyNum, ymdStrt, ymdEnd)
                .then((response) => ( this.getExtEmpWrkRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selExtEmpWrkRecList : ' + error))
        },
        getExtEmpWrkRecListAfter: function (res) {
            //this.$parent.$parent.$parent.$parent.$parent.$parent
            res.forEach(items => {
                items.wrkDt = items.wrkDt?.beforeDateFormatDot()
                
                this.$parent.$parent.$parent.$parent.$parent.$parent.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });
            });
            this.wrkRecList = res
        },
        callList: function () {
            let strt = this.wrkngPrdStrt?.afterDateFormatHyp()
            let end = this.wrkngPrdEnd?.afterDateFormatHyp()
            
            if(this.wrkngPrdStrt != '' && this.wrkngPrdEnd != '')
                this.getExtEmpWrkRecList(strt, end)
        },
        extCallList: function (dt) {
            this.wrkngPrdStrt = dt?.beforeDateFormatHyp()
            this.wrkngPrdEnd = dt?.beforeDateFormatHyp()
            this.getExtEmpWrkRecList(dt, dt)
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        mdlCloser: function (index) {
            this.menu.splice(index, 1, false)
            this.sltPk = -1
        },
        prgmOpener: function (obj) {
            let tmp = {}
            tmp.prgOpPlnPk = obj.prgOpPlnPk
            tmp.prgOpRecPk = obj.bzPk
            tmp.prgInfPk = obj.prgInfPk
           
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1300, prsst: true, typ: '4-01', inObj: tmp, outObj: null, runEvt: false,
            });
        },
        reset: function () {
            this.callList()
        },
    },

    data: () => ({
        now: '',
        sltPk: -1,
        cpmStt: 'init',
        menu: [false, false, false],
        wrkngPrdStrt: '',
        wrkngPrdEnd: '',
        wrkRecList: [], 
    }),
};
</script>