<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-center align-center">
                <span class="text-h4 font-weight-bold">프로그램&nbsp;의견수렴&nbsp;상세</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <div class="d-flex justify-center pl-1 pr-3 mt-5">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" :class="list.class" samll height="30" :color="list.color"
                        @click="onClickBtn(list.text)" rounded>
                        <v-icon small>{{ list.icon }}</v-icon>
                        <span style="font-size: 1rem;">{{ list.text }}</span>
                    </v-btn>
                </span>
            </div>
            <v-row class="mt-5">
                <v-col cols="6">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">프로그램 의견</span>
                    <v-row class="mt-1"
                        style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="3">
                            <span class="black--text font-weight-medium">작성분기</span>
                        </v-col>
                        <v-col cols="3">
                            <span style="width: 90%; ">
                                <v-select v-model="items.wrtQrtr" :items="qtr" item-text="valcdnm" item-value="valcd" dense
                                    hide-details outlined>
                                </v-select>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="3">
                            <span class="black--text font-weight-medium">작성일</span>
                        </v-col>
                        <v-col cols="3">
                            <!-- <span class="d-inline-block ml-2" style="width: 120px;">
                                <v-menu ref="menu1" v-model="menu[1]" min-width="100" max-width="300"
                                    :close-on-content-click="false" :return-value.sync="items.wrtDt"
                                    transition="scale-transition" offset-y dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="items.wrtDt" :rules="[rules.required]" readonly hide-details
                                            outlined dense v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker :key="forceRender" v-model="items.wrtDt" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="datePickClose(1)">Cancel</v-btn>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="$refs.menu1.save(items.wrtDt)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </span> -->
                            <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                                <CmmDateComp v-model="items.wrtDt" :required="true"></CmmDateComp>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row style="border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="3">
                            <span class="black--text font-weight-medium">지표구분</span>
                        </v-col>
                        <v-col cols="3">
                            <span style="width: 90%; ">
                                <v-select v-model="idxCd" :items="cdItems" item-text="valcdnm" item-value="valcd" dense
                                    hide-details outlined>
                                </v-select>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="3">
                            <span class="black--text font-weight-medium">작성자</span>
                        </v-col>
                        <v-col cols="3">
                            <span class="d-inline-block py-1 pl-2 " style="width: 100px;">
                                <v-text-field v-model="items.wrtr" color="blue" ref="info" :rules="[rules.required]"
                                    hide-details outlined dense :key="forceRender" readonly></v-text-field>
                            </span>
                            <v-btn class="brown lighten-3 d-inline-block ml-1 mb-1"
                                style="padding:0px 1px 0px 2px !important" dark small depressed min-width="30" height="28"
                                @click="onModal(0)">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>직원선택</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="" style="border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="3">
                            <div style="text-align: center;">
                                <span class="black--text font-weight-medium">수급자&nbsp;(보호자)</span>
                                <span class="black--text font-weight-medium"><br>의견&nbsp;수렴&nbsp;내용</span>
                            </div>
                        </v-col>
                        <v-col class="" cols="9">
                            <v-textarea rows="20" class="rmrksArea" no-resize outlined dense hide-details v-model="items.imprvOpn" :rules="[rules.required]">
                            </v-textarea>

                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="5" class="">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">프로그램 의견 반영</span>
                    <v-row class="mt-1"
                        style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="3">
                            <span class="black--text font-weight-medium">반영일</span>
                        </v-col>
                        <v-col cols="9">
                            <!-- <span class="d-inline-block ml-2" style="width: 120px;">
                                <v-menu ref="menu2" v-model="menu[2]" min-width="100" max-width="300"
                                    :close-on-content-click="false" :return-value.sync="items.rflDt"
                                    transition="scale-transition" offset-y dense>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="items.rflDt" readonly hide-details
                                            outlined dense v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker :key="forceRender" v-model="items.rflDt" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="datePickClose(2)">Cancel</v-btn>
                                        <v-btn class="font-weight-bold" color="blueBtnColor" text
                                            @click="$refs.menu2.save(items.rflDt)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </span> -->
                            <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                                <CmmDateComp v-model="items.rflDt"></CmmDateComp>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row style="border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="3">
                            <span class="black--text font-weight-medium">작성자</span>
                        </v-col>
                        <v-col cols="9">
                            <span class="d-inline-block py-1 pl-2" style="width: 100px;">
                                <v-text-field v-model="items.rflWrtr" color="blue" ref="info"
                                    hide-details outlined dense :key="forceRender" readonly></v-text-field>
                            </span>
                            <v-btn class="brown lighten-3 d-inline-block ml-1 mb-1"
                                style="padding:0px 1px 0px 2px !important" dark small depressed min-width="30" height="28"
                                @click="onModal(1)">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>직원선택</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="" style="border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="3">
                            <span class="black--text font-weight-medium">의견수렴<br>결과반영</span>
                        </v-col>
                        <v-col class="" cols="9">
                            <v-textarea rows="20" class="rmrksArea" no-resize  outlined dense hide-details v-model="items.imprvRfl">
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="dialog" eager content-class="round" persistent max-width="800">
            <EmpSelector ref="EmpSelector" @isClose="dialog=false" @outEmp="getEmpInfo" :prtWrtDt = "''"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'프로그램 평가'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { getStcd } from '../../api/index.js';
import { selPrgImprvInfo, insPrgImprv, delPrgImprv } from '../../api/prgrm.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
export default {
    name: 'insPrgImprv',

    components: {
        EmpSelector,
        btnModalDelete,
        CmmDateComp,

    },
    props: {
    },
    created: function () {
        let today = new Date()
        this.items.wrtDt  = this.getDateHyp(today)
        this.items.rflDt  = this.getDateHyp(today)
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        selPrgImprvInfo: function (pk) {
            this.prgImprvPk = pk
            selPrgImprvInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.selPrgImprvInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgImprvInfo : ' + error))
        },
        selPrgImprvInfoAfter: function (res) {
            res.wrtDt = res.wrtDt?.beforeDateFormatHyp()
            if(res.rflDt.length != ''){
                res.rflDt = res.rflDt?.beforeDateFormatHyp()
            }
            this.items = res
            this.idxCd = res.idxCd
        },
        onClose: function () {
            this.$emit('isClose', '.')
            this.resetPage()

        },
        onCodeload() {
            getStcd('40')
                .then((response) => (this.codeMapping(response.data)))
                .catch((error) => console.log(error))
        },
        codeMapping: function (res) {
            this.cdItems = res

        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            if (this.value == 0) {
                this.items.wrtr = obj[0].name
            } else if (this.value == 1) {
                this.items.rflWrtr = obj[0].name
            }
        },
        getEmpInfo1: function (obj) {
            if (this.value == 0) {
                this.items.wrtr = obj[0].name
            } else if (this.value == 1) {
                this.items.rflWrtr = obj[0].name
            }
        },
        onModal: function (value) {
            this.value = value
            this.dialog = true
        },
        resetPage: function () {
            this.items = Object.assign({}, { prgImprvPk: 0, wrtQrtr: 0, wrtYr: "", wrtr: "", wrtDt: "", rflWrtr: "", rflDt: "", imprvRfl: "", imprvOpn: "" })
            this.idxCd = '1'
            this.$refs.mdlform.resetValidation()
        },
        onClickBtn: function (text) {
            if (text == '저장') {
                if(this.$refs.mdlform.validate()){
                    this.insPrgImprv()
                }else{
                    this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
                }
            }
            else if (text == '삭제') {
                this.onDelete()
            }
        },
        insPrgImprv: function () {
            if (this.$refs.mdlform.validate()) {
                let obj = {
                    prgImprvPk: this.items.prgImprvPk,
                    wrtYr: this.$parent.$parent.$parent.$parent.$parent.$parent.yDate,
                    wrtQrtr: this.items.wrtQrtr,
                    idxCd: this.idxCd,
                    rflDt: this.items.rflDt?.afterDateFormatHyp(),
                    wrtDt: this.items.wrtDt?.afterDateFormatHyp(),
                    imprvOpn: this.items.imprvOpn,
                    imprvRfl: this.items.imprvRfl,
                    wrtr: this.items.wrtr,
                    rflWrtr: this.items.rflWrtr,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                }
                insPrgImprv(obj)
                    .then((response) => (this.insPrgImprvAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/insPrgInf : ' + error))
            }
        },
        insPrgImprvAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        onDelete() {
            this.dialogDelete = true
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.prgImprvPk != undefined) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgImprvPk: this.prgImprvPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delPrgImprv(obj)
                        .then((response) => (this.delPrgImprvAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }

        },
        delPrgImprvAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onClose()
                this.dialogDelete = false
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        getDateHyp(date){
            return date.getFullYear()+"-"+('00'+(date.getMonth()+1).toString()).slice(-2)+"-"+('00'+(date.getDate()).toString()).slice(-2)
        },
    },
    data: () => ({
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action' },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color: 'grey006', text: '삭제', type: 'action' },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        qtr: [{ valcd: 0, valcdnm: '선택' }, { valcd: 1, valcdnm: '1분기' }, { valcd: 2, valcdnm: '2분기' }, { valcd: 3, valcdnm: '3분기' }, { valcd: 4, valcdnm: '4분기' }],
        dialog: false,
        menu: [false, false],
        items: { wrtQrtr: 0, wrtYr: "", wrtr: "", wrtDt: "", rflWrtr: "", rflDt: "", imprvRfl: "", imprvOpn: "" },
        cdItems: [],
        snackOptions: [false, 3000, '', 'info'],
        PrgOpRec: [],
        prgCode: [],
        index: [],
        forceRender: 0,
        value: 0,
        idxCd: '1',
        prgImprvPk: 0,
        dialogDelete: false,

    }),
};
</script>