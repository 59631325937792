<template>
    <v-sheet class="ma-0" rounded="md">
        <v-form ref="mwiForm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                    <span class="font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL" cols="9">
                    <span class="d-inline-block pa-1" style="width:107px;">
                        <v-text-field
                            v-model="wrtr"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                        min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                        dark small depressed>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                    <span class="font-weight-medium">수거일</span>
                </v-col>
                <v-col class="d-flex justify-left align-center pa-1 tableBL" cols="9">
                    <span class="d-inline-block" style="width: 133px;">
                        <CmmDateComp v-model="inspDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row v-for="(list, i) in inspRecDtllist" :key="i" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                    <span class="font-weight-medium">처리물&nbsp;내용</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL" cols="9">
                    <v-radio-group v-model="list.itm1Val" :key="forceRender" style="width: 214px;"
                        class="pa-0 ma-0 d-flex justify-center align-center d-inline-block pa-1" hide-details row>
                        <v-radio class="d-inline-block d-flex justify-center ma-0 pr-2" style=""
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" label="일반의료" value="1">
                        </v-radio>
                        <v-radio class="d-inline-block d-flex justify-center ma-0 pr-2" style=""
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" label="손상성" value="2">
                        </v-radio>
                        <v-radio class="d-inline-block d-flex justify-center ma-0" style=""
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" label="기타:" value="3">
                        </v-radio>
                    </v-radio-group>
                    <span class="d-inline-block" style="width: 40%;">
                        <v-text-field
                            v-model="list.itm2Val" :disabled="list.itm1Val != '3'"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider v-if="inspRecDtllist.length > 0" class=""></v-divider>
            <v-row v-for="(list, j) in inspRecDtllist" :key="inspRecDtllist.length + j" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                    <span class="font-weight-medium">수거기관</span>
                </v-col>
                <v-col class="d-flex justify-left align-center tableBL" cols="9">
                    <span class="d-inline-block pa-1" style="width: 100%;">
                        <v-text-field
                            v-model="list.itm3Val"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider v-if="inspRecDtllist.length > 0" class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="3">
                    <span class="font-weight-medium">자료첨부</span>
                    <span class="d-inline-block">
                        <v-file-input
                            class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                            :disabled="inspRecDtllist.length != 0 ? false : true"
                            prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="ma-0 pa-0 pb-1 tableBL" cols="9">
                    <span class="d-inline-block pl-1" style=""
                        v-for="(item, j) in fileList" :key="j">
                        <v-chip            
                            class="mt-1" label close outlined
                            @click="fileDownload(j)" @click:close="delfiles(j)">
                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            <span class="longText">{{ item.orgFileNm }}</span>
                        </v-chip> 
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="inspDt"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[1]" max-width="500">
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selInspRecDtl1list, insInspRecDtl, delInspRecDtl, delInspRecDtlInfo } from '../../api/sfty.js';
import { tmpFileUpload, fileDownload, delAtchdFile, selAtchdFileList } from '../../api/index.js';
import { getMultiStcd } from '../../api/index.js';

import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'MdcWstIns',

    props : {
       
    },
        
    components: {
        EmpSelector,
        btnModalDelete,
        CmmDateComp,
    },

    created: function(){
        // this.getCode(['47'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            if(this.$parent.$parent.$parent.$parent.tab == 3){
                this.clearForm()
                let sltInspDate = value.substring(0, 7)
                let insPos = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspPrd == sltInspDate)
                let inspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].itm4

                this.getAtchdFileList()
                this.getInspRecDtllist(inspDate?.afterDateFormatDot(), 1)

                if(insPos != -1)
                    this.cntnt13 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].cntnt13
            }
        },
    },
    
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //의료폐기물점검 조회
        getInspRecDtllist: function (date){
            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, date, 507, 4)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //의료폐기물점검 저장
        setInspRecDtl: function (obj){
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //의료폐기물점검 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        //의료폐기물점검 단건 삭제
        removeInspRecDtlInfo: function (pk){
            delInspRecDtlInfo(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk )
                .then((response) => ( this.setInspRecDtlInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtlInfo : ' + error))
        },
        //첨부파일 목록 조회
        getAtchdFileList: function (){
            selAtchdFileList(this.$store.getters.getFcltyNum, 5, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        //첨부파일 목록 조회
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 다운로드
        athFileDownload: function (index) {
            fileDownload(this.fileList[index])
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)
            
            let keyList = []
            res.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                this.wrtr = items.wrtr

                if(this.inspDt == ''){
                    if(items.inspDt == null)
                        this.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.replaceAll('.', '')?.beforeDateFormatHyp()
                    else
                        this.inspDt = items.inspDt?.beforeDateFormatHyp()
                }
            });

            //res.sort((x, y) => x.itm1Cd - y.itm1Cd)

            this.inspTitle = keyList
            this.inspRecDtllist = res
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        setInspRecDtlInfoAfter: function (res){
            if(res.statusCode == 200){
                let sltInspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.substring(0, 7)
                this.getInspRecDtllist(sltInspDate.afterDateFormatMonthDot())
                
                this.clearForm()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        getAtchdFileListAfter:function(res){
            this.files = null
            this.fileList.splice(0)

            res.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
        },
        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.fileList.splice(0)
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList()
            }
            else {
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }
        },
        upFileAfter: function (res) {
            res.files.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            this.files = null
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 507
            obj.clsfc2Cd = 4
            obj.bzClcd = '5'
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.inspDt.afterDateFormatHyp()
            obj.cntnt1 = this.cntnt14
            obj.list = []
            obj.files = []

            let chk = this.$refs.mwiForm.validate()

            if(chk){
                this.inspRecDtllist.forEach(items => {               
                    items.inspDtlDt = this.inspDt.afterDateFormatHyp()
                    items.inspDt = this.inspDt.afterDateFormatHyp()
                    items.wrtr = this.wrtr
                    items.inspTmHh = '12'
                    items.inspTmMm = '00'
                    items.itm1Cd = 1
                    items.itm2Cd = 2
                    items.itm3Cd = 3

                    if(items.itm1Val != '3')
                        items.itm2Val = null
                    
                    obj.list.push(items)
                });

                this.fileList.forEach( items => {
                    if(items.atchdFilePk == undefined){
                        obj.files.push(items)
                    }
                })

                //console.log(obj)
                this.setInspRecDtl(obj)
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info']) 
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },
        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },
        fileDownload: function (idx) {
            if(this.fileList[idx] != undefined){
                this.athFileDownload(idx)
            }
        },
        delfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined){
                this.sltIndex = idx
                this.delTitle = this.fileList[idx].orgFileNm
                  
                this.dialog.splice(1, 1, true)
            }
            else {
                this.fileList.splice(idx, 1)
            }
        },
        removeFileItm: function(res) {
            if(res == 9990){
                let pk = 0

                pk = this.fileList[this.idx].atchdFilePk

                if(pk > 0)
                    this.removeAthFile(pk)
            }
            else if(res == 7){
                //this.dialogDelete = false
            }
        },
        clearForm: function () {
            this.inspTitle.splice(0)
            //this.$refs.emrForm.reset()
            this.inspRecDtllist.splice(0)
            this.wrtr = ''
            this.cntnt14 = ''
            this.inspDt = ''
            this.forceRender += 1
        },
        delModalAct: function (res) {
            if(res == 9990){    //OK
                if(this.delTitle != ''){
                    this.removeAthFile(this.fileList[this.sltIndex].atchdFilePk)
                }
                else
                    this.removeInspRecDtlInfo(this.inspRecDtllist[this.sltIndex].inspRecDtlPk)
                
                this.dialog.splice(1, 1, false)
            }
            else if(res == 7)   //Cancel
                this.dialog.splice(1, 1, false)

            this.delTitle = ''
            this.sltIndex = -1
        },
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        menu: [false],
        dialog: [false],
        inspTitle: [],
        inspRecDtllist: [],
        wrtr: '',
        cntnt14: '',
        inspDt: '',
        delTitle: '',
        files: null,
        fileList: [],
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>