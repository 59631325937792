<template>
    <v-sheet class="ma-0 overflow-y-auto" rounded="md">
        <v-row no-gutters>
            <v-col class="d-flex pb-1 align-center" cols="12">
                <v-spacer></v-spacer>
                <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;양호&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-triangle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;보통&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-close</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;불량&#44;&nbsp;&nbsp;</span>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&nbsp;N&#47;A&#58;&nbsp;해당없음</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                <span class="font-weight-medium">점검일시</span>
            </v-col>
            <v-col class="d-flex justify-left align-center pa-1" cols="4">
                <span class="d-inline-block" style="width: 133px;">
                    <CmmDateComp v-model="inspDt" :required="true"></CmmDateComp>
                </span>
                <span class="white d-inline-block pa-1" style="width: 80px;">
                    <CmmTimeField v-model="inspTm" :required="false"></CmmTimeField>                                     
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <div style="line-height:18px;">
                    <div class="font-weight-medium">점검</div>
                    <div class="font-weight-medium">장소</div>
                </div>
            </v-col>
            <v-col class="d-flex justify-left align-center" cols="2">
                <span class="d-inline-block pa-1" style="width:100%;">
                    <v-text-field v-model="inspPl1" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="font-weight-medium">작성자</span>
            </v-col>
            <v-col class="d-flex justify-left align-center" cols="2">
                <span class="d-inline-block pa-1" style="width:120px;">
                    <v-text-field
                        v-model="wrtr"
                        readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn 
                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                    min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                    dark small depressed>
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>        
        <v-divider class=""></v-divider>
        <v-row class="greyE01" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                <span class="font-weight-medium">구분</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="7">
                <span class="font-weight-medium">세부점검사항</span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="3">
                <span class="d-flex justify-center align-center d-inline-block ngh tableBR" style="width: 25%; height: 100%;">
                    <v-icon color="black" small>mdi-checkbox-blank-circle-outline</v-icon>
                </span>
                <span class="d-flex justify-center align-center d-inline-block ngh tableBR" style="width: 25%; height: 100%;">
                    <v-icon color="black" small>mdi-triangle-outline</v-icon>
                </span>
                <span class="d-flex justify-center align-center d-inline-block ngh tableBR" style="width: 25%; height: 100%;">
                    <v-icon color="black" small>mdi-close</v-icon>
                </span>
                <span class="d-flex justify-center align-center d-inline-block font-weight-medium" style="width: 25%; height: 100%;">N&#47;A</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="" v-for="n in inspTitle.length" :key="n" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR tableBB" cols="2">
                <span class="">{{inspTitle[n-1]}}</span>
            </v-col>
            <v-col class="tableBR" cols="7">
                <div v-for="(list, i) in inspRecDtllist" :key="i">
                    <div v-if="list.clsfc3CdNm == inspTitle[n-1]" class="tableBB py-1 pl-2">
                        {{ list.itm1CdNm }}
                    </div>
                </div>
            </v-col>
            <v-col class="" cols="3">
                <div v-for="(list, i) in inspRecDtllist" :key="i">
                    <div v-if="list.clsfc3CdNm == inspTitle[n-1]" class="tableBB">
                        <v-radio-group v-model="list.itm1Val" :key="forceRender"
                            class="d-flex justify-center align-center d-inline-block" style="width:100%;" hide-details row>
                            <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:25%; height: 31.11px;"
                                on-icon="mdi-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="1"></v-radio>
                            <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:25%;"
                                on-icon="mdi-triangle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="2"></v-radio>
                            <v-radio class="d-inline-block ma-0 d-flex justify-center tableBR" style="width:25%;"
                                on-icon="mdi-close" off-icon="mdi-minus-circle-outline" :ripple="false" value="3"></v-radio>
                            <v-radio class="d-inline-block ma-0 d-flex justify-center" style="width:25%;"
                                on-icon="mdi-minus-circle-off-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="0"></v-radio>
                        </v-radio-group>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col class="d-flex justify-center align-center" style="background-color: #ededed !important;" cols="2">
                <div class="font-weight-medium">
                    <span class="d-inline-block" style="width: 100%; text-align: center;">비고</span>
                    <span class="d-inline-block" style="width: 100%; text-align: center;">&#40;조치사항&#41;</span>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="10">
                <span class="d-inline-block pa-1" style="width:100%;">
                    <v-textarea
                        class="rmrksArea" v-model="cntnt11" no-resize hide-details outlined dense>
                    </v-textarea>
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="inspDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selInspRecDtl1list, insInspRecDtl, delInspRecDtl } from '../../api/sfty.js';

import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'GasSftIns',

    props : {
       
    },
        
    components: {
        EmpSelector,
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            if(this.$parent.$parent.$parent.$parent.tab == 0){
                this.clearForm()
                let sltInspDate = value.substring(0, 7)
                let insPos = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspPrd == sltInspDate)
        
                if(insPos != -1){
                    let inspDate= this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].itm1

                    this.getInspRecDtllist(inspDate?.afterDateFormatDot())
                    this.cntnt11 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].cntnt11
                    this.inspPl1 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].inspPl1
                }
            }
        },
    },
    
    methods: {
        //시설안전점검 조회
        getInspRecDtllist: function (date){
            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, date, 506, 1)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //시설안전점검 저장
        setInspRecDtl: function (obj){
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //시설안전점검 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)

            let keyList = []
            res.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                this.wrtr = items.wrtr

                if(items.inspDt == null)
                    this.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.replaceAll('.', '')?.beforeDateFormatHyp()
                else
                    this.inspDt = items.inspDt?.beforeDateFormatHyp()

                if(items.inspTmHh == null)
                    this.inspTm = '12:00'
                else
                    this.inspTm = items?.inspTmHh + ':' + items?.inspTmMm
  
                if(items.itm1Val == null)
                    items.itm1Val = '0'
            });

            res.sort((x, y) => x.itm1Cd - y.itm1Cd)

            this.inspTitle = keyList
            this.inspRecDtllist = res
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 506
            obj.clsfc2Cd = 1
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.inspDt.afterDateFormatHyp()
            obj.cntnt1 = this.cntnt11
            obj.inspPl = this.inspPl1
            obj.list = []

            this.inspRecDtllist.forEach(items => {               
                items.inspDtlDt = this.inspDt.afterDateFormatHyp()
                items.inspDt = this.inspDt.afterDateFormatHyp()
                items.wrtr = this.wrtr
                let tmp = this.inspTm.split(':', 2)
                items.inspTmHh = tmp[0]
                items.inspTmMm = tmp[1]
  
                obj.list.push(items)
            });

            //console.log(obj)
            this.setInspRecDtl(obj)
        },  
        clearForm: function (){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)
            this.wrtr = ''
            this.cntnt11 = ''
            this.inspPl1 = ''
            this.inspDt = ''
            this.inspTm = ''
            this.forceRender += 1
        },
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        menu: [false, false],
        dialog: [false],
        inspTitle: [],
        inspRecDtllist: [],
        wrtr: '',
        cntnt11: '',
        inspPl1: '',
        inspDt: '',
        inspTm: '',
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>