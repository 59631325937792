<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">직원&nbsp;선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-5 mb-2" no-gutters>
            <v-col class="d-flex align-center" cols="4">
                <span class="d-inline-block" style="width: 80px; height: 35px;">
                    <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose" @changeCheck="filterSearchD1" />
                </span>
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 120px; height: 35px;">
                    <v-text-field
                        v-model="field"
                        class="ma-0 pa-0 empSearchField" style=""
                        background-color="white" append-icon="mdi-magnify" label="이름조회"
                        @input="setSearchText"
                        single-line rounded outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-col>
            <v-col class="d-flex align-center pr-3" cols="8">
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-1" width="20" height="20"
                    @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 15)"
                    icon>
                    <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                </v-btn>
                <span class="ml-1 grey006--text fontOneRem">목록이 보이지 않는 경우</span>
            </v-col>
        </v-row>
        <v-divider :class="[empList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01', empList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="black--text font-weight-medium">직원성명</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="1">
                <span class="black--text font-weight-medium">성별</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="black--text font-weight-medium">담당직종</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="black--text font-weight-medium">입사일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="black--text font-weight-medium">퇴사일</span>
            </v-col>
            <v-col class="txtAlgnMid py-2" cols="3">
                <span class="black--text font-weight-medium">근무일정</span>
            </v-col>
            <!-- <v-col class="greyE01 py-2" style="text-align:center;" cols="1">
                <span class="black--text font-weight-medium" style="text-align:center;">적용</span>
            </v-col> -->
        </v-row>
        <v-divider :class="[empList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div v-if="empList.length > 0" class="d-inline-block overflow-y-auto" style="width: 100%; height: 433.1px">
            <v-row
                class="fixedCol tableBB"
                v-for="(list, i) in empList" :key="i"
                @click="onAction(list.empPk)"
                no-gutters>
                <v-col class="py-2 fixedCol0 txtAlgnMid tableBR" cols="2">
                    <span class="">{{ list.name }}</span>
                </v-col>
                <v-col class="py-2 fixedCol0 d-flex justify-center align-center tableBR" cols="1">
                    <span>
                        <v-img class="d-inline-block" v-if="list.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                        <v-img class="d-inline-block" v-else-if="list.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                    </span>
                </v-col>
                <v-col class="py-2 fixedCol0 txtAlgnMid tableBR" cols="2">
                    <span class="">{{ list.jobCd }}</span>
                </v-col>
                <v-col class="py-2 fixedCol0 txtAlgnMid tableBR" cols="2">
                    <span class="">{{ list.entDt }}</span>
                </v-col>
                <v-col class="py-2 fixedCol0 txtAlgnMid tableBR" cols="2">
                    <span class="">{{ list.rtrDt }}</span>
                </v-col>
                <v-col class="py-2 fixedCol0 txtAlgnMid" cols="3">
                    <span class="">{{ list.wrkSchdl }}</span>
                </v-col>
                <!-- <v-col class="py-2 fixedCol0" style="text-align:center;" cols="1">
                    <v-btn
                        v-if="list.wrkTypCd != '2'"
                        class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 2px !important"
                        color="bckgrColor" min-width="30" height="25"
                        @click="onAction(list.empPk)"
                        dark small>
                            <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                    </v-btn>
                </v-col> -->
            </v-row>
        </div>
        <div v-else class="d-inline-block overflow-y-auto" style="width: 100%; height: 433.1px">
            <v-row
                class="" no-gutters>
                <v-col class="txtAlgnMid py-2" cols="12">
                    <span class="grey006--text">※ 조회된 항목이 없습니다.</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { getEmpChcList } from '../../api/emp.js';

import exBtn from '../../components/commons/expandButtonCustom.vue';

export default {
    name: 'EmpSelector',

    props : {
        prtWrtDt: { type: String, default: '' }
    },
            
    components: {
        exBtn,
    },

    created: function(){
        this.getEmpCode()
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch: {
        'prtWrtDt': function () {
            if(this.$moment(this.prtWrtDt, 'YYYY-MM-DD', true).isValid()){
                this.wrtDt = this.prtWrtDt.afterDateFormatHyp()
                this.getEmpList(this.wrtDt)
            }
            else if(this.$moment(this.prtWrtDt, 'YYYYMMDD', true).isValid()){
                this.wrtDt = this.prtWrtDt
                this.getEmpList(this.wrtDt)
            }
            else{
                this.empList.splice(0)
                this.dummyEmpList.splice(0)
            }
        }
    },
        
    methods: {
        getEmpCode: function () {
            getMultiStcd(['41', '42', '70'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        getEmpList: function (date) {
            getEmpChcList(this.$store.getters.getFcltyNum, 1, date)
                .then((response) => ( this.getEmpListAfter(response.data) ))
                .catch((error) => console.log('connect error /empinfo/selEmpChcList : ' + error))
        },

        codeMapping: function (res) {
            this.empWorkCode = res.vCd41
            this.empJobCode = res.vCd42
            this.empStateCode = res.vCd70

            this.empJobCode.forEach(jobCode => {
                this.exBtn[0].expandList.push(jobCode.valcdnm)
            })

            this.getEmpList(this.$moment().format('YYYYMMDD'))
        },

        getEmpListAfter: function (res) {
            res.forEach(list => {
                list.entDt = list.entDt?.beforeDateFormatDot()
                list.rtrDt = list.rtrDt?.beforeDateFormatDot()

                this.empJobCode.forEach(cd => {
                    if(list.jobCd == cd.valcd) list.jobCd = cd.valcdnm
                })
            })

            this.empList = res
            this.dummyEmpList = Object.assign([], res)
        },

        setSearchText: function () {
            if(this.field == '')
                this.empList = Object.assign([], this.dummyEmpList)

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });
            let res= []
            let result = []
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd) => {
                    res = this.dummyEmpList.filter( v => v.jobCd == stcd )
                })
            }
            else
                res = this.dummyEmpList

            if(this.field != ''){
                res.forEach((sc) => {
                    if(sc.name.search(this.field) > -1) result.push(sc)
                })
            }
            else
                result = res

            if(this.schChkField1.length == 0 && this.field == '')
                this.empList = Object.assign([], this.dummyEmpList)
            else
                this.empList = result

        },

        filterSearchD1: function (sch) {
                
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            let res= []
            let result = []
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd) => {
                    this.dummyEmpList.filter( v => v.jobCd == stcd ).forEach(ttt => {
                        res.push(ttt)
                    });
                });
            }
            else
                res = this.dummyEmpList
            
            if(this.field != ''){
                res.forEach((sc) => {
                    if(sc.name.search(this.field) > -1) result.push(sc)
                })
            }
            else
                result = res

            if(this.schChkField1.length == 0 && this.field == '')
                this.empList = Object.assign([], this.dummyEmpList)
            else
                this.empList = result
        },

        onAction: function (pk) {
            let selected = this.empList.filter(v => v.empPk == pk)
            this.$emit('outEmp', selected)
            this.onClose()
        },

        onClose: function () {
            this.$emit('isClose', '.')
        },

        expandClose: function () {
            this.$refs.exBtnClose.isClose()
        },
    },
    
    data: () => ({
        wrtDt: '',
        field: '',
        schChkField1: [],
        empStateCode: [],
        empJobCode: [],
        empWorkCode: [],
        empList: [],
        dummyEmpList: [],
        exBtn: [
                {
                    index:  0,
                    btnTitle: '직종',
                    btnIcon: 'business',
                    exWidth: 134,
                    exHeigth: 220,
                    expandList: [],
                },
            ],
    }),
};
</script>
<style>
    .empSearchField div div div label { margin-top: 0px; top: 3px !important; }
    .empSearchField div .v-input__slot { padding: 0 14px 0 18px !important; }
</style>