<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">외부강사&nbsp;정보수정</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-form ref="mdfExtInstrInfo" lazy-validation>
            <v-divider class="mt-5"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">외부강사명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL tableBR py-1" cols="4">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field  v-model="empInfo.name" :rules="[rules.required]"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">성별</span>
                </v-col>
                <v-col class="tableBL px-1" cols="4">
                    <span class="">
                        <v-radio-group class="pl-2 pt-1" v-model="empInfo.gndr" hide-details row>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="남" value="M"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="여" value="F"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">강사료</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL tableBR py-1" cols="4">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field v-model="empInfo.wage" type="number"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">자격증</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL py-1" cols="4">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field v-model="empInfo.crtfcNm"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">연락처</span>
                </v-col>
                <v-col class="d-flex align-center tableBL tableBR px-1 py-1" cols="4">
                    <span class="d-inline-block" style="width: 60px;">
                        <v-text-field v-model="empInfo.celphn_1"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex align-center px-1">
                        <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px;">
                        <v-text-field v-model="empInfo.celphn_2"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex align-center px-1">
                        <v-icon class="pt-1" size="16" style="color: #dbdbdb;">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px;">
                        <v-text-field v-model="empInfo.celphn_3"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">소속</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="4">
                    <span class="d-inline-block" style="width: 80px;">
                        <v-select
                            class="wrkSelect"
                            v-model="empInfo.afltnClcd" :key="forceRender"
                            :items="extSrtt" item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 90px;" v-if="empInfo.afltnClcd == '2'">
                        <v-text-field v-model="empInfo.afltnNm"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">계약기간</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-1 py-1" cols="5">
                    <CmmDateComp v-model="empInfo.entDt" :required="true"></CmmDateComp>
                    <!-- <span class="d-inline-block black--text" style="width: 100px;">
                        <v-menu
                            ref="datePicker0" v-model="menu[0]"
                            :close-on-content-click="false" :return-value.sync="empInfo.entDt"
                            transition="scale-transition" offset-y min-width="auto" dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="empInfo.entDt"
                                    readonly hide-details outlined dense v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="empInfo.entDt" :max="empInfo.rtrDt" :key="forceRender" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(0)">Cancel</v-btn>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker0.save(empInfo.entDt)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </span> -->
                    <span class="d-flex align-center mx-2">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <CmmDateComp v-model="empInfo.rtrDt" :required="true"></CmmDateComp>
                    <!-- <span class="d-inline-block black--text" style="width: 100px;">
                        <v-menu
                            ref="datePicker1" v-model="menu[1]"
                            :close-on-content-click="false" :return-value.sync="empInfo.rtrDt"
                            transition="scale-transition" offset-y min-width="auto" dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="empInfo.rtrDt"
                                    readonly hide-details outlined dense v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="empInfo.rtrDt" :min="empInfo.entDt" :key="forceRender" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(1)">Cancel</v-btn>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker1.save(empInfo.rtrDt)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </span> -->
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL py-1" cols="10">
                    <span class="d-inline-block mx-1" style="width: 100%;">
                        <v-text-field v-model="empInfo.rmrks"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">파일첨부</span>
                    <span class="d-inline-block">
                        <v-file-input
                            class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                            prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="tableBL pa-2" cols="10">
                    <span class="pb-2 pr-2"
                        v-for="(item, j) in fileList" :key="j">
                        <v-chip
                            class="mt-1" label close outlined
                            @click="athFileDownload(j)" @click:close="delAtchfiles(j)">
                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            <span class="longText">{{ item.orgFileNm }}</span>
                        </v-chip>
                    </span>
                    <v-divider class="my-2"></v-divider>
                    <div>
                        <span class="d-inline-block mx-1"
                            v-for="( item, i ) in imgList" :key="i">
                            <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)"></imageCard>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" height="26" @click="preExtEmpsInfo()" samll rounded>
                    <v-icon small>mdi-content-save-outline</v-icon>
                    <span style="font-size: 1rem;">저장</span>
                </v-btn>
                <v-btn
                    class="white--text ml-3" color="grey006" height="26" @click="menu.splice(2, 1, true)" samll rounded>
                    <v-icon small>mdi-trash-can-outline</v-icon>
                    <span style="font-size: 1rem;">삭제</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[2]" max-width="500">  
            <btnModalDelete :title="rechkModalTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selExtEmpInfo, insEmp, delEmp } from '../../api/emp.js';
import { tmpFileUpload, selAtchdFileList, delAtchdFile } from '../../api/index.js';

import { remoteURL } from '@/api/baseAxios';

import imageCard from '../commons/imageCard.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'MdfExtEmpMdl',

    props : {
        
    },
        
    components: {
        imageCard,
        btnModalDelete,
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
          
        })
    },

    computed: {
        getSltEmpPk: function() {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
        }
    },

    watch:{
        getSltEmpPk: function(value) {
            if(value == -1)
                this.clearForm()
            else
                this.getExtEmpInfo(value)
        },
    },
    
    methods: {
        getExtEmpInfo: function (pk) {
            selExtEmpInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.getExtEmpInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selExtEmpInfo : ' + error))
        },
        setEmpInfo: function (obj) {
            insEmp(obj)
                .then((response) => ( this.asyncResultMdf(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmp : ' + error))
        },
        removeEmpInfo: function (pk) {
            delEmp(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncResultRemove(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmp : ' + error))
        },
        //첨부파일 목록 조회
        getAtchdFileList: function (pk){
            selAtchdFileList(this.$store.getters.getFcltyNum, 9, pk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
        },
        //첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
        },
        getExtEmpInfoAfter: function (res) {
            if(res.entDt != '' && res.entDt != null)
                res.entDt = res.entDt.beforeDateFormatHyp()
            if(res.rtrDt != '' && res.rtrDt != null)
                res.rtrDt = res.rtrDt.beforeDateFormatHyp()
            if(res.wage == null || res.wage == '')
                res.wage = 0

            this.empInfo = res
            this.getAtchdFileList(this.empInfo.empEtcPk)
            this.forceRender += 1
        },
        asyncResultMdf: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getExtEmpInfo(this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk)
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        asyncResultRemove: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.clearForm()
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getExtEmpList()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])

            this.onClose()
        },
        getAtchdFileListAfter: function(res){
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            let fList = res.filter(v => v.fileKndCd == '1')
            let iList = res.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgView?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })
        },
        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },
        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList(this.empInfo.empEtcPk)
                this.forceRender += 1
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        preExtEmpsInfo: function () {
            let obj = Object.assign({}, this.empInfo )
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.etcFiles = []
            obj.wrkStcd = '1'

            if(obj.entDt != '')
                obj.entDt = obj.entDt?.afterDateFormatHyp()
            if(obj.rtrDt != '')
                obj.rtrDt = obj.rtrDt?.afterDateFormatHyp()

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.etcFiles.push(items)
                }
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.etcFiles.push(items)
                }
            })

            let chk = this.$refs.mdfExtInstrInfo.validate()
            if(chk)
                this.setEmpInfo(obj)
        },
        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },
        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            }
            else {
                this.imgList.splice(idx, 1)
            }
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        onClose: function () {
            this.$emit('modalClose', '1')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        delModalAct: function(res){
            if(res == 9990){    //OK
                this.removeEmpInfo(this.empInfo.empPk)

                this.menu.splice(2, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(2, 1, false)
        },
        clearForm: function () {
            this.empInfo = Object.assign({
                empPk: 0, empWgePk: 0, empEtcPk: 0,
                name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', wgeTyp: '3', wage: 0, jobCd: '16', pstnCd: '5',
                celphn_1: '', celphn_2: '', celphn_3: '', etcClsfc1Cd: '2', etcClsfc2Cd: '5', rmrks: '', gndr: 'M', wrkStcd: '1',
            })

            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            this.forceRender += 1
        },
    },

    data: () => ({
        menu: [false, false, false,],
        rechkModalTitle: '외부강사 정보',
        files: null,
        fileList: [],
        imgList: [],
        extSrtt: [
            { valcd: '1', valcdnm: '개인' },
            { valcd: '2', valcdnm: '단체' },
        ],
        empInfo: {
            empPk: 0, empWgePk: 0, empEtcPk: 0,
            name: '', entDt: '', rtrDt: '', afltnClcd: '1', afltnNm: '', wgeTyp: '3', wage: 0, jobCd: '16', pstnCd: '5',
            celphn_1: '', celphn_2: '', celphn_3: '', etcClsfc1Cd: '2', etcClsfc2Cd: '5', rmrks: '', gndr: 'M', wrkStcd: '1',
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            pwdSizeCheck: value => (value && value.length > 3) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
        },
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>