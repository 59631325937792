<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left align-center mb-2 ">
            <span class="text-h4 font-weight-bold">신체제재 작성 및 통지</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left align-center mb-2">
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" rounded
                :disabled="list.disabled"
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>            
        </div>
        <div class="d-flex mt-2">
            <div style="width:550px">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">신체제재 상세내용</span>
                    <v-spacer></v-spacer>
                    <span>
                        <v-btn 
                            class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                            min-width="30" height="25"
                            @click="onSave"
                            small outlined>
                            <v-icon size="14">mdi-content-save-outline</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>                
                        </v-btn>
                    </span>
                </div>
                <v-form ref="mform" lazy-validation>
                    <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">수급자</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">{{bnfcrInfo.name}}</v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">성별</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">
                            <span class="">
                                <v-img class="ic-sex" v-if="bnfcrInfo.gndr == '여'" src="../../styles/images/ic-female.svg"></v-img>
                                <v-img class="ic-sex" v-else-if="bnfcrInfo.gndr == '남'" src="../../styles/images/ic-male.svg"></v-img>
                            </span>
                            <span class="ml-1">&#40;{{bnfcrInfo.age}}&#41;</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">                            
                            <span class="black--text font-weight-medium">등급</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">
                            <span :class="'ic-level level0'+bnfcrInfo.acknwRtng">{{bnfcrInfo.acknwRtng}}</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">제재기간</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="10">
                            <span class="white d-inline-block" style="width: 154px;border-radius: 10px;"> 
                                <CmmDateComp v-model="items.snctDtStrt" :maxDt="items.snctDtEnd" :required="true"></CmmDateComp>                                                  
                            </span>
                            <span class="px-1">&#126;</span>
                            <span class="white d-inline-block" style="width: 154px;border-radius: 10px;">    
                                <CmmDateComp v-model="items.snctDtEnd" :minDt="items.snctDtStrt" :required="true"></CmmDateComp>                                               
                            </span>
                        </v-col>            
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">수급자상태</div>
                                <div class="d-flex justify-center black--text font-weight-medium">&#40;제재사유&#41;</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="10">                            
                             <v-textarea v-model="items.snctRsn" class="rmrksArea" style="font-size:0.9rem !important;"
                                :rules="[rules.required]"
                                rows="5" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>            
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">신체구속의방법</div>
                                <div class="d-flex justify-center black--text font-weight-medium">&#40;제재방법&#41;</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="10">
                            <v-textarea v-model="items.snctMthd" class="rmrksArea" style="font-size:0.9rem !important;"
                                :rules="[rules.required]"
                                rows="5" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>            
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">구속시간</div>
                                <div class="d-flex justify-center black--text font-weight-medium">&#40;제재시간&#41;</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="10">
                            <v-textarea v-model="items.snctTmCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="5" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>            
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">특기해야 할</div>
                                <div class="d-flex justify-center black--text font-weight-medium">심신의 상황</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="10">
                            <v-textarea v-model="items.spclNote" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="5" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>            
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">구속해제의</div>
                                <div class="d-flex justify-center black--text font-weight-medium">사유조건</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="10">
                            <v-textarea v-model="items.rlsRsn" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="5" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>            
                    </v-row>
                </v-form>
            </div>
            <div class="ml-2" style="width:786px">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">신체제재 상세내용</span>
                </div>
                <div class="d-flex pa-0 mt-2"  no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;">
                        <span class="black--text font-weight-medium">순서</span>
                    </div>            
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:18%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">신체제재일</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:19%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">신체제재시간</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:34%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">제재방법</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="black--text font-weight-medium">제재자</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:11%;border-left: 1px solid #dbdbdb !important;">                                
                        <!-- <v-btn 
                            class="mr-1" 
                            color="blue200" 
                            height="26" 
                            @click="addRow"
                            icon>
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn> -->
                        <span class="black--text font-weight-medium">처리</span>
                    </div>
                </div>
                <div style="height:500px;">
                    <div v-if="physSnctDtlList.length>0">
                        <div v-for="(list,i) in physSnctDtlList" :key="i" class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;">             
                                <span class="d-flex justify-center align-center white d-inline-block" style="width: 154px;border-radius: 10px;">  
                                    {{list.rn}}
                                </span>
                            </div>                   
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:18%;border-left: 1px solid #dbdbdb !important;">                                
                                <span class="white d-inline-block" style="width: 154px;border-radius: 10px;">  
                                    <CmmDateComp v-model="list.snctDtStrt" :required="false"></CmmDateComp>                                    
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:19%;border-left: 1px solid #dbdbdb !important;">                                
                                <span class="white d-inline-block pa-1" style="width: 80px;">
                                    <CmmTimeField v-model="list.snctTmStrt" :required="false"></CmmTimeField>                                     
                                </span>
                                <span class="py-1">&#126;</span>
                                <span class="white d-inline-block pa-1" style="width: 80px;"> 
                                    <CmmTimeField v-model="list.snctTmEnd" :required="false"></CmmTimeField>                                     
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:34%;border-left: 1px solid #dbdbdb !important;">                                
                                <v-text-field v-model="list.snctMthd" hide-details outlined dense ></v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:65px;margin-left:0px;">
                                    <v-text-field v-model="list.wrtr" color="blue"  hide-details outlined dense :key="forceRender"></v-text-field>
                                </span>
                                <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="22"
                                    height="22"  
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onShowEmp(i)"
                                    >                                    
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:11%;border-left: 1px solid #dbdbdb !important;">                                
                                <div class="d-flex justify-center align-center">
                                    <v-btn icon color='blue200' @click="onSaveRow(i)">
                                        <v-icon>mdi-content-save-outline</v-icon>
                                    </v-btn>
                                    <v-btn v-if="list.physSnctDtlPk > 0" icon color='grey006' @click="delRow(i)">
                                        <v-icon >mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div v-else>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">                                
                            데이터가 없습니다.
                        </div>
                    </div>
                </div>
                <div class="mt-2">            
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        @input="getPage"
                        total-visible="5">
                    </v-pagination>
                </div>
            </div>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[0]" max-width="700">    
                <grdnNtcModel 
                    ref="grdnNtcModel"                   
                    @isClose="isModal(0)"
                    @modalEvent="physcSRtrigger"
                    ></grdnNtcModel>                  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isCloseEmp" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'신체제재 상세내용'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div> 
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import grdnNtcModel from './GrdnNtcModel.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import { selPhysSnctInfo, selPhysSnctDtlList, insPhysSnct, insPhysSnctDtl, delPhysSnctDtl } from '../../api/bnftrcrd.js';

export default {
    name: 'PhyscSnctnRcrdDtl',
    
    components: {
        CmmDateComp,
        CmmTimeField,
        grdnNtcModel,
        EmpSelector,
        btnModalDelete,
    },

    props : {
        bnfcrInfo:{type:Object, default:new Object}
    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
       
    },  

    watch: {
       
    },

    methods: {        
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClickBtn:function(key){
           switch (key) {
            // case 0:
            //     this.dialog.splice(key, 1, true) 
            //     this.$refs.grdnNtcModel.onLoad('6',this.items.physSnctPk,this.bnfcrInfo.bnMmbrPk)                
            //     break;
            case 0:
                // this.onSave()
                break;
           
            default:
                break;
           }
        },
        onLoad:function(value){
            this.items.physSnctPk = value
            this.getPhysSnctInfo()
        },
        onClose: function () {
            this.$emit('isClose', 0)
            this.$refs.mform.reset()
            this.items.physSnctPk = 0
            this.items.bnMmbrPk = 0
            this.$refs.mform.resetValidation()
        },
        getPhysSnctInfo:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum                
            selPhysSnctInfo(gFcltyNum,this.items.physSnctPk)
                .then((response) => ( this.getPhysSnctInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selPhysSnctInfo : ' + error))
        },
        getPhysSnctInfoAfter:function(res){    
            res.snctDtStrt = res.snctDtStrt?.beforeDateFormatHyp()
            res.snctDtEnd = res.snctDtEnd?.beforeDateFormatHyp()
            this.items = res  
            this.page = 1
            this.getPhysSnctDtlList(0,this.itemsRows)
        },
        getPhysSnctDtlList:function(strt, lmt){            

            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum,
                physSnctPk:this.items.physSnctPk,
                rnStrt:strt,
                rnLmt:lmt, 
            }       

            selPhysSnctDtlList(obj)
                .then((response) => ( this.getPhysSnctDtlListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selPhysSnctDtlList : ' + error))
        },
        getPhysSnctDtlListAfter:function(res){  
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows) 

            res.list.forEach(e => {   
                e.snctDtStrt = e.snctDtStrt?.beforeDateFormatHyp()             
                if(e.snctTmHhStrt !='')               
                    e.snctTmStrt = e.snctTmHhStrt+":"+e.snctTmMmStrt
    
                if(e.snctTmHhEnd !='')               
                    e.snctTmEnd = e.snctTmHhEnd+":"+e.snctTmMmEnd                
            });       
           
            this.physSnctDtlList = res.list     
            
            this.addRow()
        },    
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getPhysSnctDtlList(strt,this.itemsRows)
        },    
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm

            if(gBnMmbrPk > 0 && this.$refs.mform.validate()){
                let obj = JSON.parse(JSON.stringify(this.items))
                
                obj.fcltyNum = gFcltyNum
                obj.bnMmbrPk = gBnMmbrPk
                obj.userNm = gUserNm
                obj.wrtr = gUserNm
                obj.snctDtStrt = obj.snctDtStrt.afterDateFormatHyp()
                obj.snctDtEnd = obj.snctDtEnd.afterDateFormatHyp()
                insPhysSnct(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insPhysSnct : ' + error))
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99);
                this.getPhysSnctInfo()
            }else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        onSaveRow:function(idx){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm
            let saveChk = true

            if(!/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.physSnctDtlList[idx].snctDtStrt)){
                this.snackControll([true, 2500, '신체제재일을 선택해주세요.', 'warning']) 
                return    
            }

            if(!/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.physSnctDtlList[idx].snctTmStrt)){
                this.snackControll([true, 2500, '제재시간(시작)을 확인해주세요.', 'warning']) 
                return 
            }

            if(!/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.physSnctDtlList[idx].snctTmEnd)){
                this.snackControll([true, 2500, '제재시간(종료)을 확인해주세요.', 'warning']) 
                return 
            }
            

            if(this.physSnctDtlList[idx].wrtr == ''){
                this.snackControll([true, 2500, '제재자를 선택해주세요.', 'warning']) 
                return
            }

            if(gBnMmbrPk > 0 && saveChk && this.items.physSnctPk > 0){
                let obj = JSON.parse(JSON.stringify(this.physSnctDtlList[idx]))
                obj.physSnctPk = this.items.physSnctPk
                obj.snctDtStrt = obj.snctDtStrt.afterDateFormatHyp()
                obj.snctDtEnd = obj.snctDtStrt
                obj.fcltyNum = gFcltyNum
                obj.bnMmbrPk = gBnMmbrPk
                obj.userNm = gUserNm
                
                if(obj.snctTmStrt !=''){
                    let snctTmStrt = obj.snctTmStrt.split(":")
                    obj.snctTmHhStrt = snctTmStrt[0]
                    obj.snctTmMmStrt = snctTmStrt[1]
                }else{
                    obj.snctTmHhStrt = ''
                    obj.snctTmMmStrt = ''
                }

                if(obj.snctTmEnd !=''){
                    let snctTmEnd = obj.snctTmEnd.split(":")
                    obj.snctTmHhEnd = snctTmEnd[0]
                    obj.snctTmMmEnd = snctTmEnd[1]
                }else{
                    obj.snctTmHhEnd = ''
                    obj.snctTmMmEnd = ''
                }

                if(obj.physSnctDtlPk > 0) this.targetPage = this.page

                insPhysSnctDtl(obj)
                        .then((response) => ( this.onSaveRowAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insPhysSnctDtl : ' + error))
            }
        },
        onSaveRowAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                let strt = ((this.targetPage-1)*this.itemsRows)
                this.getPhysSnctDtlList(strt,this.itemsRows)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.targetPage = 1
        },
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },          
        physcSRtrigger: function (event) { 
            if(event == 99){
                this.$emit('modalEvent', 99);
            }     
        },  
        datePickClose: function (value) {
            this.menu1.splice(value, 1, false)
        },
        isCloseEmp: function () {
            this.dialog.splice(1, 1, false)
        },        
        onShowEmp: function (id) {  
            let snctDtStrt = this.physSnctDtlList[id].snctDtStrt
            
            if(snctDtStrt !=''){
                this.idx = id
                this.prtWrtDt = snctDtStrt.afterDateFormatHyp()
                this.dialog.splice(1, 1, true)
            }else{
                this.snackControll([true, 2500, '제재일자를 선택해주세요.', 'warning'])
            }
        },
        getEmpInfo: function (obj) {
            let target = this.physSnctDtlList[this.idx]
            target.wrtr = obj[0].name
            target.wrtrMmbrPk = obj[0].mmbrPk
            this.idx = 0
            ++this.forceRender
        },
        onforce1(key){
            this.mtimes1[key] = false
            ++this.forceRender
        },
        onforce2(key){
            this.mtimes2[key] = false
            ++this.forceRender
        },
        addRow(){
            let obj = {rn:'',
                fcltyNum:'',bnMmbrPk:0,physSnctDtlPk:0,physSnctPk:0,snctDtStrt:'',snctDtEnd:'',snctTmStrt:'',snctTmHhStrt:'',
                snctTmMmStrt:'',snctTmEnd:'',snctTmHhEnd:'',snctTmMmEnd:'',snctMthd:this.items.snctMthd,wrtr:'',userNm:'',
            }
            this.physSnctDtlList.splice(0,0,obj)
            // this.physSnctDtlList.forEach(e => {
            //     e.rn += 1
            // });
            this.$refs.mform.resetValidation()
        },
        delRow(key){
            let target = this.physSnctDtlList[key]
            if(target.physSnctDtlPk > 0){
                this.idx = key
                this.dialogDelete = true
            }else{
                this.physSnctDtlList.splice(key,1)
                this.physSnctDtlList.forEach(e => {
                e.rn -= 1
            });
            }
        },
        onDelete:function(res){
            if(res == 9990){  
                let target = this.physSnctDtlList[this.idx]              
                if(target.physSnctDtlPk > 0){
                    delPhysSnctDtl(this.$store.getters.getFcltyNum,this.$store.getters.getUserNm,target.physSnctDtlPk)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delPhysSnctDtl : ' + error))
                }else{
                    this.dialogDelete = false    
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getPhysSnctDtlList(0,this.itemsRows)         
                this.page=1      
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.idx = 0
            this.dialogDelete = false            
        },
    },    
    data: () => ({
        idx:0,
        forceRender:0,
        prtWrtDt:'',
        snackOptions: [false, 3000, '', 'info'],
        menu:[false,false,],
        menu1:[false,false,false,false,false,false,false,false,false,false,false,false,],
        dialog:[false,false,],
        items:{
            physSnctPk:0,
            bnMmbrPk:0,
            fcltyNum:'',
            snctDtStrt:'',
            snctDtEnd:'',
            snctTmStrt:'',
            snctTmHhStrt:'',
            snctTmMmStrt:'',
            snctTmEnd:'',
            snctTmHhEnd:'',
            snctTmMmEnd:'',
            snctRsn:'',
            snctTmCntnt:'',
            snctMthd:'',
            spclNote:'',
            rlsRsn:'',
            wrtr:'',
            userNm:'',
        },
        pageCount:0,
        itemsRows: 10,
        page:1,
        targetPage:1,
        physSnctDtlList:[{
            fcltyNum:'',
            bnMmbrPk:0,
            physSnctDtlPk:0,
            physSnctPk:0,
            snctDtStrt:'',
            snctDtEnd:'',
            snctTmStrt:'',
            snctTmHhStrt:'',
            snctTmMmStrt:'',
            snctTmEnd:'',
            snctTmHhEnd:'',
            snctTmMmEnd:'',
            snctMthd:'',
            wrtr:'',
            userNm:'',
        }],
        mtimes1:[false,false,false,false,false,false,false,],
        mtimes2:[false,false,false,false,false,false,false,],
        filledBtn: [            
            // { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '보호자통지', disabled: true, },
            // { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
        rn:0,
    }),
};
</script>