<template>
    <v-sheet class="ma-0" rounded="md">
        <v-row class="pa-0 mt-1" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-spacer></v-spacer>
                <span>
                    <v-btn
                        class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                        @click="menu.splice(0, 1, true)" :disabled="Object.keys(useAnlInfo).length > 0 ? false : true"
                        min-width="30" height="25" small outlined>
                        <v-icon size="20">mdi-text-box-search-outline</v-icon>
                        <span class="fontOneRem ml-1" style="padding-bottom: 2px;">연차이력</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">근무연차</span>
            </v-col>
            <v-col class="py-1 px-3 tableBL" cols="8">
                <span class="black--text">{{ useAnlInfo.wrkAnlDesc }}</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">발생연차</span>
            </v-col>
            <v-col class="py-1 px-3 tableBL" cols="8">
                <span class="black--text" v-if="Object.keys(useAnlInfo).length > 0">{{ useAnlInfo.anlDys }}일</span>
                <span class="black--text ml-2" v-if="Object.keys(useAnlInfo).length > 0">&#40;&nbsp;발생일&#58;&nbsp;{{ useAnlInfo.anlOcrDt }}&nbsp;&#41;</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">사용연차</span>
            </v-col>
            <v-col class="px-3 d-flex align-center tableBL" cols="8">
                <span
                    v-if="Object.keys(useAnlInfo).length > 0"
                    class="black--text">
                    {{ useAnlInfo.anlUseCnt }}일
                </span>
                <span
                    v-if="Object.keys(useAnlInfo).length > 0"
                    class="black--text ml-2">
                    &#40;&nbsp;기준일&#58;&nbsp;{{ rfrncDate }}&nbsp;&#41;
                </span>
                <!-- <v-spacer></v-spacer>
                <span style="padding-bottom: 3px;" @click="menu.splice(0, 1, true)"
                    v-if="Object.keys(useAnlInfo).length > 0">
                    <v-btn
                        class="ml-2" color="#ccc"
                        min-width="30" height="24" small icon>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="24">mdi-text-box-search-outline</v-icon>
                                </div>
                            </template>
                            <span>연차이력</span>
                        </v-tooltip>
                    </v-btn>
                </span> -->
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">차감연차</span>
            </v-col>
            <v-col class="py-1 px-3 tableBL" cols="8">
                <span class="black--text" v-if="Object.keys(useAnlInfo).length > 0">{{ useAnlInfo.ddcDys }}일</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">잔여연차</span>
            </v-col>
            <v-col class="py-1 px-3 tableBL" cols="8">
                <span class="black--text" v-if="Object.keys(useAnlInfo).length > 0">{{ useAnlInfo.rmnAnl }}일</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-dialog v-model="menu[0]" max-width="900" content-class="round" persistent eager>
            <AnlHstrMdl :sltEmpPk="prtEmpPk" :wrtMnt="useMnth" @rnwlAnl="loadAnlHstr" @modalClose="modalChanger"></AnlHstrMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selEmpAnlUseInfo } from '../../api/emp.js';

import AnlHstrMdl from './AnlHstrMdl.vue';

export default {
    name: 'EmpWrkYrs',

    props : {
       prtEmpPk: { type: Number, default: -1 },
    },
        
    components: {
        AnlHstrMdl,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.setRfrncDate(this.$parent.$parent.$parent.$parent.date)
        })
    },

    computed: {
        getDt: function () {
            return this.$parent.$parent.$parent.$parent.date
        },
    },

    watch:{
        'prtEmpPk': function () {
            if(this.prtEmpPk == -1){
                this.useAnlInfo = {}
            }
            else {
                this.useAnlInfo = {}
                this.getEmpAnlUseInfo(this.prtEmpPk, this.$parent.$parent.$parent.$parent.date)
            }
        },
        getDt: function (dt) {
            this.setRfrncDate(dt)
        },
    },
    
    methods: {
        getEmpAnlUseInfo: function (pk, ymd) {
            selEmpAnlUseInfo(this.$store.getters.getFcltyNum, pk, ymd)
                .then((response) => ( this.getEmpAnlUseInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAnlUseInfo : ' + error))
        },
        getEmpAnlUseInfoAfter: function (res) {
            res.anlOcrDt = res.anlOcrDt?.beforeDateFormatDot()
            if(res.anlUseCnt == null)
                res.anlUseCnt = 0
            
            if(res.anlDys == null)
                res.anlDys = 0
            if(res.ddcDys == null)
                res.ddcDys = 0
            if(res.rmnAnl == null)
                res.rmnAnl = 0

            if(res.wrkAnlDesc == null)
                res.wrkAnlDesc = '0년차'

            this.useMnth = parseInt( Number(res.wrkMnthCalc) )

            this.useAnlInfo = res
        },
        setRfrncDate: function(dt) {
            if(dt != ''){
                if(this.$moment().format('YYYY.MM') == this.$moment(dt, 'YYYYMM').format('YYYY.MM'))
                    this.rfrncDate = this.$moment().format('YYYY.MM.DD')
                else
                    this.rfrncDate = this.$moment(dt, 'YYYYMM').endOf('month').format('YYYY.MM.DD')
            }
        },
        loadAnlHstr: function(v) {
            if(v == 'rm'){
                this.getEmpAnlUseInfo(this.prtEmpPk, this.$parent.$parent.$parent.$parent.date)
            }
        },
        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        rfrncDate: '',
        useAnlInfo: {},
        useMnth: 0,
        menu: [false],
    }),
};
</script>