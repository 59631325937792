<template>
    <div class="">
        <v-divider></v-divider>
        <v-data-table
            class="msclnTable tblCelFx" height="660" locale="ko"
            :headers="headers" :items="recHisList" :loading="recHisLoader"
            fixed-header hide-default-footer hide-default-header dense>
            <template v-slot:header="{ props }">
                <thead>
                    <tr>
                        <th :class="props.headers[0].class" :style="props.headers[0].style">
                            {{ props.headers[0].text }}</th>
                        <th :class="props.headers[1].class" :style="props.headers[1].style">
                            {{ props.headers[1].text }}</th>
                        <th :class="props.headers[2].class" :style="props.headers[2].style">
                            {{ props.headers[2].text }}</th>
                        <th :class="props.headers[3].class" :style="props.headers[3].style">
                            {{ props.headers[3].text }}</th>
                        <th :class="props.headers[4].class" :style="props.headers[4].style">
                            {{ props.headers[4].text }}</th>
                        <th :class="props.headers[5].class" :style="props.headers[5].style">
                            {{ props.headers[5].text }}</th>
                        <th :class="props.headers[6].class" :style="props.headers[6].style">
                            {{ props.headers[6].text }}</th>
                        <th :class="props.headers[7].class" :style="props.headers[7].style">
                            {{ props.headers[7].text }}</th>
                        <th :class="props.headers[8].class" :style="props.headers[8].style">
                            {{ props.headers[8].text }}</th>
                        <th :class="props.headers[9].class" :style="props.headers[9].style">
                            {{ props.headers[9].text }}</th>
                        <th :class="props.headers[10].class" :style="props.headers[10].style">
                            {{ props.headers[10].text }}</th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item='{ item }'>
                <tr>
                    <td class="tableBR tableBB clmsBodyCommon py-2">
                        <span class="cmmFontSizeOne">{{ $moment(item.recDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.bldPrsH }}/{{ item.bldPrsL }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.pulse }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.tmprt }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.rsprt }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.bldSgr }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.wght }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.hltTmSum }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.nlsTmSum }}</span>
                    </td>
                    <td class="tableBR tableBB clmsBodyCommon txt-break pa-1" style="line-height: 1.2;">
                        <span>{{ item.cntnt }}</span>
                    </td>
                    <td class="tableBB clmsBodyCommon">
                        <span class="cmmFontSizeOne">{{ item.wrtr }}</span>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-divider class="mb-2"></v-divider>
        <v-pagination
            v-model="page" :length="pageCount" @input="getPage" total-visible="10">
        </v-pagination>
    </div>
</template>

<script>
import { selHlthNrsRecHisList } from '../../api/bnftrcrd.js';

export default {
    name: 'NsgRcrdSttsTab1',

    props: {
   
    },
    components: {

    },

    
    created: function () {
    },
    
    mounted: function () {
        this.$nextTick(function () {

        })
    },
    
    computed: {
        getBnMmbrPk: function () {
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getSrchCode: function () {
            return this.$parent.$parent.$parent.$parent.srchCd
        },
        getSltYMD: function () {
            return this.$parent.$parent.$parent.$parent.sltYmd
        },
        getSltYYMM: function () {
            return this.$parent.$parent.$parent.$parent.sltYymm
        },
        getSltYYYY: function () {
            return this.$parent.$parent.$parent.$parent.sltYyyy
        },
    },

    watch: {
        getBnMmbrPk: function ( v ) {
            this.page = 1
            this.pageCount = 0

            if(v > 0)
                this.getHlthNrsRecHisList(0, this.itemsRows)
            else
                this.recHisList.splice(0)
        },
        getSrchCode: function () {
            this.page = 1
            this.pageCount = 0

            this.$parent.$parent.$parent.$parent.sltYyyy = ''
            this.$parent.$parent.$parent.$parent.sltYymm = ''
            this.$parent.$parent.$parent.$parent.sltYmd = ''
        },
        getSltYMD: function (ymd) {
            if(ymd != '' && this.$store.getters.getBnfcrState.bnmmbr > 0){
                if(this.$parent.$parent.$parent.$parent.srchCd == 0)
                    this.getHlthNrsRecHisList(0, this.itemsRows)
            }
        },
        getSltYYMM: function (yymm) {
            if(yymm != '' && this.$store.getters.getBnfcrState.bnmmbr > 0){
                if(this.$parent.$parent.$parent.$parent.srchCd == 1)
                    this.getHlthNrsRecHisList(0, this.itemsRows)
            }
        },
        getSltYYYY: function (yyyy) {
            if(yyyy != '' && this.$store.getters.getBnfcrState.bnmmbr > 0){
                if(this.$parent.$parent.$parent.$parent.srchCd == 2)
                    this.getHlthNrsRecHisList(0, this.itemsRows)
            }
        },
    },

    methods: {
        // 건강 및 간호제공 기록내역 조회
        getHlthNrsRecHisList: function (strt, lmt) {
            this.recHisList.splice(0)
            this.recHisLoader = true
            
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.rnStrt = strt
            obj.rnLmt = lmt
            if(this.$parent.$parent.$parent.$parent.srchCd == 0)
                obj.inqYMD = this.$parent.$parent.$parent.$parent.sltYmd
            else if(this.$parent.$parent.$parent.$parent.srchCd == 1)
                obj.inqYYMM = this.$parent.$parent.$parent.$parent.sltYymm
            else if(this.$parent.$parent.$parent.$parent.srchCd == 2)
                obj.inqYYYY = this.$parent.$parent.$parent.$parent.sltYyyy
 
            selHlthNrsRecHisList(obj)
                .then((response) => ( this.selHlthNrsRecHisListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selHlthNrsRecHisList : ' + error))
        },
        selHlthNrsRecHisListAfter: function (res) {
            if(Object.keys(res).length > 0){
                if(res.rnTot != null && res.rnTot != '')
                    this.pageCount = Math.ceil( Number(res.rnTot) / this.itemsRows )
                else
                    this.pageCount = 0
                if(res.list.length > 0)
                    this.recHisList = res.list
                else
                    this.recHisList.splice(0)
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, '조회 실패', 'info'])

            this.recHisLoader = false
        },
        getPage: function () {
            let strt = ( ( this.page-1 ) * this.itemsRows )
            this.getHlthNrsRecHisList(strt, this.itemsRows)
        },    
    },
    data: () => ({
        page: 1,
        pageCount: 0,
        itemsRows: 10,
        recHisLoader: false,
        recHisList: [],
        headers: [
            { text: '작성일', value: 'recDt', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 90px !important;' },
            { text: '혈압', value: 'bldPrsH', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR', style: 'width: 65px !important;' },
            { text: '맥박', value: 'pulse', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR', style: 'width: 50px !important;' },
            { text: '체온', value: 'tmprt', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR', style: 'width: 55px !important;' },
            { text: '호흡', value: 'rsprt', align: 'center', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR', style: 'width: 50px !important;'  },
            { text: '혈당', value: 'bldSgr', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR', style: 'width: 55px !important;' },
            { text: '체중', value: 'wght', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR', style: 'width: 55px !important;' },
            { text: '건강(분)', value: 'hltTmSum', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR py-1', style: 'width: 55px !important; line-height: 1.2;' },
            { text: '간호(분)', value: 'nlstmsum', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR py-1', style: 'width: 55px !important; line-height: 1.2;' },
            { text: '간호일지', value: 'cntnt', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR', style: 'width: 209px !important;' },
            { text: '작성자', value: 'wrtr', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon', style: 'width: 65px !important;' },
        ],
    }),
};
</script>

<style>
    .tblCelFx .v-data-table__wrapper table { table-layout: fixed; }
</style>