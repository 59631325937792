<template>
    <v-sheet>
        <div class="mt-3">
            <v-row no-gutters>
                <v-col class="ma-0 pa-0" cols="12">
                    <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>                                        
                </v-col>
            </v-row>
            <v-row class="mt-3" no-gutters>
                <v-col class="ma-0 pa-0" cols="12">
                    <v-data-table hide-default-footer dense locale="ko" height="450"
                        hide-default-header fixed-header 
                        :headers="oprFeeInfHeaders" 
                        :items="oprFeeInfList"              
                        class="" disable-pagination>
                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>                            
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15%" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 60%" colspan="5" rowspan='1'>{{props.headers[1].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBT" style="width: 25%" colspan="2" rowspan='1'>{{props.headers[2].text}}</th>
                                </tr>
                                <tr style="border-top: 1px solid #dbdbdb !important;">
                                    <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[3].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[4].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[5].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[6].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[7].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR py-1">{{props.headers[8].text}}</th>
                                    <th class="black--text clmsHeaderCommon py-1">{{props.headers[9].text}}</th>
                                </tr>
                            </thead>
                        </template>     
                        <template v-slot:item='{ item }'>
                            <tr>                        
                                <td class="clmsBodyCommon tableBR">{{item.bnftClcdNm}}</td>                          
                                <td class="clmsBodyCommon tableBR">{{ item.rtgFee1?.toLocaleString('ko-KR') }}&nbsp;원</td>
                                <td class="clmsBodyCommon tableBR">{{ item.rtgFee2?.toLocaleString('ko-KR') }}&nbsp;원</td>
                                <td class="clmsBodyCommon tableBR">{{ item.rtgFee3?.toLocaleString('ko-KR') }}&nbsp;원</td>
                                <td class="clmsBodyCommon tableBR">{{ item.rtgFee4?.toLocaleString('ko-KR') }}&nbsp;원</td>
                                <td class="clmsBodyCommon tableBR">{{ item.rtgFee5?.toLocaleString('ko-KR') }}&nbsp;원</td>
                                <td class="clmsBodyCommon tableBR">{{ item.frstClncFee?.toLocaleString('ko-KR') }}&nbsp;원</td>
                                <td class="clmsBodyCommon">{{ item.clncFee?.toLocaleString('ko-KR') }}&nbsp;원</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>
<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

import { selBnftFeeHisList } from '../../api/opr.js';
export default {
    name: 'oprFeeInfListTab1',

    props: {
        tab: Number,
    },
    components: {
        expandYearOnlyPicker,
    },

    computed: {
    },

    watch: {
        'oprFeeInfYear': function(){
            this.selBnftFeeHisList()
        },
    },

    created: function () {
    },

    mounted: function () {
        this.$nextTick(function () {
            this.selBnftFeeHisList()
        })
    },

    methods: {
        getYear: function (yyyy) {
            this.oprFeeInfYear = yyyy
        },
        selBnftFeeHisList: function () {
            selBnftFeeHisList(this.$store.getters.getFcltyNum, this.oprFeeInfYear)
                .then((response) => (this.selBnftFeeHisListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selBnftFeeHisList : ' + error))
        },
        selBnftFeeHisListAfter: function (res) {
            this.oprFeeInfList.splice(0)
            res.forEach(el => {
                switch (el.bnftClcd) {
                    case '1':
                        el.bnftClcdNm = '일반'
                        break;
                    case '2':
                        el.bnftClcdNm = '치매전담실가형'
                        break;
                    case '3':
                        el.bnftClcdNm = '치매전담실나형'
                        break;
                    default:
                        break;
                }
                this.oprFeeInfList.push(el)
            });
        },
        isClose:function(key){
            this.dialog.splice(key,1,false)
        } ,
    },

    data: () => ({
        oprFeeInfHeaders: [            
            { text: '구분', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '공단급여 수가정보', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '계약의사 진찰비용', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },            
            { text: '1등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '2등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '3등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '4등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '5등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '초진비', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '재진비', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        oprFeeInfList: [],
        oprFeeInfYear: 2022,
    }),
};
</script>