<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">특이사항&nbsp;선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>      
        <v-row class="mt-3 mr-2__5" no-gutters>
            <v-col class="greyE01 py-2" style="text-align:center;" cols="1">
                <span class="black--text font-weight-medium" style="text-align:center;">연번</span>
            </v-col>            
            <v-col class="greyE01 py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="10">
                <span class="black--text font-weight-medium" style="text-align:center;">특이사항</span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="1">
                <span class="black--text font-weight-medium" style="text-align:center;">적용</span>
            </v-col>
        </v-row> 
        <div v-if="recCntntList.length > 0" class="d-inline-block" style="overflow-y:scroll;width: 100%; height: 433.1px">
            <v-row
                class="" style="border-bottom: 1px solid #dbdbdb !important;"
                v-for="(list, i) in recCntntList" :key="i" no-gutters>
                <v-col class="py-2" style="text-align:center;" cols="1">
                    <span class="" style="text-align:center;">{{i+1}}</span>
                </v-col>
                <v-col class="pa-2" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                    <div v-html="list.cntnt?.replaceAll('\n', '<br />')"></div>
                </v-col>                
                <v-col class="py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="1">
                    <v-btn
                        v-if="list.wrkTypCd != '2'"
                        class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 2px !important"
                        color="bckgrColor" min-width="30" height="25"
                        @click="onAction(list)"
                        dark small>
                            <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else class="d-inline-block overflow-y-auto" style="width: 100%; height: 433.1px">
            <v-row
                class="" no-gutters>
                <v-col class="grey006--text py-2" style="text-align: center;" cols="12">
                    ※ 조회된 항목이 없습니다.
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { selBnftOfrRecCntntList } from '../../api/bnftrcrd.js';

export default {
    name: 'recCntntListMdl',

    props : { 
        bnMmbrPk : { type: Number, default: 0 },
        clsfc1Cd : { type: String, default: '' },
        clsfc2Cd : { type: String, default: '' },
        clsfc3Cd : { type: String, default: '' },
        inqYMD   : { type: String, default: '' },
    },
            
    components: {        
    },

    created: function(){
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getRecCntntList()
        })
    },

    computed: {

    },

    watch: {
        
    },
        
    methods: {
        
        onClose: function () {
            this.$emit('isClose', '.')
        },

        getRecCntntList:function(){
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                bnMmbrPk : this.bnMmbrPk,
                clsfc1Cd : this.clsfc1Cd,
                clsfc2Cd : this.clsfc2Cd,
                clsfc3Cd : this.clsfc3Cd,
                inqYMD : this.inqYMD,
            }
            selBnftOfrRecCntntList(obj)
                .then((response) => ( this.getRecCntntListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecCntntList : ' + error))            
        },

        getRecCntntListAfter:function(res){
            this.recCntntList.splice(0)
            if(res !='' && res !=null && res != undefined){
                res.forEach(el => {
                    if(el != null){
                        if(el.cntnt != null){
                            if(el.cntnt?.isEmptyVal()){                    
                                // el.cntnt = el.cntnt?.replaceAll("\n", "<br />")
                                // el.spclNote?.replaceAll("\n", "<br />")
                                this.recCntntList.push(el)
                            }
                        } 
                    }
                    
                });
            }
        },

        onAction:function(obj){
            this.$emit('outCntnt', obj);
            this.onClose()
        }
    },
    
    data: () => ({
       recCntntList:[]
    }),
};
</script>