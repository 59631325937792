<template>
    <v-sheet class="ma-0" rounded="md">
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">근무시간</span>
                <span class="d-inline-block ml-4" style="width: 100px;">
                    <v-checkbox class="clmHisCheck" v-model="pastInclude"
                        true-value="1" false-value="2" label="변경내역포함"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" hide-details>
                    </v-checkbox>
                </span>
                <v-spacer></v-spacer>
                <span class="red--text" v-if="stdWrkHr != ''">※기준근무시간&#58;&nbsp;{{ stdWrkHr }}시간</span>
            </v-col>
        </v-row>
        <v-divider :class="['mt-3', wrkngList.length > 3 ? 'mr-2__5' : 'ma-0']"></v-divider>
        <v-row :class="['greyE01 pa-0', wrkngList.length > 3 ? 'mr-2__5' : 'ma-0']" no-gutters>
            <v-col class="tableBR" cols="3">
                <v-row class="greyE01 pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-2" cols="12">
                        <span class="black--text font-weight-medium">근로시간</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR" cols="6">
                        <span class="black--text font-weight-medium py-2">시작시간</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="6">
                        <span class="black--text font-weight-medium">종료시간</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="tableBR" cols="3">
                <v-row class="greyE01 pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-2" cols="12">
                        <span class="black--text font-weight-medium">휴게시간(분)</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR" cols="6">
                        <span class="black--text font-weight-medium py-2">주간(06~22)</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="6">
                        <span class="black--text font-weight-medium">야간(22~06)</span>
                    </v-col>
                    <!-- <v-col class="d-flex justify-center align-center" cols="4">
                        <span class="black--text font-weight-medium">야간익일(01~05)</span>
                    </v-col> -->
                </v-row>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">실근무시간</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">적용시작일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="2">
                <span class="black--text font-weight-medium">적용종료일</span>
            </v-col>
        </v-row>
        <v-divider :class="[wrkngList.length > 3 ? 'mr-2__5' : 'ma-0']"></v-divider>
        <div 
            class="d-inline-block d-flex justify-center align-center" style="width: 100%; height: 96.33px; border-bottom: 1px solid #dbdbdb;"
            v-if="wrkngList.length == 0">
            <span>조회된 내용이 없습니다.</span>
        </div>
        <div class="d-inline-block overflow-y-auto" v-else style="width: 100%; height: 96.33px;">
            <v-row class="pa-0 ma-0" v-for="(list, i) in wrkngList" :key="i" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="tableBR" cols="3">
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR" cols="6">
                            <span class="black--text py-1">{{ list.wrkTmHhStrt }}&#58;{{ list.wrkTmMmStrt }}</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center" cols="6">
                            <span class="black--text">{{ list.wrkTmHhEnd }}&#58;{{ list.wrkTmMmEnd }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="tableBR" cols="3">
                    <v-row class="ma-0" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR" cols="6">
                            <span class="black--text py-1">{{ list.brkDay }}분</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center" cols="6">
                            <span class="black--text">{{ list.brkNgt }}분</span>
                        </v-col>
                        <!-- <v-col class="d-flex justify-center align-center" cols="4">
                            <span class="black--text">{{ list.brkOvn }}분</span>
                        </v-col> -->
                    </v-row>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text py-1">{{ list.actWrkHr }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text py-1">{{ list.aplStrt }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <span class="black--text py-1">{{ list.aplEnd == '2999.12.31' ? '현재' : list.aplEnd }}</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { selEmpWrkHrList, selStdWrkHrInfo } from '../../api/emp.js';

export default {
    name: 'EmpWorkHours',

    props : {
       prtEmpPk: { type: Number, default: -1 },
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
       
        })
    },

    computed: {
        getPickerDate: function () {
            return this.$parent.$parent.$parent.$parent.date
        },
    },

    watch:{
        'prtEmpPk': function () {
            if(this.prtEmpPk == -1){
                this.wrkngList.splice(0)
            }
            else {
                this.getEmpWrkHrList(this.prtEmpPk, null)
            }
        },
        'pastInclude': function () {
            if(this.pastInclude == '1')
                this.getEmpWrkHrList(this.prtEmpPk, '29991231')
            else if(this.pastInclude == '2')
                this.getEmpWrkHrList(this.prtEmpPk, null)
        },
        getPickerDate: function (value) {
            this.getStdWrkHrInfo(value)
        },
    },
    
    methods: {
        getEmpWrkHrList: function (pk, past) {
            selEmpWrkHrList(this.$store.getters.getFcltyNum, pk, past)
                .then((response) => ( this.getEmpWrkHrListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkHrList : ' + error))
        },
        getStdWrkHrInfo: function (yymm) {
            selStdWrkHrInfo(yymm)
                .then((response) => ( this.getStdWrkHrInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selStdWrkHrInfo : ' + error))
        },
        getEmpWrkHrListAfter: function (res) {
            res.forEach(item => {
                item.aplStrt = item.aplStrt.beforeDateFormatDot()
                item.aplEnd = item.aplEnd.beforeDateFormatDot()
                item.actWrkHr = Math.floor(item.actWrkHr / 60) + '시간 ' + (item.actWrkHr % 60) + '분'
            });
            this.wrkngList = res
        },
        getStdWrkHrInfoAfter: function (res) {
            if(typeof res.stdWrkHr != 'undefined')
                this.stdWrkHr = res.stdWrkHr
            else
                this.stdWrkHr = ''
        },
    },

    data: () => ({ 
        pastInclude: '2',
        stdWrkHr: '',
        wrkngList: [],
    }),
};
</script>