/**
 * 20220101문자열을 2022.01.01 형식으로 변환
 * @returns String Date
 */
String.prototype.beforeDateFormatDot = function() {
    let result = ''
    if (this.length != 8) return this
    if (this != null && this != '') {
        result = this.substr(0, 4) + '.' + this.substr(4, 2) + '.' + this.substr(6, 2)
    }
    return result
}
/**
 * 20220101문자열을 DatePicker에서 지원하는 2022-01-01 형식으로 변환
 * @returns String Date
 */
String.prototype.beforeDateFormatHyp = function() {
    let result = ''
    if (this.length != 8) return this
    if (this != null && this != '') {
        result = this.substr(0, 4) + '-' + this.substr(4, 2) + '-' + this.substr(6, 2)
    }
    return result
}
/**
 * 202201 문자열을 2022.01 형식으로 변환
 * @returns String Date
 */
 String.prototype.beforeDateFormatMonthDot = function() {
    let result = ''
    if (this.length != 6) return this
    if (this != null && this != '') {
        result = this.substr(0, 4) + '.' + this.substr(4, 2)
    }
    return result
}
/**
 * 202201 문자열을 2022-01 형식으로 변환
 * @returns String Date
 */
 String.prototype.beforeDateFormatMonthDHyp = function() {
    let result = ''
    if (this.length != 6) return this
    if (this != null && this != '') {
        result = this.substr(0, 4) + '-' + this.substr(4, 2)
    }
    return result
}
/**
 * 2022.01 문자열을 202201 형식으로 변환
 * @returns String Date
 */
 String.prototype.afterDateFormatMonthDot = function() {
    let result = ''
    if (this.length != 7) return this
    if (this != null && this != '') {
        let splitDate = this.split('.', 2)
        splitDate.forEach((txt) => {
            result += txt
        });
    }
    return result
}
/**
 * 2022-01 문자열을 202201 형식으로 변환
 * @returns String Date
 */
 String.prototype.afterDateFormatMonthHyp = function() {
    let result = ''
    if (this.length != 7) return this
    if (this != null && this != '') {
        let splitDate = this.split('-', 2)
        splitDate.forEach((txt) => {
            result += txt
        });
    }
    return result
}
/**
 * 2022-01-01문자열을 20220101 형식으로 변환
 * @returns String Date
 */
String.prototype.afterDateFormatHyp = function() {
    let result = ''
    if (this.length != 10) return this
    if (this != null && this != '') {
        let splitDate = this.split('-', 3)
        splitDate.forEach((txt) => {
            result += txt
        });
    }
    return result
}
/**
 * 2022.01.01문자열을 20220101 형식으로 변환
 * @returns String Date
 */
String.prototype.afterDateFormatDot = function() {
    let result = ''
    if (this.length != 10) return this
    if (this != null && this != '') {
        let splitDate = this.split('.', 3)
        splitDate.forEach((txt) => {
            result += txt
        });
    }
    return result
}
/**
 * 2000문자열을 20:00 시간 형식으로 변환
 * @returns String Date
 */
String.prototype.beforeTimeFormat = function() {
    let result = ''
    if (this.length != 4) return this
    if (this != null && this != '') {
        result = this.substr(0, 2) + ':' + this.substr(2, 4)
    }
    return result
}
/**
 * 20220101문자열을 01.01 형식으로 변환
 * @returns String Date
 */
String.prototype.dateFormatDotMmDd = function() {
    let result = ''
    if (this.length != 8) return this
    if (this != null && this != '') {
        result = this.substr(4, 2) + '.' + this.substr(6, 2)
    }
    return result
}
/**
 * 공통코드의 값코드 -> 값코드명 변환
 * @param Object
 * @returns Array
 */
String.prototype.aftValCdToNm = function(obj) {    
    let result = ''
    let valCd = this
    obj.forEach(x => {                
        if(valCd == x.valcd){
            result = x.valcdnm
        }
    });
    
    return result
}

/**
 * 한글문자열  -> 바이트 계산 (2byte)
 * @param String
 * @returns String
 */
String.prototype.getBytes = function() {
    let contents = this;
    let str_character;
    let int_char_count = 0;
    let int_contents_length = contents.length;
    
    for (let k = 0; k < int_contents_length; k++) {
        str_character = contents.charAt(k);
        if (escape(str_character).length > 4)
            int_char_count += 2;
        else
            int_char_count++;
    }

    return int_char_count;
}

/**
 * 변수 null empty 체크
 * @param String
 * @returns Boolean
 */
String.prototype.isEmptyVal = function(){
    let bl = false
    let val = this;
    if(val != null && val != undefined && val != "") bl = true
    return bl
}