<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>            
            <div class="d-flex">       
                
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:680px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                     <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="2">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="10">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>

                    <div v-for="item in dsrEvltnList1" :key="item.clsfc3Cd">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="2" style="border-top: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                            </v-col>                        
                            <v-col style="border-top: 1px solid #dbdbdb;border-left: 1px solid #dbdbdb !important;" cols="10">
                                <div v-if="item.clsfc3Cd == '1'">
                                    <div v-for="(list, j) in item.list" :key="j" class="d-inline-block">
                                        <div v-if="list.valClcd =='1'" class="py-1" style="width:184px">
                                            <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                        </div>
                                        <div v-else class="d-flex py-1" style="width:184px">
                                            <span><v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox></span>
                                            <span>&#40;</span>
                                            <span style="width:100px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                            <span>&#41;</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="item.clsfc3Cd == '2'">
                                    <v-radio-group v-model="item.clsfc3Val" class="py-1" hide-details row>                                            
                                        <div v-for="(list, j) in item.list" :key="j">
                                            <span v-if="list.valClcd=='1'" class="d-inline-block pa-0 ma-0" >
                                                <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                            </span>
                                            <span v-else class="px-1">
                                                <span v-if="list.itmCd =='3'">&#40;</span>
                                                <span class="pr-1">{{list.itmCdnm}}</span>
                                                <span class="d-inline-block pa-0 ma-0 pr-1" style="width:70px">
                                                    <v-text-field v-model="list.evltn" type="number" min="0" hide-details outlined dense></v-text-field>
                                                </span>           
                                                <span v-if="list.itmCd =='4'">&#41;</span>                               
                                            </span>
                                        </div>
                                    </v-radio-group>
                                </div>
                            </v-col>                    
                        </v-row> 
                    </div>                    
                    <div class="ma-0 pa-0">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-top: 1px solid #dbdbdb !important;"> 
                                <span class="black--text font-weight-medium">주수발자</span>
                            </v-col>                        
                            <v-col class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="10"> 
                                <div style="border-left: 1px solid #dbdbdb !important;">
                                    <div v-for="(item, k) in dsrEvltnList2" :key="k">  
                                        <v-radio-group v-model="item.clsfc3Val" hide-details row>
                                            <span v-for="(list, j) in item.list" :key="j">
                                                <span class="d-inline-block">
                                                    <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                                </span>
                                                <span v-if="list.valClcd=='2'">
                                                    <span class="d-inline-block">&#40;</span>
                                                    <span class="d-inline-block" style="width:80px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                                    <span class="d-inline-block">&#41;</span>
                                                </span>  
                                            </span>
                                        </v-radio-group>
                                    </div>
                                </div>
                                <div class="pa-0 ma-0 d-flex justify-center align-center">                                    
                                    <div class="d-inline-block greyE01" style="width:20%; line-height: 61px; border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                                        <div class="black--text font-weight-medium"  style="text-align:center;">관계</div>
                                    </div>   
                                    <div class="d-inline-block" style="width:80%; border-top: 1px solid #dbdbdb !important;border-left: 1px solid #dbdbdb !important;">
                                        <div class="py-1">
                                        <div v-for="(item, m) in dsrEvltnList3" :key="m">  
                                            <v-radio-group v-model="item.clsfc3Val" :rules="[rules.required]" hide-details row>
                                                <span v-for="(list, n) in item.list" :key="n">
                                                    <span class="d-inline-block">
                                                        <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                                    </span>
                                                    <span v-if="list.valClcd=='2'">
                                                        <span class="d-inline-block">&#40;</span>
                                                        <span class="d-inline-block" style="width:80px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                                        <span class="d-inline-block">&#41;</span>
                                                    </span>  
                                                </span>
                                            </v-radio-group>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>  
                        </v-row>
                    </div>
                    <div v-for="item in dsrEvltnList4" :key="item.clsfc3Cd">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="2" style="border-top: 1px solid #dbdbdb !important;"> 
                                <span v-if="item.clsfc3Cd=='5'" class="black--text font-weight-medium">주수발자<br />경제상태</span>
                                <span v-if="item.clsfc3Cd=='6'" class="black--text font-weight-medium">주수발자<br />부양부담</span>
                            </v-col>                        
                            <v-col class="d-flex justify-center align-center" style="border-top: 1px solid #dbdbdb;border-left: 1px solid #dbdbdb !important;" cols="10">                                
                                <div class="py-1" style="width:100%">
                                    <v-radio-group v-model="item.clsfc3Val" hide-details row>                                            
                                        <div v-for="(list, j) in item.list" :key="j">
                                            <span class="d-inline-block pa-0 ma-0" >
                                                <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                            </span>
                                            <span v-if="list.valClcd=='2'">&#40;</span>
                                            <span class="d-inline-block pa-0 ma-0" style="width:70px" v-if="list.valClcd=='2'">
                                                <v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field>
                                            </span>           
                                            <span v-if="list.valClcd=='2'">&#41;</span>                               
                                        </div>
                                    </v-radio-group>
                                </div>
                            </v-col>                    
                        </v-row> 
                    </div>
                    <div v-for="item in dsrEvltnList5" :key="item.clsfc3Cd">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-top: 1px solid #dbdbdb !important;"> 
                            <span class="black--text font-weight-medium">진료병원</span>
                            </v-col>                        
                            <v-col class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="10">                             
                                <div class="pa-0 ma-0 d-flex justify-center align-center">
                                    <span class="d-inline-block greyE01" style="width:20%; border-left: 1px solid #dbdbdb !important;">
                                        <div class="black--text font-weight-medium" style="text-align:center;height:35px;line-height:35px;">진료과</div>
                                        <div class="black--text font-weight-medium" style="text-align:center;height:35px;line-height:35px;border-top: 1px solid #dbdbdb !important;">정기진료</div>
                                    </span>
                                    <span class="d-inline-block" style="width:30%; border-left: 1px solid #dbdbdb !important;">
                                        <div class="pa-1" style="height:35px;"><v-text-field v-model="itmValTxt" hide-details outlined dense></v-text-field></div>
                                        <div class="pa-1" style="height:35px;border-top: 1px solid #dbdbdb !important;">
                                            <v-radio-group v-model="item.clsfc3Val"  hide-details row>
                                                <span v-for="(list, i) in item.list" :key="i">
                                                    <span v-if="list.valClcd=='1'" class="d-inline-block">
                                                        <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                                    </span>
                                                </span>
                                            </v-radio-group>
                                        </div>
                                    </span>
                                    <span class="greyE01 d-inline-block d-flex justify-center align-center black--text font-weight-medium" style="width:20%; height: 70px;border-left: 1px solid #dbdbdb !important;">
                                        전화번호
                                    </span>
                                    <span class="d-inline-block d-flex" style="width:30%;height: 70px;border-left: 1px solid #dbdbdb !important;">
                                        <span class="pl-1 pt-5" style="width:100%"><v-text-field v-model="itmValPhone" hide-details outlined dense></v-text-field></span>
                                    </span>
                                </div>
                            </v-col>           
                                    
                        </v-row>
                    </div>
                </div>


                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:680px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                    <v-row class="pa-0 ma-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">
                          <span class="black--text font-weight-medium">확인</span>
                        </v-col>
                    </v-row>
                    <div v-for="item in dsrEvltnList6" :key="item.clsfc3Cd"> 
                        <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="3"> 
                                <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                            </v-col>
                            <v-col class="py-1 ma-0" cols="9" style="border-left: 1px solid #dbdbdb !important;">                              
                                <div v-if="item.clsfc3Cd == '8'"> 
                                    <v-radio-group v-model="item.clsfc3Val" :rules="[rules.required]" hide-details row>
                                        <span v-for="(list, i) in item.list" :key="i">
                                            <span v-if="list.valClcd=='1'" class="d-inline-block">
                                                <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                            </span>
                                            <!-- <span v-if="i==1" class="d-inline-block">&#40;</span>
                                            <span v-if="list.valClcd=='2'">
                                                <span class="d-inline-block pl-1">{{list.itmCdnm}}:</span>
                                                <span class="d-inline-block pl-1" style="width:80px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                            </span>  
                                            <span  v-if="i==3" class="d-inline-block">&#41;</span> -->
                                        </span>
                                    </v-radio-group>
                                </div>
                                <div v-else-if="item.clsfc3Cd == '9'"> 
                                    <v-radio-group v-model="item.clsfc3Val" :rules="[rules.required]" hide-details row>
                                        <span v-for="(list, i) in item.list" :key="i">
                                            <span class="d-inline-block">
                                                <v-radio :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                            </span>
                                            <span v-if="list.valClcd=='2'">
                                                <span class="d-inline-block">&#40;</span>
                                                <span class="d-inline-block" style="width:80px"><v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field></span>
                                                <span class="d-inline-block">&#41;</span>
                                            </span>  
                                        </span>
                                    </v-radio-group>
                                </div>
                                <div v-else-if="item.clsfc3Cd == '10'">
                                    <span v-for="(list, i) in item.list" :key="i">
                                        <span v-if="i==1" class="d-inline-block">&#40;</span>
                                        <span v-if="list.itmCd == '5' || list.itmCd == '6'" class="d-inline-block py-1" style="width:165px;">
                                            <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                        </span>
                                        <span v-else class="d-inline-block py-1" style="width:150px;">
                                            <v-checkbox v-model="list.itmVal"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                        </span>
                                        <span v-if="i==2" class="d-inline-block">&#41;</span>
                                        <span class="d-inline-block"  style="width:80px" v-if="list.valClcd=='2'">
                                            <v-text-field v-model="list.evltn" hide-details outlined dense></v-text-field>
                                        </span>  
                                    </span>
                                </div>
                                <div v-else class="pa-1">
                                    <span v-for="(list, j) in item.list" :key="j">                                
                                        <v-textarea v-model="list.evltn" class="rmrksArea"
                                            rows="2" no-resize hide-details outlined dense>
                                        </v-textarea>
                                    </span>
                                </div>
                            </v-col>                    
                        </v-row>   
                    </div>
                </div>  
            </div>
        </v-form>      
    </v-sheet>
</template>
<script>
import { groupBy } from '../../api/common.js';
import { insBnfcrExamHis } from '../../api/bnftSrv.js';

export default {
    name: 'DsrEvltnTab5',

    props : {
        
    },

    computed: {
        
    },  

    watch:{
        
    },

    created:function(){
        this.$nextTick(function () { 
        
        })      
    },
    
    mounted: function(){
       
    },
        
    methods: {
        onShow(value){
            this.getData(value);  
        },
        
        onClose: function () {            
            this.$emit('modalClose', this.dsrEvltn)
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        setData(data){   
            this.dsrEvltnList1.splice(0)
            this.dsrEvltnList2.splice(0)
            this.dsrEvltnList3.splice(0)
            this.dsrEvltnList4.splice(0)
            this.dsrEvltnList5.splice(0)
            this.dsrEvltnList6.splice(0)

            let groups = groupBy(data, 'clsfc3Cd')
            
            Object.keys(groups).forEach((key)  => {
                let obj = {
                    clsfc3Cd : key,
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,
                    clsfc3Val:'',
                    list : groups[key]
                }

                obj.clsfc3Val = this.getRadioData(groups[key])

                if(key == '1' || key == '2'){
                    this.dsrEvltnList1.push(obj)
                }else if(key == '3'){
                    this.dsrEvltnList2.push(obj)
                }else if(key == '4'){
                    this.dsrEvltnList3.push(obj)
                }else if(key == '5' || key == '6'){
                    this.dsrEvltnList4.push(obj)
                }else if(key == '7'){

                    groups[key].forEach(e => {
                        if(e.itmCd =='1') this.itmValTxt = e.itmVal
                        else if(e.itmCd =='4') this.itmValPhone = e.itmVal
                    });

                    this.dsrEvltnList5.push(obj)
                }else{
                    this.dsrEvltnList6.push(obj)
                }
            }); 
            this.$refs.tabform.resetValidation();
        },

        insBnfcrExam(data){
            
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting
                
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'101',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                let examList = []

                this.dsrEvltnList1.forEach(x => {
                    x.list.forEach(item => { 

                        if(item.clsfc3Cd == '2'){
                            if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                            else item.itmVal = '2'

                        }

                        if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''


                        examList.push(item)
                    });
                })

                this.dsrEvltnList2.forEach(x => {
                    x.list.forEach(item => { 
                                                
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'

                        if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''


                        examList.push(item)
                    });
                })

                this.dsrEvltnList3.forEach(x => {
                    x.list.forEach(item => { 
                                          
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'
                        
                        if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''


                        examList.push(item)
                    });
                })

                this.dsrEvltnList4.forEach(x => {
                    x.list.forEach(item => { 
                                                
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'
                        
                        if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''


                        examList.push(item)
                    });
                })

                this.dsrEvltnList5.forEach(x => {
                    x.list.forEach(item => {                         
                        if(item.itmCd =='1') {
                            item.itmVal = this.itmValTxt
                        } else if(item.itmCd =='4'){
                         item.itmVal = this.itmValPhone
                        } else {
                            if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                            else item.itmVal = '2'
                        }

                        examList.push(item)
                    });
                })

                this.dsrEvltnList6.forEach(x => {
                    x.list.forEach(item => { 

                        if(item.clsfc3Cd == '8' || item.clsfc3Cd == '9'){
                            if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                            else item.itmVal = '2'

                        }

                        if(item.valClcd =='2' && item.itmVal != '1' && item.clsfc3Cd != '11' && item.clsfc3Cd != '8' ) item.evltn=''

                        examList.push(item)
                    });
                })

                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('dsrEvltnTrigger', res)            
        },   
        getRadioData(data){     
            let itmVal = ''       
            data.forEach(item => {
                if(item.itmVal == '1') itmVal = item.itmCd
            });

            return itmVal
        }, 
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []
            
            this.dsrEvltnList1.forEach(x => {
                x.list.forEach(item => { 

                    if(item.clsfc3Cd == '2'){
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'

                    }

                    if(x.clsfc3Val !="2" && item.valClcd =='2') item.evltn=''


                    examList.push(item)
                });
            })

            this.dsrEvltnList2.forEach(x => {
                x.list.forEach(item => { 
                                            
                    if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                    else item.itmVal = '2'

                    if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''


                    examList.push(item)
                });
            })

            this.dsrEvltnList3.forEach(x => {
                x.list.forEach(item => { 
                                        
                    if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                    else item.itmVal = '2'
                    
                    if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''


                    examList.push(item)
                });
            })

            this.dsrEvltnList4.forEach(x => {
                x.list.forEach(item => { 
                                            
                    if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                    else item.itmVal = '2'
                    
                    if(item.valClcd =='2' && item.itmVal != '1') item.evltn=''


                    examList.push(item)
                });
            })

            this.dsrEvltnList5.forEach(x => {
                x.list.forEach(item => {                         
                    if(item.itmCd =='1') {
                        item.itmVal = this.itmValTxt
                    } else if(item.itmCd =='4'){
                        item.itmVal = this.itmValPhone
                    } else {
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'
                    }

                    examList.push(item)
                });
            })

            this.dsrEvltnList6.forEach(x => {
                x.list.forEach(item => { 

                    if(item.clsfc3Cd == '8' || item.clsfc3Cd == '9'){
                        if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                        else item.itmVal = '2'

                    }

                    if(item.valClcd =='2' && item.itmVal != '1' && item.clsfc3Cd != '11' && item.clsfc3Cd != '8' ) item.evltn=''

                    examList.push(item)
                });
            })

            
            return examList
        }
    },

    
    
    data: () => ({ 
        obj:{},
        dsrEvltnList1:[],
        dsrEvltnList2:[],
        dsrEvltnList3:[],
        dsrEvltnList4:[],
        dsrEvltnList5:[],
        dsrEvltnList6:[],
        itmVal:[],
        itmValTxt:'',        
        itmValPhone:'',        
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
        },
        
    }),
};
</script>