<template>    
    <v-sheet class="rounded-lg rounded-l-0 allbnftsrvprcs" min-width="1260" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="text-h4 px-2 mt-2">
            <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
            <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">2-1. 전체&nbsp;급여제공과정</span>
        </div>
        <div>
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
        </div>
        <v-row class="justify-left mt-1 mx-3"> 
            <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
            <exBtn class="ml-1" :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
            <!-- <exBtn class="ml-1" :btnData="exBtn[2]" @btnClosed="expandClose" ref="exBtnClose2" @changeCheck="filterSearchD3" /> -->
            <span class="d-inline-block ml-2" style="width: 105px;">
                <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" hide-details width="100px" height="35px"
                    single-line rounded outlined dense filled label="이름조회" @input="setSearchText" >
                </v-text-field>
            </span>
        </v-row>
        <div class="mt-5 mx-3 pr-1" style="width:1235px">            
            <v-data-table                             
                v-model="selectedItemIndex"
                hide-default-footer dense locale="ko" height="700"
                :headers="headers"  
                :items="items"
                :search="schDummy" :custom-filter="customFilter"
                hide-default-header fixed-header disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn0" style="width:60px;z-index:3" rowspan='4'>{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn1" style="width:120px;z-index:3" rowspan='4'>{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn2" style="width:60px;z-index:3" rowspan='4'>{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn3" style="width:60px;z-index:3" rowspan='4'>{{props.headers[3].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:100px;" rowspan='4'>{{props.headers[4].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:100px;" rowspan='4'>{{props.headers[5].text}}</th> 
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:60px;" rowspan='4'>{{props.headers[6].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:110px;" rowspan='4'>{{props.headers[7].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:110px;" rowspan='4'>{{props.headers[8].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="" colspan='6'>수급자 욕구평가&#40;연1회&#41;</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="" colspan='3'>급여제공 계획&#40;연1회&#41;</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT py-1" style="" colspan='2'>급여제공 결과평가<br/>&#40;반기1회&#41;</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="120px;" colspan='1'>수급자<br/>&#40;보호자&#41;<br/>참여</th>                            
                        </tr>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>{{props.headers[9].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>{{props.headers[10].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>{{props.headers[11].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>{{props.headers[12].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>{{props.headers[13].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>물리&#40;작업&#41;<br />치료평가</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>물리&#40;작업&#41;<br />치료계획</th>
                            <th class="black--text clmsHeaderCommon tableBR  py-1" style="top:70px;" colspan='2'>급여제공계획</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>{{props.headers[18].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:70px;" rowspan='2'>{{props.headers[19].text}}</th>
                            <th class="black--text clmsHeaderCommon" style="120px;top:70px;" rowspan='2'>상담내용급여<br/>제공반영<br/>&#40;연1회&#41;</th>
                        </tr>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:105px;">{{props.headers[16].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="top:105px;">{{props.headers[17].text}}</th>                            
                        </tr>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR " style="width:110px;top:140px;">{{totObj.tot101}} / {{items.length}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot102}} / {{items.length}}</th>                            
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot103}} / {{items.length}}</th> 
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot104}} / {{items.length}}</th> 
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot105}} / {{items.length}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot106}} / {{items.length}}</th>                            
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot202}} / {{items.length}}</th> 
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot201}} / {{items.length}}</th> 
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot3}} / {{items.length}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot4}} / {{items.length}}</th>                            
                            <th class="black--text clmsHeaderCommon tableBR" style="width:110px;top:140px;">{{totObj.tot5}} / {{items.length}}</th> 
                            <th class="black--text clmsHeaderCommon" style="width:120px;top:140px;">{{totObj.tot6}} / {{items.length}}</th> 
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="tableContentCommon fixedTable fixedPstn0" style="border-right: 1px solid #dbdbdb !important;">{{item.rn}}</td>
                        <td class="tableContentCommon fixedTable fixedPstn1" style="border-right: 1px solid #dbdbdb !important;">{{item.name}}</td>
                        <td class="tableContentCommon fixedTable fixedPstn2" style="border-right: 1px solid #dbdbdb !important;">
                            <v-img class="d-inline-block" style="vertical-align: middle;" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg" width="24"></v-img>
                            <v-img class="d-inline-block" style="vertical-align: middle;" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg" width="24"></v-img>
                        </td>
                        <td class="tableContentCommon fixedTable fixedPstn3" style="border-right: 1px solid #dbdbdb !important;">{{item.age}}</td>
                        <td class="tableContentCommon" style="border-right: 1px solid #dbdbdb !important;">{{item.entStcd}}</td>
                        <td class="tableContentCommon" style="border-right: 1px solid #dbdbdb !important;">{{item.lvnRmNm}}</td>
                        <td class="tableContentCommon" style="border-right: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center">
                                <span :class="'ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </div>
                        </td>
                        <td class="tableContentCommon" style="border-right: 1px solid #dbdbdb !important;">{{item.entDt?.beforeDateFormatDot()}}</td>
                        <td class="tableContentCommon" style="border-right: 1px solid #dbdbdb !important;">{{item.lvDt?.beforeDateFormatDot()}}</td>                                                
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(0,item,item.bnfcrExamHisPk101)">
                            <span v-if="item.bnfcrExamHisPk101 == null || item.bnfcrExamHisPk101 == '' || item.bnfcrExamHisPk101 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.examDt101?.beforeDateFormatDot()}}</span>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(1,item,item.bnfcrExamHisPk102)">
                            <div v-if="item.bnfcrExamHisPk102 == null || item.bnfcrExamHisPk102 == '' || item.bnfcrExamHisPk102 == 0" class="d-inline-block tableunwritten">미작성</div>
                            <div v-else>{{item.examDt102?.beforeDateFormatDot()}}</div>
                            <div v-if="item.examStt102 != '' && item.examStt102 != null" class="d-flex justify-center align-center pb-1">
                                <!-- <div><v-icon color="#4A148C">mdi-alert-decagram-outline</v-icon></div> -->
                                <div class="font-weight-medium px-1 alert-msg">{{item.examStt102}}</div>
                            </div>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(2,item,item.bnfcrExamHisPk103)">
                            <div v-if="item.bnfcrExamHisPk103 == null || item.bnfcrExamHisPk103 == '' || item.bnfcrExamHisPk103 == 0" class="d-inline-block tableunwritten">미작성</div>
                            <div v-else>{{item.examDt103?.beforeDateFormatDot()}}</div>                                
                            <div v-if="item.examStt103 != '' && item.examStt103 != null" class="d-flex justify-center align-center">
                                <!-- <div><v-icon color="#4A148C">mdi-alert-decagram-outline</v-icon></div> -->
                                <div class="font-weight-medium px-1 alert-msg">{{item.examStt103}}</div>
                            </div>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(3,item,item.bnfcrExamHisPk104)">
                            <span v-if="item.bnfcrExamHisPk104 == null || item.bnfcrExamHisPk104 == '' || item.bnfcrExamHisPk104 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.examDt104?.beforeDateFormatDot()}}</span>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(4,item,item.bnfcrExamHisPk105)">
                            <span v-if="item.bnfcrExamHisPk105 == null || item.bnfcrExamHisPk105 == '' || item.bnfcrExamHisPk105 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.examDt105?.beforeDateFormatDot()}}</span>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(5,item,item.bnfcrExamHisPk106)">
                            <span v-if="item.bnfcrExamHisPk106 == null || item.bnfcrExamHisPk106 == '' || item.bnfcrExamHisPk106 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.examDt106?.beforeDateFormatDot()}}</span>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(6,item,item.bnftOfrPlnHisPk202, item.bnfcrPk202)">                            
                            <span v-if="item.bnftOfrPlnHisPk202 == null || item.bnftOfrPlnHisPk202 == '' || item.bnftOfrPlnHisPk202 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.wrDt202?.beforeDateFormatDot()}}</span>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(7,item,item.bnftOfrPlnHisPk201, item.bnfcrPk201)">
                            <div v-if="item.bnftOfrPlnHisPk201 == null || item.bnftOfrPlnHisPk201 == '' || item.bnftOfrPlnHisPk201 == 0" class="d-inline-block tableunwritten">미작성</div>
                            <div v-else>
                                <div>{{item.wrDt201?.beforeDateFormatDot()}}</div>
                                <div v-if="item.bzPk != null && item.bzPk > 0" class="py-1">
                                    <span class="pa-1 result-msg">결과평가반영</span>
                                </div>
                            </div>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showBizMsg(item,item.bnftOfrPlnHisPk201)">
                            {{item.eSigCfm}}                            
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(9,item,item.bnftOfrPlnHisPk4, item.bnfcrPk4)">
                            <span v-if="item.bnftOfrPlnHisPk4 == null || item.bnftOfrPlnHisPk4 == '' || item.bnftOfrPlnHisPk4 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.wrDt4?.beforeDateFormatDot()}}</span>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(9,item,item.bnftOfrPlnHisPk5, item.bnfcrPk5)">
                            <span v-if="item.bnftOfrPlnHisPk5 == null || item.bnftOfrPlnHisPk5 == '' || item.bnftOfrPlnHisPk5 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.wrDt5?.beforeDateFormatDot()}}</span>
                        </td>
                        <td class="tableContentCommon fixedCol" style="border-right: 1px solid #dbdbdb !important;" @click="showDialog(8,item,item.bnftOfrPlnHisPk6)">
                            <span v-if="item.bnftOfrPlnHisPk6 == null || item.bnftOfrPlnHisPk6 == '' || item.bnftOfrPlnHisPk6 == 0" class="d-inline-block tableunwritten">미작성</span>
                            <span v-else>{{item.wrDt6?.beforeDateFormatDot()}}</span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[0]" persistent max-width="1400" min-width="1400">                
                <DsrEvltnComp 
                    ref="dsrEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :bsrEvltnPk="bsrEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></DsrEvltnComp>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[1]" persistent max-width="1400" min-width="1400">                
                <FallEvltnComp 
                    ref="fallEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :fallEvltnPk="fallEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></FallEvltnComp>
            </v-dialog>
        </div>    
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[2]" persistent max-width="1400" min-width="1400">                
                <BdsrEvltnComp 
                    ref="bdsrEvltn"
                    :bnMmbrPk="bnMmbrPk"
                    :bdsrEvltnPk="bdsrEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></BdsrEvltnComp>
            </v-dialog>
        </div>   
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[3]" persistent max-width="1400" min-width="1400">                
                <CgntvEvltnComp 
                    ref="cgntvEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :cgntvEvltnPk ="cgntvEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></CgntvEvltnComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[4]" persistent max-width="1400" min-width="1400">                
                <HlthEvltnComp 
                    ref="hlthEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :hlthEvltnPk ="hlthEvltnPk"
                    :inqYYYY="year"
                    @modalEvent="modalEvent"></HlthEvltnComp>
            </v-dialog>
        </div>   
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[5]" persistent max-width="1400" min-width="1400">                
                <PhyscThrpyEvltnComp 
                    ref="physcThrpyEvltn"
                    :bnMmbrPk="bnMmbrPk" 
                    :inqYYYY="year"
                    :physcThrpyEvltnPk="physcThrpyEvltnPk"
                    @modalEvent="modalEvent"></PhyscThrpyEvltnComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[6]" persistent max-width="1470" min-width="1470">                
                <PhyscThrpyPlnComp 
                    ref="physcThrpyPln"
                    :bnMmbrPk="bnMmbrPk" 
                    :bnfcrPk="bnfcrPk"
                    :inqYYYY="year"
                    :physcThrpyPlnPk="physcThrpyPlnPk"
                    @modalEvent="modalEvent"></PhyscThrpyPlnComp>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[7]" persistent max-width="1600" min-width="1600">                
                <BnftSrvPlnComp
                    v-if="dialogTab[7]" 
                    ref="bnftSrvPln"
                    :bnMmbrPk="bnMmbrPk" 
                    :bnfcrPk="bnfcrPk"
                    :inqYYYY="year"
                    :btnDisabled="false"
                    :bnftSrvPlnCmpPk="bnftSrvPlnCmpPk"
                    @modalEvent="modalEvent"></BnftSrvPlnComp>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[8]" persistent max-width="1400" min-width="1400">                
                <BnftSrvPlnMdfComp 
                    ref="bnftSrvPlnMdf"
                    :bnMmbrPk="bnMmbrPk" 
                    :inqYYYY="year"
                    :bnftSrvPlnPk="bnftSrvPlnPk"
                    @isClose="modalEvent(8)"
                    @modalEvent="modalEvent"></BnftSrvPlnMdfComp>
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogTab[9]" persistent max-width="1500" min-width="1500">                
                <BnftSrvRsltEvltnComp 
                    ref="bnftSrvRsltEvltn"
                    :bnMmbrPk="bnMmbrPk"
                    :bnfcrPk="bnfcrPk" 
                    :inqYYYY="year"
                    :bnftSrvRsltEvltnPk="bnftSrvRsltEvltnPk"
                    @modalEvent="modalEvent"></BnftSrvRsltEvltnComp>
            </v-dialog>
        </div>     
        <div class="pa-0 ma-0">
            <v-dialog v-model="bizMsg" max-width="1100">    
                <bizMsgMdl 
                    ref="bizMsgMdl"                    
                    :bizMsgData="bizMsgData"
                    :bizMsgDocClcd="4"
                    :bizMsgBzPk="bizMsgBzPk"
                    :bizMsgShow="bizMsg"
                    :bizMsgTitle="'급여제공계획'"
                    :bizMsgCntnt="$store.getters.getBnfcrState.name"
                    @madalState="onSendClose"
                ></bizMsgMdl>  
            </v-dialog>
        </div>  
        <v-dialog v-model="dialog" eager content-class="round" persistent max-width="1300">
            <CnslnJrnls :prtBnMmbrPk="sltBnMmbrPk" :prtYear="year" :prtQtr="qtr" @isClose="modalClose()" @reload="reset()"></CnslnJrnls>
        </v-dialog>
        <v-dialog v-model="menu[0]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(0, 1, false)"></ReportMgmt>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { getAllBnftOfrList } from '../../api/bnftSrv.js';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import CnslnJrnls from '../../components/bnfcr/CnslnJrnlsModal.vue';

import exBtn from '../../components/commons/expandButtonCustom.vue';
import DsrEvltnComp from '../../components/bnftSrv/DsrEvltn.vue'; //욕구사정
import FallEvltnComp from '../../components/bnftSrv/FallEvltn.vue'; //낙상위험도
import BdsrEvltnComp from '../../components/bnftSrv/BdsrEvltn.vue'; //욕창위험도
import CgntvEvltnComp from '../../components/bnftSrv/CgntvEvltn.vue'; //인지기능
import HlthEvltnComp from '../../components/bnftSrv/HlthEvltn.vue'; //건강수준
import PhyscThrpyEvltnComp from '../../components/bnftSrv/PhyscThrpyEvltn.vue'; //물리치료평가
import PhyscThrpyPlnComp from '../../components/bnftSrv/PhyscThrpyPln.vue'; //물리치료계획
import BnftSrvPlnComp from '../../components/bnftSrv/BnftSrvPln.vue'; //급여제공계획
import BnftSrvPlnMdfComp from '../../components/bnftSrv/BnftSrvPlnMdf.vue'; //급여제공계획변경
import BnftSrvRsltEvltnComp from '../../components/bnftSrv/BnftSrvRsltEvltn.vue'; //급여제공결과평가
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue'; //발송관리

import ReportMgmt from '../../components/commons/ReportMgmt.vue';

export default {
    name: 'AllBnftSrvPrcs',
        
    components: {
        exBtn,
        expandYearOnlyPicker,
        CnslnJrnls,
        DsrEvltnComp,
        FallEvltnComp,
        BdsrEvltnComp,
        CgntvEvltnComp,
        HlthEvltnComp,
        PhyscThrpyEvltnComp,
        PhyscThrpyPlnComp,
        BnftSrvPlnComp,
        BnftSrvPlnMdfComp,
        BnftSrvRsltEvltnComp,
        bizMsgMdl,
        ReportMgmt,
    },

    created: function(){

        getMultiStcd(['62', '65'])
            .then((response) => ( this.codeMapping(response.data) ))
            .catch((error) => console.log('connect error /bnfcr/selCmnItmValCdJsn : ' + error))

        this.year = new Date().getFullYear()

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllBnftList()
        })
    },

    watch:{
        'year': function(){
            this.getAllBnftList()
        },
    },
        
    methods: {
        getAllBnftList: function () {
            getAllBnftOfrList(this.$store.getters.getFcltyNum, this.year)
                .then((response) => ( this.getAllBnftOfrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        getAllBnftOfrListAfter: function (res) { 
            console.log(res)
            this.totObj = Object.assign({})     
            this.totObj.tot101 = 0
            this.totObj.tot102 = 0
            this.totObj.tot103 = 0
            this.totObj.tot104 = 0
            this.totObj.tot105 = 0
            this.totObj.tot106 = 0
            this.totObj.tot106 = 0
            this.totObj.tot202 = 0
            this.totObj.tot201 = 0
            this.totObj.tot3 = 0
            this.totObj.tot4 = 0
            this.totObj.tot5 = 0
            this.totObj.tot6 = 0

            this.items = res
            if(res != ''){
                
                this.items.forEach(x => {
                    // x.entDt = x.entDt
                    // x.lvDt = x.lvDt

                    // x.examDt101 = x.examDt101
                    x.examRsncd101 = this.setExamRsncdNm(x.examRsncd101)                   
                    // x.examDt102 = x.examDt102
                    // x.examDt103 = x.examDt103
                    // x.examDt104 = x.examDt104
                    // x.examDt105 = x.examDt105
                    // x.examDt106 = x.examDt106
                    // x.wrDt202 = x.wrDt202
                    // x.wrDt201 = x.wrDt201
                    // x.wrDt3 = x.wrDt3
                    // x.wrDt4 = x.wrDt4
                    // x.wrDt5 = x.wrDt5
                    // x.wrDt6 = x.wrDt6

                    if(x.bnfcrExamHisPk101 !=null && x.bnfcrExamHisPk101 != ''){
                        this.totObj.tot101++
                    }

                    if(x.bnfcrExamHisPk102 !=null && x.bnfcrExamHisPk102 != ''){
                        this.totObj.tot102++
                    }

                    if(x.bnfcrExamHisPk103 !=null && x.bnfcrExamHisPk103 != ''){
                        this.totObj.tot103++
                    }

                    if(x.bnfcrExamHisPk104 !=null && x.bnfcrExamHisPk104 != ''){
                        this.totObj.tot104++
                    }

                    if(x.bnfcrExamHisPk105 !=null && x.bnfcrExamHisPk105 != ''){
                        this.totObj.tot105++
                    }

                    if(x.bnfcrExamHisPk106 !=null && x.bnfcrExamHisPk106 != ''){
                        this.totObj.tot106++
                    }

                    if(x.bnftOfrPlnHisPk202 !=null && x.bnftOfrPlnHisPk202 != ''){
                        this.totObj.tot202++
                    }

                    if(x.bnftOfrPlnHisPk201 !=null && x.bnftOfrPlnHisPk201 != ''){
                        this.totObj.tot201++
                    }

                    if(x.bnftOfrPlnHisPk3 !=null && x.bnftOfrPlnHisPk3 != ''){
                        this.totObj.tot3++
                    }

                    if(x.bnftOfrPlnHisPk4 !=null && x.bnftOfrPlnHisPk4 != ''){
                        this.totObj.tot4++
                    }

                    if(x.bnftOfrPlnHisPk5 !=null && x.bnftOfrPlnHisPk5 != ''){
                        this.totObj.tot5++
                    }

                    if(x.bnftOfrPlnHisPk6 !=null && x.bnftOfrPlnHisPk6 != ''){
                        this.totObj.tot6++
                    }
                });

                this.items.forEach((item) => {
                    this.entStCode.forEach((cd) => {
                        if(item.entStcd == cd.valcd) item.entStcd = cd.valcdnm
                    });//end foreach
                });//end foreach

            }
            
        },
        setExamRsncdNm:function(value){
            if(value=="1"){
                // return "신규"
                return ""
            }else if(value=="2"){
                return "재사정"
            }else if(value=="3"){
                return "상태변화"
            }
        },
        codeMapping: function(res){
            this.acknwCode = res.vCd62
            this.entStCode  = res.vCd65

            this.acknwCode.forEach((cd) => {
                this.exBtn[1].expandList.push(cd.valcdnm)
            });

            this.entStCode.forEach((cd) => {
                this.exBtn[0].expandList.push(cd.valcdnm)
            });
        },
        reset: function (){
            this.selectedItemIndex = []
            this.schField = ''
            this.schDummy = ''
            this.schChkField1 = []
            this.schChkField2 = []
            this.getCnslnList()
        },
        getYear: function (yyyy){
            this.year = yyyy
        },
        onModal: function (bnmmbrPk, q){
            this.dialog = true
            this.sltBnMmbrPk = Number(bnmmbrPk)
            this.qtr = q
        },
        modalClose: function (){
            this.dialog = false
        },
        expandClose: function (index) {
            switch(index) {
                case 0: 
                    this.$refs.exBtnClose1.isClose()
                    // this.$refs.exBtnClose2.isClose()
                    break;
                case 1: 
                    this.$refs.exBtnClose0.isClose()
                    // this.$refs.exBtnClose2.isClose()
                    break;
                // case 2: 
                //     this.$refs.exBtnClose0.isClose()
                //     this.$refs.exBtnClose1.isClose()
                //     break;
                default: 
                    this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    // this.$refs.exBtnClose2.isClose()
                    break;
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD1: function (sch) {
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD2: function (sch) { 
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.acknwCode.forEach((cd) => {
                    if(item == cd.valcdnm) item = cd.valcd
                })
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD3: function (sch) {
            if(sch == '작성') this.schChkField3 = 'string'
            else if(sch == '미작성') this.schChkField3 = 'undefined'
            else this.schChkField3 = ''

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            

            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((acknw, i) => {
                    if(i == 0) col2 = filter.acknwRtng.includes(acknw)
                    else col2 = col2 || filter.acknwRtng.includes(acknw)
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            let col3 = true            
            if(this.schChkField3 == '')
                col3 = true
            else if(typeof filter.examDt101 == this.schChkField3)
                col3 = true
            else
                col3 = false

            return col0 && col1 && col2 && col3;
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.getAllBnftList();
            }else{
                switch (event) {
                    case 0:
                        this.bsrEvltnPk = -1
                        break;
                    case 1:
                        this.fallEvltnPk = -1
                        break;
                    case 2:
                        this.bdsrEvltnPk = -1
                        break;
                    case 3:
                        this.cgntvEvltnPk = -1
                        break;    
                    case 4:
                        this.hlthEvltnPk = -1
                        break;    
                    case 5:
                        this.physcThrpyEvltnPk = -1
                        break; 
                    case 6:
                        this.physcThrpyPlnPk = -1
                        break;  
                    case 7:
                        this.bnftSrvPlnCmpPk = -1
                        break;      
                    case 8:
                        this.bnftSrvPlnPk = -1
                        break;
                    case 9:
                        this.bnftSrvRsltEvltnPk = -1
                        break;
                    default:
                        break;
                }
                this.dialogTab.splice(event, 1, false)
            }      
        },
        showDialog(tabkey,item,value, bnfcr = null){   
            //console.log(value)
            if(value == null || value=='') value = 0

            this.$store.commit('setBnfcrState', {
                bnmmbr: item.bnMmbrPk,
                bnfcr: (bnfcr != null)?bnfcr:item.bnfcrPk,
                name: item.name,
                entStcd: '',
                entDt: '',
                lvDt: ''
            });
            this.bnfcrPk  = (bnfcr != null)?bnfcr:item.bnfcrPk
            this.bnMmbrPk = item.bnMmbrPk
            this.dialogTab.splice(tabkey, 1, true)

            if(tabkey == 0){      
                this.bsrEvltnPk = value      
            }else if(tabkey == 1){
                this.fallEvltnPk = value                      
            }else if(tabkey == 2){
                this.bdsrEvltnPk = value      
            }else if(tabkey == 3){
                this.cgntvEvltnPk = value
            }else if(tabkey == 4){
                this.hlthEvltnPk = value
            }else if(tabkey == 5){
                this.physcThrpyEvltnPk = value
            }else if(tabkey == 6){
                this.physcThrpyPlnPk = value
            }else if(tabkey == 7){
                this.bnftSrvPlnCmpPk = value
            }else if(tabkey == 8){                                
                this.bnftSrvPlnPk = value
            }else if(tabkey == 9){
                this.bnftSrvRsltEvltnPk = value
            }           
        },
        showBizMsg:function(item, selPk){
            
            if(selPk > 0){
                this.$store.commit('setBnfcrState', {
                    bnmmbr: item.bnMmbrPk,
                    bnfcr: item.bnfcrPk,
                    name: item.name,
                    entStcd: '',
                    entDt: '',
                    lvDt: ''
                });
                this.bizMsgData.push(item.bnMmbrPk)
                this.bizMsgBzPk = selPk
                this.bizMsg = true
            }else{
                this.snackControll([true, 5000, '급여제공계획을 작성해주세요.', 'error'])
            }
        },
        onSendClose:function(){
            this.bizMsg = false
            this.bizMsgBzPk = -1
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        menu: [ false, ],
        bnMmbrPk:0,
        bnfcrPk:0,
        bsrEvltnPk:-1,              //욕구사정
        fallEvltnPk:-1,             //낙상위험도
        bdsrEvltnPk:-1,             //욕창위험도
        cgntvEvltnPk:-1,            //인지기능
        hlthEvltnPk:-1,             //건강수준
        physcThrpyEvltnPk:-1,       //물리치료평가
        physcThrpyPlnPk:-1,         //물리치료계획
        bnftSrvPlnCmpPk:-1,         //급여제공계획
        bnftSrvPlnPk:-1,            //급여제공계획변경
        bnftSrvRsltEvltnPk:-1,      //급여제공결과평가
        bizMsgBzPk:-1,
        bizMsg:false,
        bizMsgData:[],
        dialog: false,
        sltBnMmbrPk: 0,
        items: [],
        totObj:{},
        acknwCode: null,
        entStCode: null,
        year: 1990,
        qtr: 0,
        selectedItemIndex:[],
        schField: '',
        schDummy: '',
        schChkField1: [],
        schChkField2: [],
        schChkField3: [],
        exBtn: [
            {
                index:  0,
                btnTitle: '입소현황',
                btnIcon: 'house',
                exWidth: 104,
                exHeigth: 150,
                expandList: [],
            },
            {
                index:  1,
                btnTitle: '등급',
                btnIcon: 'wheelchair',
                exWidth: 104,
                exHeigth: 220,
                expandList: [],
            },
        ],
        headers: [
            { text: '연번', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6'},
            { text: '수급자명', value: 'name', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '성별', value: 'gndr', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '나이', value: 'age', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '현황', value: 'entStcd', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '생활실', value: 'lvnRmNm', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '등급', value: 'acknwRtng', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '입소일', value: 'entDt', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '퇴소일', value: 'lvDt', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '욕구사정', value: 'bnfcrExamHisPk101', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '낙상위험도', value: 'bnfcrExamHisPk102', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '욕창위험도', value: 'bnfcrExamHisPk103', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '인지기능', value: 'bnfcrExamHisPk104', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '건강수준', value: 'bnfcrExamHisPk105', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '물리(작업)치료평가', value: 'bnfcrExamHisPk106', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '물리(작업)치료계획', value: 'bnftOfrPlnHisPk202', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '작성', value: 'wrtr201', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '서명', value: 'eSigCfm', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '전반기', value: 'bnftOfrPlnHisPk4', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '하반기', value: 'bnftOfrPlnHisPk5', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '상담내용급여 제공반영(연1회)', value: 'bnftOfrPlnHisPk6', align: 'center', sortable: true, width: '100', class: 'black--text'},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '7', inqYmdStart: '', inqYmdEnd: '',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
        dialogTab: [false,false,false,false,false,false,false,false,false,false],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>
<style>
.allbnftsrvprcs .v-data-table__wrapper table{
    width:2300px;
}
.allbnftsrvprcs .v-data-table--fixed-header{
    position: sticky !important; position: -webkit-sticky !important; 
    z-index: 1; left: 0px;
}

.allbnftsrvprcs .fixedTable {
    position: sticky !important; position: -webkit-sticky !important; 
    z-index: 1; font-size: 0.975rem !important;
}

.allbnftsrvprcs .fixedPstn0 { left: 0px; }
.allbnftsrvprcs .fixedPstn1 { left: 65px; }
.allbnftsrvprcs .fixedPstn2 { left: 196px; }
.allbnftsrvprcs .fixedPstn3 { left: 262px; }
.allbnftsrvprcs .tableunwritten { width:70px;color:red; }

.allbnftsrvprcs .alert-msg {border-radius:5px;background-color:#4A148C;color:#FFFFFF;font-size:0.9rem}
.allbnftsrvprcs .result-msg {border-radius:5px;background-color:#048d22;color:#FFFFFF;font-size:0.9rem}
</style>