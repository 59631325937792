<template>
    <v-sheet class="rounded-lg rounded-l-0 px-4" color="" min-width="1280" max-width="1280" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mt-2 pr-2 d-flex align-center">
            <div class="pl-2" style="padding-bottom: 3px;">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="hamletFont text-h5 topTitleColor--text font-weight-black">1-6. 본인부담금&nbsp;청구관리</span>
            </div>
            <span class="d-flex align-center ml-4">
                <v-btn
                    class="ml-1" width="21" height="21" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 4)" icon>
                    <v-icon color="grey006" size="21" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                </v-btn>
                <span class="mx-1 fontOneRem font-weight-medium grey006--text">급여청구 확정</span>
            </span>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span class="fontOneRem ml-1">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width" content-class="round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span class="d-flex justify-center">
                <v-btn
                    :class="outlinedBtn[0].class" :color="outlinedBtn[0].color"
                    :disabled="outlinedBtn[0].disabled" @click="prtCstStt()"
                    height="30" samll rounded outlined>
                    <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                    <span class="ml-1" style="font-size: 1rem;">{{outlinedBtn[0].text}}</span>
                </v-btn>
            </span>
            <!-- <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span> -->
        </div>
        <v-row class="mt-2 pa-0">
            <v-col cols="12">
                <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-5 align-center" no-gutters>
            <v-col cols="1">
                <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
            </v-col>
            <v-col cols="1">
                <exBtn :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
            </v-col>
            <v-col cols="2">
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" width="100px" height="35px"
                        hide-details single-line rounded outlined dense filled label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
                v-for="(itm, j) in actBtn" :key="j"
                :class="itm.class" :color="itm.color" style="padding: 13px 12px 12px 10px !important"
                @click="onClickBtn(itm.text)" :disabled="itm.disabled"
                min-width="30" height="25" small outlined>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center" v-bind="attrs" v-on="on">
                            <v-icon size="20">{{ itm.icon }}</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">{{ itm.text }}</span>
                        </div>
                    </template>
                    <span>{{ itm.tip }}</span>
                </v-tooltip>
            </v-btn>
            <!-- <v-col class="" cols="">
                <span class="d-flex align-center">
                    <v-btn
                        class="ml-1" width="21" height="21" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 4)" icon>
                        <v-icon color="grey006" size="21" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                    <span class="mx-1 fontOneRem font-weight-medium grey006--text">급여청구 확정</span>
                </span>
            </v-col> -->
        </v-row>
        <v-row class="mt-3 pa-0" style="overflow-x: scroll;" no-gutters>
            <v-col cols="12">
                <v-data-table
                    class="copayClmsTbl tableFixedElm" height="650" locale="ko"
                    ref="cpyClmsTbl"
                    :headers="headers" :items="copayClmHisList"
                    :search="schDummy" :custom-filter="customFilter"
                    :loading="cpyClmHisLoader" @current-items="calcTotAmt"
                    hide-default-header fixed-header hide-default-footer disable-pagination dense>

                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="clmsFixTbl clmsHeaderCommon tableBR tableBT" style="left: 0px; width: 55px; z-index: 5; padding-left: 17px !important;" rowspan='3'>
                                    <v-checkbox class="clmsHeaderCheck" v-model="allChecker" @change="checkBoxMessage" :ripple="false" hide-details></v-checkbox>
                                </th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 55px; width: 80px; z-index: 3;" colspan="1" rowspan='3'>{{props.headers[0].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 135px; width: 80px; z-index: 3;" colspan="1" rowspan='3'>{{props.headers[1].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 215px; width: 53px; z-index: 3;" colspan="1" rowspan='3'>{{props.headers[2].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 268px; width: 97px; z-index: 3;" colspan="1" rowspan='3'>{{props.headers[3].text}}</th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBR tableBT" style="left: 365px; width: 54px; z-index: 3;" colspan="1" rowspan='3'>{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="2" rowspan='1'>급여</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="8" rowspan='1'>비급여</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="1" rowspan='3'>{{props.headers[15].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="1" rowspan='3'>{{props.headers[16].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" colspan="1" rowspan='3'>{{props.headers[17].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT" colspan="1" rowspan='3'>{{props.headers[18].text}}</th>
                                <th
                                    class="clmsFixTbl clmsHeaderCommon tableBL tableBR tableBT px-0"
                                    style="right: 52px; z-index: 3; line-height: 20px;" colspan="1" rowspan='3'>
                                    <div class="black--text">확정</div>
                                    <div class="black--text">여부</div>
                                </th>
                                <th class="clmsFixTbl black--text clmsHeaderCommon tableBT" style="right: 0px; z-index: 3;" colspan="1" rowspan='3'>상세</th>
                            </tr>
                            <tr style="border-top: 1px solid #dbdbdb !important;">
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='2'>{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='2'>{{props.headers[6].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='2'>{{props.headers[7].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='2'>{{props.headers[8].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='2'>{{props.headers[9].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='2'>{{props.headers[10].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="4" rowspan='1'>비급여기타</th>
                            </tr>
                            <tr style="border-top: 1px solid #dbdbdb !important;">
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='1'>{{props.headers[11].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='1'>{{props.headers[12].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='1'>{{props.headers[13].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" colspan="1" rowspan='1'>{{props.headers[14].text}}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:item='{ item }'>
                        <tr class="fixedCol">
                            <td
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="left: 0; z-index: 3; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                <v-checkbox
                                    v-if="item.lvDt == ''"
                                    class="clmsHeaderCheck" style="padding-left: 11px;"
                                    v-model="item.isSelected" @change="checkBoxIndex()"
                                    hide-details>
                                </v-checkbox>
                                <v-checkbox
                                    v-else-if="$moment(item.lvDt, 'YYYYMMDD').isSameOrAfter(date+'01')"
                                    class="clmsHeaderCheck" style="padding-left: 11px;"
                                    v-model="item.isSelected" @change="checkBoxIndex(getPickerDate)"
                                    hide-details>
                                </v-checkbox>
                            </td>
                            <td
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="left: 55px; z-index: 2; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.name}}
                            </td>
                            <td
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="left: 135px; z-index: 2; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{ item.entStcd }}
                            </td>
                            <td
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="left: 215px; z-index: 2; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                <span :class="'ic-level level0'+item.acknwRtng" style="margin: auto;">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                            </td>
                            <td 
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="left: 268px; z-index: 2; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                <span v-if="item.copayNm != null">
                                    {{ item.copayNm }}<span v-if="item.copayRateDesc != 'undefined'">&#40;{{ item.copayRateDesc }}&#41;</span>
                                </span>
                            </td>
                            <td
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="left: 365px; z-index: 2; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.ofrDys}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.nhisPay?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{Math.floor(item.copay)?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.mealCost?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.lqdFood?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.upBedFee?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.btyFee?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.snackFee?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.clncPhrmcFee?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.cntrcDctrFee?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.msclnFee?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.copayAmt?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.unpdAmt?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                {{item.prpymApl?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="border-bottom: 1px solid #dbdbdb !important;">
                                {{item.clmTot?.toLocaleString('ko-KR')}}
                            </td>
                            <td
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="right: 52px; z-index: 2; border-right: 1px solid #dbdbdb !important; border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                <span
                                    v-if="item.cnfrmYn == '1'"
                                    class="d-inline-block rounded blueBtnColor"
                                    style="width: 25px; padding-bottom: 3px; margin: 1px 0 0 3px; cursor: initial;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="white--text" size="16" v-bind="attrs" v-on="on">mdi-check-bold</v-icon>
                                        </template>
                                        <span>확정</span>
                                    </v-tooltip>
                                </span>
                                <span
                                    v-if="item.cnfrmYn == '2'"
                                    class="d-inline-block rounded grey005"
                                    style="width: 25px; padding-bottom: 3px; margin: 1px 0 0 3px; cursor: initial;">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="white--text" size="16" v-bind="attrs" v-on="on">mdi-minus-thick</v-icon>
                                        </template>
                                        <span>미확정</span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td
                                :class="['clmsFixTbl clmsBodyCommon fixedCol0', item.isSelected == true ? 'blue003' : 'white']"
                                style="right: 0px; z-index: 2; border-bottom: 1px solid #dbdbdb !important;">
                                <v-btn
                                    :disabled="item.copayClmHisPk == 0"
                                    @click="menu.splice(0, 1, true), selectedItem=item.copayClmHisPk, bnmmbr=item.bnMmbrPK, bnfcrPk=item.bnfcrPk" 
                                    icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>

                    <template v-slot:[`body.append`]="{ item }">
                        <tr style="z-index: 4 !important; position: sticky; bottom: 0px;">
                            <td class="fixedTable fixedPstn0 clmsHeaderCommon black--text tableBR tableBT fixedTable py-2" style="" colspan="6">합계</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.nhisPay)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.copay)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.mealCost)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.lqdFood)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.upBedFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.btyFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.snackFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.clncPhrmcFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.cntrcDctrFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.msclnFee)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.copayAmt)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.unpdAmt)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.prpymApl)?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBT" style="font-size: 0.875rem !important;" colspan="1">{{Math.floor(copayClmHisListSum.clmTot)?.toLocaleString('ko-KR')}}</td>
                            <td class="fixedTable clmsHeaderCommon black--text tableBL tableBT" style="right: 52px; z-index: 2; font-size: 0.875rem !important; width:52px !important;" colspan="1">{{item}}</td>
                            <td class="fixedTable clmsHeaderCommon black--text tableBT" style="right: 0px; z-index: 2; font-size: 0.875rem !important; width:52px !important;" colspan="1">{{item}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="1400">
            <CopayClmsDtl
                @isClose="menu.splice(0, 1, false), selectedItem = -1, bnmmbr = -1, bnfcrPk = -1"
                :clmHisPk="selectedItem" :sltMmbrPk="bnmmbr" :sltBnfcrPk="bnfcrPk">
            </CopayClmsDtl>
        </v-dialog>
        <v-dialog v-model="menu[2]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(2, 1, false)"></ReportMgmt>
        </v-dialog>
        <v-dialog v-model="menu[3]" max-width="1500" persistent>
            <CopayClmsBillMdl
                ref="copayClmsBillMdl"
                :yyyyMm="date" 
                :copayBillPk="copayBillPk"               
                @madalState="menu.splice(3, 1, false), copayBillPk=-1">
            </CopayClmsBillMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import CopayClmsDtl from '../../components/bnfcr/CopayClmsDtl.vue';
import MsclnExpnsIns from '../../components/bnfcr/MsclnExpnsIns.vue';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import CopayClmsBillMdl from '../../components/bnfcr/CopayClmsBillMdl.vue';

import { getMultiStcd } from '../../api/index.js';
import {
    selCopayClmHisList, insCopayClmCrt, updCopayClmHisCnfrm, updCnclCopayClmCnfrm, delCopayClmHis, uptLvCalcDps
} from '../../api/bnfcr.js';

export default {
    name: 'CopayClmsMgmt',
        
    components: {
        expandMonthPicker,
        exBtn,
        CopayClmsDtl,
        MsclnExpnsIns,
        ReportMgmt,
        CopayClmsBillMdl,
    },

    created: function(){
        this.filledBtn.forEach(() => {
            this.dialog.push(false)
        })
        this.outlinedBtn.forEach(() => {
            this.dialog.push(false)
        })

        this.getCode(['62', '63', '65'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
         
        })
    },

    computed: {

    },

    watch:{     
        'selectedList': function () {
            if(this.selectedList.length != 0){
                this.btnGrpChnger(false)
                this.lvStlmChnger(false)
            }
            else{
                this.btnGrpChnger(true)
                this.lvStlmChnger(true)
            }
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getCopayClmHisList: function (yyyyMm) {
            this.copayClmHisList.splice(0)
            this.cpyClmHisLoader = true
            
            selCopayClmHisList(this.$store.getters.getFcltyNum, yyyyMm)
                .then((response) => ( this.getCopayClmHisListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmHisList : ' + error))
        },
        setCopayClmCrt: function (pks, yyyyMm) {
            console.log(pks)
            if(pks.mmbrPk.length == 0)
                this.snackControll([true, 2500, '청구 생성이 불가능한 항목입니다.', 'warning'])
            else{
                this.copayClmHisList.splice(0)
                this.cpyClmHisLoader = true

                insCopayClmCrt(this.$store.getters.getFcltyNum, pks.mmbrPk, pks.bnfcrPk, yyyyMm, this.$store.getters.getUserNm)
                    .then((response) => ( this.setCopayClmCrtAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/insCopayClmCrt : ' + error))
            }
        },
        setCopayClmHisCnfrm: function (pks, yn, yyyyMm) {
            if(pks.mmbrPk.length == 0)
                this.snackControll([true, 2500, '급여청구 확정이 불가능한 상태입니다.', 'warning'])
            else{
                this.copayClmHisList.splice(0)
                this.cpyClmHisLoader = true
                updCopayClmHisCnfrm(this.$store.getters.getFcltyNum, pks.mmbrPk, pks.bnfcrPk, yn, yyyyMm, this.$store.getters.getUserNm)
                    .then((response) => ( this.setCopayClmCrtAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/updCopayClmHisCnfrm : ' + error))
            }
        },
        setCnclCopayClmHisCnfrm: function (pks, yyyyMm) {
            console.log(pks)
            if(pks.mmbrPk.length == 0)
                this.snackControll([true, 2500, '급여청구 취소가 불가능한 상태입니다.', 'warning'])
            else{
                this.copayClmHisList.splice(0)
                this.cpyClmHisLoader = true
                updCnclCopayClmCnfrm(this.$store.getters.getFcltyNum, pks.mmbrPk, pks.bnfcrPk, yyyyMm, this.$store.getters.getUserNm)
                    .then((response) => ( this.setCopayClmCrtAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/updCnclCopayClmCnfrm : ' + error))
            }
        },
        removeCopayClmHis: function (pks, yyyyMm) {
            if(pks.mmbrPk.length == 0)
                this.snackControll([true, 2500, '급여청구 삭제가 불가능한 상태입니다.', 'warning'])
            else{
                this.copayClmHisList.splice(0)
                this.cpyClmHisLoader = true
                delCopayClmHis(this.$store.getters.getFcltyNum, pks.mmbrPk, pks.bnfcrPk, yyyyMm, this.$store.getters.getUserNm)
                    .then((response) => ( this.setCopayClmCrtAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/delCopayClmHis : ' + error))
            }
        },
        setLvCalcDps: function (pks, yyyyMm) {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPks = pks.mmbrPk
            obj.bnfcrPks = pks.bnfcrPk
            obj.inqYYMM = yyyyMm
            obj.prcClcd = '7'
            obj.userNm = this.$store.getters.getUserNm

            if(pks.mmbrPk.length == 0){
                this.snackControll([true, 2500, '퇴소정산이 불가능한 상태입니다.', 'warning'])
            }
            else{
                this.copayClmHisList.splice(0)
                this.cpyClmHisLoader = true
    
                uptLvCalcDps(obj)
                    .then((response) => ( this.setCopayClmCrtAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/uptLvCalcDps : ' + error))
            }
        },

        stCdMapping: function (res) {

            res.vCd62.forEach((code) => {
                this.exBtn[1].expandList.push(code.valcdnm)
            });//end foreach
            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd62 = res.vCd62
            this.vCd63 = res.vCd63
            this.vCd65 = res.vCd65

        },
        getCopayClmHisListAfter: function (res) {
            //this.bnmmbr = -1
            this.copayClmHisList = res
            this.allChecker = false
            this.copayClmHisListSum = Object.assign({}, this.dummyCopayClmHisListSum)

            this.copayClmHisList.forEach(items => {
                if(items.lvDt == null || typeof items.lvDt == 'undefined')
                    items.lvDt = ''

                this.vCd63.forEach(code => {
                    if(items.copayRate == code.valcd){
                        items.copayNm = code.valcdnm?.slice(0, 3)?.replaceAll('(', '')
                        items.copayRateDesc = code.valdesc + '%'
                    }
                });
                this.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd)
                        items.entStcd = code.valcdnm
                });
            });

            this.btnGrpChnger(true)
            this.lvStlmChnger(true)

            this.cpyClmHisLoader = false

            this.calcTotAmt(this.copayClmHisList)
            
        },
        setCopayClmCrtAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getCopayClmHisList(this.date)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        getPickerDate: function (date) {
            this.date = date
            //this.sltDate = date.substr(0, 4) + '년 ' + date.substr(4, 2) + '월'

            this.copayClmHisListSum = Object.assign({}, this.dummyCopayClmHisListSum)
            this.getCopayClmHisList(date)
        },
        checkBoxIndex: function () {
            let chk = []
            let pkList = []
            let bnfcrList = []
            this.copayClmHisList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)

                if(items.isSelected == true){
                    pkList.push(items.bnMmbrPK)
                    bnfcrList.push(items.bnfcrPk)
                }
            });
            this.selectedList = pkList
            this.sltBnfcrList = bnfcrList
            let result = chk.every((item) => item==true)

            if(result) this.allChecker = true
            else this.allChecker = false
        },
        checkBoxMessage: function () {
            if(this.allChecker){
                this.copayClmHisList.forEach(item => {
                    let tgrDt = this.$moment(item.lvDt, 'YYYYMMDD').format('YYYYMM')

                    if(tgrDt != 'Invalid date'){
                        if(this.$moment(tgrDt, 'YYYYMM').isSameOrAfter(this.date))
                            item.isSelected = true
                        else
                            item.isSelected = false
                    }
                    else
                        item.isSelected = true
                    // if(this.$moment(tgrDt, 'YYYYMM').isSameOrAfter(this.date))
                    //     console.log(item.lvDt)
                })
            }
            else {
                this.copayClmHisList.forEach(item => {
                    item.isSelected = false
                })
            }

            let pkList = []
            let bnfcrList = []
            this.copayClmHisList.forEach(items => {
                if(items.isSelected){
                    pkList.push(items.bnMmbrPK)
                    bnfcrList.push(items.bnfcrPk)
                }
            });

            this.selectedList = pkList
            this.sltBnfcrList = bnfcrList
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD1: function (sch) {
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD2: function (sch) {
            if(this.exBtn[1].expandList.length == sch.length)
                sch.push(null)
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2.splice(0)
            sch.forEach((item) => {
                switch (item) {
                    case '등급외':
                        this.schChkField2.push('99')
                        break;
                    default:
                        this.schChkField2.push( item.replaceAll('등급', '') )
                        break;
                }
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            

            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((rtng, i) => {
                    if(i == 0) col2 = filter.acknwRtng?.includes(rtng)
                    else col2 = col2 || filter.acknwRtng?.includes(rtng)
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            return col0 && col1 && col2;
        },
        onClickBtn: function (text) {
            if(text == '청구서 생성')
                this.setCopayClmCrt(this.rmTgtList(), this.date)
            else if(text == '퇴소정산')
                this.setLvCalcDps(this.lvTgtList(), this.date)
            else if(text == '청구 확정')
                this.setCopayClmHisCnfrm(this.cfmTgtList('1'), '1', this.date)
            else if(text == '청구 확정취소')
                this.setCnclCopayClmHisCnfrm(this.cfmTgtList('2'), this.date)
            else if(text == '청구 삭제')
                this.removeCopayClmHis(this.cfmTgtList('1'), this.date)
            else if(text == '청구서 발송')
                this.sendMsgAct()
        },
        // 급여 청구, 취소 대상 선별 함수 ( 1.확정, 2.취소 )
        cfmTgtList: function (mode) {
            let obj = {}
            obj.mmbrPk = []
            obj.bnfcrPk = []

            this.sltBnfcrList.forEach(itm => {
                let chc = this.copayClmHisList.findIndex( v => v.bnfcrPk == itm )
                if(chc > -1){
                    if(mode == '1'){
                        if(this.copayClmHisList[chc].cnfrmYn != '1' && this.copayClmHisList[chc].dpsYn != '1'){
                            obj.mmbrPk.push(this.copayClmHisList[chc].bnMmbrPK)
                            obj.bnfcrPk.push(itm)
                        }
                    }
                    else if(mode == '2'){
                        if(this.copayClmHisList[chc].cnfrmYn == '1' && this.copayClmHisList[chc].dpsYn != '1'){
                            obj.mmbrPk.push(this.copayClmHisList[chc].bnMmbrPK)
                            obj.bnfcrPk.push(itm)
                        }
                    }
                }
            });
            
            return obj
        },
        // 급여 청구 삭제 대상 선별 함수
        rmTgtList: function () {
            let obj = {}
            obj.mmbrPk = []
            obj.bnfcrPk = []

            this.sltBnfcrList.forEach(itm => {
                let chc = this.copayClmHisList.findIndex( v => v.bnfcrPk == itm )
                if(chc > -1){
                    if(this.copayClmHisList[chc].cnfrmYn == '2' && this.copayClmHisList[chc].dpsYn == '2'){
                            obj.mmbrPk.push(this.copayClmHisList[chc].bnMmbrPK)
                            obj.bnfcrPk.push(itm)
                        }
                }
            });

            return obj
        },
        // 퇴소일 기준 대상 선별 함수
        lvTgtList: function () {
            let obj = {}
            obj.mmbrPk = []
            obj.bnfcrPk = []

            this.sltBnfcrList.forEach(itm => {
                let chc = this.copayClmHisList.findIndex( v => v.bnfcrPk == itm && v.lvDt != '' )
                if(chc > -1){
                    let tgrDt = this.$moment(this.copayClmHisList[chc].lvDt, 'YYYYMMDD').format('YYYYMM')
                    if(this.$moment(tgrDt, 'YYYYMM').isSameOrAfter(this.date) && this.$moment(tgrDt, 'YYYYMM').isSame(this.date)){
                        obj.mmbrPk.push(this.copayClmHisList[chc].bnMmbrPK)
                        obj.bnfcrPk.push(itm)
                    }
                }
            });

            return obj
        },
        // 합계 계산
        calcTotAmt: function (arr) {
            let tgt = Object.keys(this.copayClmHisListSum)
            tgt.forEach(itm => {
                this.copayClmHisListSum[itm] = 0
            });

            arr.forEach(itm => {
                this.copayClmHisListSum.copay += itm.copay
                this.copayClmHisListSum.nhisPay += itm.nhisPay
                this.copayClmHisListSum.mealCost += itm.mealCost
                this.copayClmHisListSum.lqdFood += itm.lqdFood
                this.copayClmHisListSum.upBedFee += itm.upBedFee
                this.copayClmHisListSum.btyFee += itm.btyFee
                this.copayClmHisListSum.snackFee += itm.snackFee
                this.copayClmHisListSum.clncPhrmcFee += itm.clncPhrmcFee
                this.copayClmHisListSum.cntrcDctrFee += itm.cntrcDctrFee
                this.copayClmHisListSum.msclnFee += itm.msclnFee    
                this.copayClmHisListSum.copayAmt += itm.copayAmt
                this.copayClmHisListSum.unpdAmt += itm.unpdAmt
                this.copayClmHisListSum.prpymApl += itm.prpymApl
                this.copayClmHisListSum.clmTot += itm.clmTot
            });
        },
        btnGrpChnger: function (bool) {
            this.actBtn[0].disabled = bool
            this.actBtn[2].disabled = bool
            this.actBtn[3].disabled = bool
            this.actBtn[4].disabled = bool

            this.outlinedBtn[0].disabled = bool
        },
        lvStlmChnger: function (bool) {
            if(!bool){
                let rst = this.lvTgtList()

                if(rst.mmbrPk.length == 1)
                    this.actBtn[1].disabled = bool
            }
            else
                this.actBtn[1].disabled = bool
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.$refs.exBtnClose0.isClose()
                    break;
                case 1: this.$refs.exBtnClose1.isClose()
                    break;
                default:
                    this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    break;
            }
        },
        prtCstStt: function (){
            this.prtThrowObj.bnMmbrPks.splice(0)
            this.prtThrowObj.bnfcrPks.splice(0)
            this.prtThrowObj.bnMmbrPks = [...new Set(this.selectedList)]
            this.prtThrowObj.bnfcrPks = [...new Set(this.sltBnfcrList)]

            this.prtThrowObj.inqYYMM = this.date
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            if(this.prtThrowObj.bnfcrPks.length > 0){
                this.$refs.reportMgmt.prtMltDataCall(this.prtThrowObj)
                this.menu.splice(2, 1, true)
            }
            else
                this.snackControll([true, 3000, '선택 항목 중 청구 확정된 청구서가 없습니다.', 'warning'])
        },

        sendMsgAct: function (){        
            this.copayBillPk = 1               
            this.menu.splice(3, 1, true)
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        allChecker: false,
        bnmmbr: -1,
        bnfcrPk: -1,
        selectedItem: -1,
        selectedList: [],
        sltBnfcrList: [],
        cpyClmHisLoader: true,
        copayClmHisList: [],
        copayClmHisListSum: {
            copay: 0, nhisPay: 0, mealCost: 0, lqdFood: 0, upBedFee: 0, btyFee: 0, snackFee: 0, clncPhrmcFee: 0,
            cntrcDctrFee: 0, msclnFee: 0, copayAmt: 0, unpdAmt: 0, prpymApl: 0, clmTot: 0,
        },
        dummyCopayClmHisListSum: {
            copay: 0, nhisPay: 0, mealCost: 0, lqdFood: 0, upBedFee: 0, btyFee: 0, snackFee: 0, clncPhrmcFee: 0,
            cntrcDctrFee: 0, msclnFee: 0, copayAmt: 0, unpdAmt: 0, prpymApl: 0, clmTot: 0,
        },
        menu: [false, false, false, false],
        dialog: [],
        schField: '',
        schDummy: '',
        schChkField1: [],
        schChkField2: [],
        vCd62: [],
        vCd63: [],
        vCd65: [],
        date: '',
        exBtn: [ 
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 194, expandList: [], },
        ],
        headers: [
            { text: '수급자명', value: 'name', align: 'center'},
            { text: '현황', value: 'name', align: 'center'},
            { text: '등급', value: 'acknwRtng', align: 'center'},
            { text: '본인부담률', value: 'copayRate', align: 'center'},
            { text: '일수', value: 'ofrDys', align: 'center'},
            { text: '공단부담금', value: 'nhisPay', align: 'center'},
            { text: '본인부담금', value: 'copay', align: 'center'},
            { text: '식사재료비', value: 'mealCost', align: 'center'},
            { text: '경관유동식', value: 'lqdFood', align: 'center'},
            { text: '상급침실비', value: 'upBedFee', align: 'center'},
            { text: '이미용비', value: 'btyFee', align: 'center'},
            { text: '간식비', value: 'snackFee', align: 'center'},
            { text: '진료약제비', value: 'clncPhrmcFee', align: 'center'},
            { text: '진찰비', value: 'cntrcDctrFee', align: 'center'},
            { text: '기타비용', value: 'msclnFee', align: 'center'},
            { text: '본인부담총액', value: 'copayAmt', align: 'center'},
            { text: '미납금액', value: 'unpdAmt', align: 'center'},
            { text: '선납금액', value: 'prpymAmt', align: 'center'},
            { text: '총청구금액', value: 'totClmAmt', align: 'center'},
        ],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '기타비용 입력', type: 'dialogue', width:'1310px', disabled: false, },
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '청구서 발송', type: 'action', width:'800px', disabled: false, },
        ],
        actBtn: [
            { icon: 'mdi-plus-circle-multiple-outline', class: 'white--text', color:'blueBtnColor', text: '청구서 생성', tip: '본인부담금 청구서 생성', disabled: true, },
            { icon: 'mdi-calculator-variant-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '퇴소정산', tip: '퇴소 정산', disabled: true, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '청구 확정', tip: '급여청구 확정', disabled: true, },
            { icon: 'mdi-undo-variant', class: 'ml-1 white--text', color:'grey006', text: '청구 확정취소', tip: '급여 청구 확정취소', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '청구 삭제', tip: '급여 청구 삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', disabled: true, },
        ],
        prtThrowObj: { fcltyNum: 0, bnMmbrPks: [], bnfcrPks: [], prtClcd: '1', inqClcd: 2, prtCd: '8', list: [ { bzClcd: [], bzPk: [] } ], },        
        snackOptions: [false, 3000, '', 'info'],
        copayBillPk:-1,
    }),
};
</script>

<style>
    .clmsBodyCommon{ border-bottom: 1px solid #dbdbdb !important; text-align: center !important;
        font-size: 1rem !important; padding: 0 6px !important; }
    .clmsHeaderCommon{ border-bottom: 1px solid #dbdbdb !important; border-collapse: separate !important;
        background-color: #ededed !important; text-align: center !important; font-size: 1.1rem !important; padding: 0 11px !important; }
    .clmsHeaderCheck .v-input__control { width: 22px !important; }
    .clmsHeaderCheck div div div i { color: #666 !important; }
    .v-data-table--fixed-header.v-data-table--dense > .v-data-table__wrapper > table > thead > tr:nth-child(3) > th {top:64px;}
    .copayClmsTbl .v-data-table__wrapper { width: 1842px; overflow-x: initial !important; overflow-y: initial !important; }
    .copayClmsTbl .v-data-table__wrapper table thead tr th { z-index: 2; }
    .clmsFixTbl {
        position: sticky !important; position: -webkit-sticky !important;
    }
</style>