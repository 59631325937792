<template>
    <v-sheet class="mr-2" rounded="md">
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">근무&nbsp;현황</span>
            </v-col>
        </v-row>
        <v-divider :class="['mt-3', empAtndSumList.length > 3 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', empAtndSumList.length > 3 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text font-weight-medium">근무유형</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                <span class="black--text font-weight-medium">근무상세</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium">실근무시간</span>
            </v-col>
        </v-row>
        <v-divider :class="[empAtndSumList.length > 3 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" v-if="empAtndSumList.length > 0" style="width: 100%; height: 93.33px;">
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;"
                v-for="(list, w) in empAtndSumList" :key="w" no-gutters>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                    <span class="black--text">{{ list.wrkTypNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="4">
                    <span class="black--text">{{ list.vctnTypNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="4">
                    <span class="black--text">{{ list.actWrkHr }}</span>
                </v-col>
            </v-row>
        </div>
        <div class="d-inline-block d-flex justify-center align-center" v-else style="width: 100%; height: 96px;">
            조회된 내용이 없습니다.
        </div>
        <v-divider :class="[empAtndSumList.length > 3 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', empAtndSumList.length > 3 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="8">
                <span class="black--text font-weight-medium">합계</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="4">
                <span class="black--text font-weight-medium" v-if="actWrkHrTotal == 0"></span>
                <span class="black--text font-weight-medium" v-else>
                    {{ Math.floor(actWrkHrTotal / 60) + '시간 ' + (actWrkHrTotal % 60) + '분' }}
                </span>
            </v-col>
        </v-row>
        <v-divider :class="[empAtndSumList.length > 3 ? 'mr-2__5' : '']"></v-divider>
    </v-sheet>
</template>

<script>
import { selEmpAtndSumList } from '../../api/emp.js';

export default {
    name: 'EmpWorkInfo',

    props : {
       prtEmpPk: { type: Number, default: -1 },
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        
        })
    },

    computed: {
        
    },

    watch:{
        'prtEmpPk': function () {
            if(this.prtEmpPk == -1){
                this.empAtndSumList.splice(0)
            }
            else {
                this.getEmpAtndSumList(this.prtEmpPk, this.$parent.$parent.$parent.$parent.date)
            }
        },
    },
    
    methods: {
        getEmpAtndSumList: function (pk, yymm) {
            selEmpAtndSumList(this.$store.getters.getFcltyNum, pk, yymm)
                .then((response) => ( this.getEmpAtndSumListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAtndSumList : ' + error))
        },
        getEmpAtndSumListAfter: function (res) {
            this.actWrkHrTotal = 0
            res.forEach(wkHr => {
                this.actWrkHrTotal += wkHr.actWrkHr
                wkHr.actWrkHr = Math.floor(wkHr.actWrkHr / 60) + '시간 ' + (wkHr.actWrkHr % 60) + '분'
            });
            this.empAtndSumList = res
        },
    },

    data: () => ({
        actWrkHrTotal: 0,
        empAtndSumList: [],
    }),
};
</script>

<style>
    .mr-2__5 { margin-right: 10px !important; }
    .pr-2__5 { padding-right: 10px !important; }
    .pr-2__5_1 { padding-right: 11px !important; }
</style>