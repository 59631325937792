<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-center align-center">
                <span class="text-h4 font-weight-bold">게시글 작성</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <div class="d-flex pl-1 pr-1 mt-5">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" :class="list.class" :disabled="list.disabled" samll height="30" :color="list.color"
                        @click="onClickBtn(list.text)" rounded>
                        <v-icon small>{{ list.icon }}</v-icon>
                        <span style="font-size: 1rem;">{{ list.text }}</span>
                    </v-btn>
                </span>
            </div>
            <v-divider class="mt-3"></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">제목</span>
                </v-col>
                <v-col class="py-1 px-2" cols="10">
                    <span>
                        <v-text-field color="blue" ref="info" :rules="[rules.required]"
                            hide-details outlined dense :key="forceRender" v-model="items.title"></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">알림</span>
                </v-col>
                <v-col class="d-flex align-center py-1 px-2" cols="10">
                    <v-checkbox v-model="items.almYn" class="authCheck" true-value="1" false-value="2"
                        label="노출 여부" hide-details>
                    </v-checkbox>
                    <span class="white d-inline-block py-1" style="width: 154px;">
                        <CmmDateComp v-model="items.almDtStrt" :maxDt="items.almDtEnd" :required="true"></CmmDateComp>
                    </span>
                    <span class="d-flex align-center ml-4 mr-2">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                        <CmmDateComp v-model="items.almDtEnd" :minDt="items.almDtStrt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center ma-0" cols="2">
                    <div class="d-flex align-center ml-4" style="width:95px">
                        <span class="black--text font-weight-medium">자료첨부</span>
                        <v-file-input 
                            v-model="files" 
                            dense hide-details truncate-length="5" 
                            multiple counter
                            hide-input 
                            outlined 
                            @change="upFile()"
                            ></v-file-input>
                    </div>
                </v-col>
                <v-col class="px-1" cols="10">
                    <span>
                        <v-chip v-for="(item, j) in fileRes" :key="j"                        
                        label                        
                        close
                        class="ma-1"
                        outlined
                        @click="fileDownload(j)"
                        @click:close="delfiles(j)"
                    >
                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                            mdi-microsoft-excel
                        </v-icon>
                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                            mdi-file-powerpoint
                        </v-icon>
                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                            mdi-file-word
                        </v-icon>
                        <v-icon v-else color='cyan' left>
                            mdi-file-document
                        </v-icon>
                        {{ item.orgFileNm }}
                    </v-chip> 
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <div style="text-align: center;">
                        <span class="black--text font-weight-medium">내용</span>
                    </div>
                </v-col>
                <v-col class="px-2 py-1" cols="10">
                    <v-textarea rows="16" class="rmrksArea" no-resize  outlined dense hide-details v-model="items.cntnt" :rules="[rules.required]">
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="title" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { tmpFileUpload, selAtchdFileList, fileDownload, delAtchdFile } from '../../api/index.js';
import { getStcd } from '../../api/index.js';
import { selNtsBbsInfo, insNtsBbs, delNtsBbs } from '../../api/othr';
import { remoteURL } from '@/api/baseAxios';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
export default {
    name: 'insNtsBbs',

    components: {
        btnModalDelete,
        CmmDateComp,

    },
    props: {
    },
    created: function () {
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        selNtsBbsInfo: function (pk) {
            this.ntcBbsPk = pk
            selNtsBbsInfo(this.$store.getters.getFcltyNum, pk)
                .then((response) => (this.selNtsBbsInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selNtsBbsInfo : ' + error))
        },
        selNtsBbsInfoAfter: function (res) {
            if(typeof res == 'object'){
                if(res.almDtStrt == null || res.almDtStrt == '')
                    res.almDtStrt = ''
                else
                    res.almDtStrt = res.almDtStrt?.beforeDateFormatHyp()

                if(res.almDtEnd == null || res.almDtEnd == '')
                    res.almDtEnd = ''
                else
                    res.almDtEnd = res.almDtEnd?.beforeDateFormatHyp()

                this.items = res
                this.getAtchdFileList(res.ntcBbsPk)
            }
        },
        onClose: function () {
            this.ntcBbsPk = 0
            this.$emit('isClose', '.')

            // this.resetPage()

        },
        onCodeload() {
            getStcd('40')
                .then((response) => (this.codeMapping(response.data)))
                .catch((error) => console.log(error))
        },
        codeMapping: function (res) {
            this.cdItems = res

        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            if (this.value == 0) {
                this.items.wrtr = obj[0].name
            } else if (this.value == 1) {
                this.items.rflWrtr = obj[0].name
            }
        },
        onModal: function (value) {
            this.value = value
            if (value == 0) {
                this.dialog.splice(0, 1, true)
            } else if (value == 1) {
                this.dialog.splice(1, 1, true)
            }
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        resetPage: function () {
            this.items = Object.assign({}, { cntnt: '', crtDt: '', crtr: '', fcltyNum: '', ntcBbsPk: 0, ntcYn: null, rn: null, title: '', })
            this.fileRes = []
            this.idxCd = '1'
            this.$refs.mdlform.resetValidation()
        },
        onClickBtn: function (text) {
            if (text == '저장') {
                this.insNtsBbs()
            }
            else if (text == '삭제') {
                this.onDelete()
            }
        },
        insNtsBbs: function () {
            // console.log(this.items.rflDt)
            if (this.$refs.mdlform.validate()) {
                let obj = Object.assign({}, this.items)
                obj.userNm = this.$store.getters.getUserNm
                obj.fcltyNum = this.$store.getters.getFcltyNum

                if(obj.almDtStrt == null || obj.almDtStrt == '')
                    obj.almDtStrt = ''
                else
                    obj.almDtStrt = obj.almDtStrt?.afterDateFormatHyp()
                if(obj.almDtEnd == null || obj.almDtEnd == '')
                    obj.almDtEnd = ''
                else
                    obj.almDtEnd = obj.almDtEnd?.afterDateFormatHyp()

                obj.files = []
                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        obj.files.push(x)
                    }
                })
  
                insNtsBbs(obj)
                    .then((response) => (this.insNtsBbsAfter(response.data)))
                    .catch((error) => console.log('connect error ntsBbs/insNtsBbs : ' + error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        insNtsBbsAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit("reload", true)
                this.selNtsBbsInfo(this.ntcBbsPk)
                this.getAtchdFileList(res.result)
                this.items.ntcBbsPk = res.result
                this.$refs.mdlform.reset()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        onDelete() {
            this.dialogDelete = true
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if(this.title == '게시글'){
                    if (this.ntcBbsPk != undefined) {
                        let obj = {
                            ntcBbsPk: this.ntcBbsPk,
                            userNm: this.$store.getters.getUserNm,
                        }

                        delNtsBbs(obj)
                            .then((response) => (this.delNtsBbsAfter(response.data)))
                            .catch((error) => console.log(error))
                    } 
                }
                else if(this.title == '파일'){
                    this.delAtchdFile(9990)
                    this.title = '게시글'
                }
            } else if (res == 7) {
                this.dialogDelete = false
                this.title = '게시글'
            }
        },
        delNtsBbsAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onClose()
                this.$emit('snackControll', res)
                this.dialogDelete = false
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        upImgFile: function () {     
            let formData = new FormData();
            
            if(this.images != null){
                this.images.forEach(element => {
                    formData.append("files", element)
                })

                if(this.images.length > 0){
                    tmpFileUpload(formData)
                        .then((response) => ( this.upImgFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }

        },
        upImgFileAfter:function(res){
            res.files.forEach(x=>{
                x.src = remoteURL + 'opr/imgPrview?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                this.imageRes.push(x)
            })       

            this.images = null
        },
        upFile: function () {            
            let formData = new FormData();
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => ( this.upFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }            
        },
        upFileAfter:function(res){
            res.files.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
            })
            this.files = null
        },
        getAtchdFileList:function(value){
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '24'
            if(value > 0){
                selAtchdFileList(fcltyNum, clcd,value)
                        .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            }else{
                this.files = null
                this.images = null
                this.fileRes.splice(0)
                this.imageRes.splice(0)
            }
        },
        getAtchdFileListAfter:function(res){
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            res.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]  
                this.fileRes.push(x)
            })
        },
        delAtchdFile:function(res){
            if(res == 9990){
                let pk = 0
                if(this.key == 1){
                    pk = this.fileRes[this.idx].atchdFilePk
                }else{
                    pk = this.imageRes[this.idx].atchdFilePk
                }

                if(pk > 0){
                    let fcltyNum  = this.$store.getters.getFcltyNum  
                    delAtchdFile(fcltyNum, pk)
                        .then((response) => ( this.delAtchdFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        delAtchdFileAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.selNtsBbsInfo(this.ntcBbsPk)
                //this.onShow(this.$parent.$parent.$parent.$parent.mtngRcrdPk)
                this.key = 0
                this.idx = 0
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        fileDownload:function(idx){
            if(this.fileRes[idx] != undefined){
                fileDownload(this.fileRes[idx])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        deleteImg(idx){
            if(this.imageRes[idx].atchdFilePk != undefined){
                this.key = 2
                this.idx = idx
                this.title = '사진'
                this.dialogDelete = true
            }else{
                this.imageRes.splice(idx,1)
            }
        },
        delfiles:function(idx){
            if(this.fileRes[idx].atchdFilePk != undefined){
                this.key = 1
                this.idx = idx
                this.title = '파일'
                this.dialogDelete = true
            }else{
                this.fileRes.splice(idx,1)
            }
        },   
        
    },
    data: () => ({
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', disabled: false},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color: 'grey006', text: '삭제', type: 'action' },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        qtr: [{ valcd: 0, valcdnm: '선택' }, { valcd: 1, valcdnm: '1분기' }, { valcd: 2, valcdnm: '2분기' }, { valcd: 3, valcdnm: '3분기' }, { valcd: 4, valcdnm: '4분기' }],
        dialog: [false, false],
        menu: [false, false, false, false],
        items: { title: '', cntnt: '', ntcBbsPk: 0, ntcYn: '', almYn: '2', almDtStrt: '', almDtEnd: '' },
        cdItems: [],
        snackOptions: [false, 3000, '', 'info'],
        PrgOpRec: [],
        prgCode: [],
        index: [],
        forceRender: 0,
        value: 0,
        idxCd: '1',
        ntcBbsPk: 0,
        dialogDelete: false,
        files:null,
        fileRes:[],
        images:null, 
        imageRes:[],  
        imgAccept:'image/*',   
        key:0,
        idx:0,
        title:'게시글',

    }),
};
</script>