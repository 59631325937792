<template>
    <v-sheet class="ma-0 px-7" style="padding-top: 22px; padding-bottom: 20px;">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon size="30">mdi-help-circle-outline</v-icon>
                <span
                    class="ml-3 font-weight-bold grey006--text d-inline-block"
                    style="font-size: 1.4rem; height: 30px; margin-bottom: 3px;">
                    {{ helpInfo.hlpTtl }}
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <span class="d-inline-block" style="width: 4%; height: 30px;"></span>
                <span class="d-inline-block font-weight-medium grey--text darken-2" style="width: 96%; font-size: 1.2rem;">
                    <div v-for="(content, i) in helpInfo.hlpCntntArr" :key="i" class="d-flex mb-1">
                        <span class="d-inline-block pb-1" style="width: 3%;">{{ i+1 }}.</span>
                        <span class="d-inline-block" style="width: 97%;" v-html="content"></span>
                    </div>
                </span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selHlpInfmInfo } from '../../api/othr.js'

export default {
    name: 'DscrpBomMdl',

    props : {
        decrpCode: { type: Number, default: 0 },
    },
            
    components: {

    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getESndMsgList(this.decrpCode)
        })
    },

    computed: {

    },

    watch:{
        'decrpCode': function () {
            this.getESndMsgList(this.decrpCode)
        },
    },
        
    methods: {
        getESndMsgList: function (code) {
            selHlpInfmInfo(code)
                .then((response) => ( this.getESndMsgListAfter(response.data) ))
                .catch((error) => console.log('connect error othr/selHlpInfmInfo : ' + error))
        },
        getESndMsgListAfter: function (res) {
            this.helpInfo = res
        },
    },
    
    data: () => ({
        helpInfo: {},
        descrpCntnt: [
            {},
            {
                code: 1, ttl: '낙상위험방지 확인 사항',
                cntnt: [
                    '불가피하게 신체를 제한하거나 구속한 모든 경우에 대해 (침상난간 올리기, 휠체어 안전벨트 , 휠체어 식판 등 포함) 그 내용을 자세히 기록하고 <span class="red--text">가족 등에게 통지했는지 확인합니다.</span> 이 때 확인하는 "내용"은 경과기록이 아닌 제재에 대한 내용을 의미합니다.',
                    '침상난간, 휠체어 안전벨트, 휠체어 식판의 항목은 낙상위험을 보완할 수 이는 제재로 <span class="red--text">입소 시 침대 및 휠체어 이용 예정임에 따라</span> 제재 시작으로 보고 제재기록 및 통지를 수행할 경우 인정됩니다. (타 억제대는 사유가 실제로 발생했을 때에 제재 및 통지를 했는지 확인)',
                ],
            },
        ],
    }),
};
</script>