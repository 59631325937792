<template>
    <v-sheet
        class="rounded-lg rounded-l-0 overflow-auto pa-2"
        :min-width="areaWidth+80" :max-width="areaWidth+80">
        <div style="position:relative;">
            <v-snackbar
                class="pa-0" min-width="200" max-width="500"
                v-model="snackOptions[0]"
                :timeout="snackOptions[1]" :value="true" :color="snackOptions[3]"
                rounded="pill" transition="slide-y-transition"
                absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="py-1 mr-2" no-gutters>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
                <v-dialog v-if="list.type == 'dialogue' && list.visible"
                    v-model="dialog[i]" :max-width="list.width" persistent>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <CopayDpst v-if="list.text == '입금처리'" ref="copayDpst" @isClose="dialog.splice(i, 1, false)"></CopayDpst>
                        <CopayDpstCashBill v-else-if="list.text == '현금영수증처리' && dialog[i] == true" @isClose="dialog.splice(i, 1, false)"></CopayDpstCashBill>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span>
                <v-btn
                    class="font-weight-bold ml-2" height="30" color="grey006"
                    @click="pymCnfActCall()" :disabled="bnMmbrPk == -1 || bnMmbrPk == 0"
                    rounded outlined samll>
                    <v-icon small>mdi-printer-outline</v-icon>
                    <span class="fontOneRem ml-1">납부확인서 출력</span>
                </v-btn>
            </span>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <div>
            <basicInfo :bnMmbrPk="bnMmbrPk" ref="basicInfo"></basicInfo>
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="d-inline-block" style="width: 100%; height: 127px;">
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="1">
                    <div style="line-height: 1.1;">
                        <div class="txtAlgnMid">
                            <span class="black--text font-weight-medium">잔여</span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="black--text font-weight-medium">선납금</span>
                        </div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="3">
                    <span class="black--text">{{copayDpsStsInfo.rmnPrpym?.toLocaleString('ko-KR')}}</span>
                    <span>
                        <v-btn class="ml-2" width="30" min-width="30" height="26" @click="menu.splice(1, 1, true)"
                            :disabled="!(copayDpsStsInfo.rmnPrpym > 0)" small text outlined>
                            <v-icon small>mdi-cash-refund</v-icon>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="1">
                    <span class="black--text font-weight-medium">미납금</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <span class="black--text">{{copayDpsStsInfo.unpdAmt?.toLocaleString('ko-KR')}}</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="1">
                    <span class="black--text font-weight-medium">보호자</span>
                </v-col>
                <v-col
                    class="d-flex justify-center align-center py-1" style="line-height: 1.2;" cols="4">
                    <div>
                        <div v-for="(grdnm, i) in copayDpsStsInfo.grdName" :key="i">{{ grdnm }}</div>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </div>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-col class="d-flex align-center pr-2" cols="7">
                <v-icon style="font-size: 28px; width: 12px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-2">본인부담금&nbsp;청구내역</span>
            </v-col>
            <v-col class="d-flex align-center" cols="5">
                <v-icon style="font-size: 28px; width: 12px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-2">본인부담금&nbsp;입금내역</span>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col class="pr-2" cols="7">
                <v-divider></v-divider>
                <v-data-table
                    class="msclnTable" height="531" locale="ko"
                    :items="copayDpsList" :headers="copayDpsHeaders" :loading="lodnArr[0]"
                    fixed-header hide-default-header disable-pagination hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[0].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[4].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon px-1">
                                    <span class="black--text">{{ props.headers[5].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="clmsBodyCommon tableBR px-0">
                                <span class="">{{ $moment(item.clmYymm, 'YYYYMM').format('YYYY.MM') }}</span>
                            </td>
                            <td class="clmsBodyCommon tableBR px-0">{{ item.clmAmt?.toLocaleString('ko-KR') }}</td>
                            <td class="clmsBodyCommon tableBR px-0">{{ item.dpsAmt?.toLocaleString('ko-KR') }}</td>
                            <td class="clmsBodyCommon tableBR px-0">{{ item.unpdAmt?.toLocaleString('ko-KR') }}</td>
                            <td class="clmsBodyCommon tableBR px-0">
                                <span class="black--text">
                                    <v-btn
                                        class="" width="24" height="20"
                                        @click="sttmnActCall(item)"
                                        icon>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-text-box-outline</v-icon>
                                            </template>
                                            <span>명세서</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>
                                <span class="black--text">
                                    <v-btn
                                        class="" width="24" height="20"
                                        @click="rcptActCall(item)"
                                        icon>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-receipt-text-outline</v-icon>
                                            </template>
                                            <span>영수증</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>
                            </td>
                            <td class="clmsBodyCommon px-0">
                                <span class="black--text">
                                    <v-btn
                                        class="" width="24" height="20"
                                        :disabled="true"
                                        @click="sendMsgAct(item.copayDpsHisPk, item.clmYymm)"
                                        icon>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand-right</v-icon>
                                            </template>
                                            <span>발송은 준비중입니다...</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <v-col class="" cols="5">
                <v-divider></v-divider>
                <v-data-table
                    class="msclnTable" height="531" locale="ko"
                    :items="copayPrcList" :headers="copayPrcHeaders" :loading="lodnArr[1]"
                    fixed-header hide-default-header disable-pagination hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[0].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon tableBR px-1">
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>
                                <th class="clmsHeaderCommon px-1">
                                    <span class="black--text">{{ props.headers[4].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="clmsBodyCommon tableBR px-0">
                                <span class="">{{ $moment(item.dpsDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </td>
                            <td class="clmsBodyCommon tableBR px-0">{{ item.dpsTot?.toLocaleString('ko-KR') }}</td>
                            <td class="clmsBodyCommon tableBR px-0" style="width: 70px; line-height: 14px;">
                                <span
                                    class="d-inline-block pl-1"
                                    style="width: 65px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ item.dpsNm }}</span>
                                        </template>
                                        <div>
                                            <span class="mr-1">{{ item.dpsNm }}</span>
                                            <span v-if="item.dpsClcd == '1'">&#40;&nbsp;입금&nbsp;&#41;</span>
                                            <span v-else-if="item.dpsClcd == '3'">&#40;&nbsp;환불&nbsp;&#41;</span>
                                        </div>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td class="clmsBodyCommon tableBR px-0" style="width: 70px;">{{ item.dpsMthdNm }}</td>
                            <td class="clmsBodyCommon px-0">
                                <v-btn
                                    class="" color="blueBtnColor" style="padding:0px 1px 0px 2px !important"
                                    min-width="24" height="24" @click="menu.splice(4, 1, true), sltDpsPk=item.dpsPrcHisPk"
                                    small icon>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-square-edit-outline</v-icon>
                                        </template>
                                        <span>내역수정</span>
                                    </v-tooltip>
                                </v-btn>
                                <v-btn
                                    v-if="item.ableBtn"
                                    class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                    min-width="24" height="24"
                                    @click="onCncl(item.dpsPrcHisPk, item.cashBillHisPk, item.prpymRfndAmt, item.dpsClcd)"
                                    small icon>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-redo-variant</v-icon>
                                        </template>
                                        <span>입금취소</span>
                                    </v-tooltip>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        
        <v-dialog v-model="menu[0]" content-class="round" max-width="800" persistent eager>
            <CopayDpstCncl ref="copayDpstCnclDetail" @isClose="menu.splice(0, 1, false)"></CopayDpstCncl>
        </v-dialog>
        <v-dialog v-model="menu[1]" content-class="round" max-width="800" persistent eager>
            <PrpymPymntRefund
                ref="prpymPymntRefundDetail"
                :prtCopayDpsStsInfo="copayDpsStsInfo"
                @isClose="menu.splice(1, 1, false)">
            </PrpymPymntRefund>
        </v-dialog>
        <v-dialog v-model="menu[2]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(2, 1, false)"></ReportMgmt>
        </v-dialog>
        <v-dialog v-model="menu[3]" max-width="1100" persistent>
            <bizMsgMdl 
                ref="bizMsgMdl"
                :bizMsgData="bizMsgInfo.mmbrPk"
                :bizMsgDocClcd="bizMsgInfo.docClcd"
                :bizMsgBzPk="bizMsgInfo.bzPk"
                :bizMsgShow="menu[3]"
                :bizMsgTitle="bizMsgInfo.bzTitle"
                :bizMsgCntnt="bizMsgInfo.bzCntnt"
                @madalState="menu.splice(3, 1, false)">
            </bizMsgMdl>
        </v-dialog>
        <v-dialog v-model="menu[4]" content-class="round" max-width="800" persistent>
            <CopayDpstMdf :dpsPk="sltDpsPk" @isClose="menu.splice(4, 1, false)"></CopayDpstMdf>
        </v-dialog>
        <v-dialog v-model="menu[5]" content-class="round" max-width="700" persistent>
            <CmmCnfmMdl
                :compTtl="cfmInf.ttl" :compCntnt="cfmInf.cntnt"
                @isClose="menu.splice(5, 1, false)" @isSave="cfmCnclAct()">
            </CmmCnfmMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import CopayDpst from '../../components/bnfcr/CopayDpst.vue';
import CopayDpstCashBill from '../../components/bnfcr/CopayDpstCashBill.vue';
import CopayDpstCncl from '../../components/bnfcr/CopayDpstCncl.vue';
import CopayDpstMdf from '../../components/bnfcr/CopayDpstMdf.vue';
import PrpymPymntRefund from '../../components/bnfcr/PrpymPymntRefund.vue';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';
import CmmCnfmMdl from '../../components/commons/CmmCnfmMdl.vue';

import { selCopayDpsPrcList } from '../../api/bnfcr.js';

export default {
    name: 'CopayDpstMgmt',
    
    components: {
        basicInfo,
        CopayDpst,
        CopayDpstCashBill,
        CopayDpstCncl,
        CopayDpstMdf,
        PrpymPymntRefund,
        ReportMgmt,
        bizMsgMdl,
        CmmCnfmMdl,
    },

    props : {

    },

    created: function(){
        let dialogCnt = this.filledBtn.length + this.outlinedBtn.length
        for (let index = 0; index < dialogCnt; index++) {
            this.dialog.push(false)         
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getBnMmbr(){
            return this.$parent.$parent.selectedItem
        },
    },

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnMmbr: function (value){
            this.bnMmbrPk = value

            if(value == -1){
                this.filledBtn[0].disabled = true
                // this.filledBtn[2].disabled = true
                this.outlinedBtn[0].disabled = true
            }
            else {
                this.filledBtn[0].disabled = false
                // this.filledBtn[2].disabled = false
                this.outlinedBtn[0].disabled = false
            }
        },
    },

    methods: {
        getCopayDpsList: function (){
            this.lodnArr.splice(0, 1, true)
            this.lodnArr.splice(1, 1, true)
            this.copayDpsList.splice(0)
            this.copayPrcList.splice(0)

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$parent.$parent.sltBnfcrPk
            obj.inqYYYY = this.$parent.$parent.year

            selCopayDpsPrcList(obj)
                .then((response) => ( this.getCopayDpsListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayDpsPrcList : ' + error))
        },
        getCopayDpsListAfter: function (res) {
            this.lodnArr.splice(0, 1, false)
            this.lodnArr.splice(1, 1, false)

            if(res.list?.length > 0)
                this.copayDpsList = res.list

            if(res.prcList?.length > 0){
                let pkS = []
                let maxPk = 0
                let idx = -1
                res.prcList.forEach(prc => {
                    let chc = pkS.findIndex( v => v == prc.dpsPrcHisPk )
                    if(chc == -1)
                        pkS.push( prc.dpsPrcHisPk )
                    
                    prc.ableBtn = false
                });

                maxPk = Math.max(...new Set(pkS))

                idx = res.prcList.findIndex( v => v.dpsPrcHisPk == maxPk )
                if(idx > -1)
                    res.prcList[idx].ableBtn = true

                this.copayPrcList = res.prcList
            }

            if(typeof this.$refs.copayDpst == 'object')
                this.$refs.copayDpst[0].getCopayClmUnpdList()
            
        },
        pymCnfActCall: function (){
            this.prtThrowObj.prtCd = '58'
            this.prtThrowObj.inqYYYY = this.$parent.$parent.year
            this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.bnfcrPk = this.$parent.$parent.sltBnfcrPk

            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.menu.splice(2, 1, true)
        },
        rcptActCall: function (value){
            this.prtThrowObj.prtCd = '9'
            this.prtThrowObj.inqYYMM = value.clmYymm
            this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
            this.prtThrowObj.bnfcrPk = value.bnfcrPk
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.menu.splice(2, 1, true)
        },
        sttmnActCall: function (value){
            this.prtThrowObj.prtCd = '8'
            this.prtThrowObj.inqYYMM = value.clmYymm
            this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
            this.prtThrowObj.bnfcrPk = value.bnfcrPk
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.menu.splice(2, 1, true)
        },
        onCncl: function (prcPk, cashPk, rfndPk, dpsClcd) {
            this.menu.splice(0, 1, true)
            
            this.$refs.copayDpstCnclDetail.copayClmCnclInfo.dpsPrcHisPk = prcPk
            this.$refs.copayDpstCnclDetail.copayClmCnclInfo.cashBillHisPk = cashPk
            this.$refs.copayDpstCnclDetail.copayClmCnclInfo.prpymRfndAmt = rfndPk
            this.$refs.copayDpstCnclDetail.copayClmCnclInfo.dpsClcd = dpsClcd
            // if(cashPk == 0 && rfndPk == 0)
            //     this.$refs.copayDpstCnclDetail.saveBtn = false
        },
        sendMsgAct: function (pk, yymm){
            let mnt = yymm.afterDateFormatMonthDot()
            let sltIn = this.$parent.$parent.copayDpsStsList.findIndex( v => v.bnMmbrPk == this.$parent.$parent.selectedItem )

            this.bizMsgInfo.mmbrPk.splice(0)
            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = this.$parent.$parent.copayDpsStsList[sltIn].name
            this.bizMsgInfo.bzTitle = mnt.substring(0, 4) + '년 ' + mnt.substring(5, 6) + '월 ' + '본인부담금 영수증'
            this.bizMsgInfo.docClcd = 7
            this.bizMsgInfo.bzPk = pk

            this.menu.splice(3, 1, true)
        },
        cfmCnclAct: function () {
            this.menu.splice(5, 1, false)
            this.$refs.copayDpstCnclDetail.preCnclCopayDps()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        areaWidth: 0,
        activeBtn: 0,
        cfmInf: { ttl: '', cntnt: [] },
        lodnArr: [false, false],
        menu: [false, false, false, false, false, false],
        dialog: [],
        sltDpsPk: -1,
        bnMmbrPk: 0,
        copayDpsList: [],
        copayPrcList: [],
        copayDpsStsInfo: { rmnPrpym: 0, unpdAmt: 0, grdName: '' },
        prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, bnfcrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '', inqYYYY: '', inqYYMM: '', list: [ { bzClcd: [], bzPk: [] } ], },
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '입금처리', type: 'dialogue', width:'800px', visible: true, disabled: true, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '영수증발송', type: 'dialogue', width:'600px', visible: false, disabled: true, },
            { icon: 'mdi-receipt-text-arrow-right-outline', class: 'ml-1 white--text', color:'blue001', text: '현금영수증처리', type: 'dialogue', width:'1600px', visible: true, disabled: false, },
            // { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '미납안내발송', type: 'action', width:'1300px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '납부확인서 출력', width:'1000px', disabled: true, },
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '영수증 출력', width:'1000px', disabled: true, },
        ],
        copayDpsHeaders: [
            { text: '청구월', value: 'clmYymm', align: 'center'},
            { text: '청구금액', value: 'clmAmt', align: 'center'},
            { text: '청구입금액', value: 'dpsAmt', align: 'center'},
            { text: '미납액', value: 'unpdAmt', align: 'center'},
            { text: '출력', value: '', align: 'center'},
            { text: '처리', value: '', align: 'center'},
        ],
        copayPrcHeaders: [
            { text: '입금일', value: 'dpsDt', align: 'center'},
            { text: '입금액', value: 'dpsTot', align: 'center'},
            { text: '입금자', value: 'dpsNm', align: 'center'},
            { text: '입금방법', value: 'dpsMthdNm', align: 'center'},
            { text: '처리', value: '', align: 'center'},
        ],
        bizMsgInfo: {
            mmbrPk: [], docClcd: 0, bzPk: 0, bzTitle: '', bzCntnt: '',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>