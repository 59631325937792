<template>
    <v-sheet>
        <div>
            <expandMonthPicker class="py-3" @nowYearWithMonth="getPickerDate"></expandMonthPicker>
            <div class="d-flex greyE01 pa-0 mr-2__5">
                <div class="d-flex justify-center align-center tableBT tableBB pa-1" style="width:10%;" >
                    <span class="black--text font-weight-medium">수급자명</span>
                </div>
                <div class="d-flex justify-center align-center tableBT tableBB pa-1 tableBL" style="width:10%;" >
                    <span class="black--text font-weight-medium">성별&#40;나이&#41;</span>
                </div>
                <div class="tableBT tableBL" style="width:80%;">
                    <div class="d-flex">
                        <div class="" style="width:100%">
                            <div class="d-flex tableBB">
                                <div class="d-flex py-2 justify-center align-center" style="width:15%">
                                    <span class="black--text font-weight-medium">치료일</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:15%">
                                    <span class="black--text font-weight-medium">치료시간</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                    <span class="black--text font-weight-medium">구분</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:15%">
                                    <span class="black--text font-weight-medium">치료방법</span>
                                </div>
                                <div class="tableBL d-flex py-2 justify-center align-center" style="width:45%">
                                    <span class="black--text font-weight-medium">특이사항</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height:605px;overflow-y:scroll;" class="ma-0 pa-0">
                <div v-if="items.length > 0">
                    <div v-for="(item, i) in items"  :key="i">
                        <div class="d-flex pa-0 ma-0">
                            <div class="d-flex justify-center align-center tableBB pa-1" style="width:10%;" >
                                <span class="black--text font-weight-medium">{{item.name}}</span>
                            </div>
                            <div class="d-flex justify-center align-center tableBB pa-1 tableBL" style="width:10%;" >
                                <span class="d-flex black--text font-weight-medium">
                                    <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                                    <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                                    &#40;{{ item.age }}&#41;</span>
                            </div>
                            <div class="tableBL" style="width:80%;">
                                <div class="d-flex" v-for="(list,j) in item.list" :key="j" >
                                    <div class="d-flex tableBB" style="width:100%">
                                        <div class="d-flex py-2 justify-center align-center" style="width:15%">
                                            {{list.recDt?.beforeDateFormatHyp()}}
                                        </div>
                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:15%;font-size:1rem !important;">
                                            {{list.ofrTm}}
                                        </div>
                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:10%">
                                            {{list.valClNm}}
                                        </div>
                                        <div class="tableBL" style="width:15%;">
                                            <div class="d-flex justify-center align-center" v-for="(itmNmAr,j) in list.itmNmAr" :key="j">
                                                {{itmNmAr}}
                                            </div>
                                        </div>
                                        <div class="tableBL d-flex py-2 justify-center align-center" style="width:45%">
                                            {{list.itmVal3}}
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else style="height:400px;overflow-y:auto;">
                    <div class="d-flex pa-0 ma-0">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;height:380px;" >
                            <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import { selBtcInqPhyThrRecList, } from '../../api/bnftrcrd.js';
export default {
    name: 'PhyscRecTab1',
        
    components: {
        expandMonthPicker,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch:{ 
        'mDate': function (value) {
            let inqYYMM = value
            this.selBtcInqPhyThrRecList(inqYYMM)
        },
    },
        
    methods: {
        getPickerDate: function (obj) {
            this.mDate = obj
        },
        selBtcInqPhyThrRecList: function (inqYYMM){
            selBtcInqPhyThrRecList(this.$store.getters.getFcltyNum, inqYYMM)
                .then((response) => ( this.selBtcInqPhyThrRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllBnfcrListAfter : ' + error))
        },
        selBtcInqPhyThrRecListAfter: function (res) {
            this.items = res.outList
        },
        
    },
    
    data: () => ({
        mDate: 199001,
        items: [],
        midAreaWidth: '530',
        selectedItem: -1,
        bnfcrList: [],
        vCd62: [],
        vCd63: [],
        vCd65: [],
        schField: '',
        schDummy: '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng: [], target: '' },
        headers: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 tableHeaderSize bnfcrMidIndex fixedPstn0'},
            { text: '수급자', value: 'name', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 tableHeaderSize bnfcrMidIndex fixedPstn1'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize bnfcrMidIndex fixedPstn2'},
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize bnfcrMidIndex fixedPstn3'},
            { text: '현황', value: 'entStcd', sortable: true, width: '100', align: 'center', class: 'black--text greyE01 tableHeaderSize bnfcrMidIndex fixedPstn4'},
            { text: '등급', value: 'acknwRtng', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize bnfcrMidIndex fixedPstn5'},
            { text: '생년월일', value: 'rsdntBirth', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '인정번호', value: '', sortable: true, width: '170', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '인정시작일', value: 'acknwPrdStrt', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 tableHeaderSize'},
            { text: '인정종료일', value: 'acknwPrdEnd', sortable: true, align: 'center', width: '130', class: 'black--text greyE01 tableHeaderSize'},
            { text: '입소일', value: 'entDt', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '본인부담률', value: 'copayRate', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '주요질환', value: 'majorDsss', sortable: true, width: '300', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '보호자', value: 'grdName', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '관계', value: 'rltns', sortable: true, width: '120', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '보호자 연락처', value: 'grdCelphn', sortable: true, width: '150', align: 'center', class: 'black--text greyE01 tableHeaderSize'},
            { text: '퇴소사유', value: '', width: '200', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBT'},
        ],
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 104, exHeigth: 100, expandList: ['바나나', '사과', '오렌지'], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
    }),
};
</script>
