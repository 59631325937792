<template>
    <v-sheet class="ma-0" rounded="md" style="position: relative;">
        <div 
            v-if="vsbYn"
            class="d-inline-block d-flex justify-center align-center"
            style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 4;
                opacity: 0.7; background-color: #666;">
                <div>
                    <div class="mb-2" style="text-align: center; ">
                        <v-icon class="white--text" size="120">mdi-calendar-alert-outline</v-icon>
                    </div>
                    <div>
                        <span class="white--text font-weight-bold" style="font-size: 2.4rem;">조회된 항목이 없습니다.</span>
                    </div>
                </div>
        </div>
        <v-row class="mt-1 mb-2" no-gutters>
            <span class="d-inline-block d-flex" style="width: 30%;">
                <v-btn
                    v-if="hdrTyp.indexOf('ch') > -1"
                    class="white--text" height="30" color="lime darken-3"
                    @click="$emit('refresh', '')"
                    rounded samll>
                    <v-icon style="margin-top: 1px;" small>mdi-refresh</v-icon>
                    <span class="fontOneRem ml-1" style="margin-bottom: 1px;">갱신</span>
                </v-btn>
                <v-spacer></v-spacer>
            </span>
            <v-spacer></v-spacer>
            <span v-if="hdrDtYn == 'N'" class="mx-3 pb-1">
                <span class="text-h5 font-weight-bold topTitleColor--text">{{ $moment(intlDt, 'YYYYMM').format('YYYY.MM') }}</span>
            </span>
            <expandMonthPicker v-else @nowYearWithMonth="getPickerDate" :prtDate="intlDt"></expandMonthPicker>
            <v-spacer></v-spacer>
            <span class="d-inline-block d-flex" style="width: 30%;">
                <v-spacer></v-spacer>
                <span v-if="hdrTyp.indexOf('ch') > -1">
                    <v-btn
                        class="white--text" height="30" color="blue002"
                        :disabled="!(sltList.length != sltList.filter( v => v.value == '1' ).length)"
                        @click="clndrAllChkr('1')"
                        rounded samll>
                        <v-icon style="margin-top: 1px;" small>mdi-check-all</v-icon>
                        <span class="fontOneRem ml-1" style="margin-bottom: 1px;">모두선택</span>
                    </v-btn>
                    <v-btn
                        class="ml-1 white--text" height="30" color="logoutBtnColor"
                        :disabled="!(sltList.length != sltList.filter( v => v.value == '2' ).length)"
                        @click="clndrAllChkr('2')"
                        rounded samll>
                        <v-icon style="margin-top: 1px;" small>mdi-check-all</v-icon>
                        <span class="fontOneRem ml-1" style="margin-bottom: 1px;">모두해제</span>
                    </v-btn>
                </span>
            </span>
        </v-row>
        <div
            class="d-inline-block overflow-y-auto elevation-1"
            :style="{ width: '100%', 'max-height': clndrHght+'px' }">
            <v-calendar
                :class="[
                    'cmmClndr',
                    hdrTyp == 'totCnt' ? 'clndrTotCnt' : '',
                    hdrTyp == 'checkbox' ? 'clndrCheckBox' : '',
                    hdrTyp == 'chcSgl' ? 'clndrChcSgl' : '',
                    hdrTyp == 'splView' ? 'clndrSplView' : ''
                ]"
                color="" locale="ko" event-text-color="black"
                v-model="sltDt" :now="nowDt" :type="calendarObj.type"
                :events="clndrList" :event-height="60" :event-more="false" :event-ripple="false"
                @click:event="dayClck">
                <template #day-label="{ date, day }" >
                    <div class="d-flex align-center tableBB">
                        <!-- 달력 날짜 시작 -->
                        <span
                            :class="[
                                'mb-1',
                                date == nowDt && $moment(nowDt, 'YYYY-MM-DD').format('YYYYMM') == $moment(sltDt, 'YYYY-MM-DD').format('YYYYMM') ? 'ic-level nowDay dayNow' : '',
                                holiDeList.findIndex( v => v.locdateHyp == date ) != -1 && date != nowDt ? 'red--text' : ''
                                ]">
                            {{ day }}
                        </span>
                        <!-- 달력 날짜 끝 -->
                        <!-- 달력 공휴일 정보 시작 -->
                        <span
                            v-if="holiDeList.findIndex( v => v.locdateHyp == date ) != -1"
                            class="d-inline-block red--text ml-1"
                            style="width: 60px; font-size: 0.9rem; margin-bottom: 2px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                            {{ holiDeList[holiDeList.findIndex( v => v.locdateHyp == date )].dateName }}
                        </span>
                        <!-- 달력 공휴일 정보 끝 -->
                        <v-spacer></v-spacer>
                        <!-- 달력 모드 구분 (체크박스)  -->
                        <span
                            v-if="hdrTyp.indexOf('ch') > -1 && day > 0"
                            class="d-inline-block" style="width: 20px;">
                            <v-checkbox
                                v-if="sltList.findIndex( v => v.locDate == date) > -1"
                                v-model="sltList[sltList.findIndex( v => v.locDay == day)].value"
                                :disabled="sltList[sltList.findIndex( v => v.locDay == day)].disabled"
                                class="ma-0 pa-0" true-value="1" false-value="2"
                                on-icon="mdi-checkbox-marked-outline" off-icon="mdi-square-outline"
                                hide-details>
                            </v-checkbox>
                        </span>
                        <!-- 달력 모드 구분 (합계)  -->
                        <span
                            v-else-if="hdrTyp == 'totCnt' && day > 0"
                            class="d-inline-block" style="width: 40px; font-size: 0.875rem; margin-bottom: 3px; text-align: end;">
                            총 {{ clndrList.filter( v => v.start == date )?.reduce((acc, cur) => { return acc += cur.cnt }, 0) }}명
                        </span>
                    </div>
                </template>
                <template #event="{ event }">
                    <!-- 달력 모드 구분 (합계)  -->
                    <div
                        v-if="hdrTyp == 'totCnt'"
                        :class="['d-flex align-center px-2', hdrTyp == 'totCnt' ? 'crsPntr' : '']">
                        <span class="d-inline-block fontOneRem">{{ event.name }}</span>
                        <v-spacer></v-spacer>
                        <span class="d-inline-block fontOneRem" style="margin-right: 4px;">{{ event.cnt }}명</span>
                    </div>
                    <!-- 달력 모드 구분 (체크박스)  -->
                    <div
                        v-else-if="hdrTyp == 'checkbox'"
                        class="" style="position: relative;">
                        <span
                            style="position: absolute; top: -16px; left: 2px; background-color: white; padding: 0 2px;">
                            {{ event.name }}
                        </span>
                        <v-row class="pa-0" v-for="(arr, i) in event.list" :key="i" no-gutters>
                            <CmmWrkIcon
                                :size="14" :color="event.color"
                                :wrkTypCd="arr.wrkTypCd" :vctnTypCd="arr.vctnTypCd" :wrkTmHhStrt="arr.wrkTmHhStrt">
                            </CmmWrkIcon>
                            <span class="">{{ arr.wrkTmHhStrt }}:{{ arr.wrkTmMmStrt }}</span>
                            <span>
                                <v-icon style="padding: 0px 1px 3px 1px;" color="black" size="10">mdi-tilde</v-icon>
                            </span>
                            <span class="">{{ arr.wrkTmHhEnd }}:{{ arr.wrkTmMmEnd }}&nbsp;</span>
                            <span class="">&#40;{{ Math.floor(arr.actWrkHr/60) }}&#41;</span>
                        </v-row>
                    </div>
                    <!-- 달력 모드 구분 (체크박스, event list 없음)  -->
                    <div
                        v-else-if="hdrTyp == 'chcSgl'"
                        :class="[ event.vctnSbstYn == '1' ? 'chcSglThr' : 'chcSglSec' ]"
                        style="line-height: 16px;">
                        <v-row class="justify-center align-center" no-gutters>
                            <CmmWrkIcon
                                :size="14" :color="'white'"
                                :wrkTypCd="event.wrkTypCd" :vctnTypCd="event.vctnTypCd" :wrkTmHhStrt="event.wrkTmHhStrt">
                            </CmmWrkIcon>
                            <span v-if="event.wrkTypCd == '2' && event.vctnTypCd == '999'">
                                <span class="white--text fontOneRem">{{ $moment(event.vctnWrkDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </span>
                            <span v-else>
                                <span class="white--text fontOneRem">{{ event.wrkTmHhStrt }}:{{ event.wrkTmMmStrt }}</span>
                                <span>
                                    <v-icon style="padding: 1px 1px 3px 1px;" color="white" size="10">mdi-tilde</v-icon>
                                </span>
                                <span class="white--text fontOneRem">{{ event.wrkTmHhEnd }}:{{ event.wrkTmMmEnd }}</span>
                            </span>
                        </v-row>
                        <v-row class="justify-center align-center" no-gutters>
                            <span class="white--text fontOneRem">&#40;{{ event.brkDay }},&nbsp;{{ event.brkNgt }}&#41;,&nbsp;</span>
                            <span class="white--text fontOneRem">&#40;{{ Math.floor(event.actWrkHr/60) }}&#41;</span>
                            <v-btn
                                v-if="typeof event.empWrkSchdlPk == 'undefined'"
                                class="ml-1 pt-1" color="white" width="16" height="16"
                                @click="$emit('rmEvtNonePk', { strtDt: event.start, strtTm: event.wrkTmHhStrt + ':' + event.wrkTmMmStrt })"
                                icon>
                                <v-icon size="16">mdi-minus-circle-outline</v-icon>
                            </v-btn>
                            <v-btn
                                v-else
                                class="ml-1 pt-1" color="white" width="16" height="16"
                                @click="$emit('rmEvtExstPk', event)"
                                icon>
                                <v-icon size="16">mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </v-row>
                        <v-row
                            v-if="event.vctnSbstYn == '1'"
                            class="justify-center align-center" style="padding-top: 4px; padding-bottom: 2px;"
                            no-gutters>
                            <v-btn
                                class="white--text" color="white"
                                @click="$emit('paidHldy', event)"
                                x-small outlined>
                                <span class="" style="padding-bottom: 2px;">유급휴일가능</span>
                            </v-btn>
                        </v-row>
                    </div>
                    <!-- 달력 모드 구분 (체크박스)  -->
                    <div
                        v-else-if="hdrTyp == 'splView'"
                        class="overflow-text-hidden" style="position: relative;">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="txtAlgnMid font-weight-medium" v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-if="event.atndTmHh != null && event.leavTmHh == null"
                                        style="padding-right: 2px;"
                                        color="blue001" size="14">
                                        mdi-circle-slice-4
                                    </v-icon>
                                    <v-icon
                                        v-else-if="event.atndTmHh != null && event.leavTmHh != null"
                                        style="padding-right: 2px;"
                                        color="green" size="14">
                                        mdi-circle
                                    </v-icon>
                                    <v-icon
                                        v-else
                                        style="padding-right: 2px;"
                                        size="14">
                                        mdi-circle-outline
                                    </v-icon>
                                    <span v-if="event.wrkTypCd == '2'">{{ event.vctnTypNm }}</span>
                                    <span v-else>{{ event.wrkTypNm }}</span>
                                    <span>&nbsp;&#40;&nbsp;{{ event.esWrkTmHhStrt }}&#58;{{ event.esWrkTmMmStrt }}</span>
                                    <span>&#126;</span>
                                    <span>{{ event.esWrkTmHhEnd }}&#58;{{ event.esWrkTmMmEnd }}&nbsp;&#41;</span>
                                </div>
                            </template>
                            <span v-if="event.wrkTypCd == '2'">{{ event.vctnTypNm }}</span>
                            <span v-else>{{ event.wrkTypNm }}</span>
                            <span>&nbsp;&#40;&nbsp;{{ event.esWrkTmHhStrt }}&#58;{{ event.esWrkTmMmStrt }}</span>
                            <span>&#126;</span>
                            <span>{{ event.esWrkTmHhEnd }}&#58;{{ event.esWrkTmMmEnd }}&nbsp;&#41;</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-calendar>
        </div>
    </v-sheet>
</template>

<script>
import { dataApis } from '@/api/global/datagokrAxios';

import expandMonthPicker from './expandMonthPicker.vue';
import CmmWrkIcon from './CmmWrkIcon.vue';

export default {
    name: 'CmmClndr',
    
    components: {
        expandMonthPicker,
        CmmWrkIcon,
    },

    props : {
        vsbYn: { type: Boolean, default: false },                   // 달력 활성화 여부
        clndrHght: { type: String, default: '700' },                // 달력 최대 높이
        hdrDtYn: { type: String, default: 'Y' },                    // 날짜 변경 픽커 여부
        hdrTyp: { type: String, default: '' },                      // 달력 유형
        clndrList: { type: Array, default: () => { return [] } },   // 달력에 표시될 내용(events)
        intlDt: { type: String, default: '' },                      // 년월 픽커 초기 세팅
        atvDt: { type: String, default: '' },                       // 달력 선택가능 시작 일자
    },

    created: function(){
        this.nowDt = this.$moment().format('YYYY-MM-DD')
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.hdrTyp == 'splView')
                this.sltDt = this.$moment().format('YYYY-MM-DD')
        })
    },

    computed: {
 
    },  

    watch: {
        'sltDt': function(){
            let tmp = this.$moment(this.sltDt, 'YYYY-MM-DD')
            this.getHoliDeInf(tmp.format('YYYY'), tmp.format('MM'))
            this.setClndrSltBox()
        },
        'atvDt': function(){
            if(this.atvDt != '' && this.sltList.length > 0 ){
                this.sltList.forEach(idx => {
                    if(this.$moment(this.atvDt, 'YYYYMMDD').isAfter(this.$moment(idx.locDate, 'YYYY-MM-DD')))
                        idx.disabled = true
                });
            }
        },
    },

    methods: {
        // 기념일 정보 조회
        getAnniversaryInf: function (year, mnth, rowCnt) {
            dataApis.get('/getAnniversaryInfo', { params: { solYear: year, solMonth: mnth, numOfRows: rowCnt } })
                .then((response) => ( this.holiDeRst(response.data) ))
                .catch((error) => console.log('connect error http://apis.data.go.kr/getAnniversaryInfo : ' + error))
        },

        // 국경일 정보 조회
        getHoliDeInf: function (year, mnth) {
            this.holiDeList.splice(0)

            dataApis.get('/getHoliDeInfo', { params: { solYear: year, solMonth: mnth } })
                .then((response) => ( this.holiDeRst(response.data) ))
                .catch((error) => console.log('connect error http://apis.data.go.kr/getHoliDeInfo : ' + error))
        },

        // 공휴일 정보 조회
        getRestDeInf: function (year, mnth) {
            dataApis.get('/getRestDeInfo', { params: { solYear: year, solMonth: mnth } })
                .then((response) => ( this.restDeRst(response.data) ))
                .catch((error) => console.log('connect error http://apis.data.go.kr/getRestDeInfo : ' + error))
        },

        // 잡절 정보 조회
        getSundryDayInf: function (year, mnth) {
            dataApis.get('/getSundryDayInfo', { params: { solYear: year, solMonth: mnth } })
                .then((response) => ( this.sundryDayRst(response.data) ))
                .catch((error) => console.log('connect error http://apis.data.go.kr/getSundryDayInfo : ' + error))
        },

        holiDeRst: function (res) {
            if(Object.keys(res.response?.body).length > 0){
                if(res.response.body.items.item instanceof Array){
                    res.response.body.items.item.forEach(itm => {
                        itm.locdateHyp = this.$moment(itm.locdate, 'YYYYMMDD').format('YYYY-MM-DD')
                    });
                    this.holiDeList = res.response.body.items.item
                }
                else if(res.response.body.items.item instanceof Object){
                    res.response.body.items.item.locdateHyp = this.$moment(res.response.body.items.item.locdate, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.holiDeList.push(res.response.body.items.item)
                }

                if(this.$moment(this.sltDt, 'YYYY-MM-DD').format('MM') == '05'){
                    let obj = {}
                    obj.dateName = '근로자의날'
                    obj.isHoliday = 'N'
                    obj.locdate = this.$moment().format('YYYY') + '0501'
                    obj.locdateHyp = this.$moment(obj.locdate, 'YYYYMMDD').format('YYYY-MM-DD')
                    this.holiDeList.push(obj)
                }
            }
        },

        restDeRst: function (res) {
            console.log(res)
        },

        sundryDayRst: function (res) {
            console.log(res)
        },

        // 달력 클릭 이벤트
        dayClck: function (v) {
            this.$emit('dayClck', v)
        },

        // 날짜 변경시 호출
        getPickerDate: function (date) {
            this.sltDt = this.$moment(date, 'YYYYMM').format('YYYY-MM-DD')
            this.$emit('chgDtAct', this.sltDt)
        },

        // 초기 달력 체크박스 세팅
        setClndrSltBox: function () {
            this.sltList.splice(0)

            let fsDt = Number(this.$moment(this.sltDt, 'YYYY-MM-DD').startOf('month').format('DD'))
            let lsDt = Number(this.$moment(this.sltDt, 'YYYY-MM-DD').endOf('month').format('DD'))

            for (let index = fsDt; index < (lsDt + 1); index++) {
                let obj = {}
                obj.locDate = this.$moment(this.sltDt, 'YYYY-MM-DD').format('YYYY-MM') + '-' + ('00' + index).slice(-2)
                obj.locDay = String(index)
                obj.value = '2'

                if(this.atvDt != ''){
                    if(this.$moment(this.atvDt, 'YYYYMMDD').isAfter(this.$moment(obj.locDate, 'YYYY-MM-DD')))
                        obj.disabled = true
                    else
                        obj.disabled = false
                }

                this.sltList.push(obj)
            }
        },

        // 체크박스 값 전체 변경
        clndrAllChkr: function (v) {
            if(v == '1'){
                this.sltList.forEach(itm => {
                    if(!itm.disabled)
                        itm.value = v
                });
            }
            else{
                this.sltList.forEach(itm => {
                    itm.value = v
                });
            }
        },

        // 체크박스 선택 항목 반환
        getSltBoxList: function () {
            return this.sltList.filter( v => v.value == '1' )
        },
    },

    data: () => ({
        sltDt: '',
        nowDt: '',
        holiDeList: [],
        sltList: [],
        // events: [
        //     { name: '1', start: '2023-09-07', end: '2023-09-08', color: 'light-blue darken-2', cnt: 1 },
        // ],
        colors: ['blue', 'deep-orange darken-3', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        calendarObj: 
        { 
            type: 'month', types: ['month', 'week', 'day', '4day'],
            weekday: [0, 1, 2, 3, 4, 5, 6], mode: 'stack',
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
        },
    }),
};
</script>

<style>
    .cmmClndr .v-calendar-weekly__head div {
        font-size: 1.2rem; font-weight: 500; padding: 6px 0px;
        margin-right: initial !important; border-bottom: #e0e0e0 1px solid;
    }
    .cmmClndr .v-calendar-weekly__head div:first-child { color: red !important; }
    .cmmClndr .v-calendar-weekly__head div:last-child { color: #003b9e !important; }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day .v-calendar-weekly__day-label { cursor: initial; }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day .v-calendar-weekly__day-label > div {
        text-align: initial !important; padding: 0px 8px 2px 8px; font-size: 1.1rem;
    }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day { min-height: 124px; }
    .cmmClndr .v-calendar-weekly__week .v-event {
        margin-left: 2px; width: 98%; border: 1px solid #dbdbdb; cursor: initial;
    }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day { padding-bottom: 2px; }
    .cmmClndr .v-calendar-weekly__week .v-calendar-weekly__day .v-event {
        min-height: 23px !important;
    }
    .cmmClndr .v-calendar-weekly__head { 
        position: sticky; top: 0px; left: 0; width: 100%; background-color: white; z-index: 3;
        border-top: #e0e0e0 1px solid; margin-top: -1px; padding-bottom: 1px;
        border-collapse: separate !important; border-spacing: 0px !important;
    }
    
    .clndrTotCnt .v-calendar-weekly__week .v-calendar-weekly__day .v-event {
        width: 97% !important; height: 23px !important; margin-top: 2px !important; background-color: initial !important;
    }
    .clndrSplView .v-calendar-weekly__week .v-calendar-weekly__day .v-event {
        width: 97% !important; height: 23px !important; margin-top: 2px !important; padding: 0 4px;
        background-color: initial !important;
    }
    .clndrCheckBox .v-calendar-weekly__week .v-calendar-weekly__day .v-event {
        background-color: initial !important; height: initial !important; overflow: visible !important;
        margin-top: 16px !important; font-size: 0.875rem; padding: 0px 3px 0px 5px;
    }
    .clndrCheckBox .v-calendar-weekly__week .v-calendar-weekly__day div:nth-child(2){
        margin-bottom: 1px;
    }

    .clndrChcSgl .v-calendar-weekly__week .v-calendar-weekly__day > div:not(:first-child) {
        margin-top: 2px;
    }

    .chcSglSec { margin-top: 2px; }

    .clndrChcSgl .v-calendar-weekly__week .v-calendar-weekly__day .v-event:has(.chcSglThr){ height: 61px !important; }
    .clndrChcSgl .v-calendar-weekly__week .v-calendar-weekly__day .v-event:has(.chcSglSec){ height: 42px !important; }

    .crsPntr { cursor: pointer; }
    .nmlyBox .v-input__control .v-input__slot .v-input--selection-controls__input { width: 16px; height: 16px; }
    .nmlyBox .v-input__control .v-input__slot .v-input--selection-controls__input > i { background-color: white; }
    .nmlyBox .v-input__control .v-input__slot label { font-size: 0.875rem; margin-left: 3px; }
    .cndrIcon { margin-right: 3px; padding-top: 2px; }
</style>