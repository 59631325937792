<template>
    <v-sheet class="ma-0 pa-0">
        <v-row class="pa-0 my-5" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <expandYearOnlyPicker
                    :prtYear="Number($parent.$parent.$parent.$parent.sltDate.substring(0, 4))"
                    @nowYear="getYear">
                </expandYearOnlyPicker>
            </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-row class="greyE01 pa-0 ma-0" no-gutters>
            <v-col
                :class="['d-flex justify-center align-center py-2', lgIndex == 4 ? '' : 'tableBR']"
                v-for="lgIndex in 4" :key="lgIndex" cols="3">
                <span class="black--text font-weight-medium">{{ lgIndex }}월</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col
                :class="['py-2 px-6', lgIndex == 4 ? '' : 'tableBR']" style="min-height: 36px;"
                v-for="lgIndex in 4" :key="lgIndex" cols="3">
                <div class="d-flex align-center" v-for="(bz, i) in plnYearList[lgIndex-1]" :key="i">
                    <v-row class="ma-0 pa-0" v-if="bz.bzPlnClcd == '1'" no-gutters>
                        <v-col class="d-flex align-center" cols="4">
                            <v-icon class="" color="orange" size="20">mdi-cake-variant-outline</v-icon>
                            <span class="ml-2">{{ bz.name }}</span>
                        </v-col>
                        <v-col cols="8">
                            <span
                                class="black--text ml-1" style="font-size: 1.1rem;"
                                v-for="(nm, i) in bz.bzNm" :key="i">
                                    {{ nm }},
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mb-1 pa-0" v-else no-gutters>
                        <v-col class="d-flex align-center" cols="4">
                            <span class="d-inline-block rounded-circle"
                                :style="{ width: 18+'px', height: 18+'px', 'background-color': bz.bzClr, 'margin-top': 0+'px', 'margin-left': 2+'px', }">
                            </span>
                            <span class="ml-2">{{ bz.name }}</span>
                        </v-col>
                        <v-col cols="8">
                            <span
                                class="black--text ml-1" style="font-size: 1.1rem;"
                                v-for="(nm, i) in bz.bzNm" :key="i">
                                    {{ nm }}일,
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="greyE01 pa-0 ma-0" no-gutters>
            <v-col
                :class="['d-flex justify-center align-center py-2', lgIndex == 4 ? '' : 'tableBR']"
                v-for="lgIndex in 4" :key="lgIndex" cols="3">
                <span class="black--text font-weight-medium">{{ lgIndex+4 }}월</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col
                :class="['py-2 px-6', lgIndex == 4 ? '' : 'tableBR']" style="min-height: 36px;"
                v-for="lgIndex in 4" :key="lgIndex" cols="3">
                <div class="d-flex align-center" v-for="(bz, i) in plnYearList[lgIndex+3]" :key="i">
                    <v-row class="ma-0 pa-0" v-if="bz.bzPlnClcd == '1'" no-gutters>
                        <v-col class="d-flex align-center" cols="4">
                            <v-icon class="" color="orange" size="20">mdi-cake-variant-outline</v-icon>
                            <span class="ml-2">{{ bz.name }}</span>
                        </v-col>
                        <v-col cols="8">
                            <span
                                class="black--text ml-1" style="font-size: 1.1rem;"
                                v-for="(nm, i) in bz.bzNm" :key="i">
                                    {{ nm }},
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mb-1 pa-0" v-else no-gutters>
                        <v-col class="d-flex align-center" cols="4">
                            <span class="d-inline-block rounded-circle"
                                :style="{ width: 18+'px', height: 18+'px', 'background-color': bz.bzClr, 'margin-top': 0+'px', 'margin-left': 2+'px', }">
                            </span>
                            <span class="ml-2">{{ bz.name }}</span>
                        </v-col>
                        <v-col cols="8">
                            <span
                                class="black--text ml-1" style="font-size: 1.1rem;"
                                v-for="(nm, i) in bz.bzNm" :key="i">
                                    {{ nm }}일,
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="greyE01 pa-0 ma-0" no-gutters>
            <v-col
                :class="['d-flex justify-center align-center py-2', lgIndex == 4 ? '' : 'tableBR']"
                v-for="lgIndex in 4" :key="lgIndex" cols="3">
                <span class="black--text font-weight-medium">{{ lgIndex+8 }}월</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col
                :class="['py-2 px-6', lgIndex == 4 ? '' : 'tableBR']" style="min-height: 36px;"
                v-for="lgIndex in 4" :key="lgIndex" cols="3">
                <div class="d-flex align-center" v-for="(bz, i) in plnYearList[lgIndex+7]" :key="i">
                    <v-row class="ma-0 pa-0" v-if="bz.bzPlnClcd == '1'" no-gutters>
                        <v-col class="d-flex align-center" cols="4">
                            <v-icon class="" color="orange" size="20">mdi-cake-variant-outline</v-icon>
                            <span class="ml-2">{{ bz.name }}</span>
                        </v-col>
                        <v-col cols="8">
                            <span
                                class="black--text ml-1" style="font-size: 1.1rem;"
                                v-for="(nm, i) in bz.bzNm" :key="i">
                                    {{ nm }},
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="mb-1 pa-0" v-else no-gutters>
                        <v-col class="d-flex align-center" cols="4">
                            <span class="d-inline-block rounded-circle"
                                :style="{ width: 18+'px', height: 18+'px', 'background-color': bz.bzClr, 'margin-top': 0+'px', 'margin-left': 2+'px', }">
                            </span>
                            <span class="ml-2">{{ bz.name }}</span>
                        </v-col>
                        <v-col cols="8">
                            <span
                                class="black--text ml-1" style="font-size: 1.1rem;"
                                v-for="(nm, i) in bz.bzNm" :key="i">
                                    {{ nm }}일,
                            </span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../commons/expandYearOnlyPicker.vue';

import { selBzPlnYearList } from '../../api/opr.js';

export default {
    name: 'AnualPlan',

    props : {
       
    },
        
    components: {
        expandYearOnlyPicker,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getYyMmDate: function () {
            return this.$parent.$parent.$parent.$parent.sltDate
        },
    },

    watch:{
        getYyMmDate: function (value) {
            this.getBzPlnYearList(value.substring(0, 4))
        },
    },
    
    methods: {
        getBzPlnYearList: function (yyyy) {
            selBzPlnYearList(this.$store.getters.getFcltyNum, yyyy)
                .then((response) => ( this.getBzPlnYearListAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selBzPlnYearList : ' + error))
        },
        getBzPlnYearListAfter: function (res) {
            for (let index = 1; index < 13; index++) {
                let llsss = res.filter( v => v.bzMm == ('00' + index).slice(-2) )
                llsss.forEach(list => {
                    this.$parent.$parent.$parent.$parent.vCd95.forEach(cd => {
                        if(list.bzPlnClcd == cd.valcd){
                            list.name = cd.valcdnm
                            list.bzClr = cd.valdesc
                        }
                    });
                });
                this.plnYearList.splice(index-1, 1, llsss)
            }
        },
        getYear: function (yyyy){
            this.$parent.$parent.$parent.$parent.sltDate = yyyy + '01'
        },
    },

    data: () => ({
        plnYearList: [
            [], [], [], [], [], [],
            [], [], [], [], [], [],
        ],
    }),
};
</script>