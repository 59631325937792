<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">프로그램&nbsp;일정등록</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="mdlform" lazy-validation>
            <v-row class="pa-0 ma-0 mt-2" no-gutters>
                <v-col class="pa-1 ma-0" cols="7">                    
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">프로그램&nbsp;일정등록</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ml-1" 
                            color="blueBtnColor" 
                            style="padding: 13px 12px 12px 10px !important"                            
                            min-width="30" height="25" small outlined
                            :disabled="btnSave"
                            @click="insPrgOpPln"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi-content-save-edit-outline</v-icon>
                                        <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                                    </div>
                                </template>
                                <span>프로그램 일정등록 저장</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                    <div class="mt-3">
                        <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="2">
                                <span class="black--text font-weight-medium" style="text-align: center;">프로그램</span>                                
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="10">
                                <span class="d-inline-block" style="width: 400px;">
                                    <v-text-field v-model="items.prgNm" ref="info" :rules="[rules.required]" hide-details
                                        outlined dense readonly></v-text-field>
                                </span>
                                <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="30"
                                    height="25"  
                                    dark
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onPrgrm(0)"
                                    >
                                    <v-icon size="20">mdi-playlist-plus</v-icon>
                                </v-btn>
                            </v-col>                                
                        </v-row>
                        <v-row style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="2">
                                <span class="black--text font-weight-medium py-2">프로그램 유형</span>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="5">
                                <v-text-field v-model="items.prgTypNm" style="width: 85%;" ref="info"
                                    :rules="[rules.required]" hide-details outlined dense readonly></v-text-field>
                            </v-col>
                            <v-col class="d-flex justify-center align-center greyE01" cols="2">
                                <span class="black--text font-weight-medium py-2">지표구분</span>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="3">
                                <v-text-field v-model="items.idxCdnm" color="blue" ref="info" :rules="[rules.required]"
                                    hide-details outlined dense readonly></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01" cols="2">
                                <span class="black--text font-weight-medium">계획일시</span>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="5">
                                <span class="white d-inline-block py-1" style="width: 139px;">
                                    <CmmDateComp v-model="opDt" :required="true"></CmmDateComp>
                                </span>
                                <span class="d-inline-block ml-1" style="width: 60px;">
                                    <CmmTimeField v-model="outStrt" :required="true"></CmmTimeField>
                                </span>
                                ~
                                <span class="d-inline-block" style="width: 60px;">
                                    <CmmTimeField v-model="outEnd" :required="true"></CmmTimeField>
                                </span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center greyE01" cols="2">
                                <span class="black--text font-weight-medium py-2">진행자</span>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-left align-center" cols="3">
                                <span style="width:100px;">
                                    <v-text-field v-model="items1.mc" color="blue" ref="info" hide-details outlined dense readonly></v-text-field>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="mt-3" cols="12">
                                <v-data-table 
                                    height="430"
                                    locale="ko" 
                                    :headers="headers" 
                                    :items="prgBnfcrgrInfList"
                                    hide-default-header fixed-header hide-default-footer dense>
                                    <template v-slot:header="{ props }">
                                        <thead>
                                            <tr>
                                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 25%;">
                                                    {{ props.headers[0].text }}</th>
                                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 65%;">
                                                    {{ props.headers[1].text }}</th>
                                                <th class="black--text clmsHeaderCommon tableBT" style="width: 10%;">
                                                    <v-btn :disabled="btnOnOff" color="blue200" icon height="26"
                                                        @click="onPrgrmgr()"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-slot:item='{ item, index }'>
                                        <tr>
                                            <td class="clmsBodyCommon tableBR">
                                                {{ item.bnfcrgrNm }}
                                            </td>
                                            <td class="clmsBodyCommon tableBR">
                                                {{ item.bnfcrgrDesc }}
                                            </td>
                                            <td class="clmsBodyCommon">                                            
                                                <v-btn color="grey006" icon height="26" v-if="item.bnfcrgrPrgPk > 0"
                                                    @click="onDelete(index)"><v-icon>mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                                <v-btn color="grey006" icon height="26" v-else
                                                    @click="onDelete3(index)"><v-icon>mdi-minus-circle-outline</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col class="py-1 pl-3 ma-0" cols="5">                    
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">참여대상&nbsp;수급자&nbsp;정보</span>
                    </div>
                    <div class="mt-3">
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col cols="12">
                                <v-data-table 
                                    locale="ko" 
                                    item-key="rn"
                                    height="585"
                                    :headers="bnfcrHeaders" 
                                    :items="bnfcrgrBnfcrList"
                                    disable-pagination 
                                    hide-default-header fixed-header
                                    hide-default-footer dense                                 
                                >
                                    <template v-slot:header="{ props }">
                                        <thead>
                                            <tr>
                                                <th class="black--text clmsHeaderCommon tableBR tableBT" style=" width: 10%;">
                                                    {{ props.headers[0].text }}</th>
                                                <th class="black--text clmsHeaderCommon tableBR tableBT"
                                                    style=" width: 20%; border-top: 1px solid #dbdbdb">
                                                    {{ props.headers[1].text }}</th>
                                                <th class="black--text clmsHeaderCommon tableBR tableBT"
                                                    style=" width: 20%; border-top: 1px solid #dbdbdb">
                                                    {{ props.headers[2].text }}</th>
                                                <th class="black--text clmsHeaderCommon tableBR tableBT"
                                                    style=" width: 10%; border-top: 1px solid #dbdbdb">
                                                    {{ props.headers[3].text }}</th>
                                                <th class="black--text clmsHeaderCommon tableBR tableBT"
                                                    style=" width: 10%; border-top: 1px solid #dbdbdb">
                                                    {{ props.headers[4].text }}</th>
                                                <th class="black--text clmsHeaderCommon tableBT"
                                                    style=" width: 10%; border-top: 1px solid #dbdbdb">
                                                    {{ props.headers[5].text }}</th>
                                            </tr>
                                        </thead>
                                    </template>  
                                    <template v-slot:item='{ item }'>    
                                        <tr>
                                            <td class="clmsBodyCommon tableBR">
                                                <span>{{ item.rn }}</span>
                                            </td>
                                            <td class="clmsBodyCommon tableBR">
                                                <span>{{ item.bnfcrgrNm }}</span>
                                            </td>
                                            <td class="clmsBodyCommon tableBR">
                                                <span>{{ item.name }}</span>
                                            </td>
                                            <td class="clmsBodyCommon tableBR">
                                                <span>{{ item.age }}</span>
                                            </td>
                                            <td class="clmsBodyCommon tableBR">
                                                <span class="d-inline-block pt-1">
                                                    <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'"
                                                        src="../../styles/images/ic-female.svg"></v-img>
                                                    <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'"
                                                        src="../../styles/images/ic-male.svg"></v-img>
                                                </span>
                                            </td>
                                            <td class="clmsBodyCommon">
                                                <span :class="'d-inline-block ic-level level0' + item.acknwRtng">
                                                    {{ item.acknwRtng}}
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-form>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <PrgrmSelect 
                    ref="pSelect" 
                    :prgrmSelectPk="prgrmSelectPk"
                    @isClose="isModel(0)" 
                    @outPrgrm="setPrgrm"
                ></PrgrmSelect>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <PrgrmgrMultiSelector 
                    ref="pMSelector" 
                    @isClose="isModel(1)" 
                    @reload="reset()" 
                    @outEmp="setprggr" 
                    :prgBnfcrgrChkItems="prgBnfcrgrInfPks" 
                    :prgBnfcrgrNoItems="prgGrNoItems" 
                    :prgInfPk="prgInfPk"
                ></PrgrmgrMultiSelector>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete" max-width="500">
                <btnModalDelete :title="'프로그램 수급자 그룹'" @madalState="onDeleteBtn"></btnModalDelete>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import { selPrgInfInfo, selBnfcrgrBnfcrList, insPrgOpPln, selPrgBnfcrgrInfList, delBnfcrgrPrg } from '../../api/prgrm.js';
import PrgrmSelect from '../../components/prgrm/PrgrmSelect.vue';   
import CmmDateComp from '../commons/CmmDateComp.vue';
import PrgrmgrMultiSelector from '../../components/prgrm/PrgrmgrMultiSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'PrgrmNew',

    props:{
        
    },

    components: {
        PrgrmSelect,
        PrgrmgrMultiSelector,
        btnModalDelete,
        CmmDateComp,
        CmmTimeField,
    },

    created: function () {
        this.$nextTick(function () {
        })
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {
    },

    watch: {
    },

    methods: {
        init:function(){
            this.bnfcrgrBnfcrList.splice(0) 
            this.prgBnfcrgrInfList.splice(0)
            this.items1 = Object.assign({})
            this.prgBnfcrgrInfPks.splice(0)
            this.$refs.mdlform.reset()      
            this.btnOnOff = true
            this.btnSave = false
            this.$refs.mdlform.resetValidation()                  
        },
        selPrgInfInfo: function () {
            selPrgInfInfo(this.$store.getters.getFcltyNum, this.prgInfPk)
                .then((response) => (this.selPrgInfInfoAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
        },
        selPrgInfInfoAfter: function (res) {
            this.items1 = Object.assign({})            
            this.btnOnOff = true

            if(res!=null){
                this.btnOnOff = false
                this.items1 = res     
            }

            this.$refs.mdlform.resetValidation()
        },
        selBnfcrgrBnfcrList: function () {
            this.bnfcrgrBnfcrList.splice(0)    

            if(this.prgBnfcrgrInfPks.length > 0){
                selBnfcrgrBnfcrList(this.$store.getters.getFcltyNum, null, null, this.prgBnfcrgrInfPks)
                    .then((response) => (this.selBnfcrgrBnfcrListAfter(response.data)))
                    .catch((error) => console.log('connect error prgrm/selBnfcrgrBnfcrList : ' + error))
            }
        },
        selBnfcrgrBnfcrListAfter: function (res) {                 
            res.forEach(el => {
                this.bnfcrgrBnfcrList.push(el)
            });
        },
        selPrgBnfcrgrInfList: function () {
            selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum, this.prgInfPk)
                .then((response) => (this.selPrgBnfcrgrInfListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selPrgBnfcrgrInfList : ' + error))
        },
        selPrgBnfcrgrInfListAfter: function (res) {  
            this.prgBnfcrgrInfList.splice(0)
            this.prgBnfcrgrInfPks.splice(0)

            res.forEach(e => {
                if(e.bnfcrgrPrgPk!=null){
                    this.prgBnfcrgrInfList.push(e)
                    this.prgBnfcrgrInfPks.push(e.prgBnfcrgrInfPk)
                }
            });
            this.selBnfcrgrBnfcrList()
        },
        insPrgOpPln: function () {
            if (this.$refs.mdlform.validate()) {
                let outStrt = this.outStrt.split(':', 2)
                let outEnd = this.outEnd.split(':', 2)
                let obj = {
                    opDt: this.opDt?.afterDateFormatHyp(),
                    opTmHhStrt: outStrt[0],
                    opTmMmStrt: outStrt[1],
                    opTmHhEnd: outEnd[0],
                    opTmMmEnd: outEnd[1],
                    prgInfPk: this.prgInfPk,
                    userNm: this.$store.getters.getUserNm,
                    wrtr: this.$store.getters.getUserNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    list: [],
                }
                this.prgBnfcrgrInfList.forEach(e => {
                    let item = {
                        prgBnfcrgrInfPk: e.prgBnfcrgrInfPk,
                        userNm: this.$store.getters.getUserNm,
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgInfPk: this.prgInfPk,
                        // bnfcrgrPrgPk: this.
                    }

                    if(e.bnfcrgrPrgPk==null){
                        obj.list.push(item)
                    }

                });
                insPrgOpPln(obj)
                    .then((response) => (this.insPrgOpPlnAfter(response.data)))
                    .catch((error) => console.log(error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        insPrgOpPlnAfter(res) {
            if (res.statusCode == 200) {
                // this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.onClose()               
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.$emit('isClose', '.')    
            this.init()        
        },
        onPrgrm: function (value) {
            this.prgrmSelectPk = 1
            this.dialog.splice(value, 1, true)                   
        },
        onPrgrmgr:function(){           
            this.$refs.pMSelector.btnShowMethod()
            this.$refs.pMSelector.btnShowMethod3()
            this.$refs.pMSelector.onShow()            
            this.dialog.splice(1, 1, true) 
        },
        isModel:function(value){
            switch (value) {
                case 0:
                    this.prgrmSelectPk = -1
                    break;            
                default:
                    break;
            }
            this.dialog.splice(value, 1, false)
        },
        setPrgrm: function (obj) {
            this.items = obj
            this.prgInfPk = obj.prgInfPk

            this.isModel(0)
            this.selPrgInfInfo()
            this.selPrgBnfcrgrInfList()
        },
        onClickBtn: function (text) {
            if (text == '저장') {
                this.insPrgOpPln()
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        setprggr: function (emps) {            
            emps.forEach(x => {
                let bl = this.prgBnfcrgrInfPks.includes(x.prgBnfcrgrInfPk)
                if (!bl) {
                    let obj = {
                        bnfcrgrNm: x.bnfcrgrNm,
                        bnfcrgrDesc: x.bnfcrgrDesc,
                        prgBnfcrgrInfPk: x.prgBnfcrgrInfPk,
                        // bnfcrgrBnfcrPk: 0
                    }                    
                    this.prgBnfcrgrInfList.push(obj)
                    this.prgBnfcrgrInfPks.push(x.prgBnfcrgrInfPk)
                }
            })

            this.selBnfcrgrBnfcrList()
        },
        onDelete(key) {
            this.key = key
            this.dialogDelete = true
        },
        onDelete3(key) {
            this.prgBnfcrgrInfList.splice(key, 1)

            this.prgBnfcrgrInfPks.splice(0)
            this.prgBnfcrgrInfList.forEach(el => {
                this.prgBnfcrgrInfPks.push(el.prgBnfcrgrInfPk)
            });

        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.prgBnfcrgrInfList[this.key].bnfcrgrPrgPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        bnfcrgrPrgPk: this.prgBnfcrgrInfList[this.key].bnfcrgrPrgPk,
                    }
                    delBnfcrgrPrg(obj)
                        .then((response) => (this.delBnfcrgrPrgAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.prgBnfcrgrInfList.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delBnfcrgrPrgAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.prgBnfcrgrInfList.splice(0)
                this.selPrgBnfcrgrInfList()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
    },

    data: () => ({
        prgrmSelectPk:-1, //프로그램 팝업
        filledBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', disabled: false, },
        ],
        bnfcrHeaders: [
            { text: '연번', value: 'rn', sortable: true, width: '80', align: 'center', class: 'black--text py-4 greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn0' },
            { text: '그룹명', value: 'name', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn1' },
            { text: '수급자', value: 'name', sortable: true, width: '90', align: 'center', class: 'black--text greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn1' },
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '나이', value: 'age', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '성별', value: 'gndr', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize v-data-table--fixed-header fixedPstn2' },
            { text: '등급', value: 'acknwRtng', sortable: true, width: '80', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        dialog: [false],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        menu: [false],
        outStrt: '',
        outEnd: '',
        opDt: '',
        snackOptions: [false, 3000, '', 'info'],
        items: {},
        prgInfPk: 0,
        items1: {},
        bnfcrgrBnfcrList: [],
        prgBnfcrgrInfList: [],
        bnfcrgrNms: [],
        prgBnfcrgrInfPks: [],
        prgGrchkItems: [],
        prgGrNoItems: [],
        headers: [
            { text: '수급자 그룹명', hisPk: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '설명', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        btnOnOff: true,
        btnSave: false,
        key: 0,
        dialogDelete: false,

    }),

};
</script>
<style>
.PrgrmNew {
    font-size: 1.0rem !important;
}

.PrgrmNew1 {
    border-left: 1px solid #dbdbdb !important;
    border-right: 1px solid #dbdbdb !important;
}
</style>