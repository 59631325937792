<template>
    <div class="d-inline-block d-flex">
        <v-sheet class="d-inline-block" width="450" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>2-3. 물리&#40;작업&#41;치료&nbsp;평가&nbsp;및&nbsp;계획</span>
                </span>
            </div>
            <expandYearOnlyPicker @nowYear = "getYear" class="my-5"></expandYearOnlyPicker>
            <v-row class="justify-left mt-3 mx-3">
             
                <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
                <exBtn class="ml-1" :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
              
                <v-spacer></v-spacer>
                <span style="width:130px">
                    <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" hide-details  height="35px"
                        single-line rounded outlined dense filled label="이름조회" @input="setSearchText" >
                    </v-text-field>
                </span>
            </v-row>
            <div class="mt-5 ml-3 mr-2">                
                <v-data-table
                    class="bnfcrMidList" 
                    style="position: relative;"
                    v-model="selectedItemIndex" 
                    locale="ko" :loading="tableLoad" height="640"                    
                    :loading-text="tableText"
                    :search="schDummy" 
                    :custom-filter="customFilter"
                    item-key="rn"
                    :headers="headers" 
                    :items="items"
                    fixed-header 
                    disable-pagination 
                    hide-default-footer dense>

                    <template v-slot:header="props" >
                        <thead>
                            <tr>
                                <th v-for="(list, i) in props.headers" :key="i">{{list.text}}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:item='{ item, index }'>
                 
                        <tr class="fixedCol" v-if="selectedItemIndex.indexOf(item.rn) > -1" @click="rowClicked(item)">                               
                            <td class="fixedTable fixedPstn0 fixedCol0 " style="width: 70px; padding-left: 20px; background: #ebf6fe;">
                                {{index+1}}
                            </td>
                            <td class="fixedTable fixedPstn1 fixedCol0" style="width: 80px; background: #ebf6fe;">{{item.bnfcrNm}}</td>
                            <td style="background: #ebf6fe;">                                
                                <span v-if="item.lstExamDt != null && item.lstExamDt != undefined">{{$moment(item.lstExamDt,'YYYYMMDD').format('YYYY.MM.DD')}}</span>                                
                            </td>
                            <td style="background: #ebf6fe;">                                
                                <span :class="['', item.examDt == '미작성' ? 'red--text' : '']">{{item.examDt}}</span>
                            </td>
                            <td style="background: #ebf6fe;">
                                <span :class="['', item.wrtDt == '미작성' ? 'red--text' : '']">{{item.wrtDt}}</span>
                            </td>
                            <td style="background: #ebf6fe;">{{item.entStcd}}</td>
                            <td style="background: #ebf6fe;">
                                <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                                <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                            </td> 
                            <td style="background: #ebf6fe;">{{item.age}}</td>
                            <td style="background: #ebf6fe;">{{item.lvnRmNm}}</td>
                        </tr>
                        <tr class="fixedCol" v-else @click="rowClicked(item)">                                
                            <td class="fixedTable fixedPstn0" style="width: 70px; padding-left: 20px;">
                                {{index+1}}
                            </td>
                            <td class="fixedTable fixedPstn1" style="width: 80px;">{{item.bnfcrNm}}</td>    
                            <td>                                
                                <span v-if="item.lstExamDt != null && item.lstExamDt != undefined">{{$moment(item.lstExamDt,'YYYYMMDD').format('YYYY.MM.DD')}}</span>
                            </td>                   
                            <td>
                               <span :class="['', item.examDt == '미작성' ? 'red--text' : '']">{{item.examDt}}</span>    
                            </td>
                            <td>
                                <span :class="['', item.wrtDt == '미작성' ? 'red--text' : '']">{{item.wrtDt}}</span>
                            </td>
                            <td>{{item.entStcd}}</td>
                            <td>
                                <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                                <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                            </td>
                            <td>{{item.age}}</td>
                            <td>{{item.lvnRmNm}}</td>
                        </tr>

                    </template>

                    <template v-slot:foot='{ items, pagination }'>
                        <div class="px-1 d-inline-block" style="width: 100%; position: absolute; bottom: -34px; left: 0;">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="d-flex align-center" cols="7">
                                    <span class="fontOneRem">전체: {{ pagination.itemsLength }}명,&nbsp;&nbsp;</span>
                                    <span class="fontOneRem">남: {{ items.filter( v => v.gndr == 'M').length }}명&nbsp;&nbsp;</span>
                                    <span class="fontOneRem">여: {{ items.filter( v => v.gndr == 'F').length }}명</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="d-flex" cols="4">
                                    <v-spacer></v-spacer>
                                    <span class="d-inline-block" style="width: 92px;">
                                        <v-checkbox
                                            v-model="entChgBox" :ripple="false"
                                            class="checkIcon" true-value="1" false-value="2" label="퇴소자 조회"
                                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <v-sheet class="d-inline-block rounded-lg rounded-l-0 overflow-auto" min-width="600px" max-width="900px">
            <router-view ref="physcThrpyInspcPln"></router-view>
        </v-sheet>
    </div>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import { selLvnRmInf } from '../../api/opr.js';
import { getStcd } from '../../api/index.js';
import { getAllPhyThrList } from '../../api/bnftSrv.js';

export default {
    name: 'BnftSrvPlnCnfgr',
    
    components: {
        expandYearOnlyPicker,
        exBtn,
    },

    created: function(){
         getStcd('65')
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log(error))
        this.getLvnRmInf()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllList()

            this.entChgBox = 2
        })
    },

    watch: {
        'items': function () {
            if(this.items.length < 1)   console.log('code Mapping Failed')
                else {
                    this.items.forEach((item) => {
                        this.cdItems.forEach((cd) => {
                            if(item.entStcd == cd.valcd) item.entStcd = cd.valcdnm
                        });//end foreach
                    });//end foreach
                }//end else

            if(this.refresh == 1){

                this.items.forEach((pk, i) => {
                    if(pk.bnfcrPk == this.$store.getters.getBnfcrState.bnfcr){
                        this.rowClicked({rn: i+1})
                    }
                    this.refresh = 0
                });                
            }

        },
        '$route' (to, from) {
            this.routerRocation = to.bnfcrNm
            this.selectedItemIndex.splice(0)
            this.getAllList()
            console.log('Url Event from: ' + from.bnfcrNm)
        },
        'entChgBox': function () {
                this.exBtn[0].expandList.splice(0)

                if(this.entChgBox == 1){
                    this.exBtn[0].expandList.push('퇴소')
                    this.exBtn[0].mode = 'single'
                    this.sltElmnt = '퇴소'
                    this.filterSearchD1(['퇴소'])
                    this.stCd = '4'
                    this.getAllList()
                }

                else{
                    this.cdItems.forEach((code) => {
                        if(code.valcdnm != '퇴소')
                            this.exBtn[0].expandList.push(code.valcdnm)
                    });
                    this.exBtn[0].mode = ''
                    this.sltElmnt = ''
                    this.filterSearchD1([''])
                    this.stCd = null
                    this.getAllList()
                }

                this.selectedItemIndex.splice(0)
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '', entDt: '', lvDt: ''
                });
                
                let selObj = {
                    bnfcrExamHisPk:0,
                    bnftOfrPlnHisPk:0,
                    phyThrPlnPk:0,
                    year: this.year,
                }

                this.$refs.physcThrpyInspcPln.onShow(selObj)
            },
    },

    methods: {
        getYear: function (yyyy){
            this.year = yyyy
            this.getAllList()
        },
        getAllList: function (){
            getAllPhyThrList(this.$store.getters.getFcltyNum, this.year, this.stCd)
                .then((response) => ( this.tableLoading(response.data) ))
                .catch((error) => this.tableText = error.message)
        },
        tableLoading: function (res){
            this.selectedItemIndex = []
            this.items = res
            this.tableLoad = false

            this.items.forEach((item) => {
                item.examDt = item.examDt.beforeDateFormatDot()
                item.wrtDt = item.wrtDt.beforeDateFormatDot()
            });

            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                let tttt = 0
                this.items.forEach((pk, i) => {
                    if(pk.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr){
                        tttt = pk.rn
                        this.rowClicked({rn: i+1})
                    }
                });
                this.selectedItemIndex.push(tttt)
            }

        },    
        stCdMapping: function (res){
            this.cdItems = res

            this.cdItems.forEach((code) => {
        
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

        },    
        rowClicked: function (row) {    
            this.toggleSelection(row.rn);
        },
        toggleSelection: function (keyID) {
            let selObj = {
                bnfcrExamHisPk:0,
                bnftOfrPlnHisPk:0,
                phyThrPlnPk:0,
                bnfcrPk:0,
                year: this.year,
            }

            if (this.selectedItemIndex.includes(keyID)) {

                this.selectedItemIndex.splice(0)

                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '', entDt: '', lvDt: ''
                });

                this.$refs.physcThrpyInspcPln.onShow(selObj)
            } 
            else {
                this.selectedItemIndex.splice(0)
                this.selectedItemIndex.push(keyID)

                this.$store.commit('setBnfcrState', {
                    bnmmbr: this.items[this.selectedItemIndex[0]-1].bnMmbrPk,
                    bnfcr: this.items[this.selectedItemIndex[0]-1].bnfcrPk,
                    name: '',
                    entStcd: '',
                    entDt: '',
                    lvDt: ''
                });
                
                selObj.bnfcrExamHisPk   = this.items[this.selectedItemIndex[0]-1].bnfcrExamHisPk
                selObj.bnftOfrPlnHisPk  = this.items[this.selectedItemIndex[0]-1].bnftOfrPlnHisPk
                selObj.phyThrPlnPk      = this.items[this.selectedItemIndex[0]-1].phyThrPlnPk
                selObj.bnfcrPk          = this.items[this.selectedItemIndex[0]-1].bnfcrPk

                this.$refs.physcThrpyInspcPln.onShow(selObj)
            }
            
        },
        expandClose: function (index) {

            switch(index) {
                case 0: this.$refs.exBtnClose1.isClose()
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    break;
            }

        },
        clearItem: function (text) {
            if(text == 'clear'){
                this.selectedItemIndex.splice(0)

                this.$store.commit('setEntStcd', '');
            }
            else if(text == 'refresh'){
                this.selectedItemIndex.splice(0)
                this.items = []

                this.getBnfcrList()

                this.refresh = 1                
            }
        },
        filterSearchD1: function (sch) {
            
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD2: function (sch) {
            
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.bnfcrNm.includes(this.schField)
            //console.log('text : ' + this.schField)
            

            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((lvnRm, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == lvnRm
                    else col2 = col2 || filter.lvnRmNm == lvnRm
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            return col0 && col1 && col2;
        },
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },

        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },
    },

    data: () => ({
        stCd:null,
        entChgBox: 2,
        year: 0,
        exBtn: [
            {
                index:  0,
                btnTitle: '입소현황',
                btnIcon: 'house',
                exWidth: 104,
                exHeigth: 130,
                expandList: [],
            },
            {
                index:  1,
                btnTitle: '생활실',
                btnIcon: 'bed',
                exWidth: 124,
                exHeigth: 350,
                expandList: [],
            },
        ],
        headers: [                        
            { text: '연번', value: 'rn', sortable: true, width: '80', class: 'black--text bottomBorder fixedPstn0 bnfcrMidIndex'},
            { text: '수급자', value: 'bnfcrNm', sortable: true, width: '90', class: 'black--text bottomBorder fixedPstn1 bnfcrMidIndex'},
            { text: '최종평가일', value: 'lstExamDt', sortable: true, width: '120', class: 'black--text bottomBorder'},
            { text: '평가일', value: 'examDt', sortable: true, width: '90', class: 'black--text bottomBorder'},
            { text: '계획일', value: 'wrtDt', sortable: true, width: '90', class: 'black--text bottomBorder'},
            { text: '현황', value: 'entStcd', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '나이', value: 'age', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '90', class: 'black--text bottomBorder'},
        ],
        items: [],
        cdItems: [],
        selectedItemIndex:[],
        forceRender: 0,
        schField: '',
        schChkField1: [],
        schChkField2: [],
        schDummy: '',
        tableLoad: true,
        tableText: 'Loading...    Please Wait...',
    }),
};
</script>

<style>
    .v-input { font-size: 14px; }
    .v-input__icon { height: 28px; }
    .v-input__control { width: 60px; }
    .v-input__append-inner { margin-top: 5px !important; }
    .v-text-field__slot label{ top: 5px !important; position: absolute; }
</style>