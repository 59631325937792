<template>
    <v-sheet class="rounded-lg rounded-l-0">  
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex mt-2 align-center">
            <span class="d-inline-block mr-4" style="width: 105px;">
                <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify" hide-details width="100px" height="35px"
                    single-line rounded outlined dense filled label="이름조회" @input="setSearchText" >
                </v-text-field>
            </span>
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
        </div>      
        <div class="btcinq_linkRcrd pa-0 mt-2">
            <v-data-table
                class="" locale="ko" height="660" item-key="rn"                    
                :headers="linkRcrdHeader" 
                :items="linkRcrdList"
                :loading="loading"
                :search="schDummy" :custom-filter="customFilter"
                hide-default-header 
                fixed-header 
                disable-pagination 
                hide-default-footer dense>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <!-- <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn0" style="width:60px !important;z-index:4;" rowspan='2'>
                                <span class="d-inline-block ml-1" style="width: 26px;">
                                    <v-checkbox
                                        class="checkIcon" v-model="allChckr" :key="forceRender" @click="allChnger"
                                        true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        hide-details>
                                    </v-checkbox>
                                </span>
                            </th> -->
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn1" style="width:60px !important;z-index:4;" rowspan='2'>{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn2" style="width:100px !important;z-index:4;" rowspan="2">{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn3" style="width:70px !important;z-index:4;" rowspan="2">{{props.headers[3].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn4" style="width:120px !important;z-index:4;" rowspan="2">{{props.headers[4].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT fixedPstn5" style="width:120px !important;z-index:4;" rowspan="2">{{props.headers[5].text}}</th>                                
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="" colspan="4">{{props.headers[6].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="" colspan='6'>{{props.headers[7].text}}</th>                            
                        </tr>
                        <tr>                                
                            <th class="black--text clmsHeaderCommon tableBR" style="width:100px !important;">{{props.headers[8].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:120px !important;">{{props.headers[9].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:100px !important;">{{props.headers[10].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:70px !important;">{{props.headers[11].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:120px !important;">{{props.headers[12].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:100px !important;">{{props.headers[13].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:100px !important;">{{props.headers[14].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:100px !important;">{{props.headers[15].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR" style="width:150px !important;">{{props.headers[16].text}}</th>
                            <th class="black--text clmsHeaderCommon" style="width:200px">{{props.headers[17].text}}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <!-- <td class="tableContentCommon fixedTable fixedPstn0 tableBR" style="z-index:3;">
                            <span class="d-inline-block" style="width: 26px;">
                                <v-checkbox
                                    class="checkIcon" v-model="item.checkr" :key="forceRender" :disabled="item.disChekr" @change="allChekrFunc"
                                    true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                    hide-details>
                                </v-checkbox>
                            </span>
                        </td> -->
                        <td class="tableContentCommon fixedTable fixedPstn1 tableBR" style="z-index:3;">{{item.rn}}</td>
                        <td class="tableContentCommon fixedTable fixedPstn2 tableBR" style="z-index:3;">{{item.name}}</td>
                        <td class="tableContentCommon fixedTable fixedPstn3 tableBR" style="z-index:3;">
                            <div>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg" width="24"></v-img>
                                <v-img class="d-inline-block" style="vertical-align: middle;" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg" width="24"></v-img>
                            </div>
                        </td>
                        <td class="tableContentCommon fixedTable fixedPstn4 tableBR" style="z-index:3;">{{item.birth}}</td>
                        <td class="tableContentCommon fixedTable fixedPstn5 tableBR" style="z-index:3;">{{item.lvDt}}</td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.lvRsn}}</td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.wrtDt}}</td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.wrtr}}</td>
                        <td class="tableContentCommon fixedTable tableBR">
                            <span>
                                <v-btn
                                    color="grey006" height="26"                                    
                                    :disabled="item.linkRcrdPk == null"
                                    @click="onExtLink(item)"
                                    icon>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                <v-icon size="20">mdi-square-edit-outline </v-icon>
                                            </div>
                                        </template>
                                        <span>{{item.name}} 연계기록지 상세</span>
                                    </v-tooltip>
                                </v-btn>
                            </span> 
                        </td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.sndDt}}</td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.sndMdaNm}}</td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.sndNm}}</td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.rcvNm}}</td>
                        <td class="tableContentCommon fixedTable tableBR">{{item.rcvInfm}}</td>
                        <td class="tableContentCommon fixedTable">{{item.rcvCelphn}}</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="900">    
                <btnModalExtLink     
                    v-model="dialog[0]"    
                    :typ="'rcrd'"
                    @madalState="onModal(0)" @midLoad="bnfcrListReLoad"
                    @onMsg="onInfMsg" @onSndMsg="sendLinkMsgAct" @onRcrdPrt="rcrdPrt">
                </btnModalExtLink>
            </v-dialog>
        </div>
        <v-dialog v-model="dialog[1]" max-width="1100" persistent>    
            <bizMsgMdl 
                ref="bizMsgMdl"
                :bizMsgData="bizMsgInfo.mmbrPk"
                :bizMsgDocClcd="bizMsgInfo.docClcd"
                :bizMsgBzPk="bizMsgInfo.bzPk"
                :bizMsgShow="dialog[1]"
                :bizMsgTitle="bizMsgInfo.bzTitle"
                :bizMsgCntnt="bizMsgInfo.bzCntnt"
                @madalState="dialog.splice(1, 1, false)">
            </bizMsgMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import btnModalExtLink from '../../components/bnfcr/BnfcrExtLinkRec.vue';
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';
import { selBtcLinkRcrdList } from '../../api/bnfcr.js';
import { snglHdrXlsxPrdc } from '../../api/global/cmmExcelJS.js';

export default {
    name: 'BnfcrSttsTab3',

    components: {
        expandYearOnlyPicker,
        btnModalExtLink,
        bizMsgMdl,
    },

    created: function () {
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {
        getYear:function(yyyy){
            this.year = yyyy
            this.getBtcLinkRcrdList()
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.linkRcrdList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.linkRcrdList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        allChekrFunc: function () {
            let tCh = '1'
            this.linkRcrdList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },
        getBtcLinkRcrdList:function(){
            this.linkRcrdList.splice(0)
            this.loading = true
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                inqYYYY     : this.year,
                valClcdS    : ['63','65','109'],
            }
            selBtcLinkRcrdList(obj)
                .then((response) => ( this.getBtcLinkRcrdListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selBtcLinkRcrdList : ' + error))
        },
        getBtcLinkRcrdListAfter:function(res){
            
            if(res.statusCode == 200){           
                if(res.result.linkRcrdList != null){
                    res.result.linkRcrdList.forEach(el => {         
                        let obj = {
                            rn          : el.rn,
                            checkr      : '2',
                            disChekr    : false,
                            name        : el.name,
                            gndr        : el.gndr,
                            gndrNm      : (el.gndr == 'F')?'여':'남',
                            birth       : this.$moment(el.birth,'YYYYMMDD').format('YYYY.MM.DD'),
                            lvDt        : this.$moment(el.birth,'YYYYMMDD').format('YYYY.MM.DD'),
                            lvRsn       : el.lvRsn,
                            wrtDt       : (el.wrtDt !=null && el.wrtDt !='')?this.$moment(el.wrtDt,'YYYYMMDD').format('YYYY.MM.DD'):'',
                            wrtr        : el.wrtr,
                            sndDt       : (el.sndDt !=null && el.sndDt !='')?this.$moment(el.sndDt,'YYYYMMDD').format('YYYY.MM.DD'):'',
                            sndMdaNm    : el.sndMdaNm,
                            sndNm       : (el.sndNm != null && el.sndNm != '')?el.sndNm:el.wrtr,
                            rcvNm       : el.rcvNm,
                            rcvInfm     : el.rcvInfm,
                            rcvCelphn   : el.rcvCelphn,
                            linkRcrdPk  : el.linkRcrdPk,
                            bnMmbrPk    : el.bnMmbrPk,
                            bnfcrPk     : el.bnfcrPk,
                        }

                        this.linkRcrdList.push(obj)
                    });

                }
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.loading = false

            if(this.linkRcrdList.length > 0){
                this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = false
                this.$parent.$parent.$parent.$parent.outlinedBtn[1].disabled = false
            }else{
                this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = true
                this.$parent.$parent.$parent.$parent.outlinedBtn[1].disabled = true
            }
        },        
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'

            this.allChckr = '2'

            this.linkRcrdList.forEach(item => {
                if(item.disChekr == false) item.checkr = '2'
            });
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            
            return col0;
        },        
        xlsxProd: function (){
            let shetNm = this.year+'년_전체연계기록지목록'
            let title = shetNm + '_' + this.$store.getters.getFcltyNum + '_' + this.$moment().format('YYYYMMDD') 

            // 엑셀 생성 및 다운로드 ( 컬럼리스트, 데이터, 시트명, 파일명 )
            snglHdrXlsxPrdc(this.exlHeader, this.linkRcrdList, shetNm, title)
            this.exlBtn = true
        },
        onExtLink:function(obj){
            this.$store.commit('setBnfcrState', {
                bnmmbr: obj.bnMmbrPk,
                bnfcr: obj.bnfcrPk,
                name: obj.name,
                entStcd: '',
                entDt: '',
                lvDt: ''
            });

            this.dialog.splice(0, 1, true)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onModal:function(key){
            this.$store.commit('setBnfcrState', {
                bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
            });
            this.dialog.splice(key, 1, false)
            this.getBtcLinkRcrdList()
        },
        bnfcrListReLoad:function(value){
            if(value == 'trigger')
                this.getBtcLinkRcrdList()
        },
        onInfMsg: function (txt){
            this.snackControll([true, 2500, txt, 'info'])
        },
        // 연계기록지 발송 팝업
        sendLinkMsgAct: function (lrpk, dt){
            let tmp = this.$moment(dt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bizMsgInfo.mmbrPk.splice(0)
            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = tmp + ' 연계기록지'
            this.bizMsgInfo.bzPk = lrpk
            this.bizMsgInfo.bizMsgVal1 = tmp

            this.bizMsgInfo.bzTitle = tmp + ' 연계기록지'
            this.bizMsgInfo.docClcd = 13
            
            this.dialog.splice(1, 1, true)
        },        
        rcrdPrt: function (lrPk){
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYYY = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.push(lrPk)
            this.$parent.$parent.$parent.$parent.prtThrowObj.prtCd = '3'
            this.$parent.$parent.$parent.$parent.rprtExtLink()
        },
        prtLinkRcrd :function(){
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYYY = this.year
            this.$parent.$parent.$parent.$parent.prtThrowObj.prtCd = '55'
            this.$parent.$parent.$parent.$parent.rprtExtLink()
        }
    },

    data: () => ({
        loading:false,
        forceRender:0,
        year:'',
        exBtn: [           
            {
                index:  1,
                btnTitle: '생활실',
                btnIcon: 'bed',
                exWidth: 124,
                exHeigth: 350,
                expandList: [],
            },
        ],
        allChckr:'2',
        schField:'',
        schDummy:'',
        linkRcrdHeader: [
            { text: '전체', value: '', sortable: false,},
            { text: '연번', value: '', sortable: false,},
            { text: '수급자', value: '', sortable: false,},
            { text: '성별', value: '', sortable: false,},
            { text: '생년월일', value: '', sortable: false,},
            { text: '퇴소일', value: '', sortable: false,},            
            { text: '연계기록지 정보', value: '', sortable: false,},
            { text: '연계기록지 발송 정보', value: '', sortable: false,},
            { text: '연계사유', value: '', sortable: false,},
            { text: '작성일', value: '', sortable: false,},
            { text: '작성자', value: '', sortable: false,},
            { text: '상세', value: '', sortable: false,},
            { text: '제공일자', value: '', sortable: false,},
            { text: '제공방법', value: '', sortable: false,},
            { text: '제공자', value: '', sortable: false,},
            { text: '수령자', value: '', sortable: false,},
            { text: '관계', value: '', sortable: false,},
            { text: '연락처', value: '', sortable: false,},
        ],
        linkRcrdList:[],  
        exlHeader: [
            { header: '연번', key: 'rn', width: 8, }, 
            { header: '수급자', key: 'name', width: 12, },
            { header: '성별', key: 'gndrNm', width: 10, }, 
            { header: '생년월일', key: 'birth', width: 16, },
            { header: '퇴소일', key: 'lvDt', width: 16, },
            { header: '연계사유', key: 'lvRsn', width: 20, }, 
            { header: '작성일', key: 'wrtDt', width: 16, },
            { header: '작성자', key: 'wrtr', width: 12, }, 
            { header: '제공일자', key: 'sndDt', width: 12, },
            { header: '제공방법', key: 'sndMdaNm', width: 12, }, 
            { header: '제공자', key: 'sndNm', width: 12, },
            { header: '수령자', key: 'rcvNm', width: 12, }, 
            { header: '관계', key: 'rcvInfm', width: 20, },
            { header: '연락처', key: 'rcvCelphn', width: 16, }, 
        ],
        snackOptions: [ false, 3000, '', 'info' ],
        dialog:[false,false],
        bizMsgInfo: {
            mmbrPk: [], docClcd: 0, bzPk: 0, bzTitle: '', bzCntnt: '', bizMsgVal1: '',
        },
    }),
};
</script>
<style>
.btcinq_linkRcrd .v-data-table__wrapper table{
    width:1680px;
}
.btcinq_linkRcrd .v-data-table--fixed-header{
    position: sticky !important; position: -webkit-sticky !important; 
    z-index: 1; left: 0px;
}

.btcinq_linkRcrd .fixedTable {
    position: sticky !important; position: -webkit-sticky !important; 
    z-index: 1; font-size: 0.975rem !important;
}

.btcinq_linkRcrd .fixedPstn0 { left: 0px;}
.btcinq_linkRcrd .fixedPstn1 { left: 0px; }
.btcinq_linkRcrd .fixedPstn2 { left: 62px; }
.btcinq_linkRcrd .fixedPstn3 { left: 165px; }
.btcinq_linkRcrd .fixedPstn4 { left: 237px; }
.btcinq_linkRcrd .fixedPstn5 { left: 361px; }
</style>