<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">7-6.&nbsp;처우개선&#40;고충처리,포상&#41;</span>
            </v-col>
            <v-col class="d-flex pr-2" cols="7">
                <v-spacer></v-spacer>
                <!-- btn area -->
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue'"
                        v-model="dialog[i]" :max-width="list.width" content-class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                            <div v-else>개발중</div>
                    </v-dialog>
                </span>
                <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                    <v-dialog
                        v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <div>개발중</div>  
                    </v-dialog>
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-5" no-gutters>
            <v-col cols="12">
                <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-3 mx-3">
            <v-data-table hide-default-footer dense locale="ko" height="700"
                hide-default-header fixed-header 
                :headers="grvncHndlnHeader" 
                :items="grvncHndlnList" 
                class="" disable-pagination>

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>                            
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;" colspan="1" rowspan='2'>{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[3].text}}</th>                            
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[4].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 170px !important;" colspan="1" rowspan='2'>{{props.headers[5].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[6].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[7].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="" colspan="2" rowspan='1'>확인</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="width: 100px !important;" colspan="1" rowspan='2'>처리</th>
                        </tr>
                        <tr style="border-top: 1px solid #dbdbdb !important;">
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 100px !important;">{{props.headers[8].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 100px !important;">{{props.headers[9].text}}</th>
                        </tr>
                    </thead>
                </template>     
                <template v-slot:item='{ item }'>
                    <tr>                        
                        <td class="clmsBodyCommon tableBR">{{item.rgsNum}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.rgsDt?.beforeDateFormatDot()}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.aplcnNm}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.afltnNm}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.cmplnClcdNm}}</td>
                        <td class="clmsBodyCommon tableBR">
                            <div style="width:155px" class="text-overflow">{{item.cmplnCntnt}}</div></td>
                        <td class="clmsBodyCommon tableBR">
                             <div style="width:155px" class="text-overflow">{{item.prcsRst}}</div></td>
                        <td class="clmsBodyCommon tableBR">{{item.prcsDt?.beforeDateFormatDot()}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.prcsrNm}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.ldrNm}}</td>
                        <td class="clmsBodyCommon">
                            <div class="d-flex justify-center align-center">   
                                <v-btn icon @click="onShowGrvnc(item.cmplnRecPk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>                             
                                <v-btn icon color='grey006' @click="onDelRow(item.cmplnRecPk)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>                                
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-row>        
        <div class="pa-0 ma-0">
            <v-dialog v-model="modal[0]" persistent max-width="1000" min-width="1000">                
                <grvncHndlnWrt 
                    v-if="modal[0]"
                    ref="grvncHndlnWrt"
                    :sltPk = "sltPk"
                    @modalEvent="modalEvent"></grvncHndlnWrt>
            </v-dialog>
        </div> 
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete title="고충처리관리" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import grvncHndlnWrt from '../../components/emp/GrvncHndlnWrt.vue'; //고충처리
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { selCmplnRecList, delCmplnRec } from '../../api/emp.js';


export default {
    name: 'GrvncHndlnMgmt',
        
    components: {
        expandYearOnlyPicker,
        grvncHndlnWrt,
        btnModalDelete,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.sltPk = 0
                    this.modal.splice(0,1,true)
                    break;                        
                default:
                    break;
            }
        },           
        getYear: function (yyyy){
            this.year = yyyy
            this.getCmplnRecList()
        },
        getCmplnRecList:function(){   
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                inqYYYY: this.year,
                valClcdS : ['106'],
            }     
            selCmplnRecList(obj)
                .then((response) => ( this.getCmplnRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selCmplnRecList : ' + error))
        },
        getCmplnRecListAfter:function(res){            
            this.grvncHndlnList.splice(0)

            if(res.statusCode == 200){      
                if(res.result.code != null && res.result.code != undefined){
                    this.vCd106 = res.result.code.vCd106
                }

                if(res.result.list != null && res.result.list != undefined){
                    res.result.list.forEach(item => {

                        this.vCd106.forEach((cd) => {
                            if(item.cmplnClcd == cd.valcd) item.cmplnClcdNm = cd.valcdnm
                        });

                        this.grvncHndlnList.push(item)
                    });
                }
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },        
        modalEvent: function (event, msg=null) { 
            if(event == 99){
                this.getCmplnRecList()
            }else{
                this.modal.splice(event, 1, false)
                if(msg != null){
                    this.snackControll([true, 2500, msg, 'info'])
                }
            }      
        },
        onDelRow:function(pk){
            this.sltPk = pk
            this.dialogDelete = true
        },
        onDelete:function(res){            
            if(res == 9990){
                if(this.sltPk > 0){
                    let obj = {
                        fcltyNum : this.$store.getters.getFcltyNum,
                        userNm : this.$store.getters.getUserNm,
                        cmplnRecPk :this.sltPk,
                    }
                    delCmplnRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data) ))
                        .catch((error) => console.log('connect error /emp/delCmplnRec : ' + error))
                }else{
                    this.snackControll([true, 3500, '삭제 할 대상을 선택해주세요.', 'warning'])
                    this.dialogDelete = false
                }
            }else if(res == 7){
                this.dialogDelete = false
                 this.sltPk = 0
            }
        },
        onDeleteAfter:function(res){
            this.dialogDelete = false
            this.sltPk = 0

            if(res.statusCode == 200){      
                 this.snackControll([true, 2500, res.message, 'info'])
                 this.getCmplnRecList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },  
        onShowGrvnc:function(pk){    
            this.sltPk = pk        
            this.modal.splice(0,1,true)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        sltPk:0,
        year:'',
        vCd106: [],      //직원 직종 코드
        dialog:[],
        modal:[false],
        schField: '',
        schDummy: '',
        schChkField: { wrkStcd: [], jobCd: [], target: 'wrkStcd' },
        grvncHndlnHeader:[
            { text: '접수번호', value: '', align: 'center',},
            { text: '접수일자', value: '', align: 'center',},
            { text: '신청인', value: '', align: 'center',},
            { text: '소속', value: '', align: 'center',},
            { text: '구분', value: '', align: 'center',},
            { text: '내용', value: '', align: 'center'},
            { text: '처리결과', value: '', align: 'center',},
            { text: '회신일자', value: '', align: 'center',},
            { text: '상담원', value: '', align: 'center',},
            { text: '팀장', value: '', align: 'center',},
        ],
        grvncHndlnList:[],
        exBtn: [
            { index:  0, btnTitle: '상태', btnIcon: 'house', exWidth: 104, exHeigth: 100, expandList: [], },
            { index:  1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', width:'800px', disabled: false, },                        
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '고충처리대장 출력', width:'1000px', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
    }),
};
</script>