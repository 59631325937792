<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">프로그램&nbsp;권한&nbsp;선택</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <v-row class="greyE01 ma-0 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                <span class="black--text font-weight-medium">그룹명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="8">
                <span class="black--text font-weight-medium">그룹 설명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="1">
                <span class="black--text font-weight-medium">&nbsp;</span>
            </v-col>
        </v-row>
        <div>
            <v-row class="ma-0 pa-0" v-for="(cd, c) in cmnAuthGrpList" :key="c"
                style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="3">
                    <span class="black--text">{{ cd.authNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 tableBR" cols="8">
                    <span class="black--text">{{ cd.authDesc }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pt-1 pb-2" cols="1">
                    <span>
                        <v-btn
                            class="white--text darken-1" style="padding: 1px 0px 0px 2px !important"
                            color="bckgrColor" min-width="30" height="25"
                            @click="onAction(c)" small>
                                <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { selCmnAuthGrpList } from '../../api/cmm.js';

export default {
    name: 'CmnAuthGrpMdl',

    props : {
       
    },
            
    components: {
        
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCmnAuthGrpList()
        })
    },

    computed: {

    },

    watch: {

    },
        
    methods: {
        getCmnAuthGrpList: function () {
            selCmnAuthGrpList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getCmnAuthGrpListAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnAuthGrpList : ' + error))
        },
        getCmnAuthGrpListAfter: function (res) {
            this.cmnAuthGrpList = res
        },
        onAction: function (index) {
            this.$emit('prgmSlt', this.cmnAuthGrpList[index])
            this.onClose()
        },
        onClose: function () {
            this.$emit('modalClose', '5')
        },
    },
    
    data: () => ({
        cmnAuthGrpList: [],
    }),
};
</script>