<template>
    <div class="d-inline-block d-flex">
        <v-sheet class="d-inline-block" width="460" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>2-6. 사례관리</span>
                </span>
            </div>
            <div class="mt-2">
                <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            </div>
            <div class="mt-5 ml-3 mr-2">
                <!-- <v-data-table 
                    hide-default-footer 
                    dense locale="ko"                     
                    height="700"
                    class="rounded-b-0" 
                    disable-pagination
                    hide-default-header
                    :headers="headers" :items="items">

                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th class="px-2 black--text clmsHeaderCommon tableBR" style="width:18%;">{{props.headers[0].text}}</th>
                                <th class="px-2 black--text clmsHeaderCommon tableBR" style="width:22%;">{{props.headers[1].text}}</th>
                                <th class="px-2 black--text clmsHeaderCommon tableBR" style="width:22%;">{{props.headers[2].text}}</th>
                                <th class="px-2 black--text clmsHeaderCommon">
                                    <div>급여제공 반영</div>
                                    <div>&#40;반영자수/대상자수&#41;</div>
                                </th>
                            </tr>
                        </thead>
                    </template> 
                    <template v-slot:item='{ item, index }'>    
                        <tr class="fixedCol" :class="['fixedCol', selectedItem == index ? 'sltRow' : 'disSltRow']" @click="rowClicked(index)">
                            <td class="fixedCol0 clmsBodyCommon tableBR">{{item.monthNm}}</td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">{{item.mtngDt}}</td>
                            <td class="fixedCol0 clmsBodyCommon tableBR">{{item.bnfcrNm}}</td>
                            <td class="fixedCol0 clmsBodyCommon">{{item.bnftCnt}}</td>
                        </tr>

                    </template>
                </v-data-table> -->
                <v-row :class="['white rounded rounded-b-0', caseMtngList.length > 17 ? 'mr-2__5' : '']" no-gutters>
                    <v-col class="py-5" style="text-align: center;" cols="2">
                        <span class="black--text font-weight-medium">회의월도</span>
                    </v-col>
                    <v-col class="py-5" style="text-align: center;" cols="3">
                        <span class="black--text font-weight-medium">회의일</span>
                    </v-col>
                    <v-col class="py-5" style="text-align: center; vertical-align: middle;" cols="3">
                        <span class="black--text font-weight-medium">수급자</span>
                    </v-col>
                    <v-col class="py-2" style="text-align: center;" cols="4">
                        <div class="black--text font-weight-medium">급여제공 반영</div>
                        <div class="black--text font-weight-medium">&#40;반영자수/대상자수&#41;</div>
                    </v-col>
                    
                </v-row>
                <v-divider color="black"></v-divider>
                <div class="white rounded rounded-t-0 pb-2 overflow-y-auto" style="height:660px;">
                    <v-row :class="['fixedCol', selectedItem == i ? 'sltRow' : 'disSltRow']"
                        style="border-bottom: 1px solid #dbdbdb !important;"
                        v-for="(list, i) in caseMtngList" :key="i" @click="rowClicked(i)" no-gutters>
                        <v-col class="fixedCol0 py-2" style="text-align: center;" cols="2">
                            <span class="black--text">{{list.monthNm}}</span>
                        </v-col>
                        <v-col class="fixedCol0 py-2" style="text-align: center;" cols="3">
                            <span class="black--text">{{list.mtngDt}}</span>
                        </v-col>
                        <v-col class="fixedCol0 py-2" style="text-align: center; vertical-align: middle;" cols="3">
                            <span class="black--text">{{list.bnfcrNm}}</span>
                        </v-col>
                        <v-col class="fixedCol0 py-2" style="text-align: center;" cols="4">
                            <span class="black--text">{{list.bnftCnt}}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="caseMtngList?.length == 0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="py-2" style="text-align: center;" cols="12">
                            <span class="grey006--text" style="font-size: 0.925rem;">&#8251; 목록이 존재하지 않습니다.</span>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-sheet>
        <v-sheet class="d-inline-block rounded-lg rounded-l-0 overflow-auto pa-2" min-width="760px" max-width="1060px">
            <router-view ref="callDetail" :caseMgmtPk="caseMgmtPk" :year="year" @setSelected="setSelectedItem"></router-view>
        </v-sheet>
    </div>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import { selMtngCaseList } from '../../api/bnftSrv.js';

export default {
    name: 'CaseMtng',
    
    components: {
        expandYearOnlyPicker
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.gelMtngCaseList()
        })
    },

    watch: {
        'year': function(){
            this.mtngRcrdPk = 0
            this.selectedItem = -1
            this.gelMtngCaseList()
        },
    },

    methods: {
        getYear: function (yyyy){
            this.year = yyyy
        },
        gelMtngCaseList: function () {
            selMtngCaseList(this.$store.getters.getFcltyNum, this.year)
                .then((response) => ( this.gelMtngCaseListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        gelMtngCaseListAfter:function(res){
            this.caseMtngList.splice(0)
            res.forEach((element,index) => {
                element.mtngDt = element.mtngDt?.beforeDateFormatDot()                
                if(element.mtngRcrdPk == this.caseMgmtPk){
                    this.selectedItem = index 
                }
                this.caseMtngList.push(element)
            })
        },
        rowClicked: function (index) {
            this.toggleSelection(index);
        },
        toggleSelection: function (keyID) {
            if (this.selectedItem == keyID) {
                this.selectedItem = -1
                this.caseMgmtPk=0
            } else {
                if(this.caseMtngList[keyID].mtngRcrdPk != null){
                    this.selectedItem = keyID
                    this.caseMgmtPk = this.caseMtngList[keyID].mtngRcrdPk
                }
            }
            
        },
        setSelectedItem:function(pk){
            this.selectedItem = -1           
            this.caseMgmtPk = pk
            this.gelMtngCaseList()
        }
    },

    data: () => ({
        year:1990,
        tableText: 'Loading...    Please Wait...',
        tableLoad: true,
        selectedItem: -1,
        mtngRcrdPk:0,
        caseMgmtPk:-1,
        headers: [            
            { text: '회의월도', value: 'monthNm',align: 'center', sortable: true, width: '102', class: 'black--text bottomBorder'},
            { text: '회의일', value: 'mtngDt',align: 'center', sortable: true, width: '90', class: 'black--text bottomBorder'},
            { text: '수급자', value: 'bnfcrNm',align: 'center', sortable: true, width: '90', class: 'black--text bottomBorder'},
            { text: '급여제공 반영 (반영자수/대상자수)', value: 'bnftOfrDt',align: 'center', sortable: true, width: '102', class: 'black--text bottomBorder'},
        ],
        caseMtngList: [],
    }),
};
</script>