<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col cols="9">
                <span class="text-h4 font-weight-bold">{{ compTtl }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="2">
                <v-spacer></v-spacer>
                <v-icon color="black" @click="$emit('isClose', true)" x-large>mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-center align-center" cols="3">
                <v-icon color="#f8bb86" style="font-size: 130px;">mdi-alert-circle-outline</v-icon>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-center align-center" cols="7">
                <div class="py-1">
                    <div class="pb-2" v-for="(content, i) in compCntnt" :key="i">
                        <span
                            class="font-weight-medium"
                            style="font-size: 1.3rem; color: #595959;">
                            {{ content }}
                        </span>
                    </div>
                </div>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0 mt-5" no-gutters>
            <v-spacer></v-spacer>
            <span>
                <v-btn
                    class="ml-4 white--text"
                    style="font-size: 20px;"
                    height="40" color="blueBtnColor"
                    @click="$emit('isSave', true)"
                    large>
                    <span>확인</span>
                </v-btn>
            </span>
            <v-spacer></v-spacer>
        </v-row>
    </v-sheet>
</template>

<script>

export default {
    name: 'CmmCnfmMdl',

    props : {
        compTtl: { type: String, default: '' },
        compCntnt: { type: Array, default: () => { return [] } },
    },
        
    components: {

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        
        })
    },

    computed: {

    },

    watch: {
        
    },
    
    methods: {
    
    },

    data: () => ({

    }),
};
</script>