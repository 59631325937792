import axios from 'axios';

let prtcl = 'http'
let prt = 80
let dmn = 'apis.data.go.kr/'

if (process.env.NODE_ENV == 'production'){
    prtcl = 'https'
    prt = 443
}

const dataApis = axios.create({
    baseURL: prtcl + '://' + dmn + 'B090041/openapi/service/SpcdeInfoService/',
    timeout: 0,
    withCredentials: false,

    proxy: {
        protocol: prtcl,
        host: prtcl + '://' + dmn,
        port: prt,
        changeOrigin: true,
    },
})

dataApis.interceptors.request.use(
    (config) => {

        config.params.ServiceKey = 'c4l7LqGoCdMJWf68u5+TOVX3em1/3kPzCsdj+FQU/s3oChSwOUkhXanHe/f244s/w+8JrLyIXXAvzA3q7BYzgQ=='

        return config
    },
    (error) => {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error)
    }
);

export{
    dataApis
}