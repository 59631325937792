<template>
    <v-sheet class="weklyMenuTab1" >
        <expandWeekOnlyPicker :prtDate="prtDate" @nowWeek = "getWeek" class="my-1" :btnDisabled="btnDisabled" ></expandWeekOnlyPicker>
        <v-form ref="tabform" lazy-validation>
            <div class="d-flex pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:9%">                                
                    <span class="black--text font-weight-medium">구분</span>
                </div>
                <div v-for="(item, i) in dayitems" :key="i" class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:13%;border-left: 1px solid #dbdbdb !important;">                                
                    <span class="black--text font-weight-medium">{{item.date.beforeDateFormatDot()}}</span>
                    <span class="black--text font-weight-medium">&#40;{{item.day}}&#41;</span>
                </div>
            </div>
            <div class="d-flex pa-0 ma-0" no-gutters >
                <div class="greyE01" style="width:9%">   
                    <div class="d-flex justify-center align-center pa-1 ma-0 box-height" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <span class="black--text font-weight-medium">조식</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 box-height" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <span class="black--text font-weight-medium">중식</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 box-height" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <span class="black--text font-weight-medium">석식</span>
                    </div>
                    <div class="d-flex" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%">
                            <span class="black--text font-weight-medium">죽식</span>
                        </div>
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small" style="border-bottom: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">조</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small" style="border-bottom: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">중</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small">
                                <span class="black--text font-weight-medium">석</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%">
                            <span class="black--text font-weight-medium">간식</span>
                        </div>
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small" style="border-bottom: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">오전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small">
                                <span class="black--text font-weight-medium">오후</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(item, j) in dayitems" :key="j" style="width:13%;border-left: 1px solid #dbdbdb !important;">                                
                    <div class="d-flex justify-center align-center pa-1 ma-0 box-height" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <v-textarea v-model="item.gnrlBrkfs" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="5" outlined dense hide-details no-resize>
                        </v-textarea>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 box-height" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <v-textarea v-model="item.gnrlLunch" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="5" outlined dense hide-details no-resize>
                        </v-textarea>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 box-height" style="border-bottom: 1px solid #dbdbdb !important;">                             
                        <v-textarea v-model="item.gnrlDiner" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="5" outlined dense hide-details no-resize>
                        </v-textarea>
                    </div>
                    <div class="d-flex" style="border-bottom: 1px solid #dbdbdb !important;">                                                     
                        <div style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small" style="border-bottom: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.papBrkfs" color="blue" hide-details outlined dense style="font-size:0.9rem !important;"></v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small" style="border-bottom: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.papLunch" color="blue" hide-details outlined dense style="font-size:0.9rem !important;"></v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small">
                                <v-text-field v-model="item.papDiner" color="blue" hide-details outlined dense style="font-size:0.9rem !important;"></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="border-bottom: 1px solid #dbdbdb !important;"> 
                        <div style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small" style="border-bottom: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.snckAm" color="blue" hide-details outlined dense style="font-size:0.9rem !important;"></v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0 box-height-small">
                                <v-text-field v-model="item.snckPm" color="blue" hide-details outlined dense style="font-size:0.9rem !important;"></v-text-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-center mt-2 mb-1">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">주간식단표 비고 내용</span>
                <v-btn
                    class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 22)" icon>
                    <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                </v-btn>
            </div>
            <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="6">                                
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">                                
                    <span class="black--text font-weight-medium">원산지</span>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="6">                                                    
                    <v-textarea v-model="rmrks" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="3" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">                                
                    <v-textarea v-model="orgn" style="font-size:0.9rem !important;"
                        rows="3" outlined dense hide-details class="rmrksArea" no-resize>
                    </v-textarea>
                </v-col>                
            </v-row>
        </v-form>
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
        <!-- <v-text-field v-model="prtDate" color="blue" hide-details outlined dense style="font-size:0.9rem !important;"></v-text-field> -->
    </v-sheet>
</template>
<script>
import expandWeekOnlyPicker from '../commons/expandWeekOnlyPicker.vue';
import { selMenuList, insMenu, delMenu } from '../../api/bnftSrv.js';

export default {
    name: 'WeklyMenuTab1',

    props : {
        tab:Number,
    },
    components: {
        expandWeekOnlyPicker,
    },

    computed: {        
    },  

    watch:{
        'startDay':function(){
            this.onShow()
        }
    },

    created:function(){          
        // this.onShow()
    },

    mounted: function(){
        // this.onShow()  
    },
        
    methods: {   
        onShow(){
            this.btnDisabled = true
            this.setDays()            
            this.$refs.tabform.resetValidation();
        },
        setDays(){
            this.dayitems.splice(0)       
            this.rmrks = ''    
            this.orgn = ''
            for(let i=0;i<7;i++){
                let date = new Date(this.startDay.beforeDateFormatHyp());
                let arrDate = new Date(date.setDate(date.getDate()+i)).toISOString().split('T',2)
                let fdate = arrDate[0].afterDateFormatHyp()
                let obj = {
                    day:this.days[i],
                    date:fdate,
                    aplDt:'',
                    gnrlBrkfs:'',
                    gnrlDiner:'',
                    gnrlLunch:'',
                    menuPk:0,
                    orgn:'',
                    papBrkfs:'',
                    papDiner:'',
                    papLunch:'',
                    rmrks:'',
                    snckAm:'',
                    snckPm:'',
                }                
                this.dayitems.push(obj)
            }

            this.getMenuList()
        },
        getWeek : function(ws, we){
            this.startDay = ws
            this.endDay = we
        },
        getMenuList:function(){
            selMenuList(this.$store.getters.getFcltyNum, this.startDay, this.endDay)
                .then((response) => ( this.getMenuListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selMenuList : ' + error))
        },
        getMenuListAfter:function(res){
            this.dayitems.forEach(el => {
                res?.filter(x=>x?.aplDt === el.date).forEach(itm => {
                    el.aplDt = itm?.aplDt
                    el.gnrlBrkfs = itm?.gnrlBrkfs
                    el.gnrlDiner = itm?.gnrlDiner
                    el.gnrlLunch = itm?.gnrlLunch
                    el.menuPk = itm?.menuPk
                    el.orgn = itm?.orgn
                    el.rmrks = itm?.rmrks
                    el.papBrkfs = itm?.papBrkfs
                    el.papDiner = itm?.papDiner
                    el.papLunch = itm?.papLunch
                    el.snckAm = itm?.snckAm
                    el.snckPm = itm?.snckPm
                });
            });
            
            res?.forEach(itm => {
                if(itm != null){
                    this.rmrks = itm.rmrks
                    this.orgn = itm.orgn
                }
            })

            this.btnDisabled = false
        },       
        insMenu:function(){
            if(this.$refs.tabform.validate()){
                let list = []

                this.dayitems.forEach(itm=>{
                    let item = {
                        aplDt : itm.date,
                        gnrlBrkfs : itm.gnrlBrkfs,
                        gnrlDiner : itm.gnrlDiner,
                        gnrlLunch : itm.gnrlLunch,
                        menuPk : itm.menuPk,
                        papBrkfs : itm.papBrkfs,
                        papDiner : itm.papDiner,
                        papLunch : itm.papLunch,
                        snckAm : itm.snckAm,
                        snckPm : itm.snckPm,
                        rmrks : this.rmrks,
                        orgn : this.orgn,
                    }

                    list.push(item)
                })

                insMenu(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, list)
                    .then((response) => ( this.insMenuAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnftoffer/insMenu : ' + error))
            }
        },
        insMenuAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        delMenu:function(){
            
            let cnt = 0;

            this.dayitems.forEach(x=>{
                if(x.menuPk != undefined && x.menuPk != ''){
                    cnt++
                }
            })
            if(cnt > 0){
                delMenu(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, this.dayitems)
                    .then((response) => ( this.delMenuAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnftoffer/insMenu : ' + error))
            }else{
                this.snackControll([true, 5000, '삭제 대상이 없습니다.', 'error'])
            }

            
        },
        delMenuAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
            //this.snackOptions[0] = true
            //this.snackOptions[1] = 2500
            //this.snackOptions[2] = '신규 작성 모드'
            //info, success, warning, error
            //this.snackOptions[3] = 'info'
        },
    },
    
    data: () => ({ 
        btnDisabled:false,
        prtDate:'',
        data:[
            {day:'',
            date:'',
            aplDt:'',
            gnrlBrkfs:'',
            gnrlDiner:'',
            gnrlLunch:'',
            menuPk:0,
            orgn:'',
            papBrkfs:'',
            papDiner:'',
            papLunch:'',
            rmrks:'',
            snckAm:'',
            snckPm:'',}
        ],    
        startDay:0,      
        endDay:0,
        days:['월','화','수','목','금','토','일'],
        dayitems : [],
        items:[],
        rmrks:'',
        orgn:'',
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>
<style>
.weklyMenuTab1 .box-height {height:100px}
.weklyMenuTab1 .box-height .v-textarea textarea {max-width:98% !important;margin-bottom:6px !important;}
.weklyMenuTab1 .box-height-small {height:32px}
</style>