<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-6">
            <span class="text-h4 font-weight-bold">본인부담금&nbsp;입금내역&nbsp;수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="$parent.$parent.$parent.$parent.bnMmbrPk" ref="basicInfo"></basicInfo>
        <v-form ref="dpstMdfForm" lazy-validation>
            <v-row class="pa-0 mt-6" no-gutters>
                <v-icon style="font-size: 28px; width: 12px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold ml-2">입금내역&nbsp;수정</span>
            </v-row>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-1" cols="2">
                    <span class="black--text font-weight-medium">입금일</span>
                </v-col>
                <v-col class="tableBL tableBR" cols="4">
                    <span class="white d-inline-block py-1 pl-2" style="width: 147px;">
                        <CmmDateComp v-model="cpyClmInf.dpsDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01 py-1" cols="2">
                    <span class="black--text font-weight-medium">입금액</span>
                </v-col>
                <v-col class="d-flex align-center tableBL px-2" cols="4">
                    <span class="black--text fontOneRem">{{ cpyClmInf.dpsTot?.toLocaleString('ko-KR') }}&nbsp;원</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-1" cols="2">
                    <span class="black--text font-weight-medium">입금자명</span>
                </v-col>
                <v-col class="tableBL tableBR py-1 px-1" cols="4">
                    <v-text-field
                        v-model="cpyClmInf.dpsNm"
                        :rules="[rules.required]"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01 py-1" cols="2">
                    <span class="black--text font-weight-medium">입금방법</span>
                </v-col>
                <v-col class="tableBL py-1 px-1" cols="4">
                    <span class="d-inline-block" style="width: 60%;">
                        <v-select class="hsptlSelect"
                            v-model="cpyClmInf.dpsMthdCd" height="28"
                            item-text="valcdnm" item-value="valcd"
                            :items="$parent.$parent.$parent.$parent.$parent.$parent.vCd84"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-1" cols="2">
                    <span class="black--text font-weight-medium">입금&nbsp;비고</span>
                </v-col>
                <v-col class="pa-1 tableBL" cols="10">
                    <v-text-field
                        v-model="cpyClmInf.rmrks"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mb-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn class="white--text" color="blueBtnColor" height="30" @click="preCpyDpsHis()" samll rounded>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span style="font-size: 1rem;">저장</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import basicInfo from './BnfcrBasicInfo.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

import { updCopayDpsHis } from '../../api/bnfcr.js';

export default {
    name: 'CopayDpstMdf',

    props : {
        dpsPk: { type: Number, default: 0 },
    },
        
    components: {
        basicInfo,
        CmmDateComp,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getParentDate()
        })
    },

    computed: {

    },

    watch:{
        'dpsPk': function (){
            this.getParentDate()
        },
    },
    
    methods: {
        setCpyDpsHis: function (obj){
            updCopayDpsHis(obj)
                .then((response) => ( this.cpyDpsHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updCopayDpsHis : ' + error))
        },
        cpyDpsHisRst: function (res){
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.getCopayDpsList()
                this.$parent.$parent.$parent.$parent.sltDpsPk = -1
                this.clearForm()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preCpyDpsHis: function (){
            let obj = Object.assign({}, this.cpyClmInf)
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.dpsDt = this.$moment(obj.dpsDt, 'YYYY-MM-DD', true).format('YYYYMMDD')

            if(this.$refs.dpstMdfForm.validate())
                this.setCpyDpsHis(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
        },
        getParentDate: function () {
            if(this.dpsPk != 0){
                let slt = this.$parent.$parent.$parent.$parent.copayPrcList.findIndex( v => v.dpsPrcHisPk == this.dpsPk )
                if(slt > -1){
                    let obj = this.$parent.$parent.$parent.$parent.copayPrcList[slt]
                  
                    this.cpyClmInf.dpsPrcHisPk = obj.dpsPrcHisPk
                    this.cpyClmInf.dpsNm = obj.dpsNm
                    this.cpyClmInf.dpsTot = obj.dpsTot
                    this.cpyClmInf.rmrks = obj.rmrks
                    this.cpyClmInf.dpsDt = this.$moment(obj.dpsDt, 'YYYYMMDD', true).format('YYYY-MM-DD')
                    this.$parent.$parent.$parent.$parent.$parent.$parent.vCd84.forEach(cd => {
                        if( cd.valcdnm == obj.dpsMthdNm )
                            this.cpyClmInf.dpsMthdCd = cd.valcd
                    });
                }
            }
            
        },
        clearForm: function () {
            this.cpyClmInf.dpsPrcHisPk = 0
            this.cpyClmInf.dpsNm = ''
            this.cpyClmInf.dpsDt = ''
            this.cpyClmInf.dpsMthdCd = ''
            this.cpyClmInf.dpsTot = 0
            this.cpyClmInf.rmrks = ''

            this.$refs.dpstMdfForm.resetValidation()
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        cpyClmInf: {
            dpsPrcHisPk: 0, dpsNm: '', dpsDt: '', dpsMthdCd: '', dpsTot: 0, rmrks: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>