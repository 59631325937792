<template>
    <v-sheet>        
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <div class="d-flex justify-center align-center" v-if="progress">
                    <v-progress-circular            
                    :size="30"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                    <span class="pl-1">처리중...</span>
                </div>
                <span v-if="!progress">
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                            :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span style="font-size: 1rem;">{{list.text}}</span>
                        </v-btn>
                    </span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col cols="12">
                <v-data-table hide-default-footer dense locale="ko" height="570"
                    hide-default-header fixed-header 
                    :headers="alimSndHeader" 
                    :items="alimSndList" 
                    class="" disable-pagination>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr v-if="alimSndItmes.bizMsgDocClcd == 4">                            
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:4%;">
                                    <!-- <v-checkbox class="fmlyHeaderCheck" v-model="allChecker" @change="checkBoxMessage" hide-details></v-checkbox> -->
                                    <div class="d-flex justify-center align-center">
                                        <span class="d-inline-block ml-1" style="width:26px;">
                                            <v-checkbox
                                                class="checkIcon" v-model="allChecker" :key="forceRender" @click="checkBoxMessage"
                                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                    </div>
                                </th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8% !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 12% !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15% !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 20% !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15% !important;">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT" style="width: 7% !important;">{{props.headers[6].text}}</th>
                                <!-- <th class="black--text clmsHeaderCommon tableBT" style="width: 10% !important;">확인여부</th> -->
                            </tr>        
                            <tr v-else>                            
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:4%;">                                    
                                    <div class="d-flex justify-center align-center">
                                        <span class="d-inline-block ml-1" style="width:26px;">
                                            <v-checkbox
                                                class="checkIcon" v-model="allChecker" :key="forceRender" @click="checkBoxMessage"
                                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                    </div>
                                </th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8% !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 12% !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15% !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 27% !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15% !important;">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT" style="width: 8% !important;">{{props.headers[6].text}}</th>
                            </tr>                  
                        </thead>
                    </template>     
                    <template v-slot:item='{ item }'>
                        <tr>                        
                            <td class="clmsBodyCommon tableBR" style="padding-left:11px !important">
                                <!-- <v-checkbox 
                                    class="fmlyHeaderCheck" v-model="item.isSelected" hide-details @click="checkBoxIndex"></v-checkbox> -->
                                <div class="d-flex justify-center align-center">
                                    <span style="width:26px;">
                                        <v-checkbox
                                            class="checkIcon" v-model="item.isSelected" :key="forceRender" :disabled="item.disChekr"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" @change="checkBoxIndex"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </div>
                            </td>                        
                            <td class="clmsBodyCommon tableBR">{{item.rcvNm}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.rcvNmDesc}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.celphn_1}}&#45;{{item.celphn_2}}&#45;{{item.celphn_3}}</td>
                            <td class="clmsBodyCommon tableBR">{{alimSndItmes.bizMsgCntnt}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.sndSttsNm}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.sndDt?.beforeDateFormatDot()}}</td>
                            <td :class="['clmsBodyCommon', alimSndItmes.bizMsgDocClcd == 4 ? '' : '']">
                                <div class="d-flex justify-center align-center">   
                                    <v-btn icon @click="onModal(item)">
                                        <v-icon>mdi-text-box-outline</v-icon>
                                    </v-btn>                              
                                </div>
                            </td>
                            <!-- <td v-if="alimSndItmes.bizMsgDocClcd == 4" class="clmsBodyCommon">
                                <div class="d-flex justify-center align-center" v-if="item.cnfmYn == '1'">
                                    <span>완료</span>
                                    <v-btn
                                        color="blueBtnColor"
                                        min-width="30" height="26"
                                        small icon
                                        @click="onSign(item.mmbrPk)">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon style="padding-top: 1px;" v-bind="attrs" v-on="on" size="24">mdi-pencil-box-outline</v-icon>
                                            </template>
                                            <span>서명확인</span>
                                        </v-tooltip>
                                    </v-btn>
                                </div>                                
                            </td> -->
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- <v-dialog v-model="dialog[0]" max-width="333" content-class="round" eager>
            <BizMsgAlimTalkViw ref="bizMsgAlimTalkViw" @modalClose="isClose"></BizMsgAlimTalkViw>
        </v-dialog> -->
    </v-sheet>
</template>
<script>
import { selEDocRcvList, insBizMsgNoSnd } from '../../api/othr.js'

export default {
    name: 'BizMsgAlimTalk',

    props : {       
        
    },
            
    components: {        
    },

    computed: {
       
    },  

    watch:{        
    },

    created:function(){ 
        this.$nextTick(function () { 
            
        })
    },
    
    mounted: function(){
        
    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onAlimTalkSend()
                    break;
            
                default:
                    break;
            }
        },
        setData:function(setObj){            
            this.allChecker = '2'
            this.alimSndItmes = JSON.parse(JSON.stringify(setObj))  
            this.getEDocRcvList()      
        },
        getEDocRcvList:function(){ 

            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum, 
                eDocClcd:this.alimSndItmes.bizMsgDocClcd, 
                mmbrPks:this.alimSndItmes.bizMsgData, 
                bzPk:this.alimSndItmes.bizMsgBzPk, 
                bzClcd:this.alimSndItmes.bizMsgBzClcd, 
                sndMda:this.sndMda,
                msClcd:this.sndMda,
            }

            if(this.alimSndItmes.bizMsgData.length > 0){
                selEDocRcvList(obj)
                    .then((response) => ( this.getEDocRcvListAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/selEDocRcvList : ' + error))
            }else{                
                this.alimSndList.splice(0)
            }
        },
        getEDocRcvListAfter:function(res){
            this.alimSndList.splice(0)
            if(res != undefined && res != '') {
                res.forEach(el => {
                    el.isSelected = '2' 
                    el.disChekr = false
                    // if(el.sndSttsCd == null) el.disChekr = false
                    // else el.disChekr = true

                    this.alimSndList.push(el)
                });
            }
        },
        onAlimTalkSend:function(){            
            let pObj = {  
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,                
                bizMsglist:[]
            }

            this.alimSndList.forEach(item => {

                if(item.isSelected == '1'){
                    let obj = {
                        sndMda:this.sndMda,
                        fcltyNum:this.$store.getters.getFcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        bzClcd:this.alimSndItmes.bizMsgBzClcd,
                        bzPk:this.alimSndItmes.bizMsgBzPk,
                        eDocClcd:this.alimSndItmes.bizMsgDocClcd,
                        prcClcdAr:this.alimSndItmes.bizMsgClcdAr,
                        prcCnfmAr:this.alimSndItmes.bizMsgCnfmAr,
                        prtCdAr:this.alimSndItmes.bizMsgPrtCdAr,
                        val1:this.alimSndItmes.bizMsgVal1,
                        val2:this.alimSndItmes.bizMsgVal2,
                        itmCtt1:this.alimSndItmes.bizMsgTitle,
                        itmCtt3:this.alimSndItmes.bizMsgCntnt,
                        itmCtt4:this.alimSndItmes.bizMsgItmCtt4,
                        itmCtt5:this.alimSndItmes.bizMsgItmCtt5,
                        itmCtt8:this.alimSndItmes.bizMsgItmCtt8,
                        prtCd:this.alimSndItmes.bizPrtCd,
                        ntcClcd:this.alimSndItmes.bizNtcClcd,
                        rcvCelphn1:item.celphn_1,
                        rcvCelphn2:item.celphn_2,
                        rcvCelphn3:item.celphn_3,
                        mmbrPk:item.mmbrPk,
                        bnMmbrPk:item.bnMmbrPk,
                        rcvNm:item.rcvNm,
                        rcvInfm:item.rcvNmDesc,
                        pstnCd:item.pstnCd,
                    }

                    if(this.alimSndItmes.bizMsgDocClcd == '1' || this.alimSndItmes.bizMsgDocClcd == '4' || this.alimSndItmes.bizMsgDocClcd == '8' || 
                    this.alimSndItmes.bizMsgDocClcd == '9' || this.alimSndItmes.bizMsgDocClcd == '13' || this.alimSndItmes.bizMsgDocClcd == '23' 
                    || this.alimSndItmes.bizMsgDocClcd == '24'){
                        obj.lnkUrl = this.$parent.$parent.$parent.$parent.encUrlObj(item.mmbrPk, item.rcvNm, this.alimSndItmes.bizMsgBzPk)
                    }

                    pObj.bizMsglist.push(obj)
                }
            });

            if(pObj.bizMsglist.length > 0){
                this.progress = true                
                insBizMsgNoSnd(pObj)
                    .then((response) => ( this.onAlimTalkSendAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
            }else{
                this.$emit('msgTrigger','대상자를 선택해주세요.')
            }
        },
        onAlimTalkSendAfter:function(res){
            this.$emit('mdlTrigger',res)
            if(res.statusCode == 200){     
                this.allChecker='2'
                this.getEDocRcvList()
            }
            this.progress = false
        },
        checkBoxMessage: function () {
            if(this.allChecker == '1') {
                this.alimSndList.forEach(item => {
                    if(item.disChekr == false) item.isSelected = '1'
                });
            }
            else if(this.allChecker == '2') {
                this.alimSndList.forEach(item => {
                    if(item.disChekr == false) item.isSelected = '2'
                });
            }
        },
        checkBoxIndex: function () {                        
            let tCh = '1'
            this.alimSndList.forEach(el => {
                if(el.isSelected == '2') tCh = '2'
            });
            this.allChecker = tCh
        },
        
        onModal:function(obj){
            this.$parent.$parent.$parent.$parent.onModalViw(0,obj,this.alimSndItmes)
        },
        onSign:function(mmbr){
            this.$emit("onSignTrigger",mmbr)
        },
    },
    
    data: () => ({ 
        forceRender:0,
        sndMda:'1',   
        alimSndList:[],     
        dialog:[false],
        allChecker:'2',
        progress:false,
        alimSndHeader:[
             { text: '수신자', value: '', align: 'center'},
             { text: '수신자 정보', value: '', align: 'center'},
             { text: '수신자 연락처', value: '', align: 'center'},
             { text: '내용', value: '', align: 'center'},
             { text: '발송상태', value: '', align: 'center'},
             { text: '발송일', value: '', align: 'center'},
             { text: '미리보기', value: '', align: 'center'},
        ],
        alimSndItmes:{bizMsgDocClcd:0},
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: '알림톡 발송', type: 'action', width:'600px', disabled: false, },
        ],
    }),
};
</script>