<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+200" :max-width="areaWidth+300">
        <div class=" pr-2 mt-5 px-2">
            <v-tabs hide-slider v-model="tab" color="white" class="black--text" active-class="bodyColor" center-active>
                <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style">{{ item.tab }}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">
                <v-tab-item transition="fade-transition">
                    <tab1 ref="tab1" :tab="tab"></tab1>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import tab1 from '../../components/btcInq/PrgBnfcrPrtcpSttsTab1.vue';
export default {
    name: 'PrgBnfcrPrtcpStts',
        
    components: {
        tab1,
    },

    created: function(){
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
    },

    watch:{ 
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
    },
        
    methods: {
   
    },
    
    data: () => ({
        areaWidth: 708,
        tab:0,
        tabItems: [
            { index: 0, tab: '수급자 참여', style: 'font-size: 1.2rem !important;'},
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class: 'ml-1 white--text', color:'grey006', text: '엑셀', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-printer-outline', class: 'ml-1 white--text', color:'grey006', text: '출력', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
    }),
};
</script>