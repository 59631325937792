<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" @click="onClose">                
        <div class="pa-2 rounded-lg" style="background:#e7e3e3" rounded="lg">                
            <div class="px-3 py-2" v-html="msg"></div>
            <div class="pa-2">
                <div style="word-break: break-all;cursor: pointer;" @click="onLink">{{btnLink}}</div>
            </div>
        </div>
    </v-sheet>
</template>
<script>

export default {
    name: 'SmsSndPreViw',
    
    components: {  
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){      
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        
    },

    methods: {  
        onClose: function () {
            this.$emit('modalClose')
        },
        onLoad:function(setObj){
            this.btnNm = setObj.msgBtn
            this.msg = setObj.msgCntnt
            this.btnLink =  setObj.lnkUrl
        },
        
        onLink:function(){
            let ttl = '발송내요역 미리보기'
            let loc = (document.body.offsetWidth / 2) - (200 / 2)
            let posn = 'left=' + loc.toString() + ', top=0, '
            let options = 'width=380, height=740, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no'
            
            window.open(this.btnLink, ttl, posn+options)
        },
    },
    data: () => ({ 
        sObj:{},
        items:{},
        clcd:0,
        msg:'',
        btnNm:'상세보기',
        btnLink:'',
        windowRef: null,
    }),
};
</script>