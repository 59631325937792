<template>
    <v-sheet>
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
            <v-row class="mt-3" no-gutters>
                <v-col class="d-flex align-center pl-3 pr-3" cols="12">
                    <span class="d-inline-block font-weight-bold mr-1">
                        발송일자
                    </span>
                    <!-- <span class="d-inline-block" style="width: 100px;">
                        <v-menu
                            ref="datePicker0" v-model="menu[0]"
                            :close-on-content-click="false" :return-value.sync="startDt"
                            transition="scale-transition" offset-y min-width="auto" dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="startDt"
                                    readonly hide-details outlined dense v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDt" :max="endDt" :key="forceRender" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(0)">Cancel</v-btn>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker0.save(startDt)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 14px;">  
                        ~
                    </span>
                    <span class="d-inline-block mr-10" style="width: 100px;">
                        <v-menu
                            ref="datePicker1" v-model="menu[1]"
                            :close-on-content-click="false" :return-value.sync="endDt"
                            transition="scale-transition" offset-y min-width="auto" dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="endDt"
                                    readonly hide-details outlined dense v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="endDt" :min="startDt" :key="forceRender" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(1)">Cancel</v-btn>
                                <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker1.save(endDt)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </span> -->
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                        <CmmDateComp v-model="startDt" :maxDt="endDt" :required="true"></CmmDateComp>
                    </span>
                    <span class="d-flex align-center ml-4 mr-2">
                        <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                    </span>
                    <span class="white d-inline-block py-1 pl-2 mr-2" style="width: 154px;">
                        <CmmDateComp v-model="endDt" :minDt="startDt" :required="true"></CmmDateComp>
                    </span>
                    <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                    <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />                    
                    <exBtn class="mr-3" :btnData="exBtn[2]" @btnClosed="expandClose" @changeCheck="filterSearch" />                    
                    <span class="d-inline-block mt-1" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                            single-line rounded outlined dense filled hide-details label="수신자조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                    <v-spacer></v-spacer>
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                            :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span style="font-size: 1rem;">{{list.text}}</span>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-row class="mt-3" no-gutters>
                <v-col class="d-flex" cols="12">
                    <div style="width:1235px;">
                        <v-data-table class="msclnTable smsSndHistTable pt-1" height="620" locale="ko"
                            v-model="tableSelected" 
                            :headers="smsSndHistHeader" 
                            :items="smsSndHistList" 
                            :search="schDummy" 
                            :custom-filter="customFilter"
                            :loading="cpyClmHisLoader"
                            item-key="mmbrPk" checkbox-color="primary"
                            show-select fixed-header disable-pagination hide-default-footer dense>
                            <template v-slot:[`header.data-table-select`]="{ props, on }" >
                                <tr>
                                    <td>
                                        <div class="d-flex justify-center align-center" style="height:40px !important; width: 40px;">
                                            <v-checkbox class="fmlyHeaderCheck" :input-value="props.value" @change="on.input($event)" hide-details></v-checkbox>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:item='{ item, isSelected, select }'>
                                <tr>
                                    <td class="tableBB">
                                        <div class="d-flex justify-center align-center" style="height:40px !important; width: 44px;">
                                            <v-checkbox v-if="item.sndSttsCd == '4'" class="fmlyHeaderCheck" :input-value="isSelected" @change="select($event)" hide-details></v-checkbox>
                                        </div>
                                    </td>
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                            <span class="cmmFontSizeOne">{{ item.rcvNm }}</span>
                                            <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span class="cmmFontSizeOne">{{ item.rcvInfm }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span style="width:130px" class="d-inline-block cmmFontSizeOne text-overflow">
                                            {{ item.rcvAddr }}
                                        </span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span class="cmmFontSizeOne">{{ item.eDocClcdNm }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>
                                    <!-- <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span style="width:150px" class="d-inline-block cmmFontSizeOne text-overflow">
                                            {{ item.msgTtl }}
                                        </span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td> -->
                                     <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span class="cmmFontSizeOne">{{ item.sndMdaNm }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>  
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span class="cmmFontSizeOne">{{ item.sndDt?.beforeDateFormatDot() }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>                                                                     
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span class="cmmFontSizeOne">{{ item.sndSttsCdNm }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>   
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                        <span class="cmmFontSizeOne" v-if="item.sndSttsCd==4">{{ item.rcvRetMsg }}</span>
                                        <span class="d-inline-block" style="width: 18px;"></span>
                                        </div>
                                    </td>                          
                                    <td class="tableBB" style="height:40px !important;">
                                        <div class="d-flex justify-center align-center">
                                            <v-btn icon @click="onModal(item)">
                                                <v-icon>mdi-text-box-outline</v-icon>
                                            </v-btn>  
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="dialog[0]" max-width="333" content-class="round" eager>
            <SmsSndAlimTalkViw ref="smsSndAlimTalkViw" @modalClose="isClose(0)"></SmsSndAlimTalkViw>
        </v-dialog>
        <v-dialog v-model="dialog[1]" max-width="333" content-class="round" eager>
            <SmsSndPreViw ref="smsSndPreViw" @modalClose="isClose(1)"></SmsSndPreViw>
        </v-dialog>
        <v-dialog v-model="dialog[2]" max-width="800" content-class="round" eager>
            <SmsSndEmailViw ref="smsSndEmailViw" @modalClose="isClose(2)"></SmsSndEmailViw>
        </v-dialog>
    </v-sheet>
</template>
<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';
import SmsSndAlimTalkViw from './SmsSndAlimTalkViw.vue'
import SmsSndPreViw from './SmsSndPreViw.vue'
import SmsSndEmailViw from './SmsSndEmailViw.vue'
import CmmDateComp from '../commons/CmmDateComp.vue';
import { selCodeESndList, insReESndHis } from '../../api/othr.js'

export default {
    name: 'SmsSndHist',

    props : {        
        smsSndHistPk : {type:Number, default: -1},
    },

    components: {
        exBtn,
        SmsSndAlimTalkViw,
        SmsSndPreViw,
        SmsSndEmailViw,
        CmmDateComp,
    },

    created: function () {        
        this.setWeekFormat()
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        
        })
    },

    computed: {

    },

    watch: {
        'startDt':function(value){
            if(value != ''){
                this.inqYmdStart = value?.afterDateFormatHyp()
                this.getESndList()
            }
        },

        'endDt':function(value){
            if(value != ''){
                this.inqYmdEnd = value?.afterDateFormatHyp()
                this.getESndList()
            }
        },

        'smsSndHistPk':function(value){
            if(value > 0) this.getESndList()
        }
    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onReSmsSend()
                    break                
                default:
                    break
            }
        },
        onReSmsSend:function(){
            let reMsglist = []
            
            this.tableSelected.forEach(el => {
                switch (el.sndSttsCd) {
                    case '4':
                        el.eSndHisPk = 0
                        reMsglist.push(el)
                        break;
                
                    default:
                        break;
                }
                
            });

            if(reMsglist.length > 0){
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    reMsglist : reMsglist
                }

                insReESndHis(obj)
                    .then((response) => ( this.onReSmsSendAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/selESndList : ' + error))

            }else{
                this.snackControll([true, 5000, '수신자를 선택해주세요.', 'error'])
            }
        },
        onReSmsSendAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getESndList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        getESndList:function(){
            this.cpyClmHisLoader = true
             this.smsSndHistList.splice(0)
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                inqYmdStart : this.inqYmdStart,
                inqYmdEnd : this.inqYmdEnd,
                valClcdS:['108','109']
            }

            selCodeESndList(obj)
                .then((response) => ( this.getESndListAfter(response.data) ))
                .catch((error) => console.log('connect error /othr/selESndList : ' + error))
        },
        
        getESndListAfter:function(res){
            this.cpyClmHisLoader = false
            //공통코드
            this.stCdMapping(res.code)           
            res.list.forEach(item => {
                
                switch (item.sndMda) {
                    case '1':
                        item.sndMdaNm = '알림톡'
                        item.rcvAddr = item.rcvCelphn1+'-'+item.rcvCelphn2+'-'+item.rcvCelphn3
                        break;
                    case '2':
                        item.sndMdaNm = 'SMS'
                        item.rcvAddr = item.rcvCelphn1+'-'+item.rcvCelphn2+'-'+item.rcvCelphn3
                        break;
                    case '3':
                        item.sndMdaNm = 'E-MAIL'
                        item.rcvAddr = item.emailId+'@'+item.emailDmn
                        break;
                    default:
                        break;
                }

                this.vCd109.forEach(cd => {
                    if(cd.valcd == item.sndSttsCd) item.sndSttsCdNm = cd.valcdnm
                });


                this.vCd108.forEach(cd => {
                    if(cd.valcd == item.eDocClcd) item.eDocClcdNm = cd.valcdnm
                });
                
                this.smsSndHistList.push(item)
            });

        },
        stCdMapping: function (res) {
            this.vCd108 = res.vCd108
            this.exBtn[2].expandList.splice(0)
            this.vCd108.forEach(code => {
                this.exBtn[2].expandList.push(code.valcdnm)
            })
            this.vCd109 = res.vCd109
            this.exBtn[1].expandList.splice(0)
            this.vCd109.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })
            //this.getESndList()
        },
        setSearchText: function (value) {
            this.schField = value
            this.tableSelected.splice(0)
            this.schDummy = this.schDummy + '.'
        },     
        filterSearch: function (sch) {            
            switch(this.schChkField.target) {
                case 'sndMdaNm': this.schChkField.sndMdaNm.splice(0)
                    sch.forEach((item) => { this.schChkField.sndMdaNm.push(item) });
                    break;
                case 'sndSttsCdNm': this.schChkField.sndSttsCdNm.splice(0)
                    sch.forEach((item) => { this.schChkField.sndSttsCdNm.push(item) });
                    break;
                case 'eDocClcdNm': this.schChkField.eDocClcdNm.splice(0)
                    sch.forEach((item) => { this.schChkField.eDocClcdNm.push(item) });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'sndMdaNm'
                    break;
                case 1: this.schChkField.target = 'sndSttsCdNm'
                    break;
                case 2: this.schChkField.target = 'eDocClcdNm'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.rcvNm.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.sndMdaNm.length != 0){
                this.schChkField.sndMdaNm.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.sndMdaNm.includes(stcd)
                    else col1 = col1 || filter.sndMdaNm.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.sndSttsCdNm.length != 0){
                this.schChkField.sndSttsCdNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.sndSttsCdNm.includes(stcd)
                    else col2 = col2 || filter.sndSttsCdNm.includes(stcd)
                });
            }

            let col3 = true
            if(this.schChkField.eDocClcdNm.length != 0){
                this.schChkField.eDocClcdNm.forEach((stcd, i) => {
                    if(i == 0) col3 = filter.eDocClcdNm.includes(stcd)
                    else col3 = col3 || filter.eDocClcdNm.includes(stcd)
                });
            }

            return col0 && col1 && col2 && col3;
        },   
        snackControll: function (options) {
            this.snackOptions = options
        },
        getDate:function(currentDay = new Date()){
            let theYear = currentDay.getFullYear();
            let theMonth = currentDay.getMonth();
            let theDate  = currentDay.getDate();
            let theDayOfWeek = currentDay.getDay();
            let resultDay = null

            if(theDayOfWeek == 0){
                resultDay = new Date(theYear, theMonth, theDate-6)//월요일부터 계산 
            }else{
                resultDay = new Date(theYear, theMonth, theDate +(1-theDayOfWeek))//월요일부터 계산 
            }

            resultDay = resultDay.getFullYear()+"-"+('00'+(resultDay.getMonth()+1).toString()).slice(-2)+"-"+('00'+(resultDay.getDate()).toString()).slice(-2)

            return resultDay
        },
        setWeekFormat: function(){        
            let date = this.getDate()     
            let currentDay = new Date(date); 
            let arrStartDay = currentDay.toISOString().split('T',2)            
            let arrEndDay = new Date(currentDay.setDate(currentDay.getDate()+6)).toISOString().split('T',2)   
            this.startDt = arrStartDay[0]
            this.endDt = arrEndDay[0]
            this.inqYmdStart = arrStartDay[0].afterDateFormatHyp()
            this.inqYmdEnd = arrEndDay[0].afterDateFormatHyp()
        }, 
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        onModal:function(item){
            
            switch (item.sndMda) {
                case "1":
                    this.$refs.smsSndAlimTalkViw.onLoad(item)
                    this.dialog.splice(0,1,true)
                    break;
                case "2":
                    this.$refs.smsSndPreViw.onLoad(item)
                    this.dialog.splice(1,1,true)
                    break;
                 case "3":
                    this.$refs.smsSndEmailViw.onLoad(item)
                    this.dialog.splice(2,1,true)
                    break;
                default:
                    break;
            }
            
        },
        isClose:function(key){
            this.dialog.splice(key,1,false)
        } ,
    },

    data: () => ({
        startDt:'',
        endDt:'',
        inqYmdStart:0,
        inqYmdEnd:0,
        menu:[false,false],
        dialog:[false,false,false],
        vCd108:[],
        vCd109:[],
        tableSelected: [],
        schField: '',
        schDummy: '',
        cpyClmHisLoader: true,
        schChkField: { sndMdaNm: [], sndSttsCdNm: [], eDocClcdNm:[], target: '' },        
        smsSndHistHeader:[
            { text: '수신자', value: 'rcvNm', align: 'center', on: false, sortable: true, width: '8%', class: 'black--text sftyHeader py-2'},
            { text: '수신자 정보', value: 'rcvInfm', align: 'center', on: false, sortable: true, width: '10%', class: 'black--text sftyHeader py-2'},
            { text: '수신자 연락처', value: 'rcvAddr', align: 'center', on: false, sortable: true, width: '14%', class: 'black--text sftyHeader py-2'},
            { text: '문서구분', value: 'eDocClcdNm', align: 'center', on: false, sortable: true, width: '14%', class: 'black--text sftyHeader py-2'},            
            { text: '발송종류', value: 'sndMda', align: 'center', on: false, sortable: true, width: '8%', class: 'black--text sftyHeader py-2'},
            { text: '발송일', value: 'sndDt', align: 'center', on: false, sortable: true, width: '10%', class: 'black--text sftyHeader py-2'},
            { text: '전송상태', value: 'sndSttsCd', align: 'center', on: false, sortable: true, width: '10%', class: 'black--text sftyHeader py-2'},
            { text: '메세지', value: 'rcvRetMsg', align: 'center', on: false, sortable: false, width: '13%', class: 'black--text sftyHeader py-2'},
            { text: '미리보기', value: '', align: 'center', on: false, sortable: false, width: '8%', class: 'black--text sftyHeader py-2'},
            { text: '', value: '', align: 'center', on: false, sortable: false, width: '', class: 'black--text sftyHeader py-2'},
        ],
        smsSndHistList:[],
        exBtn: [
            { index:  0, btnTitle: '발송종류', btnIcon: 'variant', exWidth: 100, exHeigth: 100, expandList: ['알림톡','SMS','E-MAIL'], },
            { index:  1, btnTitle: '전송상태', btnIcon: 'variant', exWidth: 134, exHeigth: 150, expandList: [], },
            { index:  2, btnTitle: '문서구분', btnIcon: 'variant', exWidth: 234, exHeigth: 250, expandList: [], },
        ],
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: '재발송', type: 'action', width:'600px', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
    }),
}
</script>
<style>
    .smsSndHistTable .v-data-table__wrapper table .v-data-table-header tr th {background-color:#ededed !important}    
    .smsSndHistTable .v-data-table__wrapper table tbody tr td { font-size: 0.975rem !important; }
</style>