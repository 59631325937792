<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="background:#F7F8F9" @click="onClose">                
        <div class="pa-0">        
            <div style='padding:10px;'>
                <div style='width:600px;margin:0px auto;'>              
                    <div style='width:200px;'>
                        <img src='https://yoyangsys.co.kr/img/yoyangsss.png' loading='lazy' width='150'>
                    </div>
                    <div style='margin-top:12px;padding:50px;text-align:center;background-color:#ffffff;border: 1px solid #E5E8EB;border-radius: 5px;'>
                        <div style='font-size:24px;font-weight: 900;'>{{title}}</div>
                        <div style='margin:30px;padding:20px 0px;text-align:left;min-height:200px;font-weight: 600;border-top:1px solid gray;border-bottom:1px solid gray' v-html="msg">
                            
                        </div>
                        <!-- <div style='width:200px;height:50px;line-height:50px;margin:10px auto;border-radius: 5px;background-color: #2a9bed;'>
                            <a :href="btnLink" target='_blank' style='color:white;font-weight: 900;text-underline:none ;text-decoration:none'>
                                {{btnNm}}
                            </a>
                        </div> -->
                        <div @click="onLink" style='cursor:pointer;width:200px;height:50px;line-height:50px;margin:10px auto;border-radius: 5px;background-color: #2a9bed;'>
                            <span style='color:white;font-weight: 900;text-underline:none ;text-decoration:none'>
                                {{btnNm}}
                            </span>
                        </div>
                    </div>
                    <div style='margin-top:30px;text-align:center;'>
                        <div>
                            <a href='https://yoyangsys.co.kr' target='_blank' style='color:#2a9bed;font-weight: 600;text-underline:none ;text-decoration:none'>
                            고객센터 / 1:1 문의
                            </a>
                        </div>
                        <!-- <div>
                            <p style='margin:0px;padding:2px 0px;font-size:13px;padding-left:5px;'>(주)해리지앤디 / 대표  이승준 / 경기도 수원시 반달로 7번길 40 7층</p>
                            <p style='margin:0px;padding:2px 0px;font-size:13px;padding-left:5px;'>사업자등록번호  762-81-01713</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </v-sheet>
</template>
<script>
export default {
    name: 'SmsSndEmailViw',
    
    components: {  
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){      
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        
    },

    methods: {  
        onClose: function () {
            this.$emit('modalClose')
        },
        onLoad:function(setObj){
            this.title = setObj.msgTtl
            this.msg = setObj.msgCntnt
            this.btnNm = setObj.msgBtn
            this.btnLink =  setObj.lnkUrl
        },
       
        onLink:function(){
            let ttl = '발송내요역 미리보기'
            let loc = (document.body.offsetWidth / 2) - (200 / 2)
            let posn = 'left=' + loc.toString() + ', top=0, '
            let options = 'width=380, height=740, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no'
            
            window.open(this.btnLink, ttl, posn+options)
        },
    },
    data: () => ({ 
        sObj:{},
        items:{},
        clcd:0,
        title:'',
        msg:'',
        btnNm:'상세보기',
        btnLink:'',
        windowRef: null,
    }),
};
</script>