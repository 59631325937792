<template>
    <v-sheet class="pa-7" min-width="1100px" max-width="1200px" min-height="500px">
        <v-row class="mb-7 pa-0" no-gutters>
            <v-col cols="8">
                <span class="text-h4 font-weight-bold">정보변경&nbsp;이력</span>
            </v-col>
            <v-col class="d-flex" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-divider :class="['mt-2', bnfcrHisList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', bnfcrHisList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">변경일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="2">
                <span class="black--text font-weight-medium py-2">변경사유</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">입소일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">퇴소일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">생활실</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">본인부담률</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">인정등급</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">인정기간</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">작성자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR" cols="1">
                <span class="black--text font-weight-medium py-2">적용시작일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="1">
                <span class="black--text font-weight-medium py-2">적용종료일</span>
            </v-col>
        </v-row>
        <v-divider :class="[bnfcrHisList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div v-if="bnfcrHisList.length > 0" class="overflow-y-auto" style="height: 402px;">
            <v-row
                v-for="(list, i) in bnfcrHisList" :key="i"
                class="pa-0 rowReactive" ref="infoList" no-gutters>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="py-2">{{list.mdfDt?.beforeDateFormatDot()}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR tableBB" style="padding-top: 3px;" cols="2">
                    <div class="" style="width: 100%;">
                        <div v-for="(mdfArr, j) in list.mfdRsnList" :key="j">
                            <div
                                :class="['pb-1', list.mfdRsnList.length-1 == j ? '' : 'tableBB']"
                                style="text-align: center; padding-top: 2px;">
                                {{ mdfArr }}
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="">{{list.cntntJson.bnfcrInfo.entDt?.beforeDateFormatDot()}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="">{{list.cntntJson.bnfcrInfo.lvDt?.beforeDateFormatDot()}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="">{{list.cntntJson.bnfcrInfo.lvnRm}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="">{{list.cntntJson.bnfcrInfo.copayRate}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="">{{list.cntntJson.bnfcrInfo.acknwRtng}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="">{{list.cntntJson.bnfcrInfo.acknwPrdEnd?.beforeDateFormatDot()}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0 tableBR tableBB" cols="1">
                    <span class="">{{list.mdfr}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center pt-1 ma-0 tableBR tableBB" cols="1">
                    <div v-if=" typeof list.cntntJson.mdfhisList != 'undefined' " style="width: 100%;">
                        <div v-for="(mdfArr, j) in list.cntntJson.mdfhisList" :key="j">
                            <div
                                :class="['pb-1', list.cntntJson.mdfhisList.length-1 == j ? '' : 'tableBB']"
                                style="text-align: center;">
                                {{ mdfArr.aplStrt }}
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center pt-1 ma-0 tableBB" cols="1">
                    <div v-if=" typeof list.cntntJson.mdfhisList != 'undefined' " style="width: 100%;">
                        <div v-for="(mdfArr, j) in list.cntntJson.mdfhisList" :key="j">
                            <div
                                :class="['pb-1', list.cntntJson.mdfhisList.length-1 == j ? '' : 'tableBB']"
                                style="text-align: center;">
                                &nbsp;{{ mdfArr.aplEnd == '2099.12.31' ? '-' : mdfArr.aplEnd }}&nbsp;
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div v-else class="overflow-y-auto" style="height: 402px;">
            <v-row
                class="tableBB pa-0"
                no-gutters>
                <v-col class="txtAlgnMid py-3" cols="12">
                    <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { getBnfcrCntrcHisList } from '../../api/bnfcr.js';

export default {
    name: 'BnfcrMgmtHist',
        
    components: {

    },

    created: function(){
        this.getBnfcrHisList(0, 99)
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getBnMmbrPk(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getTmpObj(){
            return this.$store.getters.getTmpObj
        },
    },

    watch:{
        getBnMmbrPk(value){
            console.log('change BnMmbrPk: ' + value)
            this.getBnfcrHisList(0, 99)
        },
        getTmpObj(value){
            console.log('bnfcrHis Changed: ' + value)
            this.getBnfcrHisList(0, 99)
        },
    },
    
    methods: {
        getBnfcrHisList: function (strtRn, range) {
            getBnfcrCntrcHisList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, 1, strtRn, range)
                .then((response) => ( this.getBnfcrHisListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcHisList : ' + error))
        },

        getBnfcrHisListAfter: function (res) {
            this.bnfcrHisCnt = res.rnTot
            this.bnfcrHisList = res.list
         
            this.bnfcrHisList.forEach((list) => {
                list.mfdRsnList = list.mfdRsn.split(',')
                if(list.mfdRsnList[list.mfdRsnList.length-1] == ' ')
                    list.mfdRsnList.splice(list.mfdRsnList.length-1, 1)

                this.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                    if(cd.valcd == list.cntntJson.bnfcrInfo.copayRate)
                        list.cntntJson.bnfcrInfo.copayRate = cd.valcdnm
                });

                if(list.cntntJson.bnfcrInfo.acknwRtng != 99)
                    list.cntntJson.bnfcrInfo.acknwRtng = list.cntntJson.bnfcrInfo.acknwRtng + '등급'
                else
                    list.cntntJson.bnfcrInfo.acknwRtng = '등급외'
                
                if(Array.isArray(list.cntntJson.mdfhisList)){
                    list.cntntJson.mdfhisList.forEach(arr => {
                        if(arr.aplStrt != '')
                            arr.aplStrt = this.$moment(arr.aplStrt, 'YYYYMMDD', true).format('YYYY.MM.DD')
                        if(arr.aplEnd != '')
                            arr.aplEnd = this.$moment(arr.aplEnd, 'YYYYMMDD', true).format('YYYY.MM.DD')
                    });
                }
            });

        },

        onInfoSingle: function (index) {
            this.$refs.infoList.forEach(element => {
                element.style="border-bottom: 1px solid #dbdbdb !important;"
            });

            this.$refs.infoList[index].style="border-bottom: 1px solid #dbdbdb; background: #ebf6fe !important;"
            this.$store.commit('setTmpObj', this.bnfcrHisList[index])
            this.$emit('objChanger', true)
            this.onClose()
        },

        onClose: function () {
            this.$emit('madalState', 5)
        },
    },

    data: () => ({
        bnfcrHisCnt: 0,
        bnfcrHisList: [],   //mdfDt변경일, mfdRsn변경사유, mdfr작성자
        sltBnfcrInfo: {},
    }),
};
</script>