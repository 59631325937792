<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">가정통신문&nbsp;작성</span>
            <v-btn
                class="ml-1 mt-1" width="30" height="30" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 12)" icon>
                <v-icon size="30" style="">mdi-help-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-row class="mt-5" no-gutters>
            <v-col class="pa-0 ma-0" cols="6">
                <div class="d-flex">
                    <expandMonthPicker @nowYearWithMonth="getPickerDate" :prtDate="date"></expandMonthPicker>
                    <v-spacer></v-spacer>
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn v-if="list.type == 'action'" @click = "onClickBtn(list.text)"
                            :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span style="font-size: 1rem;">{{list.text}}</span>
                        </v-btn>
                        <v-dialog v-else-if="list.type == 'dialogue'"
                            v-model="dialog[i]" :max-width="list.width">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                        :class="list.class" samll height="30" :color="list.color" rounded>
                                        <v-icon small>{{list.icon}}</v-icon>
                                        <span style="font-size: 1rem;">{{list.text}}</span>
                                    </v-btn>
                                </template>
                                <btnModalDelete v-if="list.text == '삭제'" :title="'가정통신문 인사말'" @madalState="onModal"></btnModalDelete>
                                <div v-else>개발중</div>
                        </v-dialog>
                    </span>
                </div>
                <div class="">
                    <TipTapEditor @editorToHtml="getTipTapHtml" @editorToJson="getTipTapJson" :editorCnt="cntnt" ></TipTapEditor>
                </div>
                <div class="mt-3">  
                    <v-stepper elevation="3">
                        <v-stepper-header>
                            <v-stepper-step step="1" :complete="page == 1" editable @click="pageChanger(1)">Page&nbsp;1</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step step="2" :complete="page == 2" editable @click="pageChanger(2)">Page&nbsp;2</v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step step="3" :complete="page == 3" editable @click="pageChanger(3)">Page&nbsp;3</v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                </div>
            </v-col>
            <v-col class="pa-0 ma-0 d-flex justify-center" cols="6">
                <div ref="htmlToImg" class="previewCover mb-2" :style="setBgUrl">
                    <div class="ProseMirror tiptapEditor editorPreview" v-html="tiptapValue"></div>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="loading" content-class="round" max-width="1100">
            <v-progress-linear
                indeterminate height="12"
                color="blue600 lighten-2">
            </v-progress-linear>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { remoteURL } from '@/api/baseAxios';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import TipTapEditor from '../../components/commons/TipTapEditor.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { insfamlLtrGrt, selFamlLtrInfo, delfamlLtr } from '../../api/bnfcr.js';

//import html2canvas from 'html2canvas';
import { toCanvas } from 'html-to-image';

export default {
    name: 'FmlyLeterWrt',

    props : {
        prtDate: { type: String, default: '' }
    },
            
    components: {
        expandMonthPicker,
        btnModalDelete,
        TipTapEditor,
    },

    created: function(){
        this.tiptapValue = ''
    
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getFamlLtrInfo(this.page)
        })
    },

    computed: {
        setBgUrl () {
            return {
                backgroundImage: "url('" + remoteURL + 'opr/selBgImg' + "')",
            }
        },
    },

    watch: {
        'prtDate': function () {
            this.date = this.prtDate
            this.getFamlLtrInfo(this.page)
        },
        'cntnt': function () {
            this.tiptapValue = this.cntnt
        },
        'date': function () {
            this.getFamlLtrInfo(this.page)
        },
        'tiptapValue': function () {
            //var node = document.getElementById('printMe');
            //let me = this
   
        },
    },
        
    methods: {
        getFamlLtrInfo: function (page) {
            selFamlLtrInfo(this.$store.getters.getFcltyNum, null, 1, this.date, page)
                .then((response) => ( this.getFamlLtrInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selFamlLtrInfo : ' + error))
        },
        setfamlLtrGrt: function (obj) {
            insfamlLtrGrt(obj)
                .then((response) => ( this.setFamlLtrGrtAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insfamlLtrGrt : ' + error))
        },
        removeFamlLtr: function (famlPk) {
            delfamlLtr(this.$store.getters.getFcltyNum, famlPk, this.$store.getters.getUserNm)
                .then((response) => ( this.setFamlLtrGrtAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delfamlLtr : ' + error))
        },
        getFamlLtrInfoAfter: function (res) {
            if(res == ''){
                this.famlLtrPk = 0
                this.cntnt = ''
            }
            else{
                this.famlLtrPk = res.famlLtrPk
                this.cntnt = res.cntnt
            }
        },
        setFamlLtrGrtAfter: function (res) {
            this.loading = false
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.selFamlLtrInfo = null
                this.tiptapValue = ''
                this.tiptapJSON = ''
                this.famlLtrPk = 0
                this.cntnt = ''
                this.getFamlLtrInfo(this.page)
                //this.$emit('reload', true)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preSetFamlLtrGrt: async function () {
            let tmp = {}
            tmp.fcltyNum = this.$store.getters.getFcltyNum
            tmp.userNm = this.$store.getters.getUserNm
            tmp.bnMmbrPk = null
            tmp.cntntClcd = '1'
            tmp.stdYymm = this.date
            tmp.cntnt = this.tiptapValue
            tmp.cntntJSON = this.tiptapJSON
            tmp.famlLtrPk = this.famlLtrPk
            tmp.pgeNum = this.page
            //page

            //this.$refs.htmlToImg.style = 'height: auto;'

            //html2canvas(document.querySelector("#htmlToImg"), { useCORS: true }).then(canvas => {
                //let src = canvas.toDataURL("image/jpeg"
                //tmp.htmlToImage = src
            //});
            let htmlHeight = this.$refs.htmlToImg.clientHeight
            let htmlWidth = this.$refs.htmlToImg.clientWidth 
            
            await toCanvas(this.$refs.htmlToImg,
                {  backgroundColor: '#fff', height: htmlHeight, width: htmlWidth,
                    includeQueryParams: true, style:{ 'overflow': 'hidden'}, fontEmbedCss: '' })
            .then(function (canvas) {
                tmp.htmlToImg = canvas.toDataURL("image/jpeg")
                //console.log(tmp.htmlToImg)
            });

            //this.$refs.htmlToImg.style = ''
            //console.log(tmp)
            this.setfamlLtrGrt(tmp)
        },
        getTipTapHtml: function (html) {
            this.tiptapValue = html
        },
        getTipTapJson: function (json) {
            this.tiptapJSON = json
        },
        getPickerDate: function (date) {  
            this.date = date
            this.sltDate = date.substr(0, 4) + '년 ' + date.substr(4, 2) + '월'
        },
        pageChanger: function (index) {
            this.page = index
            this.getFamlLtrInfo(this.page)
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        onClickBtn: function (index){
            this.loading = true
            if(index == '저장'){
                this.preSetFamlLtrGrt()
            }
        },
        onModal: function(chk) {
            if(chk == 7)
                this.dialog.splice(1, 1, false)
            else if(chk == 9990){
                if(this.famlLtrPk != 0){
                    this.dialog.splice(1, 1, false)
                    this.removeFamlLtr(this.famlLtrPk)
                }
                else{
                    this.dialog.splice(1, 1, false)
                    this.snackControll([true, 3000, '저장된 내역이 없습니다.', 'info'])
                }
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },  
    
    data: () => ({
        loading: false,
        output: null,
        dialog: [false, false],
        fmlyLeterInfo: null,
        cntnt: '',
        famlLtrPk: 0,
        page: 1,
        date: '',
        sltDate: '',
        tiptapValue: '',
        tiptapJSON: '',
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'600px', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', width:'500px', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        fontFace: '@font-face { name: "Black Han Sans" ; src: url("http://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap") format("woff2"); }'
    }),
};
</script>

<style>
    .previewCover {
        width: 495px; height: 690px; background-position: center center;
        background-image: "url('" + var(--target-Url) + "')"; background-size: contain;
    }
    .editorPreview { padding: 80px 55px 0px 55px !important; overflow: hidden; height: 616px; }
</style>