<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0 justify-left" no-gutters>
            <span class="text-h4 font-weight-bold">유급휴일&nbsp;일정&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon @click="onClose()" color="black" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-5 align-center" no-gutters>
            <v-icon style="font-size: 26px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">대체휴일&nbsp;등록</span>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="greyE01 pa-0" no-gutters>
            <v-col class="textCenter tableBR py-1" cols="2">
                <span class="font-weight-bold" style="font-size: 1.1rem;">색상</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">휴가일</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">근무시작시간</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="2">
                <span class="font-weight-bold" style="font-size: 1.1rem;">대체시간</span>
            </v-col>
            <v-col class="textCenter py-1" cols="2">

            </v-col>
        </v-row>
        <v-form ref="hldyAplyFrm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center tableBR" style="padding-top: 2px;" cols="2">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-text-field
                            v-model="wrkSchdlFrm.wrkPtnColr"
                            class="ma-0 pa-0 colorFiled"
                            readonly hide-details outlined dense>
                            <template v-slot:append>
                                <v-menu
                                    v-model="clrMdl" :close-on-content-click="false"
                                    nudge-bottom="210" nudge-left="-30"
                                    top>
                                    <template v-slot:activator="{ on }">
                                        <div
                                            class="rounded crsPntr" v-on="on"
                                            :style="{ backgroundColor: wrkSchdlFrm.wrkPtnColr, width: '20px', height: '20px', margin: '1px 0 0 0' }">
                                        </div>
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker
                                                v-model="clrObj"
                                                mode="hexa"
                                                :swatches="$parent.$parent.$parent.$parent.swatches"
                                                @update:color="rflClrPicker"
                                                hide-mode-switch show-swatches>
                                            </v-color-picker>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </template>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center tableBR" style="padding-top: 2px;" cols="3">
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp v-model="wrkSchdlFrm.wrkDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center tableBR" style="padding-top: 2px;" cols="3">
                    <span class="white d-inline-block" style="width: 59px;">
                        <CmmTimeField v-model="wrkSchdlFrm.wrkTmStrt" :required="true" focusClear></CmmTimeField>                               
                    </span>
                    <span class="mx-1">
                        <v-icon class="greyE00--text" style="margin-bottom: 0px;" size="16">mdi-tilde</v-icon>
                    </span>
                    <!-- <span class="white d-inline-block" style="width: 59px;">
                        <CmmTimeField v-model="wrkSchdlFrm.wrkTmEnd" :required="true" :disabled="true" focusClear></CmmTimeField>                               
                    </span> -->
                </v-col>
                <v-col class="d-flex justify-center tableBR" cols="2">
                    <span class="white d-inline-block" style="width: 62px; margin-top: 2px; margin-bottom: 2px;">
                        <CmmTimeField
                            v-model="wrkSchdlFrm.vctnUseTm"
                            :maxTm="wrkSchdlFrm.wrkLmtTm" :required="true" :disabled="wrkSchdlFrm.wrkLmtTm == ''"
                            focusClear>
                        </CmmTimeField>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center" cols="2">
                    <span>
                        <v-btn
                            class="" color="blueBtnColor" height="26"
                            @click="preVctnSbst()" :disabled="savBtn"
                            icon>
                            <v-icon>mdi-content-save-edit-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>
        <v-row class="pa-0 mt-5 align-center" no-gutters>
            <v-icon style="font-size: 26px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">사용가능한&nbsp;대체휴일</span>
        </v-row>
        <v-divider :class="['mt-2', vctnSbstList.length > 5 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', vctnSbstList.length > 5 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="textCenter tableBR py-1" cols="1">
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="2">
                <span class="font-weight-bold" style="font-size: 1.1rem;">근무일</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">대체가능시간</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="2">
                <span class="font-weight-bold" style="font-size: 1.1rem;">작성자</span>
            </v-col>
            <v-col class="textCenter tableBR py-1" cols="3">
                <span class="font-weight-bold" style="font-size: 1.1rem;">비고</span>
            </v-col>
            <v-col class="textCenter py-1" cols="1">
             
            </v-col>
        </v-row>
        <div
            v-if="vctnSbstList.length > 0"
            class="d-inline-block overflow-y-auto" style="width: 100%; height: 186px;">
            <v-divider class=""></v-divider>
            <v-row class="pa-0 tableBB" v-for="(vSbst, i) in vctnSbstList" :key="i" no-gutters>
                <v-col class="textCenter tableBR py-1" cols="1">
                    <span class="d-inline-block" style="width: 27px;">
                        <v-checkbox
                            class="checkIcon" v-model="radioIdx"
                            :true-value="i" :false-value="-1"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                    <span class="fontOneRem">
                        {{ $moment(vSbst.vctnSbstDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="3">
                    <span class="fontOneRem">{{ Math.floor(vSbst.vctnWrkHr / 60) }}시간</span>
                    <span class="fontOneRem ml-1">{{ vSbst.vctnWrkHr % 60 }}분</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                    <span class="fontOneRem">
                        {{ vSbst.wrtr }}
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-1" cols="3">
                    <span class="fontOneRem">{{ vSbst.rmrks }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="1">
                    <span>
                        <v-btn
                            class="" color="grey006" height="26"
                            @click="rmVctnSbst(vSbst.vctnSbstPk)"
                            :disabled="vSbst.vctnSbstPk == null"
                            icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-divider class=""></v-divider>
            <v-row class="pa-0 tableBB" no-gutters>
                <v-col class="textCenter py-2" cols="12">
                    <span class="grey006--text fontOneRem">※ 사용 가능한 유급휴일 대체 정보가 없습니다.</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { selVctnSbstList, updVctnSbst, delVctnSbst } from '../../api/emp.js';

import CmmTimeField from '../commons/CmmTimeField.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'PaidHldyAplyMdl',

    props : {
        mmbrPk: { type: Number, default: 0 },
        empPk: { type: Number, default: 0 },
        yyyy: { type: String, default: '' },
    },
        
    components: {
        CmmTimeField,
        CmmDateComp,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getVctnSbstList(this.empPk, this.yyyy)
        })
    },

    computed: {

    },

    watch:{
        'radioIdx': function () {
            if(this.radioIdx == -1){
                this.savBtn = true
                this.wrkSchdlFrm.wrkLmtTm = ''
                this.wrkSchdlFrm.vctnUseTm = ''
                this.wrkSchdlFrm.vctnSbstDt = ''
            }
            else{
                let lmtMin = this.vctnSbstList[this.radioIdx].vctnWrkHr
                this.wrkSchdlFrm.wrkLmtTm = ('00' + Math.floor(lmtMin / 60)).slice(-2) + ':' + ('00' + (lmtMin % 60)).slice(-2)
                this.wrkSchdlFrm.vctnUseTm = this.wrkSchdlFrm.wrkLmtTm
                this.wrkSchdlFrm.vctnSbstDt = this.vctnSbstList[this.radioIdx].vctnSbstDt
                this.savBtn = false
            }
        },
    },
    
    methods: {
        //유급휴일 내역 조회
        getVctnSbstList: function (pk, yyyy) {
            this.vctnSbstList.splice(0)
            selVctnSbstList(this.$store.getters.getFcltyNum, pk, yyyy)
                .then((response) => ( this.vctnSbstRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selVctnSbstList : ' + error))
        },

        //유급휴일 수정
        setVctnSbst: function (obj) {
            updVctnSbst(obj)
                .then((response) => ( this.updVctnSbstRst(response.data) ))
                .catch((error) => console.log('connect error /emp/updVctnSbst : ' + error))
        },

        //유급휴일 삭제
        rmVctnSbst: function (pk) {
            delVctnSbst(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk)
                .then((response) => ( this.updVctnSbstRst(response.data) ))
                .catch((error) => console.log('connect error /emp/updVctnSbst : ' + error))
        },

        vctnSbstRst: function (res) {
            //console.log(res)
            if(res.length > 0){
                this.vctnSbstList = res.filter( v => v.vctnUseYn == '2' )
            }
        },

        updVctnSbstRst: function (res) {
            if(res.statusCode == 200){
                this.radioIdx = -1
                this.getVctnSbstList(this.empPk, this.yyyy)
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },

        preVctnSbst: function () {
            let obj = Object.assign({}, this.wrkSchdlFrm)
            let useTm = obj.vctnUseTm.split(':', 2)

            if(this.$refs.hldyAplyFrm.validate()){
                obj.wrkDt = this.$moment(obj.wrkDt, 'YYYY-MM-DD').format('YYYYMMDD')
                let rngTm = 0
                obj.vctnWrkDt = obj.vctnSbstDt
                obj.vctnUseDt = obj.wrkDt
                obj.actWrkHr = (Number(useTm[0]) * 60) + Number(useTm[1])
                obj.vctnUseHr = obj.actWrkHr
                obj.wrkTmHhStrt = obj.wrkTmStrt.split(':', 2)[0]
                obj.wrkTmMmStrt = obj.wrkTmStrt.split(':', 2)[1]
                rngTm = (Number(obj.wrkTmHhStrt) * 60) + Number(obj.wrkTmMmStrt) + obj.actWrkHr
                obj.wrkTmHhEnd = ('00' + Math.floor(rngTm / 60)).slice(-2)
                obj.wrkTmMmEnd = ('00' + rngTm % 60).slice(-2)
                obj.userNm = this.$store.getters.getUserNm
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.empPk = this.empPk
                obj.mmbrPk = this.mmbrPk

                if(Number(obj.wrkTmHhEnd > 23)){
                    obj.wrkTmHhEnd = ('00' + (Number(obj.wrkTmHhEnd) - 24)).slice(-2)
                    obj.wrkOvnYn = '1'
                }
                else
                    obj.wrkOvnYn = '2'

                //console.log(obj)
                this.setVctnSbst(obj)
            }
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'info']) 

        },

        // 컬러 픽커 내용 반영
        rflClrPicker: function (){
            this.wrkSchdlFrm.wrkPtnColr = this.clrObj.hex
        },
    
        onClose: function () {
            this.$emit('isClose', '.')
            this.$parent.$parent.$parent.$parent.rclngWrkShdl()
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        savBtn: true,
        clrMdl: false,
        radioIdx: -1,
        clrObj: null,
        vctnSbstList: [],
        wrkSchdlFrm: {
            wrkDt: '', vctnWrkDt: '', wrkTypCd: '2', vctnTypCd: '90', brkDay: 0, brkNgt: 0, actWrkHr: 0, vctnSbstDt: '',
            wrkOvnYn: '2', wrkPtnColr: '#000000', wrkLmtTm: '', vctnUseDt: '', vctnUseHr: 0, vctnUseTm: '',
            wrkTmStrt: '', wrkTmEnd: '', wrkTmHhStrt: '', wrkTmMmStrt: '', wrkTmHhEnd: '', wrkTmMmEnd: '',
        },
        rules: {
            required: value => !!value || '필수 입력 항목입니다.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>