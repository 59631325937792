<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-center align-center">
            <span class="text-h4 font-weight-bold">운영위원회&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
         
        <div class="mt-2 pa-0">
            <div :class="['d-flex greyE01', cmteList.length >  11 ? 'mr-2__5' : '']">
                <div class="d-flex py-1 align-center justify-center tableBT tableBB tableBR" style="width:4%;">
                    <span class="black--text font-weight-medium" style="text-align:center;">연번</span>
                </div>
                <div class="d-flex py-1 align-center justify-center tableBT tableBB tableBR" style="width:12%;">
                    <span class="black--text font-weight-medium" style="text-align:center;">이름</span>
                </div>
                <div class="d-flex py-1 align-center justify-center tableBT tableBB tableBR" style="width:13%;">
                    <span class="black--text font-weight-medium" style="text-align:center;">구분</span>
                </div>
                <div class="d-flex py-1 align-center justify-center tableBT tableBB tableBR" style="width:14%;">
                    <span class="black--text font-weight-medium" style="text-align:center;">분야</span>
                </div>
                <div class="d-flex py-1 align-center justify-center tableBT tableBB tableBR" style="width:19%;">
                    <span class="black--text font-weight-medium" style="text-align:center;">연락처</span>
                </div>
                <div class="d-flex py-1 align-center justify-center tableBT tableBB tableBR" style="width:30%;">
                    <span class="black--text font-weight-medium" style="text-align:center;">운영위원 기간</span>
                </div>
                <div class="d-flex py-1 align-center justify-center tableBT tableBB" style="width:8%;">
                    <span class="black--text font-weight-medium" style="text-align:center;">처리</span>
                </div>
            </div>
            <div>
                
                <v-form ref="mform" lazy-validation>
                <div :class="['d-flex', cmteList.length >  11 ? 'mr-2__5' : '']">
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:4%;">
                        <span class="black--text font-weight-medium" style="text-align:center;">작성</span>
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:12%;">
                        <span class="d-inline-block py-1" style="width: 100px;">
                            <v-text-field class="px-1" style="width: 100%;" v-model="cmteItm.name" :disabled="nmdis"
                                color="blue" hide-details outlined dense :rules="[rules.required]"></v-text-field>
                        </span>                        
                        <v-btn class="ml-1 brown lighten-3 mr-1 white--text"
                            style="padding:0px 1px 0px 2px !important" 
                            small depressed min-width="30"
                            :disabled="!nmdis"
                            height="28" @click="onEmpModel()">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:13%;">
                        <span class="px-1" style="width:100%;">
                            <v-select class="hsptlSelect"
                                v-model="cmteItm.cmteClcd" height="28" 
                                :key="forceRender"
                                item-text="valcdnm" item-value="valcd" 
                                :items="cmteNm"
                                @change="onClcd"
                                dense hide-details outlined :rules="[rules.required]">
                            </v-select>
                        </span>
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:14%;">
                        <span class="px-1" style="width:100%;" v-if="cmteItm.cmteClcd=='1'">
                            <v-select class="hsptlSelect"
                                v-model="cmteItm.cmteFdcd" height="28" :key="forceRender"
                                item-text="valcdnm" item-value="valcd" :items="cmteInSFdNm"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                        <span class="px-1" style="width:100%;" v-else-if="cmteItm.cmteClcd=='2'">
                           
                            <v-select class="hsptlSelect"
                                v-model="cmteItm.cmteFdcd" height="28" :key="forceRender"
                                item-text="valcdnm" item-value="valcd" :items="cmteOutSFdNm"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:19%;">
                        <v-text-field class="px-1" style="width: 28%; " v-model="cmteItm.celphn_1"
                            color="blue" hide-details outlined dense :rules="[rules.required]"></v-text-field>&#45;
                        <v-text-field class="px-1" style="width: 32%; " v-model="cmteItm.celphn_2"
                            color="blue" hide-details outlined dense :rules="[rules.required]"></v-text-field>&#45;
                        <v-text-field class="px-1" style="width: 32%; " v-model="cmteItm.celphn_3"
                            color="blue" hide-details outlined dense :rules="[rules.required]"></v-text-field>
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:30%;">
                        <span class="px-1" style="width:100%;">
                            <CmmDateComp v-model="cmteItm.dtStrt" :required="true"></CmmDateComp>
                        </span>
                        &#126;
                        <span class="px-1" style="width:100%;">
                            <CmmDateComp v-model="cmteItm.dtEnd" :required="true"></CmmDateComp>
                        </span>
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB" style="width:8%;">
                        <v-btn icon color='blue200' @click="onSaveRow()">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="22" v-bind="attrs" v-on="on">mdi-content-save-outline</v-icon>
                                </template>
                                <span>저장</span>
                            </v-tooltip>
                        </v-btn>
                        <v-btn
                            color='grey006'
                            @click="clearItm"
                            icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="22" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                </template>
                                <span>지우기</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                </div>
                </v-form>
            </div>
            
            <div v-if="cmteList.length > 0" class="overflow-y-auto" style="height:530px;">
                <div class="d-flex" v-for="(list, i) in cmteList" :key="i">
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:4%;">
                        {{ ++i }}
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:12%;">
                        {{list.name}}
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:13%;">
                        {{list.cmteClcdNm}}
                    </div>
                    <div class="d-flex pa-1 align-center justify-center tableBB tableBR" style="width:14%;font-size:0.9rem">
                        <div class="text-overflow" style="width:100%;text-align:center">
                            
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{list.cmteFdcdNm}}</span>
                                </template>
                                <span>{{list.cmteFdcdNm}}</span>
                            </v-tooltip>
                        </div>
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:19%;">
                        {{list.celphn_1}}&#45;{{list.celphn_2}}&#45;{{list.celphn_3}}                        
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB tableBR" style="width:30%;">
                        {{list.dtStrt}}&#126;{{list.dtEnd}}
                    </div>
                    <div class="d-flex py-1 align-center justify-center tableBB" style="width:8%;">
                        <v-btn icon color='grey006' @click="delRow(list.cmtePk)">
                            <v-icon >mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
            </div>                
        </div>     
                  
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[0]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="'참석자 선택'" :tabInfList="['1','2']" :fixList="exclsList" mode="1"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"  
                    :prtWrtDt = "$moment().format('YYYYMMDD')"                  
                    @intgrResList="getAplyList" @onClose="isCloseEmp">
                </IntgrSlctn>
            </v-dialog>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'운영위원회 관리'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>  
    </v-sheet>
</template>

<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import IntgrSlctn from '../commons/IntgrSlctn.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import { getMultiStcd } from '../../api/index.js';
import { selCmteList, insCmte, delCmte } from '../../api/opr.js';
export default {
    name: 'CnfrnEmp',

    components: {  
        CmmDateComp,
        IntgrSlctn,
        btnModalDelete,
    },
    mounted() {
        this.$nextTick(function () {
            this.onCodeload()            
        })
    },
    
    methods: {
        onDelete:function(res){
            if(res == 9990){                
                if(this.idx > 0){
                    delCmte(this.$store.getters.getFcltyNum,this.$store.getters.getUserNm,this.idx)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /opr/delCmte : ' + error))
                }else{
                    this.dialogDelete = false    
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.selCmteList()     
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.idx = 0
            this.dialogDelete = false            
        },
        delRow(pk){
            if(pk > 0){
                this.idx = pk
                this.dialogDelete = true
            }else{
                this.snackControll([true, 5000, '대상을 선택해주세요.', 'error'])
            }
        },
        getAplyList: function (obj){
            this.clearItm()
            
            obj.forEach(el => {
                this.cmteItm.name = el.name
                this.cmteItm.mmbrPk = el.bnMmbrPk
                this.cmteItm.celphn_1 = el.celphn_1
                this.cmteItm.celphn_2 = el.celphn_2
                this.cmteItm.celphn_3 = el.celphn_3
                this.cmteItm.cmteClcd = '1'
            })

            ++this.forceRender
        },
        onCodeload(){
            getMultiStcd(['41','42','65','121'], this.$store.getters.getFcltyNum)
                .then((response) => ( this.codeMapping(response.data)))
                .catch((error) => console.log(error))
        },
        codeMapping(res){
            
            this.cmteInSFdNm.splice(0)
            this.cmteOutSFdNm.splice(0)

            res.vCd121.forEach(e => {
                if(e.valdesc=="내부"){
                    this.cmteInSFdNm.push(e)
                }else if(e.valdesc=="외부"){
                    this.cmteOutSFdNm.push(e)
                }
            });

            this.vCd41  = res.vCd41
            this.vCd42  = res.vCd42
            this.vCd65  = res.vCd65
            this.vCd121 = res.vCd121
            this.selCmteList()
        },
        onEmpModel: function () {
            this.exclsList.splice(0)
            this.dialog.splice(0, 1, true)
        },
        isCloseEmp: function () {
            this.dialog.splice(0, 1, false)
        },      
        
        snackControll: function (options) {
            this.snackOptions = options
        },
        onSaveRow:function(){
            
            let pass = true
            
            if(this.cmteItm.cmteClcd == '1' && this.cmteItm.name == ''){
                pass = false
            } 

            if(pass){
                if(this.$refs.mform.validate()){


                        let obj = {
                            fcltyNum    : this.$store.getters.getFcltyNum,
                            userNm      : this.$store.getters.getUserNm,
                            cmteClcd    : this.cmteItm.cmteClcd, 
                            cmteFdcd    : this.cmteItm.cmteFdcd, 
                            mmbrPk      : this.cmteItm.mmbrPk, 
                            name        : this.cmteItm.name, 
                            celphn_1    : this.cmteItm.celphn_1, 
                            celphn_2    : this.cmteItm.celphn_2, 
                            celphn_3    : this.cmteItm.celphn_3, 
                            dtStrt      : this.$moment(this.cmteItm.dtStrt,'YYYY-MM-DD').format('YYYYMMDD'), 
                            dtEnd       : this.$moment(this.cmteItm.dtEnd,'YYYY-MM-DD').format('YYYYMMDD'),
                            cmtePk      : 0 
                        }
                        
                        insCmte(obj)
                            .then((response) => ( this.onSaveRowAfter(response.data) ))
                            .catch((error) => console.log('upload error /opr/insCmte : ' + error))
                
                }else{
                    this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
                }
            } else {
                    this.snackControll([true, 2500, '내부직원을 선택해주세요.', 'warning'])
            }
        },
        onSaveRowAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.selCmteList()
                this.clearItm()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.$emit('isClose')
        },
        getDate: function (value) {            
            this.ymd = value.date  
        },
        selCmteList: function () {
            selCmteList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selCmteListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selCmteList : ' + error))
        },
        selCmteListAfter: function (res) {
            this.cmteList.splice(0)

            res.forEach(e => {
                e.cmteClcdNm = (e.cmteClcd == '1')?'내부인사':'외부인사'
                e.dtStrt = this.$moment(e.dtStrt,'YYYYMMDD').format('YYYY.MM.DD')
                e.dtEnd = this.$moment(e.dtEnd,'YYYYMMDD').format('YYYY.MM.DD')

                this.vCd121.forEach(cd => {
                    if(cd.valcd == e.cmteFdcd) e.cmteFdcdNm = cd.valcdnm
                });

                this.cmteList.push(e)
            });
        },
        clearItm:function(){
            this.cmteItm.cmteClcd    = ''
            this.cmteItm.cmteFdcd    = ''
            this.cmteItm.mmbrPk      = ''
            this.cmteItm.name        = ''
            this.cmteItm.celphn_1    = ''
            this.cmteItm.celphn_2    = ''
            this.cmteItm.celphn_3    = ''
            this.cmteItm.dtStrt      = ''
            this.cmteItm.dtEnd       = ''
            this.cmteItm.cmtePk      = 0
            this.$refs.mform.resetValidation()
        },
        onClcd:function(){
            this.cmteItm.name   = ''
            this.cmteItm.mmbrPk = 0

            if(this.cmteItm.cmteClcd == '2'){
                this.nmdis = false
            } else {
                this.nmdis = true
            }
        }
    },
    data: () => ({ 
        dialog: [false],
        nmdis:true,
        prtWrtDt:'',      
        headers:[
            { text: '연번', value: '', align: 'center', },
            { text: '이름', value: '', align: 'center', },
            { text: '구분', value: '', align: 'center', },
            { text: '분야', value: '', align: 'center', },
            { text: '연락처', value: '', align: 'center', },
            { text: '운영위원 기간', value: '', align: 'center', },
        ],
        cmteItm: { 
            cmteClcd    : '', 
            cmteFdcd    : '', 
            mmbrPk      : 0, 
            name        : '', 
            celphn_1    : '', 
            celphn_2    : '', 
            celphn_3    : '', 
            dtStrt      : '', 
            dtEnd       : '',
            cmtePk      : 0 
        },
        cmteList: [],
        cmteNm: [{valcd:'',valcdnm:'선택'},{ valcd: '1', valcdnm: '내부인사 '}, { valcd: '2', valcdnm: '외부인사' }, ],
        ymd:'',  
        forceRender: 0,
        snackOptions: [false, 3000, '', 'info'],
        cmteInSFdNm: [{valcd:'0',valcdnm:'선택'}],
        cmteOutSFdNm: [{valcd:'0',valcdnm:'선택'}],
        vCd41:[],
        vCd42:[],
        vCd65:[],
        vCd121:[],
        exclsList:[],
        tabInfList:['1','2'],
        dialogDelete:false,
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
        },
        test:''
    }),
};
</script>