<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-left mb-2 align-center">
                <span class="text-h4 font-weight-bold">건물정보 추가</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <div class="pa-0 ma-0 mt-1">
                <v-row class="pa-0 ma-0 mt-2"
                    style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;"
                    no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="7">
                        <span class="black--text font-weight-medium">건물</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="2">
                        <span class="black--text font-weight-medium">순서</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"
                        style="border-left: 1px solid #dbdbdb !important;" cols="3">
                        <span class="black--text font-weight-medium" style="text-align:center;">
                            <v-btn icon color='blue200' @click="addRow()">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                </v-row>
                <div style="width: 554px;">
                    <div style=" height:500px; overflow-y:auto; ">
                        <v-row v-for="(item, i) in items" :key="i" class="pa-0 ma-0 ma-0"
                            style="border-bottom: 1px solid #dbdbdb !important; width: 544px;" no-gutters>
                            <v-col class="d-flex justify-start align-center pa-1 ma-0"
                                style="border-left: 1px solid #dbdbdb !important;" cols="7">
                                <v-text-field v-model="item.bldNm" color="blue" hide-details outlined dense :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0"
                                style="border-left: 1px solid #dbdbdb !important;" cols="2">
                                <v-text-field v-model="item.ord" color="blue" hide-details outlined dense type="number" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0"
                                style="border-left: 1px solid #dbdbdb !important;" cols="3">
                                <span class="d-flex align-center justify-center" style="text-align:center;">
                                    <v-btn icon color='blue200' @click="onSave(i)">
                                        <v-icon>mdi-content-save-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon color='grey006' v-if="item.bldInfPk > 0" @click="onDelete(i)">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                    <v-btn class="" color="grey006" icon height="26" v-else
                                        @click="onDelete1(i)"><v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </v-form>
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'프로그램 평가'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selBldInf, insBldInf, delBldInf } from '../../api/opr.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

export default {
    name: 'oprFeeInfList',

    components: {
        btnModalDelete,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selBldInf()
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        selBldInf: function () {
            selBldInf(this.$store.getters.getFcltyNum)
                .then((response) => (this.selBldInfAfter(response.data)))
                .catch((error) => console.log('connect error opr/selBldInf : ' + error))
        },
        selBldInfAfter: function (res) {
            this.items = res
        },
        addRow() {
            let idx = this.items.length - 1
            let bldNm = ''

            if (idx > -1) {
                bldNm = this.items[idx].bldNm
            }

            let addObj = {
                bldFlr: '',
                bldNm: bldNm,
                fcltyNum: '',
                bldInfPk: 0,
                useYn: '1',
            }
            this.items.unshift(addObj)
        },
        onSave(key) {
            if (this.$refs.mdlform.validate()) {
                let obj = {
                    ord: this.items[key].ord,
                    bldNm: this.items[key].bldNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bldInfPk: this.items[key].bldInfPk,
                    useYn: '1',
                    userNm: this.$store.getters.getUserNm,
                }
                insBldInf(obj)
                    .then((response) => (this.insBldInfAfter(response.data)))
                    .catch((error) => console.log(error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        insBldInfAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()
                this.$emit('reload', true)
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete1(key) {
            this.items.splice(key, 1)
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key
                this.dialogDelete = true
            } else {
                if (key != 0) {
                    this.items.splice(key, 1)
                }
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items[this.key].bldInfPk > 0) {
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bldInfPk: this.items[this.key].bldInfPk,
                    userNm:this.$store.getters.getUserNm,
                }
                delBldInf(obj)
                    .then((response) => (this.delBldInfAfter(response.data)))
                    .catch((error) => console.log(error))
                } else {
                    this.items.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delBldInfAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.onShow()
                this.$emit('reload', true)
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onShow() {
            this.selBldInf()
        },
        onClose: function () {
            this.$emit('isClose', '.')
            this.selBldInf()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        items: [],
        dialogDelete: false,
    }),
};
</script>