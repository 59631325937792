<template>
    <div>
        <div class="d-flex justify-end pl-1 pr-2">
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon style="padding-top: 2px;" small>{{list.icon}}</v-icon>
                    <span class="fontOneRem ml-1">{{list.text}}</span>
                </v-btn>
                <v-dialog v-if="list.type == 'dialogue' && list.visible"
                    v-model="dialog[i]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs" v-on="on"
                                :class="list.class" height="30" :color="list.color" :disabled="list.disabled"
                                samll rounded>
                                <v-icon style="padding-top: 2px;" small>{{list.icon}}</v-icon>
                                <span class="fontOneRem ml-1">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <btnModal v-if="list.text == '저장'" ref="basicSave" @madalState="onModal" :sltTab="tab" :sltSubTab="subTab"></btnModal>
                        <btnModalReEnt v-else-if="list.text == '재입소'" @madalState="onModal"></btnModalReEnt>
                        <btnModalExtLink
                            v-else-if="list.text == '퇴소/연계기록지'"
                            @madalState="onModal" @midLoad="bnfcrListReLoad"
                            @onMsg="onInfMsg" @onSndMsg="sendLinkMsgAct" @onRcrdPrt="rcrdPrt">
                        </btnModalExtLink>
                        <CnslnJrnls
                            v-else-if="list.text == '상담일지'"
                            :prtBnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
                            :prtYear="new Date().getFullYear()" :cjMtd="$parent.$parent.cjMtd"
                            @isClose="onModal">
                        </CnslnJrnls>
                        <btnModalHist v-else-if="list.text == '정보변경 이력'" @madalState="onModal" @objChanger="bnfcrInfoChanger"></btnModalHist>
                        <btnModalCntrcHist v-else-if="list.text == '계약이력'" @madalState="onModal"></btnModalCntrcHist>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <span class="d-flex justify-center">
                <v-btn
                    v-if="outlinedBtn[0].visible"
                    :class="outlinedBtn[0].class" :color="outlinedBtn[0].color"
                    :disabled="outlinedBtn[0].disabled" @click="menu.splice(1, 1, true)"
                    height="30" samll rounded outlined>
                    <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                    <span class="fontOneRem ml-1">{{outlinedBtn[0].text}}</span>
                </v-btn>
                <v-btn
                    v-if="outlinedBtn[1].visible"
                    :class="outlinedBtn[1].class" :color="outlinedBtn[1].color"
                    :disabled="outlinedBtn[1].disabled" @click="sttrmCndtnPrt()"
                    height="30" samll rounded outlined>
                    <v-icon style="padding-top: 2px;" small>{{outlinedBtn[1].icon}}</v-icon>
                    <span class="fontOneRem ml-1">{{outlinedBtn[1].text}}</span>
                </v-btn>
                <v-btn
                    v-if="outlinedBtn[2].visible"
                    :class="outlinedBtn[2].class" :color="outlinedBtn[2].color"
                    :disabled="outlinedBtn[2].disabled" @click="bnfcrInfoPrt()"
                    height="30" samll rounded outlined>
                    <v-icon style="padding-top: 2px;" small>{{outlinedBtn[2].icon}}</v-icon>
                    <span class="fontOneRem ml-1">{{outlinedBtn[2].text}}</span>
                </v-btn>
            </span>
        </div>

        <v-divider class="my-2"></v-divider>

        <v-tabs hide-slider v-model="tab" color="white" class="black--text"
            active-class="bodyColor" center-active>
            <v-tab v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
        </v-tabs>

        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

        <v-tabs-items v-model="tab">
            <v-tab-item eager transition="fade-transition">
                <tab1 ref="bnfcrInfo" @bnfcrTrigger="bnfcrListReLoad" style="max-height: 721px;"></tab1>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab2 ref="bnfcrCntInf" @subTab="subTabManager" style="max-height: 721px;"></tab2>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab3 ref="bnfcrCntDcmnt" style="max-height: 721px;"></tab3>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <tab4 ref="bnfcrDocMgmt" style="max-height: 721px;"></tab4>
            </v-tab-item>
             <v-tab-item transition="fade-transition">
                <tab5 ref="bnfcrCctvBdrmMgmt" v-if="tab==4" style="max-height: 721px;"></tab5>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="menu[0]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(0, 1, false)"></ReportMgmt>
        </v-dialog>
        <v-dialog v-model="menu[1]" content-class="round" max-width="800" persistent>
            <CnfrOfdmMdl ref="cnfrOfdmMdl" @madalState="menu.splice(1, 1, false)"></CnfrOfdmMdl>
        </v-dialog>
        <v-dialog v-model="menu[2]" max-width="1100" persistent>    
            <bizMsgMdl 
                ref="bizMsgMdl"
                :bizMsgData="bizMsgInfo.mmbrPk"
                :bizMsgDocClcd="bizMsgInfo.docClcd"
                :bizMsgBzPk="bizMsgInfo.bzPk"
                :bizMsgShow="menu[2]"
                :bizMsgTitle="bizMsgInfo.bzTitle"
                :bizMsgCntnt="bizMsgInfo.bzCntnt"
                @madalState="menu.splice(2, 1, false)">
            </bizMsgMdl>
        </v-dialog>
        <v-dialog v-model="menu[3]" max-width="620" persistent>
            <btnModalDelete :title="delMdlTtl" :contents="delMdlCntnt" @madalState="onDelMdl"></btnModalDelete>
        </v-dialog>
    </div>
</template>

<script>
import tab1 from '../../components/bnfcr/BnfcrInfrmMgmtWrt.vue';
import tab2 from '../../components/bnfcr/BnfcrInFrmMgmtCntInf.vue';
import tab3 from '../../components/bnfcr/BnfcrCnsntDcmntBepr.vue';
import tab4 from '../../components/bnfcr/BnfcrDocMgmt.vue';
import tab5 from '../../components/bnfcr/BnfcrCctvBdrmMgmt.vue';
import btnModal from '../../components/bnfcr/BnfcrBasicSave.vue';
import btnModalReEnt from '../../components/bnfcr/BnfcrBasicReEnt.vue';
import btnModalExtLink from '../../components/bnfcr/BnfcrExtLinkRec.vue';
import CnslnJrnls from '../../components/bnfcr/CnslnJrnlsModal.vue';
import btnModalHist from '../../components/bnfcr/BnfcrMgmtHist.vue';
import btnModalCntrcHist from '../../components/bnfcr/BnfcrCntrcMgmtHist.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CnfrOfdmMdl from '../../components/bnfcr/CnfrOfdmMdl.vue';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';

export default {
    name: 'bnfcrInfrmMgmt',
    
    components: {
        tab1,
        tab2,
        tab3,
        tab4,
        tab5,
        btnModal,
        btnModalReEnt,
        btnModalExtLink,
        CnslnJrnls,
        btnModalHist,
        btnModalCntrcHist,
        btnModalDelete,
        CnfrOfdmMdl,
        ReportMgmt,
        bizMsgMdl,
    },

    created: function(){
        
        if(this.$store.getters.getBnfcrState.bnmmbr != 0){
            this.filledBtn[2].disabled = false
            this.filledBtn[3].disabled = false
            this.filledBtn[4].disabled = false
            this.filledBtn[5].disabled = false
            this.filledBtn[6].disabled = false
            this.filledBtn[7].disabled = false
            this.outlinedBtn[0].disabled = false
            this.outlinedBtn[1].disabled = false
            this.outlinedBtn[2].disabled = false
        }
    },

    computed: {
        stcdRctn: function() {
            return this.$store.getters.getEntStcd
        },
        getBnmmbrPk(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
        getBnfcrCntrcInfPk(){
            return this.$store.getters.getBnfcrState.bnfcrCntrcInfPk
        },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
    
        })
    },

    watch: {
        'tab': function () {
            this.$refs.bnfcrCntInf.isSubTab(0)

            if(this.tab == 0){
                this.filledBtn.forEach((tab, i) => {
                    if(tab.text == '정보변경 이력')
                        this.filledBtn[i].visible = true
                    else if(tab.text == '계약이력')
                        this.filledBtn[i].visible = false
                });

                this.filledBtn[0].disabled = false
                this.filledBtn[0].text = '신규'
                this.filledBtn[1].disabled = false
                this.filledBtn[3].disabled = false
                this.filledBtn[4].disabled = false
                this.filledBtn[7].disabled = false
                this.outlinedBtn[1].visible = false
                this.outlinedBtn[2].visible = true
            }
            else if(this.tab == 1){
                this.filledBtn.forEach((tab, i) => {
                    if(tab.text == '정보변경 이력')
                        this.filledBtn[i].visible = false
                    else if(tab.text == '계약이력')
                        this.filledBtn[i].visible = true
                });
                if(this.$store.getters.getBnfcrState.bnfcrCntrcInfPk == 0)
                    this.filledBtn[0].disabled = true
                else
                    this.filledBtn[0].disabled = false
                this.filledBtn[0].text = '계약갱신'
                this.filledBtn[1].disabled = false
                this.filledBtn[3].disabled = false
                this.filledBtn[4].disabled = false
                this.filledBtn[7].disabled = true
                this.outlinedBtn[1].visible = true
                this.outlinedBtn[2].visible = false
            }
            else if(this.tab == 2){
                this.filledBtn.forEach((tab, i) => {
                    if(tab.text == '정보변경 이력')
                        this.filledBtn[i].visible = false
                    else if(tab.text == '계약이력')
                        this.filledBtn[i].visible = true
                });

                this.filledBtn[0].disabled = true
                this.filledBtn[0].text = '신규'
                this.filledBtn[1].disabled = false
                this.filledBtn[3].disabled = false
                this.filledBtn[4].disabled = false
                this.filledBtn[7].disabled = true
                this.outlinedBtn[1].visible = false
                this.outlinedBtn[2].visible = false
            }
            else if(this.tab == 3 || this.tab == 4){
                this.filledBtn.forEach((tab, i) => {
                    if(tab.text == '정보변경 이력')
                        this.filledBtn[i].visible = false
                    else if(tab.text == '계약이력')
                        this.filledBtn[i].visible = false
                });
                this.filledBtn[0].disabled = true
                this.filledBtn[0].text = '신규'
                this.filledBtn[1].disabled = true
                this.filledBtn[3].disabled = true
                this.filledBtn[4].disabled = true
                this.filledBtn[7].disabled = true
                this.outlinedBtn[1].visible = false
                this.outlinedBtn[2].visible = false
            }               
            else
                console.log('unkown tab checked error!!')
    
        },
        stcdRctn: function (value) {
            if(value == '퇴소' || value == 4 ){
                this.filledBtn[1].visible = false
                this.filledBtn[2].visible = true
            }
            else {
                this.filledBtn[1].visible = true
                this.filledBtn[2].visible = false
            }
        },
        getBnmmbrPk(value){
            this.bnmmbrPk = value

            if(value != 0 || value != '0'){
                this.filledBtn[2].disabled = false
                this.filledBtn[3].disabled = false
                this.filledBtn[4].disabled = false
                this.filledBtn[5].disabled = false
                this.filledBtn[6].disabled = false
                this.filledBtn[7].disabled = false
                this.outlinedBtn[0].disabled = false
                this.outlinedBtn[1].disabled = false
                this.outlinedBtn[2].disabled = false
            }
            else{
                this.filledBtn[2].disabled = true
                this.filledBtn[3].disabled = true
                this.filledBtn[4].disabled = true
                this.filledBtn[5].disabled = true
                this.filledBtn[6].disabled = true
                this.filledBtn[7].disabled = true
                this.outlinedBtn[0].disabled = true
                this.outlinedBtn[1].disabled = true
                this.outlinedBtn[2].disabled = true
            }
        },
        getBnfcrCntrcInfPk(value){
            if(value == 0) this.tabItems[2].enter = true
            else this.tabItems[2].enter = false

            if(this.$store.getters.getBnfcrState.bnfcrCntrcInfPk == 0 && this.tab == 1)
                this.filledBtn[0].disabled = true
            else
                this.filledBtn[0].disabled = false
        },
    },
    
    methods: {
        getList: function() {
            //this.$refs.bnfcrInfo.revokeBlob()
            this.$refs.bnfcrInfo.getInfoList()
            this.$refs.bnfcrInfo.getFmlyList()
            this.$refs.bnfcrInfo.getGalFmlyList()
            this.$refs.bnfcrCntInf.getCntrcList(this.$store.getters.getBnfcrState)

            this.tabItems[1].enter = false
            this.tabItems[3].enter = false
            this.tabItems[4].enter = false
            if(this.$store.getters.getBnfcrState.bnfcrCntrcInfPk == 0)
                this.tabItems[2].enter = true
            else
            this.tabItems[2].enter = false
        },
        listRefresh: function() {
            
        },
        clearForm: function () {
            if(this.tab != 1)
                this.tab = 0
            
            this.tabItems[1].enter = true
            this.tabItems[2].enter = true
            this.tabItems[3].enter = true
            this.tabItems[4].enter = true
            this.$refs.bnfcrInfo.clearFilds()
        },
        onEnterCheck: function() {
            if(this.$store.getters.getBnfcrState.bnmmbr == 0) this.tab=0
        },
        onClickBtn: function(text) {
            if(text == '저장'){
                //this.$refs.bnfcrInfo.insBnfcrFull()
            }
            else if(text == '신규') {
                this.tabItems[1].enter = true
                this.tabItems[2].enter = true
                this.tabItems[3].enter = true
                this.tabItems[4].enter = true
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '', entDt: '', lvDt: ''
                });

                this.$emit('midTableItem', 'clear')
                this.$refs.bnfcrInfo.clearFilds()
            }
            else if(text == '계약갱신') {
                let endDt = this.$refs.bnfcrCntInf.$refs.cntInf.bnfcrCntrc.cntrcPrdEnd

                this.delMdlTtl = '이전 계약종료일자가' + endDt + '입니다.              계약갱신 진행하시겠습니까?'
                this.delMdlCntnt = '계약갱신 전에 이전 계약의 종료일자를 확인하시고 필요시 이전 계약종료일자를 수정 후 계약갱신을 진행하시기 바랍니다.'

                this.menu.splice(3, 1, true)
            }
            else if(text == '삭제') {
                let nm = ''
                let chc = this.$parent.$parent.items.findIndex( v => v.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr )
                if(chc > -1)
                    nm = this.$parent.$parent.items[chc].name

                this.delMdlTtl = nm + ' 기본정보'
                this.delMdlCntnt = '삭제 하시겠습니까?'
                this.menu.splice(3, 1, true)
            }
        },
        bnfcrListReLoad: function(value) {
            if(value == 'trigger')
                this.$emit('midTableItem', 'refresh')
        },
        bnfcrInfoChanger: function(value) {
            if(value == true){
                this.$refs.bnfcrInfo.hisBnfcrInfoChanger()
            }
        },
        bnfcrCntrcChanger: function(value) {
            if(value == true){
                this.$refs.bnfcrCntInf.hisBnfcrCntrcChanger()
                this.$refs.bnfcrCntDcmnt.hisBnfcrCntrcDcmntChanger()
            }
        },
        onModal: function(chk) {
            if(chk == 9999){
                this.$refs.bnfcrInfo.formValidationFull()
                let chkData = this.$store.getters.getValidationSucc

                if(chkData) this.$refs.bnfcrInfo.insBnfcrFull()
                else this.$refs.bnfcrInfo.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])

                this.dialog.splice(1, 1, false)
            }
            else if(chk == 9998){
                this.$refs.bnfcrCntInf.intrcData()
                this.dialog.splice(1, 1, false)
            }
            else if(chk == 9997){
                this.$refs.bnfcrCntInf.intrcItmData()
                this.dialog.splice(1, 1, false)
            }
            else if(chk == 9996){
                // this.$refs.bnfcrInfo.formValidationFull()
                // let chkData = this.$store.getters.getValidationSucc
                // let hstry = this.$store.getters.getBnfcrSaveArea
                // if(hstry != null && hstry != ''){
                //     if(chkData) this.$refs.bnfcrInfo.reEntBnfcrFull()
                //     else this.$refs.bnfcrInfo.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
                // }
                this.dialog.splice(2, 1, false)
            }
            else if(chk == 9995){
                this.dialog.splice(4, 1, false)
            }
            else
                this.dialog.splice(chk, 1, false)

        },
        onDelMdl: function(chc) {
            if(chc == 9990 && this.delMdlCntnt.indexOf('계약') == -1){
                if(this.$store.getters.getBnfcrState.bnfcr == 0)
                    this.$refs.bnfcrInfo.snackControll([true, 2500, '삭제 실패', 'warning'])
                else
                    this.$refs.bnfcrInfo.delInfo()
            }
            else if(chc == 9990 && this.delMdlCntnt.indexOf('계약') > -1){
                this.$refs.bnfcrCntInf.getCntrcListNew(this.$store.getters.getBnfcrState)
            }

            this.menu.splice(3, 1, false)
        },
        subTabManager: function(value){
            this.subTab = value
        },
        onInfMsg: function (txt){
            this.$refs.bnfcrInfo.snackControll([true, 2500, txt, 'info'])
        },
        rprtActCall: function (){
            this.prtThrowObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.prtThrowObj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.menu.splice(0, 1, true)
        },
        sttrmCndtnPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYYYY = ''
            this.prtThrowObj.prtCd = '6'

            if(this.$refs.bnfcrCntInf.bnfcrCntrc.bnfcrCntrcInfPk != 0)
                this.prtThrowObj.list[0].bzPk.push(this.$refs.bnfcrCntInf.bnfcrCntrc.bnfcrCntrcInfPk)

            this.rprtActCall()
        },
        bnfcrInfoPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYYYY = ''
            this.prtThrowObj.prtCd = '51'

            if(this.$refs.bnfcrCntInf.bnfcrCntrc.bnfcrCntrcInfPk != 0)
                this.prtThrowObj.list[0].bzPk.push(this.$refs.bnfcrCntInf.bnfcrCntrc.bnfcrCntrcInfPk)

            this.rprtActCall()
        },
        cnsnFlmnPrt: function (bPk, yyyy){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.prtCd = '37'
            this.prtThrowObj.inqYYYY = yyyy
            this.prtThrowObj.list[0].bzPk.push(bPk)

            this.rprtActCall()
        },
        // 연계기록지 출력
        rcrdPrt: function (lrPk){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzClcd.splice(0)
            this.prtThrowObj.inqYYYY = ''
            this.prtThrowObj.list[0].bzPk.push(lrPk)
            this.prtThrowObj.prtCd = '3'

            this.rprtActCall()
        },
        sendMsgAct: function (pk){
            this.bizMsgInfo.mmbrPk.splice(0)
            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = this.$store.getters.getBnfcrState.name
            this.bizMsgInfo.bzPk = pk

            this.menu.splice(2, 1, true)
        },
        // 연계기록지 발송 팝업
        sendLinkMsgAct: function (lrpk, dt){
            let tmp = this.$moment(dt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bizMsgInfo.mmbrPk.splice(0)
            this.bizMsgInfo.mmbrPk.push(this.$store.getters.getBnfcrState.bnmmbr)
            this.bizMsgInfo.bzCntnt = tmp + ' 연계기록지'
            this.bizMsgInfo.bzPk = lrpk
            this.bizMsgInfo.bizMsgVal1 = tmp

            this.bizMsgInfo.bzTitle = tmp + ' 연계기록지'
            this.bizMsgInfo.docClcd = 13
            
            this.menu.splice(2, 1, true)
        }
    },

    data: () => ({
        tab: 0,
        subTab: 0,
        bnmmbrPk: 0,
        delMdlTtl: '',
        delMdlCntnt: '',
        menu: [false, false, false, false,],
        dialog: [false, false, false, false, false, false, false, false, false, false ],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', width:'600px', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'dialogue', width:'500px', visible: true, disabled: false, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '재입소', type: 'dialogue', width:'600px', visible: false, disabled: true, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '퇴소/연계기록지', type: 'dialogue', width:'900px', visible: true, disabled: true, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '상담일지', type: 'dialogue', width:'1300px', visible: true, disabled: true, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '정보변경 이력', type: 'dialogue', width:'1100px', visible: true, disabled: true, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '계약이력', type: 'dialogue', width:'1100px', visible: false, disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '입소사실확인', width:'1000px', disabled: true, visible: true },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '표준약관', width:'1000px', disabled: true, visible: false },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '수급자출력', width:'1000px', disabled: true, visible: true },
        ],
        tabItems: [
            { index: 0, tab: '수급자 기본정보', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 1, tab: '계약정보', style: 'font-size: 1.2rem !important;', enter: true },
            { index: 2, tab: '동의 및 제공서류', style: 'font-size: 1.2rem !important;', enter: true },
            { index: 3, tab: '기타 서류', style: 'font-size: 1.2rem !important;', enter: true },
            { index: 4, tab: 'CCTV 동의', style: 'font-size: 1.2rem !important;', enter: true },
        ],
        prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, bnfcrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '4', inTxt1: '', inTxt2: '', inqYYYY: '', list: [ { bzClcd: [], bzPk: [] } ], },
        bnfcrInfo: null,
        bizMsgInfo: {
            mmbrPk: [], docClcd: 0, bzPk: 0, bzTitle: '', bzCntnt: '', bizMsgVal1: '',
        },
    }),
};
</script>
<style>
    .v-input .v-label{ line-height: 21px; }
    .btnBorder { border: 1px solid #ccc; }
    .v-tabs-bar { height: 36px; }
    .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) { color: black; background-color: #ededed; }
</style>
