<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            
            <div class="d-flex overflow-y-auto" style="height:550px;">       
                
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:50%;">            
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">운동장애 및 관절제한 평가</span>
                    </div>
                    <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center pl-3 ma-0" cols="3">
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 pa-0 ma-0" cols="9"> 
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="12">
                                    <span class="black--text font-weight-medium">운동장애정도</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                                <v-col class="d-flex pa-0 ma-0" cols="12">
                                    <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">운동장애없음</span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">불안전운동장애</span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">완전운동장애</span>
                                    </div>
                                </v-col>                                
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in group1" :key="i" class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0 pl-3" cols="3">
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="9">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-0 ma-0" cols="12">                                    
                                    <v-radio-group v-model="item.itmVal" :rules="[rules.required]" style="width:100%;" class="pa-0 pa-0" hide-details row>
                                        <div v-for="(loop,j) in codeItem7" :key="j" class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">                                            
                                            <span>
                                                <v-radio class="pa-0 ma-0 mr-2" 
                                                    :ripple="false" 
                                                    :label="loop.valcdnm" 
                                                    :value="loop.valcd"                                                   
                                                >
                                                </v-radio>                                                
                                            </span>
                                        </div>                                
                                    </v-radio-group>                                    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0 mt-1" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center pl-3 ma-0" cols="3">
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 pa-0 ma-0" cols="9"> 
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="12">
                                    <span class="black--text font-weight-medium">관절제한정도</span>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                                <v-col class="d-flex pa-0 ma-0" cols="12">
                                    <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">제한없음</span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">좌/우 관절제한</span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">양관절제한</span>
                                    </div>
                                </v-col>                                
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in group2" :key="group1.length+i" class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0 pl-3" cols="3">
                            <span class="black--text font-weight-medium">{{item.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="9">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-0 ma-0" cols="12">                                    
                                    <v-radio-group v-model="item.itmVal" :rules="[rules.required]" style="width:100%;" class="pa-0 pa-0" hide-details row>
                                        <div v-for="(loop,j) in codeItem8" :key="j" class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">                                            
                                            <span>
                                                <v-radio class="pa-0 ma-0 mr-2" 
                                                    :ripple="false" 
                                                    :label="loop.valcdnm" 
                                                    :value="loop.valcd"                                                   
                                                >
                                                </v-radio>                                                
                                            </span>
                                        </div>                                
                                    </v-radio-group>                                    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0 pl-3" cols="3">
                            <span class="black--text font-weight-medium">{{items1.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="9">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-0 ma-0" cols="12">                                    
                                    <v-radio-group v-model="items1.itmVal" :rules="[rules.required]" style="width:100%;" class="pa-0 pa-0" hide-details row>
                                        <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">                                            
                                            <span>
                                                <v-radio class="pa-0 ma-0 mr-2" 
                                                    :ripple="false" 
                                                    label="없음" 
                                                    value="2"                                                   
                                                >
                                                </v-radio>                                                
                                            </span>
                                        </div>   
                                        <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">                                            
                                            <span>
                                                <v-radio class="pa-0 ma-0 mr-2" 
                                                    :ripple="false" 
                                                    label="있음" 
                                                    value="1"                                                   
                                                >
                                                </v-radio>                                                
                                            </span>
                                        </div>    
                                        <div class="d-flex justify-center align-center pa-1" style="width:33%;border-left: 1px solid #dbdbdb !important;">
                                        </div>                           
                                    </v-radio-group>                                    
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0 pl-3" cols="3">
                            <span class="black--text font-weight-medium">{{items2.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="9" style="border-left: 1px solid #dbdbdb !important;">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-1 ma-0" cols="12">                                    
                                    <v-textarea v-model="items2.evltn" class="rmrksArea" no-resize  
                                        rows="10" outlined dense hide-details>
                                    </v-textarea>                                
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>

                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:50%;">            
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">근력</span>
                    </div>
                    <v-row class="pa-0 ma-0 mt-1" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-centerma-0" cols="1">
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                        <v-col class="greyE01 pa-0 ma-0" cols="11"> 
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-0 ma-0" cols="12">
                                    <div class="d-flex justify-center align-center pa-1" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">오른쪽</span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">왼쪽</span>
                                    </div>
                                </v-col>                                
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row  class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="1">
                            <span class="black--text font-weight-medium">상지</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="11">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-0 ma-0" cols="12">                                    
                                    <div v-for="(item, i) in group3" :key="i" class="d-flex justify-center align-center pa-1" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                        <v-radio-group v-model="item.itmVal" :rules="[rules.required]" style="width:100%;" class="pa-0 pa-0" hide-details row>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="5점" value="5"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="4점" value="4"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="3점" value="3"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="2점" value="2"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="1점" value="1"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="0점" value="0"></v-radio>
                                        </v-radio-group>                                    
                                    </div>                                
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row  class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0" cols="1">
                            <span class="black--text font-weight-medium">하지</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="11">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-0 ma-0" cols="12">                                    
                                    <div v-for="(item, i) in group4" :key="i" class="d-flex justify-center align-center pa-1" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                        <v-radio-group v-model="item.itmVal" :rules="[rules.required]" style="width:100%;" class="pa-0 pa-0" hide-details row>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="5점" value="5"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="4점" value="4"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="3점" value="3"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="2점" value="2"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="1점" value="1"></v-radio>
                                            <v-radio class="pa-0 ma-0 mr-1" :ripple="false" label="0점" value="0"></v-radio>
                                        </v-radio-group>                                    
                                    </div>                                
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0 pl-3" cols="1">
                            <span class="black--text font-weight-medium">{{items3.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="11" style="border-left: 1px solid #dbdbdb !important;">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-1 ma-0" cols="12">                                    
                                    <v-textarea v-model="items3.evltn" class="rmrksArea" no-resize  
                                        rows="12" outlined dense hide-details>
                                    </v-textarea>                              
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <div class="d-flex align-center mt-2">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">기본동작평가</span>
                    </div>
                    <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;"> 
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="12">
                            <span class="black--text font-weight-medium">항목</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;"> 
                        <v-col class="justify-center align-center pa-1 ma-0" cols="12">
                            <span v-for="(item, i) in group5" :key="i">
                                <span class="d-inline-block pa-0 ma-0" :style="setStyle(item.itmCdnm.length)">
                                    <v-checkbox v-model="item.itmVal"  hide-details :ripple="false" :label="item.itmCdnm" value="1"></v-checkbox>
                                </span>                                     
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="greyE01 d-flex justify-center align-center py-1 ma-0 pl-3" cols="3">
                            <span class="black--text font-weight-medium">{{items4.itmCdnm}}</span>
                        </v-col>
                        <v-col class="d-flex pa-0 ma-0" cols="9" style="border-left: 1px solid #dbdbdb !important;">                            
                            <v-row class="pa-0 ma-0" no-gutters>
                                <v-col class="d-flex pa-1 ma-0" cols="12">                                    
                                    <v-textarea v-model="items4.evltn" class="rmrksArea" no-resize  
                                        rows="10" outlined dense hide-details>
                                    </v-textarea>                                
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-form>    
    </v-sheet>
</template>
<script>
import { getMultiStcd } from '../../api/index.js';
import { insBnfcrExamHis } from '../../api/bnftSrv.js';

export default {
    name: 'PhyscThrpyEvltnTab1',

    props : {
        
    },

    computed: {
    },  

    watch:{
    },

    created:function(){   
    },

    components: {
    },
    
    mounted: function(){

    },
        
    methods: {    
        onShow(){
            
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:200px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:80px;"
            }else{
                style="width:100px;"
            }
            return style;
        },  
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        setData(data){  
            this.onCodeload() 
            
            this.data = data   
            this.group1.splice(0)
            this.group2.splice(0)
            this.group3.splice(0)
            this.group4.splice(0)
            this.group5.splice(0)

            this.data.map(x=>{  
                 x.itmValCd = x.valClcd
                if(x.itmCd == "1"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group1.push(x)
                }else if(x.itmCd == "2"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group1.push(x)
                }else if(x.itmCd == "3"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group1.push(x)
                }else if(x.itmCd == "4"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group1.push(x)
                }else if(x.itmCd == "5"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group2.push(x)
                }else if(x.itmCd == "6"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group2.push(x)
                }else if(x.itmCd == "7"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group2.push(x)
                }else if(x.itmCd == "8"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group2.push(x)
                }else if(x.itmCd == "9"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group2.push(x)
                }else if(x.itmCd == "10"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.group2.push(x)
                }else if(x.itmCd == "11"  && x.clsfc2Cd == "1" && x.clsfc3Cd == "1"){
                    this.items1 = x
                }else if(x.clsfc2Cd == "1" && x.clsfc3Cd == "999"){
                    this.items2 = x
                }else if(x.itmCd == "1"  && x.clsfc2Cd == "2" && x.clsfc3Cd != "999"){
                    this.group3.push(x)
                }else if(x.itmCd == "2"  && x.clsfc2Cd == "2" && x.clsfc3Cd != "999"){
                    this.group3.push(x)
                }else if(x.itmCd == "3"  && x.clsfc2Cd == "2" && x.clsfc3Cd != "999"){
                    this.group4.push(x)
                }else if(x.itmCd == "4"  && x.clsfc2Cd == "2" && x.clsfc3Cd != "999"){
                    this.group4.push(x)
                }else if(x.clsfc2Cd == "2" && x.clsfc3Cd == "999"){
                    this.items3 = x
                }else if(x.clsfc2Cd == "3" && x.clsfc3Cd != "999"){
                    this.group5.push(x)
                }else if(x.clsfc2Cd == "3" && x.clsfc3Cd == "999"){
                    this.items4 = x
                }
            })            
            this.$refs.tabform.resetValidation();
        },

        onCodeload:function(){
            getMultiStcd(['4','7','8'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        
        codeMapping:function(res){
            this.codeItem4 = res["vCd4"]
            this.codeItem7 = res["vCd7"]
            this.codeItem8 = res["vCd8"]
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        


        reset(){
            this.$refs.tabform.reset()
        },

        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'106',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                //욕구사정평가 setting
                let examList = []

                this.group1.map(x=>{examList.push(x)})
                this.group2.map(x=>{examList.push(x)})
                this.group3.map(x=>{examList.push(x)})
                this.group4.map(x=>{examList.push(x)})
                this.group5.map(x=>{examList.push(x)})
                examList.push(this.items1)
                examList.push(this.items2)
                examList.push(this.items3)
                examList.push(this.items4)
                
                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('physcThrpyEvltnTrigger', res)            
        },        
        selExamList:function(){
            let examList = []

            this.group1.map(x=>{examList.push(x)})
            this.group2.map(x=>{examList.push(x)})
            this.group3.map(x=>{examList.push(x)})
            this.group4.map(x=>{examList.push(x)})
            this.group5.map(x=>{examList.push(x)})
            examList.push(this.items1)
            examList.push(this.items2)
            examList.push(this.items3)
            examList.push(this.items4)

            return examList
        },
    },
    
    data: () => ({ 
        data:[],
        obj:{},
        obj2:{}, 
        group1:[],
        group2:[],
        group3:[],
        group4:[],
        group5:[],
        group6:[],
        codeItem4:[],
        codeItem7:[],
        codeItem8:[],
        items1:{},
        items2:{},
        items3:{},
        items4:{},
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
        },
        
    }),
};
</script>