<template>
    <v-sheet class="ma-0 pa-0">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="" cols="2">
                <v-text-field
                    class="ma-0 pa-0 ntcChc" background-color="white" append-icon="mdi-magnify"
                    width="100px" height="37px" single-line rounded outlined dense filled hide-details label="제목 조회"
                    @input="setSearchText">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-3 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-data-table
                    class="" locale="ko" height="420"
                    :headers="headers" :items='items' :search="schDummy" :custom-filter="customFilter"
                    :page.sync="page" :items-per-page="itemsRows" @page-count="pageCount = $event"
                    show-select hide-default-header hide-default-footer fixed-header dense>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr class="">
                                <th class="px-2 py-2 ntcTbHeader" style="width: 8%;">
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 67%;">
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 15%;">
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>
                                <th class="px-2 ntcTbHeader" style="width: 10%; border-right: initial !important;">
                                    <span class="black--text">{{ props.headers[4].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr class="fixedCol" @click="tableClickEvent(item.ntcBbsPk)">
                            <td 
                                class="fixedCol0 tableBR tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ totCnt - item.rn + 1 }}</span>
                            </td>
                            <td 
                                class="fixedCol0 tableBR tableBB py-2">
                                <span class="fontOneRem">{{ item.title }}</span>
                            </td>
                            <td 
                                class="fixedCol0 tableBR tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtDt }}</span>
                            </td>
                            <td 
                                class="fixedCol0 tableBB py-2"
                                style="text-align: center;">
                                <span class="fontOneRem">{{ item.crtr }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col class="" cols="12">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    total-visible="10">
                </v-pagination>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selNtsBbsList, } from '../../api/othr.js';

export default {
    name: 'FcltyNtcBrdWdt',

    props : {
        prtFcltyNum: { type: String, default: '10000000000' },
    },
        
    components: {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selNtsBbsList(this.prtFcltyNum)
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        selNtsBbsList: function (code) {
            selNtsBbsList(code)
                .then((response) => (this.selNtsBbsListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selNtsBbsList : ' + error))
        },
        selNtsBbsListAfter: function (res) {
            this.totCnt = res.rnTot
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)
            this.items = res.list
        },
        tableClickEvent: function (pk) {
            if (this.selectedItem == -1) {
                this.$parent.$parent.sltNtcBbsPk = pk
            }
            else if (this.sltNtcBbsPk != pk) {
                this.$parent.$parent.sltNtcBbsPk = pk
            }
            else {
                //Form Clear
                this.$parent.$parent.sltNtcBbsPk = -1
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            // value: items, search: search key, filter: array column
            let col0 = filter.title.includes(this.schField)
            
            return col0;
        },
    },

    data: () => ({
        page: 1,
        pageCount: 0,
        totCnt: 0,
        itemsRows: 10,
        sltNtcBbsPk: -1,
        schField: '',
        schDummy: '',
        items: [],
        schChkField: { valClcdnm: [], valCdDesc: [], target: '' },
        headers: [
            { text: '번호', value: 'rn', sortable: true, align: 'center', class: 'black--text' },
            { text: '제목', value: 'name', sortable: true, align: 'center', class: 'black--text' },
            { text: '등록일', value: 'age', sortable: true, align: 'center', class: 'black--text' },
            { text: '작성자', value: 'entDt', sortable: true, align: 'center', class: 'black--text' },
        ],
    }),
};
</script>