<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">현금영수증&nbsp;내역&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-row class="mt-3 pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
            <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">입금일</span>              
            </v-col>            
            <v-col class="pa-0 ma-0 d-flex justify-start align-center" cols="5"> 
                <span class="d-inline-block py-1 px-1" style="width: 140px;">
                    <CmmDateComp v-model="strtymd" :maxDt='endymd'></CmmDateComp>
                </span>                
                <span class="pa-1">&#126;</span>
                <span class="d-inline-block ml-1" style="width: 140px;">
                    <CmmDateComp v-model="endymd" :minDt='strtymd'></CmmDateComp>
                </span>
                <span class="py-1 px-2" style="width:110px;">
                    <v-select 
                        v-model="monthCd"                               
                        :items="monthCode"
                        :key="forceRender"
                        item-text="valcdnm"
                        item-value="valcd"
                        dense hide-details outlined
                        @change="getMonth"
                    /> 
                </span>
            </v-col>
            <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">상태</span>              
            </v-col> 
            <v-col class="pa-0 ma-0 d-flex justify-start align-center" cols="5"> 
                <span class="py-1 px-2" style="width:200px;">
                <v-select 
                    v-model="tradeType"                               
                    :items="typeCode"
                    :key="forceRender"
                    item-text="valcdnm"
                    item-value="valcd"
                    dense hide-details outlined                    
                /> 
                </span>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
            <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">수급자명</span>              
            </v-col>            
            <v-col class="pa-0 ma-0 d-flex justify-start align-center" cols="5"> 
                <span class="d-inline-block py-1 px-1" style="width: 140px;">
                    <v-text-field v-model="srchWd1" color="blue" hide-details outlined dense></v-text-field>
                </span>                
            </v-col>
            <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">신분확인번호</span>              
            </v-col> 
            <v-col class="pa-0 ma-0 d-flex justify-start align-center" cols="5"> 
                <span class="py-1 px-2" style="width:200px;">
                    <v-text-field v-model="srchWd2" color="blue" hide-details outlined dense></v-text-field>
                </span>
                
                <v-btn @click="getCashBillList"
                    :class="'white--text'" samll height="28" :color="'blueBtnColor'" rounded>
                    <v-icon small>mdi-magnify</v-icon>
                    <span style="font-size: 1rem;">조회</span>
                </v-btn>  
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 d-flex align-center mt-3" no-gutters>
            <v-icon style="font-size: 28px; width: 12px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-2">현금영수증&nbsp;내역</span>
            <!-- <span class="text-h6 red--text font-weight-bold ml-2">&#40;테스트베드&#41;</span> -->
            
            <v-spacer></v-spacer>
            <span class="black--text font-weight-medium">전체</span>
            <span class="px-1">&#58;</span>
            <span class="pr-3">{{cashBilltot}}</span>
            <span class="black--text font-weight-medium">발송전</span>
            <span class="px-1">&#58;</span>
            <span class="pr-3">{{cashBillbfCnt}}</span>
            <span class="black--text font-weight-medium">발송완료</span>
            <span class="px-1">&#58;</span>
            <span class="pr-3">{{cashBilltot - cashBillbfCnt}}</span>
            <span v-if="loading">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!loading">  
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(list.text)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>                
                </span>
            </span>
        </v-row>
        <v-form ref="form" lazy-validation> 
            <div :class="['d-flex mt-2',cashBillList.length > 13 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:3%;text-align:center;">
                    <span class="d-inline-block ml-1" style="width:26px;">
                        <v-checkbox
                            class="checkIcon" v-model="allChckr" :key="forceRender" @click="allChnger"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:7%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">입금일</span>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:5%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">입금방법</span>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:5%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">수급자명</span>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:5%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">입금자명</span>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:5%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">청구월</span>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:6%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">입금액</span>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:7%;text-align:center;border-left: 1px solid #dbdbdb !important;">                                        
                    <div class='pr-1'>
                        <v-checkbox
                            v-model="clncExcAllYn"
                            class="checkIcon" 
                            style="font-size: 2rem;" 
                            true-value="2" false-value="1" 
                            @click="setAllChk('1')"
                            on-icon="mdi-checkbox-multiple-marked" off-icon="mdi-checkbox-multiple-blank-outline"
                            hide-details>
                        </v-checkbox>
                    </div>
                    <div class="black--text font-weight-medium" style="text-align:center;">진료약제비</div>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center d-flex justify-center align-center" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">                    
                    <div class='pr-1'>
                        <v-checkbox
                            v-model="dctrExcAllYn"
                            class="checkIcon" 
                            style="font-size: 2rem;" 
                            true-value="2" false-value="1" 
                            @click="setAllChk('2')"
                            on-icon="mdi-checkbox-multiple-marked" off-icon="mdi-checkbox-multiple-blank-outline"
                            hide-details>
                        </v-checkbox>
                    </div>
                    <div class="">
                        <div class="black--text font-weight-medium" style="text-align:center;">계약의사</div>
                        <div class="black--text font-weight-medium" style="text-align:center;">진찰비</div>
                    </div>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center d-flex justify-center align-center" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <div class="">
                        <div class="black--text font-weight-medium" style="text-align:center;">현금영수증</div>
                        <div class="black--text font-weight-medium" style="text-align:center;">발행금액</div>
                    </div>
                </div>
                <div class="greyE01 py-2 d-flex justify-center align-center d-flex justify-center align-center" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">신분확인번호</span>
                    <span class="pl-1">
                        <v-btn
                            width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 23)" icon>
                            <v-icon size="20">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </span>
                </div>  
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">현금승인번호</span>
                </div>    
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">발행일시</span>
                </div> 
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:7%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">상태</span>
                </div> 
                <div class="greyE01 py-2 d-flex justify-center align-center" style="width:10%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">처리</span>
                </div>       
            </div>
            <div v-if="loading" style="height:450px;">
                <div
                
                    class="d-inline-block"
                    style="width: 100%; height: 38px; text-align: center;">
                    <v-progress-linear
                        class="mb-1" color="yellow darken-2"
                        indeterminate>
                    </v-progress-linear>
                    <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
                </div>            
            </div>
            <div v-else class="overflow-y-auto" style="height:450px;">
                <div v-if="cashBillList.length > 0">
                    <div v-for="(list, j) in cashBillList" :key="j" class="d-flex" style="border-bottom: 1px solid #dbdbdb !important;font-size:1rem;" no-gutters>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:3%;">                           
                            <v-checkbox
                                class="checkIcon" v-model="list.checkr" :key="forceRender" :disabled="list.disChekr"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" @change="allChekrFunc"
                                hide-details>
                            </v-checkbox>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:7%;border-left: 1px solid #dbdbdb !important;">                                                                        
                            {{list.dpsDt?.beforeDateFormatDot()}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                            {{list.dpsMthdCd}}
                        </div>                        
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">                            
                            {{list.name}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                            {{list.dpsNm}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                            {{$moment(list.clmYymm,'YYYYMM').format('YYYY.MM')}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                            {{list.clmAmt?.toLocaleString('ko-KR')}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:7%;border-left: 1px solid #dbdbdb !important;">                            
                            <span class="" style="width: 100px;">
                                <v-checkbox 
                                    v-model="list.clncExcYn" hide-details :ripple="false" 
                                    @click="setCaluCost(list)" :label="list.clnPhrAmt?.toLocaleString('ko-KR')"
                                    :disabled="list.code > 0 && list.tradetype == '승인거래'" 
                                    true-value="2" false-value="1"
                                    value="2"></v-checkbox>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">                            
                            <span class="" style="width: 100px;">
                                <v-checkbox 
                                    v-model="list.dctrExcYn" hide-details :ripple="false" 
                                    @click="setCaluCost(list)"  
                                    :label="list.cntrDctAmt?.toLocaleString('ko-KR')"
                                    :disabled="list.code > 0 && list.tradetype == '승인거래'"
                                    true-value="2" false-value="1"
                                    value="2"></v-checkbox>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                            {{Number(list.supplycost)?.toLocaleString('ko-KR')}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                            <span v-if="list.code > 0 && list.tradetype == '승인거래'">
                                {{list.identitynum}}
                            </span>
                            <span v-else style="width:100%">
                                <v-text-field v-model="list.identitynum" color="blue" hide-details outlined dense></v-text-field>
                            </span>
                        </div> 
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                           {{list.confirmnum}}
                        </div>     
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                           <!-- {{list.tradedate?.beforeDateFormatDot()}} -->
                           <span>{{list.crtDt}}</span>
                        </div>     
                        <div class="pa-1 ma-0 d-flex justify-center align-center" style="width:7%;border-left: 1px solid #dbdbdb !important;">                            
                            <div v-if="list.code > 0">
                                <div>발행완료</div>
                                <div>&#40;{{list.tradetype}}&#41;</div>
                            </div>
                            <div v-else-if="list.sndSttsCd =='3' && list.code < 1" class="red--text">발행실패</div>
                            <div v-else>발행전</div>
                        </div>                             
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                            <span v-if="list.code > 0" class="d-flex align-center"> 
                                <span v-if="list.copayDpsHisPk > 0" class="pr-1">
                                    <v-btn icon @click="onCashBillHist(list)">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="23" v-bind="attrs" v-on="on">mdi-clipboard-text-outline</v-icon>
                                            </template>
                                            <span>현금영수증 내역</span>
                                        </v-tooltip>
                                    </v-btn>  
                                </span>                               
                                 <span>
                                    <v-btn icon @click="onCashBillPrint(list)">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="23" v-bind="attrs" v-on="on">mdi-printer-outline</v-icon>
                                            </template>
                                            <span>인쇄</span>
                                        </v-tooltip>
                                    </v-btn>  
                                    <!-- <v-btn                                        
                                        min-width="40" width="40" height="24"
                                        @click="onCashBillPrint(list)" samll outlined>
                                        <span style="font-size: 0.9rem; padding-bottom: 1px !important;">인쇄</span>
                                    </v-btn>                               -->
                                </span>
                                <span class="pl-1">
                                    <v-btn
                                        v-if="list.tradetype == '승인거래'"
                                        min-width="40" width="40" height="24" color="red"
                                        @click="onCnclCashBill(list.copayDpsHisPk)" samll outlined>
                                        <span style="font-size: 0.9rem; padding-bottom: 1px !important;">취소</span>
                                    </v-btn>
                                    <v-btn
                                        v-if="list.tradetype == '취소거래'"
                                        min-width="60" width="60" height="24" color="blueBtnColor"
                                        @click="setCashBill(list)" samll outlined>
                                        <span style="font-size: 0.9rem; padding-bottom: 1px !important;">재발행</span>
                                    </v-btn>
                                </span>
                            </span>
                            <span v-else>                                
                                <v-btn
                                    min-width="80" width="80" height="24" color="blueBtnColor"
                                    @click="setCashBill(list)" samll outlined>
                                    <span style="font-size: 0.9rem; padding-bottom: 1px !important;">영수증발행</span>
                                </v-btn>
                            </span>
                        </div>       
                    </div>
                </div>
                <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                        데이터가 없습니다.
                    </div> 
                </div>
            </div>
        </v-form>
        <div class="d-flex justify-center align-center pa-1 ma-0" >
            <v-spacer></v-spacer>
            <span class="black--text" style="font-size:0.9rem">※ YOYANGSYS를 통해 발행된 현금 영수증만 관리가 가능합니다. 발행된 현금영수증은 매일밤에 국세청으로 전송됩니다.</span>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'현금영수증'" :contents="'취소하시겠습니까?'" @madalState="setCancelCashBill"></btnModalDelete>  
            </v-dialog>
        </div>
        <v-dialog v-model="dialog[0]" content-class="round" max-width="1000" persistent>
            <CopayCashBillHisIndv v-if="dialog[0]" ref="copayCashBillHisIndv" @isClose="dialog.splice(0, 1, false)"></CopayCashBillHisIndv>
        </v-dialog>
    </v-sheet>
</template>

<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CopayCashBillHisIndv from './CopayCashBillHisIndv.vue';
import { getMultiStcd,} from '../../api/index.js';
import { selCopayDpsCashBillList, selCashBillPopUpUrl, insCopayDpsCashBill, insCancelCashBill, insMultiCashBill} from '../../api/bnfcr.js';

export default {
    name: 'CopayDpstCashBill',

    props : {

    },
        
    components: {
        CmmDateComp,
        btnModalDelete,
        CopayCashBillHisIndv,
    },

    created: function(){
        this.onCodeload()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.strtymd    =  this.$moment().format('YYYY-MM')+'-01'
            this.endymd     =  this.$moment().endOf('month').format('YYYY-MM-DD')
            this.getCashBillList()
        })
    },

    computed: {
       
    },

    watch:{
    //    'strtymd':function(){
    //         if(this.dataCall) this.getCashBillList()
    //    },
    //    'endymd':function(){
    //         if(this.dataCall) this.getCashBillList()
    //    }
    },
    
    methods: {
        onClickBtn:function(text){
            if(text == "일괄발행") this.setMultiCashBill()
        },
        onCodeload:function(){
            getMultiStcd(['109'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        onReLoad:function(){
            this.getCashBillList()
            this.$parent.$parent.$parent.$parent.getCopayDpsList()
        },
        codeMapping:function(res){
            this.vCd109 = res.vCd109
        },
        getCashBillList: function () {
            this.dataCall   = false
            this.loading    = true
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum, 
                inqYmdStart : this.strtymd?.afterDateFormatHyp(), 
                inqYmdEnd   : this.endymd?.afterDateFormatHyp(), 
                tradeType   : this.tradeType,
                srchWd1     : this.srchWd1,
                srchWd2     : this.srchWd2,
            }
            selCopayDpsCashBillList(obj)
                .then((response) => ( this.getCashBillListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayDpsCashBillList : ' + error))
        },
        getCashBillListAfter: function (res) {
            this.cashBilltot    = 0
            this.cashBillbfCnt  = 0
            this.cashBillList.splice(0)            
            let code84 = this.$parent.$parent.$parent.$parent.$parent.$parent.vCd84
            res.list.forEach(el => {
                el.checkr = '2'
                el.disChekr = false
                
                code84.forEach(code => {
                    if(code.valcd == el.dpsMthdCd) el.dpsMthdCd = code.valcdnm
                })

                // this.vCd109.forEach(cd => {
                //     if(cd.valcd == el.sndSttsCd) el.sndSttsCdNm = cd.valcdnm
                // })

                if(el.cashBillHisPk > 0) el.disChekr = true

                if(el.cashBillHisPk == null || el.cashBillHisPk < 1){
                    el.supplycost = el.clmAmt
                    el.clncExcYn = '2'
                    el.dctrExcYn = '2'
                } else {
                    if(el.tradetype == '취소거래') el.supplycost = el.clmAmt
                }


                this.cashBillList.push(el)
                this.cashBilltot++

                if(el.sndSttsCd == null) this.cashBillbfCnt++
            });
            this.dataCall   = true
            this.loading    = false
        },
        setCashBill:function(item){
            if(!item.identitynum?.isEmptyVal()){
                this.snackControll([true, 2500, '신분확인번호를 입력해주세요.', 'warning'])
                return
            }

            if(/^[0-9]*$/.test(item.identitynum) == false){
                this.snackControll([true, 2500, '신분확인번호를 숫자로 입력해주세요.', 'warning'])
                return
            }

            if(item.supplycost > 0){
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm,  
                    bnMmbrPk        : item.bnMmbrPk,
                    supplycost      : item.supplycost,   
                    identitynum     : item.identitynum,       
                    customername    : item.dpsNm,
                    copayDpsHisPk   : item.copayDpsHisPk,
                    dpsPrcHisPk     : item.dpsPrcHisPk,
                    clncExcYn       : item.clncExcYn,
                    dctrExcYn       : item.dctrExcYn,
                    dpsAmt          : item.clmAmt,
                    clncPhrmcFee    : item.clnPhrAmt,
                    cntrcDctrFee    : item.cntrDctAmt,
                }
    
                this.loading    = true
                insCopayDpsCashBill(obj)
                    .then((response) => ( this.setCashBillAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/insCopayDpsCashBill : ' + error))
            }else{
                this.snackControll([true, 2500, '발행금액을 확인해주세요.', 'warning'])
            }
            
            
        },
        setCashBillAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.onReLoad()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.loading    = false
        },
        setMultiCashBill:function(){
            let param       = {list:[]}
            let validate1   = true
            let validate2   = true

            this.cashBillList.forEach(item => {
                if(item.disChekr == false && item.checkr == '1' && item.supplycost > 0){
                    let obj = {
                        fcltyNum        : this.$store.getters.getFcltyNum,
                        userNm          : this.$store.getters.getUserNm,  
                        bnMmbrPk        : item.bnMmbrPk,
                        supplycost      : item.supplycost,   
                        identitynum     : item.identitynum,       
                        customername    : item.dpsNm,
                        copayDpsHisPk   : item.copayDpsHisPk,
                        clncExcYn       : item.clncExcYn,
                        dctrExcYn       : item.dctrExcYn,
                        dpsPrcHisPk     : item.dpsPrcHisPk,
                        dpsAmt          : item.clmAmt,
                        clncPhrmcFee    : item.clnPhrAmt,
                        cntrcDctrFee    : item.cntrDctAmt,
                    }

                    if(!item.identitynum?.isEmptyVal()){
                        validate1 = false
                    }

                    if(/^[0-9]*$/.test(item.identitynum) == false){
                        validate2 = false
                    }

                    if(validate1 && validate2) param.list.push(obj) 
                }                
            });

            if(validate1  == false){
                this.snackControll([true, 2500, '신분확인번호를 입력해주세요.', 'warning'])
                return
            }

            if(validate2 == false){
                this.snackControll([true, 2500, '신분확인번호를 숫자로 입력해주세요.', 'warning'])
                return
            }

            if(param.list.length > 0){
                this.loading    = true
                insMultiCashBill(param)
                    .then((response) => ( this.setMultiCashBillAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/insMultiCopayDpsCashBill : ' + error))
            }else{
                this.snackControll([true, 2500, '대상을 찾을 수 없습니다.', 'warning'])
            }
            
        },
        setMultiCashBillAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.onReLoad()
                this.allChckr = '2'
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.loading    = false
        },
        onCashBillPrint:function(item){
            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum,
                mgtkey          : item.mgtkey,  
            }

            selCashBillPopUpUrl(obj)
                .then((response) => ( this.onCashBillPrintAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCashBillPopUpUrl : ' + error))
        },
        onCashBillPrintAfter: function(res){
            this.onPopup(res.result)
        },
        onCnclCashBill:function(pk){
            this.copayDpsHisPk = pk
            this.dialogDelete = true
        },
        setCancelCashBill:function(res){
            if (res == 9990) {
                let obj = {
                    fcltyNum        : this.$store.getters.getFcltyNum,
                    userNm          : this.$store.getters.getUserNm, 
                    copayDpsHisPk   : this.copayDpsHisPk,
                }

                this.loading    = true
                insCancelCashBill(obj)
                    .then((response) => ( this.setCancelCashBillAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/insCancelCashBill : ' + error))
            } else if (res == 7) {
                this.copayDpsHisPk = 0
                this.dialogDelete = false
            }
        },
        setCancelCashBillAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])                
                this.onReLoad()              
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
            this.loading    = false
        },
        getMonth:function(){
            this.endymd     = this.$moment(this.strtymd).add(this.monthCd,'month').format('YYYY-MM-DD')
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.cashBillList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.cashBillList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        allChekrFunc: function () {
            let tCh = '1'
            this.cashBillList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },
        onPopup: function (url) {
            let ttl = '전자세급계산서 팝빌'
            let loc = (document.body.offsetWidth / 2) - (200 / 2)
            let posn = 'left=' + loc.toString() + ', top=0, '
            let options = 'width=810, height=720, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no'
            
            window.open(url, ttl, posn+options)
        },
        setAllChk:function(key){
            this.cashBillList.forEach(el => {
                let bl = (el.code > 0 && el.tradetype == '승인거래')

                if(!bl){
                    if(key == '1'){                        
                        if(this.clncExcAllYn == '2') el.clncExcYn = '2'
                        else  el.clncExcYn = '1'                        
                        this.setCaluCost(el)
                    }
                    
                    if(key == '2'){                        
                        if(this.dctrExcAllYn == '2') el.dctrExcYn = '2'
                        else  el.dctrExcYn = '1'
                        this.setCaluCost(el)
                    }
                }
            })
        },
        
        setCaluCost:function(target){
            let bl = (target.code > 0 && target.tradetype == '승인거래')

            if(!bl){               
                let clmAmt      = Number(target.clmAmt)

                if(target.clncExcYn != '2') {
                    clmAmt  -= Number(target.clnPhrAmt)
                }

                if(target.dctrExcYn != '2') {                    
                    clmAmt  -= Number(target.cntrDctAmt)
                }

                target.supplycost = clmAmt
            }
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        onCashBillHist:function(target){
            this.sltCashBill.name           = target.name
            this.sltCashBill.dpsDt          = target.dpsDt
            this.sltCashBill.dpsMthdCd      = target.dpsMthdCd
            this.sltCashBill.dpsNm          = target.dpsNm
            this.sltCashBill.clmYymm        = target.clmYymm
            this.sltCashBill.clmAmt         = target.clmAmt
            this.sltCashBill.clnPhrAmt      = target.clnPhrAmt
            this.sltCashBill.cntrDctAmt     = target.cntrDctAmt
            this.sltCashBill.bnMmbrPk       = target.bnMmbrPk
            this.sltCashBill.copayDpsHisPk  = target.copayDpsHisPk
            this.dialog.splice(0,1,true)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        
    },

    data: () => ({
        forceRender: 0,
        copayDpsHisPk:0,
        dpsPrcHisPk:0,
        clncExcAllYn:'2',
        dctrExcAllYn:'2',
        loading:true,
        dataCall:true,
        srchWd1:'',
        srchWd2:'',
        tradeType:'0',
        allChckr: '2',
        strtymd:'',
        endymd:'',
        monthCd:1,
        dialog:[false,],
        vCd109:[{valcdnm:'',valcd:''}],
        monthCode: [{valcdnm:'1개월',valcd:1},{valcdnm:'2개월',valcd:2},{valcdnm:'3개월',valcd:3}],
        typeCode: [{valcdnm:'전체',valcd:'0'},{valcdnm:'발행전',valcd:'3'},{valcdnm:'발행완료(승인거래)',valcd:'1'},{valcdnm:'발행완료(취소거래)',valcd:'2'}],
        sltCashBill:{name : '',dpsDt : '',dpsMthdCd : '',dpsNm : '',clmYymm : '',clmAmt : 0,clnPhrAmt : 0,cntrDctAmt : 0,bnMmbrPk:0,copayDpsHisPk:0},
        cashBilltot:0,
        cashBillbfCnt:0,
        cashBillList:[],
        filledBtn: [
            { icon: 'mdi-receipt-text-send-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '일괄발행', type: 'action', width:'800px', disabled: false, },            
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
        },
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete: false,
    }),
};
</script>