<template>
    <div class="d-inline-block d-flex">
        <v-sheet class="d-inline-block" width="360" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span v-if="routerRocation == 'BnfcrInfrmMgmt'">1-1. 수급자&nbsp;정보관리</span>
                    <span v-else-if="routerRocation == 'StayOutMgmt'">1-3. 외출&#183;외박&nbsp;관리</span>
                    <span v-else-if="routerRocation == 'BnftSrvMgmt'">2-2. 급여제공&nbsp;계획&nbsp;및&nbsp;결과평가</span>
                    <span v-else-if="routerRocation == 'CareBlnftSrvRcrd'">3-2. 요양&nbsp;급여&nbsp;제공기록</span>
                </span>
            </div>
            <div class="d-flex align-center pa-0 mt-4 mx-3">
                <exBtn
                    ref="exBtnClose0"
                    :btnData="exBtn[0]" :sltElmnt="sltElmnt"
                    @btnClosed="expandClose" @changeCheck="filterSearchD1" />
                <exBtn
                    class="ml-1" ref="exBtnClose1"
                    :btnData="exBtn[1]"
                    @btnClosed="expandClose" @changeCheck="filterSearchD2" />
                <v-spacer></v-spacer>
                <v-text-field
                    class="ma-0 pa-0 nameSearch" width="100px" height="35px"
                    label="이름조회" background-color="white" append-icon="mdi-magnify"
                    @input="setSearchText" 
                    single-line rounded outlined dense filled hide-details>
                </v-text-field>
            </div>
            <v-row class="pa-0 mt-2 ml-3 mr-2" no-gutters>
                <v-col style="text-align: center;" cols="12">
                    <span class="blue004--text">※&nbsp;숨겨진 항목은 하단 스크롤을 이용해서 보세요.</span>
                </v-col>
            </v-row>
            <v-data-table
                class="bnfcrMidList mt-3 ml-3 mr-2" style="position: relative;" height="660" locale="ko"
                v-model="selectedItemIndex" :search="schDummy" :custom-filter="customFilter"
                :headers="headers" :loading="tableLoad" :loading-text="tableText" :items="items" item-key="rn"
                fixed-header disable-pagination hide-default-footer dense>

                <template v-slot:header="props"> 
                    <thead>
                        <tr>
                            <th
                                v-for="(list, i) in props.headers" :key="i">
                                {{list.text}}
                            </th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item, index }'>
                    <tr class="fixedCol" v-if="selectedItemIndex.indexOf(item.rn) > -1" @click="rowClicked(item)">
                        <td class="fixedTable fixedPstn0 fixedCol0" style="width: 70px; padding-left: 20px; background: #ebf6fe;">
                            {{ index+1 }}
                        </td>
                        <td class="fixedTable fixedPstn1 fixedCol0" style="background: #ebf6fe;">{{item.name}}</td>
                        <td class="fixedCol0" style="background: #ebf6fe;">{{item.entStcdNm}}</td>
                        <td class="fixedCol0" style="background: #ebf6fe;">{{item.lvnRmNm}}</td>
                        <td class="fixedCol0" style="background: #ebf6fe;">{{item.age}}</td>
                        <td class="fixedCol0" style="background: #ebf6fe;">
                            <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                            <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                        </td>
                        <td class="fixedCol0" style="background: #ebf6fe;">
                            <span :class="'ic-level level0'+item.acknwRtng">
                                {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                            </span>
                        </td>
                        <td class="fixedCol0" style="background: #ebf6fe;">{{ $moment(item.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</td>
                        <td class="fixedCol0" style="background: #ebf6fe;">
                            <span :class="[$moment(item.acknwPrdEnd, 'YYYYMMDD').isAfter($moment()) ? '' : 'red--text']">
                                {{ $moment(item.acknwPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD') }}
                            </span>
                        </td>
                    </tr>
                    <tr class="fixedCol" v-else @click="rowClicked(item)">
                        <td class="fixedTable fixedPstn0 fixedCol0" style="padding-left: 20px;">
                            {{ index+1 }}
                        </td>
                        <td class="fixedTable fixedPstn1 fixedCol0">{{item.name}}</td>
                        <td class="fixedCol0" style="">{{item.entStcdNm}}</td>
                        <td class="fixedCol0">{{item.lvnRmNm}}</td>
                        <td class="fixedCol0">{{item.age}}</td>
                        <td class="fixedCol0">
                            <v-img class="ic-sex ml-1" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                            <v-img class="ic-sex ml-1" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                        </td>
                        <td class="fixedCol0">
                            <span :class="'ic-level level0'+item.acknwRtng">
                                {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                            </span>
                        </td>
                        <td class="fixedCol0">{{ $moment(item.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</td>
                        <td class="fixedCol0">
                            <span :class="[$moment(item.acknwPrdEnd, 'YYYYMMDD').isAfter($moment()) ? '' : 'red--text']">
                                {{ $moment(item.acknwPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD') }}
                            </span>
                        </td>
                    </tr>
                </template>

                <template v-slot:foot='{ items, pagination }'>
                    <div class="px-1 d-inline-block" style="width: 100%; position: absolute; bottom: -34px; left: 0;">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="d-flex align-center" cols="7">
                                <span class="fontOneRem">전체: {{ pagination.itemsLength }}명,&nbsp;&nbsp;</span>
                                <span class="fontOneRem">남: {{ items.filter( v => v.gndr == 'M').length }}명&nbsp;&nbsp;</span>
                                <span class="fontOneRem">여: {{ items.filter( v => v.gndr == 'F').length }}명</span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="d-flex" cols="4">
                                <v-spacer></v-spacer>
                                <span class="d-inline-block" style="width: 92px;">
                                    <v-checkbox
                                        v-model="entChgBox" :ripple="false"
                                        class="checkIcon" true-value="1" false-value="2" label="퇴소자 조회"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        hide-details>
                                    </v-checkbox>
                                </span>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </v-data-table>
        </v-sheet>
        <v-sheet class="d-inline-block rounded-lg rounded-l-0 overflow-auto pa-2" min-width="900px" max-width="1200px">
            <router-view ref="callDetail" @midTableItem="clearItem"></router-view>
        </v-sheet>
    </div>
</template>

<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';

import { bnfcrList, getPaymntFeeInf } from '../../api/bnfcr.js';
import { selLvnRmInf, selAckRtngBnftFeeList, selFcltyDpsInfInfo } from '../../api/opr.js';
import { getMultiStcd, getLvnRmTypList } from '../../api/index.js';

export default {
    name: 'bnfcrCnfgr',
    
    components: {
        exBtn,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)

            this.routerRocation = this.$route.name

            this.getCode(['62', '63', '64', '65', '104'])
            this.getLvnRmInf()
            this.getNbnftFeeInf()
            this.getBnftFeeList()
            this.getFcltyDpsInf()
            this.lvnRmList()

            this.getBnfcrList(null)
            this.sltElmnt = '퇴소'
            this.entChgBox = 2
        })
    },

    watch: {
        '$route' (to, from) {
            this.routerRocation = to.name
            this.selectedItemIndex.splice(0)
            this.getBnfcrList(null)
            this.entChgBox = 2
            console.log('Url Event from: ' + from.name)
        },
        'entChgBox': function () {
            this.exBtn[0].expandList.splice(0)

            if(this.entChgBox == 1){
                this.exBtn[0].expandList.push('퇴소')
                this.exBtn[0].mode = 'single'
                this.sltElmnt = '퇴소'
           
                this.getBnfcrList('4')
            }

            else{
                this.cdItems.forEach((code) => {
                    if(code.valcdnm != '퇴소')
                        this.exBtn[0].expandList.push(code.valcdnm)
                });
                this.exBtn[0].mode = ''
                this.sltElmnt = ''
       
                this.getBnfcrList(null)
            }

            this.selectedItemIndex.splice(0)
            this.$store.commit('setBnfcrState', {
                bnmmbr: 0, bnfcr: 0, name: '', entStcd: '', entDt: '', lvDt: ''
            });
            this.$refs.callDetail.clearForm()
        },
    },

    methods: {
        getCode: async function (array) {
            await getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },

        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },
        //시설 비급여 수가정보 조회
        getNbnftFeeInf: function () {
            getPaymntFeeInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getPaymntFeeInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selNbnftFeeInf : ' + error))
        },
        //수가정보 목록 조회
        getBnftFeeList: function () {
            selAckRtngBnftFeeList()
                .then((response) => ( this.getBnftFeeListAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selAckRtngBnftFeeList : ' + error))
        },
        //기관정보 조회
        getFcltyDpsInf: function () {
            selFcltyDpsInfInfo(this.$store.getters.getFcltyNum)
                .then((response) => ( this.fcltyDpsRst(response.data) ))
                .catch((error) => console.log('connect error /opr/selFcltyDpsInfInfo : ' + error))
        },

        lvnRmList: function () {
            getLvnRmTypList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.lvnRmTypList = response.data ))
                .catch((error) => console.log('connect error /opr/selLvnRmTypList : ' + error))
        },
        //수급자 목록 조회
        getBnfcrList: function (stCd){
            this.tableLoad = true
            this.items.splice(0)

            bnfcrList(this.$store.getters.getFcltyNum, stCd)
                .then((response) => ( this.tableLoading(response.data) ))
                .catch((error) => console.log('connect error bnfcr/selBnfcrList : ' + error))
        },

        tableLoading: function (res){
            if(res != null){
                if(res.length > 0){
                    res.forEach(itm => {
                        this.cdItems.forEach((cd) => {
                            if(itm.entStcd == cd.valcd)
                                itm.entStcdNm = cd.valcdnm
                        });//end foreach
                    });
                    this.items = res
                }
            }
            
            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                let tttt = 0
                this.items.forEach((pk, i) => {
                    if(pk.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr){
                        tttt = pk.rn
                        this.rowClicked({rn: i+1})
                    }
                });
                this.selectedItemIndex.push(tttt)
            }

            this.tableLoad = false
        },
        stCdMapping: function (res){
            this.acknwCode = res.vCd62
            this.copayCode = res.vCd63
            this.cjMtd = res.vCd104

            this.emailCode.push({ valcd: '0', valcdnm: '직접입력' })
            res.vCd64.forEach(cd => {
                this.emailCode.push(cd)
            });

            this.cdItems = res.vCd65
            if(this.entChgBox == 1){
                this.exBtn[0].expandList.splice(0)
                this.exBtn[0].expandList.push('퇴소')
            }
            else if(this.entChgBox == 2){
                this.exBtn[0].expandList.splice(0)
                this.cdItems.forEach((code) => {
                    if(code.valcdnm != '퇴소')
                        this.exBtn[0].expandList.push(code.valcdnm)
                });//end foreach
            }
            
        },

        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },

        getPaymntFeeInfAfter: function (res){
            this.feeInf = res
        },

        getBnftFeeListAfter: function (res){
            //acknwRtng, rtgFee
            this.bnftFeeList = res
        },

        fcltyDpsRst: function (res){
            //dpsDt: 납부일
            this.fcltyInf = res
        },

        rowClicked: function (row) {
            //console.log('rn :' + row.rn)
            this.toggleSelection(row.rn);
        },
        toggleSelection: function (keyID) {
            
            if (this.selectedItemIndex.includes(keyID)) {

                this.selectedItemIndex.splice(0)
                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '',  entDt: '', lvDt: ''
                });
                this.$refs.callDetail.clearForm()
            } 
            else {
                this.selectedItemIndex.splice(0)
                this.selectedItemIndex.push(keyID)
                this.$store.commit('setBnfcrState', {
                    bnmmbr: this.items[this.selectedItemIndex[0]-1].bnMmbrPk,
                    bnfcr: this.items[this.selectedItemIndex[0]-1].bnfcrPk,
                    name: this.items[this.selectedItemIndex[0]-1].name,
                    entStcd: this.items[this.selectedItemIndex[0]-1].entStcd,
                    entDt: this.items[this.selectedItemIndex[0]-1].entDt,
                    lvDt: ''
                });
                
                this.$refs.callDetail.clearForm()
                this.$refs.callDetail.getList()
            }
            
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.$refs.exBtnClose1.isClose()
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    break;
            }

        },
        clearItem: function (text) {
            if(text == 'clear'){
                this.selectedItemIndex.splice(0)

                this.$store.commit('setEntStcd', '');
            }
            else if(text == 'refresh'){
                this.selectedItemIndex.splice(0)

                this.entChgBox = 2
                this.getBnfcrList(null)
                
                //console.log('bnmm : ' + this.$store.getters.getBnfcrState.bnmmbr + ', bn : ' + this.$store.getters.getBnfcrState.bnfcr)
            }
        },
        filterSearchD1: function (sch) {
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD2: function (sch) {
            
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            

            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcdNm == stcd
                    else col1 = col1 || filter.entStcdNm == stcd
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((lvnRm, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == lvnRm
                    else col2 = col2 || filter.lvnRmNm == lvnRm
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            return col0 && col1 && col2;
        },
    },
    data: () => ({
        routerRocation: '',
        forceRender: 0,
        sltElmnt: '',
        entChgBox: 2,
        schField: '',
        schChkField1: [],
        schChkField2: [],
        schDummy: '',
        tableLoad: true,
        tableText: 'Loading...    Please Wait...',
        selectedItemIndex:[],
        cmprsDate: [],
        exBtn: [
            {
                index:  0,
                mode: '',
                btnTitle: '입소현황',
                btnIcon: 'house',
                exWidth: 104,
                exHeigth: 130,
                expandList: [],
            },
            {
                index:  1,
                btnTitle: '생활실',
                btnIcon: 'bed',
                exWidth: 124,
                exHeigth: 350,
                expandList: [],
            },
        ],
        headers: [
            { text: '연번', value: 'rn', sortable: true, width: '80', class: 'black--text bottomBorder fixedPstn0 bnfcrMidIndex'},
            { text: '수급자', value: 'name', sortable: true, width: '90', class: 'black--text bottomBorder fixedPstn1 bnfcrMidIndex'},
            { text: '현황', value: 'entStcd', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '생활실', value: 'lvnRmNm', sortable: true, width: '90', class: 'black--text bottomBorder'},
            { text: '나이', value: 'age', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '성별', value: 'gndr', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '등급', value: 'acknwRtng', sortable: true, width: '80', class: 'black--text bottomBorder'},
            { text: '입소일', value: 'entDt', sortable: true, width: '102', class: 'black--text bottomBorder'},
            { text: '인정만료', value: 'acknwPrdEnd', sortable: true, width: '102', class: 'black--text bottomBorder'},
        ],
        items: [],
        cdItems: [],
        acknwCode: [],
        copayCode: [],
        emailCode: [],
        cjMtd: [],
        lvnRmInf: [],
        fcltyInf: {},
        feeInf: { 
            lqdFoodFee: 0, msclnFee: 0, rmrks: '', snackCntCd: '', snackFee: 0, btyFee: 0, snackOfrClcd: '',
            nbnftFeeInfPk: 0, upBedFee1: 0, upBedFeeLmt: 0, upBedFee2: 0, aplStrt: '', lqdFoodClcd: '', aplEnd: '',
            mealCost: 0, mealCostLmt: 0
        },
        bnftFeeList: [],
        lvnRmTypList: [],
    }),
};
</script>

<style>
    .v-input { font-size: 14px; }
    .v-input__icon { height: 28px; }
    .v-input__control { width: 60px; }
    .v-input__append-inner { margin-top: 5px !important; }
    .v-text-field__slot label{ top: 5px !important; position: absolute; }

    .bnfcrMidList .v-data-table--fixed-header{
        position: sticky !important;
        z-index: 1; background: white;
    }

    .bnfcrMidList div { border-radius: 8px !important; }

    .bnfcrMidList .v-data-table__wrapper > table > thead > tr > th { border-bottom: 2px solid !important; font-size: 0.975rem !important; }

    .bnfcrMidIndex { z-index: 3 !important; }

    .fixedCol:hover .fixedCol0 { background: #ebf6fe !important; cursor: pointer !important; }

    .fixedPstn0 { left: 0px; }
    .fixedPstn1 { left: 80px; }
    .fixedPstn2 { left: 170px; }
    .fixedPstn3 { left: 250px; }
    .fixedPstn4 { left: 330px; }
    .fixedPstn5 { left: 430px; }

    .ic-sex{ width:20px;height:20px; z-index: 0; }
</style>