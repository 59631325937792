<template>
    <v-sheet>        
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <div class="d-flex justify-center align-center" v-if="progress">
                    <v-progress-circular            
                    :size="30"
                    color="primary"
                    indeterminate
                    ></v-progress-circular>
                    <span class="pl-1">처리중...</span>
                </div>
                <span v-if="!progress">
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                            :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span style="font-size: 1rem;">{{list.text}}</span>
                        </v-btn>
                    </span>
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col cols="12">
                <v-data-table hide-default-footer dense locale="ko" height="570"
                    hide-default-header fixed-header 
                    :headers="smsSndHeader" 
                    :items="smsSndList" 
                    class="" disable-pagination>

                    <template v-slot:header="{ props }" >
                        <thead>                                
                            <tr>                             
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:4%;">
                                    <div class="d-flex justify-center align-center">
                                        <span class="d-inline-block ml-1" style="width:26px;">
                                            <v-checkbox
                                                class="checkIcon" v-model="allChecker" :key="forceRender" @click="checkBoxMessage"
                                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                hide-details>
                                            </v-checkbox>
                                        </span>
                                    </div>
                                </th>
                               <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 8% !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 12% !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15% !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 27% !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 15% !important;">{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 10% !important;">{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT" style="width: 8% !important;">{{props.headers[6].text}}</th>
                            </tr>                       
                        </thead>
                    </template>     
                    <template v-slot:item='{ item }'>
                        <tr>                        
                            <td class="clmsBodyCommon tableBR" style="padding-left:11px !important">
                                <div class="d-flex justify-center align-center">
                                    <span style="width:26px;">
                                        <v-checkbox
                                            class="checkIcon" v-model="item.isSelected" :key="forceRender" :disabled="item.disChekr"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" @change="checkBoxIndex"
                                            hide-details>
                                        </v-checkbox>
                                    </span>
                                </div>
                            </td>                        
                            <td class="clmsBodyCommon tableBR">{{item.rcvNm}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.rcvNmDesc}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.celphn_1}}&#45;{{item.celphn_2}}&#45;{{item.celphn_3}}</td>
                            <td class="clmsBodyCommon tableBR">{{smsSndItmes.bizMsgCntnt}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.sndSttsNm}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.sndDt?.beforeDateFormatDot()}}</td>
                            <td :class="['clmsBodyCommon', smsSndItmes.bizMsgDocClcd == 4 ? '' : '']">
                                <div class="d-flex justify-center align-center">   
                                    <v-btn icon @click="onModal(item)">
                                        <v-icon>mdi-text-box-outline</v-icon>
                                    </v-btn>                              
                                </div>
                            </td>                            
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import { selEDocRcvList, insBizMsgNoSnd } from '../../api/othr.js'

export default {
    name: 'BizMsgSms',

    props : {       
        
    },
            
    components: {
        
    },

    computed: {
       
    },  

    watch:{        
    },

    created:function(){ 
        this.$nextTick(function () { 
            
        })
    },
    
    mounted: function(){
        
    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onSmsSend()
                    break;
            
                default:
                    break;
            }
        },
        setData:function(setObj){
            this.allChecker = '2'
            this.smsSndItmes = JSON.parse(JSON.stringify(setObj))  
            this.getEDocRcvList()      
        },
        getEDocRcvList:function(){ 

            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum, 
                eDocClcd:this.smsSndItmes.bizMsgDocClcd, 
                mmbrPks:this.smsSndItmes.bizMsgData, 
                bzPk:this.smsSndItmes.bizMsgBzPk, 
                bzClcd:this.smsSndItmes.bizMsgBzClcd, 
                sndMda:this.sndMda,
                msClcd:this.sndMda,
            }

            if(this.smsSndItmes.bizMsgData.length > 0){
                selEDocRcvList(obj)
                    .then((response) => ( this.getEDocRcvListAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/selEDocRcvList : ' + error))
            }else{                
                this.smsSndList.splice(0)
            }
        },
        getEDocRcvListAfter:function(res){  
            this.smsSndList.splice(0)
            if(res != undefined && res != '') {
                res.forEach(el => {
                    el.isSelected = '2' 
                    el.disChekr = false

                    this.smsSndList.push(el)
                });
            }
        },
        onSmsSend:function(){            
            let pObj = {  
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,                
                bizMsglist:[]
            }

            this.smsSndList.forEach(item => {

                if(item.isSelected == '1'){
                    let obj = {
                        sndMda:'2',
                        fcltyNum:this.$store.getters.getFcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        bzClcd:this.smsSndItmes.bizMsgBzClcd,
                        bzPk:this.smsSndItmes.bizMsgBzPk,
                        eDocClcd:this.smsSndItmes.bizMsgDocClcd,
                        prcClcdAr:this.smsSndItmes.bizMsgClcdAr,
                        prcCnfmAr:this.smsSndItmes.bizMsgCnfmAr,
                        prtCdAr:this.smsSndItmes.bizMsgPrtCdAr,
                        val1:this.smsSndItmes.bizMsgVal1,
                        val2:this.smsSndItmes.bizMsgVal2,
                        itmCtt1:this.smsSndItmes.bizMsgTitle,
                        itmCtt3:this.smsSndItmes.bizMsgCntnt,
                        itmCtt4:this.smsSndItmes.bizMsgItmCtt4,
                        itmCtt5:this.smsSndItmes.bizMsgItmCtt5,
                        itmCtt8:this.smsSndItmes.bizMsgItmCtt8,
                        prtCd:this.smsSndItmes.bizPrtCd,
                        ntcClcd:this.smsSndItmes.bizNtcClcd,
                        rcvCelphn1:item.celphn_1,
                        rcvCelphn2:item.celphn_2,
                        rcvCelphn3:item.celphn_3,
                        mmbrPk:item.mmbrPk,
                        bnMmbrPk:item.bnMmbrPk,
                        rcvNm:item.rcvNm,
                        rcvInfm:item.rcvNmDesc,
                        pstnCd:item.pstnCd,
                    }

                    if(this.smsSndItmes.bizMsgDocClcd == '1' || this.smsSndItmes.bizMsgDocClcd == '4' || this.smsSndItmes.bizMsgDocClcd == '8' || 
                    this.smsSndItmes.bizMsgDocClcd == '9' || this.smsSndItmes.bizMsgDocClcd == '13' || this.smsSndItmes.bizMsgDocClcd == '23' || this.smsSndItmes.bizMsgDocClcd == '24'){
                        obj.lnkUrl = this.$parent.$parent.$parent.$parent.encUrlObj(item.mmbrPk, item.rcvNm, this.smsSndItmes.bizMsgBzPk)
                    }

                    pObj.bizMsglist.push(obj)
                }
            });

            if(pObj.bizMsglist.length > 0){
                this.progress = true

                insBizMsgNoSnd(pObj)
                    .then((response) => ( this.onSmsSendAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
            }else{
                this.$emit('msgTrigger','대상자를 선택해주세요.')
            }
        },
        onSmsSendAfter:function(res){
            this.$emit('mdlTrigger',res)
            if(res.statusCode == 200){    
                this.allChecker='2'                                         
                this.getEDocRcvList()
            }
            this.progress = false
        },
        checkBoxMessage: function () {           
            if(this.allChecker == '1') {
                this.smsSndList.forEach(item => {
                    if(item.disChekr == false) item.isSelected = '1'
                });
            }
            else if(this.allChecker == '2') {
                this.smsSndList.forEach(item => {
                    if(item.disChekr == false) item.isSelected = '2'
                });
            }
        },
        checkBoxIndex: function () {            
            let tCh = '1'
            this.smsSndList.forEach(el => {
                if(el.isSelected == '2') tCh = '2'
            });
            this.allChecker = tCh
        },        
        onModal:function(obj){
            this.$parent.$parent.$parent.$parent.onModalViw(1,obj,this.smsSndItmes)
        },
        onSign:function(mmbr){
            this.$emit("onSignTrigger",mmbr)
        },
    },
    
    data: () => ({ 
        forceRender:0,
        sndMda:'2',  
        dialog:[false],
        allChecker:'2',
        progress:false,
        smsSndHeader:[
             { text: '수신자', value: '', align: 'center'},
             { text: '수신자 정보', value: '', align: 'center'},
             { text: '수신자 연락처', value: '', align: 'center'},
             { text: '내용', value: '', align: 'center'},
             { text: '발송상태', value: '', align: 'center'},
             { text: '발송일', value: '', align: 'center'},
             { text: '미리보기', value: '', align: 'center'},
        ],
        smsSndList:[],
        smsSndItmes:{bizMsgDocClcd:0},
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: '문자 발송', type: 'action', width:'600px', disabled: false, },
        ],
    }),
};
</script>