<template>
    <v-sheet class="ma-0 pa-0" rounded="md">
        <v-row class="pa-0 ma-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-center" cols="5">
                <vue-esign class="mt-4 rounded" style="border: 3px solid #4bc5e8;"
                    ref="esign" format="image/png" :width="400" :height="300"
                    :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor"/>
            </v-col>
            <v-col class="" cols="1">
                <div class="mt-4">
                    <v-btn color="blueBtnColor" height="30" @click="handleGenerate()" icon>
                        <v-icon>mdi-content-save-edit-outline</v-icon>
                    </v-btn>
                </div>
                <div class="mt-1">
                    <v-btn color="grey006" height="30" @click="handleReset()" icon>
                        <v-icon>mdi-eraser</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-sheet>
</template>

<script>
import { uploadSIgn } from '../../api/index.js';

export default {
    name: 'EmpSign',

    props : {
   
    },
        
    components: {
     
    },

    created: function(){
 
    }, 

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        setSign: function () {
            //직원(eSigClcd): 9
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.mmbrPk = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.empDtl.empInfo.mmbrPk
            obj.bzClcdPk = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.empDtl.empInfo.empPk
            obj.eSigClcd = '9'
            obj.sign = this.sign
            obj.userNm = this.$store.getters.getUserNm

            uploadSIgn(obj)
                .then((response) => ( this.$parent.$parent.$parent.$parent.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /cmm/uploadSIgn : ' + error))
        },
        handleReset: function () {
            this.$refs.esign.reset()
            this.sign = ''
        },
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res
                this.setSign()
            }).catch(err => {
                alert(err)
            })
        },
    },

    data: () => ({
        forceRender: 0,
        sign: '',
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
    }),
};
</script>