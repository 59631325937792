<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="background:#F7F8F9" @click="onClose">                
        <div class="pa-0">        
            <div style='padding:10px;'>
                <div style='width:600px;margin:0px auto;'>              
                    <div style='width:200px;'>
                        <img src='https://www.harrygnd.co.kr/publicAccess/img/yoyangsss.png' loading='lazy' width='150'>
                    </div>
                    <div style='margin-top:12px;padding:50px;text-align:center;background-color:#ffffff;border: 1px solid #E5E8EB;border-radius: 5px;'>
                        <div style='font-size:24px;font-weight: 900;'>{{title}}</div>
                        <div style='margin:30px;padding:20px 0px;text-align:left;min-height:200px;font-weight: 600;border-top:1px solid gray;border-bottom:1px solid gray' v-html="msg">
                            
                        </div>
                        <div style='width:200px;height:50px;line-height:50px;margin:10px auto;border-radius: 5px;'>
                            <!-- <a @click="onLink" style='color:white;font-weight: 900;text-underline:none ;text-decoration:none'>
                                {{btnNm}}
                            </a> -->
                            <v-btn @click="onLink" class="elevation-0" width="100%" min-width="100%">{{btnNm}}</v-btn>
                        </div>
                    </div>
                    <div style='margin-top:30px;text-align:center;'>
                        <div>
                            <a href='https://yoyangsys.co.kr' target='_blank' style='color:#2a9bed;font-weight: 600;text-underline:none ;text-decoration:none'>
                            고객센터 / 1:1 문의
                            </a>
                        </div>
                        <!-- <div>
                            <p style='margin:0px;padding:2px 0px;font-size:13px;padding-left:5px;'>(주)해리지앤디 / 대표  이승준 / 경기도 수원시 반달로 7번길 40 7층</p>
                            <p style='margin:0px;padding:2px 0px;font-size:13px;padding-left:5px;'>사업자등록번호  762-81-01713</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </v-sheet>
</template>
<script>
import { selESndMsgList } from '../../api/othr.js'

export default {
    name: 'EmpWageStmtMsgEmailViw',
    
    components: {  
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){      
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        
    },

    methods: {  
        onClose: function () {
            this.$emit('modalClose')
        },
        onLoad:function(item){
            this.lnkUrl = item.lnkUrl
            this.getESndMsgList(item)
        },
        getESndMsgList:function(item){             
            let title = this.$moment(item.yymm, 'YYYYMM', true).format('YYYY년MM월') +' 직원급여명세서'    
                   
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eDocClcd : '10',
                itmCtt1: title,
                itmCtt3:item.name,
                itmCtt4:this.$moment(item.yymm, 'YYYYMM', true).format('YYYY년MM월'), 
                sndMda:'3',
            }
            selESndMsgList(obj)
                .then((response) => ( this.getESndMsgListAfter(response.data) ))
                .catch((error) => console.log('connect error /othr/selESndMsgList : ' + error))
        },
        getESndMsgListAfter:function(res){
            if(res.length > 0){
                res.forEach(item => {
                    switch (item.itmClcd) {
                        case '1':
                            this.title = item.itmCntnt
                            break;
                        case '2':
                            this.msg = item.itmCntnt
                            break;
                        case '3':
                            this.btnNm = item.itmCntnt
                            this.btnLink = item.lnkUrl
                            break;
                        default:
                            break;
                    }
                    
                });
            }
        },
        onLink:function(){
            let ttl = '노인장기요양 급여제공 서비스 미리보기'
            let loc = (document.body.offsetWidth / 2) - (200 / 2)
            let posn = 'left=' + loc.toString() + ', top=0, '
            let options = 'width=380, height=740, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no'
            
            window.open(this.lnkUrl, ttl, posn+options)

            // this.windowRef = window.open(this.btnLink, "_blank");         
            // this.windowRef = null
        },
    },
    data: () => ({ 
        sObj:{},
        items:{},
        clcd:0,
        title:'',
        msg:'',
        btnNm:'상세보기',
        btnLink:'',
        windowRef: null,
        lnkUrl:'',
    }),
};
</script>