<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-center align-center">
                <span class="text-h4 font-weight-bold">프로그램&nbsp;선택</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <div class="mt-3">
                <v-row :class="[allPrgIMList.length > 10 ? 'mr-2__5' : '']" style="width:734px;" no-gutters>
                    <v-col class="greyE01 py-2" style="text-align:center;" cols="3">
                        <span class="black--text font-weight-medium" style="text-align:center;">프로그램 유형</span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align:center;" cols="3">
                        <span class="black--text font-weight-medium" style="text-align:center;">지표구분</span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align:center;" cols="4">
                        <span class="black--text font-weight-medium" style="text-align:center;">프로그램</span>
                    </v-col>
                    <v-col class="greyE01 py-2" style="text-align:center;" cols="2">
                        <span class="black--text font-weight-medium" style="text-align:center;">적용</span>
                    </v-col>
                </v-row>
            </div>
            <div v-if="allPrgIMList.length > 0" class="d-inline-block overflow-y-auto" style="width: 100%; height: 433.1px">
                <v-row
                    class="" style="width:734px; border-bottom: 1px solid #dbdbdb !important;"
                    v-for="(list, i) in allPrgIMList" :key="i" no-gutters>                    
                    <v-col class="py-2" style="text-align:center;" cols="3">
                        <span class="" style="text-align:center;">{{list.prgTypNm}}</span>
                    </v-col>
                    <v-col class="py-2" style="text-align:center;" cols="3">
                        <span class="" style="text-align:center;">{{list.idxCdnm}}</span>
                    </v-col>
                    <v-col class="py-2" style="text-align:center;" cols="4">
                        <span class="" style="text-align:center;">{{list.prgNm}}</span>
                    </v-col>
                    <v-col class="py-2" style="text-align:center;" cols="2">
                        <v-btn
                            v-if="list.wrkTypCd != '2'"
                            class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 2px !important"
                            color="bckgrColor" min-width="30" height="25"
                            @click="onAction(list)"
                            dark small>
                                <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-else class="d-inline-block overflow-y-auto" style="width: 100%; height: 433.1px">
                <v-row
                    class="" no-gutters>
                    <v-col class="grey006--text py-2" style="text-align: center;" cols="12">
                        ※ 조회된 항목이 없습니다.
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </div>
        </v-form>
    </v-sheet>
</template>

<script>

import { getStcd } from '../../api/index.js';
import { getAllPrgIM } from '../../api/prgrm.js';

export default {
    name: 'PrgrmSelect',

    props:{
        prgrmSelectPk:{type:Number, default:-1},
    },

    components: {
    },

    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllPrgIM()
        })
    },

    computed: {

    },

    watch: {
        'prgrmSelectPk':function(value){
            if(value > 0){
                this.getAllPrgIM()
            }
        }
    },

    methods: {
        onClose: function () {
            this.$emit('isClose', '.')
            // this.$emit('modalEvent', 1)
        },
        onAction:function(obj){
            this.$emit("outPrgrm",obj)
            this.onClose()
        },
        reset: function () {
            this.getAllPrgIM()
        },
        getAllPrgIM: function () {
            getAllPrgIM(this.$store.getters.getFcltyNum)
                .then((response) => (this.getAllPrgIMAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
        },
        getAllPrgIMAfter: function (res) {
            this.allPrgIMList.splice(0)
            res.forEach(el => {         

                this.cdItems.forEach((cd) => {
                    if (el.idxCd == cd.valcd) el.idxCdnm = cd.valcdnm
                });
                this.allPrgIMList.push(el)
            });
        },              
        stCdMapping: function (res) {
            this.cdItems = res
        },
    },

    data: () => ({
        selectedItemIndex: [],
        selectedItem: -1,
        allPrgIMList: [],
        cdItems: [],
        headers: [
            { text: '프로그램 유형', value: 'rn', sortable: true, width: '30%', class: 'black--text bottomBorder', align: 'center', },
            { text: '지표구분', value: 'name', sortable: true, width: '25%', class: 'black--text bottomBorder', align: 'center', },
            { text: '프로그램', value: 'age', sortable: true, width: '45%', class: 'black--text bottomBorder', align: 'center', },
        ],
    }),
};
</script>
<style>
.PrgrmNew {
    font-size: 1.0rem !important;
}

.PrgrmNew1 {
    border-left: 1px solid #dbdbdb !important;
    border-right: 1px solid #dbdbdb !important;
}
</style>