<template>
    <v-sheet class="ma-0" rounded="md">
        <v-form ref="prdcForm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="font-weight-medium">소독일시</span>
                </v-col>
                <v-col class="d-flex justify-left align-center pa-1 tableBL" cols="10">
                    <span class="d-inline-block" style="width: 133px;">
                        <CmmDateComp v-model="inspDt" :required="true"></CmmDateComp>
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 80px;">
                        <CmmTimeField v-model="inspTm" :required="false"></CmmTimeField>                                     
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="font-weight-medium">소독장소</span>
                </v-col>
                <v-col class="d-flex justify-left align-center pa-1 tableBL" cols="10">
                    <span class="d-inline-block" style="width: 100%;">
                        <v-text-field
                            v-model="inspRecDtlInfo.itm1Val"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="font-weight-medium">소독자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center pa-1 tableBL" cols="10">
                    <span class="d-inline-block" style="width: 20%;">
                        <v-text-field class="prdcDsnfcTF py-2"
                            v-model="inspRecDtlInfo.itm2Val"
                            label="소독자명" outlined hide-details dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 30%;">
                        <v-text-field class="prdcDsnfcTF py-2"
                            v-model="inspRecDtlInfo.itm3Val"
                            label="업체명" outlined hide-details dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block ml-3">
                        <v-checkbox class="prdcDsnfcCheck" v-model="inspRecDtlInfo.itm4Val" true-value="1" false-value="2" label="전문소독"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" hide-details></v-checkbox>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="font-weight-medium">소독내용</span>
                </v-col>
                <v-col class="d-flex justify-left align-center pa-1 tableBL" cols="10">
                    <span class="d-inline-block pa-1" style="width:100%;">
                        <v-textarea
                            class="rmrksArea" v-model="inspRecDtlInfo.itm5Val" no-resize hide-details outlined dense>
                        </v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mb-5"></v-divider>

            <v-row class="ma-0 pa-0" no-gutters>
                <v-col class="" cols="8">
                    <v-divider class=""></v-divider>
                    <v-row class="greyE01" no-gutters>
                        <v-col class="d-flex justify-center align-center tableBR py-1" cols="5">
                            <span class="font-weight-medium">소독제품명</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR py-1" cols="5">
                            <span class="font-weight-medium">제품설명</span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR"  cols="2">
                            <span class="">
                                <v-btn class="mr-1" color="blue200" height="26" @click="addRow()"
                                    :disabled="prdcList.length != 0 ? false : true" icon>
                                    <v-icon>mdi-plus-circle-outline</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row v-for="(prdc, k) in prdcList" :key="k" class="" no-gutters>
                        <v-col class="tableBR tableBB" style="text-align: center;" cols="5">
                            <span class="d-inline-block" style="width: 100%; padding: 6px 4px !important;">
                                <v-text-field
                                    v-model="prdc.itm1Val"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                        <v-col class="tableBR tableBB" style="text-align: center;" cols="5">
                            <span class="d-inline-block" style="width: 100%; padding: 6px 4px !important;">
                                <v-text-field
                                    v-model="prdc.itm2Val"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center align-center tableBR tableBB" cols="2">
                            <span class="py-1">
                                <v-btn class="mr-1" color="grey006" height="26" v-if="prdc.inspRecDtlPk != null"
                                    @click="dialog.splice(1, 1, true), delTitle=prdc.itm1Val, sltIndex=k" icon>
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                                <v-btn class="mr-1" color="grey006" height="26" icon
                                    v-else-if="prdc.inspRecDtlPk == null && prdcList.length > 1" @click="removeRow(k)">
                                    <v-icon>mdi-minus-circle-outline</v-icon>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="ma-0 pa-0" cols="4">
                    <v-divider class=""></v-divider>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center" style="border-right: 1px solid #dbdbdb !important;" cols="9">
                            <div class="d-flex align-center py-1">
                                <span class="font-weight-medium">자료첨부</span>
                            </div>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center" cols="3">
                            <span class="d-inline-block">
                                <v-file-input
                                    class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                                    :disabled="prdcList.length != 0 ? false : true"
                                    prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                                </v-file-input>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="ma-0 pa-0" no-gutters>
                        <v-col class="ma-0" cols="12">
                            <span class="d-inline-block pb-1 px-1 tableBB" style="width: 100%;"
                                v-for="(item, j) in fileList" :key="j">
                                <v-chip            
                                    class="mt-1" label close outlined
                                    @click="fileDownload(j)" @click:close="delfiles(j)">
                                    <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                        mdi-file-powerpoint
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                        mdi-file-word
                                    </v-icon>
                                    <v-icon v-else color='cyan' left>
                                        mdi-file-document
                                    </v-icon>
                                    <span class="longText">{{ item.orgFileNm }}</span>
                                </v-chip> 
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="" no-gutters>
                        <v-col :class="['ma-0 pa-0 px-1', imgList.length > 0 ? 'tableBB' : '']" cols="12">
                            <span class="d-inline-block mr-1 mt-1"
                                v-for="( item, i ) in imgList" :key="i">
                                <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'71'" @isEvent="deleteImg(i)"></imageCard>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-dialog v-model="dialog[0]" content-class="round" persistent max-width="800">
                <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="inspDt"></EmpSelector>
            </v-dialog>
            <v-dialog v-model="dialog[1]" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
            </v-dialog>
        </v-form>
    </v-sheet>
</template>

<script>
import { selInspRecDtl1list, insInspRecDtl, delInspRecDtl, delInspRecDtlInfo } from '../../api/sfty.js';
import { tmpFileUpload, fileDownload, delAtchdFile, selAtchdFileList } from '../../api/index.js';
import { getMultiStcd } from '../../api/index.js';
import { remoteURL } from '@/api/baseAxios';

import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import imageCard from '../commons/imageCard.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'PrdcDsnfc',

    props : {
       
    },
        
    components: {
        EmpSelector,
        btnModalDelete,
        imageCard,
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
        // this.getCode(['47'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            if(this.$parent.$parent.$parent.$parent.tab == 1){
                this.clearForm()
                let sltInspDate = value.substring(0, 7)
                let insPos = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspPrd == sltInspDate)
                let inspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].itm2

                this.getAtchdFileList()
                this.getInspRecDtllist(inspDate?.afterDateFormatDot(), 1)

                if(insPos != -1)
                    this.cntnt12 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].cntnt12
            }
        },
    },
    
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //정기소독 조회
        getInspRecDtllist: function (date){
            selInspRecDtl1list(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, date, 507, 2)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecDtl1list : ' + error))
        },
        //정기소독 저장
        setInspRecDtl: function (obj){
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //정기소독 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        //정기소독 단건 삭제
        removeInspRecDtlInfo: function (pk){
            delInspRecDtlInfo(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk )
                .then((response) => ( this.setInspRecDtlInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtlInfo : ' + error))
        },
        //첨부파일 목록 조회
        getAtchdFileList: function (){
            selAtchdFileList(this.$store.getters.getFcltyNum, 5, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        //첨부파일 목록 조회
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 다운로드
        athFileDownload: function (index) {
            fileDownload(this.fileList[index])
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd47 = res.vCd47
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            //this.inspRecDtllist.splice(0)
            
            let keyList = []
            res.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                this.wrtr = items.wrtr

                if(this.inspDt == ''){
                    if(items.inspDt == null)
                        this.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.replaceAll('.', '')?.beforeDateFormatHyp()
                    else
                        this.inspDt = items.inspDt?.beforeDateFormatHyp()
                }

                if(this.inspTm == ''){
                    if(items.inspTmHh == null)
                        this.inspTm = '12:00'
                    else
                        this.inspTm = items.inspTmHh + ':' + items.inspTmMm
                }
  
                // if(items.itm1Val == null)
                //     items.itm1Val = '4'
            });

            //res.sort((x, y) => x.itm1Cd - y.itm1Cd)

            this.inspTitle = keyList

            res.filter(v => v.clsfc3Cd == '1').forEach(item => {
                this.inspRecDtlInfo = item
            });
            this.prdcList = res.filter(v => v.clsfc3Cd == '2')
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        setInspRecDtlInfoAfter: function (res){
            if(res.statusCode == 200){
                let sltInspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.substring(0, 7)
                this.getInspRecDtllist(sltInspDate.afterDateFormatMonthDot())
                
                this.clearForm()
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        getAtchdFileListAfter: function(res){
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            // let fList = res.filter(v => v.fileKndCd == '1')
            let fList = res
            // let iList = res.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
            // iList.forEach( items => {
            //     items.src = remoteURL + 'opr/imgView?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
            //     this.imgList.push(items)
            // })
        },
        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.fileList.splice(0)
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList()
            }
            else {
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 507
            obj.clsfc2Cd = 2
            obj.bzClcd = '5'
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.inspDt.afterDateFormatHyp()
            obj.cntnt1 = this.inspRecDtlInfo.itm5Val
            obj.list = []
            obj.files = []

            this.inspRecDtlInfo.inspDt = this.inspDt.afterDateFormatHyp()
            this.inspRecDtlInfo.inspDtlDt = this.inspDt.afterDateFormatHyp()
            let tmp = this.inspTm.split(':', 2)
            this.inspRecDtlInfo.inspTmHh = tmp[0]
            this.inspRecDtlInfo.inspTmMm = tmp[1]
            this.inspRecDtlInfo.wrtr = ''

            obj.list.push(this.inspRecDtlInfo)

            this.prdcList.forEach(items => {
                let ooob = Object.assign({}, items)
                ooob.inspDtlDt = this.inspDt.afterDateFormatHyp()
                ooob.inspDt = this.inspDt.afterDateFormatHyp()
                ooob.inspTmHh = tmp[0]
                ooob.inspTmMm = tmp[1]
                ooob.clsfc3Cd = '2'
                ooob.itm1Cd = '1'
                ooob.itm2Cd = '2'

                if(ooob.itm1Val != null && ooob.itm2Val != null)
                    obj.list.push(ooob)
            });

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.files.push(items)
                }
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.files.push(items)
                }
            })

            //console.log(obj)
            this.setInspRecDtl(obj)
        },
        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },
        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },
        fileDownload: function (idx) {
            if(this.fileList[idx] != undefined){
                this.athFileDownload(idx)
            }
        },
        delfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined){
                this.sltIndex = idx
                this.delTitle = '파일'
                  
                this.dialog.splice(1, 1, true)
            }
            else {
                this.fileList.splice(idx, 1)
            }
        },
        removeFileItm: function(res) {
            if(res == 9990){
                let pk = 0

                pk = this.fileList[this.idx].atchdFilePk

                if(pk > 0)
                    this.removeAthFile(pk)
            }
            else if(res == 7){
                //this.dialogDelete = false
            }
        },
        deleteImg(idx){
            if(this.imgList[idx].atchdFilePk != undefined){
                this.sltIndex = idx
                this.delTitle = '사진'
                this.dialog.splice(1, 1, true)
            }
            else {
                this.imgList.splice(idx, 1)
            }
        },
        clearForm: function (){
            this.inspTitle.splice(0)
            this.$refs.prdcForm.reset()
            //this.inspRecDtllist.splice(0)
            this.wrtr = ''
            this.cntnt12 = ''
            this.inspDt = ''
            this.inspTm = ''
            this.forceRender += 1
        },
        delModalAct:function(res){
            if(res == 9990){    //OK
                if(this.delTitle == '사진'){
                    this.removeAthFile(this.imgList[this.sltIndex].atchdFilePk)
                }
                else if(this.delTitle == '파일'){
                    this.removeAthFile(this.fileList[this.sltIndex].atchdFilePk)
                }
                else
                    this.removeInspRecDtlInfo(this.prdcList[this.sltIndex].inspRecDtlPk)
                
                this.dialog.splice(1, 1, false)
            }
            else if(res == 7)   //Cancel
                this.dialog.splice(1, 1, false)

            this.delTitle = ''
            this.sltIndex = -1
        },
        addRow: function (){
            let obj = Object.assign({}, this.dummyInspRecDtlInfo)

            this.prdcList.push(obj)
        },
        removeRow: function (index) {
            this.prdcList.splice(index, 1)
        },
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
        },
        timePicked: function (){
            this.$refs.timePicker.save(this.inspTm)
            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        sltIndex: -1,
        menu: [false, false],
        dialog: [false, false],
        delTitle: '소독제품',
        inspTitle: [],
        inspRecDtlInfo: {},
        prdcList: [],
        dummyInspRecDtlInfo: {
            inspDt: '', inspRecDtlPk: null, inspTmHh: '', inspTmMm: '', clsfc1Cd: '507', clsfc2Cd: '2',
            itm2Val: null, itm1Val: null, itm1Cd: '1', itm2Cd: '2'
        },
        wrtr: '',
        dsnPlc: '',
        cntnt12: '',
        inspDt: '',
        inspTm: '',
        files: null,
        fileList: [],
        imgList: [],
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>

<style>
    .prdcDsnfcTF .v-input__control .v-input__slot .v-text-field__slot { overflow: visible !important; }
    .prdcDsnfcTF .v-input__control .v-input__slot .v-text-field__slot label { line-height: 18px !important; }
    .prdcDsnfcCheck .v-input__control { width: 84px !important; }
    .prdcDsnfcCheck .v-input__control .v-input__slot label { margin-left: 6px !important; }
    .longText { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
    .athFileInput .v-input__prepend-outer .v-input__icon button { color: #2a9bed; }
</style>