<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span v-if="routerRocation=='NsgRcrdStts'">9-4. 간호기록현황</span>
                    <span v-else-if="routerRocation=='PrgBnfcrPrtcpStts'">9-5. 프로그램&nbsp;수급자&nbsp;참여&nbsp;현황</span>
                </span>
            </div>
            <div class="d-flex mt-4 mx-2">
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[2]" @btnClosed="expandClose" @changeCheck="filterSearch" />
            
                <v-spacer></v-spacer>
                
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="35px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
            </div>
            <div class="mt-2 mx-3 rounded-lg">
                <v-data-table class="msclnTable rptMidList pt-1" height="720" locale="ko"
                    :headers="headerList" :items="bnfcrList" :search="schDummy" :custom-filter="customFilter"
                    disable-pagination fixed-header hide-default-h eader hide-default-footer dense>
                    <template v-slot:item='{ item }'>
                        <tr
                            :class="['fixedCol', sltBnMmBrPk == item.bnMmbrPk ? 'sltRow' : 'disSltRow']"
                            @click="tableClickEvent(item.bnMmbrPk)">
                            <td :class="['fixedTable fixedRpt0 fixedCol0 tableBB', sltBnMmBrPk == item.bnMmbrPk ? 'tableSltHoverClr' : 'tableDisHoverClr']"
                                style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.rn}}</span>
                                <span class="d-inline-block" style="width: 18px; height: 16px;">&nbsp;</span>
                            </td>
                            <td :class="['fixedTable fixedRpt1 fixedCol0 tableBB', sltBnMmBrPk == item.bnMmbrPk ? 'tableSltHoverClr' : 'tableDisHoverClr']"
                                style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.name}}</span>
                                <span class="d-inline-block" style="width: 14px; height: 16px;" v-if="item.name.length >= 4">&nbsp;</span>
                                <span class="d-inline-block" style="width: 18px; height: 16px;" v-else>&nbsp;</span>
                            </td>
                            <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.age}}</span>
                                <span class="d-inline-block" style="width: 18px; height: 16px;">&nbsp;</span>
                            </td>
                            <td class="fixedCol0 tableBB d-flex justify-center align-center" style="height:40px !important; text-align: center;">
                                <span :class="'ic-level level0'+item.acknwRtng">
                                    {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                                </span>
                                <span class="d-inline-block" style="width: 18px; height: 16px;">&nbsp;</span>
                            </td>
                            <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.entStcd}}</span>
                                <span class="d-inline-block" style="width: 18px; height: 16px;">&nbsp;</span>
                            </td>
                            <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.lvnRmNm}}</span>
                                <span class="d-inline-block" style="width: 18px; height: 16px;">&nbsp;</span>
                            </td>
                            <td class="fixedCol0 tableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.acknwPrdEnd}}</span>
                                <span class="d-inline-block" style="width: 18px; height: 16px;">&nbsp;</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <router-view ref="btcInqChild"></router-view>
    </v-sheet>
</template>

<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';

import { bnfcrList } from '../../api/bnfcr.js';
import { selLvnRmInf } from '../../api/opr.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'BtcInq',
        
    components: {
        exBtn,
    },

    created: function(){
        this.getCode(['62', '65'])
        this.getLvnRmInf()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.routerRocation = this.$route.name
            this.getBnfcrList()
        })
    },

    computed: {

    },

    watch:{ 
        '$route' (to, from) {
            this.routerRocation = to.name
            console.log('Url Event from: ' + from.name)
        },
        'routerRocation': function (){
            this.headerList.forEach(items => {
                items.on = false
            });
            //this.getTableList()
        },
    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getLvnRmInf: function () {
            selLvnRmInf(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInf : ' + error))
        },
        //간호기록현황, 프로그램 수급자 참여현황
        getBnfcrList: function (){
            bnfcrList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getBnfcrListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrList : ' + error))
        },
        stCdMapping: function (res) {
            res.vCd62.forEach((code) => {
                this.exBtn[2].expandList.push(code.valcdnm)
            });//end foreach

            res.vCd65.forEach((code) => {
                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach

            this.vCd62 = res.vCd62
            this.vCd65 = res.vCd65
        },
        getLvnRmInfAfter: function (res){
            this.lvnRmInf = res

            this.lvnRmInf.forEach((code) => {
                this.exBtn[1].expandList.push(code.lvnRmNm)
            });//end foreach
        },
        getBnfcrListAfter: function (res){
            this.bnfcrList.splice(0)

            let me = this
            res.forEach(items => {
                me.vCd65.forEach(code => {
                    if(items.entStcd == code.valcd) items.entStcd = code.valcdnm
                });
            });

            res.forEach(element => {
                element.acknwPrdEnd = element.acknwPrdEnd.beforeDateFormatDot()
            });

            this.bnfcrList = res

            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                this.bnfcrList.forEach(e => {
                    if(e.bnMmbrPk == this.$store.getters.getBnfcrState.bnmmbr){
                        this.tableClickEvent(e.bnMmbrPk, e.bnftOfrRecHisPk)
                    }
                });
            }
        },
        getTableList: function(){
            switch(this.routerRocation) {
                case 'NsgRcrdStts':
                    this.bnfcrList.splice(0)
                    this.midAreaWidth = '400'
                    this.headerList.forEach(items => {
                        this.groupList.NsgRcrdStts.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getBnfcrList()
                    break;
                case 'PrgBnfcrPrtcpStts':
                    this.bnfcrList.splice(0)
                    this.midAreaWidth = '400'
                    this.headerList.forEach(items => {
                        this.groupList.PrgBnfcrPrtcpStts.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getBnfcrList()
                    break;
                default:
                    break;
            }
        },
        tableClickEvent: function (pk){
            if(this.sltBnMmBrPk == -1){
                this.sltBnMmBrPk = pk
            }
            else if(this.sltBnMmBrPk != pk){
                this.sltBnMmBrPk = pk
            }
            else{
                this.sltBnMmBrPk = -1
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'entStcd': this.schChkField.entStcd.splice(0)
                    sch.forEach((item) => { this.schChkField.entStcd.push(item) });
                    break;
                case 'lvnRmNm': this.schChkField.lvnRmNm.splice(0)
                    sch.forEach((item) => { this.schChkField.lvnRmNm.push(item) });
                    break;
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.entStcd.length != 0){
                this.schChkField.entStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.lvnRmNm.length != 0){
                this.schChkField.lvnRmNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.lvnRmNm == stcd
                    else col2 = col2 || filter.lvnRmNm == stcd
                });
            }

            let col3 = true
            if(this.schChkField.acknwRtng.length != 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) col3 = filter.acknwRtng.includes(stcd)
                    else col3 = col3 || filter.acknwRtng.includes(stcd)
                });
            }

            return col0 && col1 && col2 && col3;
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'entStcd'
                    break;
                case 1: this.schChkField.target = 'lvnRmNm'
                    break;
                case 2: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
    },
    
    data: () => ({
        vCd62: [],
        vCd65: [],
        lvnRmInf: [],
        bnfcrList: [],
        midAreaWidth: '380',
        routerRocation: '',
        sltBnMmBrPk: -1,
        schField: '',
        schDummy: '',
        schChkField: { entStcd: [], lvnRmNm: [], acknwRtng:[], target: '' },
        exBtn: [
            { index:  0, btnTitle: '입소현황', btnIcon: 'house', exWidth: 104, exHeigth: 130, expandList: [], },
            { index:  1, btnTitle: '생활실', btnIcon: 'bed', exWidth: 124, exHeigth: 100, expandList: [], },
            { index:  2, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        ],
        groupList:{
            NsgRcrdStts: ['연번', '수급자', '나이', '등급', '현황', '생활실', '입소일'], PrgBnfcrPrtcpStts: ['연번', '수급자', '나이', '등급', '현황', '생활실', '입소일'],
        },
        headerList: [
            { text: '연번', value: 'rn', align: 'center', on: false, sortable: true, width: '70', class: 'v-data-table--fixed-header fixedRpt0 sftyHeader black--text py-2'},
            { text: '수급자', value: 'name', align: 'center', on: false, sortable: true, width: '70', class: 'v-data-table--fixed-header black--text sftyHeader fixedRpt1 py-2'},
            { text: '나이', value: 'age', align: 'center', on: false, sortable: true, width: '60', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '등급', value: 'acknwRtng', align: 'center', on: false, sortable: true, width: '60', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '현황', value: 'entStcd', align: 'center', on: false, sortable: true, width: '70', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '생활실', value: 'lvnRmNm', align: 'center', on: false, sortable: true, width: '70', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '입소일', value: 'acknwPrdEnd', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
        ],
    }),
};
</script>

<style>
    .tableSltHoverClr{ background: #ebf6fe !important; }
    .tableDisHoverClr{ background: white !important; }
    .rptTable {
        position: sticky !important; position: -webkit-sticky !important; 
        z-index: 1; background: white; font-size: 0.975rem !important;
    }
    .rptMidList .v-data-table__wrapper .v-data-table-header tr th {
        border-bottom: 2px solid !important;
    }
    .rptMidList .v-data-table__wrapper .v-data-table-header .v-data-table--fixed-header{
        position: sticky !important; z-index: 3 !important;
    }
    .fixedRpt0 { left: 0px; }
    .fixedRpt1 { left: 70px; }
</style>