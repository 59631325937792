<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">진료&nbsp;약제비&nbsp;&#47;&nbsp;계약의사&nbsp;진찰비&nbsp;수정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="d-flex align-center mt-4 mb-2">
            <v-icon style="font-size: 26px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">진찰기록&nbsp;내역</span>
            <v-spacer></v-spacer>
            <span v-if="rtCmp == 'stayOut'" class="red--text font-weight-bold ml-3">※&nbsp;하단&nbsp;항목은&nbsp;외출&#183;외박&nbsp;관리&nbsp;저장시&nbsp;반영됩니다&#46;</span>
        </div>
        <v-divider :class="[tableList.length > 5 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="[tableList.length > 5 ? 'greyE01 pa-0 mr-2__5' : 'greyE01 pa-0']" no-gutters>
            <v-col class="pa-0 ma-0" style="" cols="5">
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="py-4 ma-0 d-flex justify-center align-center tableBR" cols="3">
                        <span class="black--text font-weight-medium">진료일</span>
                    </v-col>
                    <v-col class="d-flex justify-center py-4 ma-0 tableBR" cols="3">
                        <span class="black--text font-weight-medium">진료 구분</span>
                    </v-col>
                    <v-col class="py-4 ma-0 d-flex justify-center align-center tableBR" cols="3">
                        <span class="black--text font-weight-medium">병의원명/계약의사명</span>
                    </v-col>
                    <v-col class="py-4 ma-0 d-flex justify-center align-center tableBR" cols="3">
                        <span class="black--text font-weight-medium">작성자</span>
                    </v-col>
                    <!-- <v-col class="pa-0 ma-0 d-flex justify-center align-center py-2" style="border-right: 1px solid #dbdbdb !important;" cols="2"><span class="black--text font-weight-medium">진료내용</span></v-col> -->
                </v-row>
            </v-col>
            <v-col class="pa-0 ma-0" style="" cols="4">
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="ma-0 py-1 tableBR" cols="3">
                        <div class="black--text font-weight-medium" style="text-align: center;">청구 구분</div>
                        <div class="black--text font-weight-medium" style="text-align: center;">/ 진료비</div>
                    </v-col>
                    <v-col class="ma-0 py-1 tableBR" cols="3">
                        <div class="black--text font-weight-medium" style="text-align: center;">청구 구분</div>
                        <div class="black--text font-weight-medium" style="text-align: center;">/ 약제비</div>
                    </v-col>
                    <v-col class="py-2 ma-0 d-flex justify-center align-center tableBR" cols="3">
                        <span class="black--text font-weight-medium">초진/재진</span>
                    </v-col>
                    <v-col class="ma-0 py-1 tableBR" cols="3">
                        <div class="black--text font-weight-medium" style="text-align: center;">청구 구분</div>
                        <div class="black--text font-weight-medium" style="text-align: center;">계약의사\진찰비</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="greyE01 pa-0 ma-0" style="" cols="3">
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center ma-0 py-4 tableBR" cols="9">
                        <span class="black--text font-weight-medium">진료내용</span>
                    </v-col>
                    <v-col class="ma-0 py-4 d-flex justify-center" cols="3">
                        <v-btn class="" color="blue200" height="26" @click="addRow()" icon>
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-col class="greyE01 pa-0 ma-0 d-flex justify-center align-center" style="" cols="1">
                <span class="font-weight-medium">
                    v-if="prtOutHisPk != 0"
                    <v-btn class="" color="blue200" height="26" @click="addRow()" icon>
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </span>
            </v-col> -->
        </v-row>
        <v-form class="d-flex" ref="mdclForm" lazy-validation>
            <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 277px;">
                <v-divider></v-divider>
                <v-row class="pa-0 ma-0 tableBB" no-gutters v-for="(list, i) in tableList" :key="i">
                    <v-col class="pa-0 ma-0" style="" cols="5">
                        <v-row class="pa-0" no-gutters>
                            <v-col class="ma-0 px-1 py-2 tableBR d-flex justify-center align-center" cols="3">       
                                <span class="d-inline-block py-2" style="width: 130px;">
                                    <CmmDateComp class="fileOLFld" v-model="list.clncDt" :required="true"></CmmDateComp>
                                </span>
                            </v-col>
                            <v-col class="d-flex justify-center ma-0 py-1 tableBR" cols="3">
                                <v-radio-group
                                    v-model="list.clncClcd"
                                    class="pa-1 ma-0 d-flex justify-center align-center d-inline-block" style="width: 120px;"
                                    hide-details row>
                                    <v-radio
                                        class="d-inline-block d-flex justify-center ma-0 pr-2" style=""
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        :ripple="false" label="계약 의사진료" value="1">
                                    </v-radio>
                                    <v-radio class="d-inline-block d-flex justify-center ma-0 pr-2" style=""
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        :ripple="false" label="외래 진료" value="2">
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col class="ma-0 px-1 tableBR d-flex justify-center align-center" cols="3">
                                <v-text-field
                                    v-model="list.hospNm" :rules="[rules.required]"
                                    hide-details readonly outlined dense>
                                </v-text-field>
                                <v-dialog 
                                    v-model="hosp[i]" max-width="800">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            class="ml-1 green lighten-3" style="padding: 1px 1px 0px 1px !important"
                                            min-width="30" height="25" v-bind="attrs" v-on="on" @click="selected = i"
                                            dark small>
                                            <v-icon size="20">mdi-hospital-building</v-icon>
                                        </v-btn>
                                    </template>
                                    <DctrCarerCntrcMdf v-if="list.clncClcd == '1'" @outDct="getDctInfo" @isClose="isHosptlSelector(i)"></DctrCarerCntrcMdf>
                                    <HsptlMdf v-else @isClose="isHosptlSelector(i)" @outEmp="getHsptlInfo"></HsptlMdf>
                                </v-dialog>
                            </v-col>
                            <v-col class="ma-0 px-1 tableBR d-flex justify-center align-center" cols="3">
                                <v-text-field v-model="list.wrtr" :rules="[rules.required]" hide-details readonly outlined dense></v-text-field>
                                <v-dialog 
                                    v-model="emp[i]" max-width="800">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            class="ml-1 brown lighten-3" style="padding: 0px 1px 0px 2px !important"
                                            min-width="30" height="25" v-bind="attrs" v-on="on" @click="selected = i"
                                            dark small>
                                            <v-icon size="20">mdi-account-edit</v-icon>
                                        </v-btn>
                                    </template>
                                    <EmpSelector @isClose="isEmpSelector(i)" @outEmp="getEmpInfo" :prtWrtDt = "list.clncDt"></EmpSelector>
                                </v-dialog>
                            </v-col>
                            <!-- <v-col class="ma-0 px-1 tableBR d-flex justify-center align-center" cols="2">
                                <v-text-field v-model="list.clncCntnt" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </v-col> -->
                        </v-row>
                    </v-col>
                    <v-col class="pa-0 ma-0" style="" cols="4">
                        <v-row class="pa-0 ma-0" style="height: 100%;" no-gutters>
                            <v-col class="pa-1 ma-0 d-flex justify-center align-center tableBR" style="height: 100%;" cols="3">
                                <div style="width: 100%;">
                                    <v-select class="mdclSelect mb-1" @change="chgSummury()"
                                        v-model="list.clncFeeClmYn"
                                        :items="clncItems" height="28"
                                        dense hide-details outlined>
                                    </v-select>
                                    <v-text-field
                                        class="clncNumFiled" type="number" min="0"
                                        v-model="list.clncFee"
                                        :rules="list.clncFeeClmYn == '미청구' ? [] : [rules.numberCheck]" @input="chgSummury()"
                                        hide-details outlined dense>
                                    </v-text-field>
                                </div>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-center align-center tableBR" style="height: 100%;" cols="3">
                                <div style="width: 100%;">
                                    <v-select class="mdclSelect mb-1" @change="chgSummury()"
                                        v-model="list.drugFeeClmYn"
                                        :items="clncItems" height="28"
                                        dense hide-details outlined>
                                    </v-select>
                                    <v-text-field
                                        class="clncNumFiled" type="number" min="0"
                                        v-model="list.drugFee"
                                        :rules="list.drugFeeClmYn == '미청구' ? [] : [rules.numberCheck]" @input="chgSummury()"
                                        hide-details outlined dense>
                                    </v-text-field>
                                </div>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-center align-center tableBR" style="height: 100%;" cols="3">
                                <v-select class="mdclSelect"
                                    v-model="list.frstYn"
                                    :items="firstItems" height="28"
                                    dense hide-details outlined>
                                </v-select>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-center align-center tableBR" style="height: 100%;" cols="3">
                                <div style="width: 100%;">
                                    <v-select class="mdclSelect mb-1"
                                        v-model="list.dctrFeeClmYn" @change="chgSummury()"
                                        :items="clncItems" height="28"
                                        dense hide-details outlined>
                                    </v-select>
                                    <v-text-field
                                        class="clncNumFiled" type="number" min="0"
                                        v-model="list.dctrFee"
                                        :rules="list.dctrFeeClmYn == '미청구' ? [] : [rules.numberCheck]"
                                        @input="chgSummury()" hide-details outlined dense>
                                    </v-text-field>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="pa-0 ma-0" style="" cols="3">
                        <v-row class="pa-0" style="height: 100%;" no-gutters>
                            <v-col class="pa-1 ma-0 d-flex justify-center align-center tableBR" style="height: 100%;" cols="9">
                                <v-textarea
                                    class="rmrksArea" rows="2" ref="rmrksArea"
                                    v-model="list.clncCntnt" :rules="[rules.required]"
                                    no-resize outlined hide-details dense>
                                </v-textarea>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-center align-center" style="height: 100%;" cols="3">
                                <v-btn class="" color="blueBtnColor" v-if="prtOutHisPk == 0 && bnmmbrPk != 0"
                                    height="26" @click="preClncRec(i)" icon>
                                    <v-icon>mdi-content-save-edit-outline</v-icon>
                                </v-btn>
                                <span v-if="rtCmp == 'stayOut'">
                                    <v-btn v-if="tableList.length > 1" class="" color="grey006" height="26" @click="deleteRow(i)" icon>
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </span>
                                <span v-else>
                                    <v-btn v-if="list.clncRecPk == 0" class="" color="grey006" height="26" @click="deleteRow(i)" icon>
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-form>
        <v-divider></v-divider>
        <v-row :class="[tableList.length > 5 ? 'greyE01 pa-0 mr-2__5' : 'greyE01 pa-0']" style="" no-gutters>
            <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="5">
                <span class="black--text font-weight-medium py-1">합계</span>
            </v-col>
            <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBR" cols="4">
                <span class="black--text font-weight-medium">진료약제비&#58;&nbsp;{{clncDrugSum?.toLocaleString('ko-KR')}}원&nbsp;&#40;{{feeCnt[0]}}건&#41;</span>
            </v-col>
            <v-col class="pa-0 ma-0 d-flex justify-center align-center" cols="3">
                <span class="black--text font-weight-medium">계약의사&nbsp;진찰비&#58;&nbsp;{{dctrSum?.toLocaleString('ko-KR')}}원&nbsp;&#40;{{feeCnt[1]}}건&#41;</span>
            </v-col>
        </v-row>
        <v-divider :class="[tableList.length > 5 ? 'mr-2__5' : '']"></v-divider>
        <v-row class="pa-0 mt-6" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn
                    v-if="rtCmp == 'stayOut'"
                    class="white--text" color="blueBtnColor" @click="aplyMdcl" height="30" samll rounded>
                    <v-icon>mdi-content-save-move</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">적용</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import EmpSelector from '../../components/commons/EmpSelector.vue';
import HsptlMdf from '../../components/bnftrcrd/HsptlMdf.vue';
import DctrCarerCntrcMdf from '../../components/bnftrcrd/DctrCarerCntrcMdf.vue'
import CmmDateComp from '../commons/CmmDateComp.vue';

import { selClncRecList, selClncRecListYyMm, selClncRecListClm, insClncRec } from '../../api/bnftrcrd.js';

export default {
    name: 'MdclExpnsMdf',

    props : {
        prtOutHisPk : { type: Number, default: 0 },
        rtCmp: { type: String, default: '' },
        bnmmbrPk: { type: Number, default: 0 },
        bnfcrPk: { type: Number, default: 0 },
        yyMm: { type: String, default: '' },
    },
        
    components: {
        EmpSelector,
        HsptlMdf,
        DctrCarerCntrcMdf,
        CmmDateComp,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.rtCmp == 'clm' && this.prtOutHisPk == 0)
                this.getClncRecListClm(this.yyMm)
            else if(this.rtCmp == 'stayOut' && this.prtOutHisPk == 0)
                this.getClncRecListYyMm(this.yyMm)
            else
                this.getClncRecList()

            if(this.rtCmp == 'stayOut')
                this.$emit('sumObj', { clncDrugSum: this.clncDrugSum, dctrSum: this.dctrSum })
        })
    },

    computed: {

    },

    watch:{
        //외출외박관리에서 호출
        'prtOutHisPk': function () {
            this.tableList.splice(0)

            if(this.rtCmp == 'clm' && this.prtOutHisPk == 0)
                this.getClncRecListClm(this.yyMm)
            else if(this.rtCmp == 'stayOut' && this.prtOutHisPk == 0)
                this.getClncRecListYyMm(this.yyMm)
            else
                this.getClncRecList()

            this.chgSummury()
        },
        //기타 공통팝업용 호출
        'bnmmbrPk': function () {
            this.tableList.splice(0)

            if(this.rtCmp == 'clm' && this.prtOutHisPk == 0)
                this.getClncRecListClm(this.yyMm)
            else if(this.rtCmp == 'stayOut' && this.prtOutHisPk == 0)
                this.getClncRecListYyMm(this.yyMm)
            else
                this.getClncRecList()

            this.chgSummury()
        },
        'yyMm': function () {
            this.tableList.splice(0)

            if(this.rtCmp == 'clm' && this.prtOutHisPk == 0)
                this.getClncRecListClm(this.yyMm)
            else if(this.rtCmp == 'stayOut' && this.prtOutHisPk == 0)
                this.getClncRecListYyMm(this.yyMm)
            else
                this.getClncRecList()

            this.chgSummury()
        },
    },
    
    methods: {
        getClncRecList: function () {
            selClncRecList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, this.prtOutHisPk)
                .then((response) => ( this.getClncRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selClncRecList : ' + error))
        },
        getClncRecListYyMm: function (yymm) {
            let tmp = this.$moment(yymm.beforeDateFormatMonthDHyp() + '-01', 'YYYY-MM-DD').subtract(1, 'month').format('YYYYMM')
            selClncRecListYyMm(this.$store.getters.getFcltyNum, this.bnmmbrPk, tmp)
                .then((response) => ( this.getClncRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selClncRecListYyMm : ' + error))
        },
        getClncRecListClm: function (yymm) {
            let oObj = {}
            oObj.fcltyNum = this.$store.getters.getFcltyNum
            oObj.bnMmbrPk = this.bnmmbrPk
            oObj.bnfcrPk = this.bnfcrPk
            oObj.clmYymm = this.$moment(yymm, 'YYYYMM').format('YYYYMM')
 
            selClncRecListClm(oObj)
                .then((response) => ( this.getClncRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selClncRecListClm : ' + error))
        },
        setClncRec: function (obj) {
            insClncRec(obj)
                .then((response) => ( this.setClncRecAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/insClncRec : ' + error))
        },
        getClncRecListAfter: function (res) {
            // console.log(res)
            if(res.length > 0){
                res.forEach(item => {
                    item.clncDt = item.clncDt.beforeDateFormatHyp()
                    if(item.clncFeeClmYn == '1') item.clncFeeClmYn = '청구'
                    else if(item.clncFeeClmYn == '2') item.clncFeeClmYn = '미청구'
                    if(item.drugFeeClmYn == '1') item.drugFeeClmYn = '청구'
                    else if(item.drugFeeClmYn == '2') item.drugFeeClmYn = '미청구'
                    if(item.dctrFeeClmYn == '1') item.dctrFeeClmYn = '청구'
                    else if(item.dctrFeeClmYn == '2') item.dctrFeeClmYn = '미청구'
                    if(item.frstYn == '1') item.frstYn = '초진'
                    else if(item.frstYn == '2') item.frstYn = '재진'
                })
                this.tableList = res

                this.chgSummury()
            }
            else if(res.length == 0){
                this.tableList = []
                this.addRow()
                this.$refs.mdclForm?.resetValidation()
            }

        },
        setClncRecAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getClncRecListYyMm(this.yyMm)
                this.$parent.$parent.$parent.$parent.refresher()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preClncRec: function (index) {
            let obj = Object.assign({}, this.tableList[index])

            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.bnMmbrPk = this.bnmmbrPk

            obj.clncDt = obj.clncDt?.afterDateFormatHyp()

            if(obj.clncFeeClmYn == '청구') obj.clncFeeClmYn = '1'
            else obj.clncFeeClmYn = '2'
            if(obj.dctrFeeClmYn == '청구') obj.dctrFeeClmYn = '1'
            else obj.dctrFeeClmYn = '2'
            if(obj.drugFeeClmYn == '청구') obj.drugFeeClmYn = '1'
            else obj.drugFeeClmYn = '2'
            if(obj.frstYn == '초진') obj.frstYn = '1'
            else obj.frstYn = '2'

            if(obj.clncFee == '') obj.clncFee = 0
            if(obj.drugFee == '') obj.drugFee = 0
            if(obj.dctrFee == '') obj.dctrFee = 0

            obj.clncFee = Number(obj.clncFee)
            obj.drugFee = Number(obj.drugFee)
            obj.dctrFee = Number(obj.dctrFee)
          
            if(obj.hospNm == '' || obj.hospNm == null)
                this.snackControll([true, 2500, '필수 항목을 입력해주세요1.', 'warning'])
            else if(obj.wrtr == '' || obj.wrtr == null)
                this.snackControll([true, 2500, '필수 항목을 입력해주세요2.', 'warning'])
            else if(isNaN(obj.clncFee) || obj.clncFee == null)
                this.snackControll([true, 2500, '필수 항목을 입력해주세요3.', 'warning'])
            else if(isNaN(obj.drugFee || obj.drugFee == null))
                this.snackControll([true, 2500, '필수 항목을 입력해주세요4.', 'warning'])
            else if(isNaN(obj.dctrFee || obj.dctrFee == null))
                this.snackControll([true, 2500, '필수 항목을 입력해주세요5.', 'warning'])
            else
                this.setClncRec(obj)
        },
        chgSummury: function () {
            this.clncDrugSum = 0
            this.dctrSum = 0
            this.feeCnt.splice(0, 1, 0)
            this.feeCnt.splice(1, 1, 0)

            this.tableList.forEach(item => {

                if(item.clncFeeClmYn == '청구')
                    this.clncDrugSum += Number(item.clncFee)

                if(item.drugFeeClmYn == '청구')
                    this.clncDrugSum += Number(item.drugFee)

                if(item.dctrFeeClmYn == '청구'){
                    this.dctrSum += Number(item.dctrFee)
                    this.feeCnt[1] += 1
                }

                if(item.clncFeeClmYn == '청구' || item.drugFeeClmYn == '청구')
                    this.feeCnt[0] += 1

            })

            if(this.rtCmp == 'stayOut')
                this.$emit('sumObj', { clncDrugSum: this.clncDrugSum, dctrSum: this.dctrSum })

        },
        addRow: function () {
            let y = Object.assign({}, this.dummyClncRec)
            this.tableList.push(y)
            this.menu.push(false)
            this.emp.push(false)
            this.hosp.push(false)
        },
        deleteRow: function (index) {
            this.tableList.splice(index, 1)
            this.menu.splice(index, 1)
            this.emp.push(false)
            this.hosp.push(false)
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },
        isEmpSelector: function (value) {
            this.emp.splice(value, 1, false)
        },
        isHosptlSelector: function (value) {
            this.hosp.splice(value, 1, false)
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        aplyMdcl: function () {
            this.$store.commit('setTmpObj', this.tableList)
            this.onClose()
        },
        getEmpInfo: function (obj) {
            if(this.selected > -1)
                this.tableList[this.selected].wrtr = obj[0].name

            this.selected = -1
        },
        getHsptlInfo: function (obj) {
            if(this.selected > -1){
                this.tableList[this.selected].hospNm = ''
                this.tableList[this.selected].dctInfPk = 0
                this.tableList[this.selected].hospInfPk = 0
                this.tableList[this.selected].hospNm = obj.hospNm
                this.tableList[this.selected].hospInfPk = obj.hospInfPk
            }

            this.selected = -1
        },
        getDctInfo: function (obj) {
            if(this.selected > -1){
                this.tableList[this.selected].hospNm = ''
                this.tableList[this.selected].dctInfPk = 0
                this.tableList[this.selected].hospInfPk = 0
                this.tableList[this.selected].hospNm = obj.hospNm
                this.tableList[this.selected].dctInfPk = obj.dctInfPk
            }

            this.selected = -1
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        menu: [],
        hosp: [],
        emp: [],
        selected: -1,
        clmStdCd: '1',      //1. 선청구, 2. 후청구
        tableList: [],
        clncDrugSum: 0,
        dctrSum: 0,
        feeCnt: [0, 0],
        clncItems: [ '청구', '미청구' ],
        firstItems: [ '초진', '재진' ],
        dummyClncRec: {
            clncDt: '', clncClcd: '2', hospInfPk: 0, dctInfPk: 0, outHisPk: 0, clncRecPk: 0,
            hospNm: '', clncCntnt: '', clncFee: 0, clncFeeClmYn: '미청구', drugFee: 0,
            drugFeeClmYn: '미청구', frstYn: '초진', wrtr: '', dctrFee: 0, dctrFeeClmYn: '미청구'
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>
<style>
    .select { max-width: 60px; width: 60px; }
    .mdclSelect .v-icon.v-icon { font-size: 18px; }
    .mdclSelect .v-input__icon { height: 22px; min-width: 0px; width: 0px; }
    .v-select.v-input--dense .v-select__selection--comma { margin: 0 0 0 0 !important; }
    .mdclSelect .v-input__control > div { padding: 0 12px 0 6px !important; }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot { padding: 0 12px; }
    .lineBtnPx { padding: 0 6px !important;}
    .clncNumFiled .v-input__control .v-input__slot { padding-left: 8px !important; padding-right: 4px !important; }
</style>