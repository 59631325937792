<template>
    <v-sheet class="ma-0 pa-0">
        <v-row class="pa-0 my-3" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <expandMonthPicker
                    :prtDate="$parent.$parent.$parent.$parent.sltDate" @nowYearWithMonth="getPickerDate">
                </expandMonthPicker>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-4" no-gutters>
            <v-col class="" cols="12">
                <v-calendar
                    class="empCalendar mnthPlan rstclBrdr" color="dayNow darken-2 white--text" locale="ko"
                    v-model="$parent.$parent.$parent.$parent.start" ref="calendar"
                    :type="calendarObj.type" :event-more="false" :now="now"
                    :events="events" :event-color="getEventColor" :event-ripple="false" interval-height="20">
                    <template #day-label="{ date, day }" >
                        <div class="d-flex align-center tableBB px-3 mb-1" style="height:29px;">
                            <span
                                :class="['mb-1', date == now ? 'ic-level nowDay dayNow' : '']"
                                style="font-size: 1.1rem;">
                                {{ day }}
                            </span>
                            <v-spacer></v-spacer>
                            <span>&nbsp;</span>
                        </div>
                    </template>
                    <template #event="{ event }" >
                        <div class="d-flex mb-2" v-for="(bz, i) in event.bzList" :key="i" style="border: 0.5px solid #dbdbdb; border-radius: 10px 10px 10px 10px;
                        border-width: 3px; height: 32 !important;" @click="onClick(bz.bzPk)">
                            <span class="d-inline-block d-flex justify-center align-center" style="width: 20%;">
                                <v-icon v-if="bz.bzPlnClcd == '1'" style="padding-top: 2px;" color="orange" size="20">mdi-cake-variant-outline</v-icon>
                                <span v-else class="d-inline-block rounded-circle"
                                    :style="{ width: 16+'px', height: 16+'px', 'background-color': bz.bzClr, 'margin-top': 2+'px', }">
                                </span>
                            </span>
                            <span class="d-inline-block d-flex align-center" style="width: 80%; "
                                v-for="(nm, j) in bz.bzNm" :key="j">
                                <span class="ml-1 black--text" style="font-size: 1.1rem;">{{ nm }},</span>
                            </span>
                        </div>
                    </template>
                </v-calendar>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" max-width="1000" content-class="round" persistent>
            <AnnBsnsDtl ref="annBsnsDtl" @modalClose="modalChanger"></AnnBsnsDtl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import expandMonthPicker from '../commons/expandMonthPicker.vue';
import AnnBsnsDtl from '../../components/opr/AnnBsnsDtl.vue';
import { selBzPlnMnthList } from '../../api/opr.js';

export default {
    name: 'MnthlPlan',

    props : {
       
    },
        
    components: {
        expandMonthPicker,
        AnnBsnsDtl,
    },

    created: function(){
        let timezoneOffset = new Date().getTimezoneOffset() * 60000
        let timezoneDate = new Date(Date.now() - timezoneOffset)
        this.now = timezoneDate.toISOString().split('T',2)[0]
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getYyMmDate: function () {
            return this.$parent.$parent.$parent.$parent.sltDate
        },
    },

    watch:{
        getYyMmDate: function (value) {
            this.getBzPlnMnthList(value.substring(0, 4), value)
        },
    },
    
    methods: {
        getBzPlnMnthList: function (yyyy, yymm) {
            selBzPlnMnthList(this.$store.getters.getFcltyNum, yyyy, yymm)
                .then((response) => ( this.getBzPlnMnthListAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selBzPlnMnthList : ' + error))
        },
        getBzPlnMnthListAfter: function (res) {
            this.events.splice(0)
            res.forEach(items => {
                let obj = {}
                let subObj = {}
                obj.start = this.$parent.$parent.$parent.$parent.sltDate.substring(0, 4) + '-' + items.bzMm + '-' + items.bzDd
                obj.end = obj.start
                obj.bzList = []
                subObj.bzNm = items.bzNm
                subObj.bzPlnClcd = items.bzPlnClcd
                subObj.bzPk = items.bzPk
                subObj.bzClr = ''
                subObj.name = ''
                this.$parent.$parent.$parent.$parent.vCd95.forEach(cd => {
                    if(items.bzPlnClcd == cd.valcd){
                        subObj.name = cd.valcdnm
                        subObj.bzClr = cd.valdesc
                    }
                });
                
                if(this.events.length == 0){
                    obj.bzList.push(subObj)
                    this.events.push(obj)
                }
                else if(this.events.length > 0){
                    let chc = this.events.findIndex( v => v.start == obj.start )

                    if(chc == -1){
                        obj.bzList.push(subObj)
                        this.events.push(obj)
                    }
                    else if(chc > -1){
                        this.events[chc].bzList.push(subObj)
                    }
                }
            });
        },
        getPickerDate: function (date) { 
            this.$parent.$parent.$parent.$parent.start = date.substr(0, 4) + '-' + date.substr(4, 2) + '-01'
            this.$parent.$parent.$parent.$parent.sltDate = date
        },
        getEventColor: function (event) {
            return event.color
        },
        onClick: function (pk) {
            this.$parent.$parent.$parent.$parent.onShow(pk[0])
        },
        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        now: '',
        events: [
            //{ name: '1', start: '2023-01-01', end: '2023-01-01', color: 'light-blue darken-2', cnt: 1 },
        ],
        colors: ['blue', 'deep-orange darken-3', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        calendarObj: 
        { 
            type: 'month', types: ['month', 'week', 'day', '4day'],
            weekday: [0, 1, 2, 3, 4, 5, 6], mode: 'stack',
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
        },
        menu: [false,],
    }),
};
</script>

<style>
    .mnthPlan .v-calendar-weekly__week .v-event { height: 68px !important; overflow-y: auto !important; }
    .mnthPlan .v-calendar-weekly__week .v-calendar-weekly__day { min-height: 80px; }
</style>