<template>
    <v-sheet class="" rounded="lg">
        <v-row class="pa-0 justify-center" no-gutters>
            <span
                class="d-inline-block d-flex justify-center align-center rounded-lg greyF02"
                style="width: 70px; height: 60px;">
                <v-icon :color="iconClr" size="36">{{ icon }}</v-icon>
            </span>
            <!-- fcf5f7 -->
        </v-row>
        <v-divider class="white my-4"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <span class="text-h4 font-weight-bold">{{ title }}</span>
        </v-row>
        <v-divider class="white my-4"></v-divider>
        <v-row class="pa-0 justify-center" v-if="Array.isArray(cntnt)" no-gutters>
            <v-col v-for="(txt, i) in cntnt" :key="i" class="txtAlgnMid px-2" cols="12">
                <span class="fontOneDotTwoRem font-weight-medium">{{ txt }}</span>
            </v-col>
        </v-row>
        <v-divider class="white my-4"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="px-1" cols="6">
                <v-btn class="greyF02" width="100%" @click="onClose" rounded text large>
                    <span class="fontOneDotTwoRem font-weight-medium">{{ cnclTxt }}</span>
                </v-btn>
            </v-col>
            <v-col class="px-1" cols="6">
                <v-btn :class="iconClr" color="white" width="100%" @click="onEvt" rounded text large>
                    <span class="fontOneDotTwoRem font-weight-medium">{{ cnfrmTxt }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>


export default {
    name: 'CmmCnfrmMdl',

    components: {
     
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getSubInObj != null){
                let obj = this.$store.getters.getSubInObj
                this.initSetter(obj)
            }
        })
    },

    computed: {

    },

    watch: {

    },

    methods: {
        initSetter: function(obj){
            this.icon = obj.icon
            this.iconClr = obj.iconClr
            this.title = obj.title
            this.cntnt = obj.cntnt
            this.cnclTxt = obj.cnclTxt
            this.cnfrmTxt = obj.cnfrmTxt
        },
        onEvt: function () {
            this.$store.commit('setSubRunEvt', true)
        },
        onClose: function () {
            this.$store.commit('setSubInf', {
                actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
            });
        },
    },

    data: () => ({
        icon: 'mdi-alert',
        iconClr: 'grey006',
        title: '',
        cntnt: [],
        cnclTxt: '취소',
        cnfrmTxt: '확인',
    }),
};
</script>