<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-2" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200"
                max-width="500" class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition"
                absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
                </div>
            </v-snackbar>
        </div>
        <!-- contents -->
        <div class="px-2 mt-2 mr-2 d-flex align-center">            
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">6-5. 급여수가정보</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(list.text)" :class="list.class"
                    samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{ list.icon }}</v-icon>
                    <span style="font-size: 1rem;">{{ list.text }}</span>
                </v-btn>
                <v-dialog v-if="list.type == 'dialogue' && list.visible" v-model="dialog[i]" :max-width="list.width">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled" :class="list.class" samll height="30"
                            :color="list.color" rounded>
                            <v-icon small>{{ list.icon }}</v-icon>
                            <span style="font-size: 1rem;">{{ list.text }}</span>
                        </v-btn>
                    </template>

                </v-dialog>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j" :class="list.class" samll height="30"
                :color="list.color" :disabled="list.disabled" rounded outlined @click="onPrintBtn(list.text)">
                <v-icon small>{{ list.icon }}</v-icon>
                <span style="font-size: 1rem;">{{ list.text }}</span>
            </v-btn>
            <div class="d-flex pl-1 pr-3" style="margin-bottom: 5px; justify-content: right !important;">
            </div>
        </div>
        <v-form ref="form" lazy-validation class="mt-10">
            <v-row no-gutters>
                <v-col cols="6">
                    <div class="d-flex">                                                
                        <div class="d-flex align-center">
                            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold">공단급여&nbsp;수가정보</span>
                        </div>  
                        <v-spacer></v-spacer>
                        <span class="mr-6 hamletFont black--text">
                            <span>(단위 : 원)</span>
                        </span>
                    </div>
                    <div class="mt-3 overflow-y-auto" style="width: 100%;height:330px;">
                        <div class="d-flex justify-center align-center greyE01 pa-0 ma-0" style="width: 100%; border-top: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center py-1 font-weight-medium "
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 80px;">
                                <span>적용시기</span>
                            </div>
                            <div class="d-flex justify-center align-center py-1 font-weight-medium "
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 160px;">
                                <span>구분</span>
                            </div>
                            <div class="d-flex justify-center align-center py-1 font-weight-medium "
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;">
                                <span>1등급</span>
                            </div>
                            <div class="d-flex justify-center align-center py-1 font-weight-medium "
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;">
                                <span>2등급</span>
                            </div>
                            <div class="d-flex justify-center align-center py-1 font-weight-medium "
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;">
                                <span>3등급</span>
                            </div>
                            <div class="d-flex justify-center align-center py-1 font-weight-medium "
                                style="border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;">
                                <span>4등급</span>
                            </div>
                            <div class="d-flex justify-center align-center py-1 font-weight-medium "
                                style="border-bottom: 1px solid #dbdbdb !important; width: 72px;">
                                <span>5등급</span>
                            </div>
                        </div>
                        <div v-for="(item, i) in kinds" :key="i" no-gutters class="d-flex justify-center align-center"
                            style="width: 100%;">
                            <div style="width: 80px; height: 96.5px; border-bottom: 1px solid #dbdbdb;"
                                class="d-flex justify-center align-center">
                                <span>
                                    {{ item }}
                                </span>
                            </div>
                            <div style="border-left: 1px solid #dbdbdb !important;" class="">
                                <div v-for="(lis, j) in group[item]" :key="j"
                                    class="d-flex justify-center align-center">
                                    <div v-if="lis.bnftClcd != '4' && lis.bnftClcd != '5'"
                                        class="d-flex justify-center align-center">
                                        <div style=" width: 159px; border-bottom: 1px solid #dbdbdb !important;"
                                            class="d-flex justify-center align-center">
                                            <span class="lack--text py-1"
                                                v-if="lis.bnftClcd == '1'">일반</span>
                                            <span class="lack--text py-1"
                                                v-else-if="lis.bnftClcd == '2'">치매전담실가형</span>
                                            <span class="lack--text py-1"
                                                v-else-if="lis.bnftClcd == '3'">치매전담실나형</span>
                                            <span class="lack--text py-1" v-else>-</span>
                                        </div>
                                        <div style=" border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;"
                                            class="d-flex justify-center align-center">
                                            <span class="black--text py-1">{{ lis.rtgFee1?.toLocaleString('ko-KR') }}</span>
                                        </div>
                                        <div style=" border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;"
                                            class="d-flex justify-center align-center">
                                            <span class="black--text py-1">{{ lis.rtgFee2?.toLocaleString('ko-KR') }}</span>
                                        </div>
                                        <div style=" border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;"
                                            class="d-flex justify-center align-center">
                                            <span class="black--text py-1">{{ lis.rtgFee3?.toLocaleString('ko-KR') }}</span>
                                        </div>
                                        <div style=" border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 72px;"
                                            class="d-flex justify-center align-center">
                                            <span class="black--text py-1">{{ lis.rtgFee4?.toLocaleString('ko-KR') }}</span>
                                        </div>
                                        <div style=" border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; width: 73px;"
                                            class="d-flex justify-center align-center">
                                            <span class="black--text py-1">{{ lis.rtgFee5?.toLocaleString('ko-KR') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:100%">
                        <div class="d-flex">                           
                            <div class="d-flex align-center">
                                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                                <span class="text-h6 font-weight-bold">계약의사&nbsp;진찰비용</span>
                            </div>  
                            <v-spacer></v-spacer>
                            <span class="mr-6 hamletFont black--text">
                                <span>(단위 : 원)</span>
                            </span>
                        </div>
                        
                        <div class="mt-3" style= "height:300px; overflow-y:auto;">
                            <v-data-table class="msclnTable" height="" locale="ko" :headers="headers" :items="ClncFee"
                                hide-default-header disable-pagination hide-default-footer dense>
                                <template v-slot:header="{ props }">
                                    <thead>
                                        <th class=" justify-center align-center greyE01 py-1"
                                            style="border-top: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important; width: 100px;">
                                            {{ props.headers[0].text }}</th>
                                        <th class=" justify-center align-center greyE01"
                                            style="border-top: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important; width: 240px;">
                                            초진비</th>
                                        <th class=" justify-center align-center greyE01"
                                            style="border-top: 1px solid #dbdbdb !important; width: 240px;">
                                            재진비</th>
                                    </thead>
                                </template>
                                <template v-slot:item='{ item }'>
                                    <tr>
                                        <td class="fixedCol0"
                                            style="height:40px !important; text-align: center; border-top: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                            <span class="cmmFontSizeOne justify-center align-center"
                                                style="width:100px;">{{ item.aplYyyy }}</span>
                                        </td>
                                        <td class="fixedCol0"
                                            style="height:40px !important; text-align: center; border-top: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                            <span class="d-inline-block"
                                                style="width:240px;">{{ item.frstClncFee?.toLocaleString('ko-KR') }}</span>
                                        </td>
                                        <td class="fixedCol0"
                                            style="height:40px !important; text-align: center; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                                            <span class="d-inline-block" style="width:240px;">{{ item.clncFee?.toLocaleString('ko-KR') }}</span>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </v-col>
                <v-col class="" cols="6">                    
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">비급여&nbsp;수가정보</span>
                    </div> 
                    <div style="max-width: 580px" class="mt-3 ml-2">
                        <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb; " no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-0 ma-0" cols="4"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium py-2">적용시작일</span>
                            </v-col>
                            <v-col class="pa-0 ma-0 d-flex justify-left align-center" style="" cols="8">
                                <span class="d-inline-block ml-6" style="width:135px;">
                                    <CmmDateComp v-model="items.aplStrt" :required="true"></CmmDateComp>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb; " no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-0 ma-0" cols="4"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium py-2">청구항목</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center greyE01 pa-0 ma-0" cols="4"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium py-2">금액</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center greyE01 pa-0 ma-0" cols="4">
                                <span class="black--text font-weight-medium py-2">청구기준</span>
                            </v-col>
                        </v-row>
                        <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-0 ma-0" cols="2"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <div>
                                    <div class="black--text font-weight-medium">상급침실비</div>
                                    <div>                                        
                                        <v-btn
                                            color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                                            min-width="30" height="25"
                                            @click="menu.splice(4,1,true)"
                                            :disabled="items.nbnftFeeInfPk == 0"
                                            small outlined>
                                            <v-icon size="14">mdi-cog-outline</v-icon>
                                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">관리</span>                
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="justify-center align-center greyE01 pa-0 ma-0" cols="2"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                                    <div class="black--text font-weight-medium" style="height:47px;line-height:47px">1인실</div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%;">
                                    <div class="black--text font-weight-medium" style="height:47px;line-height:47px">2인실</div>
                                </div>
                            </v-col>
                            <v-col class="justify-center align-center pa-0 ma-0" cols="4"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <div class="pa-1" style="width:100%; border-bottom: 1px solid #dbdbdb !important;">
                                    <div style="height:47px;">
                                        <div class="d-flex justify-end align-center">
                                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                                <v-text-field style="text-align: right !important;" dense outlined hide-details
                                                    v-model="items.upBedFee1" type="number"></v-text-field>
                                            </span>
                                            <span class="pl-1">원</span>
                                        </div>
                                        <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.upBedFee1?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                    </div>
                                </div>
                                <div class="pa-1" style="width:100%;">
                                    <div style="height:47px;">
                                        <div class="d-flex justify-end align-center">
                                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%;">
                                                <v-text-field style="text-align: right !important;" dense outlined hide-details
                                                    v-model="items.upBedFee2" type="number"></v-text-field>
                                            </span>
                                            <span class="pl-1">원</span>
                                        </div>
                                        <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.upBedFee2?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="justify-center align-center pa-0 ma-0" cols="4">
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%; border-bottom: 1px solid #dbdbdb !important;">
                                    <div class="black--text" style="height:47px;line-height:47px">1일</div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%;">
                                    <div class="black--text" style="height:47px;line-height:47px">1일</div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">식사재료비</span>
                            </v-col>
                            <v-col class="pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                                <div style="height:47px;">
                                    <div class="d-flex justify-end align-center">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details v-model="items.mealCost" type="number"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </div>
                                    <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.mealCost?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">
                                <span class="black--text font-weight-medium">1식</span>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">경관유동식</span>
                            </v-col>
                            <v-col class="pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                                <div style="height:47px;">
                                    <div class="d-flex justify-end align-center">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details v-model="items.lqdFoodFee" type="number"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </div>
                                    <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.lqdFoodFee?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">                                
                                <span class="d-inline-block" style="width: 130px;">
                                        <v-select dense hide-details outlined
                                            v-model="items.lqdFoodClcd" :items="foodCode" item-text="valcdnm"
                                            item-value="valcd">
                                        </v-select>
                                    </span>
                            </v-col>
                        </v-row>

                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">간식비</span>
                            </v-col>
                            <v-col class="pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                                <div style="height:47px;">
                                    <div class="d-flex justify-end align-center">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details v-model="items.snackFee" type="number"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </div>
                                    <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.snackFee?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">
                                <span class="d-inline-block " style="width: 130px;">
                                    <v-select dense hide-details outlined
                                        v-model="items.snackOfrClcd" :items="snackCode" item-text="valcdnm"
                                        item-value="valcd">
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>

                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">이·미용비</span>
                            </v-col>
                            <v-col class="pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                                <div style="height:47px;">
                                    <div class="d-flex justify-end align-center">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details
                                                v-model="items.btyFee" type="number"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </div>
                                    <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.btyFee?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">
                                <span class="black--text font-weight-medium">1개월</span>
                            </v-col>
                        </v-row>

                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">기타비</span>
                            </v-col>
                            <v-col class="pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">
                                <div style="height:47px;">
                                    <div class="d-flex justify-end align-center">
                                        <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%;">
                                            <v-text-field style="text-align: right !important;" dense outlined hide-details
                                                v-model="items.msclnFee" type="number"></v-text-field>
                                        </span>
                                        <span class="pl-1">원</span>
                                    </div>
                                    <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.msclnFee?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="4">
                            </v-col>
                        </v-row>
                        <v-row style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-0 ma-0" cols="2"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium py-6">월한도액</span>
                            </v-col>
                            <v-col class="justify-center align-center greyE01 pa-0 ma-0" cols="2"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                                    <div class="black--text font-weight-medium" style="height:47px;line-height:47px">상급침실비</div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%;">
                                    <div class="black--text font-weight-medium" style="height:47px;line-height:47px">식사재료비</div>
                                </div>
                            </v-col>
                            <v-col class="justify-center align-center pa-0 ma-0" cols="4"
                                style="border-right: 1px solid #dbdbdb !important;">
                                <div class="pa-1" style="width:100%; border-bottom: 1px solid #dbdbdb !important;">
                                    <div class="d-flex">
                                        <div class="pt-2" style="width:30%;height:47px;">
                                            <v-checkbox
                                                v-model="checkbox"
                                                label="없음"
                                                @click="checkboxIn"
                                            ></v-checkbox>
                                        </div>
                                        <div style="height:47px;width:70%;">
                                            <div class="d-flex justify-end align-center">                                            
                                                <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                                    <v-text-field v-model="items.upBedFeeLmt" style="text-align: right !important;" dense outlined hide-details :disabled="checkbox" type="number"></v-text-field>
                                                </span>
                                                <span class="pl-1">원</span>
                                            </div>
                                            <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.upBedFeeLmt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pa-1" style="width:100%;">
                                    <div class="d-flex">
                                        <div class="pt-2" style="width:30%;height:47px;">
                                            <v-checkbox
                                                v-model="checkbox1"
                                                label="없음"
                                                @click="checkboxIn1"
                                            ></v-checkbox>
                                        </div>
                                        <div style="height:47px;width:70%;">
                                            <div class="d-flex justify-end align-center">                                            
                                                <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                                    <v-text-field v-model="items.mealCostLmt" style="text-align: right !important;" dense outlined hide-details :disabled="checkbox1" type="number"></v-text-field>
                                                </span>
                                                <span class="pl-1">원</span>
                                            </div>
                                            <div class="d-flex justify-end align-center" style="font-size:0.9rem">{{items.mealCostLmt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}</div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="justify-center align-center pa-0 ma-0" cols="4">
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%; border-bottom: 1px solid #dbdbdb !important;">
                                    <div class="black--text" style="height:47px;line-height:47px"></div>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0"
                                    style="width:100%;">
                                    <div class="black--text" style="height:47px;line-height:47px;font-size:1.0rem">식사재료비+경관유동식+간식비</div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                            <v-col class="d-flex justify-center align-center greyE01 pa-1 ma-0" cols="4" style="border-right: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">비고</span>
                            </v-col>
                            <v-col class="pa-1 ma-0 d-flex justify-left align-center" style="" cols="8">                                
                                <v-text-field dense outlined hide-details
                                    v-model="items.rmrks"></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="1200">
            <oprFeeInfList v-if="menu[0]" ref="PrgrmMc" @isClose="isModal(0)"></oprFeeInfList>
        </v-dialog>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
            <PrgrmPrint ref="PrgrmPrint" @isClose="isModal(1)"></PrgrmPrint>
        </v-dialog>
        <v-dialog v-model="menu[3]" max-width="1100" content-class="round">
            <OprFeeInfESndMdl v-if="menu[3]" ref="oprFeeInfESndMdl" @modalClose="isModal(3)"></OprFeeInfESndMdl>
        </v-dialog>
        <v-dialog v-model="menu[4]" max-width="600" content-class="round">
            <BlnftLvnRmTypMdl v-if="menu[4]" ref="oprFeeInfESndMdl" @modalClose="isModal(4)" @modalReload="onReload"></BlnftLvnRmTypMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selBnftFeeInfList, selClncFeeList, selNbnftFeeInf, insNbnftFeeInfTyp, selLvnRmTypList } from '../../api/opr.js';
import { groupBy } from '../../api/common.js';
import { getMultiStcd } from '../../api/index.js';
import CmmDateComp from '../../components/commons/CmmDateComp.vue';
import oprFeeInfList from '../../components/opr/oprFeeInfList.vue';
import PrgrmPrint from '../../components/prgrm/PrgrmPrint.vue';
import OprFeeInfESndMdl from '../../components/opr/OprFeeInfESndMdl.vue';
import BlnftLvnRmTypMdl from '../../components/opr/BlnftLvnRmTypMdl.vue';
export default {
    name: 'BlnftAmntInf',

    components: {
        CmmDateComp,
        oprFeeInfList,
        PrgrmPrint,
        OprFeeInfESndMdl,
        BlnftLvnRmTypMdl,
    },

    created: function () {
        this.$nextTick(function () {
        })
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.onCodeload()
            this.selBnftFeeInfList()
            this.selClncFeeList()
            this.selNbnftFeeInf()
            this.selLvnRmTypList()
        })
    },

    computed: {

    },

    watch: {               
    },

    methods: {
        checkboxIn1:function(){
            if(this.checkbox1 == true){
                this.items.mealCostLmt = 0
            }
        },
        checkboxIn:function(){
            if(this.checkbox == true){
                this.items.upBedFeeLmt = 0
            } 
        },
        selBnftFeeInfList: function () {
            selBnftFeeInfList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selBnftFeeInfListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selBnftFeeInfList : ' + error))
        },
        selBnftFeeInfListAfter: function (res) {
            this.BnftFeeInf = res
            this.group = groupBy(res, 'aplYyyy')
            this.kinds = Object.keys(this.group)
        },
        selClncFeeList: function () {
            selClncFeeList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selClncFeeListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selClncFeeList : ' + error))
        },
        selClncFeeListAfter: function (res) {
            this.ClncFee = res
        },
        selNbnftFeeInf: function () {
            selNbnftFeeInf(this.$store.getters.getFcltyNum)
                .then((response) => (this.selNbnftFeeInfAfter(response.data)))
                .catch((error) => console.log('connect error opr/selNbnftFeeInf : ' + error))
        },
        selNbnftFeeInfAfter: function (res) {            
            this.aplStrt                = res.aplStrt //적용시작일
            this.aplEnd                 = res.aplEnd 
            this.items.aplStrt          = res.aplStrt?.beforeDateFormatHyp()
            this.items.aplEnd           = res.aplEnd
            this.items.btyFee           = res.btyFee
            this.items.lqdFoodClcd      = res.lqdFoodClcd
            this.items.lqdFoodFee       = res.lqdFoodFee
            this.items.mealCost         = res.mealCost
            this.items.mealCostLmt      = res.mealCostLmt
            this.items.nbnftFeeInfPk    = res.nbnftFeeInfPk
            this.items.rmrks            = res.rmrks
            this.items.snackFee         = res.snackFee
            this.items.snackOfrClcd     = res.snackOfrClcd
            this.items.upBedFee1        = res.upBedFee1
            this.items.upBedFee2        = res.upBedFee2
            this.items.upBedFeeLmt      = res.upBedFeeLmt
            this.items.msclnFee         = res.msclnFee

            this.orgAplStrt = res.aplStrt
            this.nowDate = this.items.aplStrt?.beforeDateFormatHyp()
        },
        selLvnRmTypList: function () {
            selLvnRmTypList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selLvnRmTypListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selLvnRmTypList : ' + error))
        },
        selLvnRmTypListAfter: function (res) {            
            this.rmTypList.splice(0)
            res.forEach(e => {
                this.rmTypList.push(e)
            });
        },
        insNbnftFeeInf: function () {           
            let vali = this.$refs.form.validate()

            if(this.items.lqdFoodFee > 0) {
                if(this.items.lqdFoodClcd == '' || this.items.lqdFoodClcd == 0) {
                    this.snackControll([true, 2500, '경관유동식 청구서 기준을 선택해주세요.', 'warning'])
                    return
                }
            }

            if(this.items.snackFee > 0) {
                if(this.items.snackOfrClcd == '' || this.items.snackOfrClcd == 0) {
                    this.snackControll([true, 2500, '간식비 청구서 기준을 선택해주세요.', 'warning'])
                    return
                }
            }

            if(vali){
                let preAplEnd = null
    
                let aplStrt = this.items.aplStrt?.afterDateFormatHyp()
                
                if (new Date(this.orgAplStrt?.beforeDateFormatHyp()) < new Date(this.items.aplStrt)) {
                    preAplEnd = this.items.aplStrt?.afterDateFormatHyp()
                }
    
                let obj = {
                    upBedFeeLmt: this.items.upBedFeeLmt,
                    mealCostLmt: this.items.mealCostLmt,
                    mealCost: this.items.mealCost,
                    lqdFoodFee: this.items.lqdFoodFee,
                    snackFee: this.items.snackFee,
                    btyFee: this.items.btyFee,
                    msclnFee: this.items.msclnFee,
                    upBedFee1: this.items.upBedFee1,
                    upBedFee2: this.items.upBedFee2,
                    aplStrt: aplStrt,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    lqdFoodClcd: this.items.lqdFoodClcd,
                    preAplEnd: preAplEnd,
                    nbnftFeeInfPk: this.items.nbnftFeeInfPk,
                    rmrks: this.items.rmrks,
                    snackOfrClcd: this.items.snackOfrClcd,
                    aplEnd: this.items.aplEnd,
                    userNm : this.$store.getters.getUserNm,
                    list: []
                }

                this.rmTypList.forEach(e => {
                    let item = {
                        aplEnd       : e.aplEnd,
                        aplStrt      : e.aplStrt,
                        lvnRmTypNm   : e.lvnRmTypNm,
                        lvnRmTypPk   : e.lvnRmTypPk,
                        ord          : e.ord,
                        upBedYn      : e.upBedYn,
                        useFee       : e.useFee,
                    }

                    item.aplStrt = aplStrt
                    if(e.upBedYn == '1') item.useFee = this.items.upBedFee1
                    if(e.upBedYn == '2') item.useFee = this.items.upBedFee2

                    obj.list.push(item)
                });

                insNbnftFeeInfTyp(obj)
                    .then((response) => (this.insNbnftFeeInfAfter(response.data)))
                    .catch((error) => console.log('connect error opr/insNbnftFeeInfTyp : ' + error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }

        },
        insNbnftFeeInfAfter: function (res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selNbnftFeeInf()
                this.$refs.form.resetValidation()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        onCodeload: function () {            
            getMultiStcd(['114'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping: function (res) {            
            this.vCd114.splice(0)

            if(res.vCd114 != undefined && res.vCd114 != null){
                res.vCd114.forEach(cd => {                    
                    this.vCd114.push(cd)
                });
            }
        },
        onClickBtn: function (text) {
            if (text == '저장') {
                this.insNbnftFeeInf()
            }
            else if (text == '급여수가 변경내역') {
                this.menu.splice(0, 1, true)
            }
            else if (text == '급여수가 변경안내 발송'){
                this.menu.splice(3, 1, true)
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },
        onPrintBtn: function () {
            // this.$refs.PrgrmPrint.onShow()
            this.menu.splice(1, 1, true)
        },
        onReload:function(){
            this.onCodeload()
            this.selBnftFeeInfList()
            this.selClncFeeList()
            this.selNbnftFeeInf()
            this.selLvnRmTypList()
        }
    },

    data: () => ({
        aplStrt:'',
        aplEnd:'',
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '저장', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color: 'blue001', text: '급여수가 변경내역', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color: 'blueBtnColor', text: '급여수가 변경안내 발송', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color: 'grey006 ', text: '출력', width: '500px', disabled: false, },
        ],
        headers: [
            { text: '적용시기', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '구분', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '1등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '2등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '3등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '4등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '5등급', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
            custmCheck: value => !(value == '선택' || value == '') || 'Required.',
        },        
        group: {},
        groups: {},
        kinds: [],
        kind: [],
        BnftFeeInf: [],
        ClncFee: [],
        item: [],
        rmTypTitle:[],
        rmTypList:[],
        items: { upbedFee: '', preAplEnd: '', aplEnd: '', aplStrt: '', btyFee: '', lqdFoodClcd: '', lqdFoodFee: 0, mealCost: 0, mealCostLmt: 0, msclnFee: 0, nbnftFeeInfPk: 0, rmrks: '', snackFee: 0, snackOfrClcd: '', upBedFee1: 0, upBedFee2: 0, upBedFeeLmt: 0 },
        menu: [false, false, false, false, false],
        foodCode: [{ valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '1개월' }, { valcd: '2', valcdnm: '1식' }],
        snackCode: [{ valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '1일' }, { valcd: '2', valcdnm: '1식' }],
        fooditems: { food: '선택' },
        vCd114:[],
        snackitems: { snack: '선택' },
        orgAplStrt: '',
        snackOptions: [false, 3000, '', 'info'],
        nowDate: '',
        upBedYn: 1,
        checkbox: false,
        checkbox1: false,
        mealCostLmt: '',
        upBedFeeLmt: '',
    }),
};
</script>

<style>
.blnftAmntlnf-text-field .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot input{
    text-align: right !important;
}
</style>