<template>
    <v-sheet class="ma-0 pr-1 overflow-y-auto" rounded="md" height="708">
        <v-row no-gutters>
            <v-col class="d-flex pb-1 align-center" cols="12">
                <v-spacer></v-spacer>
                <v-icon small>mdi-checkbox-blank-circle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;양호&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-triangle-outline</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;보통&#44;&nbsp;&nbsp;</span>
                <v-icon small>mdi-close</v-icon>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&#58;&nbsp;불량&#44;&nbsp;&nbsp;</span>
                <span class="font-weight-medium" style="font-size: 0.875rem;">&nbsp;N&#47;A&#58;&nbsp;해당없음</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                <span class="font-weight-medium">점검일시</span>
            </v-col>
            <v-col class="d-flex justify-left align-center pa-1" cols="4">
                <span class="d-inline-block" style="width: 133px;">
                    <CmmDateComp v-model="inspDt" :required="true"></CmmDateComp>
                </span>
                <span class="white d-inline-block pa-1" style="width: 80px;">
                    <CmmTimeField v-model="inspTm" :required="false"></CmmTimeField>                                     
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <div style="line-height:18px;">
                    <div class="font-weight-medium">점검</div>
                    <div class="font-weight-medium">장소</div>
                </div>
            </v-col>
            <v-col class="d-flex justify-left align-center" cols="2">
                <span class="d-inline-block pa-1" style="width:100%;">
                    <v-text-field v-model="inspPl3" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="font-weight-medium">작성자</span>
            </v-col>
            <v-col class="d-flex justify-left align-center" cols="2">
                <span class="d-inline-block pa-1" style="width:120px;">
                    <v-text-field
                        v-model="wrtr"
                        readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <v-btn 
                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                    min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                    dark small depressed>
                    <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                        </template>
                        <span>직원선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="greyE01" no-gutters>            
            <v-col class="d-flex justify-center align-center tableBR" cols="9">
                <span class="font-weight-medium my-1">세부점검사항</span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="3">
                <span class="d-flex justify-center align-center d-inline-block ngh tableBR" style="width: 25%; height: 100%;">
                    <v-icon class="mt-1" color="black" small>mdi-checkbox-blank-circle-outline</v-icon>
                </span>
                <span class="d-flex justify-center align-center d-inline-block ngh tableBR" style="width: 25%; height: 100%;">
                    <v-icon class="mt-1" color="black" small>mdi-triangle-outline</v-icon>
                </span>
                <span class="d-flex justify-center align-center d-inline-block ngh tableBR" style="width: 25%; height: 100%;">
                    <v-icon class="mt-1" color="black" small>mdi-close</v-icon>
                </span>
                <span class="d-flex justify-center align-center d-inline-block font-weight-medium" style="width: 25%; height: 100%;">N&#47;A</span>
            </v-col>        
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>            
            <v-col class="tableBR" cols="9">
                <div class="tableBB pl-1" v-for="(list, i) in inspRecDtllist" :key="i">
                    <div v-if="list.itm1Cd == 3 || list.itm1Cd == 4" class="d-flex justify-left align-center" style="height: 42px;">
                        <div>
                            <div class="pr-2">{{ list.itm1CdNm }}</div>
                            <div class="d-flex" style="font-size:0.875rem;" v-if="list.itmDesc != '' && list.itmDesc != null">
                                <span>&#40;</span>
                                <span>{{list.itmDesc}}</span>
                                <span>&#41;</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex justify-left align-center" style="height: 32px;">
                        <span class="pr-2">{{ list.itm1CdNm }}</span>
                    </div>
                </div>                
            </v-col>
            <v-col class="" cols="3">
                <div class="" v-for="(list, i) in inspRecDtllist" :key="i">
                    <v-radio-group v-model="list.itm1Val" :key="forceRender"
                        class="pa-0 ma-0 d-flex justify-center align-center d-inline-block tableBB" style="" hide-details row>
                        <v-radio :class="['d-inline-block ma-0 tableBR d-flex justify-center py-1',list.itm1Cd == 3 || list.itm1Cd == 4?'ht42':'']" style="width:25%;"
                            on-icon="mdi-circle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="1"></v-radio>
                        <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:25%;"
                            on-icon="mdi-triangle-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="2"></v-radio>
                        <v-radio class="d-inline-block ma-0 tableBR d-flex justify-center" style="width:25%;"
                            on-icon="mdi-close" off-icon="mdi-minus-circle-outline" :ripple="false" value="3"></v-radio>
                        <v-radio class="d-inline-block ma-0 d-flex justify-center" style="width:25%;"
                            on-icon="mdi-minus-circle-off-outline" off-icon="mdi-minus-circle-outline" :ripple="false" value="0"></v-radio>
                    </v-radio-group>
                </div>                
            </v-col>          
        </v-row>
        <v-row no-gutters>
            <v-col class="d-flex justify-center align-center" style="background-color: #ededed !important;" cols="2">
                <div class="font-weight-medium">
                    <span class="d-inline-block" style="width: 100%; text-align: center;">비고</span>
                    <span class="d-inline-block" style="width: 100%; text-align: center;">&#40;조치사항&#41;</span>
                </div>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="10">
                <span class="d-inline-block pa-1" style="width:100%;">
                    <v-textarea
                        class="rmrksArea" v-model="cntnt13" no-resize hide-details outlined dense>
                    </v-textarea>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mb-6"></v-divider>
        <v-row class="pa-0 justify-left align-center" no-gutters>
            <v-icon style="font-size: 26px;" color="black">mdi-chevron-right</v-icon>
            <span class="d-inline-block text-h6 font-weight-bold" style="height:30px;">
                소화기&nbsp;현황&nbsp;&#40;필요시&#41;
            </span>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="greyE01" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="font-weight-medium my-1">연번</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="6">
                <span class="font-weight-medium my-1">위치</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR" cols="3">
                <span class="font-weight-medium my-1">유통기한</span>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="2">
                <span class="">
                    <v-btn class="mr-1" color="blue200" height="26" @click="addRow" icon>
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="" v-for="(item, i) in fireSttList" :key="i" no-gutters>
            <v-col class="d-flex justify-center align-center tableBB tableBR" cols="1">
                <span class="my-1">{{ i + 1 }}</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBB tableBR" cols="6">
                <span class="d-inline-block pa-1" style="width:100%;">
                    <v-text-field v-model="item.itm1Val" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBB tableBR" cols="3">
                <span class="d-inline-block" style="width: 133px;">
                    <CmmDateComp v-model="item.itm2Val" :required="true"></CmmDateComp>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBB" cols="2">
                <span class="py-1">
                    <v-btn
                        v-if="item.inspRecDtlPk != null"
                        class="mr-1" color="grey006" height="26"
                        @click="removeInspRecDtlInfo(item.inspRecDtlPk)"
                        icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-btn
                        v-else-if="item.inspRecDtlPk == null"
                        class="mr-1" color="grey006" height="26"
                        @click="removeRow(i)" icon>
                        <v-icon>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-row v-if="fireSttList.length == 0" class="py-2 justify-center" no-gutters>
            <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
        </v-row>
        <v-divider v-if="fireSttList.length == 0" class=""></v-divider>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo" :prtWrtDt="inspDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selFirePrvnInfo, insInspRecDtl, delInspRecDtl, delInspRecDtlInfo } from '../../api/sfty.js';

import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'FirePrvn',

    props : {
       
    },
        
    components: {
        EmpSelector,
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        
        })
    },

    computed: {
        getSltItem: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
        },
    },

    watch:{
        getSltItem: function (value) {
            if(this.$parent.$parent.$parent.$parent.tab == 2){
                this.clearForm()
                let sltInspDate = value.substring(0, 7)
                let insPos = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList.findIndex(v => v.inspPrd == sltInspDate)
               
                if(insPos != -1){
                    let inspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos]

                    if(typeof inspDate.itm3 == 'undefined')
                        this.getInspRecDtllist(inspDate.inspPrd?.replaceAll('.', '') + '01')
                    else
                        this.getInspRecDtllist(inspDate.itm3?.afterDateFormatDot())

                    this.cntnt13 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].cntnt13
                    this.inspPl3 = this.$parent.$parent.$parent.$parent.$parent.$parent.sftyList[insPos].inspPl3
                }
            }
        },
    },
    
    methods: {
        //화재예방점검 조회
        getInspRecDtllist: function (date){
            selFirePrvnInfo(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, date, 506, 5)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selFirePrvnInfo : ' + error))
        },
        //화재예방점검 저장
        setInspRecDtl: function (obj){
            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //화재예방점검 삭제
        removeInspRecDtl: function (){
            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        //소화기 현황 단건 삭제
        removeInspRecDtlInfo: function (pk){
            delInspRecDtlInfo(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk )
                .then((response) => ( this.setInspRecDtlInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtlInfo : ' + error))
        },
        getInspRecDtllistAfter: function (res){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)

            res.result.preDtllist.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(items.itm2Val != null)
                    items.itm2Val = items.itm2Val.beforeDateFormatHyp()
            });

            let keyList = []
            res.result.dtl1list.forEach(items => {
                items.itm1Cd = Number(items.itm1Cd)

                if(keyList.length == 0)
                    keyList.push(items.clsfc3CdNm)
                else{
                    let tmp = keyList.find(v => v === items.clsfc3CdNm)
                    if(tmp == undefined) keyList.push(items.clsfc3CdNm)
                }

                this.wrtr = items.wrtr

                if(this.inspDt == ''){
                    if(items.inspDt == null)
                        this.inspDt = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem.replaceAll('.', '')?.beforeDateFormatHyp()
                    else
                        this.inspDt = items.inspDt?.beforeDateFormatHyp()
                }

                if(this.inspTm == ''){
                    if(items.inspTmHh == null)
                        this.inspTm = '12:00'
                    else
                        this.inspTm = items.inspTmHh + ':' + items.inspTmMm
                }
  
                if(items.itm1Val == null)
                    items.itm1Val = '0'
            });

            res.result.dtl1list.sort((x, y) => x.itm1Cd - y.itm1Cd)

            this.inspTitle = keyList
            this.inspRecDtllist = res.result.dtl1list
            this.fireSttList = res.result.preDtllist
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk = 0
                this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.getTableList()
                this.$parent.$parent.$parent.$parent.tab = 0
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.btnControll(1, true, 'fill')
                this.$parent.$parent.$parent.$parent.btnControll(0, true, 'out')
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        setInspRecDtlInfoAfter: function (res){
            if(res.statusCode == 200){
                let sltInspDate = this.$parent.$parent.$parent.$parent.$parent.$parent.selectedItem
                
                this.getInspRecDtllist(sltInspDate.replaceAll('.', ''))
                
                this.clearForm()
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, res.message, 'info'])
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 506
            obj.clsfc2Cd = 5
            obj.inspRecPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltInspRecPk
            obj.inspDt = this.$moment(this.inspDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
            obj.cntnt1 = this.cntnt13
            obj.inspPl = this.inspPl3
            obj.list = []

            this.inspRecDtllist.forEach(items => {               
                items.inspDtlDt = this.$moment(this.inspDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                items.inspDt = this.$moment(this.inspDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                items.wrtr = this.wrtr
                let tmp = this.inspTm.split(':', 2)
                items.inspTmHh = tmp[0]
                items.inspTmMm = tmp[1]
  
                obj.list.push(items)
            });

            this.fireSttList.forEach(items => {
                let tmp = Object.assign({}, items)
                tmp.inspDtlDt = this.$moment(this.inspDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                tmp.inspDt = this.$moment(this.inspDt, 'YYYY-MM-DD', true).format('YYYYMMDD')

                if(items.itm2Val != '' && items.itm2Val != null)
                    tmp.itm2Val = this.$moment(items.itm2Val, 'YYYY-MM-DD', true).format('YYYYMMDD')
                    
                obj.list.push(tmp)
            });

            //console.log(obj)
            this.setInspRecDtl(obj)
        },
        addRow: function (){
            let obj = Object.assign({}, this.dmyFireSttInf)

            this.fireSttList.push(obj)
        },
        removeRow: function (idx){
            if(this.fireSttList[idx].inspRecDtlPk == null)
                this.fireSttList.splice(idx, 1)
            else {
                this.sltIndex = idx
            }
        },
        clearForm: function (){
            this.inspTitle.splice(0)
            this.inspRecDtllist.splice(0)
            this.fireSttList.splice(0)
            this.sltIndex = -1
            this.wrtr = ''
            this.cntnt13 = ''
            this.inspPl3 = ''
            this.inspDt = ''
            this.inspTm = ''
            this.forceRender += 1
        },
        getEmpInfo: function (obj) {
            this.wrtr = obj[0].name
        },
        timePicked: function (){
            this.$refs.timePicker.save(this.inspTm)
            this.forceRender += 1
        },
    },

    data: () => ({
        dialog: [false],
        inspTitle: [],
        inspRecDtllist: [],
        fireSttList: [],
        sltIndex: -1,
        wrtr: '',
        cntnt13: '',
        inspPl3: '',
        inspDt: '',
        inspTm: '',
        forceRender: 0,
        dmyFireSttInf: {
            inspDt: '', inspRecDtlPk: null, clsfc1Cd: 506, clsfc2Cd: 5, clsfc3Cd: 2,
            itm1Cd: 1, itm1Val: '', itm2Cd: 2, itm2Val: ''
        },
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>

<style>
    .ht42 { height:42px !important; }
</style>