<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-1" color="" min-width="1260" max-width="1260" height="835">
        <!-- contents -->
        <div class=" mt-2 d-flex align-center pr-2">
            <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
            <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                9-1. 수급자 정보현황
            </span>
            <v-spacer></v-spacer>
            <v-btn v-for="(list, j) in outlinedBtn" :key="j" @click = "onClickBtn(list.text)"
                :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{list.icon}}</v-icon>
                <span class="fontOneRem ml-1">{{list.text}}</span>
            </v-btn>
        </div>
        <div class=" pr-2 mt-5 px-2">
            <v-tabs hide-slider v-model="tab" color="white" class="black--text" active-class="bodyColor" center-active>
                <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style">{{ item.tab }}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab" >
                <v-tab-item transition="fade-transition">
                    <tab1 ref="tab1" :tab="tab"></tab1>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <tab2 ref="tab2" :tab="tab"></tab2>
                </v-tab-item>
                <v-tab-item transition="fade-transition">
                    <tab3 ref="tab3" v-if="tab==2" :tab="tab"></tab3>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <v-dialog v-model="menu[0]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(0, 1, false)"></ReportMgmt>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { excelDownload, pdfDownload } from '../../api/index.js';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import tab1 from '../../components/btcInq/BnfcrSttsTab1.vue';
import tab2 from '../../components/btcInq/BnfcrSttsTab2.vue';
import tab3 from '../../components/btcInq/BnfcrSttsTab3.vue';
export default {
    name: 'BnfcrStts',
        
    components: {
        tab1,
        tab2,
        tab3,
        ReportMgmt,

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            //this.getExcelInfo()
        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        getExcelInfo: function () {
            excelDownload(this.$store.getters.getFcltyNum, '1', null, null, 0 )
                .then((response) => ( this.getExcelInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /report/excelDownload : ' + error))
        },
        getExcelInfoAfter: function (res) {
            this.excelInfo.filePth = res.filePth
            this.excelInfo.fileNm = res.fileNm
            this.excelInfo.orgFileNm = res.orgFileNm
        },
        fileDownLoader: function (pth, nm) {
            pdfDownload(pth, nm)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileTempDownload : ' + error))
        },
        fileDownLoaderAfter: function (res) {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))

            link.href = url
            link.setAttribute('download', `${this.excelInfo.orgFileNm}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },        
        onClickBtn: function (value){
            switch (value) {
                case '엑셀':
                    if(this.tab == 0)
                        this.$refs.tab1.xlsxProd()
                    else if(this.tab == 1)
                        this.$refs.tab2.xlsxProd()
                    else if(this.tab == 2)
                        this.$refs.tab3.xlsxProd()
                    break;
                case '출력':
                    if(this.tab == 0){
                        this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                        this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
                        this.menu.splice(0, 1, true)
                    }
                    else if(this.tab == 1)
                        this.$refs.tab2.prtOutStay()
                    else if(this.tab == 2)
                        this.$refs.tab3.prtLinkRcrd()
                    break;
                default:
                    break;
            }
        },
        rprtExtLink: function (){
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            console.log(this.prtThrowObj)
            this.menu.splice(0, 1, true)
        },
    },
    
    data: () => ({
        tab:0,
        tabItems: [
            { index: 0, tab: '수급자목록', style: 'font-size: 1.2rem !important;'},
            { index: 1, tab: '외출/외박 현황', style: 'font-size: 1.2rem !important;'},
            { index: 1, tab: '연계기록지 목록', style: 'font-size: 1.2rem !important;'},
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class:'', color:'grey006', text: '엑셀', type: 'action', width: '500px', visible: true, disabled: false, },
            { icon: 'mdi-printer-outline', class:'ml-2', color:'grey006', text: '출력', type: 'action', width: '500px', visible: true, disabled: false, },
        ],
        dialog: [false, false],
        excelInfo: { filePth: '', fileNm: '', orgFileNm: '' },
        menu: [false, false, false],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '17',
            inqYYYY: '', inqYmdStart: '', inqYmdEnd: '',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>