<template>
    <v-sheet class="overflow-y-auto overflow-x-hidden mt-1" height="721px" min-width="960" max-width="960">
        <v-form ref="form" lazy-validation>
        <v-row class="pa-0 tableBB" no-gutters>
            <v-col class="pr-1" cols="2">

                <div class="mb-3"
                    v-for="( item, i ) in imgList" :key="i">
                    <imageCard
                        ref="imageCard"
                        :cstmFit="'vrtclAspct'" :imgSrc="item.src"
                        @isEvent="delImgfiles(i)" @onDown="athFileDownload(i, '2')">
                    </imageCard>
                </div>
                <div v-if="imgList.length == 0">
                    <v-img
                        :aspect-ratio="3/4" ref="img_blob"
                        lazy-src="../../assets/bnfcrNoImg.png">
                    </v-img>
                </div>
                <!-- <v-img :aspect-ratio="3/4" ref="img_blob"
                    :src="blobSrc"
                    lazy-src="../../assets/bnfcrNoImg.png">
                </v-img> -->

                <!-- <input class="inputHidden" type="text" value="" ref="encPath" @focus="throwSrc" /> -->

                <span v-if="items.entStcd != '4'" class="d-inline-block pr-1" style="width:100%;">
                    <v-file-input
                        v-model="files"
                        class="fileOLFld" truncate-length="7"
                        prepend-icon="" prepend-inner-icon="mdi-camera-outline"
                        @change="upFile"
                        hide-details outlined dense>
                    </v-file-input>
                </span>
                <div class="d-flex justify-center align-center pt-2">
                    <span class="font-weight-bold">{{items.entStcdnm}}</span>
                </div>
                <div v-if="items.entStcd == '3'" class="d-flex justify-center align-center">
                    <span class="font-weight-mideum">&#40;&nbsp;{{items.outDtStrt}}&nbsp;{{items.outTmHhStrt}}&#58;{{items.outTmMmStrt}}&nbsp;&#126;&nbsp;&#41;</span>
                </div>
                <div v-if="items.entStcd == '4'" class="d-flex justify-center align-center">
                    <span class="font-weight-mideum">&#40;&nbsp;{{items.lvDt}}&nbsp;&#41;</span>
                </div>
                <div v-if="mfdRsn != ''" class="" style="font-size:0.8rem">
                    <div class="font-weight-bold d-flex justify-center align-center" style="color:red;">
                        <span class="font-weight-mideum">※변경일&#58;{{mdfDt}}</span>
                    </div>
                    <div class="font-weight-bold d-flex justify-center align-center" style="color:red;">
                        <span class="font-weight-mideum">{{mfdRsn}}</span>
                    </div>
                </div>
            </v-col>
            <v-col class="" cols="10">
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">수급자명</span>
                    </v-col> 
                    <v-col class="" cols="4">
                        <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                            <!-- 수급자명 area -->
                            <v-text-field v-model="items.name" color="blue" ref="info"
                                :rules="[rules.required, rules.strCheck]" hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">성별</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="">
                            <!-- 성별 area -->
                            <v-radio-group v-model="items.gndr" class="pl-2 pt-1" :rules="[rules.required]" ref="info" hide-details row>
                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="남" value="M"></v-radio>
                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="여" value="F"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">생년월일</span>
                        <v-btn
                            class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 10)" icon>
                            <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col class="d-flex align-center" cols="4">
                        <span class="d-inline-block py-1 pl-2" style="width: 154px;">
                            <CmmDateComp v-model="items.rsdntBirth" :required="true"></CmmDateComp>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">생활실</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="white d-inline-block py-1 pl-2" style="width: 180px;">
                            <!-- 생활실 area -->
                            <span class="d-inline-block d-flex flex-row">
                                <v-text-field
                                    v-model="items.lvnRm" :rules="[rules.required]"
                                    readonly hide-details outlined dense>
                                </v-text-field>
                                <v-btn
                                    class="ml-1" color="#FFB6B9" style="padding:0px 1px 0px 2px !important"
                                    min-width="30" height="25" @click="mdlMenu.splice(0, 1, true)"
                                    dark small>
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-bed-king-outline</v-icon>
                                        </template>
                                        <span>생활실선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">입소일</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                            <!-- 입소일 area -->
                            <CmmDateComp v-model="items.entDt" :required="false"></CmmDateComp>
                        </span>
                        <!-- 입소시간 area -->
                        <span class="d-inline-block ml-1" style="width: 80px;">
                            <CmmTimeField v-model="entTm" ref="test1" :required="false"></CmmTimeField>
                        </span>
                        <v-btn
                            class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 3)" icon>
                            <v-icon size="20" style="padding-bottom: 2px;">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">정부지원</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="d-inline-block">
                            <!-- 정부 지원 area -->
                            <span class="d-inline-block py-1 pl-1" style="width: 80px;">
                                <v-checkbox v-model="items.gvrnmSprt" hide-details :ripple="false" label="간식비" value="1"></v-checkbox>
                            </span>
                            <span class="d-inline-block ml-2" style="width: 100px;">
                                <v-checkbox v-model="items.gvrnmSprt" hide-details :ripple="false" label="식사재료비" value="2"></v-checkbox>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">생신일</span>
                    </v-col>
                    <v-col class="d-flex align-center" cols="4">
                        <span class="white d-inline-block py-1 pl-2 mr-1" style="width: 154px;">
                            <!-- 생신일 area -->
                            <CmmDateComp v-model="items.birth" :required="true"></CmmDateComp>
                        </span>
                        <span class="d-inline-block" style="width: 108px;">
                            <v-radio-group v-model="items.lnrYn" :rules="[rules.required]" class="" hide-details row>
                                <v-radio class="pa-0 ma-0" :ripple="false" label="양력" value="1"></v-radio>
                                <v-radio class="pa-0 ma-0" :ripple="false" label="음력" value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">본인 부담률</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="white d-inline-block pl-2 py-1" style="width: 140px;">
                            <!-- 본인 부담률 area -->
                            <span class="d-inline-block" style="width: 132px;">
                                <v-select
                                    v-model="items.copayRate" :key="forceRender"
                                    :items="$parent.$parent.$parent.$parent.$parent.copayCode"
                                    item-text="valcdnm" item-value="valcd" :rules="[rules.required]"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">건강검진 제출일</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="d-inline-block"> 
                            <!-- 입소전 건강검진 제출일 area -->
                            <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                                <CmmDateComp v-model="items.entHlthChdt" :required="true"></CmmDateComp>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">인정번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="d-inline-block pl-2" style="width: 300px;">
                            <!-- 인정번호 area -->
                            <span class="d-inline-block" style="width: 134px;">
                                <v-text-field v-model="items.acknwNum1" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </span>
                            <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1 mx-1" style="width: 15px;">
                            &#45;
                            </span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field v-model="items.acknwNum2" hide-details outlined dense></v-text-field>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                        <span class="black--text font-weight-medium">휴대폰</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="d-inline-block pl-2 pt-0 pb-1">
                            <!-- 휴대전화 area -->
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field v-model="items.celphn_1" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                            </span>
                            <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                            &#45;
                            </span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field v-model="items.celphn_2" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                            </span>
                            <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                            &#45;
                            </span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field v-model="items.celphn_3" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                            </span>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">전화번호</span>
                    </v-col>
                    <v-col class="" cols="4">
                        <span class="d-inline-block pl-2 pt-0 pb-1">
                            <!-- 전화번호 area -->
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field v-model="items.tel_1" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                            </span>
                            <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                            &#45;
                            </span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field v-model="items.tel_2" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                            </span>
                            <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                            &#45;
                            </span>
                            <span class="d-inline-block" style="width: 60px;">
                                <v-text-field v-model="items.tel_3" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                        <span class="black--text font-weight-medium">인정등급&#47;기간</span>
                    </v-col>
                    <v-col class="py-1" cols="10">
                        <span class="d-inline-block d-flex align-center pl-2">
                            <!-- 인정등급 / 기간 area -->
                            <span class="d-inline-block" style="width: 100px;">
                                <v-select
                                    v-model="items.acknwRtng" :key="forceRender"
                                    :items="$parent.$parent.$parent.$parent.$parent.acknwCode"
                                    item-text="valcdnm" item-value="valcd" :rules="[rules.required]"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                            <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                                <CmmDateComp v-model="items.acknwPrdStrt" :maxDt="items.acknwPrdEnd" :required="true"></CmmDateComp>
                            </span>
                            <span class="d-flex align-center ml-4 mr-2">
                                <v-icon size="16" style="color: #dbdbdb;">mdi-tilde</v-icon>
                            </span>
                            <span class="white d-inline-block py-1 pl-2" style="width: 154px;">
                                <CmmDateComp v-model="items.acknwPrdEnd" :minDt="items.acknwPrdStrt" :required="true"></CmmDateComp>
                            </span>
                            <span class="d-inline-block ml-2" style="width: 90px;">
                                <v-select
                                    v-model="selectYear"
                                    :items="year_term" @change="calcYear()" :key="forceRender"
                                    :disabled="items.acknwPrdStrt != '' ? false : true"
                                    dense hide-details outlined>
                                </v-select>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">주소</span>
                    </v-col>
                    <v-col class="" cols="10">
                        <span class="white d-inline-block py-1 pl-2">
                            <!-- 주소 area -->
                            <span class="d-inline-block d-flex flex-row">
                                <span class="d-inline-block" style="width: 90px;">
                                    <v-text-field 
                                        v-model="items.zip" :rules="[rules.required, rules.numberCheck]"
                                        readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn
                                    class="ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                                    min-width="30" height="25" @click="mdlMenu.splice(1, 1, true), addressPopup1=true" dark small>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                        </template>
                                        <span>주소검색</span>
                                    </v-tooltip>
                                </v-btn>
                                <span class="d-inline-block" style="width: 300px;">
                                    <v-text-field 
                                        v-model="items.addr" :rules="[rules.required]"
                                        class="ml-2" readonly hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <span class="d-inline-block" style="width: 163px;">
                                    <v-text-field v-model="items.addrDtl" class="ml-1" hide-details outlined dense></v-text-field>
                                </span>
                            </span>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">주요질환</span>
                    </v-col>
                    <v-col class="pl-2 pr-3 py-1" cols="10">
                        <v-textarea
                            class="rmrksArea" rows="1" 
                            v-model="items.majorDsss" :rules="[rules.required]"
                            no-resize hide-details outlined dense>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                        <span class="black--text font-weight-medium">비고</span>
                    </v-col>
                    <v-col class="pl-2 pr-3 py-1" cols="10">
                        <v-textarea
                            class="rmrksArea" rows="3"
                            v-model="items.rmrks"
                            no-resize hide-details outlined dense>
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <div class="d-flex align-center mt-7">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">보호자&nbsp;정보</span>
        </div>
        <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">보호자명</span>
            </v-col>
            <v-col class="pl-2 pr-3 py-1" cols="3">
                <span class="d-inline-block" style="width: 110px;">
                    <!-- 보호자명 area -->
                    <v-text-field v-model="galFmlyItems.name" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">관계</span>
            </v-col>
            <v-col class="pl-2 pr-3 py-1" cols="2">
                <span class="d-inline-block" style="width: 98px;">
                    <!-- 관계 area -->
                    <v-text-field v-model="galFmlyItems.rltns" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">전화번호</span>
            </v-col>
            <v-col class="pl-1 pr-3" cols="4">
                <span class="d-inline-block pt-0">
                    <!-- 전화번호 area -->
                    <span class="d-inline-block" style="width: 47px;">
                        <v-text-field v-model="galFmlyItems.tel_1" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 56px;">
                        <v-text-field v-model="galFmlyItems.tel_2" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 56px;">
                        <v-text-field v-model="galFmlyItems.tel_3" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                    </span>
                </span> 
            </v-col>
        </v-row>
        <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">청구서수신</span>
            </v-col>
            <v-col class="pl-2 py-1" cols="3">
                <span class="d-inline-block" style="width: 102px;">
                    <v-select
                        v-model="galFmlyItems.msClcd"
                        :items="smsCd" :rules="[rules.required]" :key="forceRender"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
                <!-- <span class="d-inline-block">
                    <span class="d-inline-block py-1" style="width: 60px;">
                        <v-checkbox v-model="galFmlyItems.billRcvYn" :rules="[rules.selectCheck]" hide-details :ripple="false" label="우편" value="1"></v-checkbox>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 60px;">
                        <v-checkbox v-model="galFmlyItems.billRcvYn" :rules="[rules.selectCheck]" hide-details :ripple="false" label="문자" value="2"></v-checkbox>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 70px;">
                        <v-checkbox v-model="galFmlyItems.billRcvYn" :rules="[rules.selectCheck]" hide-details :ripple="false" label="이메일" value="3"></v-checkbox>
                    </span>
                </span> -->
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">생년월일</span>
            </v-col>
            <v-col class="d-flex align-center pl-2 pr-5 py-1" cols="2">
                <CmmDateComp v-model="galFmlyItems.rsdntBirth" :required="true"></CmmDateComp>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">휴대폰</span>
            </v-col>
            <v-col class="pl-1" cols="4">
                <span class="d-inline-block pt-0">
                    <!-- 휴대전화 area -->
                    <span class="d-inline-block" style="width: 47px;">
                        <v-text-field v-model="galFmlyItems.celphn_1" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 56px;">
                        <v-text-field v-model="galFmlyItems.celphn_2" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 56px;">
                        <v-text-field v-model="galFmlyItems.celphn_3" :rules="[rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block">
                    <!-- 급여 청구 area -->
                    <span class="d-inline-block" style="width: 55px;">
                        <v-checkbox v-model="galFmlyItems.cellRcvYn" hide-details :ripple="false" label="급여" value="1"></v-checkbox>
                    </span>
                    <span class="d-inline-block" style="width: 55px;">
                        <v-checkbox v-model="galFmlyItems.cellRcvYn" hide-details :ripple="false" label="청구" value="2"></v-checkbox>
                    </span>
                </span>
                </span>
            </v-col>
        </v-row>
        <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">이메일</span>
            </v-col>
            <v-col class="pl-2 pr-3 py-1" cols="11">
                <span class="d-inline-block d-flex">
                    <!-- 이메일 area -->
                    <span class="d-inline-block" style="width: 150px;">
                        <v-text-field v-model="galFmlyItems.emailId"
                            :rules="galFmlyItems.billRcvYn.findIndex( v => v == '3') > -1 ? [rules.required] : []"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block d-flex justify-center align-center mx-2" style="width: 28px; height: 28px;">
                        <v-icon size="22" style="color: #dbdbdb;">mdi-at</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 150px;">
                        <v-text-field v-model="galFmlyItems.emailDmn"
                            :rules="galFmlyItems.billRcvYn.findIndex( v => v == '3') > -1 ? [rules.emailDomain, rules.required] : []"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 140px;">
                        <v-select
                            v-model="selectEmail"
                            @change="emailSelected" :key="forceRender"
                            :items="$parent.$parent.$parent.$parent.$parent.emailCode"
                            item-text="valcdnm" item-value="valcd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </span>
            </v-col>
        </v-row>
        <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">청구지주소</span>
            </v-col>
            <v-col class="pl-2 pr-3 py-1" cols="11">
                <span class="white d-inline-block" style="width: 120px;">
                    <!-- 청구지주소 area -->
                    <span class="d-inline-block d-flex flex-row">
                        <v-text-field
                            v-model="galFmlyItems.zip" :rules="[rules.required]"
                            readonly hide-details outlined dense>
                        </v-text-field>
                        <v-btn
                            class="ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                            min-width="30" height="25" @click="mdlMenu.splice(1, 1, true), addressPopup2=true" dark small>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                                </template>
                                <span>주소검색</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                </span>
                <span class="d-inline-block" style="width: 300px;">
                    <v-text-field
                        v-model="galFmlyItems.addr" :rules="[rules.required]"
                        class="ml-2" readonly hide-details outlined dense>
                    </v-text-field>
                </span>
                <span class="d-inline-block" style="width: 360px;">
                    <v-text-field v-model="galFmlyItems.addrDtl" class="ml-1" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;" no-gutters
            v-for="(list, i) in fmlyItems" :key="i">
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">가족이름&nbsp;{{i+1}}</span>
            </v-col>
            <v-col class="pl-2 pr-3 py-1" cols="3">
                <span class="d-inline-block" style="width: 110px;">
                    <!-- 가족이름1 area -->
                    <v-text-field v-model="fmlyItems[i].name" hide-details outlined dense></v-text-field>
                </span>
                <span class="d-inline-block ml-1" style="width: 102px;">
                    <v-select
                        v-model="fmlyItems[i].msClcd"
                        :items="smsCd2" :rules="fmlyItems[i].name != '' ? [rules.required] : []" :key="forceRender"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">관계&nbsp;{{i+1}}</span>
            </v-col>
            <v-col class="pl-2 pr-3 py-1" cols="2">
                <span class="d-inline-block" style="width: 120px;">
                    <!-- 관계 area -->
                    <v-text-field v-model="fmlyItems[i].rltns" hide-details outlined dense></v-text-field>
                </span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center" cols="1">
                <span class="black--text font-weight-medium">휴대폰&nbsp;{{i+1}}</span>
            </v-col>
            <v-col class="pl-1" cols="4">
                <span class="d-inline-block pt-0">
                    <!-- 휴대전화 area -->
                    <span class="d-inline-block" style="width: 47px;">
                        <v-text-field v-model="fmlyItems[i].celphn_1" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 56px;">
                        <v-text-field v-model="fmlyItems[i].celphn_2" hide-details outlined dense></v-text-field>
                    </span>
                    <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                    &#45;
                    </span>
                    <span class="d-inline-block" style="width: 56px;">
                        <v-text-field v-model="fmlyItems[i].celphn_3" hide-details outlined dense></v-text-field>
                    </span>
                    <!-- 급여 청구 area -->
                    <span class="d-inline-block" style="width: 50px;">
                        <v-checkbox v-model="fmlyItems[i].cellRcvYn" hide-details :ripple="false" label="급여" value="1"></v-checkbox>
                    </span>
                    <span class="d-inline-block" style="width: 50px;">
                        <v-checkbox v-model="fmlyItems[i].cellRcvYn" hide-details :ripple="false" label="청구" value="2"></v-checkbox>
                    </span>
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        </v-form>

        <!-- <div>{{items}}</div> -->
        <!-- <div>{{galFmlyItems}}</div> -->
        <!-- <div>{{fmlyItems}}</div> -->
        
        <v-dialog v-model="mdlMenu[0]" max-width="900" persistent eager>
            <LvnRmMdl
                ref="lvnRmMdl"
                @modalClose="mdlMenu.splice(0, 1, false)" @sltLvnRmInf="getLvnRmInf">
            </LvnRmMdl>
        </v-dialog>
        <v-dialog v-model="mdlMenu[1]" content-class="round" max-width="500" persistent eager>
            <AdrsSrch ref="adrsSrch" @onDataThr="onAddressComp" @isClose="mdlMenu.splice(1, 1, false)"></AdrsSrch>
        </v-dialog>
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute centered top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
      </v-snackbar>
    </v-sheet>
</template>

<script>
import LvnRmMdl from '../opr/LvnRmMdl.vue';
import AdrsSrch from '../commons/AdrsSrch.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import imageCard from '../commons/imageCard.vue';

import { bnfcrInfoDetail, galFmlyList, fmlyList, setBnfcrFull, delBnfcrInfo } from '../../api/bnfcr.js';
import { tmpFileUpload, selAtchdFileList, delAtchdFile, fileDownload } from '../../api/index.js';
import { remoteURL } from '@/api/baseAxios';

    export default {
        name: 'bnfcrInfrmMgmtWrt',

        props : {
            info: Object,
        },
        
        components: {
            LvnRmMdl,
            AdrsSrch,
            CmmDateComp,
            CmmTimeField,
            imageCard,
        },

        created: function(){
            //CDN 라이브러리 로드
            //let themejs = document.createElement('script')
            //themejs.setAttribute('src', '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js')
            //document.head.appendChild(themejs)             
        },

        mounted: function(){
            //돔 로딩이 끝난 후 작업
            this.$nextTick(function () {
                //this.$refs.form.validate();
                this.fmlyItems.push(Object.assign({}, this.dummyFmlyItem))
                this.fmlyItems.push(Object.assign({}, this.dummyFmlyItem))
                this.getAtchdFileList()
            })
        },

        watch: {
            'items.bnMmbrPk': function () {
                this.$store.commit('setBnfcrName', this.items.name)
                this.mdfDt = ''
                this.mfdRsn = ''
                if(this.items != [])
                    this.convertFormat()
            },

            'galFmlyItems.bnMmbrPk': function () {
                if(this.galFmlyItems != [])
                    this.galFmlyFormat()
            },

            'items.copayRate': function () {
                if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                    if(this.$store.state.bnfcrChgClmn.orgCopayRate == this.items.copayRate){
                        this.$store.state.bnfcrChgClmn.cfmSave.splice(2, 1, '2')
                        this.$store.state.bnfcrChgClmn.chgCopayRate = ''
                        this.$store.state.bnfcrChgClmn.chgCopayRateNm = ''
                    }
                    else{
                        this.$store.state.bnfcrChgClmn.cfmSave.splice(2, 1, '1')
                        this.$store.state.bnfcrChgClmn.chgCopayRate = this.items.copayRate

                        this.$parent.$parent.$parent.$parent.$parent.copayCode.forEach(cd => {
                            if(cd.valcd == this.items.copayRate)
                                this.$store.state.bnfcrChgClmn.chgCopayRateNm = cd.valcdnm
                        });
                    }
                }
                else{
                    this.$store.state.bnfcrChgClmn.cfmSave.splice(2, 1, '2')
                    this.$store.state.bnfcrChgClmn.orgCopayRate = ''
                    this.$store.state.bnfcrChgClmn.chgCopayRate = ''
                    this.$store.state.bnfcrChgClmn.chgCopayRateNm = ''
                }

                this.$store.commit('setCopayRate', this.items.copayRate)
            },

            'items.acknwRtng': function () {
                if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                    if(this.$store.state.bnfcrChgClmn.orgAcknwRtng == this.items.acknwRtng){
                        this.$store.state.bnfcrChgClmn.cfmSave.splice(3, 1, '2')
                        this.$store.state.bnfcrChgClmn.chgAcknwRtng = ''
                        this.$store.state.bnfcrChgClmn.chgAcknwRtngNm = ''
                    }
                    else{
                        this.$store.state.bnfcrChgClmn.cfmSave.splice(3, 1, '1')
                        this.$store.state.bnfcrChgClmn.chgAcknwRtng = this.items.acknwRtng
                        if(this.items.acknwRtng == '99')
                            this.$store.state.bnfcrChgClmn.chgAcknwRtngNm = '등급외'
                        else
                            this.$store.state.bnfcrChgClmn.chgAcknwRtngNm = this.items.acknwRtng + '등급'
                    }
                }
                else{
                    this.$store.state.bnfcrChgClmn.cfmSave.splice(3, 1, '2')
                    this.$store.state.bnfcrChgClmn.orgAcknwRtng = ''
                    this.$store.state.bnfcrChgClmn.chgAcknwRtng = ''
                    this.$store.state.bnfcrChgClmn.chgAcknwRtngNm = ''
                }

                this.$store.commit('setAcknwRtng', this.items.acknwRtng)
            },

            'items.gvrnmSprt': function () {
                if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                    if(this.$store.state.bnfcrChgClmn.orgGvrnmSprt.toString() == this.items.gvrnmSprt.toString()){
                        this.$store.state.bnfcrChgClmn.cfmSave.splice(1, 1, '2')
                        this.$store.state.bnfcrChgClmn.chgGvrnmSprt.splice(0)
                    }
                    else{
                        this.$store.state.bnfcrChgClmn.cfmSave.splice(1, 1, '1')
                        this.$store.state.bnfcrChgClmn.chgGvrnmSprt = Object.assign([], this.items.gvrnmSprt)
                    }
                }
                else{
                    this.$store.state.bnfcrChgClmn.cfmSave.splice(1, 1, '2')
                    this.$store.state.bnfcrChgClmn.orgGvrnmSprt.splice(0)
                    this.$store.state.bnfcrChgClmn.chgGvrnmSprt.splice(0)
                }

                this.$store.commit('setGvrnmSprt', this.items.gvrnmSprt)
            },

            // 'entTm': function () {
            //     let rst = ''
            //     rst = this.entTm.replace(/\s/gi, "")
            //     rst = rst.replace(/(\d{2})(\d{2})/, '$1:$2');
            //     console.log(rst)
            // },
        },
    
        methods: {
            getInfoList: function () {
                bnfcrInfoDetail(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState)
                    .then((response) => ( this.getInfoListAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/selBnfcrInfo : ' + error))
            },
            getGalFmlyList: function () {
                galFmlyList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState)
                    .then((response) => ( this.galFmlyItems = response.data ))
                    .catch((error) => console.log('connect error /bnfcr/selGarnInfo : ' + error))
            },

            getFmlyList: function () {
                fmlyList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState)
                    .then((response) => ( this.mappingFmlyData(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/selFmlyList : ' + error))
            }, 
            delInfo: function () {
                delBnfcrInfo(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState, this.$store.getters.getUserNm)//this.$store.getters.getBnfcrState.bnfcr
                    .then((response) => ( this.delBnfcrAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/delBnfcr : ' + error))
            },

            // 첨부파일 목록 조회
            getAtchdFileList: function (){
                selAtchdFileList(this.$store.getters.getFcltyNum, 1, this.$store.getters.getBnfcrState.bnmmbr)
                    .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            },

            // 첨부파일 임시 저장
            athFileUpload: function (formData) {
                this.fileList.splice(0)
                this.imgList.splice(0)

                tmpFileUpload(formData)
                    .then((response) => ( this.upFileAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
            },

            //첨부파일 다운로드
            athFileDownload: function (index, mode) {
                if(mode == '1'){
                    fileDownload(this.fileList[index])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
                else if(mode == '2'){
                    fileDownload(this.imgList[index])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error)) 
                }
            },

            //첨부파일 삭제
            removeAthFile: function (pk) {
                delAtchdFile(this.$store.getters.getFcltyNum, pk)
                    .then((response) => ( this.delAtchdFileAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
            },

            getInfoListAfter: function (res) {
                this.$store.state.bnfcrChgClmn.orgGvrnmSprt = res.gvrnmSprt
                this.$store.state.bnfcrChgClmn.orgCopayRate = res.copayRate.toString()
                this.$store.state.bnfcrChgClmn.orgAcknwRtng = res.acknwRtng

                this.items = Object.assign({}, res)
                this.getAtchdFileList()
            },

            getAtchdFileListAfter: function(res){
                this.fileList.splice(0)
                this.imgList.splice(0)

                let fList = res.filter(v => v.fileKndCd == '1')
                let iList = res.filter(v => v.fileKndCd == '2')

                fList.forEach( items => {
                    let fileNm = items.fileNm.split(".")
                    items.ext = fileNm[1]  
                    this.fileList.push(items)
                })
                iList.forEach( items => {
                    items.src = remoteURL + 'opr/imgView?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                    this.imgList.push(items)
                })
            },

            upFileAfter: function (res) {
                let fList = res.files.filter(v => v.fileKndCd == '1')
                let iList = res.files.filter(v => v.fileKndCd == '2')

                fList.forEach( items => {
                    let fileNm = items.fileNm.split(".")
                    items.ext = fileNm[1]  
                    this.fileList.push(items)
                })
        
                iList.forEach( items => {
                    items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                    this.imgList.push(items)
                })
            },

            delAtchdFileAfter: function (res) {
                if(res.statusCode == 200){
                    this.snackControll([true, 2500, res.message, 'info'])
                    this.getAtchdFileList()
                }
                else {
                    this.snackControll([true, 5000, res.message, 'error'])
                }
            },
            
            insBnfcrFull: function () {

                let chngHstry = this.$store.getters.getBnfcrSaveArea
                let chngHis = this.$store.getters.getBnfcrSaveChc
                this.items.mfdRsn = chngHstry
                this.items.hisChc = chngHis

                let backItems = null
                let backGalFmly = null

                if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.entTm)){
                    let splitTime = this.entTm?.split(':', 2)
                    this.items.entTmHh = splitTime[0]
                    this.items.entTmMm = splitTime[1]
                }
                else{
                    this.items.entTmHh = ''
                    this.items.entTmMm = ''
                }

                if(this.items.bnMmbrPk == '' || this.items.bnMmbrPk == null){
                    backItems = Object.assign({}, this.items)
                    backGalFmly = Object.assign({}, this.galFmlyItems)
                    backItems = this.formatBackInfo(backItems)
                    backGalFmly = this.formatBackGalFmly(backGalFmly)
                }
                else {
                    backItems = this.formatBackInfo(this.items)
                    backGalFmly = this.formatBackGalFmly(this.galFmlyItems)
                }

                let fCode = this.$store.getters.getFcltyNum
                let fNm = this.$store.getters.getUserNm
                let mdfhisList = []

                this.$store.state.bnfcrChgClmn.cfmSave.forEach((el, i) => {
                    if(el == '1')
                        mdfhisList.push(Object.assign({}, this.$parent.$parent.$parent.$refs.basicSave[0].chgList[i]))
                });

                mdfhisList.forEach(list => {
                    if(list.aplStrt != '')
                        list.aplStrt = this.$moment(list.aplStrt, 'YYYY-MM-DD', true).format('YYYYMMDD')
                    if(list.aplEnd != '')
                        list.aplEnd = this.$moment(list.aplEnd, 'YYYY-MM-DD', true).format('YYYYMMDD')
                });

                setBnfcrFull(fCode, fNm, backItems, backGalFmly, this.fmlyItems, this.imgList[0], mdfhisList)
                    .then((response) => ( this.insBnfcrFullAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/insBnfcr : ' + error))
            },
            
            insBnfcrFullAfter: function (res) {
                //console.log(res)
                //this.snackControll([true, 2500, '신규 작성 모드', 'info'])
                //mmbrPk

                if(res.statusCode == 200){
                    this.$store.commit('setBnfcrPk', res.bnfcrPk)
                    this.$store.commit('setBnfcrSaveArea', '')
                    this.$store.commit('setBnfcrSaveChc', '')
                    this.$store.commit('setTmpObj', {})

                    this.$emit('bnfcrTrigger', 'trigger')

                    //계약정보 갱신
                    this.$parent.$parent.$parent.$refs.bnfcrCntInf.getCntrcList(this.$store.getters.getBnfcrState)

                    this.clearFilds()

                    this.snackControll([true, 3000, res.message, 'info'])
                }
                else
                    this.snackControll([true, 5000, res.message, 'error'])
                
            },

            delBnfcrAfter: function (res) {
                //console.log(res)

                if(res.statusCode == 200)
                    this.snackControll([true, 2500, res.message, 'info'])
                else
                    this.snackControll([true, 5000, res.message, 'error'])
                

                this.$store.commit('setBnfcrState', {
                    bnmmbr: 0, bnfcr: 0, name: '', entStcd: '', entDt: '', lvDt: ''
                });

                this.clearFilds()

                this.$emit('bnfcrTrigger', 'trigger')
       
            },

            hisBnfcrInfoChanger: function () {
                let tmp = this.$store.getters.getTmpObj

                this.entTm = ''  
                this.$refs.form.validate()

                this.items = Object.assign({}, tmp.cntntJson.bnfcrInfo)
                this.galFmlyItems = Object.assign({}, tmp.cntntJson.galFmlyInfo)
                this.fmlyItems = Object.assign([], tmp.cntntJson.fmlyInfo)

                this.convertFormat()
                this.galFmlyFormat()

                this.mfdRsn = tmp.mfdRsn
                this.mdfDt = tmp.mdfDt.beforeDateFormatDot()

                this.forceRender += 1
                //console.log(tmp)
            },

            formValidationFull: function () {

                let succ = this.$refs.form.validate();
               
                this.$store.commit('setValidationSucc', succ);
                    //this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            },

            datePickClose: function (value) {
                this.menu.splice(value, 1, false)
                this.$store.commit('setGlblTmprDt', this.$store.getters.getPrsntDt('yyyy-mm-dd'))
                this.forceRender += 1
            },

            snackControll: function (options) {
                this.snackOptions = options
                //this.snackOptions[0] = true
                //this.snackOptions[1] = 2500
                //this.snackOptions[2] = '신규 작성 모드'
                //info, success, warning, error
                //this.snackOptions[3] = 'info'
            },

            mappingFmlyData: function (res) {
                if(res.length == 0){
                    this.fmlyItems.splice(0)
                    this.fmlyItems.push(Object.assign({}, this.dummyFmlyItem))
                    this.fmlyItems.push(Object.assign({}, this.dummyFmlyItem))
                }
                else if(res.length == 1){
                    this.fmlyItems.shift()
                    this.fmlyItems.unshift(res[0])
                }
                else if(res.length >= 2){
                    this.fmlyItems = res
                }  
            },

            convertFormat: function () {

                //select 초기화
                this.selectYear = '선택'
                this.selectEmail = '0'

                //데이터 가공(입소현황 - 공통코드 대입)
                this.$parent.$parent.$parent.$parent.$parent.cdItems.forEach(cd => {
                    if(cd.valcd == this.items.entStcd)
                        this.items.entStcdnm = cd.valcdnm
                });

                //데이터 가공(인정등급 - 공통코드 대입)
                this.$store.commit('setAcknwRtng', this.items.acknwRtng)

                //데이터 가공(본인부담률 - 공통코드 대입)
                this.$store.commit('setCopayRate', this.items.copayRate)
                this.items.copayRate = this.items.copayRate.toString()
                
                //데이터 가공(주민생년월일)
                if( this.items.rsdntBirth != '')
                    this.items.rsdntBirth = this.items.rsdntBirth?.beforeDateFormatHyp()

                //데이터 가공(입소일)
                if( this.items.entDt != '')
                    this.items.entDt = this.items.entDt?.beforeDateFormatHyp()

                //데이터 가공(생일)
                if( this.items.birth != '')
                    this.items.birth = this.items.birth?.beforeDateFormatHyp()

                //데이터 가공(입소전 건강검진 제출일)
                if( this.items.entHlthChdt != '')
                    this.items.entHlthChdt = this.items.entHlthChdt?.beforeDateFormatHyp()
                
                //데이터 가공(인정등급 기간)
                if( this.items.acknwPrdStrt != '')
                    this.items.acknwPrdStrt = this.items.acknwPrdStrt?.beforeDateFormatHyp()
                if( this.items.acknwPrdEnd != '')
                    this.items.acknwPrdEnd = this.items.acknwPrdEnd?.beforeDateFormatHyp()

                //데이터 가공(현황 기간)
                this.items.outDtStrt = this.items.outDtStrt?.beforeDateFormatHyp()

                //데이터 가공(퇴소일 기간)
                if(this.items.lvDt != null)
                    this.$store.commit('setBnfcrLvDt', this.items.lvDt)
                this.items.lvDt = this.items.lvDt?.beforeDateFormatDot()

                //데이터 가공(입소시간)
                if(this.items.entTmHh != null && this.items.entTmHh != '')
                    this.entTm = this.items.entTmHh + ':' + this.items.entTmMm

                //파일 존재 여부
                this.files = null

                this.forceRender += 1
            },

            formatBackInfo: function (items) {
                //rsdntBirth
                if( items.rsdntBirth != '' )
                    items.rsdntBirth = items.rsdntBirth?.afterDateFormatHyp()

                //entDt
                if( items.entDt != '' )
                    items.entDt = items.entDt?.afterDateFormatHyp()

                //birth
                if( items.birth != '' )
                    items.birth = items.birth?.afterDateFormatHyp()

                //entHlthChdt
                if( items.entHlthChdt != '' )
                    items.entHlthChdt = items.entHlthChdt?.afterDateFormatHyp()
    
                //acknwPrdStrt
                if( items.acknwPrdStrt != '' )
                    items.acknwPrdStrt = items.acknwPrdStrt?.afterDateFormatHyp()
  
                //acknwPrdEnd
                if( items.acknwPrdEnd != '' )
                    items.acknwPrdEnd = items.acknwPrdEnd?.afterDateFormatHyp()

                items.lvDt = items.lvDt?.afterDateFormatDot()

                return items
            },

            formatBackGalFmly: function (items) {
                //galFmlyItems
                if(items.rsdntBirth != '')
                    items.rsdntBirth = items.rsdntBirth?.afterDateFormatHyp()

                return items
            },

            galFmlyFormat: function () {
                //데이터 가공()
                if(this.galFmlyItems.rsdntBirth != '')
                    this.galFmlyItems.rsdntBirth = this.galFmlyItems.rsdntBirth?.beforeDateFormatHyp()
   
                //이메일 선택
                if(this.galFmlyItems.emailDmn != null){
                    this.$parent.$parent.$parent.$parent.$parent.emailCode.forEach(cd => {
                        if(this.galFmlyItems.emailDmn == cd.valcdnm)
                            this.selectEmail = cd.valcd
                    });
                }

                this.forceRender += 1
            },
            onAddressComp: function (result) {
                if(this.addressPopup1 == true){
                    this.items.zip = result.zonecode
                    this.items.addr = result.address
                    this.addressPopup1 = false
                }
                if(this.addressPopup2 == true){
                    this.galFmlyItems.zip = result.zonecode
                    this.galFmlyItems.addr = result.address
                    this.addressPopup2 = false
                }

                this.addressPopup1 = false
                this.addressPopup2 = false
                this.mdlMenu.splice(1, 1, false)
            },

            emailSelected: function () {
                if(this.selectEmail == '0')
                    this.galFmlyItems.emailDmn = ''
                else {
                    this.$parent.$parent.$parent.$parent.$parent.emailCode.forEach(cd => {
                        if(this.selectEmail == cd.valcd)
                            this.galFmlyItems.emailDmn = cd.valcdnm
                    });
                }
            },

            clearFilds: function () {
                //this.items = {...{}}
                //this.galFmlyItems = {...{}}
                //this.fmlyItems =  []
                //this.$refs.form.reset()

                this.items = Object.assign({}, this.dummyItems)
                this.galFmlyItems = Object.assign({}, this.dummyGalFmlyItems)
                this.fmlyItems.splice(0)
                this.fmlyItems.push(Object.assign({}, this.dummyFmlyItem))
                this.fmlyItems.push(Object.assign({}, this.dummyFmlyItem))

                this.selectYear = '선택'
                this.selectEmail = '0'
                this.entTm = null

                this.files = null
                this.fileList.splice(0)
                this.imgList.splice(0)

                this.$refs.form.resetValidation()

                this.forceRender += 1

                this.$refs.lvnRmMdl.getLvnRmInf()
                //this.snackControll([true, 2500, '신규 작성 모드', 'info'])

            },

            revokeBlob: function () {
                window.URL.revokeObjectURL(this.blobSrc)
                this.blobSrc = ''
            },

            calcYear: function () {
                if(this.items.acknwPrdStrt != ''){
                    let date = new Date(this.items.acknwPrdStrt)

                    if(this.selectYear == '1년') date.setDate(date.getDate()+365)
                    else if(this.selectYear == '2년') date.setDate(date.getDate()+730)
                    else if(this.selectYear == '3년') date.setDate(date.getDate()+1095)

                    let formater = new Intl.DateTimeFormat("fr-CA", {year: "numeric", month: "2-digit", day: "2-digit"}).format(date)
                    this.items.acknwPrdEnd = formater
                    this.forceRender += 1
                }
            },

            upFile: function () {
                let formData = new FormData()

                if(this.files != null){
                    formData.append('files', this.files)
                    this.athFileUpload(formData)
                }           
            },

            delImgfiles: function (idx) {
                if(this.imgList[idx].atchdFilePk != undefined){
                    this.removeAthFile(this.imgList[idx].atchdFilePk)
                }
                else {
                    this.imgList.splice(idx, 1)
                }
            },

            fileDownLoaderAfter: function (res) {
                let contentDisposition = decodeURI(res.headers['content-disposition'])

                let link = document.createElement('a')
                let url = window.URL.createObjectURL(new Blob([res.data]))
                let fileName = 'unknown'

                if (contentDisposition) {
                    const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                    if (fileNameMatch)
                        [ , fileName ] = fileNameMatch.split('=')
                }

                link.href = url

                fileName = fileName.replaceAll('"', '')
                link.setAttribute('download', `${fileName}`)
                link.style.cssText = 'display:none'

                document.body.appendChild(link)

                link.click()
                link.remove()
            },

            // cvImgEncode: async function () {
            //     //수급자 관리: 1(bzClcd), mmbrPk(bzPk)
            //     this.forceRender += 1

            //     let path = remoteURL + 'opr/imgDownload?'
            //     let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum
            //     let bzClcd = '&bzClcd=1&'
            //     let bzPk = 'bzPk=' + this.$store.getters.getBnfcrState.bnmmbr

            //     let tmp = new Image()
            //     tmp.src = path+fcltyNum+bzClcd+bzPk
            //     tmp.crossOrigin = 'Anonymous';

            //     let canvas = document.createElement("canvas")
            //     let context = canvas.getContext("2d")
            //     let path1 = this.$refs.encPath

            //     path1.value = ' '
            //     path1.focus()
            //     path1.blur()

            //     tmp.onload = function() {
            //         canvas.width = this.width
            //         canvas.height = this.height
            //         context.drawImage(tmp, 0, 0)

            //         canvas.toBlob((blob) => {
            //             let url = URL.createObjectURL(blob)

            //             path1.value = url
                       
            //             path1.focus()
            //             path1.blur()
            //         })
                    
            //     }
            //     tmp.onerror = function() {
            //         path1.value = remoteURL + 'opr/imgDownload'
            //         path1.focus()
            //         path1.blur()
            //     }
            // },

            // cvPreview: function () {
            //     let path = remoteURL + 'opr/imgPrview?'
            //     let fileName = 'fileNm=' + this.backFile.fileNm
            //     let filePath = 'filePth=' + this.backFile.filePth
            //     let fileCode = 'fileKndCd=2'

            //     let tmp = new Image()
            //     tmp.src = path+fileCode+'&'+filePath+'&'+fileName
            //     tmp.crossOrigin = 'Anonymous';

            //     let canvas = document.createElement("canvas")
            //     let context = canvas.getContext("2d")
            //     let path1 = this.$refs.encPath
            //     //let picture = this.$refs.img_blob

            //     tmp.onload = function() {
            //         canvas.width = this.width
            //         canvas.height = this.height
            //         context.drawImage(tmp, 0, 0)

            //         canvas.toBlob((blob) => {
            //             let url = URL.createObjectURL(blob)
 
            //             path1.value = url
            //             path1.focus()
            //             path1.blur()
            //         })
                    
            //     }
            // },

            // throwSrc: function () {
            //     this.blobSrc = this.$refs.encPath.value
            // },

            getLvnRmInf: function (obj) {
                this.items.lvnRm = obj.lvnRmNm
                this.items.lvnRmInfPk = obj.lvnRmInfPk
            },

        },

        data: () => ({
            mdlMenu: [ false, false, ],
            date: [
                { rsdntBirth: '', },        //주민번호 생년월일
                { entDt: '', },             //입소일
                { birth: '', },             //생일
                { ent_hlth_chdt: '', },     //입소전 건강검진 제출일
                { acknw_prd_strt: '', },    //인정기간_시작
                { acknw_prd_end: '', },     //인정기간_종료
            ],
            snackOptions: [false, 3000, '', 'info'],
            formError: [true, false],
            blobSrc: '',
            forceRender: 0,
            files: null,
            fileList: [],
            imgList: [],
            areaText: null,
            addressPopup1: false,
            addressPopup2: false,
            menu: [false, false, false, false, false, false, false],
            entTm: '',
            mdfDt: '',
            mfdRsn: '',
            menu2: false,
            selectYear: '선택',
            selectEmail: '0',
            year_term: ['선택', '1년', '2년', '3년',],
            email_list: ['직접입력', ],
            bnf_rec_rcv_yn: [],
            acknw_rtng: ['선택',],
            smsCd: [ { valcd: '1', valcdnm: '알림톡' }, { valcd: '2', valcdnm: '문자' }, { valcd: '3', valcdnm: '이메일' } ],
            smsCd2: [ { valcd: '1', valcdnm: '알림톡' }, { valcd: '2', valcdnm: '문자' } ],
            propsCheck: 0,
            items: { 
                bnMmbrPk: '', lnrYn: '', gndr: '', rmrks: '', lvDt: null, acknwRtng: '0', celphn_1: '', celphn_2: '', entTmMm: '',
                majorDsss: '', entHlthChdt: '', copayRate: '0', lvnRm: '', gvrnmSprt: [], entDt: '', entStcd: '1', acknwPrdStrt: '',
                tel_2: '', addr: '', tel_3: '', tel_1: '', zip: '', acknwPrdEnd: '', lstYn: '', birth: '', rsdntBirth: '',
                entTmHh: '', celphn_3: '', name: '', acknwNum1: '', acknwNum2: '', addrDtl: '', age: '', atchdFilePk: null, 
                lvnRmInfPk: 0, entStcdnm: '', bnfcrPk: '',
            },
            galFmlyItems: {
                bnMmbrPk: '', zip: '', rltns: '', emailId: '', rsdntBirth: '', celphn_1: '', celphn_2: '', celphn_3: '', grdnYn: null, cellRcvYn: [],
                emailDmn: '', name: '', tel_2: '', addr: '', addrDtl: '', tel_3: '', billRcvYn: [], tel_1: '', msClcd: '1',
            },
            fmlyItems: [],
            dummyItems: { 
                bnMmbrPk: '', lnrYn: '', gndr: '', rmrks: '', lvDt: null, acknwRtng: '0', celphn_1: '', celphn_2: '', entTmMm: '',
                majorDsss: '', entHlthChdt: '', copayRate: '0', lvnRm: '', gvrnmSprt: [], entDt: '', entStcd: '1', acknwPrdStrt: '',
                tel_2: '', addr: '', tel_3: '', tel_1: '', zip: '', acknwPrdEnd: '', lstYn: '', birth: '', rsdntBirth: '',
                entTmHh: '', celphn_3: '', name: '', acknwNum1: '', acknwNum2: '', addrDtl: '', age: '', atchdFilePk: null,
                lvnRmInfPk: 0, entStcdnm: '', bnfcrPk: '',
            },
            dummyGalFmlyItems: {
                bnMmbrPk: '', zip: '', rltns: '', emailId: '', rsdntBirth: '', celphn_1: '', celphn_2: '', celphn_3: '', grdnYn: null, cellRcvYn: [],
                emailDmn: '', name: '', tel_2: '', addr: '', addrDtl: '', tel_3: '', billRcvYn: [], tel_1: '', msClcd: '',
            },
            dummyFmlyItem: {
                cellRcvYn: [], bnMmbrPk: '', contactPk: '', rltns: '', name: '', rn: '', fmlyPk: '',
                celphn_1: '', celphn_2: '', celphn_3: '', msClcd: '',
            },
            subFmly: [],
            rules: {
                selectCheck: value => value?.length > 0 || 'Required.',
                required: value => !!value || 'Required.',
                selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
                strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
                numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
                timeFrmCfm: value => /^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(value) || 'Time Validate.',
                numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
                emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
            },
        }),
    };
</script>
<style>
    .inputHidden { color: white; font-size: 0.1rem; height: 10px; }
    .v-radio .v-icon.v-icon { font-size: 18px; color: #dbdbdb; }

    .v-input--checkbox .v-icon.v-icon { font-size: 20px; color: #dbdbdb; }
    .v-text-field input { padding: 0px; }
    .v-input--selection-controls { padding: 0px; margin-top: 0px; }
    .v-autocomplete.v-select.v-input--is-focused input { min-width: 28px; }

    .customBorder { border: 2px solid #dbdbdb; }
    .v-input__control .v-input__slot { min-height: 28px; }
    .v-input--selection-controls__input i { margin-top: 2px; }
    .v-input__append-inner { margin-top: 3px !important; }

    .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner { margin-top: 4px; margin-right: 4px; }
    .v-file-input.v-input--dense .v-text-field__slot { min-height: 30px; }
    .v-text-field__slot { overflow: hidden; }

    .v-list--dense .v-list-item .v-list-item__title { line-height: 1.12rem; }
    .v-application--is-ltr .v-input--selection-controls__input { margin-right: 0px; }

    .fileOLFld .v-input__control .v-input__slot { padding: 0 4px 0 8px !important; }
    .fileOLFld .v-input__control .v-input__slot .v-input__prepend-inner { margin-top: 3px !important; }
    .fileOLFld .v-input__control .v-input__slot .v-input__append-inner { margin-top: 2px !important; padding-left: 0px !important; }
</style>