<template>
    <v-sheet class="ma-0 pa-0" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>

        <v-divider class="mt-2"></v-divider>
        <v-row class="greyE01 pa-0 ma-0" no-gutters>
            <v-col class="tableBR" cols="4">
                <v-row class="greyE01 pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-2" cols="12">
                        <span class="black--text font-weight-medium">근로시간</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR" cols="4">
                        <span class="black--text font-weight-medium py-2">구분</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR" cols="3">
                        <span class="black--text font-weight-medium py-2">시작시간</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="5">
                        <span class="black--text font-weight-medium">종료시간</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="tableBR" cols="2">
                <v-row class="greyE01 pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-2" cols="12">
                        <span class="black--text font-weight-medium">휴게시간(분)</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR" cols="6">
                        <span class="black--text font-weight-medium py-2">주간(06~22)</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="6">
                        <span class="black--text font-weight-medium">야간(22~06)</span>
                    </v-col>
                    <!-- <v-col class="d-flex justify-center align-center" cols="4">
                        <span class="black--text font-weight-medium">야간익일(01~05)</span>
                    </v-col> -->
                </v-row>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">실근무시간</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">적용시작일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                <span class="black--text font-weight-medium">적용종료일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="1">
                <span class="">
                    <v-btn class="mr-1" color="blue200" height="26" @click="chkSumRow()" icon>
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <WrkngHoursTbl ref="wrkCd1" :prtList="listCd1" :prtClcd="'1'"></WrkngHoursTbl>
        <WrkngHoursTbl ref="wrkCd2" :prtList="listCd2" :prtClcd="'2'"></WrkngHoursTbl>
        <WrkngHoursTbl :prtList="listCd3"></WrkngHoursTbl>
    </v-sheet>
</template>

<script>
import { selEmpWrkHrList, insEmpWrkHr, delEmpWrkHr } from '../../api/emp.js';

import WrkngHoursTbl from './WrkngHoursTbl.vue';

export default {
    name: 'EmpWrkngHours',

    props : {
   
    },
        
    components: {
        WrkngHoursTbl,
    },

    created: function(){

    }, 

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltEmpPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
        },
    },

    watch:{
        getSltEmpPk: function (value) {
            this.$refs.wrkCd1.clearForm()
            if(value != -1)
                this.getEmpWrkHrList(value)
        },
    },
    
    methods: {
        getEmpWrkHrList: function (pk) {
            selEmpWrkHrList(this.$store.getters.getFcltyNum, pk, null)
                .then((response) => ( this.getEmpWrkHrListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkHrList : ' + error))
        },
        setEmpWrkHr: function (obj) {
            insEmpWrkHr(obj)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpWrkHr : ' + error))
        },
        removeEmpWrkHr: function (pk) {
            delEmpWrkHr(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmpWrkHr : ' + error))
        },
        getEmpWrkHrListAfter: function (res) {
            this.listCd3.splice(0)
            if(res.length == 0)
                this.listCd3.push(Object.assign({}, this.dummyWrkngInfo))

            this.listCd1 = res.filter( v => v.wrkHrClcd == '1' )
            this.listCd2 = res.filter( v => v.wrkHrClcd == '2' )

            let sprOpr = []
            res.forEach(items => {
                sprOpr.push(items.ord)
            });

            if(sprOpr.length > 0){
                sprOpr.sort((x, y) => x - y)
                this.maxOrd = sprOpr[sprOpr.length-1]
            }
        },
        asyncResult: function (res) {
            if(res.statusCode == 200){
                this.$refs.wrkCd1.clearForm()
                this.$refs.wrkCd2.clearForm()
                this.getEmpWrkHrList(this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk)
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preEmpWrkHrInfo: function (index) {
            let obj = Object.assign({}, this.wrkngListTot[index])
            obj.aplStrt = obj.aplStrt.afterDateFormatHyp()
            obj.aplEnd = obj.aplEnd.afterDateFormatHyp()
            obj.userNm = this.$store.getters.getUserNm
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.empPk = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
            obj.wrkTmHhStrt = this.wrkStrt[index].split(':', 2)[0]
            obj.wrkTmMmStrt = this.wrkStrt[index].split(':', 2)[1]
            obj.wrkTmHhEnd = this.wrkEnd[index].split(':', 2)[0]
            obj.wrkTmMmEnd = this.wrkEnd[index].split(':', 2)[1]
            obj.ord = this.maxOrd

            if(this.$refs.wrkngHours.validate())
               this.setEmpWrkHr(obj)
        },
        chkSumRow: function (){
            let chk = 0
            if( this.$refs.wrkCd1.wrkngList.findIndex( v => v.aplEnd == '2099-12-31' ) > -1 ) chk += 1
            if( this.$refs.wrkCd2.wrkngList.findIndex( v => v.aplEnd == '2099-12-31' ) > -1 ) chk += 1
            if( this.listCd3.length > 0 ){
                this.snackControll([true, 5000, '현재 저장되지 않은 항목이 존재합니다.', 'error'])
                return 0
            }

            if(chk > 1)
                this.snackControll([true, 5000, '잘못된 적용 종료일로 인해 항목을 추가할 수 없습니다.', 'error'])
            else
                this.listCd3.push(Object.assign({}, this.dummyWrkngInfo))
        },
        clearForm: function () {
            this.$refs.wrkCd1.clearForm()
            this.$refs.wrkCd2.clearForm()
            this.listCd3.splice(0)
        },
        datePickClose: function (value) {
            this.aplStrt.splice(value, 1, false)
        },
        datePickClose0: function (value) {
            this.aplEnd.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        forceRender: 0,
        maxOrd: 0,
        menuStrt: [],
        menuEnd: [],
        wrkStrt: [],
        wrkEnd: [],
        aplStrt: [],
        aplEnd: [],
        listCd1: [],
        listCd2: [],
        listCd3: [],
        wrkngListTot: [],
        dummyWrkngInfo: {
            empWrkHrPk: 0, empPk: 0, aplStrt: '', aplEnd: '2999-12-31', wrkOvnYn: 1, brkDay: 0, brkNgt: 0, brkOvn: 0,
            wrkTmHhStrt: '', wrkTmMmStrt: '', wrkTmHhEnd: '', wrkTmMmEnd: '', actWrkHr: 0, actWrkTxt: '', wrkHrClcd: ''
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .numArea .v-input__control .v-input__slot { padding-left: 10px !important; padding-right: 6px !important; }
    .dpickArea div .v-input__control .v-input__slot { padding: 0 6px !important; }
</style>