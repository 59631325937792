<template>
    <v-row class="ma-0 pa-0" no-gutters>
        <v-col class="my-2" cols="12">
            <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </v-col>
        <v-col class="" cols="12">
            <v-calendar
                class="empCalendar rstclBrdr" color="dayNow darken-2 white--text" locale="ko" ref="calendar" :event-more="false"
                v-model="start" :type="calendarObj.type" :now="now" :events="events" :event-color="getEventColor" interval-height="20"
                :event-ripple="false">
                <template #day-label="{ date, day }" >
                    <div class="d-flex align-center tableBB px-3 mb-1" style="height:29px;">
                        <span
                            :class="['mb-1', date == now ? 'ic-level nowDay dayNow' : '']"
                            style="font-size: 1.1rem;">
                            {{ day }}
                        </span>
                        <v-spacer></v-spacer>
                        <span>&nbsp;</span>
                    </div>
                </template>
                <template #event="{ event }" >
                    <v-row no-gutters>
                        <v-col v-for="(list, i) in event.schdl" :key="i"
                            class="d-flex justify-center" style="" cols="12">
                            <div class="black--text" style="font-size: 0.975rem;">
                                {{ list.name }}&nbsp;&#40;{{ list.jobCd }}&#41;
                            </div>
                        </v-col>
                    </v-row>
                </template>
            </v-calendar>
        </v-col>
    </v-row>
</template>

<script>
import expandMonthPicker from '../commons/expandMonthPicker.vue';

import { selExtEmpWrkSchdlList } from '../../api/emp.js';

export default {
    name: 'MntlWrksh',
    
    components: {
        expandMonthPicker
    },

    props : {

    },

    created: function(){
        let timezoneOffset = new Date().getTimezoneOffset() * 60000
        let timezoneDate = new Date(Date.now() - timezoneOffset)
        this.now = timezoneDate.toISOString().split('T',2)[0]
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },  

    watch: {
        'start': function () {
            //this.getEmpWrkJobCntList(this.start.afterDateFormatHyp().substring(0, 6))
        },
    },

    methods: {
        getExtEmpWrkSchdlList: function (yymm) {
            selExtEmpWrkSchdlList(this.$store.getters.getFcltyNum, yymm)
                .then((response) => ( this.getExtEmpWrkSchdlListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selExtEmpWrkSchdlList : ' + error))
        },
        getExtEmpWrkSchdlListAfter: function (res) {
            this.events.splice(0)
            let crtrDt = new Date(this.start)

            if(res.length > 0){
                res.forEach(item => {
                    this.$parent.$parent.$parent.$parent.$parent.$parent.vCd42.forEach(cd => {
                        if(item.jobCd == cd.valcd) item.jobCd = cd.valcdnm
                    });
                });

                for (let index = 1; index <= new Date(crtrDt.getFullYear(), crtrDt.getMonth()+1, 0).getDate(); index++) {
                    let obj = {}
                    let schDt = crtrDt.getFullYear() + '' + ('00' + (crtrDt.getMonth()+1)).slice(-2)  + ('00' + index).slice(-2)
                    let dt = (crtrDt.getFullYear() + ('00' + (crtrDt.getMonth()+1)).slice(-2) + ('00' + index).slice(-2)).beforeDateFormatHyp()
                    obj.start = dt
                    obj.end = dt 
                    obj.schdl = res.filter( v => v.wrkDt == schDt )

                    if(obj.schdl.length > 0)
                        this.events.push(obj)
                }
            }

        },
        getPickerDate: function (date) { 
            this.start = date.substr(0, 4) + '-' + date.substr(4, 2) + '-01'
            this.sltDate = date

            this.getExtEmpWrkSchdlList(date)
        },
        calendarDayAct: function (value) {
            this.sltDate = value.day.date
        },
        getEventColor: function (event) {
            return event.color
        },
    },

    data: () => ({
        now: '',
        sltDate: '',
        start: '',
        events: [
            //{ name: '1', start: '2023-01-01', end: '2023-01-01', color: 'light-blue darken-2', cnt: 1 },
        ],
        colors: ['blue', 'deep-orange darken-3', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        calendarObj: 
        { 
            type: 'month', types: ['month', 'week', 'day', '4day'],
            weekday: [0, 1, 2, 3, 4, 5, 6], mode: 'stack',
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
        },
    }),
};
</script>

<style>
    .rstclBrdr .v-calendar-weekly__week .v-calendar-weekly__day .v-event {
        border: initial !important; padding: 10px 0; width: 97% !important;
    }
    .rstclBrdr .v-calendar-weekly__week .v-calendar-weekly__day { min-height: 105px; }
    .rstclBrdr .v-calendar-weekly__week .v-calendar-weekly__day .v-event { height: 32px !important; }
</style>