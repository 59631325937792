<template>
    <v-sheet class="pa-2">
        <v-tabs hide-slider v-model="tab"  color="white" class="black--text"
            active-class="bodyColor" center-active>
            <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style">{{item.tab}}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item transition="fade-transition">
                <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                    
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="12">                                
                        <span class="black--text font-weight-medium">물리(작업)치료 계획</span>
                    </v-col>
                </v-row>
                  
                <v-row class="pa-0 ma-0" no-gutters  style="border-bottom: 1px solid #dbdbdb !important;">
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="2">                                
                        <span class="black--text font-weight-medium">치료기간</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="4">
                        {{phyThrPlnItems.thrStrt}} ~ {{phyThrPlnItems.thrEnd}}
                    </v-col>                    
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;"  cols="2">                                
                        <span class="black--text font-weight-medium">치료횟수</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="4">
                        {{phyThrPlnItems.thrTimes}}
                    </v-col>
                </v-row>    
                <v-row class="pa-0 ma-0 mt-2" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                    <v-col class="greyE01 d-flex pa-0 ma-0" cols="12">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%">
                            <span class="black--text font-weight-medium">치료구분</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">치료방법</span>
                        </div>
                        <div class="pa-0 ma-0" style="width:48%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                                <span class="black--text font-weight-medium">치료일정</span>
                            </div>
                            <div class="d-flex pa-0 ma-0" style="width:100%;border-top: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">
                                    <span class="black--text font-weight-medium">월</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">화</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">수</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">목</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">금</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">토</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">일</span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:37%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">치료시간</span>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <div v-if="phyThrPlnList.length>0" >
                    <v-row v-for="(item, j) in phyThrPlnList" :key="j" class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="d-flex pa-0 ma-0" cols="12">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:17%">                                    
                                    {{item.thrClcd?.aftValCdToNm(codeItem37)}}
                            </div>
                            <div class="d-flex align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">  
                                <span v-if="item.thrClcd=='1'">                                                                                
                                    <v-btn v-for="(cdItme, n) in item.thrMthdcd" :key="n" 
                                        color="grey006" class="mr-1" samll height="26" outlined
                                        :ripple="false"     
                                    >
                                        <span style="font-size: 0.9rem;">{{cdItme?.aftValCdToNm(codeItem92)}}</span>
                                    </v-btn>
                                </span>
                                <span v-if="item.thrClcd=='2'">                                           
                                    <v-btn v-for="(cdItme, n) in item.thrMthdcd" :key="n" 
                                        color="grey006" class="mr-1" samll height="26" outlined
                                        :ripple="false"
                                    >
                                        <span style="font-size: 0.9rem;">{{cdItme?.aftValCdToNm(codeItem93)}}</span>
                                    </v-btn>
                                </span>                                    
                            </div>
                            <div class="pa-0 ma-0" style="width:48%;height:100% !important;border-left: 1px solid #dbdbdb !important;">                                
                                <div class="d-flex pa-0 ma-0" style="width:100%;height:100% !important;">
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">                                            
                                        <span v-if="item.thrMonYn == '1'">
                                            <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                        <span v-if="item.thrTueYn == '1'">
                                            <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                        <span v-if="item.thrWedYn == '1'">
                                            <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                        <span v-if="item.thrThuYn == '1'">
                                            <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                        <span v-if="item.thrFriYn == '1'">
                                            <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                        <span v-if="item.thrSatYn == '1'">
                                            <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                                            
                                        <span v-if="item.thrSunYn == '1'">
                                            <v-icon color="blue">mdi-checkbox-marked</v-icon>
                                        </span>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:37%;border-left: 1px solid #dbdbdb !important;">
                                            {{item.thrTmStrt}} ~ {{item.thrTmEnd}}
                                    </div>
                                </div>
                            </div>                       
                        </v-col>
                    </v-row>
                </div>
                <div v-else >
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="d-flex pa-0 ma-0" cols="12">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div>                                
                        </v-col>
                    </v-row>
                </div>           
            </v-tab-item>
            <v-tab-item transition="fade-transition">
                <v-data-table
                    height="260" locale="ko"
                    :headers="headersPln"
                    :items="phyThrPlnHisList"
                    hide-default-header
                    hide-default-footer 
                    disable-pagination
                    dense
                    class="elevation-0">

                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[3].text}}</th>
                                <th class="black--text clmsFixedHeader" style="width:90px !important;">{{props.headers[4].text}}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:item='{ item }'>
                        
                        <tr>
                            <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.wrtDt}}</td>
                            <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.thrStrt}} ~ {{item.thrEnd}}</td>
                            <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.thrTimes}}</td>
                            <td class="clmsBodyCommon tableBR fixedCol0" style="text-align: center;">{{item.wrtr}}</td>
                            <td class="clmsBodyCommon fixedCol0" style="text-align: center;">
                                <v-btn icon :color="item.color" @click.stop="showDialog(item.bnftOfrPlnHisPk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>                            
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>
<script>
export default {
    name: 'PhyscThrpyInspcPlnTab2',
    
    props : {        
       
    },

    components: {
    },

    computed: {
        
    },  

    created: function(){
    },

    mounted: function(){
    },

    watch: {
    },

    methods: {
        showDialog:function(hisPk){
            this.$emit("onPhyscThrpyPln",hisPk)
        },
        setCode:function(res){
            this.codeItem37 = res["vCd37"]
            this.codeItem92 = res["vCd92"]
            this.codeItem93 = res["vCd93"]
        },
        frmReset:function(){
            this.phyThrPlnItems = Object.assign({})
            this.phyThrPlnList.splice(0)
        },
        getPhyThrPlnDataAfter(data){
            this.phyThrPlnItems = data            
            this.phyThrPlnItems.thrStrt = data.thrStrt?.beforeDateFormatHyp()
            this.phyThrPlnItems.thrEnd = data.thrEnd?.beforeDateFormatHyp()         
        },
        getPlnDtlListAfter(data){
            this.phyThrPlnList = data
            this.phyThrPlnList.forEach(x=>{
                x.thrTmStrt = x.thrTmHhStrt+':'+x.thrTmMmStrt
                x.thrTmEnd = x.thrTmHhEnd+':'+x.thrTmMmEnd                
            })
        },

        
        getPhyThrPlnListAfter: function (res) {     
            this.phyThrPlnHisList = res
            this.phyThrPlnHisList.forEach(x=>{
                x.thrStrt = x.thrStrt?.beforeDateFormatHyp()
                x.thrEnd = x.thrEnd?.beforeDateFormatHyp()
                x.wrtDt = x.wrtDt?.beforeDateFormatHyp()
            });
        },

    },

    data: () => ({
        tab:0,                
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        phyThrPlnItems:{},
        phyThrPlnList:[],
        phyThrPlnHisList:[],
        codeItem37:[],  
        codeItem92:[], //물리치료코드
        codeItem93:[], //작업치료코드
        tabItems: [
            { index: 0, tab: '계획정보', style: 'font-size: 1.2rem !important;'},
            { index: 1, tab: '계획내역', style: 'font-size: 1.2rem !important;'},
        ],
        headersPln: [
            { text: '작성일', value: '', align: 'center', width: '90', class: 'black--text greyE01 tableHeaderSize'},
            { text: '치료기간', value: 'aplctClcd', align: 'center', width: '200', class: 'black--text greyE01 tableHeaderSize'},
            { text: '치료횟수/시간', value: '', align: 'center', width: '100', class: 'black--text greyE01 tableHeaderSize'},
            { text: '작성자', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
            { text: '상세', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
        ],
    }),
};
</script>