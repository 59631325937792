<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position: relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" :color="snackOptions[3]"
                class="pa-0" max-width="500" min-width="200" rounded="pill" transition="slide-y-transition"
                absolute centered top>
                <div class="txtAlgnMid">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">전자서명</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-3 justify-end" no-gutters>
            <v-btn
                v-for="(list, i) in filledBtn" :key="i"
                :class="list.class" :color="list.color"
                style="font-size: 16px;" height="26"
                @click="sltAction(list.text)" rounded>
                <v-icon class="mr-1" size="18">{{ list.icon }}</v-icon>
                <span>{{ list.text }}</span>
            </v-btn>
        </v-row>
        <div>
            <vue-esign
                ref="esign"
                class="mt-4 rounded" style="border: 3px solid #4bc5e8;" format="image/png"
                :width="400" :height="300" :lineWidth="lineWidth" :lineColor="lineColor"
                :bgColor.sync="bgColor" :isCrop="isCrop">
            </vue-esign>
        </div>
    </v-sheet>
</template>

<script>
import { uploadSIgn } from '../../api/index.js';

export default {
    name: 'SignModel',

    props : {
        mmbrPk: { type: Number, default: 0 },
        bzClcdPk: { type: Number, default: 0 },
        bnMmbrPk: { type: Number, default: 0 },
        eSigClcd: { type: Number, default: 0 },     //수급자:1, 보호자:2
    },
            
    components: {
    
    },
    
    mounted: function(){
    
    },
        
    methods: {
        setSign: function () {
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.mmbrPk = this.mmbrPk
            obj.bnMmbrPk = this.bnMmbrPk
            obj.bzClcdPk = this.bzClcdPk
            obj.eSigClcd = this.eSigClcd
            obj.sign = this.sign
            obj.userNm = this.$store.getters.getUserNm

            uploadSIgn(obj)
                .then((response) => ( this.afterSetSign(response.data) ))
                .catch((error) => console.log('connect error /cmm/insSign : ' + error))
        },

        afterSetSign: function (res) {
            if(res.statusCode == 200){
                this.onClose()
                this.$emit('reload', this.eSigClcd)
            }
            else
                this.snackControll([true, 3500, res.message, 'error'])
        },

        sltAction: function (text) {
            if(text == '저장')
                this.handleGenerate()
            else if(text == '다시쓰기')
                this.handleReset()  
        },

        handleReset: function () {
            this.$refs.esign.reset()
        },

        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res
                this.setSign()
            }).catch(err => {
                alert(err)
            })
        },

        snackControll: function (options) {
            this.snackOptions = options
        },

        onClose: function () {
            this.handleReset()
            this.$emit('modalClose', this.eSigClcd)
        },
    },
    
    data: () => ({                   
        sign: '',
        lineWidth: 8,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', },
            { icon: 'mdi-eraser', class: 'ml-2 white--text pr-5', color:'#aaa', text: '다시쓰기', },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>