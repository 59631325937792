<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">{{ mdlTtl }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-5 pa-0" no-gutters>
            <v-col cols="12">
                <v-tabs hide-slider v-model="tab" color="white" class="black--text"
                    active-class="bodyColor" center-active>
                    <v-tab v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>

                <v-divider class="bodyColor mb-2 mr-2__5" style="padding:1px 0px;"></v-divider>

                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <BnfcrMltpRR ref="bnfcrMltpRR" :exChekrList="exclsBnfcrList" :chkTyp="mode"></BnfcrMltpRR>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <EmpMltpWh ref="empMltpWh" :exChekrList="exclsEmpList" :chkTyp="mode"></EmpMltpWh>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <CmteMltpSel ref="cmteMltpSel" :exChekrList="exclsCmteList" :chkTyp="mode"></CmteMltpSel>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="intgrThrw" height="30" samll rounded>
                    <v-icon v-if="mode == '1'">mdi-account-plus</v-icon>
                    <v-icon v-else-if="mode == '2'">mdi-account-multiple-plus</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">선택</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import EmpMltpWh from '../commons/EmpMltpWh.vue';
import BnfcrMltpRR from '../commons/BnfcrMltpRR.vue';
import CmteMltpSel from '../opr/CmteMltpSel.vue';

export default {
    name: 'SelectAll',

    props : {
        mdlTtl: { type: String, default: '참석자 선택' },
        cd41: { type: Array, default: () => { return [] } },
        cd42: { type: Array, default: () => { return [] } },
        cd65: { type: Array, default: () => { return [] } },
        fixList: { type: Array, default: () => { return [] } },
        tabInfList: { type: Array, default: () => { return [] } },
        mode: { type: String, default: '2' },
    },
            
    components: {
        EmpMltpWh,
        BnfcrMltpRR,
        CmteMltpSel,
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.wrtDt = this.$moment().format('YYYYMMDD')
        })
    },

    computed: {

    },

    watch: {
        'fixList': function () {
            if(this.fixList.length > 0)
                this.typeClsfc()
            else if(this.fixList.length == 0){
                this.exclsBnfcrList.splice(0)
                this.exclsEmpList.splice(0)
                this.exclsCmteList.splice(0)
            }
        },
        'tabInfList': function () {
            this.tab = -1
            this.tabItems.forEach(items => {
                items.enter = true
            });
            if(this.tabInfList.length > 0){
                this.tabInfList.forEach(items => {
                    this.tabItems[Number(items)-1].enter = false

                    if(this.tab == -1)
                        this.tab = Number(items)-1
                });
            }
        },
    },
        
    methods: {
        inqYMD: function(inqYMD){
            this.$refs.cmteMltpSel.getEmpChcWhList(inqYMD)
        },
        typeClsfc: function (){
            this.exclsBnfcrList = this.fixList.filter(v => v.checkr == '1')
            this.exclsEmpList = this.fixList.filter(v => v.checkr == '2')
            this.exclsCmteList = this.fixList.filter(v => v.checkr == '3')

        },
        intgrThrw: function () {
            let list = []
            this.$refs.empMltpWh.fltrThrw().forEach(el => {
                list.push(el)
            });
            this.$refs.bnfcrMltpRR.fltrThrw().forEach(el => {
                list.push(el)
            });
            this.$refs.cmteMltpSel.fltrThrw().forEach(el => {
                list.push(el)
            });

            this.$emit('intgrResList', list)
            this.onClose()
        },
        onClose: function () {
            this.$emit('onClose', '0')
        },
    },
    
    data: () => ({
        wrtDt:'',
        exclsBnfcrList: [],     //수급자 선택 제외 리스트
        exclsEmpList: [],       //직원 선택 제외 리스트
        exclsCmteList: [],       //운영위원회 선택 제외 리스트
        tab: -1,
        tabItems: [
            { index: 0, tab: '수급자', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: true },
            { index: 1, tab: '직원', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: true },
            { index: 2, tab: '운영위원회', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: true },
        ],
    }),
};
</script>