<template>
    <v-sheet>        
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <div class="mt-2 pa-0">
            <div class="d-flex greyE01 fontOneRem mr-2__5">
                <div class="py-2 d-flex justify-center align-center tableBT tableBB" style="width:5%;">
                    <span class="d-inline-block ml-1" style="width:26px;">
                        <v-checkbox
                            class="checkIcon" v-model="allChckr" :key="forceRender" @click="allChnger"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">수급자</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">현황</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:10%;">
                    <span class="black--text font-weight-medium">등급</span>
                </div>                
                <div class="d-flex tableBT tableBB" style="width:58%;">                    
                    <v-row style="width:100%;" no-gutters>
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text font-weight-medium">보호자</span>
                        </v-col>
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="5">
                            <span class="black--text font-weight-medium">연락처</span>
                        </v-col>
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="2">
                            <span class="black--text font-weight-medium">발송일</span>
                        </v-col>
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="2">
                            <span class="black--text font-weight-medium">발송결과</span>
                        </v-col>    
                    </v-row>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:7%;">                    
                    <span class="black--text font-weight-medium">미리보기</span>
                </div>
            </div>
            <div v-if="list.length > 0" style="height:510px;overflow-y:scroll">
                <div v-for="(item, i) in list" :key="i" class="d-flex" style="font-size:1rem;">
                    <div class="d-flex justify-center align-center tableBB" style="width:5%;">
                        <span v-if="item.acknwRtng != '99'" class="d-inline-block ml-1" style="width:26px;">
                            <v-checkbox
                                class="checkIcon" v-model="item.checkr" :key="forceRender" :disabled="item.disChekr"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" @change="allChekrFunc"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center tableBL tableBB" style="width:10%;">
                        {{item.name}}
                    </div>
                    <div class="d-flex justify-center align-center tableBL tableBB" style="width:10%;">
                        {{item.entStcdNm}}
                    </div>
                    <div class="d-flex justify-center align-center tableBL tableBB" style="width:10%;">
                        {{item.acknwRtngNm}}
                    </div>                     
                    <div style="width:58%;">
                        <div v-if="item.sndList.length > 0">
                            <v-row v-for="(list, j) in item.sndList" :key="j" style="width:100%;" no-gutters>
                                <v-col class="py-2 d-flex justify-center align-center tableBL tableBB" cols="3">
                                    {{list.fname}}&nbsp;&#40;{{list.rltns}}&#41;
                                </v-col>
                                <v-col class="py-2 d-flex justify-center align-center tableBL tableBB" cols="5">
                                    {{list.celphn_1}}&#45;{{list.celphn_2}}&#45;{{list.celphn_3}}
                                </v-col>        
                                <v-col class="py-2 d-flex justify-center align-center tableBL tableBB" cols="2">
                                    {{list.sndDt?.beforeDateFormatDot()}}
                                </v-col>
                                <v-col class="py-2 d-flex justify-center align-center tableBL tableBB" cols="2">
                                    {{list.sndSttsNm}}
                                </v-col>                         
                            </v-row>
                        </div>
                        <div v-else>
                            <v-row class="tableBL tableBB" style="width:100%;height:100%;" no-gutters>
                                <v-col class="py-2 d-flex justify-center align-center"  cols="12">
                                    ※ 발송대상자가 없습니다.
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div style="width:7%;" class="d-flex justify-center align-center tableBL tableBB">                    
                        <div class="d-flex justify-center align-center">   
                            <v-btn icon @click="onModal(item)">
                                <v-icon>mdi-text-box-outline</v-icon>
                            </v-btn>                              
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                    <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                </div>
            </div>
        </div>
        <v-dialog v-model="dialog[0]" max-width="333" content-class="round" eager>
            <OprFeeInfESndAlimViw ref="oprFeeInfESndAlimViw" @modalClose="isClose"></OprFeeInfESndAlimViw>
        </v-dialog>
    </v-sheet>
</template>
<script>
import OprFeeInfESndAlimViw from './OprFeeInfESndAlimViw.vue'
import { insBizMsgNoSnd } from '../../api/othr.js'

export default {
    name: 'OprFeeInfESndAlim',

    props : {       
        
    },
            
    components: {      
        OprFeeInfESndAlimViw  
    },

    computed: {
       
    },  

    watch:{        
    },

    created:function(){ 
        this.$nextTick(function () { 
        })
    },
    
    mounted: function(){
        
    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onSend()
                    break;
            
                default:
                    break;
            }
        },
        onSend:function(){            
            let pObj = {  
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,                
                bizMsglist:[]
            }
            this.list.forEach(item => {

                if(item.checkr == '1'){

                    item.sndList.forEach(el => {
                        let obj = {
                            sndMda:'1',
                            fcltyNum:this.$store.getters.getFcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            bzClcd:'30',
                            bzPk:item.bzPk,
                            eDocClcd:'25',  
                            itmCtt1: item.title,                          
                            itmCtt3: item.name,
                            itmCtt4: item.itmCtt4,
                            itmCtt5: item.itmCtt5,
                            itmCtt9: item.itmCtt9,
                            prtCd:'1',
                            ntcClcd:'2',
                            rcvCelphn1:el.celphn_1,
                            rcvCelphn2:el.celphn_2,
                            rcvCelphn3:el.celphn_3,
                            mmbrPk:el.mmbrPk,
                            bnMmbrPk:item.bnMmbrPk,
                            rcvNm:el.fname,
                            rcvInfm:item.name+' 보호자',
                            pstnCd:'2',                            
                            lnkUrl:item.lnkUrl
                        }

                        pObj.bizMsglist.push(obj)
                    })                  
                }
            });
            if(pObj.bizMsglist.length > 0){
                insBizMsgNoSnd(pObj)
                    .then((response) => ( this.onSendAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
            }else{
                this.$emit('msgTrigger','대상자를 선택해주세요.')
            }
        },
        onSendAfter:function(res){
            this.$emit('mdlTrigger',res)            
        },        
        setData:function(data){
            this.allChckr = false
            this.list.splice(0)
            data.forEach(el => {
                let  item = {
                    acknwRtng       : el.acknwRtng,
                    acknwRtngNm     : el.acknwRtngNm,
                    bnMmbrPk        : el.bnMmbrPk,
                    entDt           : el.entDt,
                    entStcd         : el.entStcd,
                    entStcdNm       : el.entStcdNm,
                    lnkUrl          : el.lnkUrl,
                    lvDt            : el.lvDt,
                    bzPk            : el.bzPk,
                    name            : el.name,
                    title           : el.title,
                    itmCtt4         : el.itmCtt4,
                    itmCtt5         : el.itmCtt5,
                    itmCtt9         : el.itmCtt9,
                    checkr          : '2',
                    disChekr        : false,
                    sndList        : [],
                }

                el.alimList.forEach(list => {
                    if(list.msClcd == '1'){                        
                        item.sndList.push(list)
                    }
                });

                this.list.push(item)
            })
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.list.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.list.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        onModal:function(obj){            
            this.$refs.oprFeeInfESndAlimViw.onLoad(obj)
            this.dialog.splice(0,1,true)
        },
        isClose:function(){
            this.dialog.splice(0,1,false)
        } ,
        allChekrFunc: function () {
            let tCh = '1'
            this.list.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },        
    },
    
    data: () => ({         
        forceRender:0,
        allChckr:false,
        list:[],
        dialog:[false,],
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: '알림톡 발송', type: 'action', width:'600px', disabled: false, },
        ],
    }),
};
</script>