<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">근골격계 증상검사</span>
            <v-spacer></v-spacer>            
            <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue'"
                        v-model="dialog[i]" :max-width="list.width" content-class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                            <div v-else>개발중</div>
                    </v-dialog>
                </span>
            <!-- <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span> -->
            <v-icon class="pl-2" color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-tabs hide-slider v-model="tab"  color="white" class="black--text mt-2"
            active-class="bodyColor" center-active>
            <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style" :disabled="item.enter">{{item.tab}}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab" style="height:670px">            
            <v-tab-item eager transition="fade-transition">
                <msclsSystmChkWrkTab1 
                    ref="tab1" @empMsysTrigger="empSysTrigger"></msclsSystmChkWrkTab1>
            </v-tab-item>
            <v-tab-item eager transition="fade-transition">
                <msclsSystmChkWrkTab2 
                    ref="tab2" @empMsysTrigger="empSysTrigger"></msclsSystmChkWrkTab2>
            </v-tab-item>     
            <v-tab-item eager transition="fade-transition">
                <msclsSystmChkWrkTab3 
                    ref="tab3" @empMsysTrigger="empSysTrigger"></msclsSystmChkWrkTab3>
            </v-tab-item>       
        </v-tabs-items>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete title="건강검진 관리" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import msclsSystmChkWrkTab1 from './MsclsSystmChkWrkTab1.vue';
import msclsSystmChkWrkTab2 from './MsclsSystmChkWrkTab2.vue';
import msclsSystmChkWrkTab3 from './MsclsSystmChkWrkTab3.vue';

import { selMscldChckpInfo, selMscldChckpDtlList, delMscldChckp  } from '../../api/emp.js';
export default {
    name: 'MsclsSystmChkWrk',
    
    components: {  
        btnModalDelete,
        msclsSystmChkWrkTab1,
        msclsSystmChkWrkTab2,
        msclsSystmChkWrkTab3,
    },

    props : {
    },

    computed: {
        
    },

    created: function(){   
    },

    mounted: function(){
        this.$nextTick(function () {            
        })
    },

    watch: {
        
    },

    methods: { 
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onSaveAct()
                    break;  
                case 1:
                    this.dialogDelete = true
                    break;              
                default:
                    break;
            }
        },
        onSaveAct:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.tab1.onSave()
                    break;
                case 1:
                    this.$refs.tab2.onSave()
                    break;
                case 2:
                    this.$refs.tab3.onSave()
                    break;
                default:
                    break;
            }
        },
        onLoad: function(item, tabidx){
            
            if(this.sltItems.mscldChckpPk > 0)  this.tab = tabidx
            else this.tab = 0

            this.sltItems = item
            this.setBasicInfo()
            this.getMscldChckpInfo()
            this.getMscldChckpDtlList()            
        },
        onClose: function (msg = null) {
            this.$emit('modalEvent', 1, msg)
        },    
        onDelete:function(res){            
            if(res == 9990){
                let hltChcInfPk = this.sltItems.mscldChckpPk
                if(hltChcInfPk > 0){
                    let obj = {
                        fcltyNum : this.$store.getters.getFcltyNum,
                        userNm : this.$store.getters.getUserNm,
                        mscldChckpPk :this.sltItems.mscldChckpPk,
                    }
                    delMscldChckp(obj)
                        .then((response) => ( this.onDeleteAfter(response.data) ))
                        .catch((error) => console.log('connect error /emp/delMscldChckp : ' + error))
                }else{
                    this.snackControll([true, 3500, '삭제 할 대상을 선택해주세요.', 'warning'])
                    this.dialogDelete = false
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){
            this.dialogDelete = false
            this.authGrpPk = 0

            if(res.statusCode == 200){                               
                this.$emit('modalEvent', 99)
                this.onClose(res.message)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },  
        getMscldChckpInfo:function(){
            selMscldChckpInfo(this.$store.getters.getFcltyNum, this.sltItems.mscldChckpPk)
                .then((response) => ( this.getMscldChckpInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /emp/selMscldChckpInfo : ' + error))
        },
        getMscldChckpInfoAfter:function(res){ 
            this.$refs.tab1.setItems(res)
            this.$refs.tab2.setItems(res)
            this.$refs.tab3.setItems(res)

            if(res.mscldChckpPk != null && res.mscldChckpPk > 0){
                this.tabItems[1].enter = false
                this.tabItems[2].enter = false
                this.filledBtn[1].disabled = false
            }else{
                this.tabItems[1].enter = true
                this.tabItems[2].enter = true
                this.filledBtn[1].disabled = true
            }
        },
        getMscldChckpDtlList:function(){
            selMscldChckpDtlList(null, this.sltItems.mscldChckpPk)
                .then((response) => ( this.getMscldChckpDtlListAfter(response.data) ))
                .catch((error) => console.log('upload error /emp/selMscldChckpDtlList : ' + error))
        },
        getMscldChckpDtlListAfter:function(res){ 
            this.$refs.tab1.setMsdDtlList(res)
            this.$refs.tab2.setMsdDtlList(res)
        },
        setBasicInfo:function(){
            this.$refs.tab1.setEmpInfo(this.sltItems)
        },
        empSysTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                if(this.tab == 0) this.sltItems.mscldChckpPk = res.result
                this.setBasicInfo()
                this.getMscldChckpInfo()
                this.getMscldChckpDtlList()    
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({   
        tab:0,
        forceRender:0,      
        mscldChckpPk:0,
        sltItems:{},  
        dialog:[false,false],       
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
        tabItems: [
            { index: 0, tab: '근골격계 증상조사표1', style: 'font-size: 1.2rem !important;', enter:false},
            { index: 1, tab: '근골격계 증상조사표2', style: 'font-size: 1.2rem !important;', enter:true},
            { index: 2, tab: '조치사항', style: 'font-size: 1.2rem !important;', enter:true},
        ],         
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
    }),
};
</script>