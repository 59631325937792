<template>
    <v-sheet>        
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <div class="mt-2 pa-0">
            <div class="d-flex greyE01 fontOneRem mr-2__5">
                <div class="py-2 d-flex justify-center align-center tableBT tableBB" style="width:3%;">
                    <span class="d-inline-block ml-1" style="width:26px;">
                        <v-checkbox
                            class="checkIcon" v-model="allChckr" :key="forceRender" @click="allChnger"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:5%;">
                    <span class="black--text font-weight-medium">수급자</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:5%;">
                    <span class="black--text font-weight-medium">등급</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:6%;">
                    <span class="black--text font-weight-medium">본인부담률</span>
                </div>                
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:7%;">
                    <span class="black--text font-weight-medium">미납금액</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:7%;">
                    <div>
                         <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">총청구액</span></div>
                        <div class="d-flex justify-center align-center">&#40;미납포함&#41;</div>
                    </div>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:7%;">                   
                    <div>
                        <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">총청구액</span></div>
                        <div class="d-flex justify-center align-center">&#40;미납제외&#41;</div>
                    </div>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:7%;">
                    <span class="black--text font-weight-medium">납부기한</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:7%;">
                    <span class="black--text font-weight-medium">입금일자</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:7%;">
                    <span class="black--text font-weight-medium">입금액</span>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:5%;">                    
                    <div>
                        <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">입금완료</span></div>
                        <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">여부</span></div>
                    </div>
                </div>
                <div class="d-flex tableBT tableBB" style="width:29%;">                    
                    <v-row style="width:100%;" no-gutters>
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text font-weight-medium">보호자</span>
                        </v-col>
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text font-weight-medium">연락처</span>
                        </v-col>
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text font-weight-medium">발송일</span>
                        </v-col> 
                        <v-col class="py-2 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text font-weight-medium">발송결과</span>
                        </v-col>
                    </v-row>
                </div>
                <div class="py-2 d-flex justify-center align-center tableBT tableBB tableBL" style="width:5%;">                    
                    <span class="black--text font-weight-medium">미리보기</span>
                </div>
            </div>
            <div v-if="copayClmsList.length > 0" style="height:510px;overflow-y:scroll">
                <div v-for="(item, i) in copayClmsList" :key="i" class="d-flex" style="font-size:1rem;">
                    <div class="d-flex justify-center align-center" style="width:3%;border-bottom: 1px solid #dbdbdb !important;">
                        <span class="d-inline-block ml-1" style="width:26px;">
                            <v-checkbox
                                class="checkIcon" v-model="item.checkr" :key="forceRender" :disabled="item.disChekr"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" @change="allChekrFunc"
                                hide-details>
                            </v-checkbox>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center" style="width:5%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.name}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:5%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.rtngNm}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:6%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.copayRtNm}}
                    </div>                
                    <div class="d-flex justify-center align-center" style="width:7%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.unpdAmt?.toLocaleString('ko-KR')}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:7%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.clmTot?.toLocaleString('ko-KR')}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:7%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                   
                        {{item.clmAmt?.toLocaleString('ko-KR')}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:7%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.dueDt?.beforeDateFormatDot()}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:7%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.dpsDt?.beforeDateFormatDot()}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:7%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        {{item.dpsAmt?.toLocaleString('ko-KR')}}
                    </div>
                    <div class="d-flex justify-center align-center" style="width:5%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                    
                        <v-icon v-if="item.dpsYn == '1'" color="blue">mdi-check</v-icon>
                    </div>
                    <div style="width:29%;border-left: 1px solid #dbdbdb !important;">
                        <div v-if="item.smsList.length > 0">
                            <v-row v-for="(list, j) in item.smsList" :key="j" style="width:100%;" no-gutters>
                                <v-col class="py-2 d-flex justify-center align-center" style="border-bottom: 1px solid #dbdbdb !important;" cols="3">
                                    {{list.fname}}&nbsp;&#40;{{list.rltns}}&#41;
                                </v-col>
                                <v-col class="py-2 d-flex justify-center align-center" style="border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="3">
                                    {{list.celphn_1}}&#45;{{list.celphn_2}}&#45;{{list.celphn_3}}
                                </v-col>
                                <v-col class="py-2 d-flex justify-center align-center" style="border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="3">
                                    {{list.sndDt?.beforeDateFormatDot()}}
                                </v-col> 
                                <v-col class="py-2 d-flex justify-center align-center" style="border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" cols="3">
                                    {{list.sndSttsNm}}
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else style="border-bottom: 1px solid #dbdbdb !important;">
                            <v-row  style="width:100%;height:100%;" no-gutters>
                                <v-col class="py-2 d-flex justify-center align-center"  cols="12">
                                    ※ 발송대상자가 없습니다.
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center" style="width:5%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                    
                        <div class="d-flex justify-center align-center">   
                            <v-btn icon @click="onModal(item)">
                                <v-icon>mdi-text-box-outline</v-icon>
                            </v-btn>                              
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                    <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                </div>
            </div>
        </div>
        <v-dialog v-model="dialog[0]" max-width="333" content-class="round" eager>
            <CopayClmsBillSmsViw ref="copayClmsBillSmsViw" @modalClose="isClose"></CopayClmsBillSmsViw>
        </v-dialog>
    </v-sheet>
</template>
<script>
import CopayClmsBillSmsViw from './CopayClmsBillSmsViw.vue'
import { insBizMsgNoSnd } from '../../api/othr.js'

export default {
    name: 'CopayClmsBillSms',

    props : {       
        
    },
            
    components: {   
        CopayClmsBillSmsViw     
    },

    computed: {
       
    },  

    watch:{        
    },

    created:function(){ 
        this.$nextTick(function () { 
            
        })
    },
    
    mounted: function(){
        
    },

    methods: {
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onSend()
                    break;
            
                default:
                    break;
            }
        },
        onSend:function(){            
            let pObj = {  
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,                
                bizMsglist:[]
            }
            this.copayClmsList.forEach(item => {

                if(item.checkr == '1'){

                    item.smsList.forEach(el => {
                        let obj = {
                            sndMda:'2',
                            fcltyNum:this.$store.getters.getFcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            bzClcd:'20',
                            bzPk:item.copayClmHisPk,
                            eDocClcd:'6',
                            val1:item.clmYymm,
                            itmCtt3:item.name+'님',
                            itmCtt4:item.name+'님',
                            itmCtt5:item.clmTot?.toLocaleString('ko-KR'),
                            rcvCelphn1:el.celphn_1,
                            rcvCelphn2:el.celphn_2,
                            rcvCelphn3:el.celphn_3,
                            mmbrPk:el.mmbrPk,
                            bnMmbrPk:item.bnMmbrPk,
                            rcvNm:el.fname,
                            rcvInfm:item.name+' 보호자',
                            pstnCd:'2',
                            lnkUrl:item.lnkUrl
                        }

                        pObj.bizMsglist.push(obj)
                    })                  
                }
            });

            if(pObj.bizMsglist.length > 0){
                insBizMsgNoSnd(pObj)
                    .then((response) => ( this.onSendAfter(response.data) ))
                    .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
            }else{
                this.$emit('msgTrigger','대상자를 선택해주세요.')
            }
        },
        onSendAfter:function(res){
            this.$emit('mdlTrigger',res)            
        },        
        setData:function(data){
            this.allChckr = false
            this.copayClmsList.splice(0)
            // this.copayClmsList = JSON.parse(JSON.stringify(data))

            // this.copayClmsList.forEach(el => {
            //     el.checkr = '2'
            //     el.disChekr = false
            // });
            data.forEach(el => {
                let  item = {
                    bnMmbrPk        : el.bnMmbrPk,
                    bnfcrPk         : el.bnfcrPk,
                    clmAmt          : el.clmAmt,
                    clmTot          : el.clmTot,
                    clmYymm         : el.clmYymm,
                    copayClmHisPk   : el.copayClmHisPk,
                    copayRtNm       : el.copayRtNm,
                    dpsAmt          : el.dpsAmt,
                    dpsDt           : el.dpsDt,
                    dpsYn           : el.dpsYn,
                    dueDt           : el.dueDt,
                    lnkUrl          : el.lnkUrl,
                    name            : el.name,
                    rtngNm          : el.rtngNm,
                    unpdAmt         : el.unpdAmt,
                    checkr          : '2',
                    disChekr        : false,
                    smsList        : []
                }

                el.smsList.forEach(list => {
                    if(list.msClcd == '2'){
                        item.smsList.push(list)
                    }
                });

                this.copayClmsList.push(item)
            });
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.copayClmsList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.copayClmsList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        onModal:function(obj){
            obj.lnkUrl = this.encUrlObj(obj.bnMmbrPk, obj.clmYymm)

            this.$refs.copayClmsBillSmsViw.onLoad(obj)
            this.dialog.splice(0,1,true)
        },
        isClose:function(){
            this.dialog.splice(0,1,false)
        } ,
        allChekrFunc: function () {
            let tCh = '1'
            this.copayClmsList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },
        //Url Encrypt
        encUrlObj: function(pk, yymm){
            let linkURI = ''

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = pk
            obj.inqYYMM = yymm

            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + 'out/payExpStt?dstnt=' + bs64
        },        
    },
    
    data: () => ({   
        forceRender:0,
        allChckr:false,
        copayClmsList:[],
        dialog:[false,],      
        filledBtn: [
            { icon: 'mdi-send-variant', class: 'ml-1 white--text', color:'blueBtnColor', text: '문자 발송', type: 'action', width:'600px', disabled: false, },
        ],
    }),
};
</script>