<template>
    <v-sheet class="rounded-lg rounded-l-0" color="">
        <div class="d-flex justify-center pr-3" style="width:100%;">
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
                <v-dialog v-if="list.type == 'dialogue' && list.visible"
                    v-model="dialog[i]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <btnModalDelete v-if="list.text == '삭제'" :title="'외출·외박 내역'" @madalState="onDialog"></btnModalDelete>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j"
                :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded outlined>
                <v-icon small>{{list.icon}}</v-icon>
                <span style="font-size: 1rem;">{{list.text}}</span>
            </v-btn>
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="my-3">
            <basicInfo :bnMmbrPk="bnMmbrPk" ref="basicInfo"></basicInfo>
        </div>
        <v-form ref="form" lazy-validation>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01  py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">신청구분</span>
                </v-col>
                <v-col class="" style="" cols="4">
                    <v-radio-group v-model="outHisInfo.aplctClcd" class="pl-2 pt-1" :rules="[rules.required]" hide-details row>
                        <v-radio class="mr-2" :ripple="false" label="외출" value="1"></v-radio>
                        <v-radio class="mr-2" :ripple="false" label="외박" value="2"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" style="" cols="4">
                    <span class="d-inline-block ml-2" style="width: 50%;">
                        <v-text-field
                            v-model="outHisInfo.wrtr"
                            :rules="[rules.required]"
                            hide-details readonly outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" style="padding: 0px 1px 0px 2px !important"
                        min-width="30" height="25"
                        @click="onModal(0), togModalIndex = 1" :disabled="btnDisabled"
                        dark small>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">신청인</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" style="" cols="4">
                    <span class="d-inline-block ml-2" style="width:34%;">
                        <v-text-field
                            v-model="outHisInfo.aplcn"
                            :rules="[rules.required]"
                            hide-details readonly outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 white--text darken-1" style="padding: 0px 1px 0px 2px !important"
                        color="bckgrColor" min-width="30" height="25"
                        @click="onModal(1)" :disabled="btnDisabled"
                        dark small>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>보호자선택</span>
                        </v-tooltip>
                    </v-btn>
                    <v-btn 
                        class="ml-1 brown lighten-3" style="padding: 0px 1px 0px 2px !important"
                        min-width="30" height="25"
                        @click="onModal(0), togModalIndex = 2" :disabled="btnDisabled"
                        dark small>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">연락처</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" style="" cols="4">
                    <span class="d-inline-block pl-2 pt-0 pb-1">
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="outHisInfo.tel_1" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="outHisInfo.tel_2" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="outHisInfo.tel_3" :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" hide-details outlined dense></v-text-field>
                        </span>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">신청일</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" style="" cols="4">
                    <span class="d-inline-block py-2 pl-2" style="width: 143px;">
                        <CmmDateComp v-model="outHisInfo.aplctDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">관계</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" style="" cols="4">
                    <span class="d-inline-block ml-2" style="width:28%;">
                        <v-text-field v-model="outHisInfo.rltns" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">기간</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" style="" cols="10">
                    <span class="d-inline-block py-2 pl-2" style="width: 143px;">
                        <CmmDateComp v-model="outHisInfo.outDtStrt" :required="true"></CmmDateComp>
                    </span>
                    <span class="d-inline-block ml-2" style="width: 60px;">
                        <CmmTimeField v-model="outStrt" :required="true"></CmmTimeField>
                    </span>
                    <span class="d-flex align-center ml-4 mr-2">
                        <v-icon size="16" color="greyE00">mdi-tilde</v-icon>
                    </span>
                    <span class="d-inline-block py-2 pl-2" style="width: 143px;" v-if="outHisInfo.aplctClcd == '2'">
                        <CmmDateComp
                            v-model="outHisInfo.outDtEnd"
                            :required="outHisInfo.aplctClcd == '2' ? false : false">
                        </CmmDateComp>
                    </span>
                    <span class="d-inline-block ml-2" style="width: 60px;">
                        <CmmTimeField v-model="outEnd" :required="false"></CmmTimeField>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">행선지</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" style="" cols="10">
                    <span class="d-inline-block mx-2" style="width: 100%;">
                        <v-text-field v-model="outHisInfo.dstnt" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">사유</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" style="" cols="10">
                    <span class="d-inline-block mx-2" style="width:100%;">
                        <v-text-field v-model="outHisInfo.rsn" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">진료비&nbsp;청구</span>
                </v-col>
                <v-col class="pa-0 ma-0 d-flex justify-left align-center px-2" style="" cols="10">
                    <!-- <v-radio-group v-model="outHisInfo.clncYn" class="d-flex ml-2" style="width:140px;" :rules="[rules.required]" hide-details row>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="청구" value="1"></v-radio>
                        <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="미청구" value="2"></v-radio>
                    </v-radio-group> -->
                    <v-btn 
                        class="" color="#E8A0BF" style="padding: 1px 1px 0px 2px !important"
                        min-width="30" height="25" @click="onModal(7)" :disabled="btnDisabled"
                        dark small>
                        <v-icon size="20">mdi-credit-card-edit-outline</v-icon>
                    </v-btn>
                    <span v-if="!btnDisabled" class="ml-4">진료 약제비 합계: {{ mdclSum.clncDrugSum?.toLocaleString('ko-KR') }}원,</span>
                    <span v-if="!btnDisabled" class="ml-4">계약의사 진찰비 합계: {{ mdclSum.dctrSum?.toLocaleString('ko-KR') }}원</span>
                    <!-- <v-btn class="d-inline ml-2" height="28" @click="onModal(7)" :disabled="btnDisabled" depressed>진료비&nbsp;청구</v-btn> -->
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="2">
                    <span class="black--text font-weight-medium">비고</span>
                </v-col>
                <v-col class="pa-2" style="" cols="10">
                    <v-textarea
                        v-model="outHisInfo.rmrks"
                        class="rmrksArea" rows="5"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-row class="pa-0 align-center mt-4 mb-2" no-gutters>
            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">외출&#183;외박&nbsp;이력</span>
            <v-spacer></v-spacer>
            <!-- <span class="d-inline-block rounded white" style="width: 230px;">
                <v-select
                    v-model="sltBnfcrPk" :key="forceRender"
                    :items="bnfcrPkList" :disabled="sltBnfcrPk == -1"
                    item-text="viewTxt" item-value="bnfcrPk"
                    dense hide-details outlined>
                </v-select>
            </span> -->
        </v-row>
        <v-divider></v-divider>
        <v-data-table
            class="" height="192"
            :headers="headers" :items="outHisList"
            :page.sync="page" :items-per-page="itemsPerPage"
            :loading="tblHisLdr"
            hide-default-footer disable-sort fixed-header dense>

            <template v-slot:item='{ item, index }'>
                <tr
                    :class="['fixedCol', selectedItem == index ? 'sltRow' : 'disSltRow']"
                    @click="tableClickEvent(index, item.outHisPk)">
                    <td class="fixedCol0 txtAlgnMid tableBR tableBB">
                        <span class="fontOneRem">{{ item.name }}</span>
                    </td>
                    <td class="fixedCol0 txtAlgnMid tableBR tableBB">
                        <span v-if="item.aplctClcd == '1'" class="fontOneRem">외출</span>
                        <span v-else-if="item.aplctClcd == '2'" class="fontOneRem">외박</span>
                        <span v-else class="fontOneRem font-weight-medium ">&nbsp;</span>
                    </td>
                    <td class="fixedCol0 txtAlgnMid tableBR tableBB py-2" style="line-height: 1.2;">
                        <span v-if="item.aplctClcd == '1'" class="fontOneRem">
                            {{item.outDtStrt.beforeDateFormatDot()}}<br/>{{item.outTmHhStrt}}&#58;{{item.outTmMmStrt}}&nbsp;&#126;&nbsp;{{item.outTmHhEnd}}&#58;{{item.outTmMmEnd}}
                        </span>
                        <span v-else-if="item.aplctClcd == '2'" class="fontOneRem">
                            {{item.outDtStrt.beforeDateFormatDot()}}&nbsp;&#126;&nbsp;{{item.outDtEnd.beforeDateFormatDot()}}
                        </span>
                        <span v-else class="fontOneRem">&nbsp;</span>
                    </td>
                    <td class="fixedCol0 txtAlgnMid tableBR tableBB">
                        <span class="fontOneRem">{{ item.aplcn }}</span>
                    </td>
                    <td class="fixedCol0 txtAlgnMid tableBR tableBB">
                        <span class="fontOneRem">{{ item.rltns }}</span>
                    </td>
                    <td class="fixedCol0 txtAlgnMid tableBR tableBB">
                        <span class="fontOneRem">{{ item.dstnt }}</span>
                    </td>
                    <td class="fixedCol0 txt-break tableBR tableBB py-1" style="line-height: 1.2;">
                        <span class="fontOneRem">{{ item.rsn }}</span>
                    </td>
                    <td class="fixedCol0 txtAlgnMid tableBB">
                        <span class="fontOneRem">{{ item.wrtr }}</span>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            @input="getPage"
            total-visible="5">
        </v-pagination>

        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = "outHisInfo.aplctDt"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="600">
            <fmlySelector :prtBnMmbrPk="bnMmbrPk" @isClose="isModal(1)" @outFmly="getFmlyInfo"></fmlySelector>
        </v-dialog>
        <v-dialog v-model="menu[7]" eager content-class="round" persistent max-width="1400">
            <MdclExpnsMdf ref="mdclExpnsMdf" @isClose="isModal(7)" :prtOutHisPk="chkHisPk" :rtCmp="'stayOut'" @sumObj="getMdclSum"></MdclExpnsMdf>
        </v-dialog>

        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top
            style="top: 55px; left: 150px;">
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
    </v-sheet>
</template>

<script>
import { selOutHisList, selOutHisInfo, insOutHis, delOutHis, insOutHisClncRec, selBnfcrPkList } from '../../api/bnfcr.js';

import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import FmlySelector from '../../components/commons/FmlySelector.vue';
import MdclExpnsMdf from '../../components/bnfcr/MdclExpnsMdf.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../../components/commons/CmmDateComp.vue';
import CmmTimeField from '../../components/commons/CmmTimeField.vue';

export default {
    name: 'StayOutMgmt',
        
    components: {
        basicInfo,
        EmpSelector,
        FmlySelector,
        btnModalDelete,
        MdclExpnsMdf,
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
        })
    },

    computed: {
        getBnMmbr(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
    },

    watch:{
        getBnMmbr: function(value){
            this.bnMmbrPk = value
        },
        'outHisInfo.aplctClcd': function(){
            if(this.outHisInfo.aplctClcd == '1')
                this.outHisInfo.outDtEnd = ''
        },
    },
        
    methods: {
        getOutHisList: function (strt, lmt) {
            this.tblHisLdr = true
            selOutHisList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, strt, lmt)
                .then((response) => ( this.getOutHisListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selOutHisList : ' + error))
        },
        getOutHisInfo: function (outhisPk) {
            selOutHisInfo(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, outhisPk)
                .then((response) => ( this.getOutHisInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selOutHisInfo : ' + error))
        },
        setOutHis: function (obj) {
            insOutHis(obj)
                .then((response) => ( this.setOutHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insOutHis : ' + error))
        },
        removeOutHis: function (outhisPk) {
            delOutHis(this.$store.getters.getFcltyNum, outhisPk, this.$store.getters.getUserNm)
                .then((response) => ( this.setOutHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delOutHis : ' + error))
        },
        setOutHisClncRec: function (info, list) {
            insOutHisClncRec(info, list)
                .then((response) => ( this.setOutHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insOutHisClncRec : ' + error))
        },
        // 수급자 구성원(bnfcrPk) 고유번호 조회
        getBnfcrPkList: function (){
            this.sltBnfcrPk = -1
            this.bnfcrPkList.splice(0)

            selBnfcrPkList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr)
                .then((response) => ( this.bnfcrPkRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrPkList : ' + error))
        },
        getOutHisListAfter: function (res) {
            res.rnTot = Number(res.rnTot)

            this.pageCount = Math.ceil(res.rnTot/this.itemsPerPage)

            this.outHisList = res.list

            this.outHisList.forEach(element => {
                element.name = this.$store.getters.getBnfcrState.name
            })

            this.tblHisLdr = false
            this.btnDisabled = false
        },
        getOutHisInfoAfter: function (res) {
            let cache = Object.assign({}, res)
            cache.aplctDt = cache.aplctDt?.beforeDateFormatHyp()
            cache.outDtStrt = cache.outDtStrt?.beforeDateFormatHyp()
            cache.outDtEnd = cache.outDtEnd?.beforeDateFormatHyp()
            if(cache.outTmHhStrt != null && cache.outTmMmStrt != null)
                this.outStrt = cache.outTmHhStrt + ':' + cache.outTmMmStrt
            else
                this.outStrt = ''
            if(cache.outTmHhEnd != null && cache.outTmMmEnd != null)
                this.outEnd = cache.outTmHhEnd + ':' + cache.outTmMmEnd
            else
                this.outEnd = ''
            this.chkHisPk = Number(cache.outHisPk)

            this.outHisInfo = cache
 
            this.filledBtn[1].disabled = false
            this.filledBtn[2].disabled = false
        },
        setOutHisAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.clearForm()
                this.getList()
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        bnfcrPkRst: function (res){
            console.log(res)
            if(res.statusCode == '200'){
                if(Array.isArray(res.result)){
                    res.result.forEach((itm, i) => {
                        let obj = ''
                        if(i == 0){
                            this.sltBnfcrPk = itm.bnfcrPk
                            obj += '현재 '
                        }
                        else
                            obj += '과거 '

                        if(itm.lvDt == null || itm.lvDt == '')
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ~ )')
                        else{
                            obj += this.$moment(itm.entDt, 'YYYYMMDD').format('( YY.MM.DD ')
                            obj += this.$moment(itm.lvDt, 'YYYYMMDD').format('~ YY.MM.DD ) ')
                        }

                        itm.viewTxt = obj
                    });

                    this.bnfcrPkList = res.result
                }
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preSetOutHis: function () {
            let vali = this.$refs.form.validate()
            if(!vali) return

            let data = Object.assign({}, this.outHisInfo)
            let strt = this.outStrt.split(':', 2)
            let end = this.outEnd.split(':', 2)

            data.aplctDt = data.aplctDt?.afterDateFormatHyp()
            data.outDtStrt = data.outDtStrt?.afterDateFormatHyp()
            if(data.aplctClcd == '1')
                data.outDtEnd = data.outDtStrt?.afterDateFormatHyp().toString()
            else
                data.outDtEnd = data.outDtEnd?.afterDateFormatHyp()
            data.outTmHhStrt = strt[0]
            data.outTmMmStrt = strt[1]
            data.outTmHhEnd = end[0]
            data.outTmMmEnd = end[1]
            data.fcltyNum = this.$store.getters.getFcltyNum
            data.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            data.userNm = this.$store.getters.getUserNm

            if(JSON.stringify(this.$store.getters.getTmpObj) !== JSON.stringify({})){
                let list = JSON.parse(JSON.stringify(this.$store.getters.getTmpObj))

                    let chk = 0
                    list.forEach(item => {
                        if(item.clncDt == '') chk += 1
                    })
                    list.forEach(item => {
                        item.clncRecPk = 0
                        if(item.clncDt != '') item.clncDt = item.clncDt?.afterDateFormatHyp()
                        if(item.clncFeeClmYn == '청구') item.clncFeeClmYn = '1'
                        else if(item.clncFeeClmYn == '미청구') item.clncFeeClmYn = '2'
                        if(item.drugFeeClmYn == '청구') item.drugFeeClmYn = '1'
                        else if(item.drugFeeClmYn == '미청구') item.drugFeeClmYn = '2'
                        if(item.dctrFeeClmYn == '청구') item.dctrFeeClmYn = '1'
                        else if(item.dctrFeeClmYn == '미청구') item.dctrFeeClmYn = '2'
                        if(item.frstYn == '초진') item.frstYn = '1'
                        else if(item.frstYn == '재진') item.frstYn = '2'
                    })

                    if(list?.length == 0)
                        list.push({clncDt: '', clncClcd: ''})

                    if(chk == 0)
                        this.setOutHisClncRec(data, list)
                    else
                        this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])

                    this.$store.commit('setTmpObj', {...{}})
                
            }
            else
                //console.log(data)
                this.setOutHisClncRec(data, [])

        },
        tableClickEvent: function (rn, pk){
            //let date = new Date().toISOString().split('T',2)

            if(this.selectedItem == -1){
                this.selectedItem = rn
                this.filledBtn[2].disabled = false
                this.getOutHisInfo(pk)
            }
            else if(this.selectedItem != rn){
                this.selectedItem = rn
                this.filledBtn[2].disabled = false
                this.getOutHisInfo(pk)
            }
            else{
                //Form Clear
                this.filledBtn[2].disabled = true
                this.resetPage()
                this.selectedItem = -1
            }

        },
        getList: function () {
            // this.getBnfcrPkList()
            this.getPage()
        },
        getPage: function () {
            let strt = ((this.page-1)*this.itemsPerPage)

            this.getOutHisList(strt, this.itemsPerPage)
        },
        resetPage: function () {
            this.outHisInfo = Object.assign({}, this.dummyOutHisInfo)
            this.outStrt = '',
            this.outEnd = '',
            this.chkHisPk = 0
            this.selectedItem = -1
            this.$refs.form.resetValidation()
            this.forceRender += 1
        },
        clearForm: function (){
            this.resetPage()
            this.btnDisabled = true
            this.outHisList.splice(0)
        },
        onClickBtn: function(text) {
            if(text == '저장'){
                let chk = this.$refs.form.validate()
                if(chk)
                    this.preSetOutHis()
                else
                    this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
            else if(text == '신규') {
                this.resetPage()
                this.selectedItem = -1
            }
            else if(text == '삭제') {
                //this.$refs.bnfcrInfo.insBnfcrFull()
            }
        },
        onModal: function(index) {  
            this.menu.splice(index, 1, true)
        },
        onDialog: function(index) {
            if(index == 1)
                this.dialog.splice(1, 1, false)
            else if(index == 7)
                this.dialog.splice(2, 1, false)
            else if(index == 9990){
                this.dialog.splice(2, 1, false)
                this.removeOutHis(this.outHisInfo.outHisPk)
            }
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },
        getMdclList: function (obj) {
            this.clncRecList = Object.assign([], obj)
        },
        getEmpInfo: function (obj) {
            if(this.menu[0] == true && this.togModalIndex == 1)
                this.outHisInfo.wrtr = obj[0].name
            else if(this.menu[0] == true && this.togModalIndex == 2){
                this.outHisInfo.aplcn = ''
                this.outHisInfo.aplcn = obj[0].name
            }
            else if(this.menu[1] == true){
                this.outHisInfo.aplcn = ''
                this.outHisInfo.aplcn = obj[0].name
            }
        },
        getFmlyInfo: function (obj) {
            this.outHisInfo.aplcn = ''
            this.outHisInfo.aplcn = obj.name
            this.outHisInfo.tel_1 = obj.celphn_1
            this.outHisInfo.tel_2 = obj.celphn_2
            this.outHisInfo.tel_3 = obj.celphn_3
        },
        getMdclSum: function (obj) {
            this.mdclSum = obj
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        tab: 3,
        menu: [false, false, false, false, false, false, false, false],
        togModalIndex: -1,
        btnDisabled: true,
        tblHisLdr: false,
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        sltBnfcrPk: -1,
        selectedItem: -1,
        outStrt: '',
        outEnd: '',
        bnMmbrPk: 0,
        chkHisPk: 0,
        forceRender: 0,
        bnfcrPkList: [],
        outHisList: [],
        mdclSum: { clncDrugSum: 0, dctrSum: 0 },
        outHisInfo: {
            aplcn: '', aplctDt: '', rltns: '', rmrks: '', outHisPk: 0, clncHisPk: 0, rsn: '', outTmHhEnd: '', outTmHhStrt: '', wrtr: '',
            outTmMmEnd: '', outTmMmStrt: '', outDtStrt: '', outDtEnd: '', dstnt: '', aplctClcd: '', tel_3: '', tel_2: '', tel_1: '', clncYn: '2', clncDrugSum: 0
        },
        dummyOutHisInfo: {
            aplcn: '', aplctDt: '', rltns: '', rmrks: '', outHisPk: 0, clncHisPk: 0, rsn: '', outTmHhEnd: '', outTmHhStrt: '', wrtr: '',
            outTmMmEnd: '', outTmMmStrt: '', outDtStrt: '', outDtEnd: '', dstnt: '', aplctClcd: '', tel_3: '', tel_2: '', tel_1: '', clncYn: '2'
        },
        clncRecList: null,
        dialog: [false, false, false],
        headers: [
            { text: '수급자', value: '', align: 'center', width: '90', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize tableBR py-2'},
            { text: '신청구분', value: 'aplctClcd', align: 'center', width: '90', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize tableBR'},
            { text: '기간', value: '', align: 'center', width: '70', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize tableBR'},
            { text: '신청인', value: 'aplcn', align: 'center', width: '90', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize tableBR'},
            { text: '관계', value: 'rltns', align: 'center', width: '70', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize tableBR'},
            { text: '행선지', value: 'dstnt', align: 'center', width: '90', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize tableBR'},
            { text: '사유', value: 'clncHisPk', align: 'center', width: '110', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize tableBR'},
            { text: '작성자', value: 'wrtr', align: 'center', width: '90', class: 'black--text nmlBfClrImp-greyE01 tableHeaderSize'},
        ],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', width:'600px', visible: true, disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'500px', visible: true, disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'dialogue', width:'500px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력', disabled: true, },

        ],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>