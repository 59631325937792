<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth + 300">
        <div class="px-2 mt-1 mr-2 mb-3 d-flex align-center">
            <div class="d-flex mx-3">
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex justify-right pl-1 pr-3">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn @click="onClickBtn(list.text)" :class="list.class" samll height="30"
                        :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{ list.icon }}</v-icon>
                        <span style="font-size: 1rem;">{{ list.text }}</span>
                    </v-btn>
                </span>
            </div>
        </div>
        <div class="" style="border-top: 1px solid #dbdbdb;">
            <div class="mt-2">
                <span>
                    <expandQtrPicker @nowQtr="getQtr"></expandQtrPicker>
                </span>
            </div>
            <div class="mt-2">
                <v-data-table class="msclnTable" height="500" locale="ko" :headers="headers" :items="items"
                    hide-default-header fixed-header disable-pagination hide-default-footer dense>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th style="border-bottom: 1px solid #dbdbdb;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[0].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[1].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[2].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[3].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[4].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[5].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb;" class="black--text clmsHeaderCommon tableBT"
                                    rowspan='2'>{{ props.headers[6].text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr>
                            <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.idxCdNm }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.prgTypNm }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.opDt }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                                <span v-for="(itm, j) in item.bnfcrgrNm" :key="j">
                                    <span v-if="j > 0">,</span>
                                    {{ itm }}
                                </span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.prtcpNum }}</span>
                            </td>
                            <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.mc }}</span>
                            </td>
                            <td class="tableBB clmsBodyCommon" style="text-align: center;">
                                <v-btn
                                    class=" rounded d-inline-block"
                                    @click="showDialog(item.prgOpPlnPk, item.prgOpRecPk, item.prgInfPk)"
                                    icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </div>
        <div class="">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">프로그램 의견수렴 내역</span>
            <v-data-table class="msclnTable" height="143" locale="ko" :headers="headers1" :items="items1"
                hide-default-header fixed-header disable-pagination hide-default-footer dense>
                <template v-slot:header="{ props }">
                    <thead>
                        <tr>
                            <th style="border-bottom: 1px solid #dbdbdb;"
                                class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{ props.headers[0].text }}
                            </th>
                            <th style="border-bottom: 1px solid #dbdbdb;"
                                class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{ props.headers[1].text }}
                            </th>
                            <th style="border-bottom: 1px solid #dbdbdb;"
                                class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{ props.headers[2].text }}
                            </th>
                            <th style="border-bottom: 1px solid #dbdbdb;"
                                class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{ props.headers[3].text }}
                            </th>
                            <th style="border-bottom: 1px solid #dbdbdb;" class="black--text clmsHeaderCommon tableBT"
                                rowspan='2'>{{ props.headers[4].text }}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.idxCdNm1 }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.wrtDt }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.wrtr }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="text-align: center;">
                            <span class="cmmFontSizeOne" v-if="item.imprvRfl.length > 0">반영</span>
                            <span class="cmmFontSizeOne" v-else>미반영</span>
                        </td>
                        <td class="tableBB clmsBodyCommon" style="text-align: center;">
                            <v-btn icon class=" rounded d-inline-block" v-ripple
                                @click="onShow(item.prgImprvPk)"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <v-dialog v-model="dialog[0]" persistent max-width="1400" min-width="1400" eager>
            <insPrgImprv ref="insPrgImprv" @isClose="isModalType" @reload="reset()"></insPrgImprv>
        </v-dialog>
    </v-sheet>
</template>

<script>
import insPrgImprv from '../../components/prgrm/insPrgImprv.vue';
import expandQtrPicker from '../../components/commons/expandQtrPicker.vue';

import { getStcd } from '../../api/index.js';
import { selPrgOpRecList, selPrgImprvList } from '../../api/prgrm.js';

export default {
    name: 'PrgImprv',

    components: {
        insPrgImprv,
        expandQtrPicker,
    },

    props: {

    },

    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getRootEvt: function () {
            return this.$store.getters.getRootRunEvt
        },
    },

    watch: {
        getRootEvt: function (bol) {
            if(bol){
                this.selPrgOpRecList()
                this.selPrgImprvList()
                this.$store.commit('setRootRunEvt', false)
            }
        },
        'qtr': function () {
            this.selPrgOpRecList()
            this.selPrgImprvList()
        },
    },

    methods: {
        selPrgOpRecList: function () {
            selPrgOpRecList(this.$store.getters.getFcltyNum, this.$parent.$parent.yDate, this.qtr)
                .then((response) => (this.selPrgOpRecListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgOpRecList : ' + error))
        },
        selPrgOpRecListAfter: function (res) {
            this.items = res.list
            this.items.forEach(e => {
                this.cdItems.forEach(i => {
                    if (e.idxCd == i.valcd) {
                        e.idxCdNm = i.valcdnm
                    }
                });
                e.opDt = e.opDt.beforeDateFormatDot()
            });
        },
        selPrgImprvList: function () {
            selPrgImprvList(this.$store.getters.getFcltyNum, this.$parent.$parent.yDate, this.qtr)
                .then((response) => (this.selPrgImprvListAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgImprvList : ' + error))
        },
        selPrgImprvListAfter: function (res) {
            this.items1 = res
            this.items1.forEach(e => {
                this.cdItems.forEach(i => {
                    if (e.idxCd == i.valcd) {
                        e.idxCdNm1 = i.valcdnm
                    }
                });
                e.wrtDt = e.wrtDt.beforeDateFormatDot()
            });
        },
        isModalType: function () {
            this.dialog.splice(0, 1, false)
      
            this.selPrgImprvList()
        },
        onClickBtn: function (text) {
            if (text == "의견수렴작성") {
                this.dialog.splice(0, 1, true)
                this.$refs.insPrgImprv.onCodeload()
            } 
        },
        showDialog: function (plnPk, recPk, prgInfPk) {
            let obj = {}
            obj.prgOpPlnPk = plnPk
            obj.prgOpRecPk = recPk
            obj.prgInfPk = prgInfPk
            
            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1300, prsst: true, typ: '4-01', inObj: obj, outObj: null, runEvt: false,
            });
        },
        onShow: function (pk) {
            this.dialog.splice(0, 1, true)
            this.$refs.insPrgImprv.selPrgImprvInfo(pk)
            this.$refs.insPrgImprv.onCodeload()
        },
        getQtr: function (value) {
            this.qtr = value
        },
        stCdMapping: function (res) {
            this.cdItems = res
        },
    },
    data: () => ({
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '의견수렴작성', type: 'action', },
        ],
        headers: [
            { text: '지표구분', align: 'center', width: '5%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '프로그램유형', align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '제공일', value: 'name', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '수급자그룹', value: 'acknwRtng', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '참여인원수', value: 'outDtStrt', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '진행자', value: 'outTmStrt', sortable: true, align: 'center', width: '5%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '상세보기', value: 'outDtEnd', sortable: true, align: 'center', width: '10%', class: 'black--text clmsHeaderCommon tableBR tableBT' },
        ],
        headers1: [
            { text: '지표구분', align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '작성일', align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '작성자', value: 'name', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '의견수렴 반영여부', value: 'acknwRtng', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '상세보기', value: 'outDtStrt', sortable: true, align: 'center', class: 'black--text clmsHeaderCommon tableBR tableBT' },
        ],
        areaWidth: 708,
        dialog: [false, false],
        items: [],
        items1: [],
        qtr: 0,
        
    }),
};
</script>