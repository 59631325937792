<template>
    <v-sheet>        
        <div class="d-flex pt-2 EmpWageInfTab1">
            <div style="width:30%">
                <v-row class="ma-0 pa-0">           
                    <exBtn class="mr-1" :btnData="exBtn[0]" :sltElmnt="sltElmnt" @btnClosed="expandClose" @changeCheck="filterSearch" />        
                    <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />        
                    <span class="d-inline-block" style="width: 130px;">
                        <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                            single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                        </v-text-field>
                    </span>
                </v-row>
                <v-row class="ma-0 pa-0 mt-3">
                    <v-data-table hide-default-footer dense locale="ko" height="665"
                        hide-default-header fixed-header 
                        :headers="headers" 
                        :items="empWageInfList" 
                        :search="schDummy"  
                        :custom-filter="customFilter"               
                        class="" disable-pagination>

                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>                                    
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;">{{props.headers[0].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;">{{props.headers[1].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;">{{props.headers[2].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;">{{props.headers[3].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBT" style="width: 100px !important;">{{props.headers[4].text}}</th>
                                </tr>
                            </thead>
                        </template>                
                        <template v-slot:item='{ item }'>
                            <tr
                                :class="['fixedCol', sltEmpPk == item.empPk ? 'sltRow' : 'disSltRow']"
                                style="cursor: pointer;"
                                @click="tableClickEvent(item)">
                                <td class="fixedCol0 clmsBodyCommon tableBR">{{item.name}}</td>
                                <td class="fixedCol0 clmsBodyCommon tableBR">{{item.wrkStcdNm}}</td>
                                <td class="fixedCol0 clmsBodyCommon tableBR">{{item.jobNm}}</td>
                                <td class="fixedCol0 clmsBodyCommon tableBR">
                                    <div>
                                        <div>{{$moment(item.entDt,'YYYYMMDD').format('YYYY.MM.DD')}}</div>
                                        <div v-if="item.wrkStcd == '2'" class="mt-1" style="font-size:0.875rem">&#40;퇴&#41;{{$moment(item.rtrDt,'YYYYMMDD').format('YYYY.MM.DD')}}</div>
                                    </div>
                                </td>
                                <td class="fixedCol0 clmsBodyCommon">{{item.wgeTypNm}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-row>
            </div>
            <div class="ml-3" style="width:70%">
                <v-form ref="tabform" lazy-validation>
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">직원정보</span>
                    </div>  
                    <div>
                        <div class="d-flex pa-0 ma-0 mt-2" style="border-top: 1px solid #dbdbdb;" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%">
                                <span class="black--text font-weight-medium">직원명</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:17%;border-left: 1px solid #dbdbdb !important;">
                                <span>{{empWgeItems.name}}</span>
                                <span class="ml-1">&#40;</span>
                                <span>
                                    <v-img class="ic-sex" v-if="empWgeItems.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                                    <v-img class="ic-sex" v-else-if="empWgeItems.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>                                
                                </span>
                                <span>&#58;</span>
                                <span>{{empWgeItems.age}}</span>
                                <span>&#41;</span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">직종</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:17%;border-left: 1px solid #dbdbdb !important;">
                                {{empWgeItems.jobNm}}
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">입사일</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="21%;border-left: 1px solid #dbdbdb !important;">
                                {{empWgeItems.entDt?.beforeDateFormatDot()}}
                            </div>
                        </div>
                        <div class="d-flex pa-0 ma-0" style="border-top: 1px solid #dbdbdb;" no-gutters>                        
                            
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">부양가족수</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:49%;border-left: 1px solid #dbdbdb !important;">
                                <span>총</span>
                                <span class="pl-1" style="width:50px;"><v-text-field color="blue" v-model="empWgeItems.dpnNum" :rules="[rules.required, rules.numberCheck]" type="number" min="0" hide-details outlined dense></v-text-field></span>
                                <span>명</span>
                                <span class="pl-1">&#40;7세이상 20세이하</span>
                                <span class="pl-1" style="width:50px;"><v-text-field color="blue" v-model="empWgeItems.dpnNumU20" type="number" min="0" hide-details outlined dense></v-text-field></span>
                                <span>명</span>
                                <span>&#41;</span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">지급일</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:21%;border-left: 1px solid #dbdbdb !important;">
                                <span>{{empWgeItems.wgpyDt}}</span>
                                <span class="pl-1">일</span>
                            </div>
                        </div>                    
                        <div class="d-flex pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:15%;">                                
                                <span class="black--text font-weight-medium">은행</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:17%;border-left: 1px solid #dbdbdb !important;;">                                      
                                <span class="d-inline-block" style="width: 110px;">
                                    <v-select 
                                        v-model="empWgeItems.bnkCd"                               
                                        :items="bnkList"
                                        item-text="bnkNm"
                                        item-value="bnkCd"
                                        dense hide-details outlined
                                        :rules="[rules.selectCheck]"
                                    />
                                </span>
                            </div>
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">예금주명</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:17%;border-left: 1px solid #dbdbdb !important;">                                     
                                <span class="d-inline-block" style="width: 110px;">
                                    <v-text-field color="blue" v-model="empWgeItems.bnkHld" :rules="[rules.required, rules.strCheck]" hide-details outlined dense></v-text-field>
                                </span>
                            </div>  
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                
                                <span class="black--text font-weight-medium">계좌번호</span>
                            </div>
                            <div class="d-flex justify-start align-center py-1 px-2 ma-0" style="width:21%;border-left: 1px solid #dbdbdb !important;"> 
                                <span class="d-inline-block" style="width: 200px;">
                                    <v-text-field color="blue" v-model="empWgeItems.bnkAcct" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                                </span>
                            </div>                                                      
                        </div>
                        <div class="d-flex align-center mt-3">
                            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                            <span class="text-h6 font-weight-bold">임금명세 정보</span>
                        </div>
                        <div class="d-flex pa-0 ma-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                                <span class="black--text font-weight-medium">임금형태</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-0 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">
                                <span style="width:230px;">
                                    <v-radio-group v-model="empWgeItems.wgeTyp" :rules="[rules.required]" class="pl-2 pt-1" hide-details row >
                                        <v-radio v-for="(code, n) in vCd102" :key="n" class="pa-0 ma-0 mr-2" :ripple="false" :label="code.valcdnm" :value="code.valcd" @click="getWageType"></v-radio>                                                                    
                                    </v-radio-group>
                                </span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">기본급</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                
                                <span class="txtfldRT" style="width:150px">
                                    <v-text-field color="blue" v-model="empWgeItems.wage" @input="getWageclclt" type="number" min="0" hide-details outlined dense :disabled="wageDisabled"></v-text-field>
                                </span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">통상시급</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <span class="txtfldRT" style="width:140px">
                                    <v-text-field color="blue" v-model="empWgeItems.hrlyWage" type="number" min="0" @input="getWageclclt" hide-details outlined dense></v-text-field>
                                </span>
                            </div>
                        </div>
                        <div class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                                <span class="black--text font-weight-medium">금액설정</span>
                                <span v-if="sltEmpPk != -1">
                                    <v-btn 
                                        height="26" 
                                        @click="onModal"
                                        icon>
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </span>
                            </div>
                            <div class="d-flex pa-0 py-1 ma-0" style="width:85%;border-left: 1px solid #dbdbdb !important;">
                                <div class="pl-2" style="width:60%">
                                    <div class="d-flex align-center">
                                        <v-icon style="font-size: 20px;" color="black">mdi-chevron-down</v-icon>
                                        <span class="text-h6">지급총액</span>
                                        <v-spacer></v-spacer>
                                        <span>합계</span>
                                        <span class="px-1">&#58;</span>
                                        <span class="pr-1">{{paymtsTotAmt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}&nbsp;원</span>
                                    </div>
                                    <div :class="['d-flex pa-0', paymtsList.length > 5 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:40%;">
                                            <span class="black--text font-weight-medium">임금항목</span>
                                        </div>                                        
                                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">과세구분</span>
                                        </div>                                        
                                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:40%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">지급액</span>
                                        </div>
                                    </div>
                                    <div v-if="paymtsList.length > 0" style="height:150px;overflow-y:auto"> 
                                        <div v-for="(item, m) in paymtsList" :key="m"  no-gutters>
                                            <div v-if="item.wgeItmClcd != '7'" class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;">
                                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:40%;">
                                                    {{item.itmNm}}
                                                </div>                                        
                                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                                    {{item.txClcdNm}}
                                                </div>                                        
                                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:40%;border-left: 1px solid #dbdbdb !important;">                                                                                                  
                                                    {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}} 원
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="d-flex pa-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                                            <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-2" style="width:40%">
                                    <div class="d-flex align-center">
                                        <v-icon style="font-size: 20px;" color="black">mdi-chevron-down</v-icon>
                                        <span class="text-h6">공제총액</span>
                                        <v-spacer></v-spacer>
                                        <span>합계</span>
                                        <span class="px-1">&#58;</span>
                                        <span class="pr-1">{{ddctnTotAmt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}&nbsp;원</span>
                                    </div>
                                    <div :class="['d-flex pa-0', ddctnList.length > 5 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                            <span class="black--text font-weight-medium">공제항목</span>
                                        </div>                                        
                                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                            <span class="black--text font-weight-medium">공제액</span>
                                        </div>
                                    </div>
                                    <div v-if="ddctnList.length > 0" style="height:150px;overflow-y:auto"> 
                                        <div v-for="(item, m) in ddctnList" :key="m" class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                                {{item.itmNm}}
                                            </div>                                         
                                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">                                                
                                                {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}} 원
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="d-flex pa-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                                            <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                                <div>
                                    <div class="d-flex justify-center black--text font-weight-medium">기타금액설정</div>
                                </div>
                            </div>
                            <div class="d-flex justify-start align-center pa-0 ma-0" style="width:85%;border-left: 1px solid #dbdbdb !important;">
                               <span class="d-inline-block" style="width:300px;">
                                    <v-checkbox v-model="etcPaySetItm.itmVal" label="공휴일 적용" value="1" hide-details></v-checkbox>                             
                                </span>
                            </div>
                        </div> -->
                        <div class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                                <div>
                                    <div class="d-flex justify-center black--text font-weight-medium">장기근속장려금</div>
                                </div>
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">
                               <span class="d-inline-block" style="width: 250px;">
                                <v-select 
                                        v-model="longPayItm.itmVal"                               
                                        :items="vCd80"
                                        item-text="valcdnm"
                                        item-value="valcd"
                                        dense hide-details outlined
                                    /> 
                               </span>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <div>
                                    <div class="d-flex justify-center black--text font-weight-medium">기타</div>
                                </div>
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;"> 
                                <div style="width:200px;">
                                    <v-checkbox
                                        class="clmHisCheck"
                                        v-model="sPayPintItm.itmVal" label="퇴직금 자동생성" :ripple="false"
                                        true-value="1" false-value="2"
                                        :key="forceRender"
                                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                        hide-details>
                                    </v-checkbox>
                                </div>                               
                            </div>
                            <!-- <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                                <div>
                                    <div class="d-flex justify-center black--text font-weight-medium">장기근속 실지금액</div>
                                </div>
                            </div>
                            <div class="d-flex justify-start align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">
                                
                            </div> -->
                        </div>
                        <div class="d-flex pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                                <span class="black--text font-weight-medium">4대보험</span>
                            </div>
                            <div class="justify-center align-center pa-1 ma-0" style="width:85%;border-left: 1px solid #dbdbdb !important;">
                               <div class="d-flex pa-0 ma-0" style="width:100%;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:25%;">
                                        <span class="black--text font-weight-medium">국민연금</span>
                                    </div>
                                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">고용보험</span>
                                    </div>
                                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">건강보험</span>
                                    </div>
                                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">
                                        <span class="black--text font-weight-medium">산재보험</span>
                                    </div>
                               </div>
                               <div class="d-flex pa-0 ma-0" style="width:100%;height:150px;overflow:auto;border-bottom: 1px solid #dbdbdb;" no-gutters>
                                    <div class="pt-1 px-1 ma-0" style="width:25%;">                                        
                                        <v-row class="pb-1" v-for="(list, h) in ntnPenList" :key="h" no-gutters>
                                            <v-col cols="6">
                                                <v-checkbox
                                                    class="clmHisCheck"
                                                    v-model="list.itmVal" :label="list.itmNm" :ripple="false"
                                                    true-value="1" false-value="2"
                                                    :key="forceRender"
                                                    on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                    hide-details>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col class="txtfldRT" cols="6">
                                                <v-text-field v-if="list.itmVal == '1' && list.valClcd=='8'" v-model="list.amt" color="blue" type="number" min="0" hide-details outlined dense></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-row class="pb-1" v-for="(list, h) in empInsList" :key="h" no-gutters>
                                            <v-col cols="6">
                                                <v-checkbox
                                                    class="clmHisCheck"
                                                    v-model="list.itmVal" :label="list.itmNm" :ripple="false"
                                                    true-value="1" false-value="2"
                                                    :key="forceRender"
                                                    on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                    hide-details>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col class="txtfldRT" cols="6">
                                                <v-text-field v-if="list.itmVal == '1' && list.valClcd=='8'" v-model="list.amt" color="blue" type="number" min="0" hide-details outlined dense></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-row class="pb-1" v-for="(list, h) in hltInsList" :key="h" no-gutters>
                                            <v-col cols="6">
                                                <v-checkbox
                                                    class="clmHisCheck"
                                                    v-model="list.itmVal" :label="list.itmNm" :ripple="false"
                                                    true-value="1" false-value="2"
                                                    :key="forceRender"
                                                    on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                    hide-details>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col class="txtfldRT" cols="6">
                                                <v-text-field v-if="list.itmVal == '1' && list.valClcd=='8'" v-model="list.amt" color="blue" type="number" min="0" hide-details outlined dense></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-row class="pb-1" v-for="(list, h) in indstList" :key="h" no-gutters>
                                            <v-col cols="6">
                                                <v-checkbox
                                                    class="clmHisCheck"
                                                    v-model="list.itmVal" :label="list.itmNm" :ripple="false"
                                                    true-value="1" false-value="2"
                                                    :key="forceRender"
                                                    on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                    hide-details>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col class="txtfldRT" cols="6">
                                                <v-text-field v-if="list.itmVal == '1' && list.valClcd=='8'" v-model="list.amt" color="blue" type="number" min="0" hide-details outlined dense></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </v-form>
            </div>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="modal[0]" persistent max-width="1100" min-width="1100"> 
                <EmpWageInfPayMnt
                    ref="empWageInfPayMnt"
                    :empWageInfPayMntPk="empWageInfPayMntPk"
                    @setPayMnt="onPayMnt"
                    @modalClose="onClose"
                ></EmpWageInfPayMnt>   
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';
import EmpWageInfPayMnt from './EmpWageInfPayMnt.vue';
import { selEmpWgeTypViw, selEmpWgeTypList, selEmpWgeItmList, selEmpWgeInfo, insMultiEmpWgeItm } from '../../api/impExp.js';
export default {
    name: 'EmpWageInfTab1',
    
    components: {  
        exBtn,
        EmpWageInfPayMnt
    },

    props : {
    },

    computed: {
        
    },

    created: function(){  

    },

    mounted: function(){
        this.$nextTick(function () {            
            this.getEmpWgeTypViw()
        })
    },

    watch: {
        
    },

    methods: {          
        onLoad:function(){            
            this.getEmpWgeTypViw()
        },
        
        onSave: function(){
            if(this.$refs.tabform.validate()){
                if(this.empWgeItems.dpnNum > 0){

                    let obj = {}
                    obj.fcltyNum = this.$store.getters.getFcltyNum
                    obj.userNm = this.$store.getters.getUserNm
                    obj.empWgePk = this.empWgeItems.empWgePk
                    obj.mmbrPk = this.empWgeItems.mmbrPk
                    obj.empPk = this.empWgeItems.empPk
                    obj.dpnNum = this.empWgeItems.dpnNum
                    obj.dpnNumU20 = this.empWgeItems.dpnNumU20
                    obj.bnkCd = this.empWgeItems.bnkCd
                    obj.bnkNm = ''
                    obj.bnkAcct = this.empWgeItems.bnkAcct
                    obj.bnkHld = this.empWgeItems.bnkHld
                    obj.wage = this.empWgeItems.wage
                    obj.wgeTyp = this.empWgeItems.wgeTyp
                    obj.wgpyDt  = this.empWgeItems.wgpyDt
                    obj.empWgeYn = '1'
                    obj.list = []
    
                    
                    this.bnkList.forEach(item => {
                        if(item.bnkCd == this.empWgeItems.bnkCd){
                            obj.bnkNm = item.bnkNm
                        } 
                    });
    
    
                    this.paymtsList.forEach(item => {
                        obj.list.push(item)
                    });
    
                    this.ddctnList.forEach(item => {
                        obj.list.push(item)
                    });
    
                    obj.list.push(this.etcPaySetItm)
    
                    this.vCd80.forEach(el => {
                        if(this.longPayItm.itmVal == el.valcd) this.longPayItm.amt = el.valdesc
                    });
    
                    obj.list.push(this.longPayItm)
                    obj.list.push(this.sPayPintItm)
    
                    this.ntnPenList.forEach(item => {
                        if(item.itmVal != '1' && item.valClcd=='8') item.amt = 0
                        obj.list.push(item)
                    });
    
                    this.empInsList.forEach(item => {
                        if(item.itmVal != '1' && item.valClcd=='8') item.amt = 0
                        obj.list.push(item)
                    });
    
                    this.hltInsList.forEach(item => {
                        if(item.itmVal != '1' && item.valClcd=='8') item.amt = 0
                        obj.list.push(item)
                    });
    
                    this.indstList.forEach(item => {
                        if(item.itmVal != '1' && item.valClcd=='8') item.amt = 0
                        obj.list.push(item)
                    });
                    
                    this.setMultiEmpWgeItm(obj)
                }else{
                    let res = {
                        statusCode  : 500,
                        message     : '부양가족수 본인 포함 1명 이상 입력해주세요.'
                    }
                    this.$emit('trigger', res)
                }

            }
        },
        setMultiEmpWgeItm: function (obj) {
            insMultiEmpWgeItm(obj)
                .then((response) => ( this.onSaveAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/insMultiEmpWgeItm : ' + error))
        },
        onSaveAfter: function (res) {
            this.$emit('trigger', res)

            if(res.statusCode == 200){                                                                
                this.getEmpWgeInfo()
                this.getEmpWgeTypList()
            }
        },
        
        getEmpWgeTypViw: function () {
            this.empWageInfList.splice(0)
            this.bnkList.splice(0)
            this.vCd42.splice(0)
            this.vCd70.splice(0)
            this.vCd80.splice(0)
            this.vCd102.splice(0)

            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                valClcdS: ['42','70','80','102'],
            }
            selEmpWgeTypViw(obj)
                .then((response) => ( this.getEmpWgeTypViwAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selEmpWgeTypViw : ' + error))
        },
        getEmpWgeTypViwAfter: function (res) {
            if(res.statusCode == 200){   

                if(res.result.code != null){

                    this.vCd42 = res.result.code.vCd42
                    this.vCd70 = res.result.code.vCd70
                    this.vCd80 = res.result.code.vCd80
                    this.vCd102 = res.result.code.vCd102
                    this.vCd42.forEach(code => {
                        this.exBtn[1].expandList.push(code.valcdnm)
                    })

                    this.exBtn[0].expandList.push('전체')

                    this.vCd70.forEach(code => {
                        this.exBtn[0].expandList.push(code.valcdnm)
                    })
                }

                if(res.result.bnkList != null){
                    res.result.bnkList.forEach(el => {
                        this.bnkList.push(el)
                    });
                }

                if(res.result.list != null){

                    res.result.list.forEach(items => {                
                        this.vCd70.forEach(item => {
                            if(items.wrkStcd == item.valcd)
                                items.wrkStcdNm = item.valcdnm
                        });
                        this.empWageInfList.push(items)
                        
                    });
                }

            } else{
                this.$emit('trigger', res)
            }
            // setInterval(this.myCallback, 500)
        },        
        // myCallback:function(){
        //     this.schChkField.target = 'wrkStcdNm'
        //     this.sltElmnt = '재직'
        // },
        getEmpWgeTypList: function () {
            selEmpWgeTypList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getEmpWgeTypListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selEmpWgeTypList : ' + error))
        },
        getEmpWgeTypListAfter: function (res) { 
            this.empWageInfList.splice(0)

            res.forEach(items => {                
                this.vCd70.forEach(item => {
                    if(items.wrkStcd == item.valcd)
                        items.wrkStcdNm = item.valcdnm
                });
                this.empWageInfList.push(items)
                
            });
        },
        getEmpWgeInfo: function () {
            selEmpWgeInfo(this.$store.getters.getFcltyNum, this.sltEmpPk)
                .then((response) => ( this.getEmpWgeInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selEmpWgeInfo : ' + error))
        },
        getEmpWgeInfoAfter: function (res) {
            this.$refs.tabform.resetValidation();
            this.empWgeItems = res

            if(this.empWgeItems.dpnNum == null){
                this.empWgeItems.dpnNum = 1
            }

            if(this.empWgeItems.dpnNumU20 == null){
                this.empWgeItems.dpnNumU20 = 0
            }

            this.getWageclclt()
            this.getEmpWgeItmList()
        },        
        
        getEmpWgeItmList: function () {
            selEmpWgeItmList(this.$store.getters.getFcltyNum, this.sltEmpPk)
                .then((response) => ( this.getEmpWgeItmListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selEmpWgeItmList : ' + error))
        },
        getEmpWgeItmListAfter: function (res) {
            this.$refs.tabform.resetValidation();
            this.clearForm(2)

            let etcList = []

            res.forEach(item => {                
                switch (item.calcClcd) {
                    case '1':
                        item.amt = (item.amt == '' || item.amt == null)?'0':item.amt
                        this.paymtsList.push(item)
                        break;
                    case '2':
                        item.amt = (item.amt == '' || item.amt == null)?'0':item.amt
                        this.ddctnList.push(item)
                        break;
                    case '3':
                        etcList.push(item)
                        break;
                    default:                            
                        break;
                }

                switch (item.txClcd) {
                    case '1':
                        item.txClcdNm = '과세'
                        break;
                    case '2':
                         item.txClcdNm = '비과세'
                        break;
                    default:
                        break;
                }
            });
           
            etcList.forEach(item => {
                switch (item.wgeItmClcd) {
                    case '8':
                        this.ntnPenList.push(item)
                        break;
                    case '9':
                        this.empInsList.push(item)
                        break;
                    case '10':
                        this.hltInsList.push(item)
                        break;
                    case '12':
                        this.indstList.push(item)
                        break;
                    case '7':
                        this.longPayItm     = Object.assign({}, item)
                        break;
                    case '16':
                        this.sPayPintItm    = Object.assign({}, item)
                        break;
                    case '99':
                        this.etcPaySetItm = item
                        break;
                    default:                            
                        break;
                }
            });

            if(this.sPayPintItm.itmVal == undefined || this.sPayPintItm.itmVal == null || this.sPayPintItm.itmVal == ''){
                this.sPayPintItm.itmVal = '1'
            }

            this.ntnPenList.forEach(el => {
                if(el.valClcd == '3' && this.empWgeItems.age >= 60 && el.empWgeItmPk == null)  el.itmVal ='1'
            })

            this.empInsList.forEach(el => {
                if(el.valClcd == '5' && this.empWgeItems.age >= 65 && el.empWgeItmPk == null)  el.itmVal ='1'
            })

            this.getTotCalcl()
            
            this.forceRender += 1

        },   
        tableClickEvent: function(item){
            if(this.sltEmpPk == item.empPk){
                this.sltEmpPk = -1
                this.clearForm(1)
            }
            else{
                this.sltEmpPk = item.empPk
                this.getEmpWgeInfo()
            }

            if(item.wrkStcd == '1') this.$parent.$parent.$parent.$parent.filledBtn[0].disabled = false
            else this.$parent.$parent.$parent.$parent.filledBtn[0].disabled = true
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'wrkStcdNm': this.schChkField.wrkStcdNm.splice(0)
                    sch.forEach((item) => { this.schChkField.wrkStcdNm.push(item) });
                    break;
                case 'jobNm': this.schChkField.jobNm.splice(0)
                    sch.forEach((item) => { this.schChkField.jobNm.push(item) });
                    break;
            }

            this.schDummy = this.schDummy + '.'
        },

        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if(this.schChkField.wrkStcdNm.length != 0){
                this.schChkField.wrkStcdNm.forEach((stcd, i) => {
                    if(stcd == '전체'){
                        col1 = true
                    }else{
                        if(i == 0) col1 = filter.wrkStcdNm.includes(stcd)
                        else col1 = col1 || filter.wrkStcdNm.includes(stcd)
                    }
                    
                });
            }

            let col2 = true
            if(this.schChkField.jobNm.length != 0){
                this.schChkField.jobNm.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.jobNm.includes(stcd)
                    else col2 = col2 || filter.jobNm.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'wrkStcdNm'
                    break;
                case 1: this.schChkField.target = 'jobNm'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        getWageType:function(){            
            this.wageDisabled = false

            switch (this.empWgeItems.wgeTyp) {
                case '1':
                    this.empWgeItems.hrlyWage = Math.round(Number(this.empWgeItems.wage)/209)                    
                    break;
                case '2':
                    this.empWgeItems.wage = this.empWgeItems.hrlyWage
                    this.wageDisabled = true
                    break;  
                default:
                    break;
            }
        },
        getWageclclt:function(){
            
            switch (this.empWgeItems.wgeTyp) {
                case '1':
                    this.empWgeItems.hrlyWage = Math.round(Number(this.empWgeItems.wage)/209)                    
                    break;
                case '2':
                    this.empWgeItems.wage = this.empWgeItems.hrlyWage
                    break;            
                default:
                    break;
            }
        },
        getTotCalcl:function(){
            this.paymtsTotAmt = 0
            this.paymtsList.forEach(el => {
                if(el.wgeItmClcd != '7'){
                    this.paymtsTotAmt += Number(el.amt)
                }
            });

            this.ddctnTotAmt = 0
            this.ddctnList.forEach(el => {
                this.ddctnTotAmt += Number(el.amt)
            });
        },
        clearForm: function(mode){
            this.paymtsList.splice(0)
            this.ddctnList.splice(0)

            this.etcPaySetItm = {}
            this.longPayItm = {}
            if(mode == 1)
                this.empWgeItems = { wgeTyp: '' }
            this.ntnPenList.splice(0)
            this.empInsList.splice(0)
            this.hltInsList.splice(0)
            this.indstList.splice(0)

            this.$refs.tabform.resetValidation();
        },
        onModal:function(){
            this.empWageInfPayMntPk = 1
            this.modal.splice(0,1,true)
        },
        onClose:function(){
            this.empWageInfPayMntPk = -1
            this.modal.splice(0,1,false)
        },
        onPayMnt:function(plist,dlist){
            this.paymtsList = JSON.parse(JSON.stringify(plist))
            this.ddctnList = JSON.parse(JSON.stringify(dlist))
            this.getTotCalcl()
        },
    },

    data: () => ({ 
        forceRender: 0,
        empWageInfPayMntPk:-1,
        modal:[false,],
        wageDisabled:false,
        bnkList:[{bnkCd:'',bnkNm:''}],
        vCd42:[],
        vCd70:[],
        vCd80:[],
        vCd102:[],
        sltElmnt: '',
        schField: '',
        schDummy: '',
        sltEmpPk: -1,
        paymtsTotAmt : 0,
        paymtsList:[],
        ddctnTotAmt : 0,
        ddctnList:[],
        etcPaySetItm:{},
        longPayItm:{},
        ntnPenList:[],
        empInsList:[],
        hltInsList:[],
        indstList:[],
        sPayPintItm:{},      //퇴직적립금 자동생성
        schChkField: { wrkStcdNm: [], jobNm: [], target: '' },
        exBtn: [ 
            { index:  0, btnTitle: '상태', btnIcon: 'house', mode: 'single', exWidth: 94, exHeigth: 120, expandList: [], },
            { index:  1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        headers: [
            { text: '직원명', value: 'name', align: 'center'},
            { text: '상태', value: 'entDt', align: 'center'},
            { text: '직종', value: 'jobNm', align: 'center'},
            { text: '입사일', value: 'entDt', align: 'center'},
            { text: '임금형태', value: 'wgeTypNm', align: 'center'},        
        ],
        empWageInfList:[],
        empWgeItems:{wgeTyp:''},
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
    }),
};
</script>
<style>    
    .EmpWageInfTab1 .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot { padding: 0 6px; }    
</style>