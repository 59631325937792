<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">보호자&nbsp;선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="mt-5" no-gutters>
            <span class="d-inline-block" style="width: 120px; height: 35px;">
                <v-text-field
                    v-model="field"
                    class="ma-0 pa-0 fmlySelector" style=""
                    background-color="white" append-icon="mdi-magnify" label="이름조회"
                    @input="setSearchText()"
                    single-line hide-details rounded outlined filled dense>
                </v-text-field>
            </span>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="greyE01 pa-0" no-gutters>
            <v-col class="txtAlgnMid tableBR py-2" cols="2">
                <span class="black--text font-weight-medium">수급자</span>
            </v-col>
            <v-col cols="10">
                <v-row no-gutters>
                    <v-col class="txtAlgnMid tableBR py-2" cols="3">
                        <span class="black--text font-weight-medium">보호자명</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-2" cols="3">
                        <span class="black--text font-weight-medium">관계</span>
                    </v-col>
                    <v-col class="txtAlgnMid tableBR py-2" cols="4">
                        <span class="black--text font-weight-medium">연락처</span>
                    </v-col>
                    <v-col class="txtAlgnMid py-2" cols="2">
                        <span class="black--text font-weight-medium">적용</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-divider></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 252px;">
            <v-row v-for="(list, i) in bnNameCol" :key="i" class="pa-0" no-gutters>
                <v-col class="d-flex justify-center align-center tableBB tableBR" cols="2">
                    <span class="">{{ list }}</span>
                </v-col>
                <v-col cols="10">
                    <v-row v-for="(list1, j) in fmlyList" :key="j" no-gutters>
                        <v-col class="txtAlgnMid tableBB tableBR pt-2" v-if="list == list1.bnName" cols="3">{{list1.name}}</v-col>
                        <v-col class="overflow-text-hidden txtAlgnMid tableBB tableBR px-1 pt-2" v-if="list == list1.bnName" cols="3">{{list1.rltns}}</v-col>
                        <v-col class="txtAlgnMid tableBB tableBR pt-2" v-if="list == list1.bnName" cols="4">
                            {{list1.celphn_1}}&#45;{{list1.celphn_2}}&#45;{{list1.celphn_3}}
                        </v-col>
                        <v-col class="d-flex justify-center tableBB py-2" v-if="list == list1.bnName" cols="2">
                            <v-btn
                                class="white--text darken-1" style="padding: 1px 0px 0px 0px !important"
                                color="bckgrColor" min-width="30" height="25"
                                @click="onAction(j)"
                                dark small>
                                    <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="bnNameCol.length == 0" class="pa-0" no-gutters>
                <v-col class="txtAlgnMid tableBB py-3" cols="12">
                    <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { getAllFmlyList } from '../../api/index.js';
import { groupBy } from '../../api/common.js';

export default {
    name: 'FmlySelector',

    props : {
        prtBnMmbrPk: { type: Number, default: 0 }
    },
            
    components: {
    
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getFmlyList(this.prtBnMmbrPk)
        })
    },

    computed: {

    },

    watch: {
        'prtBnMmbrPk': function() {
            this.getFmlyList(this.prtBnMmbrPk)
        },

        'fmlyList': function() {
            this.bnNameCol = []
            this.bnColspan = []
            this.bnColList = []

            this.bnColList = groupBy(this.fmlyList, 'bnMmbrPk')

            this.fmlyList.forEach(item => {
                if(this.bnNameCol.length == 0) this.bnNameCol.push(item.bnName)
                else { 
                    if(this.bnNameCol.includes(item.bnName) == false) this.bnNameCol.push(item.bnName)
                }
            })

            this.bnNameCol.forEach(item => {
                let cnt = 0
                this.fmlyList.forEach(bn => {
                    if(item == bn.bnName) cnt += 1
                })
                this.bnColspan.push(cnt)
            })

        },
    },
        
    methods: {
        getFmlyList: function (pk) {
            getAllFmlyList(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.getFmlyListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selAllFmlyList : ' + error))
        },

        getFmlyListAfter: function (res) {

            this.fmlyList = Object.assign([], res)
            this.dump = Object.assign([], res)
          
            //let tmp = this.fmlyList.filter(v => v.name == '김정재')
            //console.log(tmp)

        },

        setSearchText: function () {
          
            //let tmp = this.dump.filter(v => v.name == this.field)
            //let tmp = this.dump.search(this.field)

            let res= []
            this.dump.forEach((sc) => {
                if(sc.name.search(this.field) > -1) res.push(sc)
            })

            if(res.length != 0)
                this.fmlyList = Object.assign([], res)
            else
                this.fmlyList = []

            //if(tmp.length != 0)
                //this.fmlyList = Object.assign([], tmp)
            //else
                //this.fmlyList = []

            if(this.field == '')  this.fmlyList = Object.assign([], this.dump)

        },

        onAction: function (index) {
            this.$emit('outFmly', this.fmlyList[index])
            this.onClose()
        },

        onClose: function () {
            this.$emit('isClose', '.')
        },
    },
    
    data: () => ({
        bnMmbrPk: 0,
        field: '',
        bnNameCol: [],
        bnColspan: [],
        bnColList: [],
        fmlyList: [],
        dump: [],
    }),
};
</script>

<style>
    .fmlySelector .v-input .v-label { line-height: 16px !important; }
    .fmlySelector div div div label { margin-top: 0px; top: 3px !important; }
    .fmlySelector div .v-input__slot { padding: 0 16px !important; }
</style>