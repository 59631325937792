<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
        <v-row no-gutters>
            <v-col cols="6">
                <div>
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">경관영양 관리기록</span>
                        <v-spacer></v-spacer>
                        <span v-if="preBtn[0]">
                            <v-btn 
                                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                                min-width="30" height="25"
                                @click="getPreInfo"
                                small outlined>
                                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                            </v-btn>
                        </span>
                    </div>
                    <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <span class="black--text font-weight-medium">경관영양</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">
                            <span style="width:200px;">
                                <v-radio-group v-model="recItems1.itm1Val" :rules="[rules.required]" hide-details row>
                                    <v-radio v-for="(code, i) in codeItem30" :key="i" :ripple="false" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                </v-radio-group>
                            </span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <div>
                                <div class="d-flex justify-center black--text font-weight-medium">도구 소독 등</div>
                                <div class="d-flex justify-center black--text font-weight-medium">관리기록</div>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">
                            <v-textarea v-model="items.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="15" outlined dense hide-details no-resize>
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                            <span class="black--text font-weight-medium">제공자</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">   
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field v-model="items.wrtr" color="blue" ref="info" readonly
                                    :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="26"  
                                style="padding:0px 1px 0px 2px !important"
                                @click="onModal(0)"
                                >                                    
                                <v-tooltip top >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                    </template>
                                    <span>직원선택</span>
                                </v-tooltip>

                            </v-btn>                     
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="pl-2 ma-0 align-self-start">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">경관식 제공 기록</span>
                        <v-spacer></v-spacer>
                        <span v-if="preBtn[1]">
                            <v-btn 
                                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                                min-width="30" height="25"
                                @click="getPreList"
                                small outlined>
                                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                            </v-btn>
                        </span>
                    </div>
                    <div :class="['d-flex mt-2', nsgCareRcrdList.length > 7 ? 'mr-2__5' : '']" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;font-size:1rem">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:10%;">                                
                            <span class="black--text font-weight-medium">횟수</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:34%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사종류</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사량&#40;ml&#41;</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사시간</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow()">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div style="height:316px;overflow-y:auto;">
                        <div v-if="nsgCareRcrdList.length > 0">
                            <div v-for="(item,m) in nsgCareRcrdList" :key="m" class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;">                                
                                    {{m+1}}
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:34%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="d-inline-block" style="width: 140px;">
                                        <v-select 
                                            v-model="item.itm2Val"                               
                                            :items="codeItem91"
                                            item-text="valcdnm"
                                            item-value="valcd"
                                            :rules="[rules.required]"
                                            dense hide-details outlined
                                        /> 
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span style="width:90px">
                                        <v-text-field color="blue" v-model="item.itm3Val" @focus="item.itm3Val=''" type="number" hide-details outlined dense></v-text-field>
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="white d-inline-block pa-1" style="width: 80px;">                                     
                                        <CmmTimeField v-model="item.ofrTmStrt" :required="true"></CmmTimeField>    
                                    </span>
                                </div>
                                <div  class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                                                   
                                    <v-btn v-if="item.bnftOfrRecPk > 0" icon color='grey006' @click="delRow(m)"> 
                                        <v-icon>mdi-trash-can-outline</v-icon>                                           
                                    </v-btn> 
                                    <v-btn v-else icon color='grey006' @click="delRow(m)"> 
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div> 
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>            
        </v-form>
        <div class="d-flex align-center mt-3">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">경관식 제공 기록 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="225" locale="ko"
                :headers="histHeaders"
                :items="nsgCareRcrdHist" 
                hide-default-header       
                hide-default-footer 
                fixed-header
                dense disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:15%">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:35%">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:35%">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:15%">{{props.headers[3].text}}</th>
                       
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr :class="['fixedCol', $parent.$parent.$parent.$parent.inqYMD == item.recDt ? 'sltRow' : 'disSltRow']"
                        @click="tableClickEvent(item.recDt)">
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.recDt?.beforeDateFormatDot()}}</td> 
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:250px;" class="overflow-text-hidden">{{item.dietRec}}</div>
                        </td>                             
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:250px;" class="overflow-text-hidden">{{item.cntnt}}</div>
                        </td>  
                        <td class="clmsBodyCommon fixedCol0">{{item.wrtr}}</td>            
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div>            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delModelTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div> 
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import { getMultiStcd } from '../../api/index.js';
import { selBnftOfrRecList, selPreBnftOfrRecList, insBnftOfrRecMulti, selNsgstTubeRecList, delAllBnftOfrRec, delBnftOfrRec} from '../../api/bnftrcrd.js';

export default {
    name: 'NsgCareRcrdTab2',
    
    components: {
        EmpSelector,
        btnModalDelete,
        CmmTimeField,

    },

    props : {
        
    },

    created: function(){       
        getMultiStcd(['30','91'], this.$store.getters.getFcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
    },

    mounted: function(){
        
    },

    computed: {
       
    },  

    watch: {
        
    },

    methods: {
        codeMapping: function (res){            
            this.codeItem30 = res.vCd30
            this.codeItem91 = res.vCd91
        },
        onLoad:function(){
            this.frmReset()
            this.getBnftOfrRecList()
            this.getNsgstTubeRecList(0,this.itemsRows)
            this.$refs.tabform.resetValidation()
        },
        frmReset:function(){
            this.$refs.tabform.reset()              
            this.nsgCareRcrdList.splice(0)
            this.items.bnftOfrRecHisPk  = 0
            this.items.bnftOfrRecPk     = 0
            this.items.recDt            = ''
            this.items.itm1Val          = ''
            this.items.cntnt            = ''
            this.items.spclNote         = ''
            this.items.wrtr             = ''
            this.items.wrtrMmbrPk       = 0

            this.recItems1.bnftOfrRecHisPk  = 0
            this.recItems1.bnftOfrRecPk     = 0
            this.recItems1.recDt            = ''
            this.recItems1.wrtr             = ''
            this.recItems1.wrtrMmbrPk       = 0
            this.recItems1.ofrTmStrt        =  ""
            this.recItems1.ofrTmHhStrt      = ""
            this.recItems1.ofrTmMmStrt      = ""
            this.recItems1.itm1Val          =  ""
        },        
        getPreInfo:function(){
            this.pretype = "1"
            this.getPreBnftOfrRecList1()
        },
        getPreList:function(){
            this.pretype = "2"
            this.getPreBnftOfrRecList1()
        },
        getBnftOfrRecList(){

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '2',
                clsfc3Cd: '2',
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }

            selBnftOfrRecList(obj)
            .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
            .catch((error) => console.log(error))
        },      
        getBnftOfrRecListAfter(res){                 
            if(res.length > 0){     
                res.forEach(x=>{
                                 
                    this.items.wrtr = x.hisWrtr
                    this.items.wrtrMmbrPk = x.hisWrtrMmbrPk
                    this.items.cntnt = x.cntnt                    
                    this.items.bnftOfrRecHisPk = x.bnftOfrRecHisPk
                    this.items.itm1Val = x.itm1Val
                    
                    if(x.itm1Cd == '1' && x.itm2Cd =='2' && x.itm3Cd == '3'){
                        let obj = {
                            bnftOfrRecHisPk : x.bnftOfrRecHisPk,
                            bnftOfrRecPk: x.bnftOfrRecPk,
                            recDt:'',
                            clsfc1Cd:'301',
                            clsfc2Cd:'2',
                            clsfc3Cd:'2',
                            ofrTmStrt: '',
                            itm1Cd:"1",
                            itm1Val: x.itm1Val,
                            itm2Cd: "2",
                            itm2Val: x.itm2Val,
                            itm3Cd: "3",
                            itm3Val: x.itm3Val
                        }

                        if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()){
                            obj.ofrTmStrt =  x.ofrTmHhStrt+":"+x.ofrTmMmStrt
                        }

                        this.nsgCareRcrdList.push(obj)
                    }else{
                        this.recItems1.bnftOfrRecHisPk =  x.bnftOfrRecHisPk
                        this.recItems1.bnftOfrRecPk = x.bnftOfrRecPk
                        this.recItems1.itm1Cd = "1"
                        this.recItems1.itm1Val =  x.itm1Val
                    }
                    
                })                
            }

            if(this.nsgCareRcrdList.length > 0) {
                this.preBtn.splice(1,1,false)
            }else{
                this.preBtn.splice(1,1,true)
            }
        }, 

        getPreBnftOfrRecList1(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc1Cd: '301',
                clsfc2Cd: '2',
                clsfc3Cd: '2',
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreBnftOfrRecList1After(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selPreBnftOfrRecList : ' + error))
        },
        getPreBnftOfrRecList1After:function(res){
            if(this.pretype == '2'){
                this.nsgCareRcrdList.splice(0)
            }

            if(res.length > 0){     
                res.forEach(x=>{  

                    if(this.pretype == '1'){                              
                        this.items.wrtr = x.hisWrtr
                        this.items.wrtrMmbrPk = x.hisWrtrMmbrPk
                        this.items.cntnt = x.cntnt             
                        this.items.itm1Val = x.itm1Val         
                    }

                    if(x.itm1Cd == '1' && x.itm2Cd =='2' && x.itm3Cd == '3'){
                        if(this.pretype == '2'){ 
                                
                            let obj = {
                                bnftOfrRecHisPk : 0,
                                bnftOfrRecPk: 0,
                                recDt:'',
                                clsfc1Cd:'301',
                                clsfc2Cd:'2',
                                clsfc3Cd:'2',
                                ofrTmStrt: '',
                                itm1Cd:"1",
                                itm1Val: x.itm1Val,
                                itm2Cd: "2",
                                itm2Val: x.itm2Val,
                                itm3Cd: "3",
                                itm3Val: x.itm3Val
                            }

                            if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()){
                                obj.ofrTmStrt =  x.ofrTmHhStrt+":"+x.ofrTmMmStrt
                            }

                            this.nsgCareRcrdList.push(obj)
                        }
                    }else{
                        if(this.pretype == '1'){                            
                            this.recItems1.itm1Val =  x.itm1Val
                        }
                    }
                    
                })
            }
        },
          
        getNsgstTubeRecList : function(strt, lmt){     
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                rnStrt:strt,
                rnLmt:lmt, 
            }         
            selNsgstTubeRecList(obj)
                .then((response) => ( this.getNsgstTubeRecListAfter(response.data)))
                .catch((error) => console.log(error))                
        },
        getNsgstTubeRecListAfter:function(res){
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)
            this.nsgCareRcrdHist = res.list   
            this.nsgCareRcrdHist.forEach(e => {
                let dietRec = ""
                let n=0
                e.dietRec.forEach(e => {                                        
                    if(n == 0)
                        dietRec = e
                    else
                        dietRec += ", "+e
                    n++
                });
                e.dietRec = dietRec
            });         
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getNsgstTubeRecList(strt,this.itemsRows)
        },
        onSave:function(){
            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){

                if(this.$refs.tabform.validate()) {

                    let obj = {list:[]}
    
                    let recItems = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,                        
                        recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                        wrtr        : this.items.wrtr,
                        wrtrMmbrPk  : this.items.wrtrMmbrPk,
                        cntnt       : this.items.cntnt,
                        spclNote    : this.items.spclNote,
                        clsfc1Cd    : '301',
                        clsfc2Cd    : '2',
                        clsfc3Cd    : '2',
                        listDtl     : []
                    }
    
                    recItems.listDtl.push({
                        itm1Cd : '1',
                        itm1Val : this.recItems1.itm1Val
                    })
    
                    this.nsgCareRcrdList.forEach(x=>{                    
                        let itm = {
                            wrtr        :this.items.wrtr,
                            wrtrMmbrPk  :this.items.wrtrMmbrPk,
                            ofrTmStrt   : "",
                            ofrTmHhStrt : "",
                            ofrTmMmStrt : "",
                            itm1Cd      : "1",
                            itm1Val     : this.recItems1.itm1Val,
                            itm2Cd      : "2",
                            itm2Val     : x.itm2Val,
                            itm3Cd      : "3",
                            itm3Val     : x.itm3Val
                        }
                        if(x.ofrTmStrt?.isEmptyVal()){
                            let ofrTmStrt = x.ofrTmStrt.split(":")
                            itm.ofrTmHhStrt = ofrTmStrt[0]
                            itm.ofrTmMmStrt = ofrTmStrt[1]
                        }
                        
                        recItems.listDtl.push(itm)
                    })
                    obj.list.push(recItems)                
                    this.$parent.$parent.$parent.$parent.progress = true
                    //insMealSrvRcrd -> insBnftOfrRecMulti
                    insBnftOfrRecMulti(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insBnftOfrRecMulti : ' + error))
                    
                }else{
                    let error = {
                        statusCode:500,
                        message:'필수항목을 입력해주세요.'
                    }
                    this.$emit("nsgCareRcrdTrigger",error)
                }
            }else{
                let error = {
                    statusCode:500,
                    message:'수급자를 선택해주세요.'
                }
                this.$emit("nsgCareRcrdTrigger",error)
            }
        },
        onSaveAfter:function(res){
            this.$emit('nsgCareRcrdTrigger', res);
            this.onLoad()
        }, 
        onforce(key){
            this.mtimes1[key] = false
            ++this.forceRender
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk            
        },
        addRow(){
            this.nsgCareRcrdList.push({bnftOfrRecPk:0,clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',ofrTmStrt: "",itm1Cd:"1",itm1Val: "",itm2Cd: "2",itm2Val: "",itm3Cd: "3",itm3Val: ""})
        },
        delAll(){
            this.delModelTitle='경관영양'
            this.type = 'all'
            this.dialogDelete = true
        },
        delRow(key){
            if(this.nsgCareRcrdList[key].bnftOfrRecPk > 0){
                this.delModelTitle='경관식 제공 기록'
                this.idx = key
                this.type = 'rec'
                this.dialogDelete = true
            }else{
                this.nsgCareRcrdList.splice(key,1)
            }
        },
        onDelete:function(res){
            if(res == 9990){
                if(this.type=='all'){
                    if(this.items.bnftOfrRecHisPk > 0){    
                        let fcltyNum = this.$store.getters.getFcltyNum                    
                        let obj = {list:[
                            {
                                fcltyNum:fcltyNum,
                                userNm:this.$store.getters.getUserNm,
                                bnftOfrRecHisPk:this.items.bnftOfrRecHisPk,
                            }

                        ]}              
                        this.$parent.$parent.$parent.$parent.progress = true
                        delAllBnftOfrRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log(error))
                    }
                }else{

                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        bnMmbrPk : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm : this.$store.getters.getUserNm,
                        bnftOfrRecPk : this.nsgCareRcrdList[this.idx].bnftOfrRecPk,
                        bnftOfrRecHisPk:this.nsgCareRcrdList[this.idx].bnftOfrRecHisPk,
                    }
                    this.$parent.$parent.$parent.$parent.progress = true
                    delBnftOfrRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){            
            if(res.statusCode == 200){                   
                if(this.type=='all'){
                    this.$emit('nsgCareRcrdTrigger', res);
                    this.onLoad()
                }else if(this.type == 'rec'){                    
                    this.type = 'all'
                    this.delModelTitle='경관영양'
                    this.idx = 0
                    this.$emit('nsgCareRcrdTrigger', res);
                    this.onLoad()
                }
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false            
        },
        tableClickEvent(value){
            if(value != ''){
                this.$emit("setDate",value)
            }
        },
    },
    data: () => ({
        delModelTitle:'',
        type:'',
        forceRender:0,
        pageCount:0,
        itemsRows: 6,
        page:1,
        items:{bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt : '',itm1Val:'',cntnt:'',spclNote:'',wrtr:'',wrtrMmbrPk:0,},        
        recItems1:{bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt:'',clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',wrtr:'',wrtrMmbrPk:0,ofrTmStrt: "",ofrTmHhStrt:"",ofrTmMmStrt:"",itm1Cd:"1",itm1Val: ""},        
        nsgCareRcrdList:[
            {bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt:'',clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',wrtr:'',wrtrMmbrPk:0,ofrTmStrt: "",ofrTmHhStrt:"",ofrTmMmStrt:"",itm1Cd:"1",itm1Val: "",itm2Cd: "2",itm2Val: "",itm3Cd: "3",itm3Val: ""}
        ],
        nsgCareRcrdHist:[],
        histHeaders: [
            { text: '기록일자',width: '', value: '', align: 'center', },
            { text: '식사종류(식사량 ml)',width: '', value: '', align: 'center', },
            { text: '관리내용',width: '', value: '', align: 'center', },
            { text: '제공자',width: '', value: '', align: 'center', },
        ],
        codeItem30:[],
        codeItem91:[],        
        rtimes1:[false,false,false,false,false,false,false,],
        mtimes1:[false,false,false,false,false,false,false,],
        dialog:[false,],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
        pretype:'',
        preBtn:[true,true]
    }),
};
</script>
<style>
.overflow-text-hidden { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
</style>