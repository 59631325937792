<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">        
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">전자서명</span>
            <v-spacer></v-spacer>                       
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-3">
            <v-row class="pa-0 ma-0" no-gutters>                                     
                <v-col cols="12">                     
                    <div class="mt-0 rounded" style="border: 3px solid #4bc5e8;">
                        <v-img :src="imgSrc" :width="imgWidth"></v-img>
                    </div>
                </v-col>                        
            </v-row>
        </div>      
    </v-sheet>
</template>

<script>
import { remoteURL } from '@/api/baseAxios';

export default {
    name: 'BizMsgSign',

    props : {        
        bizMsgSignPk: { type: Number, default: -1 },
        bzClcd : { type: Number, default: 0 },
        mmbrPk : { type: Number, default: -1 },
    },

    components: {
        
    },

    created: function(){
        
    },
    
    computed: {

    },

    watch: {     
        'bizMsgSignPk':function(value){            
            if(value > -1){
                this.bzPk = value
                this.setSignImg()
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bzPk = this.bizMsgSignPk
            this.setSignImg()
        })
    },
        
    methods: {   
        setSignImg:function(){            
            let path = remoteURL + 'cmm/eSigImgDownload?'
            let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum
            let bzClcd = '&eSigClcd='+ this.bzClcd
            let bzPk = '&bzClcdPk=' + this.bzPk
            let mmbrPk = '&mmbrPk=' + this.mmbrPk
            this.imgSrc = path + fcltyNum + bzClcd + bzPk + mmbrPk + '&t=' + new Date().getTime()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClose: function () {
            this.bzPk = 0
            this.$emit('isClose')
        },        
    },
    
    data: () => ({
        bzPk:0,
        sign: '',
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
        signCanv:false,
        signImg:false,
        imgSrc:'',
        imgWidth:'400',
        dialogDelete:false,
    }),
};
</script>