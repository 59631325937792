<template>
    <v-sheet class="pa-0 ma-0" min-width="960" max-width="960">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="pt-4">
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
        </div>
        <v-row class="pt-2" no-gutters>
            <v-col class="d-flex align-center" cols="5">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">CCTV 침실 촬영 동의</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"  
                @click="preCctvBdrm()"             
                min-width="30" height="25" small outlined>
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center" v-bind="attrs" v-on="on">
                            <v-icon size="20">mdi-content-save-edit-outline</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                        </div>
                    </template>
                    <span>CCTV 침실 촬영 동의 저장</span>
                </v-tooltip>
            </v-btn>
        </v-row>
        <v-form ref="cctvBdrmInfo" lazy-validation>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="py-1 px-2" cols="4">
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp v-model="cctvInfo.wrtDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">동의기간</span>
                </v-col>
                <v-col class="d-flex justify-start align-center py-1 px-2" cols="8">
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp v-model="cctvInfo.agrDtStrt" :required="true"></CmmDateComp>
                    </span>
                    <span class="px-1">&#126;</span>
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp v-model="cctvInfo.agrDtEnd" :required="true"></CmmDateComp>
                    </span>
                </v-col>                
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">동의여부</span>
                </v-col>
                <v-col class="d-flex justify-start align-center py-1 px-2" cols="8">
                    <span class="black--text font-weight-medium" style="width:141px;" >
                        <v-radio-group v-model="cctvInfo.agrYn" hide-details row>
                            <v-radio :ripple="false" label="예" value="1"></v-radio>
                            <v-radio :ripple="false" label="아니오" value="2"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>                
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">보호자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center py-1 px-2" cols="4">
                    {{cctvInfo.agrRcvNm}}
                </v-col>   
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">관계</span>
                </v-col>
                <v-col class="d-flex justify-start align-center py-1 px-2" cols="4">
                    {{cctvInfo.agrRcvRltns}}
                </v-col>                  
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">연락처</span>
                </v-col>
                <v-col class="d-flex justify-start align-center py-1 px-2" cols="8">
                    <span>{{cctvInfo.celphn1}}</span>
                    <span class="px-1">&#45;</span>
                    <span>{{cctvInfo.celphn2}}</span>
                    <span class="px-1">&#45;</span>
                    <span>{{cctvInfo.celphn3}}</span>
                </v-col>                             
            </v-row>
            <v-divider></v-divider>
        </v-form>

        <div class="d-flex align-center mt-6 pr-1">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">전자서명</span>
            <span v-if="cctvInfo.cctvBdrmInfPk==0" class="ml-2">
                <v-icon color="red" size="21">mdi-alert-circle-outline</v-icon>
                <span class="pl-1 fontOneRem font-weight-medium grey006--text">전자서명은 CCTV 침실 촬영 동의 저장 후 이용하실 수 있습니다.</span>
            </span>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="cctvBdrmMsg()"
                :disabled="fmlyBtn.disabled"
                small outlined>
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center" v-bind="attrs" v-on="on">
                            <v-icon size="14">mdi-arrow-expand-right</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">서명요청</span>
                        </div>
                    </template>
                    <span>서명요청발송</span>
                </v-tooltip>
            </v-btn>
        </div>
        <v-row class="pa-0 ma-0 mt-2 mr-1" style="border-top: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" style="border-right: 1px solid #dbdbdb !important;" cols="6">
                <span class="black--text font-weight-medium mr-2" style="font-size: 1.2rem;">수급자</span>
                <v-dialog v-model="dialog[0]" persistent max-width="450">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-1" color="blueBtnColor"
                            min-width="30" height="26" v-bind="attrs" v-on="on"
                            :disabled="bnfcrBtn.disabled"
                            small icon>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon style="padding-top: 1px;" v-bind="attrs" v-on="on" size="24">mdi-pencil-box-outline</v-icon>
                                </template>
                                <span>전자서명</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <SignComp
                        :mmbrPk="this.$store.getters.getBnfcrState.bnmmbr" :bzClcdPk="cctvInfo.cctvBdrmInfPk" :bnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
                        :eSigClcd=12 @modalClose="modalClose(0)" @reload="blobChanger">
                    </SignComp>
                </v-dialog>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="6">
                <span class="black--text font-weight-medium mr-2">보호자</span>
                <v-dialog v-model="dialog[1]" persistent max-width="450">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-1" color="blueBtnColor"
                            min-width="30" height="26" v-bind="attrs" v-on="on"
                            :disabled="fmlyBtn.disabled"
                            small icon>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon style="padding-top: 1px;" v-bind="attrs" v-on="on" size="24">mdi-pencil-box-outline</v-icon>
                                </template>
                                <span>전자서명</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <SignComp
                        :mmbrPk="cctvInfo.grdnMmbrPk" :bzClcdPk="cctvInfo.cctvBdrmInfPk" :bnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
                        :eSigClcd=13 @modalClose="modalClose(1)" @reload="blobChanger">
                    </SignComp>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mr-1" style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb;" no-gutters>
            <v-col class="d-flex justify-center align-center pa-0 ma-0" style="border-right: 1px solid #dbdbdb !important;" cols="6">
                <span class="py-2">
                    <v-img :aspect-ratio="4/3" eager
                        :src="bnfcrSrc.src" width="120" height="90"
                        lazy-src="../../assets/noSignImg.png">
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                    indeterminate color="grey lighten-5">
                                </v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="6">
                <span class="py-2">
                    <v-img :aspect-ratio="4/3" eager
                        :src="fmlySrc.src" width="120" height="90"
                        lazy-src="../../assets/noSignImg.png">
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                    indeterminate color="grey lighten-5">
                                </v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </span>
            </v-col>
        </v-row>

        <v-row class="pt-4" no-gutters>
            <v-col class="d-flex align-center" cols="5">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">CCTV 침실 촬영 동의 내역</span>
            </v-col>
            <v-spacer></v-spacer>            
        </v-row>
        <v-divider :class="['mt-3', cctvBdrmList.length > 2 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', cctvBdrmList.length > 2 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                <span class="black--text font-weight-medium">동의기간</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                <span class="black--text font-weight-medium">동의여부</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">보호자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">관계</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">연락처</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="[cctvBdrmList.length > 2 ? 'mr-2__5' : '']"></v-divider>
        <v-row class="py-4 tableBB" v-if="cctvBdrmList.length == 0" no-gutters>
            <v-col
                style="text-align: center;"
                cols="12">
                <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <div
            class="d-inlice-block overflow-y-auto" style="width: 100%; height: 152px;"
            v-else>
            <v-row class="pa-0 tableBB" v-for="(list, i) in cctvBdrmList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="3">
                    <span class="black--text">{{ list.agrDtStrt?.beforeDateFormatDot() }}</span>
                    <span class="px-1">&#126;</span>
                    <span class="black--text">{{ list.agrDtEnd?.beforeDateFormatDot() }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                    <span class="black--text">{{ list.agrYnNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ list.agrRcvNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ list.agrRcvRltns }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span>{{list.celphn1}}</span>
                    <span class="px-1">&#45;</span>
                    <span>{{list.celphn2}}</span>
                    <span class="px-1">&#45;</span>
                    <span>{{list.celphn3}}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2" cols="2">                     
                    <span class="mr-1">
                        <v-btn color="grey006" height="26" @click="rcrdPrt(list)" icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi-printer-outline</v-icon>
                                    </div>
                                </template>
                                <span>CCTV촬영동의출력</span>
                            </v-tooltip>
                        </v-btn>
                    </span>                   
                    <span>
                        <v-btn @click="removeCctvMng(list.cctvBdrmInfPk)" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'CCTV 침실 촬영 동의'" @madalState="setDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import { galFmlyList, selCctvBdrmList, selCctvBdrmInfo, bnfcrInfoDetail, insCctvBdrmInf, delCctvBdrmInf } from '../../api/bnfcr.js';
import { getBlobSignURL } from '../../api/common.js';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import SignComp from '../../components/commons/SignModel.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

export default {
    name: 'BnfcrCctvBdrmMgmt',
            
    components: {
        expandYearOnlyPicker,
        CmmDateComp,
        SignComp,
        btnModalDelete,
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0){
                this.clearForm()
                this.onShow()
            }
        })
    },

    computed: {
        
    },

    watch:{
        
    },

    destroyed: function(){
        window.URL.revokeObjectURL(this.bnfcrSrc.src)
        window.URL.revokeObjectURL(this.fmlySrc.src)
    },
        
    methods: {
        onShow:function(){
            this.getCctvBdrmList()
        },
        preCctvBdrm: function () {
            
            if(this.cctvInfo.agrRcvNm != ''){                
                let chk = this.$refs.cctvBdrmInfo.validate()
    
                if(chk){

                    let obj = {
                        fcltyNum        : this.$store.getters.getFcltyNum,
                        bnMmbrPk        : this.$store.getters.getBnfcrState.bnmmbr,
                        cctvBdrmInfPk   : this.cctvInfo.cctvBdrmInfPk,
                        grdnMmbrPk      : this.cctvInfo.grdnMmbrPk,
                        agrYyyy         : this.$moment(this.cctvInfo.agrDtStrt, 'YYYY-MM-DD').format('YYYY'),
                        agrYn           : this.cctvInfo.agrYn,
                        agrDtStrt       : this.cctvInfo.agrDtStrt?.afterDateFormatHyp(),
                        agrDtEnd        : this.cctvInfo.agrDtEnd?.afterDateFormatHyp(),
                        agrRcvNm        : this.cctvInfo.agrRcvNm,
                        agrRcvRltns     : this.cctvInfo.agrRcvRltns,
                        celphn1         : this.cctvInfo.celphn1,
                        celphn2         : this.cctvInfo.celphn2,
                        celphn3         : this.cctvInfo.celphn3,
                        userNm          : this.$store.getters.getUserNm,
                        wrtr            : this.cctvInfo.wrtr,
                        wrtDt           : this.cctvInfo.wrtDt?.afterDateFormatHyp(),
                    }     
                    
                    this.setCctvBdrmInf(obj)
                }
            }else{
                this.snackControll([true, 5000, '수급자 관리에서 주보호자를 저장해주세요.', 'warning'])
            }
        },
        getCctvBdrmList: function (){
            selCctvBdrmList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr)
                .then((response) => ( this.getCctvBdrmListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selCctvBdrmList : ' + error))
        },     
        getFmlyList: function (){
            let obj = {
                bnfcr:this.$store.getters.getBnfcrState.bnfcr
            }
            galFmlyList(this.$store.getters.getFcltyNum, obj)
                .then((response) => ( this.getFmlyListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/galFmlyList : ' + error))
        },      
        getCctvBdrmInfo:function(obj){            
            selCctvBdrmInfo(obj)
                .then((response) => ( this.getCctvBdrmInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selCctvBdrmInfo : ' + error))
        },
        getBnfcrInfoDetail:function(){
            let obj = {
                bnmmbr:this.$store.getters.getBnfcrState.bnmmbr,
                bnfcr:this.$store.getters.getBnfcrState.bnfcr
            }
            bnfcrInfoDetail(this.$store.getters.getFcltyNum,obj)
                .then((response) => ( this.getBnfcrInfoDetailAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selCctvBdrmInfo : ' + error))
        },
        setCctvBdrmInf:function(obj){
            insCctvBdrmInf(obj)
                .then((response) => ( this.setCctvBdrmInfAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/insCctvBdrmInf : ' + error))
        },
        setDelete:function(res){
            if(res == 9990){
                if(this.sltCctvIndex > 0){
                    let obj = {
                        fcltyNum :  this.$store.getters.getFcltyNum,
                        cctvBdrmInfPk : this.sltCctvIndex,
                        userNm:this.$store.getters.getUserNm,
                    }

                    delCctvBdrmInf(obj)
                        .then((response) => ( this.setDeleteAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnfcr/delCctvBdrmInf : ' + error))
                }else{
                    this.snackControll([true, 5000, 'CCTV 침실 촬영 동의서 고유번호가 존재하지 않습니다.', 'error'])
                }
            }else if(res == 7){
                this.sltCctvIndex = -1                
                this.dialogDelete = false
            }
        },
        setDeleteAfter:function(res){
             if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                    agrYyyy     : this.year
                }
                this.getCctvBdrmInfo(obj)
                this.getCctvBdrmList()
                this.sltCctvIndex = -1
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.dialogDelete = false
        },
        getCctvBdrmInfoAfter:function(res){
            this.clearForm()
            if(res !='' && res !=null && res !=undefined){
                this.cctvInfo.agrYyyy           = res.agrYyyy
                this.cctvInfo.agrYn             = res.agrYn
                this.cctvInfo.agrDtStrt         = res.agrDtStrt?.beforeDateFormatHyp() 
                this.cctvInfo.agrDtEnd          = res.agrDtEnd?.beforeDateFormatHyp()
                this.cctvInfo.agrRcvNm          = res.agrRcvNm
                this.cctvInfo.agrRcvRltns       = res.agrRcvRltns
                this.cctvInfo.celphn1           = res.celphn1
                this.cctvInfo.celphn2           = res.celphn2
                this.cctvInfo.celphn3           = res.celphn3
                this.cctvInfo.wrtr              = res.wrtr 
                this.cctvInfo.wrtDt             = res.wrtDt?.beforeDateFormatHyp() 
                this.cctvInfo.cctvBdrmInfPk     =  res.cctvBdrmInfPk
                this.cctvInfo.bnMmbrPk          =  res.bnMmbrPk
                this.cctvInfo.grdnMmbrPk        =  res.grdnMmbrPk

                this.bnfcrCntrc = Object.assign({}, this.prtBnfcrCntrc)

                window.URL.revokeObjectURL(this.bnfcrSrc.src)
                window.URL.revokeObjectURL(this.fmlySrc.src)

                this.bnfcrBtn.disabled    = false
                this.fmlyBtn.disabled     = false
            }else{
                this.getFmlyList()
                this.getBnfcrInfoDetail()
                //선택 년도 마지막날
                let lastYearDay = this.$moment(this.year+'1201','YYYYMMDD').endOf('month').format('YYYY-MM-DD')
                this.cctvInfo.agrDtEnd          = lastYearDay
            }

            this.bnfcrSrc = getBlobSignURL(this.$store.getters.getFcltyNum, 12, this.cctvInfo.cctvBdrmInfPk)
            this.fmlySrc = getBlobSignURL(this.$store.getters.getFcltyNum, 13, this.cctvInfo.cctvBdrmInfPk)
            
        },
        getCctvBdrmListAfter: function (res){
            this.cctvBdrmList.splice(0)
            res.forEach(el => {
                if(el.agrYn == '1'){
                    el.agrYnNm = '예'
                }else if(el.agrYn == '2'){
                    el.agrYnNm = '아니오'
                }

                this.cctvBdrmList.push(el)
            });
        },  
        getFmlyListAfter:function(res){
            if(res != undefined && res != '' && res != null){
                this.cctvInfo.grdnMmbrPk    = res.mmbrPk
                this.cctvInfo.agrRcvNm      = res.name
                this.cctvInfo.agrRcvRltns   = res.rltns
                this.cctvInfo.celphn1       = res.celphn_1
                this.cctvInfo.celphn2       = res.celphn_2
                this.cctvInfo.celphn3       = res.celphn_3
            }
        },
        getBnfcrInfoDetailAfter:function(res){
            let entYear = res.entDt.slice(0,4)
            if(this.year == entYear) this.cctvInfo.agrDtStrt = res.entDt?.beforeDateFormatHyp()
            else  this.cctvInfo.agrDtStrt = this.year+'-01-01'
        },
        setCctvBdrmInfAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                    cctvBdrmInfPk     : res.result
                }
                this.getCctvBdrmInfo(obj)
                this.getCctvBdrmList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        clearForm: function () {
            this.cctvInfo.wrtDt = this.$moment().format('YYYY-MM-DD')
            this.cctvInfo.agrYn     = ''
            this.cctvInfo.agrYyyy   = ''
            this.cctvInfo.agrDtStrt = '' 
            this.cctvInfo.agrDtEnd  = ''
            this.cctvInfo.agrRcvNm  = ''
            this.cctvInfo.agrRcvRltns  = ''
            this.cctvInfo.celphn1   = ''
            this.cctvInfo.celphn2   = ''
            this.cctvInfo.celphn3   = ''
            this.cctvInfo.userNm    = '' 
            this.cctvInfo.wrtr      = '' 
            this.cctvInfo.cctvBdrmInfPk =  0
            this.cctvInfo.bnMmbrPk    =  0
            this.cctvInfo.grdnMmbrPk  =  0

            this.bnfcrBtn.disabled    = true
            this.fmlyBtn.disabled     = true

            this.$refs.cctvBdrmInfo.resetValidation()
        },
        tblEvt: function (idx) {
            if(this.sltCctvIndex == idx){
                this.sltCctvIndex = -1                
            } else {
                this.sltCctvIndex = idx

                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                    cctvBdrmInfPk     : this.sltCctvIndex
                }
                
                this.getCctvBdrmInfo(obj)
            }

        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        getYear: function (yyyy){
            this.year = yyyy
             
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bnMmbrPk    : this.$store.getters.getBnfcrState.bnmmbr,
                agrYyyy     : yyyy
            }

            this.getCctvBdrmInfo(obj)
        },
        modalClose: function (index) {
            this.dialog.splice(index, 1, false)
        },
        blobChanger: function (value) {
            if(value == 12){
                this.bnfcrSrc = getBlobSignURL(this.$store.getters.getFcltyNum, 12, this.cctvInfo.cctvBdrmInfPk)
            }
            else if(value == 13){
                this.fmlySrc = getBlobSignURL(this.$store.getters.getFcltyNum, 13, this.cctvInfo.cctvBdrmInfPk)
            }            
        },        
        cctvBdrmMsg:function(){            
            this.$parent.$parent.$parent.bizMsgInfo.bzTitle = 'CCTV 침실 촬영 동의'
            this.$parent.$parent.$parent.bizMsgInfo.docClcd = 23
            this.$parent.$parent.$parent.sendMsgAct(this.cctvInfo.cctvBdrmInfPk)
        },
        rcrdPrt:function(obj){
            this.$parent.$parent.$parent.cnsnFlmnPrt(obj.cctvBdrmInfPk, obj.agrYyyy)
        },
        removeCctvMng:function(pk){
            this.sltCctvIndex = pk
            this.dialogDelete = true
        }
    },
    
    data: () => ({
        year:'',
        menu: [ false, false, ],
        sltCctvIndex: -1,
        cctvBdrmList: [],
        dialog: [false, false, false],
        cctvInfo: { 
            fcltyNum: 0, 
            cctvBdrmInfPk : 0, 
            bnMmbrPk    : 0,
            grdnMmbrPk  : 0,
            agrYyyy     : '',
            agrDtStrt   : '', 
            agrDtEnd    : '',
            agrRcvNm    : '',
            agrRcvRltns : '',
            celphn1     : '',
            celphn2     : '',
            celphn3     : '',
            userNm      : '', 
            wrtr        : '', 
            wrtDt       : '', 
        },
        bnfcrSrc: { src: '', org: '' },
        fmlySrc: { src: '', org: ''},
        bnfcrBtn : {disabled:true},
        fmlyBtn : {disabled:true},
        snackOptions: [ false, 3000, '', 'info' ],
        dialogDelete:false
    }),
};
</script>