<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">투약처방</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left align-center mb-2">
            <v-spacer></v-spacer>
            <span v-if="!progress">
                <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(i)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="list.disabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
            <span v-if="!progress">
                <v-btn
                    class="font-weight-bold ml-2" color="grey006" height="30" style="font-size: 14px;"
                    @click="onClickBtn(1)"
                    rounded outlined>
                    <v-icon class="mr-1" size="18">mdi-printer-outline</v-icon>
                    <span>출력</span>
                </v-btn>                 
            </span>
        </div>
        <v-form ref="tabform2" lazy-validation>   
        <div>
            <div v-if="progress" style="height:649px;">
                <div
                
                    class="d-inline-block"
                    style="width: 100%; height: 38px; text-align: center;">
                    <v-progress-linear
                        class="mb-1" color="yellow darken-2"
                        indeterminate>
                    </v-progress-linear>
                    <span class="greyC00--text font-weight-medium" style="font-size: 0.95rem;">불러오는&nbsp;중...</span>
                </div>            
            </div> 
            <div v-else>
                <div :class="['pa-0 mt-1 d-flex',doseList.length > 7 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;font-size:1rem !important">            
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:12%;">
                        <span class="black--text font-weight-medium">투약명</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">투약량</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">투약방법</span>
                    </div>
                    <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">
                        <v-simple-checkbox color="blue200" v-model="chkAll" @input="setAllDose"></v-simple-checkbox>
                        <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">                           
                            <CmmDateComp v-model="doseStrt"></CmmDateComp>               
                        </span>
                        <span class="px-1">&#126;</span>
                        <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">
                            <CmmDateComp v-model="doseEnd"></CmmDateComp>
                        </span>
                    </div>
                    <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">아침</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                <span class="black--text font-weight-medium">식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">식후</span>
                            </div>
                        </div>
                    </div>
                    <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">점심</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                <span class="black--text font-weight-medium">식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">식후</span>
                            </div>
                        </div>
                    </div>
                    <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">저녁</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                                <span class="black--text font-weight-medium">식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">식후</span>
                            </div>
                        </div>
                    </div>
                    <div class="greyE01 pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                        <div>
                            <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">취침</span></div>
                            <div class="d-flex justify-center align-center"><span class="black--text font-weight-medium">전</span></div>
                        </div>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">투약효능&#47;비고</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">작성자</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                        <v-btn icon class="mr-1" color="blue200" height="26" @click="onModal(0)">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div :class="['',doseList.length > 7 ? 'mdctnField-y-scroll' : '']" style="height:550px;"> 
                    <div v-if="doseList.length > 0">                        
                        <div v-for="(item,i) in doseList" :key="i" class="pa-0 ma-0 d-flex" style="border-bottom: 1px solid #dbdbdb;">            
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:12%;">
                                <v-text-field v-model="item.drugNm" color="blue" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.drugAmt" color="blue" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                                <v-text-field v-model="item.drugMthd" color="blue" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </div>
                            <div class="pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center mt-1" style="width:100%;">
                                    <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">  
                                        <CmmDateComp v-model="item.doseStrt"></CmmDateComp>
                                    </span>
                                    <span class="px-1">&#126;</span>
                                    <span class="white d-inline-block" style="width:130px;border-radius: 10px;">  
                                        <CmmDateComp v-model="item.doseEnd"></CmmDateComp>
                                    </span>
                                    <span>                                
                                        <v-btn 
                                            icon color='grey006'
                                            min-width="30" height="28"
                                            @click="onWeek(i)">
                                            <v-tooltip top >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-calendar-week-outline</v-icon>
                                                </template>
                                                <span>요일별</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </span>
                                </div>
                                <div v-if="item.weekChk" class="d-flex justify-center align-center" style="width:100%;">
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox v-model="item.dsYnMon" hide-details :ripple="false" label="월" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox v-model="item.dsYnTue" hide-details :ripple="false" label="화" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox v-model="item.dsYnWed" hide-details :ripple="false" label="수" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox v-model="item.dsYnThu" hide-details :ripple="false" label="목" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox v-model="item.dsYnFri" hide-details :ripple="false" label="금" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox v-model="item.dsYnSat" hide-details :ripple="false" label="토" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                    <span class="d-inline-block pt-1 pl-1" style="width: 40px;">
                                        <v-checkbox v-model="item.dsYnSun" hide-details :ripple="false" label="일" true-value="1" false-value="2"></v-checkbox>
                                    </span>
                                </div>
                            </div>
                            <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                    
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">
                                        <v-checkbox class="checkIcon" v-model="item.dsBrkfsBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-checkbox class="checkIcon" v-model="item.dsBrkfsAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                   
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">
                                        <!-- <v-simple-checkbox color="blue200" v-model="item.dsLunchBf" ></v-simple-checkbox> -->
                                        <v-checkbox class="checkIcon" v-model="item.dsLunchBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-checkbox class="checkIcon" v-model="item.dsLunchAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                   
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">                                        
                                        <v-checkbox class="checkIcon" v-model="item.dsDinerBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">                                        
                                        <v-checkbox class="checkIcon" v-model="item.dsDinerAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">                                
                                <v-checkbox class="checkIcon" v-model="item.dsBedBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                            <div class="justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                                            
                                <v-text-field v-model="item.drugEfct" hide-details outlined dense placeholder="투약효능"></v-text-field>
                                <v-text-field v-model="item.rmrks" class="mt-2" hide-details outlined dense placeholder="비고"></v-text-field>                            
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                                <span style="width:65px;margin-left:12px;">
                                    <v-text-field v-model="item.wrtr" color="blue" :key="forceRender" hide-details outlined dense></v-text-field>
                                </span>                                
                                    <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="22"
                                    height="22"  
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onEmpModal(i)"
                                    >                                    
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>

                                </v-btn>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                                <v-btn icon color='grey006' @click="delList(i)"> 
                                    <v-icon v-if="item.doseInfPk > 0">mdi-trash-can-outline</v-icon>                                           
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                </v-btn>                            
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                            데이터가 없습니다.
                        </div> 
                    </div>
                </div>
            </div>            
        </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1100">    
                <drugInfMgmt @outDrugInf="addDrugInf" @isClose="isModal(0)"></drugInfMgmt>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import drugInfMgmt from '../../components/commons/DrugInfMgmt.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { selDoseInfList, insDoseInfMulti, delDoseInf } from '../../api/bnftrcrd.js';

export default {
    name: 'MdctnPrscrMdl',

    props : {
        mdctnPrscrMdlPk:{type:Number, default:0},
        inqYMD:{type:String, default:''}
    },
            
    components: {
       CmmDateComp,
       EmpSelector,
       drugInfMgmt,
       btnModalDelete,
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.bnMmbrPk   = this.mdctnPrscrMdlPk
            this.ymd        = this.inqYMD
            this.getDoseInfList()
        })
    },

    computed: {

    },

    watch: {
        'mdctnPrscrMdlPk':function(value){
            if(value > 0){
                this.bnMmbrPk   = value
                this.ymd        = this.inqYMD
                this.getDoseInfList()
            }
        }
    },
        
    methods: {
        onClickBtn:function(key){            
           switch (key) {
            case 0:                
                this.onSave()
                break;
            case 1:
                this.$parent.$parent.$parent.$parent.rprtActCall()     
                break;
            default:
                break;
           }
        },
        getDoseInfList(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.ymd,
            }           
            this.progress = true 
            selDoseInfList(obj)
            .then((response) => ( this.getDoseInfListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getDoseInfListAfter:function(res){
            this.doseList.splice(0)

            res.forEach(e => {                
                e.doseStrt = e.doseStrt?.beforeDateFormatHyp()
                e.doseEnd = e.doseEnd?.beforeDateFormatHyp()
                e.weekChk = false                
                this.doseList.push(e)
            });

            this.progress = false
        },
        onSave:function(){
            let obj = {                            
                list:[],
            }
            
            this.doseList.forEach(e => {
                let item = Object.assign({},e)
                item.doseStrt = item.doseStrt?.afterDateFormatHyp()
                item.doseEnd = item.doseEnd?.afterDateFormatHyp()
                item.fcltyNum = this.$store.getters.getFcltyNum
                item.userNm =this.$store.getters.getUserNm
                item.bnMmbrPk =this.bnMmbrPk
                obj.list.push(item)
            });

            this.progress = true
            insDoseInfMulti(obj)
                .then((response) => ( this.onSaveAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/insDoseInfMulti : ' + error))
        },
        onSaveAfter:function(res){            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit("modelEvent")
                this.getDoseInfList()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }   

            this.progress = false
        },
        delList:function(idx){
           if(this.doseList[idx].doseInfPk > 0){
                this.dialogDelete=true
                this.idx = idx
                this.delTitle = "투약내용" 
            }else{
                this.doseList.splice(idx,1)                
            }
        },
        //기록 DB 삭제
        onDelete:function(res){
            let fcltyNum = this.$store.getters.getFcltyNum    
            let userNm = this.$store.getters.getUserNm
            let bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk

            if(res == 9990){
                this.dialogDelete = false                
                let doseInfPk = this.doseList[this.idx].doseInfPk

                if(doseInfPk > 0){
                    let obj = {
                        fcltyNum: fcltyNum,
                        bnMmbrPk : bnMmbrPk,
                        userNm : userNm,
                        doseInfPk : doseInfPk,
                    }
                    delDoseInf(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delDoseInf : ' + error))
                }else{
                    let error = {
                        statusCode:500,
                        message:'삭제 대상이 존재하지 않습니다.'
                    }
                    this.$emit("hlthBlnftTrigger",error)
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.doseList.splice(this.idx,1)
                this.$emit("modelEvent")
                this.getDoseInfList()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }    

            this.idx = 0
            this.delTitle = ''
        },
        setAllDose:function(){
            if(this.chkAll){
                this.doseList.forEach(e => {
                    e.doseStrt = this.doseStrt
                    e.doseEnd = this.doseEnd
                });
            }
        },
        onModal: function (key) {
            this.dialog.splice(key, 1, true)          
        },
        isModal: function (key) {            
            this.dialog.splice(key, 1, false)
        },
        addDrugInf:function(list){
            list.forEach(el => {                
                this.doseList.push({
                    drugInfPk:el.drugInfPk,
                    doseInfPk:0,
                    drugNm:el.drugNm,
                    drugAmt:'',
                    drugMthd:el.drugMthd,
                    doseYn:false,
                    doseStrt:'',
                    doseEnd:'',
                    dsBrkfsBf:'2',
                    dsBrkfsAf:'2',
                    dsLunchBf:'2',
                    dsLunchAf:'2',
                    dsDinerBf:'2',
                    dsDinerAf:'2',
                    dsBedBf:'2',
                    weekChk:false,
                    dsYnMon:'2',
                    dsYnTue:'2',
                    dsYnWed:'2',
                    dsYnThu:'2',
                    dsYnFri:'2',
                    dsYnSat:'2',
                    dsYnSun:'2',
                    drugEfct:el.drugEfct,
                    rmrks:el.rmrks,
                })
            });
        },
        onWeek:function(idx){
            this.doseList[idx].weekChk = !this.doseList[idx].weekChk
        },
        onEmpModal:function(key){
            this.prtWrtDt = this.doseList[key].doseStrt
            this.idx = key
            this.dialog.splice(1, 1, true)
        },
        getEmpInfo:function(obj){            
            this.doseList[this.idx].wrtr = obj[0].name
            ++this.forceRender
        },
        onClose: function () {
            this.bnMmbrPk = 0
            this.ymd = ''
            this.doseStrt = ''
            this.doseEnd = ''
            this.doseList.splice(0)
            this.$emit('isClose', '.')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({   
        prtWrtDt:'',     
        forceRender:0,
        chkAll:false,
        idx:0,
        bnMmbrPk:0,
        ymd:'',
        doseStrt:'', 
        doseEnd:'',
        doseList:[],
        snackOptions: [false, 3000, '', 'info'],
        dialog:[false,false],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
        ],
        delTitle:'',
        dialogDelete:false,
        progress:false,
    }),
};
</script>
<style>    
    .mdctnField-y-scroll {overflow-y:scroll;}
</style>