import axios from 'axios';
import { store } from '../store.js';

let remoteURL = ''
let remotePORT = 80
let remotePROTOCOL = 'http'

if (process.env.NODE_ENV == 'development') {
    remoteURL = process.env.VUE_APP_DEV_URI
    remotePORT = 80
    remotePROTOCOL = 'http'
}
else if (process.env.NODE_ENV == 'production'){
    remoteURL = process.env.VUE_APP_PROD_URI
    remotePORT = 443
    remotePROTOCOL = 'https'
}

const http = axios.create({
    baseURL: remoteURL,
    timeout: 0,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
    },
    proxy: {
        protocol: remotePROTOCOL,
        host: remoteURL,
        port: remotePORT,
        changeOrigin: true,
    },
})

http.interceptors.request.use(
    (config) => {

        config.headers.Bearer = store.state.authToken
        
        return config;
    },
    (error) => {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error)
    }
);

export{
    http, remoteURL
}
