<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">특이사항&nbsp;선택</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>      
        <v-row class="mt-3 mr-2__5" no-gutters>
            <v-col class="greyE01 py-2" style="text-align:center;" cols="1">
                <span class="black--text font-weight-medium" style="text-align:center;">연번</span>
            </v-col>            
            <v-col class="greyE01 py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="10">
                <span class="black--text font-weight-medium" style="text-align:center;">특이사항</span>
            </v-col>
            <!-- <v-col class="greyE01 py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="4">
                <span class="black--text font-weight-medium" style="text-align:center;">상세내용</span>
            </v-col>
            <v-col class="greyE01 py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="2">
                <span class="black--text font-weight-medium" style="text-align:center;">작성일</span>
            </v-col> -->
            <v-col class="greyE01 py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="1">
                <span class="black--text font-weight-medium" style="text-align:center;">적용</span>
            </v-col>
        </v-row> 
        <div v-if="hlthNrsRecCntntList.length > 0" class="d-inline-block" style="overflow-y:scroll;width: 100%; height: 433.1px">
            <v-row
                class="" style="border-bottom: 1px solid #dbdbdb !important;"
                v-for="(list, i) in hlthNrsRecCntntList" :key="i" no-gutters>
                <v-col class="py-2" style="text-align:center;" cols="1">
                    <span class="" style="text-align:center;">{{i+1}}</span>
                </v-col>
                <v-col class="pa-2" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                    <div v-html="list.cntnt?.replaceAll('\n', '<br />')"></div>
                </v-col>
                <!-- <v-col class="pa-2" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    {{list.spclNote}}
                </v-col>   
                <v-col class="py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="2">
                    <span class="" style="text-align:center;">{{list.recDt?.beforeDateFormatDot()}}</span>
                </v-col>               -->
                <v-col class="py-2" style="text-align:center;border-left: 1px solid #dbdbdb !important;" cols="1">
                    <v-btn
                        v-if="list.wrkTypCd != '2'"
                        class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 2px !important"
                        color="bckgrColor" min-width="30" height="25"
                        @click="onAction(list)"
                        dark small>
                            <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else class="d-inline-block overflow-y-auto" style="width: 100%; height: 433.1px">
            <v-row
                class="" no-gutters>
                <v-col class="grey006--text py-2" style="text-align: center;" cols="12">
                    ※ 조회된 항목이 없습니다.
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { selHlthNrsRecCntntList } from '../../api/bnftrcrd.js';

export default {
    name: 'HlthNrsRecCntntListMdl',

    props : { 
        hlthNrsRecCntntPk : { type: Number, default: 0 },
        bnMmbrPk : { type: Number, default: 0 },
        prtWrtDt: { type: String, default: '' }
    },
            
    components: {        
    },

    created: function(){
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getHlthNrsRecCntntList()
        })
    },

    computed: {

    },

    watch: {
        'hlthNrsRecCntntPk':function(value){
            if(value > 0){
                this.getHlthNrsRecCntntList()
            }
        }
    },
        
    methods: {
        
        onClose: function () {
            this.$emit('isClose', '.')
        },

        getHlthNrsRecCntntList:function(){
            if(this.prtWrtDt !=''){
                selHlthNrsRecCntntList(this.$store.getters.getFcltyNum, this.bnMmbrPk, this.prtWrtDt)
                    .then((response) => ( this.getHlthNrsRecCntntListAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/selHlthNrsRecCntntList : ' + error))
            }else{
                this.hlthNrsRecCntntList.splice(0)
            }
        },

        getHlthNrsRecCntntListAfter:function(res){
            this.hlthNrsRecCntntList.splice(0)
            if(res !='' && res !=null && res != undefined){
                res.forEach(el => {
                    if(el.cntnt?.isEmptyVal()){                    
                        // el.cntnt = el.cntnt?.replaceAll("\n", "<br />")
                        // el.spclNote?.replaceAll("\n", "<br />")
                        this.hlthNrsRecCntntList.push(el)
                    }
                    
                });
            }
        },

        onAction:function(obj){
            this.$emit('outCntnt', obj);
            this.onClose()
        }
    },
    
    data: () => ({
       hlthNrsRecCntntList:[]
    }),
};
</script>