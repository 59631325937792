import { store } from "../store.js"

const config = {
    NODE_MODE: process.env.NODE_ENV,
    DEV_URL: process.env.VUE_APP_DEV_URI,
    PROD_URL: process.env.VUE_APP_PROD_URI
}

const groupBy = function(data, key) {
        return data.reduce(function(carry, el) {
            var group = el[key];

            if (carry[group] === undefined) {
                carry[group] = []
            }

            carry[group].push(el)
            return carry
        }, {})
    }
    /**
     * 깊은 복사를 진행해 새로운 오브젝트를 반환한다.
     * @returns Object
     */
function cloneObject(obj) {
    var clone = {}
    for (var key in obj) {
        if (typeof obj[key] == "object" && obj[key] != null) {
            clone[key] = cloneObject(obj[key])
        } else {
            clone[key] = obj[key]
        }
    }

    return clone;
}

function cmprsDateToNow(dateDot) {
    let now = new Date()
    let cmprs = new Date(dateDot.beforeDateFormatHyp().afterDateFormatDot())
    let result = null

    if (cmprs > now) result = 1
    else if (cmprs < now) result = 2
    else if (cmprs == now) result = 3

    return result
}

function getBlobSignURL(code, eSigClcd, bzClcdPk) {
    let url = '';

    if (config.NODE_MODE == 'development') url = config.DEV_URL
    else if (config.NODE_MODE == 'production') url = config.PROD_URL

    let path = url + 'cmm/eSigImgDownload?'
    let fcltyNum = 'fcltyNum=' + code
    let bzClcd = '&eSigClcd=' + eSigClcd
    let bzPk = '&bzClcdPk=' + bzClcdPk

    let tmp = new Image()
    tmp.src = path + fcltyNum + bzClcd + bzPk + '&t=' + new Date().getTime()

    tmp.crossOrigin = 'Anonymous';

    let canvas = document.createElement("canvas")
    let context = canvas.getContext("2d")

    let me = { src: '', org: tmp.src }

    tmp.onload = function() {
        canvas.width = this.width
        canvas.height = this.height
        context.drawImage(tmp, 0, 0)

        canvas.toBlob((blob) => {
            me.src = URL.createObjectURL(blob)
        })

    }

    tmp.onerror = function() {
        me.src = url + "cmm/eSigImgDownload"
    }

    return me
}

function physicalNameToAlias(pkType) {
    let res = []

    if(pkType == null){
        res.push({ pk: '', value: 0 })
        return res
    }

    pkType.forEach(items => {
        switch(items) {
            case 'EMP_PK': 
                res.push({ pk: 'EMP_PK', value: 0 })
                break;
            case 'LINK_RCRD_PK': 
                res.push({ pk: 'INK_RCRD_PK', value: 0 })
                break;
            case 'COPAY_CLM_HIS_PK': 
                res.push({ pk: 'COPAY_CLM_HIS_PK', value: 0 })
                break;
            case 'FAML_LTR_PK': 
                res.push({ pk: 'FAML_LTR_PK', value: store.getters.getBnfcrState.famlLtrPk })
                break;
            case 'BNFT_OFR_PLN_HIS_PK': 
                res.push({ pk: 'BNFT_OFR_PLN_HIS_PK', value: store.getters.getBnfcrState.bnftOfrPlnHisPk })
                break;
            case 'MMBR_PK': 
                res.push({ pk: 'MMBR_PK', value: store.getters.getBnfcrState.bnmmbr })
                break;
            case 'COPAY_DPS_HIS_PK': 
                res.push({ pk: 'COPAY_DPS_HIS_PK', value: 0 })
                break;
            case 'BNFCR_CNTRC_INF_PK': 
                res.push({ pk: 'BNFCR_CNTRC_INF_PK', value: store.getters.getBnfcrState.bnfcrCntrcInfPk })
                break;
            case 'PHY_THR_PLN_PK': 
                res.push({ pk: 'HY_THR_PLN_PK', value: store.getters.getBnfcrState.phyThrPlnPk })
                break;
            case 'BNFCR_EXAM_HIS_PK': 
                res.push({ pk: 'BNFCR_EXAM_HIS_PK', value: store.getters.getBnfcrState.bnfcrExamHisPk })
                break;
            default:
                res.push({ pk: '', value: 0 })
                break;
        }
    });

    return res
}

export {
    groupBy,
    cloneObject,
    cmprsDateToNow,
    getBlobSignURL,
    physicalNameToAlias,
}