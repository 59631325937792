<template>
    <v-sheet>
        <div class="d-flex align-center">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">급여제공반영 정보</span>
        </div>
        <div class="my-2">
            <v-data-table
                height="300" locale="ko"
                :headers="headers"
                :items="caseBnftList"
                 hide-default-header
                hide-default-footer 
                disable-pagination
                dense
                class="elevation-0" >

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:710px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:90px !important;">{{props.headers[2].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.atndNm}}</td>
                        <td class="clmsBodyCommon tableBR fixedCol0"><div style="width:700px" class="text-overflow">{{item.aftBnftCntnt}}</div></td>
                        <td class="clmsBodyCommon fixedCol0">
                            <v-btn 
                                v-if="item.bnftOfrPlnHisPk > 0"
                                @click="showDialog(item.mmbrPk, item.bnftOfrPlnHisPk)"
                                icon>
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                            <v-btn 
                                v-else
                                @click="showDialog(item.mmbrPk, 0)"
                                icon>
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                        </td>
                    </tr>                            
                </template>
            </v-data-table>
        </div>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">관련자료</span>
        </div>
        <v-row class="pa-0 ma-0 mt-2" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;"> 
            <v-col class="greyE01 d-flex justify-center pa-1 ma-0" cols="6">
                <div class="d-flex align-center" style="width:120px">
                    <span class="black--text font-weight-medium">사진첨부</span>
                    <v-file-input 
                        class="athFileInput"
                        v-model="images" 
                        :accept="imgAccept"
                        dense hide-details truncate-length="5" 
                        multiple counter
                        prepend-icon="mdi-camera-outline"
                        hide-input 
                        @change="upImgFile()"
                        ></v-file-input>
                </div>
            </v-col>
            <v-col class="greyE01 d-flex justify-center pa-1 ma-0" cols="6" style="border-left: 1px solid #dbdbdb !important;">
                <div class="d-flex align-center" style="width:120px">
                    <span class="black--text font-weight-medium">자료첨부</span>
                    <v-file-input 
                        v-model="files" 
                        class="athFileInput"
                        dense hide-details truncate-length="5" 
                        multiple counter
                        prepend-icon="mdi-upload"
                        hide-input                                                                 
                        outlined 
                        @change="upFile()"
                        ></v-file-input>
                </div>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;"> 
            <v-col class="justify-start align-center ma-0 pa-1 overflow-y-auto" cols="6" style="min-height:100px;max-height:270px;">               
                <span class="ma-1 d-inline-block" v-for="(item,i) in imageRes" :key="i">
                    <imageCard 
                        ref="imageCard" 
                        :imgSrc="item.src" 
                        :imgWidth="'120'" 
                        @onDown="fileDownload(i,2)"
                        @isEvent="deleteImg(i)"></imageCard>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center ma-0 overflow-y-auto" cols="6" 
                style="border-left: 1px solid #dbdbdb !important;min-height:100px;max-height:270px;">
                <div style="width:100%;min-height:100px;">                      
                    <v-chip v-for="(item, j) in fileRes" :key="j"                        
                        label                        
                        close
                        class="ma-1"
                        outlined
                        @click="fileDownload(j,1)"
                        @click:close="delfiles(j)"
                    >
                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                            mdi-microsoft-excel
                        </v-icon>
                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                            mdi-file-powerpoint
                        </v-icon>
                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                            mdi-file-word
                        </v-icon>
                        <v-icon v-else color='cyan' left>
                            mdi-file-document
                        </v-icon>
                        {{ item.orgFileNm }}
                    </v-chip> 
                </div>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="title" @madalState="delAtchdFile"></btnModalDelete>  
        </v-dialog>
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="dialogTab" persistent max-width="1400" min-width="1400">                                
                <BnftSrvPlnMdfComp 
                    ref="bnftSrvPlnMdf"
                    :bnMmbrPk="bnMmbrPk" 
                    :inqYYYY="year"
                    :mdfRsncd="'6'"
                    :bnftSrvPlnBzPk="bnftSrvPlnBzPk"
                    :bnftSrvPlnPk="bnftSrvPlnPk"
                    @isClose="modalEvent(8)"
                    @modalEvent="modalEvent"></BnftSrvPlnMdfComp>
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { tmpFileUpload, fileDownload, delAtchdFile } from '../../api/index.js';
import { insMtngRcrdFile } from '../../api/bnftSrv.js';
import { remoteURL } from '@/api/baseAxios';
import imageCard from '../commons/imageCard.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import BnftSrvPlnMdfComp from '../bnftSrv/BnftSrvPlnMdf.vue'; //급여제공계획변경

export default {
    name: 'CaseMgmtTab2',
    
    components: {
        imageCard,
        btnModalDelete,
        BnftSrvPlnMdfComp,
    },

    props : {
        year : {type:Number, default:0}
    },

    computed: {
        
    },

    created: function(){
    },

    mounted: function(){
       
    },

    watch: {
        
    },

    methods: {
        onReset:function(){
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            this.caseBnftList.splice(0)            
        },
        onforce(){
            ++this.forceRender
        },        

        onSave(mtngRcrdPk){    
            
                let obj = {                    
                    fcltyNum : this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                    mtngRcrdPk : mtngRcrdPk,                    
                    files:[],
                }

                if(this.imageRes.length == 0){
                    this.snackControll([true, 5000, "사진을 첨부해주세요.", 'warning'])
                    return
                }

                this.imageRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        obj.files.push(x)
                    }
                })

                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        x.fileKndCd = '1'
                        obj.files.push(x)
                    }
                })

                insMtngRcrdFile(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('caseMgmtTrigger')
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        getlMtngCaseBnftListAfter:function(res){            
            this.caseBnftList = res            
        },

        getAtchdFileListAfter:function(res){            
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            res.forEach(x=>{
                if(x.fileKndCd == "2"){
                    x.src = remoteURL + 'opr/imgView?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                }else{
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]  
                    this.fileRes.push(x)
                }
            })
        },
          
        upFile: function () {            
            let formData = new FormData();
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => ( this.upFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }            
        },
        upFileAfter:function(res){

            res.files.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
            })

            this.files = null
        },
        upImgFile: function () {     
            let formData = new FormData();
            
            if(this.images != null){
                this.images.forEach(element => {
                    formData.append("files", element)
                })

                if(this.images.length > 0){
                    tmpFileUpload(formData)
                        .then((response) => ( this.upImgFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }

        },
        upImgFileAfter:function(res){
            
            res.files.forEach(x=>{
                x.src = remoteURL + 'opr/imgPrview?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                this.imageRes.push(x)
            })       

            this.images = null
        },
        delfiles:function(idx){
            if(this.fileRes[idx].atchdFilePk != undefined){
                this.key = 1
                this.idx = idx
                this.title = '파일'
                this.dialogDelete = true
            }else{
                this.fileRes.splice(idx,1)
            }
        },      
        deleteImg(idx){
            if(this.imageRes[idx].atchdFilePk != undefined){
                this.key = 2
                this.idx = idx
                this.title = '사진'
                this.dialogDelete = true
            }else{
                this.imageRes.splice(idx,1)
            }
        },

        delAtchdFile:function(res){
            if(res == 9990){
                let pk = 0
                if(this.key == 1){
                    pk = this.fileRes[this.idx].atchdFilePk
                }else{
                    pk = this.imageRes[this.idx].atchdFilePk
                }

                if(pk > 0){
                    let fcltyNum  = this.$store.getters.getFcltyNum  
                    delAtchdFile(fcltyNum, pk)
                        .then((response) => ( this.delAtchdFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        delAtchdFileAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.key = 0
                this.idx = 0
                this.$emit('caseMgmtTrigger')
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        
        fileDownload:function(idx, type){
            if(type == 1){
                if(this.fileRes[idx] != undefined){
                    fileDownload(this.fileRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }else{
                    this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
                }
            }else if(type==2){
                if(this.imageRes[idx] != undefined){
                    fileDownload(this.imageRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }else{
                    this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
                }
            }else{
                    this.snackControll([true, 3000, '선택된 파일이 없습니다.', 'warning'])
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        
        showDialog:function(mmbr,value){
            this.dialogTab = true
            this.bnMmbrPk = mmbr    
            this.bnftSrvPlnPk = value        
            this.bnftSrvPlnBzPk = this.$parent.$parent.$parent.$parent.mtngRcrdPk           
        },

        modalEvent:function(event){
            if(event == 99){
                this.$emit('caseMgmtTrigger')
            }else{
                this.bnMmbrPk = 0
                this.bnftSrvPlnPk = -1
                this.bnftSrvPlnBzPk = -1
                this.dialogTab = false
            }  
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },  
    
    data: () => ({ 
        key:0,
        idx:0,        
        bnMmbrPk:0,
        bnftSrvPlnPk:-1,
        bnftSrvPlnBzPk:-1,
        title:'사진',
        files:null,
        fileRes:[],
        images:null, 
        imageRes:[],  
        caseBnftList:[],
        fileRules: [
            value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
        imgAccept:'image/*',        
        forceRender:0,
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
        headers: [
            { text: '수급자', value: '', align: 'center', width: '90', class: 'black--text greyE01 tableHeaderSize'},
            { text: '변경내용', value: 'aplctClcd', align: 'center', width: '300', class: 'black--text greyE01 tableHeaderSize'},
            { text: '처리', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
        ],
        dialogTab:false,
    }),
};
</script>