import { http } from '@/api/baseAxios';

//관찰내역 다건 조회
function selBnfcrObsHisList(code, bnmmbrPk, startdt, enddt) {
    return http.post('othr/selBnfcrObsHisList', { fcltyNum: code, bnMmbrPk: bnmmbrPk, inqYmdStart: startdt, inqYmdEnd:enddt })
}
//관찰내역 저장
function insBnfcrObsHis(obj) {
    return http.post('othr/insBnfcrObsHis', obj)
}
//관찰내역 삭제
function delBnfcrObsHis(obj) {
    return http.post('othr/insBnfcrObsHis', obj)
}
//공지사항 다건 조회
function selNtsBbsList(code) {
    return http.post('ntsBbs/selNtsBbsList', { fcltyNum: code })
}
//공지사항 단건 조회
function selNtsBbsInfo(code, pk) {
    return http.post('ntsBbs/selNtsBbsInfo', { fcltyNum: code, ntcBbsPk: pk})
}
//공지사항 게시글 전후 Pk 조회
function selNtsBbsPreNxtInfo(code, pk) {
    return http.post('ntsBbs/selNtsBbsPreNxtInfo', { fcltyNum: code, ntcBbsPk: pk})
}
//공지사항 저장
function insNtsBbs(obj) {
    return http.post('ntsBbs/insNtsBbs', obj)
}
//공지사항 삭제
function delNtsBbs(obj) {
    return http.post('ntsBbs/delNtsBbs', obj)
}
//전자문서수신대상 다건 조회
function selEDocRcvList(obj) {
    return http.post('othr/selEDocRcvList', obj)
}

//전자문서 문구 다건 조회
function selESndMsgList(obj) {
    return http.post('othr/selESndMsgList', obj)
}

function selESndMsgMgmtList(obj) {
    return http.post('othr/selESndMsgMgmtList', obj)
}

//전자발송정보 저장
function insBizMsgSnd(obj) {
    return http.post('othr/insBizMsgSnd', obj)
}

//전자발송정보 저장(발송 X)
function insBizMsgNoSnd(obj) {
    return http.post('othr/insBizMsgNoSnd', obj)
}

//전자발송정보 저장(SMS)
function insBizMsgSmsSnd(obj) {
    return http.post('othr/insBizMsgSmsSnd', obj)
}

//전자발송문구정보 저장
function insESndMsg(obj) {
    return http.post('othr/insESndMsg', obj)
}

//전자발송문구정보 삭제
function delESndMsg(obj) {
    return http.post('othr/delESndMsg', obj)
}

//전자발송관리 다건 조회
function selESndList(obj) {
    return http.post('othr/selESndList', obj)
}

//전자발송관리 다건 조회(코드포함)
function selCodeESndList(obj) {
    return http.post('othr/selCodeESndList', obj)
}

//전자발송 실패 재전송
function insReESndHis(obj) {
    return http.post('othr/insReESndHis', obj)
}

//도움말 내용 조회
function selHlpInfmInfo(code) {
    return http.post('othr/selHlpInfmInfo', { hlpCd: code })
}

//발송전송 직접입력
function insESnd(obj) {
    return http.post('othr/insESnd', obj)
}

function delESnd(obj) {
    return http.post('othr/delESnd', obj)
}

export {
    selBnfcrObsHisList,
    insBnfcrObsHis,
    delBnfcrObsHis,
    selNtsBbsList,
    insNtsBbs,
    selNtsBbsInfo,
    selNtsBbsPreNxtInfo,
    delNtsBbs,
    selEDocRcvList,
    selESndMsgList,
    selESndMsgMgmtList,
    insBizMsgSnd,
    insBizMsgNoSnd,
    insBizMsgSmsSnd,
    insESndMsg,
    delESndMsg,
    selESndList,
    selCodeESndList,
    insReESndHis,
    selHlpInfmInfo,
    insESnd,
    delESnd
}