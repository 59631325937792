<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
            
            <div class="d-flex">       
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:50%;">
                    <v-row class="pa-0 ma-0" no-gutters style="width:calc(100% - 10px) !important; border-top: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">요소</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                            <span class="black--text font-weight-medium">판정</span>
                        </v-col>
                    </v-row>
                    <div style="height:493px;overflow-y:scroll;">
                        <v-row v-for="(item,i) in groups1" :key="i" 
                            class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 pa-1 ma-0" cols="3">                                
                                <span class="black--text font-weight-medium d-flex justify-start">{{item.itmNm}}</span>
                                <span class="black--text font-weight-light d-flex justify-start" style="font-size:0.8rem !important;">{{item.itmDesc}}</span>
                            </v-col>
                            <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                                <div v-if="item.itmCd=='8'">  
                                    <div class="pl-1 ma-0">
                                        <v-radio-group v-model="itmVal[item.itmCd]" :rules="[rules.required]" hide-details>
                                            <v-radio v-for="(itm, j) in codegroups1[item.itmCd]" :key="j" class="pa-0 ma-0 mr-2"
                                                :ripple="false" :label="itm.itmNm" :value="itm.itmVal"
                                                @click="setScore()">
                                            </v-radio>
                                        </v-radio-group>     
                                    </div>
                                    <div class="pl-1 ma-0" style="border-top: 1px solid #dbdbdb !important;">
                                        <v-radio-group v-model="itmVal['9']" :rules="[rules.required]" hide-details>                                            
                                            <v-radio v-for="(itm, j) in codegroups1['9']" :key="j" 
                                                :ripple="false" :label="itm.itmNm" :value="itm.itmVal"
                                                class="pa-0 ma-0 mr-2"
                                                @click="setScore()"
                                            ></v-radio>
                                        </v-radio-group> 
                                    </div>
                                </div>
                                <div v-else class="pl-1 ma-0">   
                                    <div class="d-inline-block font-weight-light pb-1" v-if="item.itmCd=='6' || item.itmCd=='7'" style="width:100%;font-size:1rem">
                                        (방법: 발을 최대한 근접시켜 검사자가 손바닥을 이용하여<br />피검사자의 흉골 부위를 세 번 가볍게 민다.)
                                    </div>
                                    <v-radio-group v-model="itmVal[item.itmCd]" :rules="[rules.required]" hide-details>
                                        <v-radio v-for="(itm, j) in codegroups1[item.itmCd]" :key="j" class="pa-0 ma-0 mr-2" style="width:100%" 
                                            :ripple="false" :label="itm.itmNm" :value="itm.itmVal"
                                            @click="setScore()">
                                        </v-radio>
                                    </v-radio-group>   
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row class="pa-0 ma-0" no-gutters style="width:calc(100% - 10px) !important;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-start align-center pa-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">균형력 부분점수</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                            {{score1}}/ 15점
                        </v-col>
                    </v-row>
                </div>
                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:50%;">
                    <v-row class="pa-0 mr-2__5" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">                                
                            <span class="black--text font-weight-medium">요소</span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                            <span class="black--text font-weight-medium">판정</span>
                        </v-col>
                    </v-row>
                    <div class="overflow-y-auto" style="height:520px;">
                        <v-row v-for="(item,i) in groups2" :key="i" 
                            class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 pa-1 ma-0" cols="3">                                
                                <span class="black--text font-weight-medium d-flex justify-start">{{item.itmNm}}</span>
                                <span v-if="item.itmDesc != ''" class="black--text font-weight-light d-flex justify-start" style="font-size:0.8rem !important;">{{item.itmDesc}}</span>
                            </v-col>
                            <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">
                                <div v-if="item.itmCd=='12' || item.itmCd=='14'">  
                                    <div class="pl-1 ma-0">
                                        <v-radio-group v-model="itmVal[item.itmCd]" :rules="[rules.required]" hide-details>
                                                <v-radio v-for="(itm, j) in codegroups2[item.itmCd]" :key="j"
                                                    class="pa-0 ma-0 mr-2" :ripple="false" 
                                                    :label="itm.itmNm" :value="itm.itmVal"
                                                    @click="setScore()"
                                                ></v-radio>
                                        </v-radio-group>     
                                    </div>
                                    <div class="pl-1 ma-0" style="border-top: 1px solid #dbdbdb !important;">
                                        <v-radio-group v-model="itmVal[Number(item.itmCd)+1]" :rules="[rules.required]" hide-details>
                                                <v-radio v-for="(itm, j) in codegroups2[Number(item.itmCd)+1]" :key="j" class="pa-0 ma-0 mr-2"
                                                    :ripple="false" :label="itm.itmNm" :value="itm.itmVal"
                                                    @click="setScore()"
                                                ></v-radio>
                                        </v-radio-group> 
                                    </div>
                                </div>
                                <div v-else class="pl-1 ma-0">   
                                    <div class="d-inline-block font-weight-light pb-1" v-if="item.itmCd=='11'" style="font-size:1rem">
                                        &#40;방법: 걸으라는 지시가 있을 때 걸음이 시작되는 상황 평가&#41;
                                    </div>
                                    <div class="d-inline-block font-weight-light pb-1" v-if="item.itmCd=='18'" style="font-size:1rem">
                                        &#40;방법: 20-25cm의 보도를 걷도록 한다.&#41;
                                    </div>
                                    <v-radio-group v-model="itmVal[item.itmCd]" :rules="[rules.required]" hide-details>
                                        <v-radio v-for="(itm, j) in codegroups2[item.itmCd]" :key="j" class="pa-0 ma-0 mr-2" 
                                            :ripple="false" :label="itm.itmNm" :value="itm.itmVal"
                                            @click="setScore()"
                                        ></v-radio>
                                    </v-radio-group>   
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 d-flex justify-start align-center pa-1 ma-0" cols="3">                                
                                <span class="black--text font-weight-medium">걸음걸이 부분점수</span>
                            </v-col>
                            <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                                {{score2}}&#47; 11점
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">                        
                            <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="12">                                
                                총점 &#58; 균형 {{score1}}점 &#43; 걸음걸이 {{score2}}점 = {{totscore}}점&#47;26점 
                                <br />* 총점 26점&#44; 최하 0점&#44; 점수가 높을수록 걸음걸이와 보행 우수
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <v-col class="greyE01 d-flex justify-start align-center pa-1 ma-0" cols="3">                                
                                <span class="black--text font-weight-medium">평가</span>
                            </v-col>
                            <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                                <v-textarea v-model="evltn" class="rmrksArea" no-resize  
                                    rows="12" outlined dense hide-details>
                                </v-textarea> 
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </v-form>    
    </v-sheet>
</template>
<script>

import { insBnfcrExamHis} from '../../api/bnftSrv.js';

export default {
    name: 'PhyscThrpyEvltnTab3',

    props : {
        
    },

    computed: {
        
    },  

    watch:{        
        
    },

    created:function(){  
        
    },

    components: {
    },
    
    mounted: function(){  
    },
        
    methods: {    
        onShow(){
            
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:200px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:90px;"
            }else{
                style="width:100px;"
            }
            return style;
        },   
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        setData(data){  
            this.itmVal.splice(0)
            this.data = data
            this.data.map(x=>{
                x.itmValCd = x.valClcd
                if(x.clsfc3Cd =='999'){
                    this.evltn = x.evltn
                }else{
                    if(x.itmVal != undefined && x.itmVal != ''){
                        this.itmVal[x.itmCd] = x.itmVal
                    }
                }
            })
            
            this.setScore()
            this.$refs.tabform.resetValidation();
        },

        reset(){
            this.$refs.tabform.reset()
        },

        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'106',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                //욕구사정평가 setting
                let examList = []
                this.data.map(x=>{
                    if(x.clsfc3Cd =='999'){
                        x.evltn = this.evltn
                    }else{
                        x.itmVal = this.itmVal[x.itmCd]
                    }

                    examList.push(x)
                });  
                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('physcThrpyEvltnTrigger', res)            
        },    
        
        setScore(){
            this.score1 = 0
            this.score2 = 0
            this.itmVal.forEach((value, key) => {
                let k = Number(key)
                let val = Number(value)

                if(k < 11){
                    this.score1 += val
                }else{
                    this.score2 += val
                }

            })
            
            this.totscore = this.score1 + this.score2

        },

        selExamList:function(){
            //욕구사정평가 setting
            let examList = []
            this.data.map(x=>{
                if(x.clsfc3Cd =='999'){
                    x.evltn = this.evltn
                }else{
                    x.itmVal = this.itmVal[x.itmCd]
                }

                examList.push(x)
            });  

            return examList
        }, 
    },
    
    data: () => ({        
        data:[],
        obj:{},
        itmVal:[],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' || value == undefined) || 'Required.',
        },
        score1:0,
        score2:0,
        totscore:0,
        evltn:'',
        groups1:[
            {itmCd:'1',itmNm:'1. 앉아 있기',itmDesc:'Sitting(in hard, armless chair)'},
            {itmCd:'2',itmNm:'2. 일어나기',itmDesc:'Arising'},
            {itmCd:'3',itmNm:'3. 일어나기 시도',itmDesc:'Attempts to arise'},
            {itmCd:'4',itmNm:'4. 일어선 직후의 균형력(일어선 후 5초 이내)',itmDesc:'Immediate Standing balance'},
            {itmCd:'5',itmNm:'5. 서서 있을 때의 균형력',itmDesc:'Standing balance'},
            {itmCd:'6',itmNm:'6. 살짝 밀기',itmDesc:'Balance with Nudge'},
            {itmCd:'7',itmNm:'7. 눈감은 상태에서 살짝 밀기',itmDesc:'Balance with Eyes closed'},
            {itmCd:'8',itmNm:'8. 360도 회전하기',itmDesc:'Balance with 360 degree turn'},
            {itmCd:'10',itmNm:'9. 앉기(앉을 때의 동작)',itmDesc:'Sitting Down'},
        ],
        groups2:[
            {itmCd:'11',itmNm:'10. 걸음 시작',itmDesc:'Initiation'},
            {itmCd:'12',itmNm:'11. 보폭과 높이',itmDesc:'Step length and height : 우측 발 움직이기'},
            {itmCd:'14',itmNm:'12. 보폭과 높이',itmDesc:'Step length and height : 좌측 발 움직이기'},
            {itmCd:'16',itmNm:'13. 보폭의 대칭성',itmDesc:'Step symmetry'},
            {itmCd:'17',itmNm:'14. 보폭의 연속성',itmDesc:'Step continuity'},
            {itmCd:'18',itmNm:'15. 보도 통과',itmDesc:'Path'},
            {itmCd:'19',itmNm:'16. 몸체의 흔들림',itmDesc:'Trunk'},
            {itmCd:'20',itmNm:'17. 발의 자세',itmDesc:''},
        ],
        codegroups1: {
                '1':[
                    {itmVal:'0',itmNm:'0: 의자에 기대거나 미끄러지듯 앉아 있기'},
                    {itmVal:'1',itmNm:'1: 안정적이고 안전하게 앉아 있기'},
                ],
                '2':[
                    {itmVal:'0',itmNm:'0: 도움 없이 일어나기가 불가능'},
                    {itmVal:'1',itmNm:'1: 팔을 사용하여 일어남'},
                    {itmVal:'2',itmNm:'2: 팔을 사용 않고도 일어남'},
                ],
                '3':[
                    {itmVal:'0',itmNm:'0:도움 없이는 불가능'},
                    {itmVal:'1',itmNm:'1: 두 번 이상의 시도로 가능'},
                    {itmVal:'2',itmNm:'2: 한 번의 시도로 가능'},
                ],
                '4':[
                    {itmVal:'0',itmNm:'0: 일어선 직후와 서 있을 때 불안정, 비틀거리거나 발이 움직이거나 몸체가 흔들림'},
                    {itmVal:'1',itmNm:'1: 지팡이, 보행기, 주변물체 잡으면 안정된 자세 취함'},
                    {itmVal:'2',itmNm:'2: 발을 가까이 모으거나 주변 물체 잡는 것 없이 안정된 자세 취함'},
                ],
                '5':[
                    {itmVal:'0',itmNm:'0: 서 있을 때 불안정'},
                    {itmVal:'1',itmNm:'1: 지팡이, 보행기, 주변물체 잡거나 발을 12인치(약 30cm) 이상 벌리고 서 있으면 안정된 자세 취함'},
                ],
                '6':[
                    {itmVal:'0',itmNm:'0: 넘어지기 시작'},
                    {itmVal:'1',itmNm:'1: 비틀거리거나 주변 물체 잡고 중심 잡음'},
                    {itmVal:'2',itmNm:'2: 흔들리지 않고 안정된 중심 잡음'},
                ],
                '7':[
                    {itmVal:'0',itmNm:'0: 불안정'},
                    {itmVal:'1',itmNm:'1: 안정적'},
                ],
                '8':[
                    {itmVal:'0',itmNm:'0: 연속적으로 발걸음을 옮길 수 없다.'},
                    {itmVal:'1',itmNm:'1: 연속적으로 발걸음을 옮긴다.'},
                ],
                '9':[
                    {itmVal:'0',itmNm:'0: 비틀거리나, 주변 물체를 잡으면서 불안정'},
                    {itmVal:'1',itmNm:'1: 안정적'},
                ],
                '10':[
                    {itmVal:'0',itmNm:'0: 불안전(덥석 주저앉거나, 거리 사정을 못함)'},
                    {itmVal:'1',itmNm:'1: 팔을 이용하거나 동작이 매끄럽지 못함'},
                    {itmVal:'2',itmNm:'2: 안전하고 동작이 매끄러움'},
                ],
            },
            codegroups2: {
                '11':[
                    {itmVal:'0',itmNm:'0: 여러 번 시도 또는 머뭇거리며 걷기 시작'},
                    {itmVal:'1',itmNm:'1: 머뭇거림 없이 걷기 시작'},
                ],
                '12':[
                    {itmVal:'0',itmNm:'0: 우측 발이 지면에 지지하고 있는 좌측 발을 통과하지 못함'},
                    {itmVal:'1',itmNm:'1: 우측 발이 지면에 지지하고 있는 좌측 발을 통과'},
                ],
                '13':[
                    {itmVal:'0',itmNm:'0: 우측 발을 지면으로부터 완전하게 올리지 못함'},
                    {itmVal:'1',itmNm:'1: 지면으로부터 5cm 이상 완전하게 올림'},
                ],
                '14':[
                    {itmVal:'0',itmNm:'0: 좌측 발이 지면에 지지하고 있는 우측 발을 통과하지 못함'},
                    {itmVal:'1',itmNm:'1: 좌측 발이 지면에 지지하고 있는 우측 발을 통과'},
                ],
                '15':[
                    {itmVal:'0',itmNm:'0: 좌측 발을 지면으로부터 완전하게 올리지 못함'},
                    {itmVal:'1',itmNm:'1: 지면으로부터 5cm 이상 완전하게 올림'},
                ],
                '16':[
                    {itmVal:'0',itmNm:'0: 좌, 우 보폭이 동일하지 못해 대칭적이지 않음'},
                    {itmVal:'1',itmNm:'1: 좌, 우 보폭이 동일하여 대칭적'},
                ],
                '17':[
                    {itmVal:'0',itmNm:'0: 좌, 우 걸음 사이 중단되거나 연속적이지 못함'},
                    {itmVal:'1',itmNm:'1: 좌, 우 걸음 사이가 중단되지 않으며 연속적'},
                ],
                '18':[
                    {itmVal:'0',itmNm:'0: 보도를 벗어남'},
                    {itmVal:'1',itmNm:'1: 약간 벗어나거나 보행 보조 기구를 사용'},
                    {itmVal:'2',itmNm:'2: 보행 보조기구 없이 똑바로 걷는다.'},
                ],
                '19':[
                    {itmVal:'0',itmNm:'0: 몸체가 많이 흔들리거나 보행보조기구를 사용'},
                    {itmVal:'1',itmNm:'1: 몸체의 흔들림 없이 안정적'},
                ],
                '20':[
                    {itmVal:'0',itmNm:'0: 걷는 동안 좌, 우측의 발꿈치가 서로 떨어져 있음'},
                    {itmVal:'1',itmNm:'1: 걷는 동안 좌, 우측 발꿈치가 거의 접근하는 자세 보임'},
                ],    
            }


    }),
};
</script>