<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">서류&nbsp;수정</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>

        <basicInfo
            class="mt-4" :bnMmbrPk="$store.getters.getBnfcrState.bnmmbr"
            ref="basicInfo">
        </basicInfo>

        <v-form ref="docMdfInfo" lazy-validation>
            <v-divider class="mt-5"></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">서류명</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 px-2" cols="4">
                    <span class="d-inline-block px-2" style="width: 100%;">
                        <v-text-field v-model="docInfo.docNm" :rules="[rules.required]"
                            color="blue" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="py-1 px-2" cols="4">
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp v-model="docInfo.wrtDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-divider class="mt-5"></v-divider>
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-1" cols="2">
                    <span class="black--text font-weight-medium">자료첨부</span>
                    <span class="d-inline-block ml-1" style="padding-top: 2px;">
                        <v-file-input
                            class="athFileInput" v-model="files" truncate-length="5" @change="upFile()"
                            :disabled="$store.getters.getBnfcrState.bnmmbr == 0 ? true : false"
                            prepend-icon="mdi-upload" multiple counter hide-input outlined dense hide-details>
                        </v-file-input>
                    </span>
                </v-col>
                <v-col class="pa-2" cols="10">
                    <span class="pb-2 pr-2"
                        v-for="(item, j) in fileList" :key="j">
                        <v-chip            
                            class="mt-1" label close outlined
                            @click="athFileDownload(j, '1')" @click:close="delAtchfiles(j)">
                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            <span class="longText">{{ item.orgFileNm }}</span>
                        </v-chip> 
                    </span>
                    <v-divider class="my-2"></v-divider>
                    <div>
                        <span class="d-inline-block mx-1"
                            v-for="( item, i ) in imgList" :key="i">
                            <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)" @onDown="athFileDownload(i, '2')"></imageCard>
                        </span>
                    </div>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 mt-4" no-gutters>
                <v-col class="d-flex justify-center" cols="12">
                    <v-btn
                        class="white--text" color="blueBtnColor" @click="preDocMng()" height="26" samll rounded>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span style="font-size: 1rem;">저장</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>

<script>
import { tmpFileUpload, fileDownload, delAtchdFile, selAtchdFileList } from '../../api/index.js';
import { insDocMng } from '../../api/cmm.js';
import { remoteURL } from '@/api/baseAxios';

import imageCard from '../commons/imageCard.vue';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'EtcDocMdfMdl',

    props : {
   
    },
        
    components: {
        imageCard,
        basicInfo,
        CmmDateComp,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAtchdFileList(this.docInfo.docMngPk)
        })
    },

    computed: {
        getSltDoc: function () {
            return this.$parent.$parent.$parent.$parent.sltDocIndex
        },
    },

    watch:{
        getSltDoc: function (value) {
            if(value == -1){
                this.clearForm()
                this.docInfo = Object.assign({ docNm: '', userNm: '', wrtDt: '', fcltyNum: 0, bzClcd: 26, bzPk: 0, docClcd: 8 })
            }
            else{
                let oObj = Object.assign({}, this.$parent.$parent.$parent.$parent.docMngCntList[value])
                oObj.wrtDt = this.$moment(oObj.wrtDt, 'YYYY.MM.DD').format('YYYY-MM-DD')

                this.docInfo = oObj
                this.getAtchdFileList(this.docInfo.docMngPk)
            }
        },
    },
    
    methods: {
        //첨부파일 목록 조회
        getAtchdFileList: function (pk){
            selAtchdFileList(this.$store.getters.getFcltyNum, 12, pk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
        },
        //첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },
        //첨부파일 다운로드
        athFileDownload: function (index, mode) {
            if(mode == '1'){
                fileDownload(this.fileList[index])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
            else if(mode == '2'){
                fileDownload(this.imgList[index])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error)) 
            }
        },
        //첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
        },
        //서류등록
        setDocMng: function (obj) {
            insDocMng(obj)
                .then((response) => ( this.setDocMngAfter(response.data) ))
                .catch((error) => console.log('upload error /cmm/insDocMng : ' + error))
        },
        getAtchdFileListAfter: function(res){
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            let fList = res.filter(v => v.fileKndCd == '1')
            let iList = res.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgView?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })
        },
        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },
        delAtchdFileAfter: function (res) {
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.getDocMngCntList(this.$store.getters.getBnfcrState.bnmmbr)
                this.getAtchdFileList(this.docInfo.docMngPk)
            }
            else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },
        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            }
            else {
                this.imgList.splice(idx, 1)
            }
        },
        delAtchfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.fileList[idx].atchdFilePk)
            }
            else {
                this.fileList.splice(idx, 1)
            }
        },
        setDocMngAfter: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.getDocMngCntList(this.$store.getters.getBnfcrState.bnmmbr)
                this.$parent.$parent.$parent.$parent.snackControll([true, 3000, res.message, 'info'])
                this.clearForm()
                this.onClose()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preDocMng: function () {
            let obj = Object.assign({}, this.docInfo)
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.bzPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.wrtDt = this.$moment(obj.wrtDt, 'YYYY-MM-DD').format('YYYYMMDD')
            obj.bzClcd = '26'
            obj.docClcd = '8'
            obj.files = []

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.files.push(items)
                }
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    obj.files.push(items)
                }
            })

            this.setDocMng(obj)
        },
        clearForm: function () {
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)
            this.docInfo.docNm = ''
            this.docInfo.wrtDt = ''
        },
        onClose: function () {
            this.$emit('modalClose', '1')
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        files: null,
        fileList: [],
        imgList: [],
        docInfo: { docNm: '', userNm: '', wrtDt: '', fcltyNum: 0, bzClcd: 26, bzPk: 0, docClcd: 8 },
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>