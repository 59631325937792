<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span v-if="routerRocation=='NtyInspJrnls'">5-1. 야간점검&#40;근무&#41;일지</span>
                    <span v-else-if="routerRocation=='DailyDsnfcJrnls'">5-2. 일상소독일지</span>
                    <span v-else-if="routerRocation=='FoodHygnMgmt'">5-3. 식품위생관리</span>
                    <span v-else-if="routerRocation=='NsgEqpmnMgmt'">5-4. 간호비품관리</span>
                    <span v-else-if="routerRocation=='FcsftInspc'">5-5. 시설안전점검</span>
                    <span v-else-if="routerRocation=='InfctCntrlInspc'">5-6. 감염관리점검</span>
                </span>
            </div>
            <div v-if="routerRocation != 'FcsftInspc' && routerRocation != 'InfctCntrlInspc'" class="my-4">
                <expandWeekOnlyPicker :prtDate="sltDate" @nowWeek="getWeek"></expandWeekOnlyPicker>
            </div>
            <div v-else class="my-4">
                <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
            </div>
            <div class="mt-4 ml-3 mr-2 rounded-lg">
                <v-data-table class="py-1" :height="routerRocation != 'FcsftInspc' && routerRocation != 'InfctCntrlInspc' ? '321' : '569'" locale="ko" :headers="headerList" :items="sftyList"
                    disable-pagination fixed-header hide-default-header hide-default-footer dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>
                                <th v-if="props.headers[0].on" :class="props.headers[0].class" :style="props.headers[0].style">{{props.headers[0].text}}</th>
                                <th v-if="props.headers[1].on" :class="props.headers[1].class" :style="props.headers[1].style">{{props.headers[1].text}}</th>
                                <th v-if="props.headers[2].on" :class="props.headers[2].class" :style="props.headers[2].style">{{props.headers[2].text}}</th>
                                <th v-if="props.headers[3].on" :class="props.headers[3].class" :style="props.headers[3].style">{{props.headers[3].text}}</th>
                                <th v-if="props.headers[4].on" :class="props.headers[4].class" :style="props.headers[4].style">{{props.headers[4].text}}</th>
                                <th v-if="props.headers[5].on" :class="props.headers[5].class" :style="props.headers[5].style">{{props.headers[5].text}}</th>
                                <th v-if="props.headers[6].on" :class="props.headers[6].class" :style="props.headers[6].style">{{props.headers[6].text}}</th>
                                <th v-if="props.headers[7].on" :class="props.headers[7].class" :style="props.headers[7].style">{{props.headers[7].text}}</th>
                                <th v-if="props.headers[8].on" :class="props.headers[8].class" :style="props.headers[8].style">{{props.headers[8].text}}</th>
                                <th v-if="props.headers[9].on" :class="props.headers[9].class" :style="props.headers[9].style">{{props.headers[9].text}}</th>
                                <th v-if="props.headers[10].on" :class="props.headers[10].class" :style="props.headers[10].style">{{props.headers[10].text}}</th>
                                <th v-if="props.headers[11].on" :class="props.headers[11].class" :style="props.headers[11].style">
                                    <div class="pt-1">{{props.headers[11].text}}</div>
                                    <div class="pb-1">{{props.headers[11].text2}}</div>
                                </th>
                                <th v-if="props.headers[12].on" :class="props.headers[12].class" :style="props.headers[12].style">
                                    <div class="pt-1">{{props.headers[12].text}}</div>
                                    <div class="pb-1">{{props.headers[12].text2}}</div>
                                </th>
                                <th v-if="props.headers[13].on" :class="props.headers[13].class" :style="props.headers[13].style">
                                    <div class="pt-1">{{props.headers[13].text}}</div>
                                    <div class="pb-1">{{props.headers[13].text2}}</div>
                                </th>
                                <th v-if="props.headers[14].on" :class="props.headers[14].class" :style="props.headers[14].style">
                                    <div class="pt-1">{{props.headers[14].text}}</div>
                                    <div class="pb-1">{{props.headers[14].text2}}</div>
                                </th>
                                <th v-if="props.headers[15].on" :class="props.headers[15].class" :style="props.headers[15].style">
                                    <div class="pt-1">{{props.headers[15].text}}</div>
                                    <div class="pb-1">{{props.headers[15].text2}}</div>
                                </th>
                                <th v-if="props.headers[16].on" :class="props.headers[16].class" :style="props.headers[16].style">
                                    {{props.headers[16].text}}
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr v-if="routerRocation == 'FoodHygnMgmt'" :class="['fixedCol', selectedItem == item.inspDt ? 'sltRow' : 'disSltRow']" @click="tableClickEvent(item.inspDt, item.inspRecPk)">
                            <td class="fixedCol0 tableBT removeTableBB" style="height:40px !important; text-align: center;" rowspan="1">
                                <span class="cmmFontSizeOne">{{item.inspDt}}</span>
                            </td>
                            <td class="fixedCol0 tableBT removeTableBB" style="height:40px !important; text-align: center;" rowspan="1">
                                <span v-if="item.wrtr == null" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{item.wrtr}}</span>
                            </td>
                            <td v-if="item.topDt != ''" class="tableBT removeTableBB" style="height:40px !important; text-align: center;" rowspan="7">
                                <span :class="['cmmFontSizeOne', item.topDt == '미작성' ? 'red--text' : '']">{{item.topDt}}</span>
                            </td>
                            <td v-if="item.topWrtr != ''" class="tableBT removeTableBB" style="height:40px !important; text-align: center;" rowspan="7">
                                <span :class="['cmmFontSizeOne', item.topWrtr == '미작성' ? 'red--text' : '']">{{item.topWrtr}}</span>
                            </td>
                        </tr>
                        <tr v-else-if="routerRocation == 'FcsftInspc'">
                            <td v-if="item.inspCyclText != '' && item.inspCyclText != '연별'"
                                class="" style="height:40px !important; text-align: center;" :rowspan="sftyList.length-1">
                                <span class="cmmFontSizeOne">{{item.inspCyclText}}</span>
                            </td>
                            <td v-else-if="item.inspCyclText == '연별'"
                                class="fixedCol0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.inspCyclText}}</span>
                            </td>
                            <td class="tableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.inspPrdNm}}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd+'01' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;" @click="tableClickEvent(item.inspPrd+'01', item.itmPk1)">
                                <span v-if="typeof item.itm1 == 'undefined'" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm1, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd+'02' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;" @click="tableClickEvent(item.inspPrd+'02', item.itmPk2)">
                                <span v-if="typeof item.itm2 == 'undefined'" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm2, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                            <td
                                v-if="item.inspCyclText == '연별'" class="px-0">
                                <v-row class="pa-0" no-gutters>
                                    <v-spacer></v-spacer>
                                    <v-col
                                        :class="['fixedCol', selectedItem == item.inspPrd+'03' ? 'sltRow' : 'disSltRow']"
                                        style="height: 38px; text-align: end; padding-top: 8px;"
                                        @click="tableClickEvent(item.inspPrd+'03', item.itmPk3)" cols="4">
                                        <span v-if="typeof item.itm3 == 'undefined'" class="cmmFontSizeOne red--text">미작성</span>
                                        <span v-else class="cmmFontSizeOne">{{ $moment(item.itm3, 'YYYY.MM.DD').format('MM. DD') }}</span>
                                    </v-col>
                                    <v-col style="padding-top: 9px;" cols="1">
                                        <v-icon size="17">mdi-slash-forward</v-icon>
                                    </v-col>
                                    <v-col
                                        :class="['fixedCol', selectedItem == item.inspPrd+'04' ? 'sltRow' : 'disSltRow']"
                                        style="height: 38px; text-align: start; padding-top: 8px; padding-left: 3px;"
                                        @click="tableClickEvent(item.inspPrd+'04', item.itmPk5)" cols="4">
                                        <span v-if="typeof item.itm5 == 'undefined'" class="cmmFontSizeOne red--text">미작성</span>
                                        <span v-else class="cmmFontSizeOne">{{ $moment(item.itm5, 'YYYY.MM.DD').format('MM. DD') }}</span>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                </v-row>
                            </td>
                            <td
                                v-else
                                :class="['fixedCol tableBB', selectedItem == item.inspPrd+'03' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;"
                                @click="tableClickEvent(item.inspPrd+'03', item.itmPk3)">
                                <span v-if="typeof item.itm3 == 'undefined'" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm3, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd+'05' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;"
                                @click="item.inspCyclText == '연별' ? '' : tableClickEvent(item.inspPrd+'05', item.itmPk4)"> 
                                <span v-if="item.inspPrd.length > 4">
                                    <span v-if="typeof item.itm4 == 'undefined'" class="cmmFontSizeOne red--text">미작성</span>
                                    <span v-else class="cmmFontSizeOne">{{ $moment(item.itm4, 'YYYY.MM.DD').format('MM. DD') }}</span>
                                </span>
                                <span v-else>
                                    <v-icon size="16">mdi-minus</v-icon>
                                </span>
                            </td>
                        </tr>
                        <tr v-else-if="routerRocation == 'InfctCntrlInspc'">
                            <td v-if="item.inspCyclText != ''" class="tableBB px-1" style="height:40px !important; text-align: center;" :rowspan="item.cnt">
                                <span class="cmmFontSizeOne">{{item.inspCyclText}}</span>
                            </td>
                            <td class="tableBB" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.inspPrdNm}}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd + '01' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;" @click="tableClickEvent(item.inspPrd+'01', item.itmPk1)">
                                <span v-if="item.itm1 == null || item.itm1 == ''" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm1, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd + '02' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;" @click="tableClickEvent(item.inspPrd+'02', item.itmPk2)">
                                <span v-if="item.itm2 == null || item.itm2 == ''" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm2, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd + '03' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;" @click="tableClickEvent(item.inspPrd+'03', item.itmPk3)">
                                <span v-if="item.itm3 == null || item.itm3 == ''" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm3, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd + '04' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;" @click="tableClickEvent(item.inspPrd+'04', item.itmPk4)">
                                <span v-if="item.itm4 == null || item.itm4 == ''" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm4, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                            <td :class="['fixedCol tableBB', selectedItem == item.inspPrd + '05' ? 'sltRow' : 'disSltRow']"
                                style="height:40px !important; text-align: center;" @click="tableClickEvent(item.inspPrd+'05', item.itmPk5)">
                                <span v-if="item.itm5 == null || item.itm5 == ''" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{ $moment(item.itm5, 'YYYY.MM.DD').format('MM. DD') }}</span>
                            </td>
                        </tr>
                        <tr v-else :class="['fixedCol', selectedItem == item.inspDt ? 'sltRow' : 'disSltRow']" @click="tableClickEvent(item.inspDt, item.inspRecPk)">
                            <td v-if="(typeof item.inspDt != 'undefined')" class="fixedCol0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.inspDt}}</span>
                            </td>
                            <td v-if="(typeof item.wrtr != 'undefined')" class="fixedCol0" style="height:40px !important; text-align: center;">
                                <span v-if="item.wrtr == null" class="cmmFontSizeOne red--text">미작성</span>
                                <span v-else class="cmmFontSizeOne">{{item.wrtr}}</span>
                            </td>
                            <td v-if="(typeof item.inspCnt != 'undefined')" class="fixedCol0" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{item.inspCnt}}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <router-view ref="sftyChild"></router-view>
    </v-sheet>
</template>

<script>
import expandWeekOnlyPicker from '../../components/commons/expandWeekOnlyPicker.vue';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

import { selInspDtlSmryList, selInspSmry502List, selSftyInspSmryList, selFoodHygnList, selInfctInspSmryList } from '../../api/sfty.js';

export default {
    name: 'SftyCnfgr',
        
    components: {
        expandWeekOnlyPicker,
        expandYearOnlyPicker,
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.routerRocation = this.$route.name
            this.getTableList()
        })
    },

    computed: {

    },

    watch:{ 
        '$route' (to, from) {
            this.routerRocation = to.name
            this.selectedItem = -1
            console.log('Url Event from: ' + from.name)
        },
        'routerRocation': function (){
            this.headerList.forEach(items => {
                items.on = false
            });
            this.getTableList()
        },
        'date.startDay': function (){
            if(this.sftyList != []){
                let fIndex = this.sftyList.findIndex(v => v.inspDt == this.selectedItem.beforeDateFormatDot())
                if(fIndex != -1){
                    let pkkk = this.sftyList[fIndex].inspRecPk
                    if(pkkk == null)
                        this.sltInspRecPk = 0
                    else
                        this.sltInspRecPk = pkkk
                }
                else
                    this.sltInspRecPk = 0
            }
        },
        'sltInspRecPk': function (){
            if(this.sltInspRecPk == 0)
                this.$refs.sftyChild.clearForm()
        },
        'selectedItem': function (){
            if(this.selectedItem == -1)
                this.$refs.sftyChild.clearForm()
        },
    },
        
    methods: {
        //야간점검일지, 간호비품관리
        getInspDtlSmryList: function (strt, end, cd1, cd2){
            selInspDtlSmryList(this.$store.getters.getFcltyNum, strt, end, cd1, cd2)
                .then((response) => ( this.getListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspDtlSmryList : ' + error))
        },
        //식품위생관리
        getFoodHygnList: function (strt, end){
            selFoodHygnList(this.$store.getters.getFcltyNum, strt, end)
                .then((response) => ( this.getFoodHygnListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selFoodHygnList : ' + error))
        },
        //일상소독일지
        getInspSmryList: function (strt, end, cd1, cd2){
            selInspSmry502List(this.$store.getters.getFcltyNum, strt, end, cd1, cd2)
                .then((response) => ( this.getListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspSmry502List : ' + error))
        },
        //시설안전점검
        getSftyInspSmryList: function (yyyy, cd1){
            selSftyInspSmryList(this.$store.getters.getFcltyNum, yyyy, cd1)
                .then((response) => ( this.getSftyInspSmryListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selSftyInspSmryList : ' + error))
        },
        //감염관리
        getInfctInspSmryList: function (yyyy, cd1, cd2){
            selInfctInspSmryList(this.$store.getters.getFcltyNum, yyyy, cd1, cd2)
                .then((response) => ( this.getInfctInspSmryListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInfctInspSmryList : ' + error))
        },
        getListAfter: function (res){
            if(res != '' && res != null){
                res.forEach(items => {
                    items.inspDt = items.inspDt?.beforeDateFormatDot()
    
                    if(this.selectedItem == items.inspDt){
                        if(items.inspRecPk == null)
                            this.sltInspRecPk = 0
                        else{
                            this.sltInspRecPk = items.inspRecPk
                        }
                    }
    
                    if(items.inspRecPk == null)
                        items.inspRecPk = 0
                });
                this.sftyList = res

                if(this.routerRocation == 'NtyInspJrnls')
                    this.$refs.sftyChild.onLoad()
                else if(this.routerRocation == 'DailyDsnfcJrnls')
                    this.$refs.sftyChild.getInspRecDtllist()
                else if(this.routerRocation == 'NsgEqpmnMgmt')
                    this.$refs.sftyChild.getInspRecDtllist()
            }
        },
        getFoodHygnListAfter: function (res){
            let tmp = res.dsnfclist
            let sch = tmp.filter(v => v.wrtr != null)
            this.sftyList = res.inspclist
            this.sftyList.forEach((items, i) => {
                items.inspDt = items.inspDt?.beforeDateFormatDot()
                if(tmp[i].inspRecPk == null)
                    items.inspRecPkS = 0
                else
                    items.inspRecPkS = tmp[i].inspRecPk
                items.topDt = ''
                items.topWrtr = ''
                items.wrtrS = tmp[i].wrtr
            });

            if(sch.length != 0){
                this.sftyList[0].topDt = sch[0].inspDt?.beforeDateFormatDot()
                this.sftyList[0].topWrtr = sch[0].wrtr
            }
            else{
                this.sftyList[0].topDt = '미작성'
                this.sftyList[0].topWrtr = '미작성'
            }
            this.sftyList.forEach(items => {
                items.inspDt = items.inspDt?.beforeDateFormatDot()
                if(this.selectedItem == items.inspDt){
                    if(items.inspRecPk == null)
                        this.sltInspRecPk = 0
                    else{
                        this.sltInspRecPk = items.inspRecPk
                    }
                }
            });
            this.$refs.sftyChild.onLoad()
        },
        getSftyInspSmryListAfter: function (res){
            if(res.length != 0){
                res.forEach((items, i) => {
                    if( i > 1) items.inspCyclText = ''

                    if(items.inspPrd.length == 6){
                        items.inspPrdNm = items.inspPrd.slice(-2)
                        items.inspPrd = this.$moment(items.inspPrd, 'YYYYMM').format('YYYY.MM')
                    }
                    else if(items.inspPrd.length == 4)
                        items.inspPrdNm = items.inspPrd
                    
                    if(items.itmPk1 == null) items.itmPk1 = 0
                    if(items.itmPk2 == null) items.itmPk2 = 0
                    if(items.itmPk3 == null) items.itmPk3 = 0
                    if(items.itmPk4 == null) items.itmPk4 = 0
                    if(items.itmPk5 == null) items.itmPk5 = 0
                    items.itm1 = items.itm1?.beforeDateFormatDot()
                    items.itm2 = items.itm2?.beforeDateFormatDot()
                    items.itm3 = items.itm3?.beforeDateFormatDot()
                    items.itm4 = items.itm4?.beforeDateFormatDot()
                    items.itm5 = items.itm5?.beforeDateFormatDot()
                });
            }
            this.sftyList = res
        },
        getInfctInspSmryListAfter: function (res){
            let inspType = []
            let spanData = []

            res.forEach(items => {
                if(items.inspPrd.length == 6){
                    items.inspPrdNm = items.inspPrd.slice(-2)
                    items.inspPrd = this.$moment(items.inspPrd, 'YYYYMM').format('YYYY.MM')
                }
                else if(items.inspPrd.length == 4)
                    items.inspPrdNm = items.inspPrd

                items.itm1 = items.itm1?.beforeDateFormatDot()
                items.itm2 = items.itm2?.beforeDateFormatDot()
                items.itm3 = items.itm3?.beforeDateFormatDot()
                items.itm4 = items.itm4?.beforeDateFormatDot()
                items.itm5 = items.itm5?.beforeDateFormatDot()
            });

            res.forEach(items => {
                //점검주기 종류를 추출
                if( inspType.findIndex(v => v == items.inspCyclText) == -1 ) inspType.push(items.inspCyclText)
            });
            
            inspType.forEach(items => {
                //동일 점검주기의 로우 개수를 구함
                let count = res.reduce((cnt, element) => cnt + (items === element.inspCyclText), 0)

                //점검주기를 검색
                let tmp = res.filter(function(item) {
                    return item.inspCyclText == items
                });

                //리스트에 span 정보를 담아 재가공
                tmp.forEach((obj, i) => {
                    if( i == 0 ) obj.cnt = count
                    else obj.inspCyclText = ''
                    spanData.push(obj)
                });
            });

            this.sftyList = spanData
        },
        getTableList: function(){
            switch(this.routerRocation) {
                case 'NtyInspJrnls':
                    this.sftyList.splice(0)
                    this.midAreaWidth = '400'
                    this.headerList.forEach(items => {
                        this.groupList.NtyInspJrnls.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    //this.headerList[this.getTableLastOn()].class = 'black--text bottomBorder cmmFontSizeOne sftyHeader'
                    this.getInspDtlSmryList(this.date.startDay, this.date.endDay, 501, 1)
                    break;
                case 'DailyDsnfcJrnls':
                    this.sftyList.splice(0)
                    this.midAreaWidth = '420'
                    this.headerList.forEach(items => {
                        this.groupList.DailyDsnfcJrnls.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getInspSmryList(this.date.startDay, this.date.endDay, 502, 1)
                    break;
                case 'FoodHygnMgmt':
                    this.sftyList.splice(0)
                    this.midAreaWidth = '400'
                    this.headerList.forEach(items => {
                        this.groupList.FoodHygnMgmt.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getFoodHygnList(this.date.startDay, this.date.endDay)
                    break;
                case 'NsgEqpmnMgmt':
                    this.sftyList.splice(0)
                    this.midAreaWidth = '420'
                    this.headerList.forEach(items => {
                        this.groupList.NsgEqpmnMgmt.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    this.getInspDtlSmryList(this.date.startDay, this.date.endDay, 505, 1)
                    break;
                case 'FcsftInspc':
                    this.sftyList.splice(0)
                    this.midAreaWidth = '560'
                    this.headerList.forEach(items => {
                        this.groupList.FcsftInspc.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    if(this.sltDate != '')
                        this.getSftyInspSmryList(this.sltDate.substring(0, 4), 506)
                    break;
                case 'InfctCntrlInspc':
                    this.sftyList.splice(0)
                    this.midAreaWidth = '600'
                    this.headerList.forEach(items => {
                        this.groupList.InfctCntrlInspc.forEach(element => {
                            if(items.text == element) items.on = true
                        });
                    });
                    if(this.sltDate != '')
                        this.getInfctInspSmryList(this.sltDate.substring(0, 4), 507, 1)
                    break;
                default:
                    break;
            }
        },
        getTableLastOn: function(){
            let obj = this.headerList.filter(v => v.on === true)
            let index = this.headerList.lastIndexOf(obj[obj.length-1])
            return index
        },
        getYear: function (yyyy){
            this.sltDate = yyyy + '0101'
            this.selectedItem = -1
            this.sltInspRecPk = 0
            this.getTableList()
        },
        getWeek: function(ws, we){
            this.date.startDay = ws
            this.date.endDay = we
  
            this.headerList.forEach(items => {
                items.on = false
            });
            this.getTableList()
        },
        tableClickEvent: function (dt, pk){
            this.$refs.sftyChild.sltDate = dt.afterDateFormatDot()
            if(this.selectedItem == -1){
                this.selectedItem = dt
                this.sltInspRecPk = pk
            }
            else if(this.selectedItem != dt){
                this.selectedItem = dt
                this.sltInspRecPk = pk
            }
            else{
                //Form Clear
                this.selectedItem = -1
                this.sltInspRecPk = 0
            }

            if(typeof this.sltInspRecPk != 'number') 
                this.sltInspRecPk = 0

        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        selectedItem: -1,
        routerRocation: '',
        sltDate: '',
        sltInspRecPk: -1,
        sftyList: [],
        date: { startDay: '', endDay: '' },
        midAreaWidth: '400',
        headerList: [
            { text: '점검일', value: 'inspDt', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '점검자', value: 'wrtr', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader', style: 'border-bottom: 2px solid !important;'},
            { text: '소독일', value: '', align: 'center', on: false, sortable: true, width:  '80', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
            { text: '소독자', value: '', align: 'center', on: false, sortable: true, width: '80', class: 'black--text sftyHeader', style: 'border-bottom: 2px solid !important;'},
            { text: '점검횟수', value: 'inspCnt', align: 'center', on: false, sortable: true, width: '90', class: 'black--text sftyHeader', style: 'border-bottom: 2px solid !important;'},
            { text: '간호비품수', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader', style: 'border-bottom: 2px solid !important;'},
            { text: '점검주기', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader pa-3', style: 'border-bottom: 2px solid !important;'},
            { text: '점검시기', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '가스안전', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '전기안전', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '화재예방(정밀/작동)', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '약품', text2: '(분기별1회)', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '정기소독', text2: '(분기별1회)', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '응급의료기기', text2: '(현장평가)', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '의료폐기물', text2: '(수시)', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '감염병관리', text2: '(수시)', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
            { text: '미세먼지', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader px-1', style: 'border-bottom: 2px solid !important;'},
        ],
        groupList:{
            NtyInspJrnls: ['점검일', '점검횟수', '점검자'], DailyDsnfcJrnls: ['소독일', '소독자'],
            FoodHygnMgmt: ['점검일', '점검자', '소독일', '소독자' ], NsgEqpmnMgmt: ['소독일', '소독자', '간호비품수'],
            FcsftInspc: ['점검주기', '점검시기', '가스안전', '전기안전', '화재예방(정밀/작동)','미세먼지'],
            InfctCntrlInspc: ['점검주기', '점검시기', '약품', '정기소독', '응급의료기기', '의료폐기물', '감염병관리'],
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .removeTableBT{ border-top: 0px !important; }
    .removeTableBB{ border-bottom: 0px !important; }
    .removeTableBR{ border-right: 0px !important; }
    .sftyHeader{ text-align: center !important; font-size: 1.1rem !important;}
</style>