import { http } from '@/api/baseAxios';

//야간점검일지, 간호비품관리 기간별 요약 조회
function selInspDtlSmryList(code, strt, end, cd1, cd2) {
    return http.post('sfty/selInspDtlSmryList', { fcltyNum: code, inqYmdStart: strt, inqYmdEnd: end, clsfc1Cd: cd1, clsfc2Cd: cd2 })
}

//일상소독일지 기간별 요약 조회
function selFoodHygnList(code, strt, end) {
    return http.post('sfty/selFoodHygnList', { fcltyNum: code, inqYmdStart: strt, inqYmdEnd: end })
}

//식품위생관리, 일상소독일지 기간별 요약 조회
function selInspSmryList(code, strt, end, cd1, cd2) {
    return http.post('sfty/selInspSmryList', { fcltyNum: code, inqYmdStart: strt, inqYmdEnd: end, clsfc1Cd: cd1, clsfc2Cd: cd2 })
}

//시설안전점검 기간별 요약 조회
function selSftyInspSmryList(code, yyyy, cd1) {
    return http.post('sfty/selSftyInspSmryList', { fcltyNum: code, inqYYYY: yyyy, clsfc1Cd: cd1 })
}

//감염관리 기간별 요약 조회
function selInfctInspSmryList(code, yyyy, cd1, cd2) {
    return http.post('sfty/selInfctInspSmryList', { fcltyNum: code, inqYYYY: yyyy, clsfc1Cd: cd1, clsfc2Cd: cd2 })
}

//야간근무일지 조회
function selInspRecInfo(code, recPk, yyMmDd, cd1, cd2) {
    return http.post('sfty/selInspRecInfo', { fcltyNum: code, inspRecPk: recPk, inqYMD: yyMmDd, clsfc1Cd: cd1, clsfc2Cd: cd2 })
}

//야간점검일지상세 조회
function selInspRecDtllist(code, recPk, yyMmDd, cd1, cd2) {
    return http.post('sfty/selInspRecDtllist', { fcltyNum: code, inspRecPk: recPk, inqYMD: yyMmDd, clsfc1Cd: cd1, clsfc2Cd: cd2 })
}

//야간점검일지 저장
function insInspRecDtl(obj) {
    return http.post('sfty/insInspRecDtl', obj)
}

//야간점검일지, 야간근무일지 삭제
function delInspRecDtl(code, recPk, nm, bzCode = null) {
    return http.post('sfty/delInspRecDtl', { fcltyNum: code, inspRecPk: recPk, userNm: nm, bzClcd:bzCode })
}

//야간근무일지 저장
function insInspRec(obj) {
    return http.post('sfty/insInspRec', obj)
}

//일상소독일지상세 조회
function selInspRecDtl1list(code, recPk, yyMmDd, cd1, cd2, cd3 = null) {
    return http.post('sfty/selInspRecDtl1list', { fcltyNum: code, inspRecPk: recPk, inqYMD: yyMmDd, clsfc1Cd: cd1, clsfc2Cd: cd2, clsfc3Cd: cd3 })
}

//화제예방 정밀 조회
function selFirePrvnInfo(code, recPk, yyMmDd, cd1, cd2) {
    return http.post('sfty/selFirePrvnInfo', { fcltyNum: code, inspRecPk: recPk, inqYMD: yyMmDd, clsfc1Cd: cd1, clsfc2Cd: cd2 })
}

//소화기 현황 삭제
function delInspRecDtlInfo(code, nm, recdtlPk) {
    return http.post('sfty/delInspRecDtlInfo', { fcltyNum: code, userNm: nm, inspRecDtlPk: recdtlPk })
}

//약품점검 목록
function selInspRecDtl507list(code, recPk) {
    return http.post('sfty/selInspRecDtl507list', { fcltyNum: code, inspRecPk: recPk })
}

//일상소독일지 상세 조회
function selInspRecInfDtl(code, recPk, yyMmDd, cd1, cd2, cd3 = null) {
    return http.post('sfty/selInspRecInfDtl', { fcltyNum: code, inspRecPk: recPk, inqYMD: yyMmDd, clsfc1Cd: cd1, clsfc2Cd: cd2, clsfc3Cd: cd3 })
}

//일상소독일지 기간별 요약 조회
function selInspSmry502List(code, strt, end, cd1, cd2) {
    return http.post('sfty/selInspSmry502List', { fcltyNum: code, inqYmdStart: strt, inqYmdEnd: end, clsfc1Cd: cd1, clsfc2Cd: cd2 })
}

export {
    selInspDtlSmryList,
    selFoodHygnList,
    selInspSmryList,
    selSftyInspSmryList,
    selInfctInspSmryList,
    selInspRecInfo,
    selInspRecDtllist,
    insInspRecDtl,
    delInspRecDtl,
    insInspRec,
    selInspRecDtl1list,
    delInspRecDtlInfo,
    selInspRecDtl507list,
    selInspRecInfDtl,
    selInspSmry502List,
    selFirePrvnInfo,
}