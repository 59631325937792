<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">7-4. 교육&nbsp;및&nbsp;훈련일지</span>
            </v-col>
            <v-col class="d-flex pr-2" cols="7">
                <v-spacer></v-spacer>
                <!-- btn area -->
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue' && list.visible"
                        v-model="dialog[i]" :max-width="list.width" content-class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                            <div v-else>개발중</div>
                    </v-dialog>
                </span>
                <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                    <v-dialog
                        v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <div>개발중</div>  
                    </v-dialog>
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-5" no-gutters>
            <v-col cols="12">
                <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            </v-col>
        </v-row>
        <v-row class="mt-2" no-gutters>
            <v-col cols="12">
                <v-tabs hide-slider v-model="tab"  color="white" class="black--text mt-2"
                    active-class="bodyColor" center-active>
                    <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab" style="height:670px">            
                    <v-tab-item eager transition="fade-transition">
                        <edctnJrnlEmpRprt 
                            ref="edctnJrnlEmpRprt" @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"></edctnJrnlEmpRprt>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <edctnJrnlSilvCareRprt 
                            ref="edctnJrnlSilvCareRprt" @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"></edctnJrnlSilvCareRprt>
                    </v-tab-item>     
                    <v-tab-item eager transition="fade-transition">
                        <edctnJrnlDssRes 
                            ref="edctnJrnlDssRes" @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"></edctnJrnlDssRes>
                    </v-tab-item>       
                    <!-- <v-tab-item eager transition="fade-transition">
                        <edctnJrnlInfrm 
                            ref="edctnJrnlInfrm" @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"></edctnJrnlInfrm>                        
                    </v-tab-item>        -->
                    <v-tab-item eager transition="fade-transition">
                        <empwrMmt 
                            ref="empwrMmt"></empwrMmt>                        
                    </v-tab-item> 
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import edctnJrnlEmpRprt from '../../components/emp/EdctnJrnlEmpRprt.vue';
import edctnJrnlSilvCareRprt from '../../components/emp/EdctnJrnlSilvCareRprt.vue';
import edctnJrnlDssRes from '../../components/emp/EdctnJrnlDssRes.vue';
// import edctnJrnlInfrm from '../../components/emp/EdctnJrnlInfrm.vue';
import empwrMmt from '../../components/emp/EmpwrMmt.vue';

export default {
    name: 'EdctnJrnl',
        
    components: {
        expandYearOnlyPicker,
        edctnJrnlEmpRprt,
        edctnJrnlSilvCareRprt,
        edctnJrnlDssRes,
        // edctnJrnlInfrm,
        empwrMmt,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 
        // 'tab':function(value){
        //     if(value == 0 || value == 1 || value == 2){
        //         this.filledBtn[2].visible = true
        //         this.filledBtn[2].disabled = false
        //     } else {
        //         this.filledBtn[2].visible = false
        //         this.filledBtn[2].disabled = true
        //     }
        // }
    },
        
    methods: {
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onReset()
                    break;      
                case 1:
                    this.onSave()
                    break; 
                case 2:                    
                    this.onSend()
                    break; 
                case 3:
                    this.onDel()
                    break;                   
                default:
                    break;
            }
        },   
        onReset:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.frmReset()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.frmReset()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.frmReset()
                    break;
                case 3:
                    this.$refs.empwrMmt.frmReset()
                    break;
                default:
                    break;
            }
        },
        onSave:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.onSave()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.onSave()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.onSave()
                    break;
                case 3:
                    this.$refs.empwrMmt.onSave()
                    break;
                default:
                    break;
            }
        },
        onSend:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.onSend()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.onSend()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.onSend()
                    break;
                case 3:
                    this.$refs.empwrMmt.onSend()
                    break;
                default:
                    break;
            }
        },
        onDel:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.onDelete()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.onDelete()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.onDelete()
                    break;
                case 3:
                    this.$refs.empwrMmt.onDelete()
                    break;
                default:
                    break;
            }
        },
        getYear: function (yyyy){
            this.year = yyyy
                this.$refs.edctnJrnlEmpRprt.onLoad(yyyy)
                this.$refs.edctnJrnlSilvCareRprt.onLoad(yyyy)
                this.$refs.edctnJrnlDssRes.onLoad(yyyy)
            // switch (this.tab) {
            //     case 0:
            //         break; 
            //     case 1:
            //         break;             
            //     default:
            //         break;
            // }
        },             
        snackControll: function (options) {
            this.snackOptions = options
        },
        edctnJrnlMsg:function(msg){                                                        
            this.snackControll([true, 2500, msg, 'warning'])
        },
        edctnJrnlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
    },
    
    data: () => ({
        tab:0,
        year:'',
        dialog:[],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', width:'800px', visible: true, disabled: false, },                        
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', visible: true, disabled: false, },
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '서명 안내 발송', type: 'action', width:'800px', visible: true, disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: false, },
        ],
        tabItems: [
            { index: 0, tab: '직원교육', style: 'font-size: 1.2rem !important;', enter:false},
            { index: 1, tab: '노인인권 및 학대 예방교육', style: 'font-size: 1.2rem !important;', enter:false},
            { index: 2, tab: '재난상황대응훈련', style: 'font-size: 1.2rem !important;', enter:false},
            { index: 3, tab: '역량강화', style: 'font-size: 1.2rem !important;', enter:false},
            // { index: 3, tab: '교육정보', style: 'font-size: 1.2rem !important;', enter:false},
        ],   
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>