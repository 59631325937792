<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>            
            <v-row class="pa-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="2">  
                    <span class="black--text font-weight-medium">욕구사정&nbsp;&#40;총평&#41;</span>    
                </v-col>
                <v-col class="px-2 py-2 ma-0" cols="10" >  
                    <div v-html="dsrEvltn" class="ma-0 pa-0 overflow-y-auto" style="font-size:1rem;height:150px;"></div>
                </v-col>               
            </v-row>
            <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="6">  
                    <span class="black--text font-weight-medium">목표</span>    
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-2 ma-0" cols="6" style="border-left: 1px solid #dbdbdb !important;">  
                    <span class="black--text font-weight-medium">종합의견</span>    
                </v-col>                
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="pa-1 ma-0" cols="6" >  
                    <v-textarea v-model="goal" class="rmrksArea" no-resize  
                        rows="15" outlined dense hide-details>
                    </v-textarea>
                </v-col>
                <v-col class="d-flex pa-1 ma-0" cols="6" style="border-left: 1px solid #dbdbdb !important;">  
                    <v-textarea v-model="cmnts" class="rmrksArea" no-resize  
                        rows="15" outlined dense hide-details>
                    </v-textarea>
                </v-col>
            </v-row>
        </v-form>
    </v-sheet>
</template>
<script>
export default {
    name: 'BnftSrvPlnTab3',

    props : {
        inqYYYY: Number
    },

    computed: {        
    },  

    components: {        
    },
    
    mounted: function(){
    },
        
    methods: {   
        onShow(){                     
        },
        setStyle:function(value){
            let style = "";
            if(value > 1){
                style=""            
            }else{
                style="height:100% !important;"
            }
            return style;
        },   
        setCmnts:function(obj){            
            this.cmnts = obj.cmnts
            this.goal = obj.goal
        },
        selBnftOfrPlnListAfter(data){     
            this.groups = data.groups
            this.tmpgroups = this.groups
            this.listPln = data.listPln  
            
            this.exBtn[0].expandList = []
            this.groups.forEach(x=>{
                this.exBtn[0].expandList.push(x.clsfc2Cdnm)
            })
            
            this.exBtn[0].exHeigth = 20+(28*this.groups.length)

            if(this.srhObj.length > 0){
                this.filterSearch(this.srhObj)
            }
        },
        getBnfcrExamSmryListAfter:function(res){
            this.dsrEvltn = ''
            res.forEach(el => {
                this.dsrEvltn = el.itmVal?.replaceAll("\n", "<br />")
            });
        },
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        reset(){
            this.cmnts = ''  
            this.dsrEvltn = ''  
            this.goal = ''  
        },

        selCmnts:function(){
            return this.cmnts
        },
        selGoal:function(){
            return this.goal
        },
        expandClose: function (index) {
            switch(index) {
                case 0: 
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    break;
            }
        },
        filterSearch:function(srh){  
            this.srhObj = srh         
            if(this.srhObj !=undefined && this.srhObj.length > 0){            
                this.tmpgroups = this.groups.filter(x=> this.srhObj.some(e=>e == x.clsfc2Cdnm))
            }else{
                this.tmpgroups = this.groups
            }
        }
    },
    
    data: () => ({ 
        dsrEvltn:'',
        groups:[],
        tmpgroups:[],
        listPln:{}, 
        srhObj:[],
        cmnts:'', 
        goal:'', 
        exBtn: [
            {
                index:  0,
                btnTitle: '',
                btnIcon: 'listChecks',
                exWidth: 154,
                exHeigth: 50,
                expandList: [],
            },
        ],             
    }),
};
</script>