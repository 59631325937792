<template>
    <v-sheet class="ma-0" rounded="md">
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="5">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">입</span>
                <v-icon color="black" size="16">mdi-circle-small</v-icon>
                <span class="text-h6 font-weight-bold">퇴사 이력</span>
            </v-col>
        </v-row>
        <v-divider :class="['mt-3', empHisList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', empHisList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid py-1 tableBR" cols="1">
                <span class="black--text font-weight-medium">구분</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                <span class="black--text font-weight-medium">입사일</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                <span class="black--text font-weight-medium">퇴사일</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="2">
                <span class="black--text font-weight-medium">변경일</span>
            </v-col>
            <v-col class="txtAlgnMid py-1 tableBR" cols="3">
                <span class="black--text font-weight-medium">사유</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="2">
                <span class="black--text font-weight-medium">인수인계</span>
            </v-col>
        </v-row>
        <v-divider :class="[empHisList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div
            v-if="empHisList.length == 0"
            class="d-flex justify-center align-center tableBB py-4">
            <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
        </div>
        <div
            v-else
            class="d-inlice-block overflow-y-auto" style="width: 100%; height: 370px;">
            <v-row class="pa-0 tableBB" v-for="(hstr, i) in empHisList" :key="i" no-gutters>
                <v-col class="txtAlgnMid py-2 tableBR" cols="1">
                    <span class="fontOneRem">{{ hstr.hisClNm }}</span>
                </v-col>
                <v-col class="txtAlgnMid py-2 tableBR" cols="2">
                    <span class="fontOneRem">{{ hstr.entDtDot }}</span>
                </v-col>
                <v-col class="txtAlgnMid py-2 tableBR" cols="2">
                    <span class="fontOneRem">{{ hstr.rtrDtDot }}</span>
                </v-col>
                <v-col class="txtAlgnMid py-2 tableBR" cols="2">
                    <span class="fontOneRem">{{ hstr.wrkStDtDot }}</span>
                </v-col>
                <v-col class="txt-break pa-2 tableBR" cols="3">
                    <span class="fontOneRem">{{ hstr.rsn }}</span>
                </v-col>
                <v-col class="txt-break pa-2" cols="2">
                    <span class="fontOneRem">{{ hstr.tknOvr }}</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { selEmpHisList } from '../../api/emp.js';

export default {
    name: 'EmpStateHstr',

    props : {
        prtMmbrPk: { type: Number, default: -1 },
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getEmpHisList(this.prtMmbrPk)
        })
    },

    computed: {
        
    },

    watch:{
        'prtMmbrPk': function () {
            if(this.prtMmbrPk != -1)
                this.getEmpHisList(this.prtMmbrPk)
        },
    },
    
    methods: {
        // 직원 입,퇴소 이력 조회
        getEmpHisList: function (pk) {
            this.empHisList.splice(0)

            selEmpHisList(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.empHisRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpHisList : ' + error))
        },
        empHisRst: function (res) {
            if(res.statusCode == '200'){
                if(res.result.length > 0){
                    res.result.forEach(itm => {
                        this.$parent.$parent.$parent.$parent.$parent.$parent.vCd127.forEach(idx => {
                            if(itm.hisClcd == idx.valcd)
                                itm.hisClNm = idx.valcdnm
                        });
                        itm.entDtDot = this.$moment(itm.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                        if(itm.entDtDot == 'Invalid date') itm.entDtDot = ''
                        itm.rtrDtDot = this.$moment(itm.rtrDt, 'YYYYMMDD').format('YYYY.MM.DD')
                        if(itm.rtrDtDot == 'Invalid date') itm.rtrDtDot = ''
                        itm.wrkStDtDot = this.$moment(itm.wrkStDt, 'YYYYMMDD').format('YYYY.MM.DD')
                        if(itm.wrkStDtDot == 'Invalid date') itm.wrkStDtDot = ''
                    });

                }

                this.empHisList = res.result
            }
            else
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
    },

    data: () => ({
        empHisList: [],
    }),
};
</script>