<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">6-3. 시설업무일정</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center pr-3" cols="5">
                <v-spacer></v-spacer>
                <v-btn 
                    class="ml-2 white--text" color="blueBtnColor" height="30"
                    @click="onShow(null)"
                    samll rounded>
                    <v-icon class="mr-1" small>mdi-pencil-outline</v-icon>
                    <span class="mr-1" style="font-size: 1rem;">업무작성</span>
                </v-btn>
                <!-- <v-btn 
                    class="ml-1 white--text" color="blueBtnColor" height="30"
                    samll rounded>
                    <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
                    <span class="mr-1" style="font-size: 1rem;">저장</span>
                </v-btn> -->
                
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-7" no-gutters>
            <v-col cols="12">
                <v-tabs hide-slider v-model="tab" color="white" class="black--text"
                    active-class="bodyColor" center-active>
                    <v-tab v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <MnthlPlan ref="mnthlPlan"></MnthlPlan>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <AnualPlan ref="anualPlan"></AnualPlan>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" max-width="1000" content-class="round" persistent eager>
            <AnnBsnsDtl ref="annBsnsDtl" @modalClose="modalChanger" ></AnnBsnsDtl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import MnthlPlan from '../../components/opr/MnthlPlan.vue';
import AnualPlan from '../../components/opr/AnualPlan.vue';
import AnnBsnsDtl from '../../components/opr/AnnBsnsDtl.vue';

import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'AnlWrkPln',
    
    components: {
        MnthlPlan,
        AnualPlan,
        AnnBsnsDtl,
    },

    props : {

    },

    created: function(){
        this.getCode(['41', '42', '65', '95'], this.$store.getters.getFcltyNum)
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
          
        })
    },

    computed: {

    },  

    watch: {

    },

    methods: {
        getCode: function (array, code) {
            getMultiStcd(array, code)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
            this.vCd95 = res.vCd95
        },
        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onShow: function(pk) {
            if(pk!=null&&pk!=0){
                this.$refs.annBsnsDtl.tableClickEvent(pk)
                this.menu.splice(0, 1, true)
            }else{
                this.menu.splice(0, 1, true)
            }
        },
    },

    data: () => ({
        menu: [false,],
        start: '',
        sltDate: '',
        vCd41: [],
        vCd42: [],
        vCd65: [],
        vCd95: [],
        tab: 0,
        tabItems: [
            { index: 0, tab: '월간계획', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: false, },
            { index: 1, tab: '연간계획', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: false, },
        ],
        exBtn: [
            { index:  0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '업무작성', type: 'action', width:'600px', visible: true, disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>