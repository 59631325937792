<template>
    <v-sheet class="pt-6 pb-2 px-4" rounded="lg">
        <div class="d-flex justify-center mb-4">
            <v-icon color="#f8bb86" style="font-size: 100px;">mdi-alert-circle-outline</v-icon>
        </div>
        <div class="d-flex justify-center mb-4">
            <span class="text-h6 font-weight-bold" style="font-size: 1.875em; color: #595959;">{{message}}</span>
        </div>
        <div class="d-flex justify-center mb-2">
            <span class="text-h6 font-weight-bold" style="font-size: 1.875em; color: #595959;">{{question}}</span>
        </div>
        <div class="d-flex justify-center mb-4">
            <span class="text-subtitle-1 font-weight-medium" style="font-size: 1.875em; color: #595959;">&#40;작성일자 : {{recymd}}, 작성자 : {{wrtr}}&#41;</span>
        </div>
        <div class="d-flex justify-center mb-4 mt-2">
            <v-btn v-for="(list, i) in filledBtn" :key="i" @click="callParents(list.text)"
                :class="list.class" large height="40" :color="list.color"
                style="font-size: 16px;">
                <v-icon large>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
        </div>
    </v-sheet>
</template>

<script>
    export default {
        name: 'BnftrcrdConfirm',
            
        components: {
    
        },

        props:{
            question: { type: String, default: '' },
            message: { type: String, default: '' },
            recymd: { type: String, default: '' },
            wrtr: { type: String, default: '' },
        },
    
        mounted: function(){
    
        },
        
        methods: {
            callParents: function(value){
                if(value == 'Cancel') this.$emit('madalState', 7)
                else if(value == 'OK'){
                    this.$emit('madalState', 9990)
                }

            },
        },
    
        data: () => ({
            area: '',
            filledBtn: [
                { icon: '', class: 'ml-4 white--text', color:'blueBtnColor', text: 'OK', },
                { icon: '', class: 'ml-4 white--text', color:'#aaa', text: 'Cancel', },
            ],
        }),
    };
    </script>