<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">
                {{ $parent.$parent.$parent.$parent.date.substring(0, 4) + '년 ' + $parent.$parent.$parent.$parent.date.substring(4, 6) + '월' }}
                &nbsp;청구&nbsp;상세내역</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <v-row class="" no-gutters>
            <v-col class="pr-4" cols="8">
                <div class="d-flex justify-right mt-4">
                    <v-icon style="font-size: 30px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h5 font-weight-bold ml-3">청구&nbsp;상세내역</span>
                    <v-spacer></v-spacer>
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn @click="menu.splice(i, 1, true)"
                            :class="list.class" samll height="30" color="blueBtnColor" :disabled="list.disabled" rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span style="font-size: 1rem;">{{list.text}}</span>
                        </v-btn>
                    </span>
                </div>
                <div class="mt-3 mb-1" style="font-size: 0.875rem;">
                    서비스&nbsp;제공&#40;{{clmRmrkInfo.ofrDys}}일&#41;&#44;&nbsp;외박&#40;{{clmRmrkInfo.outNtCnt}}일&#41;&#44;&nbsp;
                    <!-- 외박초과일&#40;0일&#41;&#44;&nbsp; -->
                    상급침실&#40;{{clmRmrkInfo.upBdCnt}}박&#41;&nbsp;&#124;&nbsp;
                    식사&#47;간식&nbsp;청구기준&#58;&nbsp;한달전액청구&#40;외출,&nbsp;외박제외&#41;&nbsp;&#124;&nbsp;
                    청구기준&#58;&nbsp;{{clmRmrkInfo.clmStdNm}}&nbsp;&#124;&nbsp;
                    생성일시&#58;&nbsp;{{clmRmrkInfo.crtDt}}
                    <!-- 2021&#46;11&#46;15&nbsp;08&#58;58&#58;41 -->
                </div>
                <div
                    :class="['', copayClmDtlList.length > 12 ? 'pa-0' : 'pr-2__5_1']">
                    <v-data-table
                        class="cpDtlTbl" locale="ko" height="591"
                        :headers="headers" :items="copayClmDtlList" :loading="cpyClmLoader"
                        fixed-header hide-default-header hide-default-footer dense disable-pagination>
                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>
                                    <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR">{{props.headers[0]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[1]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[2]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[3]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[4]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[5]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[6]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[7]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[8]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[9]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[10]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT tableBR">{{props.headers[11]?.text}}</th>
                                    <th class="black--text clmsDtlHeaderCommon tableBT">{{props.headers[12]?.text}}</th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item='{ item }'>
                            <tr>
                                <td class="clmsBodyCommon tableBR pa-0" style="width:88px;">{{item.ofrDt}}</td>
                                <td class="clmsBodyCommon tableBR pa-0" style="width:54px;">{{ item.clmEntStcd }}</td>
                                <td class="clmsBodyCommon tableBR" style="width:84px;">{{ item.copayRateNm }}&#40;{{ item.copayRateDesc }}&#41;</td>
                                <td class="clmsBodyCommon tableBR" style="width:80px;">{{item.bnftFee?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsBodyCommon tableBR" style="width:80px;">{{item.mealCost?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsBodyCommon tableBR" style="width:80px;">{{item.lqdFood?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsBodyCommon tableBR" style="width:40px;">
                                    <span v-if="item.brkfsYn == '1'">
                                        <v-icon color="" small>mdi-check-circle-outline</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <td class="clmsBodyCommon tableBR" style="width:40px;">
                                    <span v-if="item.lunchYn == '1'">
                                        <v-icon color="" small>mdi-check-circle-outline</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <td class="clmsBodyCommon tableBR" style="width:40px;">
                                    <span v-if="item.dinerYn == '1'">
                                        <v-icon color="" small>mdi-check-circle-outline</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <td class="clmsBodyCommon tableBR" style="width:60px;">{{item.snackFee?.toLocaleString('ko-KR')}}</td>
                                <td class="clmsBodyCommon tableBR" style="width:40px;">
                                    <span v-if="item.snckAmYn == '1'">
                                        <v-icon color="" small>mdi-check-circle-outline</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <td class="clmsBodyCommon tableBR" style="width:40px;">
                                    <span v-if="item.snckPmYn == '1'">
                                        <v-icon color="" small>mdi-check-circle-outline</v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon color="" small>mdi-minus-circle-outline</v-icon>
                                    </span>
                                </td>
                                <td class="clmsBodyCommon" style="width:80px;">{{item.upBedFee?.toLocaleString('ko-KR')}}</td>
                            </tr>
                        </template>
                        <template v-slot:[`foot`]="{ props }">
                            <tfoot>
                                <tr>
                                    <td class="clmsDtlHeaderCommon black--text font-weight-medium py-2" style="border-bottom: initial !important;" colspan="3">당월&nbsp;합계</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlListSum.bnftFee?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlListSum.mealCost?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlListSum.lqdFood?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="3">{{props}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlListSum.snackFee?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="2">&nbsp;</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important; border-right: initial !important;" colspan="1">{{copayClmDtlListSum.upBedFee?.toLocaleString('ko-KR')}}</td>
                                </tr>
                                <tr>
                                    <td class="clmsDtlHeaderCommon py-1" style="border-bottom: initial !important;" colspan="3">
                                        <span class="black--text font-weight-medium mr-2">전월&nbsp;재정산&nbsp;가감액</span>
                                        <v-btn
                                            class="ml-1" color="blueBtnColor" min-width="30" height="25"
                                            style="padding: 9px 8px 8px 6px !important; background-color: white;"
                                            @click="setPreMCopayClm()"
                                            small outlined>
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                        <v-icon size="16">mdi-trash-can-outline</v-icon>
                                                        <span style="padding-bottom: 2px; font-size: 0.875rem; margin-left: 2px;">삭제</span>
                                                    </div>
                                                </template>
                                                <span>가감액 정보 초기화</span>
                                            </v-tooltip>
                                        </v-btn>
                                    </td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlInfo.bnftFeeCalc?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlInfo.mealCostCalc?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlInfo.lqdFoodCalc?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="3">&nbsp;</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="1">{{copayClmDtlInfo.snackFeeCalc?.toLocaleString('ko-KR')}}</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important;" colspan="2">&nbsp;</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-bottom: initial !important; border-right: initial !important;" colspan="1">{{copayClmDtlInfo.upBedFeeCalc?.toLocaleString('ko-KR')}}</td>
                                </tr>
                                <tr>
                                    <td class="clmsDtlHeaderCommon black--text font-weight-medium py-2" colspan="3">최종&nbsp;청구액</td>
                                    <td class="clmsDtlHeaderCommon black--text" colspan="1">
                                        {{(copayClmDtlListSum.bnftFee + copayClmDtlInfo.bnftFeeCalc)?.toLocaleString('ko-KR')}}
                                    </td>
                                    <td class="clmsDtlHeaderCommon black--text" colspan="1">
                                        {{(copayClmDtlListSum.mealCost + copayClmDtlInfo.mealCostCalc)?.toLocaleString('ko-KR')}}
                                    </td>
                                    <td class="clmsDtlHeaderCommon black--text" colspan="1">
                                        {{(copayClmDtlListSum.lqdFood + copayClmDtlInfo.lqdFoodCalc)?.toLocaleString('ko-KR')}}
                                    </td>
                                    <td class="clmsDtlHeaderCommon black--text" colspan="3">&nbsp;</td>
                                    <td class="clmsDtlHeaderCommon black--text" colspan="1">
                                        {{(copayClmDtlListSum.snackFee + copayClmDtlInfo.snackFeeCalc)?.toLocaleString('ko-KR')}}
                                    </td>
                                    <td class="clmsDtlHeaderCommon black--text" colspan="2">&nbsp;</td>
                                    <td class="clmsDtlHeaderCommon black--text" style="border-right: initial !important;" colspan="1">
                                        {{(copayClmDtlListSum.upBedFee + copayClmDtlInfo.upBedFeeCalc)?.toLocaleString('ko-KR')}}
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
            <v-col class="pa-0 ma-0 pl-4" cols="4">
                <div class="d-flex justify-right mt-4">
                    <v-icon style="font-size: 30px; width: 8px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h5 font-weight-bold ml-3">청구내역</span>
                    <v-spacer></v-spacer>
                    <!-- <v-btn 
                        class="ml-1 white--text" height="30" color="blueBtnColor" samll rounded>
                        <v-icon small>mdi-pencil-outline</v-icon>
                        <span style="font-size: 1rem;">급여청구&nbsp;재생성</span>
                    </v-btn> -->
                    <v-btn
                        class="ml-1 white--text" height="30" color="blueBtnColor"
                        @click="menu.splice(3, 1, true)" samll rounded>
                        <v-icon small>mdi-content-save-outline</v-icon>
                        <span style="font-size: 1rem;">청구내역&nbsp;수정</span>
                    </v-btn>
                </div>
                <div v-if="!cpyClmDtlLdr"> 
                    <v-divider class="mt-8"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">수급자</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{copayClmDtlInfo.name}}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">청구년월</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{copayClmDtlInfo.clmYymm}}</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">등급</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL tableBR" cols="3">
                            <span :class="'ic-level level0'+copayClmDtlInfo.acknwRtng" style="margin: auto;">
                                {{ copayClmDtlInfo.acknwRtng == '99' ? '외' : copayClmDtlInfo.acknwRtng }}
                            </span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">본인부담률</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">
                                {{ copayClmDtlInfo.copayRateNm }}&#40;{{ copayClmDtlInfo.copayRateDesc }}&#41;
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">제공일수</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{copayClmDtlInfo.ofrDys}}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="6">
                            <v-row class="pa-0" no-gutters>
                                <v-col class="pa-0 ma-0 greyE01" cols="6">
                                    <div class="black--text font-weight-medium cmmFontSizeOne d-flex justify-center align-center tableBB py-1">청구일</div>
                                    <div class="black--text font-weight-medium cmmFontSizeOne d-flex justify-center align-center py-1">납부기한</div>
                                </v-col>
                                <v-col class="pa-0 ma-0 tableBL" cols="6">
                                    <div class="black--text cmmFontSizeOne d-flex justify-center align-center tableBB py-1">
                                        {{copayClmDtlInfo.clmDt == null ? '&nbsp;' : copayClmDtlInfo.clmDt}}
                                    </div>
                                    <div class="black--text cmmFontSizeOne d-flex justify-center align-center py-1">{{copayClmDtlInfo.dueDt}}</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-divider class="mt-3"></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">본인부담금ⓐ</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ (copayClmDtlInfo.copay)?.toLocaleString('ko-KR') }}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">공단부담금</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ (copayClmDtlInfo.nhisPay)?.toLocaleString('ko-KR') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="9">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">
                                급여&nbsp;계&nbsp;&#40;본인부담금&#43;공단부담금&#41;
                            </span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">
                                {{(Number(copayClmDtlInfo.copay) + Number(copayClmDtlInfo.nhisPay))?.toLocaleString('ko-KR')}}
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-divider class="mt-3"></v-divider>
                    <v-row class="pa-0 " no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">식사재료비①</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ (copayClmDtlInfo.mealCost)?.toLocaleString('ko-KR') }}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">상급침실비②</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ (copayClmDtlInfo.upBedFee)?.toLocaleString('ko-KR') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">이,미용비③</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.btyFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">경관유동식④</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ (copayClmDtlInfo.lqdFood)?.toLocaleString('ko-KR') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">간식비⑤</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ (copayClmDtlInfo.snackFee)?.toLocaleString('ko-KR') }}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">기타비용⑥</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.msclnFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne">진료약제비⑦</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.clncPhrmcFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01" cols="3">
                            <div class="black--text font-weight-medium cmmFontSizeOne pt-1" style="text-align:center;">계약의사</div>
                            <div class="black--text font-weight-medium cmmFontSizeOne pb-1" style="text-align:center;">진찰비⑧</div>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.cntrcDctrFee.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">등급외⑨</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.otrAmt.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">이전미납금⑩</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.unpdAmt.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01" cols="9">
                            <div class="black--text font-weight-medium cmmFontSizeOne pt-1" style="text-align:center;">
                                비급여&nbsp;계
                            </div>
                            <div class="black--text font-weight-medium cmmFontSizeOne pb-1" style="text-align:center;">
                                ⓑ&nbsp;=&nbsp;①&#43;②&#43;③&#43;④&#43;⑤&#43;⑥&#43;⑦&#43;⑧&#43;⑨&#43;⑩
                            </div>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">
                                {{
                                    (
                                        Number(copayClmDtlInfo.mealCost) +
                                        Number(copayClmDtlInfo.upBedFee) +
                                        Number(copayClmDtlInfo.btyFee) +
                                        Number(copayClmDtlInfo.lqdFood) +
                                        Number(copayClmDtlInfo.snackFee) +
                                        Number(copayClmDtlInfo.msclnFee) +
                                        Number(copayClmDtlInfo.clncPhrmcFee) + Number(copayClmDtlInfo.cntrcDctrFee) +
                                        Number(copayClmDtlInfo.otrAmt) + Number(copayClmDtlInfo.unpdAmt)
                                    )?.toLocaleString('ko-KR')
                                }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="9">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">당월본인부담금합계&nbsp;ⓒ&nbsp;=&nbsp;ⓐ&#43;ⓑ&#45;⑩</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">
                                {{
                                    (
                                        Number(copayClmDtlInfo.copay) +         
                                        Number(copayClmDtlInfo.mealCost) +
                                        Number(copayClmDtlInfo.upBedFee) +
                                        Number(copayClmDtlInfo.btyFee) +
                                        Number(copayClmDtlInfo.lqdFood) +
                                        Number(copayClmDtlInfo.snackFee) +
                                        Number(copayClmDtlInfo.msclnFee) +
                                        Number(copayClmDtlInfo.clncPhrmcFee) +
                                        Number(copayClmDtlInfo.cntrcDctrFee) +
                                        Number(copayClmDtlInfo.otrAmt) +
                                        Number(copayClmDtlInfo.unpdAmt) - 
                                        Number(copayClmDtlInfo.unpdAmt)
                                    )?.toLocaleString('ko-KR')
                                }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">선납적용액ⓓ</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL tableBR" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.prpymApl.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">잔여선납금</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center pa-1 tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">{{ copayClmDtlInfo.rmnPrpym.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="9">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">부담금&nbsp;총합&#40;ⓐ&#43;ⓑ&#45;ⓓ&#41;</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL" cols="3">
                            <span class="black--text cmmFontSizeOne py-1">
                                {{ 
                                    (
                                        Number(copayClmDtlInfo.copay) +         
                                        Number(copayClmDtlInfo.mealCost) +
                                        Number(copayClmDtlInfo.upBedFee) +
                                        Number(copayClmDtlInfo.btyFee) +
                                        Number(copayClmDtlInfo.lqdFood) +
                                        Number(copayClmDtlInfo.snackFee) +
                                        Number(copayClmDtlInfo.msclnFee) +
                                        Number(copayClmDtlInfo.clncPhrmcFee) +
                                        Number(copayClmDtlInfo.cntrcDctrFee) +
                                        Number(copayClmDtlInfo.otrAmt) +
                                        Number(copayClmDtlInfo.unpdAmt) - 
                                        Number(copayClmDtlInfo.prpymApl)
                                    )?.toLocaleString('ko-KR')
                                }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-0 ma-0 greyE01 d-flex justify-center align-center" cols="3">
                            <span class="black--text font-weight-medium cmmFontSizeOne py-1">비고</span>
                        </v-col>
                        <v-col class="pa-0 ma-0 d-flex justify-center align-center tableBL" cols="9">
                            <span class="black--text cmmFontSizeOne py-1">{{copayClmDtlInfo.rmrks}}</span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                </div>
                <div v-else>
                    <v-divider class="mt-8"></v-divider>
                    <v-skeleton-loader
                        v-bind="attrs"
                        type="table-heading, list-item-three-line, table-heading, list-item-three-line, table-heading, list-item-three-line">
                    </v-skeleton-loader>
                    <v-divider class=""></v-divider>
                </div> 
            </v-col>
        </v-row>
        <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="1220">
            <CopayCalcDtl
                ref="cpyCalcDtl"
                :yyMm="$parent.$parent.$parent.$parent.date"
                :bnmmbrPk="sltMmbrPk" :bnfcrPk="sltBnfcrPk"
                @isClose="menu.splice(0, 1, false)">
            </CopayCalcDtl>
        </v-dialog>
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="1260">
            <ClmsDtlMdf
                ref="clmsDtlMdf"
                :yyMm="$parent.$parent.$parent.$parent.date"
                :bnmmbrPk="sltMmbrPk" :bnfcrPk="sltBnfcrPk"
                @isClose="menu.splice(1, 1, false)">
            </ClmsDtlMdf>
        </v-dialog>
        <v-dialog v-model="menu[2]" content-class="round" persistent max-width="1360">
            <MdclExpnsMdf
                :yyMm="$parent.$parent.$parent.$parent.date"
                :bnmmbrPk="sltMmbrPk" :bnfcrPk="sltBnfcrPk" :rtCmp="'clm'"
                @isClose="menu.splice(2, 1, false)">
            </MdclExpnsMdf>
        </v-dialog>
        <v-dialog v-model="menu[3]" eager content-class="round" persistent max-width="900">
            <MdfCopayClmsMdl
                ref="mdfCopayClms"
                :yyMm="$parent.$parent.$parent.$parent.date" :bnmmbrPk="sltMmbrPk"
                @isClose="menu.splice(3, 1, false)">
            </MdfCopayClmsMdl>
        </v-dialog>
        <v-dialog v-model="menu[4]" content-class="round" max-width="900" persistent>
            <MsclnExpnsMdl
                :yyMm="$parent.$parent.$parent.$parent.date"
                :bnmmbrPk="sltMmbrPk" :bnfcrPk="sltBnfcrPk"
                @isClose="menu.splice(4, 1, false)">
            </MsclnExpnsMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selCopayClmHisInfo, insCopayClmHis, selCopayClmRmrkInfo, updPreMCopayClm } from '../../api/bnfcr.js';

import MdclExpnsMdf from './MdclExpnsMdf.vue';
import CopayCalcDtl from './CopayCalcDtl.vue';
import ClmsDtlMdf from './ClmsDtlMdf.vue';
import MdfCopayClmsMdl from './MdfCopayClmsMdl.vue';
import MsclnExpnsMdl from './MsclnExpnsMdl.vue';

export default {
    name: 'CopayClmsDtl',

    components: {
        MdclExpnsMdf,
        CopayCalcDtl,
        ClmsDtlMdf,
        MdfCopayClmsMdl,
        MsclnExpnsMdl,
    },

    props : {
        clmHisPk: { type: Number, default: -1 },
        sltMmbrPk: { type: Number, default: -1 },
        sltBnfcrPk: { type: Number, default: -1 },
    },

    created: function(){
        this.copayClmDtlInfo = Object.assign({}, this.dmyCpyClmDtlInf)
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.sltMmbrPk != -1 && this.sltBnfcrPk != -1)
                this.getCopayClmHisInfo(this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk, this.sltBnfcrPk)

        })
    },

    computed: {
        // getBnMmbrPk: function () {
        //     return this.sltMmbrPk
        // },
        // getDate: function () {
        //     return this.$parent.$parent.$parent.$parent.date
        // },
    },

    watch: {
        // getBnMmbrPk: function (v) {
        //     if(this.sltMmbrPk != -1 && this.sltBnfcrPk != -1)
        //         this.getCopayClmHisInfo(this.$parent.$parent.$parent.$parent.date, v, this.sltBnfcrPk)
        // },
        // getDate: function (v) {
        //     if(this.sltMmbrPk != -1 && this.sltBnfcrPk != -1)
        //         this.getCopayClmHisInfo(v, this.sltMmbrPk, this.sltBnfcrPk)
        // },
        'clmHisPk': function () {
            if(this.sltMmbrPk != -1 && this.$parent.$parent.$parent.$parent.date != '')
                this.getCopayClmHisInfo(this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk, this.sltBnfcrPk)

            this.getCopayClmRmrkInfo(this.$parent.$parent.$parent.$parent.date, this.clmHisPk)
        },
    },

    methods:{
        getCopayClmHisInfo: function (yyyyMm, bnmmbrPk, bnfcr) {
            this.cpyClmDtlLdr = true
            this.copayClmDtlInfo = Object.assign({}, this.dmyCpyClmDtlInf)
            
            selCopayClmHisInfo(this.$store.getters.getFcltyNum, yyyyMm, bnmmbrPk, bnfcr)
                .then((response) => ( this.getCopayClmHisInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmHisInfo : ' + error))
        },
        getCopayClmRmrkInfo: function (yymm, pk) {
            selCopayClmRmrkInfo(this.$store.getters.getFcltyNum, yymm, pk)
                .then((response) => ( this.getCopayClmRmrkInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmRmrkInfo : ' + error))
        },
        setCopayClmHis: function (obj) {
            insCopayClmHis(obj)
                .then((response) => ( this.setCopayClmHisAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insCopayClmHis : ' + error))
        },
        setPreMCopayClm: function () {
            updPreMCopayClm(this.$store.getters.getFcltyNum, this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk, this.sltBnfcrPk, this.$store.getters.getUserNm)
                .then((response) => ( this.preMCopayClmRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updPreMCopayClm : ' + error))
        },
        getCopayClmHisInfoAfter: function (res) {
            if(typeof res != 'string'){
                this.orgCpyClmDtlInf = Object.assign({}, res)

                res.clmYymm = this.$moment(res.clmYymm, 'YYYYMM').format('YYYY.MM')
                res.clmDt = this.$moment(res.clmDt, 'YYYYMMDD').format('YYYY.MM.DD')
                res.dueDt = this.$moment(res.dueDt, 'YYYYMMDD').format('YYYY.MM.DD')
                res.ofrDys += '일'

                this.$parent.$parent.$parent.$parent.vCd63.forEach(code => {
                    if(res.copayRate == code.valcd){
                        res.copayRateNm = code.valcdnm?.slice(0, 3)?.replaceAll('(', '')
                        res.copayRateDesc = code.valdesc + '%'
                    }
                });

                this.copayClmDtlInfo = res
                this.cpyClmDtlLdr = false

                if(this.refresherChk){
                    this.refresherChk = false
                    this.preCopayClmHis()
                }
            }
        },
        getCopayClmRmrkInfoAfter: function (res) {
            this.clmRmrkInfo = res
        },
        setCopayClmHisAfter: function (res) {
            if(res.statusCode == 200 && this.clmHisPk != -1){
                //this.snackControll([true, 2500, res.message, 'info'])
                this.$refs.mdfCopayClms.snackControll([true, 2500, res.message, 'info'])
                this.getCopayClmHisInfo(this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk, this.sltBnfcrPk)
                this.$parent.$parent.$parent.$parent.getCopayClmHisList(this.$parent.$parent.$parent.$parent.date)
                this.$refs.clmsDtlMdf.getCopayClmList(this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk)
                this.$refs.mdfCopayClms.$refs.frmClm.resetValidation()
            }
            else
                this.$refs.mdfCopayClms.snackControll([true, 5000, res.message, 'error'])
                //this.snackControll([true, 5000, res.message, 'error'])
        },
        preMCopayClmRst: function (res) {
            if(res.statusCode == '200'){
                if(this.sltMmbrPk != -1 && this.sltBnfcrPk != -1)
                    this.getCopayClmHisInfo(this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk, this.sltBnfcrPk)
                    this.$refs.cpyCalcDtl.getCopayClmCalcList(this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk, this.sltBnfcrPk)
            }
            else
                this.$refs.mdfCopayClms.snackControll([true, 3000, res.message, 'error'])
        },
        preCopayClmHis: function () {
            let obj = Object.assign({}, this.copayClmDtlInfo)

            this.$parent.$parent.$parent.$parent.vCd65.forEach(code => {
                if(obj.entStcd == code.valcdnm) obj.entStcd = code.valcd
            });
            
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.prcClcd = '3'
            obj.prcCdAr = []
            obj.userNm = this.$store.getters.getUserNm
            obj.ofrDys = obj.ofrDys?.replaceAll('일', '')
            obj.clmYymm = this.$moment(obj.clmYymm, 'YYYY.MM').format('YYYYMM')
            obj.clmDt = this.$moment(obj.clmDt, 'YYYY.MM.DD').format('YYYYMMDD')
            obj.dueDt = this.$moment(obj.dueDt, 'YYYY.MM.DD').format('YYYYMMDD')

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.$refs.mdfCopayClms.frmDueDt))
                obj.dueDt = this.$moment(this.$refs.mdfCopayClms.frmDueDt, 'YYYY-MM-DD').format('YYYYMMDD')
            else
                obj.dueDt = this.$moment(obj.dueDt, 'YYYY.MM.DD').format('YYYYMMDD')

            if(obj.copay != this.orgCpyClmDtlInf.copay)
                obj.prcCdAr.push('2')
            if(obj.nhisPay != this.orgCpyClmDtlInf.nhisPay)
                obj.prcCdAr.push('3')
            if(obj.mealCost != this.orgCpyClmDtlInf.mealCost)
                obj.prcCdAr.push('4')
            if(obj.upBedFee != this.orgCpyClmDtlInf.upBedFee)
                obj.prcCdAr.push('5')
            if(obj.btyFee != this.orgCpyClmDtlInf.btyFee)
                obj.prcCdAr.push('6')
            if(obj.lqdFood != this.orgCpyClmDtlInf.lqdFood)
                obj.prcCdAr.push('7')
            if(obj.snackFee != this.orgCpyClmDtlInf.snackFee)
                obj.prcCdAr.push('8')
            if(obj.msclnFee != this.orgCpyClmDtlInf.msclnFee)
                obj.prcCdAr.push('9')
            if(obj.clncPhrmcFee != this.orgCpyClmDtlInf.clncPhrmcFee)
                obj.prcCdAr.push('10')
            if(obj.cntrcDctrFee != this.orgCpyClmDtlInf.cntrcDctrFee)
                obj.prcCdAr.push('11')
            if(obj.otrAmt != this.orgCpyClmDtlInf.otrAmt)
                obj.prcCdAr.push('12')

            //console.log(obj)
            if(this.$refs.mdfCopayClms.$refs.frmClm.validate())
                this.setCopayClmHis(obj)
            else
                this.$refs.mdfCopayClms.snackControll([true, 2500, '입력 항목을 확인해주세요.', 'warning'])
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        refresher: function () {
            this.getCopayClmHisInfo(this.$parent.$parent.$parent.$parent.date, this.sltMmbrPk, this.sltBnfcrPk)
            this.refresherChk = true
            this.menu.splice(2, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        date: '',
        attrs: {
            class: '',
            boilerplate: false,
            elevation: 0,
        },
        selectedItem: -1,
        cpyClmLoader: true,
        refresherChk: false,
        cpyClmDtlLdr: true,
        menu: [false, false, false, false, false],
        vCd83: [],
        clmRmrkInfo: { clmStdNm: '', clmYymm: '', crtDt: '', ofrDys: 0, outCnt: 0, outNtCnt: 0, upBdCnt: 0 },
        copayClmDtlList: [],
        orgCpyClmDtlInf: {},
        copayClmDtlInfo: {},
        dmyCpyClmDtlInf: {
            upBedFee: 0, clmTot: 0, clncPhrmcFee: 0, nhisPay: 0, rmrks: '', bnfcrPk: 0, btyFee: 0, acknwRtng: '',
            clmYymm: '', dueDt: '', copayRate: 0, copay: 0, cnfrmYn: '', clmDt: '', copayClmHisPk: 0, bnMmbrPK: 0,
            msclnFee: 0, snackFee: 0, lqdFood: 0, rmnPrpym: 0, cntrcDctrFee: 0, name: '', copayAmt: 0, mealCost: 0,
            unpdAmt: 0, ofrDys: 0, prpymApl: 0, nhisPayCalc: 0, copayCalc: 0,bnftFeeCalc: 0, mealCostCalc: 0, lqdFoodCalc: 0,
            snackFeeCalc: 0, upBedFeeCalc: 0, otrAmt: 0, copayRateNm: ''
        },
        copayClmDtlListSum: { nhisPay: 0, copay: 0, mealCost: 0, lqdFood: 0, snackFee: 0, upBedFee: 0 },
        filledBtn: [
            { icon: 'mdi-text-box-edit-outline', class: 'white--text', color:'blueBtnColor', text: '전월 정산내역', disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '청구내역 상세수정', disabled: false, },
        ],
        headers: [],
        rules: {
            required: value => !!value || 'Required.',
        },
        snackOptions: [false, 3000, '', 'info'],
    })
}
</script>

<style>
    .clmsDtlHeaderCommon {
        border-bottom: 1px solid #dbdbdb !important; background-color: #ededed !important;
        text-align: center !important; font-size: 1rem !important; padding: 0 4px !important;
    }
    .cmmFontSizeOne { font-size: 1rem !important; }
    
    .cpDtlTbl .v-data-table__wrapper table {
        border-spacing: 0px !important;
    }
    .cpDtlTbl .v-data-table__wrapper table tfoot tr { height: 39px; }
    .cpDtlTbl .v-data-table__wrapper table tfoot tr td {
        position: sticky; top: 0; border-right: 1px solid #dbdbdb !important; z-index: 3;
    }
    .cpDtlTbl .v-data-table__wrapper table tfoot tr:first-child td { bottom: 78px; }
    .cpDtlTbl .v-data-table__wrapper table tfoot tr:nth-child(2) td { bottom: 39px; }
    .cpDtlTbl .v-data-table__wrapper table tfoot tr:last-child td { bottom: 0px; }
</style>