<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>   
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">전자서명 관리</span>
            <v-spacer></v-spacer>                       
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-3">
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="ma-0 pa-0" cols="5">                            
                     <v-data-table hide-default-footer dense locale="ko" height="370"
                        hide-default-header fixed-header 
                        :headers="edctnSignHeaders" 
                        :items="edctnSignList" 
                        class="" disable-pagination>
                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>                            
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;">{{props.headers[0].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 110px !important;">{{props.headers[1].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBT" style="width: 100px !important;">{{props.headers[2].text}}</th>
                                </tr>
                            </thead>
                        </template>                
                        <template v-slot:item='{ item }'>
                            <tr :class="['fixedCol', sltEduAtndPk == item.eduAtndPk ? 'sltRow' : 'disSltRow']" @click="tableClickEvent(item)">
                                <td class="fixedCol0 clmsBodyCommon tableBR">{{item.name}}</td>
                                <td class="fixedCol0 clmsBodyCommon tableBR">{{item.pstnNm}}</td>
                                <td class="fixedCol0 clmsBodyCommon">
                                    <v-icon v-if="item.eSigPk > 0">mdi-circle-outline</v-icon>
                                    <v-icon v-else>mdi-close</v-icon>
                                </td>                        
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>                        
                <v-col cols="7" class="pl-3">                     
                    <div v-if="signCanv" class="d-flex justify-center">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="ma-0 pa-0" cols="11">          
                                <vue-esign class="mt-0 rounded" style="border: 3px solid #4bc5e8;"
                                ref="esign" format="image/png" :width="400" :height="300"
                                :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor"/>
                            </v-col>
                            <v-col class="ma-0 pa-0" cols="1">
                                <v-btn color="blueBtnColor" height="30" @click="handleGenerate()" icon>
                                    <v-icon>mdi-content-save-edit-outline</v-icon>
                                </v-btn>  
                                <v-btn color="grey006" height="30" @click="handleReset()" icon>
                                    <v-icon>mdi-eraser</v-icon>
                                </v-btn>                      
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="signImg" class="d-flex justify-center">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col class="ma-0 pa-0" cols="11">   
                                <div class="mt-0 rounded" style="border: 3px solid #4bc5e8;">
                                    <v-img :src="imgSrc" :width="imgWidth"></v-img>
                                </div>
                            </v-col>
                            <v-col class="ma-0 pa-0" cols="1">                               
                                <v-btn color="grey006" height="30" @click="onDelSign()" icon>
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>                      
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="sltEduAtndPk == 0" class="d-inline-block d-flex justify-center align-center rounded-lg" style="width: 100%; height: 100%; opacity: 0.7;border: 3px solid #4bc5e8;">
                        <span class="font-weight-bold red--text" style="font-size: 1.5rem;">좌측 메뉴에서 선택후 이용해주세요.</span>
                    </div>
                </v-col>                        
            </v-row>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'전자서명'" @madalState="delSignAct"></btnModalDelete>  
            </v-dialog>
        </div>        
    </v-sheet>
</template>

<script>
import { remoteURL } from '@/api/baseAxios';
import { selEduAtndSigList, } from '../../api/emp.js';
import { uploadSIgn, delESig } from '../../api/index.js';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';

export default {
    name: 'EdctnJrnlSignMdl',

    props : {
        
    },

    components: {
        btnModalDelete
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch: {
    },
        
    methods: {   
        onLoad:function(pk){
            this.sltEduRecPk = pk
            this.sltEduAtndPk = 0
            if(this.$refs.esign != undefined) this.$refs.esign.reset()
            this.sign = ''
            this.signCanv = false
            this.signImg = false 
            this.getEduAtndSigList()
        },    
        getEduAtndSigList:function(){  
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eduRecPk : this.sltEduRecPk,
            }     
            selEduAtndSigList(obj)
                .then((response) => ( this.getEduAtndSigListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduAtndSigList : ' + error))
        },
        getEduAtndSigListAfter:function(res){
            this.edctnSignList = res

            if(this.sltEduAtndPk > 0){
                let eSigPk = 0
                let target = this.edctnSignList.filter(x=>x.eduAtndPk === this.sltEduAtndPk)
                target.forEach(e => {
                    eSigPk = e.eSigPk;
                });

                if(eSigPk > 0){
                    this.setSignImg()
                    this.signCanv = false
                    this.signImg = true 
                }else{
                    this.signCanv = true
                    this.signImg = false 
                }

            }
        },
        handleReset: function () {
            this.$refs.esign.reset()
            this.sign = ''
        },
        handleGenerate: function () {
            this.$refs.esign.generate().then(res => {
                this.sign = res
                this.setSign()
            }).catch(err => {
                alert(err)
            })
        },
        setSign: function () {
            let mmbrPk = 0;
            
            let target = this.edctnSignList.filter(x=>x.eduAtndPk === this.sltEduAtndPk)
            target.forEach(e => {
                mmbrPk = e.mmbrPk;
            });
            
            if(mmbrPk > 0){
                //교육기록(eSigClcd): 8
                let obj = {}
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.mmbrPk = mmbrPk
                obj.bzClcdPk = this.sltEduRecPk
                obj.eSigClcd = '8'
                obj.sign = this.sign
                obj.userNm = this.$store.getters.getUserNm

                uploadSIgn(obj)
                    .then((response) => ( this.setSignAfter(response.data) ))
                    .catch((error) => console.log('connect error /cmm/uploadSIgn : ' + error))
            }
        },
        setSignAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.getEduAtndSigList()

            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        tableClickEvent:function(obj){
            this.sltEduAtndPk = obj.eduAtndPk
            if(obj.eSigPk > 0){
                this.signCanv = false
                this.signImg = true                
                this.setSignImg()
            }else{
                this.signCanv = true
                this.signImg = false
                if(this.$refs.esign != undefined) this.$refs.esign.reset()
                this.sign = ''
            }

        },
        setSignImg:function(){
            let mpk = 0;
            
            let target = this.edctnSignList.filter(x=>x.eduAtndPk === this.sltEduAtndPk)
            target.forEach(e => {
                mpk = e.mmbrPk;
            });

            let path = remoteURL + 'cmm/eSigImgDownload?'
            let fcltyNum = 'fcltyNum=' + this.$store.getters.getFcltyNum
            let bzClcd = '&eSigClcd=8'
            let bzPk = '&bzClcdPk=' + this.sltEduRecPk
            let mmbrPk = '&mmbrPk=' + mpk
            this.imgSrc = path + fcltyNum + bzClcd + bzPk + mmbrPk + '&t=' + new Date().getTime()
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClose: function () {
            this.$emit('modalEvent', 2)
        },
        onDelSign:function(){
            if(this.sltEduAtndPk > 0){
                this.dialogDelete = true
            }
        },
        delSignAct:function(res){
            if(res == 9990){
                let eSigPk = 0
                let target = this.edctnSignList.filter(x=>x.eduAtndPk === this.sltEduAtndPk)

                target.forEach(e => {
                    eSigPk = e.eSigPk;
                });

                if(eSigPk > 0){
                    let obj = {
                        fcltyNum:this.$store.getters.getFcltyNum,
                        eSigPk : eSigPk,
                        userNm : this.$store.getters.getUserNm,
                    }
                    delESig(obj)
                        .then((response) => ( this.delSignActAfter(response.data) ))
                        .catch((error) => console.log('connect error /cmm/delESig : ' + error))
                }

            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        delSignActAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.getEduAtndSigList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        }
    },
    
    data: () => ({
        sltEduRecPk:0,
        sltEduAtndPk:-1,
        edctnSignHeaders: [
            { text: '대상자', value: '', align: 'center'},
            { text: '구분', value: '', align: 'center'},
            { text: '서명여부', value: '', align: 'center'},
        ],
        edctnSignList:[],
        snackOptions: [false, 3000, '', 'info'],
        sign: '',
        lineWidth: 6,               //브러시 굵기 (2, 4, 6, 8, 10)
        lineColor: '#000000',       //브러시 색상 
        bgColor: '',                //캔버스 색상
        resultImg: '',              //생성된 이미지
        isCrop: false,              //자르기
        signCanv:false,
        signImg:false,
        imgSrc:'',
        imgWidth:'400',
        dialogDelete:false,
    }),
};
</script>