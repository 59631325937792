<template>
    <div class="d-flex justify-center align-center" style="padding-bottom: 1px;">
        <v-btn class=" mr-1" color="btnBrdrColor" x-small outlined @click="isPrev"
            style="background-color: white; width:26px;" height="26">
            <span class="black--text">
                <v-icon>mdi-chevron-left</v-icon>
            </span>
        </v-btn>
        <v-btn class="" color="btnBrdrColor" x-small outlined @click="isNext"
            style="background-color: white; width:26px;" height="26">
            <span class="black--text">
                <v-icon>mdi-chevron-right</v-icon>
            </span>
        </v-btn>

        <span class="text-h5 font-weight-bold topTitleColor--text mx-3" style="padding-bottom: 3px;">{{sltYear}}년</span>

        <span class="d-inline-flex">
            <v-btn class="" color="btnBrdrColor" x-small outlined @click="isOpen"
                style="background-color: white; width:26px;" height="26">
                <span class="black--text">
                    <v-icon small>mdi-calendar-month</v-icon>
                </span>
            </v-btn>
            <v-expand-transition>
                <v-card v-show="menuEx" height="204" style="position: absolute; z-index: 11;" v-click-outside="onClickOutside"
                    class="mt-7 black--text overflow-y-auto" outlined width="80">
                    <div class="" v-for="i in nowYear" :key="i">
                        <div class="py-1 yearRow" style="text-align: center;" ref="yearRef" @click="onClicked(i)">
                            {{i}}
                        </div>
                    </div>
                </v-card>
            </v-expand-transition>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'expandYearOnlyPicker',

        props : {
            prtYear: { type: Number, default: 2000 }
        },
        
        components: {
        
        },

        created: function(){
            //확장 패널에 년도 추가
            for(var i = 2000; i < new Date().getFullYear()+3; i++){
                this.nowYear.push(i+1)
            }
            this.nowYear.sort(function(a, b)  {
                return b - a;
            });
        },

        mounted: function(){
            this.$nextTick(function () {
                let tmp = this.sltYear-2000
                this.$refs.yearRef[this.$refs.yearRef.length - tmp].style="text-align: center; background: #ebf6fe !important;"
                this.$emit('nowYear', this.sltYear)
            })
        },

        watch: {
            sltYear: function () {
                let tmp = this.sltYear-2000

                if(Math.max.apply(null, this.nowYear) >= this.sltYear){
                    this.$refs.yearRef.forEach(element => {
                        element.style="text-align: center;"
                    });
                    this.$refs.yearRef[this.$refs.yearRef.length - tmp].style="text-align: center; background: #ebf6fe !important;"
                }

            },
            'prtYear': function () {
                this.sltYear = this.prtYear
            },
        },
    
        methods: {
            isOpen: function (){
                this.menuEx = !this.menuEx;
                this.extChk = 1
            },
            isClose: function (){
                this.menuEx = false
            },
            isPrev: function (){
                if(Math.min.apply(null, this.nowYear) < this.sltYear )
                    this.sltYear -= 1

                this.$emit('nowYear', this.sltYear)
            },
            isNext: function (){
                if(Math.max.apply(null, this.nowYear) > this.sltYear )
                    this.sltYear += 1

                this.$emit('nowYear', this.sltYear)
            },
            onClicked: function (item){
                this.sltYear = item
                this.menuEx = false
                this.$emit('nowYear', this.sltYear)
            },
            onClickOutside: function (){
                if(this.extChk != 1) this.menuEx = false
                this.extChk = 0
            },
        },

        data: () => ({
            menuEx: false,
            nowYear: [],
            extChk: 0,
            sltYear: new Date().getFullYear(),
            ckBoxAll: 'all',
            allBtnState: true,
            ckBox: [],
        }),
    };
</script>

<style>
    .theme--light.v-label{ color: #666 !important; font-size: 0.98rem; }
    .v-input .v-label{line-height: 21px;}
    .yearRow:hover { background: #ebf6fe !important; }
</style>