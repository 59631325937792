<template>
    <v-form ref="wrkngHours" lazy-validation>
        <v-row class="pa-0 ma-0 tableBB" v-for="(list, i) in wrkngList" :key="i" no-gutters>
            <v-col class="tableBR" cols="4">
                <v-row class="pa-0 ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR" cols="4">
                        <span v-if="prtClcd ==''" class="d-inline-block mx-2" style="width: 80px;">
                            <v-select
                                class="wrkSelect"
                                v-model="list.wrkHrClcd" :key="forceRender" :rules="[rules.required]"
                                :items="$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd115"
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                        <span v-else class="d-inline-block mx-2" style="width: 80px;">
                            <v-select
                                class="wrkSelect"
                                v-model="list.wrkHrClcd" :key="forceRender" :rules="[rules.required]" :disabled="i+1 == wrkngList.length ? false : true"
                                :items="$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd115"
                                item-text="valcdnm" item-value="valcd"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR" cols="3">
                        <span class="white d-inline-block pa-1" style="width: 80px;">
                            <CmmTimeField
                                v-model="wrkStrt[i]" :required="true"
                                :disabled="i+1 == wrkngList.length ? false : true">
                            </CmmTimeField>                                     
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="5">
                        <span class="white d-inline-block pa-1" style="width: 80px;">
                            <CmmTimeField
                                v-model="wrkEnd[i]" :required="true"
                                :disabled="i+1 == wrkngList.length ? false : true">
                            </CmmTimeField>                                     
                        </span>
                        <span class="d-inline-block ml-2" style="width: 50px;">
                            <v-checkbox
                                class="checkIcon" v-model="list.wrkOvnYn" :key="forceRender" label="익일"
                                true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                disabled hide-details>
                            </v-checkbox>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="tableBR" cols="2">
                <v-row class="ma-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR" cols="6">
                        <span class="d-inline-block py-1" style="width:60%;">
                            <v-text-field class="numArea"
                                v-model="list.brkDay" type="number" @input="actWrkHrCalc(i)" :disabled="i+1 == wrkngList.length ? false : true"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="ml-1">분</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="6">
                        <span class="d-inline-block py-1" style="width:60%;">
                            <v-text-field class="numArea"
                                v-model="list.brkNgt" type="number" @input="actWrkHrCalc(i)" :disabled="i+1 == wrkngList.length ? false : true"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="ml-1">분</span>
                    </v-col>
                    <!-- <v-col class="d-flex justify-center align-center" cols="4">
                        <span class="d-inline-block pa-1" style="width:50%;">
                            <v-text-field class="numArea"
                                v-model="list.brkOvn" type="number" @input="actWrkHrCalc(i)" :disabled="i+1 == wrkngList.length ? false : true"
                                hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <span class="ml-1">분</span>
                    </v-col> -->
                </v-row>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="2">
                <span class="black--text">{{ list.actWrkTxt }}</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR pa-1" cols="2">
                <span class="d-inline-block" style="width: 133px;">
                    <CmmDateComp
                        v-model="list.aplStrt" :required="true"
                        :disabled="i+1 == wrkngList.length ? false : true">
                    </CmmDateComp>
                </span>
                <!-- <span class="d-inline-block black--text dpickArea" style="width: 100px;">
                    <v-menu
                        ref="datePicker" v-model="aplStrt[i]"
                        :close-on-content-click="false" :return-value.sync="list.aplStrt"
                        transition="scale-transition" offset-y min-width="auto" dense>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="list.aplStrt" :rules="[rules.required]" :disabled="i+1 == wrkngList.length ? false : true"
                                readonly hide-details outlined dense v-bind="attrs" v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="list.aplStrt" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose(i)">Cancel</v-btn>
                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker[i].save(list.aplStrt), chkAplVal(i)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </span> -->
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR pa-1" cols="1">
                <span class="black--text">{{ list.aplEnd == '2999-12-31' ? '현재' : list.aplEnd }}</span>
                <!-- <span class="d-inline-block black--text dpickArea" style="width: 100px;">
                    <v-menu
                        ref="datePicker0" v-model="aplEnd[i]"
                        :close-on-content-click="false" :return-value.sync="list.aplEnd"
                        transition="scale-transition" offset-y min-width="auto" dense>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                style="padding: 0 0 !important;"
                                v-model="list.aplEnd" :rules="[rules.required]" :disabled="i+1 == wrkngList.length ? false : true"
                                readonly hide-details outlined dense v-bind="attrs" v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="list.aplEnd" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="datePickClose0(i)">Cancel</v-btn>
                            <v-btn class="font-weight-bold" color="blueBtnColor" text @click="$refs.datePicker0[i].save(list.aplEnd)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </span> -->
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="1">
                <span v-if="(i+1 == wrkngList.length ? true : false)" class="">
                    <v-btn class="" color="blueBtnColor" height="26" @click="preEmpWrkHrInfo(i)" icon>
                        <v-icon>mdi-content-save-edit-outline</v-icon>
                    </v-btn>
                </span>
                <span v-if="list.empWrkHrPk == 0" @click="$parent.$parent.listCd3.splice(i, 1)" class="">
                    <v-btn class="" color="grey006" height="26" icon>
                        <v-icon>mdi-minus-circle-outline</v-icon>
                    </v-btn>
                </span>
                <span v-if="(i+1 == wrkngList.length && list.empWrkHrPk != 0 ? true : false)" class="">
                    <v-btn class="" color="grey006" height="26" @click="$parent.$parent.removeEmpWrkHr(list.empWrkHrPk)" icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'WrkngHoursTbl',

    props : {
        prtClcd: { type: String, default: '' },
        prtList: { type: Array, default: () => { return [] }, }
    },
        
    components: {
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
        
    }, 

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.dummyWrkngInfo.wrkHrClcd = this.prtClcd
            //this.addRow(this.dummyWrkngInfo)
        })
    },

    computed: {

    },

    watch:{
        'prtList': function () {
            this.getListAfter(this.prtList)
        }
    },
    
    methods: {
        getListAfter: function (res) {
            this.wrkngList.splice(0)
            
            if(this.prtClcd == ''){
                let timezoneOffset = new Date().getTimezoneOffset() * 60000
                let timezoneDate = new Date(Date.now() - timezoneOffset)

                res.forEach((item, i) => {
                    item.aplStrt = timezoneDate.toISOString().split('T',2)[0].substring(0, 10) 

                    this.addRow(item)
                    this.wrkStrt.splice(i, 1 , '')
                    this.wrkEnd.splice(i, 1 , '')
                });
            }
            else{
                res.forEach((item, i) => {
                    item.aplStrt = item.aplStrt.beforeDateFormatHyp()
                    item.aplEnd = item.aplEnd.beforeDateFormatHyp()
                    this.addRow(item)
                    this.wrkStrt.splice(i, 1 , item.wrkTmHhStrt + ':' + item.wrkTmMmStrt)
                    this.wrkEnd.splice(i, 1 , item.wrkTmHhEnd + ':' + item.wrkTmMmEnd)
                    this.actWrkHrCalc(i)
                });
            }

        },
        preEmpWrkHrInfo: function (index) {
            //적용 종료일 삭제 전 적용시작일 보다 큰 적용 시작일 적용 2999-12-31 현재 표기
            let obj = Object.assign({}, this.wrkngList[index])
            obj.aplStrt = obj.aplStrt.afterDateFormatHyp()
            obj.aplEnd = obj.aplEnd.afterDateFormatHyp()
            obj.userNm = this.$store.getters.getUserNm
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.empPk = this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
            obj.wrkTmHhStrt = this.wrkStrt[index].split(':', 2)[0]
            obj.wrkTmMmStrt = this.wrkStrt[index].split(':', 2)[1]
            obj.wrkTmHhEnd = this.wrkEnd[index].split(':', 2)[0]
            obj.wrkTmMmEnd = this.wrkEnd[index].split(':', 2)[1]
            obj.ord = this.$parent.$parent.maxOrd + 1
   
            let rrr = this.dataInspc(obj)
            if(rrr == 0)
                this.$parent.$parent.snackControll([true, 5000, '기존 등록된 적용시작일보다 이후 날짜만 입력 가능합니다.', 'error'])
            else if(rrr == 1)
                this.$parent.$parent.snackControll([true, 5000, '현재 적용 시작일에 진행중인 스케쥴이 존재합니다.', 'error'])

        },
        dataInspc: function (obj) {
            let sprOpr = []
            let tmpDate = obj.aplStrt.beforeDateFormatHyp()

            if(obj.wrkHrClcd == '1'){
                this.$parent.$parent.$refs.wrkCd1.wrkngList.forEach(items => {
                    sprOpr.push(items.aplStrt)
                });

                sprOpr.sort((x, y) => new Date(x) - new Date(y))

                if(new Date(sprOpr[sprOpr.length-1]) >= new Date(tmpDate))
                    return 0
            }
            else if(obj.wrkHrClcd == '2'){
                this.$parent.$parent.$refs.wrkCd2.wrkngList.forEach(items => {
                    sprOpr.push(items.aplStrt)
                });

                sprOpr.sort((x, y) => new Date(x) - new Date(y))

                if(new Date(sprOpr[sprOpr.length-1]) >= new Date(tmpDate))
                    return 0
            }

            if(this.$refs.wrkngHours.validate())
                this.$parent.$parent.setEmpWrkHr(obj)
        },
        actWrkHrCalc: function (index) {
            if(this.wrkStrt[index] != '' && this.wrkEnd[index] != ''){
                //시간을 분단위로 변경
                let strtMm = ( Number(this.wrkStrt[index].split(':', 2)[0]) * 60 )
                    + Number( this.wrkStrt[index].split(':', 2)[1] )
                let endMm = ( Number(this.wrkEnd[index].split(':', 2)[0]) * 60 )
                    + Number( this.wrkEnd[index].split(':', 2)[1] )
                
                if(strtMm <= endMm){
                    let hh = Math.floor( ( endMm - strtMm - this.wrkngList[index].brkDay - this.wrkngList[index].brkNgt - this.wrkngList[index].brkOvn ) / 60 )
                    let mm = ( endMm - strtMm - this.wrkngList[index].brkDay - this.wrkngList[index].brkNgt - this.wrkngList[index].brkOvn ) % 60
                    this.wrkngList[index].actWrkTxt = hh + '시간 ' + mm + '분'
                    this.wrkngList[index].actWrkHr = (hh * 60) + mm
                    this.wrkngList[index].wrkOvnYn = "2"
                }
                else if(strtMm > endMm){
                    let hh = Math.floor( (((24 * 60) - strtMm) + endMm - this.wrkngList[index].brkDay - this.wrkngList[index].brkNgt - this.wrkngList[index].brkOvn ) / 60 )
                    let mm = ( ((24 * 60) - strtMm) + endMm - this.wrkngList[index].brkDay - this.wrkngList[index].brkNgt - this.wrkngList[index].brkOvn ) % 60
                    this.wrkngList[index].actWrkTxt = hh + '시간 ' + mm + '분'
                    this.wrkngList[index].actWrkHr = (hh * 60) + mm
                    this.wrkngList[index].wrkOvnYn = "1"
                }
                this.forceRender += 1    
            }
            else return 0

        },
        chkAplVal: function (sltIndex){
            let stn = new Date(this.wrkngList[sltIndex].aplStrt)
            let chk = false

            for (let index = 0; index < this.wrkngList.length-1; index++) {
                let trgt = new Date(this.wrkngList[index].aplEnd)
                if(trgt != '2099-12-31')
                    chk = trgt >= stn
            }

            if(chk){
                this.snackControll([true, 2500, '이미 등록된 날짜 범위입니다.', 'error'])
                this.$refs.datePicker[sltIndex].save('')
            }
        },
        chkSumRow: function (){
            let chk = false
            this.wrkngList.forEach(item => {
                if(item.aplEnd == '2099-12-31') chk = true
            });
            if(chk)
                this.snackControll([true, 5000, '잘못된 적용 종료일 값으로 인해 추가할 수 없습니다.', 'error'])
            else
                this.addRow(this.dummyWrkngInfo)
        },
        clearForm: function () {
            this.wrkngList.splice(0)
            this.menuStrt.splice(0)
            this.menuEnd.splice(0)
            this.wrkStrt.splice(0)
            this.wrkEnd.splice(0)
            this.aplStrt.splice(0)
            this.aplEnd.splice(0)
            
            this.$refs.wrkngHours.resetValidation()
            this.forceRender =+ 1
        },
        addRow: function (tmp){
            let obj = Object.assign({}, tmp)

            this.wrkngList.push(obj)
            
            this.menuStrt.push(false)
            this.menuEnd.push(false)
            this.wrkStrt.push('')
            this.wrkEnd.push('')
            this.aplStrt.push('')
            this.aplEnd.push('')
        },
        datePickClose: function (value) {
            this.aplStrt.splice(value, 1, false)
        },
        datePickClose0: function (value) {
            this.aplEnd.splice(value, 1, false)
        },
    },

    data: () => ({
        forceRender: 0,
        menuStrt: [],
        menuEnd: [],
        wrkStrt: [],
        wrkEnd: [],
        aplStrt: [],
        aplEnd: [],
        wrkngList: [],
        dummyWrkngInfo: {
            empWrkHrPk: 0, empPk: 0, aplStrt: '', aplEnd: '2999-12-31', wrkOvnYn: 1, brkDay: 0, brkNgt: 0, brkOvn: 0,
            wrkTmHhStrt: '', wrkTmMmStrt: '', wrkTmHhEnd: '', wrkTmMmEnd: '', actWrkHr: 0, actWrkTxt: '', wrkHrClcd: '1'
        },
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9\s+]*$/.test(value) || 'Number Validate.',
        },
    }),
};
</script>