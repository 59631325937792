<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold blue--text">'{{itmNm}}'</span>
            <span class="pl-2 text-h4 font-weight-bold">손익상세관리</span>
            <span class="text-h4 font-weight-bold">&#40;</span>
            <span class="text-h4 font-weight-bold blue--text" v-if="calcClcd=='1'&& stdClcd=='1'">수익</span>
            <span class="text-h4 font-weight-bold blue--text" v-else-if="calcClcd=='1' && stdClcd=='2'">수입</span>
            <span class="text-h4 font-weight-bold red--text" v-else-if="calcClcd=='2' && stdClcd=='1'">비용</span>
            <span class="text-h4 font-weight-bold red--text" v-else-if="calcClcd=='2' && stdClcd=='2'">지출</span>
            <span class="text-h4 font-weight-bold">&#41;</span>
            <v-spacer></v-spacer>                                   
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <div class="d-flex justify-left mb-2 align-center">
            <v-spacer></v-spacer> 
            <span v-if="progress">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">   
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="list.disabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>     
            </span> 
        </div>
        <v-row class="ma-0 pa-0 mt-2" no-gutters>
            <v-col cols="12" class="ma-0 pa-0">
                <v-data-table 
                     locale="ko"
                    :headers="prfLosDtlHeader" 
                    :items="prfLosDtlList"
                    height="350"
                    fixed-header
                    hide-default-header 
                    hide-default-footer 
                    disable-pagination
                    dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:20% !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:25% !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader tableBT tableBR" style="width:15% !important;">{{props.headers[2].text}}</th>                                
                                <th class="black--text clmsFixedHeader tableBT" style="width:40% !important;">{{props.headers[3].text}}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr>
                            <td v-if="index==0" class="clmsBodyCommon tableBR" :rowspan="prfLosDtlList.length">                                
                                <span class="font-weight-bold blue--text" v-if="calcClcd=='1'&& stdClcd=='1'">수익</span>
                                <span class="font-weight-bold blue--text" v-else-if="calcClcd=='1' && stdClcd=='2'">수입</span>
                                <span class="font-weight-bold red--text" v-else-if="calcClcd=='2' && stdClcd=='1'">비용</span>
                                <span class="font-weight-bold red--text" v-else-if="calcClcd=='2' && stdClcd=='2'">지출</span>
                            </td> 

                            <td v-if="index==0" class="clmsBodyCommon tableBR" :rowspan="prfLosDtlList.length">
                                {{itmNm}}
                            </td>                                
                            <td class="clmsBodyCommon fixedCol0 tableBR">
                                {{item.itmDtlNm}}
                            </td> 
                            <td class="clmsBodyCommon fixedCol0">                               
                                <v-row no-gutters>
                                    <v-col class="py-1" cols="6">
                                        <span class="txtfldRT">
                                            <v-text-field color="blue" v-model="item.amt" type="number" @input="setTotAmt" hide-details outlined dense></v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="6" class="py-1 pl-1">
                                        <span class="d-flex justify-end">
                                            {{item.amt?.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}}
                                        </span>
                                    </v-col>
                                </v-row>   
                            </td>                                
                        </tr>
                    </template>
                    <template v-slot:[`body.append`]="{ item }">
                        <tr style="z-index: 4 !important; position: sticky; bottom: 0px;">
                            <td class="fixedTable fixedPstn0 clmsHeaderCommon black--text tableBR tableBT fixedTable py-2" style="" colspan="3">합계</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" colspan="1">{{Math.floor(prfLosDtlSum.totAmt)?.toLocaleString('ko-KR')}}{{item}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
import { insMultiFcltyPrfLosDtl, selFcltyPrfLosDtlList, } from '../../api/impExp.js';

export default {
    name: 'FcltyPrfLosDtlMdl',
    
    components: {            
    },

    props : {        
        yymm       : { type: String, default: '' },
        itmCd       : { type: String, default: '' },
        itmNm       : { type: String, default: '' },
        calcClcd    : { type: String, default: '' },
        stdClcd     : { type: String, default: '' },
    },

    computed: {
    },

    created: function(){  

    },

    mounted: function(){
        this.$nextTick(function () {
            this.calcClcdNm =  this.getClcdNm(this.calcClcd)
            this.getFcltyPrfLosDtlList()
        })
    },

    watch: {
    },

    methods: {   
        //기관손익관리상세 목록 조회  
        getFcltyPrfLosDtlList: function () {
            this.prfLosDtlList.splice(0)
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                inqYYMM     : this.yymm,
                stdClcd     : this.stdClcd,
                itmCd       : this.itmCd,
            }
            selFcltyPrfLosDtlList(obj)
                .then((response) => ( this.getFcltyPrfLosDtlListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selFcltyPrfLosDtlList : ' + error))
        },

        //기관손익관리상세 저장
        setMultiFcltyPrfLosDtl: function (obj) {
            this.progress = true
            insMultiFcltyPrfLosDtl(obj)
                .then((response) => ( this.setMultiFcltyPrfLosDtlAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/insMultiFcltyPrfLosDtl : ' + error))
        },

        getFcltyPrfLosDtlListAfter:function(res){            
            if (res.statusCode == 200) {
                if(res.result != null){
                    res.result.forEach(el => {                        
                        this.prfLosDtlList.push(el)
                    });
                }
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.setTotAmt()            
        },

        setMultiFcltyPrfLosDtlAfter:function(res){
            this.progress = false
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info']) 
                this.status='save'
                this.getFcltyPrfLosDtlList()
                // this.$emit('modalEvent', 99)                
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },


        onClickBtn:function(text){
            switch (text) {
                case '저장':
                    this.preSetSave()
                    break;    
                case '전체 금액 지우기':
                    this.resetAmt()
                    break;
                default:
                    break;
            }
        },

        preSetSave:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                userNm      : this.$store.getters.getUserNm,
                stdYymm     : this.yymm,
                calcClcd    : this.calcClcd,
                stdClcd     : this.stdClcd,
                itmCd       : this.itmCd,
                itmNm       : this.itmNm,
                amt         : this.prfLosDtlSum.totAmt,
                list        : [],
            }


            this.prfLosDtlList.forEach(el => {
                let itm = {                    
                    itmDtlCd    : el.itmDtlCd,
                    itmDtlNm    : el.itmDtlNm,
                    amt         : (el.amt != null && el.amt != '')?Number(el.amt):0,
                }

                obj.list.push(itm)                
            });
            this.setMultiFcltyPrfLosDtl(obj)
        },

        setTotInpt:function(){
            let obj = {
                stdYymm     : this.yymm,
                stdClcd     : this.stdClcd,
                calcClcd    : this.calcClcd,
                itmCd       : this.itmCd,
                amt         : this.prfLosDtlSum.totAmt,
            }
            this.$emit('setAmt', obj)

            this.status = ''
        },

        getClcdNm(val){
            let restn = ''
            switch (val) {
                case '1':
                    restn = '수익'
                    break;
                case '2':
                    restn = '비용'
                    break;
                default:
                    break;
            }

            return restn
        },

        //합계 계산
        setTotAmt:function(){    
            this.prfLosDtlSum.totAmt = 0                    

            this.prfLosDtlList.forEach(itm => {                
                this.prfLosDtlSum.totAmt          += (itm.amt != null)?Number(itm.amt):0
            });         
            
            if(this.status == 'save') this.setTotInpt()
        },
        
        resetAmt:function(){
            this.prfLosDtlSum.totAmt = 0                    

            this.prfLosDtlList.forEach(itm => {                
                itm.amt = 0
            }); 
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
        onClose: function () {
            this.$emit('isClose')
        },
    },
    data: () => ({ 
        forceRender     : 0, 
        status          : '',
        progress        : false,  
        calcClcdNm      : '',     
        prfLosDtlHeader : [
            { text: '계산구분', value: '', align: 'center', },
            { text: '항목', value: '', align: 'center', },
            { text: '상세항목', value: '', align: 'center', },
            { text: '금액', value: '', align: 'center', },
        ],
        prfLosDtlList   : [],
        prfLosDtlSum    : {totAmt:0,orgTotAmt:0,},
        snackOptions    : [false, 3000, '', 'info'],        
        filledBtn: [                        
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-eraser', class: 'ml-1 white--text', color:'grey006', text: '전체 금액 지우기', type: 'action', visible: true, disabled: false,},
        ], 
    }),
};
</script>