<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">7-7. 직원건강관리</span>
            </v-col>
            <v-col class="d-flex pr-2" cols="7">
                <v-spacer></v-spacer>
                <!-- btn area -->
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue'"
                        v-model="dialog[i]" :max-width="list.width" content-class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                            <div v-else>개발중</div>
                    </v-dialog>
                </span>
                <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                    <v-dialog
                        v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <div>개발중</div>  
                    </v-dialog>
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-5" no-gutters>
            <v-col cols="12">
                <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-3">    
            <v-col class="ma-0 pa-0" cols="12">
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />

                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-3">
            <v-col class="ma-0 pa-0" cols="12">
                <v-data-table hide-default-footer dense locale="ko" height="670" min-width="1360"
                    hide-default-header fixed-header 
                    :headers="empHlthHeader" 
                    :items="empHlthList" 
                    :search="schDummy"  
                    :custom-filter="customFilter"               
                    class="" disable-pagination>

                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>                            
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 170px !important;" colspan="1" rowspan='2'>{{props.headers[1].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 130px !important;" colspan="1" rowspan='2'>{{props.headers[2].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[3].text}}</th>                            
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 60px !important;" colspan="1" rowspan='2'>{{props.headers[4].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[5].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 120px !important;" colspan="1" rowspan='2'>{{props.headers[6].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 130px !important;" colspan="1" rowspan='2'>{{props.headers[7].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBT" style="" colspan="2" rowspan='1'>{{props.headers[8].text}}</th>
                            </tr>
                            <tr style="border-top: 1px solid #dbdbdb !important;">
                                <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 120px !important;">{{props.headers[9].text}}</th>
                                <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 120px !important;">{{props.headers[10].text}}</th>
                            </tr>
                        </thead>
                    </template>     
                    <template v-slot:item='{ item }'>
                        <tr>                        
                            <td class="clmsBodyCommon tableBR">{{item.wrkStcd}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.jobCd}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.name}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.rsdntBirth?.beforeDateFormatDot()}}</td>
                            <td class="clmsBodyCommon tableBR">
                                <span class="d-inline-block pt-1" style="">
                                    <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                                    <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                                </span>
                            </td>
                            <td class="clmsBodyCommon tableBR">{{item.entDt?.beforeDateFormatDot()}}</td>
                            <td class="clmsBodyCommon tableBR">{{item.rtrDt?.beforeDateFormatDot()}}</td>
                            <td class="fixedCol clmsBodyCommon tableBR" @click="onModelHlthChk(item.mmbrPk, item.hltChcInfPk)">
                                <span v-if="item.hltChcDt == null || item.hltChcDt == ''">
                                    <span v-if="item.rtrYn == '2'" class="d-inline-block red--text">미작성</span>
                                    <span v-else class="d-inline-block">퇴직</span>
                                </span>
                                <span v-else>{{item.hltChcDt?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="fixedCol clmsBodyCommon tableBR" @click="onModelMsclsSystm(item,0)">
                                <span v-if="item.wrtDt == null || item.wrtDt == ''">
                                    <span v-if="item.rtrYn == '2'" class="d-inline-block red--text">미작성</span>
                                    <span v-else class="d-inline-block">퇴직</span>
                                </span>
                                <span v-else>{{item.wrtDt?.beforeDateFormatDot()}}</span>
                            </td>
                            <td class="fixedCol clmsBodyCommon" @click="onModelMsclsSystm(item,2)">
                                <div class="d-flex justify-center align-center" style="height:100%" >
                                    <div v-if="item.wrkTgYn == '1'">
                                        <span v-if="item.wrkDt == null || item.wrkDt == ''" class="d-inline-block">
                                            <span v-if="item.rtrYn == '2'" class="d-inline-block red--text">미작성</span>
                                            <span v-else class="d-inline-block">퇴직</span>
                                        </span>
                                        <span v-else>{{item.wrkDt?.beforeDateFormatDot()}}</span>
                                    </div>
                                    <div v-else>
                                        <span class="d-inline-block">해당없음</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="modal[0]" persistent max-width="800" min-width="800">                
                <hlthChckWrt 
                    ref="hlthChckWrt"
                    @modalEvent="modalEvent"></hlthChckWrt>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="modal[1]" persistent max-width="1400" min-width="1400">                
                <msclsSystmChkWrk 
                    ref="msclsSystmChkWrk"
                    @modalEvent="modalEvent"></msclsSystmChkWrk>
            </v-dialog>
        </div> 
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { selEmpHltChcList } from '../../api/emp.js';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import hlthChckWrt from '../../components/emp/HlthChckWrt.vue'; //건강검진
import msclsSystmChkWrk from '../../components/emp/MsclsSystmChkWrk.vue'; //근골격계


export default {
    name: 'EmpHlthMgmt',
        
    components: {
        exBtn,
        expandYearOnlyPicker,
        hlthChckWrt,
        msclsSystmChkWrk,
    },

    created: function(){
        this.getCode(['42', '70'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {

            this.vCd42 = res.vCd42
            this.vCd70 = res.vCd70

            this.vCd42.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })
            this.vCd70.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })

            this.getEmpHltChcList()
        },
        getYear: function (yyyy){
            this.year = yyyy
            this.getEmpHltChcList()
        },
        getEmpHltChcList:function(){       
            selEmpHltChcList(this.$store.getters.getFcltyNum, this.year)
                .then((response) => ( this.getEmpHltChcListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/updlWgePymCnfrm : ' + error))
        },
        getEmpHltChcListAfter:function(res){
            this.empHlthList = res
            this.empHlthList.forEach(item => {
                
                item.rtrYn = '2'

                if(item.rtrDt != null && item.rtrDt != ''){
                    let nowYear = Number(this.year)
                    let rtrYear = Number(item.rtrDt.slice(0,4))

                    if(rtrYear <= nowYear){
                        item.rtrYn = '1'
                    }
                }

                this.vCd42.forEach(cd => {
                    if(item.jobCd == cd.valcd) item.jobCd = cd.valcdnm
                })

                this.vCd70.forEach(cd => {
                    if(item.wrkStcd == cd.valcd) item.wrkStcd = cd.valcdnm
                })
            });
        },
        setSearchText: function (value) {
            this.schField = value
            this.tableSelected.splice(0)
            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'wrkStcd': this.schChkField.wrkStcd.splice(0)
                    sch.forEach((item) => {
                        this.schChkField.wrkStcd.push(item)
                    });
                    break;
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.wrkStcd.length != 0){
                this.schChkField.wrkStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.wrkStcd.includes(stcd)
                    else col1 = col1 || filter.wrkStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.jobCd.includes(stcd)
                    else col2 = col2 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'wrkStcd'
                    break;
                case 1: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        //건강검진 모달
        onModelHlthChk:function(mmbr, pk){            
            this.$refs.hlthChckWrt.onLoad(mmbr, pk)
            this.modal.splice(0, 1, true)
        },
        onModelMsclsSystm:function(item, tabIdx){
            this.$refs.msclsSystmChkWrk.onLoad(item, tabIdx)
            this.modal.splice(1, 1, true)
        },
        modalEvent: function (event, msg=null) { 
            if(event == 99){
                this.getEmpHltChcList()
            }else{
                this.modal.splice(event, 1, false)
                if(msg != null){
                    this.snackControll([true, 2500, msg, 'info'])
                }
            }      
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        year:'',
        vCd42: [],      //직원 직종 코드
        vCd70: [],      //직원 상태 코드
        dialog:[],
        modal:[false],
        schField: '',
        schDummy: '',
        schChkField: { wrkStcd: [], jobCd: [], target: 'wrkStcd' },
        empHlthHeader:[
            { text: '상태', value: '', align: 'center'},
            { text: '직종', value: '', align: 'center'},
            { text: '이름', value: '', align: 'center'},
            { text: '생년월일', value: '', align: 'center'},
            { text: '성별', value: '', align: 'center'},
            { text: '입사일', value: '', align: 'center'},
            { text: '퇴사일', value: '', align: 'center'},
            { text: '건강검진(연1회)', value: '', align: 'center'},
            { text: '근골격계 증상검사', value: '', align: 'center'},
            { text: '작성일자', value: '', align: 'center'},
            { text: '조치일자', value: '', align: 'center'},
        ],
        empHlthList:[],
        exBtn: [
            { index:  0, btnTitle: '상태', btnIcon: 'house', exWidth: 104, exHeigth: 100, expandList: [], },
            { index:  1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        filledBtn: [
            // { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
            // { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>