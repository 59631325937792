<template>
    <v-sheet class="pt-4">
        <v-row class="pa-0" no-gutters>
            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">연계기록지</span>
            <v-spacer></v-spacer>
            <v-btn
                class="white--text" height="26" color="blueBtnColor"
                @click="pre_RcrdInf()" :disabled="saveBtn" rounded samll>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span class="fontOneRem">저장</span>
            </v-btn>
        </v-row>
        
        <v-form ref="form" lazy-validation>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="pa-1" cols="4">
                    <span class="d-inline-block" style="width: 130px;">
                        <CmmDateComp
                            v-model="rcrdInfo.wrtDt"
                            :required="true">
                        </CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-left align-center" cols="4">
                    <span class="d-inline-block ml-1" style="width: 100px;">
                        <v-text-field
                            v-model="rcrdInfo.wrtr" :rules="[rules.required]"
                            readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3 d-inline-block"
                        style="padding: 0px 1px 0px 2px !important;"
                        min-width="30" height="26" @click="onModal"
                        dark small>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                    <span class="black--text font-weight-medium">연계사유</span>
                </v-col>
                <v-col class="py-2" cols="10">
                    <span class="black--text font-weight-medium">
                        <v-radio-group
                            class="ml-2"
                            v-model="rcrdInfo.lvClcd" :rules="[rules.required]"
                            hide-details row>
                            <span class="d-flex justify-right">
                                <v-radio :ripple="false" label="퇴소" value="1"></v-radio>
                                <v-radio :ripple="false" label="전원" value="2"></v-radio>
                                <v-radio :ripple="false" label="사망" value="3"></v-radio>
                            </span>
                        </v-radio-group>
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 pt-9" cols="2">
                    <div class="txtAlgnMid mb-1">
                        <span class="black--text font-weight-medium">제공급여</span>
                    </div>
                    <div class="txtAlgnMid">
                        <v-btn
                            class="nmlBfClrImp-white ml-1" color="blueBtnColor"
                            style="padding: 7px 6px 6px 6px !important"
                            @click="rcrdInfo.bnftOfrCntnt = bnftOfr"
                            :disabled="bnftOfr == ''"
                            min-width="30" height="25" small outlined>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <span class="fontEighthRem" style="padding-bottom: 2px;">불러오기</span>
                                    </div>
                                </template>
                                <span>급여제공계획 급여항목에서 불러옴</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1" cols="4">
                    <v-textarea
                        class="rmrksArea" rows="6"
                        v-model="rcrdInfo.bnftOfrCntnt" :rules="[rules.required]"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
                <v-col class="greyE01 pt-9" cols="2">
                    <div class="txtAlgnMid mb-1">
                        <span class="black--text font-weight-medium">심신기능&nbsp;상태</span>
                    </div>
                    <div class="txtAlgnMid">
                        <v-btn
                            class="nmlBfClrImp-white ml-1" color="blueBtnColor"
                            style="padding: 7px 6px 6px 6px !important"
                            @click="rcrdInfo.mBdyFnctnStts = exmStt"
                            :disabled="exmStt == ''"
                            min-width="30" height="25" small outlined>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <span class="fontEighthRem" style="padding-bottom: 2px;">불러오기</span>
                                    </div>
                                </template>
                                <span>욕구사정 총평에서 불러옴</span>
                            </v-tooltip>
                        </v-btn>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1" cols="4">
                    <v-textarea
                        class="rmrksArea" rows="6"
                        v-model="rcrdInfo.mBdyFnctnStts" :rules="[rules.required]"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1" cols="2">
                    <div>
                        <div class="txtAlgnMid">
                            <span class="black--text font-weight-medium">퇴소&nbsp;후&nbsp;서비스</span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="black--text font-weight-medium">이용계획</span>
                        </div>
                        <div class="txtAlgnMid">
                            <span class="black--text font-weight-medium">&#40;수급자&nbsp;희망급여&#41;</span>
                        </div>
                    </div>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-1" cols="4">
                    <v-textarea
                        class="rmrksArea" rows="9"
                        v-model="rcrdInfo.lvSrvcPlan" :rules="[rules.required]"
                        no-resize hide-details outlined dense>
                    </v-textarea>
                </v-col>
                <v-col class="greyE01" cols="2">
                    <div class="d-flex justify-center align-center tableBB" style="height: 50%;">
                        <span class="black--text font-weight-medium">특이사항</span>
                    </div>
                    <div class="d-flex justify-center align-center" style="height: 50%;">
                        <span class="black--text font-weight-medium">종합소견</span>
                    </div>
                </v-col>
                <v-col class="" cols="4">
                    <div class="d-flex justify-center align-center tableBB pa-1" style="height: 50%;">
                        <v-textarea
                            class="rmrksArea" rows="4"
                            v-model="rcrdInfo.spclNote"
                            no-resize hide-details outlined dense>
                        </v-textarea>
                    </div>
                    <div class="d-flex justify-center align-center pa-1" style="height: 50%;">
                        <v-textarea
                            class="rmrksArea" rows="4"
                            v-model="rcrdInfo.gnrlOpnn"
                            no-resize hide-details outlined dense>
                        </v-textarea>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
        </v-form>

        <v-divider class="bodyColor my-2 mb-4" style="padding: 1px 0px;"></v-divider>

        <v-row class="align-center" no-gutters>
            <v-icon style="font-size: 28px; width: 8px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold ml-3">연계&nbsp;이력</span>
            <v-spacer></v-spacer>
            <!-- <span class="grey006--text" style="font-size: 0.875rem;">&#8251;&nbsp;연계기록지 발송 기능은 추후 제공될 예정입니다.</span> -->
        </v-row>

        <v-divider :class="['mt-2', rcrdList.length > 2 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['pa-0 greyE01', rcrdList.length > 2 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="1">
                <span class="black--text font-weight-medium">연번</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="1">
                <span class="black--text font-weight-medium">사유</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="2">
                <span class="black--text font-weight-medium">작성일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="2">
                <span class="black--text font-weight-medium">작성자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="2">
                <span class="black--text font-weight-medium">수신자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="2">
                <span class="black--text font-weight-medium">발송일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBL py-2" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="['', rcrdList.length > 2 ? 'mr-2__5' : '']"></v-divider>

        <div
            class="overflow-auto d-inline-block"
            style="width: 100%; height: 74px;"
            v-if="rcrdList?.length > 0">
            <v-row
                class="fixedCol tableBB pa-0" style="cursor: pointer;"
                v-for="(list, i) in rcrdList" :key="i"
                @click="tblEvt(i)"
                no-gutters>
                <v-col :class="['txtAlgnMid py-1', sltItm == i ? 'sltRow' : '' ]" cols="1">
                    <span class="black--text">{{ list.rn }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="1">
                    <span v-if="list.lvClcd == 1">퇴소</span>
                    <span v-else-if="list.lvClcd == 2">전원</span>
                    <span v-else-if="list.lvClcd == 3">사망</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="2">
                    <span class="black--text">{{ list.lrWrtDt == null ? '' : $moment(list.lrWrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="2">
                    <span class="black--text">{{ list.wrtr }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="2">
                    <span class="black--text">{{ list.rcvNm }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="2">
                    <span class="black--text">{{ list.sndDt == null ? '' : $moment(list.sndDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col :class="['txtAlgnMid tableBL py-1', sltItm == i ? 'sltRow' : '' ]" cols="2">
                    <span class="mr-1">
                        <v-btn color="grey006" height="26" @click="delLinkRcrd(list.linkRcrdPk)" icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                                    </div>
                                </template>
                                <span>연계기록지 삭제</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                    <span class="mr-1">
                        <v-btn color="grey006" height="26" @click="rcrdPrt(list.linkRcrdPk)" icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi-printer-outline</v-icon>
                                    </div>
                                </template>
                                <span>연계기록지 출력</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                    <span>
                        <v-btn color="grey006" height="26" @click="onSndMdl(list.linkRcrdPk, list.lrWrtDt)"  icon>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="18">mdi-arrow-expand-right</v-icon>
                                    </div>
                                </template>
                                <span>연계기록지 발송</span>
                            </v-tooltip>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="pa-0 tableBB" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="grey006--text" style="font-size: 0.925rem;">&#8251;조회된&nbsp;항목이&nbsp;없습니다.</span>
            </v-col>
        </v-row>

        <v-dialog v-model="menu[1]" content-class="round" max-width="800" persistent eager>
            <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "rcrdInfo.wrtDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { sellinkRcrdList, sellinkRcrdInfo, insLinkRcrd, delLinkRcrd } from '../../api/bnfcr.js';
import { selBnfcrExamSmryList, selLnkRcrdBnftOfrInfo } from '../../api/bnftoffer.js';

import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'BnfcrExtLinkRecRcrd',

    props: {

    },
            
    components: {
        EmpSelector,
        CmmDateComp,
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getRcrdList()
            this.getExamSmryList()
            this.getLnkRcrdInf(this.$store.getters.getBnfcrState.bnmmbr, this.$store.getters.getBnfcrState.bnfcr)
        })
    },

    computed: {
        getBnMmbrPk: function(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
    },

    watch:{
        getBnMmbrPk: function(v){
            this.sltItm = -1

            if(v == 0)
                this.clearInf()
            else{
                this.getExamSmryList()
                this.getLnkRcrdInf(v, this.$store.getters.getBnfcrState.bnfcr)
                this.getRcrdList()
            }
        },
        'sltItm': function(){
            if(this.sltItm == -1)
                this.clearInf()
            else
                this.getRcrdInfo(this.rcrdList[this.sltItm].linkRcrdPk)
        },
    },
        
    methods: {
        // 연계 이력 단건 조회
        getRcrdInfo: function (lrPk) {
            this.saveBtn = true

            sellinkRcrdInfo(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr, lrPk)
                .then((response) => ( this.getRcrdInfRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/sellinkRcrdInfo : ' + error))
        },

        // 연계 이력 다건 조회
        getRcrdList: function () {
            this.saveBtn = true
            this.rcrdList.splice(0)

            sellinkRcrdList(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState.bnmmbr)
                .then((response) => ( this.getRcrdListRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/sellinkRcrdList : ' + error))
        },

        // 연계 기록지 저장
        setLinkRcrd: function (obj) {
            insLinkRcrd(obj)
                .then((response) => ( this.lvLinkRcrd(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/inslinkRcrd : ' + error))
        },

        // 심신기능 텍스트 조회
        getExamSmryList: function () {
            this.exmStt = ''

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum,
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.clsfc1Cd = '101'
            obj.clsfc2Cd = '999'

            selBnfcrExamSmryList(obj)
                .then((response) => ( this.examSmryRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrExamSmryList : ' + error))
        },

        // 제공급여 텍스트 조회
        getLnkRcrdInf: function (pk, bnfcr) {
            this.bnftOfr = ''

            selLnkRcrdBnftOfrInfo(this.$store.getters.getFcltyNum, pk, bnfcr)
                .then((response) => ( this.lnkRcrdRst(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selLnkRcrdBnftOfrInfo : ' + error))
        },

        // 연계 기록지 삭제
        delLinkRcrd: function (lrPk) {
            delLinkRcrd(lrPk, this.$store.getters.getUserNm)
                .then((response) => ( this.lvLinkRcrd(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delLinkRcrd : ' + error))
        },

        getRcrdInfRst: function (res) {
            if(typeof res != 'undefined')
                this.setInf(res)

            this.saveBtn = false
        },

        getRcrdListRst: function (res) {
            if(res.length > 0)
                this.rcrdList = res

            this.saveBtn = false
        },

        lvLinkRcrd: function (res) {
            if(res.statusCode == 200){
                if(this.sltItm == -1)
                    this.clearInf()

                this.sltItm = -1
                this.getRcrdList()
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        examSmryRst: function (res) {
            if(res.length > 0){
                res.forEach(itm => {
                    if(itm.itmVal == null)
                        this.exmStt = ''
                    else
                        this.exmStt = itm.itmVal
                });
            }
        },

        lnkRcrdRst: function (res) {
            if(Object.keys(res).length > 0)
                this.bnftOfr = res.clsfc3Cdnms
        },

        pre_RcrdInf: function () {
            this.saveBtn = true
            let obj = Object.assign({}, this.rcrdInfo)
            obj.wrtDt = this.$moment(obj.wrtDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.userNm = this.$store.getters.getUserNm
            
            if(this.$refs.form.validate())
                this.setLinkRcrd(obj)
            else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, '필수 항목을 작성해 주세요.', 'warning'])
                this.saveBtn = false
            }
        },

        tblEvt: function (idx) {
            if(this.sltItm == idx)
                this.sltItm = -1
            else
                this.sltItm = idx
        },

        // 연계 기록지 출력 팝업
        rcrdPrt: function (lrPk){
            this.$parent.$parent.$parent.$parent.onRcrdPrt(lrPk)
        },

        setInf: function (obj) {
            this.rcrdInfo.linkRcrdPk = obj.linkRcrdPk
            this.rcrdInfo.bnMmbrPk = obj.bnMmbrPk
            this.rcrdInfo.lvInfPk = obj.lvInfPk
            this.rcrdInfo.lvClcd  = obj.lvClcd
            this.rcrdInfo.bnftOfrCntnt = obj.bnftOfrCntnt
            this.rcrdInfo.gnrlOpnn = obj.gnrlOpnn
            this.rcrdInfo.wrtr = obj.wrtr
            this.rcrdInfo.wrtDt = this.$moment(obj.wrtDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.rcrdInfo.lvSrvcPlan = obj.lvSrvcPlan
            this.rcrdInfo.spclNote = obj.spclNote
            this.rcrdInfo.mBdyFnctnStts = obj.mBdyFnctnStts
        },

        clearInf: function () {
            this.rcrdInfo.linkRcrdPk = 0
            this.rcrdInfo.bnMmbrPk = 0
            this.rcrdInfo.lvInfPk = 0
            
            this.rcrdInfo.lvClcd  = ''
            this.rcrdInfo.bnftOfrCntnt = ''
            this.rcrdInfo.gnrlOpnn = ''
            this.rcrdInfo.wrtr = ''
            this.rcrdInfo.wrtDt = ''
            this.rcrdInfo.lvSrvcPlan = ''
            this.rcrdInfo.spclNote = ''
            this.rcrdInfo.mBdyFnctnStts = ''

            this.$refs.form.resetValidation()
        },

        onSndMdl: function (lrpk, dt) {
            this.$parent.$parent.$parent.$parent.onSndMsg(lrpk, dt)
        },

        onModal: function () {
            this.menu.splice(1, 1, true)
        },

        isModal: function () {
            this.menu.splice(1, 1, false)
        },

        getEmpInfo: function (obj) {
            this.rcrdInfo.wrtr = obj[0].name
        },
    },
    
    data: () => ({
        exmStt: '',
        bnftOfr: '',
        saveBtn: true,
        sltItm: -1,
        menu: [false, false, false],
        rcrdList: [],
        rcrdInfo: {
            linkRcrdPk: 0, bnMmbrPk: 0, lvInfPk: 0,
            bnftOfrCntnt: '', gnrlOpnn: '', wrtr: '', wrtDt: '', lvClcd:'', lvSrvcPlan: '', spclNote: '', mBdyFnctnStts: '',
        },
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>