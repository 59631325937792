<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">처우개선&#40;고충처리,포상&#41;</span>
            <v-spacer></v-spacer>            
            <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue'"
                        v-model="dialog[i]" :max-width="list.width" content-class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                            <div v-else>개발중</div>
                    </v-dialog>
                </span>
            <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length + j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
            <v-icon class="pl-2" color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="ma-0 pa-0">
        <v-form ref="form" lazy-validation> 
            <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">구분</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                    <span style="width:100px;">
                        <v-select
                            v-model="items.cmplnClcd"
                            :items="$parent.$parent.$parent.$parent.vCd106"
                            item-text="valcdnm" item-value="valcd" :rules="[rules.required]"
                            dense outlined hide-details>
                        </v-select>
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">접수번호</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span style="width:200px;">
                        <v-text-field color="blue" v-model="items.rgsNum" hide-details outlined dense disabled></v-text-field>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">접수일자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp v-model="items.rgsDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>   
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">신청인</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-text-field v-model="items.aplcnNm" color="blue" ref="info" readonly
                            :rules="[rules.required, rules.strCheck]" hide-details outlined dense :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="26"  
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(0,'aplcn')"
                        >
                        <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">소속</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span style="width:150px;">
                        <v-text-field color="blue" v-model="items.afltnNm" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>   
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">내용</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                    <v-textarea v-model="items.cmplnCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="7" :rules="[rules.required]" outlined dense hide-details no-resize>
                    </v-textarea>     
                </v-col>                
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">처리일자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateComp v-model="items.prcsDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>               
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">처리자&#40;상담자&#41;</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-text-field v-model="items.prcsrNm" color="blue" ref="info" readonly
                            :rules="[rules.strCheck]" hide-details outlined dense :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="26"  
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(0,'prcsr')"
                        >
                        <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">팀장</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-text-field v-model="items.ldrNm" color="blue" ref="info" readonly
                            :rules="[rules.strCheck]" hide-details outlined dense :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="26"  
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(0,'ldr')"
                        >
                        <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>   
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">처리결과</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                    <v-textarea v-model="items.prcsRst" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="7" outlined dense hide-details no-resize>
                    </v-textarea>     
                </v-col>                
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <div class="d-flex justify-center align-center" style="width:90px">
                        <span class="black--text font-weight-medium">자료첨부</span>
                        <v-file-input 
                            v-model="files" 
                            dense hide-details truncate-length="5" 
                            multiple counter
                            hide-input                                         
                            outlined 
                            @change="upFile()"
                            ></v-file-input>
                    </div>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                    <div style="width:100%;min-height:100px;">                      
                        <v-chip v-for="(item, j) in fileRes" :key="j"                        
                            label                        
                            close
                            class="ma-1"
                            outlined
                            @click="fileDownload(j)"
                            @click:close="delfiles(j)"
                        >
                            <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                mdi-file-pdf-box
                            </v-icon>
                            <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                mdi-microsoft-excel
                            </v-icon>
                            <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                mdi-file-powerpoint
                            </v-icon>
                            <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                mdi-file-word
                            </v-icon>
                            <v-icon v-else color='cyan' left>
                                mdi-file-document
                            </v-icon>
                            {{ item.orgFileNm }}
                        </v-chip> 
                    </div>
                </v-col>                
            </v-row>
        </v-form>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>
    </v-sheet>
</template>
<script>
import { tmpFileUpload, selAtchdFileList ,fileDownload, delAtchdFile} from '../../api/index.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

import { selCmplnRecInfo, insCmplnRec, delCmplnRec  } from '../../api/emp.js';

export default {
    name: 'GrvncHndlnWrt',
    
    components: {  
        btnModalDelete,
        CmmDateComp,
        EmpSelector,
    },

    props : {
        sltPk:{ type: Number, default: -1}
    },

    computed: {
        
    },

    created: function(){  
    },

    mounted: function(){
        this.$nextTick(function () {                
            this.cmplnRecPk = this.sltPk
            this.frmReset()
            this.onLoad()
        })
    },

    watch: {
        
    },

    methods: { 
        onLoad:function(){
            if(this.cmplnRecPk > 0) this.filledBtn[1].disabled = false
            else this.filledBtn[1].disabled = true
            
            this.getCmplnRecInfo()
            this.getAtchdFileList()
        },
        onClose: function (msg = null) {
            this.$emit('modalEvent', 0, msg)
        },
        //초기화
        frmReset:function(){

            this.items.cmplnClcd    = ''
            this.items.afltnNm      = ''
            this.items.aplcnNm      = ''
            this.items.cmplnCntnt   = ''
            this.items.ldrNm        = ''
            this.items.prcsDt       = ''
            this.items.prcsRst      = ''
            this.items.prcsrNm      = ''
            this.items.rgsDt        = ''
            this.items.rgsNum       = ''

            this.files = null
            this.fileRes.splice(0)

            this.$refs.form.resetValidation()
        },
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onSave()
                    break;  
                case 1:
                    this.delType = 'all'
                    this.delTitle = '고충처리관리'
                    this.dialogDelete = true
                    break;              
                default:
                    break;
            }
        },    
        onSave:function(){   
            if(this.$refs.form.validate()){         
                let obj = {                    
                    cmplnRecPk  : this.cmplnRecPk,  
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    userNm      : this.$store.getters.getUserNm,     
                    aplcnNm     : this.items.aplcnNm,            
                    afltnNm     : this.items.afltnNm,
                    cmplnCntnt  : this.items.cmplnCntnt,
                    prcsDt      : this.items.prcsDt?.afterDateFormatHyp(),
                    prcsrNm     : this.items.prcsrNm,
                    ldrNm       : this.items.ldrNm,
                    prcsRst     : this.items.prcsRst,
                    rgsDt       : this.items.rgsDt?.afterDateFormatHyp(),
                    cmplnClcd   : this.items.cmplnClcd,
                    rgsNum      : this.items.rgsNum,
                    files       : [],
                }

                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        obj.files.push(x)
                    }
                })

                insCmplnRec(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /emp/insCmplnRec : ' + error))
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.cmplnRecPk = res.result
                this.frmReset()
                this.onLoad()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete:function(res){            
            if(res == 9990){
                if(this.delType =='file'){
                    let pk = this.fileRes[this.idx].atchdFilePk
                    if(pk > 0){
                        let fcltyNum  = this.$store.getters.getFcltyNum  
                        delAtchdFile(fcltyNum, pk)
                            .then((response) => ( this.delAtchdFileAfter(response.data) ))
                            .catch((error) => console.log('upload error /opr/delAtchdFile : ' + error))
                    }
                }else{
                    let cmplnRecPk = this.cmplnRecPk
                    if(cmplnRecPk > 0){
                        let obj = {
                            fcltyNum : this.$store.getters.getFcltyNum,
                            userNm : this.$store.getters.getUserNm,
                            cmplnRecPk :this.cmplnRecPk,
                        }
                        delCmplnRec(obj)
                            .then((response) => ( this.onDeleteAfter(response.data) ))
                            .catch((error) => console.log('connect error /emp/delCmplnRec : ' + error))
                    }else{
                        this.snackControll([true, 3500, '삭제 할 대상을 선택해주세요.', 'warning'])
                        this.dialogDelete = false
                    }
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){
            this.dialogDelete = false
            this.authGrpPk = 0

            if(res.statusCode == 200){                               
                this.$emit('modalEvent', 99)
                this.onClose(res.message)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },   
        getCmplnRecInfo:function(){   
            if(this.cmplnRecPk > 0){
                let obj = {
                    fcltyNum : this.$store.getters.getFcltyNum,
                    cmplnRecPk : this.cmplnRecPk,
                }
                selCmplnRecInfo(obj)
                    .then((response) => ( this.getCmplnRecInfoAfter(response.data) ))
                    .catch((error) => console.log('connect error /emp/selCmplnRecInfo : ' + error))
            }else{
                this.items = Object.assign({})     
                this.$refs.form.resetValidation()          
            }
        },
        getCmplnRecInfoAfter:function(res){
            if(res.statusCode == 200){ 
                if(res.result != null && res.result != undefined){

                    this.items.afltnNm      = res.result.afltnNm
                    this.items.aplcnNm      = res.result.aplcnNm                    
                    this.items.cmplnCntnt   = res.result.cmplnCntnt
                    this.items.ldrNm        = res.result.ldrNm
                    this.items.prcsDt       = (res.result.prcsDt != '' && res.result.prcsDt != null)?this.$moment(res.result.prcsDt,'YYYYMMDD').format('YYYY-MM-DD'):''
                    this.items.prcsRst      = res.result.prcsRst
                    this.items.prcsrNm      = res.result.prcsrNm
                    this.items.rgsDt        = (res.result.rgsDt != '' && res.result.rgsDt != null)?this.$moment(res.result.rgsDt,'YYYYMMDD').format('YYYY-MM-DD'):''
                    this.items.rgsNum       = res.result.rgsNum
                    this.items.cmplnClcd    = res.result.cmplnClcd
                }
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }            
        },  
        getAtchdFileList:function(){
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '15'
            if(this.cmplnRecPk > 0){
                selAtchdFileList(fcltyNum, clcd,this.cmplnRecPk)
                        .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
            }else{
                this.files = null
                this.fileRes.splice(0)
            }
        },
        getAtchdFileListAfter:function(res){
            this.files = null
            this.fileRes.splice(0)
            
            res.forEach(x=>{               
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]  
                this.fileRes.push(x)
            })
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },  
        snackControll: function (options) {
            this.snackOptions = options
        },
        onModal: function (value, type) {
            this.type = type
            this.prtWrtDt = (this.items.rgsDt !=null)?this.items.rgsDt:''
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            if(this.type=='aplcn'){
                this.items.aplcnNm = obj[0].name
            }else if(this.type=='prcsr'){
                this.items.prcsrNm = obj[0].name
            }else if(this.type=='ldr'){
                this.items.ldrNm = obj[0].name
            }
        },
        //임시파일 업로드
        upFile: function () {            
            let formData = new FormData();
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => ( this.upFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }            
        },
        upFileAfter:function(res){

            res.files.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
            })

            this.files = null
        },
        delfiles:function(idx){

            if(this.fileRes[idx].atchdFilePk != undefined){     
                this.idx = idx
                this.delType = 'file'
                this.delTitle = '자료첨부파일'
                this.dialogDelete = true
            }else{
                this.fileRes.splice(idx,1)
            }
        },          
        delAtchdFileAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList(this.items.cmplnRecPk)
                this.idx = 0
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        //첨부파일 다운로드
        fileDownload:function(idx){
            if(this.fileRes[idx] != undefined){
                fileDownload(this.fileRes[idx])
                    .then((response) => ( this.fileDownLoaderAfter(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
    },
    data: () => ({  
        prtWrtDt:'',
        idx:0,
        cmplnRecPk:-1,
        delTitle:'고충처리관리',
        type:'',
        delType:'',
        forceRender:0,  
        items:{
            afltnNm:'',
            aplcnNm:'',
            cmplnCntnt:'',
            ldrNm:'',
            prcsDt:'',
            prcsRst:'',
            prcsrNm:'',
            rgsDt:'',
            rgsNum:'',
            cmplnClcd:'',
        },
        files:null,
        fileRes:[],
        dialog:[false,false],       
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,  
        menu:[false,false], 
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', disabled: false, },            
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>