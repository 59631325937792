<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="btnDialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <v-divider class="my-2"></v-divider>
        <basicInfo ref="basicInfo" :bnMmbrPk="$parent.$parent.sltBnMmBrPk" @setParentData="getBnfcrInfo"></basicInfo>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">응급상황기록 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="630" locale="ko"
                :headers="emrgnRcrdHeaders"
                :items="emrgnRcrdList" 
                disable-pagination fixed-header hide-default-header hide-default-footer dense>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="black--text clmsFixedHeader tableBR" style="width:200px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:400px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:110px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>
 
                <template v-slot:item='{ item, index }'>
                    <tr class="disSltRow">
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.recDt?.beforeDateFormatDot()}}</td>
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:400px" class="text-overflow">{{item.itm2Val}}</div>
                        </td>                        
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.wrtr}}</td>
                        <td class="clmsBodyCommon fixedCol0">
                            <div class="d-flex justify-center align-center">   
                                <v-btn icon @click="onShowEmrgn(item.bnftOfrRecHisPk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>                             
                                <v-btn icon color='grey006' @click="delRow(index)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>                                
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[0]" max-width="1000">    
                <emrgnRcrdWrt 
                    ref="emrgnRcrdWrt" 
                    :bnfcrInfo="bnfcrInfo"
                    @isClose="isModal(0)" 
                    @modalEvent="modalEvent"></emrgnRcrdWrt>                  
            </v-dialog>   
        </div>        
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'응급상황기록'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div> 
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import emrgnRcrdWrt from '../../components/bnftrcrd/EmrgnRcrdWrt.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import { selBnftOfrRecList, delAllBnftOfrRec } from '../../api/bnftrcrd.js';

export default {
    name: 'EmrgnRcrd',
    
    components: {
        expandYearOnlyPicker,
        emrgnRcrdWrt,
        basicInfo,
        btnModalDelete,
    },

    props : {

    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk
            
            if(this.bnMmbrPk > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true
            }      
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        }
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnmmbrPk:function(value){
            this.bnMmbrPk = value  
            if(value > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true
            }
        },
        'year':function(value){
            if(value != ''){
                this.$parent.$parent.getAllEmergRecList(value)
                this.onLoad()
            }
        }
    },

    methods: {
        onLoad:function(){
            this.getBnftOfrRecList()
        },
        onClickBtn:function(key){
            this.dialog.splice(key, 1, true)
            
            switch (key) {
                case 0:

                    break;
            
                default:
                    break;
            }
        },       
        onShowEmrgn:function(pk){
            this.$refs.emrgnRcrdWrt.onLoad(pk)
            this.dialog.splice(0, 1, true)
        },
        delRow:function(idx){
            this.idx=idx
            this.dialogDelete = true
        },
        onDelete:function(res){
            let gFcltyNum = this.$store.getters.getFcltyNum    
            let gUserNm = this.$store.getters.getUserNm
            
            if(res == 9990){  
                let target = this.emrgnRcrdList[this.idx]              
                let bnftOfrRecHisPk = target.bnftOfrRecHisPk
                if(bnftOfrRecHisPk > 0){                        
                    let obj = {list:[]}              
                    
                    obj.list.push({
                        fcltyNum:gFcltyNum,
                        bnftOfrRecHisPk:bnftOfrRecHisPk,
                        userNm:gUserNm,
                    })

                    delAllBnftOfrRec(obj)
                    .then((response) => ( this.onDeleteAfter(response.data)))
                    .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){            
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.onLoad()               
                this.$parent.$parent.getAllEmergRecList(this.year)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.idx = 0
            this.dialogDelete = false            
        },
        getBnftOfrRecList(){
            let gFcltyNum = this.$store.getters.getFcltyNum

            let obj = {
                fcltyNum: gFcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '305',
                clsfc2Cd: '1',
                clsfc3Cd: '1',
                bnMmbrPk: this.bnMmbrPk,
                inqYYYY:this.year,
            }

            selBnftOfrRecList(obj)
                .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
                .catch((error) => console.log('upload error /bnftrcrd/selBnfcrSpclbdUseList : ' + error))
        },
        getBnftOfrRecListAfter:function(res){
            this.emrgnRcrdList = res
        },
        getYear: function (yyyy){
            this.year = yyyy
        },
        getBnfcrInfo: function(item){
            this.bnfcrInfo = item
        },
        snackControll: function (options) {
            this.snackOptions = options
        },     
        modalEvent: function (event) { 
            if(event == 99){
                this.onLoad()
                this.$parent.$parent.getAllEmergRecList(this.year)
            }else{
                this.dialog.splice(event, 1, false)
            }      
        },    
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },         
    },
    data: () => ({
        idx:0,
        year:'',
        bnMmbrPk:0,
        areaWidth: 708,
        bnfcrInfo:{},
        snackOptions: [false, 3000, '', 'info'],
        btnDialog: [false, false, false, false, ],
        dialog:[false,false,false,],
        emrgnRcrdHeaders: [            
            { text: '발생일',width: '', value: '', align: 'center', },
            { text: '사유',width: '', value: '', align: 'center', },
            { text: '작성자',width: '', value: '', align: 'center', },
            { text: '처리',width: '', value: '', align: 'center', },
        ],
        emrgnRcrdList:[],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '작성', disabled: true, },
        ],
        outlinedBtn: [      
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력',  disabled: false, },
        ], 
        dialogDelete:false,
    }),
};
</script>