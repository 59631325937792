<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">조편성&nbsp;선택</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-divider class="mt-5 mr-2"></v-divider>
        <v-row class="greyE01 pa-0 mr-2" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR py-2" cols="3">
                <span class="black--text font-weight-medium">조명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center tableBR py-1" cols="8">
                <span class="black--text font-weight-medium">설명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="1">
            </v-col>
        </v-row>
        <div class="d-inline-block pr-2" style="width: 100%; height: 391.1px;">
            <v-row
                class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;"
                v-for="(item, j) in grpList" :key="j"
                no-gutters>
                <v-col class="d-flex justify-center align-center tableBR py-2" cols="3">
                    {{ item.grpNm }}
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR py-2" cols="8">
                    {{ item.dscp }}
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="1">
                    <v-btn
                        class="ml-1 white--text darken-1" style="padding: 1px 0px 0px 0px !important"
                        color="bckgrColor" min-width="30" height="25"
                        @click="thrObj(j)" dark small>
                            <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>

export default {
    name: 'EmpGrpChcMdl',

    props : {
        grpList: { type: Array, default: () => { return [] }  }
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        thrObj: function (index) {
            this.$emit('grpObj', this.grpList[index])
            this.onClose()
        },
        onClose: function () {
            this.$emit('modalClose', '6')
        },
    },

    data: () => ({

    }),
};
</script>