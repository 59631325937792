<!-- 공통 다이얼로그 호출 유형 정리 -->
<!--
    actv: 활성여부(true, false)
    mxWdth: 가로 크기(최소 500)
    prsst: 다이얼로그 지속여부
    typ: 호출 컴포넌트 유형(대메뉴번호-다이얼로그순번)
    inObj: 내부로 전달할 데이터
    outObj: 외부로 전달할 데이터
    runEvt: 이벤트 발생 여부(true, false)
-->
<!-- 필요 공통코드 유형 정리 -->
<!--
    4: 프로그램 - (3, 38, 40)
-->
<template>
    <v-dialog
        content-class="round"
        :value="$store.getters.getRootActv"
        :max-width="$store.getters.getRootMxWdth"
        :persistent="$store.getters.getRootPrsst"
        :scrollable="false" :transition="false"
        @click:outside="outSideEvt">
        <v-card class="pa-7">
            <div class="overflow-hidden rounded" style="max-height: 740px;">
                <PrgrmRec
                    v-if="$store.getters.getRootTyp == '4-01'"
                    :prtCd3="vCd3" :prtCd38="vCd38" :prtCd40="vCd40">
                </PrgrmRec>
                <v-row v-else class="pa-0" no-gutters>
                    <span class="text-h4 font-weight-bold">잘못된&nbsp;요청&nbsp;입니다.</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" @click="mdlOff" x-large>mdi-close</v-icon>
                </v-row>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';

import PrgrmRec from '../prgrm/PrgrmRec.vue';        // 프로그램 운영기록 상세(typ: '4-01')

export default {
    name: 'CmmRootMdl',

    components: {
        PrgrmRec,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getRootTyp != ''){
                let chc = this.$store.getters.getRootTyp?.split('-', 2)
                if(typeof chc != 'undefined')
                    this.ctgr = chc[0]
            }
        })
    },

    computed: {
        getRootTyp: function () {
            return this.$store.getters.getRootTyp
        },
    },

    watch: {
        getRootTyp: function (txt) {
            if(txt != ''){
                let chc = txt?.split('-', 2)
                if(typeof chc != 'undefined')
                    this.ctgr = chc[0]
            }
        },
        'ctgr': function () {
            if(this.ctgr != '')
                this.ldrItmCd()
        },
    },

    methods: {
        getCode: function (arr) {
            getMultiStcd(arr)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res){
            switch (this.ctgr) {
                case '4':
                    this.vCd3 = res.vCd3
                    this.vCd38 = res.vCd38
                    this.vCd40 = res.vCd40
                    break;
                default:
                    break;
            }

            this.ctgr = ''
        },
        ldrItmCd: function (){
            let chc = false
            switch (this.ctgr) {
                case '4':
                    if(this.vCd3.length == 0)
                        chc = true
                    if(this.vCd38.length == 0)
                        chc = true
                    if(this.vCd40.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['3', '38', '40'])
                    break;
                default:
                    break;
            }
        },
        mdlOff: function () {
            this.$store.commit('setRootInf', {
                actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
            });
        },
        outSideEvt: function () {
            if(this.$store.getters.getRootPrsst == false)
                this.$store.commit('setRootActv', false)
        },
    },

    data: () => ({
        ctgr: '',
        vCd3: [],       // 상중하
        vCd38: [],      // 기능회복훈련코드
        vCd40: [],      // 지표구분
    }),
};
</script>