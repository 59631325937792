<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">{{title}}</span>
            <span class="text-h4 font-weight-bold">&nbsp;발송&nbsp;관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>        
        <div class="mt-2" style="height:650px;">
            <v-tabs hide-slider v-model="tab" color="white" class="black--text"
                active-class="bodyColor" center-active>
                <v-tab class="fmlySend" v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>

            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

            <v-tabs-items v-model="tab">
                <v-tab-item eager transition="fade-transition">
                    <FmlyLeterSndAlim ref="fmlyLeterSndAlim" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></FmlyLeterSndAlim>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <FmlyLeterSndSms ref="fmlyLeterSndSms" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></FmlyLeterSndSms>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <FmlyLeterSndEmail ref="fmlyLeterSndEmail" @msgTrigger="msgTrigger" @mdlTrigger="mdlTrigger"></FmlyLeterSndEmail>
                </v-tab-item>
                <v-tab-item transition="false">
                    <FmlyLeterSndHist v-if="tab == 3" ref="fmlyLeterSndHist"></FmlyLeterSndHist>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
import FmlyLeterSndAlim from './FmlyLeterSndAlim.vue'
import FmlyLeterSndSms from './FmlyLeterSndSms.vue'
import FmlyLeterSndEmail from './FmlyLeterSndEmail.vue'
import FmlyLeterSndHist from './FmlyLeterSndHist.vue'
import { selESndFmlyLeterList } from '../../api/bnfcr.js'

export default {
    name: 'FmlyLeterSnd',

    props : {        
        bzPk: { type: Number, default: 0 },                   //bzPk
        title:{ type: String, default: '' },                  //메세지 제목
        cntnt:{ type: String, default: '' },                  //메세지 내용
        mdlShow: { type: Boolean, default: false },              //v-model ON/OFF
        prtCdAr :{ type: Array, default: () => { return [] }},                
        yymm:{ type: String, default: '' },          
    },
            
    components: {
       FmlyLeterSndAlim,
       FmlyLeterSndSms,
       FmlyLeterSndEmail,
       FmlyLeterSndHist,
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.onLoad()
        })
    },

    computed: {

    },

    watch: {
        'mdlShow':function(value){
            if(value) this.onLoad()
        }
    },
        
    methods: {
        onLoad : function () {
            this.getList()
        },
        onClose: function () {
            this.$emit('madalState')
        },
        getList:function(){            
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                bzPk        : this.bzPk,
                stdYymm     : this.yymm,
                valClcdS    :['62', '65']
            }

            selESndFmlyLeterList(obj)
                .then((response) => ( this.getListAfter(response.data) ))
                .catch((error) => console.log('connect error /othr/selESndFmlyLeterList : ' + error))
        },
        getListAfter:function(res){
            this.fmlyleterList.splice(0)

            res.list.forEach(el => {
                el.lnkUrl   = this.encUrlObj(el.bnMmbrPk, el.bnfcrPk)
                el.cntnt    = this.cntnt
                el.title    = this.title

                res.code.vCd62.forEach((cd) => {
                    if(el.acknwRtng == cd.valcd) el.acknwRtngNm = cd.valcdnm
                })

                res.code.vCd65.forEach((cd) => {
                    if(el.entStcd == cd.valcd) el.entStcdNm = cd.valcdnm
                })
                this.fmlyleterList.push(el)
            })

            this.$refs.fmlyLeterSndAlim.setData(this.fmlyleterList)
            this.$refs.fmlyLeterSndSms.setData(this.fmlyleterList)
            this.$refs.fmlyLeterSndEmail.setData(this.fmlyleterList)
            
        },
        encUrlObj: function(pk, bnfcr){
            let linkURI = ''

            //개발, 운영 모드에 따라 링크 URL을 환경변수에서 불러옴
            switch (process.env.NODE_ENV) {
                case 'development':
                    linkURI = process.env.VUE_APP_MOBILE_DEV_URI
                    break;
                case 'production':
                    linkURI = process.env.VUE_APP_MOBILE_PROD_URI
                    break;
                default:
                    break;
            }

            let obj = {
                fcltyNum   : this.$store.getters.getFcltyNum,
                bnMmbrPk   : pk,
                inqYYMM    : this.yymm,
                prtCdAr    : this.prtCdAr,
                bnfcrPk    : bnfcr
            }

            //오브젝트를 JSON 직렬화를 사용해 암호화 (문자열만 암호화 가능)
            let encrypted = this.$crypto.AES.encrypt(JSON.stringify(obj), this.$store.getters.getCrypKey)
            let bs64 = this.$crypto.enc.Base64.stringify(this.$crypto.enc.Utf8.parse(encrypted.toString()))
            return linkURI + 'out/pyrlSrvcMgmt?dstnt=' + bs64
        },
        msgTrigger:function(msg){
            this.snackControll([true, 2500, msg, 'warning'])
        },
        mdlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getList()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },  
    },
    
    data: () => ({
        tab: 0,
        fmlyleterList:[],
        tabItems: [
            { index: 0, tab: '알림톡', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 1, tab: '문자', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 2, tab: '이메일', style: 'font-size: 1.2rem !important;', enter: false },
            { index: 3, tab: '발송내역', style: 'font-size: 1.2rem !important;', enter: false },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .fmlySend { padding: 0 60px !important; }
</style>