<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">   
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>       
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">식사급여기록</span>
            <v-spacer></v-spacer>    
            <span v-if="progress">
                <v-progress-circular            
                :size="25"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">                    
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                        :class="list.class" height="30" :color="list.color" rounded
                        :disabled="list.disabled"
                        style="font-size: 14px;">
                        <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                        {{list.text}}
                    </v-btn>
                </span>
            </span>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <v-form ref="tabform" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1"> 
                    <span class="black--text font-weight-medium">식사일</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="3">  
                    <span class="white d-inline-block pa-1" style="width: 90px;">                           
                        {{inqYMD?.beforeDateFormatDot()}}                        
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="7">  
                    <span class="d-inline-block py-1 pl-2" style="width: 100px;">
                        <v-text-field v-model="items1.wrtr" color="blue" ref="info"
                            :rules="[rules.required]" hide-details outlined dense :key="forceRender" readonly></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal(0)"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
            <div class="d-flex mt-2">
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:60%">
                    <v-row style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters >
                        <v-col class="d-flex greyE01 justify-center align-center pa-1 ma-0" cols="3">
                            <span class="black--text font-weight-medium">식사</span>
                        </v-col>
                        <v-col class="d-flex greyE01 justify-center align-center pa-1 ma-0" cols="6" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">급여기록</span>
                        </v-col>
                        <v-col class="d-flex greyE01 justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">제공시간</span>
                        </v-col>
                        <v-col class="d-flex greyE01 justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">처리</span>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, i) in groups1" :key="i" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters >
                        <v-col class="greyE01" cols="3">
                            <v-row no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="6">
                                    <span class="black--text font-weight-medium">{{item.label}}</span>
                                </v-col>
                                <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">
                                    <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="height:42px;">
                                        <span class="black--text font-weight-medium">식사종류</span>
                                    </div>
                                    <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="border-top: 1px solid #dbdbdb !important;height:42px;">
                                        <span class="black--text font-weight-medium">식사량</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="6" style="border-left: 1px solid #dbdbdb !important;">
                            <div class="ma-0" style="height:42px;padding-top:6px;">
                                <v-radio-group v-model="item.itm1Val" class="pl-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem14" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </div>
                            <div class="ma-0" style="height:42px;padding-top:6px;border-top: 1px solid #dbdbdb !important;">
                                <v-radio-group v-model="item.itm2Val" class="pl-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem15" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                            <div style="width:80px;">
                                <CmmTimeField v-model="item.ofrTm"></CmmTimeField>
                                <!-- <v-text-field v-model="item.ofrTm" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly></v-text-field> -->
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                            <v-btn icon color='grey006' @click="delGrp1Row('1',i)"> 
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-minus-circle-outline</v-icon>
                                    </template>
                                    <span>삭제</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, j) in groups1_1" :key="j+(groups1.length)" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters >
                        <v-col class="greyE01" cols="3">
                            <v-row no-gutters>
                                <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="6">
                                    <span class="black--text font-weight-medium">{{item.label}}</span>
                                </v-col>
                                <v-col class="pa-0 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="6">
                                    <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="height:42px;">
                                        <span class="black--text font-weight-medium">오전</span>
                                    </div>
                                    <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="border-top: 1px solid #dbdbdb !important;height:34px;">
                                        <span class="black--text font-weight-medium">오후</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="6" style="border-left: 1px solid #dbdbdb !important;">
                            <div class="ma-0" style="height:42px;padding-top:6px;">
                                <v-radio-group v-model="item.itm1Val" class="pl-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem1" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </div>
                            <div class="ma-0" style="height:42px;padding-top:6px;border-top: 1px solid #dbdbdb !important;">
                                <v-radio-group v-model="item.itm2Val" class="pl-1" hide-details row :key="forceRender">
                                    <v-radio v-for="(list, j) in codeItem1" :key="j" class="pa-0 ma-0 mr-2" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                                </v-radio-group>
                            </div>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                            <div class="ma-0 d-flex justify-center align-center" style="height:42px;padding-top:6px;">
                                <div style="width:80px;">
                                    <CmmTimeField v-model="item.ofr1Tm"></CmmTimeField> 
                                    <!-- <v-text-field v-model="item.ofr1Tm" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly></v-text-field> -->
                                </div>
                            </div>
                            <div class="ma-0 d-flex justify-center align-center" style="height:42px;padding-top:6px;border-top: 1px solid #dbdbdb !important;">
                                <div style="width:80px;">
                                    <CmmTimeField v-model="item.ofr2Tm"></CmmTimeField> 
                                    <!-- <v-text-field v-model="item.ofr2Tm" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly></v-text-field> -->
                                </div>
                            </div>
                            
                        </v-col>
                        <v-col class="d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                            <v-btn icon color='grey006' @click="delGrp1Row('2',0)"> 
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-minus-circle-outline</v-icon>
                                    </template>
                                    <span>삭제</span>
                                </v-tooltip>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row style="border-bottom: 1px solid #dbdbdb !important;" no-gutters >
                        <v-col class="d-flex greyE01 justify-center align-center pa-1 ma-0 " cols="3">
                            <span class="black--text font-weight-medium">특이사항</span>                            
                        </v-col>
                        <v-col class="pa-1 ma-0" cols="9" style="border-left: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="items1.spclNote" hide-details outlined dense></v-text-field>
                        </v-col>                        
                    </v-row>
                </div>
                <div class="d-inline-block pl-2 ma-0 align-self-start" style="width:40%;">
                    <div class="d-flex align-center">
                        <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                        <span class="text-h6 font-weight-bold">비위관 영양&#40;필요시&#41;</span>
                    </div>
                    <v-row class="pa-0 ma-0"  style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                            <span class="black--text font-weight-medium">경관영양</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="9">
                            <span style="width:200px;">
                                <v-radio-group v-model="recItems1.itm1Val" hide-details row>
                                    <v-radio v-for="(code, i) in codeItem30" :key="i" :ripple="false" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                </v-radio-group>
                            </span>
                        </v-col>
                    </v-row>
                    <div :class="['d-flex pa-0 mt-2', nsgCareRcrdList.length > 6 ? 'mr-2__5' : '']" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:10%;">                                
                            <span class="black--text font-weight-medium">횟수</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사종류</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사량&#40;ml&#41;</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                            <span class="black--text font-weight-medium">식사시간</span>
                        </div>
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                
                            <v-btn icon class="mr-1" color="blue200" height="26" @click="addRow()">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="overflow-y-auto" style="height:300px;">
                        <div v-if="nsgCareRcrdList.length > 0">
                            <div v-for="(item,m) in nsgCareRcrdList" :key="m" class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;">                                
                                    {{m+1}}
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:35%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="d-inline-block" style="width: 140px;">
                                        <v-select 
                                            v-model="item.itm2Val"                               
                                            :items="codeItem91"
                                            item-text="valcdnm"
                                            item-value="valcd"
                                            dense hide-details outlined
                                        /> 
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span style="width:90px">
                                        <v-text-field color="blue" v-model="item.itm3Val" @focus="item.itm3Val=''" type="number" hide-details outlined dense></v-text-field>
                                    </span>
                                </div>
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">                                
                                    <span class="white d-inline-block pa-1" style="width: 80px;"> 
                                        <CmmTimeField v-model="item.ofrTmStrt"></CmmTimeField>
                                    </span>
                                </div>
                                <div  class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                                                   
                                    <v-btn v-if="item.bnftOfrRecPk > 0" icon color='grey006' @click="delRow(m)"> 
                                        <v-icon>mdi-trash-can-outline</v-icon>                                           
                                    </v-btn> 
                                    <v-btn v-else icon color='grey006' @click="delRow(m)"> 
                                        <v-icon>mdi-minus-circle-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delModelTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>      
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDup" max-width="500">    
                <bnftrcrdConfirm 
                    :question="question" 
                    :message="message" 
                    :recymd="recymd"
                    :wrtr="recwrtr"
                    @madalState="onDupSave"
                ></bnftrcrdConfirm>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import bnftrcrdConfirm from '../../components/bnftrcrd/BnftrcrdConfirm.vue';
import { getMultiStcd } from '../../api/index.js';
import { selBnftOfrRecList, selPreBnftOfrRecList, insMultiBnftRcrd, delAllBnftOfrRec, delBnftOfrRec} from '../../api/bnftrcrd.js';
import { selBnfcrDiets } from '../../api/bnftSrv.js';

export default {
    name: 'MealSrvRcrd',
    
    components: {  
        CmmTimeField,   
        EmpSelector,
        basicInfo,
        btnModalDelete, 
        bnftrcrdConfirm,
    },

    props : {
        inqYMD          : { type: String, default: '' },  
        bnMmbrPk        : { type: Number, default: -1 },
        mealSrvRcrdPk   : { type: Number, default: -1 },
        empWrtr         : { type: String, default: '' }, 
        empWrtrpk       : { type: Number, default: -1 },   
    },

    computed: {
        
    },

    created: function(){        
        this.onCodeload()        
    },

    mounted: function(){
        this.$nextTick(function () {
            this.frmReset()
            this.getData()
        })
    },

    watch: {
        // 'mealSrvRcrdPk':function(value){            
        //     if(value > 0){                
        //         this.getData()
        //     }else{
        //         this.frmReset()
        //     }
        // }
    },

    methods: {  
        onCodeload:function(){
            getMultiStcd(['1','14','15','30','91'], this.$store.getters.getFcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },
        codeMapping:function(res){
            this.codeItem1 = res["vCd1"]
            this.codeItem14 = res["vCd14"].filter(x=>x.valcd !='5')
            this.codeItem15 = res["vCd15"]
            this.codeItem30 = res["vCd30"]
            this.codeItem91 = res["vCd91"]           
        },               
        reLoad(){   
            this.frmReset()         
            this.getData()
        },
        frmReset(){      
            
            this.prtWrtDt=''  

            this.items1.bnftOfrRecHisPk = 0
            this.items1.recDt           = ''
            this.items1.wrtr            = ''
            this.items1.itm1Val         = ''
            this.items1.wrtrMmbrPk      = 0
            this.items1.ofrTmStrt       = ''
            this.items1.ofrTmEnd        = ''
            this.items1.spclNote        = ''
            this.items1.recList         = []

            this.groups1.forEach(el => {
                el.itmRecPk  = 0
                el.itm1Val = ''
                el.itm2Val = ''
                el.ofrTm = ''
            });

            this.groups1_1.splice(0)
            this.groups1_1.push({label:'간식',itm1Pk:0,itm2Pk:0,itm1Val:'',itm2Val:'',ofr1Tm:'',ofr2Tm:''})

            this.items2.bnftOfrRecHisPk = 0
            this.items2.bnftOfrRecPk    = 0
            this.items2.recDt           =  ''
            this.items2.itm1Val         = ''
            this.items2.cntnt           = ''
            this.items2.spclNote        = ''
            this.items2.wrtr            = ''
            this.items2.wrtrMmbrPk      = 0

            this.recItems1.bnftOfrRecHisPk  = 0
            this.recItems1.bnftOfrRecPk     = 0
            this.recItems1.recDt            = ''
            this.recItems1.wrtr             = ''
            this.recItems1.wrtrMmbrPk       = 0
            this.recItems1.ofrTmStrt        =  ""
            this.recItems1.ofrTmHhStrt      = ""
            this.recItems1.ofrTmMmStrt      = ""
            this.recItems1.itm1Cd           = "1"
            this.recItems1.itm1Val          =  ""

            this.nsgCareRcrdList.splice(0)
            this.$refs.tabform.resetValidation();
        },
        onClickBtn:function(text){
            if(text == "전일자료"){
                this.getPreData()
            } else if(text == "저장"){
                this.onSave()
            }else if(text == "삭제"){
                this.dialogDelete = true
            }
        },        
        onSave:function(dupYn = '1'){
            
            if(this.bnMmbrPk > 0 && this.$refs.tabform.validate()){
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : this.bnMmbrPk,
                    userNm : this.$store.getters.getUserNm,
                    dupList     : [],
                    hisList     : []
                }

                let recItems1 = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    bnMmbrPk : this.bnMmbrPk,
                    userNm : this.$store.getters.getUserNm,
                    recDt:this.inqYMD,
                    wrtr:this.items1.wrtr,
                    wrtrMmbrPk:this.items1.wrtrMmbrPk,
                    spclNote:this.items1.spclNote,
                    clsfc1Cd:'301',
                    clsfc2Cd:'2',
                    clsfc3Cd:'1',
                    recList :[]
                }

                this.groups1.forEach(el => {
                    let itm = {                        
                        wrtr:this.items1.wrtr,
                        wrtrMmbrPk:this.items1.wrtrMmbrPk,
                        ofrTmHhStrt:'',
                        ofrTmMmStrt:'',
                        itm1Cd:el.itm1Cd,
                        itm1Val:el.itm1Val,
                        itm2Cd:el.itm2Cd,
                        itm2Val:el.itm2Val
                    }

                    if(el.ofrTm?.isEmptyVal()){
                        let ofrTm = el.ofrTm.split(":")
                        itm.ofrTmHhStrt = ofrTm[0]
                        itm.ofrTmMmStrt = ofrTm[1]
                    } 

                    recItems1.recList.push(itm)
                });

                let itm1 = {
                    wrtr:this.items1.wrtr,
                    wrtrMmbrPk:this.items1.wrtrMmbrPk,
                    ofrTmHhStrt:'',
                    ofrTmMmStrt:'',
                    itm1Cd:7,
                    itm1Val:this.groups1_1[0].itm1Val,
                }

                if(this.groups1_1[0].ofr1Tm?.isEmptyVal()){
                    let ofrTm1 = this.groups1_1[0].ofr1Tm.split(":")
                    itm1.ofrTmHhStrt = ofrTm1[0]
                    itm1.ofrTmMmStrt = ofrTm1[1]
                }

                recItems1.recList.push(itm1)

                let itm2 = {
                    wrtr:this.items1.wrtr,
                    wrtrMmbrPk:this.items1.wrtrMmbrPk,
                    ofrTmHhStrt:'',
                    ofrTmMmStrt:'',
                    itm1Cd:8,
                    itm1Val:this.groups1_1[0].itm2Val,
                }

                if(this.groups1_1[0].ofr2Tm?.isEmptyVal()){
                    let ofrTm2 = this.groups1_1[0].ofr2Tm.split(":")
                    itm2.ofrTmHhStrt = ofrTm2[0]
                    itm2.ofrTmMmStrt = ofrTm2[1]
                }

                recItems1.recList.push(itm2)

                obj.hisList.push(recItems1)

                if(this.items1.bnftOfrRecHisPk == 0 && dupYn == '1'){
                    let dupObj = {
                        clsfc1Cd    :'301',
                        clsfc2Cd    :'2',
                        clsfc3Cd    :'1',
                        recDt       : this.inqYMD,
                    }

                    obj.dupList.push(dupObj)
                }

                if(this.recItems1.itm1Val == '1' || this.recItems1.itm1Val == '2'){
                    if(this.nsgCareRcrdList.length > 0){

                        let recItems2 = {
                            fcltyNum: this.$store.getters.getFcltyNum,
                            bnMmbrPk : this.bnMmbrPk,
                            userNm : this.$store.getters.getUserNm,
                            recDt:this.inqYMD,
                            wrtr:this.items2.wrtr,
                            wrtrMmbrPk:this.items2.wrtrMmbrPk,
                            cntnt:this.items2.cntnt,
                            clsfc1Cd:'301',
                            clsfc2Cd:'2',
                            clsfc3Cd:'2',
                            recList :[]
                        }   
        
                        let rec1 = {
                            wrtr:this.items2.wrtr,
                            wrtrMmbrPk:this.items2.wrtrMmbrPk,
                            ofrTmStrt: "",
                            ofrTmHhStrt:"",
                            ofrTmMmStrt:"",
                            itm1Cd:"1",
                            itm1Val: this.recItems1.itm1Val,
                        }
                            
                        recItems2.recList.push(rec1)
                       
                        
                        this.nsgCareRcrdList.forEach(x=>{                    
                            let obj = {
                                wrtr:this.items2.wrtr,
                                wrtrMmbrPk:this.items2.wrtrMmbrPk,
                                ofrTmStrt: "",
                                ofrTmHhStrt:"",
                                ofrTmMmStrt:"",
                                itm1Cd:"1",
                                itm1Val: this.recItems1.itm1Val,
                                itm2Cd: "2",
                                itm2Val: x.itm2Val,
                                itm3Cd: "3",
                                itm3Val: x.itm3Val
                            }
                            if(x.ofrTmStrt?.isEmptyVal()){
                                let ofrTmStrt = x.ofrTmStrt.split(":")
                                obj.ofrTmHhStrt = ofrTmStrt[0]
                                obj.ofrTmMmStrt = ofrTmStrt[1]
                            }
                            
                            recItems2.recList.push(obj)
                        })
        
                        obj.hisList.push(recItems2)
                    }
                }
                this.progress = true
                insMultiBnftRcrd(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/insMultiBnftRcrd : ' + error))
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                this.reLoad()
            } else if(res.statusCode == 205) {
                this.dupTitle = res.message
                this.dupCntnt = res.question
                this.question = res.question
                this.message = res.message
                this.recymd = res.recymd
                this.recwrtr = res.wrtr
                this.dialogDup = true
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.progress = false
        },    
        onDupSave:function(res){
            if(res == 9990){
                this.onSave('2')
            }else if(res == 7){
                this.$emit('modalEvent', 99)
                this.reLoad()
            }
             this.dialogDup = false
        },    
        getData(){

            this.items1.wrtr = this.empWrtr
            this.items1.wrtrMmbrPk = this.empWrtrpk

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '2',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
 
            selBnftOfrRecList(obj)
            .then((response) => ( this.resData(response.data)))
            .catch((error) => console.log(error))
        },
        resData(res){    
            if(res.length > 0){     
                //저장된 데이터 조회                          
                this.setItmList(res)
                this.filledBtn[0].visible = false
                this.filledBtn[2].disabled = false 

                let target = res.filter(x=> x.clsfc3Cd == '1')
                if(target != undefined){
                    if(target.length == 0) {
                        this.getBnfcrDiets()
                        this.filledBtn[0].visible = true
                    }
                }
            }else{
                //기초 정보 가져오기
                this.getBnfcrDiets()
                this.filledBtn[0].visible = true
                this.filledBtn[2].disabled = true
            }


        }, 

        setItmList(data){
            this.items1.bnftOfrRecHisPk=0
            this.items1.wrtr=''
            this.items1.wrtrMmbrPk=0
            this.items1.spclNote=''

            data.forEach(x=>{                
                let ofrTm = ''
                if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()) ofrTm = x.ofrTmHhStrt + ':' + x.ofrTmMmStrt

                if(x.clsfc3Cd == "1"){

                    this.items1.recDt = x.recDt
                    this.items1.wrtr = x.hisWrtr
                    this.items1.wrtrMmbrPk = x.hisWrtrMmbrPk
                    this.items1.spclNote = x.spclNote                    
                    this.items1.bnftOfrRecHisPk = x.bnftOfrRecHisPk
                    
                    //아침
                    if(x.itm1Cd == '1') {
                        this.groups1[0].itmRecPk = x.bnftOfrRecPk
                        this.groups1[0].itm1Val = x.itm1Val
                        this.groups1[0].itm2Val = x.itm2Val
                        this.groups1[0].ofrTm = ofrTm
                    //점심
                    }else if(x.itm1Cd == '3'){
                        this.groups1[1].itmRecPk = x.bnftOfrRecPk
                        this.groups1[1].itm1Val = x.itm1Val
                        this.groups1[1].itm2Val = x.itm2Val
                        this.groups1[1].ofrTm = ofrTm
                    //저녁                        
                    }else if(x.itm1Cd == '5'){
                        this.groups1[2].itmRecPk = x.bnftOfrRecPk
                        this.groups1[2].itm1Val = x.itm1Val
                        this.groups1[2].itm2Val = x.itm2Val
                        this.groups1[2].ofrTm = ofrTm
                    //간식 오전
                    }else if(x.itm1Cd == '7'){  
                        this.groups1_1[0].itm1Pk    = x.bnftOfrRecPk
                        this.groups1_1[0].itm1Val   = x.itm1Val
                        this.groups1_1[0].ofr1Tm    = ofrTm
                    //간식 오후
                    }else if(x.itm1Cd == '8'){
                        this.groups1_1[0].itm2Pk    = x.bnftOfrRecPk
                        this.groups1_1[0].itm2Val   = x.itm1Val
                        this.groups1_1[0].ofr2Tm    = ofrTm
                    }
                }else if(x.clsfc3Cd == "2"){

                    this.items2.recDt = x.recDt
                    this.items2.cntnt = x.cntnt                    
                    this.items2.bnftOfrRecHisPk =x.bnftOfrRecHisPk
                    this.items2.wrtr = x.hisWrtr
                    this.items2.wrtrMmbrPk = x.hisWrtrMmbrPk
                                        
                    if(x.itm1Cd == '1' && x.itm2Cd =='2' && x.itm3Cd == '3'){
                        let obj = {
                            bnftOfrRecHisPk :x.bnftOfrRecHisPk,
                            bnftOfrRecPk:x.bnftOfrRecPk,
                            recDt:'',
                            clsfc1Cd:'301',
                            clsfc2Cd:'2',
                            clsfc3Cd:'2',
                            ofrTmStrt: '',
                            itm1Cd:"1",
                            itm1Val: x.itm1Val,
                            itm2Cd: "2",
                            itm2Val: x.itm2Val,
                            itm3Cd: "3",
                            itm3Val: x.itm3Val
                        }

                        if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()){
                            obj.ofrTmStrt =  x.ofrTmHhStrt+":"+x.ofrTmMmStrt
                        }

                        this.nsgCareRcrdList.push(obj)
                    }else{
                        this.recItems1.bnftOfrRecHisPk =  x.bnftOfrRecHisPk
                        this.recItems1.bnftOfrRecPk = x.bnftOfrRecPk
                        this.recItems1.itm1Cd = "1"
                        this.recItems1.itm1Val =  x.itm1Val
                    }
                }
            })

        },  

        getPreData(){
            let fcltyNum = this.$store.getters.getFcltyNum

            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '2',
                bnMmbrPk: this.bnMmbrPk,
                inqYMD:this.inqYMD,
            }
            this.progress = true
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.resPreData(response.data)))
            .catch((error) => console.log(error))
        },
        resPreData(res){
            if(res.length > 0){                    
                //저장된 데이터 조회                          
                this.setPreItmList(res)                
            }else{
                this.snackControll([true, 5000, '전일자료가 없습니다.', 'warning'])
            }
            this.progress = false
        }, 

        setPreItmList(data){
            this.items1.bnftOfrRecHisPk=0
            this.items1.wrtr=''
            this.items1.wrtrMmbrPk=0
            this.items1.spclNote=''        

            let preNsgCareRcrdList = []
            let itm1Val = ''

            data.forEach(x=>{
                
                let ofrTm = ''
                if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()) ofrTm = x.ofrTmHhStrt + ':' + x.ofrTmMmStrt

                if(x.clsfc3Cd == "1"){

                    this.items1.recDt = x.recDt
                    this.items1.wrtr = x.hisWrtr
                    this.items1.wrtrMmbrPk = 0
                    this.items1.spclNote = x.spclNote                    
                    this.items1.bnftOfrRecHisPk = 0
                    
                    //아침
                    if(x.itm1Cd == '1') {
                        this.groups1[0].itmRecPk = 0
                        this.groups1[0].itm1Val = x.itm1Val
                        this.groups1[0].itm2Val = x.itm2Val
                        this.groups1[0].ofrTm = ofrTm
                    //점심
                    }else if(x.itm1Cd == '3'){
                        this.groups1[1].itmRecPk = 0
                        this.groups1[1].itm1Val = x.itm1Val
                        this.groups1[1].itm2Val = x.itm2Val
                        this.groups1[1].ofrTm = ofrTm
                    //저녁                        
                    }else if(x.itm1Cd == '5'){
                        this.groups1[2].itmRecPk = 0
                        this.groups1[2].itm1Val = x.itm1Val
                        this.groups1[2].itm2Val = x.itm2Val
                        this.groups1[2].ofrTm = ofrTm
                    //간식 오전
                    }else if(x.itm1Cd == '7'){  
                        this.groups1_1[0].itm1Pk    = 0
                        this.groups1_1[0].itm1Val   = x.itm1Val
                        this.groups1_1[0].ofr1Tm    = ofrTm
                    //간식 오후
                    }else if(x.itm1Cd == '8'){
                        this.groups1_1[0].itm2Pk    = 0
                        this.groups1_1[0].itm2Val   = x.itm1Val
                        this.groups1_1[0].ofr2Tm    = ofrTm
                    }
                } 
                
                if(this.recItems1.bnftOfrRecHisPk == 0){
                    if(x.clsfc3Cd == "2"){
    
                        this.items2.recDt = x.recDt
                        this.items2.cntnt = x.cntnt                    
                        this.items2.bnftOfrRecHisPk = 0
                        this.items2.wrtr = x.hisWrtr
                        this.items2.wrtrMmbrPk = x.hisWrtrMmbrPk
                                            
                        if(x.itm1Cd == '1' && x.itm2Cd =='2' && x.itm3Cd == '3'){
                            let obj = {
                                bnftOfrRecHisPk : 0,
                                bnftOfrRecPk: 0,
                                recDt:'',
                                clsfc1Cd:'301',
                                clsfc2Cd:'2',
                                clsfc3Cd:'2',
                                ofrTmStrt: '',
                                itm1Cd:"1",
                                itm1Val: x.itm1Val,
                                itm2Cd: "2",
                                itm2Val: x.itm2Val,
                                itm3Cd: "3",
                                itm3Val: x.itm3Val
                            }
    
                            if(x.ofrTmHhStrt?.isEmptyVal() && x.ofrTmMmStrt?.isEmptyVal()){
                                obj.ofrTmStrt =  x.ofrTmHhStrt+":"+x.ofrTmMmStrt
                            }
    
                            preNsgCareRcrdList.push(obj)
                        }else{
                            itm1Val = x.itm1Val
                            // this.recItems1.bnftOfrRecHisPk =  0
                            // this.recItems1.bnftOfrRecPk = 0
                            // this.recItems1.itm1Cd = "1"
                            // this.recItems1.itm1Val =  x.itm1Val
                        }
                    }
                }
            })

            //이전자료 있을경우 
            if(this.recItems1.bnftOfrRecHisPk == 0){
                this.nsgCareRcrdList.splice(0)

                preNsgCareRcrdList.forEach(el => {
                    this.nsgCareRcrdList.push(el)
                })

                this.recItems1.bnftOfrRecHisPk =  0
                this.recItems1.bnftOfrRecPk = 0
                this.recItems1.itm1Cd = "1"
                this.recItems1.itm1Val =  itm1Val
            }
        },
        getBnfcrDiets: function(){
            let obj  = { 
                fcltyNum: this.$store.getters.getFcltyNum, 
                inqYMD: this.inqYMD, 
                bnMmbrPk:this.bnMmbrPk
            }
            selBnfcrDiets(obj)
            .then((response) => ( this.getBnfcrDietsAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getBnfcrDietsAfter:function(res){

            res.forEach(el => {
                if(el.mlTmHh?.isEmptyVal() && el.mlTmMm?.isEmptyVal()){
                    
                    if(el.mlClcd == '4') {
                        this.groups1_1[0].ofr1Tm = el.mlTmHh + ':' + el.mlTmMm
                    }else if(el.mlClcd == '5'){
                        this.groups1_1[0].ofr2Tm = el.mlTmHh + ':' + el.mlTmMm
                    }else{
                        let idx = Number(el.mlClcd)-1
                        this.groups1[idx].ofrTm = el.mlTmHh + ':' + el.mlTmMm
                        this.groups1[idx].itm1Val = el.mlTycd
                    }
                }
            });

            ++this.forceRender
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items1.wrtr = obj[0].name
            this.items1.wrtrMmbrPk = obj[0].mmbrPk
        },
        onModal: function (value) {
            this.prtWrtDt = this.inqYMD
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        onforce(key){
            this.mtimes[key] = false
            ++this.forceRender
        },
        onforce2(key){
            this.mtimes1[key] = false
            ++this.forceRender
        },
        onDelete:function(res){
            
            if(res == 9990){
                if(this.type=='all'){
                    let fcltyNum = this.$store.getters.getFcltyNum                    
                    let obj = {list:[]}              

                    if(this.items1.bnftOfrRecHisPk > 0) {
                        let item1 = {
                            fcltyNum:fcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            bnftOfrRecHisPk:this.items1.bnftOfrRecHisPk,
                        }
                        obj.list.push(item1)
                    }

                    if(this.items2.bnftOfrRecHisPk > 0) {
                        let item2 = {
                            fcltyNum:fcltyNum,
                            userNm:this.$store.getters.getUserNm,
                            bnftOfrRecHisPk:this.items2.bnftOfrRecHisPk,
                        }
                        obj.list.push(item2)
                    }

                    if(obj.list.length > 0){
                        delAllBnftOfrRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log(error))
                    }else{
                        this.snackControll([true, 2500, "삭제 대상이 존재 하지 않습니다.", 'warning']) 
                        this.dialogDelete = false
                    }

                }else{

                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        bnMmbrPk : this.bnMmbrPk,
                        userNm : this.$store.getters.getUserNm,
                        bnftOfrRecPk : this.nsgCareRcrdList[this.idx].bnftOfrRecPk,
                        bnftOfrRecHisPk:this.nsgCareRcrdList[this.idx].bnftOfrRecHisPk,
                    }

                    delBnftOfrRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log(error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){            
            if(res.statusCode == 200){   
                this.snackControll([true, 2500, res.message, 'info'])                                             
                if(this.type=='all'){
                    this.frmReset()
                    this.$emit('modalEvent', 99)
                    this.onClose()
                }else if(this.type == 'ngsttb'){
                    this.nsgCareRcrdList.splice(this.idx,1)
                    this.delModelTitle='식사급여기록'
                    this.reLoad()
                    this.type = 'all'
                    this.idx = 0
                }
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false            
        },
        addRow(){
            this.nsgCareRcrdList.push({bnftOfrRecPk:0,clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',ofrTmStrt: "",itm1Cd:"1",itm1Val: "",itm2Cd: "2",itm2Val: "",itm3Cd: "3",itm3Val: ""})
        },
        delRow(key){
            if(this.nsgCareRcrdList[key].bnftOfrRecPk > 0){
                this.delModelTitle='비위관 영양'
                this.idx = key
                this.type = 'ngsttb'
                this.dialogDelete = true
            }else{
                this.nsgCareRcrdList.splice(key,1)
            }
        },
        delGrp1Row:function(type, idx){
            if(type == '1') {
                this.groups1[idx].itm2Val = ''
            } else {
                this.groups1_1[0].itm1Val = ''
                this.groups1_1[0].itm2Val = ''
            }
        },
        onClose: function () {   
            this.frmReset()         
            this.$emit('modalEvent', 1)
        },
        mealSrvTrigger:function(res,isClose){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99)
                if(isClose){
                    this.onClose()
                }
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        }, 
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({              
        preymd:'',
        prtWrtDt:'',   
        idx:0,
        type:'all',
        forceRender:0,
        delModelTitle:'식사급여기록',
        groups1:[
            {label:'아침',itmRecPk:0,itm1Cd:1,itm2Cd:2,itm1Val:'',itm2Val:'',ofrTm:'',},
            {label:'점심',itmRecPk:0,itm1Cd:3,itm2Cd:4,itm1Val:'',itm2Val:'',ofrTm:'',},
            {label:'저녁',itmRecPk:0,itm1Cd:5,itm2Cd:6,itm1Val:'',itm2Val:'',ofrTm:'',},
        ],
        groups1_1:[
            {label:'간식',itm1Pk:0,itm2Pk:0,itm1Val:'',itm2Val:'',ofr1Tm:'',ofr2Tm:''},
        ],
        items1:{bnftOfrRecHisPk:0,clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'1',recDt:'',wrtr:'',itm1Val:'',wrtrMmbrPk:0,ofrTmStrt:'',ofrTmEnd:'',spclNote:'',recList:[]},         
        items2:{bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt : '',itm1Val:'',cntnt:'',spclNote:'',wrtr:'',wrtrMmbrPk:0,},         
        recItems1:{bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt:'',clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',wrtr:'',wrtrMmbrPk:0,ofrTmStrt: "",ofrTmHhStrt:"",ofrTmMmStrt:"",itm1Cd:"1",itm1Val: ""},        
        nsgCareRcrdList:[
            {bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt:'',clsfc1Cd:'301',clsfc2Cd:'2',clsfc3Cd:'2',wrtr:'',wrtrMmbrPk:0,ofrTmStrt: "",ofrTmHhStrt:"",ofrTmMmStrt:"",itm1Cd:"1",itm1Val: "",itm2Cd: "2",itm2Val: "",itm3Cd: "3",itm3Val: ""}
        ],        
        timelist:[
            {list:[{num:0,ofrTmStrt:'',bnftOfrRecPk:0,wrtr:'',wrtrMmbrPk:0}]},
            {list:[{num:1,ofrTmStrt:'',bnftOfrRecPk:0,wrtr:'',wrtrMmbrPk:0}]},
            {list:[{num:2,ofrTmStrt:'',bnftOfrRecPk:0,wrtr:'',wrtrMmbrPk:0}]},
            {list:[{num:3,ofrTmStrt:'',bnftOfrRecPk:0,wrtr:'',wrtrMmbrPk:0},{num:4,ofrTmStrt:'',bnftOfrRecPk:0,wrtr:'',wrtrMmbrPk:0}]}], 
          
        rcrdInfo : {},
        menu: [false],
        dialog : [false],
        rtimes:[false,false,false,false,false,false,false,],
        mtimes:[false,false,false,false,false,false,false,],
        rtimes1:[false,false,false,false,false,false,false,],
        mtimes1:[false,false,false,false,false,false,false,],
        codeItem1:[{valcd:'',valcdnm:''}],
        codeItem14:[{valcd:'',valcdnm:''}],
        codeItem15:[{valcd:'',valcdnm:''}],
        codeItem30:[{valcd:'',valcdnm:''}],
        codeItem91:[{valcd:'',valcdnm:''}],
        snackOptions: [false, 3000, '', 'info'],    
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '전일자료', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, disabled: false,},
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', visible: true, disabled: true,},
        ],    
        dialogDelete:false,
        dialogDup: false,
        question:'',
        message:'',
        recymd:'',
        recwrtr:'',
        progress:false,
    }),
};
</script>