import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [createPersistedState(
        { paths: ['sessionInfo', 'authToken', 'acesAthrt'], storage: window.sessionStorage },
    )],
    state: {
        sessionInfo: { fcltyNum: 0, userNm: '', mmbrPk: 0, exp: 0, jobNm: '', ntyAct: false, },
        authToken: null,
        onDscrp: false,
        sltDscrp: 0,
        crypKey: 'bd948bbd4b067e198b65a715957ee829',
        acesAthrt: [],
        expandBtnGroups: [false, false],
        bnfcrState: {
            name: '', bnmmbr: 0, bnfcr: 0, entDt: '', lvDt: '', bnfcrCntrcInfPk: 0, famlLtrPk: 0,
            bnfcrExamHisPk: 0, bnftOfrPlnHisPk: 0, phyThrPlnPk: 0,
            acknwRtng: 0, copayRate: 0, gvrnmSprt: [], entStcd: '',
        },
        bnfcrChgClmn: {
            cfmSave: ['2', '2', '2', '2'],
            orgGvrnmSprt: [], orgCopayRate: '', orgAcknwRtng: '', orgCntrcPrdEnd: '',
            chgGvrnmSprt: [], chgCopayRate: '', chgCopayRateNm: '', chgAcknwRtng: '', chgAcknwRtngNm: '', chgCntrcPrdEnd: '',
        },
        bnfcrSaveArea: '',
        bnfcrSaveChc: '',
        blobImg: '',
        validationChk: false,
        tmpObj: {},             //데이터 전송 오브젝트 임시 저장소
        pkCodeList: [
            'empPk', 'linkRcrdPk', 'copayClmHisPk', 'famlLtrPk', 'copayClmHisPk', 'copayDpsHisPk',
            'bnfcrCntrcInfPk', 'bnMmbrPk', 'bnfcrExamHisPk', 'bnftOfrPlnHisPk', 'phyThrPlnPk',
        ],
        prsntDt: [
            { typ: 'yyyymmdd', dt: '' },
            { typ: 'yyyy-mm-dd', dt: '' },
            { typ: 'yyyy.mm.dd', dt: '' },
            { typ: 'yyyymm', dt: '' },
            { typ: 'yyyy-mm', dt: '' },
            { typ: 'yyyy.mm', dt: '' },
            { typ: 'yyyy', dt: '' }, { typ: 'mm', dt: '' }, { typ: 'dd', dt: '' },
        ],
        glblTmprDt: '',
        cmmWrnn: { actv: false, tmr: 3000, type: 'info', title: '', cntnt: [] },
        rootMdl: { actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false, },
        subMdl: { actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false, },
    },
    getters: {
        getExpandBtnState: function(state) {
            return state.expandBtnGroups
        },
        getAuthToken: function(state) {
            return state.authToken
        },
        getOnDscrp: function(state) {
            return state.onDscrp
        },
        getSltDscrp: function(state) {
            return state.sltDscrp
        },
        getCrypKey: function(state) {
            return state.crypKey
        },
        getAcesAthrt: function(state) {
            return state.acesAthrt
        },
        getFcltyNum: function(state) {
            return state.sessionInfo.fcltyNum
        },
        getUserNm: function(state) {
            return state.sessionInfo.userNm
        },
        getJobNm: function(state) {
            return state.sessionInfo.jobNm
        },
        getMmbrPk: function(state) {
            return state.sessionInfo.mmbrPk
        },
        getExpTime: function(state) {
            return state.sessionInfo.exp
        },
        getNtyAct: function(state) {
            return state.sessionInfo.ntyAct
        },
        getBnfcrState: function(state) {
            return state.bnfcrState
        },
        getAcknwRtng: function(state) {
            return state.bnfcrState.acknwRtng
        },
        getCopayRate: function(state) {
            return state.bnfcrState.copayRate
        },
        getGvrnmSprt: function(state) {
            return state.bnfcrState.gvrnmSprt
        },
        getEntStcd: function(state) {
            return state.bnfcrState.entStcd
        },
        getBnfcrEntDt: function(state) {
            return state.bnfcrState.entDt
        },
        getBnfcrLvDt: function(state) {
            return state.bnfcrState.lvDt
        },
        getBnfcrSaveArea: function(state) {
            return state.bnfcrSaveArea
        },
        getBnfcrSaveChc: function(state) {
            return state.bnfcrSaveChc
        },
        getBlobImg: function(state) {
            return state.blobImg
        },
        getValidationSucc: function(state) {
            return state.validationChk
        },
        getTmpObj: function(state) {
            return state.tmpObj
        },
        getBnfcrExamHisPk: function(state) {
            return state.bnfcrState.bnfcrExamHisPk
        },
        getBnftOfrPlnHisPk: function(state) {
            return state.bnfcrState.bnftOfrPlnHisPk
        },
        getPhyThrPlnPk: function(state) {
            return state.bnfcrState.phyThrPlnPk
        },
        getPrsntDt: (state) => (type) => {
            return state.prsntDt.find(todo => todo.typ === type).dt
        },
        getGlblTmprDt: function(state) {
            return state.glblTmprDt
        },
        getWrnnActv: function(state) {
            return state.cmmWrnn.actv
        },
        getWrnnTmr: function(state) {
            return state.cmmWrnn.tmr
        },
        getWrnnTyp: function(state) {
            return state.cmmWrnn.type
        },
        getWrnnTtl: function(state) {
            return state.cmmWrnn.title
        },
        getWrnnCntnt: function(state) {
            return state.cmmWrnn.cntnt
        },
        getRootActv: function(state) {
            return state.rootMdl.actv
        },
        getRootMxWdth: function(state) {
            return state.rootMdl.mxWdth
        },
        getRootPrsst: function(state) {
            return state.rootMdl.prsst
        },
        getRootTyp: function(state) {
            return state.rootMdl.typ
        },
        getRootInObj: function(state) {
            return state.rootMdl.inObj
        },
        getRootOutObj: function(state) {
            return state.rootMdl.outObj
        },
        getRootRunEvt: function(state) {
            return state.rootMdl.runEvt
        },
        getSubActv: function(state) {
            return state.subMdl.actv
        },
        getSubMxWdth: function(state) {
            return state.subMdl.mxWdth
        },
        getSubPrsst: function(state) {
            return state.subMdl.prsst
        },
        getSubTyp: function(state) {
            return state.subMdl.typ
        },
        getSubInObj: function(state) {
            return state.subMdl.inObj
        },
        getSubOutObj: function(state) {
            return state.subMdl.outObj
        },
        getSubRunEvt: function(state) {
            return state.subMdl.runEvt
        },
    },
    mutations: {
        setExpandBtnState: function(state, payload) {
            state.expandBtnGroups[payload.index] = payload.data;
        },
        setAuthToken: function(state, payload) {
            state.authToken = payload
        },
        setOnDscrp: function(state, payload) {
            state.onDscrp = payload
        },
        setSltDscrp: function(state, payload) {
            state.sltDscrp = payload
        },
        setAcesAthrt: function(state, payload) {
            state.acesAthrt = payload
        },
        setFcltyNum: function(state, payload) {
            state.sessionInfo.fcltyNum = payload
        },
        setUserNm: function(state, payload) {
            state.sessionInfo.userNm = payload
        },
        setJobNm: function(state, payload) {
            state.sessionInfo.jobNm = payload
        },
        setSsMmbrPk: function(state, payload) {
            state.sessionInfo.mmbrPk = payload
        },
        setExpTime: function(state, payload) {
            state.sessionInfo.exp = payload
        },
        setNtyAct: function(state, payload) {
            state.sessionInfo.ntyAct = payload
        },
        setBnfcrState: function(state, payload) {
            state.bnfcrState.code = payload.code
            state.bnfcrState.bnmmbr = payload.bnmmbr
            state.bnfcrState.bnfcr = payload.bnfcr
            state.bnfcrState.name = payload.name
            state.bnfcrState.entStcd = payload.entStcd
            state.bnfcrState.entDt = payload.entDt
            state.bnfcrState.lvDt = payload.lvDt
        },
        setBnfcrName: function(state, payload) {
            state.bnfcrState.name = payload
        },
        setBnfcrPk: function(state, payload) {
            state.bnfcrState.bnfcr = payload
        },
        setMmbrPk: function(state, payload) {
            state.bnfcrState.bnmmbr = payload
        },
        setAcknwRtng: function(state, payload) {
            state.bnfcrState.acknwRtng = payload;
        },
        setCopayRate: function(state, payload) {
            state.bnfcrState.copayRate = payload;
        },
        setGvrnmSprt: function(state, payload) {
            state.bnfcrState.gvrnmSprt = payload;
        },
        setEntStcd: function(state, payload) {
            state.bnfcrState.entStcd = payload;
        },
        setCntrcInfPk: function(state, payload) {
            state.bnfcrState.bnfcrCntrcInfPk = payload
        },
        setFamlLtrPk: function(state, payload) {
            state.bnfcrState.famlLtrPk = payload
        },
        setBnfcrSaveArea: function(state, payload) {
            state.bnfcrSaveArea = payload;
        },
        setBnfcrSaveChc: function(state, payload) {
            state.bnfcrSaveChc= payload;
        },
        setBnfcrEntDt: function(state, payload) {
            state.bnfcrState.entDt = payload;
        },
        setBnfcrLvDt: function(state, payload) {
            state.bnfcrState.lvDt = payload;
        },
        setBlobImg: function(state, payload) {
            state.blobImg = payload;
        },
        setValidationSucc: function(state, payload) {
            state.validationChk = payload;
        },
        setTmpObj: function(state, payload) {
            state.tmpObj = payload;
        },
        setBnfcrExamHisPk: function(state, payload) {
            state.bnfcrState.bnfcrExamHisPk = payload;
        },
        setBnftOfrPlnHisPk: function(state, payload) {
            state.bnfcrState.bnftOfrPlnHisPk = payload;
        },
        setPhyThrPlnPk: function(state, payload) {
            state.bnfcrState.phyThrPlnPk = payload;
        },
        setPrsntDt: function(state, payload) {
            state.prsntDt = payload;
        },
        setGlblTmprDt: function(state, payload) {
            state.glblTmprDt = payload;
        },
        setWrnnActv: function(state, payload) {
            state.cmmWrnn.actv = payload;
        },
        setWrnnTmr: function(state, payload) {
            state.cmmWrnn.tmr = payload;
        },
        setWrnnTyp: function(state, payload) {
            state.cmmWrnn.type = payload;
        },
        setWrnnTtl: function(state, payload) {
            state.cmmWrnn.title = payload;
        },
        setWrnnCntnt: function(state, payload) {
            state.cmmWrnn.cntnt = payload;
        },
        setWrnnInf: function(state, payload) {
            state.cmmWrnn.actv = payload.actv;
            state.cmmWrnn.tmr = payload.tmr;
            state.cmmWrnn.type = payload.type;
            state.cmmWrnn.title = payload.title;
            state.cmmWrnn.cntnt = payload.cntnt;
        },
        setRootActv: function(state, payload) {
            state.rootMdl.actv = payload;
        },
        setRootMxWdt: function(state, payload) {
            state.rootMdl.mxWdth = payload;
        },
        setRootPrsst: function(state, payload) {
            state.rootMdl.prsst = payload;
        },
        setRootTyp: function(state, payload) {
            state.rootMdl.typ = payload;
        },
        setRootInObj: function(state, payload) {
            state.rootMdl.inObj = payload;
        },
        setRootOutObj: function(state, payload) {
            state.rootMdl.outObj = payload;
        },
        setRootRunEvt: function(state, payload) {
            state.rootMdl.runEvt = payload;
        },
        setRootInf: function(state, payload) {
            state.rootMdl.actv = payload.actv;
            state.rootMdl.mxWdth = payload.mxWdth;
            state.rootMdl.prsst = payload.prsst;
            state.rootMdl.typ = payload.typ;
            state.rootMdl.inObj = payload.inObj;
            state.rootMdl.outObj = payload.outObj;
            state.rootMdl.runEvt = payload.runEvt;
        },
        setSubActv: function(state, payload) {
            state.subMdl.actv = payload;
        },
        setSubMxWdt: function(state, payload) {
            state.subMdl.mxWdth = payload;
        },
        setSubPrsst: function(state, payload) {
            state.subMdl.prsst = payload;
        },
        setSubTyp: function(state, payload) {
            state.subMdl.typ = payload;
        },
        setSubInObj: function(state, payload) {
            state.subMdl.inObj = payload;
        },
        setSubOutObj: function(state, payload) {
            state.subMdl.outObj = payload;
        },
        setSubRunEvt: function(state, payload) {
            state.subMdl.runEvt = payload;
        },
        setSubInf: function(state, payload) {
            state.subMdl.actv = payload.actv;
            state.subMdl.mxWdth = payload.mxWdth;
            state.subMdl.prsst = payload.prsst;
            state.subMdl.typ = payload.typ;
            state.subMdl.inObj = payload.inObj;
            state.subMdl.outObj = payload.outObj;
            state.subMdl.runEvt = payload.runEvt;
        },
    },
    // ...
});