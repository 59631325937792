<template>
    <v-sheet class="ma-0 pa-5" rounded="lg" color="#bacee0" @click="onClose">        
        <div class="mt-2 pa-5" style="position:relative;">
            <div class="pa-2">
                <div class="pa-5 rounded-t-lg" style="background-color:#fee500;">                    
                    <span class="font-weight-medium" style="font-size:1.2rem;">알림톡 도착</span>
                </div>
                <div class="px-3 py-2" style="background:white" v-html="msg"></div>
                <div class="pa-5 rounded-lg rounded-t-0" style="background:white">
                    <v-btn @click="onLink" class="elevation-0" width="100%" min-width="100%">{{btnNm}}</v-btn>
                </div>
            </div>
            <div 
                class="d-flex justify-center align-center rounded-circle" 
                style="position:absolute;top:0px;right:0px;
                width:70px;height:70px;background-color:#444444;
                font-size:1rem;color:white;">kakao</div>
        </div>
    </v-sheet>
</template>
<script>
import { selESndMsgList } from '../../api/othr.js'

export default {
    name: 'BizMsgAlimTalkViw',
    
    components: {  
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){      
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        
    },

    methods: {  
        onClose: function () {
            this.$emit('modalClose')
        },
        onLoad:function(item,setObj){            
            this.sObj = setObj
            this.items = item
            this.getESndMsgList()
        },
        getESndMsgList:function(){    
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eDocClcd : this.sObj.bizMsgDocClcd,
                itmCtt3: this.sObj.bizMsgCntnt,
                itmCtt4:this.sObj.bizMsgItmCtt4,
                itmCtt5:this.sObj.bizMsgItmCtt5,
                itmCtt8:this.sObj.bizMsgItmCtt8,
                sndMda:'1',
            }
            selESndMsgList(obj)
                .then((response) => ( this.getESndMsgListAfter(response.data) ))
                .catch((error) => console.log('connect error /othr/selESndMsgList : ' + error))
        },
        getESndMsgListAfter:function(res){
            if(res.length > 0){
                res.forEach(item => {
                    switch (item.itmClcd) {
                        case '1':
                            
                            break;
                        case '2':
                            this.msg = item.itmCntnt?.replaceAll("\\n", "<br />")
                            break;
                        case '3':
                            this.btnNm = item.itmCntnt
                            this.btnLink = item.lnkUrl
                            break;
                        default:
                            break;
                    }
                    
                });
            }

            if(this.sObj.bizMsgDocClcd == '1' || this.sObj.bizMsgDocClcd == '4' || this.sObj.bizMsgDocClcd == '8' || 
            this.sObj.bizMsgDocClcd == '9' || this.sObj.bizMsgDocClcd == '13' || this.sObj.bizMsgDocClcd == '23' || this.sObj.bizMsgDocClcd == '24'){
                this.lnkUrl = this.$parent.$parent.$parent.$parent.encUrlObj(this.items.mmbrPk, this.items.rcvNm, this.sObj.bizMsgBzPk)
            }else{
                this.lnkUrl = this.btnLink
            }
        },       
        onLink:function(){
            let ttl = this.sObj.bizMsgTitle
            let loc = (document.body.offsetWidth / 2) - (200 / 2)
            let posn = 'left=' + loc.toString() + ', top=0, '
            let options = 'width=380, height=740, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no'
            
            window.open(this.lnkUrl, ttl, posn+options)

            // this.windowRef = window.open(this.btnLink, "_blank");         
            // this.windowRef = null
        },        
    },
    data: () => ({ 
        sObj:{},
        items:{},
        clcd:0,
        msg:'',
        btnNm:'상세보기',
        btnLink:'',
        lnkUrl:'',
        windowRef: null,
    }),
};
</script>