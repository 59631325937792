<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <v-divider :class="['mt-2', bnfcrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', bnfcrList.length > 10 ? 'mr-2__5' : 'ma-0']" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="1">
                <span class="d-inline-block ml-1" style="width: 26px;">
                    <v-checkbox
                        class="checkIcon" v-model="allChckr" :key="forceRender" @click="allChnger"
                        true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">수급자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                <span class="black--text font-weight-medium">성별</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                <span class="black--text font-weight-medium">나이</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">현황</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">생활실</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2" cols="3">
                <span class="black--text font-weight-medium">연락처</span>
            </v-col>
        </v-row>
        <v-divider :class="[bnfcrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div class="d-inline-block overflow-y-auto" style="width: 100%; height: 401px;">
            <v-row class="ma-0 pa-0" style="border-bottom: 1px solid #dbdbdb;"
                v-for="(wList, i) in bnfcrList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="d-inline-block ml-1" style="width: 26px;">
                        <v-checkbox
                            class="checkIcon" v-model="wList.checkr" :key="forceRender" :disabled="wList.disChekr" @change="allChekrFunc"
                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>                    
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.name }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                    <span class="d-inline-block" style="width: 20px; heigth: 20px;">
                        <v-img class="ic-sex" v-if="wList.gndr == 'F'" src="../../styles/images/ic-female.svg"></v-img>
                        <v-img class="ic-sex" v-else-if="wList.gndr == 'M'" src="../../styles/images/ic-male.svg"></v-img>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                    <span class="black--text">{{ wList.age }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.entStcd }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                    <span class="black--text">{{ wList.lvnRmNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-2" cols="3">
                    <span class="black--text">
                        {{wList.celphn}}
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 92px;">
                    <v-checkbox
                        v-model="entChgBox" :ripple="false"
                        class="checkIcon" true-value="1" false-value="2" label="퇴소자 조회"
                        on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                        hide-details>
                    </v-checkbox>
                </span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { selBnfcrCntctList } from '../../api/bnfcr.js';
export default {
    name: 'SmsSndBnfcrList',

    props : {
        
    },
            
    components: {
        
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getBnfcrCntctList()
        })
    },

    computed: {

    },

    watch: {
        'entChgBox':function(val){
            if(val == '1'){
                this.getBnfcrCntctList('4')
            }else{
                this.getBnfcrCntctList(null)
            }
        }
    },
        
    methods: {  
        //수급자 다건 조회
        getBnfcrCntctList: function (stcd=null) {
            selBnfcrCntctList({fcltyNum:this.$store.getters.getFcltyNum,entStcd:stcd})
                .then((response) => ( this.getBnfcrCntctListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selBnfcrCntctList : ' + error))
        },
        getBnfcrCntctListAfter: function (res) {
            this.allChckr =  '2'
            this.bnfcrList.splice(0)
            this.forceRender += 1

            res.forEach(item => {
                item.disChekr = false
                item.checkr = '2'
                item.dtTyp = 'bnfcr'
                this.$parent.$parent.$parent.$parent.vCd65.forEach(cd => {
                    if(cd.valcd == item.entStcd) item.entStcd = cd.valcdnm
                });

                item.celphn = (item.celphn_1 != '' && item.celphn_2 != '' && item.celphn_3 != '')?item.celphn_1+'-'+item.celphn_2+'-'+item.celphn_3:''

            });

            this.bnfcrList = res
        },
        allChnger: function () {
            if(this.allChckr == '1') {
                this.bnfcrList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '1'
                });
            }
            else if(this.allChckr == '2') {
                this.bnfcrList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }
        },
        allChekrFunc: function () {
            let tCh = '1'
            this.bnfcrList.forEach(el => {
                if(el.checkr == '2') tCh = '2'
            });
            this.allChckr = tCh
        },        
        chngInfrm: function (exChekrList) {
            this.bnfcrList.forEach(items => {
                items.checkr = '2'
                items.disChekr = false
            })

            exChekrList.forEach(item => {
                this.bnfcrList.forEach(items => {
                    if(item.mmbr == items.bnMmbrPk){
                        items.checkr = '1'
                        items.disChekr = true
                    }
                });
            });
        },
        fltrThrw: function () {
            let arr = this.bnfcrList.filter(v => v.disChekr == false)

            return arr.filter(v => v.checkr == '1')
        },
    },
    
    data: () => ({
        bnfcrList: [],
        vCd65:[],
        allChckr: '2',
        forceRender: 0,
        entChgBox:'2',
    }),
};
</script>