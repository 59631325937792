<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left mb-2 align-center">
            <span class="text-h4 font-weight-bold">낙상위험도 평가</span>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" @click = "onClickBtn(list.text)"
                    :class="list.class" height="30" :color="list.color" rounded
                    :disabled="btnDisabled"
                    style="font-size: 14px;">
                    <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </span>
            <v-btn v-for="(list, j) in outlinedBtn" :key="filledBtn.length + j" @click = "onClickBtn(list.text)"
                :class="list.class" samll height="30" :color="list.color" rounded outlined
                :disabled="list.disabled">
                <v-icon small>{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <basicInfo :bnMmbrPk="bnMmbrPk"></basicInfo>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 my-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">조사사유</span>
                </v-col>
                <v-col class="py-1 pl-2 d-flex justify-center align-center" cols="3">
                    <span class="d-inline-block" style="width: 250px;">
                        <v-radio-group v-model="items.examRsncd" :rules="[rules.required]" class="pl-2 pt-1" hide-details row>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="신규" value="1"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="재사정" value="2"></v-radio>
                            <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="상태변화" value="3"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성일</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="2">
                    <span class="white d-inline-block py-1 pl-2" style="width: 154px;"> 
                        <CmmDateComp v-model="items.examDt" :required="true"></CmmDateComp>                                                  
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-start align-center" cols="4">
                    <span class="d-inline-block py-1 pl-2" style="width: 120px;">
                        <v-text-field v-model="items.wrtr" color="blue" ref="info"
                            :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3" 
                        small 
                        min-width="30"
                        height="25"  
                        dark
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModal"
                        >
                        <v-icon size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>            
            </v-row>
            
            <div class="pa-0 ma-0 align-self-start" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">            
                <v-row class="pa-0 ma-0" no-gutters>   
                    <v-col class="greyE01 d-flex pa-0 ma-0" cols="12">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:10%;">
                                <span class="black--text font-weight-medium">구분</span>
                            </div>
                            <div style="width:84%;" class="d-flex pa-0 pa-0"> 
                                <div class="d-flex justify-center align-center" style="width:22%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">4점</span>
                                </div>
                                <div class="d-flex justify-center align-center" style="width:22%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">3점</span>
                                </div>
                                <div class="d-flex justify-center align-center" style="width:22%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">2점</span>
                                </div>
                                <div class="d-flex justify-center align-center" style="width:22%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">1점</span>
                                </div>
                                <div class="d-flex justify-center align-center" style="width:12%;border-left: 1px solid #dbdbdb !important;">
                                    <span class="black--text font-weight-medium">0점</span>
                                </div>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">점수</span>
                            </div>
                        </v-row>
                    </v-col>                    
                </v-row>

                <v-row class="pa-0 ma-0" no-gutters v-for="(item, i) in fallEvltnList" :key="i">                    
                    
                    <v-col class="d-flex pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="12">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:10%;">
                                <span class="black--text font-weight-medium">{{item.clsfc3Cdnm}}</span>
                            </div>                                    
                            <v-radio-group v-model="item.clsfc3Val" :rules="[rules.required]" style="width:84%;" class="pa-0 pa-0" hide-details row>
                                <div v-for="(loop,j) in loops" :key="j" class="d-flex justify-center align-center" :style="setStyle(item.clsfc3Cd,loop)">                                    
                                    <span v-if="item.list[loop] != undefined">
                                        <v-radio class="pa-0 ma-0 mr-2" 
                                            :ripple="false" 
                                            :label="item.list[loop].itmCdnm" 
                                            :value="item.list[loop].itmCd"     
                                            @click="setScore"                                       
                                        >
                                        </v-radio>
                                    </span>
                                </div>                                
                            </v-radio-group>
                            <div class="d-flex justify-center align-center pa-2 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">{{item.clsfc3Val}}</span>
                            </div>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row class="pa-0 ma-0" no-gutters>  
                    <v-col class="d-flex pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="12">
                        <v-row class="pa-0 ma-0" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:10%;">
                                <span class="black--text font-weight-medium">합계</span>
                            </div>                                    
                            <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:84%;border-left: 1px solid #dbdbdb !important;">
                                <span>※&nbsp;Huhn의 낙상위험도 평가도구 척도&#40;합계점수 해석&#41; : 4점 이하 : 낙상위험 낮음 &#47; ∙ 5-10점 : 낙상위험 높음 &#47; ∙ 11점 이상 : 낙상위험 아주 높음</span>
                            </div> 
                            <div class="d-flex justify-center align-center pa-2 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">{{totItems.itmVal}}</span>
                            </div>  
                        </v-row>
                    </v-col> 
                </v-row>

                <v-row class="pa-0 ma-0" no-gutters>                    
                    <v-col class="d-flex pa-0 ma-0" style="border-top: 1px solid #dbdbdb !important;" cols="12">
                        <div class="greyE01 d-flex justify-center align-center pa-2 ma-0" style="width:10%;">
                            <span class="black--text font-weight-medium">비고</span>
                        </div>

                        <div class="d-flex justify-start align-center pl-1" style="width:90%;border-left: 1px solid #dbdbdb !important;">
                            <span style="width:100%">                                
                                <v-text-field v-model="fallEvltnTot.evltn" hide-details outlined dense></v-text-field>
                            </span>
                        </div>                        
                    </v-col>
                </v-row>

            </div>

        </v-form>
        
        <v-dialog v-model="dialogDelete" max-width="500">    
            <btnModalDelete :title="'낙상위험도평가'" @madalState="onDelete"></btnModalDelete>  
        </v-dialog>     
        <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
        </v-dialog>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import basicInfo from '../bnfcr/BnfcrBasicInfo.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import { groupBy } from '../../api/common.js';
import { insBnfcrExamHis, getBnfcrExamHisInfo, getBnfcrExamList, delBnfcrExamHis} from '../../api/bnftSrv.js';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'FallEvltn',

    props : {   
        inqYYYY: Number,  
        bnMmbrPk : { type: Number, default: -1 },
        fallEvltnPk : { type: Number, default: -1 },
        btnDisabled : { type: Boolean, default: false },      
    },
        
    components: {
        CmmDateComp,
        basicInfo,
        btnModalDelete,
        EmpSelector,
    },
    

    watch: {     
        'fallEvltnPk':function(value){ 
             this.filledBtn[0].visible = false 
            if(value > -1){
                this.bnfcrExamHisPk = value
                this.onShow()
                if(value == 0) this.filledBtn[0].visible = true
            }
        }
    },

    mounted: function(){             
        this.$nextTick(function () {  
            this.bnfcrExamHisPk = this.fallEvltnPk
            this.onShow()
            this.filledBtn[0].visible = false
            if(this.bnfcrExamHisPk == 0) this.filledBtn[0].visible = true
        })
    },
    
    methods: {
        setStyle:function(value,key){
            let style = "";
            let width = "";

            if(key == 4){
                width = "width:12%;";
            }else{
                width = "width:22%;";
            }

            if(value == 6 || value == 7){                
                style=width+"height:62px;border-left: 1px solid #dbdbdb !important;"
            }else{
                style=width+"height:39px;border-left: 1px solid #dbdbdb !important;"
            }
            return style;
        },

        onShow : function(){            
            this.getInit()
            this.getExamHisInfo();
            this.getBnfcrExamList();
        },
        onClose: function () {
            this.bnfcrExamHisPk=0
            this.frmReset();
            this.$emit('modalEvent', 1)
        },
        getInit(){
            // let date = new Date()                                    
            // let dateStr = date.toISOString().split('T',2)[0]
            
            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)
            let dateStr = timezoneDate.toISOString().split('T',2)[0]
            this.items.examDt = dateStr
            this.items.wrtr = this.$store.getters.getUserNm;
            this.minDate = this.inqYYYY+'-'+'01'+'-'+'01'
            this.maxDate = this.inqYYYY+'-'+'12'+'-'+'31'

            this.forceRender += 1
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        onClickBtn: function(text) {
            if(text=="이전자료"){
                this.getPreData = true
                this.getBnfcrExamList()
            }else if(text=="저장"){
                 if(this.$refs.form.validate()){                 
                    this.insBnfcrExam();
                 }
            } else if(text=="삭제"){
                if(this.bnfcrExamHisPk > 0){
                    this.dialogDelete = true
                }
            } else if(text=="출력"){
                this.fallEvltnPrt()
            }
        },

        onDelete:function(res){
            if(res == 9990){
                let code = this.$store.getters.getFcltyNum;
                let value = this.bnfcrExamHisPk;
                let user = this.$store.getters.getUserNm

                delBnfcrExamHis(code, value, user)
                .then((response) => ( this.delBnfcrExamHisAfter(response.data)))
                .catch((error) => console.log(error))
            }else if(res == 7){
                this.dialogDelete = false
            }

        },

        delBnfcrExamHisAfter:function(res){
            if(res.statusCode == 200){                                                
                this.$emit('modalEvent', 99)
                this.onClose()
            }
            else{
                this.$store.commit('setBnfcrExamHisPk', '')
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        insBnfcrExam(){

            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm:this.$store.getters.getUserNm,
                bnMmbrPk:this.bnMmbrPk,
                bnfcrExamHisPk:this.bnfcrExamHisPk,
                examClcd:'102',                    
                examRsncd : this.items.examRsncd,
                examDt : this.items.examDt.afterDateFormatHyp(),
                wrtr : this.items.wrtr,
                examYyyy : this.inqYYYY,
            }

            let examList = []

            this.fallEvltnList.forEach(x => {
                x.list.forEach(item => {                    
                    if(item.itmCd == x.clsfc3Val) item.itmVal = '1'
                    else item.itmVal = '2'
                    examList.push(item)
                });
            })

            examList.push(this.fallEvltnTot)
            examList.push(this.totItems)

            insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
           
        },

        insBnfcrExamAfter: function (res) {
            this.fallEvltnReLoad(res)         
        },

        frmReset:function(){      
            this.scores = [];
            this.totScore = 0;          
            this.$refs.form.reset();
        },

        getBnfcrExamList(){  

            if(this.getPreData){
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '102',
                    bnfcrExamHisPk:null,
                    bnMmbrPk:this.bnMmbrPk
                }
            }else{         
                this.obj = {
                    code: this.$store.getters.getFcltyNum, 
                    inqTFclsfc1Cd: true, 
                    inqTFclsfc2Cd: true, 
                    inqTFclsfc3Cd: true, 
                    inqTFitmCd: true, 
                    clsfc1Cd: '102',
                    bnfcrExamHisPk:this.bnfcrExamHisPk
                }
            }
            
            getBnfcrExamList(this.obj)
                .then((response) => ( this.getBnfcrExamListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftoffer/selBnfcrExamList : ' + error))
        },

        getBnfcrExamListAfter(res){ 
               
            let clsfcCdData = []
            let clsfcCdTotData = Object.assign({})
            let totscore = 0

            this.totItems = Object.assign({},this.dummyTotItems)

            res.forEach(item => {
                if(this.getPreData) item.bnfcrExamPk = 0
                
                if(item.clsfc2Cd == '1'){                    
                    if(item.clsfc3Cd == '99') this.totItems = item                    
                    else clsfcCdData.push(item)
                }else if(item.clsfc2Cd == '999'){
                    clsfcCdTotData = item
                }                
            });

            this.fallEvltnList.splice(0)
            let groups = groupBy(clsfcCdData, 'clsfc3Cd')
            
            Object.keys(groups).forEach((key)  => {
                let obj = {
                    clsfc3Cd : key,
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,
                    clsfc3Val: '',
                    list : [],
                }
                
                obj.clsfc3Val = this.getRadioData(groups[key])

                groups[key].forEach(e => {
                    switch (e.itmCd) {
                        case '0':
                            obj.list[4] = e
                            break;
                        case '1':
                            obj.list[3] = e
                            break;
                        case '2':
                            obj.list[2] = e
                            break;
                        case '3':
                            obj.list[1] = e
                            break;
                        case '4':
                            obj.list[0] = e
                            break;
                        default:
                            break;
                    }
                });
                this.fallEvltnList.push(obj)

                totscore += Number((obj.clsfc3Val == '')?0:obj.clsfc3Val)
            }); 

            this.fallEvltnTot = clsfcCdTotData
            this.totItems.itmVal = totscore
            this.$refs.form.resetValidation();

            if(this.getPreData) this.getPreData = false
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
        

        fallEvltnReLoad:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$store.commit('setBnfcrExamHisPk', res.result)
                this.bnfcrExamHisPk = res.result
                this.getExamHisInfo();
                //수급자 평가내역 Reload
                this.onShow()
                this.$emit('modalEvent', 99)
                this.filledBtn[0].visible = false
            }
            else{
                this.$store.commit('setBnfcrExamHisPk', '')
                // this.snackControll([true, 5000, res.message, 'error'])
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        getExamHisInfo(){
            if(this.bnfcrExamHisPk !='' && this.bnfcrExamHisPk > 0){
                let code = this.$store.getters.getFcltyNum;
                getBnfcrExamHisInfo(code, this.bnfcrExamHisPk)
                .then((response) => ( this.setData(response.data)))
                .catch((error) => console.log(error))
            }
        },
        setData(data){
            data.examDt = data.examDt.beforeDateFormatHyp()
            this.items = data
            this.$refs.form.resetValidation();
        },

        setRadioData(key){            
            this.itemsList[key].map(x=>{
                if(this.itmVal[key] == x.itmCd){
                    x.itmVal = "1"
                }else{
                    x.itmVal = ""
                }
            })
        },    

        getRadioData(data){     
            let itmVal = ''       
            data.forEach(item => {
                if(item.itmVal == '1') itmVal = item.itmCd
            });

            return itmVal
        }, 

        setScore(){
            let totscore = 0
            this.fallEvltnList.forEach(obj => {
                totscore += Number((obj.clsfc3Val == '')?0:obj.clsfc3Val)
            });
            this.totItems.itmVal = totscore
        },

        //낙상위험도 출력
        fallEvltnPrt: function (){
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYmdStart = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYmdEnd = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.inqYYYY = ''
            this.$parent.$parent.$parent.$parent.prtThrowObj.bnMmbrPk = 0
            this.$parent.$parent.$parent.$parent.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.$parent.$parent.$parent.$parent.prtThrowObj.prtClcd = '1'
            this.$parent.$parent.$parent.$parent.prtThrowObj.prtCd = '41'
            this.$parent.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.push(this.bnfcrExamHisPk)

            this.$parent.$parent.$parent.$parent.$refs.reportMgmt.prtDataCall(this.$parent.$parent.$parent.$parent.prtThrowObj)
            this.$parent.$parent.$parent.$parent.menu.splice(0, 1, true)
        },
        
        onModal: function () {
            this.prtWrtDt = this.items.examDt
            this.menu.splice(1, 1, true)
        },
        isModal: function () {
            this.menu.splice(1, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
    },
    
    data: () => ({   
        getPreData:false,     
        minDate:'',
        maxDate:'',
        prtWrtDt:'',                    
        items:{},  
        totItems:{bnfcrExamPk:0,clsfc1Cd:'102', clsfc1Cdnm:'낙상위험도평가', clsfc2Cd:'1', clsfc2Cdnm:'낙상위험도평가', clsfc3Cd:'99', clsfc3Cdnm:'합계', evltn:'',
                itmCd:'1', itmCdnm:'합계', itmDesc:'', itmVal:'', valClcd:'2',},
        dummyTotItems:{bnfcrExamPk:0,clsfc1Cd:'102', clsfc1Cdnm:'낙상위험도평가', clsfc2Cd:'1', clsfc2Cdnm:'낙상위험도평가', clsfc3Cd:'99', clsfc3Cdnm:'합계', evltn:'',
                itmCd:'1', itmCdnm:'합계', itmDesc:'', itmVal:'', valClcd:'2',},
        menu: [false,false],
        bnfcrExamHisPk : '',
        fallEvltnList:[],
        fallEvltnTot:{evltn:'',},
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },        
        filledBtn: [
            { icon: 'mdi-text-box-search-outline', class: 'white--text', color:'#3C9099', text: '이전자료', type:'action', visible: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type:'action', visible: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type:'action', visible: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력',disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        forceRender:0,
        obj:{},          
        loops:[0,1,2,3,4],        
        dialogDelete:false,
    }),
};
</script>