<template>
    <div class="d-inline-block d-flex">
        <v-sheet class="d-inline-block" width="500" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>4-2. 프로그램&nbsp;정보관리</span>
                </span>
            </div>
            <v-row class="justify-left mt-3 mx-3">
                <!-- Props btnData:버튼 타이틀과 아이콘 모양을 결정, btnClosed: 확장버튼에 닫기 버튼 클릭 이벤트,
                    changeCheck: 확장 버튼에 선택된 체크 박스를 1차원 배열로 받아옴 -->
                <exBtn ref="exBtnClose" :btnData="exBtn[0]" @changeCheck="filterSearchD1" />
                <v-spacer></v-spacer>

                <v-text-field class="ma-0 pa-0 nameSearch" background-color="white" append-icon="mdi-magnify"
                    width="100px" height="35px" single-line rounded outlined dense filled hide-details label="프로그램명 조회" @input="setSearchText">
                </v-text-field>
            </v-row>
            <div class="mt-5 ml-3 mr-2">
                <v-data-table
                    class="bnfcrMidList" height="660" locale="ko"
                    :headers="headers" :items="items"
                    :search="schDummy" :custom-filter="customFilter"
                    fixed-header disable-pagination hide-default-footer dense>

                    <template v-slot:header="props">
                        <thead>
                            <th v-for="(list, i) in props.headers" :key="i">{{ list.text }}</th>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr :class="['fixedCol', selectedItem == index ? 'sltRow' : 'disSltRow']"  @click="tableClickEvent(index, item)">
                            <td class="fixedCol0" style=" text-align:center;">{{ item.prgTypNm }}</td>
                            <td class="fixedCol0" style=" text-align:center;">
                                <span class="d-inline-block">
                                    <v-chip :class="('ic-level level0' + item.idxCd)" class="" style="width: 50px; height: 20px;">{{
                                            item.idxCdnm?.slice(0, 2)
                                    }}</v-chip>
                                </span>
                            </td>
                            <td class="fixedCol0" style=" text-align:center;">{{ item.prgNm }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>
        <v-sheet class="d-inline-block rounded-lg rounded-l-0 overflow-auto pa-2" height="835" min-width="800px" max-width="850px">
            <router-view ref="callDetail" @reload="reset()" @trColorChange="trColorChange()"></router-view>
        </v-sheet>
    </div>
</template>


<script>
import exBtn from '../../components/commons/expandButtonCustom.vue';

import { getStcd } from '../../api/index.js';
import { getAllPrgIM } from '../../api/prgrm.js';


export default {
    watch: {
        'items': function () {
            if (this.items.length < 1) console.log('code Mapping Failed')
            else {
                this.items.forEach((item) => {
                    this.cdItems.forEach((cd) => {
                        if (item.idxCd == cd.valcd) item.idxCdnm = cd.valcdnm
                    });//end foreach
                });//end foreach
            }//end else

        },
    },

    components: {
        exBtn,
    },
    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },
    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllPrgIM()
        })
    },
    methods: {
        reset: function () {
            this.getAllPrgIM()
            this.selectedItem = -1
        },
        stCdMapping: function (res) {
            this.cdItems = res

            this.cdItems.forEach((code) => {

                this.exBtn[0].expandList.push(code.valcdnm)
            });//end foreach
        },
        getAllPrgIM: function () {
            getAllPrgIM(this.$store.getters.getFcltyNum)
                .then((response) => (this.getAllPrgIMAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
        },
        getAllPrgIMAfter: function (res) {
            this.items = res
        },
        tableClickEvent: function (rn, pk) {
            if (this.selectedItem == -1) {
                this.selectedItem = rn
                this.$refs.callDetail.onShow(pk)
            }
            else if (this.selectedItem != rn) {
                this.selectedItem = rn
                this.$refs.callDetail.onShow(pk)
            }
            else {
                //Form Clear
                this.selectedItem = -1
                this.$refs.callDetail.resetPage()
                
            }
        },
        trColorChange: function () {
            this.selectedItem = -1
            this.getAllPrgIM()
        },
        filterSearchD1: function (sch) {
            if (sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function (item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.prgNm.includes(this.schField)
            
            let col1 = true
            if (this.schChkField1.length != 0) {
                this.schChkField1.forEach((idxCdnm1, i) => {
                    if (i == 0) col1 = filter.idxCdnm.includes(idxCdnm1)
                    else col1 = col1 || filter.idxCdnm.includes(idxCdnm1)
                });
            }

            return col0 && col1;
        },
        // onShow() {
        //     this.getPrgTypMng()
        // },
    },

    data: () => ({
        schField: '',
        selectedItemIndex: [],
        selectedItem: -1,
        items: [],
        chkHisPk: 0,
        cdItems: [],
        exBtn: [
            {

                btnTitle: '지표구분',
                btnIcon: 'category',
                exWidth: 144,
                exHeigth: 130,
                expandList: [],
            },
        ],
        headers: [
            { text: '프로그램 유형', value: 'prgTypNm', sortable: true, width: '30%', class: 'black--text bottomBorder', align: 'center', },
            { text: '지표구분', value: 'idxCd', sortable: true, width: '25%', class: 'black--text bottomBorder', align: 'center', },
            { text: '프로그램', value: 'prgNm', sortable: true, width: '45%', class: 'black--text bottomBorder', align: 'center', },
        ],
        schChkField1: [],
        schDummy: '',
    })
}
</script>
<style>
    .tableHeaderSize { font-size: 1.025rem !important; }
    .fixedCol:hover { background: #ebf6fe !important; }
    .sltRow { background: #ebf6fe !important; }
    .disSltRow { background: white !important; }
</style>