<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0 pa-3" min-width="1260" max-width="1560" height="835">
        <v-row class="ma-0 pa-0 mb-10" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <!-- <v-icon color="topTitleColor" style="margin-top: 2px;">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-1. SMS&nbsp;발송관리</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0 mt-3" no-gutters>
            <v-col cols="12">
                <v-tabs hide-slider v-model="smsSndTab"  color="white" class="black--text"
                    active-class="bodyColor" center-active>
                    <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="smsSndTab">
                    <v-tab-item eager transition="fade-transition">
                        <SmsSndWrt ref="smsSndWrt"></SmsSndWrt>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <SmsSndHist ref="smsSndHist" :smsSndHistPk="smsSndHistPk"></SmsSndHist>
                    </v-tab-item>                
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import SmsSndWrt from '../../components/othr/SmsSndWrt.vue';
import SmsSndHist from '../../components/othr/SmsSndHist.vue';
export default {
    name: 'SmsSndMgmt',

    components: {
        SmsSndWrt,
        SmsSndHist
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        
        })
    },

    computed: {

    },

    watch: {
        'smsSndTab':function(value){
            if(value == 1) this.smsSndHistPk = 1
            else this.smsSndHistPk = -1
        }
    },

    methods: {

    },

    data: () => ({
        smsSndTab:0,
        smsSndHistPk:-1,
        tabItems: [
            { index: 0, tab: '문자전송', style: 'font-size: 1.2rem !important;'},
            { index: 1, tab: '발송내역', style: 'font-size: 1.2rem !important;'},
        ],
    }),
}
</script>