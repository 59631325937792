<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">생활실&nbsp;선택</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-divider :class="['mt-7', cntLvnRmInf > 15 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', cntLvnRmInf > 15 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center tableBR" cols="3">
                <span class="black--text font-weight-medium">건물</span>
            </v-col>
            <v-col class="" cols="9">
                <v-row class="ma-0 pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR" cols="3">
                        <span class="black--text font-weight-medium">층</span>
                    </v-col>
                    <v-col class="" cols="9">
                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="d-flex justify-center align-center tableBR" cols="5">
                                <span class="black--text font-weight-medium">생활실</span>
                            </v-col>
                            <v-col class="d-flex justify-center align-center tableBR py-1" cols="5">
                                <div style="text-align: center;">
                                    <div class="black--text font-weight-medium">입실현황</div>
                                    <div class="black--text font-weight-medium">&#40;&nbsp;잔여수&nbsp;&#47;&nbsp;정원수&nbsp;&#41;</div>
                                </div>
                            </v-col>
                            <v-col class="d-flex justify-center align-center" cols="2">
                                &nbsp;
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div v-if="lvnRmInf.length > 0" class="overflow-y-auto d-inline-block" style="width: 100%; height: 511px;">
            <v-divider class=""></v-divider>
            <v-row class="ma-0 pa-0" v-for="(bld, a) in vwList" :key="a" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" style="border-bottom: 1px solid #e0e0e0;" cols="3">
                    {{ bld[0][0].bldNm }}
                </v-col>
                <v-col class="" cols="9">
                    <v-row class="ma-0 pa-0" v-for="(flr, b) in bld" :key="b" no-gutters>
                        <v-col
                            class="d-flex justify-center align-center tableBR"
                            style="border-bottom: 1px solid #e0e0e0;" cols="3">
                            {{ flr[0].bldFlr }}층
                        </v-col>
                        <v-col class="" cols="9">
                            <v-row class="ma-0 pa-0" v-for="(info, c) in flr" :key="c" no-gutters>
                                <v-col
                                    class="d-flex justify-center align-center tableBR"
                                    style="border-bottom: 1px solid #e0e0e0;" cols="5">
                                    {{ info.lvnRmNm }}
                                </v-col>
                                <v-col 
                                    class="d-flex justify-center align-center tableBR"
                                    style="border-bottom: 1px solid #e0e0e0;" cols="5">
                                    {{ info.rmnCnt }}&nbsp;&#47;&nbsp;{{ info.fxNum }}
                                </v-col>
                                <v-col
                                    class="d-flex justify-center align-center py-1"
                                    style="border-bottom: 1px solid #e0e0e0;" cols="2">
                                    <!-- lvnRmInfPk bldNm -->
                                    <v-btn
                                        v-if="info.rmnCnt > 0"
                                        class="ml-1 white--text darken-1" style="padding:1px 3px 0px 2px !important"
                                        color="bckgrColor" min-width="30" height="25"
                                        @click="$emit('sltLvnRmInf', info), onClose()"
                                        dark small>
                                            <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                                    </v-btn>
                                    <span v-else class="d-inline-block" style="width: 25px; height: 25px;"></span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <div v-else class="d-inline-block pt-5 mb-7" style="width: 100%; height: 35px; text-align: center;">
            <span class="grey006--text">※ 조회된 항목이 없습니다.</span>
            <v-divider class="mt-5"></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { selLvnRmInfList } from '../../api/opr.js';

export default {
    name: 'LvnRmMdl',

    props : {

    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getLvnRmInf()
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        getLvnRmInf: function () {
            selLvnRmInfList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmInfAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selLvnRmInfList : ' + error))
        },
        getLvnRmInfAfter: function (res) {
            this.vwList.splice(0)
            
            this.lvnRmInf = res
            this.cntLvnRmInf = res.length
            this.rdsLvnRm()
        },
        rdsLvnRm: function () {
            let bldList = []
            let bldFlr = []

            this.lvnRmInf.forEach(dOne => {
                let chc = bldList.findIndex( v => v == dOne.bldNm )
                if(chc == -1) bldList.push(dOne.bldNm)
            });

            this.lvnRmInf.forEach(dTwo => {
                let chc = bldFlr.findIndex( v => v == dTwo.bldFlr )
                if(chc == -1) bldFlr.push(dTwo.bldFlr)
            });

            bldList.forEach(ll => {
                let tmp = []
                let tmpList = this.lvnRmInf.filter( v => v.bldNm == ll )
                bldFlr.forEach(ff => {
                    let dFf = tmpList.filter( v => v.bldFlr == ff )
                    if(dFf.length > 0)
                        tmp.push(dFf)
                });

                this.vwList.push(tmp)
            });

        },
        onClose: function () {
            this.$emit('modalClose', '0')
        },
    },

    data: () => ({
        cntLvnRmInf: 0,
        lvnRmInf: [],
        vwList: [],
    }),
};
</script>