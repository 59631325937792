<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left align-center mb-2 ">
            <span class="text-h4 font-weight-bold">응급상황 기록상세</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left align-center mb-2">
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" rounded
                :disabled="list.disabled"
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>  
            <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="btnDialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>          
        </div>
        <v-form ref="mform" lazy-validation>
            <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">수급자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">{{bnfcrInfo.name}}</v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">성별</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">
                    <span class="">
                        <v-img class="ic-sex" v-if="bnfcrInfo.gndr == '여'" src="../../styles/images/ic-female.svg"></v-img>
                        <v-img class="ic-sex" v-else-if="bnfcrInfo.gndr == '남'" src="../../styles/images/ic-male.svg"></v-img>
                    </span>
                    <span class="ml-1">&#40;{{bnfcrInfo.age}}&#41;</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">주요질환</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="5">
                    <!-- {{items.majorDsss}} -->
                    <v-tooltip
                        v-model="majorDsssTooltip"
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            text
                            v-bind="attrs"
                            v-on="on"
                            height="20"
                            style="padding:0px 5px !important;"
                            >
                            {{bnfcrInfo.majorDsss}}
                            </v-btn>
                        </template>
                        <span>{{bnfcrInfo.majorOrgDsss}}</span>
                    </v-tooltip>
                </v-col>
            </v-row>    
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">발생일시</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="3">
                    <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">                           
                        <CmmDateComp v-model="items.recDt" :required="true"></CmmDateComp>
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 80px;"> 
                        <CmmTimeField v-model="items.ofrTmStrt" :required="true"></CmmTimeField>   
                    </span>                    
                </v-col>   
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">응급이송병원</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="3">
                    <v-text-field v-model="items.itm1Val" color="blue" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                </v-col>         
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">담당자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="2">
                    <span class="white d-inline-block" style="width: 70px;border-radius: 10px;">
                        <v-text-field v-model="items.wrtr" color="blue" ref="info"
                            :rules="[rules.required]" hide-details outlined dense :key="forceRender" readonly></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3 "  
                        x-small 
                        min-width="30"
                        height="25"                          
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModel(0)"
                        >
                        <v-icon class="white--text" size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">사유</span>
                </v-col>                
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    <v-textarea v-model="items.itm2Val" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>
            </v-row> 
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">조치사항</span>
                </v-col>                
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    <v-textarea v-model="items.itm3Val" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <div>
                        <div class="d-flex justify-center black--text font-weight-medium">보호자</div>
                        <div class="d-flex justify-center black--text font-weight-medium">상담</div>
                    </div>
                </v-col>                
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    <v-textarea v-model="items.itm4Val" class="rmrksArea" no-resize  style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">사후관리</span>
                </v-col>                
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    <v-textarea v-model="items.itm5Val" class="rmrksArea" no-resize  style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <div>
                        <div class="d-flex justify-center black--text font-weight-medium">담당자</div>
                        <div class="d-flex justify-center black--text font-weight-medium">의견</div>
                    </div>
                </v-col>                
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    <v-textarea v-model="items.itm6Val" class="rmrksArea" no-resize  style="font-size:0.9rem !important;"
                        rows="5" outlined dense hide-details>
                    </v-textarea>
                </v-col>
            </v-row>       
        </v-form>        
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'응급상황기록'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import { selBnftOfrRecList, insBnftOfrRecMulti, delAllBnftOfrRec } from '../../api/bnftrcrd.js';

export default {
    name: 'EmrgnRcrdWrt',
    
    components: {
        EmpSelector,
        btnModalDelete,
        CmmDateComp,
        CmmTimeField,
    },

    props : {
        bnfcrInfo:{type:Object, default:new Object}
    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
       
    },  

    watch: {
       
    },

    methods: {      
        onLoad:function(value){
            if(value > 0) this.filledBtn[1].disabled = false
            this.getBnftOfrRecList(value)
        },       
        getBnftOfrRecList:function(value){
            let fcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk
            
            let obj = {
                fcltyNum: fcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '305',
                clsfc2Cd: '1',
                clsfc3Cd: '1',
                bnMmbrPk: gBnMmbrPk,
                bnftOfrRecHisPk : value
            }
            
            selBnftOfrRecList(obj)
            .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getBnftOfrRecListAfter:function(res){
            res.forEach(e => {
                e.recDt = e.recDt?.beforeDateFormatHyp()
                if(e.ofrTmHhStrt != '' && e.ofrTmMmStrt != '')
                    e.ofrTmStrt = e.ofrTmHhStrt+":"+e.ofrTmMmStrt
                this.items = e
            })
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClickBtn:function(key){            
           switch (key) {
            case 0:                
                this.onSave()
                break;
            case 1:
                this.dialogDelete = true
                break;
            default:
                break;
           }
        },
        onSave: function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm
            if(gBnMmbrPk > 0){
                
                if(this.items.ofrTmStrt !=""){
                    let ofrTmStrt = this.items.ofrTmStrt.split(":")
                    this.items.ofrTmHhStrt = ofrTmStrt[0]
                    this.items.ofrTmMmStrt = ofrTmStrt[1]
                }

                let recItems = {
                    fcltyNum: gFcltyNum,
                    bnMmbrPk : gBnMmbrPk,
                    userNm : gUserNm,
                    // bnftOfrRecHisPk:this.items.bnftOfrRecHisPk,
                    recDt:this.items.recDt?.afterDateFormatHyp(),
                    wrtr:this.items.wrtr,
                    wrtrMmbrPk:this.items.wrtrMmbrPk,                 
                    clsfc1Cd:'305',
                    clsfc2Cd:'1',
                    clsfc3Cd:'1',
                    listDtl :[{
                        // bnftOfrRecPk:this.items.bnftOfrRecPk,
                        ofrTmHhStrt:this.items.ofrTmHhStrt,
                        ofrTmMmStrt:this.items.ofrTmMmStrt,
                        itm1Cd:'1',
                        itm1Val:this.items.itm1Val,
                        itm2Cd:'2',
                        itm2Val:this.items.itm2Val,
                        itm3Cd:'3',
                        itm3Val:this.items.itm3Val,
                        itm4Cd:'4',
                        itm4Val:this.items.itm4Val,
                        itm5Cd:'5',
                        itm5Val:this.items.itm5Val,
                        itm6Cd:'6',
                        itm6Val:this.items.itm6Val,
                        wrtr:this.items.wrtr,
                        wrtrMmbrPk:this.items.wrtrMmbrPk,
                    }]
                }

                let obj = {list:[recItems]}

                insBnftOfrRecMulti(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /bnftrcrd/insBnftOfrRecMulti : ' + error))
            }
            
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.$emit('modalEvent', 99);
                this.onLoad(res.result)
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }            
        },        
        onClose: function () {
            this.$emit('isClose', 0)
            this.$refs.mform.reset()
            this.items.bnftOfrRecHisPk =0
            this.items.bnftOfrRecPk=0
            this.items.bnMmbrPk=0
            this.items.wrtrMmbrPk=0
            this.filledBtn[1].disabled = true
            this.$refs.mform.resetValidation()
        },   
        onforce(key){
            this.mtimes[key] = false
            ++this.forceRender
        },
        onModel:function (key) {
            this.prtWrtDt = this.items.useDtStrt
            this.dialog.splice(key, 1, true)
        },       
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },     
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },                
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk
        },     
        onDelete:function(res){
            if(res == 9990){                
                if(this.items.bnftOfrRecHisPk > 0){    
                    let gFcltyNum = this.$store.getters.getFcltyNum      
                    let gUserNm = this.$store.getters.getUserNm              
                    let obj = {list:[
                        {
                            fcltyNum:gFcltyNum,
                            bnftOfrRecHisPk:this.items.bnftOfrRecHisPk,
                            userNm:gUserNm,
                        }
                    ]}              
                    
                    delAllBnftOfrRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter(res){            
            if(res.statusCode == 200){   
                this.snackControll([true, 2500, res.message, 'info'])                                                                 
                this.$emit('modalEvent', 99)
                this.onClose()
                this.dialogDelete = false            
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
    },    
    data: () => ({
        forceRender:0,
        prtWrtDt:'',
        items:{
            bnftOfrRecHisPk:0,
            bnftOfrRecPk:0,
            fcltyNum:'',
            bnMmbrPk:0,
            recDt:'',
            ofrTmStrt:'',
            ofrTmHhStrt:'',
            ofrTmMmStrt:'',
            itm1Val:'',
            itm2Val:'',
            itm3Val:'',
            itm4Val:'',
            itm5Val:'',
            itm6Val:'',
            wrtr:'',
            wrtrMmbrPk:0,
            userNm:'',
        },   
        menu:[false],  
        dialog:[false],
        rtimes:false,
        mtimes:false,
        majorDsssTooltip:false,
        snackOptions: [false, 3000, '', 'info'],
        btnDialog: [false, false, false, false, ],
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [      
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력',  disabled: true, },
        ], 
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
    }),
};
</script>