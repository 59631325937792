<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">{{ cmpTtl }}</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mt-5 mb-2 pr-2 d-flex justify-start align-center" style="width: 100%;">             
            <span class="d-inline-block" style="width: 140px; height: 35px;">
                <v-text-field v-model="field" class="ma-0 pa-0 empSearchField" style="" background-color="white" append-icon="mdi-magnify" hide-details
                    single-line rounded outlined dense filled label="투약명조회" @input="setSearchText">
                </v-text-field>
            </span>            
        </div>    
        <v-form ref="form" lazy-validation> 
            <div :class="['d-flex',drugInfList.length > 10 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                <div class="greyE01 py-2" style="width:5%;text-align:center;">                    
                </div>
                <div class="greyE01 py-2" style="width:7%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">분류</span>
                </div>
                <div class="greyE01 py-2" style="width:18%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">투약명</span>
                </div>
                <!-- <div class="greyE01 py-2" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">투약량</span>
                </div> -->
                <div class="greyE01 py-2" style="width:16%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">투약효능</span>
                </div>
                <div class="greyE01 py-2" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">투약방법</span>
                </div>
                <div class="greyE01 py-2" style="width:10%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">취급방법</span>
                </div>
                <div class="greyE01 py-2" style="width:19%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">비고</span>
                </div>  
                <div class="greyE01 py-2" style="width:9%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">작성자</span>
                </div>    
                <div class="greyE01 py-2" style="width:8%;text-align:center;border-left: 1px solid #dbdbdb !important;">
                    <v-btn icon class="mr-1" color="blue200" height="26" @click="onModal(0)">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </div>       
            </div>
            <div class="overflow-y-auto" style="height:450px;">
                <div v-if="drugInfList.length > 0">
                    <div v-for="(list, j) in drugInfList" :key="j" class="d-flex" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;">
                            <!-- <v-btn
                                class="white--text darken-1" style="padding: 1px 0px 0px 0px !important"
                                color="bckgrColor" min-width="30" height="25"
                                @click="onAction(list)" v-if="cmpTtl =='수급자 의약품 조회'"
                                dark small>
                                    <v-icon size="20">mdi-checkbox-marked-outline</v-icon>
                            </v-btn> -->
                            
                            <span v-if="cmpTtl =='수급자 의약품 조회'" class="d-inline-block ml-1" style="width: 26px;">
                                <v-checkbox
                                    class="checkIcon" v-model="list.checkr" :key="forceRender"
                                    true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                    hide-details>
                                </v-checkbox>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:7%;border-left: 1px solid #dbdbdb !important;">                                            
                            <!-- <v-select 
                                v-model="list.drugClcd"                               
                                :items="drugCode"
                                item-text="valcdnm"
                                item-value="valcd"
                                dense hide-details outlined
                            />  -->
                            {{list.drugClcdNm}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:18%;border-left: 1px solid #dbdbdb !important;">
                            <!-- <v-text-field v-model="list.drugNm" color="blue" hide-details outlined dense></v-text-field> -->
                            {{list.drugNm}}
                        </div>
                        <!-- <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="list.drugAmt" color="blue" type="number" hide-details outlined dense></v-text-field>
                        </div> -->
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:16%;border-left: 1px solid #dbdbdb !important;">
                            <!-- <v-text-field v-model="list.drugEfct" hide-details outlined dense></v-text-field> -->
                            {{list.drugEfct}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                            <!-- <v-text-field v-model="list.drugMthd" hide-details outlined dense></v-text-field> -->
                            {{list.drugMthd}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                            <!-- <v-text-field v-model="list.hndlMthd" hide-details outlined dense></v-text-field> -->
                            {{list.hndlMthd}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:19%;border-left: 1px solid #dbdbdb !important;">
                            <!-- <v-text-field v-model="list.rmrks" hide-details outlined dense></v-text-field> -->
                            {{list.rmrks}}
                        </div> 
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;border-left: 1px solid #dbdbdb !important;">                            
                            {{list.wrtr}}
                        </div>        
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">
                            <v-btn icon color='blue200' @click="onShowDalog(list)">
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                            <v-btn v-if="list.drugInfPk > 0" icon color='grey006' @click="delDrug(list.drugInfPk)"> 
                                <v-icon>mdi-trash-can-outline</v-icon>                                           
                            </v-btn> 
                            <!-- <v-btn v-else icon color='grey006' @click="delList(j)"> 
                                <v-icon>mdi-minus-circle-outline</v-icon>
                            </v-btn> -->
                        </div>       
                    </div>
                </div>
                <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                        데이터가 없습니다.
                    </div> 
                </div>
            </div>
        </v-form>
        <v-row v-if="cmpTtl =='수급자 의약품 조회'" class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="onAction" height="30" samll rounded>
                    <v-icon>mdi-text-box-plus-outline</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">의약품 선택</span>
                </v-btn>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'의약품'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="600">    
                <drugInfMgmtWrt 
                    :drugInfWrtPk="drugInfWrtPk"  
                    @isClose="modalEvent(0)" 
                    @modalEvent="modalEvent"
                ></drugInfMgmtWrt>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { insDrugInf, delDrugInf, selDrugInfList } from '../../api/index.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import drugInfMgmtWrt from './DrugInfMgmtWrt.vue'

export default {
    name: 'DrugInfMgmt',

    props : {
        cmpTtl: { type: String, default: '수급자 의약품 조회' },
    },
            
    components: {
        btnModalDelete,
        drugInfMgmtWrt,
    },

    created: function(){
        
    },
    
    mounted: function(){
        this.$nextTick(function () {
            this.getDrugInfList()
        })
    },

    computed: {

    },

    watch: {
        
    },
        
    methods: {
        onClickBtn:function(text){
            console.log(text)
        },
        onClose: function () {            
            this.field = ''

            this.setSearchText()

            this.drugInfList.forEach(item => {
                item.checkr = '2'
            })

            this.$emit('isClose', '.')
        },
        onSave: function(item) {    
            let save = true

            if(item.drugNm == ''){
                this.snackControll([true, 2500, '투약명을 입력해주세요.', 'warning'])
                save = false
            }

            if(item.drugClcd == ''){
                this.snackControll([true, 2500, '분류를 선택해주세요.', 'warning'])
                save = false
            }

            if(save){
                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    drugInfPk:item.drugInfPk, 
                    drugClcd:item.drugClcd,
                    drugNm:item.drugNm,
                    drugAmt:item.drugAmt,
                    drugEfct:item.drugEfct,
                    drugMthd:item.drugMthd,
                    hndlMthd:item.hndlMthd,
                    rmrks:item.rmrks,
                }
                //console.log(obj)
                insDrugInf(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('upload error /cmm/insDrugInf : ' + error))
            }
        },
        onSaveAfter :function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getDrugInfList()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete:function(res){            
            let userNm = this.$store.getters.getUserNm
            if(res == 9990){                
                if(this.drugInfPk > 0){
                    let obj = {
                        userNm : userNm,
                        drugInfPk : this.drugInfPk,
                    }
                    delDrugInf(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /cmm/delDrugInf : ' + error))
                }else{
                    this.snackControll([true, 5000, '삭제 대상이 존재하지 않습니다.', 'error'])
                }
            }else if(res == 7){
                this.dialogDelete = false
                this.drugInfPk = 0
            }

        },
        onDeleteAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getDrugInfList()
                this.drugInfPk = 0
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
        fltrThrw: function () {
            let arr = this.drugInfList.filter(v => v.disChekr == false)

            return arr.filter(v => v.checkr == '1')
        },
        onAction: function(){
            let list = []
            this.drugInfList.filter(v => v.checkr == '1').forEach(item => {
                list.push(item)
            });

            this.$emit('outDrugInf', list)
            this.onClose()
        },   
        getDrugInfList(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
            }            
            selDrugInfList(obj)
            .then((response) => ( this.getDrugInfListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getDrugInfListAfter:function(res){
            this.drugInfList.splice(0)

            res.forEach(item => {
                item.checkr = '2'
                item.drugClcdNm = (item.drugClcd=='1')?'처방':'상비'
                this.drugInfList.push(item)
            })

            this.dummyDrugInfList = Object.assign([], this.drugInfList)

            this.$emit('reRoadTrg', true)
        },
        addList:function(){
            let obj = {drugInfPk:0,drugClcd:'',drugNm:'',drugAmt:0,drugEfct:'',drugMthd:'',rmrks:''}
            this.drugInfList.splice(0,0,obj)
        },
        delList:function(idx){
            this.drugInfList.splice(idx,1)
        },
        delDrug:function(pk){
            if(pk > 0){
                this.drugInfPk = pk
                this.dialogDelete = true
            }
        },
        setSearchText: function () {
            if(this.field == '')
                this.drugInfList = Object.assign([], this.dummyDrugInfList)

            let res= []
            let result = []
            res = this.dummyDrugInfList

            if(this.field != ''){
                res.forEach((sc) => {
                    if(sc.drugNm.search(this.field) > -1) result.push(sc)
                })
            }
            else
                result = res

            if(this.field == '')
                this.drugInfList = Object.assign([], this.dummyDrugInfList)
            else
                this.drugInfList = result

        },
        onModal:function(key){
            this.drugInfWrtPk = 1
            this.dialog.splice(key,1,true)
        },
        onShowDalog:function(item){
            this.sltItems = JSON.parse(JSON.stringify(item))            
            this.drugInfWrtPk = 1
            this.dialog.splice(0,1,true)
        },
        modalEvent:function(key, res=null){
            if(key == 99){
                if(res != null){
                    this.onSaveAfter(res)
                }
            }else{
                this.drugInfWrtPk = -1
                this.sltItems = JSON.parse(JSON.stringify({drugInfPk:0,drugClcd:'',drugNm:'',drugAmt:0,drugEfct:'',drugMthd:'',rmrks:''}))
                this.dialog.splice(key,1,false)
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        field:'',
        drugInfPk:0,
        forceRender:0,
        schChkField1: [],
        drugCode: [{valcdnm:'선택',valcd:''},{valcdnm:'처방',valcd:'1'},{valcdnm:'상비',valcd:'2'}],
        drugInfList: [{drugInfPk:0,drugClcd:'',drugNm:'',drugAmt:0,drugEfct:'',drugMthd:'',rmrks:''}],
        dummyDrugInfList: [],   
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },      
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'], 
        dialogDelete:false,
        dialog:[false,],
        drugInfWrtPk:-1,
        sltItems:{drugInfPk:0,drugClcd:'',drugNm:'',drugAmt:0,drugEfct:'',drugMthd:'',rmrks:''}
    }),
};
</script>