<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <!-- <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span> -->
        </div>
        <v-divider class="my-2"></v-divider>
        <v-form ref="ddjForm" lazy-validation>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center" cols="2">
                    <span class="font-weight-bold my-3">소독자</span>
                    <v-btn
                        class="ml-3 brown lighten-3 white--text" min-width="30" height="25"
                        @click="dialog.splice((dialog.length-1), 1, true)"
                        style="padding: 0px 1px 0px 2px !important" small>
                        <v-icon size="20">mdi-account-multiple</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex align-center tableBL pa-1" cols="10">
                    <div class="d-inline-block overflow-y-auto" style="width: 690px; height: 80px;">
                        <v-chip
                            v-for="(list, i) in inspRecDtlWrtrList" :key="i"
                            class="ma-1" color="blue200"
                            outlined>
                            <span class="font-weight-medium">{{ list.name }}&nbsp;&#40;{{ list.jobNm }}&#41;</span>
                        </v-chip>
                    </div>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="my-2"></v-divider>
            <div class="overflow-y-auto" style="height:526px;">
                <v-divider></v-divider>
                <v-row class="pa-0 greyE01" no-gutters>
                    <v-col class="d-flex justify-center align-center font-weight-bold py-1 tableBR" cols="1">
                        <span class="">연번</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center font-weight-bold py-1 tableBR" cols="8">
                        <span class="">소독부위</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center font-weight-bold py-1 tableBR" cols="2">
                        <span class="">소독제</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center font-weight-bold py-1" cols="1">
                        <span class="">확인</span>
                    </v-col>
                    <!-- <v-col class="d-flex justify-center align-center font-weight-bold py-1" cols="2">
                        <span class="">소독자</span>
                    </v-col> -->
                </v-row>
                <v-divider></v-divider>
                <v-row class="tableBB" v-for="(ird, i) in inspRecDtllist" :key="i" no-gutters>
                    <v-col class="d-flex justify-center align-center tableBR py-1" cols="1">
                        <span class="">{{ird.rn}}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR px-1" cols="8">
                        <span class="">{{ird.itm1CdNm}}</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBR" cols="2">
                        <span class="d-inline-block pa-1" style="width:100%;">
                            <v-text-field
                                v-model="ird.itm2Val" hide-details outlined dense>
                            </v-text-field>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center" cols="1">
                        <span class="d-inline-block">
                            <v-checkbox class="clmHisCheck" v-model="ird.itm3Val" true-value="1" false-value="2"
                                on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline" hide-details></v-checkbox>
                        </span>
                    </v-col>
                    <!-- <v-col class="d-flex justify-center align-center" cols="2">
                        <span class="d-inline-block pa-1" style="width:74%;">
                            <v-text-field
                                v-model="ird.wrtr"
                                readonly hide-details outlined dense>
                            </v-text-field>
                        </span>
                        <v-btn 
                            class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                            min-width="30" height="28" @click="dialog.splice((dialog.length-1), 1, true), sltItem=i"
                            dark small depressed>
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>
                        </v-btn>
                    </v-col> -->
                </v-row>
                <v-row class="tableBB" v-if="inspRecDtllist.length == 0" no-gutters>
                    <v-col class="d-flex justify-center align-center py-1" cols="12">
                        <span class="grey006--text" style="font-size:1rem">※목록이&nbsp;없습니다&#46;</span>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
            <v-row no-gutters>
                <v-col class="d-flex justify-center align-center font-weight-bold py-1" style="background-color: #ededed !important;" cols="2">
                    <span class="">비고</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="10">
                    <span class="d-inline-block pa-1" style="width:100%;">
                        <v-textarea
                            class="rmrksArea" v-model="cntnt" no-resize hide-details outlined dense>
                        </v-textarea>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </v-form>
        <v-dialog v-model="dialog[dialog.length-1]" eager content-class="round" persistent max-width="700">
            <IntgrSlctn
                :mdlTtl="'직원 선택'" :tabInfList="['2']" :fixList="exclsList" :mode="'2'"
                :cd41="vCd41"
                :cd42="vCd42"
                :cd65="vCd65"
                :prtWrtDt = "sltDate"
                @intgrResList="getAplyList" @onClose="dialog.splice((dialog.length-1), 1, false)">
            </IntgrSlctn>
            <!-- <EmpSelector @isClose="dialog.splice((dialog.length-1), 1, false)" @outEmp="getEmpInfo" :prtWrtDt="sltDate"></EmpSelector> -->
        </v-dialog>
        <v-dialog v-model="menu[0]" max-width="500">
            <btnModalDelete :title="delTitle" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
    </v-sheet>
</template>

<script>
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import IntgrSlctn from '../../components/commons/IntgrSlctn.vue';

import { selInspRecInfDtl, insInspRecDtl, delInspRecDtl } from '../../api/sfty.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'DailyDsnfcJrnls',
    
    components: {
        btnModalDelete,
        expandNowDatePicker,
        IntgrSlctn,
    },

    props : {

    },

    created: function(){
        this.getCode(['41', '42', '65'])

        for (let index = 0; index < this.outlinedBtn.length+1; index++) {
            this.dialog.push(false)      
        }
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)

            let timezoneOffset = new Date().getTimezoneOffset() * 60000
            let timezoneDate = new Date(Date.now() - timezoneOffset)

            this.sltDate = timezoneDate.toISOString().split('T',2)[0].afterDateFormatHyp()

            //this.getInspRecDtllist()
        })
    },

    computed: {
        getStrtDate: function () {
            return this.$parent.$parent.date.startDay
        },
        getSltInspRecPk: function () {
            return this.$parent.$parent.sltInspRecPk
        },
        getSelectedItem: function () {
            return this.$parent.$parent.selectedItem
        },
    },  

    watch: {
        getStrtDate: function (value) {
            let fDate = new Date(value.beforeDateFormatHyp())
            let nDate = new Date(this.$parent.$parent.sltDate.beforeDateFormatHyp())
            let lDate = new Date(this.$parent.$parent.date.endDay.beforeDateFormatHyp())

            if(fDate <= nDate && nDate <= lDate) this.$parent.$parent.sltDate = this.sltDate
            else {
                fDate.setDate(fDate.getDate()+3)
                this.sltDate = fDate.toISOString().split('T',2)[0].afterDateFormatHyp()
            }
        },
        getSltInspRecPk: function (value) {
            if(value == 0){
                this.btnControll(1, true, 'fill')
                this.btnControll(0, true, 'out')
            }
            else if(value != 0){
                this.btnControll(1, false, 'fill')
                this.btnControll(0, false, 'out')
            }

            //this.getInspRecDtllist()
        },
        getSelectedItem: function (value) {
            if(value == -1)
                this.btnControll(0, true, 'fill')
            else{
                this.btnControll(0, false, 'fill')
                this.getInspRecDtllist()
            }
        },
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        //일상소독일지 상세조회
        getInspRecDtllist: function (){
            selInspRecInfDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.sltInspRecPk, this.sltDate, 502, 1)
                .then((response) => ( this.getInspRecDtllistAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selInspRecInfDtl : ' + error))
        },
        //일상소독일지 저장
        setInspRecDtl: function (obj){
            this.btnControll(1, true, 'fill')
            this.btnControll(0, true, 'fill')

            insInspRecDtl(obj)
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/insInspRecDtl : ' + error))
        },
        //야간점검일지 삭제
        removeInspRecDtl: function (){
            this.btnControll(1, true, 'fill')
            this.btnControll(0, true, 'fill')

            delInspRecDtl(this.$store.getters.getFcltyNum, this.$parent.$parent.sltInspRecPk, this.$store.getters.getUserNm )
                .then((response) => ( this.setInspRecAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/delInspRecDtl : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
        },
        getInspRecDtllistAfter: function (res){
            this.exclsList.splice(0)
            this.inspRecDtlWrtrList.splice(0)

            if(Array.isArray(res.list)){
                res.list.forEach(arr => {
                    arr.itm3Val = arr.itm1Val
                });
            }

            this.inspRecDtlWrtrList = res.wrtrAr
            this.inspRecDtllist = res.list

            if(this.inspRecDtlWrtrList.length > 0){
                this.inspRecDtlWrtrList.forEach(cd => {
                    cd.disChekr = false
                    cd.checkr = '2'
                    this.exclsList.push(cd)
                });
            }

            this.$parent.$parent.sftyList.forEach(items => {
                if(this.$parent.$parent.selectedItem == items.inspDt)
                    this.cntnt = items.cntnt1
            });
        },
        setInspRecAfter: function (res){
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.getTableList()
                
                this.clearForm()
                this.btnControll(1, false, 'fill')
                this.btnControll(0, false, 'fill')
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        preInspRecDtllist: function (){
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.clsfc1Cd = 502
            obj.clsfc2Cd = 1
            obj.inspRecPk = this.$parent.$parent.sltInspRecPk
            obj.inspDt = this.$parent.$parent.sltDate
            obj.cntnt1 = this.cntnt
            obj.wrtrMmbrPkAr = []
            obj.list = []

            this.inspRecDtllist.forEach(items => {
                items.inspTmHh = '00'
                items.inspTmMm = '00'

                if(items.itm3Val == null)
                    items.itm1Val = '2'
                else
                    items.itm1Val = items.itm3Val

                items.itm3Val = null

                obj.list.push(items)
            });

            this.inspRecDtlWrtrList.forEach(ppp => {
                obj.wrtrMmbrPkAr.push(ppp.mmbrPk)
            });

            this.setInspRecDtl(obj)
        },
        getDate: function (value) {
            this.$parent.$parent.sltDate = value.date
            this.sltDate = value.date

            let fIndex = this.$parent.$parent.sftyList.findIndex(v => v.inspDt == value.date.beforeDateFormatDot())
            if(fIndex != -1){
                let pkkk = this.$parent.$parent.sftyList[fIndex].inspRecPk

                if(pkkk == null)
                    this.$parent.$parent.sltInspRecPk = 0
                else
                    this.$parent.$parent.sltInspRecPk = pkkk

                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }
            else{
                this.$parent.$parent.selectedItem = value.date.beforeDateFormatDot()
            }

            let nDate = new Date(value.date.beforeDateFormatHyp())
            nDate.setDate(nDate.getDate() + 1)

            this.nextDate = nDate.toISOString().split('T',2)[0].afterDateFormatHyp()
        },
        onClickBtn: function (index) {
            switch(index) {
                case 0:     //저장
                    this.preInspRecDtllist()
                    break;
                case 1:     //삭제
                    this.delTitle = '일상소독일지'
                    this.menu.splice(0, 1, true)
                    break;
                default:
                    break;
            }
        },
        btnControll: function (index, dis, type) {
            switch (type) {
                case 'out':
                    //this.outlinedBtn[index].disabled = dis
                    break;
                case 'fill':
                    this.filledBtn[index].disabled = dis
                    break;
                default:
                    break;
            }
        },
        delModalAct: function (res) {
            this.delTitle = ''
            if(res == 9990){    //OK
                this.removeInspRecDtl()

                this.menu.splice(0, 1, false)
            }
            else if(res == 7)   //Cancel
                this.menu.splice(0, 1, false)
        },
        clearForm: function () {
            this.cntnt = ''
            this.inspRecDtllist.splice(0)
            this.exclsList.splice(0)
            this.inspRecDtlWrtrList.splice(0)
        },
        getEmpInfo: function (obj) {
            this.inspRecDtllist[this.sltItem].wrtr = obj[0].name
            this.sltItem = -1
        },
        getAplyList: function (arr){
            this.inspRecDtlWrtrList.splice(0)
            
            arr.forEach(ar => {
                let obj = { empPk: ar.empPk, mmbrPk: ar.mmbrPk, name: ar.name, jobNm: ar.jobCd, disChekr: false }
                this.inspRecDtlWrtrList.push(obj)
            });
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        delTitle: '',
        sltItem: -1,
        sltDate: '',
        nextDate: '',
        vCd41: [],
        vCd42: [],
        vCd65: [],
        exclsList: [],
        inspRecDtllist: [],
        inspRecDtlWrtrList: [],
        cntnt: '',
        menu: [false],
        dialog: [],
        areaWidth: 708,
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>