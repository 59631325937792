<template>
    <v-sheet class="ml-0">
        <div class="d-flex mx-3 justify-center align-center ">
            <span class="d-inline-block" style="width: 97px;">
                <v-select v-model="srchCd" :items="selectItems" item-text="valcdnm" item-value="valcd"
                    dense hide-details outlined />
            </span>
            <div class="d-flex mx-3">
                <span>
                    <expandNowDatePicker @nowDate="getDate" v-if="srchCd==0"></expandNowDatePicker>
                    <expandMonthPicker @nowYearWithMonth="getPickerDate" v-if="srchCd==1"></expandMonthPicker>
                    <expandYearOnlyPicker @nowYear="getYear" v-if="srchCd==2"></expandYearOnlyPicker>
                </span>
            </div>
        </div>
        <div class="mt-2 rounded-lg">
            <v-data-table 
                class="msclnTable" 
                height="660" locale="ko" 
                :headers="headers" 
                :items="prgPrtcpList"
                fixed-header disable-pagination hide-default-header hide-default-footer dense>
                <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 86px !important;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[0].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 65px !important;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[1].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 124px !important;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[2].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 168px !important;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[3].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 55px !important;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[4].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 55px !important;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[5].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 55px !important;"
                                    class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>
                                    {{ props.headers[6].text }}</th>
                                <th style="border-bottom: 1px solid #dbdbdb; width: 420px !important;"
                                    class="black--text clmsHeaderCommon tableBT" rowspan='2'>
                                    {{ props.headers[7].text }}</th>
                            </tr>
                        </thead>
                    </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.opDt }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.idxNm }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.prgTypNm }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.prgNm }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.prtcpNm }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.stsfcNm }}</span>
                        </td>
                        <td class="tableBR tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.perfNm }}</span>
                        </td>
                        <td class="tableBB clmsBodyCommon" style="height:40px !important; text-align: center;">
                            <span class="cmmFontSizeOne">{{ item.spclNote }}</span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div>            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
    </v-sheet>
</template>
<script>
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import { selBtcInqPrgPrtcpList } from '../../api/bnfcrStts';
export default {
    name: 'PrgBnfcrPrtcpSttsTab1',

    props: {
        tab: Number,
    },
    components: {
        expandNowDatePicker,
        expandYearOnlyPicker,
        expandMonthPicker,

    },

    computed: {
        getBnMmbrPk: function() {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltBnMmBrPk
        }
    },
    
    watch: {
        getBnMmbrPk(value){
            this.bnMmbrPk = value
            this.page = 1
            if(this.bnMmbrPk > 0){
                if(this.srchCd == 0){
                    this.selBtcInqPrgPrtcpList('ymd',0,this.itemsRows)
                } 
                else if(this.srchCd == 1){
                    this.selBtcInqPrgPrtcpList('yymm',0,this.itemsRows)
                } 
                else if(this.srchCd == 2){
                    this.selBtcInqPrgPrtcpList('yyyy',0,this.itemsRows)
                }
            }else{
                this.prgPrtcpList.splice(0)
            }   
        },
        'ymd': function(){   
            this.page = 1         
            this.selBtcInqPrgPrtcpList('ymd',0,this.itemsRows)
        },
        'yymm': function(){            
            this.page = 1
            this.selBtcInqPrgPrtcpList('yymm',0,this.itemsRows)
        },
        'yyyy': function(){          
            this.page = 1      
            this.selBtcInqPrgPrtcpList('yyyy',0,this.itemsRows)
        },
    },

    created: function () {
    },

    mounted: function () {
        this.$nextTick(function () {
        })
    },

    methods: {
        selBtcInqPrgPrtcpList: function (typ, strt, lmt) {
            this.srchTyp = typ

             let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum, 
                bnMmbrPk    : this.bnMmbrPk, 
                inqYMD      : null,
                inqYYMM     : null, 
                inqYYYY     : null,
                rnStrt      : strt,
                rnLmt       : lmt, 
            }

            if(this.srchTyp == 'yymm'){
                obj.inqYYMM = this.yymm
            } else if(this.srchTyp == 'yyyy') {
                obj.inqYYYY = this.yyyy
            } else {
                obj.inqYMD = this.ymd
            }

            selBtcInqPrgPrtcpList(obj)
                .then((response) => ( this.selBtcInqPrgPrtcpListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBtcInqPrgPrtcpList : ' + error))
        },
        selBtcInqPrgPrtcpListAfter: function (res) {
            this.prgPrtcpList.splice(0)
            
            if(res != null){
                if(res.rnTot != undefined) this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)

                if(res.list != undefined && res.list != null){
                    res.list.forEach(el => {
                        this.prgPrtcpList.push(el)
                    })
                }
            }
        },
        getDate: function (obj) {
            this.ymd = obj.date
        },
        getPickerDate: function (obj) {
            this.yymm = obj
        },
        getYear: function (obj) {
            this.yyyy = obj
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.selBtcInqPrgPrtcpList(this.srchTyp,strt,this.itemsRows)
        },
    },
    data: () => ({
        headers: [
            { text: '제공일', value: 'name', align: 'center', width: '100', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR tableBT ml-3' },
            { text: '지표', value: 'jobCd', align: 'center', width: '120', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '프로그램 유형', value: 'wrkCtCnt', align: 'center', width: '140', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '프로그램', value: 'actWrkHr', align: 'center', width: '160', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '참여도', value: 'actWrkHr', align: 'center', width: '80', on: false, sortable: true, class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '만족도', value: 'actWrkHr', align: 'center', width: '80', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '수행도', value: 'actWrkHr', align: 'center', width: '80', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBR tableBT' },
            { text: '반응 및 특이사항', value: 'actWrkHr', align: 'center', on: false, sortable: true,  class: 'black--text clmsHeaderCommon tableBT' },
        ],
        selectItems: [{valcdnm: '일별',valcd: 0}, {valcdnm: '월별',valcd: 1},{valcdnm: '연별',valcd: 2}, ],
        srchCd:0,
        bnMmbrPk:0,
        srchTyp:'ymd',
        ymd: 19900101,
        yymm: 199001,
        yyyy: 1990,
        pageCount:0,
        itemsRows: 10,
        page:1,
        prgPrtcpList: [],
    }),
};
</script>