<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="midAreaWidth" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">10-4. 권한관리</span>
            </div>
            <v-row class="justify-left mt-3 mx-3">
                <v-spacer></v-spacer>              
                <span style="width:120px;">
                    <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                        single-line rounded outlined dense filled hide-details label="그룹명" @input="setSearchText">
                    </v-text-field>
                </span>
            </v-row>
            
            <div class="mt-5 ml-3 mr-2 rounded-lg">
                <v-data-table class="msclnTable pt-1" height="700" locale="ko"
                    :headers="authGrpHeaders" :items="authGrpList" :search="schDummy" 
                    disable-pagination fixed-header hide-default-header hide-default-footer dense>               

                    <template v-slot:header="{ props }" >
                        <thead>
                            <th v-for="(list, i) in props.headers" :key="i" :class="props.headers[i].class" :style="props.headers[i].style">{{list.text}}</th>
                        </thead>
                    </template>                
                    <template v-slot:item='{ item }'>
                        <tr :class="['fixedCol', sltAuthGrpPk == item.cmnAuthGrpPk ? 'sltRow' : 'disSltRow']"  @click="tableClickEvent(item.cmnAuthGrpPk, item.fcltyNum)">
                            <td class="fixedCol0 tableBT removeTableBB" style="height:40px !important; text-align: center;">
                                 <span class="cmmFontSizeOne">{{item.authNm}}</span></td>
                            <td class="fixedCol0 tableBT d-flex justify-center align-center removeTableBB" style="height:40px !important; text-align: center;">
                                <span style="width:130px" class="cmmFontSizeOne text-overflow">{{item.authDesc}}</span>
                            </td>
                            <td class="fixedCol0 tableBT removeTableBB" style="height:40px !important; text-align: center;">
                               <span class="cmmFontSizeOne"> {{item.empCnt}}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-sheet>        
        <router-view ref="cmmAuthMgmt"></router-view>
    </v-sheet>    
</template>

<script>
import { selCmnAuthGrpList } from '../../api/cmm.js';

export default {
    name: 'CmmAuthCnfgr',
        
    components: {
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCmnAuthGrpList()
        })
    },

    computed: {

    },

    watch:{ 
        
    },
        
    methods: {
        getCmnAuthGrpList: function () {
            selCmnAuthGrpList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getCmnAuthGrpListAfter(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnAuthGrpList : ' + error))
        },
        getCmnAuthGrpListAfter:function(res){
            this.authGrpList = res
        },

        setSearchText: function (value) {
            this.schDummy = value
        },

        tableClickEvent:function (pk, code) {
            if(this.sltAuthGrpPk == pk){
                this.sltAuthGrpPk = -1             
            }
            else if(this.sltBnMmBrPk != pk){
                this.sltAuthGrpPk = pk
            }
            
            if(typeof pk == 'undefined' || pk == null)
                this.sltAuthGrpPk = -1

            this.$refs.cmmAuthMgmt.onLoad(pk, code)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({     
        schField: '',
        schDummy: '',   
        midAreaWidth: '360',
        sltAuthGrpPk:-1,        
        authGrpHeaders: [
            { text: '그룹명', value: 'authNm', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},            
            { text: '설명', value: 'authDesc', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},        
            { text: '직원수', value: '', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;'},
        ],
        authGrpList:[],
        snackOptions: [false, 3000, '', 'info'],
    })
};
</script>