<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">{{date.beforeDateFormatDot()}}&nbsp;&nbsp;근무일정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div style="width: 924px;">
            <v-divider class="mt-5"></v-divider>
            <v-row class="pa-0 ma-0 greyE01" style="text-align: center;" no-gutters>
                <v-col class="tableBR py-1" style="text-align: center;" cols="3">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">담당직종</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">직원명</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">근무유형</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">근무상세&nbsp;&#40;패턴&#41;</span>
                </v-col>
                <v-col class="py-1" style="text-align: center;" cols="3">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">근무시간</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
        <div class="overflow-y-auto" style="height: 320px; width: 934px;">
            <v-row class="pa-0 ma-0 tableBB" v-for="(list, i) in empWrkSchdlList" :key="i" style="width: 924px;" no-gutters>
                <v-col class="tableBR py-1" style="text-align: center;" cols="3">
                    <span class="black--text">{{ list.jobCd }}</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span class="black--text">{{ list.name }}</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span class="black--text">{{ list.wrkTypDtl }}</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span class="black--text">{{ list.wrkTypNm }}{{ list.wrkTypColr }}</span>
                </v-col>
                <v-col class="py-1" style="text-align: center;" cols="3">
                    <span class="black--text">{{ list.wrkTmHhStrt }}&#58;{{ list.wrkTmMmStrt }}&nbsp;&#126;&nbsp;{{ list.wrkTmHhEnd }}&#58;{{ list.wrkTmMmEnd }}</span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { selEmpWrkSchdlList } from '../../api/emp.js';

export default {
    name: 'WrkngSchdlModal',

    props : {
       
    },
        
    components: {

    },

    created: function(){
        //73 근무형태코드
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltDate: function () {
            return this.$parent.$parent.$parent.$parent.sltDate
        },
    },

    watch:{
        getSltDate: function (value) {
            this.date = value.afterDateFormatHyp()
        },
        'date': function () {
            this.getEmpWrkSchdlList()
        },
    },
    
    methods: {
        //직원근무일정 다건 조회
        getEmpWrkSchdlList: function () {
            selEmpWrkSchdlList(this.$store.getters.getFcltyNum, this.date)
                .then((response) => ( this.getEmpWrkSchdlListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpWrkSchdlList : ' + error))
        },
        getEmpWrkSchdlListAfter: function (res) {
            res.forEach(items => {
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd42.forEach(cd => {
                    if(items.jobCd == cd.valcd)
                        items.jobCd = cd.valcdnm
                });
            });

            this.empWrkSchdlList = res
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },
        clearForm: function (){

        },
    },

    data: () => ({
        date: '',
        menu: [false],
        dialog: [false],
        empWrkSchdlList: [],
        forceRender: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>