<template>
    <v-row class="pa-0" no-gutters>
        <v-col class="d-flex" style="position: relative;" cols="12">
            <div style="position: absolute; left: 0; top: 8px; z-index: 4;">
                <v-btn
                    class="font-weight-bold" height="28" color="grey006"
                    @click="rprtActCall()"
                    rounded outlined samll>
                    <div class="d-flex justify-left align-center">
                        <v-icon style="margin-bottom: 1px;" size="18">mdi-printer-outline</v-icon>
                        <span class="ml-2" style="font-size: 1rem;">출퇴근&nbsp;근무표</span>
                    </div>
                </v-btn>
            </div>
            <span class="" style="position: absolute; right: 14px; top: 11px; z-index: 4;">
                <v-icon
                    class="mr-1" style="padding-right: 2px;"
                    size="16">
                    mdi-circle-outline
                </v-icon>
                <span class="mr-3" style="font-size: 13px;">출근전</span>
                <v-icon
                    class="mr-1" style="padding-right: 2px;"
                    color="blue001" size="16">
                    mdi-circle-slice-4
                </v-icon>
                <span class="mr-3" style="font-size: 13px;">근무중</span>
                <v-icon
                    class="mr-1" style="padding-right: 2px;"
                    color="green" size="16">
                    mdi-circle
                </v-icon>
                <span class="" style="font-size: 13px;">근무종료</span>
            </span>
        </v-col>
        <v-col class="mt-1" cols="12">
            <CmmClndr
                ref="cmmClndr"
                :clndrHght="'706'" :hdrTyp="'splView'" :vsbYn="savBtn"
                :intlDt="$moment().format('YYYYMM')" :clndrList="wrkSchdlList"
                @chgDtAct="getPickerDate">
            </CmmClndr>
        </v-col>
        <!-- <v-col cols="12">
            {{ events }}
        </v-col> -->
        <v-dialog v-model="menu" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu = false"></ReportMgmt>
        </v-dialog>
    </v-row>
</template>

<script>
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import CmmClndr from '../commons/CmmClndr.vue';

import { selEmpAtndList } from '../../api/emp.js';

export default {
    name: 'WrkSttEmp',
    
    components: {
        ReportMgmt,
        CmmClndr,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {
        getSltEmpPk: function () {
            return this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
        },
    },  

    watch: {
        getSltEmpPk: function (value) {
            if(this.sltDate != '')
                this.getEmpAtndList(value, this.sltDate)
            if(value == -1)
                this.savBtn = true
            else
                this.savBtn = false
        },
    },

    methods: {
        getEmpAtndList: function (pk, yymm) {
            this.wrkList.splice(0)
            this.wrkSchdlList.splice(0)

            selEmpAtndList(this.$store.getters.getFcltyNum, pk, yymm, null)
                .then((response) => ( this.getEmpAtndListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAtndList : ' + error))
        },
        getEmpAtndListAfter: function (res) {
            if(res.length > 0){
                this.wrkList = res.filter( v => v.wrkDt == this.$moment().format('YYYYMMDD') )
                this.cvtClndrFrmList(res)
            }
        },
        getPickerDate: function (date) {
            this.sltDate = this.$moment(date, 'YYYY-MM-DD').format('YYYYMM')

            let cd = this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.trgCd
            if(cd == '')
                this.$parent.$parent.$parent.$parent.$parent.$parent.getEmpAtndHrlList(this.sltDate, '1')
            else
                this.$parent.$parent.$parent.$parent.$parent.$parent.getEmpAtndHrlList(this.sltDate, cd)

            if(this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk != -1)
                this.getEmpAtndList(this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk, this.sltDate)
        },
        cvtClndrFrmList: function (arr) {
            arr.forEach(itm => {
                itm.start = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')
                itm.color = '#dbdbdb'
                if(itm.wrkOvnYn == '1')
                    itm.end = this.$moment(itm.wrkDt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')
                else
                    itm.end = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')

                this.$parent.$parent.$parent.$parent.$parent.$parent.vCd73.forEach(idx => {
                    if(idx.valcd == itm.wrkTypCd)
                        itm.wrkTypNm = idx.valcdnm
                });
                this.$parent.$parent.$parent.$parent.$parent.$parent.vCd107.forEach(idx => {
                    if(idx.valcd == itm.vctnTypCd)
                        itm.vctnTypNm = idx.valcdnm
                });
            });

            this.wrkSchdlList = arr
        },
        rprtActCall: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzPk.push(this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk)
            this.prtThrowObj.inqBzPk = this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.inqYYMM = this.sltDate

            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.menu = true
        },
    },

    data: () => ({
        menu: false,
        savBtn: true,
        sltDate: '',
        wrkList: [],
        wrkSchdlList: [],
        prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '11', inqYYMM: '', list: [ { bzClcd: [], bzPk: [] } ], },
    }),
};
</script>