<template>
    <router-view></router-view>
</template>

<script>
require('./styles/css/common.css')
require('./styles/css/cmm.css')

export default {
  name: 'App',

  components: {

  },

  beforeCreate: function(){
   
  },

  created: function() {
    
  },

  computed:{

  },

  mounted: function() {
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = 'auto'
  },

  watch:{

  },
  
  destroyed: function() {
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = null
  },

  methods: {
    
  },

  data: () => ({
    forceRender: 0,
  }),
};
</script>

<style>
  .v-application{ font-family: 'Noto Sans KR', sans-serif !important; font-size: 1.1rem !important;}
  .v-application::-webkit-scrollbar {
    width: 10px;
    background-color: blue;
  }
  
  .ic-notice{ width:18px;height:18px; font-size:0; margin-right:0.313rem; }

  ::-webkit-scrollbar { width: 10px; height: 10px; }
  ::-webkit-scrollbar-thumb { background-color: #a6daff; border-radius: 40px; }
  ::-webkit-scrollbar-track { background-color: #ddd; border-radius: 40px; }
</style>
