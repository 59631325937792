<template>
    <v-sheet class="pa-7 ma-0" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
            <div class="d-flex justify-left">
                <span class="text-h4 font-weight-bold">'{{$parent.$parent.$parent.$parent.sltCashBill.name}}'  현금영수증&nbsp;내역</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
            </div>
            <div class="mt-2">
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">수급자</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$parent.$parent.$parent.$parent.sltCashBill.name}}
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">입금일</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$moment($parent.$parent.$parent.$parent.sltCashBill.dpsDt,'YYYYMMDD').format('YYYY.MM.DD')}}
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">입금방법</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$parent.$parent.$parent.$parent.sltCashBill.dpsMthdCd}}
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">입금자명</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$parent.$parent.$parent.$parent.sltCashBill.dpsNm}}
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">청구월</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$moment($parent.$parent.$parent.$parent.sltCashBill.clmYymm,'YYYYMM').format('YYYY.MM')}}
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">입금액</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$parent.$parent.$parent.$parent.sltCashBill.clmAmt?.toLocaleString('ko-KR')}}
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">진료약제비</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$parent.$parent.$parent.$parent.sltCashBill.clnPhrAmt?.toLocaleString('ko-KR')}}
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center py-2" cols="2">
                        <span class="black--text font-weight-medium">계약의사진찰비</span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center py-2" cols="4">
                        {{$parent.$parent.$parent.$parent.sltCashBill.cntrDctAmt?.toLocaleString('ko-KR')}}
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>

                <v-row class="pa-0 mt-3" no-gutters>
                    <v-col cols="12">
                        <v-divider></v-divider>
                        <v-data-table
                            class="msclnTable" height="231" locale="ko"
                            :items="hisIndvList" :headers="hisIndvHeaders"
                            fixed-header hide-default-header disable-pagination hide-default-footer dense>
                            <template v-slot:header="{ props }" >
                                <thead>
                                    <tr>
                                        <th class="clmsHeaderCommon tableBR px-1">
                                            <span class="black--text">{{ props.headers[0].text }}</span>
                                        </th>
                                        <th class="clmsHeaderCommon tableBR px-1">
                                            <span class="black--text">{{ props.headers[1].text }}</span>
                                        </th>
                                        <th class="clmsHeaderCommon tableBR px-1">
                                            <span class="black--text">{{ props.headers[2].text }}</span>
                                        </th>
                                        <th class="clmsHeaderCommon tableBR px-1">
                                            <span class="black--text">{{ props.headers[3].text }}</span>
                                        </th>
                                        <th class="clmsHeaderCommon tableBR px-1">
                                            <span class="black--text">{{ props.headers[4].text }}</span>
                                        </th>
                                        <th class="clmsHeaderCommon tableBR px-1">
                                            <span class="black--text">{{ props.headers[5].text }}</span>
                                        </th>
                                        <th class="clmsHeaderCommon px-1">
                                            <span class="black--text">{{ props.headers[6].text }}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item='{ item }'>
                                <tr>
                                    <td class="clmsBodyCommon tableBR px-0">
                                       {{Number(item.supplycost)?.toLocaleString('ko-KR')}}
                                    </td>
                                     <td class="clmsBodyCommon tableBR px-0">                                        
                                        <div class="d-flex justify-center align-center"> 
                                            <v-icon v-if="item.clncExcYn=='2'" size="20" color="blue">mdi-check-circle-outline</v-icon> 
                                            <v-icon v-else size="20">mdi-minus-circle-outline</v-icon>
                                        </div>
                                    </td>
                                     <td class="clmsBodyCommon tableBR px-0">
                                        <div class="d-flex justify-center align-center"> 
                                            <v-icon v-if="item.dctrExcYn=='2'" size="20" color="blue">mdi-check-circle-outline</v-icon> 
                                            <v-icon v-else size="20">mdi-minus-circle-outline</v-icon>
                                        </div>                                        
                                    </td>
                                    <td class="clmsBodyCommon tableBR px-0">
                                        {{item.identitynum}}
                                    </td>
                                    <td class="clmsBodyCommon tableBR px-0">{{item.confirmnum}}</td>
                                    <td class="clmsBodyCommon tableBR px-0">
                                        {{item.crtDt}}
                                    </td>
                                    <td class="clmsBodyCommon px-0">
                                        <div v-if="item.code > 0">
                                            <div>발행완료</div>
                                            <div>&#40;{{item.tradetype}}&#41;</div>
                                        </div>
                                        <div v-else-if="item.sndSttsCd =='3' && item.code < 1" class="red--text">발행실패</div>
                                        <div v-else>발행전</div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>                   
                </v-row>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import { selCashBillHisIndvList } from '../../api/bnfcr.js';

export default {
    name: 'CopayCashBillHisIndv',

    props : {

    },
        
    components: {
    },

    created: function(){        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {      
            this.getCashBillHisIndvList()      
        })
    },

    computed: {
       
    },

    watch:{    
    },
    
    methods: {
        getCashBillHisIndvList: function () {
            this.hisIndvList.splice(0)

            let obj = {
                fcltyNum        : this.$store.getters.getFcltyNum, 
                bnMmbrPk        : this.$parent.$parent.$parent.$parent.sltCashBill.bnMmbrPk, 
                copayDpsHisPk   : this.$parent.$parent.$parent.$parent.sltCashBill.copayDpsHisPk, 
            }
            selCashBillHisIndvList(obj)
                .then((response) => ( this.getCashBillListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCashBillHisIndvList : ' + error))
        },
        getCashBillListAfter: function (res) {
            if(res.statusCode == 200){                                                
                
                if(res.result != null){
                    res.result.forEach(el => {
                        this.hisIndvList.push(el)
                    });
                }

            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.$emit('isClose', '.')
        },        
        snackControll: function (options) {
            this.snackOptions = options
        },
        
    },

    data: () => ({     
        hisIndvHeaders: [
            { text: '발행금액', value: '', align: 'center'},
            { text: '진료약제비', value: '', align: 'center'},
            { text: '계약의사 진찰비', value: '', align: 'center'},
            { text: '신분확인번호', value: '', align: 'center'},
            { text: '현금승인번호', value: '', align: 'center'},
            { text: '발행일시', value: '', align: 'center'},
            { text: '상태', value: '', align: 'center'},
        ],   
        hisIndvList:[],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>
	