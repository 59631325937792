<template>
    <v-sheet class="pa-7" rounded="lg">
        <v-row class="pa-0 mb-3 justify-center" no-gutters>
            <v-icon color="#f8bb86" style="font-size: 120px;">mdi-alert-circle-outline</v-icon>
        </v-row>
        <v-row class="pa-0 mb-4 justify-center" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="txtAlgnMid" cols="11">
                <span class="text-h4 font-weight-bold" style="font-size: 1.875em; color: #595959;">{{ title }}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-0 mb-5 justify-center" no-gutters>
            <span class="text-subtitle-1 font-weight-medium" style="font-size: 1.875em; color: #595959;">{{ contents }}</span>
        </v-row>

        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                v-for="(list, i) in filledBtn" :key="i"
                :class="list.class" large height="40" :color="list.color"
                style="font-size: 16px;" @click="callParents(list.text)">
                <v-icon large>{{ list.icon }}</v-icon>
                <span>{{ list.text }}</span>
            </v-btn>
        </v-row>
    </v-sheet>
</template>

<script>
    export default {
        name: 'BnfcrDeleteConfirm',
            
        components: {
    
        },

        props:{
            title: { type: String, default: '' },
            contents : { type: String, default: '삭제하시겠습니까?' },
        },
    
        mounted: function(){
    
        },
        
        methods: {
            callParents: function(value){
                if(value == 'Cancel') this.$emit('madalState', 7)
                else if(value == 'OK'){
                    this.$emit('madalState', 9990)
                }

            },
        },
    
        data: () => ({
            area: '',
            filledBtn: [
                { icon: '', class: 'ml-4 white--text', color:'blueBtnColor', text: 'OK', },
                { icon: '', class: 'ml-4 white--text', color:'#aaa', text: 'Cancel', },
            ],
        }),
    };
    </script>