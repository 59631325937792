<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-form ref="mdlform" lazy-validation>
            <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
                class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
                </div>
            </v-snackbar>
            <div class="d-flex justify-left">
                <span class="text-h4 font-weight-bold">프로그램&nbsp;수급자&nbsp;그룹&nbsp;관리</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <v-row class="mt-5" no-gutters>
                <v-col cols="6" class="d-flex justify-start">

                </v-col>
                <v-col cols="6" class="d-flex justify-end" v-if="btnShow1 != 2">
                    <span class="d-inline-block">
                        <v-btn class="white--text" height="30" color="blueBtnColor" rounded @click="onAction">
                            <v-icon small>mdi-check</v-icon>
                            선택완료
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <div class="d-flex mt-2"
                style="width:744px; border-top:1px solid #dbdbdb !important; border-bottom:1px solid #dbdbdb !important;">
                <div class="greyE01 " style="width:5%;text-align:center; border-right:1px solid #dbdbdb !important;"
                    v-if="btnShow1 != 2">
                    <v-checkbox class="fmlyHeaderCheck" style="padding:3px 7px;" v-model="allChecker"
                        :readonly="allReadonly" @change="allChk" hide-details></v-checkbox>
                </div>
                <div class="greyE01 d-flex align-center justify-center"
                    style="width:30%;text-align:center; border-right:1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">수급자 그룹명</span>
                </div>
                <div class="greyE01 d-flex align-center justify-center"
                    style="width:60%;text-align:center; border-right:1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">수급자 그룹 설명</span>
                </div>
                <div class="greyE01 " style="width:10%;text-align:center;">
                    <span class="black--text font-weight-medium" style="text-align:center;">
                        <v-btn icon color='blue200' v-if="btnShow != 1" @click="addRow()">
                            <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </span>
                </div>
            </div>
            <div v-if="items.length > 0">
                <div class="d-flex" style="width:744px; border-bottom: 1px solid #dbdbdb !important;"
                    v-for="(list, i) in items" :key="i">
                    <div class="" style="width:5%;text-align:center; border-right:1px solid #dbdbdb !important;"
                        v-if="btnShow1 != 2">
                        <v-checkbox class="fmlyHeaderCheck" style="padding:3px 7px" v-model="list.chkyn" @change="selChk"
                            hide-details :key="forceRender"></v-checkbox>
                        <span class="" style="text-align:center;">

                        </span>
                    </div>
                    <div class="d-flex align-center justify-center"
                        style="width:30%;text-align:center; border-right:1px solid #dbdbdb !important;">
                        <v-text-field style="width: 95%; margin-left: 5px; margin-right: 5px;" v-model="list.bnfcrgrNm"
                            color="blue" hide-details outlined dense
                            :rules="[rules.required]"></v-text-field>
                    </div>
                    <div class="d-flex align-center justify-center"
                        style="width:60%;text-align:center; border-right:1px solid #dbdbdb !important;">
                        <v-text-field style="width: 93%; margin-left: 5px; margin-right: 5px;" v-model="list.bnfcrgrDesc"
                            color="blue" hide-details outlined dense
                            :rules="[rules.required]"></v-text-field>
                    </div>
                    <div class="d-flex align-center justify-center" style="width:10%;text-align:center;">
                        <span class="d-flex align-center justify-center" style="text-align:center;" v-if="btnShow2 != 1">
                            <v-btn icon color='blue200' v-if="btnShow != 1" @click="onSave(i)" style="width: 35px;">
                                <v-icon>mdi-content-save-outline</v-icon>
                            </v-btn>
                            <v-btn icon color='grey006' v-if="list.prgBnfcrgrInfPk > 0" @click="onDelete(i)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn class="" color="grey006" icon height="26" v-else
                                @click="onDelete1(i)"><v-icon>mdi-minus-circle-outline</v-icon>
                            </v-btn>
                        </span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex" style="width:744px; border-bottom: 1px solid #dbdbdb !important;">
                    <div class="" style="width:100%;text-align:center;">
                        <span class="" style="text-align:center;">데이터가 없습니다.</span>
                    </div>
                </div>
            </div>
        </v-form>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'프로그램 평가'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>

import { selPrgBnfcrgrInfList, insPrgBnfcrgrInf, delPrgBnfcrgrInf } from '../../api/prgrm.js';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
export default {
    name: 'PrgrmgrMultiSelector',

    props: {
        prtWrtDt: { type: String, default: '' },
        prgBnfcrgrChkItems: {type:Array, default: () => { return [] }}, 
        prgBnfcrgrNoItems : {type:Array, default: () => { return [] }}, 
        prgInfPk: { type: Number, default: 0 },
    },

    components: {
        btnModalDelete,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selPrgBnfcrgrInfList()
        })
    },

    computed: {

    },

    watch: {
        'prgInfPk': function () {
            this.selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum, this.prgInfPk)
        },
    },

    methods: {
        onShow(){               
            // this.getBnfcrList()
            this.prgBnfcrgrDummyList = Object.assign([], this.bnfcrList)      

            if(this.prgBnfcrgrChkItems != null){
                if(this.prgBnfcrgrChkItems.length > 0)
                    this.setReadonlys(this.prgBnfcrgrChkItems)
            }

            if(this.prgBnfcrgrNoItems != null){
                if(this.prgBnfcrgrNoItems.length > 0)
                    this.setNoDisplay(this.prgBnfcrgrNoItems)
            }
        },
        selPrgBnfcrgrInfList: function () {
            selPrgBnfcrgrInfList(this.$store.getters.getFcltyNum, this.prgInfPk)
                .then((response) => (this.selPrgBnfcrgrInfListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selPrgBnfcrgrInfList : ' + error))
        },
        selPrgBnfcrgrInfListAfter: function (res) {
            this.items = res
            this.items.forEach((item) => {
                item.chkyn = false
            });
        },
        onSave(key) {
            if (this.$refs.mdlform.validate()) {
                let obj = {
                    bnfcrgrDesc: this.items[key].bnfcrgrDesc,
                    bnfcrgrNm: this.items[key].bnfcrgrNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    prgBnfcrgrInfPk: this.items[key].prgBnfcrgrInfPk,
                    useYn: '1',
                    userNm : this.$store.getters.getUserNm,
                }
                insPrgBnfcrgrInf(obj)
                    .then((response) => (this.insPrgBnfcrgrInfAfter(response.data)))
                    .catch((error) => console.log(error))
            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        insPrgBnfcrgrInfAfter(res) {

            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.selPrgBnfcrgrInfList()
                this.onShow()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete1(key) {
            this.items.splice(key, 1)
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key
                this.dialogDelete = true
            } else {
                if (key != 0) {
                    this.items.splice(key, 1)
                }
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items[this.key].prgBnfcrgrInfPk > 0) {
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        prgBnfcrgrInfPk: this.items[this.key].prgBnfcrgrInfPk,
                        userNm: this.$store.getters.getUserNm,
                    }
                    delPrgBnfcrgrInf(obj)
                        .then((response) => (this.delPrgBnfcrgrInfAfter(response.data)))
                        .catch((error) => console.log(error))
                } else {
                    this.items.splice(this.key, 1)
                }
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delPrgBnfcrgrInfAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.selPrgBnfcrgrInfList()
                this.onShow()
                this.$emit('reload', true)

            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onAction: function () {
            let selected = []

            this.items.forEach(x => {
                if (x.chkyn) {
                    selected.push(x)
                }
            })
            this.$emit('outEmp', selected)
            this.onClose()
        },

        onClose: function () {
            this.allChecker = false
            this.items.forEach(x=>{
                x.chkyn = false
            })
            ++this.forceRender
            this.$emit('isClose', '.')
        },

        expandClose: function () {

            this.$refs.exBtnClose.isClose()

        },

        allChk: function () {
            this.items.forEach(x => {
                if (this.allChecker) {
                    x.chkyn = true
                } else {
                    x.chkyn = false
                }
            })
        },
        selChk: function () {
            let chk = true
            this.items.forEach(x => {
                if (!x.chkyn) {
                    chk = false
                }
            })
            this.allChecker = chk
        },
        addRow() {
            let idx = this.items.length - 1
            let bldNm = ''

            if (idx > -1) {
                bldNm = this.items[idx].bldNm
            }

            let addObj = {
                bldFlr: '',
                bldNm: bldNm,
                bnfcrgrNm: null,
                bnfcrgrdesc: null,
                fcltyNum: '',
                bldInfPk: 0,
                useYn: '1',
            }
            this.items.push(addObj)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        btnShowMethod: function () {
            let pN = 1
            this.btnShow = pN
        },
        btnShowMethod2: function () {
            let pN1 = 2
            this.btnShow1 = pN1
        },
        btnShowMethod3: function () {
            let pN1 = 1
            this.btnShow2 = pN1
        },
        setReadonlys:function(prgGrInfPks){     
            this.items.forEach((x)=>{
                let bl = prgGrInfPks.includes(x.prgBnfcrgrInfPk)
                if(bl){
                    x.chkyn = true
                } 
            })
            ++this.forceRender
            this.selChk()
        },
        setNoDisplay:function(prgGrInfPks){
            this.allReadonly = false
            this.prgBnfcrGrDummyList.splice(0)

            this.items.forEach(x=>{
                let bl = prgGrInfPks.includes(x.prgBnfcrgrInfPk)
                if(!bl){
                    this.prgDummyList.push(x)
                }  
            })            
        }
    },

    data: () => ({
        items: [],
        wrtDt: '',
        field: '',
        allChecker: false,
        allReadonly: false,
        dialogDelete: false,
        forceRender: 0,
        exBtn: [
            {
                index: 0,
                btnTitle: '직종',
                btnIcon: 'business',
                exWidth: 134,
                exHeigth: 220,
                expandList: [],
            },
        ],
        snackOptions: [false, 3000, '', 'info'],
        btnShow: 0,
        btnShow1: 0,
        btnShow2: 0,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
};
</script>
<style>
.empSearchField div div div label {
    margin-top: 0px;
    top: 3px !important;
}

.empSearchField div .v-input__slot {
    padding: 0 14px 0 18px !important;
}
</style>