<template>
    <v-sheet class="pa-0 ma-0" min-width="960" max-width="960">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pt-4" no-gutters>
            <v-col class="d-flex align-center" cols="5">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">서류목록</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center pr-2" cols="5">
                <v-spacer></v-spacer>
                <span>
                    <v-btn class="ml-1 white--text" color="blueBtnColor" height="30"
                        :disabled="$store.getters.getBnfcrState.bnmmbr == 0 ? true : false"
                        @click="menu.splice(0, 1, true)" samll rounded>
                        <v-icon small>mdi-pencil-outline</v-icon>
                        <span class="fontOneRem" >서류등록</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-divider :class="['mt-3', docMngCntList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', docMngCntList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="5">
                <span class="black--text font-weight-medium">서류명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                <span class="black--text font-weight-medium">사진개수</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                <span class="black--text font-weight-medium">파일개수</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="2">
                <span class="black--text font-weight-medium">동록일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2 tableBR" cols="1">
                <span class="black--text font-weight-medium">등록자</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-2" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="[docMngCntList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row class="py-4 tableBB" v-if="docMngCntList.length == 0" no-gutters>
            <v-col
                style="text-align: center;"
                cols="12">
                <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
            </v-col>
        </v-row>
        <div
            class="d-inlice-block overflow-y-auto" style="width: 100%; height: 370px;"
            v-else>
            <v-row class="pa-0 tableBB" v-for="(list, i) in docMngCntList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="5">
                    <span class="black--text">{{ list.docNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="black--text">{{ list.cntPict }}개</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="black--text">{{ list.cntMtrl }}개</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text">{{ list.wrtDt }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="1">
                    <span class="black--text">{{ list.wrtr }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <span class="mr-2">
                        <v-btn icon @click="menu.splice(1, 1, true), sltDocIndex = i">
                            <v-icon>mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </span>
                    <span>
                        <v-btn @click="removeDocMng(list.docMngPk)" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="menu[0]" max-width="900" content-class="round" persistent eager>
            <EtcDocRgstrMdl @modalClose="modalChanger"></EtcDocRgstrMdl>
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="900" content-class="round" persistent eager>
            <EtcDocMdfMdl @modalClose="modalChanger"></EtcDocMdfMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selDocMngCntList, delDocMng } from '../../api/cmm.js';

import EtcDocRgstrMdl from './EtcDocRgstrMdl.vue';
import EtcDocMdfMdl from './EtcDocMdfMdl.vue';

export default {
    name: 'BnfcrDocMgmt',
            
    components: {
        EtcDocRgstrMdl,
        EtcDocMdfMdl,
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                this.getDocMngCntList(this.$store.getters.getBnfcrState.bnmmbr)
        })
    },

    computed: {
        getBnMmbrPk: function(){
            return this.$store.getters.getBnfcrState.bnmmbr
        },
    },

    watch:{
        getBnMmbrPk: function(v){
            if( v != 0 )
                this.getDocMngCntList(v)
        },
    },

    destroyed: function(){
     
    },
        
    methods: {
        getDocMngCntList: function (pk){
            selDocMngCntList(this.$store.getters.getFcltyNum, 26, 8, pk)
                .then((response) => ( this.getDocMngCntListAfter(response.data) ))
                .catch((error) => console.log('upload error /cmm/selDocMngCntList : ' + error))
        },
        removeDocMng: function (pk){
            delDocMng(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk)
                .then((response) => ( this.asyncResult(response.data) ))
                .catch((error) => console.log('upload error /cmm/delDocMng : ' + error))
        },
        getDocMngCntListAfter: function (res){
            if(res.length != 0){
                res.forEach(items => {
                    items.wrtDt = items.wrtDt?.beforeDateFormatDot()
                });
            }
            this.docMngCntList = res
        },
        asyncResult: function (res){
            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])
                this.getDocMngCntList(this.$store.getters.getBnfcrState.bnmmbr)
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        modalChanger: function (value) {
            this.menu.splice(value, 1, false)
            this.sltDocIndex = -1
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        menu: [ false, false, ],
        sltDocIndex: -1,
        docMngCntList: [],
        snackOptions: [ false, 3000, '', 'info' ],
    }),
};
</script>