<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>                            
            <div class="d-flex overflow-y-auto" style="height:550px;">       
                <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:390px;"> 
                    <v-row class="pa-0 ma-0" no-gutters style="border: 1px solid #dbdbdb !important;">   
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="12">
                            <v-img src="../../assets/physcthrpy_v1.png"></v-img>
                        </v-col>
                    </v-row>
                </div>
                <div class="d-inline-block pa-0 ml-3 align-self-start" style="width:1090px;font-size:0.9rem !important;">  
                    <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                        <v-col class="pa-0 ma-0 d-flex" cols="12">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:4%;">
                                <span class="black--text font-weight-medium">위치</span>
                            </div>
                            <div class="greyE01 pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                               <div style="width:100%" class="d-flex justify-center align-center pa-1 ma-0">
                                    <span class="black--text font-weight-medium">오른쪽</span>
                               </div>
                               <div style="width:100%;border-top: 1px solid #dbdbdb !important;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">                                        
                                        <span class="black--text font-weight-medium">형태</span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                        <span class="black--text font-weight-medium">정도</span>                                        
                                    </div>
                               </div>
                            </div>
                            <div class="greyE01 pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">
                                <div style="width:100%" class="d-flex justify-center align-center pa-1 ma-0">
                                    <span class="black--text font-weight-medium">왼쪽</span>
                               </div>
                               <div style="width:100%;border-top: 1px solid #dbdbdb !important;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">                                        
                                        <span class="black--text font-weight-medium">형태</span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                        <span class="black--text font-weight-medium">정도</span>                                        
                                    </div>
                               </div>
                            </div>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">위치</span>
                            </div>
                            <div class="greyE01 pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                               <div style="width:100%" class="d-flex justify-center align-center pa-1 ma-0">
                                    <span class="black--text font-weight-medium">오른쪽</span>
                               </div>
                               <div style="width:100%;border-top: 1px solid #dbdbdb !important;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">                                        
                                        <span class="black--text font-weight-medium">형태</span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                        <span class="black--text font-weight-medium">정도</span>                                        
                                    </div>
                               </div>
                            </div>
                            <div class="greyE01 pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">
                                <div style="width:100%" class="d-flex justify-center align-center pa-1 ma-0">
                                    <span class="black--text font-weight-medium">왼쪽</span>
                               </div>
                               <div style="width:100%;border-top: 1px solid #dbdbdb !important;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">                                        
                                        <span class="black--text font-weight-medium">형태</span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                        <span class="black--text font-weight-medium">정도</span>                                        
                                    </div>
                               </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-for="i in 10" :key="i" class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                        <v-col class="pa-0 ma-0 d-flex" cols="12">
                            <div  class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;">
                                <span class="black--text font-weight-medium">{{i}}</span>
                            </div>
                            <div class="pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;"> 
                               <div style="width:100%;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">
                                         <span class="d-inline-block" style="width: 90px;">                                            
                                            <v-select
                                                v-model="group1[i]"                                            
                                                :items="codeItem11"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            />
                                         </span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                        <span class="d-inline-block" style="width: 90px;">
                                        <v-select
                                            v-model="group2[i]"                                            
                                            :items="codeItem10"
                                            item-text="valcdnm"
                                            item-value="valcd"
                                            dense hide-details outlined
                                        />     
                                        </span>                               
                                    </div>
                               </div>
                            </div>
                            <div class=" pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">
                               <div style="width:100%;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">                                                                                
                                        <span class="d-inline-block" style="width: 90px;">
                                            <v-select
                                                v-model="group3[i]"                                            
                                                :items="codeItem11"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            />
                                        </span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                         <span class="d-inline-block" style="width: 90px;">
                                            <v-select
                                                v-model="group4[i]"                                            
                                                :items="codeItem10"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            />
                                        </span>
                                    </div>
                               </div>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">{{i+10}}</span>
                            </div>
                            <div class="pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">  
                               <div style="width:100%;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">                                                                                
                                        <span class="d-inline-block" style="width: 90px;">
                                            <v-select
                                                v-model="group1[i+10]"                                            
                                                :items="codeItem11"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            />
                                        </span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                         <span class="d-inline-block" style="width: 90px;">
                                            <v-select
                                                v-model="group2[i+10]"                                            
                                                :items="codeItem10"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            />
                                        </span>
                                    </div>
                               </div>
                            </div>
                            <div class="pa-0 ma-0" style="width:23%;border-left: 1px solid #dbdbdb !important;">                                
                               <div style="width:100%;" class="d-flex">
                                    <div style="width:50%"  class="d-flex justify-center align-center pa-1 ma-0">                                                                                
                                        <span class="d-inline-block" style="width: 90px;">
                                            <v-select
                                                v-model="group3[i+10]"                                            
                                                :items="codeItem11"
                                                item-text="valcdnm"
                                                item-value="valcd"
                                                dense hide-details outlined
                                            />
                                        </span>
                                    </div>
                                    <div style="width:50%;border-left: 1px solid #dbdbdb !important;"  class="d-flex justify-center align-center pa-1 ma-0">
                                         <span class="d-inline-block" style="width: 90px;">
                                            <v-select
                                                v-model="group4[i+10]"                                            
                                                :items="codeItem10"
                                                item-text="valcdnm"
                                                item-value="valcd"                                                
                                                dense hide-details outlined
                                            />
                                        </span>
                                    </div>
                               </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">   
                        <v-col class="pa-0 ma-0 d-flex" cols="12">
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:4%;">
                                <span class="black--text font-weight-medium">평가</span>
                            </div>
                            <div class="pa-1 ma-0" style="width:96%;border-left: 1px solid #dbdbdb !important;">
                                <v-textarea v-model="evltn" class="rmrksArea" no-resize  
                                    rows="12" outlined dense hide-details>
                                </v-textarea>   
                            </div>
                        </v-col>                        
                    </v-row>
                </div>
            </div>
        </v-form>   
    </v-sheet>
</template>
<script>
import { getMultiStcd } from '../../api/index.js';
import { insBnfcrExamHis} from '../../api/bnftSrv.js';

export default {
    name: 'PhyscThrpyEvltnTab2',

    props : {
        
    },

    computed: {
       
    },  

    watch:{        
        
    },

    created:function(){  
       
    },

    components: {
    },
    
    mounted: function(){ 
        this.$nextTick(function () { 
            
        }) 
    },
        
    methods: {    
        onValReset(){
            for(let i=1;i<21;i++){
                this.group1[i] = '1'
                this.group2[i] = '1'
                this.group3[i] = '1'
                this.group4[i] = '1'
            }            
        },
        onShow(){           
           
        },
        setStyle:function(value){
            let style = "";
            if(value > 13){
                style="width:200px;"
            }else if(value > 10 && value <= 13){
                style="width:160px;"
            }else if(value > 5 && value <= 10){
                style="width:130px;"
            }else if(value == 2){
                style="width:90px;"
            }else{
                style="width:100px;"
            }
            return style;
        },   

        setData(data){  
            this.onCodeload()
            
            this.data = data   
            this.group1.splice(0)
            this.group2.splice(0)
            this.group3.splice(0)
            this.group4.splice(0)
            this.data.map(x=>{
                x.itmValCd = x.valClcd

                if(x.clsfc3Cd =='1'){                    
                    this.group1[x.itmCd] = (x.itmVal != undefined && x.itmVal != '') ?x.itmVal:'1'
                }else if(x.clsfc3Cd =='2'){
                    this.group2[x.itmCd] = (x.itmVal != undefined && x.itmVal != '') ?x.itmVal:'0'
                }else if(x.clsfc3Cd =='3'){
                    this.group3[x.itmCd] = (x.itmVal != undefined && x.itmVal != '') ?x.itmVal:'1'
                }else if(x.clsfc3Cd =='4'){
                    this.group4[x.itmCd] = (x.itmVal != undefined && x.itmVal != '') ?x.itmVal:'0'
                }else if(x.clsfc3Cd =='999'){
                    this.evltn = x.evltn
                }
            }); 

        },
        
        onClose: function () {
            this.$emit('modalClose', this.dsrEvltn)
        },

        onCodeload:function(){
            getMultiStcd(['10','11'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
        },

        codeMapping:function(res){
            this.codeItem10 = res["vCd10"]
            this.codeItem11 = res["vCd11"]
        },

        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },

        reset(){
            this.$refs.tabform.reset()
        },

        insBnfcrExam(data){
            if(this.$refs.tabform.validate()){
                //수급자평가 내역 setting

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bnMmbrPk:data.bnMmbrPk,
                    bnfcrExamHisPk:data.bnfcrExamHisPk,
                    examClcd:'106',                    
                    examRsncd : data.examRsncd,
                    examDt : data.examDt.afterDateFormatHyp(),
                    wrtr : data.wrtr,
                    examYyyy : data.inqYYYY,
                }

                //욕구사정평가 setting
                let examList = []
                this.data.map(x=>{

                    if(x.clsfc3Cd =='1'){
                        if(this.group1[x.itmCd] != undefined){
                            x.itmVal = this.group1[x.itmCd]
                        }
                    }

                    if(x.clsfc3Cd =='2'){
                        if(this.group2[x.itmCd] != undefined){
                            x.itmVal = this.group2[x.itmCd]
                        }
                    }

                    if(x.clsfc3Cd =='3'){
                        if(this.group3[x.itmCd] != undefined){
                            x.itmVal = this.group3[x.itmCd]
                        }
                    }

                    if(x.clsfc3Cd =='4'){
                        if(this.group4[x.itmCd] != undefined){
                            x.itmVal = this.group4[x.itmCd]
                        }
                    }

                    if(x.clsfc3Cd =='999'){
                        x.evltn = this.evltn
                    }
                    examList.push(x)
                });  

                insBnfcrExamHis(obj,examList)
                .then((response) => ( this.insBnfcrExamAfter(response.data)))
                .catch((error) => console.log(error))
            }
        },
        insBnfcrExamAfter: function (res) {
            this.$emit('physcThrpyEvltnTrigger', res)            
        },       
        selExamList:function(){
            //욕구사정평가 setting
            let examList = []
            this.data.map(x=>{

                if(x.clsfc3Cd =='1'){
                    if(this.group1[x.itmCd] != undefined){
                        x.itmVal = this.group1[x.itmCd]
                    }
                }

                if(x.clsfc3Cd =='2'){
                    if(this.group2[x.itmCd] != undefined){
                        x.itmVal = this.group2[x.itmCd]
                    }
                }

                if(x.clsfc3Cd =='3'){
                    if(this.group3[x.itmCd] != undefined){
                        x.itmVal = this.group3[x.itmCd]
                    }
                }

                if(x.clsfc3Cd =='4'){
                    if(this.group4[x.itmCd] != undefined){
                        x.itmVal = this.group4[x.itmCd]
                    }
                }

                if(x.clsfc3Cd =='999'){
                    x.evltn = this.evltn
                }
                examList.push(x)
            });  

            return examList
        }, 
    },
    
    data: () => ({    
        loading:true, 
        showdiv:false,   
        selected:'',
        data:[],
        obj:{},
        group1:[],
        group2:[],
        group3:[],
        group4:[],
        codeItem10:[],
        codeItem11:[],
        evltn:'',
        loops:{},
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' || value == undefined) || 'Required.',
        },
        
    }),
};
</script>