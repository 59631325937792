<template>
    <v-sheet class="ma-0 pa-5" rounded="lg" color="#bacee0" @click="onClose">        
        <div class="mt-2 pa-5" style="position:relative;">
            <div class="pa-2">
                <div class="pa-5 rounded-t-lg" style="background-color:#fee500;">                    
                    <span class="font-weight-medium" style="font-size:1.2rem;">알림톡 도착</span>
                </div>
                <div class="px-3 py-2" style="background:white" v-html="msg"></div>
                <div class="pa-5 rounded-lg rounded-t-0" style="background:white">
                    <v-btn @click="onLink" class="elevation-0" width="100%" min-width="100%">{{btnNm}}</v-btn>
                </div>
            </div>
            <div 
                class="d-flex justify-center align-center rounded-circle" 
                style="position:absolute;top:0px;right:0px;
                width:70px;height:70px;background-color:#444444;
                font-size:1rem;color:white;">kakao</div>
        </div>
    </v-sheet>
</template>
<script>

export default {
    name: 'SmsSndAlimTalkViw',
    
    components: {  
    },

    props : {
        
    },

    computed: {
        
    },

    created: function(){      
    },

    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        
    },

    methods: {  
        onClose: function () {
            this.$emit('modalClose')
        },
        onLoad:function(setObj){
            this.btnNm = setObj.msgBtn
            this.msg = setObj.msgCntnt
            this.btnLink =  setObj.lnkUrl
        },
        
        // onLink:function(){
        //     this.windowRef = window.open(this.btnLink, "_blank");         
        //     this.windowRef = null  
        // },

        onLink:function(){
            let ttl = '발송내요역 미리보기'
            let loc = (document.body.offsetWidth / 2) - (200 / 2)
            let posn = 'left=' + loc.toString() + ', top=0, '
            let options = 'width=380, height=740, menubar=no, toolbar=no, station=no resizable=no, scrollbars=no'
            
            window.open(this.btnLink, ttl, posn+options)
        },
    },
    data: () => ({ 
        sObj:{},
        clcd:0,
        msg:'',
        btnNm:'상세보기',
        btnLink:'',
        windowRef: null,
    }),
};
</script>