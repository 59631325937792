<template>
    <v-sheet>        
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">투약상황기록</span>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="onMdctnLvnRm"
                small outlined>
                <v-icon size="14">mdi-clipboard-text-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">생활실별 투약관리</span>                
            </v-btn>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="onMdctnPrscr"
                small outlined>
                <v-icon size="14">mdi-clipboard-text-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">투약처방</span>                
            </v-btn>
            <v-btn v-if="getPreData"
                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="getPreDoseRecListHis"
                small outlined>
                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
            </v-btn>
        </div>
        <v-form ref="tabform1" lazy-validation>
        <div :class="['pa-0 mt-1 d-flex',doseReclist.length > 9 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;">
            <div style="width:95%">
                <div class="d-flex greyE01" style="width:100%;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:26%;">
                        <span class="black--text font-weight-medium">제공자</span>
                    </div>
                    <div style="width:18%;font-size:0.9rem;">
                        <div class="pa-0 ma-0" style="width:100%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;padding: 4px;">                            
                            <div class="d-flex justify-center align-center py-1">
                                <span v-if="dummyHisInfo.wrtrGr[0] !='' || dummyHisInfo.wrtrGr[1] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(0)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-close-circle</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="d-flex" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[0]" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(0)"></v-text-field>
                                </span>                                
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[1]" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(1)"></v-text-field>
                                </span>                                
                            </div>
                        </div>
                    </div>
                    <div style="width:18%;font-size:0.9rem;">
                        <div class="pa-0 ma-0" style="width:100%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;padding: 7.5px;">
                            <div class="d-flex justify-center align-center py-1">
                                <span v-if="dummyHisInfo.wrtrGr[2] !='' || dummyHisInfo.wrtrGr[3] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(1)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-close-circle</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="d-flex" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[2]" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(2)"></v-text-field>
                                </span>                                
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[3]" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(3)"></v-text-field>
                                </span>                                
                            </div>
                        </div>
                    </div>
                    <div style="width:18%;font-size:0.9rem;">
                        <div class="pa-0 ma-0" style="width:100%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-center align-center py-1">
                                <span v-if="dummyHisInfo.wrtrGr[4] !='' || dummyHisInfo.wrtrGr[5] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>                            
                                <v-btn icon @click="dsReset(2)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-close-circle</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="d-flex" style="width:100%;">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[4]" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(4)"></v-text-field>
                                </span>                                
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                    <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[5]" color="blue" ref="info"
                                        hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(5)"></v-text-field>
                                </span>                                
                            </div>
                        </div>
                    </div>
                    <div style="width:10%;font-size:0.9rem;">
                        <div class="pa-0 ma-0" style="width:100%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;padding: 7.5px;">
                            <div class="d-flex justify-center align-center py-1">
                                <span v-if="dummyHisInfo.wrtrGr[6] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(3)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-close-circle</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-left: 1px solid #dbdbdb !important;">
                            <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[6]" color="blue" ref="info"
                                    hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(6)"></v-text-field>
                            </span>                           
                        </div>
                    </div>
                    <div style="width:10%;font-size:0.9rem;">
                        <div class="pa-0 ma-0" style="width:100%;border-left: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;padding: 7.5px;">
                            <div class="d-flex justify-center align-center py-1">
                                <span v-if="dummyHisInfo.wrtrGr[7] !=''" class="black--text font-weight-medium">작성완료</span>
                                <span v-else class="red--text font-weight-medium">미작성</span>
                                <v-btn icon @click="dsReset(4)" style="width:28px;height:28px;">
                                    <v-icon size='22'>mdi-close-circle</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-left: 1px solid #dbdbdb !important;">
                            <span class="white d-inline-block" style="width: 60px;border-radius: 10px;">
                                <v-text-field class="mdctnField" v-model="hisInfo.wrtrGr[7]" color="blue" ref="info"
                                    hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(7)"></v-text-field>
                            </span>                            
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="width:100%;font-size:0.9rem;border-top: 1px solid #dbdbdb !important;">
                    <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:14%;">
                        <span class="black--text font-weight-medium">투약명</span>
                    </div>
                    <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">투약량</span>
                    </div>
                    <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">투약방법</span>
                    </div>
                    <div class="d-flex" style="width:18%;">
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0">
                                <span class="black--text font-weight-medium">아침식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsBrkfsBfTm"></CmmTimeField></span>
                            </div>
                        </div>
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0">
                                <span class="black--text font-weight-medium">아침식후</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsBrkfsAfTm"></CmmTimeField></span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="width:18%;">
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">                                    
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0">
                                <span class="black--text font-weight-medium">점심식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsLunchBfTm"></CmmTimeField></span>
                            </div>
                        </div>
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                                <span class="black--text font-weight-medium">점심식후</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsLunchAfTm"></CmmTimeField></span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="width:18%;">
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0">
                                <span class="black--text font-weight-medium">저녁식전</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsDinerBfTm"></CmmTimeField></span>
                            </div>
                        </div>
                        <div style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex greyE01 justify-center align-center pa-1 ma-0">
                                <span class="black--text font-weight-medium">저녁식후</span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                                <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsDinerAfTm"></CmmTimeField></span>
                            </div>
                        </div>
                    </div>
                    <div style="width:10%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0">
                            <span class="black--text font-weight-medium">취침전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                            <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsBedBfTm"></CmmTimeField></span>
                        </div>
                    </div>
                    <div style="width:10%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="border-top:1px solid #dbdbdb">
                            <span class="black--text font-weight-medium">필요시</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0">
                            <span style="width:60px;"><CmmTimeField v-model="hisInfo.dsNeedTm"></CmmTimeField></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex greyE01 justify-center align-center" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                <v-btn icon color="blue200" height="26" @click="onModal(0,'1')">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </div>
        </div>
        <div style="max-height:440px;overflow-y:auto"> 
            <div v-if="doseReclist.length > 0">  
                <div v-for="(list, k) in doseReclist" :key="k" class="pa-0 ma-0 d-flex" style="border-top: 1px solid #dbdbdb;font-size:0.9rem !important">
                    <div class="d-flex" style="width:95%">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:14%;">
                            {{list.drugNm}}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="list.drugAmt" color="blue" hide-details outlined dense></v-text-field>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                            {{list.drugMthd}}
                        </div>
                        <div class="d-flex" style="width:18%;font-size:0.9rem">
                            <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">                                    
                                <v-checkbox class="checkIcon" v-model="list.dsBrkfsBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                            <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">                                        
                                <v-checkbox class="checkIcon" v-model="list.dsBrkfsAf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </div>
                        <div class="d-flex" style="width:18%;font-size:0.9rem">
                            <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <v-checkbox class="checkIcon" v-model="list.dsLunchBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                            <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <v-checkbox class="checkIcon" v-model="list.dsLunchAf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </div>
                        <div class="d-flex" style="width:18%;font-size:0.9rem">
                            <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <v-checkbox class="checkIcon" v-model="list.dsDinerBf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                            <div class="d-flex justify-center align-center px-1 py-2 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                                <v-checkbox class="checkIcon" v-model="list.dsDinerAf" true-value="1" false-value="2" hide-details></v-checkbox>
                            </div>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                            <v-checkbox class="checkIcon" v-model="list.dsBedBf" true-value="1" false-value="2" hide-details></v-checkbox>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:10%;border-left: 1px solid #dbdbdb !important;">
                            <v-checkbox class="checkIcon" v-model="list.dsNeed" true-value="1" false-value="2" hide-details></v-checkbox>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center" style="width:5%;border-left: 1px solid #dbdbdb !important;">                                
                        <v-btn icon color='grey006' @click="delList(k,'subRec1')"> 
                            <v-icon v-if="list.doseRecPk > 0">mdi-trash-can-outline</v-icon>                                           
                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                        </v-btn> 
                    </div>
                </div>                        
            </div>
            <div v-else class="d-flex pa-0" no-gutters style="border-top: 1px solid #dbdbdb !important;">
                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                    데이터가 없습니다.
                </div> 
            </div>
        </div>
        <div class="pa-0 ma-0 d-flex" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;">
            <div class="d-flex" style="width:95%">
                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:26%;">
                    <span class="black--text font-weight-medium">비고</span>
                </div>                                                
                <div class="d-flex justify-center align-center pa-1" style="width:74%;border-left: 1px solid #dbdbdb !important;">
                    <v-text-field v-model="hisInfo.cntnt" color="blue" hide-details outlined dense></v-text-field>
                </div>
            </div>
            <div class="d-flex justify-center align-center" style="width:5%;">
                
            </div>
        </div>
        </v-form>        
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" max-width="1100">    
                <drugInfMgmt @outDrugInf="addDrugInf" @isClose="isModal"></drugInfMgmt>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[2]" content-class="round" persistent max-width="1500">
                <MdctnMgmtLvnRmMdl 
                    :mdctnLvnRmMdlPk="mdctnLvnRmMdlPk"
                    :inqYMD="$parent.$parent.$parent.$parent.inqYMD"
                    :lvnRmInfPk="lvnRmInfPk"
                    @modalEvent="reLoad"
                    @isClose="isModal(2)">
                </MdctnMgmtLvnRmMdl>
            </v-dialog>  
        </div>
    </v-sheet>
</template>
<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import drugInfMgmt from '../../components/commons/DrugInfMgmt.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import MdctnMgmtLvnRmMdl from './MdctnMgmtLvnRmMdl.vue';
import { selDoseRecListHis, selDoseInfList, insDoseRecMulti, delDoseRec, delDoseRecHis } from '../../api/bnftrcrd.js';

export default {
    name: 'MdctnMgmtTab',

    components: {
        CmmTimeField,
        btnModalDelete,
        drugInfMgmt,
        EmpSelector,
        MdctnMgmtLvnRmMdl
    },

    props : {
        lvnRmInfPk:{type:Number, default:0},
    },

    created: function(){    
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           this.inqYMD = this.$parent.$parent.$parent.$parent.inqYMD
        })
    },

    computed: {
        getYmd:function(){
            return this.$parent.$parent.$parent.$parent.inqYMD
        }
    },  

    watch: {
        'getYmd':function(value){
           this.inqYMD = value 
        }
    },

    methods: {   
        onLoad:function(){            
            this.frmReset()   
            this.getDoseRecListHis()         
        },
        reLoad:function(){
            this.frmReset()
            this.getDoseRecListHis()
        },
        frmReset:function(){
            this.empidx =0
            this.idx = 0
            this.type = ''
            this.addType = ''
            this.delTitle = '투약관리'
            
            this.hisInfo = Object.assign({bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',}) 
            this.dummyHisInfo = Object.assign({bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',}) 

            this.doseReclist.splice(0)
            this.$refs.tabform1.resetValidation();            
        },
        dsReset:function(key){
            if(key==0){
                this.hisInfo.wrtrGr.splice(0, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(0, 1, '')
                this.hisInfo.wrtrGr.splice(1, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(1, 1, '')
                this.hisInfo.dsBrkfsBfTm = ''
                this.hisInfo.dsBrkfsAfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsBrkfsBf = "2"   
                    e.dsBrkfsAf = "2"
                })
            }else if(key==1){
                this.hisInfo.wrtrGr.splice(2, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(2, 1, '')
                this.hisInfo.wrtrGr.splice(3, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(3, 1, '')
                this.hisInfo.dsLunchBfTm = ''
                this.hisInfo.dsLunchAfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsLunchBf = "2"   
                    e.dsLunchAf = "2"
                })
            }else if(key==2){
                this.hisInfo.wrtrGr.splice(4, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(4, 1, '')
                this.hisInfo.wrtrGr.splice(5, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(5, 1, '')
                this.hisInfo.dsDinerBfTm = ''
                this.hisInfo.dsDinerAfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsDinerBf = "2"   
                    e.dsDinerAf = "2"
                })
            }else if(key==3){
                this.hisInfo.wrtrGr.splice(6, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(6, 1, '')
                this.hisInfo.dsBedBfTm = ''
                this.doseReclist.forEach(e => {
                    e.dsBedBf = "2"   
                })
            }else if(key==4){
                this.hisInfo.wrtrGr.splice(7, 1, '')
                this.hisInfo.wrtrGrMmbrPk.splice(7, 1, '')
                this.hisInfo.dsNeedTm = ''
                this.doseReclist.forEach(e => {
                    e.dsNeed = "2"   
                })
            }
            
        },
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm            
            let gInqYMD = this.$parent.$parent.$parent.$parent.inqYMD

            if(gBnMmbrPk > 0){
                if(this.$refs.tabform1.validate()){
                    let obj = {    
                        bnftOfrRecHisPk:this.hisInfo.bnftOfrRecHisPk,
                        fcltyNum:gFcltyNum,
                        bnMmbrPk:gBnMmbrPk,
                        userNm:gUserNm,
                        lvnRmInfPk  : this.lvnRmInfPk,
                        recDt:gInqYMD,
                        wrtrGr:this.hisInfo.wrtrGr,
                        wrtrGrMmbrPk:this.hisInfo.wrtrGrMmbrPk,
                        wrtrMmbrPk1 : this.hisInfo.wrtrGrMmbrPk[0],
                        wrtrMmbrPk2 : this.hisInfo.wrtrGrMmbrPk[1],
                        wrtrMmbrPk3 : this.hisInfo.wrtrGrMmbrPk[2],
                        wrtrMmbrPk4 : this.hisInfo.wrtrGrMmbrPk[3],
                        wrtrMmbrPk5 : this.hisInfo.wrtrGrMmbrPk[4],
                        wrtrMmbrPk6 : this.hisInfo.wrtrGrMmbrPk[5],
                        wrtrMmbrPk7 : this.hisInfo.wrtrGrMmbrPk[6],
                        wrtrMmbrPk8 : this.hisInfo.wrtrGrMmbrPk[7], 
                        brkfsTmHh   : '',
                        brkfsTmMm   : '',
                        lunchTmHh   : '',
                        lunchTmMm   : '',
                        dinerTmHh   : '',
                        dinerTmMm   : '',
                        bedTmHh     : '',
                        bedTmMm     : '',
                        needTmHh    : '',
                        needTmMm    : '',                       
                        cntnt:this.hisInfo.cntnt,
                        list:[],
                    }

                    this.doseReclist.forEach(e => {
                        let item = Object.assign({},e)
                        item.fcltyNum = gFcltyNum
                        item.bnMmbrPk = gBnMmbrPk
                        item.userNm = gUserNm
                        item.doseDt = gInqYMD
                        
                        //아침
                        if(e.dsBrkfsBf == "1"){
                            if(this.hisInfo.dsBrkfsBfTm?.isEmptyVal()){
                                let dsBrkfsBfTm =  this.hisInfo.dsBrkfsBfTm.split(":")
                                item.brkfsTmHh = dsBrkfsBfTm[0]
                                item.brkfsTmMm = dsBrkfsBfTm[1]
                                obj.brkfsTmHh = dsBrkfsBfTm[0]
                                obj.brkfsTmMm = dsBrkfsBfTm[1]
                            }
                        }

                        if(e.dsBrkfsAf == "1"){
                            if(this.hisInfo.dsBrkfsAfTm?.isEmptyVal()){
                                let dsBrkfsAfTm =  this.hisInfo.dsBrkfsAfTm.split(":")
                                item.brkfsTmHh = dsBrkfsAfTm[0]
                                item.brkfsTmMm = dsBrkfsAfTm[1]
                                obj.brkfsTmHh = dsBrkfsAfTm[0]
                                obj.brkfsTmMm = dsBrkfsAfTm[1]
                            }
                        }

                        //점심
                        if(e.dsLunchBf == "1"){
                            if(this.hisInfo.dsLunchBfTm?.isEmptyVal()){
                                let dsLunchBfTm =  this.hisInfo.dsLunchBfTm.split(":")
                                item.lunchTmHh = dsLunchBfTm[0]
                                item.lunchTmMm = dsLunchBfTm[1]
                                obj.lunchTmHh = dsLunchBfTm[0]
                                obj.lunchTmMm = dsLunchBfTm[1]
                            }
                        }

                        if(e.dsLunchAf == "1"){
                            if(this.hisInfo.dsLunchAfTm?.isEmptyVal()){
                                let dsLunchAfTm =  this.hisInfo.dsLunchAfTm.split(":")
                                item.lunchTmHh = dsLunchAfTm[0]
                                item.lunchTmMm = dsLunchAfTm[1]
                                obj.lunchTmHh = dsLunchAfTm[0]
                                obj.lunchTmMm = dsLunchAfTm[1]
                            }
                        }

                        //저녁
                        if(e.dsDinerBf == "1"){
                            if(this.hisInfo.dsDinerBfTm?.isEmptyVal()){
                                let dsDinerBfTm =  this.hisInfo.dsDinerBfTm.split(":")
                                item.dinerTmHh = dsDinerBfTm[0]
                                item.dinerTmMm = dsDinerBfTm[1]
                                obj.dinerTmHh = dsDinerBfTm[0]
                                obj.dinerTmMm = dsDinerBfTm[1]
                            }
                        }

                        if(e.dsDinerAf == "1"){
                            if(this.hisInfo.dsDinerAfTm?.isEmptyVal()){
                                let dsDinerAfTm =  this.hisInfo.dsDinerAfTm.split(":")
                                item.dinerTmHh = dsDinerAfTm[0]
                                item.dinerTmMm = dsDinerAfTm[1]
                                obj.dinerTmHh = dsDinerAfTm[0]
                                obj.dinerTmMm = dsDinerAfTm[1]
                            }
                        }

                        //취췸전
                        if(e.dsBedBf == "1"){
                            if(this.hisInfo.dsBedBfTm?.isEmptyVal()){
                                let dsBedBfTm =  this.hisInfo.dsBedBfTm.split(":")
                                item.bedTmHh = dsBedBfTm[0]
                                item.bedTmMm = dsBedBfTm[1]
                                obj.bedTmHh = dsBedBfTm[0]
                                obj.bedTmMm = dsBedBfTm[1]
                            }
                        }

                        //취췸전
                        if(e.dsNeed == "1"){
                            if(this.hisInfo.dsNeedTm?.isEmptyVal()){
                                let dsNeedTm =  this.hisInfo.dsNeedTm.split(":")
                                item.needTmHh = dsNeedTm[0]
                                item.needTmMm = dsNeedTm[1]
                                obj.needTmHh = dsNeedTm[0]
                                obj.needTmMm = dsNeedTm[1]
                            }
                        }
                        obj.list.push(item)
                    });

                    if(this.doseReclist.length > 0){                    
                        insDoseRecMulti(obj)
                            .then((response) => ( this.onSaveAfter(response.data) ))
                            .catch((error) => console.log('upload error /bnftrcrd/insDoseRecMulti : ' + error))
                    }
                }                
            }
        },
        onSaveAfter:function(res){
            this.$emit("hlthBlnftTrigger",res)
            if(res.statusCode != 300) this.reLoad()
        },
        //기록 DB 삭제
        onDelete:function(res){
            let fcltyNum = this.$store.getters.getFcltyNum    
            let userNm = this.$store.getters.getUserNm
            let bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk

            if(res == 9990){
                this.dialogDelete = false
                if(this.type=='all'){      
                    let bnftOfrRecHisPk = this.hisInfo.bnftOfrRecHisPk
                    if(bnftOfrRecHisPk > 0){
                        let obj = {
                            fcltyNum: fcltyNum,
                            bnMmbrPk : bnMmbrPk,
                            userNm : userNm,
                            bnftOfrRecHisPk : bnftOfrRecHisPk,
                        }
                        delDoseRecHis(obj)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('upload error /bnftrcrd/delDoseRecHis : ' + error))
                    }
                }else if(this.type=="subRec1"){
                    let doseRecPk = this.doseReclist[this.idx].doseRecPk

                    if(doseRecPk > 0){
                        let obj = {
                            fcltyNum: fcltyNum,
                            bnMmbrPk : bnMmbrPk,
                            userNm : userNm,
                            doseRecPk : doseRecPk,
                        }
                        delDoseRec(obj)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('upload error /bnftrcrd/delDoseRec : ' + error))
                    }else{
                        let error = {
                            statusCode:500,
                            message:'삭제 대상이 존재하지 않습니다.'
                        }
                        this.$emit("hlthBlnftTrigger",error)
                    }
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter:function(res){

            if(this.type=="subRec1"){
                this.doseReclist.splice(this.idx,1)    
            }

            this.$emit("hlthBlnftTrigger",res)
            this.reLoad()
            this.idx = 0
            this.type = ''
            this.delTitle = ''
        },
        onModal: function (value,type) {
            this.dialog.splice(value, 1, true)
            this.addType = type
        },
        onEmpModel:function(value){
            this.empidx = value
            this.dialog.splice(1, 1, true)
        },
        isModal: function (value) {    
            if(value == 2) this.mdctnLvnRmMdlPk = -1
            this.dialog.splice(value, 1, false)
        },
        getDoseRecListHis(){
            this.getPreData = true

            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }        
            selDoseRecListHis(obj)
                .then((response) => ( this.getDoseRecListHisAfter(response.data)))
                .catch((error) => console.log('upload error /bnftrcrd/selDoseRecListHis : ' + error))
        },
        getDoseRecListHisAfter:function(res){   
                 
            if(res.hisInfo != null){
                this.hisInfo = res.hisInfo
                this.getPreData = false
                this.dummyHisInfo = JSON.parse(JSON.stringify(res.hisInfo))
            }

            if(res.doseTmInfo.brkfsTmHhBf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmBf?.isEmptyVal()){
                this.hisInfo.dsBrkfsBfTm = res.doseTmInfo.brkfsTmHhBf + ":" + res.doseTmInfo.brkfsTmMmBf
            }

            if(res.doseTmInfo.brkfsTmHhAf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmAf?.isEmptyVal()){
                this.hisInfo.dsBrkfsAfTm = res.doseTmInfo.brkfsTmHhAf + ":" + res.doseTmInfo.brkfsTmMmAf
            }

            if(res.doseTmInfo.lunchTmHhBf?.isEmptyVal() && res.doseTmInfo.lunchTmMmBf?.isEmptyVal()){
                this.hisInfo.dsLunchBfTm = res.doseTmInfo.lunchTmHhBf + ":" + res.doseTmInfo.lunchTmMmBf
            }

            if(res.doseTmInfo.lunchTmHhAf?.isEmptyVal() && res.doseTmInfo.lunchTmMmAf?.isEmptyVal()){
                this.hisInfo.dsLunchAfTm = res.doseTmInfo.lunchTmHhAf + ":" + res.doseTmInfo.lunchTmMmAf
            }

            if(res.doseTmInfo.dinerTmHhBf?.isEmptyVal() && res.doseTmInfo.dinerTmMmBf?.isEmptyVal()){
                this.hisInfo.dsDinerBfTm = res.doseTmInfo.dinerTmHhBf + ":" + res.doseTmInfo.dinerTmMmBf
            }

            if(res.doseTmInfo.dinerTmHhAf?.isEmptyVal() && res.doseTmInfo.dinerTmMmAf?.isEmptyVal()){
                this.hisInfo.dsDinerAfTm = res.doseTmInfo.dinerTmHhAf + ":" + res.doseTmInfo.dinerTmMmAf
            }

            if(res.doseTmInfo.bedTmHh?.isEmptyVal() && res.doseTmInfo.bedTmMm?.isEmptyVal()){
                this.hisInfo.dsBedBfTm = res.doseTmInfo. bedTmHh+ ":" + res.doseTmInfo.bedTmMm
            }

            if(res.doseReclist.length > 0){
                
                this.doseReclist = res.doseReclist

                this.doseReclist.forEach(e => {    
                    //아침
                    if(e.dsBrkfsBf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsBfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    if(e.dsBrkfsAf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsAfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    //점심
                    if(e.dsLunchBf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchBfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    if(e.dsLunchAf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchAfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    //저녁
                    if(e.dsDinerBf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerBfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
    
                    if(e.dsDinerAf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerAfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
                    
                    //취침전
                    if(e.dsBedBf == "1"){
                        if(e.bedTmHh?.isEmptyVal() && e.bedTmMm?.isEmptyVal()){
                            this.hisInfo.dsBedBfTm = e.bedTmHh + ":" + e.bedTmMm
                        }
                    }
    
                    //필요시
                    if(e.dsNeed == "1"){
                        if(e.needTmHh?.isEmptyVal() && e.needTmMm?.isEmptyVal()){
                            this.hisInfo.dsNeedTm = e.needTmHh + ":" + e.needTmMm
                        }
                    }    
                });
                
            }else{
                this.getDoseInfList()
            }
        },

        getPreDoseRecListHis:function(){
            let date = new Date(this.$parent.$parent.$parent.$parent.inqYMD?.beforeDateFormatHyp())
            let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
            date = dateArr[0]?.afterDateFormatHyp()    
            this.preymd = date
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.preymd,
            }        

            selDoseRecListHis(obj)
                .then((response) => ( this.getPreDoseRecListHisAfter(response.data)))
                .catch((error) => console.log('upload error /bnftrcrd/selDoseRecListHis : ' + error))
        },

        getPreDoseRecListHisAfter:function(res){
            if(res.hisInfo != null){
                this.hisInfo = res.hisInfo
            }

            if(res.doseTmInfo.brkfsTmHhBf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmBf?.isEmptyVal()){
                this.hisInfo.dsBrkfsBfTm = res.doseTmInfo.brkfsTmHhBf + ":" + res.doseTmInfo.brkfsTmMmBf
            }

            if(res.doseTmInfo.brkfsTmHhAf?.isEmptyVal() && res.doseTmInfo.brkfsTmMmAf?.isEmptyVal()){
                this.hisInfo.dsBrkfsAfTm = res.doseTmInfo.brkfsTmHhAf + ":" + res.doseTmInfo.brkfsTmMmAf
            }

            if(res.doseTmInfo.lunchTmHhBf?.isEmptyVal() && res.doseTmInfo.lunchTmMmBf?.isEmptyVal()){
                this.hisInfo.dsLunchBfTm = res.doseTmInfo.lunchTmHhBf + ":" + res.doseTmInfo.lunchTmMmBf
            }

            if(res.doseTmInfo.lunchTmHhAf?.isEmptyVal() && res.doseTmInfo.lunchTmMmAf?.isEmptyVal()){
                this.hisInfo.dsLunchAfTm = res.doseTmInfo.lunchTmHhAf + ":" + res.doseTmInfo.lunchTmMmAf
            }

            if(res.doseTmInfo.dinerTmHhBf?.isEmptyVal() && res.doseTmInfo.dinerTmMmBf?.isEmptyVal()){
                this.hisInfo.dsDinerBfTm = res.doseTmInfo.dinerTmHhBf + ":" + res.doseTmInfo.dinerTmMmBf
            }

            if(res.doseTmInfo.dinerTmHhAf?.isEmptyVal() && res.doseTmInfo.dinerTmMmAf?.isEmptyVal()){
                this.hisInfo.dsDinerAfTm = res.doseTmInfo.dinerTmHhAf + ":" + res.doseTmInfo.dinerTmMmAf
            }

            if(res.doseTmInfo.bedTmHh?.isEmptyVal() && res.doseTmInfo.bedTmMm?.isEmptyVal()){
                this.hisInfo.dsBedBfTm = res.doseTmInfo. bedTmHh+ ":" + res.doseTmInfo.bedTmMm
            }

            this.hisInfo.bnftOfrRecHisPk = 0

            if(res.doseReclist.length > 0){
                this.doseReclist = res.doseReclist

                this.doseReclist.forEach(e => {    
                    e.doseRecPk = 0                                    
                    //아침
                    if(e.dsBrkfsBf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsBfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    if(e.dsBrkfsAf == "1"){
                        if(e.brkfsTmHh?.isEmptyVal() && e.brkfsTmMm?.isEmptyVal()){
                            this.hisInfo.dsBrkfsAfTm = e.brkfsTmHh + ":" + e.brkfsTmMm
                        }
                    }
    
                    //점심
                    if(e.dsLunchBf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchBfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    if(e.dsLunchAf == "1"){
                        if(e.lunchTmHh?.isEmptyVal() && e.lunchTmMm?.isEmptyVal()){
                            this.hisInfo.dsLunchAfTm = e.lunchTmHh + ":" + e.lunchTmMm
                        }
                    }
    
                    //저녁
                    if(e.dsDinerBf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerBfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
    
                    if(e.dsDinerAf == "1"){
                        if(e.dinerTmHh?.isEmptyVal() && e.dinerTmMm?.isEmptyVal()){
                            this.hisInfo.dsDinerAfTm = e.dinerTmHh + ":" + e.dinerTmMm
                        }
                    }
                    
                    //취침전
                    if(e.dsBedBf == "1"){
                        if(e.bedTmHh?.isEmptyVal() && e.bedTmMm?.isEmptyVal()){
                            this.hisInfo.dsBedBfTm = e.bedTmHh + ":" + e.bedTmMm
                        }
                    }
    
                    //필요시
                    if(e.dsNeed == "1"){
                        if(e.needTmHh?.isEmptyVal() && e.needTmMm?.isEmptyVal()){
                            this.hisInfo.dsNeedTm = e.needTmHh + ":" + e.needTmMm
                        }
                    }    
                });
                
            }else{
                this.getPreDoseInfList()
            }
        },

        getDoseInfList(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            } 
            
            selDoseInfList(obj)
            .then((response) => ( this.getDoseInfListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getPreDoseInfList(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD:this.preymd,
            } 
            
            selDoseInfList(obj)
            .then((response) => ( this.getDoseInfListAfter(response.data)))
            .catch((error) => console.log(error))
        },
        getDoseInfListAfter:function(res){
            this.doseReclist.splice(0)

            res.forEach(e => {                    
                let item = {
                    doseRecPk:0,
                    doseInfPk:e.doseInfPk,
                    doseDt:'',
                    drugNm:e.drugNm,
                    drugAmt:e.drugAmt,
                    drugMthd:e.drugMthd,
                    dsBrkfsBf:e.dsBrkfsBf,
                    dsBrkfsAf:e.dsBrkfsAf,
                    dsLunchBf:e.dsLunchBf,
                    dsLunchAf:e.dsLunchAf,
                    dsDinerBf:e.dsDinerBf,
                    dsDinerAf:e.dsDinerAf,
                    dsBedBf:e.dsBedBf,
                    dsNeed:'2',
                }
                this.doseReclist.push(item)                                          
            });
        },
        
        addDrugInf:function(list){
            list.forEach(item => {                
                this.doseReclist.push({
                    drugInfPk:item.drugInfPk,
                    doseInfPk:0,
                    doseRecPk:0,doseDt:'',
                    drugNm:item.drugNm,drugAmt:'',drugMthd:item.drugMthd,
                    dsBrkfsBf:'2',dsBrkfsAf:'2',
                    dsLunchBf:'2',dsLunchAf:'2',dsDinerBf:'2',dsDinerAf:'2',dsBedBf:'2',dsNeed:'2',
                })
            });
        },
        delList:function(idx,type){
            if(type == "subRec1"){
                if(this.doseReclist[idx].doseRecPk > 0){
                    this.dialogDelete=true
                    this.idx = idx
                    this.type= type
                    this.delTitle = "투약상황기록" 
                }else{
                    this.doseReclist.splice(idx,1)                
                }
            }
           
        },
        delAll:function(){
            this.type= 'all'
            this.delTitle = "투약관리"
            this.dialogDelete=true
        },
        getEmpInfo: function (obj) {
            this.hisInfo.wrtrGr[this.empidx] = obj[0].name
            this.hisInfo.wrtrGrMmbrPk[this.empidx] = obj[0].mmbrPk
        },
        onMdctnLvnRm:function(){
            this.mdctnLvnRmMdlPk = 1
            this.dialog.splice(2, 1, true)
        },                
        onMdctnPrscr:function(){
            this.$emit("onMdctnPrscr")
        }
    },
    data: () => ({
        preymd : '',
        getPreData:true,
        empidx:0,
        idx:0,
        type:'',
        addType:'',
        delTitle:'투약관리',
        forceRender:0,
        chkAll:false,
        subTab:0,      
        doseStrt:'', 
        doseEnd:'',
        doseItems:[],
        hisInfo:{
            bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',},
        dummyHisInfo:{
            bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',},
        doseReclist:[{
            doseRecPk:0,doseInfPk:0,doseDt:'',drugNm:'',drugAmt:'',drugMthd:'',dsBrkfsBf:'2',dsBrkfsAf:'2',
            dsLunchBf:'2',dsLunchAf:'2',dsDinerBf:'2',dsDinerAf:'2',dsBedBf:'2',dsNeed:'2',
        }],
        mdctnLvnRmMdlPk:-1,
        menu:[false,false,false,false,],
        menu2:[false,false,false,false,],
        menu3:[false,false,false,false,],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
        dialog:[false,false,false]
    }),
}
</script>

<style>
    .mdctnField .v-input__control .v-input__slot { min-height: 24px !important; font-size:0.9rem !important;}
</style>