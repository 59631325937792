<template>
    <div class="d-flex justify-center align-center">
        <v-btn
            class="mr-1" color="btnBrdrColor"
            style="background-color: white; width:26px;" height="26"
            @click="isActive(-1, 'left')" :disabled="disabled"
            x-small outlined>
            <span class="black--text">
                <v-icon>mdi-chevron-left</v-icon>
            </span>
        </v-btn>
        <v-btn
            class="" color="btnBrdrColor"
            style="background-color: white; width:26px;" height="26"
            @click="isActive(1, 'right')" :disabled="disabled"
            x-small outlined>
            <span class="black--text">
                <v-icon>mdi-chevron-right</v-icon>
            </span>
        </v-btn>
        <span class="text-h5 font-weight-bold topTitleColor--text mx-3 pb-1">{{ dotDate }}</span>
        <v-menu
            ref="menu" v-model="menu"
            :close-on-content-click="false" :return-value.sync="hypDate"
            transition="scale-transition" offset-y min-width="auto" dense>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="monthBtnPicker pa-0" color="btnBrdrColor" height="26"
                    style="min-width: 32px !important; background-color: white;"
                    v-bind="attrs" v-on="on" :disabled="disabled"
                    small outlined>
                    <v-icon class="black--text mt-0" small>mdi-calendar-month</v-icon>
                </v-btn>
            </template>
            <v-date-picker
                v-model="hypDate"
                class="cmmDtPckr" locale="en-US"
                :weekday-format="getDay" :month-format="getMonth" :header-date-format="getTitle"
                :key="forceRender" :max="lmtNxtDt"
                @click:date="datePickClose"
                no-title scrollable>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>

export default {
    name: 'expandNowDatePicker',

    props : {
        prtDate: { type: String, default: '' },
        lmtNxtDt: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
    },
    
    components: {
   
    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            if(this.prtDate == '')
                this.date = this.$moment().format('YYYYMMDD')
            else if(this.prtDate != '')
                this.date = this.prtDate.substr(0, 4) + '' + this.prtDate.substr(4, 2) + '' + this.prtDate.substr(6, 2)
        })
    },

    watch: {
        'date': function() {
            let obj = {}
            this.dotDate = this.$moment(this.date, 'YYYYMMDD', true).format('YYYY.MM.DD')
            this.hypDate = this.$moment(this.date, 'YYYYMMDD', true).format('YYYY-MM-DD')

            obj.date = this.date
            obj.action = this.action
            this.$emit('nowDate', obj)
        },
        'hypDate': function() {
            this.date = this.$moment(this.hypDate, 'YYYY-MM-DD', true).format('YYYYMMDD')
            this.dotDate = this.$moment(this.date, 'YYYYMMDD', true).format('YYYY.MM.DD')
            this.action = 'picker'
        },
        'prtDate': function() {
            if(this.prtDate != '')
                this.date = this.prtDate
        },
    },

    methods: {
        getTitle: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)

            if(date.length == 4)
                return new Date(date).getFullYear() + '년'
            else
                return new Date(date).getFullYear() + '년 ' + monthName[i]
        },
        getMonth: function(date) {
            let monthName = [
                '1월',
                '2월',
                '3월',
                '4월',
                '5월',
                '6월',
                '7월',
                '8월',
                '9월',
                '10월',
                '11월',
                '12월',
            ];

            let i = new Date(date).getMonth(date)
            return monthName[i]
        },
        getDay: function (date) {
            const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
            let i = new Date(date).getDay(date)
            return daysOfWeek[i]
        },
        isActive: function (val, act){
            if(val == -1)
                this.date = this.$moment(this.hypDate, 'YYYY-MM-DD').subtract(1, 'days').format('YYYYMMDD')
            else if(val == 1){

                if(this.lmtNxtDt != ''){
                    let chc = this.$moment(this.hypDate, 'YYYY-MM-DD').isSameOrAfter(this.lmtNxtDt)
                    if(!chc)
                        this.date = this.$moment(this.hypDate, 'YYYY-MM-DD').add(1, 'days').format('YYYYMMDD')
                }
                else
                    this.date = this.$moment(this.hypDate, 'YYYY-MM-DD').add(1, 'days').format('YYYYMMDD')

            }

            this.action = act
        },
        datePickClose: function () {
            this.menu = false
            this.$refs.menu.save(this.hypDate)
        },
    },

    data: () => ({
        menu: false,
        date: '',
        dotDate: '',
        hypDate: '',
        action: '',
        forceRender: 0,
    }),
};
</script>