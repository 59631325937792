<!-- 
※ 직원 관리에서 사용되는 아이콘을 구분 값에 따라 표시하는 컴포넌트
    - wrkTyp: 근무코드, vctnTyp: 휴가코드, wrkTmHh: 근무, 휴가 코드가 중복일 경우만 사용
-->
<template>
    <v-icon
        class="cndrIcon" :size="size" :color="color">
        {{ iconTyp }}
    </v-icon>
</template>

<script>

export default {
    name: 'CmmWrkIcon',

    components: {
  
    },

    props : {
        size: { type: Number, default: 20 },
        color: { type: String, default: 'black' },
        wrkTypCd: { type: String, default: '' },
        vctnTypCd: { type: String, default: '' },
        wrkTmHhStrt: { type: String, default: '' },
    },

    computed:{

    },

    watch:{

    },

    created: function(){

    },

    mounted: function(){
        this.$nextTick(function () {
            this.icnChc()
        })
    },

    methods: {
        icnChc: function () {
            // console.log(this.wrkTypCd + ',  ' + this.vctnTypCd + ',  ' + this.wrkTmHhStrt)
            let tmTyp = ''
            if(Number(this.wrkTmHhStrt) < 18 && this.wrkTypCd == '1')
                tmTyp = 'am'
            else if(Number(this.wrkTmHhStrt) > 17 && this.wrkTypCd == '1')
                tmTyp = 'pm'

            let idx = this.wrkIcon.findIndex( v => v.wrkTyp == this.wrkTypCd && v.vctnTyp == this.vctnTypCd && v.tm == tmTyp )
            if(idx > -1){
                this.iconTyp = this.wrkIcon[idx].icon
            }
            else
                this.iconTyp = 'mdi-alert-rhombus-outline'
        },
    },

    data: () => ({
        iconTyp: '',
        wrkIcon: [
            { wrkTyp: '1', vctnTyp: '', tm: 'am', icon: 'mdi-white-balance-sunny' },
            { wrkTyp: '1', vctnTyp: '', tm: 'pm', icon: 'mdi-weather-night' },
            { wrkTyp: '2', vctnTyp: '1', tm: '', icon: 'mdi-umbrella-beach-outline' },
            { wrkTyp: '2', vctnTyp: '2', tm: '', icon: 'mdi-alarm-off' },
            { wrkTyp: '2', vctnTyp: '3', tm: '', icon: 'mdi-flower' },
            { wrkTyp: '2', vctnTyp: '4', tm: '', icon: 'mdi-flower' },
            { wrkTyp: '2', vctnTyp: '5', tm: '', icon: 'mdi-baby' },
            { wrkTyp: '2', vctnTyp: '6', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '7', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '8', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '9', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '10', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '11', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '12', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '13', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '14', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '15', tm: '', icon: 'mdi-ghost' },
            { wrkTyp: '2', vctnTyp: '16', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '17', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '18', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '19', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '20', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '21', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '22', tm: '', icon: 'mdi-ghost-outline' },
            { wrkTyp: '2', vctnTyp: '24', tm: '', icon: 'mdi-pill' },
            { wrkTyp: '2', vctnTyp: '25', tm: '', icon: 'mdi-airplane' },
            { wrkTyp: '2', vctnTyp: '26', tm: '', icon: 'mdi-face-mask-outline' },
            { wrkTyp: '2', vctnTyp: '27', tm: '', icon: 'mdi-face-mask-outline' },
            { wrkTyp: '2', vctnTyp: '28', tm: '', icon: 'mdi-airplane' },
            { wrkTyp: '2', vctnTyp: '29', tm: '', icon: 'mdi-virus-outline' },
            { wrkTyp: '2', vctnTyp: '30', tm: '', icon: 'mdi-needle' },
            { wrkTyp: '2', vctnTyp: '90', tm: '', icon: 'mdi-alarm-plus' },
            { wrkTyp: '2', vctnTyp: '91', tm: '', icon: 'mdi-alarm-plus' },
            { wrkTyp: '2', vctnTyp: '999', tm: '', icon: 'mdi-swap-horizontal-circle-outline' },
            { wrkTyp: '3', vctnTyp: '', tm: '', icon: 'mdi-school-outline' },
            { wrkTyp: '4', vctnTyp: '', tm: '', icon: 'mdi-car-side' },
            { wrkTyp: '5', vctnTyp: '', tm: '', icon: 'mdi-text-box-outline' },
            { wrkTyp: '6', vctnTyp: '', tm: '', icon: 'mdi-baby-bottle-outline' },
            { wrkTyp: '7', vctnTyp: '', tm: '', icon: 'mdi-clipboard-pulse-outline' },
            { wrkTyp: '8', vctnTyp: '', tm: '', icon: 'mdi-human-pregnant' },
            { wrkTyp: '9', vctnTyp: '', tm: '', icon: 'mdi-clipboard-pulse-outline' },
            { wrkTyp: '10', vctnTyp: '', tm: '', icon: 'mdi-airplane' },
            { wrkTyp: '11', vctnTyp: '', tm: '', icon: 'mdi-human-female-boy' },
            { wrkTyp: '12', vctnTyp: '', tm: '', icon: 'mdi-medical-cotton-swab' },
            { wrkTyp: '13', vctnTyp: '', tm: '', icon: 'mdi-school-outline' },
            { wrkTyp: '14', vctnTyp: '', tm: '', icon: 'mdi-clipboard-pulse-outline' },
            { wrkTyp: '', vctnTyp: '', tm: '', icon: 'mdi-alert-rhombus-outline' },
        ],
    }),
};
</script>