<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position: relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0 justify-left align-center" no-gutters>
            <v-col class="" cols="2">
                <span class="text-h4 font-weight-bold">근무일정&nbsp;작성</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-center align-center" cols="7">
                <v-icon
                    class="cndrIcon" style="padding: 0 0 2px 0;" size="18" color="black">
                    mdi-white-balance-sunny
                </v-icon>
                <span class="font-weight-medium fontOneRem mr-6">주간근무</span>
                <v-icon
                    class="cndrIcon" style="padding: 0 0 2px 0;" size="18" color="black">
                    mdi-weather-night
                </v-icon>
                <span class="font-weight-medium fontOneRem mr-6">야간근무</span>
                <v-icon
                    class="cndrIcon" style="padding: 0 0 2px 0;" size="18" color="black">
                    mdi-umbrella-beach-outline
                </v-icon>
                <span class="font-weight-medium fontOneRem mr-6">연차</span>
                <v-icon
                    class="cndrIcon" style="padding: 0 0 2px 0;" size="18" color="black">
                    mdi-alarm-off
                </v-icon>
                <span class="font-weight-medium fontOneRem mr-6">반차</span>
                <v-icon
                    class="cndrIcon" style="padding: 0 0 2px 0;" size="18" color="black">
                    mdi-alarm-plus
                </v-icon>
                <span class="font-weight-medium fontOneRem mr-6">유급휴일근무</span>
                <v-icon
                    class="cndrIcon" style="padding: 0 0 2px 0;" size="18" color="black">
                    mdi-swap-horizontal-circle-outline
                </v-icon>
                <span class="font-weight-medium fontOneRem mr-6">대체휴무</span>
                <v-icon
                    class="cndrIcon" style="padding: 0 0 2px 0;" size="18" color="black">
                    mdi-text-box-outline
                </v-icon>
                <span class="font-weight-medium fontOneRem">공가</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end" cols="2">
                <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-2" no-gutters>
            <v-col class="" style="padding-top: 5px;" cols="3">
                <!-- 좌측 메뉴 -->
                <span
                    class="d-inline-block d-flex rounded mb-2"
                    style="border: 2px solid white; padding: 2px 0px; height: 32px;">
                    <v-btn
                        class="ml-1" color="blueBtnColor"
                        style="padding: 9px 8px 8px 8px !important"
                        min-width="30" height="25"
                        @click="hldyAplyMdl = true"
                        small outlined>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="14">mdi-calendar-start-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">대체휴일관리</span>
                                </div>
                            </template>
                            <span>유급휴일 등록 및 삭제</span>
                        </v-tooltip>
                    </v-btn>
                    <span class="ml-3">
                        <v-btn
                            class="" width="18" height="18"
                            @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 30)"
                            icon>
                            <v-icon class="pb-1" size="18">mdi-help-circle-outline</v-icon>
                        </v-btn>
                    </span>
                </span>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">직원명</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem">{{ empInf.name }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">입사일</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem">{{ $moment(empInf.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">담당직종</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem">{{ empInf.jobCdNm }}</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">잔여연차</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem">{{ empInf.anlDys }}일</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">유급휴일대체</span>
                        <span class="ml-1">
                            <v-btn
                                class="" width="18" height="18"
                                @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 29)"
                                icon>
                                <v-icon class="pb-1" size="18">mdi-help-circle-outline</v-icon>
                            </v-btn>
                        </span>
                    </v-col>
                    <v-col class="d-flex justify-center align-center tableBL py-1" cols="7">
                        <span class="fontOneRem mr-1">{{ empInf.sbstCnt }}건</span>
                        <!-- <v-btn
                            v-if="empInf.sbstCnt > 0"
                            class="ml-1" color="blueBtnColor"
                            style="padding: 9px 8px 8px 8px !important"
                            min-width="30" height="25"
                            @click="hldyAplyMdl = true"
                            small outlined>
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="14">mdi-calendar-start-outline</v-icon>
                                        <span class="fontOneRem ml-1" style="padding-bottom: 2px;">등록</span>
                                    </div>
                                </template>
                                <span>유급휴일등록</span>
                            </v-tooltip>
                        </v-btn> -->
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-divider class="mt-3"></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">기준&nbsp;근무시간</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem font-weight-medium">{{ wrkHr }}&nbsp;시간</span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="greyE01 textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">작성된&nbsp;근무시간</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem font-weight-medium red--text">
                            {{ Math.floor(wrkSchdlList.filter( v => $moment(v.start, 'YYYY-MM-DD').format('YYYYMM') == $moment(start, 'YYYY-MM-DD').format('YYYYMM') ).reduce((acc, cur) => { return acc += cur.actWrkHr }, 0) / 60) }}시간
                        </span>
                        <span class="fontOneRem font-weight-medium red--text">
                            {{ wrkSchdlList.reduce((acc, cur) => { return acc += cur.actWrkHr }, 0) % 60 }}분
                        </span>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-0 mt-5 align-center" no-gutters>
                    <v-icon style="font-size: 20px; padding-top: 2px;" color="black">mdi-chevron-down</v-icon>
                    <span class="text-h6 ml-1">근무정보</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-1" color="#FB8C00" min-width="30" height="25"
                        style="padding: 11px 10px 10px 8px !important; margin-top: 1px;"
                        @click="clndrSchdlAply()"
                        small outlined>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    <v-icon size="20" style="margin-top: 2px;">mdi-calendar-edit-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">반영</span>
                                </div>
                            </template>
                            <span>근무정보 달력에 추가</span>
                        </v-tooltip>
                    </v-btn>            
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">패턴</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem font-weight-bold">색상</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pa-1" cols="5">
                        <v-select
                            v-model="sltWrkPtnPk"
                            class="mdclSelect" height="28"
                            item-text="wrkPtnNm" item-value="wrkPtnPk"
                            @change="ptnChgFnc" :items="wrkPtnList"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="tableBL px-1 pt-1" cols="7">
                        <span class="d-inline-block" style="width: 110px;">
                            <v-text-field
                                v-model="wrkFrmInf.wrkPtnColr"
                                class="ma-0 pa-0 colorFiled"
                                readonly hide-details outlined dense>
                                <template v-slot:append>
                                    <v-menu
                                        v-model="clrMdl" :close-on-content-click="false"
                                        nudge-bottom="210" nudge-left="-30"
                                        top>
                                        <template v-slot:activator="{ on }">
                                            <div
                                                class="rounded crsPntr" v-on="on"
                                                :style="{ backgroundColor: wrkFrmInf.wrkPtnColr, width: '20px', height: '20px', margin: '1px 0 0 0' }">
                                            </div>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0">
                                                <v-color-picker
                                                    v-model="clrObj"
                                                    mode="hexa"
                                                    :swatches="swatches"
                                                    @update:color="rflClrPicker"
                                                    hide-mode-switch show-swatches>
                                                </v-color-picker>
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-divider class="mt-2"></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="textCenter py-1" cols="5">
                        <span class="fontOneRem font-weight-bold">근무형태</span>
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="7">
                        <span class="fontOneRem font-weight-bold">휴가종류</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pa-1" cols="5">
                        <v-select
                            v-model="wrkFrmInf.wrkTypCd"
                            class="mdclSelect" height="28" item-text="valcdnm" item-value="valcd"
                            :items="$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd73"
                            @change="wrkFrmInf.vctnTypCd = ''"
                            dense hide-details outlined>
                        </v-select>
                    </v-col>
                    <v-col class="tableBL pa-1" cols="7">
                        <span v-if="wrkFrmInf.wrkTypCd == '2'">
                            <v-select
                                v-model="wrkFrmInf.vctnTypCd"
                                class="mdclSelect" height="28" item-text="valcdnm" item-value="valcd"
                                :items="$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd107"
                                dense hide-details outlined>
                            </v-select>
                        </span>
                    </v-col>
                </v-row>
                <v-divider
                    v-if="wrkFrmInf.vctnTypCd == '999' && wrkFrmInf.wrkTypCd == '2'"
                    class="">
                </v-divider>
                <v-row
                    v-if="wrkFrmInf.vctnTypCd == '999' && wrkFrmInf.wrkTypCd == '2'"
                    class="pa-0"
                    no-gutters>
                    <v-col class="pa-1" cols="5">
                    </v-col>
                    <v-col class="tableBL pa-1" cols="7">
                        <span class="d-inline-block" style="width: 133px;">
                            <CmmDateComp v-model="wrkFrmInf.vctnWrkDt" :required="false"></CmmDateComp>
                        </span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-divider class="mt-2"></v-divider>
                <v-row class="greyE01 pa-0" no-gutters>
                    <v-col class="d-flex justify-center align-center px-4 py-1" cols="6">
                        <span class="fontOneRem font-weight-bold">근무시간</span>
                        <!-- <v-spacer></v-spacer>
                        <span class="d-inline-block" style="width: 65px; padding-bottom: 1px;">
                            <v-checkbox
                                class="nmlyBox"
                                v-model="wrkFrmInf.wrkFullTm" :ripple="false"
                                label="종일근무" true-value="1" false-value="2"
                                hide-details>
                            </v-checkbox>
                        </span> -->
                    </v-col>
                    <v-col class="textCenter tableBL py-1" cols="6">
                        <span class="fontOneRem font-weight-bold">휴게시간&nbsp;&#40;&nbsp;주&nbsp;&#47;&nbsp;야&nbsp;&#41;</span>
                    </v-col>
                </v-row>
                <v-divider class=""></v-divider>
                <v-form ref="shdlInf" lazy-validation>
                    <v-row class="pa-0" no-gutters>
                        <v-col class="pa-1" cols="6">
                            <span class="white d-inline-block" style="width: 59px;">
                                <CmmTimeField v-model="wrkFrmInf.wrkTmStrt" :required="true" focusClear></CmmTimeField>                               
                            </span>
                            <span class="mx-1">
                                <v-icon class="greyE00--text" style="margin-bottom: 1px;" size="16">mdi-tilde</v-icon>
                            </span>
                            <span class="white d-inline-block" style="width: 59px;">
                                <CmmTimeField v-model="wrkFrmInf.wrkTmEnd" :required="true" focusClear></CmmTimeField>                               
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-center tableBL pa-1" cols="6">
                            <span class="d-inline-block" style="width: 62px;">
                                <v-text-field
                                    v-model="wrkFrmInf.brkDay"
                                    type="number" @focus="wrkFrmInf.brkDay = ''"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                            <span class="mx-2">&#47;</span>
                            <span class="d-inline-block" style="width: 62px;">
                                <v-text-field
                                    v-model="wrkFrmInf.brkNgt"
                                    type="number" @focus="wrkFrmInf.brkNgt = ''"
                                    hide-details outlined dense>
                                </v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                </v-form>
                <div class="d-inline-block" style="height: 1px;"></div>
                <v-row class="pa-0" no-gutters>
                    <v-col class="pr-1" cols="6">
                        <v-btn
                            class="py-5" width="100%" color="#2a9bed"
                            @click="preInsEmpWrkSchdl()"
                            :disabled="wrkSchdlList.length == 0">
                            <v-icon class="white--text" style="padding-top: 1px;" size="22">mdi-content-save-outline</v-icon>
                            <span
                                :class="['ml-2', wrkSchdlList.length == 0 ? 'grey005--text' : 'white--text' ]"
                                style="font-size: 1.3rem;">
                                저장
                            </span>
                        </v-btn>
                    </v-col>
                    <v-col class="pl-1" cols="6">
                        <v-btn
                            class="py-5" width="100%" color="#666"
                            @click="rmMdl = true"
                            :disabled="$refs.cmmClndr?.getSltBoxList().length == 0">
                            <v-icon class="white--text" style="padding-top: 1px;" size="22">mdi-trash-can-outline</v-icon>
                            <span
                                :class="['ml-2', $refs.cmmClndr?.getSltBoxList().length == 0 ? 'grey005--text' : 'white--text' ]"
                                style="font-size: 1.3rem;">
                                삭제
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="px-2" cols="9">
                <!-- 우측 달력 -->
                <CmmClndr
                    ref="cmmClndr"
                    :clndrHght="'622'" :hdrTyp="'chcSgl'" :vsbYn="!savBtn"
                    :intlDt="sltYyMm" :clndrList="wrkSchdlList" :atvDt="empInf.entDt"
                    @chgDtAct="getPickerDate" @rmEvtExstPk="rmSnglWrkSchdl" @rmEvtNonePk="rmvSngSchdl"
                    @refresh="rclngWrkShdl" @paidHldy="rclngPaidHldy" >
                </CmmClndr>
            </v-col>
        </v-row>
        <v-dialog v-model="rmMdl" max-width="500">
            <btnModalDelete :title="$parent.$parent.$parent.$parent.shdlRmTtl" @madalState="delModalAct"></btnModalDelete>  
        </v-dialog>
        <v-dialog v-model="hldyMdl" max-width="700">
            <PaidHldyMdl v-if="hldyMdl" :wrkHldyInf="wrkHldyInf" @isClose="hldyMdl = false" ></PaidHldyMdl>
        </v-dialog>
        <v-dialog v-model="hldyAplyMdl" max-width="700">
            <PaidHldyAplyMdl
                v-if="hldyAplyMdl"
                :mmbrPk="mmbrPk" :empPk="empPk" :yyyy="$moment(start, 'YYYY-MM-DD').format('YYYY')"
                @isClose="hldyAplyMdl = false">
            </PaidHldyAplyMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import {
    selEmpSchdlInfo,
    selStdWrkHrInfo,
    insEmpWrkSchdlMulti,
    delEmpWrkSchdl,
    delMultiEmpWrkSchdl
    } from '../../api/emp.js';

import CmmClndr from '../../components/commons/CmmClndr.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import PaidHldyMdl from './PaidHldyMdl.vue';
import PaidHldyAplyMdl from './PaidHldyAplyMdl.vue';

export default {
    name: 'DlySchdlRgstr',

    props : {
        empPk: { type: Number, default: 0 },
        mmbrPk: { type: Number, default: 0 },
        sltYyMm: { type: String, default: '' },
        ptnList: { type: Array, default: () => { return [] } },
    },
        
    components: {
        CmmClndr,
        CmmTimeField,
        CmmDateComp,
        btnModalDelete,
        PaidHldyMdl,
        PaidHldyAplyMdl,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.wrkPtnList.splice(0)
            this.wrkPtnList = Object.assign([], this.ptnList)
            this.wrkPtnList.unshift( { wrkPtnPk: 0, wrkPtnNm: '직접입력' } )
        })
    },

    computed: {

    },

    watch:{
        'empPk': function () {
            this.getEmpSchdlInf( this.sltYyMm, this.mmbrPk, this.empPk )
            this.getWrkHr( this.sltYyMm )
        },
        'sltYyMm': function () {
            this.getEmpSchdlInf( this.sltYyMm, this.mmbrPk, this.empPk )
            this.getWrkHr( this.sltYyMm )
        },
        'ptnList': function () {
            this.wrkPtnList.splice(0)
            this.wrkPtnList = Object.assign([], this.ptnList)
            this.wrkPtnList.unshift( { wrkPtnPk: 0, wrkPtnNm: '직접입력' } )
        },
    },
    
    methods: {
        // 직원 기본정보 조회
        getEmpSchdlInf: function (yymm, mPk, ePk) {
            this.wrkSchdlList.splice(0)

            selEmpSchdlInfo(this.$store.getters.getFcltyNum, yymm, mPk, ePk)
                .then((response) => ( this.empSchdlInfRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpSchdlInfo : ' + error))
        },

        // 기준 근무시간 조회
        getWrkHr: function (yymm) {
            selStdWrkHrInfo(yymm)
            .then((response) => ( this.wrkHrRst(response.data) ))
            .catch((error) => console.log('connect error /impExp/selStdWrkHrInfo : ' + error))
        },
        
        // 근무일정 다건 등록
        setInsWrkSchdl: function (list) {
            this.wrkSchdlList.splice(0)

            insEmpWrkSchdlMulti(list)
                .then((response) => ( this.setWrkSchdlRst(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpWrkSchdlMulti : ' + error))
        },

        // 근무일정 단건 삭제
        rmSnglWrkSchdl: function (obj) {
            this.savBtn = false

            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm

            delEmpWrkSchdl(obj)
                .then((response) => ( this.setWrkSchdlRst(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmpWrkSchdl : ' + error))
        },

        // 근무일정 다건 삭제
        rmMultiWrkSchdl: function (obj) {
            this.wrkSchdlList.splice(0)

            delMultiEmpWrkSchdl(obj)
                .then((response) => ( this.setWrkSchdlRst(response.data) ))
                .catch((error) => console.log('connect error /emp/delMultiEmpWrkSchdl : ' + error))
        },

        empSchdlInfRst: function (res) {
            if(Object.keys(res.anlUseInfo).length > 0)
                this.empInf.anlDys = res.anlUseInfo.anlDys
            if(Object.keys(res.empInfo).length > 0)
                this.setEmpInf(res.empInfo)
            if(res.schlList.length > 0)
                this.cvtClndrFrmList(res.schlList)

            this.empInf.sbstCnt = res.vctnSbstCnt

            this.savBtn = true
        },

        wrkHrRst: function (res) {
            if(typeof res.stdWrkHr != 'undefined')
                this.wrkHr = res.stdWrkHr
            else
                this.wrkHr = ''
        },

        setWrkSchdlRst: function (res) {
            let trgDt = this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM')

            if(res.statusCode == 200){
                this.snackControll([true, 2500, res.message, 'info'])

                this.getEmpSchdlInf( trgDt, this.mmbrPk, this.empPk )
                this.getWrkHr( trgDt )

                this.clearForm()
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },

        // 달력에 반영된 정보 저장
        preInsEmpWrkSchdl: function () {
            this.savBtn = false

            let rst = []

            this.wrkSchdlList.forEach(seed => {
                seed.fcltyNum = this.$store.getters.getFcltyNum
                seed.userNm = this.$store.getters.getUserNm
                seed.empPk = this.empPk
                seed.mmbrPk= this.mmbrPk
                
                if(seed.vctnWrkDt != '' && seed.vctnWrkDt != null)
                    seed.vctnWrkDt = this.$moment(seed.vctnWrkDt, 'YYYY-MM-DD').format('YYYYMMDD')

                rst.push(seed)
            });
            
            if(rst.length > 0)
                this.setInsWrkSchdl(rst)
            else
                this.snackControll([true, 2000, '달력에 반영된 일정이 없습니다.', 'warning'])
        },

       // 스케쥴 삭제
       rmEmpWrkSchdl: function () {
            this.savBtn = false

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.userNm = this.$store.getters.getUserNm
            obj.inqYYMM = this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM')
            obj.mmbrPks = []
            obj.wrkDts = []
            obj.list = []

            obj.mmbrPks.push(this.mmbrPk)

            this.$refs.cmmClndr.getSltBoxList().forEach(itm => {
                let oObj = {}
                oObj.inqYMD = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD')
                obj.list.push(oObj)

                obj.wrkDts.push(this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD'))
            });

            if(obj.mmbrPks.length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '대상 직원 정보가 없습니다.', 'info'])
                return
            }
            if(obj.list.length == 0){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2000, '지정된 날짜가 없습니다.', 'info'])
                return
            }

            this.rmMultiWrkSchdl(obj)
       },

        // 근무 정보 달력 반영
        clndrSchdlAply: function () {
            let clndrList = this.$refs.cmmClndr.getSltBoxList()
      
            // 예외사항 처리
            if(clndrList.length == 0){
                this.snackControll([true, 2000, '날짜가 선택되지 않았습니다.', 'info'])
                return
            }
            if( this.$refs.shdlInf.validate() == false ){
                this.snackControll([true, 2000, '근무 시간을 올바르게 입력해주세요.', 'info'])
                return
            }
            // if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.wrkFrmInf.wrkTmStrt) == false){
            //     this.snackControll([true, 2000, '근무정보에 누락된 항목을 확인해주세요.', 'info'])
            //     return
            // }
            // if(/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(this.wrkFrmInf.wrkTmEnd) == false){
            //     this.snackControll([true, 2000, '근무정보에 누락된 항목을 확인해주세요.', 'info'])
            //     return
            // }
            if(this.wrkFrmInf.wrkTypCd == '2' && (this.wrkFrmInf.vctnTypCd == '' || typeof this.wrkFrmInf.vctnTypCd == 'undefined')){
                this.snackControll([true, 2000, '휴가종류를 선택해주세요.', 'info'])
                return
            }
            if(this.wrkFrmInf.wrkTypCd == '2' && this.wrkFrmInf.vctnTypCd == '999' && this.wrkFrmInf.vctnWrkDt == ''){
                this.snackControll([true, 2000, '대체휴가 날짜를 작성해 주세요.', 'info'])
                return
            }

            let failTmCnt = 0
            let failTypeCnt = 0
            clndrList.forEach(itm => {
                let oObj = Object.assign({}, this.wrkFrmInf)
                oObj.empPk = this.empPk
                oObj.mmbrPk = this.mmbrPk
                oObj.wrkDt = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYYMMDD')
                oObj.color = oObj.wrkPtnColr
                oObj.start = itm.locDate
                oObj.wrkTmHhStrt = oObj.wrkTmStrt.split(':', 2)[0]
                oObj.wrkTmMmStrt = oObj.wrkTmStrt.split(':', 2)[1]
                oObj.wrkTmHhEnd = oObj.wrkTmEnd.split(':', 2)[0]
                oObj.wrkTmMmEnd = oObj.wrkTmEnd.split(':', 2)[1]

                if(oObj.brkDay == '')
                    oObj.brkDay = 0
                if(oObj.brkNgt == '')
                    oObj.brkNgt = 0
                
                let today = this.$moment().format('YYYYMMDD')
                let tm1 = this.$moment(today + ' ' + oObj.wrkTmStrt, 'YYYYMMDD hh:mm')

                // 익일근무, 종일근무
                if(this.$moment(oObj.wrkTmStrt, 'hh:mm').diff(this.$moment(oObj.wrkTmEnd, 'hh:mm'), 'minutes') > -1){
                    let tm2 = this.$moment(oObj.wrkTmEnd, 'hh:mm').add(1, 'days')
                    oObj.actWrkHr = this.$moment.duration(tm2.diff(tm1)).asMinutes() - oObj.brkDay - oObj.brkNgt
                    oObj.wrkOvnYn = '1'
                }
                // 주간근무
                else{
                    let tm2 = this.$moment(oObj.wrkTmEnd, 'hh:mm')
                    oObj.actWrkHr = this.$moment.duration(tm2.diff(tm1)).asMinutes() - oObj.brkDay - oObj.brkNgt
                    oObj.wrkOvnYn = '2'
                }

                // 대체휴가 (근무인정없음)인 경우 근무시간 초기화
                if( oObj.wrkTypCd == '2' && oObj.vctnTypCd == '999' )
                    oObj.actWrkHr = 0

                if(oObj.wrkOvnYn == '1')
                    oObj.end = this.$moment(itm.locDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                else
                    oObj.end = this.$moment(itm.locDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                
                // 근무유형 검사 로직 제거 (20240117)
                let schdlCnt = -1
                    // 근무유형 검사 로직 원본 - 매일 유형별 한건만 등록 가능 ( ~ 20240117 )
                    // this.wrkSchdlList.findIndex(
                    //     v => v.start == oObj.start && v.wrkTypCd == oObj.wrkTypCd && v.vctnTypCd == oObj.vctnTypCd
                    // )
                if(schdlCnt == -1){
                    let trgStrt = this.$moment(oObj.start + ' ' + oObj.wrkTmStrt, 'YYYY-MM-DD hh:mm')
                    let trgEnd = this.$moment(oObj.end + ' ' + oObj.wrkTmEnd, 'YYYY-MM-DD hh:mm')

                    let schdlDtlCnt = this.wrkSchdlList.findIndex(
                        v => trgStrt.isBetween(v.start + ' ' + v.wrkTmHhStrt+':'+v.wrkTmMmStrt, v.end + ' ' + v.wrkTmHhEnd+':'+v.wrkTmMmEnd, 'minute', '[)')
                        || trgEnd.isBetween(v.start + ' ' + v.wrkTmHhStrt+':'+v.wrkTmMmStrt, v.end + ' ' + v.wrkTmHhEnd+':'+v.wrkTmMmEnd, 'minute', '(]')
                    )
                    
                    // let schdlDtlCnt = this.wrkSchdlList.findIndex(
                    //     v => v.start == oObj.start
                    //     && trgStrt.isSameOrAfter(v.start + ' ' + v.wrkTmHhStrt+':'+v.wrkTmMmStrt)
                    //     && trgStrt.isBefore(v.start + ' ' + v.wrkTmHhEnd+':'+v.wrkTmMmEnd)
                    //     )

                    // let schdlDtlNxtCnt = -1
                    // if( oObj.wrkOvnYn == '1' ){
                    //     schdlDtlNxtCnt = this.wrkSchdlList.findIndex(
                    //         v => v.start == oObj.end
                    //         && !(trgEnd.isSameOrBefore(v.start + ' ' + v.wrkTmHhStrt+':'+v.wrkTmMmStrt))
                    //         )
                    // }

                    // let schdlDtlPreCnt = this.wrkSchdlList.findIndex(
                    //     v => v.start == this.$moment(oObj.start, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD')
                    //     && !(trgStrt.isSameOrAfter(v.end + ' ' + v.wrkTmHhEnd+':'+v.wrkTmMmEnd))
                    //     )

                    if(schdlDtlCnt == -1)
                        this.wrkSchdlList.push(oObj)
                    else
                        failTmCnt++
                }
                else
                    failTypeCnt++

            });

            if(failTmCnt > 0 && failTypeCnt > 0)
                this.snackControll([true, 2500, '근무유형이 중복되는 ' + failTypeCnt + '건, 근무시간이 중복되는 ' + failTmCnt + '건을 제외하고 반영 되었습니다.', 'info'])
            else if(failTmCnt > 0 && failTypeCnt == 0)
                this.snackControll([true, 2500, '근무시간이 중복되는 ' + failTmCnt + '건을 제외하고 반영 되었습니다.', 'info'])
            else if(failTmCnt == 0 && failTypeCnt > 0)
                this.snackControll([true, 2500, '근무유형이 중복되는 ' + failTypeCnt + '건을 제외하고 반영 되었습니다.', 'info'])

        },

        // 날짜 변경 호출
        getPickerDate: function (date) {
            this.start = date
            this.rclngWrkShdl()
        },

        // 조회된 데이터에 캘런더 필요 데이터 추가
        cvtClndrFrmList: function (arr) {
            arr.forEach(itm => {
                itm.start = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')
                itm.color = itm.wrkPtnColr
                if(itm.vctnTypCd == null || itm.vctnTypCd == '0')
                    itm.vctnTypCd = ''
                if(itm.wrkOvnYn == '1')
                    itm.end = this.$moment(itm.wrkDt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')
                else
                    itm.end = this.$moment(itm.wrkDt, 'YYYYMMDD').format('YYYY-MM-DD')
            });

            this.wrkSchdlList = arr
        },
        
        // 조회된 직원 기본정보 반영
        setEmpInf: function (obj) {
            let idx =
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd42.findIndex( v => v.valcd == obj.jobCd )

            this.empInf.name = obj.name
            this.empInf.entDt = obj.entDt
            this.empInf.jobCd = obj.jobCd

            if(idx > -1)
                this.empInf.jobCdNm = 
                    this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.vCd42[idx].valcdnm
        },

        // 패턴 종류 반영
        ptnChgFnc: function (){
            let chc = this.$parent.$parent.$parent.$parent.wrkPtnList.findIndex( v => v.wrkPtnPk == this.sltWrkPtnPk )
            if( chc > -1 ){
                let sObj = this.$parent.$parent.$parent.$parent.wrkPtnList[chc]

                this.wrkFrmInf.wrkTypCd = sObj.wrkTypCd
                if(typeof sObj.vctnWrkDt == 'undefined' || sObj.vctnWrkDt == '0' || sObj.vctnWrkDt == null)
                    this.wrkFrmInf.vctnTypCd = ''
                else
                    this.wrkFrmInf.vctnTypCd = sObj.vctnTypCd

                if(typeof sObj.vctnWrkDt == 'undefined')
                    this.wrkFrmInf.vctnWrkDt = ''
                else
                    this.wrkFrmInf.vctnWrkDt = sObj.vctnWrkDt
                this.wrkFrmInf.wrkTmStrt = sObj.wrkTmHhStrt + ':' + sObj.wrkTmMmStrt
                this.wrkFrmInf.wrkTmEnd = sObj.wrkTmHhEnd + ':' + sObj.wrkTmMmEnd
                this.wrkFrmInf.wrkTmHhStrt = sObj.wrkTmHhStrt
                this.wrkFrmInf.wrkTmMmStrt = sObj.wrkTmMmStrt
                this.wrkFrmInf.wrkTmHhEnd = sObj.wrkTmHhEnd
                this.wrkFrmInf.wrkTmMmEnd = sObj.wrkTmMmEnd
                this.wrkFrmInf.brkDay = sObj.brkDay
                this.wrkFrmInf.brkNgt = sObj.brkNgt
                this.wrkFrmInf.wrkPtnColr = sObj.wrkPtnColr
            }
            else{
                this.wrkFrmInf.wrkTypCd = '1'
                this.wrkFrmInf.vctnTypCd = ''
                this.wrkFrmInf.vctnWrkDt = ''
                this.wrkFrmInf.wrkTmStrt = '09:00'
                this.wrkFrmInf.wrkTmEnd = '18:00'
                this.wrkFrmInf.wrkTmHhStrt = '09'
                this.wrkFrmInf.wrkTmMmStrt = '00'
                this.wrkFrmInf.wrkTmHhEnd = '18'
                this.wrkFrmInf.wrkTmMmEnd = '09'
                this.wrkFrmInf.brkDay = 60
                this.wrkFrmInf.brkNgt = 0
                this.wrkFrmInf.wrkPtnColr = '#000000'
            }

        },

        // 컬러 픽커 내용 반영
        rflClrPicker: function (){
            this.wrkFrmInf.wrkPtnColr = this.clrObj.hex
 
        },

        // 직원 기본정보 초기화
        clrEmpInf: function () {
            this.empInf.name = ''
            this.empInf.entDt = ''
            this.empInf.jobCd = ''
            this.empInf.jobCdNm = ''
            this.empInf.anlDys = 0
        },

        // 저장 안된 스케쥴 단일 로우 삭제
        rmvSngSchdl: function (obj) {
            let chc = this.wrkSchdlList.findIndex(
                v => v.start == obj.strtDt && ( v.wrkTmHhStrt + ':' + v.wrkTmMmStrt ) == obj.strtTm
            )
            if(chc > -1)
                this.wrkSchdlList.splice(chc, 1)
            else
                this.snackControll([true, 2000, '단일 근무 스케쥴 삭제 실패.', 'info'])
        },

        clearForm: function () {
        },

        rclngWrkShdl: function () {
            this.getEmpSchdlInf( this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM'), this.mmbrPk, this.empPk )
            this.getWrkHr( this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM') )
            this.$parent.$parent.$parent.$parent.rclngWrkSchdHrl()
        },

        rclngPaidHldy: function (obj) {
            let oObj = {}
            oObj.empWrkSchdlPk = obj.empWrkSchdlPk
            oObj.empPk = obj.empPk
            oObj.mmbrPk = obj.mmbrPk
            oObj.wrkDt = obj.wrkDt
            oObj.actWrkHr = obj.actWrkHr
            oObj.vctnSbstYn = obj.vctnSbstYn
            oObj.wrkTmHhStrt = obj.wrkTmHhStrt
            oObj.wrkTmMmStrt = obj.wrkTmMmStrt
            oObj.wrkTmHhEnd = obj.wrkTmHhEnd
            oObj.wrkTmMmEnd = obj.wrkTmMmEnd
            oObj.brkDay = obj.brkDay
            oObj.brkNgt = obj.brkNgt
            oObj.wrkOvnYn = obj.wrkOvnYn
            oObj.stdWrkHr = this.wrkHr * 60
            oObj.wrtnWrkHr = this.wrkSchdlList.filter( v => this.$moment(v.start, 'YYYY-MM-DD').format('YYYYMM') == this.$moment(this.start, 'YYYY-MM-DD').format('YYYYMM') ).reduce((acc, cur) => { return acc += cur.actWrkHr }, 0)

            this.wrkHldyInf = oObj
            this.hldyMdl = true
        },
   
        onClose: function () {
            let pkS = []
            this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.tableSelected.forEach(items => {
                pkS.push(items.mmbrPk)
            });

            if(pkS.length > 0)
                this.$parent.$parent.$parent.$parent.getWrkSchdlList(
                    this.$moment(this.$parent.$parent.$parent.$parent.start, 'YYYY-MM-DD').format('YYYYMM'), pkS
                )

            this.rclngWrkShdl()
            this.$emit('isClose', '.')
        },

        // 삭제 팝업 제어
        delModalAct: function(delMsg){
            if(delMsg == 9990)   //OK
                this.rmEmpWrkSchdl()

            this.rmMdl = false
        },

        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        hldyMdl: false,
        hldyAplyMdl: false,
        rmMdl: false,
        start: '',
        wrkHr: '',
        sltWrkPtnPk: 0,
        savBtn: true,
        clrMdl: false,
        clrObj: null,
        wrkSchdlList: [],
        wrkPtnList: [],
        wrkHldyInf: {},
        empInf: { 
            name: '', entDt: '', jobCd: '', jobCdNm: '', anlDys: 0, sbstCnt: 0,
        },
        wrkFrmInf:{
            wrkDt: '', wrkTypCd: '1', vctnTypCd: '', vctnWrkDt: '', wrkOvnYn: '2', wrkFullTm: '2',
            wrkTmStrt: '09:00', wrkTmEnd: '18:00', wrkTmHhStrt: '', wrkTmMmStrt: '', wrkTmHhEnd: '', wrkTmMmEnd: '',
            brkDay: 60, brkNgt: 0, actWrkHr: 0, wrkPtnColr: '#000000',
        },
        swatches: [
            ['#FF0000', '#AA0000', '#550000'],
            ['#FFFF00', '#AAAA00', '#555500'],
            ['#00FF00', '#00AA00', '#005500'],
            ['#00FFFF', '#00AAAA', '#005555'],
            ['#0000FF', '#0000AA', '#000055'],
        ],
        rules: {
            required: value => !!value || '필수입력 항목 입니다.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>