<template>
    <v-sheet
        class="d-flex rounded-lg rounded-l-0"
        min-width="1260" max-width="1560" height="835">
        <v-sheet
            class="d-inline-block pa-3" height="835" color="midAreaColor"
            :min-width="midAreaWidth" :max-width="midAreaWidth">
            <v-row class="pa-0" no-gutters>
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="hamletFont text-h5">
                    <span class="topTitleColor--text font-weight-black">1-7. 본인부담금&nbsp;입금관리</span>
                </span>
            </v-row>
            <v-row class="align-center mt-5 mb-3" no-gutters>
                <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
                <!-- <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" /> -->
                <v-spacer></v-spacer>
                <span class="d-inline-block" style="width: 130px;">
                    <v-text-field
                        class="ma-0 pa-0 nameSearch" height="35px"
                        background-color="white" append-icon="mdi-magnify" label="이름조회"
                        @input="setSearchText"
                        single-line rounded outlined filled hide-details dense>
                    </v-text-field>
                </span>     
            </v-row>
            <v-data-table
                class="msclnTable bnfcrMidList" height="677" locale="ko"
                :headers="headers" :items="copayDpsStsList" :search="schDummy" :custom-filter="customFilter"
                disable-pagination fixed-header hide-default-footer dense>
                <template v-slot:item='{ item }'>
                    <tr
                        :class="[
                            'fixedCol',
                            selectedItem == item.bnMmbrPk && sltBnfcrPk == item.bnfcrPk ? 'sltRow' : 'disSltRow'
                        ]"
                        @click="tableClickEvent(item)">
                        <td class="fixedCol0 txtAlgnMid" style="height:40px !important;">
                            <span class="cmmFontSizeOne">{{item.name}}</span>
                            <span class="d-inline-block" style="width:18px;"></span>
                        </td>
                        <td class="fixedCol0 txtAlgnMid" style="height:40px !important;">
                            <span class="cmmFontSizeOne">{{item.entstnm}}</span>
                            <span class="d-inline-block" style="width:18px;"></span>
                        </td>
                        <!-- <td class="fixedCol0 txtAlgnMid" style="height:40px !important;">
                            <span class="cmmFontSizeOne">{{item.dpsDt}}</span>
                            <span class="d-inline-block" style="width:18px;"></span>
                        </td> -->
                        <td class="fixedCol0 txtAlgnMid" style="height:40px !important;">
                            <span class="cmmFontSizeOne">{{item.dpsSts}}</span>
                            <span class="d-inline-block" style="width:16px;"></span>
                        </td>
                        <td class="fixedCol0 txtAlgnMid" style="height:40px !important;">
                            <span class="cmmFontSizeOne">{{item.rmnPrpym?.toLocaleString('ko-KR')}}</span>
                            <span class="d-inline-block" style="width:18px;"></span>
                        </td>
                        <td class="fixedCol0 txtAlgnMid" style="height:40px !important;">
                            <span class="cmmFontSizeOne">{{item.unpdAmt?.toLocaleString('ko-KR')}}</span>
                            <span class="d-inline-block" style="width:12px;"></span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
        <router-view ref="copayDpstDetail"></router-view>
    </v-sheet>
</template>

<script>
// import exBtn from '../../components/commons/expandButtonCustom.vue';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

import { selCopayDpsStsList } from '../../api/bnfcr.js';

import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'PayLevy',
    
    components: {
        // exBtn,
        expandYearOnlyPicker,
    },

    props : {

    },

    created: function(){
        this.getCode(['62', '84'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    watch: {
        'sltBnfcrPk': function () {
            if(this.sltBnfcrPk == -1 ){
                this.$store.commit('setBnfcrPk', 0)
                this.$store.commit('setMmbrPk', 0)
   
                this.$refs.copayDpstDetail.copayDpsStsInfo.rmnPrpym = 0
                this.$refs.copayDpstDetail.copayDpsStsInfo.unpdAmt = 0
                this.$refs.copayDpstDetail.copayDpsStsInfo.grdName = ''
                this.$refs.copayDpstDetail.copayDpsList.splice(0)
                this.$refs.copayDpstDetail.copayPrcList.splice(0)
                this.$refs.copayDpstDetail.lodnArr.splice(0, 1, false)
                this.$refs.copayDpstDetail.lodnArr.splice(1, 1, false)
            }
            else{
                this.$store.commit('setBnfcrPk', this.sltBnfcrPk)
                this.$store.commit('setMmbrPk', this.selectedItem)
                this.copayDpsStsList.forEach(items => {
                    if(items.bnfcrPk == this.sltBnfcrPk){
                        this.$refs.copayDpstDetail.copayDpsStsInfo.rmnPrpym = items.rmnPrpym
                        this.$refs.copayDpstDetail.copayDpsStsInfo.unpdAmt = items.unpdAmt
                        this.$refs.copayDpstDetail.copayDpsStsInfo.grdName = items.grdName
                    }
                });
                this.$refs.copayDpstDetail.getCopayDpsList()
            }
        },
        'year': function () {
            if(this.selectedItem != -1)
                this.$refs.copayDpstDetail.getCopayDpsList()
        },
    },

    methods: {
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        getCopayDpsStsList: function (yymm){
            selCopayDpsStsList(this.$store.getters.getFcltyNum, yymm)
                .then((response) => ( this.getCopayDpsStsListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayDpsStsList : ' + error))
        },
        stCdMapping: function (res) {
            // res.vCd62.forEach((code) => {
            //     this.exBtn[0].expandList.push(code.valcdnm)
            // });

            this.vCd62 = res.vCd62
            this.vCd84 = res.vCd84
        },
        getCopayDpsStsListAfter: function (res) {
            // console.log('본인부담금 입금관리 목록 : ')

            if(typeof res != 'string'){
                res.forEach(items => {
                    items.dpsDt = items.dpsDt?.beforeDateFormatDot()
                });
    
                this.copayDpsStsList = res
    
                if(this.$store.getters.getBnfcrState.bnmmbr != 0)
                    this.selectedItem = this.$store.getters.getBnfcrState.bnmmbr
    
                if(this.selectedItem > -1){
                    let chc = this.copayDpsStsList.findIndex( v => v.bnMmbrPk == this.selectedItem )
                    if(chc > -1){
                        this.$refs.copayDpstDetail.copayDpsStsInfo.rmnPrpym = this.copayDpsStsList[chc].rmnPrpym
                        this.$refs.copayDpstDetail.copayDpsStsInfo.unpdAmt = this.copayDpsStsList[chc].unpdAmt
                        this.$refs.copayDpstDetail.copayDpsStsInfo.grdName = this.copayDpsStsList[chc].grdName
                    }
                }
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'acknwRtng': this.schChkField.acknwRtng.splice(0)
                    sch.forEach((item) => {
                        if(item == '등급외')
                            this.schChkField.acknwRtng.push('99')
                        else
                            this.schChkField.acknwRtng.push(item.replaceAll('등급', ''))
                    });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)

            let col1 = true
            if(this.schChkField.acknwRtng.length != 0){
                this.schChkField.acknwRtng.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.acknwRtng.includes(stcd)
                    else col1 = col1 || filter.acknwRtng.includes(stcd)
                });
            }

            return col0 && col1;
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'acknwRtng'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        tableClickEvent: function (itm){
            if(itm.bnfcrPk == this.sltBnfcrPk){
                this.selectedItem = -1
                this.sltBnfcrPk = -1
            }
            else if(itm.bnfcrPk != this.sltBnfcrPk){
                this.selectedItem = itm.bnMmbrPk
                this.sltBnfcrPk = itm.bnfcrPk
            }
        },
        getYear: function (yyyy){
            this.year = yyyy
            this.reloadList()
        },
        reloadList: function (){
            this.getCopayDpsStsList(this.year)
        },
    },

    data: () => ({
        midAreaWidth: '442',
        selectedItem: -1,
        sltBnfcrPk: -1,
        copayDpsStsList: [],
        vCd62: [],
        vCd84: [],
        year: '',
        schField: '',
        schDummy: '',
        schChkField: { acknwRtng: [], target: '' },
        // exBtn: [
        //     { index:  0, btnTitle: '등급', btnIcon: 'wheelchair', exWidth: 104, exHeigth: 198, expandList: [], },
        // ],
        headers: [
            { text: '수급자', value: 'name', sortable: true, align:'center', width: '88', class: 'black--text bottomBorder cmmFontSizeOne py-2'},
            { text: '입소상태', value: 'entstnm', sortable: true, align:'center', width: '73', class: 'black--text bottomBorder cmmFontSizeOne'},
            // { text: '최종납입일', value: 'dpsDt', sortable: true, align:'center', width: '94', class: 'black--text bottomBorder cmmFontSizeOne'},
            { text: '입금상태', value: 'dpsSts', sortable: true, align:'center', width: '73', class: 'black--text bottomBorder cmmFontSizeOne'},
            { text: '잔여선납액', value: 'rmnPrpym', sortable: true, align:'center', width: '87', class: 'black--text bottomBorder cmmFontSizeOne'},
            { text: '미납액', value: 'unpdAmt', sortable: true, align:'center', width: '87', class: 'black--text bottomBorder cmmFontSizeOne'},
        ],
    }),
};
</script>