<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="10">
                <span class="text-h4 font-weight-bold">{{ mdlTtl }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="2">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-5 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-textarea v-model="cmnts" class="rmrksArea" no-resize  
                    :rows="hSize" outlined dense hide-details>
                </v-textarea>
            </v-col>
        </v-row>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="insText" height="30" samll rounded>
                    <v-icon>mdi-receipt-text-arrow-right-outline</v-icon>
                    <span class="ml-2" style="font-size: 1.1rem; padding-bottom: 2px;">적용</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>            
</template>
<script>
export default {
    
    name: 'CmntsMdl',

    props : {
        mdlTtl      : { type: String, default: '상세보기' },
        mdlCmnts    : { type: String, default: '' },
        hSize       : { type: Number, default: 5 },
    },
            
    components: {
       
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.cmnts = this.mdlCmnts
        }) 
    },

    computed: {
        
    },

    watch:{
        
    },
        
    methods: {        
        onClose: function () {
            this.$emit('isClose')
        },
        insText:function(){
            this.$emit('outText', this.cmnts)
            this.onClose()
        }
    },
    
    data: () => ({
        cmnts: '',
    }),
};
</script>