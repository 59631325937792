<template>
    <v-sheet class="rounded-lg rounded-l-0" min-width="1260" max-width="1560" height="835" color="">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>        
        <div class="px-2 mt-2 mr-2 d-flex align-center">
            <div class="text-h4">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">8-1. 직원&nbsp;임금명세서&nbsp;관리</span>
            </div>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action'" @click="onClickBtn(i)"
                    :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
                <v-dialog v-else-if="list.type == 'dialogue'"
                    v-model="dialog[i]" :max-width="list.width" content-class="round">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                        <div v-else>개발중</div>
                </v-dialog>
            </span>
            <!-- <v-btn
                :class="outlinedBtn[0].class" :color="outlinedBtn[0].color" :disabled="outlinedBtn[0].disabled"
                @click="getExcelCrtDwnl()" height="30" samll rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                <span class="ml-1" style="font-size: 1rem;">{{outlinedBtn[0].text}}</span>
            </v-btn> -->
            <v-btn
                :class="outlinedBtn[0].class" :color="outlinedBtn[0].color"
                :disabled="empWageStmtList.filter( v => v.wgePymHisPk != null ).length == 0"
                @click="getExlEmplWgeEtcHdInf()" height="30" samll rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                <span class="ml-1" style="font-size: 1rem;">{{outlinedBtn[0].text}}</span>
            </v-btn>
            <v-btn
                :class="outlinedBtn[1].class" :color="outlinedBtn[1].color"
                :disabled="empWageStmtList.filter( v => v.wgePymHisPk != null ).length == 0"
                @click="empWageDtlPrt" height="30" samll rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{outlinedBtn[1].icon}}</v-icon>
                <span class="ml-1" style="font-size: 1rem;">{{outlinedBtn[1].text}}</span>
            </v-btn>
        </div>
        <div class="mt-5">
            <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
        </div>
        <v-row class="ma-0 pa-0 mt-3 mx-3">           
            <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />        
            <span class="d-inline-block" style="width: 130px;">
                <v-text-field class="ma-0 pa-0 nameSearch" height="33px" background-color="white" append-icon="mdi-magnify"
                    single-line rounded outlined dense filled hide-details label="이름조회" @input="setSearchText">
                </v-text-field>
            </span>
        </v-row>
        <v-row class="ma-0 pa-0 mt-3 mx-3">
            <v-data-table hide-default-footer dense locale="ko" height="665" min-width="1360"
                hide-default-header fixed-header 
                :headers="headers" 
                :items="empWageStmtList" 
                :search="schDummy"  
                :custom-filter="customFilter"  
                @current-items="calcTotAmt"             
                class="" disable-pagination>

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="clmsHeaderCommon tableBR tableBT" style="width: 45px !important;" rowspan='2'>
                                <v-checkbox class="clmsHeaderCheck" v-model="allChecker" @change="checkBoxMessage" hide-details></v-checkbox>
                            </th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 70px !important;" colspan="1" rowspan='2'>{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;" colspan="1" rowspan='2'>{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;" colspan="1" rowspan='2'>{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;" colspan="1" rowspan='2'>{{props.headers[3].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="" colspan="3">{{props.headers[4].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 250px !important;" colspan="4">{{props.headers[5].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 80px !important;" colspan="1" rowspan='2'>{{props.headers[6].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" style="width: 100px !important;" colspan="2">{{props.headers[7].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBT" style="width: 67px !important;" colspan="1" rowspan='2'>{{props.headers[8].text}}</th>
                        </tr>
                        <tr style="border-top: 1px solid #dbdbdb !important;">
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[9].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 95px !important;">{{props.headers[10].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[11].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[12].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[13].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[14].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[15].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 80px !important;">{{props.headers[16].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR py-1" style="width: 100px !important;">{{props.headers[17].text}}</th>
                        </tr>
                    </thead>
                </template>                
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR" style="padding-left: 10px !important; width: 45px !important;">
                            <v-checkbox class="clmsHeaderCheck" v-model="item.isSelected" @change="checkBoxIndex()" hide-details></v-checkbox>
                        </td>
                        <td class="clmsBodyCommon tableBR">{{item.name}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.jobNm}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.entDt?.beforeDateFormatDot()}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.wgeTypNm?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt1Tx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt1Ntx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt1Tot?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Ins?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Tx?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Msc?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amt2Tot?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amtRealPym?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amtSclInsrn?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.amtRtr?.toLocaleString('ko-KR')}}</td>
                        <td class="clmsBodyCommon">  
                            <div class="d-flex">
                                <span>
                                    <v-btn
                                        color="grey006" width="30" height="26"
                                        @click="onShowEmp(item.empPk, item.mmbrPk)"
                                        :disabled="item.wgePymHisPk == null"
                                        icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="20">mdi-square-edit-outline</v-icon>
                                                </div>
                                            </template>
                                            <span>임금명세서조회</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span> 
                                <span>
                                    <v-btn
                                        color="grey006" width="30" height="26"   
                                        :disabled="item.wgePymHisPk == null" 
                                        @click="empWageStmtPrt(item.empPk)"                          
                                        icon>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                                    <v-icon size="20">mdi-printer-outline</v-icon>
                                                </div>
                                            </template>
                                            <span>임금명세서출력</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>   
                            </div>                          
                        </td>
                    </tr>
                </template>

                <template v-slot:[`body.append`]="{ item }">
                        <tr style="z-index: 4 !important; position: sticky; bottom: 0px;">
                            <td class="clmsHeaderCommon black--text tableBR tableBT fixedTable py-2" style="" colspan="5">합계</td>                            
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt1Tx?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt1Ntx?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt1Tot?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Ins?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Tx?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Msc?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amt2Tot?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amtRealPym?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amtSclInsrn?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBR tableBT" style="font-size: 0.875rem !important;" colspan="1">{{totItems.amtRtr?.toLocaleString('ko-KR')}}</td>
                            <td class="clmsHeaderCommon black--text tableBT" style="font-size: 0.875rem !important; width:52px !important;" colspan="1">{{item}}</td>
                        </tr>
                    </template>
            </v-data-table>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" :contents="contents" @madalState="onAction"></btnModalDelete>  
            </v-dialog>   
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="modal[0]" persistent max-width="1200" min-width="1200">                
                <empWageStmtWrt 
                    ref="empWageStmtWrt"
                    :date="date"
                    :empWageStmtWrtPk="empWageStmtWrtPk"
                    :mmbrPk="sltMmbrPk"
                    :cnfrm="filledBtn[1].disabled"
                    @modalEvent="modalEvent"></empWageStmtWrt>
            </v-dialog>
        </div> 
        <div class="pa-0 ma-0">
            <v-dialog eager v-model="modal[1]" persistent max-width="1200" min-width="1200">                
                <empWageStmtMsgSend 
                    ref="empWageStmtMsgSend"
                    :date="date"
                    :empWageStmtMsgSendPk="empWageStmtMsgSendPk"
                    :cnfrm="filledBtn[1].disabled"
                    @modalEvent="modalEvent"></empWageStmtMsgSend>
            </v-dialog>
        </div>
        <v-dialog v-model="modal[2]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="modal.splice(2, 1, false)"></ReportMgmt>
        </v-dialog>
    </v-sheet>
</template>
<script>
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';
import exBtn from '../../components/commons/expandButtonCustom.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import empWageStmtWrt from '../../components/impExp/EmpWageStmtWrt.vue';  //임금명세서 저장
import empWageStmtMsgSend from '../../components/impExp/EmpWageStmtMsgSend.vue';  //임금명세서 발송

import { excelCrtDwnl } from '../../api/index.js';
import { selAllEmplWgePymList, insWgePym, updlWgePymCnfrm, selExlEmplWgeEtcHdInfo } from '../../api/impExp.js';
import { empWageStmtXlsxPrdc } from '../../api/global/cmmExcelJS.js';

export default {
    name: 'EmpWageStmtMgmt',
        
    components: {
        expandMonthPicker,
        exBtn,
        btnModalDelete,
        ReportMgmt,
        empWageStmtWrt,
        empWageStmtMsgSend,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {           
            // this.dataCall = false 
            // this.date = this.$moment().format('YYYYMM')
            // if(!this.dataCall) this.getAllEmplWgePymList()
            // console.log(this.date)
            // this.getCode(['42'])
        })
    },

    watch:{
    },

    methods: {
        getPickerDate: function (date) {
            this.date = date
            //this.getExcelInfo()
            if(!this.dataCall) this.getAllEmplWgePymList()
        },
        getExcelCrtDwnl: function () {
            excelCrtDwnl(this.$store.getters.getFcltyNum, '4', null, this.date, 0)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /report/excelCrtDwnl : ' + error))
        },
        // 엑셀 생성 데이터 조회
        getExlEmplWgeEtcHdInf: function () {
            selExlEmplWgeEtcHdInfo(this.$store.getters.getFcltyNum, this.date)
                .then((response) => ( this.exlEmplWgeEtcHdRst(response.data) ))
                .catch((error) => console.log('connect error /impExp/selExlEmplWgeEtcHdInfo : ' + error))
        },
        exlEmplWgeEtcHdRst: function (res) {
            if(res?.list.length > 0){
                // 엑셀 헤더 정보 작성
                let exlColPrvsEtc = []
                let exlColDdctEtc = []
                let exlColPrvsTot = [{ header: '합계', key: 'pAmtTot', width: 20 }]
                let exlColDdctTot = [{ header: '합계', key: 'dAmtTot', width: 20 }]
                let actTot = [{ header: '실수령액', key: 'actAmtTot', width: 20 }]
                res.ptAmtNmsList.forEach(itm => {
                    let obj = {}
                    obj.header = itm.val
                    obj.key = itm.col
                    obj.width = 22

                    exlColPrvsEtc.push(obj)
                });
                res.dtAmtNmsList.forEach(itm => {
                    let obj = {}
                    obj.header = itm.val
                    obj.key = itm.col
                    obj.width = 22

                    exlColDdctEtc.push(obj)
                });

                // 동적 컬럼 갯수 정보 작성
                let colLengObj = {}
                colLengObj.dft = this.exlColDft.length
                colLengObj.prvs = this.exlColPrvs.length
                colLengObj.prvsEtc = exlColPrvsEtc.length
                colLengObj.ddct = this.exlColDdct.length
                colLengObj.ddctEtc = exlColDdctEtc.length
                colLengObj.fclty = this.exlColFclty.length

                // 헤더 정보를 1차원 배열로 합성
                let hArr = [
                    ...this.exlColDft,
                    ...this.exlColPrvs, ...exlColPrvsEtc, ...exlColPrvsTot,
                    ...this.exlColDdct, ...exlColDdctEtc, ...exlColDdctTot,
                    ...actTot, ...this.exlColFclty
                ]

                // 엑셀 생성 함수 호출 ( 직원 임금명세 전용 )
                let ttl = this.$moment(this.date, 'YYYYMM').format('YYYY년 MM월')
                let subTtl = this.$moment(this.date+'01', 'YYYYMMDD').format('YYYY.MM.DD') + ' ~ ' + this.$moment(this.date+'01', 'YYYYMMDD').endOf('month').format('YYYY.MM.DD')
                empWageStmtXlsxPrdc( hArr, res.list, colLengObj, ttl, subTtl, this.$store.getters.getUserNm )
            }
        },
        getExcelInfoAfter: function (res) {
            this.excelInfo.filePth = res.filePth
            this.excelInfo.fileNm = res.fileNm
            this.excelInfo.orgFileNm = res.orgFileNm
        },
        fileDownLoaderAfter: function (res) {
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))

            link.href = url
            link.setAttribute('download', '직원임금내역_' + this.$store.getters.getFcltyNum + '_' + this.date + '.xlsx')
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },
        //직원임금명세전체 조회
        getAllEmplWgePymList: function () {
            this.dataCall = true
            selAllEmplWgePymList(this.$store.getters.getFcltyNum, this.date)
                .then((response) => ( this.getAllEmplWgePymListAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/selAllEmplWgePymList : ' + error))
        },
        getAllEmplWgePymListAfter: function (res) {            
            if(res.code != null){
                this.vCd42 = res.code.vCd42

                res.code.vCd42.forEach(code => {
                    this.exBtn[0].expandList.push(code.valcdnm)
                })

            }

            this.empWageStmtList.splice(0)
            

            this.filledBtn[0].disabled = false
            this.filledBtn[1].disabled = false
            this.filledBtn[2].disabled = true

            this.allChecker = false
            res.list.forEach(items => {
                this.vCd42.forEach(item => {
                    if(items.jobCd == item.valcd)
                        items.jobCd = item.valcdnm
                });    
                
                // items.amt1Tot = Number(items.amt1Tx)+Number(items.amt1Ntx)
                // items.amt2Tot = Number(items.amt2Tx)+Number(items.amt2Ins)+Number(items.amt2Msc)

                if(items.cnfrmYn == '1'){
                    this.filledBtn[0].disabled = true
                    this.filledBtn[1].disabled = true
                }

                // this.totItems.amt1Tx        += Number(items.amt1Tx)
                // this.totItems.amt1Ntx       += Number(items.amt1Ntx)
                // this.totItems.amt1Tot       += Number(items.amt1Tot)
                // this.totItems.amt2Ins       += Number(items.amt2Ins)
                // this.totItems.amt2Tx        += Number(items.amt2Tx)
                // this.totItems.amt2Msc       += Number(items.amt2Msc)
                // this.totItems.amt2Tot       += Number(items.amt2Tot)
                // this.totItems.amtRealPym    += Number(items.amtRealPym)
                // this.totItems.amtSclInsrn   += Number(items.amtSclInsrn)
                // this.totItems.amtRtr        += Number(items.amtRtr)


                this.empWageStmtList.push(items)
            });
            
            if(this.filledBtn[1].disabled) this.filledBtn[2].disabled = false

            this.dataCall = false
        },
        // 합계 계산
        calcTotAmt: function (arr) {
            this.totItems.amt1Tx        = 0
            this.totItems.amt1Ntx       = 0
            this.totItems.amt1Tot       = 0
            this.totItems.amt2Ins       = 0
            this.totItems.amt2Tx        = 0
            this.totItems.amt2Msc       = 0
            this.totItems.amt2Tot       = 0
            this.totItems.amtRealPym    = 0
            this.totItems.amtSclInsrn   = 0
            this.totItems.amtRtr        = 0
            
            arr.forEach(itm => {
                itm.amt1Tot = Number(itm.amt1Tx)+Number(itm.amt1Ntx)
                itm.amt2Tot = Number(itm.amt2Tx)+Number(itm.amt2Ins)+Number(itm.amt2Msc)

                this.totItems.amt1Tx        += Number(itm.amt1Tx)
                this.totItems.amt1Ntx       += Number(itm.amt1Ntx)
                this.totItems.amt1Tot       += Number(itm.amt1Tot)
                this.totItems.amt2Ins       += Number(itm.amt2Ins)
                this.totItems.amt2Tx        += Number(itm.amt2Tx)
                this.totItems.amt2Msc       += Number(itm.amt2Msc)
                this.totItems.amt2Tot       += Number(itm.amt2Tot)
                this.totItems.amtRealPym    += Number(itm.amtRealPym)
                this.totItems.amtSclInsrn   += Number(itm.amtSclInsrn)
                this.totItems.amtRtr        += Number(itm.amtRtr)
            });
        },
        filterSearch: function (sch) {            
            if(sch.length > 0){
                sch.forEach((item) => { this.schChkField.jobCd.push(item) });            
                this.schDummy = this.schDummy + '.'
            }else{
                this.schDummy= ''
            }
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.jobCd.includes(stcd)
                    else col1 = col1 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1;
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onWgePym()
                    break;
                case 1:
                    this.type="cnfrm"
                    this.delTitle = '확정처리'
                    this.dialogDelete = true
                    break;
                case 2:
                    this.type="cncl"
                    this.delTitle = '확정취소'
                    this.dialogDelete = true
                    break;
                case 3:
                    this.empWageStmtMsgSendPk = 1
                    this.modal.splice(1, 1, true)
                    break;
                default:
                    break;
            }
        },
        onWgePym:function(){
            let selectors = []

            this.empWageStmtList.forEach(item => {
                if(item.isSelected == true) selectors.push(item.empPk)
            });

            if(selectors.length > 0){
                insWgePym(this.$store.getters.getFcltyNum, this.date, selectors, this.$store.getters.getUserNm)
                    .then((response) => ( this.onWgePymAfter(response.data) ))
                    .catch((error) => console.log('connect error /impExp/insWgePym : ' + error))
            }else{
                this.snackControll([true, 3000, '직원을 선택해주세요.', 'warning'])
            }
        },
        onWgePymAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getAllEmplWgePymList(this.date)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onAction:function(res){
            if(res == 9990){  
               if(this.type=="cnfrm"){                    
                    this.onWgePymCnfrm()
               }else{
                    this.onWgePymCncl()
               }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onWgePymCnfrm:function(){           
            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm: this.$store.getters.getUserNm,
                stdYymm:this.date,
                cnfrmYn:'1'
            }
            updlWgePymCnfrm(obj)
                .then((response) => ( this.onWgePymCnfrmAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/updlWgePymCnfrm : ' + error))
        },
        onWgePymCnfrmAfter:function(res){
            this.dialogDelete = false

            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getAllEmplWgePymList(this.date)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }            
        },
        onWgePymCncl:function(){           
            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum,
                userNm: this.$store.getters.getUserNm,
                stdYymm:this.date,
                cnfrmYn:'2'
            }
            updlWgePymCnfrm(obj)
                .then((response) => ( this.onWgePymCnclAfter(response.data) ))
                .catch((error) => console.log('connect error /impExp/updlWgePymCnfrm : ' + error))
        },
        onWgePymCnclAfter:function(res){
            this.dialogDelete = false

            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info']) 
                this.getAllEmplWgePymList(this.date)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onShowEmp:function(pk, mmbr){  
            this.empWageStmtWrtPk = pk
            this.sltMmbrPk = mmbr
            this.modal.splice(0, 1, true)
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        
        checkBoxIndex: function () {
            let chk = []
            let pkList = []
            this.empWageStmtList.forEach(items => {
                if(typeof items.isSelected == 'undefined') chk.push(false)
                else chk.push(items.isSelected)

                if(items.isSelected == true) pkList.push(items.empPk)
            });
            this.selectedList = pkList
            let result = chk.every((item) => item==true)

            if(result) this.allChecker = true
            else this.allChecker = false
        },
        checkBoxMessage: function () {
            if(this.allChecker == true){
                this.empWageStmtList.forEach(item => {
                    item.isSelected = true
                })
            }
            else if(this.allChecker == false){
                this.empWageStmtList.forEach(item => {
                    item.isSelected = false
                })
            }
            let pkList = []
            this.empWageStmtList.forEach(items => {
                if(items.isSelected == true) pkList.push(items.empPk)
            });
            this.selectedList = pkList
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.getAllEmplWgePymList(this.date)
            }else{
                if(event == 0) this.empWageStmtWrtPk = -1
                if(event == 1) this.empWageStmtMsgSendPk = -1
                this.modal.splice(event, 1, false)
            }      
        },
        rprtActCall: function (){
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum

            this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            this.modal.splice(2, 1, true)
        },
        empWageDtlPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.prtCd = '24'
            this.prtThrowObj.inqYYMM = this.date
            this.rprtActCall()
        },
        empWageStmtPrt:function(bzpk){
            this.prtThrowObj.list[0].bzPk.splice(0)
            // prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '24', inqYYMM: '', list: [ { bzClcd: [], bzPk: [] } ], },            
            this.prtThrowObj.prtCd = '31'
            this.prtThrowObj.inqYYMM = this.date
            this.prtThrowObj.list[0].bzPk.push(bzpk)
            //console.log(this.prtThrowObj)
            this.rprtActCall()
        },
        createExcel: function (){
            let list = []
            list.push({ rn: '1', name: '이요양', birth: '721201', entDt: '20210130' })
            empWageStmtXlsxPrdc(this.exlColDft, list)
            // { header: '', key: 'empty', width: 5, },
            // { header: '순번', key: 'rn', width: 12, }, { header: '직원명', key: 'name', width: 12, },
            // { header: '생년월일', key: 'birth', width: 12, }, { header: '입사일', key: 'entDt', width: 12, },
        },
    },
    data: () => ({
        allChecker:false,
        dataCall:false,
        date:'',
        type:'',
        empWageStmtWrtPk:-1,
        sltMmbrPk: -1,
        empWageStmtMsgSendPk:-1,
        delTitle:'',
        contents:'하시겠습니까',
        dialog: [],
        modal:[false,false,false],
        empWageStmtList:[],
        schField: '',
        schDummy: '',
        schChkField: { jobCd: [], target: '' },
        exBtn: [ 
            { index:  0, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        headers: [
            { text: '직원명', value: 'name', align: 'center'},
            { text: '직종', value: 'jobNm', align: 'center'},
            { text: '입사일', value: 'entDt', align: 'center'},
            { text: '임금형태', value: 'wgeTypNm', align: 'center'},
            { text: '지급내역', value: '', align: 'center'},
            { text: '공제내역', value: '', align: 'center'},
            { text: '실지급액', value: '', align: 'center'},
            { text: '기관부담금', value: '', align: 'center'},
            { text: '상세', value: '', align: 'center'},
            { text: '과세합계', value: '', align: 'center'},
            { text: '비과세합계', value: '', align: 'center'},
            { text: '합계', value: '', align: 'center'},
            { text: '4대보험', value: '', align: 'center'},
            { text: '소득세', value: '', align: 'center'},
            { text: '기타공제', value: '', align: 'center'},            
            { text: '합계', value: '', align: 'center'},            
            { text: '사회보험', value: '', align: 'center'},            
            { text: '퇴직적립금', value: '', align: 'center'},            
        ],
        exlColDft: [
            { header: '', key: 'empty', width: 5, },
            { header: '순번', key: 'rn', width: 12, }, { header: '직원명', key: 'name', width: 12, },
            { header: '생년월일', key: 'rsdntBirth', width: 12, }, { header: '입사일', key: 'entDt', width: 12, },
            { header: '직책', key: 'jobNm', width: 12, }, { header: '급여구분', key: 'wgeTypNm', width: 12, },
            { header: '근무시간', key: 'actWrkHr', width: 12, }, { header: '근무일수', key: 'actWrkDd', width: 12, },
        ],
        exlColPrvs:[
            { header: '기본급', key: 'pAmt1', width: 14, }, { header: '장기근속장려금', key: 'pAmt2', width: 14, },
            { header: '주휴수당', key: 'pAmt3', width: 14, }, { header: '시간외수당', key: 'pAmt4', width: 14, },
            { header: '야간근무수당', key: 'pAmt5', width: 14, }, { header: '휴일근무수당', key: 'pAmt6', width: 14, },
            { header: '연장근무수당', key: 'pAmt7', width: 14, }, { header: '연차수당', key: 'pAmt8', width: 14, },
            { header: '연말정산', key: 'pAmt9', width: 14, }, { header: '식대(비과세)', key: 'pAmt10', width: 14, },
        ],
        exlColDdct: [
            { header: '국민연금', key: 'dAmt1', width: 16, }, { header: '고용보험', key: 'dAmt2', width: 16, },
            { header: '건강보험', key: 'dAmt3', width: 16, }, { header: '장기요양보험', key: 'dAmt4', width: 16, },
            { header: '소득세', key: 'dAmt5', width: 16, }, { header: '지방소득세', key: 'dAmt6', width: 16, },
        ],
        exlColFclty:[
            { header: '국민연금', key: 'fAmt1', width: 16, }, { header: '고용보험', key: 'fAmt2', width: 16, },
            { header: '건강보험', key: 'fAmt3', width: 16, }, { header: '장기요양보험', key: 'fAmt4', width: 16, },
            { header: '산재보험', key: 'fAmt5', width: 16, }, { header: '퇴직적립금', key: 'dAmtRtr', width: 16, },
        ],
        totItems:{
            amt1Tx:0,
            amt1Ntx:0,
            amt1Tot:0,
            amt2Ins:0,
            amt2Tx:0,
            amt2Msc:0,
            amt2Tot:0,
            amtRealPym:0,
            amtSclInsrn:0,
            amtRtr:0,
        },
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '임금명세서 (재)생성', type: 'action', width:'800px', disabled: false, },            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '확정처리', type: 'action', width:'800px', disabled: false, },
            { icon: 'mdi-file-cancel', class: 'ml-1 white--text', color:'grey006', text: '확정취소', type: 'action', width:'800px', disabled: true, },
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '명세서 발송', type: 'action', width:'800px', disabled: false, },
        ],
        outlinedBtn: [
            { icon: 'mdi-microsoft-excel', class: 'ml-1 font-weight-bold', color:'grey006', text: '엑셀', width:'1000px', disabled: false, },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '24', inqYYMM: '', list: [ { bzClcd: [], bzPk: [] } ], },
        snackOptions: [false, 3000, '', 'info'],
        dialogDelete:false,
    })
}
</script>