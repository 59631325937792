<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-3" color="" :min-width="areaWidth" :max-width="areaWidth">
        <div style="height: 40px; border-bottom: 1px solid #dbdbdb;">
            <div style="float: left;"> 
                <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
            </div>
            
            <v-spacer></v-spacer>
            <div class="d-flex justify-center pl-1 pr-3" style="float: right;">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action'"   :class="list.class" @click="onClickBtn(list.text)" samll height="30" :color="list.color"
                        :disabled="list.disabled" rounded>
                        <v-icon small>{{ list.icon }}</v-icon>
                        <span style="font-size: 1rem;">{{ list.text }}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue'" v-model="dialogue[i]"
                        :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled" :class="list.class" samll height="30"
                                :color="list.color" rounded>
                                <v-icon small>{{ list.icon }}</v-icon>
                                <span style="font-size: 1rem;">{{ list.text }}</span>
                            </v-btn>
                        </template>
                    </v-dialog>
                </span>
            </div>
        </div>
        <div>
            <v-data-table class="msclnTable mt-2" height="" locale="ko" :headers="headers" :items="items" hide-default-header
                disable-pagination hide-default-footer dense style="border-bottom: 1px solid #dbdbdb;">
                <template v-slot:header="{ props }">
                    <thead style="">
                        <th class=" justify-center align-center greyE01 py-1"
                            style="border-right: 1px solid #dbdbdb !important; width: 18%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[0].text }}</th>
                        <th class=" justify-center align-center greyE01"
                            style="border-right: 1px solid #dbdbdb !important; width: 12%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[1].text }}</th>
                        <th class=" justify-center align-center greyE01"
                            style="border-right: 1px solid #dbdbdb !important; width: 32%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[2].text }}</th>
                        <th class=" justify-center align-center greyE01"
                            style="border-right: 1px solid #dbdbdb !important; width: 13%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[3].text }}</th>
                        <th class=" justify-center align-center greyE01"
                            style="border-right: 1px solid #dbdbdb !important; width: 13%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[4].text }}</th>
                        <th class=" justify-center align-center greyE01"
                            style="width: 12%; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                            {{ props.headers[5].text }}</th>
                    </thead>
                </template>
                <template v-slot:item='{ item, index }'>
                    <tr style="border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;">
                        <td class="fixedCol0" style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                            <span class="cmmFontSizeOne justify-center align-center" style="width:18%" >{{ item.mtngTypcd }}</span>
                        </td>
                        <td class="fixedCol0"
                            style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                            <span style="width:12%;">{{ item.mtngDt }}</span>
                        </td>
                        <td class="fixedCol0"
                            style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                            <span style="width:35%;">{{ item.mtngTopc }}</span>
                        </td>
                        <td class="fixedCol0"
                            style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                            <span style="width:13%;">
                                <v-btn icon class=" rounded d-inline-block" v-ripple
                                @click="rowClicked(item.mtngRcrdPk)" depressed><v-icon>mdi-square-edit-outline</v-icon></v-btn>
                            </span>
                        </td>
                        <td class="fixedCol0"
                            style="height:40px !important; text-align: center; border-right: 1px solid #dbdbdb !important;">
                            <span style="width:8%;">
                                <v-btn icon color="grey006" height="26" style="width: 24px !important;"
                                    @click="onSender(item)" >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand-right</v-icon>
                                        </template>
                                        <span>발송안내</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                            <span style="width:13%;">
                                <v-btn class="" height="26" style="min-width: 46px; width:46px; border: 1px solid #666;" @click="rowClicked1(item.mtngRcrdPk)" depressed>{{ item.sigSts }}</v-btn>    
                            </span>
                            
                        </td>
                        <td class="fixedCol0" style="height:40px !important; text-align: center;">
                            <!-- <span style="width:8%;">
                                <v-btn icon color="grey006" height="26" style="width: 24px !important;"
                                    @click="onSender(item)" >
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="22" v-bind="attrs" v-on="on">mdi-arrow-expand-right</v-icon>
                                        </template>
                                        <span>발송안내</span>
                                    </v-tooltip>
                                </v-btn>
                            </span> -->
                            <span style="width:8%;">
                                <v-btn icon color="grey006" height="26" @click="mtngRcrdPrt(item)" style="width: 24px !important;">
                                    <v-icon size="22">mdi-printer-outline</v-icon>
                                </v-btn>
                            </span>
                            <span style="width:8%;">
                                <v-btn icon color="grey006" height="26" @click="onDelete(index)" style="width: 24px !important;">
                                    <v-icon size="22">mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="1200">
            <oprMtngRcrd ref="oprMtngRcrd" @isClose="isModalType(0)" @reload="reset()"></oprMtngRcrd>
        </v-dialog>
        <v-dialog v-model="dialog[1]" content-class="round" persistent max-width="750">
            <oprMtngSign 
                ref="oprMtngSign" 
                :oprMtngSignPk="oprMtngSignPk"
                @isClose="isModalType(1)" 
                @reload="reset()"
            ></oprMtngSign>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="'회의록'" @madalState="onDeleteBtn"></btnModalDelete>
        </v-dialog>
        <div class="pa-0 ma-0">
            <v-dialog v-model="bizMsg" max-width="1100">    
                <bizMsgMdl 
                    ref="bizMsgMdl"                    
                    :bizMsgData="eSndData"
                    :bizMsgDocClcd="eDocClcd"
                    :bizMsgBzPk="sltPk"
                    :bizMsgShow="bizMsg"
                    :bizMsgTitle="eCntnt"
                    :bizMsgCntnt="eCntnt"
                    @madalState="onSendClose"
                ></bizMsgMdl>  
            </v-dialog>
        </div>
        <v-dialog v-model="menu[0]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(0, 1, false)"></ReportMgmt>
        </v-dialog>
        <v-dialog v-model="menu[1]" content-class="round" max-width="1115" persistent eager>
            <CnfrnEmp @isClose="isModal(1)"></CnfrnEmp>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selMtngRcrdList, delMtngRcrd, selMtngAtndSigList } from '../../api/opr.js';
import { getMultiStcd } from '../../api/index.js';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import oprMtngRcrd from '../../components/opr/oprMtngRcrd.vue';
import oprMtngSign from '../../components/opr/oprMtngSign.vue';
import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import CnfrnEmp from '../../components/opr/CnfrnEmp.vue';

export default {
    name: 'CnfrnRcrd',
    
    components: {
        expandYearOnlyPicker,
        oprMtngRcrd,
        oprMtngSign,
        btnModalDelete,
        bizMsgMdl,
        ReportMgmt,
        CnfrnEmp,
    },

    props : {

    },

    created: function(){
        this.getCode(['43', '46'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
    },

    methods: {
        selMtngRcrdList: function () {
            selMtngRcrdList(this.$store.getters.getFcltyNum, this.year)
                .then((response) => (this.selMtngRcrdListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selMtngRcrdList : ' + error))
        },
        selMtngRcrdListAfter: function (res) {
            this.items = res
            this.items.forEach(items => {
                this.vCd46.forEach(code => {
                    if(items.mtngTypcd == code.valcd) items.mtngTypcd = code.valcdnm
                });
                items.mtngDt = items.mtngDt?.beforeDateFormatDot()
            });
        },
        getYear: function (yyyy){
            this.year = yyyy
            this.selMtngRcrdList()
            this.$emit('reload', this.year)
        },
        onDelete(key) {
            if (key > -1) {
                this.key = key
                this.dialogDelete = true
            } else {
                if (key != 0) {
                    this.items1.splice(key, 1)
                }
            }
        },
        onDeleteBtn: function (res) {
            if (res == 9990) {
                if (this.items[this.key].mtngRcrdPk != undefined) {
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    mtngRcrdPk: this.items[this.key].mtngRcrdPk,
                    userNm: this.$store.getters.getUserNm,
                }
                delMtngRcrd(obj)
                    .then((response) => (this.delMtngRcrdAfter(response.data)))
                    .catch((error) => console.log(error))
                } 
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        delMtngRcrdAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.dialogDelete = false
                this.reset()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false            
        },     
        isModalType: function (key) {
            this.dialog.splice(key, 1, false)
            if(key == 1) this.oprMtngSignPk = -1
        },
        onClickBtn:function(text){
            if(text == "회의작성"){
                this.dialog.splice(0, 1, true)
                this.$refs.oprMtngRcrd.reset()
                // this.$refs.oprMtngRcrd.mdlform.validate()
            } else if (text == "운영위원등록"){                
                this.menu.splice(1, 1, true)
            }
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },
        mtngRcrdPrt:function(item){
            this.inqBzPk[0] = item.mtngRcrdPk
            if(item.mtngTypcd == '운영위원회'){
                this.prtThrowObj.prtCd = '33'
                this.prtThrowObj.list[0].bzPk = this.inqBzPk
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
                this.menu.splice(0, 1, true)
            }else{
                this.prtThrowObj.prtCd = '34'
                this.prtThrowObj.list[0].bzPk = this.inqBzPk
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
                this.menu.splice(0, 1, true)
            }
        },
        //발송안내 ON
        onSender:function(item){            
            this.sltPk = item.mtngRcrdPk
            this.eCntnt = item.mtngTopc
            selMtngAtndSigList(this.$store.getters.getFcltyNum, item.mtngRcrdPk)
                .then((response) => (this.onSenderAfter(response.data)))
                .catch((error) => console.log('connect error opr/selMtngRcrdInfo : ' + error))
        },
        onSenderAfter:function(res){
            if(res.length > 0){                
                this.eSndData.splice(0)
                res.forEach(el => {
                    this.eSndData.push(el.mmbrPk)
                });          
                this.bizMsg = true 
            }else{
                this.snackControll([true, 5000, '발송 대상자를 조회 할 수 없습니다.', 'error'])
                this.sltPk = -1
                this.eCntnt = ''
            }

        },
        //발송안내 OFF
        onSendClose:function(){
            this.sltPk = -1
            this.eCntnt = ''
            this.bizMsg = false
        },
        rowClicked: function (pk) {
            this.$refs.oprMtngRcrd.onShow(pk)
            this.dialog.splice(0, 1, true)
        },
        rowClicked1: function (pk) {
            this.oprMtngSignPk = pk //mtngRcrdPk 변경
            this.mtngRcrdPk = pk
            this.dialog.splice(1, 1, true)
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => (this.stCdMapping(response.data)))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd43 = res.vCd43
            this.vCd46 = res.vCd46
        },
        reset: function () {
            this.selMtngRcrdList()
            this.$emit('reload', this.year)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        oprMtngSignPk:-1,
        filledBtn: [
            {  class: 'ml-1 white--text', color: 'blueBtnColor', text: '운영위원등록', type: 'action', },
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color: 'blueBtnColor', text: '회의작성', type: 'action', },
        ],  
        headers: [
            { text: '회의구분', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '회의일', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '회의제목', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '상세내용', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '서명', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
            { text: '처리', value: '', align: 'center', class: 'black--text greyE01 tableHeaderSize' },
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '29', inqYmdStart: '', inqYmdEnd: '',
            list: [ { bzClcd: [], bzPk: [] } ],
        },
        dialog: [false,false],
        dialogue: [false,false],
        areaWidth: 708,
        items: [],
        date: '',
        vCd43: [],
        vCd46: [],
        mtngRcrdPk: 0,
        snackOptions: [false, 3000, '', 'info'],
        year: 1990,
        dialogDelete: false,
        bizMsg:false,
        sltPk:-1,
        eSndData:[],
        eDocClcd :9,
        eCntnt:'',
        menu:[false,false],
        disabled: [],
        inqBzPk: [],
    }),
};
</script>