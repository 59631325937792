<template>
    <v-app class="minimumSize" :style="{background: $vuetify.theme.themes[theme].bckgrColor}">
  
        <v-main class="overflow-hidden">
            <v-container fluid>
                <!-- header bar -->
                <div class="d-flex justify-center rounded-lg">
                    <HeaderBar ref="headerTpl002" :style="reflectHeader"/>
                </div>
                <div class="d-flex justify-center rounded-lg mt-4">
                    <div id="reflectWd" class="d-flex d-inline-block rounded-lg elevation-7">
                        <!-- left -->
                        <LeftMenu/>

                        <!-- main -->
                        <router-view></router-view>
                    </div>
                </div>
            </v-container>
        </v-main>

        <CmmRootMdl></CmmRootMdl>
        <CmmSubMdl></CmmSubMdl>
     
        <v-snackbar
            class="ntfcBar" elevation="14"
            :value="$store.getters.getWrnnActv"
            absolute centered top>
            <v-alert
                class="subWarn" border="left" :type="$store.getters.getWrnnTyp"
                dismissible dense>
                <template v-slot:close="{ toggle }">
                    <v-btn class="ml-4" @click="closeAlert(toggle)" x-small icon>
                        <v-icon style="padding: 2px 0px;" color="white" size="24">mdi-close</v-icon>
                    </v-btn>
                </template>
                <div class="mb-1" style="margin-top: 1px;">
                    <div class="text-h5 mb-1">{{ $store.getters.getWrnnTtl }}</div>
                    <div v-if="Array.isArray($store.getters.getWrnnCntnt)">
                        <div
                            v-for="(itm, i) in $store.getters.getWrnnCntnt" :key="i">
                            {{ itm }}
                        </div>
                    </div>
                    <!-- <div v-else>{{ $store.getters.getWrnnCntnt }}</div> -->
                </div>
            </v-alert>
        </v-snackbar>
      
        <v-bottom-sheet
            v-model="dscrpMdl" inset>
            <v-card tile>
                <v-progress-linear class="my-0" height="5" color="amber accent-3" :value="50"></v-progress-linear>
                <DscrpBomMdl :decrpCode="$store.getters.getSltDscrp"></DscrpBomMdl>
            </v-card>
        </v-bottom-sheet>
    </v-app>
</template>
  
<script>
import LeftMenu from './LeftMenu.vue';
import HeaderBar from './HeaderBar.vue';
import DscrpBomMdl from '../components/commons/DscrpBomMdl.vue';
import CmmRootMdl from '../components/commons/CmmRootMdl.vue';
import CmmSubMdl from '../components/commons/CmmSubMdl.vue';

export default {
    name: 'AppTpl002',
  
    components: {
        LeftMenu,
        HeaderBar,
        DscrpBomMdl,
        CmmRootMdl,
        CmmSubMdl,
    },
  
    computed:{
        theme: function() {
            return (this.$vuetify.theme.dark) ? 'dark' : 'light'
        },
        getDscrp: function() {
            return this.$store.getters.getOnDscrp
        },
        getWrnnActv: function() {
            return this.$store.getters.getWrnnActv
        },
    },

    watch:{
        getDscrp: function(value) {
            this.dscrpMdl = value
        },
        'dscrpMdl': function() {
            if(this.dscrpMdl == false)
                this.$store.commit('setOnDscrp', false)
        },
        getWrnnActv: function(value) {
            if(value){
                if(this.$store.getters.getWrnnTmr != -1){
                    if(this.tmtItv != null)
                        clearInterval(this.tmtItv)
                    this.tmtItv = setInterval(()=>{
                        this.closeAlert()
                    }, this.$store.getters.getWrnnTmr)
                }
            }
        },
    },

    created: function(){
        //현재 날짜를 전역변수로 이용하기 위해 스토어에 저장
        let dt = [
            { typ: 'yyyymmdd', dt: this.$moment().format('YYYYMMDD') },
            { typ: 'yyyy-mm-dd', dt: this.$moment().format('YYYY-MM-DD') },
            { typ: 'yyyy.mm.dd', dt: this.$moment().format('YYYY.MM.DD') },
            { typ: 'yyyymm', dt: this.$moment().format('YYYYMM') },
            { typ: 'yyyy-mm', dt: this.$moment().format('YYYY-MM') },
            { typ: 'yyyy.mm', dt: this.$moment().format('YYYY.MM') },
            { typ: 'yyyy', dt: this.$moment().format('YYYY') }, { typ: 'mm', dt: this.$moment().format('MM') },
            { typ: 'dd', dt: this.$moment().format('DD') },
        ]
        this.$store.commit('setPrsntDt', dt)
    },
  
    mounted: function(){
        let obj = {}
        obj.actv = false
        obj.tmr = 3000
        obj.type = 'info'
        obj.title = ''
        obj.cntnt = []

        this.$store.commit('setWrnnInf', obj)

        const refTag = document.getElementById('reflectWd');

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const {width} = entry.contentRect;
                this.reflectHeader = 'width:' + width + 'px;';
            }
        });

        observer.observe(refTag);
    },
  
    methods: {
        calcWidth: function () {
            var element = document.getElementById('reflectWd');
            var positionInfo = element.getBoundingClientRect();
            //var height = positionInfo.height;
            this.reflectHeader = positionInfo.width;
        },
        closeAlert: function () {
            this.$store.commit('setWrnnActv', false)
        },
    },
  
    data: () => ({
        dscrpMdl: false,
        tmtItv: null,
        reflectHeader: null,
    }),
};
</script>
  
<style>
    .minimumSize{min-width: 730px;}
</style>
  