<template>
    <v-sheet class="pa-7">
        <v-row class="justify-left" no-gutters>
            <span class="text-h4 font-weight-bold">기타비용</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="onClose()" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider :class="['mt-3', bnftOfrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <v-row :class="['greyE01 pa-0', bnftOfrList.length > 10 ? 'mr-2__5' : '']" no-gutters>
            <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                <span class="font-weight-bold">급여제공일</span>
            </v-col>
            <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                <span class="font-weight-bold">비용항목</span>
            </v-col>
            <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                <span class="font-weight-bold">금액</span>
            </v-col>
            <v-col class="tableBR py-1" style="text-align: center;" cols="4">
                <span class="font-weight-bold">상세내용</span>
            </v-col>
            <v-col class="py-1" style="text-align: center;" cols="2">
                <span class="font-weight-bold">처리자</span>
            </v-col>
        </v-row>
        <v-divider :class="[bnftOfrList.length > 10 ? 'mr-2__5' : '']"></v-divider>
        <div
            v-if="bnftOfrList.length > 0"
            class="d-inline-block overflow-y-auto"
            style="width: 100%; height: 312px;">
            <v-row
                class="pa-0 tableBB"
                v-for="(list, i) in bnftOfrList" :key="i"
                no-gutters>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span>{{ $moment(list.bnftOfrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span>{{ list.costItm }}</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="2">
                    <span>{{ list.amt?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="tableBR py-1" style="text-align: center;" cols="4">
                    <span>{{ list.dtlCntnt }}</span>
                </v-col>
                <v-col class="py-1" style="text-align: center;" cols="2">
                    <span>{{ list.wrtr }}</span>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0" no-gutters>
                <v-col class="py-2" style="text-align: center;" col="12">
                    <span class="grey006--text">※ 조회된 목록이 없습니다.</span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { selMsclnFeeDtlList } from '../../api/bnfcr.js';

export default {
    name: 'MsclnExpnsMdl',

    props : {
        bnmmbrPk: { type: Number, default: -1 },
        bnfcrPk: { type: Number, default: -1 },
        yyMm: { type: String, default: '' },
    },
        
    components: {
        
    },

    watch: {
        'bnmmbrPk': function () {
            this.getMsclnFeeDtlClm(this.bnmmbrPk, this.bnfcrPk, this.yyMm)
        },
        'yyMm': function () {
            this.getMsclnFeeDtlClm(this.bnmmbrPk, this.bnfcrPk, this.yyMm)
        },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getMsclnFeeDtlClm(this.bnmmbrPk, this.bnfcrPk, this.yyMm)
        })
    },
    
    methods: {
        getMsclnFeeDtlClm: function (pk, bnfcr, yymm){
            this.bnftOfrList.splice(0)
            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = pk
            obj.bnfcrPk = bnfcr
            obj.clmYymm = yymm

            selMsclnFeeDtlList(obj)
                .then((response) => ( this.msclnFeeDtlRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selMsclnFeeDtlList : ' + error))
        },
        msclnFeeDtlRst: function (res){
            if(res.length > 0){
                this.bnftOfrList = res
            }
        },
        onClose: function(){
            this.$emit('isClose', '.')
        },
    },

    data: () => ({
        bnftOfrList: [],
    }),
};
</script>