<template>
    <v-sheet class="pa-0 ma-0" min-width="960" max-width="960">
        <v-row class="pa-0 mt-3 align-center" no-gutters>
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">개인정보&nbsp;수집&nbsp;및&nbsp;이용&nbsp;동의서</span>
        </v-row>
        <v-divider class="mt-2 mr-1 ml-3"></v-divider>
        <v-row class="greyE01 pa-0 mr-1 ml-3" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium py-2">작성일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">동의여부</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                <span class="black--text font-weight-medium">서명</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="2">
                <span class="black--text font-weight-medium">출력</span>
            </v-col>
        </v-row>
        <v-form ref="agrForm" lazy-validation>
            <v-divider class="mr-1 ml-3"></v-divider>
            <v-row class="pa-0 mr-1 ml-3" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="d-inline-block py-1 px-1" style="width: 140px;">
                        <CmmDateComp v-model="bnfcrCntrc.prvcyAgrDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium" style="margin: auto; width:141px;" >
                        <v-radio-group v-model="bnfcrCntrc.prvcyAgrYn" :rules="[rules.required]" hide-details row>
                            <v-radio :ripple="false" label="예" value="1"></v-radio>
                            <v-radio :ripple="false" label="아니오" value="2"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="4">
                    <span class="fontOneRem font-weight-medium" style="width: 50px;">수급자&#58;</span>
                    <span>
                        <v-img :aspect-ratio="4/3" eager 
                            :src="bnfcrMmSrc.src" lazy-src="../../assets/noSignImg.png" width="40" height="30">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </span>
                    <span class="ml-2 fontOneRem font-weight-medium" style="width: 50px;">보호자&#58;</span>
                    <span>
                        <v-img :aspect-ratio="4/3" eager 
                            :src="galMmScr.src" lazy-src="../../assets/noSignImg.png" width="40" height="30">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="font-weight-medium">
                        <v-btn class="" color="blueBtnColor" height="26" @click="setCntrcAgr()" icon>
                            <v-icon>mdi-content-save-edit-outline</v-icon>
                        </v-btn>
                        <v-btn class="" color="grey006" height="26"  @click="delCntrcAgr()" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">
                        <v-btn class="" color="grey006" height="26" @click="prtAgrCall()" icon>
                            <v-icon>mdi-printer-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1 ml-3"></v-divider>
        </v-form>
        <v-row class="pa-0 mt-7 align-center" no-gutters>
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">노인인권&nbsp;보호지침&nbsp;제공</span>
        </v-row>
        <v-divider class="mt-2 mr-1 ml-3"></v-divider>
        <v-row class="greyE01 pa-0 mr-1 ml-3" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium py-2">작성일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">동의여부</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                <span class="black--text font-weight-medium">제공&nbsp;받은자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">출력</span>
            </v-col>
        </v-row>
        <v-form ref="eldForm" lazy-validation>
            <v-divider class="mr-1 ml-3"></v-divider>
            <v-row class="pa-0 mr-1 ml-3" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="d-inline-block py-1 px-1" style="width: 140px;">
                        <CmmDateComp v-model="bnfcrCntrc.eldHmnOfrDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium" style="margin: auto; width:141px;" >
                        <v-radio-group v-model="bnfcrCntrc.eldHmnOfrYn" :rules="[rules.required]" hide-details row>
                            <v-radio :ripple="false" label="예" value="1"></v-radio>
                            <v-radio :ripple="false" label="아니오" value="2"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="4">
                    <span class="black--text font-weight-medium" style="width:161px;" >
                        <v-radio-group v-model="bnfcrCntrc.eldHmnRcvPstnCd" :rules="[rules.required]" hide-details row>
                            <v-radio :ripple="false" label="수급자" value="1"></v-radio>
                            <v-radio :ripple="false" label="보호자" value="2"></v-radio>
                        </v-radio-group>
                    </span>
                    <span class="d-inline-block py-1" style="width: 120px;">
                        <v-text-field v-model="bnfcrCntrc.eldHmnRcvNm" color="blue"
                            :rules="[rules.required, rules.strCheck]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="font-weight-medium">
                        <v-btn class="" color="blueBtnColor" height="26" @click="setCntrcEld()" icon>
                            <v-icon>mdi-content-save-edit-outline</v-icon>
                        </v-btn>
                        <v-btn class="" color="grey006" height="26"  @click="delCntrcEld()" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">
                        <v-btn class="" color="grey006" height="26" @click="prtEldCall()" icon>
                            <v-icon>mdi-printer-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1 ml-3"></v-divider>
        </v-form>
        <v-row class="pa-0 mt-7 align-center" no-gutters>
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">수급자&nbsp;안전관리&nbsp;통지서</span>
        </v-row>
        <v-divider class="mt-2 mr-1 ml-3"></v-divider>
        <v-row class="greyE01 pa-0 mr-1 ml-3" no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium py-2">작성일</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">동의여부</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                <span class="black--text font-weight-medium">통지&nbsp;받은자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="2">
                <span class="black--text font-weight-medium">출력</span>
            </v-col>
        </v-row>
        <v-form ref="sftForm" lazy-validation>
            <v-divider class="mr-1 ml-3"></v-divider>
            <v-row class="pa-0 mr-1 ml-3" no-gutters>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="d-inline-block py-1 px-1" style="width: 140px;">
                        <CmmDateComp v-model="bnfcrCntrc.sftNtcOfrDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="black--text font-weight-medium" style="margin: auto; width:141px;" >
                        <v-radio-group v-model="bnfcrCntrc.sftNtcOfrYn" :rules="[rules.required]" hide-details row>
                            <v-radio :ripple="false" label="예" value="1"></v-radio>
                            <v-radio :ripple="false" label="아니오" value="2"></v-radio>
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="4">
                    <span class="black--text font-weight-medium" style="width:161px;" >
                        <v-radio-group v-model="bnfcrCntrc.sftNtcRcvPstnCd" :rules="[rules.required]" hide-details row>
                            <v-radio :ripple="false" label="수급자" value="1"></v-radio>
                            <v-radio :ripple="false" label="보호자" value="2"></v-radio>
                        </v-radio-group>
                    </span>
                    <span class="d-inline-block py-1" style="width: 120px;">
                        <v-text-field v-model="bnfcrCntrc.sftNtcRcvNm" color="blue"
                            :rules="[rules.required, rules.strCheck]" hide-details outlined dense></v-text-field>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBR" cols="2">
                    <span class="font-weight-medium">
                        <v-btn class="" color="blueBtnColor" height="26" @click="setCntrcSft()" icon>
                            <v-icon>mdi-content-save-edit-outline</v-icon>
                        </v-btn>
                        <v-btn class="" color="grey006" height="26"  @click="delCntrcSft()" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center" cols="2">
                    <span class="black--text font-weight-medium">
                        <v-btn class="" color="grey006" height="26" @click="prtSftCall()" icon>
                            <v-icon>mdi-printer-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <v-divider class="mr-1 ml-3"></v-divider>
        </v-form>
        <div class="d-flex align-center mt-7 pr-1">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">낙상위험&nbsp;방지&nbsp;관련&nbsp;확인사항</span>
            <v-btn
                class="ml-1" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 1)" icon>
                <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                @click="setBnfcrCntrcCnFm($parent.$parent.$parent.$refs.bnfcrCntInf.bnfcrCntrc.bnfcrCntrcInfPk, cntrcCnfm)"
                min-width="30" height="25" small outlined>
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center" v-bind="attrs" v-on="on">
                            <v-icon size="20">mdi-content-save-edit-outline</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">저장</span>
                        </div>
                    </template>
                    <span>낙상위험 방지 관련 확인사항 저장</span>
                </v-tooltip>
            </v-btn>
        </div>
        <v-form ref="rskFallForm" lazy-validation>
            <v-divider class="mr-1 ml-3 mt-3"></v-divider>
            <v-row v-for="(list, i) in cntrcCnfm" :key="i"
                class="pa-0 mr-1 ml-3" style="border-bottom: 1px solid #dbdbdb;"
                no-gutters>
                <v-col class="d-flex justify-center align-center greyE01 py-2" style="" cols="3">
                    <span class="d-inline-block" style="width: 180px">
                        <v-checkbox
                            class="checkIcon cnsntIcon" v-model="list.cnfmYn"
                            true-value="1" false-value="2" :label="list.cnfmCntnt"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
                <v-col class="d-flex justify-left align-center px-3 tableBL" style="" cols="9">
                    {{ list.cnfmCntntDtl }}
                </v-col>
            </v-row>
        </v-form>
        <!-- <div class="overflow-y-auto d-inline-block" style="width: 100%; height: 60px;">{{ cntrcCnfm }}</div> -->
        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute centered top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
    </v-sheet>
</template>

<script>
import { bnfcrCntrcAgr, bnfcrCntrcCnfmDetail, setBnfcrCntrcAgr, setBnfcrCntrcEld, setBnfcrCntrcSft, setCntrcCnfm } from '../../api/bnfcr.js';
import { getBlobSignURL } from '../../api/common.js';

import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'BnfcrCnsntDcmntBepr',
            
    components: {
        CmmDateComp,
    },

    created: function(){
        this.bnfcrCntrc = this.dummyBnfcrCntrc
        
        if(this.$store.getters.getBnfcrState.bnmmbr != 0){
            this.getCntrcList(this.$store.getters.getBnfcrState.bnmmbr)
        }

        this.forceRender + 1
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.$refs.agrForm.resetValidation()
            this.$refs.eldForm.resetValidation()
            this.$refs.sftForm.resetValidation()
        })
    },

    computed: {
        // getBnmmbrPk(){
        //     return this.$store.getters.getBnfcrState.bnmmbr
        // },
        getCntrcInfPk(){
            return this.$store.getters.getBnfcrState.bnfcrCntrcInfPk
        },
    },

    watch:{
        // getBnmmbrPk(value){
        //     this.bnMmbrPk = value
        //     this.getCntrcList(value)
        //     //this.getNbnftFeeInf()
        // },
        getCntrcInfPk(value){
            this.cntrcInfPk = -1
            if(value != 0){
                this.cntrcInfPk = value
                this.getCntrcList(value)
                this.getCntrcfmList(value)
            }
        },
        'bnfcrCntrc': function () {
            //eSigClcd = 1 수급자, eSigClcd = 2 보호자
            window.URL.revokeObjectURL(this.bnfcrMmSrc.src)
            window.URL.revokeObjectURL(this.galMmScr.src)
            this.bnfcrMmSrc = getBlobSignURL(this.$store.getters.getFcltyNum, 1, this.bnfcrCntrc.bnfcrCntrcInfPk)
            this.galMmScr = getBlobSignURL(this.$store.getters.getFcltyNum, 2, this.bnfcrCntrc.bnfcrCntrcInfPk)
            this.$refs.agrForm.resetValidation()
            this.$refs.eldForm.resetValidation()
            this.$refs.sftForm.resetValidation()
            this.forceRender += 1
        },
    },

    destroyed: function(){
        window.URL.revokeObjectURL(this.bnfcrMmSrc.src)
        window.URL.revokeObjectURL(this.galMmScr.src)
    },
        
    methods: {
        getCntrcList: function (pk) {
            bnfcrCntrcAgr(this.$store.getters.getFcltyNum, this.$store.getters.getBnfcrState, pk)
                .then((response) => ( this.getCntrcMmbrPk(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcInf : ' + error))
        },
        getCntrcfmList: function (pk) {
            bnfcrCntrcCnfmDetail(this.$store.getters.getFcltyNum, pk, '2')
                .then((response) => ( this.cntrcCnfm = response.data ))
                .catch((error) => console.log('connect error /bnfcr/selBnfcrCntrcCnfmList : ' + error))
        },
        setCntrcAgr: function () {
            let chk = this.$refs.agrForm.validate()
            if(chk){
                let obj = {}
                obj.prvcyAgrDt = this.$moment(this.bnfcrCntrc.prvcyAgrDt, 'YYYY-MM-DD').format('YYYYMMDD')
                obj.prvcyAgrYn = this.bnfcrCntrc.prvcyAgrYn
                obj.bnfcrCntrcInfPk = this.bnfcrCntrc.bnfcrCntrcInfPk
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
                obj.userNm = this.$store.getters.getUserNm
              
                setBnfcrCntrcAgr(obj)
                    .then((response) => ( this.insCntrcAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/updBnfcrCntrcAgr : ' + error))
            }
        },
        setCntrcEld: function () {
            let chk = this.$refs.eldForm.validate()
            if(chk){
                let obj = {}
                obj.eldHmnOfrDt = this.$moment(this.bnfcrCntrc.eldHmnOfrDt, 'YYYY-MM-DD').format('YYYYMMDD')
                obj.eldHmnOfrYn = this.bnfcrCntrc.eldHmnOfrYn
                obj.eldHmnRcvPstnCd = this.bnfcrCntrc.eldHmnRcvPstnCd
                obj.eldHmnRcvNm = this.bnfcrCntrc.eldHmnRcvNm
                obj.bnfcrCntrcInfPk = this.bnfcrCntrc.bnfcrCntrcInfPk
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
                obj.userNm = this.$store.getters.getUserNm

                setBnfcrCntrcEld(obj)
                    .then((response) => ( this.insCntrcAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/updBnfcrCntrcAgr : ' + error))
            }
        },
        setCntrcSft: function () {
            let chk = this.$refs.sftForm.validate()
            if(chk){
                let obj = {}
                obj.sftNtcOfrDt = this.$moment(this.bnfcrCntrc.sftNtcOfrDt, 'YYYY-MM-DD').format('YYYYMMDD')
                obj.sftNtcOfrYn = this.bnfcrCntrc.sftNtcOfrYn
                obj.sftNtcRcvPstnCd = this.bnfcrCntrc.sftNtcRcvPstnCd
                obj.sftNtcRcvNm = this.bnfcrCntrc.sftNtcRcvNm
                obj.bnfcrCntrcInfPk = this.bnfcrCntrc.bnfcrCntrcInfPk
                obj.fcltyNum = this.$store.getters.getFcltyNum
                obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
                obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
                obj.userNm = this.$store.getters.getUserNm

                setBnfcrCntrcSft(obj)
                    .then((response) => ( this.insCntrcAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/updBnfcrCntrcAgr : ' + error))
            }
        },
        setBnfcrCntrcCnFm: function (pk, list) {
            setCntrcCnfm(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, pk, list)
                .then((response) => ( this.insCntrcfmItmAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insBnfcrCntrcCnFm : ' + error))
        },
        delCntrcAgr: function () {
            let obj = {}
            obj.prvcyAgrDt = ''
            obj.prvcyAgrYn = ''
            obj.bnfcrCntrcInfPk = this.bnfcrCntrc.bnfcrCntrcInfPk
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.userNm = this.$store.getters.getUserNm

            setBnfcrCntrcAgr(obj)
                .then((response) => ( this.insCntrcAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updBnfcrCntrcAgr : ' + error))
        },
        delCntrcEld: function () {
            let obj = {}
            obj.eldHmnOfrDt = ''
            obj.eldHmnOfrYn = ''
            obj.eldHmnRcvPstnCd = ''
            obj.eldHmnRcvNm = ''
            obj.bnfcrCntrcInfPk = this.bnfcrCntrc.bnfcrCntrcInfPk
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.userNm = this.$store.getters.getUserNm

            setBnfcrCntrcEld(obj)
                .then((response) => ( this.insCntrcAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updBnfcrCntrcAgr : ' + error))
        },
        delCntrcSft: function () {
            let obj = {}
            obj.sftNtcOfrDt = ''
            obj.sftNtcOfrYn = ''
            obj.sftNtcRcvPstnCd = ''
            obj.sftNtcRcvNm = ''
            obj.bnfcrCntrcInfPk = this.bnfcrCntrc.bnfcrCntrcInfPk
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            obj.bnfcrPk = this.$store.getters.getBnfcrState.bnfcr
            obj.userNm = this.$store.getters.getUserNm

            setBnfcrCntrcSft(obj)
                .then((response) => ( this.insCntrcAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/updBnfcrCntrcAgr : ' + error))
        },
        getCntrcMmbrPk: function (res) {         
            if(res.bnfcrCntrcInfPk != 0){
                if(res.prvcyAgrDt.length == 0)
                    res.prvcyAgrDt = ''
                else
                    res.prvcyAgrDt = res.prvcyAgrDt?.beforeDateFormatHyp()
                if(res.eldHmnOfrDt.length == 0)
                    res.eldHmnOfrDt = ''
                else
                    res.eldHmnOfrDt = res.eldHmnOfrDt?.beforeDateFormatHyp()
                if(res.sftNtcOfrDt.length == 0)
                    res.sftNtcOfrDt = ''
                else
                    res.sftNtcOfrDt = res.sftNtcOfrDt?.beforeDateFormatHyp()

                this.bnfcrCntrc = res
                this.forceRender += 1
            }
        },
        insCntrcAfter: function (res) {
            if(res.statusCode == 200){
                this.getCntrcList(this.cntrcInfPk)
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])

        },
        insCntrcfmItmAfter: function (res) {
            if(res.statusCode == 200){
                this.getCntrcfmList(this.cntrcInfPk)
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])
        },
        hisBnfcrCntrcDcmntChanger: function () {
            let tmp = this.$store.getters.getTmpObj
            this.bnfcrCntrc = tmp.cntntJson.bnfcrCntrc
            tmp.cntntJson.bnfcrCntrc.prvcyAgrDt = tmp.cntntJson.bnfcrCntrc.prvcyAgrDt?.beforeDateFormatHyp()
            tmp.cntntJson.bnfcrCntrc.eldHmnOfrDt = tmp.cntntJson.bnfcrCntrc.eldHmnOfrDt?.beforeDateFormatHyp()
            tmp.cntntJson.bnfcrCntrc.sftNtcOfrDt = tmp.cntntJson.bnfcrCntrc.sftNtcOfrDt?.beforeDateFormatHyp()

            this.$store.commit('setTmpObj', '')
        },
        prtAgrCall: function () {
            this.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.prtThrowObj.prtCd = '25'
            this.$parent.$parent.$parent.prtThrowObj.prtClcd = '1'

            this.$parent.$parent.$parent.rprtActCall()
            this.$parent.$parent.$parent.menu.splice(0, 1, true)
        },
        prtEldCall: function () {
            this.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.prtThrowObj.prtCd = '201'
            this.$parent.$parent.$parent.prtThrowObj.prtClcd = '2'

            this.$parent.$parent.$parent.rprtActCall()
            this.$parent.$parent.$parent.menu.splice(0, 1, true)
        },
        prtSftCall: function () {
            this.$parent.$parent.$parent.prtThrowObj.list[0].bzClcd.splice(0)
            this.$parent.$parent.$parent.prtThrowObj.list[0].bzPk.splice(0)
            this.$parent.$parent.$parent.prtThrowObj.prtCd = '26'
            this.$parent.$parent.$parent.prtThrowObj.prtClcd = '1'

            this.$parent.$parent.$parent.rprtActCall()
            this.$parent.$parent.$parent.menu.splice(0, 1, true)
        },
        agrMsg: function () {
            this.$parent.$parent.$parent.bizMsgInfo.bzTitle = '개인정보 수집 및 이용 동의서'
            this.$parent.$parent.$parent.bizMsgInfo.docClcd = 2
            this.$parent.$parent.$parent.sendMsgAct(this.bnfcrCntrc.bnfcrCntrcInfPk)
        },
        eldMsg: function () {
            this.$parent.$parent.$parent.bizMsgInfo.bzTitle = '노인인권 보호지침 제공'
            this.$parent.$parent.$parent.bizMsgInfo.docClcd = 3
            this.$parent.$parent.$parent.sendMsgAct(this.bnfcrCntrc.bnfcrCntrcInfPk)
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
            //this.snackOptions[0] = true
            //this.snackOptions[1] = 2500
            //this.snackOptions[2] = '신규 작성 모드'
            //info, success, warning, error
            //this.snackOptions[3] = 'info'
        },
    },
    
    data: () => ({
        cntrcInfPk:-1,
        menu: [false, false, false, false],
        bnfcrMmSrc: { src: '', org: '' },
        galMmScr: { src: '', org: '' },
        forceRender: 0,
        cntrcCnfm: [
            {
                cntrcCnfmItmPk: 1, bnfcrCntrcCnfmPk: 0, cnfmYn: '2', cnfmCntnt: '침상 난간 올리기',
                cnfmCntntDtl: '낙상을 예방하기 위하여 취침 시 침상 난간을 올립니다.',
            },
            {
                cntrcCnfmItmPk: 2, bnfcrCntrcCnfmPk: 0, cnfmYn: '2', cnfmCntnt: '휠체어 안전벨트 착용',
                cnfmCntntDtl: '낙상을 예방하기 위하여 휠체어 타실 때 안전벨트를 착용합니다.',
            },
            {
                cntrcCnfmItmPk: 3, bnfcrCntrcCnfmPk: 0, cnfmYn: '2', cnfmCntnt: '휠체어 식판 제공',
                cnfmCntntDtl: '식사 편의와 안전을 위해 휠체어에서 식자제공 시 휠체어 식판을 제공합니다.',
            },
            {
                cntrcCnfmItmPk: 4, bnfcrCntrcCnfmPk: 0, cnfmYn: '2', cnfmCntnt: '목욕 의자 벨트 착용',
                cnfmCntntDtl: '낙상을 예방하기 위하여 목욕 시 벨트를 착용합니다.',
            },
        ],
        bnfcrCntrc: {},
        dummyBnfcrCntrc: {
            bnMmbrPk: 0, snackCntCd: '', sftNtcOfrDt: '', bnfcrCntrcInfPk: 0, btyFee: 0, snackOfrClcd: '', eldHmnRcvNm: '', pymntDt: '',
            eldHmnOfrDt: '', eldHmnRcvPstnCd: '', cntrcPrdEnd: '', prvcyAgrYn: '', sftNtcRcvNm: '', cntrcPrdStrt: '', sftNtcOfrYn: '', cntrcDt: '',
            prvcyAgrDt: '', sftNtcRcvPstnCd: '', lstYn: '', snackFee: 0, mealCostLmtYn: '', lqdFood: 0, mfdRsn: '', mealCalcCd: '1', eldHmnOfrYn: '',
            lqdFoodClcd: '', pymntMthdCd: '', npymntFeeStd: '1', mealCost: 0, mealCostLmt: 0, garMmbrPk: 0,
        },
        rules: {
            required: value => !!value || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>

<style>
    .cnsntIcon .v-input__control .v-input__slot div { margin-top: 2px; }
    .cnsntIcon .v-input__control .v-input__slot div .v-input--selection-controls__ripple { margin-top: 7px; }
    .cnsntIcon .v-input__control .v-input__slot label { margin-left: 10px; font-size: 1.1rem; color: #666 !important; font-weight: 500; }
</style>