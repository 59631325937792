<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute centered top style="top:120px;">
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col cols="6">
                <span class="text-h4 font-weight-bold">계약서&nbsp;확인사항&nbsp;관리</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>

        <v-divider class="mt-7"></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0 tableBR" cols="10">
                <span class="black--text font-weight-medium py-2">확인&nbsp;내용</span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                <span class="black--text font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex justify-center align-center pa-1 ma-0 tableBR" cols="10">
                <v-textarea
                    class="rmrksArea" rows="5"
                    v-model="area"
                    no-resize hide-details outlined dense>
                </v-textarea>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="2">
                <span class="black--text font-weight-medium">
                    <v-btn class="" color="blueBtnColor" height="26" @click="setCntrcfmItem" icon>
                        <v-icon>mdi-content-save-edit-outline</v-icon>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        
        <div class="overflow-y-auto" style="height: 390px;">
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0 tableBB" no-gutters
                v-for="(list, i) in $parent.$parent.$parent.$parent.cntrcCnfm" :key="i">
                <v-col class="d-flex justify-left align-center pa-0 ma-0 tableBR" cols="10">
                    <span class="black--text font-weight-medium py-2">
                        {{list.cnfmCntnt}}
                    </span>
                </v-col>
                <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium">
                        <v-btn class="" color="grey006" height="26" @click="delCntrcCnfmItm(list.cntrcCnfmItmPk)" icon>
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { setCntrcCnfmItm, delCntrcCnfmItm } from '../../api/bnfcr.js';

export default {
    name: 'BnfcrBasicCnFmItm',
            
    components: {
    
    },
    
    mounted: function(){
     
    },
        
    methods: {
        setCntrcfmItem: function () {
            setCntrcCnfmItm(this.$store.getters.getFcltyNum, this.area)
                .then((response) => ( this.afterCntrcfmItem(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/insCntrcCnfmItm : ' + error))
        },
        delCntrcCnfmItm: function (pk) {
            delCntrcCnfmItm(pk, this.$store.getters.getUserNm)
                .then((response) => ( this.afterCntrcfmItem(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/delCntrcCnfmItm : ' + error))
        },
        afterCntrcfmItem: function (res) {
            if(res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.$parent.$parent.$parent.$parent.getCntrcfmList()
                this.area = ''
                this.$emit('cntrcfmListLoad', true)
            }
            else this.snackControll([true, 2500, res.message, 'error'])
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClose: function () {
            this.$emit('modalClose', 0)
        },
    },
    
    data: () => ({
        area: '',
        cntrcCnfm: [],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>
