import { http } from '@/api/baseAxios';

//은행코드 조회
function selBnkList() {
    return http.post('impExp/selBnkList', {})
}

//직원임금명세전체 조회
function selAllEmplWgePymList(code, yymm) {
    return http.post('impExp/selAllEmplWgePymList', {fcltyNum:code,inqYYMM:yymm})
}

//직원임금정보 한건 조회
function selEmpWgeInfo(code, pk) {
    return http.post('impExp/selEmpWgeInfo', {fcltyNum:code,empPk:pk})
}

//직원임금정보 한건 조회
function selEmpWgeHisInfo(code, pk, yymm) {
    return http.post('impExp/selEmpWgeHisInfo', { fcltyNum: code, empPk: pk, inqYYMM: yymm })
}

//임금명세 근무정보 조회
function selWgeWrkList(code, yymm, pk) {
    return http.post('impExp/selWgeWrkList', {fcltyNum:code,inqYYMM:yymm,empPk:pk})
}

//직원임금명세 다건 조회
function selEmpWgePymList(code, yymm, pk) {
    return http.post('impExp/selEmpWgePymList', {fcltyNum:code,inqYYMM:yymm,empPk:pk})
}

//직원임금명세 다건 조회
function selWgeNtcSndList(obj) {
    return http.post('impExp/selWgeNtcSndList', obj)
}

//기관부담명세전체 조회
function selAllFcltylWgePymList(code, yymm) {
    return http.post('impExp/selAllFcltylWgePymList', {fcltyNum:code,inqYYMM:yymm})
}

//요양급여가산관리 기본정보 조회
function selBnftAddBscInfo(code, yymm) {
    return http.post('impExp/selBnftAddBscInfo', {fcltyNum:code,inqYYMM:yymm})
}

function selBnftAddScrInfo(obj) {
    return http.post('impExp/selBnftAddScrInfo', obj)
}

//손익항목관리 정보 조회
function selPrfLosItmList(code, clcd) {
    return http.post('impExp/selPrfLosItmList', {fcltyNum:code,calcClcd:clcd})
}

//기관손익관리 정보 조회
function selFcltyPrfLosList(code, yymm, stdclcd, clcd=null) {
    return http.post('impExp/selFcltyPrfLosList', {fcltyNum:code,inqYYMM:yymm,stdClcd:stdclcd,calcClcd:clcd})
}

//직원임금형태다건 조회
function selEmpWgeTypList(code) {
    return http.post('impExp/selEmpWgeTypList', {fcltyNum:code})
}

//직원임금항목 다건 조회
function selEmpWgeItmList(code, pk) {
    return http.post('impExp/selEmpWgeItmList', {fcltyNum:code, empPk:pk})
}

//기관임금정보 다건 조회
function selFcltyWgeItmList(code) {
    return http.post('impExp/selFcltyWgeItmList', {fcltyNum:code})
}

//임금명세서 생성
function insWgePym(code, yymm, pks, user) {
    return http.post('impExp/insWgePym', {fcltyNum:code,inqYYMM:yymm,empPks:pks, userNm:user})
}


//임금생성 확정처리
function updlWgePymCnfrm(obj) {
    return http.post('impExp/updlWgePymCnfrm', obj)
}
    
//임금지급상세 저장
function updWgePymMulti(obj) {
    return http.post('impExp/updWgePymMulti', obj)
}

//손익항목관리 저장
function insPrfLosItm(obj) {
    return http.post('impExp/insPrfLosItm', obj)
}

//손익항목관리 삭제
function delPrfLosItm(obj) {
    return http.post('impExp/delPrfLosItm', obj)
}

//기관손익관리 저장 (다건)
function insMultiFcltyPrfLos(obj) {
    return http.post('impExp/insMultiFcltyPrfLos', obj)
}

//기관임금항목 저장
function insFcltyWgeItm(obj) {
    return http.post('impExp/insFcltyWgeItm', obj)
}

//기관임금항목 삭제
function delFcltyWgeItm(code,pk) {
    return http.post('impExp/delFcltyWgeItm', {fcltyNum:code,fcltyWgeItmPk:pk})
}

//임금기본항목 다건 조회
function selWgeItmList(code, use) {
    return http.post('impExp/selWgeItmList', {fcltyNum:code, useYn:use})
}

//임금항목 저장(다건 처리)
function insMultiWgeItm(obj) {
    return http.post('impExp/insMultiWgeItm', obj)
}

//임금항목 저장(한건 처리)
function insWgeItm(obj) {
    return http.post('impExp/insWgeItm', obj)
}

//임금항목 삭제(한건 처리)
function delWgeItm(obj) {
    return http.post('impExp/delWgeItm', obj)
}

//직원임금항목(다건)
function insMultiEmpWgeItm(obj) {
    return http.post('impExp/insMultiEmpWgeItm', obj)
}

//기관임금항목 저장(다건)
function insMultiFcltyWgeItm(obj) {
    return http.post('impExp/insMultiFcltyWgeItm', obj)
}

//인건비지출비율 저장
function insLbrexRto(obj) {
    return http.post('impExp/insLbrexRto', obj)
}

//인건비지급내역 저장
function insLbrexPym(obj) {
    return http.post('impExp/insLbrexPym', obj)
}

//인건비지급비율 한건 조회
function selLbrexRtoInfo(code, yyyy, yymm) {
    return http.post('impExp/selLbrexRtoInfo', {fcltyNum:code ,inqYYYY:yyyy, inqYYMM:yymm})
}

//인건비지급비율 한건 조회
function selLbrexPymList(code, yymm) {
    return http.post('impExp/selLbrexPymList', {fcltyNum:code, inqYYMM:yymm})
}

//기관임금지급상세 수정
function updFcltyWgePym(obj) {
    return http.post('impExp/updFcltyWgePym', obj)
}

//모바일 직원임금명세서 다건 조회	
function selMobEmpWgePymList(code, yymm, pk) {
    return http.post('impExp/selMobEmpWgePymList', {fcltyNum:code,inqYYMM:yymm,empPk:pk})
}

//임금명세서 비고 저장
function updWgePymHisRmrks(obj) {
    return http.post('impExp/updWgePymHisRmrks', obj)
}

//공제 다건 저장
function updWgeDctnMulti(obj) {
    return http.post('impExp/updWgeDctnMulti', obj)
}

//임금명세서 상세조회
function selEmpWgeStmtInfo(code, yymm, pk) {
    return http.post('impExp/selEmpWgeStmtInfo', {fcltyNum:code,inqYYMM:yymm,empPk:pk})
}

// 임금명세서 엑셀 생성 데이터 조회
function selExlEmplWgeEtcHdInfo(code, yymm) {
    return http.post('impExp/selExlEmplWgeEtcHdInfo', { fcltyNum: code, inqYYMM: yymm })
}

// 직원별 임금명세 조회
function selEmplWgePymList(obj) {
    return http.post('impExp/selEmplWgePymList', obj)
}

// 직원연간임금내역 조회 (excel)
function selExlEmplWgeList2(code, yyyy, pk) {
    return http.post('impExp/selExlEmplWgeList2', { fcltyNum: code, inqYYYY: yyyy, empPk: pk })
}

//직원임금정보 조회(8-3)
function selEmpWgeTypViw(obj) {
    return http.post('impExp/selEmpWgeTypViw', obj)
}

//손익항목상세 저장
function insPrfLosItmDtl(obj) {
    return http.post('impExp/insPrfLosItmDtl', obj)
}

//손익항목상세 삭제
function delPrfLosItmDtl(obj) {
    return http.post('impExp/delPrfLosItmDtl', obj)
}

//손익항목상세 정보 조회
function selPrfLosItmDtlList(obj) {
    return http.post('impExp/selPrfLosItmDtlList', obj)
}

//기관손익상세 저장
function insMultiFcltyPrfLosDtl(obj) {
    return http.post('impExp/insMultiFcltyPrfLosDtl', obj)
}

//기관손익상세관리 삭제
function delFcltyPrfLosDtl(obj) {
    return http.post('impExp/delFcltyPrfLosDtl', obj)
}

//기관손익관리상세
function selFcltyPrfLosDtlList(obj) {
    return http.post('impExp/selFcltyPrfLosDtlList', obj)
}

export {
    selBnkList,
    selAllEmplWgePymList,
    selWgeWrkList,
    selEmpWgePymList,
    selWgeNtcSndList,
    selAllFcltylWgePymList,
    selBnftAddBscInfo,
    selBnftAddScrInfo,
    selEmpWgeInfo,
    selEmpWgeHisInfo,
    selPrfLosItmList,
    selFcltyPrfLosList,
    selEmpWgeTypList,
    selEmpWgeItmList,
    selWgeItmList,
    selFcltyWgeItmList,
    insWgePym,
    updlWgePymCnfrm,
    updWgePymMulti,
    insPrfLosItm,
    delPrfLosItm,
    insMultiFcltyPrfLos,
    insFcltyWgeItm,
    delFcltyWgeItm,
    insMultiWgeItm,
    insWgeItm,
    delWgeItm,
    insMultiEmpWgeItm,
    insMultiFcltyWgeItm,
    insLbrexRto,
    insLbrexPym,
    selLbrexRtoInfo,
    selLbrexPymList,
    updFcltyWgePym,
    selMobEmpWgePymList,
    updWgePymHisRmrks,
    updWgeDctnMulti,
    selEmpWgeStmtInfo,
    selExlEmplWgeEtcHdInfo,
    selEmplWgePymList,
    selExlEmplWgeList2,
    selEmpWgeTypViw,
    insPrfLosItmDtl,
    delPrfLosItmDtl,
    selPrfLosItmDtlList,
    insMultiFcltyPrfLosDtl,
    delFcltyPrfLosDtl,
    selFcltyPrfLosDtlList,
}