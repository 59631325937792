<template>
    <v-sheet class="ma-0 pa-7" rounded="md">
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="4">
                <span class="text-h4 font-weight-bold">직원정보&nbsp;수정</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col cols="12">
                <v-tabs hide-slider v-model="tab" color="white" class="black--text"
                    active-class="bodyColor" center-active>
                    <v-tab v-for="item in tabItems" :style="item.style" :key="item.tab" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>

                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <EmpInfoModal ref="mfyEmpInfo"></EmpInfoModal>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <EmpWrkngHours ref="mfyEmpWrkng"></EmpWrkngHours>
                    </v-tab-item>
                    <v-tab-item eager :transition="false">
                        <EmpInsrnCrtfc ref="mfyInsrnCrtfc"></EmpInsrnCrtfc>
                    </v-tab-item>
                    <v-tab-item :transition="false">
                        <EmpSign ref="mfyEmpSign"></EmpSign>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { insEmp, delEmp, selEmpAcntIdSrch, insEmpAcnt } from '../../api/emp.js';

import EmpInfoModal from './EmpInfoModal.vue';
import EmpWrkngHours from './EmpWrkngHours.vue';
import EmpInsrnCrtfc from './EmpInsrnCrtfc.vue';
import EmpSign from './EmpSign.vue';

export default {
    name: 'EmpModifyModal',

    props : {
   
    },
        
    components: {
        EmpInfoModal,
        EmpWrkngHours,
        EmpInsrnCrtfc,
        EmpSign,
    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
     
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        setEmpInfo: function (obj) {
            insEmp(obj)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmp : ' + error))
        },
        setAcntInfo: function (obj) {
            insEmpAcnt(obj)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpAcnt : ' + error))
        },
        removeEmpInfo: function (pk) {
            delEmp(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.asyncResultChk(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmp : ' + error))
        },
        pwdCk: function (id) {
            selEmpAcntIdSrch(this.$store.getters.getFcltyNum, id)
                .then((response) => ( this.pwdCkBack(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpAcntIdSrch : ' + error))
        },
        asyncResultChk: function (res) {
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])

                switch (this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.trgCd) {
                    case '':
                    case '1':
                        this.$parent.$parent.$parent.$parent.$parent.$parent.getEmpList('1')
                        break;
                    default:
                        this.$parent.$parent.$parent.$parent.$parent.$parent.getEmpList(
                            this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.trgCd
                        )
                        break;
                }

                this.$parent.$parent.$parent.$parent.$refs.empGrpModal.getEmpGrpList()
                this.$refs.mfyEmpInfo.clearForm()
                this.$refs.mfyEmpWrkng.clearForm()
                this.onClose()

                this.$parent.$parent.$parent.$parent.$parent.$parent.sltEmpPk = -1
                this.$parent.$parent.$parent.$parent.$parent.$parent.filterSearch(this.$parent.$parent.$parent.$parent.$parent.$parent.schChkField.wrkStcd)
            }
            else
                this.$refs.mfyEmpInfo.snackControll([true, 5000, res.message, 'error'])
        },
        pwdCkBack: function (res) {
            this.$refs.mfyEmpInfo.pwdEqChk()
            if(res.result == '0')
                this.$refs.mfyEmpInfo.acntInfo.id_res = false
            else
                this.$refs.mfyEmpInfo.acntInfo.id_res = true
        },
        onClose: function () {
            this.$emit('modalClose', '1')
        },
    },

    data: () => ({
        tab: 0,
        asgEmpInfo: null,
        tabItems: [
            { index: 0, tab: '직원정보수정', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: false },
            { index: 1, tab: '근무시간', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: false },
            { index: 2, tab: '보험·자격증', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: false },
            { index: 3, tab: '전자서명', style: 'font-size: 1.2rem !important; padding: 0px 60px;', enter: false },
        ],
    }),
};
</script>