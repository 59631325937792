<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col cols="7">
                <span v-if="sltTab==0" class="text-h4 font-weight-bold">수급자정보관리&nbsp;저장</span>
                <span v-else-if="sltTab==1" class="text-h4 font-weight-bold">
                    <span v-if="sltSubTab==0" class="text-h4 font-weight-bold">계약정보&nbsp;저장</span>
                    <span v-else-if="sltSubTab==1" class="text-h4 font-weight-bold">계약서&nbsp;확인사항&nbsp;저장</span>
                </span>
                <span v-else-if="sltTab==3" class="text-h5 font-weight-bold">외출&#183;외박&nbsp;관리&nbsp;저장</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="1">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="callParents('닫기')">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">이력관리</span>
                </v-col>
                <v-col class="pa-0 ma-0 pl-2 pt-3 pr-3" cols="10">
                    <span
                        v-if="sltSubTab == 0 && sltTab != 3"
                        class="d-inline-block mx-1 mb-3" style="width: 100%;">
                        <v-checkbox
                            v-model="hisChc" :disabled="hisChg && (sltTab == 0 || sltTab == 1)"
                            class="clmHisCheck" true-value="1" false-value="2" label="변경 이력 저장"
                            on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                            hide-details>
                        </v-checkbox>
                    </span>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="2">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">변경사유</span>
                </v-col>
                <v-col class="pa-0 ma-0 pl-2 pt-3 pr-3" cols="10">
                    <v-textarea
                        class="rmrksArea" rows="5" ref="rmrksArea"
                        v-model="area" :rules="hisChc == '1' ? [rules.required] : []"
                        no-resize outlined dense>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-divider class="mt-4" v-if="hisChg && sltTab == 0"></v-divider>
            <v-row v-if="hisChg && sltTab == 0" class="pa-0 greyE01" no-gutters>
                <v-col class="py-1 tableBB tableBR" style="text-align: center;" cols="3">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">구분</span>
                </v-col>
                <v-col class="py-1 tableBB tableBR" style="text-align: center;" cols="3">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">적용시작일</span>
                </v-col>
                <v-col class="py-1 tableBB tableBR" style="text-align: center;" cols="3">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">적용종료일</span>
                </v-col>
                <v-col class="py-1 tableBB" style="text-align: center;" cols="3">
                    <span class="black--text font-weight-medium" style="font-size:1.1rem">내용</span>
                </v-col>
            </v-row>
            <v-row v-if="hisChg && sltTab == 0" class="pa-0" no-gutters>
                <v-col v-if="$store.state.bnfcrChgClmn.cfmSave[1] == '1'" cols="12">
                    <v-row class="pa-0 tableBB" no-gutters>
                        <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                            <span class="font-weight-medium fontOneRem">정부지원</span>
                        </v-col>
                        <v-col class="d-flex align-center px-2 py-1 tableBR" cols="3">
                            <CmmDateComp v-model="chgList[1].aplStrt" :required="true" :btnActv="false"></CmmDateComp>
                        </v-col>
                        <v-col class="d-flex align-center px-2 py-1 tableBR" cols="3">
                            <CmmDateComp v-model="chgList[1].aplEnd" :required="true" :btnActv="false"></CmmDateComp>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1 px-2" cols="3">
                            <span class="font-weight-medium fontOneRem">
                                {{ this.chgList[1].cntnt }}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="$store.state.bnfcrChgClmn.cfmSave[2] == '1'" cols="12">
                    <v-row class="pa-0 tableBB" no-gutters>
                        <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                            <span class="font-weight-medium fontOneRem">본인부담률</span>
                        </v-col>
                        <v-col class="px-2 py-1 tableBR" cols="3">
                            <CmmDateComp v-model="chgList[2].aplStrt" :required="true" :btnActv="false"></CmmDateComp>
                        </v-col>
                        <v-col class="d-flex justify-center align-center px-2 py-1 tableBR" cols="3">
                            <span>현재</span>
                            <!-- <CmmDateComp v-model="chgList[2].aplEnd" :required="true" :btnActv="false"></CmmDateComp> -->
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="3">
                            <span class="font-weight-medium fontOneRem">
                                {{ this.chgList[2].cntnt }}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="$store.state.bnfcrChgClmn.cfmSave[3] == '1'" cols="12">
                    <v-row class="pa-0 tableBB" no-gutters>
                        <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                            <span class="font-weight-medium fontOneRem">인정등급</span>
                        </v-col>
                        <v-col class="px-2 py-1 tableBR" cols="3">
                            <CmmDateComp v-model="chgList[3].aplStrt" :required="true" :btnActv="false"></CmmDateComp>
                        </v-col>
                        <v-col class="px-2 py-1 tableBR" cols="3">
                            <CmmDateComp v-model="chgList[3].aplEnd" :required="true" :btnActv="false"></CmmDateComp>
                        </v-col>
                        <v-col class="d-flex justify-center align-center py-1" cols="3">
                            <span class="font-weight-medium fontOneRem">
                                {{ this.chgList[3].cntnt }}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <v-row class="pa-0 mt-4" no-gutters>
            <v-col class="d-flex justify-center" cols="12">
                <v-btn v-for="(list, i) in filledBtn" :key="i" @click="callParents(list.text)"
                    :class="list.class" samll height="30" :color="list.color" rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    {{list.text}}
                </v-btn>
            </v-col>
        </v-row>

        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
      </v-snackbar>
    </v-sheet>
</template>

<script>
    import CmmDateComp from '../commons/CmmDateComp.vue';

    export default {
        name: 'BnfcrBasicSave',

        props : {
            sltTab: Number,
            sltSubTab: Number,
        },
            
        components: {
            CmmDateComp,
        },
    
        mounted: function(){
            //돔 로딩이 끝난 후 작업
            this.$nextTick(function () {
                let idx = this.$store.state.bnfcrChgClmn.cfmSave.findIndex( v => v == '1')

                if(idx > -1){
                    this.hisChc = '1'
                    this.hisChg = true
                }
                else{
                    this.hisChc = '2'
                    this.hisChg = false
                }

                if(this.sltTab == 1 && this.$store.state.bnfcrChgClmn.cfmSave[0] == '1')
                    this.area += '계약기간 변경, '
                if(this.sltTab == 0 && this.$store.state.bnfcrChgClmn.cfmSave[1] == '1')
                    this.area += '정부지원 변경, '
                if(this.sltTab == 0 && this.$store.state.bnfcrChgClmn.cfmSave[2] == '1')
                    this.area += '본인부담률 변경, '
                if(this.sltTab == 0 && this.$store.state.bnfcrChgClmn.cfmSave[3] == '1')
                    this.area += '인정등급 변경, '

                if(this.area.length > 0)
                    this.area = this.area.slice(0, -2)

                this.chgList[1].cntnt = ''
                this.chgList[1].valArr = this.$store.state.bnfcrChgClmn.chgGvrnmSprt
                let idx1 = this.$store.state.bnfcrChgClmn.chgGvrnmSprt.findIndex( v => v == '1')
                if(idx1 > -1)
                    this.chgList[1].cntnt += '간식비, '
                let idx2 = this.$store.state.bnfcrChgClmn.chgGvrnmSprt.findIndex( v => v == '2')
                if(idx2 > -1)
                    this.chgList[1].cntnt += '식사재료비, '

                if(this.chgList[1].cntnt.length > 0)
                    this.chgList[1].cntnt = this.chgList[1].cntnt.slice(0, -2)

                this.chgList[2].val = this.$store.state.bnfcrChgClmn.chgCopayRate
                this.chgList[2].cntnt = this.$store.state.bnfcrChgClmn.chgCopayRateNm
                this.chgList[3].val = this.$store.state.bnfcrChgClmn.chgAcknwRtng
                this.chgList[3].cntnt = this.$store.state.bnfcrChgClmn.chgAcknwRtngNm
                this.chgList[0].val = this.$store.state.bnfcrChgClmn.orgCntrcPrdEnd
                this.chgList[0].cntnt = this.$store.state.bnfcrChgClmn.chgCntrcPrdEnd
            })
        },

        computed: {
            getBnMmbrPk: function(){
                return this.$store.getters.getBnfcrState.bnmmbr
            },
            getBnfcrChgClmn: function(){
                return this.$store.state.bnfcrChgClmn.cfmSave
            },
            getGvrnmSprt: function(){
                return this.$store.state.bnfcrChgClmn.chgGvrnmSprt
            },
            getCopayRate: function(){
                return this.$store.state.bnfcrChgClmn.chgCopayRate
            },
            getAcknwRtng: function(){
                return this.$store.state.bnfcrChgClmn.chgAcknwRtng
            },
            getCntrcPrdEnd: function(){
                return this.$store.state.bnfcrChgClmn.chgCntrcPrdEnd
            },
        },

        watch: {
            getBnMmbrPk: function(){
                this.hisChc = '2'
                this.hisChg = false
            },
            'sltTab': function(){
                this.area = ''

                if(this.sltTab == 0){
                    
                    let arr = [...this.$store.state.bnfcrChgClmn.cfmSave]
                    arr.shift()
                    let idx = arr.findIndex( v => v == '1')
                    if(idx > -1){
                        this.hisChc = '1'
                        this.hisChg = true
                    }
                    else{
                        this.hisChc = '2'
                        this.hisChg = false
                    }
                }
                else if(this.sltTab == 1){
                    if(this.$store.state.bnfcrChgClmn.cfmSave[0] == '1'){
                        this.hisChc = '1'
                        this.hisChg = true
                    }
                    else{
                        this.hisChc = '2'
                        this.hisChg = false
                    }
                }
                else{
                    this.hisChg = false
                    this.hisChc = '2'
                }
            },
            getBnfcrChgClmn: function(arr){
                this.area = ''
                let idx = arr.findIndex( v => v == '1')
  
                if(idx > -1){
                    this.hisChc = '1'
                    this.hisChg = true
                }
                else{
                    this.hisChc = '2'
                    this.hisChg = false
                }

                if(this.sltTab == 1 && arr[0] == '1')
                    this.area += '계약기간 변경, '
                if(this.sltTab == 0 && arr[1] == '1')
                    this.area += '정부지원 변경, '
                if(this.sltTab == 0 && arr[2] == '1')
                    this.area += '본인부담률 변경, '
                if(this.sltTab == 0 && arr[3] == '1')
                    this.area += '인정등급 변경, '

                if(this.area.length > 0)
                    this.area = this.area.slice(0, -2)
            },
            getGvrnmSprt: function(){
                this.chgList[1].cntnt = ''
                this.chgList[1].valArr = this.$store.state.bnfcrChgClmn.chgGvrnmSprt
                let idx = this.$store.state.bnfcrChgClmn.chgGvrnmSprt.findIndex( v => v == '1')
                if(idx > -1)
                    this.chgList[1].cntnt += '간식비, '
                idx = this.$store.state.bnfcrChgClmn.chgGvrnmSprt.findIndex( v => v == '2')
                if(idx > -1)
                    this.chgList[1].cntnt += '식사재료비, '

                if(this.chgList[1].cntnt.length > 0)
                    this.chgList[1].cntnt = this.chgList[1].cntnt.slice(0, -2)
            },
            getCopayRate: function(){
                this.chgList[2].val = this.$store.state.bnfcrChgClmn.chgCopayRate
                this.chgList[2].cntnt = this.$store.state.bnfcrChgClmn.chgCopayRateNm
            },
            getAcknwRtng: function(){
                this.chgList[3].val = this.$store.state.bnfcrChgClmn.chgAcknwRtng
                this.chgList[3].cntnt = this.$store.state.bnfcrChgClmn.chgAcknwRtngNm
            },
            getCntrcPrdEnd: function(){
                this.chgList[0].val = this.$store.state.bnfcrChgClmn.chgCntrcPrdEnd
                this.chgList[0].cntnt = this.$store.state.bnfcrChgClmn.chgCntrcPrdEnd
                // if(this.$store.state.bnfcrChgClmn.orgCntrcPrdEnd != this.$store.state.bnfcrChgClmn.chgCntrcPrdEnd){
                //     this.hisChc = '1'
                //     this.hisChg = true
                // }
                // else{
                //     this.hisChc = '2'
                //     this.hisChg = false
                // }
            },
        },
        
        methods: {
            callParents: function(value){
                if(value == '닫기') this.$emit('madalState', 1)
                else if(value == '저장'){
                    let chk = this.$refs.form.validate()

                    if(chk){
                        this.$store.commit('setBnfcrSaveArea', this.area)
                        this.$store.commit('setBnfcrSaveChc', this.hisChc)
                        if(this.sltTab == 0) this.$emit('madalState', 9999)
                        else if(this.sltTab == 1 && this.sltSubTab == 0) this.$emit('madalState', 9998)

                        if(this.sltSubTab == 1 && this.$store.getters.getBnfcrState.bnfcrCntrcInfPk != 0)
                            this.$emit('madalState', 9997)

                        this.area = ''
                        this.hisChc = '2'
                    }
                    else{
                        this.$refs.rmrksArea.focus()
                        this.$refs.rmrksArea.blur()
                    }
                }

                this.$refs.form.resetValidation()
            },
        },
    
        data: () => ({
            area: '',
            hisChc: '2',
            hisChg: false,
            chgList: [
                { mdfClcd: '4', aplStrt: '', aplEnd: '', valArr: [], cntnt: '' },
                { mdfClcd: '3', aplStrt: '', aplEnd: '', valArr: [], cntnt: '' },
                { mdfClcd: '2', aplStrt: '', aplEnd: '2099-12-31', val: '', cntnt: '' },
                { mdfClcd: '1', aplStrt: '', aplEnd: '', val: '', cntnt: '' },
            ],
            filledBtn: [
                { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', },
                // { icon: 'mdi-close', class: 'ml-1 white--text', color:'grey006', text: '닫기', },
            ],
            rules: {
                required: value => !!value || '필수 입력 항목입니다.',
            },
            snackOptions: [false, 3000, '', 'info'],
        }),
    };
    </script>