<template>
    <v-sheet class="ma-0 overflow-y-auto pa-2" rounded="md">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="50"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn :class="list.class" height="30" :color="list.color"
                        :disabled="list.disabled" @click="onClickBtn(list.text)" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </span>
            <v-btn
                :class="outlinedBtn[0].class" :color="outlinedBtn[0].color" :disabled="outlinedBtn[0].disabled"
                @click="onClickBtn(outlinedBtn[0].text)" height="30" samll rounded outlined>
                <v-icon style="padding-top: 2px;" small>{{outlinedBtn[0].icon}}</v-icon>
                <span class="ml-1" style="font-size: 1rem;">{{outlinedBtn[0].text}}</span>
            </v-btn>
        </div>
        <v-divider class="my-2"></v-divider>
        <v-form ref="sdlform" lazy-validation>
            <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb; width: 900px;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">급식일</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text">{{ date }}</span> 
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>              
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                    <span class="d-inline-block pa-1" style="width:120px;">
                        <v-text-field
                            v-model="wrtr" 
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn 
                        class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                        min-width="30" height="28" @click="dialog.splice(0, 1, true)"
                        dark small depressed>
                        <v-tooltip top >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                            </template>
                            <span>직원선택</span>
                        </v-tooltip>
                    </v-btn>
                    <!-- <v-btn 
                        class="ml-10" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                        min-width="30" height="25" @click="getPreData"
                        small outlined>
                        <v-icon size="14">mdi-text-box-search-outline</v-icon>
                        <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
                    </v-btn> -->
                </v-col>
            </v-row>
            <div  style="height:700px;overflow-y:scroll;"> 
                <div class="d-flex align-center mt-3 ">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">근무현황 및 근무내용</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                        <span class="black--text font-weight-medium">근무현황</span>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:20%;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">영양사</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조리사 및 조리원</span>
                        </div>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:65%; height: 63px;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; border-bottom: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="rmrksVal[0]" hide-details outlined dense ></v-text-field>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; ">
                            <v-text-field v-model="rmrksVal[1]" hide-details outlined dense ></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;">
                        <span class="black--text font-weight-medium">근무내용</span>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:20%;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">영양사</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; border-left: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조리사 및 조리원</span>
                        </div>
                    </div>
                    <div class="d-inline-block pa-0 ma-0 align-self-start" style="width:65%; height: 63px;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; border-bottom: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="rmrksVal[2]" hide-details outlined dense ></v-text-field>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%; height: 50%; ">
                            <v-text-field v-model="rmrksVal[3]" hide-details outlined dense ></v-text-field>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-center mt-3">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">금일식단 및 평가</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:11%;">
                        <span class="black--text font-weight-medium">구분</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">조식</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">중식</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">석식</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:23%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">간식</span>
                    </div>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-bottom: 1px solid #dbdbdb !important;height: 290px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:11%;">
                        <span class="black--text font-weight-medium">메뉴</span>
                    </div>
                    <div class="d-flex justify-center align-start pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <v-textarea v-model="brkfsVal[0]"  class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="16" outlined dense hide-details no-resize disabled>
                        </v-textarea>
                    </div>
                    <div class="d-flex justify-center align-start pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <v-textarea v-model="lunchVal[0]"  class="rmrksArea" style="font-size:0.9rem !important; color:black !important;"
                                rows="16" outlined dense hide-details no-resize disabled>
                        </v-textarea>
                    </div>
                    <div class="d-flex justify-center align-start pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <v-textarea v-model="dinerVal[0]"  class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="16" outlined dense hide-details no-resize disabled>
                        </v-textarea>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:7%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center pa-1" style="width:100%; height: 50%; border-right: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">오전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1" style="width:100%; height: 50%; border-right: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">오후</span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:15%;">
                        <div class="d-flex justify-center align-start pa-1" style="width:100%; height: 50%;border-bottom: 1px solid #dbdbdb !important;">
                            <v-textarea v-model="snckAmVal[0]"  class="rmrksArea mt-1" style="font-size:0.9rem !important;"
                                    rows="7" outlined dense hide-details no-resize disabled>
                            </v-textarea>
                        </div>
                        <div class="d-flex justify-center align-start pa-1" style="width:100%; height: 50%;">
                            <v-textarea v-model="snckPmVal[0]"  class="rmrksArea mt-1" style="font-size:0.9rem !important;"
                                    rows="7" outlined dense hide-details no-resize disabled>
                            </v-textarea>
                        </div>
                    </div>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:11%;">
                        <span class="black--text font-weight-medium">평가</span>
                    </div>
                    <div class="d-inline-block justify-start align-center pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center ma-0">
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">양호</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">보통</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:34%;">불량</span>
                        </div>
                        <v-radio-group v-model="brkfsVal[1]" row class="d-flex pa-0 ma-0" style="width:100%;">
                            <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0" style="width:34%;"></v-radio>
                        </v-radio-group>
                    </div>
                    <div class="d-inline-block justify-start align-center pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center ma-0">
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">양호</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">보통</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:34%;">불량</span>
                        </div>
                        <v-radio-group v-model="lunchVal[1]" row class="d-flex pa-0 ma-0" style="width:100%;">
                            <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0" style="width:34%;"></v-radio>
                        </v-radio-group>
                    </div>
                    <div class="d-inline-block justify-start align-center pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center ma-0">
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">양호</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">보통</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:34%;">불량</span>
                        </div>
                        <v-radio-group v-model="dinerVal[1]" row class="d-flex pa-0 ma-0" style="width:100%;">
                            <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0" style="width:34%;"></v-radio>
                        </v-radio-group>
                    </div>
                    <div class="d-inline-block justify-start align-center pa-1 ma-0" style="width:22%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center ma-0">
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">양호</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:33%;">보통</span>
                            <span class="black--text font-weight-medium d-flex justify-center align-center" style="width:34%;">불량</span>
                        </div>
                        <v-radio-group v-model="snckAmVal[1]" row class="d-flex pa-0 ma-0" style="width:100%;">
                            <v-radio value="1" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="2" class="d-flex justify-center align-center pa-1 ma-0" style="width:33%;"></v-radio>
                            <v-radio value="3" class="d-flex justify-center align-center pa-1 ma-0" style="width:34%;"></v-radio>
                        </v-radio-group>
                    </div>
                    
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:11%;">
                        <span class="black--text font-weight-medium">비고</span>
                    </div>
                   
                    <div class="d-flex justify-start align-center pa-1 ma-0" style="width:89%; border-left: 1px solid #dbdbdb !important;">
                        <v-textarea v-model="rmrksVal[4]"  class="rmrksArea" style="font-size:0.9rem !important;"
                                rows="1" outlined dense hide-details no-resize>
                        </v-textarea>
                    </div>
                </div>
                <div class="d-flex align-center mt-3">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">식이&nbsp;및&nbsp;식수&nbsp;인원</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:16%;">
                        <span class="black--text font-weight-medium">구분</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">조식</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">중식</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">석식</span>
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">오전간식</span>    
                    </div>
                    <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:16%; border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">오후간식</span>
                    </div>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-bottom: 1px solid #dbdbdb !important;height: 253px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;">
                        <span class="black--text font-weight-medium">식사형태</span>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:12%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">일반식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">다진식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">죽식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">미음</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">경관식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="black--text font-weight-medium">기타</span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[2]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[3]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[4]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[5]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[6]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[7]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[2]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[3]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[4]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[5]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[6]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[7]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[2]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[3]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[4]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[5]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[6]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[7]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-start pa-1 ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                    </div>
                    <div class="d-flex justify-center align-start pa-1 ma-0" style="width:16%; border-left: 1px solid #dbdbdb !important;">
                    </div>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;height: 84px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-bottom: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">수급자</span>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:12%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">식사인원</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">외출, 외박</span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[8]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="brkfsVal[9]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[8]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="lunchVal[9]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[8]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="dinerVal[9]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="snckAmVal[2]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:16%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <v-text-field v-model="snckPmVal[1]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                        </div>
                    </div>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;border-bottom: 1px solid #dbdbdb !important;height: 43px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:16%;">
                        <span class="black--text font-weight-medium">직원&nbsp;식사인원</span>
                    </div>
                    <div class="d-flex justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <span class="d-inline-block blnftAmntlnf-text-field mt-2" style="width:70%; text-align: right !important;">
                            <v-text-field v-model="brkfsVal[10]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <span class="d-inline-block blnftAmntlnf-text-field mt-2" style="width:70%; text-align: right !important;">
                            <v-text-field v-model="lunchVal[10]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                        <span class="d-inline-block blnftAmntlnf-text-field mt-2" style="width:70%; text-align: right !important;">
                            <v-text-field v-model="dinerVal[10]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-start ma-0" style="width:17%; border-left: 1px solid #dbdbdb !important;">
                    </div>
                    <div class="d-flex justify-center align-start ma-0" style="width:16%; border-left: 1px solid #dbdbdb !important;">
                    </div>
                </div>
                <div class="d-flex align-center mt-3">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">보존식관리</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px; border-top: 1px solid #dbdbdb !important; border-bottom: 1px solid #dbdbdb !important;height: 253px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;">
                        <span class="black--text font-weight-medium">보존식</span>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:18%;border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">보존일</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">폐기일</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">배식완료시간</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조리완료시간</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="black--text font-weight-medium">담당자</span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:26%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">조식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="brkfsVal[11]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmDateComp v-model="brkfsVal[11]" :required="false"></CmmDateComp>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="brkfsVal[12]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmDateComp v-model="brkfsVal[12]" :required="false"></CmmDateComp>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="brkfsVal[13]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="brkfsVal[13]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="brkfsVal[14]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="brkfsVal[14]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="brkfsVal[15]" style="text-align: right !important;" dense outlined hide-details></v-text-field> -->
                                <span class="d-inline-block pa-1" style="width:120px;">
                                    <v-text-field
                                        v-model="brkfsVal[15]" 
                                        hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn 
                                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                                    min-width="30" height="28" @click="dialog.splice(1, 1, true)"
                                    dark small depressed>
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:26%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">중식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="lunchVal[11]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmDateComp v-model="lunchVal[11]" :required="false"></CmmDateComp>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  v-model="lunchVal[12]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmDateComp v-model="lunchVal[12]" :required="false"></CmmDateComp>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  v-model="lunchVal[13]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="lunchVal[13]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  v-model="lunchVal[14]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="lunchVal[14]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field  v-model="lunchVal[15]" style="text-align: right !important;" dense outlined hide-details></v-text-field> -->
                                <span class="d-inline-block pa-1" style="width:120px;">
                                    <v-text-field
                                        v-model="lunchVal[15]" 
                                        hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn 
                                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                                    min-width="30" height="28" @click="dialog.splice(2, 1, true)"
                                    dark small depressed>
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </div>
                    </div>
                    <div class="d-inline-block justify-center align-start ma-0" style="width:26%; border-left: 1px solid #dbdbdb !important;">
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">석식</span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="dinerVal[11]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmDateComp v-model="dinerVal[11]" :required="false"></CmmDateComp>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="dinerVal[12]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmDateComp v-model="dinerVal[12]" :required="false"></CmmDateComp>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="dinerVal[13]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="dinerVal[13]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="dinerVal[14]" style="text-align: right !important;" dense outlined hide-details type="number"></v-text-field> -->
                                <CmmTimeField v-model="dinerVal[14]" :required="false"></CmmTimeField>
                            </span>
                        </div>
                        <div class="d-flex justify-center align-center" style="width:100%; height: 42px;">
                            <span class="d-inline-block blnftAmntlnf-text-field" style="width:70%; text-align: right !important;">
                                <!-- <v-text-field v-model="dinerVal[15]" style="text-align: right !important;" dense outlined hide-details></v-text-field> -->
                                <span class="d-inline-block pa-1" style="width:120px;">
                                    <v-text-field
                                        v-model="dinerVal[15]" 
                                        hide-details outlined dense>
                                    </v-text-field>
                                </span>
                                <v-btn 
                                    class="brown lighten-3 d-inline-block" style="padding:0px 1px 0px 2px !important"
                                    min-width="30" height="28" @click="dialog.splice(3, 1, true)"
                                    dark small depressed>
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                        </template>
                                        <span>직원선택</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-center mt-3">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">어르신의&nbsp;소리&nbsp;및&nbsp;특이사항</span>
                </div>
                <div class="d-flex pa-0 ma-0" no-gutters style="width:900px;height: 60px;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;">
                        <v-textarea v-model="seniorCtz"  class="rmrksArea" style="font-size:0.9rem !important; resize:none"
                                rows="3"  outlined dense hide-details no-resize>
                        </v-textarea>
                    </div>
                    
                </div>
            </div>
        </v-form>
        <v-dialog v-model="menu[0]" content-class="round" max-width="1000" persistent eager>
            <ReportMgmt ref="reportMgmt" @madalState="menu.splice(0, 1, false)"></ReportMgmt>
        </v-dialog>
        <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(0, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(1, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[2]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(2, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
        <v-dialog v-model="dialog[3]" eager content-class="round" persistent max-width="800">
            <EmpSelector @isClose="dialog.splice(3, 1, false)" @outEmp="getEmpInfo"></EmpSelector>
        </v-dialog>
        <v-dialog eager v-model="dialog[4]" persistent max-width="1000" min-width="1000">
            <MealSrvcLogDefault ref="MealSrvcLogDefault" @isClose="isModal()" @reload="reset()"></MealSrvcLogDefault>
        </v-dialog>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'급식운영일지'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>    
    </v-sheet>
</template>

<script>
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import { selMealSrvcJrnlList, insMealSrvcJrnl, delMealSrvcJrnl } from '../../api/bnftrcrd.js';
import ReportMgmt from '../../components/commons/ReportMgmt.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';
import CmmDateComp from '../../components/commons/CmmDateComp.vue';
import CmmTimeField from '../../components/commons/CmmTimeField.vue';
import MealSrvcLogDefault from '../../components/sfty/MealSrvcLogDefault.vue';

export default {
    name: 'MealSrvcLog',

    props : {
       
    },
        
    components: {
        EmpSelector,
        expandNowDatePicker,
        btnModalDelete,
        CmmDateComp,
        CmmTimeField,
        ReportMgmt,
        MealSrvcLogDefault,
    },

    created: function(){
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
    },

    watch:{
        'seniorCtz': function () {
            if(this.seniorCtz != null){
                if(this.seniorCtz.split('\n').length > 3){
                    this.plusColor = 'red'
                }else{
                    this.plusColor = 'black'
                }
            }
        },
    },
    
    methods: {
        reset(){
            this.selMealSrvcJrnlList(this.$parent.$parent.sltDate, 2)
        },
        isModal: function () {
            this.dialog.splice(4, 1, false)
        },
        getPreData:function(){
            let date = new Date(this.date)
            let dateArr = new Date(date.setDate(date.getDate())).toISOString().split('T',2)
            date = dateArr[0]?.afterDateFormatHyp()    
            this.selMealSrvcJrnlList(date, 1)
        },
        getEmpInfo: function (obj) {
            if(this.dialog[0]==true){
                this.wrtr = obj[0].name
            }else if(this.dialog[1]==true){
                this.brkfsVal[15] = obj[0].name
            }else if(this.dialog[2]==true){
                this.lunchVal[15] = obj[0].name
            }else if(this.dialog[3]==true){
                this.dinerVal[15] = obj[0].name
            }
        },
        getDate: function (value) {
            this.$parent.$parent.sltDate = value.date
            this.date = value.date?.beforeDateFormatDot()
            this.selMealSrvcJrnlList(this.date?.afterDateFormatHyp(), 2)

            let fIndex = this.$parent.$parent.sftyList.findIndex(v => v.mealSrvcDt.beforeDateFormatDot() == value.date.beforeDateFormatDot())
            if(fIndex != -1){
                this.$parent.$parent.selectedItem = value.date
            }
            else{
                this.$parent.$parent.selectedItem = value.date
            }
        },
        onClickBtn: function (text){
            if(text=='저장'){
                this.onSave()
            }else if(text=='삭제'){
                this.dialogDelete = true
            }else if(text=='출력'){
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.prtThrowObj.inqYMD= this.date?.afterDateFormatDot() 
                this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
                this.menu.splice(0, 1, true)
            }else if(text=='기본값 설정'){
                this.dialog.splice(4, 1, true)
                this.$refs.MealSrvcLogDefault.selMealSrvcJrnlDefaultList()
            }
        },
        selMealSrvcJrnlList: function (dt ,inqPreYn){
            let ymd = dt?.afterDateFormatDot()
            selMealSrvcJrnlList(this.$store.getters.getFcltyNum, ymd, inqPreYn)
                .then((response) => ( this.selMealSrvcJrnlListAfter(response.data) ))
                .catch((error) => console.log('connect error /sfty/selMealSrvcJrnlList : ' + error))
        },
        selMealSrvcJrnlListAfter: function (res) {
            this.progress = false
            this.pageReset()
            this.sftyList = res
            this.sftyList.forEach(e => {
                if(e.clcd1 == '1' && e.clcd2 == '1' && e.clcd3 =='1'){
                    if(e.rmrks!=null){
                        this.filledBtn[2].disabled = false
                        this.outlinedBtn[0].disabled = false
                    }else{
                        this.filledBtn[2].disabled = true
                        this.outlinedBtn[0].disabled = true
                    }
                    this.rmrksVal.push(e.rmrks)
                }
                if(e.clcd1 == '1' && e.clcd2 == '2' && e.clcd3 =='1'){ 
                    this.rmrksVal.push(e.rmrks)
                }
                if(e.clcd1 == '2' && e.clcd2 == '1' && e.clcd3 =='1'){
                    this.rmrksVal.push(e.rmrks)
                }
                if(e.clcd1 == '2' && e.clcd2 == '2' && e.clcd3 =='1'){
                    this.rmrksVal.push(e.rmrks)
                }
                // 0. 메뉴
                if(e.clcd1 == '3' && e.clcd2 == '1' && e.clcd3 == '1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                    this.snckAmVal.push(e.snckAm)
                    this.snckPmVal.push(e.snckPm)
                    this.rmrksVal.push(e.rmrks)
                }
                // 1. 평가
                if(e.clcd1 == '4' && e.clcd2 == '1' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                    this.snckAmVal.push(e.snckAm)
                }
                // 2. 일반식 식수
                if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                // 3. 다진식 식수
                if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='2'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                // 4. 죽식 식수
                if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='3'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                // 5. 미음 식수
                if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='4'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                // 6. 경관식 식수
                if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='5'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                // 7. 기타 식수
                if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='6'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                // 8. 수급자 식수
                if(e.clcd1 == '5' && e.clcd2 == '4' && e.clcd3 =='11'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                    this.snckAmVal.push(e.snckAm)
                    this.snckPmVal.push(e.snckPm)
                }
                // 9. 수급자 외출, 외박
                if(e.clcd1 == '5' && e.clcd2 == '4' && e.clcd3 =='12'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                // 10. 직원 식수
                if(e.clcd1 == '5' && e.clcd2 == '5' && e.clcd3 =='13'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                if(e.clcd1 == '6' && e.clcd2 == '6' && e.clcd3 == '1'){
                    this.brkfsVal.push(e.brkfs?.beforeDateFormatHyp())
                    this.lunchVal.push(e.lunch?.beforeDateFormatHyp())
                    this.dinerVal.push(e.diner?.beforeDateFormatHyp())
                }
                if(e.clcd1 == '6' && e.clcd2 == '7' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs?.beforeDateFormatHyp())
                    this.lunchVal.push(e.lunch?.beforeDateFormatHyp())
                    this.dinerVal.push(e.diner?.beforeDateFormatHyp())
                }
                if(e.clcd1 == '6' && e.clcd2 == '8' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                if(e.clcd1 == '6' && e.clcd2 == '9' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                if(e.clcd1 == '6' && e.clcd2 == '10' && e.clcd3 =='1'){
                    this.brkfsVal.push(e.brkfs)
                    this.lunchVal.push(e.lunch)
                    this.dinerVal.push(e.diner)
                }
                if(e.clcd1 == '7' && e.clcd2 == '1' && e.clcd3 =='1'){
                    this.seniorCtz = e.rmrks
                }
            });
            this.wrtr = this.sftyList[0].wrtr
            this.$refs.sdlform.resetValidation()
        },
        pageReset: function () {
            this.rmrksVal.splice(0)
            this.brkfsVal.splice(0)
            this.lunchVal.splice(0)
            this.dinerVal.splice(0)
            this.snckAmVal.splice(0)
            this.snckPmVal.splice(0)
            this.wrtr = ''
            this.seniorCtz = ''
        },
        onSave:function(){
                let list = []
                this.sftyList.forEach(e=>{
                    if(e.clcd1 == '1' && e.clcd2 == '1' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '1',
                            clcd2 : '1',
                            clcd3 : '1',
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            rmrks : this.rmrksVal[0],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr
                        }
                        list.push(item)
                    }if (e.clcd1 == '1' && e.clcd2 == '2' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '1',
                            clcd2 : '2',
                            clcd3 : '1',
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            rmrks : this.rmrksVal[1],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr,
                        }
                        list.push(item)
                    }if (e.clcd1 == '2' && e.clcd2 == '1' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '2',
                            clcd2 : '1',
                            clcd3 : '1',
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            rmrks : this.rmrksVal[2],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr,
                        }
                        list.push(item)
                        
                    }if (e.clcd1 == '2' && e.clcd2 == '2' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '2',
                            clcd2 : '2',
                            clcd3 : '1',
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            rmrks : this.rmrksVal[3],
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                            wrtr : this.wrtr,
                        }
                        list.push(item)
                        
                    }if (e.clcd1 == '3' && e.clcd2 == '1' && e.clcd3 == '1'){
                        let item={
                            clcd1 : '3',
                            clcd2 : '1',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[0],
                            lunch : this.lunchVal[0],
                            diner : this.dinerVal[0],
                            snckAm : this.snckAmVal[0],
                            snckPm : this.snckPmVal[0],
                            rmrks : this.rmrksVal[4],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if (e.clcd1 == '4' && e.clcd2 == '1' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '4',
                            clcd2 : '1',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[1],
                            lunch : this.lunchVal[1],
                            diner : this.dinerVal[1],
                            snckAm : this.snckAmVal[1],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='1'){
                        
                        let item={
                        clcd1 : '5',
                        clcd2 : '3',
                        clcd3 : '1',
                        brkfs : this.brkfsVal[2],
                        lunch : this.lunchVal[2],
                        diner : this.dinerVal[2],
                        wrtr : this.wrtr,
                        mealSrvcDt : this.date?.afterDateFormatDot(),
                        mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[2] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[2] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[2] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='2'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '3',
                            clcd3 : '2',
                            brkfs : this.brkfsVal[3],
                            lunch : this.lunchVal[3],
                            diner : this.dinerVal[3],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[3] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[3] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[3] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                       
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='3'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '3',
                            clcd3 : '3',
                            brkfs : this.brkfsVal[4],
                            lunch : this.lunchVal[4],
                            diner : this.dinerVal[4],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[4] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[4] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[4] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='4'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '3',
                            clcd3 : '4',
                            brkfs : this.brkfsVal[5],
                            lunch : this.lunchVal[5],
                            diner : this.dinerVal[5],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[5] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[5] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[5] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='5'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '3',
                            clcd3 : '5',
                            brkfs : this.brkfsVal[6],
                            lunch : this.lunchVal[6],
                            diner : this.dinerVal[6],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[6] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[6] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[6] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '3' && e.clcd3 =='6'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '3',
                            clcd3 : '6',
                            brkfs : this.brkfsVal[7],
                            lunch : this.lunchVal[7],
                            diner : this.dinerVal[7],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[7] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[7] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[7] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '4' && e.clcd3 =='11'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '4',
                            clcd3 : '11',
                            brkfs : this.brkfsVal[8],
                            lunch : this.lunchVal[8],
                            diner : this.dinerVal[8],
                            snckAm : this.snckAmVal[2],
                            snckPm : this.snckPmVal[1],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[8] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[8] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[8] == null){
                            item.diner = "0"
                        }
                        if(this.snckAmVal[2] == null){
                            item.snckAm = "0"
                        }
                        if(this.snckPmVal[1] == null){
                            item.snckPm = "0"
                        }
                        
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '4' && e.clcd3 =='12'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '4',
                            clcd3 : '12',
                            brkfs : this.brkfsVal[9],
                            lunch : this.lunchVal[9],
                            diner : this.dinerVal[9],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[9] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[9] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[9] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '5' && e.clcd2 == '5' && e.clcd3 =='13'){
                       let item={
                            clcd1 : '5',
                            clcd2 : '5',
                            clcd3 : '13',
                            brkfs : this.brkfsVal[10],
                            lunch : this.lunchVal[10],
                            diner : this.dinerVal[10],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        if(this.brkfsVal[10] == null){
                            item.brkfs = "0"
                        }
                        if(this.lunchVal[10] == null){
                            item.lunch = "0"
                        }
                        if(this.dinerVal[10] == null){
                            item.diner = "0"
                        }
                        
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '6' && e.clcd2 == '6' && e.clcd3 =='1'){
                       let item={
                            clcd1 : '6',
                            clcd2 : '6',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[11]?.afterDateFormatHyp(),
                            lunch : this.lunchVal[11]?.afterDateFormatHyp(),
                            diner : this.dinerVal[11]?.afterDateFormatHyp(),
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }
                    if(e.clcd1 == '6' && e.clcd2 == '7' && e.clcd3 =='1'){
                       let item={
                            clcd1 :'6',
                            clcd2 :'7',
                            clcd3 :'1',
                            brkfs :this.brkfsVal[12]?.afterDateFormatHyp(),
                            lunch :this.lunchVal[12]?.afterDateFormatHyp(),
                            diner :this.dinerVal[12]?.afterDateFormatHyp(),
                            wrtr :this.wrtr,
                            mealSrvcDt :this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk :e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '6' && e.clcd2 == '8' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '6',
                            clcd2 : '8',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[13],
                            lunch : this.lunchVal[13],
                            diner : this.dinerVal[13],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '6' && e.clcd2 == '9' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '6',
                            clcd2 : '9',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[14],
                            lunch : this.lunchVal[14],
                            diner : this.dinerVal[14],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '6' && e.clcd2 == '10' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '6',
                            clcd2 : '10',
                            clcd3 : '1',
                            brkfs : this.brkfsVal[15],
                            lunch : this.lunchVal[15],
                            diner : this.dinerVal[15],
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }if(e.clcd1 == '7' && e.clcd2 == '1' && e.clcd3 =='1'){
                        let item={
                            clcd1 : '7',
                            clcd2 : '1',
                            clcd3 : '1',
                            rmrks : this.seniorCtz,
                            wrtr : this.wrtr,
                            mealSrvcDt : this.date?.afterDateFormatDot(),
                            mealSrvcJrnlPk : e.mealSrvcJrnlPk,
                        }
                        list.push(item)
                        
                    }
                })
                let obj = true
                this.progress = true
                if(obj){
                    insMealSrvcJrnl(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, list)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insMealSrvcJrnl : ' + error))
                }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.pageReset()
                this.selMealSrvcJrnlList(this.$parent.$parent.sltDate, 2)
                this.$refs.MealSrvcLogDefault.selMealSrvcJrnlDefaultList()
                this.$emit('reload', 0)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete:function(res){
            if(res == 9990){
                
                let cnt = 0;

                this.sftyList.forEach(x=>{
                    if(x.mealSrvcJrnlPk != undefined && x.mealSrvcJrnlPk != ''){
                        cnt++
                    }
                })
                if(cnt > 0){
                    delMealSrvcJrnl(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, this.sftyList)
                        .then((response) => ( this.delMealSrvcJrnlAfter(response.data) ))
                        .catch((error) => console.log('connect error /bnftoffer/insMenu : ' + error))
                }else{
                    this.snackControll([true, 5000, '삭제 대상이 없습니다.', 'error'])
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        delMealSrvcJrnlAfter(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.selMealSrvcJrnlList(this.sltDate, 2)
                this.$emit('reload', 0)
                this.pageReset()
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false            
        },     
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        dialog: [false,false,false,false],    
        date: '',
        sltDate: '',
        wrtr: '',
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        inspTitle: [],
        inspRecDtllist: [],
        filledBtn: [
            { icon: 'mdi-cog-outline', class: 'white--text', color:'blueBtnColor', text: '기본값 설정',type: 'action', disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: false, },
        ],
        rmrksVal: [],
        seniorCtz: '',
        plusColor: 'black',
        brkfsVal: [],
        lunchVal: [],
        dinerVal: [],
        snckAmVal: [],
        snckPmVal: [],
        snackOptions: [false, 3000, '', 'info'], 
        sftyList:[],
        dialogDelete:false,
        inqYMD:'',
        menu: [false],
        prtThrowObj: { fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '30', list: [ { bzClcd: [], bzPk: [] } ], },
        progress:false,
    }),
};
</script>