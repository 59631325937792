<template>
    <v-sheet class="" rounded="md">
        <v-row class="pa-0 ma-0" no-gutters>
            <v-col class="d-flex align-center" cols="12">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">자격증&nbsp;정보</span>
            </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-row class="greyE01 pa-0 ma-0" no-gutters>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                <span class="black--text font-weight-medium">자격증명</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                <span class="black--text font-weight-medium">자격증번호</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                <span class="black--text font-weight-medium">발급일</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                <span class="black--text font-weight-medium">발급기관</span>
            </v-col>
            <v-col class="d-flex justify-center align-center py-1" cols="2">
                <span class="black--text font-weight-medium">첨부파일</span>
            </v-col>
        </v-row>
        <v-divider class=""></v-divider>
        <div v-if="prtCrtfcList.length == 0">
            <v-row class="pa-0 ma-0" no-gutters>
                <v-col class="d-flex justify-center align-center py-2" cols="12">
                    <span class="black--text">조회된 정보가 없습니다.</span>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0 ma-0" v-for="(cList, i) in prtCrtfcList" :key="i" no-gutters>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                    <span class="black--text">{{ cList.crtfcNm }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="3">
                    <span class="black--text">{{ cList.crtfcNum }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                    <span class="black--text">{{ cList.issueDt }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1 tableBR" cols="2">
                    <span class="black--text">{{ cList.issueOrg }}</span>
                </v-col>
                <v-col class="d-flex justify-center align-center py-1" cols="2">
                    <span class="">
                        <v-btn class="" @click="athFileDownload({ filePth: cList.filePth, fileNm: cList.fileNm, orgFileNm: cList.orgFileNm, atchdFilePk: cList.atchdFilePk })"
                            :disabled="cList.atchdFilePk == null ? true : false" icon>
                            <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
        </div>
        <v-divider class=""></v-divider>
    </v-sheet>
</template>

<script>
import { fileDownload } from '../../api/index.js';

export default {
    name: 'EmpCrtfInfo',

    props : {
       prtEmpPk: { type: Number, default: -1 },
    },
        
    components: {

    },

    created: function(){
   
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.prtCrtfcList = this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.crtfcList
        })
    },

    computed: {
        getCrtfcList: function (){
            return this.$parent.$parent.$parent.$parent.$refs.modifyModal.$refs.mfyInsrnCrtfc.crtfcList
        },
    },

    watch:{
        'prtEmpPk': function () {

        },
        getCrtfcList: function (value) {
            this.prtCrtfcList = Object.assign([], value)
            this.prtCrtfcList.forEach((item, i) => {
                if(item.empPk == 0) this.prtCrtfcList.splice(i, 1)
            });
        },
    },
    
    methods: {
        //첨부파일 다운로드
        athFileDownload: function (obj) {
            fileDownload(obj)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },
    },

    data: () => ({
        prtCrtfcList: [],
    }),
};
</script>