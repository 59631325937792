import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { en, ko } from 'vuetify/es5/locale';

Vue.use(Vuetify);

export default new Vuetify({
    // 언어 설정
    lang: {
        locales: { ko, en },
        current: 'ko',
    },
    //사용자 아이콘 설정
    icons: {
        iconfont: 'mdi',
    },
    //테마 설정
    theme: {
        dark: false,
        default: 'light',
        themes: {
            light: {
                bckgrColor: '#a6daff',
                topBtnColor: '#63A3DF',
                logoutBtnColor: '#666666',
                leftMemuColor: '#454545',
                blueBtnColor: '#2a9bed',
                blackBtnColor: '#666',
                slctdTxtColor: '#4f9ce3',
                midAreaColor: '#f4f4f4',
                topTitleColor: '#004584',
                bodyColor: '#333',
                btnBrdrColor: '#ccc',
                authTxtClr: '#7e839e',
                dayNow: '#82b1ff',
                dayOut: '#f7f7f7',
                level01: '#ef81bd',
                level02: '#73b2e0',
                level03: '#f2d17a',
                level04: '#7cd39f',
                level05: '#b099e3',
                level099: '#5D4037',
                blue001: '#005593',
                blue002: '#180ED9',
                blue003: '#ebf6fe',
                blue004: '#4f9ce3',
                blue100: '#1289e0',
                blue200: '#2a9bed',
                blue500: '#63a3df',
                blue600: '#2196f3',
                blue800: '#82b5da',
                black100: '#1d1d1d',
                grey005: '#bfbfbf',
                grey006: '#666',
                greyE00: '#dbdbdb',
                greyE01: '#ededed',
                greyF01: '#f4f4f4',
                greyF02: '#fcf5f7',
                greyC00: '#ccc'
            },
            dark: {
                primaryText: '#9e9e9e',
                secondaryText: '#e75546',
                thirdText: '#fff',
                fourthText: '#4b82f2',
                subText: '#606e79',
                mainText: '#9c9996',
                menuDiv: '#4f4f4f',
                backgroundColor: '#121212',
            },
        },
    },
});