<template>
    <v-sheet>
        <div class="d-flex">
            <div style="width:615px;">
                <div class="d-flex pa-0 ma-0" style="width:600px;border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <div class="greyE01 d-flex justify-center align-center pa-1  ma-0" style="width:20%;">
                        <span class="black--text font-weight-medium">교육대상자</span>
                    </div>   
                    <div class="d-flex" style="width:80%;">
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">교육시기</span>
                        </div>   
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">교육명</span>
                        </div>                                        
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">교육일자</span>
                        </div>                                        
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">서명</span>
                        </div>
                    </div>
                </div>
                <div style="height:635px;overflow-y:auto">
                    <div  class="d-flex pa-0 ma-0" style="width:600px;" no-gutters>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">수급자</span>
                        </div>   
                        <div v-if="eduRecList1.length > 0" style="width:80%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex" v-for="(list, j) in eduRecList1" :key="j"  style="width:100%;border-bottom: 1px solid #dbdbdb;">
                                <div :class="['fixedCol d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:30%;"  @click="rowClicked(list.eduRecPk)">
                                    {{list.eduHlf}}
                                </div>   
                                <div :class="['fixedCol d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:30%;border-left: 1px solid #dbdbdb !important;"  @click="rowClicked(list.eduRecPk)">
                                    <div style="width:135px" class="text-overflow">{{list.eduNm}}</div>
                                </div>                                        
                                <div :class="['fixedCol d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:20%;border-left: 1px solid #dbdbdb !important;" @click="rowClicked(list.eduRecPk)">
                                    {{list.eduDtStrt?.beforeDateFormatDot()}}
                                </div>  
                                <div :class="['d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                     <v-btn class="" height="26" style="min-width: 46px; width:46px; border: 1px solid #666;" @click="onSignMdl(list.eduRecPk)" depressed>
                                        {{list.eduSigCnt?.toLocaleString('ko-KR')}}&#47;{{list.eduAtndCnt?.toLocaleString('ko-KR')}}
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-else style="width:80%;border-left: 1px solid #dbdbdb !important;">
                            <div class="fixedCol d-flex disSltRow" style="width:100%;height:100%;border-bottom: 1px solid #dbdbdb;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;">
                                </div>   
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">
                                </div>                                        
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                </div>                                        
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex pa-0 ma-0" style="width:600px;" no-gutters>
                        <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-bottom: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">직원</span>
                        </div>   
                        <div v-if="eduRecList2.length > 0" style="width:80%;border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex" v-for="(list, j) in eduRecList2" :key="j" style="width:100%;border-bottom: 1px solid #dbdbdb;">
                                <div :class="['fixedCol d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:30%;"  @click="rowClicked(list.eduRecPk)">
                                    {{list.eduHlf}}
                                </div>   
                                <div :class="['fixedCol d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:30%;border-left: 1px solid #dbdbdb !important;"  @click="rowClicked(list.eduRecPk)">
                                    <div style="width:135px" class="text-overflow">{{list.eduNm}}</div>
                                </div>                                        
                                <div :class="['fixedCol d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:20%;border-left: 1px solid #dbdbdb !important;" @click="rowClicked(list.eduRecPk)">
                                    {{list.eduDtStrt?.beforeDateFormatDot()}}
                                </div>                                        
                                <div :class="['d-flex justify-center align-center pa-1 ma-0', sltPk == list.eduRecPk ? 'sltRow' : 'disSltRow']" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                     <v-btn class="" height="26" style="min-width: 46px; width:46px; border: 1px solid #666;" @click="onSignMdl(list.eduRecPk)" depressed>
                                        {{list.eduSigCnt?.toLocaleString('ko-KR')}}&#47;{{list.eduAtndCnt?.toLocaleString('ko-KR')}}
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div v-else style="width:80%;border-left: 1px solid #dbdbdb !important;">
                            <div class="fixedCol d-flex disSltRow" style="width:100%;height:100%;border-bottom: 1px solid #dbdbdb;">
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;">
                                </div>   
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:30%;border-left: 1px solid #dbdbdb !important;">
                                </div>                                        
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                </div>                                        
                                <div class="d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-left: 1px solid #dbdbdb !important;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="ml-3" style="width:709px;">
                <v-form ref="tabform" lazy-validation>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1  ma-0" cols="2">
                            <span class="black--text font-weight-medium">훈련일시</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp v-model="items.eduDtStrt" :required="true"></CmmDateComp>
                            </span>
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField v-model="items.eduTmStrt" :required="true"></CmmTimeField>                                     
                            </span>
                            <span class="px-2">&#126;</span>
                            <!-- <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp v-model="items.eduDtEnd" :required="true" :minDt="items.eduDtStrt"></CmmDateComp>
                            </span> -->
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField v-model="items.eduTmEnd" :required="true"></CmmTimeField>                                     
                            </span>
                        </v-col>
                    </v-row>

                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>                        
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                            <span class="black--text font-weight-medium">작성자</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field v-model="items.wrtr" color="blue" ref="info" readonly
                                    :rules="[rules.required, rules.strCheck]" hide-details outlined dense :key="forceRender"></v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="26"  
                                style="padding:0px 1px 0px 2px !important"
                                @click="onModal(0)"
                                >
                                <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>                        
                        <v-col class="greyE01 d-flex justify-center align-center pa-1  ma-0" cols="2">
                            <span class="black--text font-weight-medium">훈련명</span>
                        </v-col>                        
                        <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                            <span class="d-inline-block" style="width: 200px;">
                                <v-text-field v-model="items.eduNm" color="blue" :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                            </span>
                        </v-col>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1  ma-0" cols="2">
                            <span class="black--text font-weight-medium">교육장소</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="4" style="border-left: 1px solid #dbdbdb !important;">
                            <v-text-field v-model="items.eduLoc" color="blue" ref="info"
                                :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                        </v-col>
                    </v-row>                    
                    
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-2  ma-0" cols="2">
                            <span class="black--text font-weight-medium">참석자</span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="25"  
                                dark
                                style="padding:0px 1px 0px 2px !important"
                                @click="onModal(1)"
                                >
                                <v-icon size="20">mdi-account-multiple</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                            <div style="height:60px;overflow-y:auto">
                                <v-chip 
                                    v-for="(list, j) in exclsList" :key="j" 
                                    class="pa-2 mr-1"
                                    close
                                    label                            
                                    color="blue"
                                    outlined
                                    small      
                                    @click:close="delAtndRow(j)"                                
                                    >
                                    {{list.name}}
                                </v-chip>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center pa-1  ma-0" cols="2">
                            <span class="black--text font-weight-medium">훈련내용</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                            <v-textarea v-model="items.eduCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                    rows="10" outlined dense hide-details no-resize>
                            </v-textarea>   
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-start align-center py-1 pl-3  ma-0" cols="2">
                            <div class="d-flex align-center" style="width:95px">
                                <span class="black--text font-weight-medium">사진첨부</span>
                                <v-file-input 
                                    class="pl-1"
                                    v-model="images" 
                                    :accept="imgAccept"
                                    dense hide-details truncate-length="5" 
                                    multiple counter
                                    prepend-icon="mdi-camera-outline"
                                    hide-input 
                                    @change="upImgFile()"
                                    ></v-file-input>
                            </div>
                        </v-col>
                        <v-col class="pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                            <div class="d-flex justify-start align-center" style="height:100px;overflow-y:auto">
                                <span class="ma-1 d-inline-block" v-for="(item,i) in imageRes" :key="i">
                                    <imageCard 
                                        ref="imageCard" 
                                        :imgSrc="item.src" 
                                        :imgWidth="'100'" 
                                        @isEvent="deleteImg(i)" 
                                        @onDown="fileDownload(i,2)"></imageCard>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="greyE01 d-flex justify-start align-center py-1 pl-3  ma-0" cols="2">
                            <div class="d-flex align-center" style="width:95px">
                                <span class="black--text font-weight-medium">자료첨부</span>
                                <v-file-input 
                                    v-model="files" 
                                    dense hide-details truncate-length="5" 
                                    multiple counter
                                    hide-input                                         
                                    outlined 
                                    @change="upFile()"
                                    ></v-file-input>
                            </div>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="10" style="border-left: 1px solid #dbdbdb !important;">
                            <div style="width:100%;height:100px;overflow-y:auto">                      
                                <v-chip v-for="(item, j) in fileRes" :key="j"                        
                                    label                        
                                    close
                                    class="ma-1"
                                    outlined
                                    @click="fileDownload(j,1)"
                                    @click:close="delfiles(j)"
                                >
                                    <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                        mdi-file-powerpoint
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                        mdi-file-word
                                    </v-icon>
                                    <v-icon v-else color='cyan' left>
                                        mdi-file-document
                                    </v-icon>
                                    {{ item.orgFileNm }}
                                </v-chip> 
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt = "''"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="'참석자 선택'" :tabInfList="['1','2']" :fixList="exclsList" mode="2"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"
                    :prtWrtDt = "items.eduDtStrt"
                    @intgrResList="getAplyList" @onClose="isModal(1)">
                </IntgrSlctn>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[2]" max-width="800" eager>    
                <edctnJrnlSignMdl ref="edctnJrnlSignMdl" @modalEvent="modalEvent"></edctnJrnlSignMdl>  
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete[0]" max-width="500">    
                <btnModalDelete :title="title" @madalState="delAtchdFile"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete[1]" max-width="500">    
                <btnModalDelete :title="'참석자'" @madalState="delAtnd"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete[2]" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="deleteAll"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="bizMsg" max-width="1100">    
                <bizMsgMdl 
                    ref="bizMsgMdl"                    
                    :bizMsgData="eSndData"
                    :bizMsgDocClcd="eDocClcd"
                    :bizMsgBzPk="sltPk"
                    :bizMsgShow="bizMsg"
                    :bizMsgTitle="eCntnt"
                    :bizMsgCntnt="eCntnt"
                    @madalState="onSendClose"
                ></bizMsgMdl>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { remoteURL } from '@/api/baseAxios';
import { getMultiStcd, tmpFileUpload, selAtchdFileList, fileDownload, delAtchdFile} from '../../api/index.js';
import { selEduRecList, selEduRecInfo, selEduAtndList, insEduRec, delEduAtnd, delEduRec } from '../../api/emp.js';

import IntgrSlctn from '../commons/IntgrSlctn.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import imageCard from '../commons/imageCard.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import edctnJrnlSignMdl from './EdctnJrnlSignMdl.vue';
import bizMsgMdl from '../commons/BizMsgMdl.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';

export default {
    name: 'EdctnJrnlDssRes',
    
    props : {           
       tab : { type: Number, default: 0 },
    },

    components: {    
        IntgrSlctn,  
        EmpSelector,
        imageCard,  
        btnModalDelete,
        edctnJrnlSignMdl,
        bizMsgMdl,
        CmmDateComp,
        CmmTimeField,
    },

    created: function(){
        this.getCode(['41', '42', '65', '74'])
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
        })
    },

    computed: {

    },

    watch:{ 
        
    },
        
    methods: {
        onLoad:function(yyyy){             
            this.year = yyyy         
            this.sltPk = 0
            this.getEduRecList(yyyy)
            this.frmReset()
        },
        onDelete:function(){
            if(this.sltPk > 0){
                this.dialogDelete.splice(2,1,true)
            }
        },
        //발송정보 모달 오픈
        onSend:function(){            

            if(this.sltPk > 0){
                this.bizMsg = true
                this.eCntnt = this.items.eduNm
                this.eSndData.splice(0)
                this.exclsList.forEach(item => {
                    this.eSndData.push(item.mmbrpk)
                });
            }else{
                this.$emit("edctnJrnlMsg","교육을(를) 선택해주세요.")
            }
        },
        onSendClose:function(){
            this.bizMsg = false
        },
        setInit:function(){
            this.eduTypcd = '703'
            this.delTitle = '재난상황대응훈련'
        },
        rowClicked:function(pk){
            this.eduClcd = '-1'
            if(this.sltPk == pk){
                this.frmReset()                
            }else{
                this.sltPk = pk   
                if(this.sltPk != null){
                    this.getEduRecInfo()
                    this.getEduAtndList()
                    this.getAtchdFileList(pk)
                }else{
                    this.frmReset()
                }
            }
        },        
        frmReset:function(){
            this.sltPk = 0
            this.items = Object.assign({})
            this.exclsList.splice(0)
            this.eduAtndList.splice(0)
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            this.$refs.tabform.resetValidation()            
        },
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
            this.vCd74 = res.vCd74
        },
        getEduRecList:function(yyyy){  
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                inqYYYY : yyyy,
                eduTypcd: this.eduTypcd
            }     
            selEduRecList(obj)
                .then((response) => ( this.getEduRecListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduRecList : ' + error))
        },
        getEduRecListAfter:function(res){
            this.eduRecList1.splice(0)
            this.eduRecList2.splice(0)
            if(res != null && res != ''){
                res.forEach(item => {
                    item.eduHlf = (item.eduHlf=='1')?'상반기':'하반기' 
    
                    switch (item.pstnCd) {
                        case '1':
                            this.eduRecList1.push(item)
                            break;
                        case '3':
                            this.eduRecList2.push(item)
                            break;
                        default:
                            break;
                    }
                });
            }
        },  
        getEduRecInfo:function(){  
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eduRecPk : this.sltPk,
            }     
            selEduRecInfo(obj)
                .then((response) => ( this.getEduRecInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduRecInfo : ' + error))
        },
        getEduRecInfoAfter:function(res){
           this.eduClcd = res.eduClcd           
           this.items = res
           this.items.eduDtStrt = res.eduDtStrt.beforeDateFormatHyp()
        //    this.items.eduDtEnd = res.eduDtEnd.beforeDateFormatHyp()
           this.items.eduTmStrt = res.eduTmHhStrt+":"+res.eduTmMmStrt
           this.items.eduTmEnd = res.eduTmHhEnd+":"+res.eduTmMmEnd
           this.$refs.tabform.resetValidation()
        },  
        getEduAtndList:function(){  
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eduRecPk : this.sltPk,
            }     
            if(this.sltPk > 0){
                selEduAtndList(obj)
                    .then((response) => ( this.getEduAtndListAfter(response.data) ))
                    .catch((error) => console.log('connect error /emp/selEduAtndList : ' + error))
            }else{
                this.exclsList.splice(0)
                this.eduAtndList.splice(0)
            }
        },
        getEduAtndListAfter:function(res){
            this.exclsList.splice(0)
            this.eduAtndList.splice(0)
            this.eduAtndList = res
            this.eduAtndList.forEach(e => {

                let obj = {
                    eduAtndPk:e.eduAtndPk,
                    name:e.atndNm,
                    mmbrpk:e.mmbrPk,
                    disChekr:true,
                    checkr:(e.pstnCd=='1')?'1':'2'
                }

                this.exclsList.push(obj)
            });
        },  
        onSave:function(){
            
            let gFcltyNum = this.$store.getters.getFcltyNum           
            let gUserNm = this.$store.getters.getUserNm

            if(this.$refs.tabform.validate()){
                let obj = {
                    fcltyNum:gFcltyNum,
                    userNm:gUserNm,
                    eduRecPk:this.sltPk,
                    eduTypcd:this.eduTypcd,
                    eduClcd:this.eduClcd,
                    eduSubjCd:this.items.eduSubjCd,
                    eduNm:this.items.eduNm,
                    instrNm:this.items.instrNm,
                    eduDtStrt:this.items.eduDtStrt?.afterDateFormatHyp(),
                    eduTmHhStrt:'',
                    eduTmMmStrt:'',
                    eduDtEnd:this.items.eduDtStrt?.afterDateFormatHyp(),
                    eduTmHhEnd:'',
                    eduTmMmEnd:'',
                    orgnz:this.items.orgnz,
                    eduLoc:this.items.eduLoc,
                    eduMthdCd:this.items.eduMthdCd,
                    eduCntnt:this.items.eduCntnt,
                    wrtr:this.items.wrtr,
                    atndList:[],
                    files:[],
                }

                if(this.items.eduTmStrt !='' ) {
                    let eduTmStrt = this.items.eduTmStrt.split(":")
                    obj.eduTmHhStrt = eduTmStrt[0]
                    obj.eduTmMmStrt = eduTmStrt[1]
                }

                if(this.items.eduTmEnd !='' ) {
                    let eduTmEnd = this.items.eduTmEnd.split(":")
                    obj.eduTmHhEnd = eduTmEnd[0]
                    obj.eduTmMmEnd = eduTmEnd[1]
                }

                this.imageRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        obj.files.push(x)
                    }
                })

                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        x.fileKndCd = '1'
                        obj.files.push(x)
                    }
                })

                this.exclsList.forEach(x => {
                    let atnd = {
                        fcltyNum:gFcltyNum,
                        userNm:gUserNm,
                        atndNm:x.name,
                        mmbrPk:x.mmbrpk,
                        eduAtndPk:x.eduAtndPk,
                        pstnCd:(x.checkr=='1')?x.checkr:'3',
                    }
                    
                    obj.atndList.push(atnd)
                });
                
                insEduRec(obj)
                    .then((response) => ( this.onSaveAfter(response.data) ))
                    .catch((error) => console.log('connect error /emp/insEduRec : ' + error))
            }
        }, 
        onSaveAfter:function(res){
            this.$emit('edctnJrnlTrigger', res)
            if(res.statusCode == 200){
                this.eduClcd = '-1'
                this.sltPk = Number(res.result)
                this.getEduRecList(this.year)
                this.getEduRecInfo()
                this.getEduAtndList()
                this.getAtchdFileList(res.result)
            }
        },
        onModal: function (value) {        
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
        },
        //서명
        //서명
        onSignMdl:function(pk){            
            this.sltPk = pk   
            if(this.sltPk != null){
                this.getEduRecInfo()
                this.getEduAtndList()
                this.getAtchdFileList(pk)
                this.dialog.splice(2, 1, true)
                this.$refs.edctnJrnlSignMdl.onLoad(pk)
            }else{
                this.frmReset()
            }
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.getEduRecList(this.year)
            }else{
                this.dialog.splice(event, 1, false)
            }      
        },
        getAplyList: function (arr){
            this.exclsList.splice(0)

            this.eduAtndList.forEach(e => {

                let obj = {
                    eduAtndPk:e.eduAtndPk,
                    name:e.atndNm,
                    mmbrpk:e.mmbrPk,
                    disChekr:true,
                    checkr:(e.pstnCd=='1')?'1':'2'
                }

                this.exclsList.push(obj)
            });

            arr.forEach(e => {  
                let mmbrpk = 0
                let cd = ''

                switch (e.dtTyp) {
                    case 'bnfcr':
                        cd = '1'
                        mmbrpk = e.bnMmbrPk
                        break;
                    case 'emp':
                        cd = '2'
                        mmbrpk = e.mmbrPk
                        break;
                    default:
                        break;
                }            

                if(this.exclsList.findIndex( v => v.mmbrpk == mmbrpk) == -1){
                    let obj = {
                        name:e.name,
                        mmbrpk:mmbrpk,
                        disChekr:false,
                        checkr:cd
                    }

                    this.exclsList.push(obj)
                }

            });            
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        onforce:function(){
            this.forceRender++
        },
        upFile: function () {            
            let formData = new FormData();
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => ( this.upFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }            
        },
        upFileAfter:function(res){

            res.files.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
            })

            this.files = null
        },
        upImgFile: function () {     
            let formData = new FormData();
            
            if(this.images != null){
                this.images.forEach(element => {
                    formData.append("files", element)
                })

                if(this.images.length > 0){
                    tmpFileUpload(formData)
                        .then((response) => ( this.upImgFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }

        },
        upImgFileAfter:function(res){
            
            res.files.forEach(x=>{
                x.src = remoteURL + 'opr/imgPrview?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                this.imageRes.push(x)
            })       

            this.images = null
        },
        getAtchdFileList:function(value){
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '10'
            if(value > 0){
                selAtchdFileList(fcltyNum, clcd,value)
                        .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/selAtchdFileList : ' + error))
            }else{
                this.files = null
                this.images = null
                this.fileRes.splice(0)
                this.imageRes.splice(0)
            }
        },
        getAtchdFileListAfter:function(res){
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            res.forEach(x=>{
                if(x.fileKndCd == "2"){
                    x.src = remoteURL + 'opr/imgView?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                }else{
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]  
                    this.fileRes.push(x)
                }
            })
        },
        fileDownload:function(idx, type){
            if(type == 1){
                if(this.fileRes[idx] != undefined){
                    fileDownload(this.fileRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }else if(type==2){
                if(this.imageRes[idx] != undefined){
                    fileDownload(this.imageRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        deleteAll:function(res){            
            if(res == 9990){
                if(this.sltPk > 0){
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        eduRecPk:this.sltPk,
                    }
                    delEduRec(obj)
                        .then((response) => ( this.delEduRecAfter(response.data) ))
                        .catch((error) => console.log('upload error /emp/delEduRec : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete.splice(2,1,false)
            }
        },
        delEduRecAfter:function(res){
            this.$emit('edctnJrnlTrigger', res)
            if(res.statusCode == 200){     
                this.onLoad(this.year)
            }
            this.dialogDelete.splice(2,1,false)
        },
        delfiles:function(idx){
            if(this.fileRes[idx].atchdFilePk != undefined){
                this.key = 1
                this.idx = idx
                this.title = '파일'
                this.dialogDelete.splice(0,1,true)
            }else{
                this.fileRes.splice(idx,1)
            }
        },    
        deleteImg(idx){
            if(this.imageRes[idx].atchdFilePk != undefined){
                this.key = 2
                this.idx = idx
                this.title = '사진'
                this.dialogDelete.splice(0,1,true)
            }else{
                this.imageRes.splice(idx,1)
            }
        },
        delAtchdFile:function(res){
            if(res == 9990){
                let pk = 0
                if(this.key == 1){
                    pk = this.fileRes[this.idx].atchdFilePk
                }else{
                    pk = this.imageRes[this.idx].atchdFilePk
                }

                if(pk > 0){
                    let fcltyNum  = this.$store.getters.getFcltyNum  
                    delAtchdFile(fcltyNum, pk)
                        .then((response) => ( this.delAtchdFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete.splice(0,1,false)
            }
        },
        delAtchdFileAfter:function(res){
            this.$emit('edctnJrnlTrigger', res)
            if(res.statusCode == 200){     
                this.getAtchdFileList(this.sltPk)
                this.key = 0
                this.idx = 0
            }
            this.dialogDelete.splice(0,1,false)
        },
        delAtndRow:function(idx){
            if(this.exclsList[idx].eduAtndPk > 0){
                this.atndPk = this.exclsList[idx].eduAtndPk
                this.dialogDelete.splice(1,1,true)
            }else{
                 this.exclsList.splice(idx,1)
            }
        },
        delAtnd:function(res){
            if(res == 9990){                
                if(this.atndPk > 0){ 
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm : this.$store.getters.getUserNm,
                        eduAtndPk: this.atndPk,
                    }

                    delEduAtnd(obj)
                        .then((response) => ( this.delAtndAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete.splice(1,1,false)
            }
        },
        delAtndAfter:function(res){
            this.$emit('edctnJrnlTrigger', res)
            if(res.statusCode == 200){     
                this.getEduAtndList()
                this.getEduRecList(this.year)
                this.atndPk = 0
            }
            this.dialogDelete.splice(1,1,false)
        },
    },

    data: () => ({
        sltPk:-1,
        delTitle:'재난상황대응훈련',
        year:'',
        type:'',
        eduTypcd:'703',
        mdlTtl:'직원 선택',
        mode:'1',
        tabInfList:['2'],
        vCd41:[],
        vCd42:[],
        vCd65:[],
        vCd74:[],
        eduClcd:'',
        forceRender:0, 
        clsfc2Cds:[],
        clsfc3Cds:[],        
        eduRecList1:[],
        eduRecList2:[],
        dialog:[false,false,false],
        exclsList:[],
        eduAtndList:[],
        items:{},
        menu:[false,false,], 
        rtimes:false,
        mtimes:false,
        idx:0,
        title:'사진',
        files:null,
        fileRes:[],
        images:null, 
        imageRes:[],
        imgAccept:'image/*', 
        atndPk:0,       
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:[false,false],
        bizMsg:false,
        eSndData:[],
        eDocClcd :8,
        eCntnt:'',
    }),
};
</script>