<template>
    <v-row class="ma-0 pa-0" no-gutters>
        <v-col v-for="(pcy, i) in policyTitle" :key="i" cols="12">
            <div class="mt-5">제 {{ i+1 }}조 ( {{ pcy }} )</div>
            <div v-for="(pcl, j) in policyList" :key="j">
                <div class="policyBox fontSizeDotEight rounded-lg py-2 px-3 mt-2" v-if="i == pcl.index">
                    <div v-for="(con, k) in pcl.content" :key="k">
                        <div class="d-flex">
                            <span class="d-inline-block" style="width: 22px;">{{ String.fromCharCode('0x246'+k) }}</span>
                            <span class="d-inline-block" style="width: 100%;">{{ con.mmm }}</span>
                        </div>
                        <div class="px-6" v-for="(dep, l) in con.subList" :key="l">
                            <div class="d-flex">
                                <span class="d-inline-block" style="width: 22px;">{{ l+1 }}.</span>
                                <span class="d-inline-block" style="width: 100%;">{{ dep }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12">
            <div class="mt-5">&#60;&nbsp;&nbsp;&nbsp;&nbsp;부&nbsp;&nbsp;칙&nbsp;&nbsp;&nbsp;&nbsp;&#62;</div>
            <div class="policyBox fontSizeDotEight rounded-lg py-2 px-3 mt-2">
                <div>
                    <div class="d-flex">
                        <span class="d-inline-block" style="width: 22px;">①</span>
                        <span class="d-inline-block" style="width: 100%;">제 1 조 (시행일)</span>
                    </div>
                    <div class="d-flex">
                        <span class="d-inline-block" style="width: 22px;"></span>
                        <span class="d-inline-block" style="width: 100%;">본 약관은 2023년 5월 1일부터 시행합니다.</span>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: 'SrvcPolicies',
        
    components: {

    },

    created: function(){
       
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{ 

    },
        
    methods: {
        
    },
    
    data: () => ({
        policyTitle: [
            '목적', '용어의 정의', '약관의 명시 및 변경', '서비스 종류 및 변경', '이용신청 및 약관동의', '서비스 신청 안내', '가입 및 자격',
            '회사의 의무', '고객의 의무', '서비스의 이용시간', '서비스 자료의 보관', '서비스의 중단', '이용의 제한', '이용제한 및 해제 절차',
            '이용요금 납부', '이용계약의 해지', '비용의 환불', '비용의 정산', '손해배상의 책임 및 범위', '고객의 손해배상의 청구', '고객에 대한 손해배상 청구',
            '면책', '분쟁해결 및 관할법원'
        ],
        policyList: [
            {
                content: [
                    {
                        mmm: '본 약관(이하 "약관")은 해리지앤디("https://yoyangsys.co.kr/"이하 "요양SYS")에서 제공하는 서비스 이용과 관련하여, 당사와 회원에 관한 제반 사항을 규정함을 목적으로 합니다.',
                        subList: [],
                    },
                ],
                index : 0
            },
            {
                content: [
                    {
                        mmm: '이 약관에서 사용하는 용어의 정의는 다음과 같습니다.',
                        subList: [
                            '고객 : 회사와 서비스 이용계약을 체결하고 서비스를 받는 자를 말합니다.',
                            '이용계약 : 서비스 이용과 관련하여 회사와 고객간에 체결하는 계약을 말합니다.',
                            '아이디(ID) : 고객의 식별과 서비스 이용을 위하여 고객에게 제공되는 문자와 숫자의 조합을 말합니다.',
                            '비밀번호(PASSWORD) : 고객의 개인정보 및 권익 보호를 위하여 고객에게 제공되는 문자와 숫자의 조합으로 고객의 아이디(ID)와 함께 고객의 신원확인에 사용됩니다.',
                        ],
                    },
                    {
                        mmm: '전 항에서 정한 것을 제외하고는 관계법령이나 기타 서비스별 안내에서 정하는 바에 따릅니다.',
                        subList: [],
                    },
                ],
                index : 1
            },
            {
                content: [
                    {
                        mmm: '회사는 합리적인 사유가 발생할 경우 관련법령에 위배되지 않는 범위에서 이 약관을 개정할 수 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사가 약관을 개정할 경우에는 시행일자 및 개정사유를 명시하여 현행약관과 함께 회사의 서비스 홈페이지 초기화면에 시행일자 이전부터 7일 동안 게시합니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 변경된 약관에 동의하지 않을 경우 이용계약 해지를 요청할 수 있으며, 변경된 약관의 효력 발생일로 부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.',
                        subList: [],
                    },
                ],
                index : 2
            },
            {
                content: [
                    {
                        mmm: '회사는 서비스 종류, 서비스 내용, 가격 및 기타 서비스 관련사항을 회사의 서비스 홈페이지를 통하여 게시하며 고객은 회사의 홈페이지를 기준으로 서비스 이용신청을 해야 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '또한 회사는 서비스 종류의 신설 또는 기타 변경사항에 대해서도 홈페이지를 통하여 게시하며 이미 서비스 이용 중인 고객에 대한 서비스의 변경사항은 홈페이지에 게시하거나 고객의 전자우편 주소를 통해서 통보하며 게시 또는 통보 후 7일 이내에 이의를 제기하지 않으면 고객이 변경사항에 대해서 동의한 것으로 간주합니다.',
                        subList: [],
                    },
                ],
                index : 3
            },
            {
                content: [
                    {
                        mmm: '이용신청은 고객이 이 약관에 동의하고 회사가 정한 신청양식을 인터넷에서 작성 후 신청하는 것으로 이루어지 며 필요한 경우에 고객에게 구비서류 제출을 요구할 수 있습니다. 이 이용약관에 대한 동의는 이용신청 당시 회사 홈페이지의 "서비스 이용약관을 읽고 동의합니다." 버튼을 누름으로써 의사표시를 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 이용신청 시 실명, 실제정보를 입력하여야 하며, 이를 위반한 고객은 법적인 보호를 받을 수 없으며 또한 서비스 이용에 제한을 받을 수 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객이 서비스 신청시 제공한 개인정보는 관계법령 및 회사의 개인정보보호정책에 따라 보호를 받습니다.',
                        subList: [],
                    },
                ],
                index : 4
            },
            {
                content: [
                    {
                        mmm: '회사는 고객의 서비스 신청이 완료되면 필요사항을 확인한 후 서비스 신청에 대한 승인 여부를 결정합니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 서비스 신청 결과를 고객의 이메일(또는 문자)을 통해 통지합니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 서비스 신청이 다음 각 호에 해당하는 경우에는 승낙을 하지 아니 합니다.',
                        subList: [
                            '타인 또는 타사의 정보로 신청한 경우',
                            '서비스 신청서 상의 입력 정보 내용이 허위인 경우',
                        ],
                    },
                ],
                index : 5
            },
            {
                content: [
                    {
                        mmm: '회사가 정한 양식에 따라 가입정보를 기입한 후 가입을 신청하고 회사가 승낙함으로써 서비스 이용 고객으로 등록됩니다. 서비스의 특별한 이용에 관한 계약은 별도 계약에 의해 제공됩니다.',
                        subList: [],
                    },
                    {
                        mmm: '다음 각 호에 해당하는 경우에 회사는 고객가입을 인정하지 않거나 서비스 이용을 제한, 영구금지, 가입자격 박탈등의 조치를 취할 수 있습니다.',
                        subList: [
                            '다른 고객의 명의를 사용하여 가입 신청한 경우',
                            '신청 시 필수 작성 사항을 기재하지 않거나 허위로 기재한 경우',
                            '관계법령의 위반을 목적으로 신청하거나 그러한 행위를 하는 경우',
                            '사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하거나 그러한 행위를 하는 경우',
                            '다른 고객의 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우',
                            '제 9조 고객의 의무를 지키지 않는 경우',
                        ],
                    },
                    {
                        mmm: '회사가 가입자격을 박탈하는 경우에는 고객등록을 말소합니다. 이 경우 고객에게 사전 통지하여 소명할 기회를 부여합니다. 단, 고객의 귀책사유로 통지할 수 없는 경우 통지를 생략할 수 있습니다.',
                        subList: [],
                    },
                ],
                index : 6
            },
            {
                content: [
                    {
                        mmm: '회사는 고객에게 본 약관에 명시된 서비스를 안정적이고 지속적으로 제공해야 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 서비스 운영에 지장이 되는 장애가 발생했을 경우 이를 즉시 수리 또는 복구해야 하며, 안정적인 서비스 운영을 위해 최선을 다해야 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 고객이 정당하게 요구하는 의견이나 불만에 대해서 즉시 또는 회사가 정한 기간과 절차에 따라 처리해야 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 원활한 서비스 신청 및 운영을 위해 고객의 개인정보를 수집하여 보관하며 본인의 허락 없이는 제3자에게 이를 제공할 수 없습니다. 다만 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 정보통신 윤리위원회의 요청이 있는 경우, 회사의 이용요금을 체납하여 신용정보사업자 또는 신용정보집중기관에 제공하는 경우는 예외로 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 이용계약의 체결 및 해지 등 고객과의 계약관련 절차 및 내용 등에 있어 고객에게 편의를 제공하도록 노력합니다.',
                        subList: [],
                    },
                ],
                index : 7
            },
            {
                content: [
                    {
                        mmm: '고객은 회사가 정한 서비스 이용요금을 지정된 일자에 납입할 의무가 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 회사의 동의 없이 회사가 제공하는 서비스를 제 3자에게 제공하여서는 안 됩니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 회사와 타 고객의 서비스 운영에 방해가 되는 행위를 하지 않아야 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 자신이 운영 중인 서비스의 데이터 등에 대해 별도로 저장할 의무가 있으며 내부직원 실수나 외부 침입(계정정보 누출) 등으로 인한 정보의 유출, 누락 또는 자료의 손실에 대해 회사는 책임을 지지 않습니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 자신의 각종 아이디(ID)와 비밀번호를 제 3자에게 누출할 수 없으며, 누출로 발생하는 손해에 대한 책임은 고객에게 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 서비스 신청 시 회사에 제공한 정보에 변경내용이 발생했을 경우 해당 절차를 거쳐 빠른 시일 내에 수정, 보완해야 하며, 그로 인하여 발생하는 문제에 대한 책임은 고객에게 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.',
                        subList: [],
                    },
                ],
                index : 8
            },
            {
                content: [
                    {
                        mmm: '서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 서비스 제공을 원칙으로 하나 정기점검이나 시스템의 업그레이드가 필요한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시중단은 회사 홈페이지를 통해 사전에 공지합니다.',
                        subList: [],
                    },
                ],
                index : 9
            },
            {
                content: [
                    {
                        mmm: '고객의 서비스 이용 자료는 서비스 유지기간까지 보존관리힌다.',
                        subList: [],
                    },
                    {
                        mmm: '고객의 서비스 탈퇴, 해지 시에는 고객의 정보 및 서비스 이용자료를 즉각 삭제조치한다.',
                        subList: [],
                    },
                    {
                        mmm: '고객의 서비스 만료시에는 만료일 다음날부터 5년까지 보존관리한다.',
                        subList: [],
                    },
                ],
                index : 10
            },
            {
                content: [
                    {
                        mmm: '회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 다음 각 호의 경우에 서비스 제공을 일시 중단할 수 있습니다. 서비스 중단이 발생할 경우 회사는 고객에게 이러한 사실을 전자우편 등의 방법으로 사전 또는 사후에 통보하여야 하며, 회사가 운영하는 홈페이지를 통하여 게시하는 것으로 대신할 수 있습니다.',
                        subList: [
                            '서버 설비의 보수 혹은 공사상 불가피한 경우',
                            '전용회선 경로상의 장애가 발생한 경우',
                            '천재지변, 국가비상사태 등의 사유로 인하여 기계적인 작동불능이 발생한 경우',
                        ],
                    },
                ],
                index : 11
            },
            {
                content: [
                    {
                        mmm: '회사는 고객의 서비스 이용 내용이 다음 각호에 해당할 경우 서비스 이용을 일시적으로 제한할 수 있습니다.',
                        subList: [
                            '제 9조 규정에 의한 고객의 의무를 중대하게 위반한 경우',
                            '타인의 정보 유출, 비밀번호 도용 등의 부정한 행위를 한 경우',
                            '관련 법령에 위반되는 행위를 하는 경우',
                            '서비스를 범죄에 이용한다고 객관적으로 판단되는 경우',
                            '이 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위',
                        ],
                    },
                ],
                index : 12
            },
            {
                content: [
                    {
                        mmm: '회사는 제13조에 의거하여 이용제한을 할 경우에는 해당고객 또는 대리인에게 그 사유 및 기간 등을 고지한 후에 서비스 이용을 제한할 수 있으나 시스템 운영에 중대한 장애가 초래되거나 사안이 긴급한 경우에는 먼저 서비스를 정지한 후 이를 사후에 통보할 수 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 전항에 의하여 서비스가 제한된 고객이 그 위반행위를 해소할 경우에는 관련 절차에 따라 서비스 이용제한을 즉시 해제합니다.',
                        subList: [],
                    },
                ],
                index : 13
            },
            {
                content: [
                    {
                        mmm: '고객은 회사가 인지하기 어려운 이름으로 이용요금을 납부하는 경우, 이에 대해 회사에 통보할 의무가 있습니다. 회사가 인지하기 어려운 이름의 납부에 대한 통지를 하지 않음으로 인해 발생하는 서비스 중단 등의 불이익은 고객의 책임입니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객은 납부일자에 이용요금을 납부해야 합니다. 이를 납부하지 못할 경우에는 제13조에 따른 서비스 이용제한 또는 제15조제2항에 의거 이용계약이 해지될 수 있습니다.',
                        subList: [],
                    },
                ],
                index : 14
            },
            {
                content: [
                    {
                        mmm: '고객은 서비스의 계약 체결 후 회사의 절차에 따라 서비스의 이용 해지 신청을 할 수 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 고객이 다음 각호에 해당할 경우에 사전통지 없이 이용계약을 해지할 수 있습니다. 회사는 위와 같이 서비스 계약이 해지된 고객이 다시 이용신청을 하는 경우 이용 승낙을 거부할 수 있습니다.',
                        subList: [
                            '이용신청서에 기재한 내용이 허위로 판명된 경우',
                            '서비스 운영을 고의로 방해하여 회사에 막대한 손해를 입힌 경우',
                            '제 13조에 의하여 이용 제한된 후 그 이용제한 기간 내에 이용제한 사유를 해소하지 않는 경우',
                        ],
                    },
                ],
                index : 15
            },
            {
                content: [
                    {
                        mmm: '비용을 지불하여 구매한 모든 상품은 결제 후 7일 이내에 환불이 가능하며, 구매한 상품으로 서비스를 이용한 내역이 존재하는 경우 환불처리는 불가합니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객의 결제 방법에 따라 환불에 대한 수수료는 아래 각호와 같습니다. 홈페이지에서 진행되는 모든 결제방식은 결제대행업체인 한국사이버페이먼트(KCP)를 통하여 진행되며, 환불시에는 결제대행업체, 은행사, 카드사의 수수료를 제외한 나머지 결제 비용만 환불 처리가 가능합니다.',
                        subList: [
                            '카드결제에 대한 전액 환불 처리시 수수료는 없으며, 매입취소와 승인취소를 통하여 환불이 진행됩니다. 최종 환불은 카드사의 환급 절차에 따라 진행됩니다. 단,카드 금액의 전체 또는 부분 금액을 고객의 통장계좌로 환불 요청시에는 환불금액의 3.3% 수수료가 발생됩니다.',
                            '가상계좌 결제에 대한 환불 처리는 고객의 통장계좌로 환불이 되며, 이때 환불금액에서 500원의 수수료를 제외한 나머지 금액을 환불 해 드립니다.',
                            '실시간계좌이체 결제에 대한 환불 처리는 고객의 통장계좌로 환불이 되며, 이때 환불금액에서 3.3%의 수수료를 제외한 나머지 금액을 환불 해 드립니다.',
                            '고객의 통장계좌로 계좌이체에 따른 수수료 500원이 발생됩니다.',
                        ],
                    },
                ],
                index : 16
            },
            {
                content: [
                    {
                        mmm: '비용의 환불로 인한 정산은 서비스 해지일자 기준으로 잔여기간에 대하여 정산처리 한다. 단, 6개월과 12개월 선납 결제시 제공된 추가 무료 개월은 정산에서 제외한다.',
                        subList: [],
                    },
                    {
                        mmm: '비용 결제시 지불되었던 수수료 및 부가가치세 세액 및 그 밖의 수수료는 정산에서 제외한다. 그 항목은 아래와 같다.',
                        subList: [
                            '카드를 통한 결제시 PG사 수수료 3.3%는 정산비용에서 제외한다.',
                            '실시간계좌이체를 통한 결제시 수수료 PG사 2.2% 정산비용에서 제외한다.',
                            '전자세금계산서가 발행된 경우 세액은 정산비용에서 제외한다.',
                        ],
                    },
                ],
                index : 17
            },
            {
                content: [
                    {
                        mmm: '회사가 제공하는 서비스와 관련하여 고객에게 어떠한 손해가 발생한 경우 회사는 고의 또는 중과실이 없는 한 이에 대하여 책임지지 않습니다.',
                        subList: [],
                    },
                ],
                index : 18
            },
            {
                content: [
                    {
                        mmm: '고객의 손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 서면으로 하여야 합니다.',
                        subList: [],
                    },
                ],
                index : 19
            },
            {
                content: [
                    {
                        mmm: '고객이 제8조 제1항에서 제7항까지 규정한 의무사항을 위반하여 회사에 손해를 끼친 경우에 회사는 해당 고객에 대해 손해배상을 청구할 수 있습니다.',
                        subList: [],
                    },
                ],
                index : 20
            },
            {
                content: [
                    {
                        mmm: '회사는 고객이 게재한 정보, 자료 및 그에 대한 사실의 정확성, 신뢰성 등에 관하여는 어떠한 책임도지지 않으며, 서비스를 이용하여 게시 또는 전송한 자료, 자료의 취사선택, 기타 서비스 이용과 관련하여 고객에게 어떠한 손해 또는 불이익이 발생하더라도 이에 대한 모든 책임은 고객에게 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 고객과 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다. 또한 고객이 이 규정을 위반함으로 인하여 회사가 다른 고객 또는 제3자에 대하여 책임을 부담하게 되고, 이로써 회사가 손해를 입게 되는 경우, 고객은 회사에게 대하여 모든 손해를 배상하여야 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '고객 아이디(ID)와 비밀번호의 관리 및 이용상의 부주의로 인하여 발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두 고객에게 있습니다.',
                        subList: [],
                    },
                    {
                        mmm: '불법적인 침입으로 인해 발생한 고객의 정보에 대한 손해는 배상하지 아니 합니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사에서 고객에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.',
                        subList: [],
                    },
                    {
                        mmm: '회사는 다음 각 호의에 의해 손해가 발생한 경우에는 책임을 지지 아니 합니다.',
                        subList: [
                            '전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 불가항력적인 경우',
                            '고객의 고의 또는 과실로 인하여 발생한 경우',
                            '전기통신사업법에 의한 회사 외 타 기간통신사업자가 제공하는 전기통신서비스 장애 및 설비부족으로 인한 경우',
                            '전기통신서비스의 특성상 불가피한 사유로 서비스 제공이 불가능한 경우',
                        ],
                    },
                ],
                index : 21
            },
            {
                content: [
                    {
                        mmm: '본 약관과 관련하여 "해리지앤디(이하 "요양SYS")"와 "고객" 간에 분쟁이 발생한 경우 기본적으로 상호 협의하여 해결함을 원칙으로 하며, 합의가 이루어지지 않을 경우 관련법규 및 상관습에 따릅니다.',
                        subList: [],
                    },
                    {
                        mmm: '"서비스" 및 본 약관과 관련한 소송의 관할법원은 "해리지앤디(이하 "요양SYS")"의 소재지 관할법원으로 합니다.',
                        subList: [],
                    },
                ],
                index : 22
            },
        ],
    }),
};
</script>

<style>
    .policyBox { border: 1px solid #efefef; color: #7d7d7d; }
</style>