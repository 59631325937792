<template>
    <v-sheet class="ma-0 pa-0" rounded="md">
        <div id="csmWatch" class="rounded py-1 px-6" v-bind:style="contentStyle" @click="clicked">
            <div class="wrapper">
                <h1 v-if="one" v-bind:class="faded">
                    <span>{{timeOne}}</span>
                    <span class="font-weight-black" style="font-size: 1.8rem;">&nbsp;&#40;{{ nowDayNm }}&#41;</span>
                </h1>
                <h1 v-if="two" v-bind:class="faded">{{timeTwo}}</h1>
                <h1 v-if="three" v-bind:class="faded">{{timeThree}}</h1>
                <h1 v-if="four" v-bind:class="faded">{{timeFour}}</h1>
            </div>
        </div>
    </v-sheet>
</template>

<script>

export default {
    name: 'CsmClock',

    props : {
   
    },
        
    components: {
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.timeOne = this.$moment().format('LTS')
            this.timeTwo = this.$moment().format('l')
            this.timeThree = this.$moment().format('MMMM Do YY')
            this.timeFour = this.$moment().format('LT')

            setInterval(()=>{
                this.timeOne = this.$moment().format('LTS')
            },1000)
            this.nowDayNm = this.dayNm[this.$moment().day()]
        })
    },

    computed: {

    },

    watch:{

    },
    
    methods: {
        clicked: function() {
            this.contentStyle.backgroundColor = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
            this.instructionsStyle.opacity = 0
            // if(this.one == true){
            //     this.faded = 'faded'
            //     setTimeout(()=>{
            //         this.faded = ''
            //         this.one = false
            //         this.two = true
            //     },200)
            // }
            // else if(this.two == true) {
            //     this.faded = 'faded'
            //     setTimeout(()=>{
            //         this.faded = ''
            //         this.two = false
            //         this.three = true
            //     },200)
            // }
            // else if(this.three == true){
            //     this.faded = 'faded'
            //     setTimeout(()=>{
            //         this.faded = ''
            //         this.three = false
            //         this.four = true
            //     },200)
            // }
            // else if(this.four == true){
            //     this.faded = 'faded'
            //     setTimeout(()=>{
            //         this.faded = ''
            //         this.four = false
            //         this.one = true
            //     },200)
            // }
        }
    },

    data: () => ({
        contentStyle: {
            backgroundColor: "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16)})
        },
        timeOne: null,
        timeTwo: null,
        timeThree: null,
        timeFour: null,
        one: true,
        two: false,
        three: false,
        four: false,
        faded: 'd-flex justify-center align-center',
        instructionsStyle: {
            opacity: 1,
            transition: '.2s'
        },
        nowDayNm: '',
        dayNm: ['일', '월', '화', '수', '목', '금', '토',],
    }),
};
</script>

<style>
    #csmWatch {
        cursor: pointer;
        color: #fff; transition: .5s; text-align: center;
    }
    .wrapper { margin-bottom: 2px; }
    h1 { transition: .2s; font-size: 2rem; margin: 0; }
    p { transition: .2s; }
    .faded { opacity: 0; transition: .2s; }
</style>