<template>
    <v-sheet>
        <v-form ref="tabform" lazy-validation>
        <div class="d-flex">
            <div class="d-inline-block" style="width:540px;">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">배설 간호 기록</span>
                    <v-spacer></v-spacer>
                    <span v-if="preBtn[0]">
                        <v-btn 
                            class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                            min-width="30" height="25"
                            @click="getPreItmes"
                            small outlined>
                            <v-icon size="14">mdi-text-box-search-outline</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                        </v-btn>
                    </span>
                </div>
                <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                        <span class="black--text font-weight-medium">제공자</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="3">
                        <span class="d-inline-block" style="width: 100px;">
                            <v-text-field v-model="items.wrtr" color="blue" ref="info" readonly
                                :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="26"  
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(0)"
                            >                                    
                            <v-tooltip top >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon size="20" class="white--text" v-bind="attrs" v-on="on">mdi-account-edit</v-icon>
                                </template>
                                <span>직원선택</span>
                            </v-tooltip>

                        </v-btn>   
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>                    
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                        <div>
                            <div class="d-flex justify-center black--text font-weight-medium">배설간호</div>
                            <div class="d-flex justify-center black--text font-weight-medium">내용</div>
                        </div>
                    </v-col>
                    <v-col class="justify-start align-center pa-1 ma-0" cols="8">
                        <div class="d-inline-block" v-for="(item, i) in codeItem33" :key="i">
                            <v-checkbox v-if="item.valcdnm !='기타'" v-model="items.itm3ValAr" :label="item.valcdnm" hide-details :value="item.valcd" :style="setStyle(item.valcdnm.length)"></v-checkbox>
                        </div>
                        <div class="d-flex mt-1">
                            <v-checkbox v-model="items.itm3ValAr" label="기타" hide-details value="7" @click="onItemChk"></v-checkbox>
                            <div style="width:120px;">
                                <v-text-field v-model="items.itm3Val" :disabled="etcDisabled" hide-details outlined dense></v-text-field>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="4">
                        <div>
                            <div class="d-flex justify-center black--text font-weight-medium">관리기록</div>
                        </div>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="8">
                        <v-textarea v-model="items.cntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="9" outlined dense hide-details no-resize>
                        </v-textarea>
                    </v-col>
                </v-row>
            </div>
            <div class="d-inline-block pl-2 ma-0 align-self-start" style="width:460px;">
                <div class="d-flex align-center">
                    <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                    <span class="text-h6 font-weight-bold">배설 관찰 기록</span>
                    <v-spacer></v-spacer>
                    <span v-if="preBtn[1]">
                        <v-btn 
                            class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                            min-width="30" height="25"
                            @click="getPreRecItems"
                            small outlined>
                            <v-icon size="14">mdi-text-box-search-outline</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">이전자료</span>                
                        </v-btn>
                    </span>
                </div>
                <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                        <span class="black--text font-weight-medium">대변</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="9">
                        <span style="width:60px" class="ml-1">
                            <v-text-field v-model="recItems1.itm1Val" type="number" hide-details outlined dense></v-text-field>
                        </span>               
                        <span class="ml-1">회</span>         
                    </v-col>                    
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                        <span class="black--text font-weight-medium">소변</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="9">
                        <span style="width:60px" class="ml-1">
                            <v-text-field v-model="recItems1.itm2Val" type="number" hide-details outlined dense></v-text-field>
                        </span>     
                        <span class="ml-1">회</span>
                    </v-col>                    
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                        <span class="black--text font-weight-medium">설사</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="9">
                        <span style="width:60px" class="ml-1">
                            <v-text-field v-model="recItems1.itm3Val" type="number" hide-details outlined dense></v-text-field>
                        </span>  
                        <span class="ml-1">회</span>
                    </v-col>                    
                </v-row>
                 <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                        <span class="black--text font-weight-medium">특이사항</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="9">
                        <v-textarea v-model="recItems1.spclNote" class="rmrksArea" style="font-size:0.9rem !important;"
                            rows="3" outlined dense hide-details no-resize>
                        </v-textarea>
                    </v-col>                    
                </v-row>
            </div>
        </div>        
        </v-form>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">배설 간호 기록 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="210" locale="ko"
                :headers="histHeaders"
                :items="nsgCareRcrdHist" 
                hide-default-header       
                hide-default-footer 
                fixed-header
                dense disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:15%">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:35%">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:35%">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:15%">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr :class="['fixedCol', $parent.$parent.$parent.$parent.inqYMD == item.recDt ? 'sltRow' : 'disSltRow']"
                        @click="tableClickEvent(item.recDt)">
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.recDt?.beforeDateFormatDot()}}</td> 
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:250px;" class="overflow-text-hidden">{{item.itm2ValNm}}</div>
                        </td>                             
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:250px;" class="overflow-text-hidden">{{item.cntnt}}</div>
                        </td>  
                        <td class="clmsBodyCommon fixedCol0">{{item.wrtr}}</td>            
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div>            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delModelTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div>
    </v-sheet>
</template>
<script>
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import { selBnftOfrRecList, selExcrtNrsRecList, selPreBnftOfrRecList, insBnftOfrRecMulti, delAllBnftOfrRec } from '../../api/bnftrcrd.js';
import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'NsgCareRcrdTab3',
    
    components: {
        EmpSelector,
        btnModalDelete
    },

    props : {
        
    },

    created: function(){       
      getMultiStcd(['31','32','33'], this.$store.getters.getFcltyNum)
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
    },

    mounted: function(){
        
    },

    computed: {
       
    },  

    watch: {
    },

    methods: {
        codeMapping: function (res){            
            this.codeItem31 = res.vCd31
            this.codeItem33 = res.vCd33
            this.$refs.tabform.reset()
        },  
        setStyle:function(len){
            let style = "";
            let wd = (len*15)+20;
            style="width:"+wd+"px"        
            return style;
        },  
        onLoad:function(){
            this.frmReset()
            this.getBnftOfrRecList() //배설간호기록            
            this.getExcrtNrsRecList(0,this.itemsRows)
            this.$refs.tabform.resetValidation()
        },    
        frmReset:function(){
            this.$refs.tabform.reset()              
            this.items.bnftOfrRecHisPk  = 0
            this.items.bnftOfrRecPk     = 0
            this.items.recDt            = '',
            this.items.bnMmbrPk         = 0,
            this.items.userNm           = '',
            this.items.itm1Val          = ''
            this.items.itm2ValAr.splice(0)
            this.items.itm3ValAr.splice(0)
            this.items.itm3Val          = ''
            this.items.cntnt            = ''
            this.items.wrtr             = ''
            this.items.wrtrMmbrPk       = 0

            this.recItems1.bnftOfrRecHisPk  = 0
            this.recItems1.bnftOfrRecPk     = 0
            this.recItems1.recDt            = ''
            this.recItems1.spclNote         = ''
            this.recItems1.itm1Val          = ''
            this.recItems1.itm2Val          = ''
            this.recItems1.itm3Val          = ''
            this.recItems1.wrtr             = ''
            this.recItems1.wrtrMmbrPk       = 0
        },
        getBnftOfrRecList1(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '301',
                clsfc2Cd: '3',
                clsfc3Cd: '3',
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD: this.$parent.$parent.$parent.$parent.inqYMD,
            }

            selBnftOfrRecList(obj)
            .then((response) => ( this.getBnftOfrRecList1After(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecList : ' + error))
        },

        getBnftOfrRecList(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                inqTFclsfc1Cd: true, 
                inqTFclsfc2Cd: true, 
                inqTFclsfc3Cd: true, 
                inqTFitmCd: true, 
                clsfc1Cd: '303',
                clsfc2Cd: '2',
                clsfc3Cd: '1',
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                inqYMD: this.$parent.$parent.$parent.$parent.inqYMD,
            }
            selBnftOfrRecList(obj)
            .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRecList : ' + error))
        },

       
        
        getBnftOfrRecList1After:function(res){
            res.forEach(e => {        
                this.recItems1.bnftOfrRecHisPk = e.bnftOfrRecHisPk
                this.recItems1.bnftOfrRecPk = e.bnftOfrRecPk
                this.recItems1.spclNote = e.spclNote
                this.recItems1.itm1Val = e.itm1Val
                this.recItems1.itm2Val = e.itm2Val
                this.recItems1.itm3Val = e.itm3Val                    
                this.recItems1.wrtr = e.wrtr   
                this.recItems1.wrtrMmbrPk = e.wrtrMmbrPk                                  
            });

        },

        getBnftOfrRecListAfter:function(res){            
            res.forEach(e => {                           
                this.items.bnftOfrRecHisPk = e.bnftOfrRecHisPk
                this.items.bnftOfrRecPk = e.bnftOfrRecPk
                this.items.itm1Val = e.itm1Val                
                this.items.itm3ValAr = []
                this.items.itm3Val = e.itm3Val
                this.items.cntnt = e.cntnt
                this.items.wrtr = e.wrtr
                this.items.wrtrMmbrPk = e.wrtrMmbrPk

                if(e.itm3ValAr ){
                    e.itm3ValAr.forEach(val => {
                        this.items.itm3ValAr.push(val)
                    });
                }

                this.onItemChk()
            });

            this.getBnftOfrRecList1() //화장실 이용기록(배설관찰)
        },        
        
        getPreItmes(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc1Cd: '303',
                clsfc2Cd: '2',
                clsfc3Cd: '1',
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreBnftOfrRecList1After(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selPreBnftOfrRecList : ' + error))
        },
        getPreBnftOfrRecList1After:function(res){
            res.forEach(el => {
                this.items.itm1Val = el.itm1Val                
                this.items.itm3ValAr = []
                this.items.itm3Val = el.itm3Val
                this.items.cntnt = el.cntnt
                this.items.wrtr = el.wrtr
                this.items.wrtrMmbrPk = res.wrtrMmbrPk
                if(el.itm3ValAr.length > 0 ){
                    el.itm3ValAr.forEach(val => {
                        this.items.itm3ValAr.push(val)
                    });
                }

                this.onItemChk()                
            });                        
        },

        getPreRecItems(){
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc1Cd: '301',
                clsfc2Cd: '3',
                clsfc3Cd: '3',
                inqYMD:this.$parent.$parent.$parent.$parent.inqYMD,
            }
            selPreBnftOfrRecList(obj)
            .then((response) => ( this.getPreBnftOfrRecList2After(response.data)))
            .catch((error) => console.log('upload error /bnftrcrd/selPreBnftOfrRecList : ' + error))
        },

        getPreBnftOfrRecList2After:function(res){
            res.forEach(e => {                        
                this.recItems1.spclNote = e.spclNote
                this.recItems1.itm1Val = e.itm1Val
                this.recItems1.itm2Val = e.itm2Val
                this.recItems1.itm3Val = e.itm3Val                    
                this.recItems1.wrtr = e.wrtr   
                this.recItems1.wrtrMmbrPk = e.wrtrMmbrPk    
                              
            });
        },

        getExcrtNrsRecList : function(strt, lmt){     
            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum,
                bnMmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                clsfc3Cd: 1,
                rnStrt:strt,
                rnLmt:lmt, 
            }         
            selExcrtNrsRecList(obj)
                .then((response) => ( this.getExcrtNrsRecListAfter(response.data)))
                .catch((error) => console.log(error))                
        },
        getExcrtNrsRecListAfter:function(res){
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)            
            this.nsgCareRcrdHist = res.list

            this.nsgCareRcrdHist.forEach(e => {
                let target = this.codeItem31.find(x=>x.valcd == e.itm1Val) 
                e.itm1ValNm = target.valcdnm
                let itm2ValNm = ""
                let n=0
                e.itm2ValAr.forEach(e => {                                        
                    if(n == 0)
                        itm2ValNm = e
                    else
                        itm2ValNm += ", "+e
                    n++
                });

                e.itm2ValNm = itm2ValNm
            });               
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getExcrtNrsRecList(strt,this.itemsRows)
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk            
        },
        onItemChk:function(){
            let etcBl = this.items.itm3ValAr.some((element) => element =='7')
            this.etcDisabled = !etcBl            
        },
        onforce(key){
            this.mtimes[key] = false
            ++this.forceRender
        },
        onSave:function(){
            if(this.$parent.$parent.$parent.$parent.bnMmbrPk > 0){
                
                if(this.$refs.tabform.validate()){

                    let recItems = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,
                        recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                        wrtr        : this.items.wrtr,
                        wrtrMmbrPk  : this.items.wrtrMmbrPk,          
                        clsfc1Cd    : '303',
                        clsfc2Cd    : '2',
                        clsfc3Cd    : '1',
                        cntnt:this.items.cntnt,
                        listDtl : [
                            {                            
                                itm1Val:'5',
                                itm1Cd:'31',
                                itm2ValAr:this.items.itm2ValAr,
                                itm2Cd:'32',
                                itm3ValAr:this.items.itm3ValAr,
                                itm3Cd:'33',
                                itm3Val:this.items.itm3Val,
                                wrtr:this.items.wrtr,
                                wrtrMmbrPk:this.items.wrtrMmbrPk,
                            }
                        ]
                    }
    
    
                    let recItems1 = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        bnMmbrPk    : this.$parent.$parent.$parent.$parent.bnMmbrPk,
                        userNm      : this.$store.getters.getUserNm,
                        recDt       : this.$parent.$parent.$parent.$parent.inqYMD,
                        wrtr        : this.items.wrtr,
                        wrtrMmbrPk  : this.items.wrtrMmbrPk,                 
                        clsfc1Cd    : '301',
                        clsfc2Cd    : '3',
                        clsfc3Cd    : '3',
                        spclNote    : this.recItems1.spclNote,
                        listDtl     : [
                            {                                
                                itm1Cd:'1',
                                itm1Val:this.recItems1.itm1Val,
                                itm2Cd:'2',
                                itm2Val:this.recItems1.itm2Val,
                                itm3Cd:'3',
                                itm3Val:this.recItems1.itm3Val,
                                wrtr:this.items.wrtr,
                                wrtrMmbrPk:this.items.wrtrMmbrPk,
                            }
                        ]
                    }
                    let obj = {list:[recItems,recItems1]}
                    this.$parent.$parent.$parent.$parent.progress = true
                    insBnftOfrRecMulti(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insBnftOfrRecMulti : ' + error))
                }else{
                    let error = {
                        statusCode:500,
                        message:'필수항목을 입력해주세요.'
                    }
                    this.$emit("nsgCareRcrdTrigger",error)
                }


            }else{
                let error = {
                    statusCode:500,
                    message:'수급자를 선택해주세요.'
                }
                this.$emit("nsgCareRcrdTrigger",error)
            }
        },
        onSaveAfter:function(res){
            this.$emit('nsgCareRcrdTrigger', res);
            this.onLoad()
        },   
        delAll(){
            this.delModelTitle='배설관리'
            this.type = 'all'
            this.dialogDelete = true
        },
        onDelete:function(res){
            if(res == 9990){
                this.dialogDelete = false
                let fcltyNum = this.$store.getters.getFcltyNum     
                                
                let obj = {list:[]}  

                if(this.items.bnftOfrRecHisPk > 0){
                    obj.list.push({
                        fcltyNum:fcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        bnftOfrRecHisPk:this.items.bnftOfrRecHisPk,
                    })
                }
                
                if(this.recItems1.bnftOfrRecHisPk > 0){
                    obj.list.push({
                        fcltyNum:fcltyNum,
                        userNm:this.$store.getters.getUserNm,
                        bnftOfrRecHisPk:this.recItems1.bnftOfrRecHisPk,
                    })
                }

                if(obj.list.length > 0) {
                    this.$parent.$parent.$parent.$parent.progress = true
                    delAllBnftOfrRec(obj)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delAllBnftOfrRec : ' + error))
                } else {
                    this.$emit('onMsgTrigger','warning','삭제 대상이 존재하지 않습니다.')
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){            
            this.$emit('nsgCareRcrdTrigger', res);
            this.onLoad()
            this.dialogDelete = false            
        },
        tableClickEvent(value){
            if(value != ''){
                this.$emit("setDate",value)
            }
        },
    },
    data: () => ({
        idx:0,
        type:'',
        delModelTitle:'배설관리',
        forceRender:0,
        pageCount:0,
        itemsRows: 5,
        page:1,
        codeItem31:[],
        codeItem33:[],
        items:{
            bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt : '', bnMmbrPk:0, userNm:'',
            clsfc1Cd:'303',clsfc2Cd:'2',clsfc3Cd:'1',
            itm1Val:'',itm1Cd:'31',itm2ValAr:[],itm2Cd:'32',itm3ValAr:[],itm3Cd:'33',itm3Val:'',
            cntnt:'',wrtr:'',wrtrMmbrPk:0,},       
        recItems1:{
            bnftOfrRecHisPk:0,bnftOfrRecPk:0,recDt:'',
            spclNote:'',clsfc1Cd:'301',clsfc2Cd:'3',clsfc3Cd:'3',
            itm1Cd:'1',itm1Val:'',itm2Cd:'2',itm2Val:'',itm3Cd:'3',itm3Val:'',wrtr:'',wrtrMmbrPk:0,
        },   
        restot:{itm1ValTot:0},
        etcDisabled:true,
        nsgCareRcrdHist:[],
        histHeaders: [
            { text: '일자',width: '', value: '', align: 'center', },
            { text: '조치사항',width: '', value: '', align: 'center', },
            { text: '간호내용',width: '', value: '', align: 'center', },
            { text: '제공자',width: '', value: '', align: 'center', },
        ],
        dialog:[false,],
        rtimes:[false,false,false,false,false,false,false,],
        mtimes:[false,false,false,false,false,false,false,],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
        preBtn:[true,true],
    }),
};
</script>