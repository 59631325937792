<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">본인부담금&nbsp;정산내역</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <div v-if="cpyClmInf != null">
            <v-divider class="mt-5"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="1">
                    <span class="black--text font-weight-medium fontOneDotOneRem">수급자</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="2">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.name }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                    <span class="black--text font-weight-medium fontOneDotOneRem">제공일수</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="1">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.ofrDys }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                    <span class="black--text font-weight-medium fontOneDotOneRem">본인부담률</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="2">
                    <span class="black--text fontOneDotOneRem">
                        {{ cpyClmInf.copayRateNm }}&#40;{{ cpyClmInf.copayRateDesc }}&#41;
                    </span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="1">
                    <span class="black--text font-weight-medium fontOneDotOneRem">등급</span>
                </v-col>
                <v-col class="d-flex justify-center align-center tableBL" cols="1">
                    <span :class="'ic-level level0' + cpyClmInf.acknwRtng" style="margin: auto;">
                        {{ cpyClmInf.acknwRtng == '99' ? '외' : cpyClmInf.acknwRtng }}
                    </span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="mt-2"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                    <span class="black--text font-weight-medium fontOneDotOneRem">청구년월</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="2">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.clmYymm }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                    <span class="black--text font-weight-medium fontOneDotOneRem">청구일</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="2">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.clmDt }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="2">
                    <span class="black--text font-weight-medium fontOneDotOneRem">납부기한</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="2">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.dueDt }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">본인부담금&nbsp;ⓐ</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.copay?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">공단부담금</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.nhisPay?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="6">
                    <span class="black--text font-weight-medium fontOneDotOneRem">
                        급여&nbsp;계&nbsp;&#40;본인부담금&#43;공단부담금&#41;
                    </span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="6">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.totPay?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-divider class="mt-4"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">식사재료비&nbsp;①</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.mealCost?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">상급침실비&nbsp;②</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.upBedFee?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">이,미용비&nbsp;③</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.btyFee?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">경관유동식&nbsp;④</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.lqdFood?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">간식비&nbsp;⑤</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.snackFee?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">기타비용&nbsp;⑥</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.msclnFee?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">진료약제비&nbsp;⑦</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.clncPhrmcFee?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">계약의사&nbsp;진찰비&nbsp;⑧</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.cntrcDctrFee?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">등급외&nbsp;⑨</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.otrAmt?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">이전미납금&nbsp;⑩</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.unpdAmt?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-2" cols="9">
                    <span class="black--text font-weight-medium fontOneDotOneRem">
                        비급여&nbsp;계&nbsp;&nbsp;ⓑ&nbsp;=&nbsp;①&#43;②&#43;③&#43;④&#43;⑤&#43;⑥&#43;⑦&#43;⑧&#43;⑨&#43;⑩
                    </span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-2" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.totNonPymnt?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-2" cols="9">
                    <span class="black--text font-weight-medium fontOneDotOneRem">
                        당월&nbsp;본인부담금&nbsp;합계&nbsp;ⓒ&nbsp;=&nbsp;ⓐ&#43;ⓑ&#45;⑩
                    </span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-2" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.mnthTotPymnt?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">선납적용액&nbsp;ⓓ</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL tableBR py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.prpymApl?.toLocaleString('ko-KR') }}</span>
                </v-col>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">잔여선납금&nbsp;</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.rmnPrpym?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-2" cols="9">
                    <span class="black--text font-weight-medium fontOneDotOneRem">
                        부담금&nbsp;총합&#40;ⓐ&#43;ⓑ&#45;ⓓ&#41;
                    </span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-2" cols="3">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.totLevy?.toLocaleString('ko-KR') }}</span>
                </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="greyE01 txtAlgnMid py-1" cols="3">
                    <span class="black--text font-weight-medium fontOneDotOneRem">비고</span>
                </v-col>
                <v-col class="txtAlgnMid tableBL py-1" cols="9">
                    <span class="black--text fontOneDotOneRem">{{ cpyClmInf.rmrks }}</span>
                </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
        </div>
        <div v-else>
            <v-divider class="mt-5"></v-divider>
            <v-row class="py-3 justify-center" no-gutters>
                <span class="grey006--text">※ 비정상 호출로 인해 데이터 조회에 실패했습니다.</span>
            </v-row>
            <v-divider class=""></v-divider>
        </div>
    </v-sheet>
</template>

<script>
import { selCopayClmHisInfo } from '../../api/bnfcr.js';

export default {
    name: 'RtrmnStlmnDtl',

    components: {
      
    },

    props : {
        prtCd62:{ type: Array, default: () => { return [] } },
        prtCd63:{ type: Array, default: () => { return [] } },
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getSubInObj != null){
                let obj = this.$store.getters.getSubInObj

                this.mmbrPk = obj.bnMmbrPk
                this.bnfcrPk = obj.bnfcrPk
                this.inqYYMM = this.$moment(obj.lvDt, 'YYYYMMDD').format('YYYYMM')

                this.getCpyClmHisInf()
            }
        })
    },

    computed: {
      
    },

    watch: {
        
    },

    methods:{
        getCpyClmHisInf: function () {
            if( this.inqYYMM == '' && this.mmbrPk == -1 && this.bnfcrPk == -1 )
                return

            selCopayClmHisInfo(this.$store.getters.getFcltyNum, this.inqYYMM, this.mmbrPk, this.bnfcrPk)
                .then((response) => ( this.cpyClmHisRst(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmHisInfo : ' + error))
        },
        cpyClmHisRst: function (res) {
            if(Object.keys(res).length > 0){
                res.clmYymm = this.$moment(res.clmYymm, 'YYYYMM').format('YYYY.MM')
                res.clmDt = this.$moment(res.clmDt, 'YYYYMMDD').format('YYYY.MM.DD')
                res.dueDt = this.$moment(res.dueDt, 'YYYYMMDD').format('YYYY.MM.DD')
                res.ofrDys += '일'

                let chc = this.prtCd63.findIndex( v => v.valcd == res.copayRate )
                if(chc > -1){
                    res.copayRateNm = this.prtCd63[chc].valcdnm?.slice(0, 3)?.replaceAll('(', '')
                    res.copayRateDesc = this.prtCd63[chc].valdesc + '%'
                }

                res.totPay = res.copay + res.nhisPay
                res.totNonPymnt = res.mealCost + res.upBedFee + res.btyFee + res.lqdFood + res.snackFee
                    + res.msclnFee + res.clncPhrmcFee + res.cntrcDctrFee + res.otrAmt + res.unpdAmt
                res.mnthTotPymnt = res.copay + res.totNonPymnt - res.unpdAmt
                res.totLevy = res.copay + res.totNonPymnt - res.prpymApl

                this.cpyClmInf = res
            }
        },
    },

    data: () => ({
       inqYYMM: '',
       mmbrPk: -1,
       bnfcrPk: -1,
       cpyClmInf: null,
    })
}
</script>