<template>
    <v-sheet class="d-inline-block rounded-lg rounded-l-0 py-2 px-4" min-width="1260" max-width="1560" height="835" color="">
        <v-row class="pa-0 ml-1" no-gutters>
            <span class="text-h4">
                <span class="hamletFont text-h5 topTitleColor--text font-weight-black">1-2. 상담일지</span>
            </span>
        </v-row>

        <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>

        <v-row class="my-2" no-gutters>
            <v-col cols="1">
                <exBtn :btnData="exBtn[0]" @btnClosed="expandClose" ref="exBtnClose0" @changeCheck="filterSearchD1" />
            </v-col>
            <v-col cols="1">
                <exBtn :btnData="exBtn[1]" @btnClosed="expandClose" ref="exBtnClose1" @changeCheck="filterSearchD2" />
            </v-col>
            <v-col cols="2">
                <span class="d-inline-block ml-2" style="width: 120px;">
                    <v-text-field
                        class="ma-0 pa-0 nameSearch" width="100px" height="35px"
                        label="이름조회" background-color="white" append-icon="mdi-magnify"
                        @input="setSearchText" 
                        single-line rounded outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-col>
        </v-row>
        <v-data-table
            v-model="selectedItemIndex" class="cnslnJrnls msclnTable" locale="ko" height="700"
            :search="schDummy" :custom-filter="customFilter" :headers="headers" :items="items" :loading="cnslnLdr"
            fixed-header hide-default-header hide-default-footer disable-pagination dense>

            <template v-slot:header="{ props }" >
                <thead>
                    <tr>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 5%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[0].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 8%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[1].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 5%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[2].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 5%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[3].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 7%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[4].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 7%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[5].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 9%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[6].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 5%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[7].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01" style="width: 6%;" rowspan='2'>
                            <span class="black--text">{{ props.headers[8].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01 py-1" style="width: 28%;" colspan='4'>
                            <span class="black--text">상담이력</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01 tblIntBR" style="width: 11%; border-right: initial !important;" rowspan='2'>
                            <div class="d-flex justify-center align-center">
                                <span class="black--text">{{ props.headers[13].text }}</span>
                                <v-btn
                                    class="ml-1" width="20" height="20"
                                    @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 2)"
                                    icon>
                                    <v-icon size="20" style="padding-top: 3px;">mdi-help-circle-outline</v-icon>
                                </v-btn>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01 py-1" style="width: 7%; border-top: initial !important;">
                            <span class="black--text">{{ props.headers[9].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01 py-1" style="width: 7%; border-top: initial !important;">
                            <span class="black--text">{{ props.headers[10].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01 py-1" style="width: 7%; border-top: initial !important;">
                            <span class="black--text">{{ props.headers[11].text }}</span>
                        </th>
                        <th class="tableHeaderCommon nmlBfClrImp-greyE01 py-1" style="width: 7%; border-top: initial !important;">
                            <span class="black--text">{{ props.headers[12].text }}</span>
                        </th>
                    </tr>
                </thead>
            </template>

            <template v-slot:item='{ item }'>
                <tr>
                    <td class="tableContentCommon tableBR">{{ item.rn }}</td>
                    <td class="tableContentCommon tableBR">{{ item.name }}</td>
                    <td class="tableContentCommon tableBR">
                        <v-img class="d-inline-block" style="vertical-align: middle;" v-if="item.gndr == 'F'" src="../../styles/images/ic-female.svg" width="24"></v-img>
                        <v-img class="d-inline-block" style="vertical-align: middle;" v-else-if="item.gndr == 'M'" src="../../styles/images/ic-male.svg" width="24"></v-img>
                    </td>
                    <td class="tableContentCommon tableBR">{{ item.age }}</td>
                    <td class="tableContentCommon tableBR">{{ item.entDtDot }}</td>
                    <td class="tableContentCommon tableBR">{{ item.lvDtDot }}</td>
                    <td class="tableContentCommon tableBR">{{ item.acknwPrdEndDot }}</td>
                    <td class="tableContentCommon tableBR">
                        <span :class="'d-inline-block ic-level level0'+item.acknwRtng">
                            {{ item.acknwRtng == '99' ? '외' : item.acknwRtng }}
                        </span>
                    </td>
                    <td class="tableContentCommon tableBR">{{item.entStcd}}</td>
                    <td class="tableContentCommon tableBR py-2">
                        <div v-if="item.cnsCnt1q > -1">
                            <v-btn
                                class="px-0" width="52" min-width="40" height="26"
                                :color="item.cnsCnt1q == 0 ? 'red' : 'black'"
                                @click="onModal(item.bnMmbrPk, item.bnfcrPk, 1)" outlined>
                                <span v-if="item.cnsCnt1q == 0">미작성</span>
                                <span v-else>{{ item.cnsCnt1q }}건</span>
                            </v-btn>
                        </div>
                        <div v-else>해당없음</div>
                    </td>
                    <td class="tableContentCommon tableBR py-2">
                        <div v-if="item.cnsCnt2q > -1">
                            <v-btn
                                class="px-0" width="52" min-width="40" height="26"
                                :color="item.cnsCnt2q == 0 ? 'red' : 'black'"
                                @click="onModal(item.bnMmbrPk, item.bnfcrPk, 2)" outlined>
                                <span v-if="item.cnsCnt2q == 0">미작성</span>
                                <span v-else>{{ item.cnsCnt2q }}건</span>
                            </v-btn>
                        </div>
                        <div v-else>해당없음</div>
                    </td>
                    <td class="tableContentCommon tableBR py-2">
                        <div v-if="item.cnsCnt3q > -1">
                            <v-btn
                                class="px-0" width="52" min-width="40" height="26"
                                :color="item.cnsCnt3q == 0 ? 'red' : 'black'"
                                @click="onModal(item.bnMmbrPk, item.bnfcrPk, 3)" outlined>
                                <span v-if="item.cnsCnt3q == 0">미작성</span>
                                <span v-else>{{ item.cnsCnt3q }}건</span>
                            </v-btn>
                        </div>
                        <div v-else>해당없음</div>
                    </td>
                    <td class="tableContentCommon tableBR py-2">
                        <div v-if="item.cnsCnt4q > -1">
                            <v-btn
                                class="px-0" width="52" min-width="40" height="26"
                                :color="item.cnsCnt4q == 0 ? 'red' : 'black'"
                                @click="onModal(item.bnMmbrPk, item.bnfcrPk, 4)" outlined>
                                <span v-if="item.cnsCnt4q == 0">미작성</span>
                                <span v-else>{{ item.cnsCnt4q }}건</span>
                            </v-btn>
                        </div>
                        <div v-else>해당없음</div>
                    </td>
                    <td class="tableContentCommon">{{ item.bnftOfrCnt }}건</td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" content-class="round" max-width="1300" persistent>
            <CnslnJrnls
                :prtBnMmbrPk="sltBnMmbrPk"
                :prtYear="year" :prtQtr="qtr" :cjMtd="vCd104"
                @isClose="modalClose()" @reload="reset()">
            </CnslnJrnls>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { getCnslnHisList } from '../../api/bnfcr.js';

import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import CnslnJrnls from '../../components/bnfcr/CnslnJrnlsModal.vue';

import exBtn from '../../components/commons/expandButtonCustom.vue';

export default {
    name: 'BnfcrCnslnJrnls',
        
    components: {
        exBtn,
        expandYearOnlyPicker,
        CnslnJrnls,
    },

    created: function(){

        getMultiStcd(['62', '65', '104'])
            .then((response) => ( this.codeMapping(response.data) ))
            .catch((error) => console.log('connect error /bnfcr/selCmnItmValCdJsn : ' + error))

        this.year = new Date().getFullYear()

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    watch:{
        'year': function(){
            this.getCnslnList()
        },
    },
        
    methods: {
        getCnslnList: function () {
            this.cnslnLdr = true
            this.items.splice(0)

            getCnslnHisList(this.$store.getters.getFcltyNum, this.year)
                .then((response) => ( this.getCnslnListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCnslnHisList : ' + error))
        },
        getCnslnListAfter: function (res) {
            if(res.length > 0){
                res.forEach(itm => {
                    if(itm.entDt != '' && itm.entDt != null)
                        itm.entDtDot = this.$moment(itm.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.entDtDot = ''
                    if(itm.lvDt != '' && itm.lvDt != null)
                        itm.lvDtDot = this.$moment(itm.lvDt, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.lvDtDot = ''
                    if(itm.acknwPrdEnd != '' && itm.acknwPrdEnd != null)
                        itm.acknwPrdEndDot = this.$moment(itm.acknwPrdEnd, 'YYYYMMDD').format('YYYY.MM.DD')
                    else
                        itm.acknwPrdEndDot = ''

                    this.entStCode.forEach((cd) => {
                        if(itm.entStcd == cd.valcd)
                            itm.entStcd = cd.valcdnm
                    });
                });
                this.items = res
            }
            this.cnslnLdr = false
        },
        codeMapping: function(res){
            this.acknwCode = res.vCd62
            this.entStCode  = res.vCd65
            this.vCd104  = res.vCd104

            this.acknwCode.forEach((cd) => {
                this.exBtn[1].expandList.push(cd.valcdnm)
            });

            this.entStCode.forEach((cd) => {
                this.exBtn[0].expandList.push(cd.valcdnm)
            });
        },
        reset: function (){
            this.selectedItemIndex = []
            this.schField = ''
            this.schDummy = ''
            this.schChkField1 = []
            this.schChkField2 = []
            this.getCnslnList()
        },
        getYear: function (yyyy){
            this.year = yyyy
        },
        onModal: function (bnmmbrPk, bnfcr, q){
            this.dialog = true
            this.sltBnMmbrPk = Number(bnmmbrPk)
            this.sltBnfcrPk = Number(bnfcr)
            this.qtr = q
        },
        modalClose: function (){
            this.dialog = false
            this.sltBnMmbrPk = 0
            this.sltBnfcrPk = 0
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.$refs.exBtnClose1.isClose()
                    break;
                case 1: this.$refs.exBtnClose0.isClose()
                    break;
                default: this.$refs.exBtnClose0.isClose()
                    this.$refs.exBtnClose1.isClose()
                    break;
            }
        },
        setSearchText: function (value) {
            this.schField = value

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD1: function (sch) {
            if(sch.length == 0) this.schChkField1.push('')

            this.schChkField1 = []
            sch.forEach((item) => {
                this.schChkField1.push(item)
            });

            //배열 공백 제거
            this.schChkField1 = this.schChkField1.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField1 = this.schChkField1.filter((val, idx) => {
                return this.schChkField1.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        filterSearchD2: function (sch) { 
            if(sch.length == 0) this.schChkField2.push('')

            this.schChkField2 = []
            sch.forEach((item) => {
                this.acknwCode.forEach((cd) => {
                    if(item == cd.valcdnm) item = cd.valcd
                })
                this.schChkField2.push(item)
            });

            //배열 공백 제거
            this.schChkField2 = this.schChkField2.filter(function(item) {
                return item !== null && item !== undefined && item !== '';
            });
            //배열 중복 값 제거
            this.schChkField2 = this.schChkField2.filter((val, idx) => {
                return this.schChkField2.indexOf(val) === idx
            });

            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column

            let col0 = filter.name.includes(this.schField)
            //console.log('text : ' + this.schField)
            

            let col1 = true
            if(this.schChkField1.length != 0){
                this.schChkField1.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.entStcd.includes(stcd)
                    else col1 = col1 || filter.entStcd.includes(stcd)
                    //console.log('array1 : ' + this.schChkField1)
                });
            }

            let col2 = true
            if(this.schChkField2.length != 0){
                this.schChkField2.forEach((acknw, i) => {
                    if(i == 0) col2 = filter.acknwRtng.includes(acknw)
                    else col2 = col2 || filter.acknwRtng.includes(acknw)
                    //console.log('array2 : ' + this.schChkField2)
                });
            }

            return col0 && col1 && col2;
        },
    },
    
    data: () => ({
        dialog: false,
        cnslnLdr: true,
        sltBnMmbrPk: 0,
        sltBnfcrPk: 0,
        items: [],
        acknwCode: null,
        entStCode: null,
        vCd104: [],
        year: 1990,
        qtr: 0,
        selectedItemIndex:[],
        schField: '',
        schDummy: '',
        schChkField1: [],
        schChkField2: [],
        exBtn: [
            {
                index:  0,
                btnTitle: '입소현황',
                btnIcon: 'house',
                exWidth: 104,
                exHeigth: 130,
                expandList: [],
            },
            {
                index:  1,
                btnTitle: '등급',
                btnIcon: 'wheelchair',
                exWidth: 104,
                exHeigth: 194,
                expandList: [],
            },
        ],
        headers: [
            { text: '연번', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6'},
            { text: '수급자명', value: 'name', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '성별', value: 'gndr', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '나이', value: 'age', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '입소일', value: 'entDt', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '퇴소일', value: 'lvDt', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '인정만료일', value: 'acknwPrdEnd', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '등급', value: 'acknwRtng', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '현황', value: 'entStcd', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '1분기', value: 'cnsCnt1q', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '2분기', value: 'cnsCnt2q', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '3분기', value: 'cnsCnt3q', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '4분기', value: 'cnsCnt4q', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '급여제공 반영', value: 'bnftOfrCn', align: 'center', sortable: true, width: '100', class: 'black--text'},
        ],
    }),
};
</script>
<style>
    .tableHeaderCommon{ border-top: 1px solid #dbdbdb !important; border-right: 1px solid #dbdbdb !important;
        background-color: #ededed; text-align: center !important; font-size: 1.1rem !important; }
    .tableContentCommon { font-size: 1rem !important; text-align: center !important; border-bottom: 1px solid #dbdbdb !important; }
    .inCustomStyle { border: 1px solid #666; }
    .inCustomStyleFocus { border: 1px solid red; color: red; }
    .round { border-radius: 24px; }
    .cnslnSearch div .v-input__slot { padding: 0 8px 0 12px !important; }
    .cnslnJrnls div table { border-collapse: separate !important; border-spacing: 0px !important; position: sticky !important;
        z-index: 3 !important; }
</style>