<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" style="position:relative">
        <v-form ref="mdlform" lazy-validation>
            <div class="d-flex justify-center align-center">
                <span class="text-h4 font-weight-bold">프로그램정보&nbsp;출력</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
        </v-form>
    </v-sheet>
</template>

<script>

export default {
    name: 'PrgrmPrint',

            
    components: {
       
    },

    created: function(){
       
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {

    },

    watch: {

    },
        
    methods: {
        onClose: function () {
            this.$emit('isClose', '.')
        },
    },
    
    data: () => ({
        
    }),
};
</script>