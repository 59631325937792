<template>
    <div>
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="" cols="9">
                <div class="d-flex">
                    <span
                        class="d-inline-block rounded-lg rounded-r-0 elevation-7 py-6 px-7"
                        style="width: 50%; height: 740px; background-color: #faf8fd; position: relative;">

                        <v-row class="ma-0 pa-0" no-gutters>
                            <v-col class="" cols="1">
                                <v-btn @click="$parent.$parent.$parent.authStep=0" icon>
                                    <v-icon class="pt-1" color="black" size="30">mdi-chevron-left</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="mt-10 pa-0" no-gutters>
                            <v-col class="d-flex justify-center" cols="12">
                                <span class="font-weight-bold mr-1" style="font-size: 2rem;">아이디 찾기</span>
                            </v-col>
                        </v-row>
                        <v-form ref="findIdInf" lazy-validation>
                            <v-row class="px-10" style="margin-top: 120px;" no-gutters>
                                <v-col class="" cols="12">
                                    <v-text-field
                                        v-model="findInf.fcltyNum1"
                                        class="authTxtField rgstrWhClr" hint="'-' 를 제외한 14자리 숫자만 입력해주세요." label="기관번호*"
                                        :rules="[rules.required, rules.fcltyConfirm, rules.numberCheck]"
                                        counter outlined>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-8 pa-0" no-gutters>
                                <v-col class="d-flex align-center px-10" cols="12">
                                    <span class="d-inline-block" style="width: 29%;">
                                        <v-text-field
                                            v-model="findInf.emailId"
                                            class="authTxtField rgstrWhClr" label="이메일*"
                                            :rules="[rules.required]"
                                            hide-details outlined>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block d-flex justify-center align-center mx-2" style="width: 28px; height: 28px;">
                                        <v-icon size="22" style="color: #dbdbdb;">mdi-at</v-icon>
                                    </span>
                                    <span class="d-inline-block" style="width: 30%;">
                                        <v-text-field
                                            v-model="findInf.emailDmn"
                                            class="authTxtField rgstrWhClr" label="도메인"
                                            :rules="[rules.required]" :disabled="findInf.emailDmnSlt != '0'"
                                            hide-details outlined>
                                        </v-text-field>
                                    </span>
                                    <span class="d-inline-block ml-1" style="width: 29%;">
                                        <v-select
                                            v-model="findInf.emailDmnSlt"
                                            class="rgstrSlt" :items="$parent.$parent.$parent.emailCode" @change="emailSelected"
                                            item-text="valcdnm" item-value="valcd"
                                            dense outlined hide-details>
                                        </v-select>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row class="pa-0" style="margin-top: 160px;" no-gutters>
                            <v-col class="px-10" cols="12">
                                <v-btn class="py-6" width="100%" color="#3688c1" @click="preFindId()">
                                    <span class="white--text" style="font-size: 1.3rem;">아이디 찾기</span>
                                </v-btn>
                            </v-col>
                        </v-row>

                    </span>
                    <span
                        class="d-inline-block rounded-lg rounded-l-0 elevation-7 py-6 px-7"
                        style="width: 50%; height: 740px; background-color: white; position: relative;">

                        <v-row class="pa-0" style="margin-top: 76px;" no-gutters>
                            <v-col class="d-flex justify-center" cols="12">
                                <span class="font-weight-bold mr-1" style="font-size: 2rem;">비밀번호 찾기</span>
                            </v-col>
                        </v-row>
                        <v-form ref="findPasswdInf" lazy-validation>
                            <v-row class="px-10" style="margin-top: 120px;" no-gutters>
                                <v-col class="" cols="12">
                                    <v-text-field
                                        v-model="findInf.fcltyNum2"
                                        class="authTxtField rgstrWhClr" hint="'-' 를 제외한 14자리 숫자만 입력해주세요." label="기관번호*"
                                        :rules="[rules.required, rules.fcltyConfirm, rules.numberCheck]"
                                        counter outlined>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-8 pa-0" no-gutters>
                                <v-col class="d-flex align-center px-10" cols="12">
                                    <v-text-field
                                        v-model="findInf.id"
                                        class="authTxtField rgstrWhClr" hint="2자리 이상 한글, 영문, 숫자만 입력 가능합니다." label="아이디*"
                                        :rules="[rules.required, rules.strIdSize, rules.regExp]"
                                        outlined>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="pa-0" style="margin-top: 128px;" no-gutters>
                                <v-col class="px-10" cols="12">
                                    <v-btn class="py-6" width="100%" color="#2a9bed" @click="preFindPwd()">
                                        <span class="white--text" style="font-size: 1.3rem;">비밀번호 찾기</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        
                    </span>
                </div>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </div>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'FrgtIdPswrd',

    components: {
     
    },

    computed:{

    },

    watch:{

    },

    created: function(){
        
    },

    mounted: function(){
        this.$nextTick(function () {

        })
    },

    methods: {
        findId: function (obj){
            http.post('auth/srchId', obj)
                .then((response) => ( this.axiosResult(response.data) ))
                .catch((error) => console.log('axios error /auth/srchId : ' + error))
        },
        findPwd: function (obj){
            http.post('auth/srchPw', obj)
                .then((response) => ( this.axiosResult(response.data) ))
                .catch((error) => console.log('axios error /auth/srchPw : ' + error))
        },
        axiosResult: function (res){
            if(res.statusCode == '200')
                this.$parent.$parent.$parent.snackControll([true, 3000, res.message, 'info'])
            else if(res.statusCode == '201')
                this.$parent.$parent.$parent.snackControll([true, 3000, res.message, 'warning'])
            else
                this.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
        },
        preFindId: function (){
            let obj = {}
            obj.fcltyNum = this.findInf.fcltyNum1
            obj.emailId = this.findInf.emailId
            obj.emailDmn = this.findInf.emailDmn

            if(this.$refs.findIdInf.validate())
                this.findId(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
        },
        preFindPwd: function (){
            let obj = {}
            obj.fcltyNum = this.findInf.fcltyNum2
            obj.id = this.findInf.id
            

            if(this.$refs.findPasswdInf.validate())
                this.findPwd(obj)
            else
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
        },
        emailSelected: function () {
            if(this.findInf.emailDmnSlt == '0')
                this.findInf.emailDmn = ''
            else {
                this.$parent.$parent.$parent.emailCode.forEach(cd => {
                    if(this.findInf.emailDmnSlt == cd.valcd)
                        this.findInf.emailDmn = cd.valcdnm
                });
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({
        findInf: {
            fcltyNum1: '', fcltyNum2: '', emailId: '', emailDmn: '', emailDmnSlt: '0', id: '',
        },
        rules: {
            fcltyConfirm: value => value.length == 11 || '잘못된 기관코드 입니다.',
            bsnsConfirm: value => value.length == 10 || '잘못된 사업자 번호 입니다.',
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            required: value => !!value || '필수입력 항목 입니다.',
            strIdSize: value => value.length > 1 || '2자리 이상 입력해주세요.',
            regExp: value => !(/[^\w\sㄱ-힣()0-9 ]/g.test(value)) || '특수문자는 입력이 불가능 합니다.',
            passwdReq: value => value.length > 3 || '비밀번호는 4자리 이상 입니다.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>