<template>
    <v-sheet class="" rounded="lg">
        <v-row class="mt-2 pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="5">
                <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
                <span class="text-h6 font-weight-bold">계약서&nbsp;확인사항</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center pr-1" cols="2">
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog[0]" persistent max-width="900">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                            min-width="30" height="25" v-bind="attrs" v-on="on"
                            small outlined>
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi-file-document-edit-outline</v-icon>
                                        <span class="fontOneRem ml-1" style="padding-bottom: 2px;">작성</span>
                                    </div>
                                </template>
                                <span>계약서 확인사항 관리</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <CnFmItem @modalClose="modalClose" @cntrcfmListLoad="getCntrcfmList"></CnFmItem>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-2 mr-1" style="border-top: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" style="border-right: 1px solid #dbdbdb !important;" cols="8">
                <span class="black--text font-weight-medium py-2">확인&nbsp;내용</span>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="4">
                <span class="black--text font-weight-medium">확인</span>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mr-1" style="border-top: 1px solid #dbdbdb;"
            v-for="(list, i) in cntrcCnfm" :key="i" no-gutters>
            <v-col class="d-flex justify-left align-center pa-0 ma-0 pl-1" style="border-right: 1px solid #dbdbdb !important;" cols="8">
                <span class=" py-2">{{list.cnfmCntnt}}</span>
            </v-col>
            <v-col class="pa-0 ma-0 d-flex align-center" cols="4">
                <span class="d-inline-block black--text font-weight-medium" style="width: 100%;">
                    <v-radio-group v-model="list.cnfmYn" class="" ref="info" hide-details row>
                        <span class="d-flex justify-center" style="margin:auto;">
                            <v-radio :ripple="false" label="예" value="1"></v-radio>
                            <v-radio :ripple="false" label="아니오" value="2"></v-radio>
                        </span>
                    </v-radio-group>
                </span>
            </v-col>
        </v-row>
        <v-divider></v-divider>

        <div class="d-flex align-center mt-6 pr-1">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">전자서명</span>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25" @click="cntrcCnfmMsg()"
                small outlined>
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center" v-bind="attrs" v-on="on">
                            <v-icon size="14">mdi-arrow-expand-right</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">서명요청</span>
                        </div>
                    </template>
                    <span>서명요청발송</span>
                </v-tooltip>
            </v-btn>
        </div>
        <v-row class="pa-0 ma-0 mt-2 mr-1" style="border-top: 1px solid #dbdbdb;" no-gutters>
            <v-col class="greyE01 d-flex justify-center align-center py-1" style="border-right: 1px solid #dbdbdb !important;" cols="6">
                <span class="black--text font-weight-medium mr-2" style="font-size: 1.2rem;">수급자</span>
                <v-dialog v-model="dialog[1]" persistent max-width="450">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-1" color="blueBtnColor"
                            min-width="30" height="26" v-bind="attrs" v-on="on"
                            small icon>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon style="padding-top: 1px;" v-bind="attrs" v-on="on" size="24">mdi-pencil-box-outline</v-icon>
                                </template>
                                <span>전자서명</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <SignComp
                        :mmbrPk="bnfcrCntrc.bnMmbrPk" :bzClcdPk="bnfcrCntrc.bnfcrCntrcInfPk" :bnMmbrPk="bnfcrCntrc.bnMmbrPk"
                        :eSigClcd=1 @modalClose="modalClose" @reload="blobChanger">
                    </SignComp>
                </v-dialog>
            </v-col>
            <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="6">
                <span class="black--text font-weight-medium mr-2">보호자</span>
                <v-dialog v-model="dialog[2]" persistent max-width="450">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="ml-1" color="blueBtnColor"
                            min-width="30" height="26" v-bind="attrs" v-on="on"
                            small icon>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon style="padding-top: 1px;" v-bind="attrs" v-on="on" size="24">mdi-pencil-box-outline</v-icon>
                                </template>
                                <span>전자서명</span>
                            </v-tooltip>
                        </v-btn>
                    </template>
                    <SignComp
                        :mmbrPk="bnfcrCntrc.garMmbrPk" :bzClcdPk="bnfcrCntrc.bnfcrCntrcInfPk" :bnMmbrPk="bnfcrCntrc.bnMmbrPk"
                        :eSigClcd=2 @modalClose="modalClose" @reload="blobChanger">
                    </SignComp>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mr-1" style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb;" no-gutters>
            <v-col class="d-flex justify-center align-center pa-0 ma-0" style="border-right: 1px solid #dbdbdb !important;" cols="6">
                <span class="py-2">
                    <v-img :aspect-ratio="4/3" eager
                        :src="bnfcrSrc.src" width="120" height="90"
                        lazy-src="../../assets/noSignImg.png">
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                    indeterminate color="grey lighten-5">
                                </v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </span>
            </v-col>
            <v-col class="d-flex justify-center align-center pa-0 ma-0" cols="6">
                <span class="py-2">
                    <v-img :aspect-ratio="4/3" eager
                        :src="fmlySrc.src" width="120" height="90"
                        lazy-src="../../assets/noSignImg.png">
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                    indeterminate color="grey lighten-5">
                                </v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </span>
            </v-col>
        </v-row>

        <v-snackbar
            v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
            :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute centered top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
            </div>
        </v-snackbar>
    </v-sheet>
</template>

<script>
import { setCntrcCnfm } from '../../api/bnfcr.js';
import { getBlobSignURL } from '../../api/common.js';
import CnFmItem from '../../components/bnfcr/BnfcrBasicCnFmItm.vue';
import SignComp from '../../components/commons/SignModel.vue';

export default {
    name: 'BnfcrInFrmMgmtCntrcAcptn',

    props : {
        prtCntrcCnfm: Array,
        prtBnfcrCntrc: Object,
    },

    created: function(){
        this.bnfcrCntrc = this.dummyBnfcrCntrc
        this.cntrcCnfm = this.dummyCntrcCnfm
    },

    computed: {

    },
            
    components: {
        CnFmItem,
        SignComp,
    },
    
    mounted: function(){
        this.$nextTick(function () {
            
        })
    },

    watch: {
        'prtBnfcrCntrc.bnfcrCntrcInfPk': function(){
            this.bnfcrCntrc = Object.assign({}, this.prtBnfcrCntrc)

            window.URL.revokeObjectURL(this.bnfcrSrc.src)
            window.URL.revokeObjectURL(this.fmlySrc.src)
            this.bnfcrSrc = getBlobSignURL(this.$store.getters.getFcltyNum, 1, this.bnfcrCntrc.bnfcrCntrcInfPk)
            this.fmlySrc = getBlobSignURL(this.$store.getters.getFcltyNum, 2, this.bnfcrCntrc.bnfcrCntrcInfPk)
        },
        'prtCntrcCnfm': function(){
            this.cntrcCnfm = Object.assign([], this.prtCntrcCnfm)
        },
        'bnfcrSrc': function(){
            this.forceRender += 1
        },
        'fmlySrc': function(){
            this.forceRender += 3
        },
    },

    destroyed: function(){
        window.URL.revokeObjectURL(this.bnfcrSrc.src)
        window.URL.revokeObjectURL(this.fmlySrc.src)
    },
        
    methods: {
        setCntrcCnfmInf: function () {
            setCntrcCnfm(this.$store.getters.getFcltyNum, this.$store.getters.getUserNm, this.bnfcrCntrc.bnfcrCntrcInfPk, this.cntrcCnfm)
                .then((response) => ( this.insCntrcfmItmAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/setCntrcCnfm : ' + error))
        },
        insCntrcfmItmAfter: function (res) {
            if(res.statusCode == 200){
                this.getCntrcfmList()
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else
                this.snackControll([true, 5000, res.message, 'error'])

        },
        getCntrcfmList: function () {
            this.$emit('reload', 'CntrcfmList')
        },
        modalClose: function (index) {
            this.dialog.splice(index, 1, false)
        },
        blobChanger: function (value) {
            this.$store.commit('setTmpObj', null)
            this.$store.commit('setTmpObj', {})

            if(value == 1){
                this.bnfcrSrc = getBlobSignURL(this.$store.getters.getFcltyNum, 1, this.bnfcrCntrc.bnfcrCntrcInfPk)
            }
            else if(value == 2){
                this.fmlySrc = getBlobSignURL(this.$store.getters.getFcltyNum, 2, this.bnfcrCntrc.bnfcrCntrcInfPk)
            }
            //this.$parent.$parent.$parent.$parent.$parent.$refs.bnfcrCntDcmnt.getCntrcList(this.$store.getters.getBnfcrState.bnfcrCntrcInfPk)
        },
        cntrcCnfmMsg: function () {
            this.$parent.$parent.$parent.$parent.$parent.bizMsgInfo.bzTitle = '장기이용급여 표준약관'
            this.$parent.$parent.$parent.$parent.$parent.bizMsgInfo.docClcd = 1
            this.$parent.$parent.$parent.$parent.$parent.sendMsgAct(this.$parent.$parent.bnfcrCntrc.bnfcrCntrcInfPk)
        },
        snackControll: function (options) {
            this.snackOptions = options
            //this.snackOptions[0] = true
            //this.snackOptions[1] = 2500
            //this.snackOptions[2] = '신규 작성 모드'
            //info, success, warning, error
            //this.snackOptions[3] = 'info'
        },
        
    },
    
    data: () => ({
        bnfcrCntrc: {},
        cntrcCnfm: [],
        dummyBnfcrCntrc: {
            bnMmbrPk: 0, snackCntCd: '', sftNtcOfrDt: '', bnfcrCntrcInfPk: 0, btyFee: '', snackOfrClcd: '', eldHmnRcvNm: '', pymntDt: '',
            eldHmnOfrDt: '', eldHmnRcvPstnCd: '', cntrcPrdEnd: '', prvcyAgrYn: '', sftNtcRcvNm: '', cntrcPrdStrt: '', sftNtcOfrYn: '', cntrcDt: '',
            prvcyAgrDt: '', sftNtcRcvPstnCd: '', lstYn: '', snackFee: '', mealCostLmtYn: '', lqdFood: '', mfdRsn: '', mealCalcCd: '', eldHmnOfrYn: '',
            lqdFoodClcd: '', pymntMthdCd: '', npymntFeeStd: '', mealCost: '', mealCostLmt: '', garMmbrPk: 0,
        },
        dummyCntrcCnfm: [
            { cntrcCnfmItmPk: '', cnfmCntnt: '', cnfmYn: '', bnfcrCntrcCnfmPk: 0, },
        ],
        bnfcrSrc: { src: '', org: '' },
        fmlySrc: { src: '', org: '' },
        forceRender: 0,
        forceRender2: 0,
        menu: [false, false, false, ],
        dialog: [false, false, false],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>