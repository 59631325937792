<template>
    <v-sheet class="pa-2">
        <v-tabs hide-slider v-model="tab"  color="white" class="black--text"
            active-class="bodyColor" center-active>
            <v-tab v-for="item in tabItems" :key="item.tab" :style="item.style">{{item.tab}}</v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item transition="fade-transition">
                <v-row class="pa-0 ma-0" no-gutters style="width:870px;border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">                    
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="12">                                
                        <span class="black--text font-weight-medium">물리(작업) 치료평가</span>
                    </v-col>
                </v-row>
                <div v-if="examData.length>0" style="height:300px;overflow-y:auto">
                    <v-row v-for="(item, i) in examData" :key="i" 
                        class="pa-0 ma-0" no-gutters  style="width:870px;border-bottom: 1px solid #dbdbdb !important;">
                        <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0"  cols="3">                                
                            <span class="black--text font-weight-medium">{{item.clsfc2Cdnm}}</span>
                        </v-col>
                        <v-col class="d-flex justify-start align-center pa-1 ma-0" style="border-left: 1px solid #dbdbdb !important;" cols="9">                                
                            {{item.evltn}}
                        </v-col>
                    </v-row>
                </div>
                <div v-else >
                    <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb !important;" no-gutters>
                        <v-col class="d-flex pa-0 ma-0" cols="12">
                            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                                데이터가 없습니다.
                            </div>                                
                        </v-col>
                    </v-row>
                </div>                     
            </v-tab-item>
            <v-tab-item transition="fade-transition">                
                 <v-data-table
                    height="300" locale="ko"
                    :headers="headers"
                    :items="examList"
                    hide-default-header
                    hide-default-footer 
                    disable-pagination
                    dense
                    class="elevation-0">

                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr> 
                                <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:610px !important;">{{props.headers[1].text}}</th>
                                <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                                <th class="black--text clmsFixedHeader" style="width:90px !important;">{{props.headers[3].text}}</th>
                            </tr>
                        </thead>
                    </template>

                    <template v-slot:item='{ item }'>                            
                        <tr>
                            <td class="clmsBodyCommon tableBR fixedCol0" >{{item.examDt}}</td>
                            <td class="clmsBodyCommon tableBR fixedCol0" ><div style="width:600px" class="text-overflow">{{item.evltn}}</div></td>
                            <td class="clmsBodyCommon tableBR fixedCol0" >{{item.wrtr}}</td>
                            <td class="clmsBodyCommon fixedCol0" >
                                <v-btn icon :color="item.color" @click.stop="showDialog(item.bnfcrExamHisPk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>          
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>
<script>
export default {
    name: 'PhyscThrpyInspcPlnTab1',
    
    props : {        
        
    },

    components: {
    },

    computed: {
        
    },  

    created: function(){
    },

    mounted: function(){
    },

    watch: {
    },

    methods: {
        showDialog:function(examPk){
            this.$emit("onPhyscThrpyEvltn",examPk)
        },
        getExamInfDataAfter(data){  
            this.examData = data            
        },
        getBnfcrPhyThrExamListAfter: function (res) { 
            this.examList = res

            this.pageCount = Number(res.rnTot)
            this.examList = res.list

            this.examList.forEach(x=>{
                x.examDt = x.examDt?.beforeDateFormatHyp()
            });
        },
    },

    data: () => ({
        tab:0,     
        pageCount:0,   
        examData:[],  
        examList:[],      
        tabItems: [
            { index: 0, tab: '평가정보', style: 'font-size: 1.2rem !important;'},
            { index: 1, tab: '평가내역', style: 'font-size: 1.2rem !important;'},
        ],
        headers: [
            { text: '작성일', value: '', align: 'center', width: '90', class: 'black--text greyE01 tableHeaderSize'},
            { text: '평가내용', value: 'aplctClcd', align: 'center', width: '300', class: 'black--text greyE01 tableHeaderSize'},
            { text: '작성자', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
            { text: '상세', value: '', align: 'center', width: '70', class: 'black--text greyE01 tableHeaderSize'},
        ],        
    }),
};
</script>