<template>
    <v-sheet class="ma-0 pa-0" rounded="lg">
        <div class="blue600 rounded px-4 mt-3 mb-2 d-flex justify-center align-center elevation-2">
            <span class="d-inline-block" style="width: 100px;">
                <v-select class="pdfZoomSelect" color="white" @change="reRenderSvg()"
                    v-model="pdfScale" height="28"
                    item-text="valcdnm" item-value="valcd" :items="zoomItems"
                    dense hide-details outlined>
                </v-select>
            </span>
            <v-spacer></v-spacer>
            <span class="d-flex align-center">
                <v-btn class="pt-1 pr-1 mr-2" color="white" large icon @click="isPrev"
                    style="width:26px;" height="26">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-text-field class="pdfPage" color="white" @input="reRenderSvg()"
                    v-model="page" type="number" :max="totalCnt" :min=1
                    hide-details outlined dense>
                </v-text-field>
                <span class="mx-2 white--text" style="font-size: 1.3rem;">&#47;</span>
                <span class="white--text" style="font-size: 1.3rem;">{{totalCnt}}</span>
                <v-btn class="pt-1 ml-3" color="white" large icon @click="isNext"
                    style="width:26px;" height="26">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </span>
            <v-spacer></v-spacer>
            <v-btn
                class="mb-1" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" color="white"
                @click="fileDownLoader(pdfPath)" icon>
                <v-tooltip top >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="white--text mt-1" v-bind="attrs" v-on="on" small>mdi-download</v-icon>
                    </template>
                    <span>Pdf Download</span>
                </v-tooltip>
            </v-btn>
            <v-btn
                class="mb-1 mr-1" style="min-width: 24px; padding: 0px 0px; margin: 2px 0;" color="white"
                @click="onPdfPrint()" icon>
                <v-tooltip top >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="white--text mt-1" v-bind="attrs" v-on="on">mdi-printer-outline</v-icon>
                    </template>
                    <span>Pdf Print</span>
                </v-tooltip>
            </v-btn>
        </div>

        <div id="svg-print" class="d-flex justify-center overflow-y-auto" style="height: 520px;">
            <span id="the-svg" ref="parentSvg"></span>
        </div>
    </v-sheet>
</template>

<script>
import { fileDownload } from '../../api/index.js';

import * as printJs from 'print-js';
import * as pdfjsLib from "pdfjs-dist";

import "pdfjs-dist/web/pdf_viewer.css";

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.0.279/build/pdf.worker.min.js'

export default {
    name: 'PdfViewer',

    props : {
        url: { type: String, default: '' },
        pdfPath: { type: Object, default: new Object() },
    },
            
    components: {
      
    },

    created: function(){
  
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {

        })
    },

    computed: {

    },

    watch:{
        'url': function () {
            if(this.url != ''){
                this.clear()
                this.loadingTask = pdfjsLib.getDocument(this.url, this.pdfOptions)

                this.getPdfForSvg()
            }
        }
    },

    beforeDestroy: function () {
        this.loadingTask?.destroy()
    },
        
    methods: {

        fileDownLoader: function (obj) {
            fileDownload(obj)
                .then((response) => ( this.fileDownLoaderAfter(response) ))
                .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
        },

        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;

            fileName = fileName.replaceAll('"', '')
            //fileName = this.pdfPath.orgFileNm
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        onPdfPrint: async function () {
            printJs({printable:this.url, type:'pdf', showModal:true}) 
        },
        //Do not Use!!
        oldPdfPrint: async function () {
            this.$refs.parentSvg.firstChild?.remove()
            this.$refs.parentSvg.style = ''

            this.getPdfAllPagesForSvg()

            while (this.chkSum != this.totalCnt) {
                await this.sleep(3)
            }

            let src = document.querySelectorAll('image')
            console.log(src)
            var mywindow = window.open('', 'PRINT', 'height=820,width=1150');
            mywindow.document.write('<body style="padding: 0px; margin: 0px; margin-top: 20px;">');
            src.forEach(items => {
                mywindow.document.write('<img src="' + items.href.baseVal + '" style="width: 800px;" />');
            });
            mywindow.document.write('</body>');

            mywindow.document.close();  // necessary for IE >= 10
            mywindow.focus();           // necessary for IE >= 10


            mywindow.print();
            mywindow.close();

            var list = document.getElementById("the-svg");

            while (list.hasChildNodes()) {
                list.removeChild(list.firstChild)
            }

            this.$refs.parentSvg.style = ''
            this.chkSum = 0
            this.page = 1
            this.getPdfForSvg()
        },

        getPdfForCanvas: async function () {
            let res = ''
                
            this.loadingTask.promise.then(function(pdf) {
                console.log('PDF loaded');
                
                // Fetch the first page
                let pageNumber = 1;
                
                pdf.getPage(pageNumber).then(function(page) {
                    console.log('Page loaded');
                    
                    var scale = 3
                    //var outputScale = window.devicePixelRatio
                    var viewport = page.getViewport({scale: scale})

                    // Prepare canvas using PDF page dimensions
                    var canvas = document.createElement("canvas")
                    var context = canvas.getContext('2d')
             
                    canvas.height = viewport.height
                    canvas.width = viewport.width
       
                    // Render PDF page into canvas context
                    var renderContext = {
                        canvasContext: context,
                      
                        viewport: viewport
                    };

                    var renderTask = page.render(renderContext)

                    renderTask.promise.then(function () {
                        console.log('Page rendered')
                    });

                    canvas.toBlob((blob) => {
                        res = URL.createObjectURL(blob)
                        console.log(res)
                    })

                });
            }, function (reason) {
                // PDF loading error
                console.error(reason)
            });

            return res
        },
        getPdfForSvg: async function() {
            let me = this
                
            this.loadingTask.promise.then(function(pdf) {
                //console.log('PDF loaded')
                
                // Fetch the first page
                me.totalCnt = pdf.numPages
               
                pdf.getPage(Number(me.page)).then(function(page) {
                    // Set scale (zoom) level
                    // Get viewport (dimensions)
                    var viewport = page.getViewport({scale: me.pdfScale})
                    // Get div#the-svg
                    var container = document.getElementById('the-svg')
           
                    // Set dimensions
                    container.style.width = viewport.width + 'px'
                    container.style.height = viewport.height + 'px'

                    // SVG rendering by PDF.js
                    page.getOperatorList()
                        .then(function (opList) {
                            var svgGfx = new pdfjsLib.SVGGraphics(page.commonObjs, page.objs);
                           
                            return svgGfx.getSVG(opList, viewport);
                        })
                        .then(function (svg) {
                            container.appendChild(svg);
                        });
                });
            }, function (reason) {
                // PDF loading error
                console.error(reason)
            });
        },

        getPdfAllPagesForSvg: async function() {
            let me = this
                
            await this.loadingTask.promise.then(function(pdf) {
                console.log('PDF loaded')
                
                // Fetch the first page
                me.totalCnt = pdf.numPages
                for (let index = 1; index <= pdf.numPages; index++) {
                    pdf.getPage(index).then(function(page) {
                        // Set scale (zoom) level
                        // Get viewport (dimensions)
                        var viewport = page.getViewport({scale: me.pdfScale})
                        // Get div#the-svg
                        var container = document.getElementById('the-svg')
            
                        // Set dimensions
                        container.style.width = viewport.width + 'px'
                        container.style.height = viewport.height + 'px'

                        // SVG rendering by PDF.js
                        page.getOperatorList()
                            .then(function (opList) {
                                var svgGfx = new pdfjsLib.SVGGraphics(page.commonObjs, page.objs);
                            
                                return svgGfx.getSVG(opList, viewport);
                            })
                            .then(function (svg) {
                                container.appendChild(svg);
                                me.chkSum += 1
                            });
                    });
                }
            }, function (reason) {
                // PDF loading error
                console.error(reason)
            });
        },

        isPrev: function () {
            if(this.page > 1){
                this.page -= 1
                this.$refs.parentSvg.firstChild?.remove()
                this.$refs.parentSvg.style = ''
                this.getPdfForSvg()
            }
        },

        isNext: function () {
            if(this.page < this.totalCnt){
                this.page += 1
                this.$refs.parentSvg.firstChild?.remove()
                this.$refs.parentSvg.style = ''
                this.getPdfForSvg()
            }
        },

        reRenderSvg: function () {
            this.$refs.parentSvg.firstChild.remove()
            this.$refs.parentSvg.style = ''
            this.getPdfForSvg()
        },

        clear: function () {
            var list = document.getElementById("the-svg");

            while (list.hasChildNodes()) {
                list.removeChild(list.firstChild)
            }

            this.$refs.parentSvg.style = ''
            this.chkSum = 0
            this.page = 1
            this.pdfScale = 1
            this.totalCnt = 0
        },

        sleep: function(sec) {
            return new Promise(resolve => setTimeout(resolve, sec * 100))    
        },

    },
    
    data: () => ({
        page: 1,
        chkSum: 0,
        pdfScale: 1,
        totalCnt: 0,
        loadingTask: null,
        zoomItems: [
            { valcdnm: '75%', valcd: 0.75 }, { valcdnm: '100%', valcd: 1 }, { valcdnm: '125%', valcd: 1.25 },
            { valcdnm: '150%', valcd: 1.5 }
        ],
        pdfOptions: { cMapPacked: true, cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.0.279/cmaps/', disableFontFace: true, showPreviousViewOnLoad: false, },
    }),
};
</script>

<style>
    .pdfPage div div div input{ color: white !important; }
    .pdfZoomSelect div div fieldset { color: white !important; }
    .pdfZoomSelect div div div .v-input__append-inner { margin-top: 1px !important; }
    .pdfZoomSelect div div div .v-input__append-inner div i { color: white !important; }
    .pdfZoomSelect div div div div div { color: white !important; }
</style>