<template>
    <v-sheet class="d-flex rounded-lg rounded-l-0" color="" min-width="1260" max-width="1560" height="835">
        <v-sheet class="d-inline-block" :width="736" height="835" color="midAreaColor">
            <div class="text-h4 px-2">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">
                    <span>4-5. 프로그램개선&#40;의견수렴&#41;</span>
                </span>
            </div>
            <div class="d-flex align-center justify-center mt-3">
                <span>
                    <expandYearOnlyPicker @nowYear="getYear"></expandYearOnlyPicker>
                </span>
            </div>
            <div class="mt-2 mr-2 ml-2 rounded-lg">
                <v-data-table class="msclnTable" height="635" locale="ko" :headers="headers" :items="items"
                    hide-default-header fixed-header disable-pagination hide-default-footer dense>
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th :class="props.headers[0].class" :style="props.headers[0].style" rowspan='2'>
                                    {{ props.headers[0].text }}</th>
                                <th :class="props.headers[1].class" :style="props.headers[1].style" colspan='2'>
                                    {{ props.headers[1].text }}</th>
                                <th :class="props.headers[2].class" :style="props.headers[2].style" colspan='2'>
                                    {{ props.headers[2].text }}</th>
                                <th :class="props.headers[3].class" :style="props.headers[3].style" colspan='2'>
                                    {{ props.headers[3].text }}</th>
                                <th :class="props.headers[4].class" :style="props.headers[4].style" colspan='2'>
                                    {{ props.headers[4].text }}</th>
                                <th :class="props.headers[5].class" :style="props.headers[5].style" rowspan='2'>
                                    {{ props.headers[5].text }}</th>
                            </tr>
                            <tr style="border-top: 1px solid #dbdbdb !important;">
                                <th :class="props.headers[6].class" :style="props.headers[6].style">
                                    {{ props.headers[6].text }}</th>
                                <th :class="props.headers[7].class" :style="props.headers[7].style">
                                    {{ props.headers[7].text }}</th>
                                <th :class="props.headers[8].class" :style="props.headers[8].style">
                                    {{ props.headers[8].text }}</th>
                                <th :class="props.headers[9].class" :style="props.headers[9].style">
                                    {{ props.headers[9].text }}</th>
                                <th :class="props.headers[10].class" :style="props.headers[10].style">
                                    {{ props.headers[10].text }}</th>
                                <th :class="props.headers[11].class" :style="props.headers[11].style">
                                    {{ props.headers[11].text }}</th>
                                <th :class="props.headers[12].class" :style="props.headers[12].style">
                                    {{ props.headers[12].text }}</th>
                                <th :class="props.headers[13].class" :style="props.headers[13].style">
                                    {{ props.headers[13].text }}</th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item }'>
                        <tr class="" >
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne">{{ item.idxNm }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.recQrtr1 == 0">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.recQrtr1 != 0">{{ item.recQrtr1 }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.opnQrtr1 == null">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.opnQrtr1 != null">{{ item.opnQrtr1 }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.recQrtr2 == 0">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.recQrtr2 != 0">{{ item.recQrtr2 }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.opnQrtr2 == null">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.opnQrtr2 != null">{{ item.opnQrtr2 }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.recQrtr3 == 0">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.recQrtr3 != 0">{{ item.recQrtr3 }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.opnQrtr3 == null">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.opnQrtr3 != null">{{ item.opnQrtr3 }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.recQrtr4 == 0">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.recQrtr4 != 0">{{ item.recQrtr4 }}</span>
                            </td>
                            <td class=" tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.opnQrtr4 == null">미작성</span>
                                <span class="cmmFontSizeOne" v-if="item.opnQrtr4 != null">{{ item.opnQrtr4 }}</span>
                            </td>
                            <td class="tableBB  tableContentCommon" style="height:40px !important; text-align: center;">
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.rflDt == null">미작성</span>
                                <span class="cmmFontSizeOne" style="color: red;" v-if="item.rflDt != null">{{ item.rflDt }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <div style="height: 5rem; text-align: center;">
                <span>※ 인지, 여가 프로그램 의견수렴을 분기별 1회 이상 실시하고 그 내용을 연 1회 이상 반영 </span>
            </div>
        </v-sheet>
        <router-view ref="prgmChild"></router-view>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import { selPrgImprvQrtrList } from '../../api/prgrm.js';
import { getStcd } from '../../api/index.js';
export default {
    name: 'PrgrmvCnfgr',

    components: {
        expandYearOnlyPicker,

    },

    created: function () {
        getStcd('40')
            .then((response) => (this.stCdMapping(response.data)))
            .catch((error) => console.log(error))
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
           
        })
    },

    computed: {

    },

    watch: {
        'yDate': function (value) {
            this.selPrgImprvQrtrList(value)
            this.$refs.prgmChild.selPrgOpRecList()
            this.$refs.prgmChild.selPrgImprvList()
        },
    },

    methods: {
        selPrgImprvQrtrList: function (yyyy) {
            selPrgImprvQrtrList(this.$store.getters.getFcltyNum, yyyy)
                .then((response) => (this.selPrgImprvQrtrListAfter(response.data)))
                .catch((error) => console.log('connect error /bnfcr/selPrgImprvQrtrList : ' + error))
        },
        selPrgImprvQrtrListAfter: function (res) {            
            this.items = res
            this.items.forEach(e => {
                this.cdItems.forEach(i => {
                    if (e.idxCd == i.valcd) {
                        this.idxCdNm = i.valcdnm
                    }
                });
            });
        },
        stCdMapping: function (res) {
            this.cdItems = res
        },
        getYear: function (obj) {
            this.yDate = obj
        },
    },

    data: () => ({
        headers: [
            { text: '지표구분', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '1분기', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '2분기', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '3분기', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '4분기', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '의견반영', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '제공', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '의견수렴', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '제공', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '의견수렴', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '제공', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '의견수렴', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '제공', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
            { text: '의견수렴', value: '', align: 'center', on: false, sortable: true, class: 'black--text sftyHeader py-2', style: 'border-bottom: 2px solid !important;' },
        ],
        midAreaWidth: '560',
        yDate: 1990,
        items: [],
        cdItems: [],
        idxCdNm: '',
        prgImprvPk: 0,
    }),
};
</script>