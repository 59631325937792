<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">생활실 투약시간 설정</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn v-if="list.type == 'action' && list.visible" :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(list.text)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
        </div>
        <v-form ref="form" lazy-validation>
        <div class="mt-5">
            <div :class="['pa-0 mt-1 d-flex greyE01',lvnRmDoseList.length > 15 ? 'mr-2__5' : '']">
                <div class="d-flex justify-center align-center pa-1 ma-0 tableBT tableBB" style="width:15%;">
                    <span class="black--text font-weight-medium">건물명</span>
                </div>
                <div class="d-flex justify-center align-center pa-1 ma-0 tableBT tableBL tableBB" style="width:5%;">
                    <span class="black--text font-weight-medium">층</span>
                </div>
                <div class="d-flex justify-center align-center pa-1 ma-0 tableBT tableBL tableBB" style="width:15%;">
                    <span class="black--text font-weight-medium">생활실</span>
                </div>
                <div class="pa-0 ma-0 tableBT tableBL tableBB" style="width:19%;">
                    <div class="d-flex justify-center align-center pa-1">
                        <span class="black--text font-weight-medium">아침</span>
                    </div>
                    <div class="d-flex tableBT">
                        <div class="d-flex justify-center align-center pa-1" style="width:50%">
                            <span class="black--text font-weight-medium">식전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 tableBL" style="width:50%">
                            <span class="black--text font-weight-medium">식후</span>
                        </div>
                    </div>
                </div>
                <div class="pa-0 ma-0 tableBT tableBL tableBB" style="width:19%;">
                    <div class="d-flex justify-center align-center pa-1">
                        <span class="black--text font-weight-medium">점심</span>
                    </div>
                    <div class="d-flex tableBT">
                        <div class="d-flex justify-center align-center pa-1" style="width:50%">
                            <span class="black--text font-weight-medium">식전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 tableBL" style="width:50%">
                            <span class="black--text font-weight-medium">식후</span>
                        </div>
                    </div>
                </div>
                <div class="pa-0 ma-0 tableBT tableBL tableBB" style="width:19%;">
                    <div class="d-flex justify-center align-center pa-1">
                        <span class="black--text font-weight-medium">저녁</span>
                    </div>
                    <div class="d-flex tableBT">
                        <div class="d-flex justify-center align-center pa-1" style="width:50%">
                            <span class="black--text font-weight-medium">식전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 tableBL" style="width:50%">
                            <span class="black--text font-weight-medium">식후</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center align-center pa-1 ma-0 tableBT tableBL tableBB" style="width:8%;">
                    <span class="black--text font-weight-medium">취침전</span>
                </div>
            </div>
            <div v-if="lvnRmDoseList.length > 0" class="overflow-y-auto" style="height:555px;">
                <div v-for="(item, index) in lvnRmDoseList" :key="index" class="d-flex">
                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBB" style="width:15%;">
                        {{item.bldNm}}
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:5%;">
                        {{item.bldFlrNm}}
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:15%;">
                        {{item.lvnRmNm}}
                    </div>
                    <div class="pa-0 ma-0 tableBL tableBB" style="width:19%;">                   
                        <div class="d-flex">
                            <div class="d-flex justify-center align-center pa-1" style="width:50%">
                                <span style="width:60px;"><CmmTimeField :ref="`cmmtime_`+(index)" v-model="item.brkfsBfTm" @nxtFocus="nxtAction(index,1)"></CmmTimeField></span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 tableBL" style="width:50%">
                                <span style="width:60px;"><CmmTimeField v-model="item.brkfsAfTm" :ref="`cmmtime_`+(index)" @nxtFocus="nxtAction(index,2)"></CmmTimeField></span>
                            </div>
                        </div>
                    </div>
                    <div class="pa-0 ma-0 tableBL tableBB" style="width:19%;">                   
                        <div class="d-flex ">
                            <div class="d-flex justify-center align-center pa-1" style="width:50%">
                                <span style="width:60px;">
                                    <CmmTimeField v-model="item.lunchBfTm" :ref="`cmmtime_`+(index)" @nxtFocus="nxtAction(index,3)"></CmmTimeField>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 tableBL" style="width:50%">
                                <span style="width:60px;">
                                    <CmmTimeField v-model="item.lunchAfTm" :ref="`cmmtime_`+(index)" @nxtFocus="nxtAction(index,4)"></CmmTimeField>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="pa-0 ma-0 tableBL tableBB" style="width:19%;">                   
                        <div class="d-flex">
                            <div class="d-flex justify-center align-center pa-1" style="width:50%">
                                <span style="width:60px;">
                                    <CmmTimeField v-model="item.dinerBfTm" :ref="`cmmtime_`+(index)" @nxtFocus="nxtAction(index,5)"></CmmTimeField>
                                </span>
                            </div>
                            <div class="d-flex justify-center align-center pa-1 tableBL" style="width:50%">
                                <span style="width:60px;">
                                    <CmmTimeField v-model="item.dinerAfTm" :ref="`cmmtime_`+(index)" @nxtFocus="nxtAction(index,6)"></CmmTimeField>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:8%;">
                        <span style="width:60px;">
                            <CmmTimeField v-model="item.bedBfTm" :ref="`cmmtime_`+(index)" @nxtFocus="nxtAction(index,0)"></CmmTimeField>
                        </span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex justify-center align-center pa-1 ma-0 tableBB" style="width:100%;">
                    <span class="black--text font-weight-medium">※ 목록이 없습니다.</span>
                </div>
            </div>    
        </div>
        </v-form>
    </v-sheet>
</template>

<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import { selLvnRmDoseList, insLvnRmDose } from '../../api/bnftrcrd.js';

export default {
    name: 'MdctnMgmtLvnRmTimer',

    props : { 

    },
            
    components: {
        CmmTimeField
    },

    created: function(){
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getLvnRmDoseList()
        })
    },

    computed: {
    
    },

    watch: {
    },
        
    methods: {  
        getLvnRmDoseList(){  
            selLvnRmDoseList(this.$store.getters.getFcltyNum)
                .then((response) => ( this.getLvnRmDoseListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnfcr/selCopayClmHisList : ' + error))
        },     
        setLvnRmDose(){ 
            if(this.$refs.form.validate()){

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm : this.$store.getters.getUserNm,
                    list:[]
                }
    
                this.lvnRmDoseList.forEach(el => {
    
                    let brkfsBfTmHh = ''
                    let brkfsBfTmMm = ''
                    let brkfsAfTmHh = ''
                    let brkfsAfTmMm = ''
                    let lunchBfTmHh = ''
                    let lunchBfTmMm = ''
                    let lunchAfTmHh = ''
                    let lunchAfTmMm = ''
                    let dinerBfTmHh = ''
                    let dinerBfTmMm = ''
                    let dinerAfTmHh = ''
                    let dinerAfTmMm = ''
                    let bedBfTmHh = ''
                    let bedBfTmMm = ''
                    
                    if(el.brkfsBfTm?.isEmptyVal()) {
                        let brkfsBfTm = el.brkfsBfTm.split(":")
                        brkfsBfTmHh = brkfsBfTm[0]
                        brkfsBfTmMm = brkfsBfTm[1]
                    }
                        
                    if(el.brkfsAfTm?.isEmptyVal())  {
                        let brkfsAfTm = el.brkfsAfTm.split(":")
                        brkfsAfTmHh = brkfsAfTm[0]
                        brkfsAfTmMm = brkfsAfTm[1]
                    }
                    
                    if(el.lunchBfTm?.isEmptyVal())  {
                        let lunchBfTm = el.lunchBfTm.split(":")
                        lunchBfTmHh = lunchBfTm[0]
                        lunchBfTmMm = lunchBfTm[1]
                    }
                    
                    if(el.lunchAfTm?.isEmptyVal())  {
                        let lunchAfTm = el.lunchAfTm.split(":")
                        lunchAfTmHh = lunchAfTm[0]
                        lunchAfTmMm = lunchAfTm[1]
                    }
                    
                    if(el.dinerBfTm?.isEmptyVal())  {
                        let dinerBfTm = el.dinerBfTm.split(":")
                        dinerBfTmHh = dinerBfTm[0]
                        dinerBfTmMm = dinerBfTm[1]
                    }
                    
                    if(el.dinerAfTm?.isEmptyVal())  {
                        let dinerAfTm = el.dinerAfTm.split(":")
                        dinerAfTmHh = dinerAfTm[0]
                        dinerAfTmMm = dinerAfTm[1]
                    }
                    
                    if(el.bedBfTm?.isEmptyVal())  {
                        let bedBfTm = el.bedBfTm.split(":")
                        bedBfTmHh = bedBfTm[0]
                        bedBfTmMm = bedBfTm[1]
                    }
    
                     let item = {
                        lvnRmInfPk:el.lvnRmInfPk,
                        lvnRmDosePk:el.lvnRmDosePk,
                        brkfsBfTmHh : brkfsBfTmHh,
                        brkfsBfTmMm : brkfsBfTmMm,
                        brkfsAfTmHh : brkfsAfTmHh,
                        brkfsAfTmMm : brkfsAfTmMm,
                        lunchBfTmHh : lunchBfTmHh,
                        lunchBfTmMm : lunchBfTmMm,
                        lunchAfTmHh : lunchAfTmHh,
                        lunchAfTmMm : lunchAfTmMm,
                        dinerBfTmHh : dinerBfTmHh,
                        dinerBfTmMm : dinerBfTmMm,
                        dinerAfTmHh : dinerAfTmHh,
                        dinerAfTmMm : dinerAfTmMm,
                        bedBfTmHh : bedBfTmHh,
                        bedBfTmMm : bedBfTmMm,
                     }
    
                    obj.list.push(item)
                });
    
                insLvnRmDose(obj)
                    .then((response) => ( this.setLvnRmDoseAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnfcr/selCopayClmHisList : ' + error))
            }
        },
        getLvnRmDoseListAfter:function(res){
            this.lvnRmDoseList.splice(0)
            res.forEach(item => {
                if(item.brkfsBfTmHh?.isEmptyVal() && item.brkfsBfTmMm?.isEmptyVal()) item.brkfsBfTm = item.brkfsBfTmHh + ":" + item.brkfsBfTmMm
                if(item.brkfsAfTmHh?.isEmptyVal() && item.brkfsAfTmMm?.isEmptyVal()) item.brkfsAfTm = item.brkfsAfTmHh + ":" + item.brkfsAfTmMm
                if(item.lunchBfTmHh?.isEmptyVal() && item.lunchBfTmMm?.isEmptyVal()) item.lunchBfTm = item.lunchBfTmHh + ":" + item.lunchBfTmMm
                if(item.lunchAfTmHh?.isEmptyVal() && item.lunchAfTmMm?.isEmptyVal()) item.lunchAfTm = item.lunchAfTmHh + ":" + item.lunchAfTmMm
                if(item.dinerBfTmHh?.isEmptyVal() && item.dinerBfTmMm?.isEmptyVal()) item.dinerBfTm = item.dinerBfTmHh + ":" + item.dinerBfTmMm
                if(item.dinerAfTmHh?.isEmptyVal() && item.dinerAfTmMm?.isEmptyVal()) item.dinerAfTm = item.dinerAfTmHh + ":" + item.dinerAfTmMm
                if(item.bedBfTmHh?.isEmptyVal() && item.bedBfTmMm?.isEmptyVal()) item.bedBfTm = item.bedBfTmHh + ":" + item.bedBfTmMm
                this.lvnRmDoseList.push(item)
            });
        }, 
        setLvnRmDoseAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getLvnRmDoseList()
                this.$emit("modalEvent",99)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClickBtn:function(text){
            if(text=='저장') this.setLvnRmDose()
            else if(text == '기본값') this.resetVal()
        },
        resetVal:function(){
            this.lvnRmDoseList.forEach((item,index) => {
                let min = (index < 60)?('00'+index).slice(-2):'00'
                item.brkfsBfTm = '07:'+min
                item.brkfsAfTm = '08:'+min
                item.lunchBfTm = '11:'+min
                item.lunchAfTm = '12:'+min
                item.dinerBfTm = '16:'+min
                item.dinerAfTm = '17:'+min
                item.bedBfTm = '21:'+min
            });
        },
        onClose: function () {        
            this.$emit('isClose', '.')
        },                
        nxtAction:function(key, index){
            
            if(index != 0){
                let target =  'cmmtime_'+key
                if(this.$refs[target][index].$refs.cmmTimeField != undefined)  this.$refs[target][index].$refs.cmmTimeField.focus()
            }else{
                let target =  'cmmtime_'+(key+1)
                if(this.$refs[target] != undefined && this.$refs[target][index].$refs.cmmTimeField != undefined)  this.$refs[target][index].$refs.cmmTimeField.focus()
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({
        test:'',
        forceRender:0,
        headers: [
            { text: '건물', value: 'bldNm'},
            { text: '층', value: 'bldFlrNm'},
            { text: '생활실', value: 'lvnRmNm'},
            { text: '아침', value: ''},          
            { text: '점심', value: ''},
            { text: '저녁', value: ''},            
            { text: '취침전', value: ''},
        ], 
        lvnRmDoseList:[],
        filledBtn: [
            { icon: 'mdi-cog-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '기본값',type: 'action', visible: true,disabled: false, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장',type: 'action', visible: true,disabled: false, },
        ],
        snackOptions: [false, 3000, '', 'info'],
    }),
};
</script>